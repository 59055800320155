import { Alert, Center, Title, Stack, Group, Button } from '@mantine/core';
import { ParrotIcon } from 'components/Sidebar/icons';
import { UserContext } from 'context/UserContext';
import queryString from 'query-string';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_CUSTOMER } from 'hooks/useCustomerParams';

export function NotFound() {
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const shownPath = pathname || queryString.parse(search)?.path;

  const handleReturnFrom404 = () => {
    navigate(`/${DEFAULT_CUSTOMER.path}`);
  };

  return (
    <Center sx={{ height: '100vh', backgroundColor: 'white', flex: 1 }}>
      <Stack align="center" spacing="xs">
        <Group>
          <ParrotIcon className="parrot-icon" />
          <Title size={50} order={1}>Page not found</Title>
        </Group>
        <Stack spacing="sm" align="center">
          <Title order={2}>The following url or customer doesn&apos;t exist</Title>
          <Alert color="blue" sx={{ textAlign: 'center' }}>
            <code>{shownPath}</code>
          </Alert>
          {!!currentUser && (
            <Center>
              <Button my="sm" onClick={handleReturnFrom404}>Return to Studio</Button>
            </Center>
          )}
        </Stack>
      </Stack>
    </Center>
  );
}
