import React, { ReactElement, useCallback, useEffect, useMemo, useContext } from 'react';
import { ModelWizardProjectStep } from 'models';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { createModelWorkflow, listModelWorkflow } from 'store/modelWorkflow/asyncThunks';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { CreateModelWorkflowRequest, ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { selectModelWorkflows } from 'store/modelWorkflow/selectors';
import { UserContext } from 'context/UserContext';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';
import { Paper, Stack, Text } from '@mantine/core';
import { SuggestionsModelArtifactInputsModelType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import ModelWizardProjectStepper from 'pages/ModelWizardProject/ModelWizardProjectStepper';
import dayjs from 'dayjs';
import { selectDerivedLabelingTasksMap } from 'store/labelingTask/selectors';
import CopyableValue from 'components/CopyableValue';
import { ConfigStep, EvalStep, TrainStep } from '../components/Steps';
import { GenerativeConfigForm, useGenerativeConfigForm } from './Config';
import { convertTestSetScoresToTableData, FoundConversationsTable, QATable, QuantitativeScoresTable } from '../components/Tables';
import { getSelectionInstruction } from '../../getSelectionInstruction';
import { useQAConfigForm } from '../components/QATaskSetup';
import { useModelWorkflow } from '../useModelWorkflow';

export function Generative(): ReactElement {
  const [activeStepIndex, setActiveStepIndex] = useUrlParam<number>('step', 0);
  const activeStepIndexNumber = Number(activeStepIndex);
  const [workflowId, setWorkflowId, clearWorkflowId] = useUrlParam<string>('workflowId');
  const [,, clearCompareTo] = useUrlParam<string>('compareTo', '');
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const currentUser = useContext(UserContext);
  const modelWorkflows = useSelector<ModelWorkflow[]>(selectModelWorkflows);
  const modelWorkflow = useModelWorkflow(workflowId);
  const qaTasksMap = useSelector(selectDerivedLabelingTasksMap);
  const qaTask = qaTasksMap.get(getId('labelingTask', modelWorkflow?.trainSuggestionsGenerativeModelOutput?.qaTask?.labelingTask?.name));
  const params = useParams<{ customer: string; projectId: string }>();

  useEffect(() => {
    dispatch(listModelWorkflow({
      parent: customerProfile,
      filter: { modelProjectId: params.projectId },
    }));
  }, [customerProfile, params.projectId]);

  const onClickPreviewEval = useCallback((workflowName: string) => {
    const id = getId('modelWorkflow', workflowName);
    if (id) {
      setWorkflowId(id);
      setActiveStepIndex(2);
    }
  }, [setActiveStepIndex, setWorkflowId]);

  const generativeForm = useGenerativeConfigForm();
  const qaConfigForm = useQAConfigForm();

  const onClickNext = useCallback(() => {
    const modelWorkflowId = crypto.randomUUID();

    const getModelWorkflow: () => ModelWorkflow = () => {
      const modelProject = `${customerProfile}/modelProjects/${params.projectId}`;
      const startTime = dayjs(generativeForm.values.conversationDates[0]).toISOString();
      const endTime = dayjs(generativeForm.values.conversationDates[1]).toISOString();
      const selectionInstruction = getSelectionInstruction(qaConfigForm.values.taskOption, {
        after: qaConfigForm.values.qaConversationDates[0],
        before: qaConfigForm.values.qaConversationDates[1],
        modelUri: qaConfigForm.values.qaComparisonModelUrl,
        maxConversationCount: qaConfigForm.values.qaConversationCount,
      });

      const ret: ModelWorkflow = {
        modelProject,
        trainSuggestionsGenerativeModelConfig: {
          modelType: generativeForm.values.modelType as SuggestionsModelArtifactInputsModelType,
          selectionInstruction,
          datasetInputs: {
            //  * Start date of dataset (inclusive). This should be truncated to a day in America/Los_Angeles
            //  * timezone. If not, the backend will truncate.
            startTime,
            //  * End date of dataset (exclusive). This should be truncated to a day in America/Los_Angeles
            //  * timezone. If not, the backend will truncate.
            endTime,
            //  * Suffix in days of time range to use as test set.
            testSetDaysSuffix: generativeForm.values.testSetDaysSuffix,
            //  * Interval to shard data by when splitting into train/eval/test set.
            shardInterval: generativeForm.values.shardInterval,
            //  * Ratio of train / (train + validation) set split. Should be in (0, 1].
            splitRatioTrain: generativeForm.values.splitRatioTrain === 0 ? 0 : generativeForm.values.splitRatioTrain / 100,
            //  * Whether to extract expert agent chats separately.
            addExperts: generativeForm.values.addExperts,
            // * List of expert agent user ids to use instead of experts from config.
            expertAgentUserIds: generativeForm.values.expertAgentUserIds,
          },
          processConfigOptions: {
            loadPreprocessingFromConfigRepo: generativeForm.values.loadPreprocessingConfig,
            loadPostprocessingFromConfigRepo: generativeForm.values.loadPostprocessingConfig,
            materializeConfigs: generativeForm.values.materializeConfigs,
          },
        },
      };
      return ret;
    };

    const modelWorkflow = getModelWorkflow();
    const request: CreateModelWorkflowRequest = {
      parent: customerProfile,
      modelWorkflowId,
      commonInput: {
        title: modelWorkflowId,
        assigneeUserId: currentUser?.id,
      },
      modelWorkflow,
    };
    dispatch(createModelWorkflow(request)).then(() => {
      setActiveStepIndex(1);
      dispatch(listModelWorkflow({ parent: customerProfile, filter: { modelProjectId: params.projectId } }));
    });
  }, [generativeForm]);

  const quantitaveScoresTableData = useMemo(() => convertTestSetScoresToTableData(modelWorkflow?.trainSuggestionsGenerativeModelOutput?.trainOutputs?.testSetScores || {}), [modelWorkflow]);
  const selectionInstruction = modelWorkflow?.trainSuggestionsGenerativeModelConfig?.selectionInstruction;
  const model = modelWorkflow?.trainSuggestionsGenerativeModelOutput?.ensembleModelUri;
  const STEPS: ModelWizardProjectStep[] = [
    {
      label: 'Configure',
      children: (
        <ConfigStep onClickNext={onClickNext}>
          <GenerativeConfigForm
            generativeForm={generativeForm}
            qaConfigForm={qaConfigForm}
          />
          <div style={{ flex: 1, height: '100%', overflowY: 'auto' }}>
            <Paper p="lg" pb="sm" style={{ height: '100%' }}>
              <Text>Number of conversations found</Text>
              <FoundConversationsTable
                agentIds={generativeForm.values.expertAgentUserIds}
                dateStart={generativeForm.values.conversationDates[0]}
                dateEnd={generativeForm.values.conversationDates[1]}
                splitRatioTrain={generativeForm.values.splitRatioTrain}
                testSetDaysSuffix={generativeForm.values.testSetDaysSuffix}
              />
            </Paper>
          </div>
        </ConfigStep>),
    },
    {
      label: 'Train',
      children: <TrainStep
        onClickPreviewEval={onClickPreviewEval}
        modelWorkflows={modelWorkflows}
      />,
    },
    {
      label: 'Evaluate',
      children: (
        <EvalStep
          modelWorkflows={modelWorkflows}
        >
          <Stack style={{ flex: 1 }}>
            {model && <CopyableValue copiedValue={model} displayValue={model} />}
            <Text>Quantitative Scores</Text>
            <QuantitativeScoresTable dataSource={quantitaveScoresTableData} />
            {selectionInstruction && (
              <>
                <Text>QA Scores</Text>
                <QATable selectionInstruction={selectionInstruction} qaTask={qaTask?.labelingTask} />
              </>
            )}
          </Stack>
        </EvalStep>),
    },
  ];

  return (
    <ModelWizardProjectStepper
      steps={STEPS}
      stepIndex={activeStepIndexNumber}
      onStepClick={(index) => {
        if (index === activeStepIndexNumber) {
          return;
        }
        setActiveStepIndex(index);
        clearWorkflowId();
        clearCompareTo();
      }}
    />
  );
}
