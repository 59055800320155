import React, { ReactElement } from 'react';
import { Table } from 'antd';
import { ModelMetrics, ModelScores } from '@cresta/web-client/dist/cresta/v1/studio/storage/modelbuilder/modelbuilder.pb';
import { ColumnsType, ColumnGroupType } from 'antd/lib/table';

// Component to reshape per-split scores and render a table.
export function ModelScoresTable({ scores }: { scores: ModelScores }): ReactElement {
  if (!scores || (Object.keys(scores?.test || {}).length === 0
    && Object.keys(scores?.val || {}).length === 0 && Object.keys(scores?.train || {}).length === 0)) {
    return <> No scores. </>;
  }
  const train = scores?.train || {};
  const val = scores?.val || {};
  const test = scores?.test || {};
  interface Row {
    intent: string;
    train: ModelMetrics;
    validation: ModelMetrics;
    test: ModelMetrics;
  }
  const intents = Object.keys(scores?.test || {});
  const flattenedScores: Row[] = intents.map((intent) => ({
    intent,
    train: train[intent] || {},
    validation: val[intent] || {},
    test: test[intent] || {},
  })).sort((a, b) => {
    if (a.intent === 'weighted avg') {
      return -1;
    }
    if (b.intent === 'weighted avg') {
      return 1;
    }
    return 0;
  });

  const width = 45;
  const align = 'center';
  const splits = ['train', 'validation', 'test'];
  const metricsColumns = (split: string): ColumnGroupType<Row> => ({
    title: split,
    children: [
      { title: 'precision', dataIndex: split, render: (v) => v?.precision || '-', width, align },
      { title: 'recall', dataIndex: split, render: (v) => v?.recall || '-', width, align },
      { title: 'f1', dataIndex: split, render: (v) => v?.f1 || '-', width, align },
      { title: 'support', dataIndex: split, render: (v) => v?.support || '-', width, align },
    ],
  });
  const scoresColumns: ColumnsType<Row> = [
    { title: '', dataIndex: 'intent', width },
    ...splits.map((split) => metricsColumns(split)),
  ];

  return (
    <Table columns={scoresColumns} dataSource={flattenedScores} rowKey={(record) => record.intent} size="small" />
  );
}
