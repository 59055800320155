import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'hooks/reduxHooks';
import React, { useMemo, useState } from 'react';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { Text, useMantineTheme } from '@mantine/core';
import { useRubrics } from 'hooks/useRubrics';
import CopyableValue from 'components/CopyableValue';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import ConversationPreview from 'components/ConversationPreview';
import { useLabelingItems } from 'hooks/useLabelingItems';
import { round } from 'lodash';
import { UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import styles from '../styles.module.scss';
import { BaseModelTable } from '..';
import ModelSummaryCell from './ModelSummaryCell';

interface ModelSummarizationQaTableProps extends BaseModelTable {}

interface ResultsRow {
  conversationId: string;
  summary: string;
  conversationPosition: number;
  rubrics: {
    [name: string]: {
      score: number;
      explanation: string;
    }
  };
  weightedScore: number;
}

export default function ModelSummarizationQaTable({
  searchTerm,
  taskId,
  modelId,
  allConceptsMap,
}: ModelSummarizationQaTableProps) {
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const customerProfile = useCustomerProfile();
  const [rubrics, loadingRubrics] = useRubrics(UserLabelingTaskType.QA_SUMMARIZATION);
  const theme = useMantineTheme();
  const taskName = `${customerProfile}/labelingTasks/${taskId}`;

  // For conversation preview
  const [showConversation, setShowConversation] = useState(false);
  const [selectedConversationPosition, setSelectedConversationPosition] = useState<number>();
  const [labelingItemData, isLoadingLabelingItemData] = useLabelingItems(taskName, selectedConversationPosition);

  const rubricsColumns: ColumnsType<ResultsRow> = useMemo(() => rubrics.map((rubric) => ({
    title: (title) => <span style={{ textTransform: 'capitalize' }}>{rubric.rubricTitle}</span>,
    width: 100,
    dataIndex: ['rubrics', rubric.name.split('/')[1]],
    key: rubric.name,
    render: (value) => (
      <>
        <Text>{value?.score}</Text>
        <Text>{value?.explanation}</Text>
      </>
    ),
    sorter: (a, b) => b.rubrics[rubric.name]?.score - a.rubrics[rubric.name]?.score,
  })), [rubrics]);

  const summaryResults: ResultsRow[] = useMemo(() => {
    const resultsMap = new Map<string, ResultsRow>();
    const rubricsKeys = (rubrics || []).map((rubric) => rubric.name.split('/')[1]).reduce((acc, key) => {
      acc[key] = {
        score: 0,
        explanation: '',
      };
      return acc;
    }, {});
    annotationBundles.forEach((bundle) => {
      const { rubricValue } = bundle.annotation.value;
      if (rubricValue.modelUri === modelId) {
        const { rubricId } = rubricValue;
        const { conversationRawData } = bundle.annotation.rawData;
        const conversationId = conversationRawData.v2ConversationId || conversationRawData.conversationId;
        let resultRow = resultsMap.get(conversationId);
        if (!resultRow) {
          resultRow = {
            conversationId,
            conversationPosition: bundle.annotation.rawData.conversationPositionNumber,
            summary: '',
            rubrics: { ...rubricsKeys },
            weightedScore: 0,
          };
          resultsMap.set(conversationId, resultRow);
        }

        resultRow.rubrics[rubricId] = {
          score: rubricValue.score,
          explanation: rubricValue.explanation,
        };
      }
    });
    const fullSummary = Array.from(resultsMap.values());
    return fullSummary;
  }, [annotationBundles, rubrics, modelId]);

  const columns: ColumnsType<ResultsRow> = [
    {
      title: 'Conversation',
      dataIndex: 'conversationId',
      key: 'conversationId',
      width: 200,
      render: (value, row) => (
        <CopyableValue copiedValue={value} displayValue={value} />
      ),
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      width: 200,
      render: (value, row) => (
        <ModelSummaryCell taskName={taskName} activeConversationIndex={row.conversationPosition}/>
      ),
    },
    ...rubricsColumns,
    {
      title: 'Weighted Score',
      width: 100,
      dataIndex: 'weightedScore',
      key: 'weightedScore',
      sorter: (a, b) => b.weightedScore - a.weightedScore,
      render: (value, row) => {
        const rubricsList = Object.values(row.rubrics);
        const scoresSum = rubricsList.reduce((acc, val) => acc + val.score, 0);
        return round(scoresSum / rubricsList?.length, 2);
      },
    },
  ];

  return (
    <div style={{
      width: '100%',
      flex: 1,
      marginTop: theme.spacing.lg,
    }}
    >
      <div style={{ display: 'flex' }}>
        <Table
          loading={{
            spinning: loadingRubrics || taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
          columns={columns}
          rowKey={(row) => row.conversationId}
          dataSource={summaryResults}
          className={styles.summaryTable}
          onRow={(record) => ({
            onClick: (e) => {
              setSelectedConversationPosition(record.conversationPosition);
              setShowConversation(true);
            },
          })}
        />
        <ConversationPreview
          messages={labelingItemData?.conversationLabelingItems[0]?.messagesAndContexts || []}
          focusedMessageId={null}
          loading={isLoadingLabelingItemData}
          onClose={() => setShowConversation(false)}
          showConversation={showConversation}
        />
      </div>
    </div>
  );
}
