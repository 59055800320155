import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { cloneDeep, debounce } from 'lodash';
import { Radio, Input, Select, RadioChangeEvent, Checkbox } from 'antd';
import { UnorderedListOutlined, ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  FetchAnnotationSummaryRequest,
  FetchAnnotationSummaryResponseConceptAnnotationSummary,
  SearchAnnotationsResponseAnnotationBundle as AnnotationBundle,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Concept, ConceptConceptType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import {
  DataSplit,
  DerivedLabelingTask,
  Message,
  SelectionMethod,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  Annotation, BinaryValueValue, CorpusMetricType, TargetType,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { searchAnnotations, upsertAnnotations } from 'store/annotation/asyncThunks';
import {
  getAnnotationRawDataKey,
  selectCurrentAnnotations,
  selectHasNextPage,
} from 'store/annotation/selectors';
import { AnnotationFilters } from 'store/annotation/state';
import { resetAnnotations, updateAnnotationFilters } from 'store/annotation/annotationSlice';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { toMessage } from 'components/LabelingMessage';
import TaskTypeTag from 'components/TaskTypeTag';
import { ApiStatus } from 'store/types';
import { selectDerivedLabelingTasks } from 'store/labelingTask/selectors';
import { selectConceptsFactory } from 'store/concept/selectors';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';
import ItemPicker from 'components/ItemPicker';
import IntentTag from 'components/MultiTags';
import { createConfirm } from 'components/ConfirmModal';
import { TableRowSelection } from 'antd/lib/table/interface';
import { selectConversationMessages, selectApiStatus as selectConversationApiStatus } from 'store/conversation/selectors';
import { UserContext } from 'context/UserContext';
import { AnnotationApi } from 'services/annotationApi';
import { getConceptTypeFromTask, getMomentAnnotationType } from 'pages/LabelingView/utils';
import { updateSyntheticLabelsModal } from 'store/app/appSlice';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/moment/moment_annotation.pb';
import { ActionAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/action/action_annotation.pb';
import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { Button, Space, Text } from '@mantine/core';
import ConversationPreview from 'components/ConversationPreview';
import * as CSV from 'csv-string';
import { downloadDataString } from 'utils/data-export';
import { PageContainer } from 'components/PageContainer';
import { DataGrid } from 'components/DataGrid';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import SyntheticLabelsModal from 'pages/SyntheticLabelsModal';
import { selectSyntheticLabelsModal } from 'store/app/selectors';
import styles from './styles.module.scss';
import BinaryLabelsReviewTable from './BinaryLabelsReviewTable';
import SummarizationLabelsReviewTable from './SummarizationLabelsReviewTable';
import { ImportLabelsButton } from './ImportLabelsButton';

export function formatLabelValue(value: BinaryValueValue): string {
  if (value === BinaryValueValue.VALUE_POSITIVE) {
    return 'Positive';
  }
  if (value === BinaryValueValue.VALUE_NEGATIVE) {
    return 'Negative';
  }
  if (value === BinaryValueValue.VALUE_SKIP) {
    return 'Skip';
  }
  if (value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
    return 'Unsure';
  }
  return value;
}

type LabelsReviewProps = {
  children?: React.ReactNode | string | number;
};

interface ModelReadyRecord {
  type: string;
  positive: number;
  negative: number;
}

export interface AnnotationBundleWithMetrics extends AnnotationBundle {
  diversityScore?: number;
}

export default function LabelsReview({ children }: LabelsReviewProps) {
  const [search] = useSearchParams();
  const { intentId } = useParams<{ intentId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const currentUser = useContext(UserContext);

  const conversationApiStatus = useSelector<ApiStatus>(selectConversationApiStatus);
  const [selectedRows, setSelectedRows] = useState<AnnotationBundle[]>([]);
  const [{ page, pageSize }, setPageInfo] = useState<{ page: number, pageSize: number }>({ page: 1, pageSize: 10 });

  // Completed, non-dual tasks.
  const tasks = useSelector<DerivedLabelingTask[]>(selectDerivedLabelingTasks)
    .filter((t) => t.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED
      && !t.labelingTask.taskData.taskDescriptor.dualTaskId
      && t.labelingTask.taskData.taskDescriptor.selectionInstruction.selectionMethod !== SelectionMethod.DUAL);
  const [conceptAnnotationSummaries, setConceptAnnotationSummaries] = useState<FetchAnnotationSummaryResponseConceptAnnotationSummary[]>();

  // Filter params.
  const [conceptType] = useUrlParam<ConceptConceptType>('concept');
  const [entityMessageDisplay, setEntityMessageDisplay] = useUrlParam<'span' | 'utterance'>('messageDisplay');
  const [dataSplit, setDataSplit] = useUrlParam<DataSplit>('split');
  const [targetType, setTargetType] = useUrlParam<TargetType>('target');
  const [taskId, setTaskId, clearTaskId] = useUrlParam<string>('taskId');
  const [label, setLabel, clearLabel] = useUrlParam<BinaryValueValue>('label');
  const [text, setText, clearText] = useUrlParam<string>('text');

  // Concept maps for intents in the summary.
  const concepts = useSelector<Concept[]>(selectConceptsFactory(conceptType || ConceptConceptType.INTENT));
  const conceptsMap = useMemo(() => new Map(concepts.filter((concept) => concept.state !== ConceptState.DEPRECATED)
    .map((concept) => {
      const conceptId = getId('concept', concept.name);
      return [conceptId, { conceptId, conceptTitle: concept.conceptTitle, concept }];
    })), [conceptType, concepts]);

  // Current selected intent.
  const intent = conceptsMap.get(intentId)?.conceptTitle || '';
  const intents = Array.from(conceptsMap.values());

  // Filter annotations by label and target type QA
  const currentAnnotations: AnnotationBundleWithMetrics[] = useSelector<AnnotationBundle[]>(selectCurrentAnnotations).filter((annotation) => {
    if (label && annotation.annotation.value.binaryValue.value !== label) {
      return false;
    }
    if (targetType === TargetType.QA) {
      return annotation.annotation.split === DataSplit.DATA_SPLIT_UNSPECIFIED;
    } else {
      return true;
    }
  }).map((annotation) => {
    const annotationMetrics = annotation.metrics.find((metric) => metric.type === CorpusMetricType.INTRA_SAMPLE_DIVERSITY);

    const diversityScore = annotationMetrics?.value ? annotationMetrics.value : undefined;

    return {
      ...annotation,
      diversityScore,
    };
  });

  // Total number needed in the confirm modal when moving annotations to model ready
  const getIntentTotalAnnotations = useCallback(async () => {
    const request: FetchAnnotationSummaryRequest = {
      parent: customerProfile,
      filter: {
        usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
        languageCode: customer.languageCode,
        splits: [DataSplit.TEST, DataSplit.TRAIN],
        conceptType: ConceptConceptType.INTENT,
        detailedTypeFilter: {
          momentAnnotationTypes: [MomentAnnotationType.INTENT],
          momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
          actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
        },
      },
    };
    const response = await AnnotationApi.fetchAnnotationSummary(request);
    setConceptAnnotationSummaries(response.conceptAnnotationSummaries);
  }, [setConceptAnnotationSummaries, customerProfile]);

  useEffect(() => {
    if (customerProfile) {
      getIntentTotalAnnotations();
    }
  }, []);

  // Filter state update. Search text change is handled separately.
  useEffect(() => {
    const momentAnnotationType = getMomentAnnotationType(getConceptTypeFromTask(conceptType));
    const isUnspecified = momentAnnotationType === MomentAnnotationType.TYPE_UNSPECIFIED;
    const annotationFilters: AnnotationFilters = {
      conceptIds: [intentId],
      conceptType,
      labels: label ? [label] : [BinaryValueValue.VALUE_POSITIVE, BinaryValueValue.VALUE_NEGATIVE, BinaryValueValue.VALUE_FLAG_FOR_REVIEW],
      taskIds: taskId ? [taskId] : [],
      dataSplit,
      textContain: text,
      detailedTypeFilter: {
        // We cannot send LABELING because we also want to include labels that were QA and promoted
        targetType: targetType === TargetType.LABELING ? undefined : TargetType.QA,
        // If type is unspecified, we send empty array
        momentAnnotationTypes: isUnspecified ? [] : [momentAnnotationType],
        momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
        actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
      },
    };

    // For summarization labels
    if (conceptType === ConceptConceptType.SUMMARIZATION) {
      annotationFilters.labels = [];
    }
    dispatch(updateAnnotationFilters(annotationFilters));
    dispatch(searchAnnotations({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
    }));
  }, [intentId, dataSplit, conceptType, taskId, targetType, label]);
  // Handle search text change with debounce.
  const debouncedSearchTextChange = useMemo(() => debounce(() => dispatch(searchAnnotations({
    parent: customerProfile,
    usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
    languageCode: customer.languageCode,
  })), 500), [customerProfile, customer?.path]);
  const onSearchTextChange = useCallback((e) => {
    const t = e.target.value;
    if (!t) {
      clearText();
    } else {
      setText(t);
    }
    const annotationFilters: AnnotationFilters = {
      textContain: t || '',
    };
    dispatch(updateAnnotationFilters(annotationFilters));
    debouncedSearchTextChange();
  }, [customerProfile, clearText, setText]);

  // Selected annotation.
  const [annotation, setAnnotation] = useState<AnnotationBundle>(null);
  const rawDataKey = getAnnotationRawDataKey(annotation?.annotation);
  const conversationId = annotation?.annotation?.rawData?.[rawDataKey]?.v2ConversationId;
  const messageId = annotation?.annotation?.rawData?.[rawDataKey]?.v2MessageId;
  const messages = useSelector<Message[]>(selectConversationMessages).map(toMessage);

  // Page info and load more annotations if on the last page.
  const total = currentAnnotations.length;
  const start = Math.min(((page - 1) * pageSize) + 1, total);
  const end = Math.min(page * pageSize, total);
  const hasNextPage = useSelector<boolean>(selectHasNextPage);
  useEffect(() => {
    if (page >= total / pageSize && hasNextPage) {
      dispatch(searchAnnotations({
        parent: customerProfile,
        usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
        languageCode: customer.languageCode,
      }));
    }
  }, [total, page, pageSize, hasNextPage]);
  const displayTotal = hasNextPage ? 'many' : `${total} total`;

  const setTask = useCallback((taskId: string | null) => {
    if (!taskId) {
      clearTaskId();
      return;
    }
    setTaskId(taskId);
    const task = tasks.find((t) => getId('labelingTask', t.labelingTask.name) === taskId);
    setDataSplit(task.labelingTask.taskData.taskDescriptor.split);
  }, [tasks, setTaskId, clearTaskId, setDataSplit]);
  const setSplitOrTarget = useCallback((splitOrTarget: DataSplit | TargetType.QA) => {
    clearTaskId();
    if (splitOrTarget === TargetType.QA) {
      setTargetType(TargetType.QA);
      setDataSplit(null);
    } else {
      setTargetType(TargetType.LABELING);
      setDataSplit(splitOrTarget);
    }
  }, [setDataSplit, clearTaskId]);

  const changeAnnotation = useCallback(async (annotation: Annotation, value: BinaryValueValue) => {
    const confirmSwap = await createConfirm<boolean>({
      title: 'Caution',
      content: (
        <p>Changing label could have downstream effects on evaluation sets.
          <br/>Before continuing, please confirm with the CD for this deployment.
        </p>),
      buttons: [
        {
          text: 'Continue',
          value: true,
        },
        {
          text: 'Cancel',
          buttonProps: {
            variant: 'subtle',
          },
          value: false,
        },
      ],
    });

    if (!confirmSwap) return;

    const updatedAnnotation = cloneDeep(annotation);
    updatedAnnotation.value.binaryValue.value = value;
    await AnnotationApi.modifyAnnotation({
      name: updatedAnnotation.name,
      newValue: updatedAnnotation.value,
      modifierUserId: currentUser?.id,
    });
    dispatch(resetAnnotations());
    dispatch(searchAnnotations({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
    }));
  }, [currentUser, customerProfile]);

  const showAddSyntheticLabelsModal = () => {
    dispatch(updateSyntheticLabelsModal({
      visible: true,
      conceptId: intentId,
      split: dataSplit,
    }));
  };

  // Export annotations as CSV.
  // Beware, this only exports annotations already loaded in the client.
  const exportCSV = () => {
    const headerRow = [
      'intent',
      'phrase',
      'true/false',
      'split',
      'intent-type',
    ];
    const valuesTextMap = new Map([
      [BinaryValueValue.VALUE_POSITIVE, 'TRUE'],
      [BinaryValueValue.VALUE_NEGATIVE, 'FALSE'],
    ]);
    const splitTextMap = new Map([
      [DataSplit.TEST, 'Test'],
      [DataSplit.TRAIN, 'Train'],
    ]);
    const intentTypes = new Map([
      [IntentIntentType.AGENT_INTENT, 'Agent'],
      [IntentIntentType.VISITOR_INTENT, 'Visitor'],
    ]);
    const concept = conceptsMap.get(intentId);
    const intentType = intentTypes?.get(concept?.concept.intent?.intentType);
    const rows = currentAnnotations.map((bundle) => ([
      intent,
      bundle.textMessage,
      valuesTextMap.get(bundle.annotation.value.binaryValue?.value) || '',
      splitTextMap.get(bundle.annotation.split),
      intentType,
    ]));
    const csvData = [headerRow, ...rows];
    const csvString = CSV.stringify(csvData);
    const dataString = `data:text/tab-separated-values;charset=utf-8,${encodeURIComponent(csvString)}`;
    downloadDataString(dataString, `annotations_${intent}_${label ? label.toLowerCase() : 'all'}.csv`);
  };

  const toggleSelectAll = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentAnnotations);
    }
  };

  // Replace select all checkbox with custom element
  // and behavior that select all items from all pages
  const headerCheckbox = (
    <Checkbox
      checked={selectedRows.length > 0}
      indeterminate={
        selectedRows.length > 0 && selectedRows.length < currentAnnotations.length
      }
      onChange={toggleSelectAll}
    />
  );

  // Table row selection object
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rowSelection: TableRowSelection<AnnotationBundle> = {
    selectedRowKeys: selectedRows.map((row) => row.annotation.name),
    onChange: (selectedRowKeys: React.Key[], selectedRows: AnnotationBundle[]) => {
      setSelectedRows(selectedRows);
    },
    columnTitle: headerCheckbox,
  };

  // Promote QA annotations
  const moveToModelReady = async () => {
    let modelReadySplit = DataSplit.TRAIN;
    const setModelReadySplit = (e: RadioChangeEvent) => {
      modelReadySplit = e.target.value;
    };

    // Show when numbers are not accurate by adding plus to it (e.g. 500+)
    const formatCellNumberValue = (info: CellContext<ModelReadyRecord, unknown>) =>
      (hasNextPage && info.row.original.type !== 'Selected' ? `${info.getValue()}+` : info.getValue());

    // Table columns
    const modelReadyColumns: ColumnDef<ModelReadyRecord>[] = [
      {
        header: ' ',
        cell: (info) => info.getValue(),
        enableSorting: false,
        accessorFn: (row) => row.type,
      },
      {
        header: 'Positive',
        cell: formatCellNumberValue,
        enableSorting: false,
        accessorFn: (row) => row.positive,
      },
      {
        header: 'Negative',
        cell: formatCellNumberValue,
        enableSorting: false,
        accessorFn: (row) => row.negative,
      },
    ];

    const conceptSummary = conceptAnnotationSummaries.find((data) => data.conceptId === intentId);
    const totalPositive = conceptSummary.modelReadyPositiveAnnotationCount;
    const totalNegative = conceptSummary.modelReadyNegativeAnnotationCount;

    const selected: ModelReadyRecord = {
      type: 'Selected',
      positive: 0,
      negative: 0,
    };
    const total: ModelReadyRecord = {
      type: 'Exists',
      positive: totalPositive,
      negative: totalNegative,
    };

    selectedRows.forEach((row) => {
      const { value } = row.annotation.value.binaryValue;
      if (value === BinaryValueValue.VALUE_POSITIVE) {
        selected.positive += 1;
      } else {
        selected.negative += 1;
      }
    });

    const after: ModelReadyRecord = {
      type: 'Total after move',
      positive: (totalPositive || 0) + selected.positive,
      negative: (totalNegative || 0) + selected.negative,
    };

    const modelReadyData = [selected, total, after];

    const value = await createConfirm<boolean>({
      title: 'Confirm Move',
      width: 400,
      buttons: [
        {
          text: 'Yes, continue',
          value: true,
        },
        {
          text: 'No, cancel',
          value: false,
          buttonProps: { variant: 'subtle' },
        },
      ],
      content: (
        <div>
          <p>
            Data set
          </p>
          <Radio.Group defaultValue={modelReadySplit} className={styles.confirmSplitRadios} onChange={setModelReadySplit}>
            <Radio
              value={DataSplit.TRAIN}
            >Train
            </Radio>
            <Radio
              value={DataSplit.TEST}
            >Test
            </Radio>
          </Radio.Group>
          <DataGrid columns={modelReadyColumns} dataSource={modelReadyData} style={{ width: '100%' }}/>
          <Space h="md"/>
          <Text size="sm">This action is not reversible. Are you sure?</Text>
        </div>
      ),
    });
    if (value) {
      const updatedAnnotations: Annotation[] = selectedRows.map((row) => ({
        ...row.annotation,
        split: modelReadySplit,
      }));
      dispatch(upsertAnnotations({
        parent: customerProfile,
        annotations: updatedAnnotations,
      }));
      setSelectedRows([]);
    }
  };

  const renderTable = () => {
    switch (conceptType) {
      case ConceptConceptType.SUMMARIZATION:
        return (
          <SummarizationLabelsReviewTable
            conversationId={conversationId}
            setAnnotation={setAnnotation}
            currentAnnotations={currentAnnotations}
            setPageInfo={setPageInfo}
            rowSelection={rowSelection}
          />
        );
      default:
        return (
          <BinaryLabelsReviewTable
            changeAnnotation={changeAnnotation}
            messageId={messageId}
            setAnnotation={setAnnotation}
            currentAnnotations={currentAnnotations}
            conceptType={conceptType}
            setPageInfo={setPageInfo}
            rowSelection={rowSelection}
          />
        );
    }
  };
  const syntheticLabelsModal = useSelector(selectSyntheticLabelsModal);

  return (
    <PageContainer>
      <SyntheticLabelsModal
        opened={syntheticLabelsModal.visible}
        onClose={() => {
          dispatch(updateSyntheticLabelsModal({
            ...syntheticLabelsModal,
            visible: false,
          }));
        }}
      />
      <Link to="../annotations">
        <ArrowLeftOutlined /> Back to Labels
      </Link>
      <div className={classNames([styles.filters, styles.header])}>
        <ItemPicker
          items={intents}
          idKeyPath="intentId"
          value=""
          renderItem={(item) => <IntentTag tags={[item?.conceptTitle]} />}
          filterKey={(item) => item.conceptTitle}
          placeholder="Select"
          onChange={(item) => navigate({ pathname: item.conceptId.toString(), search: search.toString() })}
        >
          <Button variant="white" compact size="lg"><UnorderedListOutlined /></Button>
        </ItemPicker>

        <span className={styles.title}>{intent}</span>
        <span className={styles.spacer} />
        <Select
          className={styles.select}
          showArrow
          size="large"
          value={taskId == null ? 'From all labeling tasks' : `${taskId} `}
          onChange={(id) => setTask(id)}
        >
          <Select.Option key="" value={null}>
            From all labeling tasks
          </Select.Option>
          {
            tasks.map((task) => {
              const id = getId('labelingTask', task.labelingTask.name);
              return (
                <Select.Option key={id} value={id}>
                  <div className={styles.selectOption}>
                    <span>{id.substring(0, 13)}...</span>
                    <span className={styles.spacer} />
                    <TaskTypeTag taskType={task.userLabelingTaskType} />
                  </div>
                </Select.Option>
              );
            })
          }
        </Select>
      </div>
      <div className={classNames([styles.filters, styles.quickFilters])}>
        {
          selectedRows.length > 0 ? (
            <>
              <span>{`${selectedRows.length} selected`}</span>
              <span className={styles.modelReadyLink} onClick={moveToModelReady}>Move to Model Ready</span>
            </>
          ) : (
            <Text size="md">{`${start}-${end} of ${displayTotal}`}</Text>
          )
        }
        <Button
          className={styles.filter}
          color="green"
          size="xs"
          compact
          variant="outline"
          onClick={exportCSV}
        >Export CSV
        </Button>
        <span className={styles.spacer} />
        <Radio.Group value={targetType === TargetType.QA ? TargetType.QA : dataSplit} onChange={(e) => setSplitOrTarget(e.target.value)}>
          {
            conceptType === ConceptConceptType.INTENT && (
              <Radio.Button value={TargetType.QA}>QA</Radio.Button>
            )
          }
          {
            [ConceptConceptType.INTENT, ConceptConceptType.SUMMARIZATION].includes(conceptType) && (
              <>
                <Radio.Button value={DataSplit.TRAIN}>Train</Radio.Button>
                <Radio.Button value={DataSplit.TEST}>Test</Radio.Button>
              </>
            )
          }
        </Radio.Group>
        { conceptType === ConceptConceptType.INTENT && (
          <Radio.Group
            className={styles.filter}
            value={label}
            onChange={(e) => {
              if (!e.target.value) {
                clearLabel();
                return;
              }
              setLabel(e.target.value);
            }}
          >
            <Radio.Button value={undefined}>All</Radio.Button>
            <Radio.Button value={BinaryValueValue.VALUE_POSITIVE}>Positive</Radio.Button>
            <Radio.Button value={BinaryValueValue.VALUE_NEGATIVE}>Negative</Radio.Button>
            <Radio.Button value={BinaryValueValue.VALUE_FLAG_FOR_REVIEW}>Unsure</Radio.Button>
          </Radio.Group>
        ) }
        { conceptType === ConceptConceptType.ENTITY && (
          <Radio.Group
            className={styles.filter}
            value={entityMessageDisplay}
            onChange={(e) => setEntityMessageDisplay(e.target.value)}
          >
            <Radio.Button value="span">Span</Radio.Button>
            <Radio.Button value="utterance">Utterance</Radio.Button>
          </Radio.Group>
        ) }
        <Input
          className={classNames([styles.input, styles.filter])}
          addonAfter={<SearchOutlined />}
          placeholder="input search text"
          value={text}
          onChange={(e) => onSearchTextChange(e)}
        />
        {
          targetType === TargetType.LABELING && conceptType === ConceptConceptType.INTENT && (
            <ImportLabelsButton>Import</ImportLabelsButton>
          )
        }
        {
          targetType === TargetType.LABELING && conceptType === ConceptConceptType.INTENT && (
            <Button className={styles.filter} onClick={() => showAddSyntheticLabelsModal()}>Add more</Button>
          )
        }
      </div>
      <div className={styles.content}>
        {renderTable()}
        <ConversationPreview
          messages={annotation ? messages : []}
          focusedMessageId={messageId}
          onClose={() => setAnnotation(null)}
          showConversation
          loading={conversationApiStatus === 'loading'}
        />
      </div>
    </PageContainer>
  );
}
