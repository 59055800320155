import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { State } from './state';

/** General reducer for starting a conversation API. */
export function conversationApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
    conversationMessages: [],
  };
}

/** General reducer for a failed concept API. */
export function conversationApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** Reducer for listing conversations successfully. */
export function listConversationsSuccess(state: State, action: PayloadAction<Conversation[]>): State {
  return {
    ...state,
    status: 'succeeded',
    conversations: action.payload,
  };
}

/** Reducer for listing conversation messages successfully. */
export function listConversationMessagesSuccess(state: State, action: PayloadAction<Message[]>): State {
  return {
    ...state,
    status: 'succeeded',
    conversationMessages: action.payload.sort((a, b) => a.sequencePosition - b.sequencePosition),
  };
}
