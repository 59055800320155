// eslint-disable no-unused-vars
import React, { ReactElement, ReactNode, useContext, useState, useEffect, useMemo } from 'react';
import { Alert, DatePicker, Input, Form, Radio, Select, FormInstance, Checkbox } from 'antd';
import SelectWithCreateNew, { SimplifiedSelectWithCreateNew } from 'components/SelectWithCreateNew';
import moment from 'moment';
import { ModelProject } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import {
  ModelArtifactArtifactType,
  ModelArtifactStatus,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { useSelector } from 'hooks/reduxHooks';
import { Concept, ConceptConceptType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectApiStatus, selectConceptsFactory } from 'store/concept/selectors';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { ProdIntent } from 'store/modelBuilder/state';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { selectLoadProdTaxonomiesStatus } from 'store/modelBuilder/selectors';
import { Button } from '@mantine/core';
import { getId } from 'common/resourceName';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { useModelArtifacts, ResourceCreationArgs } from './hooks';
import { TaskType } from '../../constants/intents';
import { OktaAuthContext } from '../../context/OktaAuthContext';
import { IntentsDiff } from './IntentsDiff';

interface Props {
  form: FormInstance<any>
  resourceRoute: string
  formBodyArgs: any
  bodyArgs: any
  currentProject: ModelProject,
  createResource: (args: ResourceCreationArgs) => Promise<void>,
  onValuesChangeCallback?: (values: any) => void,
  prodIntents: ProdIntent[];
  warning?: ReactNode,
}
export function ResourceCreationForm(props: Props) {
  const {
    form,
    resourceRoute,
    formBodyArgs,
    bodyArgs,
    currentProject,
    createResource,
    onValuesChangeCallback,
    prodIntents,
    warning,
  } = props;
  const { data: { email } } = useContext(OktaAuthContext);
  const customer = useCustomerParams();
  const intents = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT)).filter((concept) => concept.state !== ConceptState.DEPRECATED);
  const conceptApiStatus = useSelector(selectApiStatus);
  const prodIntentsStatus = useSelector(selectLoadProdTaxonomiesStatus);
  const allAgentIntents = intents
    .filter((concept) => concept.intent.intentType === IntentIntentType.AGENT_INTENT)
    .map((c) => c.conceptTitle)
    .sort((a, b) => a.localeCompare(b));
  const allVisitorIntents = intents
    .filter((concept) => concept.intent.intentType === IntentIntentType.VISITOR_INTENT)
    .map((c) => c.conceptTitle)
    .sort((a, b) => a.localeCompare(b));
  const allChatDrivers = intents
    .filter((concept) => concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER)
    .map((c) => c.conceptTitle)
    .sort((a, b) => a.localeCompare(b));

  const [taskType, setTaskType] = useState('');
  const projectIntents = useMemo(() => {
    let taskTypeIntents = [];
    switch (taskType) {
      case TaskType.AGENT_INTENT:
        taskTypeIntents = allAgentIntents;
        break;
      case TaskType.VISITOR_INTENT:
        taskTypeIntents = allVisitorIntents;
        break;
      case TaskType.CHAT_DRIVER:
        taskTypeIntents = allChatDrivers;
        break;
      default:
    }
    return currentProject?.metadata?.intents?.filter((intent) => taskTypeIntents.includes(intent));
  }, [taskType, currentProject, allAgentIntents, allVisitorIntents, allChatDrivers]);

  const projectId = useMemo(() => Number(getId('modelProject', currentProject?.name)), [currentProject]);
  const [submitting, setSubmitting] = useState(false);
  let index = 0;
  const [chatids, setChatids] = useState([]);
  const [name, setName] = useState('');
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addChatid = (e) => {
    e.preventDefault();
    setChatids([...chatids, name || `New item ${index++}`]);
    setName('');
  };
  const fieldNames = (formBodyArgs as any).map((arg) => arg.name);
  fieldNames.push('created_by');
  const supportedFormItemTypes = [
    'input', 'date', 'radio', 'checkbox', 'selectTask',
    'selectIntents', 'selectAgentIntents', 'selectVisitorIntents',
    'selectChatDrivers', 'selectNeuralClassifier', 'SelectChatIds',
    'SelectRuleActionClassifier', 'SelectTrainingDatasetUrl',
    'SelectTestDatasetUrl', 'selectProjectIntents',
    'selectSuggestionsDataset', 'selectSuggestionsGPTModel',
    'selectSuggestionsT5Model',
  ];
  const formItems = [];
  const [neuralArtifacts] = useModelArtifacts(projectId, ModelArtifactArtifactType.NEURAL_CLASSIFIER);
  const [regexArtifacts] = useModelArtifacts(projectId, ModelArtifactArtifactType.REGEX_CLASSIFIER);
  const [datasetArtifacts] = useModelArtifacts(projectId, ModelArtifactArtifactType.DATASET);
  const [suggestionsDatasetArtifacts] = useModelArtifacts(projectId, ModelArtifactArtifactType.SUGGESTIONS_DATASET);
  const [suggestionsModelArtifacts] = useModelArtifacts(projectId, ModelArtifactArtifactType.SUGGESTIONS_MODEL);
  for (const arg of formBodyArgs) {
    if (!supportedFormItemTypes.includes(arg.type)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    let formItem;
    // By default normalize with identity.
    let normalize = (e: any) => e;
    switch (arg.type) {
      case 'input':
        formItem = <Input placeholder={arg.placeholder || ''} />;
        // Strip all whitespace at the front and the end of the input and map falsy strings to
        // undefined to avoid backend problems from copy-paste. See
        // https://crestalabs.slack.com/archives/C02TC1YJ9DG/p1652980939917949?thread_ts=1652829123.865029&cid=C02TC1YJ9DG.
        //
        // Note: This means you also can't enter spaces at the front or the end of the input field.
        // But we should not need that.
        normalize = (e: string | undefined) => (e ? e.trim() : undefined);
        break;
      case 'date': {
        // Note: Format needs to a function and not "YYYY-MM-DD". Otherwise we run into a bug
        // mentioned in https://github.com/cresta/studio/pull/623.
        const format = (value) => moment(value).format('YYYY-MM-DD');
        formItem = <DatePicker placeholder="YYYY-MM-DD" format={format} />;
        break;
      }
      case 'checkbox':
        formItem = (
          <Checkbox />
        );
        break;
      case 'radio':
        formItem = (
          <Radio.Group defaultValue={arg.default}>
            {arg.options.map((option) => (<Radio key={option} value={option}>{option}</Radio>))}
          </Radio.Group>
        );
        break;
      case 'selectTask':
        formItem = (
          <Select onChange={(value) => setTaskType(value as string)} allowClear>
            <Select.Option
              value={TaskType.AGENT_INTENT}
              key={TaskType.AGENT_INTENT}
            >
              {TaskType.AGENT_INTENT}
            </Select.Option>
            <Select.Option
              value={TaskType.VISITOR_INTENT}
              key={TaskType.VISITOR_INTENT}
            >
              {TaskType.VISITOR_INTENT}
            </Select.Option>
            <Select.Option
              value={TaskType.CHAT_DRIVER}
              key={TaskType.CHAT_DRIVER}
            >
              {TaskType.CHAT_DRIVER}
            </Select.Option>
            <Select.Option
              value={TaskType.CURRENT_INTENT}
              key={TaskType.CURRENT_INTENT}
            >
              {TaskType.CURRENT_INTENT}
            </Select.Option>
            <Select.Option
              value={TaskType.NEXT_INTENT}
              key={TaskType.NEXT_INTENT}
            >
              {TaskType.NEXT_INTENT}
            </Select.Option>
          </Select>
        );
        break;
      case 'selectProjectIntents':
        formItem = (
          <Select mode="multiple" showArrow allowClear disabled={!taskType}>
            {projectIntents?.map((intent) => (
              <Select.Option value={intent} key={intent}>
                {intent}
              </Select.Option>
            ))}
          </Select>
        );
        break;
      case 'SelectChatIds':
        formItem = (
          <SelectWithCreateNew
            newValue={name}
            onChangeNewValue={onNameChange}
            onAdd={addChatid}
            addPlaceholder="Please enter a chat id"
            options={chatids.map((option) => ({
              label: option,
              value: option,
            }))}
          />
        );
        break;
      case 'selectIntents':
        formItem = (
          <Select mode="multiple" showArrow disabled={!taskType}>
            {intents
              .filter((concept) => {
                if (taskType === TaskType.AGENT_INTENT) {
                  return concept.intent.intentType === IntentIntentType.AGENT_INTENT;
                } else if (taskType === TaskType.VISITOR_INTENT) {
                  return (
                    concept.intent.intentType === IntentIntentType.VISITOR_INTENT
                  );
                } else {
                  return (
                    concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER
                  );
                }
              })
              .sort((a, b) => a.conceptTitle.localeCompare(b.conceptTitle))
              .map(({ conceptTitle, name }) => (
                <Select.Option value={conceptTitle} key={name}>
                  {conceptTitle}
                </Select.Option>
              ))}
          </Select>
        );
        break;
      case 'selectAgentIntents':
        arg.initialValue = allAgentIntents;
        formItem = (
          <SelectWithAddAll
            intents={allAgentIntents}
            status={prodIntentsStatus}
            prodIntents={prodIntents.filter((i) => i.type === IntentIntentType.AGENT_INTENT).map((i) => i.intent)}
          />
        );
        break;
      case 'selectVisitorIntents':
        arg.initialValue = allVisitorIntents;
        formItem = (
          <SelectWithAddAll
            intents={allVisitorIntents}
            status={prodIntentsStatus}
            prodIntents={prodIntents.filter((i) => i.type === IntentIntentType.VISITOR_INTENT).map((i) => i.intent)}
          />
        );
        break;
      case 'selectChatDrivers':
        arg.initialValue = allChatDrivers;
        formItem = (
          <SelectWithAddAll
            intents={allChatDrivers}
            status={prodIntentsStatus}
            prodIntents={prodIntents.filter((i) => i.type === IntentIntentType.CONVERSATION_DRIVER).map((i) => i.intent)}
          />
        );
        break;
      case 'selectNeuralClassifier':
        formItem = (
          <SimplifiedSelectWithCreateNew
            initialOptions={neuralArtifacts
              .filter(({ status }) => status === ModelArtifactStatus.ACTIVE)
              .map(({ key, neuralClassifierArtifact }) => {
                const modelUri = neuralClassifierArtifact?.outputs?.modelUri || '';
                const outputAsString = `${key}.${modelUri}`;
                return {
                  key,
                  label: outputAsString,
                  value: modelUri,
                };
              })}
          />
        );
        break;
      case 'SelectRuleActionClassifier':
        formItem = (
          <SimplifiedSelectWithCreateNew initialOptions={regexArtifacts
            .filter(({ status }) => status === ModelArtifactStatus.ACTIVE)
            .map(({ key, regexClassifierArtifact }) => {
              const modelUri = regexClassifierArtifact?.outputs?.modelUri || '';
              const outputAsString = `${key}.${modelUri}`;
              return {
                key,
                label: outputAsString,
                value: modelUri,
              };
            })}
          />
        );
        break;
      case 'SelectTrainingDatasetUrl':
        formItem = (
          <SimplifiedSelectWithCreateNew initialOptions={datasetArtifacts
            .filter(({ status, datasetArtifact }) => status === ModelArtifactStatus.ACTIVE && datasetArtifact?.outputs?.trainDataset?.uri)
            .map(({ key, datasetArtifact }) => {
              const uri = datasetArtifact?.outputs?.trainDataset?.uri;
              const outputAsString = `${key}.${uri}`;
              return {
                key,
                label: outputAsString,
                value: uri,
              };
            })}
          />
        );
        break;
      case 'SelectTestDatasetUrl':
        formItem = (
          <SimplifiedSelectWithCreateNew initialOptions={datasetArtifacts
            .filter(({ status, datasetArtifact }) => status === ModelArtifactStatus.ACTIVE && datasetArtifact?.outputs?.testDataset?.uri)
            .map(({ key, datasetArtifact }) => {
              const uri = datasetArtifact?.outputs?.testDataset?.uri;
              const outputAsString = `${key}.${uri}`;
              return {
                key,
                label: outputAsString,
                value: uri,
              };
            })}
          />
        );
        break;
      case 'selectSuggestionsDataset':
        formItem = (
          <SimplifiedSelectWithCreateNew initialOptions={suggestionsDatasetArtifacts
            .filter(({ status }) => status === ModelArtifactStatus.ACTIVE)
            .map(({ key, suggestionsDatasetArtifact }) => {
              const uri = suggestionsDatasetArtifact?.outputs?.datasetPath;
              const outputAsString = `${key}.${uri}`;
              return {
                key,
                label: outputAsString,
                value: uri,
              };
            })}
          />
        );
        break;
      case 'selectSuggestionsGPTModel':
        formItem = (
          <SimplifiedSelectWithCreateNew
            initialOptions={
              suggestionsModelArtifacts
                .filter(({ status }) => status === ModelArtifactStatus.ACTIVE)
                .map(({ key, suggestionsModelArtifact }) => [key, suggestionsModelArtifact?.outputs?.modelUri])
                .filter(([, p]) => !!p && p.includes('gpt'))
                .map(([key, uri]) => ({
                  key,
                  label: `${key}.${uri}`,
                  value: uri,
                }))
            }
          />
        );
        break;
      case 'selectSuggestionsT5Model':
        formItem = (
          <SimplifiedSelectWithCreateNew
            initialOptions={
              suggestionsModelArtifacts
                .filter(({ status }) => status === ModelArtifactStatus.ACTIVE)
                .map(({ key, suggestionsModelArtifact }) => [key, suggestionsModelArtifact?.outputs?.modelUri])
                .filter(([, p]) => !!p && p.includes('t5'))
                .map(([key, uri]) => ({
                  key,
                  label: `${key}.${uri}`,
                  value: uri,
                }))
            }
          />
        );
        break;
      default:
        console.warn(`ResourceCreationForm does not support input type ${arg.type}.`);
    }
    if (!arg?.hidden) {
      formItems.push(
        <Form.Item
          key={arg.label}
          label={arg.label}
          name={arg.name}
          initialValue={arg?.initialValue}
          help={arg?.help}
          hidden={arg?.hidden}
          rules={[{ required: arg?.required, message: '' }]}
          valuePropName={arg.type === 'checkbox' ? 'checked' : 'value'}
          normalize={normalize}
        >
          {formItem}
        </Form.Item>,
      );
    }
  }
  formItems.push(
    <Form.Item
      key="Created by"
      label="Created by"
      name="created_by"
      initialValue={email}
    >
      <Input />
    </Form.Item>,
  );
  const handleSubmit = async () => {
    setSubmitting(true);
    const formVals = form.getFieldsValue(fieldNames);
    const body = { ...formVals, ...bodyArgs };
    if (!body.agent_intents) {
      body.agent_intents = [];
    }
    if (!body.visitor_intents) {
      body.visitor_intents = [];
    }
    if (!body.chat_drivers) {
      body.chat_drivers = [];
    }
    if (resourceRoute.includes('deployment')) {
      body.customer_id = customer?.customerId;
      body.profile_id = customer?.profileId;
      body.usecase_id = customer?.usecaseId;
      body.language_code = customer?.languageCode;
    }
    createResource({
      resourceRoute,
      createResourceBodyArgs: body,
      acceptedCallback: (resourceId: number) => {
        alert(`Task ${resourceId} created! It may take a bit to finish the job.`);
      },
      completedCallback: () => setSubmitting(false),
    });
  };
  if (conceptApiStatus === 'loading' || conceptApiStatus === 'failed') return <div>Error loading intents!</div>;
  return (
    <div style={{ width: '50%' }}>
      {
        warning === undefined ? <></> : <Alert message={warning} type="warning" style={{ marginBottom: '20px' }} />
      }
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={(e) => console.error('Something went wrong with submission: ', e)}
        onValuesChange={onValuesChangeCallback}
      >
        {formItems}
      </Form>
      <Button loading={submitting} onClick={form.submit}>
        Submit
      </Button>
    </div>
  );
}

interface SelectWithAddAllProps {
  intents: string[];
  prodIntents: string[];
  status: ApiStatus;
  value?: string[];
  onChange?: (value: string[]) => void;
}

function SelectWithAddAll({
  intents,
  prodIntents,
  status,
  value,
  onChange,
}: SelectWithAddAllProps): ReactElement {
  const [internalValue, setInternalValue] = useState<string[]>(intents);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  return (
    <>
      <Select
        mode="multiple"
        showArrow
        allowClear
        value={internalValue}
        onChange={(value) => {
          if (value.includes('all')) {
            onChange(intents);
          } else {
            onChange(value);
          }
        }}
      >
        <Select.Option value="all" key="all">
          Add All
        </Select.Option>
        {intents.map((intent) => (
          <Select.Option value={intent} key={intent}>
            {intent}
          </Select.Option>
        ))}
      </Select>
      {status === 'succeeded' && (
        <IntentsDiff
          studioIntents={internalValue}
          prodIntents={prodIntents}
        />
      )}
      {status === 'loading' && <Loading />}
      {status === 'failed' && (
        <span style={{ color: '#ff2323' }}>
          Failed to get intents in prod!
        </span>
      )}
    </>
  );
}
