import { useEffect, useState, useCallback } from 'react';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { SimulationTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { openNotification } from 'components/Notification';
import { SimulationTaskApi } from 'services/simulationTaskApi';
import { MomentAnnotationAdherenceType, MomentAnnotationDetailedType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { getId } from 'common/resourceName';
import dayjs from 'dayjs';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import { mapToStudioMessage, ProdConversationApi } from 'services/prodConversationApi';
import { SimulatorTableRow, Prediction as RowPrediction } from './rowTypes';
import { detailedTypeRoleMap, getMessagePredictionMap } from './utils';

export const usePredictions = (conversationName: string, simulationTask: SimulationTask): [SimulatorTableRow[], boolean] => {
  const [data, setData] = useState([]);
  const [isLoading, toggleLoading] = useState(false);

  const getPredictions = useCallback(async () => {
    if (!simulationTask || simulationTask?.abstractTask?.status !== TaskStatus.TASK_COMPLETED) {
      return;
    }
    setData([]);
    toggleLoading(true);
    let messages: Message[];
    let preds: Prediction[];
    try {
      messages = (await ProdConversationApi.listAllMessages(conversationName)).map(mapToStudioMessage);
      const predictionsResponse = await SimulationTaskApi.fetchAllConversationPredictions({
        simulationTask: simulationTask?.name,
        conversation: conversationName,
      });
      const { predictions } = predictionsResponse;
      preds = predictions;
    } catch (err) {
      openNotification('error', 'Failed to Get Result', undefined, err);
      return;
    } finally {
      toggleLoading(false);
    }
    const messagePredictionMap = getMessagePredictionMap(preds);
    setData(messages
      .sort((a, b) => a.publishTime.localeCompare(b.publishTime))
      .map((message) => convertToTableRow(message, messagePredictionMap.get(message.name) || [])));
  }, [conversationName, simulationTask]);

  useEffect(() => {
    if (conversationName && simulationTask?.name) {
      getPredictions();
    }
  }, [conversationName, simulationTask]);

  return [data, isLoading];
};

const detectionDetailedTypes = new Set<MomentAnnotationDetailedType>([
  MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT,
  MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER,
  MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT,
]);

function convertToTableRow(message: Message, predictions: Prediction[]): SimulatorTableRow {
  const messageId = getId('conversationMessage', message.name);
  const pubDate = new Date(dayjs(message.publishTime).format(ACCEPTED_DATE_FORMAT));
  const cvt = (pred: Prediction): RowPrediction => ({
    role: detailedTypeRoleMap.get(pred.moment.detailedType) || null,
    intent: pred.output,
    confidence: pred.metadata?.confidence,
  });
  const detections: RowPrediction[] = predictions
    .filter((pred) => detectionDetailedTypes.has(pred.moment.detailedType))
    .map(cvt);
  const coaching: RowPrediction[] = predictions.filter((pred) => pred.moment.adherenceType === MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED
    && pred.moment.detailedType === MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT).map(cvt);
  return {
    utterance: {
      id: messageId,
      text: message.content,
      pubDate,
      msgId: messageId,
      speakerId: message.actorId,
      speakerRole: message.actorType.toLowerCase(),
      chat_name: null,
      suggestions: null,
      hints: null,
    },
    detections,
    coaching,
    policy: predictions.map((pred) => pred.moment.intentPayload?.dialoguePolicyDebugOutput as object).filter((v) => !!v),
  };
}
