import React from 'react';
import { Skeleton } from '@mantine/core';

interface MultilineSeletonProps {
    rows?: number;
    lastRowWidth?: string;
}

export const MultilineSeleton = (props: MultilineSeletonProps) => {
  const { rows = 1, lastRowWidth = '80%' } = props;
  const getFullWidthRows = (rows: number) => {
    const rowsArray = [];
    for (let i = 0; i < rows; i++) {
      rowsArray.push(<Skeleton key={i} width="100%" height="10px" />);
    }
    return rowsArray;
  };
  return (
    <>
      {getFullWidthRows(rows)}
      <Skeleton key={rows + 1} width={lastRowWidth} height="10px" />
    </>
  );
};
