import { useEffect, useState } from 'react';
import {
  AuthProtoRole,
  ListUsersRequest,
  ListUsersRequestListUsersFilter,
  ListUsersResponse,
} from '@cresta/web-client';
import { ProdUsersApi } from 'services/prodUsersApi';
import { getId } from 'common/resourceName';
import { useCustomerParams } from 'hooks/useCustomerParams';

export const useProdAgents = () => {
  const { customerId } = useCustomerParams();
  const [agents, setAgents] = useState<{
    value: string,
    label: string
  }[]>([]);
  // Populate Agent dropdown from Actor Service
  useEffect(() => {
    const filter: ListUsersRequestListUsersFilter = {
      roles: [AuthProtoRole.AGENT],
    };
    const params: ListUsersRequest = {
      parent: `customers/${customerId}`,
      pageSize: 1000,
      filter,
    };

    function alphabeticalByLabel(a: { label: string }, b: { label: string }) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }

    ProdUsersApi.listUsers(params)
      .then((response: ListUsersResponse) => {
        const agentNames = response.users
          .map((agent) => ({
            value: getId('prodUser', agent.name),
            label: agent.displayName || 'Error retrieving name',
          }))
          .sort(alphabeticalByLabel);
        setAgents(agentNames);
      })
      .catch((err) => console.error(err));
  }, []);
  return agents;
};
