import { User } from '@cresta/web-client';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { State } from './state';

/** General reducer for starting a actor API. */
export function setApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed actor API. */
export function setApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** Reducer for listing agents successfully. */
export function listAgentsSuccess(state: State, action: PayloadAction<User[]>): State {
  return {
    ...state,
    status: 'succeeded',
    agents: action.payload,
  };
}
