import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Annotation, AnnotationValueType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { ConceptConceptType, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { showNotification } from '@mantine/notifications';
import { getId } from 'common/resourceName';
import { useSelector } from 'hooks/reduxHooks';
import { selectConceptsFactory } from 'store/concept/selectors';

export interface Stage {
    annotationId: string;
    stageId: string;
    stageName: string;
    conversationIndexStart: number;
    conversationIndexEnd: number;
}

interface UITagType {
    value: string;
    label: string;
    tagType: TagTagType;
    endStageId?: string;
}

interface TaggingPageProviderValues {
    stages: Stage[]
    stageIdToBeAdded: string
    handleStageBeginSelection: (stageId: string, messageId: string) => void
    handleStageEndSelection: (index: number) => void
    resetStageTagging: () => void
    onRemoveStageEnd: (stage: Stage) => void;
    selectedStageAnnotationId: string
    onStageSelection: (annotationId: string) => void
}

export const StagesContext = createContext<TaggingPageProviderValues>(null);

interface StagesProviderProps {
  messages: Message[]
  annotations: Annotation[]
  handleStageAnnotationCreation?: any;
  removeAnnotationIds?: (annotationIdsToRemove: string[]) => Promise<void>
  children: React.ReactNode
  tempStage?: Stage | null
  setTempStage?: (stage: Stage | null) => void
}

export const StagesProvider = (props: StagesProviderProps) => {
  const {
    messages,
    annotations,
    handleStageAnnotationCreation = () => {},
    removeAnnotationIds = (vals) => Promise.resolve(vals),
    children,
    tempStage,
    setTempStage,
  } = props;
  const areEditsDisabled = !handleStageAnnotationCreation || !removeAnnotationIds;
  const tagConcepts = useSelector(selectConceptsFactory(ConceptConceptType.TAG));
  const [stageIdToBeAdded, setStageIdToBeAdded] = useState(null);
  const [selectedStageBeginMessageId, setSelectedStageBeginMessageId] = useState(null);
  // stage currently selected
  const [selectedStageAnnotationId, setSelectedAnnotationStageId] = useState(null);
  const resetStageTagging = () => {
    if (setTempStage) {
      setTempStage(null);
    }
    setStageIdToBeAdded(null);
    setSelectedStageBeginMessageId(null);
  };

  // resets stage Tagging when activeConversationIndex changes
  useEffect(() => {
    resetStageTagging();
  }, [messages]);

  // sets all tags in profile to be selected
  const tagsInProfile = useMemo(() => {
    if (tagConcepts.length === 0) return [];
    const mappedTags: UITagType[] = tagConcepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      return {
        value: conceptId,
        label: concept.conceptTitle,
        tagType: concept.tag.tagType,
      };
    });
    return mappedTags;
  }, [tagConcepts]);

  const tagsMap = new Map<string, any>(tagsInProfile.map((tag) => [tag.value, tag]));

  const onStageSelection = (stageAnnotationId: string) => {
    if (stageAnnotationId === selectedStageAnnotationId) {
      setSelectedAnnotationStageId(null);
    } else {
      setSelectedAnnotationStageId(stageAnnotationId);
    }
  };
  const addTempStage = (stageId: string, messageId: string) => {
    const beginMessageIndex = messages.findIndex((message) => message.v2MessageId === messageId);

    if (setTempStage) {
      setTempStage({
        annotationId: 'temp',
        stageId,
        stageName: tagsMap.get(stageId)?.label,
        conversationIndexStart: beginMessageIndex,
        conversationIndexEnd: beginMessageIndex,
      });
    }
    setSelectedAnnotationStageId('temp');
    setStageIdToBeAdded(stageId);
    setSelectedStageBeginMessageId(messageId);
  };

  const onRemoveStageEnd = async (stage: Stage) => {
    if (areEditsDisabled) return;
    const stageIdToUpdate = stage.stageId;
    const beginningMessageId = messages[stage.conversationIndexStart].v2MessageId;

    await removeAnnotationIds([stage.annotationId]);
    addTempStage(stageIdToUpdate, beginningMessageId);
  };

  const handleStageBeginSelection = (stageId: string, messageId: string) => {
    if (areEditsDisabled) return;
    // chek if index is within the range of any other stage
    const beginMessageIndex = messages.findIndex((message) => message.v2MessageId === messageId);
    const isWithinPreviousStage = stages.some((stage) => beginMessageIndex >= stage.conversationIndexStart && beginMessageIndex <= stage.conversationIndexEnd);

    if (isWithinPreviousStage) {
      showNotification({
        color: 'red',
        message: 'New stage cannot be within the range of another stage',
      });
      return;
    }
    addTempStage(stageId, messageId);
  };

  const handleStageEndSelection = async (index: number) => {
    if (!selectedStageBeginMessageId) return;
    // handle stage end selection
    const stageTag = tagsInProfile.find((tag) => tag.value === stageIdToBeAdded);
    const beginMessageId = selectedStageBeginMessageId;

    // check if end index is before begin index
    const beginMessageIndex = messages.findIndex((message) => message.v2MessageId === beginMessageId);
    if (index < beginMessageIndex) {
      showNotification({
        color: 'red',
        message: 'End of stage cannot be before the beginning of the stage',
      });
      return;
    }

    // check if end index is after the next stages begin index
    const stagesWithoutCurrentStage = stages.filter((stage) => stage.annotationId !== selectedStageAnnotationId);
    const nextStage = stagesWithoutCurrentStage.find((stage) => stage.conversationIndexStart > beginMessageIndex);
    if (nextStage && index >= nextStage.conversationIndexStart) {
      showNotification({
        color: 'red',
        message: 'End of stage cannot overlap with another stage',
      });
      return;
    }

    const endMessageId = messages[index].v2MessageId;

    const tag = {
      id: stageTag.value,
      title: stageTag.label,
      type: stageTag.tagType,
    };
    const stageBeginMessageId = beginMessageId;
    const stageEndMessageId = endMessageId;

    const stageTagAnnotationData = {
      tag,
      stageBeginMessageId,
      stageEndMessageId,
    };

    await handleStageAnnotationCreation(stageTagAnnotationData);
    resetStageTagging();
  };

  const stagesUnsorted: Stage[] = useMemo(() => {
    const stageAnnotations = annotations.filter((annotation) => annotation.value.type === AnnotationValueType.TYPE_CONVERSATION_STAGE);
    const unsortedStages = stageAnnotations.map((stageAnnotation) => {
      const { stageConceptId, stageBeginMessageId, stageEndMessageId } = stageAnnotation.value.conversationStageValue;
      const stageId = stageConceptId;
      const stageName = tagsMap.get(stageId)?.label;
      const conversationIndexStart = messages.findIndex((message) => message.v2MessageId === stageBeginMessageId);
      const conversationIndexEnd = messages.findIndex((message) => message.v2MessageId === stageEndMessageId);

      return {
        annotationId: getId('annotation', stageAnnotation.name),
        stageId,
        stageName,
        conversationIndexStart,
        conversationIndexEnd,
      };
    });
    if (tempStage) {
      unsortedStages.push(tempStage);
    }
    return unsortedStages;
  }, [annotations, messages, tagsMap]);
  const getStages = () => {
    // sort in order of conversationIndexStart
    // if same conversationIndexStart, then order by conversationIndexEnd (largest number first
    const stages = stagesUnsorted.sort((a, b) => {
      if (a.conversationIndexStart < b.conversationIndexStart) return -1;
      if (a.conversationIndexStart > b.conversationIndexStart) return 1;
      if (a.conversationIndexEnd > b.conversationIndexEnd) return -1;
      if (a.conversationIndexEnd < b.conversationIndexEnd) return 1;
      return 0;
    });
    return stages;
  };

  const stages = getStages();

  const value = useMemo(() => ({
    stages,
    onStageSelection,
    handleStageBeginSelection,
    handleStageEndSelection,
    selectedStageAnnotationId,
    resetStageTagging,
    onRemoveStageEnd,
    stageIdToBeAdded,
  }), [stages, onStageSelection, handleStageBeginSelection, handleStageEndSelection, selectedStageAnnotationId, resetStageTagging, onRemoveStageEnd, stageIdToBeAdded]);

  return (
    <StagesContext.Provider value={value}>
      {children}
    </StagesContext.Provider>
  );
};
