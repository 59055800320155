enum TaskType {
    AGENT_INTENT = 'agent-intent',
    VISITOR_INTENT = 'visitor-intent',
    CURRENT_INTENT = 'current-intent',
    NEXT_INTENT = 'next-intent',
    CHAT_DRIVER = 'chat-driver'
}

export {
  TaskType,
};
