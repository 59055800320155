import { Box, Text, Paper, Select, Flex, Textarea, Divider, useMantineTheme, Group, Button, TextInput, Space, Stack } from '@mantine/core';
import InputWithLabelValue from 'components/InputWithLabelValue';
import React from 'react';
import { LabelValue } from 'types';

export default function ChatGpt() {
  const theme = useMantineTheme();
  const flowGuidance = [
    'Confirm whether or not the customer is making a temporary or permanent move.',
    'Confirm whether or not the customer knows who is moving into their current location after they move out',
    'Ask for the new address where they are moving in order to send their prorated bill.',
  ];
  const rewardSystem = [
    'Transfer service to new address',
    'Generate service lead',
    'Register customer for seasonal hold',
    'Overcome objections',
  ];
  return (
    <Box>
      <Paper p="lg">
        <Group position="apart" mb="lg">
          <Text>Add details below</Text>
          <Group>
            <Button variant="light">Simulate</Button>
            <Button>Deploy</Button>
          </Group>
        </Group>
        <Flex
          align="start"
          justify="stretch"
        >
          <Box style={{ flex: 1 }}>
            <Select
              label="Use case"
              data={[{
                label: 'Moving, cancel service',
                value: 'Moving, cancel service',
              }]}
              value="Moving, cancel service"
              mb="lg"
            />
            <Textarea
              label="Background"
              placeholder="You’re a chatbot doing retention..."
              value="You are a chatbot for a large internet service provider, supporting customer retention."
              mb="lg"
              minRows={3}
            />
            <Textarea
              label="Objective"
              placeholder="Try and get customers to retain, sign up for seasonal hold, etc..."
              value="Your goal is to have a conversation with a human who is chatting in order to cancel their internet service because they are moving locations and no longer need internet services at their existing location.You should ask them several questions to figure out whether or not they qualify for a retention. Only after they have been qualified and determined unfit for retention should you proceed to cancel their services."
              mb="lg"
              minRows={4}
            />
            <Textarea
              label="Limitations"
              placeholder=""
              value="- Your responses should be 50 words or less.&#10;- No curse words&#10;- Context should be focused on internet services"
              mb="lg"
              minRows={3}
            />
            <Textarea
              label="Metadata"
              placeholder=""
              value="- Seasonal Hold, [Price:$20/mo]&#10;- Referral Discount, [Price:$50/mo]&#10;- Service Area, [Zip Code: 78747, 78745, 74746]"
              mb="lg"
              minRows={3}
            />
            <Text size="md" mb="sm">Flow guidance</Text>
            <Stack>
              {flowGuidance.map((item, i) => (
                <InputWithLabelValue
                  number={i + 1}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  showToggle={false}
                  valueData={{
                    value: item,
                    type: LabelValue.POSITIVE,
                    hidden: false,
                  }}
                  onChange={() => {}}
                  onDeleteHandler={() => {}}
                  showLabelValue={false}
                />
              ))}
            </Stack>
          </Box>
          <Divider mx="lg" orientation="vertical" style={{ height: 'auto' }} color={theme.colors.gray[1]} />
          <Box style={{ flex: 1 }}>
            <Divider w="100%" color={theme.colors.gray[1]}/>
            <Text size="sm" pr="md" color={theme.colors.gray[3]} style={{ backgroundColor: 'white', position: 'relative', top: -12, display: 'inline-block' }}>Specific verbiage</Text>
            <Textarea
              label="Greeting"
              placeholder="Enter greeting"
              value="Hi, thanks for chatting! I am Zander, our TelcoGPT virtual agent. How can I help you out today?"
              mb="lg"
              minRows={3}
            />
            <Textarea
              label="Conclusion"
              placeholder="Enter conclusion"
              value="Thanks chatting in today, I hope you have a great rest of your day! Feel free to drop by anytime if there is anything else you need help with"
              mb="xl"
              minRows={3}
            />
            <Space h="lg"/>
            <Divider w="100%" color={theme.colors.gray[1]}/>
            <Text size="sm" pr="md" color={theme.colors.gray[3]} style={{ backgroundColor: 'white', position: 'relative', top: -12, display: 'inline-block' }}>Webhook</Text>
            <Group>
              <TextInput
                label="Display name"
                placeholder=""
                value="Seasonal Hold Update"
                mb="lg"
                style={{ flex: 1 }}
              />
              <TextInput
                label="Webhook timeout"
                placeholder=""
                value="5"
                rightSection={<Text size="sm" style={{ marginLeft: -35 }}>seconds</Text>}
                mb="lg"
              />
            </Group>
            <Select
              label="Type"
              data={[{
                label: 'Generic web service',
                value: 'Generic web service',
              }]}
              value="Generic web service"
              mb="lg"
            />
            <Select
              label="Webhook URL"
              data={[{
                label: 'https://crestatelco-6483.twil.io/SeasonalHoldUpdate',
                value: 'https://crestatelco-6483.twil.io/SeasonalHoldUpdate',
              }]}
              value="https://crestatelco-6483.twil.io/SeasonalHoldUpdate"
              mb="lg"
            />
            <Text mb="sm" size="md">Reward system</Text>
            <Stack>
              {rewardSystem.map((item, i) => (
                <InputWithLabelValue
                  number={i + 1}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  showToggle={false}
                  valueData={{
                    value: item,
                    type: LabelValue.POSITIVE,
                    hidden: false,
                  }}
                  onChange={() => {}}
                  onDeleteHandler={() => {}}
                  showLabelValue={false}
                />
              ))}
            </Stack>
          </Box>
        </Flex>
        {/* <Textarea minRows={3} mb="lg" label="Background" placeholder="You’re a chatbot doing retention..."/>
        <Textarea minRows={3} mb="lg" label="Objective" placeholder="Try and get customers to retain, sign up for seasonal hold, etc..."/>
        <Textarea
          label="Flow Guidance"
          placeholder="1. Check seasonal hold&#10;2. check next tenants&#10;3.prorated billing"
          mb="lg"
          minRows={3}
        />
        <Textarea
          label="Limitations"
          placeholder="- Responses should be 50 words or less &#10;- If customer asks about non-retention related tasks, and are more customer service, reroute them."
          mb="lg"
          minRows={3}
        />
        <Textarea
          label="Metadata"
          placeholder="- Internet Plans [Plan A]&#10;- Cost [Plan A: $30]"
          mb="lg"
          minRows={3}
        /> */}
      </Paper>
    </Box>
  );
}
