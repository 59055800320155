import React, { useState } from 'react';
import { Button, Group, Modal, Stack, TextInput } from '@mantine/core';

interface Props {
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  tagTitle: string;
  setTagTitle: (tagTitle: string) => void;
  tag: { value: string; label: string };
  updateConceptTitle: (conceptId: string, newTitle: string) => Promise<void>;
}

export const EditTagNameModal = (props: Props) => {
  const { isOpen, toggleOpen, tagTitle, setTagTitle, tag, updateConceptTitle } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, toggleSaving] = useState(false);
  const onCLose = () => {
    setErrorMessage('');
    toggleSaving(false);
    toggleOpen(false);
  };
  return (
    <Modal
      opened={isOpen}
      onClose={onCLose}
      title="Edit Tag Name"
    >
      <Stack>
        <TextInput
          label="New Tag Name"
          value={tagTitle}
          onChange={(e) => {
            setErrorMessage('');
            setTagTitle(e.target.value);
          }}
          error={errorMessage}
        />
        <Group position="right">
          <Button
            onClick={onCLose}
            variant="subtle"
          >Cancel
          </Button>
          <Button
            loading={isSaving}
            onClick={async () => {
              if (tagTitle === tag.label) {
                setErrorMessage('Tag name is the same as the current tag name');
                return;
              }
              toggleSaving(true);
              await updateConceptTitle(tag.value, tagTitle);
              toggleSaving(false);
              toggleOpen(false);
            }}
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
