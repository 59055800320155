import { ListUsersResponse, UserService } from '@cresta/web-client/dist/cresta/v1/user';
import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { extractCustomerFromResource, getId } from 'common/resourceName';
import { StudioApi } from 'services/studioApi';

enum Action {
  GET_TASK_PLAYLIST_CUSTOMERS_CURRENT_USER_IDS = 'GET_TASK_PLAYLIST_CUSTOMERS_CURRENT_USER_IDS'
}

interface CustomerConfigsAndEmail {
  customerConfigs: Config[],
  email: string,
}

export interface CustomersCurrentUserIdMap {
  [customer: string]: string
}

export const getCustomersCurrentUserIds = createAsyncThunk<CustomersCurrentUserIdMap, CustomerConfigsAndEmail>(Action.GET_TASK_PLAYLIST_CUSTOMERS_CURRENT_USER_IDS, async ({
  customerConfigs,
  email,
}) => {
  const promises: Promise<ListUsersResponse>[] = customerConfigs.map((customerConfig) => {
    const customerProfile = `studio/customers/${customerConfig.customerId}/profiles/${customerConfig.profileId}`;
    const { initReq } = StudioApi.getHeaders(customerConfig);
    return UserService.ListUsers({
      parent: customerProfile,
      filter: {
        emailContains: email,
      },
    }, initReq);
  });

  const responses = await Promise.all(promises);
  const customersCurrentUserIdMap = {};
  responses.forEach((response, i) => {
    const userResourceName = response.users[0]?.name;
    const { customerId, profileId } = extractCustomerFromResource('user', userResourceName);
    customersCurrentUserIdMap[`${customerId}/${profileId}`] = userResourceName ? getId('user', userResourceName) : undefined;
  });
  return customersCurrentUserIdMap;
});
