import React from 'react';
import { useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import './ChatList.scss';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { ChatList as ChatListComponent } from './ChatList';

interface Props {
  chats: Conversation[];
  isLoading: boolean;
  hasNextPage: boolean;
  handleFilterReset: () => void;
  handleLoadMore: () => Promise<void>;
}

export default function ChatList(props: Props) {
  const { chats, isLoading, hasNextPage, handleFilterReset, handleLoadMore } = props;
  const { search } = useLocation();
  const customer = useCustomerParams();

  const sortChats = (chats: Conversation[]) => [...sortBy(chats, 'openDate')];

  const sortedChats = sortChats(chats);

  const handleNavigation = (id:string) => ({
    pathname: `/${customer.path}/conversation/${id}`,
    search,
  });

  return (
    <ChatListComponent
      handleNavigation={handleNavigation}
      chats={sortedChats}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      handleFilterReset={handleFilterReset}
      loadMoreChats={handleLoadMore}
    />
  );
}
