import { Annotation, SuggestionQaValue, SuggestionQaValueCompareModelValue, SuggestionQaValueSingleModelValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Divider, Group, Select, SelectItem, Space, Text, useMantineTheme } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { clipText } from 'common/clipText';
import { getId } from 'common/resourceName';
import { getAnnotationTextAndClassByValue } from 'components/Annotations';
import AnnotationTag from 'components/Annotations/AnnotationTag';
import ConversationPreview from 'components/ConversationPreview';
import CopyableValue from 'components/CopyableValue';
import MessageSuggestions from 'components/LabelingItemsDisplay/MessageSuggestions';
import Loading from 'components/Loading';
import MultiTags from 'components/MultiTags';
import { useSelector } from 'hooks/reduxHooks';
import { useLabelingItems } from 'hooks/useLabelingItems';
import { usePredictions } from 'hooks/usePredictions';
import { round } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { selectAnnotationBundles, selectApiStatus, selectDerivedLabelingTaskFactory } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import { BaseSummaryTable } from '..';

function formatPercentage(value: number, total: number) {
  if (!total || total === 0) return null;
  return `(${round(value / total * 100, 2)}%)`;
}

function MetaInfo({ annotations, models, isCompare }: { annotations: Annotation[], models: string[], isCompare: boolean }) {
  const theme = useMantineTheme();

  let total = 0;
  // Single model
  let singleGood = 0;
  let singleBad = 0;
  let singleNeutral = 0;
  // Compare models
  let compareModel1 = 0;
  let compareModel2 = 0;
  let compareModelBoth = 0;
  let compareModelNeither = 0;

  annotations.forEach((annotation) => {
    if (annotation.value.suggestionQaValue?.singleModelValue === SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_GOOD) {
      singleGood += 1;
    }
    if (annotation.value.suggestionQaValue?.singleModelValue === SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_BAD) {
      singleBad += 1;
    }
    if (annotation.value.suggestionQaValue?.singleModelValue === SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_NEUTRAL) {
      singleNeutral += 1;
    }
    if (annotation.value.suggestionQaValue?.compareModelValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_1) {
      compareModel1 += 1;
    }
    if (annotation.value.suggestionQaValue?.compareModelValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_2) {
      compareModel2 += 1;
    }
    if (annotation.value.suggestionQaValue?.compareModelValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH) {
      compareModelBoth += 1;
    }
    if (annotation.value.suggestionQaValue?.compareModelValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER) {
      compareModelNeither += 1;
    }
    total += 1;
  });

  return (
    <div>
      <Group>
        <Group spacing="xs">
          <Text color="gray">Total:</Text><Text>{total}</Text>
        </Group>

        {!isCompare ? (
          <>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Good" type="positive" />
              <Text>{singleGood} {formatPercentage(singleGood, total)}</Text>
            </Group>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Bad" type="negative" />
              <Text>{singleBad} {formatPercentage(singleBad, total)}</Text>
            </Group>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Neutral" type="flagged" />
              <Text>{singleNeutral} {formatPercentage(singleNeutral, total)}</Text>
            </Group>
          </>
        ) : (
          <>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Model 1" type="orange" />
              <Text>{compareModel1} {formatPercentage(compareModel1, total)}</Text>
            </Group>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Model 2" type="purple" />
              <Text>{compareModel2} {formatPercentage(compareModel2, total)}</Text>
            </Group>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Neither" type="negative" />
              <Text>{compareModelNeither} {formatPercentage(compareModelNeither, total)}</Text>
            </Group>
            <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
            <Group spacing="xs">
              <AnnotationTag text="Both" type="positive" />
              <Text>{compareModelBoth} {formatPercentage(compareModelBoth, total)}</Text>
            </Group>
          </>
        )}

        <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
        <Group>
          <Text>Models</Text>
          <MultiTags tags={models.filter((model) => model)} detail/>
        </Group>
      </Group>
    </div>
  );
}

type SuggestionFilterValue = SuggestionQaValueSingleModelValue | SuggestionQaValueCompareModelValue | 'ALL';

interface SummarySuggestionsTableOldProps extends BaseSummaryTable {}

export function SummarySuggestionsTableOld({ searchTerm, taskId, filtersNode }: SummarySuggestionsTableOldProps) {
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const annotations = useMemo(() => annotationBundles.map((bundle) => bundle.annotation), [annotationBundles]);

  // Filters
  const [filterValue, setFilterValue] = useState<SuggestionFilterValue>('ALL');

  // For conversation preview
  const [showConversation, setShowConversation] = useState(false);
  const customerProfile = useCustomerProfile();
  const task = useSelector<DerivedLabelingTask>(
    selectDerivedLabelingTaskFactory(`${customerProfile}/labelingTasks/${taskId}`),
  );
  const modelUri1 = task?.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.modelUri;
  const modelUri2 = task?.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.modelUri2;
  const [selectedRowRecord, setSelectedRowRecord] = useState<SearchAnnotationsResponseAnnotationBundle>();

  const conversationPosition = useMemo(
    () =>
      selectedRowRecord?.annotation.rawData.conversationPositionNumber
    , [selectedRowRecord],
  );

  // We need messages from labeling items because only they have policyQaContext
  const [labelingItemData, isLoadingLabelingItemData] = useLabelingItems(`${customerProfile}/labelingTasks/${taskId}`, conversationPosition);
  const messages = labelingItemData?.conversationLabelingItems?.[0]?.messagesAndContexts || [];

  // Message from selected table row
  const focusedMessage = useMemo(() => messages.find((message) => {
    if (!selectedRowRecord) return null;
    const messageId = message?.v2MessageId;
    const { messageRawData } = selectedRowRecord?.annotation.rawData;
    return messageId === messageRawData.v2MessageId;
  }), [messages, selectedRowRecord]);

  const predictionIds = focusedMessage?.selectionContext.suggestionQaContext?.predictionIds || [];
  const [predictions] = usePredictions(`${customerProfile}/conversations/-`, predictionIds);

  // Split predictions based on model uri
  const [modelPredictions1, modelPredictions2] = useMemo(() => {
    const modelPredictions1: Prediction[] = [];
    const modelPredictions2: Prediction[] = [];
    const targetMessagePredictionIds = focusedMessage?.selectionContext.suggestionQaContext?.predictionIds || [];
    predictions.filter((prediction) => prediction.action?.suggestionPayload).forEach((prediction) => {
      // Check if the prediction exist in message suggestion context
      if (targetMessagePredictionIds.includes(getId('prediction', prediction.name))) {
        if (prediction?.action?.suggestionPayload?.results?.map((r) => r.ensembleModelId || r.modelId).some((m) => modelUri1.includes(m))) {
          modelPredictions1.push(prediction);
        } else if (prediction?.action?.suggestionPayload?.results?.map((r) => r.ensembleModelId || r.modelId).some((m) => modelUri2.includes(m))) {
          modelPredictions2.push(prediction);
        }
      }
    });
    return [modelPredictions1, modelPredictions2];
  }, [predictions, focusedMessage, modelUri1, modelUri2]);

  const perMessageColumns: ColumnsType<SearchAnnotationsResponseAnnotationBundle> = [
    {
      title: 'Chat ID',
      width: 120,
      dataIndex: 'chatId',
      key: 'chatId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2ConversationId;
        return <CopyableValue displayValue={clipText(id, 6)} copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Message',
      dataIndex: ['textMessage'],
      key: 'message',
    },
    {
      title: 'Result',
      dataIndex: ['annotation', 'value', 'suggestionQaValue'],
      key: 'prediction',
      render: (value: SuggestionQaValue, record) => {
        if (record.annotation.value.binaryValue) {
          const { text, className } = getAnnotationTextAndClassByValue(record.annotation.value);
          return (
            <div>
              <AnnotationTag text={text} type={className} />
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Notes',
      dataIndex: ['annotation', 'value', 'suggestionQaValue', 'note'],
      width: '20%',
      key: 'comment',
      render: (value, record) => value || 'N/A',
    },
  ];

  const summaryResults = useMemo(() => {
    const filteredSummary = annotationBundles
      .filter((bundle) => bundle.textMessage.includes(searchTerm.toLowerCase()))
      .filter(((bundle) => {
        if (!filterValue || filterValue === 'ALL') return true;
        if (modelUri2) {
          return bundle.annotation.value.suggestionQaValue?.compareModelValue === filterValue;
        } else {
          return bundle.annotation.value.suggestionQaValue?.singleModelValue === filterValue;
        }
      }));
    return filteredSummary;
  }, [annotationBundles, searchTerm, modelUri2, filterValue]);

  const valuesFilterOptions: SelectItem[] = useMemo(() => {
    const options = [
      {
        label: 'All results',
        value: 'ALL',
      },
    ];
    if (modelUri2) {
      // For compare models
      return [
        ...options,
        {
          label: 'Model 1',
          value: SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_1,
        },
        {
          label: 'Model 2',
          value: SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_2,
        },
        {
          label: 'Both',
          value: SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH,
        },
        {
          label: 'Neither',
          value: SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER,
        },
      ];
    } else {
      // For single model
      return [
        ...options,
        {
          label: 'Good',
          value: SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_GOOD,
        },
        {
          label: 'Bad',
          value: SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_BAD,
        },
        {
          label: 'Neutral',
          value: SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_NEUTRAL,
        },
      ];
    }
  }, [modelUri2]);

  // Table data
  return (
    <div style={{
      width: '100%',
      flex: 1,
    }}
    >
      <MetaInfo annotations={annotations} models={[modelUri1, modelUri2]} isCompare={!!modelUri2}/>
      <Space h="lg"/>
      <Group mb="lg" position="right">
        <>
          <Select
            data={valuesFilterOptions}
            defaultValue="ALL"
            onChange={(value) => setFilterValue(value as SuggestionFilterValue)}
          />
          {filtersNode && filtersNode}
        </>
      </Group>
      <div style={{
        display: 'flex',
      }}
      >
        <Table
          rowKey={(row) => row.annotation.name}
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
          style={{
            width: '100%',
          }}
          columns={perMessageColumns}
          dataSource={summaryResults}
          onRow={(record) => ({
            onClick: (e) => {
              if (!record.annotation.rawData.messageRawData.v2MessageId) return;
              setSelectedRowRecord(record);
              setShowConversation(true);
            },
          })}
        />
        <ConversationPreview
          messages={messages}
          focusedMessageId={focusedMessage?.v2MessageId}
          loading={isLoadingLabelingItemData}
          onClose={() => setShowConversation(false)}
          showConversation={showConversation}
        >
          <div>
            <MessageSuggestions
              predictions1={modelPredictions1}
              predictions2={modelPredictions2}
              hasSecondModel={!!modelUri2}
            />
          </div>
        </ConversationPreview>
      </div>
    </div>
  );
}
