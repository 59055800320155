import {
  SetService,
  GetSetRequest,
  GetSetResponse,
  ListSetsRequest,
  ListSetsResponse,
  CreateSetRequest,
  CreateSetResponse,
  DeleteSetRequest,
  DeleteSetResponse,
  AddToSetRequest,
  AddToSetResponse,
  RemoveFromSetRequest,
  RemoveFromSetResponse,
  FetchSetsForSetItemRequest,
  FetchSetsForSetItemResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { StudioApi } from './studioApi';

export abstract class SetApi {
  public static listSets(request: ListSetsRequest): Promise<ListSetsResponse> {
    return SetService.ListSets(request, StudioApi.getHeaders().initReq);
  }

  public static getSet(request: GetSetRequest): Promise<GetSetResponse> {
    return SetService.GetSet(request, StudioApi.getHeaders().initReq);
  }

  public static createSet(request: CreateSetRequest): Promise<CreateSetResponse> {
    return SetService.CreateSet(request, StudioApi.getHeaders().initReq);
  }

  public static deleteSet(request: DeleteSetRequest): Promise<DeleteSetResponse> {
    return SetService.DeleteSet(request, StudioApi.getHeaders().initReq);
  }

  public static addToSet(request: AddToSetRequest): Promise<AddToSetResponse> {
    return SetService.AddToSet(request, StudioApi.getHeaders().initReq);
  }

  public static removeFromSet(request: RemoveFromSetRequest): Promise<RemoveFromSetResponse> {
    return SetService.RemoveFromSet(request, StudioApi.getHeaders().initReq);
  }

  public static fetchSetsForSetItem(request: FetchSetsForSetItemRequest): Promise<FetchSetsForSetItemResponse> {
    return SetService.FetchSetsForSetItem(request, StudioApi.getHeaders().initReq);
  }
}
