import { BinaryValueValue, SuggestionQaValueCompareModelValue, SuggestionQaValueSingleModelValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { AnnotationTagType } from 'components/Annotations/AnnotationTag';

/**
 * A map of labeling task states with classes for styling
 */
export const TASK_STATUS_MAP = new Map<TaskStatus, {text: string, className: string}>([
  [TaskStatus.TASK_ABORTED, { text: 'Aborted', className: '' }],
  [TaskStatus.TASK_COMPLETED, { text: 'Completed', className: 'completed' }],
  [TaskStatus.TASK_CREATED, { text: 'Created', className: 'created' }],
  [TaskStatus.TASK_ERROR, { text: 'Error', className: 'error' }],
  [TaskStatus.TASK_IN_PROGRESS, { text: 'In progress', className: 'in-progress' }],
  [TaskStatus.TASK_READY, { text: 'Ready', className: 'ready' }],
  [TaskStatus.TASK_STATUS_UNSPECIFIED, { text: 'Unspecified', className: '' }],
  [TaskStatus.TASK_TIMEOUT, { text: 'Timeout', className: '' }],
]);

/**
 * A map of binary annotation values with their user friendly names
 */
export const ANNOTATION_BINARY_VALUE_MAP = new Map<BinaryValueValue, {text: string, className: AnnotationTagType}>([
  [BinaryValueValue.VALUE_FLAG_FOR_REVIEW, { text: 'Unsure', className: 'flagged' }],
  [BinaryValueValue.VALUE_NEGATIVE, { text: 'No', className: 'negative' }],
  [BinaryValueValue.VALUE_POSITIVE, { text: 'Yes', className: 'positive' }],
  [BinaryValueValue.VALUE_SKIP, { text: 'Skipped', className: 'skipped' }],
  [BinaryValueValue.VALUE_UNSPECIFIED, { text: 'Unspecified', className: 'unspecified' }],
]);

/**
 * A map of suggestion single model annotation values with their user friendly names
 */
export const ANNOTATION_SUGGESTION_SINGLE_VALUE_MAP = new Map<SuggestionQaValueSingleModelValue, {text: string, className: AnnotationTagType}>([
  [SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_BAD, { text: 'Bad', className: 'negative' }],
  [SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_GOOD, { text: 'Good', className: 'positive' }],
  [SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_NEUTRAL, { text: 'Neutral', className: 'flagged' }],
]);

/**
 * A map of suggestion compare model annotation values with their user friendly names
 */
export const ANNOTATION_SUGGESTION_COMPARE_VALUE_MAP = new Map<SuggestionQaValueCompareModelValue, {text: string, className: AnnotationTagType}>([
  [SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH, { text: 'Both', className: 'positive' }],
  [SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_1, { text: 'Model 1', className: 'orange' }],
  [SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_2, { text: 'Model 2', className: 'purple' }],
  [SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER, { text: 'Neither', className: 'negative' }],
]);
