import './TaxonomyTree.scss';
import '../TreeNode/TreeNodeIcon.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';
import { Concept, ConceptConceptTagType, IntentIntentType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
// @ts-ignore
import iconPlus from 'assets/svg/icon-node-plus.svg';
// @ts-ignore
import iconMinus from 'assets/svg/icon-node-minus.svg';
import { toTitleCase } from 'utils';
import MultiTags from 'components/MultiTags';

/** Wraps the concept and its parent and children. */
export interface TaxonomyTreeNode {
  // Concept ID.
  id: string;
  // Concept name without parent prefix.
  // e.g. `intent` if the concept title is `stage.intent`.
  name: string;
  concept: Concept;
  parentNode?: TaxonomyTreeNode;
  childNodes: TaxonomyTreeNode[];
  // Count of the intents under this node.
  intentCount: number;
}

/** Supported filters for the taxonomy tree node. */
export interface Filters {
  text: string;
  intentStates: ConceptState[];
  tags: ConceptConceptTagType[];
}

interface TaxonomyTreeProps {
  concepts: Concept[];
  focusedNodeId?: string;
  showTags?: boolean;
  onFocus?: (node: TaxonomyTreeNode) => void;
  filters?: Filters;
  loading: boolean;
}

const DEFAUT_DRIVER_ID = '0';
const TEMPORARY_UUID_PREFFIX = 'c71999ca-ac1e-4a38-b9da-b2cfcdd90411';

/** Helper to get parent driver ID recursively. Return null if no parent driver. */
export function getParentDriverId(parent?: TaxonomyTreeNode): string | null {
  if (!parent) {
    return null;
  }
  if (parent.concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER) {
    if (parent.id === DEFAUT_DRIVER_ID) {
      return null;
    }
    return parent.id;
  }
  return getParentDriverId(parent.parentNode);
}

/** Helper to reconstuct intent prefix. */
export function getIntentPrefix(parent?: TaxonomyTreeNode): string {
  if (!parent || parent.concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER) {
    return '';
  }
  const prefix = getIntentPrefix(parent.parentNode);
  if (prefix === '') {
    return `${parent.name}.`;
  }
  return `${prefix}${parent.name}.`;
}

function traverseTreeNodes(nodes: TaxonomyTreeNode[], callback: (node: TaxonomyTreeNode) => void) {
  for (const node of nodes) {
    callback(node);
    traverseTreeNodes(node.childNodes, callback);
  }
}

/** Helper function to tell whether a concept is an intent. */
export function isIntent(concept: Concept): boolean {
  return concept.intent?.intentType === IntentIntentType.AGENT_INTENT || concept.intent?.intentType === IntentIntentType.VISITOR_INTENT;
}

/**
 * Helper function to filter intent concepts with the tree hierachy.
 * If an intent concept is in the filtered results, its parent driver
 * and stage concepts will be kept.
 */
export function filterIntentConcepts(concepts: Concept[], filterFn: (concept: Concept) => boolean): Concept[] {
  const filteredConcepts: Concept[] = [];
  // Driver ID to driver concept.
  const discardDrivers = new Map<string, Concept>();
  // Stage title to stage concept.
  const discardStages = new Map<string, Concept>();
  concepts.forEach((concept) => {
    if (filterFn(concept)) {
      filteredConcepts.push(concept);
    } else if (concept.intent.intentType === IntentIntentType.STAGE) {
      discardStages.set(concept.conceptTitle, concept);
    } else if (concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER) {
      discardDrivers.set(getId('concept', concept.name), concept);
    }
  });
  // Parent drivers and stages for filtered intents should be presented.
  const parentConcepts: Concept[] = [];
  for (const concept of filteredConcepts) {
    // Skip intents non-conforming to new taxonomy pattern.
    if (isIntent(concept) && concept.conceptTitle.split('.').length === 2) {
      for (const parentDriverId of concept.intent.parentConversationDriverNames) {
        if (discardDrivers.has(parentDriverId)) {
          parentConcepts.push(discardDrivers.get(parentDriverId));
          discardDrivers.delete(parentDriverId);
        }
      }
      const stageTitle = concept.conceptTitle.split('.')[0];
      if (discardStages.has(stageTitle)) {
        parentConcepts.push(discardStages.get(stageTitle));
        discardStages.delete(stageTitle);
      }
    }
  }
  return [...filteredConcepts, ...parentConcepts];
}

// Build taxonomy trees from the given intent concepts. Return the root nodes which are
// conversation drivers.
function buildTaxonomyTree(concepts: Concept[]): TaxonomyTreeNode[] {
  const driversMap = new Map<string, TaxonomyTreeNode>();
  const defaultDriverNode: TaxonomyTreeNode = {
    id: DEFAUT_DRIVER_ID,
    name: 'default',
    concept: {
      intent: {
        intentType: IntentIntentType.CONVERSATION_DRIVER,
      },
    },
    childNodes: [],
    intentCount: 0,
  };
  // Add driver nodes.
  driversMap.set(defaultDriverNode.id, defaultDriverNode);
  for (const concept of concepts) {
    if (concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER) {
      const driverNode: TaxonomyTreeNode = {
        id: getId('concept', concept.name),
        // Strip the chat_driver prefix for some legacy drivers, e.g. chat_driver.some_driver
        name: concept.conceptTitle?.split('.').pop(),
        concept,
        childNodes: [],
        intentCount: 0,
      };
      driversMap.set(driverNode.id, driverNode);
    }
  }
  // Helper to insert STAGE/INTENT concepts to a taxonomy tree.
  const insertConcept = (current: TaxonomyTreeNode, concept: Concept, level: number) => {
    const spans = concept.conceptTitle?.split('.');
    if (!spans || spans.length === 0) return;
    // Insert the concept.
    if (level === spans.length) {
      // eslint-disable-next-line no-param-reassign
      current.id = `${getId('concept', concept.name)}:${getParentDriverId(current) || DEFAUT_DRIVER_ID}`;
      // eslint-disable-next-line no-param-reassign
      current.concept = concept;
      return;
    }

    let next: TaxonomyTreeNode = current.childNodes.find((node) => node.name === spans[level]);
    if (!next) {
      next = {
        id: '',
        name: spans[level],
        concept: { intent: { intentType: IntentIntentType.STAGE } },
        childNodes: [],
        parentNode: current,
        intentCount: 0,
      };
      current.childNodes.push(next);
    }
    // eslint-disable-next-line no-param-reassign
    current.intentCount += (isIntent(concept) ? 1 : 0);
    insertConcept(next, concept, level + 1);
  };
  // Add stage/intent nodes.
  const intentTypes = new Set([IntentIntentType.STAGE, IntentIntentType.AGENT_INTENT, IntentIntentType.VISITOR_INTENT]);
  for (const concept of concepts) {
    if (intentTypes.has(concept.intent.intentType)) {
      if (!concept.intent.parentConversationDriverNames?.length) {
        insertConcept(driversMap.get(DEFAUT_DRIVER_ID), concept, 0);
      }
      for (const driverId of concept.intent.parentConversationDriverNames) {
        if (driversMap.has(driverId)) {
          insertConcept(driversMap.get(driverId), concept, 0);
        } else if (driverId === 'None') {
          insertConcept(driversMap.get(DEFAUT_DRIVER_ID), concept, 0);
        }
      }
    }
  }
  const driverNodes = Array.from(driversMap.values());
  // Assign a temp uuid for those dummy stage nodes.
  let i = 0;
  traverseTreeNodes(driverNodes, (node) => {
    if (!node.id) {
      // eslint-disable-next-line no-param-reassign
      node.id = `${TEMPORARY_UUID_PREFFIX}-${i}`;
      // eslint-disable-next-line no-param-reassign
      node.concept.conceptTitle = node.name;
      i++;
    }
  });
  // Sort staging nodes by the default order: 'open', a-z, 'close', 'inform'.
  const orders = new Map<string, number>([['open', -1], ['close', 1], ['inform', 2]]);
  driverNodes.forEach((driver) => driver.childNodes.sort((s1, s2) => {
    const v1 = orders.get(s1.name) || 0;
    const v2 = orders.get(s2.name) || 0;
    if (v1 !== v2) {
      return v1 - v2;
    }
    // v1 === v2
    if (v1 === 0) {
      return s1.name.localeCompare(s2.name);
    }
    return 0;
  }));
  return driverNodes;
}

function intentTypeText(type: IntentIntentType): string {
  switch (type) {
    case IntentIntentType.CONVERSATION_DRIVER:
      return 'Chat Driver';
    case IntentIntentType.AGENT_INTENT:
      return 'Agent Intent';
    case IntentIntentType.VISITOR_INTENT:
      return 'Visitor Intent';
    case IntentIntentType.STAGE:
      return 'Stage';
    default:
      return 'Unknown';
  }
}

function intentTypeClass(type: IntentIntentType): string {
  switch (type) {
    case IntentIntentType.CONVERSATION_DRIVER:
      return 'chat-driver';
    case IntentIntentType.AGENT_INTENT:
      return 'agent';
    case IntentIntentType.VISITOR_INTENT:
      return 'visitor';
    case IntentIntentType.STAGE:
      return 'stage';
    default:
      return 'unknown';
  }
}

function intentNodeClass(type: IntentIntentType): string {
  switch (type) {
    case IntentIntentType.CONVERSATION_DRIVER:
      return 'chat-driver';
    case IntentIntentType.STAGE:
      return 'stage';
    case IntentIntentType.AGENT_INTENT:
    case IntentIntentType.VISITOR_INTENT:
      return 'intent';
    default:
      return 'unknown';
  }
}

/** Tree node icon styled by the intent type. */
export function TreeNodeIcon({ intentType, state }: { intentType: IntentIntentType, state: ConceptState }) {
  const deprecated = state === ConceptState.DEPRECATED;
  return (
    <Tooltip title={intentTypeText(intentType)}>
      <div className={`tree-node-icon ${intentTypeClass(intentType)} ${deprecated ? 'deprecated' : ''}`} />
    </Tooltip>
  );
}

export function IntentStateIcon({ intentType, state }: { intentType: IntentIntentType, state: ConceptState | ConceptState }) {
  if (intentType === IntentIntentType.AGENT_INTENT || intentType === IntentIntentType.VISITOR_INTENT) {
    return (
      <Tooltip title={toTitleCase(state)}>
        <span className={`intent-state-indicator ${state?.toLowerCase()}`} />
      </Tooltip>
    );
  }
  return (null);
}

export default function TaxonomyTree({
  concepts,
  focusedNodeId,
  showTags,
  onFocus,
  filters,
  loading,
}: TaxonomyTreeProps) {
  const driverNodes = useMemo(() => {
    const tagsSet = new Set<ConceptConceptTagType>(filters?.tags);
    const filterFn = (concept: Concept) => {
      if (concept.intent.intentType === IntentIntentType.INTENT_TYPE_UNSPECIFIED) {
        return false;
      }
      // Tags filter.
      if (tagsSet.size > 0) {
        let containsTag = false;
        for (const tag of concept.conceptTags) {
          if (tagsSet.has(tag)) {
            containsTag = true;
            break;
          }
        }
        if (!containsTag) {
          return false;
        }
      }
      // State filter.
      if (filters?.intentStates?.length > 0 && !filters.intentStates.includes(concept.state)) {
        return false;
      }
      // Search text filter.
      if (filters?.text && !concept.conceptTitle.includes(filters.text)) {
        return false;
      }
      return true;
    };
    const filteredConcepts = filterIntentConcepts(concepts, filterFn).sort((a, b) => a.name.localeCompare(b.name));
    return buildTaxonomyTree(filteredConcepts);
  }, [concepts, filters]);

  const [expanded, setExpanded] = useState<Map<string, boolean>>(new Map());
  // To prevent rendering the tree without computed expanded map
  const [expandedComputed, setExpandedComputed] = useState(false);
  useEffect(() => {
    const expandAll = !!filters?.text;
    const nextExpanded = new Map<string, boolean>();
    traverseTreeNodes(driverNodes, (node) => {
      if (onFocus && focusedNodeId && node.id === focusedNodeId) {
        onFocus(node);
      }
      let expand = expandAll;
      if (!expandAll) {
        if (expanded.has(node.id)) {
          expand = expanded.get(node.id);
        } else {
          expand = node.concept?.intent?.intentType === IntentIntentType.CONVERSATION_DRIVER;
        }
      }
      nextExpanded.set(node.id, expand);
    });
    setExpanded(nextExpanded);
    setExpandedComputed(true);
  }, [driverNodes]);

  const flipNodeExpansion = useCallback((nodeId: string) => {
    if (expanded.has(nodeId)) {
      const nextExpanded = new Map(expanded);
      nextExpanded.set(nodeId, !expanded.get(nodeId));
      setExpanded(nextExpanded);
    }
  }, [expanded]);

  // Render node recursively.
  const renderNode = (node: TaxonomyTreeNode, level: number) => (
    <div key={`${node.id}-group`} className={`tree-nodes-group level-${level}`}>
      <div
        key={node.id}
        className={`tree-node ${intentNodeClass(node.concept.intent.intentType)} ${String(node.concept.state).toLowerCase()}`}
        onClick={(e) => {
          e.stopPropagation();
          if (onFocus) {
            onFocus(node);
          }
        }}
      >
        {(node.childNodes.length > 0) && (
          <div
            className="collapse-button"
            onClick={(e) => {
              e.stopPropagation();
              flipNodeExpansion(node.id);
            }}
          >
            {expanded.get(node.id) ? (
              <img src={iconMinus} alt="Collapse" />
            ) : (
              <img src={iconPlus} alt="Expand" />
            )}
          </div>
        )}
        <div className={`node-container ${intentNodeClass(node.concept.intent.intentType)} ${node.id === focusedNodeId ? 'focused' : ''}`}>
          <TreeNodeIcon
            intentType={node.concept.intent.intentType}
            state={node.concept.state}
          />
          <span>{node.name}</span>
          <div className="tags" hidden={!showTags}>
            <MultiTags
              tags={node.concept.conceptTags?.map((tag) => toTitleCase(tag))}
              type="conceptTag"
            />
          </div>
          <IntentStateIcon
            intentType={node.concept.intent.intentType}
            state={node.concept.state}
          />
          {(node.intentCount > 0) && (
            <span className="count">({node.intentCount})</span>
          )}
        </div>
      </div>
      {(node.childNodes.length > 0 && expanded.get(node.id)) && node.childNodes.map(
        (childNode) => renderNode(childNode, level + 1),
      )}
    </div>
  );

  if (loading || !expandedComputed) {
    return (
      <div className="tree-nodes-loading">
        {new Array(5).fill(null).map((_, i) => (
          <div key={uuid()} className="animated-background" />
        ))}
      </div>
    );
  }

  return (
    <div>
      {driverNodes.map((driver) => renderNode(driver, 0))}
    </div>
  );
}
