// Shared component to add concepts that only require a display name.
import React, { ReactElement, useEffect, useState } from 'react';
import './SimpleAddConceptModal.scss';
import { Modal, Input, Button } from '@mantine/core';
import { conceptNameRule } from 'utils/form-rules';
import { RoleIcon } from 'components/RoleIcon';

interface SimpleAddConceptModalProps {
  conceptType: 'driver' | 'stage' | 'outcome';
  opened: boolean;
  onAdd: (displayName: string) => void;
  onClose: () => void;
}

export function SimpleAddConceptModal({
  conceptType,
  opened,
  onAdd,
  onClose,
}: SimpleAddConceptModalProps): ReactElement {
  const [displayNameValue, setDisplayNameValue] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');
  const handleSubmit = () => {
    if (displayNameValue && conceptNameRule.pattern.test(displayNameValue)) {
      onAdd(displayNameValue);
      setDisplayNameValue('');
      onClose();
    }
  };
  const [touched, setTouched] = useState<boolean>(false);
  useEffect(() => setTouched(false), [opened]);
  useEffect(() => {
    if (!touched) {
      setErrMsg('');
      return;
    }
    if (!displayNameValue) {
      setErrMsg('Required');
    } else if (!conceptNameRule.pattern.test(displayNameValue)) {
      setErrMsg(conceptNameRule.message);
    } else {
      setErrMsg('');
    }
  }, [displayNameValue, touched]);
  return (
    <Modal
      title={`New ${conceptType?.at(0).toUpperCase()}${conceptType?.substring(1)}`}
      opened={opened}
      onClose={() => {
        setDisplayNameValue('');
        onClose();
      }}
      size="400px"
    >
      <Input.Wrapper
        error={errMsg}
      >
        <Input
          icon={conceptType === 'stage' && <RoleIcon conceptRole="stage" />}
          value={displayNameValue}
          data-cy="concept-title-input"
          onChange={(e) => {
            setDisplayNameValue(e.target.value);
            setTouched(true);
          }}
          placeholder={`Enter ${conceptType} name`}
        />
      </Input.Wrapper>
      <div className="action-button-container">
        <Button
          disabled={!displayNameValue || !conceptNameRule.pattern.test(displayNameValue)}
          onClick={handleSubmit}
          data-cy="add-concept-button"
        >
          Add
        </Button>
        <Button
          variant="subtle"
          onClick={() => {
            setDisplayNameValue('');
            onClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
