import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { UnstyledButton, Button, Tooltip, Select, Space, Loader } from '@mantine/core';
import { Badge } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'hooks/reduxHooks';
import { useDeployedDialoguePolicy, useDraftDialoguePolicy, useDialoguePolicyInBranch } from 'hooks/useDialoguePolicy';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { selectUsers } from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { createConfirm } from 'components/ConfirmModal';
import UserTag from 'components/UserTag';
import { User } from 'types';
import { getId } from 'common/resourceName';
import { useApiGet } from 'hooks/network';
import { openNotification } from 'components/Notification';
import { YamlEditor } from '../YamlEditor';
import { PythonEditor } from '../PythonEditor';
import '../dialogPolicy.scss';

export const MASTER_BRANCH_NAME = 'master';

interface IProps { }

export const PolicyEdit: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const customer = useCustomerParams();
  const { currentConfig } = useContext(CustomerConfigContext);
  const apiGet = useApiGet(false);
  // Deployed
  const [deployedSourceCode, deployedConfig, loadingDeploy, buildTime] = useDeployedDialoguePolicy();
  const hasDeployed = !!deployedSourceCode || !!deployedConfig;
  // Master
  const [masterSnapshot, loadingMaster, mergeToMaster] = useDialoguePolicyInBranch(MASTER_BRANCH_NAME);
  const masterSnapshotId = useMemo(() => getId('dialoguePolicySnapshot', masterSnapshot?.name || ''), [masterSnapshot]);
  // Personal draft
  const { sourceCode, config, loading, setSourceCode, setConfig, load, save, timestamp } = useDraftDialoguePolicy();

  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: user.name,
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  useEffect(() => { load(); }, [load]);
  const [diffWith, setDiffWith] = useState<'deployed' | 'master' | ''>('');
  const [currentTab, setCurrentTab] = useState<'master' | 'draft' | 'deployed'>('draft');
  const [editorType, setEditorType] = useState<'python' | 'yaml'>('python');

  const handleClickTab = useCallback((tab: 'master' | 'draft' | 'deployed') => {
    if (tab === 'deployed' && !hasDeployed) {
      return;
    }
    setDiffWith('');
    setCurrentTab(tab);
  }, [hasDeployed]);

  const handleRunSimulator = useCallback(async () => {
    if (currentTab === 'master') {
      const path = `${currentConfig?.customerShortName}/dp/${masterSnapshotId}/validate`;
      const validation = await apiGet(path);
      if (validation?.status !== 'ok') {
        openNotification('error', 'Error', validation?.message);
        throw new Error(validation?.message);
      }
      navigate(`/${customer.path}/simulator/simulation?taskId=&editingMode=master`);
    } else if (currentTab === 'draft') {
      const id = await save();
      if (id) {
        navigate(`/${customer.path}/simulator/simulation?taskId=&editingMode=draft`);
      }
    }
  }, [currentTab, currentConfig, save, navigate, customer.path, masterSnapshotId]);

  const isSimulationRunDisabled = useMemo(
    () => currentTab === 'draft' && (!sourceCode || !config),
    [currentTab, sourceCode, config],
  );

  const confirmResetFn = useCallback(async () => createConfirm<boolean>({
    title: 'Are you sure?',
    content: 'Any unsaved change will be lost!',
    buttons: [
      {
        text: 'Yes!',
        value: true,
      },
      {
        text: 'Cancel',
        buttonProps: {
          variant: 'subtle',
          dataCy: 'button-cancel',
        },
        value: false,
      },
    ],
    width: 300,
  }), []);

  const handleResetToDeployed = useCallback(async () => {
    const yes = await confirmResetFn();
    if (!yes) {
      return;
    }
    if (currentTab === 'master') {
      try {
        await mergeToMaster(deployedSourceCode, deployedConfig);
      } catch (e) {
        openNotification('error', 'Error', `${e}`);
      }
    } else if (currentTab === 'draft') {
      setSourceCode(deployedSourceCode);
      setConfig(deployedConfig);
    }
  }, [currentTab, deployedConfig, deployedSourceCode]);

  const handleResetToMaster = useCallback(async () => {
    const yes = await confirmResetFn();
    if (!yes) {
      return;
    }
    setSourceCode(masterSnapshot?.decompressedSourceCode || '');
    setConfig(masterSnapshot?.decompressedConfig || '');
  }, [masterSnapshot]);

  const confirmPromoteFn = useCallback(async () => createConfirm<boolean>({
    title: 'Are you sure?',
    content: 'This will be merged to Master and shared across all. This action is irreversible.',
    buttons: [
      {
        text: 'Confirm',
        value: true,
      },
      {
        text: 'Cancel',
        buttonProps: {
          variant: 'subtle',
          dataCy: 'button-cancel',
        },
        value: false,
      },
    ],
    width: 300,
  }), []);

  const handlePromoteToMaster = useCallback(async () => {
    const yes = await confirmPromoteFn();
    if (!yes) {
      return;
    }
    await mergeToMaster(sourceCode, config);
  }, [sourceCode, config]);

  const defaultCodeValue = useMemo(() => {
    switch (currentTab) {
      case 'deployed':
        return deployedSourceCode;
      case 'master':
        return masterSnapshot?.decompressedSourceCode || '';
      case 'draft':
        return sourceCode;
      default:
        return '';
    }
  }, [currentTab, deployedSourceCode, masterSnapshot, sourceCode]);

  const originalCodeValue = useMemo(() => {
    switch (diffWith) {
      case 'deployed':
        return deployedSourceCode;
      case 'master':
        return masterSnapshot?.decompressedSourceCode || '';
      default:
        return '';
    }
  }, [diffWith, deployedSourceCode, masterSnapshot]);

  const defaultConfigValue = useMemo(() => {
    switch (currentTab) {
      case 'deployed':
        return deployedConfig;
      case 'master':
        return masterSnapshot?.decompressedConfig || '';
      case 'draft':
        return config;
      default:
        return '';
    }
  }, [currentTab, deployedConfig, masterSnapshot, config]);

  const originalConfigValue = useMemo(() => {
    switch (diffWith) {
      case 'deployed':
        return deployedConfig;
      case 'master':
        return masterSnapshot?.decompressedConfig || '';
      default:
        return '';
    }
  }, [diffWith, deployedConfig, masterSnapshot]);

  return (
    <div className="dialogPolicy-editWrapper">
      <div className="dialogPolicy-editCardWrapper">
        <Tooltip
          label="Current dialogue policy in PROD is not deployed from a Studio snapshot."
          disabled={hasDeployed}
        >
          <div
            onClick={() => handleClickTab('deployed')}
            key="deployed"
            className={classNames([
              'dialogPolicy-editCard',
              currentTab === 'deployed' ? 'active' : undefined,
              hasDeployed ? undefined : 'disabled',
            ])}
          >
            <Badge color="#51CF66" />
            Deployed
            {loadingDeploy && <Loader size="xs" />}
          </div>
        </Tooltip>
        <div
          onClick={() => handleClickTab('master')}
          key="master"
          className={classNames(['dialogPolicy-editCard', currentTab === 'master' ? 'active' : undefined])}
        >
          <Badge color="#FAB005" />
          Master
          {loadingMaster && <Loader size="xs" />}
        </div>
        <div
          onClick={() => handleClickTab('draft')}
          key="draft"
          className={classNames(['dialogPolicy-editCard', currentTab === 'draft' ? 'active' : undefined])}
        >
          <Badge color="#FAB005" />
          Personal draft
          {loading && <Loader size="xs" />}
        </div>
      </div>
      <div className="dialogPolicy-edit">
        <div className="dialogPolicy-editInfo">
          <div role="group">
            <Button onClick={() => setEditorType('python')} variant="subtle" color={editorType === 'python' ? undefined : 'gray'} size="sm" compact>
              Dialog Policy
            </Button>
            <div className="divider" />
            <Button onClick={() => setEditorType('yaml')} variant="subtle" size="sm" color={editorType === 'yaml' ? undefined : 'gray'} compact>
              Config
            </Button>
          </div>
          {currentTab === 'master' && (
            <Button
              disabled={!hasDeployed}
              variant="light"
              onClick={() => {
                if (diffWith) {
                  setDiffWith('');
                } else {
                  setDiffWith('deployed');
                }
              }}
              className="dialogPolicy-editRun"
            >
              {diffWith ? 'Exit comparison' : 'Compare with Deployed'}
            </Button>
          )}
          {currentTab === 'draft' && (
            <Select
              placeholder="Compare with"
              clearable
              data={[
                {
                  value: 'deployed',
                  label: 'Deployed',
                  disabled: !hasDeployed,
                },
                {
                  value: 'master',
                  label: 'Master',
                },
              ]}
              onChange={(v) => setDiffWith(v as 'deployed' | 'master' | '')}
            />
          )}
          {currentTab !== 'deployed' && <Space w="md" />}
          <Button
            disabled={isSimulationRunDisabled}
            onClick={handleRunSimulator}
            className="dialogPolicy-editRun"
          >
            Run Simulator
          </Button>
          <span
            className="dialogPolicy-editTime"
          >
            {currentTab === 'deployed' && `Built at: ${buildTime}`}
            {currentTab === 'master' && (
              <>
                Last change: {masterSnapshot?.formatedCreateTime}
                <Space w="lg" />
                <UserTag
                  name={users.find((user) => user.id === masterSnapshot?.creator)?.full_name || 'Unassigned'}
                />
              </>
            )}
            {currentTab === 'draft' && `Last change: ${timestamp}`}
            <span className="spacer" />
            {currentTab !== 'deployed' && (
              <UnstyledButton
                className={classNames(['dialogPolicy-resetButton', hasDeployed ? undefined : 'disabled'])}
                onClick={handleResetToDeployed}
                disabled={!hasDeployed}
              >
                Reset to Deployed
              </UnstyledButton>
            )}
            {currentTab === 'draft' && (
              <>
                <div className={classNames(['divider', 'withSpace'])} />
                <UnstyledButton
                  className="dialogPolicy-resetButton"
                  onClick={handleResetToMaster}
                >
                  Reset to Master
                </UnstyledButton>
                <div className={classNames(['divider', 'withSpace'])} />
                <UnstyledButton
                  className="dialogPolicy-resetButton"
                  onClick={handlePromoteToMaster}
                  disabled={!sourceCode || !config}
                >
                  Promote to Master
                </UnstyledButton>
              </>
            )}
          </span>
        </div>
        <div className="dialogPolicy-editor">
          {editorType === 'python' ? (
            <PythonEditor
              currentTab={currentTab}
              defaultValue={defaultCodeValue}
              onCodeChange={setSourceCode}
              diff={currentTab !== 'deployed' && !!diffWith}
              diffWith={diffWith}
              originalValue={originalCodeValue}
              options={{
                readOnly: currentTab !== 'draft',
              }}
            />
          ) : (
            <YamlEditor
              currentTab={currentTab}
              defaultValue={defaultConfigValue}
              onCodeChange={setConfig}
              diff={currentTab !== 'deployed' && !!diffWith}
              diffWith={diffWith}
              originalValue={originalConfigValue}
              options={{
                readOnly: currentTab !== 'draft',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
