import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UserApi } from 'services/userApi';
import { CreateUserResponse, User, UserRole } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { OktaAuthContext } from 'context/OktaAuthContext';
import { openNotification } from 'components/Notification';
import { getId } from 'common/resourceName';
import { PageLoader } from 'components/PageLoader';

export interface UserWithId extends User {
  id?: string;
}

export const UserContext = createContext<UserWithId>({
  id: '',
  name: '',
  email: '',
  fullName: '',
  role: UserRole.ROLE_UNSPECIFIED,
});

export const UserContextProvider = ({ children }) => {
  const { data: { email, firstname, lastname, display_name } } = useContext(OktaAuthContext);
  const parent = useCustomerProfile();
  const userRef = useRef<UserWithId>({});
  const [ready, setReady] = useState<boolean>(false);

  const getOrCreateUser = useCallback(async () => {
    setReady(false);
    let response: CreateUserResponse = {};
    const userId = uuidv4();
    const user: User = {
      name: `${parent}/users/${userId}`,
      email,
      fullName: display_name || `${firstname} ${lastname}`,
      role: UserRole.CONVERSATION_ANALYST,
      roleData: { caData: {} },
    };
    try {
      response = await UserApi.createUser({ parent, userId, user });
    } catch (e) {
      openNotification('error', 'Failed to get Studio user', JSON.stringify(e));
    }
    userRef.current = {
      id: getId('user', response.user?.name || ''),
      ...(response.user || {}),
    };
    setReady(true);
  }, [parent, email, firstname, lastname, display_name]);

  useEffect(() => {
    getOrCreateUser();
  }, [parent, email, firstname, lastname, display_name]);

  if (ready) {
    return (
      <UserContext.Provider value={userRef.current}>
        {children}
      </UserContext.Provider>
    );
  }
  return <PageLoader />;
};
