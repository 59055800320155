import classNames from 'classnames';
import React from 'react';
import './PolicyDecision.scss';

export type PolicyDecisionListItemType = 'default' | 'positive' | 'negative';

interface PolicyDecisionListItemProps {
  checkbox?: boolean;
  children?: React.ReactNode | string | number;
  type?: PolicyDecisionListItemType;
  onClick?: () => void;
}

export default function PolicyDecisionListItem({
  checkbox = false,
  children,
  type = 'default',
  onClick = () => {},
}: PolicyDecisionListItemProps) {
  return (
    <div
      className={classNames(
        'policy-decision-list-item',
        type,
        { hoverable: checkbox },
      )}
      onClick={onClick}
    >
      {checkbox && (
        <div className="policy-decision-list-item-checkbox" />
      )}
      <span className="policy-decision-list-item-text">{children}</span>
    </div>
  );
}
