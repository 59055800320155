import React, { ReactElement, useState, useCallback } from 'react';
import './SimpleConceptEditor.scss';
import {
  Concept,
  ConceptConceptSource,
  ConceptState,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Button, Card, Group, Tooltip } from '@mantine/core';
import ConceptTag from 'components/ConceptTag';
import { useForm } from 'antd/lib/form/Form';
import { toRequestUpdateMask } from 'utils';
import { cloneDeep } from 'lodash';
import { useIsSharedLibrary } from 'hooks/useCustomerParams';
import { ConceptForm, EDITABLE_CONCEPT_FIELDS } from '../ConceptForm';
import { ConceptDetail } from '../ConceptDetail';

interface SimpleConceptEditorProps {
  concept: Concept;
  onUpdate: (concept: Concept, updateMask: string) => void;
  showTags?: boolean;
}

export function SimpleConceptEditor({
  concept,
  onUpdate,
  showTags,
}: SimpleConceptEditorProps): ReactElement {
  const [editing, setEditing] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const isSharedLibrary = useIsSharedLibrary();

  // Form to edit the concept.
  const [form] = useForm<Concept>();
  const { getFieldsValue, getFieldsError, isFieldsTouched, isFieldTouched, resetFields } = form;
  const shouldDisableSave = () => {
    const errors = getFieldsError(EDITABLE_CONCEPT_FIELDS);
    const total = errors.map((e) => e.errors.length).reduce((sum, count) => sum + count, 0);
    setDisabledSave(total > 0 || (!isFieldsTouched(EDITABLE_CONCEPT_FIELDS)));
  };

  const handleSave = useCallback(() => {
    const updatedConcept: Concept = getFieldsValue();
    // Reconstruct the concept title since only the last segment is edited in the form.
    const segments = concept.conceptTitle.split('.');
    segments.pop();
    const conceptPrefix = segments.length ? `${segments.join('.')}.` : '';
    updatedConcept.conceptTitle = `${conceptPrefix}${form.getFieldValue('conceptTitle')}`;
    updatedConcept.name = concept.name;

    const needContextFieldValue: 'show' | 'hide' = form.getFieldValue('intent')?.needsContext;
    updatedConcept.intent.needsContext = needContextFieldValue === 'show';

    const updateMask: string[] = [];

    for (const path of EDITABLE_CONCEPT_FIELDS) {
      if (isFieldTouched(path)) {
        updateMask.push(Array.isArray(path) ? path.join('.') : String(path));
      }
    }

    const requestUpdateMask = toRequestUpdateMask(updateMask);

    onUpdate(updatedConcept, requestUpdateMask);
    setEditing(false);
  }, [concept, onUpdate, form]);

  const handleCancel = useCallback(() => {
    resetFields();
    setEditing(false);
  }, [form]);

  const handleDeprecate = useCallback(() => {
    const updatedConcept = cloneDeep(concept);
    updatedConcept.state = ConceptState.DEPRECATED;
    onUpdate(updatedConcept, 'state');
    setEditing(false);
  }, [concept, onUpdate]);

  const isOOB = concept?.conceptSource === ConceptConceptSource.SHARED;
  const isEditDisabled = isOOB && !isSharedLibrary;

  return (
    <Card radius="lg" p="lg">
      {!concept ? (
        <div className="simple-concept-editor-placeholder">Select a concept.</div>
      ) : (
        <>
          <Group position="apart">
            <ConceptTag value={concept.conceptTitle} />
            {!editing && (
              <Tooltip withinPortal label={isEditDisabled ? 'OOB intents cannot be edited' : ''} disabled={!isEditDisabled}>
                <div>
                  <Button
                    variant="subtle"
                    onClick={() => setEditing(true)}
                    disabled={isEditDisabled}
                  >
                    Edit
                  </Button>
                </div>
              </Tooltip>
            )}
            <Group hidden={!editing} spacing="xs">
              <Button
                variant="subtle"
                color="red"
                onClick={handleDeprecate}
              >
                Deprecate
              </Button>
              <Button
                variant="subtle"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={disabledSave}
                onClick={handleSave}
              >
                Save
              </Button>
            </Group>
          </Group>
          {editing ? (
            <ConceptForm
              form={form}
              concept={concept}
              onFieldsChange={shouldDisableSave}
              showTags={showTags}
              setDisabledSave={setDisabledSave}
            />
          ) : (
            <ConceptDetail concept={concept} showTags={showTags} />
          )}
        </>
      )}
    </Card>
  );
}
