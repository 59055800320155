enum PolicyLocalStorageKey {
  RECENT_MODELS = 'policy.recentModels',
  RECENT_RULE_GRAPHS = 'policy.recentModels.ruleGraph',
  LABELING_CHAT_NAME = 'policy.labeling.chatName',
  LABELING_MESSAGE_ID = 'policy.labeling.messageId',
  LABEL_REVIEW_ACTIONS = 'policy.labelReview.actions',
  LABEL_REVIEW_VALUES = 'policy.labelReview.values',
  LABEL_REVIEW_SPLIT = 'policy.labelReview.split',
  LABEL_REVIEW_TASK_IDS = 'policy.labelReview.tasks',
}

export { PolicyLocalStorageKey };
