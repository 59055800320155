import { useState, useEffect } from 'react';
import { FetchLabelingItemsRequest, FetchLabelingItemsResponse } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { stripHTMLFromString } from 'utils';
import { useLabelingData } from './useLabelingData';

export const useLabelingItems = (taskName: string, activeConversationIndex: number): [FetchLabelingItemsResponse, boolean, any] => {
  const [labelingItems, setLabelingItems] = useState<FetchLabelingItemsResponse>();
  const [refetch, toggleRefetch] = useState(false);
  const [labelingData, isLoadingLabelingData] = useLabelingData(taskName, [refetch]);
  const [isLoading, toggleLoading] = useState(activeConversationIndex !== undefined);
  const [error, setError] = useState(null);

  const taskStatus = labelingData?.labelingTask?.abstractTask?.status;

  const isTaskCreating = taskStatus === TaskStatus.TASK_CREATED;
  const isTaskCompleted = taskStatus === TaskStatus.TASK_COMPLETED;

  useEffect(() => {
    // if task is creating, toggle refetch after 1s
    if (isTaskCreating) {
      setTimeout(() => {
        toggleRefetch(!refetch);
      }, 1000);
    }
  }, [isLoadingLabelingData]);

  useEffect(() => {
    if (isLoadingLabelingData || isTaskCreating) return;
    if (!taskName) {
      const err = 'No Task Name Provided to useLabelingItems';
      console.error(err);
      setError(err);
      toggleLoading(false);
      return;
    }
    if (activeConversationIndex === undefined) {
      const err = 'No Active Conversation Index Provided to useLabelingItems';
      console.error(err);
      setError(err);
      toggleLoading(false);
      return;
    }
    const fetchLabelingItems = async () => {
      toggleLoading(true);
      const conversationNumberStart = activeConversationIndex;
      const conversationNumberEnd = conversationNumberStart + 1;
      const fetchLabelingDataRequest: FetchLabelingItemsRequest = {
        taskName,
        conversationNumberStart,
        conversationNumberEnd,
        isPostTaskCompletion: isTaskCompleted,
      };
      try {
        const response = await LabelingTaskApi.fetchLabelingItems(fetchLabelingDataRequest);
        const rawConversationLabelingItems = response.conversationLabelingItems[0];
        const htmlStrippedMessagesAndContexts = (rawConversationLabelingItems?.messagesAndContexts || []).map(
          (m) => ({
            ...m,
            messageContent: stripHTMLFromString(m.messageContent),
          }),
        );
        const conversationLabelingItems = {
          ...rawConversationLabelingItems,
          messagesAndContexts: htmlStrippedMessagesAndContexts,
        };
        const labelingItems: FetchLabelingItemsResponse = {
          ...response,
          conversationLabelingItems: [conversationLabelingItems],
        };

        setLabelingItems(labelingItems);
      } catch (err) {
        setError(err);
      } finally {
        toggleLoading(false);
      }
    };
    fetchLabelingItems();
  }, [taskName, activeConversationIndex, isLoadingLabelingData, isTaskCreating]);

  return [labelingItems, isLoading, error];
};
