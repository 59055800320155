import React from 'react';
import './TaskStatusIndicator.scss';
import classNames from 'classnames';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { Tooltip } from 'antd';
import { TASK_STATUS_MAP } from 'constants/labeling';
import Loading from 'components/Loading';

type TaskStatusIndicatorProps = {
  status: TaskStatus,
  count?: string,
  errorMessage?: string,
  preparing?: boolean,
};

export default function TaskStatusIndicator({ status, count, errorMessage = '', preparing = false }: TaskStatusIndicatorProps) {
  if (preparing) {
    return (
      <div className={classNames(['labeling-task-status', 'preparing'])}>
        <Loading inline={false} size="small"/>
        <span>Creating...</span>
      </div>
    );
  }

  const indicator = (
    <div className={classNames(['labeling-task-status', TASK_STATUS_MAP.get(status)?.className])}>
      <span data-cy="status-value">{`${count || ''}${TASK_STATUS_MAP.get(status)?.text}`}</span>
    </div>
  );

  if (status === TaskStatus.TASK_ERROR) {
    return (
      <Tooltip title={errorMessage}>
        {indicator}
      </Tooltip>
    );
  }

  return indicator;
}
