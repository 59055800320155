import {
  Box,
  Text,
  Paper,
  Flex,
  Divider,
  useMantineTheme,
  Group,
  Button,
  Stack,
  ScrollArea,
  Loader,
  Center,
  ActionIcon,
} from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getErrorMessage } from 'utils';
import { showNotification } from '@mantine/notifications';
import { IPrompt, usePrompts } from './hooks/usePrompts';
import { ListItem } from './components/ListItem';
import { RefreshIcon } from './assets/RefreshIcon';
import { DeployedPrompt } from './components/DeployedPrompt';
import { PromptEditor } from './PromptEditor';

dayjs.extend(relativeTime);

export const Prompts = () => {
  const theme = useMantineTheme();
  const [selectedPromptId, setSelectedPromptId] = useState<string | null>(null);
  const [selectedPromptGroupId, setSelectedPromptGroupId] = useState<string | null>(null);

  const { error, refetch, deployedPrompt, status, listPrompts } = usePrompts();

  useEffect(() => {
    // do not reset form if selectedPromptId is set
    if (selectedPromptId) return;
    if (deployedPrompt) {
      setSelectedPromptId(deployedPrompt.id);
      setSelectedPromptGroupId(deployedPrompt.promptGroupId);
    }
  }, [deployedPrompt]);

  const resetForm = () => {
    setSelectedPromptId(null);
    setSelectedPromptGroupId(null);
  };

  // handle error
  useEffect(() => {
    if (error) {
      const errorMessage = getErrorMessage(error);
      showNotification({
        title: 'Error fetching prompts',
        message: errorMessage,
        color: 'red',
      });
      resetForm();
    }
  }, [error]);

  const onSetPrompt = (prompt: IPrompt) => {
    setSelectedPromptId(prompt.id);
    setSelectedPromptGroupId(prompt.promptGroupId);
  };

  const updateActivePromptIds = (promptId: string, selectedPromptGroupId: string) => {
    setSelectedPromptId(promptId);
    setSelectedPromptGroupId(selectedPromptGroupId);
  };

  return (
    <Box>
      <Paper p="lg" style={{ height: 600, display: 'flex', flexDirection: 'column' }}>
        <Group position="apart" mb="lg">
          <Group>
            <Text>Prompt Management</Text>
            <ActionIcon onClick={() => refetch()}>
              <RefreshIcon />
            </ActionIcon>
          </Group>
          <Group>
            <Button
              disabled={!selectedPromptId}
              onClick={() => {
                resetForm();
              }}
            >
              New
            </Button>
          </Group>
        </Group>
        {status === 'loading' ? (
          <Center style={{ flex: 1 }}>
            <Loader size="xl" />
          </Center>
        ) : (
          <Flex align="stretch" style={{ flex: 1, overflow: 'hidden' }}>
            <Flex direction="column">
              <Text color="dimmed" size="xs">
                Deployed Prompt
              </Text>
              <DeployedPrompt deployedPrompt={deployedPrompt} />
              <Divider my="sm" />
              <Box component={ScrollArea} scrollbarSize={8} w={310}>
                {listPrompts.map((prevPrompt) => (
                  <ListItem
                    key={prevPrompt.id}
                    isSelected={
                      prevPrompt.promptGroupId
                        ? prevPrompt.promptGroupId === selectedPromptGroupId
                        : prevPrompt.id === selectedPromptId
                    }
                    onClick={onSetPrompt}
                    title={prevPrompt.displayName}
                    timestamp={new Date(prevPrompt.updatedAt || prevPrompt.createdAt)}
                    prompt={prevPrompt}
                  />
                ))}
              </Box>
            </Flex>
            <Divider
              mx="lg"
              orientation="vertical"
              style={{ height: 'auto' }}
              color={theme.colors.gray[1]}
            />
            <Stack spacing="xs" style={{ flex: 1 }}>
              <PromptEditor
                promptId={selectedPromptId}
                updateActivePromptIds={updateActivePromptIds}
              />
            </Stack>
          </Flex>
        )}
      </Paper>
    </Box>
  );
};
