import React, { useMemo } from 'react';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import MessageList from 'components/MessageList';
import LabelingMessage from 'components/LabelingMessage';
import { AnnotationsDisplayType, AnnotationValueType } from 'components/Annotations';

interface ChatDisplayProps {
  targetMessageId: string,
  targetMessageConversationId: string,
  messages: Message[],
  messageAnnotationsMap: Map<string, Annotation[]>,
  failedMessages: { [id: string]: boolean },
  getConceptTitle: (conceptId: string) => string;
  setTargetMessage: (message: Message) => void;
  annotationsDisplayType: AnnotationsDisplayType;
  customMessageTags?: (message: Message) => JSX.Element;
  blurredMessageId?: string;
  // For partial chat task types we want to
  // show a range of messages defined by the tuple below ([first shown message, last shown message])
  visibleMessagesRange?: [number, number];
  annotationValueType?: AnnotationValueType;
}

/** Shows either full conversation or partial conversation (based on visibleMessagesCount) */
export default function ChatDisplay({
  targetMessageId,
  targetMessageConversationId,
  messages,
  messageAnnotationsMap,
  failedMessages,
  getConceptTitle,
  setTargetMessage,
  annotationsDisplayType,
  customMessageTags,
  blurredMessageId,
  visibleMessagesRange,
  annotationValueType,
}: ChatDisplayProps) {
  const partialChatMessages = useMemo(() => {
    if (!visibleMessagesRange) {
      return messages;
    }
    const trailing = visibleMessagesRange[0];
    const leading = visibleMessagesRange[1];
    const highlightedMessageIndex = messages.findIndex((message) => message?.v2MessageId === targetMessageId);
    return messages.slice(Math.max(0, highlightedMessageIndex - trailing), highlightedMessageIndex + leading + 1);
  }, [messages, targetMessageId, visibleMessagesRange]);

  // Set scroll to first message if no message scroll id
  const firstMessageId = messages[0]?.v2MessageId;

  const focusScrollMessageId = useMemo(() => {
    const messageId = targetMessageId || firstMessageId;
    return `${targetMessageConversationId}${messageId}`;
  }, [targetMessageConversationId, targetMessageId, firstMessageId]);

  return (
    <div className="chat-display">
      <MessageList
        focusScrollId={focusScrollMessageId}
        focusedVerticalPosition="center"
      >
        {partialChatMessages.map((message) => {
          const messageId = message?.v2MessageId;
          const conversationId = message?.v2ConversationId;
          const messageAnnotations = messageAnnotationsMap.get(messageId);
          const isSelected = targetMessageId === messageId && targetMessageConversationId === conversationId;
          const isBlurred = blurredMessageId === messageId && targetMessageConversationId === conversationId;
          const error = failedMessages[messageId] && 'Failed to save labels';
          return (
            <LabelingMessage
              scrollId={`${targetMessageConversationId}${messageId}`}
              key={`${targetMessageConversationId}${messageId}`}
              annotations={messageAnnotations}
              message={message}
              handleClick={() => setTargetMessage(message)}
              isFocused={isSelected}
              isSelected={isSelected}
              selectable={message.isTargetMessage}
              annotationsDisplayType={annotationsDisplayType}
              customTags={customMessageTags && customMessageTags(message)}
              showSpeaker
              getConceptTitle={getConceptTitle}
              error={error}
              isBlur={isBlurred}
              annotationValueType={annotationValueType}
            />
          );
        })}
      </MessageList>
    </div>
  );
}
