import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { Annotation, AnnotationValueType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { EdgeEdgeConditionType, FlowNodesCollection } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { getId } from 'common/resourceName';
import { UITagType } from '..';
import { Stage } from '../StagesProvider';

interface ActiveMessageInfo {
    setId: string;
    conversationId: string;
    messageId: string;
}

export const filterAnnotationsForMessageByTagId = (
  tagId: string,
  {
    setId,
    conversationId,
    messageId,
  }: ActiveMessageInfo,
) => (annotation: Annotation) => {
  const isCurrentSet = annotation?.value?.setId === setId;
  const isCurrentConversation = annotation?.rawData?.messageRawData?.conversationId === conversationId;
  let isCurrentMessage = false;
  let isCurrentTag = false;
  // handle Stage tag
  if (annotation?.valueType === AnnotationValueType.TYPE_CONVERSATION_STAGE) {
    isCurrentMessage = annotation?.value?.conversationStageValue?.stageBeginMessageId === messageId;
    isCurrentTag = annotation?.value?.conversationStageValue?.stageConceptId === tagId;
  } else {
    isCurrentMessage = annotation?.rawData?.messageRawData?.messageId === messageId;
    isCurrentTag = annotation?.value?.binaryValue?.conceptId === tagId;
  }
  const isTagToFilter = isCurrentSet && isCurrentConversation && isCurrentMessage && isCurrentTag;

  return isTagToFilter;
};

export const getTagDataById = (tagId: string, tags: UITagType[]) => tags.find((tag) => tag.value === tagId);

const deriveStageEndLabelFromStageStartLabel = (stageStartLabel: string) => {
  const stageStartLabelParts = stageStartLabel.split('.');
  // remove the last part of the stage start label
  stageStartLabelParts.pop();
  // add the stage end label
  stageStartLabelParts.push('end');
  return stageStartLabelParts.join('.');
};

export const getStageEndIdFromStageBeginId = (stageBeginTagId: string, tags: UITagType[]) => {
  const beginTag = getTagDataById(stageBeginTagId, tags);
  const stageEndLabel = deriveStageEndLabelFromStageStartLabel(beginTag.label);
  const stageEndTag = tags.find((tag) => tag.label === stageEndLabel);
  const stageEndId = stageEndTag?.value;
  return stageEndId;
};

/**
 * Converts concept to flow nodes collection with tag type
 */
export const toFlowNodesCollection = (concept: Concept, tagType?: TagTagType): FlowNodesCollection => {
  const flowNodesCollection: FlowNodesCollection = {
    conceptName: concept.name,
    nextEdge: {
      conditionType: EdgeEdgeConditionType.NEXT_IMMEDIATE,
    },
  };

  if (tagType) {
    flowNodesCollection.tagType = tagType;
  }

  return flowNodesCollection;
};

/**
 * Filter predicted flow nodes based on message type
 */
export const filterPredictedFlowNodes = (flowNodes: FlowNodesCollection[], message: Message, tempStage?: Stage): FlowNodesCollection[] => {
  let filterTypes: TagTagType[] = [];

  const stages = [
    TagTagType.STAGE,
    TagTagType.STAGE_BEGIN,
    TagTagType.STAGE_END,
  ];

  if (message.actorType === ActorType.VISITOR) {
    filterTypes = [
      TagTagType.VISITOR_INTENT,
      TagTagType.DRIVER,
      TagTagType.CONVERSATION_OUTCOME,
      ...stages,
    ];
  } else if (message.actorType === ActorType.AGENT) {
    filterTypes = [
      TagTagType.AGENT_INTENT,
      TagTagType.CONVERSATION_OUTCOME,
      ...stages,
    ];
  }

  const filtered = flowNodes.filter((node) => {
    if (tempStage) {
      const isDisabled = tempStage.stageId && node.tagType === TagTagType.STAGE && getId('concept', node.conceptName) !== tempStage.stageId;
      if (isDisabled) return false;
    } else if (!tempStage && node.tagType === TagTagType.STAGE_END) {
      return false;
    }

    return filterTypes.includes(node.tagType);
  });
  // Cap the amount of predicted nodes
  return filtered.slice(0, 4);
};
