import React, { FC, useCallback, useMemo } from 'react';
import { Conversation as TypeConversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { SimulationTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { Button, UnstyledButton } from '@mantine/core';
import { Button as AntButton, Radio } from 'antd';
import { toTitleCase, truncateMiddle } from 'utils';
import { getId } from 'common/resourceName';
import UserTag from 'components/UserTag';
import CopyableValue from 'components/CopyableValue';
import dayjs from 'dayjs';
import { selectUsers } from 'store/user/selectors';
import { useSelector } from 'hooks/reduxHooks';
import useUrlParam from 'hooks/useUrlParam';
import { User } from 'types';
import { MomentAnnotationDetailedType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { SimulatorResultConvoTable } from './SimulatorResultConvoTable';
import { SimulatorIntentTable } from './SimulatorIntentTable';
import { SimulatorResultIntentTable } from './SimulatorResultIntentTable';
import styles from './SimulatorComparedResult.module.scss';
import { SimulatorResultUtteranceTableWrapper } from './SimulatorResultUtteranceTableWrapper';

interface Props {
  simulationTask: SimulationTask;
  conversations: TypeConversation[];
  handleOpenDrawer: () => void;
  handleBack: () => void;
  handleTabChange: (activeKey: string) => void;
}
export const SimulatorComparedResult: FC<Props> = ({
  simulationTask,
  conversations,
  handleOpenDrawer,
  handleBack,
  handleTabChange,
}) => {
  const [tableType, setTableType] = useUrlParam<string>('tableType', 'Utterance');
  const [, setConversationId] = useUrlParam<string>('conversationId');

  const users = useSelector(selectUsers).map((user) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const compareData = useMemo(() => {
    const rawData = simulationTask?.taskData?.predictionSummary;
    return {
      total: { predictions: rawData?.total?.predictionCount, 'Unique intents': rawData?.total?.uniqueIntentCount, 'total Convos': rawData?.total?.conversationCount },
      same: { predictions: rawData?.same?.predictionCount, 'Unique intents': rawData?.same?.uniqueIntentCount, 'total Convos': rawData?.same?.conversationCount },
      added: { predictions: rawData?.added?.predictionCount, 'Unique intents': rawData?.added?.uniqueIntentCount, 'total Convos': rawData?.added?.conversationCount },
      removed: { predictions: rawData?.removed?.predictionCount, 'Unique intents': rawData?.removed?.uniqueIntentCount, 'total Convos': rawData?.removed?.conversationCount },
    };
  }, [simulationTask]);

  const intentData = useMemo(() => {
    const rawData = simulationTask?.taskData?.predictionSummary;
    const conceptRoles = {
      [MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT]: 'agent',
      [MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER]: 'driver',
      [MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT]: 'agent',
      [MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT]: 'visitor',
    };
    return rawData?.momentPredictions.map((convo) => ({
      name: convo.prediction.taxonomy,
      conceptRole: conceptRoles[convo.prediction.momentDetailedType] || '', // 'agent'|'visitor'|'stage'|'driver'|''
      type: convo.prediction.momentDetailedType,
      same: convo.samePredictionCount,
      added: convo.addedPredictionCount,
      removed: convo.removedPredictionCount,
    }));
  }, [simulationTask]);

  const convoData = useMemo(() => {
    const rawData = simulationTask?.taskData?.predictionSummary;
    return !rawData?.conversationPredictionCounts ? [] : rawData.conversationPredictionCounts.map((convo) => ({
      convoId: convo.conversationId,
      convoDate: convo.conversationTime && dayjs(convo.conversationTime).format('MM/DD/YYYY h:mm a'),
      same: {
        predictions: convo.same.predictionCount,
        intents: convo.same.uniqueMomentCount,
      },
      added: {
        predictions: convo.added.predictionCount,
        intents: convo.added.uniqueMomentCount,
      },
      removed: {
        predictions: convo.removed.predictionCount,
        intents: convo.removed.uniqueMomentCount,
      },
    }));
  }, [simulationTask.name]);

  const SimulatorResultTable = useCallback(() => {
    switch (tableType) {
      case 'Utterance':
        return (
          <SimulatorResultUtteranceTableWrapper
            simulationTaskName={simulationTask?.name}
            conversations={conversations}
          />
        );
      case 'Detection':
        return <SimulatorIntentTable simulationTask={simulationTask} />;
      case 'Intent':
        return <SimulatorResultIntentTable tableData={intentData} />;
      case 'Convo':
        return (
          <SimulatorResultConvoTable
            tableData={convoData}
            onRowClick={(convoId) => {
              const convo = conversations.find((c) => getId('conversation', c.name) === convoId);
              if (convo) {
                setTableType('Utterance');
                setConversationId(convoId);
              }
            }}
          />
        );
      default:
        return <></>;
    }
  }, [tableType, conversations]);

  const SimulatorDashboard = (key: string, data: { predictions: number, ['Unique intents']: number, ['total Convos']: number }) => (
    <div key={key} className={styles.simulatorDashboardCard}>
      <span data-type={key} className={styles.simulatorDashboardCardTitle}>{key}</span>
      <div className={styles.simulatorDashboardCardBody}>
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <div aria-label="value">{value}</div>
            <div aria-label="key">{key}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const taskId = simulationTask?.abstractTask?.workflowId;

  return (
    <div className={styles.simulatorResult}>
      <div className={styles.simulatorResultHeader}>
        <div className={styles.simulatorResultTitle}>
          <UnstyledButton className={styles.active}>Simulator</UnstyledButton>
          <UnstyledButton onClick={() => handleTabChange('history')}>History</UnstyledButton>
        </div>
        <Button
          onClick={handleBack}
          className={styles.simulatorModelSetupButton}
        >
          Change setup
        </Button>
        <div className={styles.simulatorResultCompare}>
          <div className={styles.simulatorResultCompareTitle}>
            <span className={styles.simulatorResultCompareLabel}>Task:</span>
            <CopyableValue
              displayValue={truncateMiddle(taskId, 0, 6)}
              copiedValue={taskId}
            />
            &nbsp;&nbsp;
            {toTitleCase(simulationTask?.taskData?.simulationType)}
          </div>
          {simulationTask?.abstractTask?.assigneeUserId && (
          <div className={styles.simulatorResultCompareCreator}>
            <span className={styles.simulatorResultCompareLabel}>Creator:</span>
            <UserTag name={users.find((u) => u.id === simulationTask?.abstractTask?.assigneeUserId)?.full_name} />
          </div>
          )}
          {simulationTask?.abstractTask?.endTime && (
          <div className={styles.simulatorResultCompareTime}>
            <span className={styles.simulatorResultCompareLabel}>Date completed:</span>
            {dayjs(simulationTask.abstractTask.endTime).format('MM/DD/YYYY h:mm a')}
          </div>
          )}
          <AntButton onClick={handleOpenDrawer} type="link">See comparing models </AntButton>
        </div>
        <div className={styles.simulatorDashboard}>
          {Object.entries(compareData).map(([key, data]) => SimulatorDashboard(key, data))}
        </div>
      </div>
      <div className={styles.buttonsGroup}>
        <Radio.Group
          onChange={(event) => setTableType(event.target.value)}
          value={tableType}
          options={['Utterance', 'Detection', 'Intent', 'Convo']}
          optionType="button"
        />
      </div>
      <SimulatorResultTable />
    </div>
  );
};
