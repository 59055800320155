import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { useCallback, useMemo } from 'react';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';

export function useConversationSelect(conversations: Conversation[]): [
  conversationId: string,
  currentIndex: number,
  conversation: Conversation,
  setConversationId: (id: string) => void,
  nextConversation: () => void,
  prevConversation: () => void,
] {
  const [conversationId, setConversationId] = useUrlParam<string>('conversationId');
  const [currentConversationIndex, conversation] = useMemo(() => {
    if (!conversations.length) {
      return [0, null];
    }
    if (!conversationId) {
      return [0, conversations[0]];
    }
    const index = conversations.findIndex((conversation) => getId('conversation', conversation.name || '') === conversationId);
    return index >= 0 ? [index, conversations[index]] : [0, null];
  }, [conversations, conversationId]);

  const handlePreviousConversation = useCallback(() => {
    if (currentConversationIndex > 0) {
      setConversationId(getId('conversation', conversations[currentConversationIndex - 1].name));
    }
  }, [conversations, setConversationId, currentConversationIndex]);

  const handleNextConversation = useCallback(() => {
    if (currentConversationIndex < conversations?.length - 1) {
      setConversationId(getId('conversation', conversations[currentConversationIndex + 1].name));
    }
  }, [conversations, setConversationId, currentConversationIndex]);

  return [
    conversationId,
    currentConversationIndex,
    conversation,
    setConversationId,
    handleNextConversation,
    handlePreviousConversation,
  ];
}
