import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import './CustomerPicker.scss';
import { Cascader } from 'components/Cascader';
import { Box, Drawer, SelectItem, TextInput } from '@mantine/core';
import { ListActiveConfigsResponseConfig as CustomerConfig } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { composeCustomerUrl, useCustomerParams, CustomerParams } from 'hooks/useCustomerParams';
import { sortBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { toTitleCase } from 'utils';
import { signInPopup } from 'common/auth';

// Minimum search value length for search filter to kick in (inclusive)
const MIN_SEARCH_LEN = 2;

interface SelectItemWithCustomer extends SelectItem {
  languageCode?: string;
  config?: CustomerConfig;
  isActive?: boolean;
  isExpanded?: boolean;
  parent?: SelectItemWithCustomer;
  chidlren?: SelectItemWithCustomer[];
}

/** Value is a string of the form "customer_id/profile_id/usecase_id/languageCode" */
export function flattenCustomerConfigs(customerConfigs: CustomerConfig[]): SelectItem[] {
  const items: SelectItem[] = [];
  customerConfigs.forEach((config) => {
    const { customerId, profileId, usecaseId, defaultLanguageCode, supportedLanguageCodes, customerShortName } = config;
    let langs = supportedLanguageCodes || [];
    if (defaultLanguageCode && !langs.includes(defaultLanguageCode)) {
      langs = [defaultLanguageCode, ...langs];
    }
    for (const languageCode of langs) {
      items.push({
        value: `${customerId}/${profileId}/${usecaseId}/${languageCode}`,
        label: `${toTitleCase(customerShortName, '-')} (${usecaseId}, ${languageCode})`,
      });
    }
  });
  return items;
}

/** Value is a string of the form "customer_id/profile_id/usecase_id" */
export function flattenCustomerUsecases(customerConfigs: CustomerConfig[]): SelectItem[] {
  const items: SelectItem[] = [];
  customerConfigs.forEach((config) => {
    const { customerId, profileId, usecaseId, customerShortName } = config;
    items.push({
      value: `${customerId}/${profileId}/${usecaseId}`,
      label: `${toTitleCase(customerShortName, '-')} (${usecaseId})`,
    });
  });
  return items;
}

/** Turn flat list of customer configs into a nested tree (customers->profiles->usescases->langs->config) */
export function getCustomerConfigsTree(customers: CustomerConfig[], searchValue: string, activeCustomer: CustomerParams) {
  const configs = {};

  const sortedCustomers = sortBy(customers, ((customer) => {
    const isActiveCustomer = activeCustomer.customerId === customer.customerId;

    if (isActiveCustomer) {
      // Return as first customer
      return 'AAA';
    } else {
      return customer.customerId;
    }
  }));
  sortedCustomers.forEach((customer) => {
    const { customerId, profileId, usecaseId, supportedLanguageCodes } = customer;
    configs[customerId] = configs[customerId] || {};
    configs[customerId][profileId] = configs[customerId][profileId] || {};
    configs[customerId][profileId][usecaseId] = configs[customerId][profileId][usecaseId] || {};
    supportedLanguageCodes.forEach((languageCode) => {
      configs[customerId][profileId][usecaseId][languageCode] = configs[customerId][profileId][usecaseId][languageCode] || {
        value: languageCode,
        label: languageCode,
        config: customer,
      };
    });
  });

  const configsSelectItems: SelectItemWithCustomer[] = [];
  const lowercaseSearchValue = searchValue.toLowerCase().trim();
  const isSearching = lowercaseSearchValue.length > MIN_SEARCH_LEN;

  // TODO: Refactor this
  // Customer ids
  Object.keys(configs).forEach((customerId) => {
    const isActive = customerId === activeCustomer.customerId;
    const isExpanded = isActive || (customerId.includes(lowercaseSearchValue) && isSearching);
    const customerItem: SelectItemWithCustomer = {
      value: customerId,
      label: customerId,
      children: [],
      isActive,
      isExpanded,
    };
    configsSelectItems.push(customerItem);
    const profiles = configs[customerId];
    // Profile ids
    Object.keys(profiles).forEach((profileId) => {
      const isActive = profileId === activeCustomer.profileId && customerItem.isActive;
      const isExpanded = isActive || (profileId.includes(lowercaseSearchValue) && isSearching);
      if (isExpanded) {
        customerItem.isExpanded = true;
      }
      const profileItem: SelectItemWithCustomer = {
        value: profileId,
        label: profileId,
        children: [],
        config: configs[customerId][profileId]?.config,
        isActive,
        isExpanded,
        parent: customerItem,
      };
      customerItem.children.push(profileItem);
      const usecaseIds = configs[customerId][profileId];
      // Usecase ids
      Object.keys(usecaseIds).forEach((usecaseId) => {
        const isActive = usecaseId === activeCustomer.usecaseId && profileItem.isActive;
        const isExpanded = isActive || (usecaseId.includes(lowercaseSearchValue) && isSearching);
        if (isExpanded) {
          profileItem.isExpanded = true;
        }
        const usecaseItem: SelectItemWithCustomer = {
          value: usecaseId,
          label: usecaseId,
          children: [],
          isActive,
          isExpanded,
          parent: profileItem,
        };
        profileItem.children.push(usecaseItem);
        const langs = configs[customerId][profileId][usecaseId];
        // Langs
        Object.keys(langs).forEach((languageCode) => {
          const isActive = languageCode === activeCustomer.languageCode && usecaseItem.isActive;
          const langItem: SelectItemWithCustomer = {
            value: languageCode,
            label: languageCode,
            children: [],
            config: configs[customerId][profileId][usecaseId][languageCode]?.config,
            languageCode,
            isActive,
          };
          usecaseItem.children.push(langItem);
        });
      });
    });
  });
  return configsSelectItems;
}

interface CustomerPickerProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  toggleSidebar: (value: boolean) => void;
}

export default function CustomerPicker({
  opened,
  setOpened,
  toggleSidebar,
}: CustomerPickerProps) {
  const { allConfigs } = useContext(CustomerConfigContext);
  const customerParams = useCustomerParams();
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const searchInput = useRef<HTMLInputElement>();
  const location = useLocation();

  const { pathname } = location;
  const splitPath = pathname.split('/');
  // The 5th element of the url is the page route
  const makePath = (customer: CustomerParams) => [customer.path, splitPath[5]].join('/');

  useEffect(() => {
    // Don't wait for search value to be more than MIN_SEARCH_LEN, open customers list immediately
    if (searchValue?.length > 0 && !opened) {
      setOpened(true);
    }
  }, [searchValue]);

  const filteredCustomers = useMemo(() => {
    const searchValueLowercase = searchValue.toLowerCase();
    if (searchValue.length > MIN_SEARCH_LEN) {
      return allConfigs.filter((customer) => {
        const customerNameAndProfile = `${customer.customerId} ${customer.profileId}`.toLowerCase();
        return customerNameAndProfile.includes(searchValueLowercase);
      });
    }

    return allConfigs;
  }, [searchValue, allConfigs]);

  const nestedTreeConfigs = useMemo(
    () => getCustomerConfigsTree(filteredCustomers, searchValue, customerParams),
    [filteredCustomers, searchValue, customerParams.path],
  );
  const [selectedCustomerConfig, setSelectedCustomerConfig] = useState<SelectItemWithCustomer>(null);

  const handleSetCustomer = useCallback(async (customerItem: SelectItemWithCustomer) => {
    const { customerId, profileId, usecaseId } = customerItem.config;
    setSelectedCustomerConfig(customerItem);
    setOpened(false);
    toggleSidebar(false);

    const customer: CustomerParams = {
      customerId,
      profileId,
      usecaseId,
      languageCode: customerItem.languageCode,
      path: composeCustomerUrl({
        customerId,
        profileId,
        usecaseId,
        languageCode: customerItem.languageCode,
      }),
    };
    // If switching customers, re-auth for new token exchange.
    if (customerParams.customerId !== customerId) {
      const auth = await signInPopup(customerId, profileId);
      // If signInPopup fails to authenticate, it will trigger signOut within the function, so we only navigate to a new customer on successful auth.
      if (!auth) {
        return navigate(window.location.pathname);
      }
    }
    return navigate(`/${makePath(customer)}`);
  }, [setSelectedCustomerConfig]);

  return (
    <>
      <TextInput
        ref={searchInput}
        style={{ width: '100%' }}
        value={searchValue}
        placeholder="Search customer"
        onClick={() => setOpened(true)}
        onChange={(e) => setSearchValue(e.target.value)}
        autoFocus
        autoComplete="off"
      />
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        size={400}
        zIndex={150}
        withOverlay={false}
        transitionProps={{
          transition: {
            in: {
              transform: 'translateX(0)',
              opacity: 1,
              transitionDuration: '0.1s',
            },
            out: {
              transform: 'translateX(-100%)',
              opacity: 0,
              transitionDuration: '0s',
            },
            transitionProperty: 'transform, opacity',
          },
        }}
        styles={{
          inner: {
            left: 240,
          },
          content: {
            overflowX: 'hidden',
          },
          header: {
            display: 'none',
          },
        }}
      >
        <Box p="lg">
          <Cascader
            options={nestedTreeConfigs}
            value={selectedCustomerConfig}
            setValue={handleSetCustomer}
          />
        </Box>
      </Drawer>
    </>
  );
}
