import React, { useEffect, useState, useContext } from 'react';
import { Button, Collapse, Group, MultiSelect, Stack, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { DataGrid } from 'components/DataGrid';
import { ColumnDef } from '@tanstack/react-table';
import { getAccessToken } from 'common/auth';
import { useApiPost } from 'hooks/network';
import CopyableValue from 'components/CopyableValue';
import { truncateMiddle } from 'utils';
import { showNotification } from '@mantine/notifications';
import { useSelector } from 'hooks/reduxHooks';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { selectAllConcepts } from 'store/concept/selectors';
import { getId } from 'common/resourceName';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { StudioApi } from 'services/studioApi';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { CalendarIcon, TimeIcon } from '../icons';

// fixes issue with js Date.toISOString() creating a string that our python backend can't parse with datetime.fromisoformat()
// https://linear.app/cresta/issue/STU-4119/versioning-iso-string-parsing-error
function toISOWithoutMSAndZ(date: Date) {
  return `${date.toISOString().split('.')[0]}`;
}

interface Count {
  label: string;
  task_created_at: string;
  task_id: string;
  test_negative: number;
  test_positive: number;
  train_negative: number;
  train_positive: number;
}

const RevertAnnotationsPreviewTable = ({
  timeValue,
  toggleShowResults,
}: {
  timeValue: Date;
  toggleShowResults: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isLoading, toggleLoading] = useState(true);
  const [data, setData] = useState<Count[]>([]);
  const { currentConfig } = useContext(CustomerConfigContext);
  const { customerId, profileId, usecaseId, languageCode } = useCustomerParams();
  const apiPost = useApiPost(true, true);
  const [submittingDeprecationRequest, toggleDeprecationRequest] = useState(false);
  const [conceptIds, setConceptIds] = useState<string[]>([]);
  const allConcepts = useSelector(selectAllConcepts);

  const handleDeprecateTasks = async () => {
    toggleDeprecationRequest(true);
    try {
      await apiPost('deprecate_annotations', {
        after: toISOWithoutMSAndZ(timeValue),
        concept_ids: conceptIds,
      });

      showNotification({
        color: 'green',
        message: 'Deprecation successful',
      });
      toggleShowResults(false);
      toggleDeprecationRequest(false);
    } catch (error) {
      console.error('error', error);
      showNotification({
        color: 'red',
        message: 'Deprecation failed',
      });
      toggleDeprecationRequest(false);
    }
  };

  useEffect(() => {
    if (!currentConfig) {
      return;
    }
    toggleLoading(true);
    fetch(
      `${StudioApi.getPythonApiEndpoint(currentConfig)}/${customerId}/${profileId}/${usecaseId}/${languageCode}/get_annotation_counts_for_deprecation?after=${toISOWithoutMSAndZ(timeValue)}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: getAccessToken(),
        }),
      },
    )
      .then((response) => response.json())
      .then((json) => {
        const counts = json.counts || ([] as Count[]);
        setData(counts);
        toggleLoading(false);
      })
      .catch((error) => {
        console.error('error', error);
        showNotification({
          color: 'red',
          message: 'Reading QA label counts failed',
        });
        toggleLoading(false);
      });
  }, []);

  const columns: ColumnDef<unknown>[] = [
    {
      header: 'Task ID',
      accessorKey: 'task_id',
      cell: (info) => {
        const taskId = info.getValue() as string;
        return <CopyableValue displayValue={truncateMiddle(taskId)} copiedValue={taskId} />;
      },
    },
    {
      header: 'Created at',
      accessorKey: 'task_created_at',
    },
    {
      header: 'Label',
      accessorKey: 'label',
    },
    {
      header: 'Train positive',
      accessorKey: 'train_positive',
    },
    {
      header: 'Train negative',
      accessorKey: 'train_negative',
    },
    {
      header: 'Test positive',
      accessorKey: 'test_positive',
    },
    {
      header: 'Test negative',
      accessorKey: 'test_negative',
    },
  ];

  const filterUnique = (value: Count, index: number, self: Count[]) =>
    self.findIndex((item) => item.label === value.label) === index;

  const getConceptByLabel = (label: string) => {
    const concept = allConcepts.find((concept) => concept.conceptTitle === label);
    if (!concept) {
      showNotification({
        color: 'red',
        message: 'Concept not found',
      });
      return null;
    }
    return getId('concept', concept?.name);
  };
  return (
    <>
      <DataGrid
        style={{ margin: '0 -16px' }}
        isLoading={isLoading}
        columns={columns}
        dataSource={data}
      />
      <Text>Do you want to deprecate these annotations? This cannot be undone.</Text>
      <Group>
        <Button
          color="red"
          onClick={handleDeprecateTasks}
          loading={submittingDeprecationRequest}
          disabled={data.length === 0}
        >
          Yes, continue
        </Button>
        <MultiSelect
          placeholder="Selected concepts"
          disabled={data.length === 0}
          data={data
            .filter(filterUnique)
            .map((item) => ({ label: item.label, value: getConceptByLabel(item.label) }))}
          onChange={setConceptIds}
        />
        <Button variant="subtle" onClick={() => toggleShowResults(false)}>
          No, cancel
        </Button>
      </Group>
    </>
  );
};

export const AnnotationReversion = () => {
  const [showResults, toggleShowResults] = useState(false);
  const [dateTimePickerValue, setDateTimePickerValue] = useState<Date>(null);

  useEffect(() => {
    if (showResults) {
      toggleShowResults(false);
    }
  }, [dateTimePickerValue]);

  return (
    <Stack>
      <Text>Revert annotations any labeling tasks made after this timestamp</Text>
      <Group align="end">
        <DateTimePicker
          disabled={showResults}
          valueFormat={DATE_TIME_FORMAT}
          label="Pick date and time"
          placeholder="Pick date and time"
          value={dateTimePickerValue}
          onChange={setDateTimePickerValue}
          rightSection={<CalendarIcon />}
          timeInputProps={{
            rightSectionWidth: 80,
            rightSection: (
              <Group spacing="xs">
                <Text color="dimmed">PT</Text>
                <TimeIcon />
              </Group>
            ),
          }}
        />
        {showResults || (
          <>
            <Button onClick={() => toggleShowResults(true)} disabled={!dateTimePickerValue}>
              Run
            </Button>
            <Button
              variant="subtle"
              onClick={() => {
                setDateTimePickerValue(null);
              }}
            >
              Clear
            </Button>
          </>
        )}
      </Group>
      <Collapse in={showResults}>
        <Stack>
          {showResults && (
            <RevertAnnotationsPreviewTable
              timeValue={dateTimePickerValue}
              toggleShowResults={toggleShowResults}
            />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};
