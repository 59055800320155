import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SendNotificationRequest,
} from '@cresta/web-client/dist/cresta/v1/studio/notification/notification_service.pb';
import { NotificationApi } from 'services/notificationApi';
import { openNotification } from 'components/Notification';
import moment from 'moment';

enum Action {
  NOTIFICATION_SEND_NOTIFICATION = 'NOTIFICATION_SEND_NOTIFICATION',
}

/** AsyncThunk for SendNotification API. */
export const sendNotification = createAsyncThunk<void, SendNotificationRequest>(Action.NOTIFICATION_SEND_NOTIFICATION, async (request: SendNotificationRequest) => {
  try {
    // Skip slack notification in testing profile because it will mention the AI delivery team.
    if (request.profile.includes('chat-demo') || request.profile.includes('chat-sandbox')) {
      return;
    }
    const response = await NotificationApi.sendNotification(request);
    const sendTime = moment.utc(response.sentTime).local().format('YYYY-MM-DD hh:mm a');
    openNotification('success', `Notification sent at ${sendTime}`);
  } catch (err) {
    openNotification('error', 'Failed to send notification', undefined, err);
    throw err;
  }
});
