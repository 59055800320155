import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { ApiStatus } from 'store/types';

/** Concept state. */
export interface State {
  conversations: Conversation[],
  status: ApiStatus,
  // Current conversation messages in chronological order.
  conversationMessages: Message[],
}

/** Initial concept state. */
export const INITIAL_STATE: State = {
  conversations: [],
  status: 'idle',
  conversationMessages: [],
};
