import React, { createContext } from 'react';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';

interface TaggingPageProviderValues {
    tagsInProfile: UITagType[]
    taskConceptIds: string[]
    conceptIdsOnCurrentMessage: string[]
    annotations: Annotation[]
    removeTagFromMessage: (tagId: string, messageIndex?: number) => Promise<void>;
    // Required by notes, tagging
    taskName: string
    // Required by notes component
    currentSetId: string,
    activeConversationIndex: number
    activeConversationId: string
    // Required by Tagging component
    handleCreateAnnotationResponse: (annotations: Annotation[]) => void
    onUpdateTagIdsInTask: (tagIds: string[]) => void
    activeSetId: string
    removeAnnotationIds: (annotationIds: string[]) => Promise<void>
    activeMessage: {
      id: string
      index: number
      actorType: ActorType
    }
}

export const TaggingPageContext = createContext<TaggingPageProviderValues>(null);

interface UITagType {
  value: string
  label: string
  tagType: TagTagType
}

interface TaggingPageProviderProps {
  children: React.ReactNode
  value: TaggingPageProviderValues
}

export const TaggingPageProvider = ({ value, children }: TaggingPageProviderProps) => (
  <TaggingPageContext.Provider value={value}>{children}</TaggingPageContext.Provider>
);
