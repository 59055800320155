import {
  NotificationService,
  SendNotificationRequest,
  SendNotificationResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/notification/notification_service.pb';
import { StudioApi } from './studioApi';

export abstract class NotificationApi {
  public static sendNotification(request: SendNotificationRequest): Promise<SendNotificationResponse> {
    return NotificationService.SendNotification(request, StudioApi.getHeaders().initReq);
  }
}
