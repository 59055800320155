import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
  functionalUpdate,
} from '@tanstack/react-table';
import { Checkbox, Group, LoadingOverlay, Paper, Stack, Table, TableProps, Text, useMantineTheme } from '@mantine/core';
import { NoDataIcon, SortableIcon, SortedAscendingIcon, SortedDescendingIcon } from './Icons';
import { Pagination } from './Pagination';

interface DataGridProps<T> {
    style?: React.CSSProperties;
    columns: ColumnDef<T, any>[];
    dataSource: T[];
    initialPageSize?: number;
    tableProps?: TableProps;
    isLoading?: boolean;
    onRowClick?: (row: T, index: number) => void;
    rowSelection?: {
      selectedRowKeys: React.Key[];
      onChange: (selectedRowKeys: React.Key[]) => void;
      disabledRowAccessorFn?: (row: T) => boolean;
      disabledCheckBoxValue?: boolean;
    };
}
// This component combines Mantine styling with React Table functionality
// The Example used to create this component was found here: https://tanstack.com/table/v8/docs/examples/react/sorting
// For further information on react-table see: https://tanstack.com/table/v8/docs/adapters/react-table
export const DataGrid = <T extends unknown>({
  style,
  columns: columnsRaw,
  dataSource,
  tableProps = {},
  isLoading,
  onRowClick,
  initialPageSize = 10,
  rowSelection,
}: DataGridProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const theme = useMantineTheme();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>({});
  useEffect(() => {
    const newRowSelectionState = rowSelection?.selectedRowKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {});
    if (!isEqual(newRowSelectionState, rowSelectionState)) {
      setRowSelectionState(newRowSelectionState);
    }
  }, [rowSelection?.selectedRowKeys]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  // adds select column if rowSelection is enabled
  const columns = useMemo(() => {
    if (rowSelection) {
      return [
        {
          id: 'selection',
          header: () => (
            <Checkbox
              size="xs"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ),
          cell: ({ row }) => {
            const isDisabled = rowSelection?.disabledRowAccessorFn?.(row.original) ?? false;
            return (
              <Checkbox
                size="xs"
                disabled={isDisabled}
                checked={isDisabled ? rowSelection?.disabledCheckBoxValue : row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
              />
            );
          },
          width: 40,
        },
        ...columnsRaw,
      ];
    }
    return columnsRaw;
  }, [rowSelection, columnsRaw, dataSource]);

  const onRowSelectionChange = useCallback((updater: Updater<RowSelectionState>) => {
    const oldRowSelectionState = rowSelection?.selectedRowKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {});
    const newRowSelectionState = functionalUpdate(updater, oldRowSelectionState);
    if (isEqual(oldRowSelectionState, newRowSelectionState)) return;
    setRowSelectionState(newRowSelectionState);
    const selectedRowKeys = Object.keys(newRowSelectionState).filter((key) => newRowSelectionState[key]);
    rowSelection?.onChange(selectedRowKeys);
  }, [rowSelection]);

  const table = useReactTable({
    data: dataSource,
    columns,
    state: {
      rowSelection: rowSelection ? rowSelectionState : undefined,
      pagination,
      sorting,
      expanded,
    },
    getRowId: (row, index) => (row as { id: string }).id ?? `${index}`,
    onRowSelectionChange,
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    // @ts-ignore-next-line
    getSubRows: (row) => row.subRows,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const hasFooter = table.getFooterGroups()[0].headers.some((header) => header.column.columnDef.footer);

  // extract withBorder from tableProps
  const { withBorder, ...restTableProps } = tableProps;

  return (
    <Stack spacing={0} style={style}>
      <Paper
        radius="lg"
        sx={(theme) => ({
          border: withBorder ? `1px solid ${theme.colors.gray[3]}` : 'none',
          overflow: 'hidden',
        })}
      >
        <Table
          verticalSpacing="md"
          horizontalSpacing="md"
          highlightOnHover
          {...restTableProps}
        >
          {(isLoading || dataSource.length === 0) && (
          <caption style={{ position: 'relative' }}>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <Stack align="center" my="xl">
              <NoDataIcon />
              <Text size="md" color={theme.colors.gray[2]}>No Data</Text>
            </Stack>
          </caption>
          )}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                    header.column.getCanSort()
                      ? 'cursor-pointer select-none data-clickable'
                      : ''
                    }
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <Group position="apart" noWrap>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanSort() && (!!header.column.getIsSorted() || <SortableIcon />)}
                        {{
                          asc: <SortedAscendingIcon />,
                          desc: <SortedDescendingIcon />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </Group>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {!isLoading && (
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row, index) => (
                <tr key={row.id} onClick={onRowClick ? () => onRowClick(row.original, index) : undefined}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
          )}

          {hasFooter && !isLoading && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
          )}
        </Table>
      </Paper>
      {table.getPageCount() > 1 && <Pagination table={table} />}
    </Stack>
  );
};
