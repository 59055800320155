import React from 'react';
import { ActionIcon, Box, ButtonStylesParams, createStyles, Group, Text } from '@mantine/core';
import { TrashCanIcon } from './icons';

const useStyles = createStyles((theme, params) => {
  const radius = 'md';

  const transarency = 0.6;
  return {
    root: {
      ...theme.fn.fontStyles(),
      ...theme.fn.focusStyles(),
      display: 'block',
      width: '100%',
      borderRadius: theme.fn.radius(radius),
      position: 'relative',
      lineHeight: 1,
      userSelect: 'none',
      cursor: 'pointer',
      ...theme.fn.variant({ variant: 'light' }),
      color: theme.black,
      // set fill for svg icons
      '& svg': {
        height: 18,
        width: 18,
        padding: 2,
        fill: theme.colors.gray[4],
        '&:hover': {
          fill: theme.colors.gray[6],
        },
      },

      '&:hover': {
        backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.fn.rgba(theme.colors.dark[7], 0.5)
              : theme.fn.lighten(theme.colors.blue[1], transarency),
      },
      '&[data-active="true"]': {
        // otherwise top/bottom borders are not visible
        margin: '1px 0',
        outline: '1px solid',
        outlineColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      },

      '&:disabled, &[data-disabled]': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.fn.lighten(theme.colors.blue[0], transarency),
        color: theme.fn.lighten(theme.black, transarency),
        cursor: 'not-allowed',
        backgroundImage: 'none',
        pointerEvents: 'none',

        '& svg': {
          fill: theme.fn.lighten(theme.colors.gray[4], transarency),
        },
        // handle sub-label styles
        '& .sub-label': {
          color: theme.fn.lighten(theme.colors.gray[4], transarency),
        },

        '&:active': {
          transform: 'none',
        },
        '&[data-active="true"]': {
          outlineColor: theme.fn.lighten(theme.colors[theme.primaryColor][theme.fn.primaryShade()], transarency),
        },
      },

      '&[data-loading]': {
        pointerEvents: 'none',

        '&::before': {
          content: '""',
          position: 'absolute',
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.fn.rgba(theme.colors.dark[7], 0.5)
              : 'rgba(255, 255, 255, .5)',
          borderRadius: theme.fn.radius(radius),
          cursor: 'not-allowed',
        },
      },

    },
  };
});

interface CardListItemProps extends Partial<ButtonStylesParams> {
    label?: string
    subLabel?: string
    noIcon?: boolean
    disabled?: boolean
    selected?: boolean
    onClick?: () => void
    onDelete?: () => void
}

export const CardListItem = (props:CardListItemProps) => {
  const { label = '', subLabel = '', noIcon = false, disabled, selected = false, onClick, onDelete } = props;
  const { classes } = useStyles();

  return (
    <Box
      className={classes.root}
      px="xs"
      py={5}
      style={{ width: '100%' }}
      data-disabled={disabled}
      data-active={selected}
      onClick={onClick}
    >
      <Group position="apart" noWrap>
        <Text
          size="sm"
          style={{
            wordBreak: 'break-word',
          }}
        >{label}
        </Text>
        {noIcon || <ActionIcon size="sm" variant="transparent" onClick={onDelete}><TrashCanIcon /></ActionIcon>}
      </Group>
      <Text size="sm" color="dimmed" className="sub-label">{subLabel}</Text>
    </Box>

  );
};
