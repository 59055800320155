/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Annotation, AnnotationValue, AnnotationValuePresenceType, AnnotationValueType, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Button, Space, Textarea, Text, Box, Group, Divider, useMantineTheme, ScrollArea } from '@mantine/core';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType, SuggestionActionPayloadSuggestionResult } from '@cresta/web-client/dist/cresta/ai_service/common';
import './SuggestionsDecision.scss';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { getId } from 'common/resourceName';
import { Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { cloneDeep } from 'lodash';
import { SuggestionBox } from 'components/LabelingItemsDisplay/SuggestionBox';
import { FetchCandidateSuggestionTextsResponseCannedSuggestion } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { useHotkeys } from '@mantine/hooks';
import RubricGroup from '../RubricGroup';
import { SuggestionsAutocomplete } from './SuggestionsAutocomplete';

interface SuggestionTempState {
  text: string;
  predictionId: string;
  modelUri: string;
  rubrics: Rubric[];
  rubricsScores: number[];
  rubricsTags: string[][];
  note: string;
  predictionIndex: number;
  resultIndex: number;
}

interface SuggestionsDecisionSingleModelProps {
  message: Message,
  contextShown: MessageRawDataContextShown,
  createAnnotation: (
    messageRawData: MessageRawData,
    value: AnnotationValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => void;
  hasSecondModel: boolean;
  predictions: Prediction[];
  annotations: Annotation[];
  selectedSuggestionIndex: number,
  setSelectedSuggestionIndex: (index: number) => void,
  nextChat: () => void;
  rubrics: Rubric[];
  cannedSuggestions?: FetchCandidateSuggestionTextsResponseCannedSuggestion[];
  setOverviewConceptId: (id: string) => void;
}

const DEFAULT_OPTIMAL_SUGGESTION: FetchCandidateSuggestionTextsResponseCannedSuggestion = {
  cannedSuggestionDocName: undefined,
  cannedSuggestionDocId: undefined,
  suggestionText: '',
};

export function SuggestionsDecisionSingleModel({
  message,
  contextShown,
  createAnnotation,
  submitAnnotations,
  hasSecondModel,
  predictions,
  annotations,
  selectedSuggestionIndex,
  setSelectedSuggestionIndex,
  nextChat,
  rubrics = [],
  cannedSuggestions = [],
  setOverviewConceptId,
}: SuggestionsDecisionSingleModelProps) {
  const theme = useMantineTheme();
  const [commentNote, setCommentNote] = useState('');
  const [suggestionsTempState, setSuggestionsTempState] = useState<SuggestionTempState[]>([]);
  const [isOptimalSuggestionSelection, setIsOptimalSuggestionSelection] = useState(false);
  const [optimalSuggestion, setOptimalSuggestion] = useState<FetchCandidateSuggestionTextsResponseCannedSuggestion>({ ...DEFAULT_OPTIMAL_SUGGESTION });
  const [suggestionsAutocompleteVisible, setSuggestionsAutocompleteVisible] = useState(false);

  const isConversationLabeled = annotations?.length > 0;
  const hasSuggestions = suggestionsTempState?.length > 0;

  // All predictions suggestions flattened
  const allSuggestionResults: SuggestionActionPayloadSuggestionResult[] = useMemo(() => {
    const results: SuggestionActionPayloadSuggestionResult[] = [];
    predictions.forEach((prediction) => {
      (prediction.action?.suggestionPayload?.results || []).forEach((result) => {
        results.push(result);
      });
    });
    return results;
  }, [predictions]);

  // Canned (candidate) suggestions + previously added optimal suggestions
  const filteredCannedSuggestions: FetchCandidateSuggestionTextsResponseCannedSuggestion[] = useMemo(() => {
    const defaultSuggestions = suggestionsTempState.map((suggestion) => ({
      suggestionText: suggestion.text,
    }));
    if (optimalSuggestion.suggestionText?.length > 1) {
      const lowercaseText = optimalSuggestion.suggestionText.toLowerCase();
      return [...defaultSuggestions, ...cannedSuggestions].filter((suggestion) => suggestion.suggestionText.toLowerCase().includes(lowercaseText));
    } else {
      return defaultSuggestions;
    }
  }, [cannedSuggestions, optimalSuggestion?.suggestionText]);

  // Reset comment note on suggestions change
  useEffect(() => {
    setCommentNote(suggestionsTempState[selectedSuggestionIndex]?.note || '');
  }, [selectedSuggestionIndex]);

  // Save note to temporary suggestion state
  useEffect(() => {
    const suggestionsTempStateCopy = cloneDeep(suggestionsTempState);
    if (suggestionsTempStateCopy[selectedSuggestionIndex]) {
      suggestionsTempStateCopy[selectedSuggestionIndex].note = commentNote;
      setSuggestionsTempState(suggestionsTempStateCopy);
    }
  }, [commentNote]);

  // Reset local state on message change
  useEffect(() => {
    setCommentNote('');
    setOptimalSuggestion({ ...DEFAULT_OPTIMAL_SUGGESTION });
    setIsOptimalSuggestionSelection(false);
    setSelectedSuggestionIndex(0);
  }, [message]);

  // Set optimal suggestion local state
  const optimalSuggestionAnnotation = useMemo(() => annotations.find((annotation) => annotation.value.predictionValue), [message, annotations]);
  useEffect(() => {
    if (optimalSuggestionAnnotation) {
      setOptimalSuggestion({
        suggestionText: optimalSuggestionAnnotation.value.predictionValue.optimalPredictionResult.textResult,
        cannedSuggestionDocName: optimalSuggestionAnnotation.value.predictionValue.optimalPredictionResult.cannedSuggestionDocName,
        cannedSuggestionDocId: undefined,
      });
    }
  }, [optimalSuggestionAnnotation?.name]);

  // Map of path and annotations, where path is prediction + result id
  const rubricAnnotationsMap = useMemo(() => {
    const annotationsMap = new Map<string, Annotation[]>();
    annotations.forEach((annotation) => {
      if (annotation.value.rubricValue) {
        const { predictionId, suggestionPosition } = annotation.rawData.messageRawData;
        const annotationPath = `${predictionId}:${suggestionPosition}`;
        const rubricAnnotations = annotationsMap.get(annotationPath) || [];
        annotationsMap.set(annotationPath, [...rubricAnnotations, annotation]);
      }
    });

    return annotationsMap;
  }, [annotations]);

  // Create local state of suggestions and rubrics from annotations (if any) that can be changed
  // and later converted to annotations
  useEffect(() => {
    const updatedSuggestionsTempState: SuggestionTempState[] = [];
    let suggestionIndex = 0;
    predictions.forEach((prediction, predictionIndex) => {
      (prediction.action?.suggestionPayload?.results || []).forEach((result, resultIndex) => {
        // If the selected message hasn't changed, we only recreate existing state
        let suggestionTempState = null;
        if (
          suggestionsTempState[suggestionIndex]?.predictionId === getId('prediction', prediction.name)
          && suggestionsTempState[suggestionIndex]?.resultIndex === resultIndex
        ) {
          suggestionTempState = suggestionsTempState[suggestionIndex];
        }
        const state: SuggestionTempState = {
          text: result.text,
          predictionId: getId('prediction', prediction.name),
          modelUri: result.ensembleModelId,
          rubrics,
          rubricsScores: suggestionTempState?.rubricsScores || new Array(rubrics.length).fill(0),
          rubricsTags: suggestionTempState?.rubricsTags || [],
          note: suggestionTempState?.note || '',
          predictionIndex,
          resultIndex,
        };

        // Set note annotation if exists
        const noteAnnotation = annotations.find((annotation) => {
          const isThisPrediction = annotation.rawData.messageRawData.predictionId === getId('prediction', prediction.name);
          const isThisSuggestion = annotation.rawData.messageRawData.suggestionPosition === resultIndex;
          const isTextType = annotation.value.textValue;
          return isThisPrediction && isThisSuggestion && isTextType;
        });
        if (noteAnnotation) {
          state.note = noteAnnotation.value.textValue.value;
        }

        const suggestionPath = `${getId('prediction', prediction.name)}:${resultIndex}`;
        const rubricAnnotations = rubricAnnotationsMap.get(suggestionPath);
        // Set rubric annotation if exists
        if (rubricAnnotations?.length) {
          rubricAnnotations.forEach((rubricAnnotation) => {
            const annotationRubricIndex = state.rubrics.findIndex((rubric) => rubric.name.split('/')?.[1] === rubricAnnotation.value.rubricValue?.rubricId);
            state.rubricsScores[annotationRubricIndex] = rubricAnnotation.value.rubricValue.score;
            state.rubricsTags[annotationRubricIndex] = rubricAnnotation.value.rubricValue.rubricTags || [];
          });
        }

        updatedSuggestionsTempState.push(state);
        suggestionIndex += 1;
      });
    });
    setSuggestionsTempState(updatedSuggestionsTempState);
  }, [message, rubrics, annotations, predictions]);

  // Save annotations
  const confirmDecisionSingle = useCallback(() => {
    const annotations: SaveTemporalAnnotationRequestRawDataValueTuple[] = [];

    suggestionsTempState.forEach((suggestion) => {
      const annotationMessageRawData: MessageRawData = {
        conversationId: message.conversationId,
        messageId: message.messageId,
        v2ConversationId: message.v2ConversationId,
        v2MessageId: message.v2MessageId,
        contextShown,
        spanStart: message.targetSpanStart,
        spanEnd: message.targetSpanEnd,
        predictionId: suggestion.predictionId,
        suggestionPosition: suggestion.resultIndex,
      };
      // Add note annotation for each suggestion
      const value: AnnotationValue = {
        presenceType: AnnotationValuePresenceType.PRESENCE,
        type: AnnotationValueType.TYPE_TEXT,
        textValue: {
          value: suggestion.note,
        },
      };
      const annotation = createAnnotation(annotationMessageRawData, value);

      annotations.push(annotation);

      suggestion.rubrics.forEach((rubric, rubricIndex) => {
        // Add rubric annotation for each rubric
        const value: AnnotationValue = {
          presenceType: AnnotationValuePresenceType.PRESENCE,
          type: AnnotationValueType.TYPE_RUBRIC,
          rubricValue: {
            rubricId: rubric.name.split('/')?.[1],
            modelUri: suggestion.modelUri,
            score: suggestion.rubricsScores[rubricIndex],
            rubricTags: suggestion.rubricsTags[rubricIndex],
          },
        };

        const annotation = createAnnotation(annotationMessageRawData, value);

        annotations.push(annotation);
      });
    });

    // Add optimal annotation
    const value: AnnotationValue = {
      presenceType: AnnotationValuePresenceType.PRESENCE,
      type: AnnotationValueType.TYPE_PREDICTION,
      predictionValue: {
        optimalPredictionResult: {
          textResult: optimalSuggestion?.suggestionText,
          // ID and name is flipped here (name is empty and id is name)
          cannedSuggestionDocName: optimalSuggestion?.cannedSuggestionDocId,
        },
      },
    };

    const annotation = createAnnotation({
      conversationId: message.conversationId,
      messageId: message.messageId,
      v2ConversationId: message.v2ConversationId,
      v2MessageId: message.v2MessageId,
      contextShown,
      spanStart: message.targetSpanStart,
      spanEnd: message.targetSpanEnd,
    }, value);

    annotations.push(annotation);

    submitAnnotations(annotations, 'message');
  }, [suggestionsTempState, createAnnotation, optimalSuggestion]);

  // Select rubric score and update local state
  const selectScore = useCallback((suggestionIndex: number, rubricsIndex: number, score: number) => {
    const suggestionsTempStateCopy = cloneDeep(suggestionsTempState);
    suggestionsTempStateCopy[suggestionIndex].rubricsScores[rubricsIndex] = score;
    setSuggestionsTempState(suggestionsTempStateCopy);
  }, [suggestionsTempState]);

  // Add or remove selected tags
  const selectTag = useCallback((suggestionIndex: number, rubricsIndex: number, tagName: string) => {
    const suggestionsTempStateCopy = cloneDeep(suggestionsTempState);
    if (suggestionsTempStateCopy[suggestionIndex].rubricsTags[rubricsIndex].includes(tagName)) {
      const toBeRemovedIndex = suggestionsTempStateCopy[suggestionIndex].rubricsTags[rubricsIndex].findIndex((tag) => tag === tagName);
      suggestionsTempStateCopy[suggestionIndex].rubricsTags[rubricsIndex].splice(toBeRemovedIndex, 1);
    } else {
      suggestionsTempStateCopy[suggestionIndex].rubricsTags[rubricsIndex].push(tagName);
    }
    setSuggestionsTempState(suggestionsTempStateCopy);
  }, [suggestionsTempState]);

  const handleConfirm = useCallback(() => {
    // If no suggestions, save optimal prediction
    if (!hasSuggestions) {
      confirmDecisionSingle();
    }

    // Go to next suggestion
    const nextSuggestion = suggestionsTempState[selectedSuggestionIndex + 1];
    if (nextSuggestion) {
      // Proceed to next suggestion
      setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
    } else if (isOptimalSuggestionSelection) {
      // Save annotations
      confirmDecisionSingle();
    } else {
      setIsOptimalSuggestionSelection(true);
    }
  }, [hasSecondModel, suggestionsTempState, selectedSuggestionIndex, confirmDecisionSingle, isOptimalSuggestionSelection]);

  // Hotkeys
  useHotkeys([
    ['s', () => {
      nextChat();
    }],
    ['c', () => {
      handleConfirm();
    }],
  ]);

  // Check if all rubrics have scores
  const canConfirm = useMemo(() => {
    if (suggestionsTempState?.length === 0) return true;

    return suggestionsTempState[selectedSuggestionIndex]?.rubricsScores.every((score) => score > 0);
  }, [suggestionsTempState]);

  return (
    <div className="suggestions-decision">
      <ScrollArea style={{ height: '100%' }}>
        <Box p="lg">
          {!hasSuggestions || isOptimalSuggestionSelection ? (
            <Box style={{ position: 'relative' }}>
              <Text size="md" weight={500}>What would be the optimal suggestion for this message? (optional)</Text>
              <Space h="xs"/>
              <SuggestionsAutocomplete
                visible={suggestionsAutocompleteVisible}
                setVisible={setSuggestionsAutocompleteVisible}
                suggestion={optimalSuggestion}
                suggestions={filteredCannedSuggestions}
                setSuggestion={setOptimalSuggestion}
              />
            </Box>
          ) : (
            <>
              {hasSuggestions && (
                <>
                  <Text size="md" weight={500} >Fill out below scores</Text>
                  <Text size="sm" mt="sm" mb="xs">Suggestion {selectedSuggestionIndex + 1} of {allSuggestionResults.length}</Text>
                  {suggestionsTempState[selectedSuggestionIndex] && (
                  <SuggestionBox
                    text={suggestionsTempState[selectedSuggestionIndex]?.text}
                    active
                    type="single-model"
                  />
                  )}
                  <Space h="md" />
                  {suggestionsTempState[selectedSuggestionIndex] && (
                    suggestionsTempState[selectedSuggestionIndex]?.rubrics.map((rubric, rubricIndex) => {
                      const suggestion = suggestionsTempState[selectedSuggestionIndex];
                      const score = suggestion?.rubricsScores[rubricIndex];
                      const isOpened = score !== 0;
                      return (
                        <Box mb="sm" key={rubric.name}>
                          <RubricGroup
                            opened={isOpened}
                            description={isOpened ? rubric.numericRubric.scoreGuidelines[score] : null}
                            title={rubric.rubricTitle}
                            concepts={[]}
                            selectScore={(value) => selectScore(selectedSuggestionIndex, rubricIndex, value)}
                            selectTag={(value) => selectTag(selectedSuggestionIndex, rubricIndex, value)}
                            updateExplanation={(value) => {}}
                            activeScore={suggestion?.rubricsScores[rubricIndex]}
                            hasExplanation={false}
                            maxScore={rubric.numericRubric.maxScore}
                            rubricTags={suggestion?.rubrics[rubricIndex].availableTags}
                            activeTags={suggestion?.rubricsTags[rubricIndex]}
                            infoButtonHandler={() => setOverviewConceptId(rubric?.name.split('/')?.[1])}
                          />
                        </Box>
                      );
                    })
                  )}
                  <div className="note">
                    <Text size="md" weight={500}>Notes</Text>
                    <Space h="xs"/>
                    <Textarea placeholder="Any comments or notes?" value={commentNote} onChange={(e) => setCommentNote(e.currentTarget.value)}/>
                  </div>
                  <Space h="md" />
                </>
              )}
            </>
          )}
        </Box>
      </ScrollArea>
      <Box>
        <Divider color={theme.colors.gray[1]} m={0}/>
        <Group grow p="lg">
          <Button
            variant="light"
            onClick={nextChat}
          >Skip (S)
          </Button>
          <Button
            variant="light"
            color="green"
            disabled={!canConfirm}
            data-active={canConfirm && isConversationLabeled}
            data-cy="confirm-button"
            onClick={handleConfirm}
          >Confirm (C)
          </Button>
        </Group>
      </Box>
    </div>
  );
}
