import React, { ReactElement, ReactNode } from 'react';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  PredictionsDiff,
} from '@cresta/web-client/dist/cresta/v1/studio/storage/prediction/prediction.pb';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getPredictionType, getPredictionName } from './utils';

interface Props {
  diff?: PredictionsDiff;
}

export function RegressionTaxonomyTable({ diff }: Props): ReactElement {
  if (!diff || !diff.convDiffs) {
    return <></>;
  }

  // Map from moment/action type (e.g., INTENT) -> dict with old and new consisting of taxonomy items.
  const items: {
    [type: string]: {
      new: Set<string>,
      old: Set<string>,
    }
  } = {};
  const addItem = (type: string, col: 'old' | 'new', name: string) => {
    if (!(type in items)) {
      items[type] = {
        new: new Set<string>(),
        old: new Set<string>(),
      };
    }
    items[type][col].add(name);
  };

  Object.values(diff.convDiffs).forEach((convDiff) => {
    if (convDiff.msgDiffs === undefined) {
      return;
    }
    convDiff.msgDiffs.forEach((msgDiff) => {
      if (msgDiff.predsOnlyInOld !== undefined) {
        msgDiff.predsOnlyInOld.forEach((pred) => {
          addItem(getPredictionType(pred.output), 'old', getPredictionName(pred.output));
        });
      }
      if (msgDiff.predsInBoth !== undefined) {
        msgDiff.predsInBoth.forEach((pred) => {
          addItem(getPredictionType(pred.output), 'old', getPredictionName(pred.output));
          addItem(getPredictionType(pred.output), 'new', getPredictionName(pred.output));
        });
      }
      if (msgDiff.predsOnlyInNew !== undefined) {
        msgDiff.predsOnlyInNew.forEach((pred) => {
          addItem(getPredictionType(pred.output), 'new', getPredictionName(pred.output));
        });
      }
    });
  });

  interface TableRow {
    type: string;
    onlyOld: ReactNode;
    both: ReactNode;
    onlyNew: ReactNode;
  }
  const columns: ColumnsType<TableRow> = [
    { title: 'Type', dataIndex: 'type', sorter: (a, b) => a.type.localeCompare(b.type), defaultSortOrder: 'ascend' },
    { title: 'Old predictions', dataIndex: 'onlyOld' },
    { title: 'Common predictions', dataIndex: 'both' },
    { title: 'New predictions', dataIndex: 'onlyNew' },
  ];

  const rows: TableRow[] = [];
  Object.entries(items).forEach(
    ([type, v]) => {
      const intersection = new Set(
        Array.from(v.old).filter((x) => v.new.has(x)),
      );

      rows.push({
        type,
        onlyOld: (
          <>
            {Array.from(v.old).map((x) => (intersection.has(x) ? <></> : <Tag icon={<CloseCircleOutlined />} color="error" key={x}>{x}</Tag>))}
          </>
        ),
        both: (
          <>
            {Array.from(intersection).map((x) => <Tag icon={<CheckCircleOutlined />} color="success" key={x}>{x}</Tag>)}
          </>
        ),
        onlyNew: (
          <>
            {Array.from(v.new).map((x) => (intersection.has(x) ? <></> : <Tag icon={<ExclamationCircleOutlined />} color="warning" key={x}>{x}</Tag>))}
          </>
        ),
      });
    },
  );
  return <Table title={() => 'Prediction taxonomy'} columns={columns} dataSource={rows} size="middle" />;
}
