import React, { useMemo } from 'react';
import { Text, Paper, Group, Button, useMantineTheme, Divider, Stack, RingProgress, LoadingOverlay, Badge, Skeleton, Checkbox } from '@mantine/core';
import { ModelArtifact, ModelArtifactStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { Model } from 'store/modelBuilder/state';

interface StatusBarProps {
  modelArtifact: ModelArtifact;
  servingModelsMap: Map<string, Model>;
  loading: boolean;
  submitting?: boolean;
  step?: number;
  onSubmit: () => void;
  canDeploy?: boolean;
}

type ModelUriType = 'not_found' | 'prod' | 'new' | 'removed';

interface ModelUriDict {
  [key: string]: {
    type: ModelUriType,
    uri: string
  }
}

export default function StatusBar({
  modelArtifact,
  servingModelsMap,
  loading,
  submitting,
  step = 0,
  onSubmit,
  canDeploy,
}: StatusBarProps) {
  const theme = useMantineTheme();
  const intentPolicyModelDeploymentUnit = modelArtifact?.deploymentArtifact?.inputs.intentPolicyModelDeploymentUnit;

  const modelUris = useMemo(() => {
    const agentServingModelUri = servingModelsMap?.get('agent')?.url;
    const visitorServingModelUri = servingModelsMap?.get('visitor')?.url;
    const driverServingModelUri = servingModelsMap?.get('driver')?.url;
    const policyServingModelUri = servingModelsMap?.get('policy')?.url;

    const {
      agentIntentModelUri,
      visitorIntentModelUri,
      chatDriverModelUri,
      dialoguePolicyModelUri,
    } = intentPolicyModelDeploymentUnit || {};

    const uris: ModelUriDict = {
      agent: {
        type: 'not_found',
        uri: '',
      },
      visitor: {
        type: 'not_found',
        uri: '',
      },
      driver: {
        type: 'not_found',
        uri: '',
      },
      policy: {
        type: 'not_found',
        uri: '',
      },
    };

    // Prod (serving) model uris
    if (agentServingModelUri) {
      uris.agent.type = 'prod';
      uris.agent.uri = agentServingModelUri;
    }

    if (visitorServingModelUri) {
      uris.visitor.type = 'prod';
      uris.visitor.uri = visitorServingModelUri;
    }

    if (driverServingModelUri) {
      uris.driver.type = 'prod';
      uris.driver.uri = driverServingModelUri;
    }

    if (policyServingModelUri) {
      uris.policy.type = 'prod';
      uris.policy.uri = policyServingModelUri;
    }

    // Removed model uris
    if (agentServingModelUri && !agentIntentModelUri) {
      uris.agent.type = 'removed';
      uris.agent.uri = '';
    }

    if (visitorServingModelUri && !visitorIntentModelUri) {
      uris.visitor.type = 'removed';
      uris.visitor.uri = '';
    }

    if (driverServingModelUri && !driverServingModelUri) {
      uris.driver.type = 'removed';
      uris.driver.uri = '';
    }

    if (policyServingModelUri && !dialoguePolicyModelUri) {
      uris.policy.type = 'removed';
      uris.policy.uri = '';
    }

    // New model uris
    if (agentIntentModelUri && agentIntentModelUri !== agentServingModelUri) {
      uris.agent.type = 'new';
      uris.agent.uri = agentIntentModelUri;
    }
    if (visitorIntentModelUri && visitorIntentModelUri !== visitorServingModelUri) {
      uris.visitor.type = 'new';
      uris.visitor.uri = visitorIntentModelUri;
    }
    if (chatDriverModelUri && chatDriverModelUri !== driverServingModelUri) {
      uris.driver.type = 'new';
      uris.driver.uri = chatDriverModelUri;
    }
    if (dialoguePolicyModelUri && dialoguePolicyModelUri !== policyServingModelUri) {
      uris.policy.type = 'new';
      uris.policy.uri = dialoguePolicyModelUri;
    }

    return uris;
  }, [servingModelsMap, intentPolicyModelDeploymentUnit]);

  const renderModelUri = (uri: string, uriType: ModelUriType) => {
    let typeElement = null;
    if (uriType !== 'not_found') {
      typeElement = <Badge radius="sm" px={4} sx={{ fontWeight: 500 }}>{uriType}</Badge>;
    }

    return (
      <>
        {typeElement}
        <Text color={theme.colors.gray[3]}>{uri || '-'}</Text>
      </>
    );
  };

  const kwargs = modelArtifact?.deploymentArtifact?.inputs.kwargs as { [key: string]: string };
  const hasAdvancedOptions = kwargs?.skip_regression_test_check || kwargs?.skip_taxonomy_compatibility_check;

  return (
    <Paper p="lg" my="lg">
      <Group position="apart" noWrap>
        {loading ? (
          <Stack>
            <Skeleton height={30} width={160}/>
            <Skeleton height={22} width={500}/>
            <Skeleton height={22} width={350}/>
          </Stack>
        ) : (
          <Stack spacing="sm">
            <Text size="xl" weight="medium">New deployment set up</Text>
            <Group>
              <Text>Agent model:</Text>
              {renderModelUri(modelUris.agent.uri, modelUris.agent.type)}
              <Divider sx={{ height: '20px' }} orientation="vertical" color={theme.colors.gray[0]} />
              <Text>Visitor model:</Text>
              {renderModelUri(modelUris.visitor.uri, modelUris.visitor.type)}
            </Group>
            <Group>
              <Text>Chat driver model:</Text>
              {renderModelUri(modelUris.driver.uri, modelUris.driver.type)}
              <Divider sx={{ height: '20px' }} orientation="vertical" color={theme.colors.gray[0]} />
              <Text>Dialog policy</Text>
              {renderModelUri(modelUris.policy.uri, modelUris.policy.type)}
            </Group>
            <Group noWrap>
              <Text>Commit message:</Text><Text sx={{ width: 600 }} color={theme.colors.gray[3]}>{modelArtifact?.deploymentArtifact?.outputs.commitMessage || '-'}</Text>
            </Group>
            {hasAdvancedOptions && (
              <Stack>
                <Group>
                  <Text>Advanced options:</Text>
                </Group>
                <Group>
                  <Text>Skip regression test:</Text><Checkbox readOnly checked={!!kwargs?.skip_regression_test_check} />
                  <Text>Skip taxonomu compatiblity check:</Text><Checkbox readOnly checked={!!kwargs?.skip_taxonomy_compatibility_check} />
                </Group>
              </Stack>
            )}
          </Stack>
        )}
        {canDeploy && (
          <Group px="lg">
            <Divider sx={{ height: '80px' }} orientation="vertical" color={theme.colors.gray[0]} mr="lg" />
            <RingProgress
              size={36}
              thickness={3}
              sections={[
                {
                  value: step * 25,
                  color: 'blue',
                },
              ]}
            />
            <Text>{step}/4 Complete</Text>
            <Group style={{ position: 'relative' }}>
              <Button disabled={step !== 4 || modelArtifact?.status !== ModelArtifactStatus.ACTIVE} onClick={() => onSubmit()} loading={submitting}>Deploy</Button>
              {
                step !== 4 && (
                  <LoadingOverlay loader={<span />} visible overlayOpacity={0.2} />
                )
              }
            </Group>
          </Group>
        )}
      </Group>
    </Paper>
  );
}
