/* eslint-disable no-nested-ternary */
import React from 'react';
import { Group, Loader, Stack, TextInput } from '@mantine/core';
import { ConversationDurationSlider } from 'components/ConversationDurationSlider';
import { DatePickerInput } from '@mantine/dates';
import * as SetCard from '../components';
import { SetData } from '../..';

interface CreatedCardProps {
    index: number;
    nameError: string;
    isNameErrorChecking?: boolean;
    conversationFilterError: string;
    isConversationsErrorChecking?: boolean;
    setData: SetData;
    onDataChange: (updatedData: Partial<SetData>) => void
    agentData: { value: string, label: string }[];
    onClearAll: () => void
    onRemove: () => void
}

export const CreatedCard = ({ index, nameError, isNameErrorChecking, conversationFilterError, isConversationsErrorChecking, setData, agentData, onDataChange, onClearAll, onRemove }: CreatedCardProps) => (
  <SetCard.Container
    style={{
      width: 280,
    }}
  >
    <Stack style={{ position: 'relative' }}>
      <TextInput
        required
        pl={0}
        label="Conversation Set Name"
        data-cy={`set-title-input-${index}`}
        placeholder="Enter set name"
        autoFocus
        error={!setData.name ? '* Name required' : nameError}
        rightSection={isNameErrorChecking && <Loader size="xs" />}
        value={setData.name}
        onChange={(e) => onDataChange({ name: e.target.value })}
      />
      <SetCard.ConversationQuantityInput
        value={setData.convoQuantity}
        onChange={(e) => onDataChange({ convoQuantity: +e.target.value })}
      />
      <SetCard.AgentSelect
        data={agentData}
        value={setData.agentIdsSelected}
        handleAgentChange={(newValue) => onDataChange({
          agentIdsSelected: newValue,
        })}
        isLoading={setData.agentIdsSelected.length > 0 && isConversationsErrorChecking}
        error={setData.agentIdsSelected.length > 0 && conversationFilterError}
      />
      <DatePickerInput
        type="range"
        label="Date Range"
        placeholder="Pick dates range"
        value={setData.dateRange}
        onChange={(newValue) => onDataChange({ dateRange: newValue })}
      />
      <ConversationDurationSlider
        min={setData.min}
        max={20}
        handleMinDurationChange={(min) => {
          onDataChange({ min });
        }}
        error={setData.min !== 0 && conversationFilterError}
      />
      <Group grow>
        <SetCard.ActionButton onClick={onClearAll}>Clear all</SetCard.ActionButton>
        <SetCard.ActionButton onClick={onRemove} color="red">Remove</SetCard.ActionButton>
      </Group>
    </Stack>
  </SetCard.Container>
);
