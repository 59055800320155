import React from 'react';
import './LetterAvatar.scss';

export default function LetterAvatar({ role }: { role: string }) {
  if (typeof role !== 'string') {
    console.error('Role Not supplied to Letter Avatar, intead found: ', role);
    return null;
  }

  const letter = role[0];

  if (letter.toLowerCase() === 'a') {
    return <div className="letter-avatar" title="Agent"><span className="avatar agent">A</span></div>;
  }
  if (letter.toLowerCase() === 'v') {
    return <div className="letter-avatar" title="Visitor"><span className="avatar visitor">V</span></div>;
  }
  if (letter.toLowerCase() === 's') {
    return <div className="letter-avatar" title="System"><span className="avatar system">S</span></div>;
  }
  if (letter.toLowerCase() === 'b') {
    return <div className="letter-avatar" title="Bot"><span className="avatar system">B</span></div>;
  }
  return <div className="letter-avatar" title="Unspecified Type"><span className="avatar system">?</span></div>;
}
