import React, { ReactElement, useEffect, useState } from 'react';
import './ConceptsList.scss';
import { Input, Card } from '@mantine/core';
import {
  Concept,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import ConceptTag from 'components/ConceptTag';
import { ModalType } from 'components/AddConceptModal';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';
import useUrlParam from 'hooks/useUrlParam';

interface Props {
  name: string;
  data: Concept[];
  onFocus: (concept: Concept) => void;
  onAdd: (modalType: ModalType) => void;
  showTags?: boolean;
}
export function ConceptsList({
  name,
  data,
  onFocus,
  onAdd,
  showTags,
}: Props): ReactElement {
  const [searchText, setSearchText] = useState<string>('');
  const [focusedName, setFocusedName] = useUrlParam<string>('node', '');
  useEffect(() => {
    if (focusedName) {
      const concept = data.find((concept) => concept.name === focusedName);
      if (concept) {
        onFocus(concept);
      }
    }
  }, [data, focusedName]);

  const filteredConcepts = data.filter((concept) => {
    if (searchText && !concept.conceptTitle.includes(searchText)) {
      return false;
    }
    return true;
  });

  return (
    <Card radius="lg" p="lg">
      <div className="list-concepts-header">
        <span>Name</span>
        <span className="spacer" />
        {/* <span>Labels</span> */}
      </div>
      <div className="list-concepts-search">
        <Input
          icon={<SearchOutlined />}
          data-cy="entity-search"
          variant="unstyled"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="list-concepts-content">
        {!data?.length && (<div className="placeholder">No {name}.</div>)}
        {filteredConcepts.map((concept) => (
          <div
            key={concept.name}
            className={classNames([
              'list-concepts-item',
              concept.name === focusedName ? 'focused' : '',
            ])}
            onClick={(e) => {
              e.stopPropagation();
              setFocusedName(concept.name);
            }}
          >
            <ConceptTag value={concept.conceptTitle} />
            <span className="spacer" />
            {showTags && (
              <MultiTags
                tags={concept.conceptTags?.map((tag) => toTitleCase(tag))}
                type="conceptTag"
                size="small"
              />
            )}
          </div>
        ))}
        <div
          className="add-new-button"
          onClick={() => onAdd('library')}
        >
          <PlusCircleOutlined />&nbsp;New {name}
        </div>
      </div>
    </Card>
  );
}
