import { MessageRawDataConfusionType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import MultiTags from 'components/MultiTags';
import { useSelector } from 'hooks/reduxHooks';
import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { ConfustionTypeFilter } from 'types';
import { Group } from '@mantine/core';
import styles from '../styles.module.scss';
import { BaseSummaryTable } from '..';

interface SummaryPolicyTableProps extends BaseSummaryTable {}

export default function SummaryPolicyTable({
  searchTerm,
  taskId,
  allConceptsMap,
  filtersNode,
}: SummaryPolicyTableProps) {
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);

  const makeLink = useCallback((conceptId: string, confusionTypeFilter: ConfustionTypeFilter) =>
    `../score-view/${taskId}/${conceptId}?confusionTypeFilter=${confusionTypeFilter}`, [taskId]);

  const summaryResults = useMemo(() => {
    const resultsMap = new Map<string, ResultsRow>();
    annotationBundles.forEach((bundle) => {
      const intentId = bundle.annotation.value.binaryValue?.conceptId;
      const { messageRawData } = bundle.annotation.rawData;
      let resultRow = resultsMap.get(intentId);
      // Only show next intent predictions (exclude)
      if (messageRawData.confusionType !== MessageRawDataConfusionType.CONFUSION_TYPE_UNSPECIFIED) {
        if (!resultRow) {
          resultRow = {
            id: intentId,
            name: allConceptsMap.get(intentId)?.name || '',
            intent: allConceptsMap.get(intentId)?.conceptTitle || '',
            correct: 0,
            incorrectFN: 0,
            incorrectFPRules: 0,
            incorrectFPTriggers: 0,
          };
          resultsMap.set(intentId, resultRow);
        }
        if (messageRawData.confusionType === MessageRawDataConfusionType.TRUE_POSITIVE) {
          resultRow.correct += 1;
        } else if (messageRawData.confusionType === MessageRawDataConfusionType.FALSE_NEGATIVE) {
          resultRow.incorrectFN += 1;
        } else if (messageRawData.confusionType === MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE) {
          resultRow.incorrectFPRules += 1;
        } else if (messageRawData.confusionType === MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER) {
          resultRow.incorrectFPTriggers += 1;
        }
      }
    });

    const fullSummary = Array.from(resultsMap.values());
    const filteredSummary = fullSummary.filter((row) => row.intent.toLowerCase().includes(searchTerm.toLowerCase()));
    return filteredSummary;
  }, [annotationBundles, searchTerm]);

  interface ResultsRow {
    id: string;
    name: string;
    intent: string;
    correct: number;
    incorrectFN: 0,
    incorrectFPRules: 0,
    incorrectFPTriggers: 0,
  }

  const columns: ColumnsType<ResultsRow> = [
    {
      title: 'Intent',
      dataIndex: 'intent',
      key: 'intent',
      render: (value, row) => <MultiTags type="intent" tags={[value]}/>,
    },
    {
      title: '# Correct',
      width: 180,
      dataIndex: 'correct',
      key: 'correct',
      sorter: (a, b) => b.correct - a.correct,
      render: (value, row) => {
        if (value !== 0) {
          return <NavLink to={makeLink(row.id, 'CORRECT')}>{value}</NavLink>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: '# Incorrect - FN',
      width: 180,
      dataIndex: 'incorrectFN',
      key: 'incorrectFN',
      sorter: (a, b) => b.incorrectFN - a.incorrectFN,
      render: (value, row) => {
        if (value !== 0) {
          return <NavLink to={makeLink(row.id, 'FN')}>{value}</NavLink>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: '# Incorrect - FP (rules)',
      width: 180,
      dataIndex: 'incorrectFPRules',
      key: 'incorrectFPRules',
      sorter: (a, b) => b.incorrectFPRules - a.incorrectFPRules,
      render: (value, row) => {
        if (value !== 0) {
          return <NavLink to={makeLink(row.id, 'FP_RULES')}>{value}</NavLink>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
    {
      title: '# Incorrect - FP (trigger)',
      width: 180,
      dataIndex: 'incorrectFPTriggers',
      key: 'incorrectFPTriggers',
      sorter: (a, b) => b.incorrectFPTriggers - a.incorrectFPTriggers,
      render: (value, row) => {
        if (value !== 0) {
          return <NavLink to={makeLink(row.id, 'FP_TRIGGERS')}>{value}</NavLink>;
        } else {
          return <span>{value}</span>;
        }
      },
    },
  ];

  return (
    <div style={{
      width: '100%',
      flex: 1,
    }}
    >
      <Group mb="lg" position="right">
        <>
          {filtersNode && filtersNode}
        </>
      </Group>
      <Table
        loading={{
          spinning: taskApiStatus === 'loading',
          indicator: <Loading />,
        }}
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={summaryResults}
        className={styles.summaryTable}
      />
    </div>
  );
}
