import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as AgentState } from './state';

const selectAgent = (state: State) => state.agent;

/** Select agents. */
export const selectAgents = createSelector(selectAgent, (agentState: AgentState) => agentState.agents);

/** Select API status. */
export const selectApiStatus = createSelector(selectAgent, (agentState: AgentState) => agentState.status);
