import { useEffect, useState, useCallback } from 'react';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { openNotification } from 'components/Notification';
import { SimulationTaskApi } from 'services/simulationTaskApi';
import { isEqual } from 'lodash';
import { MomentAnnotationDetailedType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { getId } from 'common/resourceName';
import dayjs from 'dayjs';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import { PredictionsDiffMessagePredictionDiff } from '@cresta/web-client/dist/cresta/v1/studio/storage/prediction/prediction.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { mapToStudioMessage, ProdConversationApi } from 'services/prodConversationApi';
import { SimulatorTableRow } from './rowTypes';
import { detailedTypeRoleMap, getMessagePredictionMap } from './utils';

export const useComparePredictions = (conversationName: string, simulationTaskName: string): [SimulatorTableRow[], boolean] => {
  const [data, setData] = useState([]);
  const [isLoading, toggleLoading] = useState(false);

  const getPredictions = useCallback(async () => {
    setData([]);
    toggleLoading(true);
    let messages: Message[];
    let predsOld: Prediction[];
    let predsNew: Prediction[];
    let predDiffs: PredictionsDiffMessagePredictionDiff[];
    try {
      messages = (await ProdConversationApi.listAllMessages(conversationName)).map(mapToStudioMessage);
      const predictionsResponse = await SimulationTaskApi.fetchAllConversationPredictions({
        simulationTask: simulationTaskName,
        conversation: conversationName,
      });

      const { oldPredictions, predictions, predictionDiff } = predictionsResponse;
      predsOld = oldPredictions;
      predsNew = predictions;
      predDiffs = predictionDiff?.msgDiffs || [];
    } catch (err) {
      openNotification('error', 'Failed to Get Result', undefined, err);
      return;
    } finally {
      toggleLoading(false);
    }
    const messagePredictionMap = getMessagePredictionMap(predsNew);
    const oldMessagePredictionMap = getMessagePredictionMap(predsOld);
    setData(messages
      .sort((a, b) => a.publishTime.localeCompare(b.publishTime))
      .map((message) => convertToCompareTableRow(message, predDiffs?.find((diff) => diff.name === message.name), messagePredictionMap.get(message.name), oldMessagePredictionMap.get(message.name))));
  }, [conversationName, simulationTaskName]);

  useEffect(() => {
    if (conversationName && simulationTaskName) {
      getPredictions();
    }
  }, [conversationName, simulationTaskName]);
  return [data, isLoading];
};

function convertToCompareTableRow(message: Message, predictionDiffs: PredictionsDiffMessagePredictionDiff, newMessagePredictions:Prediction[] = [], oldMessagePredictions:Prediction[] = []): SimulatorTableRow {
  const messageId = getId('conversationMessage', message.name);
  const pubDate = new Date(dayjs(message.publishTime).format(ACCEPTED_DATE_FORMAT));

  const detections = [];
  const coaching = [];

  predictionDiffs?.predsInBoth.forEach((pred) => {
    const detection = {
      role: detailedTypeRoleMap.get(pred.prediction.momentDetailedType) || null,
      intent: pred.prediction.taxonomy,
      confidence: pred.newConfidence,
      oldConfidence: pred.oldConfidence,
      changeOfType: 'same',
    };

    if (pred.prediction.momentDetailedType === MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT) {
      coaching.push(detection);
    } else {
      detections.push(detection);
    }
    return detection;
  });

  predictionDiffs?.predsOnlyInNew.forEach((pred) => {
    const detection = {
      role: detailedTypeRoleMap.get(pred.prediction.momentDetailedType) || null,
      intent: pred.prediction.taxonomy,
      confidence: pred.newConfidence,
      oldConfidence: pred.oldConfidence,
      changeOfType: 'added',
    };
    if (pred.prediction.momentDetailedType === MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT) {
      coaching.push(detection);
    } else {
      detections.push(detection);
    }
  });

  predictionDiffs?.predsOnlyInOld.forEach((pred) => {
    const detection = {
      role: detailedTypeRoleMap.get(pred.prediction.momentDetailedType) || null,
      intent: pred.prediction.taxonomy,
      confidence: pred.newConfidence,
      oldConfidence: pred.oldConfidence,
      changeOfType: 'removed',
    };
    if (pred.prediction.momentDetailedType === MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT) {
      coaching.push(detection);
    } else {
      detections.push(detection);
    }
  });

  // temporary 'any' until proto is updated
  const oldPolicy = oldMessagePredictions.map((pred) => pred.moment.intentPayload?.dialoguePolicyDebugOutput as any).filter((v) => !!v);
  const newPolicy = newMessagePredictions.map((pred) => pred.moment.intentPayload?.dialoguePolicyDebugOutput as any).filter((v) => !!v);

  // merge oldPolicy and newPolicy adding a property 'changeOfType' value to either 'added', 'removed' or 'same' using trigger_turn_id
  const policy = [];
  oldPolicy.forEach((oldPolicy) => {
    const idx = newPolicy.findIndex((newPolicy) => isEqual(newPolicy, oldPolicy));
    if (idx >= 0) {
      // remove the same policy from newPolicy
      newPolicy.splice(idx, 1);
      policy.push({
        ...oldPolicy,
        changeOfType: 'same',
      });
    } else {
      policy.push({
        ...oldPolicy,
        changeOfType: 'removed',
      });
    }
  });
  newPolicy.forEach((newPolicy) => {
    policy.push({
      ...newPolicy,
      changeOfType: 'added',
    });
  });

  return {
    utterance: {
      id: messageId,
      text: message.content,
      pubDate,
      msgId: messageId,
      speakerId: message.actorId,
      speakerRole: message.actorType?.toLowerCase(),
      chat_name: null,
      suggestions: null,
      hints: null,
    },
    detections,
    coaching,
    policy,
  };
}
