import { ListActiveConfigsResponse, ListActiveConfigsResponseConfig, StudioConfigService } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { StudioApi } from './studioApi';

const TEST_CUSTOMERS_CONFIG: ListActiveConfigsResponseConfig[] = [
  {
    customerId: 'cresta',
    customerShortName: 'chat-demo',
    defaultLanguageCode: 'en-US',
    domain: 'chat-demo.cresta.com',
    environmentId: 'chat-prod',
    isHipaaRequired: false,
    profileId: 'chat-demo',
    supportedLanguageCodes: ['en-US', 'es-ES', 'es-MX'],
    usecaseId: 'chat-demo',
  },
  {
    customerId: 'cresta',
    customerShortName: 'chat-sandbox',
    defaultLanguageCode: 'en-US',
    domain: 'chat-sandbox.cresta.com',
    environmentId: 'chat-prod',
    isHipaaRequired: false,
    profileId: 'chat-sandbox',
    supportedLanguageCodes: ['en-US', 'en-UK', 'es-ES'],
    usecaseId: 'chat-sandbox',
  },
];

export abstract class ConfigApi {
  /** Fetch customer configs */
  public static listActiveConfigs(): Promise<ListActiveConfigsResponse> {
    if (process.env.REACT_APP_USE_TEST_CONFIG === 'true') {
      const response: ListActiveConfigsResponse = {
        configs: TEST_CUSTOMERS_CONFIG,
      };
      return Promise.resolve(response);
    }

    return StudioConfigService.ListActiveConfigs({}, StudioApi.getConfigHeader());
  }
}
