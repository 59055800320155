import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification } from 'components/Notification';
import { AuthProtoRole, User } from '@cresta/web-client';
import { ProdUsersApi } from 'services/prodUsersApi';

enum Action {
  ACTORS_LIST_AGENTS = 'ACTORS_LIST_AGENTS',
}

// from actors.pb.ts:
/**
   * The maximum number of actors to return. The service may return fewer than
   * this value.
   * */
const PAGE_SIZE = 100;

/** AsyncThunk for listing first PAGE_SIZE * MAX_FETCH_REQUESTS agents. */
export const listAgents = createAsyncThunk<User[], string>(
  Action.ACTORS_LIST_AGENTS,
  async (parent: string) => {
    try {
      const MAX_FETCH_REQUESTS = 50;
      let requestNumber = 0;
      const users: User[] = [];
      let nextPageToken: string | undefined;
      do {
        // eslint-disable-next-line no-await-in-loop
        const response = await ProdUsersApi.listUsers({
          parent,
          pageSize: PAGE_SIZE,
          filter: {
            roles: [AuthProtoRole.AGENT],
          },
          pageToken: nextPageToken,
        });
        users.push(...response.users);
        requestNumber++;
        // eslint-disable-next-line prefer-destructuring
        nextPageToken = response.nextPageToken;
      } while (nextPageToken && requestNumber < MAX_FETCH_REQUESTS);
      return users;
    } catch (err) {
      openNotification('error', 'Failed to list agents', undefined, err);
      throw err;
    }
  },
);
