import React from 'react';
import { Divider, Paper, Title } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { LabelReversion } from './LabelReversion';
import { LabelPromotion } from './LabelPromotion';
import { AnnotationReversion } from './AnnotationReversion';

export const Versioning = () => (
  <PageContainer>
    <Title order={2}>Versioning</Title>
    <Paper p="lg">
      <AnnotationReversion />
      <Divider my="lg" />
      <LabelReversion />
      <Divider my="lg" />
      <LabelPromotion />
    </Paper>
  </PageContainer>
);
