import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { Annotation, BinaryValue, BinaryValueValue, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ConceptType, Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import MultipleBinaryDecision from 'components/LabelingDecision/MultipleBinaryDecision/MultipleBinaryDecision';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import { cloneDeep } from 'lodash';
import { GotoNextType } from 'pages/LabelingView';
import { filterConceptsByActorType } from 'pages/LabelingView/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface MultipleBinaryLabelingViewProps {
  concepts: Concept[];
  messages: Message[];
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  conceptType: ConceptType;
}

export function MultipleBinaryLabelingView({
  concepts,
  messages,
  targetMessage,
  messageAnnotationsMap,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  createAnnotation,
  upsertAnnotations,
  conceptType,
} : MultipleBinaryLabelingViewProps) {
  const targetMessageId = targetMessage?.v2MessageId;
  const [selectedConceptIds, setSelectedConceptIds] = useState<string[]>([]);
  const [selectedConceptsUpdated, setSelectedConceptsUpdated] = useState(false);
  const context = MessageRawDataContextShown.FULL_CONVERSATION_SHOWN;

  // If it's intent type labeling, filter intents based on actor type
  const filteredConcepts = useMemo(() => {
    if (conceptType === ConceptType.INTENT) {
      return filterConceptsByActorType(concepts, targetMessage?.actorType);
    } else {
      return concepts;
    }
  }, [concepts, targetMessage, filterConceptsByActorType, conceptType]);

  // Get list of annotations for target message
  const targetMessageAnnotations = useMemo(() =>
    messageAnnotationsMap.get(targetMessageId) || [], [targetMessage, messageAnnotationsMap]);

  useEffect(() => {
    // Set new selected concepts
    const selectedConceptIds = targetMessageAnnotations.reduce((acc, annotation) => {
      if (annotation.value.binaryValue.value === BinaryValueValue.VALUE_POSITIVE) {
        return [...acc, annotation.value.binaryValue.conceptId];
      } else {
        return acc;
      }
    }, []);
    setSelectedConceptIds(selectedConceptIds);
    setSelectedConceptsUpdated(true);
  }, [targetMessageId]);

  // Concept ids that should be labeled for this message
  const annotationConceptIds = useMemo(() => filteredConcepts.map((concept) => getId('concept', concept.name)), [filteredConcepts]);

  const hasAnnotations = targetMessageAnnotations.length > 0;
  const isSkipOrUnsure = targetMessageAnnotations.some((annotation) =>
    [BinaryValueValue.VALUE_SKIP, BinaryValueValue.VALUE_FLAG_FOR_REVIEW].includes(annotation.value.binaryValue.value));

  // Flag to determine whether to show and save "ghost" annotations
  const annotationsChanged = useMemo(() => selectedConceptIds.length > 0 || (!isSkipOrUnsure && hasAnnotations), [selectedConceptIds, isSkipOrUnsure, hasAnnotations]);

  // Annotations calculated from selected concepts
  const targetMessageGhostAnnotations = useMemo(() => annotationConceptIds.map((conceptId) => {
    const annotation: Annotation = {
      rawData: {
        messageRawData: {
          messageId: targetMessageId,
        },
      },
      value: {
        binaryValue: {
          value: selectedConceptIds.indexOf(conceptId) === -1 ? BinaryValueValue.VALUE_NEGATIVE : BinaryValueValue.VALUE_POSITIVE,
          conceptId,
        },
      },
    };

    return annotation;
  }), [annotationConceptIds, selectedConceptIds, targetMessageId]);

  // This annotations map overrides target message annotations if it's unlabeled
  // with "ghost" annotations based on what concepts are/aren't selected in the right panel
  const messageCombinedAnnotationsMap = useMemo(() => {
    const mapCopy = cloneDeep(messageAnnotationsMap);
    // If the message has no labels or already has skip or unsure annotations,
    // don't show ghost annotations
    if (annotationsChanged) {
      mapCopy.set(targetMessageId, targetMessageGhostAnnotations);
    }
    return mapCopy;
  }, [messageAnnotationsMap, targetMessageGhostAnnotations, annotationsChanged, targetMessageId]);

  const submitAnnotationsHandler = useCallback((
    newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType = 'message',
  ) => {
    upsertAnnotations(newAnnotations, gotoNext, targetMessageAnnotations);
  }, [upsertAnnotations, context, targetMessageAnnotations]);

  // When user clicks to another message, based on a few conditions
  // we may save the current annotations if they were changed
  const setTargetMessageHandler = useCallback((message: Message) => {
    setSelectedConceptsUpdated(false);
    const oldAnnotationsIdValueMap = new Map<string, BinaryValueValue>(
      targetMessageAnnotations.map((annotation) => ([annotation.value.binaryValue.conceptId, annotation.value.binaryValue.value])),
    );
    const newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[] = targetMessageGhostAnnotations.map(
      (annotation) => createAnnotation({
        conversationId: targetMessage.conversationId,
        messageId: targetMessage.messageId,
        v2ConversationId: targetMessage.v2ConversationId,
        v2MessageId: targetMessage.v2MessageId,
        contextShown: context,
        spanStart: targetMessage.targetSpanStart,
        spanEnd: targetMessage.targetSpanEnd,
      }, annotation.value.binaryValue),
    );
    const areAnnotationsDifferent = newAnnotations.some((newAnnotation) => {
      const oldAnnotationValue = oldAnnotationsIdValueMap.get(newAnnotation.value.binaryValue.conceptId);
      return newAnnotation.value.binaryValue.value !== oldAnnotationValue;
    });
    if ((annotationsChanged && areAnnotationsDifferent) || !hasAnnotations) {
      submitAnnotationsHandler(newAnnotations, 'noop');
    }
    setTargetMessage(message);
  }, [
    submitAnnotationsHandler,
    targetMessage,
    context,
    targetMessageAnnotations,
    targetMessageGhostAnnotations,
    annotationsChanged,
    hasAnnotations,
  ]);

  return (
    <div
      className={classNames([
        'labeling-view',
        'vertical-layout',
      ])}
    >
      <div className="labeling-items-display">
        <LabelingItemsDisplay
          context={context}
          showContextButtonVisible={false}
          messages={messages}
          targetMessageId={targetMessageId}
          setTargetMessage={setTargetMessageHandler}
          messageAnnotationsMap={selectedConceptsUpdated ? messageCombinedAnnotationsMap : messageAnnotationsMap}
          getConceptTitle={getConceptTitle}
          failedMessages={failedMessages}
          annotationsDisplayType="concepts-colors"
        />
      </div>
      <div className="labeling-decision-panel">
        <MultipleBinaryDecision
          message={targetMessage}
          concepts={filteredConcepts}
          selectedConceptIds={selectedConceptIds}
          setSelectedConceptIds={setSelectedConceptIds}
          annotations={targetMessageAnnotations}
          createAnnotation={createAnnotation}
          submitAnnotations={submitAnnotationsHandler}
          contextShown={context}
        />
      </div>
    </div>
  );
}
