import './ConceptsSelect.scss';
import React, { ReactElement, ReactNode, useState } from 'react';
import {
  Concept,
  ConceptConceptTagType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';
import { ConceptTagsControl } from 'components/ConceptTagsControl';

interface Props {
  title: string;
  concepts: Concept[];
  filterText?: string;
  allowTag?: boolean;
  renderConcept?: (concept: Concept) => ReactNode;
  onClick: (concept: Concept) => void;
}

export function ConceptsSelect({
  title,
  concepts,
  filterText,
  allowTag,
  renderConcept,
  onClick,
}: Props): ReactElement {
  const conceptTags = Array.from(new Set(concepts.map((concept) => concept.conceptTags).flat()).values());
  const [filterTag, setFilterTag] = useState<ConceptConceptTagType>(null);
  const filteredConcepts = concepts.filter((concept) => {
    if (filterText && !concept.conceptTitle.toLowerCase().includes(filterText.toLowerCase())) {
      return false;
    }
    if (filterTag && !concept.conceptTags?.includes(filterTag)) {
      return false;
    }
    return true;
  });

  const conceptRow = (concept: Concept) => (
    <div
      key={concept.name}
      className="library-concept"
      onClick={() => onClick(concept)}
    >
      {renderConcept ? renderConcept(concept) : <span>{concept.conceptTitle}</span>}
      <span className="spacer" />
      {allowTag && concept.conceptTags?.length > 0 && (
        <MultiTags
          tags={concept.conceptTags.map((tag) => toTitleCase(tag))}
          type="conceptTag"
        />
      )}
    </div>
  );

  return (
    <div className="concepts-select-wrapper">
      <span className="concepts-select-title">{title}</span>
      {allowTag && conceptTags?.length > 0 && (
        <ConceptTagsControl
          conceptTags={conceptTags}
          value={filterTag}
          onChange={setFilterTag}
        />
      )}
      {
        filteredConcepts.map((concept) => conceptRow(concept))
      }
    </div>
  );
}
