import React, { useEffect, useState } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { INITIAL_SUGGESTIONS_PER_PAGE, UPDATES_UNTIL_REFRESH_SUGGESTIONS } from '../../config';
import { Category, ConfigFormValues, Preview } from '../../types';
import { PreviewSection as PreviewSectionComponent } from './PreviewSection';
import { usePreviews } from './usePreviews';
import { convertExampleObjsToExampleUtterances } from '../../utils';

interface PreviewSectionProps {
  configForm: UseFormReturnType<ConfigFormValues>;
  onUpdateExamples: (preview: Preview, category: Category) => void;
  onTogglePanel: () => void;
}

export const PreviewSection = (props: PreviewSectionProps) => {
  const { configForm, onUpdateExamples, onTogglePanel } = props;
  const [updateCount, setUpdateCount] = useState(0);
  const [isRefreshSuggestionVisible, toggleRefreshSuggestion] = useState(false);
  const [data, isLoading] = usePreviews({
    intentDisplayName: configForm.values.humanReadableName,
    intentDescription: configForm.values.description,
    positiveExamples: convertExampleObjsToExampleUtterances(configForm.values.positiveExamples),
    negativeExamples: convertExampleObjsToExampleUtterances(configForm.values.negativeExamples),
    speakerRole: configForm.values.speakerRole,
  });

  const handleUpdateExamples = (preview: Preview, category: Category) => {
    onUpdateExamples(preview, category);
    setUpdateCount((prev) => prev + 1);
  };

  // reset update count if preview data changes
  useEffect(() => {
    setUpdateCount(0);
  }, [data]);

  // toggle refresh suggestion if update count exceeds threshold
  useEffect(() => {
    if (updateCount >= UPDATES_UNTIL_REFRESH_SUGGESTIONS) {
      toggleRefreshSuggestion(true);
    }
  }, [updateCount]);

  const onCloseRefreshSuggestion = () => {
    toggleRefreshSuggestion(false);
    setUpdateCount(0);
  };
  return (
    <PreviewSectionComponent
      isLoading={isLoading}
      positivePreviews={data?.positivePreviews}
      negativePreviews={data?.negativePreviews}
      pageSize={INITIAL_SUGGESTIONS_PER_PAGE}
      onUpdateExamples={handleUpdateExamples}
      isRefreshSuggestionVisible={isRefreshSuggestionVisible}
      onCloseRefreshSuggestion={onCloseRefreshSuggestion}
      onTogglePanel={onTogglePanel}
    />
  );
};
