import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Group, Title, Anchor, useMantineTheme, Tooltip, Badge } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Loading from 'components/Loading';
import { modelArtifactApi } from 'services/modelArtifactApi';
import { ListModelArtifactsRequest, ModelArtifact, ModelArtifactArtifactType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { ArrowLeftOutlined, WarningOutlined } from '@ant-design/icons';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { getId } from 'common/resourceName';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import CopyableValue from 'components/CopyableValue';
import { orderBy } from 'lodash';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { getDeploymentStatusElement } from './utils';

interface DeploymentTasksRow {
  dryRun: ModelArtifact;
  fullDeploy?: ModelArtifact;
}

function getRowTask(row: DeploymentTasksRow): ModelArtifact {
  return row.fullDeploy || row.dryRun;
}

export function DeploymentHistory() {
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const [modelArtifacts, setModelArtifacts] = useState<ModelArtifact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useMantineTheme();

  const tableData: DeploymentTasksRow[] = useMemo(() => {
    // First loop to create a map of fully deployed tasks
    // and a list of dry run tasks that will be paired in the second loop
    const deploymentsMap = new Map<string, DeploymentTasksRow>();
    const dryRunTasks: ModelArtifact[] = [];
    modelArtifacts.forEach((task) => {
      if (task.deploymentArtifact.inputs.validateOnly) {
        dryRunTasks.push(task);
      } else {
        deploymentsMap.set(getId('modelArtifact', task.name), {
          dryRun: undefined,
          fullDeploy: task,
        });
      }
    });

    // Loop over remaining dry run tasks and pair them with fully deployed tasks
    dryRunTasks.forEach((task) => {
      const dryRunTaskId = String(task.deploymentArtifact.outputs.dryRunTaskId);
      const fullDeployTask = deploymentsMap.get(dryRunTaskId);
      if (fullDeployTask) {
        deploymentsMap.set(dryRunTaskId, {
          ...fullDeployTask,
          dryRun: task,
        });
      } else {
        deploymentsMap.set(getId('modelArtifact', task.name), {
          fullDeploy: undefined,
          dryRun: task,
        });
      }
    });

    const taskRows = Array.from(deploymentsMap.values());

    // Sort by date
    return orderBy(taskRows, (row) => dayjs((row.fullDeploy || row.dryRun)?.completeTime).unix(), 'desc');
  }, [modelArtifacts]);

  const listModelArtifacts = useCallback(async () => {
    const request: ListModelArtifactsRequest = {
      parent: `${customerProfile}/modelProjects/-`,
      filter: {
        artifactType: ModelArtifactArtifactType.DEPLOYMENT,
        usecaseId,
        languageCode,
      },
    };
    setLoading(true);
    try {
      const response = await modelArtifactApi.listModelArtifacts(request);
      setModelArtifacts(response.modelArtifacts);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [path]);

  useEffect(() => {
    if (customerProfile) {
      listModelArtifacts();
    }
  }, [customerProfile]);

  const columns: ColumnsType<DeploymentTasksRow> = [
    {
      title: '#',
      key: 'taskId',
      render: (_, row) => getId('modelArtifact', getRowTask(row)?.name),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, row) => {
        const status = getRowTask(row)?.status;
        return (
          <Group spacing="xs" noWrap>
            {getDeploymentStatusElement(status, theme)}
            {row.dryRun?.deploymentArtifact?.inputs.validateOnly && <Badge color="orange" radius="sm" px={4} sx={{ fontWeight: 500 }}>Dry run</Badge>}
          </Group>
        );
      },
    },
    {
      title: 'Created at',
      key: 'createTime',
      render: (_, row) => {
        const createTime = getRowTask(row)?.createTime;
        return dayjs(createTime).format(DATE_TIME_FORMAT);
      },
    },
    {
      title: 'Completed at',
      key: 'completeTime',
      render: (_, row) => {
        const completeTime = getRowTask(row)?.completeTime;
        return dayjs(completeTime).format(DATE_TIME_FORMAT);
      },
    },
    {
      title: 'Model URI(s)',
      key: 'modelUri',
      render: (_, row) => {
        const intentPolicyModelDeploymentUnit = getRowTask(row)?.deploymentArtifact.inputs.intentPolicyModelDeploymentUnit;
        return Object.values(intentPolicyModelDeploymentUnit || {}).map((value) => {
          if (!value) return null;
          const formattedValue = `...${(value as string).split('/')[1]}`;
          return <CopyableValue copiedValue={value as string} displayValue={formattedValue} />;
        });
      },
    },
    {
      title: 'PR',
      key: 'pr',
      render: (_, row) => {
        const prs = getRowTask(row)?.deploymentArtifact.outputs.deploymentPr;
        return (
          <>
            {prs.split(',').map((val) => {
              if (val === 'N/A' || !val) return <p>{val}</p>;

              return <Anchor target="_blank" href={val}>/{val.split('/').pop()}</Anchor>;
            })}
          </>
        );
      },
    },
    {
      title: 'Error message',
      key: 'errMsg',
      render: (_, row) => {
        const error = getRowTask(row)?.deploymentArtifact.outputs.errorMessage;
        if (error) {
          return <Tooltip multiline width={300} label={error}><WarningOutlined style={{ color: 'red' }} /></Tooltip>;
        }
        return null;
      },
    },
    {
      title: 'Commit message',
      key: 'commitMessage',
      render: (_, row) => getRowTask(row)?.deploymentArtifact.outputs.commitMessage,
    },
    {
      title: 'Checklist',
      dataIndex: '',
      key: 'checklist',
      render: (value, row) => <NavLink to={`/${path}/deployment/checklist/${getId('modelArtifact', getRowTask(row)?.name)}`}>See checklist</NavLink>,
    },
  ];

  return (
    <PageContainer>
      <Group>
        <NavLink to={`/${path}/deployment/summary`}>
          <ArrowLeftOutlined /> Back to deployment summary
        </NavLink>
      </Group>
      <Group position="apart">
        <Title order={1}>Deployment History</Title>
      </Group>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={{
          spinning: loading,
          indicator: <Loading />,
        }}
      />
    </PageContainer>
  );
}
