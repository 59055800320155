import React, { useMemo, useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { useSelector } from 'hooks/reduxHooks';
import { useConversationData } from 'hooks/useConversationData';
import { selectApiStatus } from 'store/agent/selectors';
import { useAgentNames } from 'hooks/useAgentNames';
import { getId } from 'common/resourceName';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { ChatWindowTopper as ChatWindowTopperComponent } from './ChatWindowTopper';
import { getDuration } from './utils';

export interface ChatWindowTopperProps {
    className?: string
    title?: string // overrides Agent Name as title
    conversationName: string;
    actionComponent?: (props: Conversation) => React.ReactElement<Conversation>
    isLoading?: boolean
}

export const ChatWindowTopper = ({ className, title, conversationName, actionComponent, isLoading: isLoadingOverride }: ChatWindowTopperProps) => {
  const [conversationData, isLoading] = useConversationData(conversationName, [isLoadingOverride]);
  const agentsLoadingStatus = useSelector(selectApiStatus);
  const { getAgentNameFromActorId } = useAgentNames();
  const customer = useCustomerParams();
  const { currentConfig } = useContext(CustomerConfigContext);

  const createExternalLink = (id: string) => {
    const encodedId = encodeURIComponent(id);
    if (!customer) return '';
    const { profileId } = customer;
    let url;
    if (profileId?.includes('voice')) {
      url = `https://${currentConfig?.customerShortName}.cresta.com/director/conversations/voice/closed/${encodedId}`;
    } else {
      const domainExtension = currentConfig?.environmentId.includes('prod') ? 'com' : 'ai';
      url = `https://${currentConfig?.customerShortName}.cresta.${domainExtension}/director/conversations/chat/closed/${encodedId}`;
    }
    return url;
  };

  const getEngagementIdData = (conversationData: Conversation) => {
    if (!conversationData.legacyConversationId) {
      return null;
    }
    const id = conversationData.legacyConversationId;
    const externalLink = createExternalLink(conversationData.metadata?.prodConversationId);

    return {
      id,
      label: 'Engagement ID',
      linkLabel: 'View in Director',
      externalLink,
    };
  };

  const getTitle = (conversationData: Conversation) => {
    if (!conversationData) {
      return 'No conversation data available';
    }

    return getAgentNameFromActorId(conversationData.actorIds[0]);
  };

  const headerTitle = useMemo(() => {
    if (title) return title;
    return getTitle(conversationData);
  }, [conversationData]);

  const durationMins = conversationData ? getDuration(conversationData.openTime, conversationData.endTime) : null;
  const openTime = conversationData?.openTime ? dayjs(conversationData?.openTime).format(DATE_TIME_FORMAT) : null;
  const engagementIdData = conversationData ? getEngagementIdData(conversationData) : null;
  const studioIdData = conversationData ? { id: getId('conversation', conversationData.name), label: 'Studio ID' } : null;

  const agentsLoading = conversationName && agentsLoadingStatus === 'loading';

  const ActionComponent = useCallback(() => {
    if (!actionComponent) {
      return null;
    }
    const ActionComponent = actionComponent;
    return <ActionComponent {...conversationData} />;
  }, [conversationData]);

  return (
    <ChatWindowTopperComponent
      className={className}
      isLoading={isLoadingOverride || isLoading}
      title={headerTitle}
      agentsLoading={agentsLoading}
      durationMins={durationMins}
      openTime={openTime}
      engagementIdData={engagementIdData}
      studioIdData={studioIdData}
      actionComponent={<ActionComponent />}
    />
  );
};
