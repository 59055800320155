import {
  AnnotationRawDataType,
  AnnotationValuePresenceType,
  AnnotationValueType,
  MessageRawDataContextShown,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  FetchLabelingItemsRequest,
  RemoveTemporalAnnotationRequest,
  SaveTemporalAnnotationRequest,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { v4 as uuid } from 'uuid';

export const createNote = ({
  taskName,
  conversationNote,
  conversationId,
  conversationPositionNumber,
  setId,
}: {
  taskName: string;
  conversationNote: string;
  conversationId: string;
  conversationPositionNumber: number;
  setId: string;
}) => {
  const newId = uuid();
  const newAnnotation: SaveTemporalAnnotationRequest = {
    taskName,
    rawDataValueTuples: [
      {
        annotationId: newId,
        rawData: {
          type: AnnotationRawDataType.TYPE_ON_CONVERSATION,
          conversationRawData: {
            conversationId,
            v2ConversationId: conversationId,
            contextShown: MessageRawDataContextShown.FULL_CONVERSATION_SHOWN,
          },
          conversationPositionNumber,
        },
        value: {
          type: AnnotationValueType.TYPE_TEXT,
          textValue: {
            value: conversationNote,
          },
          setId,
          presenceType: AnnotationValuePresenceType.PRESENCE,
        },
        targetType: TargetType.MANUAL_ANALYSIS,
      },
    ],
  };
  return LabelingTaskApi.saveTemporalAnnotation(newAnnotation);
};

export const deleteNote = (request: RemoveTemporalAnnotationRequest) =>
  LabelingTaskApi.removeTemporalAnnotation(request);

export const getFilteredNoteAnnotations = async (request: FetchLabelingItemsRequest) => {
  const res = await LabelingTaskApi.fetchLabelingItems(request);
  const taskAnnotations = res?.temporalAnnotations || [];
  const filteredAnnotations = taskAnnotations.filter((annotation) => {
    const isCorrectType = annotation.rawDataType === 'TYPE_ON_CONVERSATION';
    const isCorrectValueType = annotation.valueType === 'TYPE_TEXT';
    return isCorrectType && isCorrectValueType;
  });
  return filteredAnnotations;
};
