import LetterAvatar from 'components/LetterAvatar';
import React from 'react';
import './UserTag.scss';

type UserTagProps = {
  name: string;
};

export default function UserTag({ name }: UserTagProps) {
  return (
    <div className="user-tag">
      <LetterAvatar name={name} />
      <span>{name}</span>
    </div>
  );
}
