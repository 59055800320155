import React, { useCallback, useEffect, useState } from 'react';
import { ArrowLeftOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { selectAllConcepts } from 'store/concept/selectors';
import { selectDerivedLabelingTaskFactory } from 'store/labelingTask/selectors';
import { fetchLabelingTaskAnnotations } from 'store/labelingTask/asyncThunks';
import ItemPicker from 'components/ItemPicker';
import { Concept, ConceptConceptType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useDebounce } from 'hooks/useDebounce';
import Loading from 'components/Loading';
import { Button } from '@mantine/core';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import styles from './styles.module.scss';
import AnalysisUtteranceTable from './AnalysisUtteranceTable';

export default function AnalysisUtteranceView() {
  const dispatch = useDispatch();
  const params = useParams<{taskId: string, conceptId: string}>();
  const { taskId, conceptId } = params;

  const [searchTerm] = useState('');
  const customerProfile = useCustomerProfile();
  const navigate = useNavigate();

  // Handle search text change with debounce.
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 300);

  // Completed derived analysis task
  const task = useSelector<DerivedLabelingTask>(
    selectDerivedLabelingTaskFactory(`${customerProfile}/labelingTasks/${taskId}`),
  );

  // IntentsMap for intents in the summary.
  const concepts = useSelector<Concept[]>(selectAllConcepts);
  const conceptsMap = new Map<string, Concept>(concepts.filter((concept) => {
    // Only concepts for this task
    const conceptId = getId('concept', concept.name);
    const isConceptInActiveTask = task?.labelingTask.taskData.taskDescriptor.conceptIds.includes(conceptId);
    const isActiveConcept = concept.state !== ConceptState.DEPRECATED;
    return isActiveConcept && isConceptInActiveTask;
  })
    .map((concept) => {
      const conceptId = getId('concept', concept.name);
      return [conceptId, concept];
    }));

  // Current selected intent.
  const selectedConcept = conceptsMap.get(conceptId);
  const availableConcepts = Array.from(conceptsMap.values());

  const searchFilter = {
    taskIds: taskId ? [taskId] : [],
    conceptIds: conceptId ? [conceptId] : [],
    textContain: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
  };

  const fetchSummary = async () => {
    dispatch(fetchLabelingTaskAnnotations({
      parent: customerProfile,
      searchFilter,
      pageSize: 1000,
    }));
  };

  useEffect(() => {
    fetchSummary();
  }, [taskId, conceptId, debouncedSearchTerm]);

  const getTagType = (concept: Concept): TagTagType => {
    if (concept?.conceptType === ConceptConceptType.INTENT) {
      switch (concept?.intent.intentType) {
        case IntentIntentType.CONVERSATION_DRIVER:
          return TagTagType.DRIVER;
        case IntentIntentType.VISITOR_INTENT:
          return TagTagType.VISITOR_INTENT;
        case IntentIntentType.AGENT_INTENT:
          return TagTagType.AGENT_INTENT;
        default:
          return TagTagType.TAG_TYPE_UNSPECIFIED;
      }
    }
    if (concept?.conceptType === ConceptConceptType.CONVERSATION_OUTCOME) {
      return TagTagType.CONVERSATION_OUTCOME;
    }
    return TagTagType.TAG_TYPE_UNSPECIFIED;
  };

  const getConceptTitle = useCallback((conceptId: string) => conceptsMap.get(conceptId)?.conceptTitle, [conceptsMap]);

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <Link to="./.." className={styles.backBtn}>
        <ArrowLeftOutlined /> Back to Summary
      </Link>
      <div className={styles.header}>
        <ItemPicker
          items={availableConcepts}
          idKeyPath="name"
          value=""
          renderItem={(item) => <ManualAnalysisTag tagType={getTagType(item)}>{item?.conceptTitle}</ManualAnalysisTag>}
          filterKey={(item) => item.conceptTitle}
          placeholder="Select"
          onChange={(item) => navigate({ pathname: getId('concept', item?.name) })}
        >
          <Button className={styles.dropdownIcon} compact size="lg" variant="white"><UnorderedListOutlined /></Button>
        </ItemPicker>
        {selectedConcept && (
          <ManualAnalysisTag tagType={getTagType(selectedConcept)}>{selectedConcept?.conceptTitle}</ManualAnalysisTag>
        )}
      </div>
      <div className={styles.content}>
        {task ? (
          <AnalysisUtteranceTable
            searchTerm={searchTerm}
            taskId={taskId}
            conceptId={conceptId}
            getConceptTitle={getConceptTitle}
          />
        ) : <Loading/>}
      </div>
    </div>
  );
}
