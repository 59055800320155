import { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { DatesRangeValue } from '@mantine/dates';

export enum QATaskOption {
  SINGLE = 'SINGLE',
  COMPARE = 'COMPARE',
  NO_QA = 'NO_QA',
}

export interface QAConfigFormValues {
  taskOption: QATaskOption;
  qaComparisonModelUrl: string;
  qaConversationDates: DatesRangeValue;
  qaConversationCount: number;
}

export const useQAConfigForm = () => {
  const form = useForm<QAConfigFormValues>({
    initialValues: {
      taskOption: QATaskOption.SINGLE,
      qaComparisonModelUrl: '',
      qaConversationDates: [null, null],
      qaConversationCount: 30,
    },
  });

  useEffect(() => {
    form.setFieldValue('qaComparisonModelUrl', '');
    form.setFieldValue('qaConversationDates', [null, null]);
    form.setFieldValue('qaConversationCount', 30);
  }, [form.values.taskOption]);
  return form;
};
