import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import './IntentConceptEditor.scss';
import {
  Concept,
  ConceptConceptSource,
  ConceptState,
  IntentIntentType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Card, Modal, Group, Text, List, Button, Tooltip } from '@mantine/core';
import ConceptTag from 'components/ConceptTag';
import { useForm } from 'antd/lib/form/Form';
import { toRequestUpdateMask } from 'utils';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import EditableTagGroup, { EditableTagType } from 'components/EditableTagGroup/EditableTagGroup';
import { getId } from 'common/resourceName';
import { selectDerivedLabelingTasks } from 'store/labelingTask/selectors';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useSelector } from 'hooks/reduxHooks';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { ApiStatus } from 'store/types';
import { selectLoadProdTaxonomiesStatus, selectProdIntents } from 'store/modelBuilder/selectors';
import { ProdIntent } from 'store/modelBuilder/state';
import { WarningOutlined } from '@ant-design/icons';
import { ConceptConfigTags } from 'components/ConceptsView/ConceptConfigTags/ConceptConfigTags';
import { useIsSharedLibrary } from 'hooks/useCustomerParams';
import { ConceptDetail } from '../ConceptDetail';
import { ConceptForm, EDITABLE_CONCEPT_FIELDS } from '../ConceptForm';

const CanDeprecateModal = ({ isOpen, onClose, onConfirm, isProdIntent }) => (
  <Modal
    title={isProdIntent ? (
      <div className="deprecate-modal-warning-title">
        <WarningOutlined />&nbsp;Warning: Intent Live in Prod
      </div>
    ) : 'Are you sure?'}
    opened={isOpen}
    onClose={onClose}
    centered
  >
    {isProdIntent ? (
      <>
        <Text>This intent is live in production. Deprecating will result in the following:</Text>
        <List withPadding listStyleType="disc">
          <List.Item>All labels for intent will be deprecated</List.Item>
          <List.Item>All regexes for intent will be deprecated</List.Item>
          <List.Item>Future model deployments with this intent will be blocked</List.Item>
        </List>
        <Text style={{ marginBottom: 25 }}>Please confirm with MLE team before continuing.</Text>
      </>
    ) : (
      <Text size="sm" style={{ marginBottom: 25 }}>
        This action is irreversible and you will lose all labels for this intent. Additionally, you will be unable to QA this intent, and any model deploy involving this intent in any model will fail. Please confirm with the CD/MLE before doing this.
      </Text>
    )}
    <Group>
      <Button color="red" onClick={onConfirm}>Yes, Deprecate</Button>
      <Button variant="subtle" onClick={onClose}>No, cancel</Button>
    </Group>
  </Modal>
);

const CannotDeprecateModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal
    title="Open Tasks Blocking Deprecation"
    opened={isOpen}
    onClose={onClose}
    centered
  >
    <Text size="sm" style={{ marginBottom: 25 }}>There are open tasks for this intent. Please FINISH the tasks (including any archived ones) before continuing.</Text>
    <Group>
      <Button onClick={onConfirm}>See open tasks</Button>
      <Button variant="subtle" onClick={onClose}>Cancel</Button>
    </Group>
  </Modal>
);

interface IntentConceptEditorProps {
  drivers: Concept[];
  concept: Concept;
  onUpdate: (concept: Concept, updateMask: string) => void;
  showTags?: boolean;
  setShowExportContentModal?: (value: boolean) => void;
}

export function IntentConceptEditor({
  drivers,
  concept,
  onUpdate,
  showTags,
  setShowExportContentModal,
}: IntentConceptEditorProps): ReactElement {
  const [editing, setEditing] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [showDeprecateModal, setShowDeprecateModal] = useState<boolean>(false);
  const [showCannotDeprecateModal, setShowCannotDeprecateModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const derivedLabelingTasks = useSelector<DerivedLabelingTask[]>(selectDerivedLabelingTasks);
  const isSharedLibrary = useIsSharedLibrary();

  // State to edit the parent drivers.
  const [parentDriverIds, setParentDriverIds] = useState<string[]>([]);
  const [parentDriverTouched, setParentDriverTouched] = useState<boolean>(false);

  useEffect(() => {
    setParentDriverIds(concept?.intent.parentConversationDriverNames || []);
    setParentDriverTouched(false);
  }, [concept]);

  // Form to edit the concept.
  const [form] = useForm<Concept>();
  const { getFieldsValue, isFieldTouched, resetFields, getFieldsError, isFieldsTouched } = form;
  const shouldDisableSave = () => {
    const errors = getFieldsError(EDITABLE_CONCEPT_FIELDS);
    const total = errors.map((e) => e.errors.length).reduce((sum, count) => sum + count, 0);
    setDisabledSave(total > 0 || (!isFieldsTouched(EDITABLE_CONCEPT_FIELDS) && !parentDriverTouched));
  };
  useEffect(() => shouldDisableSave(), [parentDriverTouched]);

  const handleSave = useCallback(() => {
    const updatedConcept: Concept = getFieldsValue();
    // Reconstruct the concept title since only the last segment is edited in the form.
    const segments = concept.conceptTitle.split('.');
    segments.pop();
    const conceptPrefix = segments.length ? `${segments.join('.')}.` : '';
    updatedConcept.conceptTitle = `${conceptPrefix}${form.getFieldValue('conceptTitle')}`;
    updatedConcept.name = concept.name;

    const needContextFieldValue: 'show' | 'hide' = form.getFieldValue('intent')?.needsContext;
    updatedConcept.intent.needsContext = needContextFieldValue === 'show';

    const updateMask: string[] = [];

    for (const path of EDITABLE_CONCEPT_FIELDS) {
      if (isFieldTouched(path)) {
        updateMask.push(Array.isArray(path) ? path.join('.') : String(path));
      }
    }
    if (parentDriverTouched) {
      updatedConcept.intent.parentConversationDriverNames = parentDriverIds;
      updateMask.push('intent.parentConversationDriverNames');
    }

    const requestUpdateMask = toRequestUpdateMask(updateMask);

    onUpdate(updatedConcept, requestUpdateMask);
    setEditing(false);
  }, [concept, onUpdate, form, parentDriverTouched, parentDriverIds]);

  const handleUpdateDrivers = useCallback((tags: EditableTagType<Concept>[]) => {
    setParentDriverIds(tags.map((tag) => tag.key as string));
    setParentDriverTouched(true);
  }, [concept]);

  const handleCancel = useCallback(() => {
    resetFields();
    setParentDriverIds(concept?.intent.parentConversationDriverNames);
    setParentDriverTouched(false);
    setEditing(false);
  }, [form]);

  const prodIntentsStatus = useSelector<ApiStatus>(selectLoadProdTaxonomiesStatus);
  const prodIntents = useSelector<ProdIntent[]>(selectProdIntents).map((intent) => intent.intent);
  const handleDeprecate = useCallback(() => {
    const updatedConcept = cloneDeep(concept);
    updatedConcept.state = ConceptState.DEPRECATED;
    onUpdate(updatedConcept, 'state');
    setEditing(false);
  }, [concept, onUpdate]);

  const handleDeprecateClick = () => {
    // Related labeling tasks in progress -> user has to archive or complete tasks firstabstarctTask
    // TODO: possibly a better way to determine if a task is still in progress?
    const conceptId = getId('concept', concept.name);
    const tasksForCurrentConcept = derivedLabelingTasks.filter((task) => task.labelingTask.taskData.taskDescriptor.conceptIds.includes(conceptId));
    const isRelatedTaskInProgress = tasksForCurrentConcept.some((task) => task.labelingTask.abstractTask.status === TaskStatus.TASK_IN_PROGRESS);

    if (isRelatedTaskInProgress) {
      setShowCannotDeprecateModal(true);
    } else {
      setShowDeprecateModal(true);
    }
  };

  const handleDeprecateConfirm = () => {
    handleDeprecate();
    setShowDeprecateModal(false);
  };

  let conceptRole: 'agent' | 'visitor';
  if (concept?.intent?.intentType === IntentIntentType.AGENT_INTENT) {
    conceptRole = 'agent';
  }
  if (concept?.intent?.intentType === IntentIntentType.VISITOR_INTENT) {
    conceptRole = 'visitor';
  }

  const driverOptions: EditableTagType<Concept>[] = drivers.map((driver) => ({
    key: getId('concept', driver.name),
    displayName: `Driver: ${driver.conceptTitle}`,
    value: driver,
  }));
  const driverValues = driverOptions?.filter((option) => parentDriverIds?.includes(option.key as string)) || [];
  const isOOB = concept?.conceptSource === ConceptConceptSource.SHARED;
  const isEditDisabled = isOOB && !isSharedLibrary;

  return (
    <Card radius="lg" p="lg">
      {!concept ? (
        <div className="intent-concept-editor-placeholder">Select an intent.</div>
      ) : (
        <>
          <Group position="apart">
            <EditableTagGroup
              values={driverValues}
              options={driverOptions}
              editing={editing}
              addButtonName="+ Other drivers"
              onChange={handleUpdateDrivers}
            />
            <Group spacing={0}>
              <Button
                variant="subtle"
                color="red"
                onClick={handleDeprecateClick}
              >
                Deprecate
              </Button>
              {!editing && (
                <Tooltip withinPortal label={isEditDisabled ? 'OOB intents cannot be edited' : ''} disabled={!isEditDisabled}>
                  <div>
                    <Button
                      variant="subtle"
                      onClick={() => setEditing(true)}
                      disabled={isEditDisabled}
                    >
                      Edit
                    </Button>
                  </div>
                </Tooltip>
              )}
              <Group hidden={!editing} spacing="xs">
                <Button
                  variant="subtle"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={disabledSave && !parentDriverTouched}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Group>
            </Group>
          </Group>

          {!editing && (
            <Group>
              <ConceptConfigTags concept={concept} />
              <ConceptTag
                role={conceptRole}
                value={concept.conceptTitle}
              />
            </Group>
          )}
          {editing ? (
            <ConceptForm
              form={form}
              concept={concept}
              onFieldsChange={shouldDisableSave}
              showTags={showTags}
              setDisabledSave={setDisabledSave}
            />
          ) : (
            <ConceptDetail concept={concept} showTags={showTags} />
          )}
          <div className="intent-concept-editor-buttons" hidden={!editing}>
            <CanDeprecateModal
              isOpen={showDeprecateModal}
              onClose={() => setShowDeprecateModal(false)}
              onConfirm={handleDeprecateConfirm}
              isProdIntent={prodIntentsStatus === 'succeeded' && prodIntents.includes(concept.conceptTitle)}
            />
            <CannotDeprecateModal
              isOpen={showCannotDeprecateModal}
              onClose={() => setShowCannotDeprecateModal(false)}
              onConfirm={() => {
                // open labeling tasks with intent filters applied
                const intentToFilterBy = getId('concept', concept.name);
                const url = `../labeling/tasks?archived=true&conceptType=INTENT&intent=${intentToFilterBy}`;
                navigate(url);
              }}
            />
          </div>
        </>
      )}
    </Card>
  );
}
