import React, { useMemo } from 'react';
import { SankeyChart } from './SankeyChart';
import { CallDriverHeader } from '../components/CallDriverHeader';
import { useAutoanalysisContext } from '..';

export default function ConversationFlow() {
  const { jobs, clusterVersions, clusterVersionName, setClusterVersionName } = useAutoanalysisContext();
  const job = useMemo(() => jobs.find((job) => job.payload.callDriverDiscoveryPayload.versionName === clusterVersionName), [jobs, clusterVersionName]);
  return (
    <>
      <CallDriverHeader
        clusterVersionName={clusterVersionName}
        setClusterVersionName={setClusterVersionName}
        clusterVersions={clusterVersions}
      />
      <SankeyChart
        elasticSearchName={clusterVersionName}
        job={job}
      />
    </>
  );
}
