import React from 'react';
import { Anchor, Badge } from '@mantine/core';
import { DataGrid } from 'components/DataGrid';
import externalLinkIcon from 'assets/svg/external-link.svg';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { truncateMiddle } from 'utils';
import { useCustomerParams } from 'hooks/useCustomerParams';

export interface ComparisonModelSummary {
  model1Count: number | null;
  model2Count: number | null;
  neitherCount: number | null;
  bothCount: number | null;
  model2Uri: string;
  taskSummary: string;
  dateCompleted: Date | null;
}

interface Props {
  dataSource: ComparisonModelSummary[]
}

export const QAComparisonTable = ({ dataSource }: Props) => {
  const customer = useCustomerParams();

  const columns = [
    {
      header: 'This model',
      accessorKey: 'model1Count',
      cell: (info) => {
        const model1Count = info.getValue() as number;
        if (!model1Count) return '--';
        return model1Count;
      },
    },
    {
      header: 'Comparison model',
      accessorKey: 'model2Count',
      cell: (info) => {
        const model2Count = info.getValue() as number;
        if (!model2Count) return '--';
        return model2Count;
      },
    },
    {
      id: 'neither',
      header: () => <Badge color="red">Neither</Badge>,
      accessorKey: 'neitherCount',
      cell: (info) => {
        const neitherCount = info.getValue() as number;
        if (!neitherCount) return '--';
        return neitherCount;
      },
    },
    {
      id: 'both',
      header: () => <Badge color="green">Both</Badge>,
      accessorKey: 'bothCount',
      cell: (info) => {
        const bothCount = info.getValue() as number;
        if (!bothCount) return '--';
        return bothCount;
      },
    },
    {
      header: 'Comparing model',
      accessorKey: 'model2Uri',
    },
    {
      header: 'Task Summary',
      accessorKey: 'taskSummary',
      cell: (info) => {
        const taskSummary = info.getValue() as string;
        return (
          <Anchor
            href={`/${customer.path}/qa/score-view/${taskSummary}`}
            target="_blank"
          >{`${truncateMiddle(taskSummary)} `}<img src={externalLinkIcon} alt="external-link" height={10} />
          </Anchor>
        );
      },
    },
    {
      header: 'Date completed',
      accessorKey: 'dateCompleted',
      cell: (info) => {
        const date = info.getValue() as string;
        if (!date) return 'Incomplete';
        return dayjs(date).format(DATE_TIME_FORMAT);
      },
    },
  ];

  return (
    <DataGrid
      style={{
        border: '1px solid #eaeaea',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
      columns={columns}
      dataSource={dataSource}
    />
  );
};
