import './ConceptTag.scss';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@mantine/core';

export type ConceptTagRole = 'agent' | 'visitor' | 'driver' | 'stage';
export type ConceptTagColor = 'green' | 'red' | 'blue';

interface Props {
  role?: ConceptTagRole;
  value: string;
  count?: number;
  color?: ConceptTagColor;
}

export function ConceptTag({ role, value, count, color = 'blue' }: Props): ReactElement {
  const values = value?.split('.') || [];
  const segments = values.map((seg, index) => {
    if (index === values.length - 1) {
      return seg;
    }
    return `${seg}.`;
  });
  if (count != null) {
    segments[segments.length - 1] = `${segments[segments.length - 1]}.(${count})`;
  }
  return (
    <Tooltip label={value} withinPortal withArrow>
      <div className="concept-tag-wrapper">
        {role && (<div className={classNames(['role-icon', role])}>{role[0].toUpperCase()}</div>)}
        {segments.map((seg, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={classNames('concept-tag-segment', color)}>{seg}</div>
        ))}
      </div>
    </Tooltip>
  );
}
