import React, { useState } from 'react';
import { Button, Group, Stack, Alert, NumberInput, Switch } from '@mantine/core';
import { SetTitleInput } from './components/SetTitleInput';
import { createOrderedSubset, createRandomSubset } from './utils';

interface ManualTabProps {
    conversationNames: string[];
    setTitle: string;
    setSetTitle: (value: string | React.ChangeEvent<any>) => void
    onClose: () => void;
    onCreate: (setItemNames: string[]) => Promise<void>;
}

export const ManualTab = ({ conversationNames, setTitle, setSetTitle, onClose, onCreate }: ManualTabProps) => {
  const [isCreatingSet, toggleCreatingSet] = useState(false);
  // Conversations selected from page
  const convos = conversationNames.length;
  const [sampleSize, setSampleSize] = useState<number>(convos);
  const [isRandomSelected, toggleRandomSelected] = useState(true);

  const handleClose = () => {
    onClose();
    setSetTitle('');
  };

  const handleCreateSet = async () => {
    toggleCreatingSet(true);
    let itemsToAdd = conversationNames;
    if (sampleSize < convos) {
      const createSubset = isRandomSelected ? createRandomSubset : createOrderedSubset;
      itemsToAdd = createSubset(conversationNames, sampleSize);
    }
    try {
      await onCreate(itemsToAdd);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      toggleCreatingSet(false);
      handleClose();
    }
  };

  const count = convos;
  return (
    <Stack>
      <Alert px={10} py={6} color={count === 0 ? 'red' : 'gray'}><b>{count}</b> conversations selected currently</Alert>
      <SetTitleInput disabled={convos === 0} value={setTitle} onChange={setSetTitle} />
      <NumberInput
        label="Max number of conversations"
        data-cy="conversation-sets-number-input"
        placeholder="Enter number of conversations"
        max={convos}
        min={1}
        value={sampleSize}
        onChange={(value) => {
          if (typeof value === 'number') {
            setSampleSize(value);
          }
        }}
      />
      <Switch
        label="Randomize subset"
        name="From current selection"
        checked={isRandomSelected}
        disabled={convos === 0 || sampleSize === convos}
        onChange={() => toggleRandomSelected((prev) => !prev)}
      />
      <Group position="right">
        <Button onClick={onClose} variant="subtle" disabled={isCreatingSet}>Cancel</Button>
        <Button loading={isCreatingSet} disabled={!setTitle || convos === 0} onClick={handleCreateSet}>Create Set</Button>
      </Group>
    </Stack>
  );
};
