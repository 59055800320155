import { useEffect, useMemo, useState } from 'react';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import { showNotification } from '@mantine/notifications';
import { useCustomerProfile, useCustomerUsecase, useCustomerParams } from 'hooks/useCustomerParams';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { ConversationApi } from 'services/conversationApi';
import { ListConversationsRequestFilter } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import dayjs from 'dayjs';

interface FoundConversationsConfig {
  agentIds: string[];
  dateStart: Date;
  dateEnd: Date;
  testSetDaysSuffix: number;
}

export interface ConversationCounts {
  agentId: string;
  trainCount: number;
  testCount: number;
}

export const useFoundConversations: (
  config: FoundConversationsConfig,
) => [ConversationCounts[], boolean] = (config) => {
  const { agentIds, dateStart, dateEnd, testSetDaysSuffix } = config;

  const parent = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { languageCode } = useCustomerParams();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [trainSetConversations, setTrainSetConversations] = useState<Conversation[]>([]);
  const [testSetConversations, setTestSetConversations] = useState<Conversation[]>([]);
  useEffect(() => {
    if (!dateStart || !dateEnd) {
      setTrainSetConversations([]);
      setTestSetConversations([]);
      return;
    }
    toggleLoading(true);
    const getConversations = async () => {
      const testTrainDate = dayjs(dateEnd).subtract(testSetDaysSuffix, 'day');
      const trainSetFilter: ListConversationsRequestFilter = {
        usecase,
        languageCode,
        agentIds: agentIds.length ? agentIds : undefined,
        beginTime: dateStart ? dayjs(dateStart).format(ACCEPTED_DATE_FORMAT) : undefined,
        endTime: dateEnd ? testTrainDate.format(ACCEPTED_DATE_FORMAT) : undefined,
      };
      const testSetFilter: ListConversationsRequestFilter = {
        usecase,
        languageCode,
        agentIds: agentIds.length ? agentIds : undefined,
        beginTime: dateStart ? testTrainDate.format(ACCEPTED_DATE_FORMAT) : undefined,
        endTime: dateEnd ? dayjs(dateEnd).format(ACCEPTED_DATE_FORMAT) : undefined,
      };
      const trainSetConversationRequest = await ConversationApi.listConversations({
        parent,
        filter: trainSetFilter,
        pageSize: 1000,
      });
      const testSetConversationRequest = await ConversationApi.listConversations({
        parent,
        filter: testSetFilter,
        pageSize: 1000,
      });

      Promise.all([trainSetConversationRequest, testSetConversationRequest])
        .then((response) => {
          setTrainSetConversations(response[0].conversations);
          setTestSetConversations(response[1].conversations);
        })
        .catch((err) => {
          showNotification({
            color: 'red',
            title: 'Error Fetching Conversations',
            message: err.message,
          });
        })
        .finally(() => {
          toggleLoading(false);
        });
    };

    getConversations();
  }, [dateStart, dateEnd, agentIds, testSetDaysSuffix]);

  const conversationCounts: ConversationCounts[] = useMemo(() => {
    // Create a map of agent IDs to conversation counts
    const counts = new Map<string, { trainCount: number; testCount: number }>();

    const addConversation = (agentIds: string[], train: boolean) => {
      agentIds.forEach((agentId) => {
        if (!counts.has(agentId)) {
          counts.set(agentId, { trainCount: 0, testCount: 0 });
        }
        if (train) {
          counts.get(agentId).trainCount++;
        } else {
          counts.get(agentId).testCount++;
        }
      });
    };

    trainSetConversations.forEach((conversation) => {
      addConversation(conversation.actorIds, true);
    });
    testSetConversations.forEach((conversation) => {
      addConversation(conversation.actorIds, false);
    });

    return Array.from(counts, ([agentId, { trainCount, testCount }]) => ({
      agentId,
      trainCount,
      testCount,
    }));
  }, [trainSetConversations, testSetConversations]);

  return [conversationCounts, isLoading];
};
