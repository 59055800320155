import React, { ReactElement, useCallback } from 'react';
import './ConceptTagsControl.scss';
import classNames from 'classnames';
import { ConceptConceptTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { cloneDeep } from 'lodash';
import { toTitleCase } from 'utils';

interface ConceptTagsControlProps {
  conceptTags: ConceptConceptTagType[];
  value: ConceptConceptTagType[] | ConceptConceptTagType | null;
  onChange: ((tags: ConceptConceptTagType[] | null) => void) | ((tag: ConceptConceptTagType | null) => void);
  allowMulti?: boolean;
}
export function ConceptTagsControl({
  conceptTags,
  value,
  onChange,
  allowMulti,
}: ConceptTagsControlProps): ReactElement {
  const tagValue = allowMulti ? null : value as ConceptConceptTagType;
  const tagsValue = allowMulti ? value as ConceptConceptTagType[] || [] : null;

  const onTagChange = allowMulti ? null : onChange as (tag: ConceptConceptTagType | null) => void;
  const onTagsChange = allowMulti ? onChange as (tags: ConceptConceptTagType[] | null) => void : null;

  const isTagSelected = useCallback((tag: ConceptConceptTagType) => {
    if (allowMulti) {
      return tagsValue?.includes(tag);
    }
    return tagValue === tag;
  }, [value]);

  const toggleTag = useCallback((tag?: ConceptConceptTagType) => {
    if (allowMulti) {
      // Toggle all.
      if (!tag) {
        onTagsChange(null);
      } else {
        const index = tagsValue?.findIndex((ele) => ele === tag);
        const nextValue = cloneDeep(tagsValue);
        if (index < 0) {
          nextValue.push(tag);
        } else {
          nextValue.splice(index, 1);
        }
        if (nextValue.length === 0) {
          onTagsChange(null);
        } else {
          onTagsChange(nextValue);
        }
      }
    } else if (tagValue === tag) {
      onTagChange(null);
    } else {
      onTagChange(tag);
    }
  }, [allowMulti, value]);

  return (
    <div className="concept-tags-control">
      {allowMulti && (
        <div
          key="all"
          className={classNames(['concept-tag', value === null || value.length === 0 ? 'selected' : ''])}
          onClick={(e) => {
            e.stopPropagation();
            toggleTag();
          }}
        >
          All
        </div>
      )}
      {
        conceptTags.map((tag) => (
          <div
            key={tag}
            className={classNames(['concept-tag', isTagSelected(tag) ? 'selected' : ''])}
            onClick={(e) => {
              e.stopPropagation();
              toggleTag(tag);
            }}
          >
            {toTitleCase(tag)}
          </div>
        ))
      }
    </div>
  );
}
