import React from 'react';
import { ActionIcon, Group, Select } from '@mantine/core';
import { LeftChevron, RightChevron } from './Icons';

const MAX_SEQUENTIAL_BUTTONS = 5;

interface PaginationButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    active?: boolean;
    transparent?: boolean;
    onClick?: () => void;
}

const PaginationButton = ({ active = false, transparent, children, ...rest }: PaginationButtonProps) => (
  <ActionIcon
    variant={active ? 'outline' : 'light'}
    color="blue"
    style={{ fontSize: 12, background: transparent ? 'transparent' : 'white' }}
    radius="sm"
    {...rest}
  >
    {children}
  </ActionIcon>
);

const InitialPageButtons = ({ pageCount, pageIndex, handleNewPage }) => (
  <>{new Array(Math.min(pageCount, MAX_SEQUENTIAL_BUTTONS)).fill(null).map((_, index) => (
    <PaginationButton
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      active={index === pageIndex}
      onClick={() => handleNewPage(index)}
    >
      {index + 1}
    </PaginationButton>
  ))}
  </>
);

const SlidingPageButtons = ({ pageIndex, handleNewPage }) => (
  <>{new Array(MAX_SEQUENTIAL_BUTTONS).fill(null).map((_, index) => {
    const value = pageIndex - 2 + index;
    return (
      <PaginationButton
        key={value}
        active={index === 2}
        onClick={() => handleNewPage(value)}
      >
        {value + 1}
      </PaginationButton>
    );
  })}
  </>
);

const FinalPageButtons = ({ pageCount, pageIndex, handleNewPage }) => (
  <>{new Array(MAX_SEQUENTIAL_BUTTONS).fill(null).map((_, index) => {
    const value = pageCount - MAX_SEQUENTIAL_BUTTONS + index;
    return (
      <PaginationButton
        key={value}
        active={value === pageIndex}
        onClick={() => handleNewPage(value)}
      >
        {value + 1}
      </PaginationButton>
    );
  })}
  </>
);

const Ellipsis = ({ icon, onClick }:{icon:string, onClick:() => void}) => {
  const [isHovered, toggledHover] = React.useState(false);
  return (
    <PaginationButton
      transparent
      color="gray"
      onClick={onClick}
      onMouseEnter={() => toggledHover(true)}
      onMouseLeave={() => toggledHover(false)}
    >
      {isHovered ? icon : '⋯'}
    </PaginationButton>
  );
};

// Component consists of 3 parts: the first 5 pages, the sliding pages, and the last 5 pages
export const Pagination = ({ table }) => {
  const pageCount = table.getPageCount();
  const { pageIndex } = table.getState().pagination;
  const handleNewPage = (newPageIndex: number) => table.setPageIndex(newPageIndex);
  const MEDIAN = Math.ceil(MAX_SEQUENTIAL_BUTTONS / 2);

  return (
    <Group position="right" my="sm">
      <PaginationButton
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <LeftChevron />
      </PaginationButton>

      {/* Determine whether to show single btn or first few */}
      {pageIndex >= MEDIAN && pageCount > MAX_SEQUENTIAL_BUTTONS ? (
        <PaginationButton
          active={pageIndex === 0}
          onClick={() => handleNewPage(0)}
        >
          {1}
        </PaginationButton>
      ) : <InitialPageButtons pageCount={pageCount} pageIndex={pageIndex} handleNewPage={handleNewPage} />}

      {/* Determine whether we need the sliding page buttons */}
      {pageCount > MAX_SEQUENTIAL_BUTTONS + 1
        && (
        <>
          {pageIndex > MEDIAN && (
          <Ellipsis
            icon="<<"
            onClick={() => {
              const newIndex = Math.max(0, pageIndex - MAX_SEQUENTIAL_BUTTONS);
              return handleNewPage(newIndex);
            }}
          />
          )}
          {pageIndex >= MEDIAN && pageCount - pageIndex > MEDIAN && <SlidingPageButtons pageIndex={pageIndex} handleNewPage={handleNewPage} />}
        </>
        )}

      {/* Determine whether we need trailing buttons */}
      {pageCount > MAX_SEQUENTIAL_BUTTONS
        && (
        <>{pageCount - pageIndex >= MAX_SEQUENTIAL_BUTTONS && pageCount > MAX_SEQUENTIAL_BUTTONS + 1 && (
        <Ellipsis
          icon=">>"
          onClick={() => {
            const newIndex = Math.min(pageIndex + MAX_SEQUENTIAL_BUTTONS, pageCount - 1);
            return handleNewPage(newIndex);
          }}
        />
        )}
          {pageCount - pageIndex > MEDIAN ? (
            <PaginationButton
              active={pageIndex === pageCount - 1}
              onClick={() => handleNewPage(pageCount - 1)}
            >
              {pageCount}
            </PaginationButton>
          ) : <FinalPageButtons pageCount={pageCount} pageIndex={pageIndex} handleNewPage={handleNewPage} />}
        </>
        )}
      <PaginationButton
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <RightChevron />
      </PaginationButton>
      <Select
        size="xs"
        wrapperProps={{ style: { width: 90 } }}
        value={`${table.getState().pagination.pageSize}`}
        placeholder="Rows per page"
        onChange={(val) => table.setPageSize(+val)}
        data={
            [
              { label: '5 / page', value: '5' },
              { label: '10 / page', value: '10' },
              { label: '20 / page', value: '20' },
              { label: '30 / page', value: '30' },
              { label: '40 / page', value: '40' },
              { label: '50 / page', value: '50' },
            ]
          }
      />
    </Group>
  );
};
