import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Radio, RadioChangeEvent } from 'antd';
import { useListIntents } from 'hooks/useListIntents';
import { getId } from 'common/resourceName';
import { ImportConceptIds, ImportData, InputFormType } from '.';
import styles from './styles.module.scss';

type Prop = {
  importData: ImportData;
  setMomentID: (value: string) => void;
  setDatasetURI: (value: string) => void;
  handleDatasetTypeChange: (value: string) => void;
  handleInputFormChange: (value: InputFormType) => void;
  setSelectedConceptIds: (value: ImportConceptIds) => void;
};

function ImportForm(props: Prop) {
  const {
    importData,
    setMomentID,
    setDatasetURI,
    handleDatasetTypeChange,
    handleInputFormChange,
    setSelectedConceptIds,
  } = props;
  const onDatasetTypeChange = (e: RadioChangeEvent) => {
    handleDatasetTypeChange(e.target.value);
  };
  const {
    importType,
    momentID,
    datasetURI,
    datasetType,
    selectedConceptIds,
    form,
    customerProfile,
    languageCode,
  } = importData;
  const [intents, setIntents] = useState<Map<string, string>>(new Map());
  const intentList = useListIntents({ parent: customerProfile, languageCode });

  useEffect(() => {
    const intentMap = new Map<string, string>(
      intentList.map((concept) => [concept.name, concept.conceptTitle]),
    );
    setIntents(intentMap);
  }, [intentList]);

  const handleConceptChange = (value: string, conceptType: 'source' | 'destination') => {
    if (conceptType === 'source') {
      const sourceConceptId = getId('concept', value);
      const sourceUsecaseId = intentList.find((intent) => intent.name === value).usecaseId;
      setSelectedConceptIds({ ...selectedConceptIds, sourceConceptId, sourceUsecaseId });
    } else {
      const destinationConceptId = getId('concept', value);
      const destinationUsecaseId = intentList.find((intent) => intent.name === value).usecaseId;
      setSelectedConceptIds({ ...selectedConceptIds, destinationConceptId, destinationUsecaseId });
    }
  };

  const filterConcepts = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const intentsMapObject = Array.from(intents, ([value, label]) => ({ value, label }));

  const renderForm = () => {
    switch (importType) {
      case 'momentID':
        return (
          <Form.Item name="Moment ID" className={styles.importFormItem}>
            <Input
              onChange={(event) => setMomentID(event.target.value)}
              value={momentID}
              placeholder="Moment ID"
              size="small"
            />
          </Form.Item>
        );
      case 'datasetURI':
        return (
          <Form.Item name="Training Dataset URI" className={styles.importFormItem}>
            <Input
              onChange={(event) => setDatasetURI(event.target.value)}
              value={datasetURI}
              placeholder={`${datasetType === 'train' ? 'Training' : 'Testing'} Dataset URI`}
              size="small"
            />
          </Form.Item>
        );
      case 'labels':
        return (
          <>
            <div className={styles.importLabelContainer}>
              <div>
                <p>Source Concept ID</p>
                <Form.Item
                  name="Source Concept ID"
                  label=""
                  initialValue=""
                  className={styles.importFormItem}
                >
                  <Select
                    style={{ width: 240 }}
                    showSearch
                    filterOption={filterConcepts}
                    onChange={(value) => handleConceptChange(value, 'source')}
                    placeholder="Source Concept ID"
                    options={intentsMapObject}
                  />
                </Form.Item>
              </div>
              {selectedConceptIds.sourceUsecaseId && (
                <p className={styles.importLabelCaption}>{selectedConceptIds.sourceUsecaseId}</p>
              )}
            </div>
            <div className={styles.importLabelContainer}>
              <div>
                <p>Destination Concept ID</p>
                <Form.Item
                  name="Destination Concept ID"
                  label=""
                  initialValue=""
                  className={styles.importFormItem}
                >
                  <Select
                    style={{ width: 240 }}
                    showSearch
                    filterOption={filterConcepts}
                    onChange={(value) => handleConceptChange(value, 'destination')}
                    placeholder="Destination Concept ID"
                    options={intentsMapObject}
                  />
                </Form.Item>
              </div>
              {selectedConceptIds.destinationUsecaseId && (
                <p className={styles.importLabelCaption}>{selectedConceptIds.destinationUsecaseId}</p>
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <div className={styles.importFormType}>
        <Form.Item
          name="Import Type"
          label=""
          initialValue="momentID"
          className={styles.importFormItem}
        >
          <Select
            style={{ width: 120 }}
            onChange={handleInputFormChange}
            options={[
              { value: 'momentID', label: 'Moment ID' },
              { value: 'datasetURI', label: 'Datasets' },
              { value: 'labels', label: 'Labels' },
            ]}
          />
        </Form.Item>
        {importType === 'datasetURI' && (
          <Radio.Group onChange={onDatasetTypeChange} defaultValue="train">
            <Radio.Button value="train">Train</Radio.Button>
            <Radio.Button value="test">Test</Radio.Button>
          </Radio.Group>
        )}
      </div>
      {renderForm()}
    </Form>
  );
}

export default ImportForm;
