/* eslint-disable dot-notation */
import React, { forwardRef, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Title, Text, Paper, Group, useMantineTheme, Indicator, Accordion, createStyles, Select, SelectItem, Anchor, Alert, Stack, Skeleton, Stepper } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { TIMEOUT_ERROR_MSG } from 'hooks/network';
import { useSelector } from 'hooks/reduxHooks';
import { ModelArtifact, ModelArtifactStatus, RetrieveModelScoresRequest, RetrieveModelScoresResponse } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { selectGetModelArtifactsStatus } from 'store/modelArtifact/selectors';
import { ApiStatus } from 'store/types';
import { useInterval } from '@mantine/hooks';
import { SimulationTaskApi } from 'services/simulationTaskApi';
import { SimulationTask, SimulationTaskDataSimulationType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import externalLinkIcon from 'assets/svg/icon-external-link.svg';
import { getId } from 'common/resourceName';
import { modelArtifactApi } from 'services/modelArtifactApi';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { useResourceCreation } from 'hooks/useResourceCreation';
import { selectServingModels } from 'store/modelBuilder/selectors';
import { Model } from 'store/modelBuilder/state';
import LetterAvatar from 'components/LetterAvatar';
import dayjs from 'dayjs';
import { User } from '@cresta/web-client/dist/cresta/v1/user';
import { selectUsersMap } from 'store/user/selectors';
import { openNotification, RpcError } from 'components/Notification';
import { UserContext } from 'context/UserContext';
import { DATE_TIME_FORMAT } from 'studioConstants';
import StatusBar from './StatusBar';
import DryRunResults from './DryRunResults';
import EvalAccordionItem from './EvalAccordionItem';
import { DeploymentOperation } from './NewDeployment';

interface ParamProps {
  taskId?: string;
}

// Return operation based on comparison between new and serving uri
export function getDeploymentOperation(newModelUri: string, servingModelUri: string): DeploymentOperation {
  if (newModelUri) return 'update';
  if (!newModelUri) return 'remove';
  return 'none';
}

// We return serving model uri as a fallback (it still needs to be sent even if operation is 'remove')
export function getDeploymentUri(newModelUri: string, servingModelUri: string): string {
  return newModelUri || servingModelUri || '';
}

export function NewDeploymentChecklist() {
  const theme = useMantineTheme();
  const { taskId }: ParamProps = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const customerProfile = useCustomerProfile();
  const { customerId, profileId, usecaseId, languageCode, path } = useCustomerParams();
  const apiStatus = useSelector<ApiStatus>(selectGetModelArtifactsStatus);
  const [modelArtifact, setModelArtifact] = useState<ModelArtifact>();
  const modelTaskId = getId('modelArtifact', modelArtifact?.name);
  const [simulationTasks, setSimulationTasks] = useState<SimulationTask[]>([]);
  const [selectedSimulationTaskId, setSelectedSimulationTaskId] = useState<string>();
  const [createResource] = useResourceCreation();
  const [submitting, setSubmitting] = useState(false);
  const [loadingModelScores, setLoadingModelScores] = useState(true);
  const servingModels: Model[] = useSelector<Model[]>(selectServingModels);
  const servingModelsMap = new Map<string, Model>(servingModels.map((model) => [model.type, model]));
  const usersMap = useSelector<Map<string, User>>(selectUsersMap);
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();

  // For Evaluation metrics table
  const [retrieveModelScoresArtifacts, setRetrieveModelScoresArtifacts] = useState<ModelArtifact[]>();
  const modelScoresMap: Map<string, ModelArtifact> = useMemo(() => new Map((retrieveModelScoresArtifacts || []).map((model) => [model.evaluationArtifact?.inputs?.modelUri, model])), [retrieveModelScoresArtifacts]);

  // New model uris
  const intentPolicyModelDeploymentUnit = modelArtifact?.deploymentArtifact?.inputs?.intentPolicyModelDeploymentUnit || {};
  const {
    agentIntentModelUri,
    visitorIntentModelUri,
    chatDriverModelUri,
  } = intentPolicyModelDeploymentUnit;

  // New models
  const newAgentModel = modelScoresMap.get(agentIntentModelUri);
  const newVisitorModel = modelScoresMap.get(visitorIntentModelUri);
  const newDriverModel = modelScoresMap.get(chatDriverModelUri);

  // Serving model uris
  const servingAgentModelUri = servingModelsMap?.get('agent')?.url;
  const servingVisitorModelUri = servingModelsMap?.get('visitor')?.url;
  const servingDriverModelUri = servingModelsMap?.get('driver')?.url;
  const servingPolicyModelUri = servingModelsMap?.get('policy')?.url;

  // Serving models
  const servingAgentModel = modelScoresMap.get(servingAgentModelUri);
  const servingVisitorModel = modelScoresMap.get(servingVisitorModelUri);
  const servingDriverModel = modelScoresMap.get(servingDriverModelUri);

  const promotedTaskId = modelArtifact?.deploymentArtifact?.outputs?.goldenRegressionTestUpdate?.promotedTaskId;

  // List simulation tasks
  const listSimulationTasks = useCallback(async () => {
    const response = await SimulationTaskApi.listSimulationTasks({
      parent: customerProfile,
      filter: {
        type: SimulationTaskDataSimulationType.REGRESSION_TEST,
        usecaseId,
        languageCode,
      },
    });
    setSimulationTasks(response.simulationTasks);
  }, [customerProfile, usecaseId, languageCode]);

  // Check or uncheck step
  const handleStepperClick = useCallback((step) => {
    if (activeStep === 3 && step === 3) {
      setActiveStep(4);
    } else {
      setActiveStep(step);
    }
  }, [setActiveStep, activeStep]);

  // Retrieve models with scores
  const retrieveModelScores = useCallback(async (modelUris: string[]) => {
    const request: RetrieveModelScoresRequest = {
      modelProject: `${customerProfile}/modelProjects/-`,
      modelUris,
      latestPerModelUri: true,
    };
    let response: RetrieveModelScoresResponse = {};
    try {
      response = await modelArtifactApi.retrieveModelScores(request);

      setRetrieveModelScoresArtifacts(response.modelArtifacts);
    } catch (err) {
      if (err['status'] !== 'NOT_FOUND') {
        throw err;
      }
    }
  }, [customerProfile, setRetrieveModelScoresArtifacts]);

  // Fetch simulation tasks when model artifact is loaded
  useEffect(() => {
    listSimulationTasks();
  }, [modelArtifact?.name]);

  // Poll until status changes
  const pollDryRun = useCallback(async () => {
    try {
      const response = await modelArtifactApi.getModelArtifact({
        name: `${customerProfile}/modelProjects/-/modelArtifacts/${taskId}`,
      });
      setModelArtifact(response.modelArtifact);
    } catch (err) {
      openNotification('error', 'Failed to get model artifact', undefined, err);
      throw err;
    }
  }, [customerProfile, taskId, setModelArtifact]);

  const { start, stop } = useInterval(pollDryRun, 5000);

  useEffect(() => {
    if (modelArtifact?.status !== ModelArtifactStatus.ACCEPTED) {
      // Stop polling
      stop();
    }
  }, [modelArtifact?.status]);

  useEffect(() => {
    if (loadingModelScores && modelArtifact?.deploymentArtifact?.inputs.intentPolicyModelDeploymentUnit) {
      // Retrieve model scores for all model uris
      const prodAndNewModels = new Set([
        modelArtifact?.deploymentArtifact?.inputs.intentPolicyModelDeploymentUnit?.agentIntentModelUri,
        modelArtifact?.deploymentArtifact?.inputs.intentPolicyModelDeploymentUnit?.visitorIntentModelUri,
        modelArtifact?.deploymentArtifact?.inputs.intentPolicyModelDeploymentUnit?.chatDriverModelUri,
        servingAgentModelUri,
        servingVisitorModelUri,
        servingDriverModelUri,
      ].filter((model) => model));

      retrieveModelScores(Array.from(prodAndNewModels.values()));
      setLoadingModelScores(false);
    }
  }, [modelArtifact?.deploymentArtifact?.inputs.intentPolicyModelDeploymentUnit]);

  // Non-dry-run deploy
  const handleSubmit = useCallback(() => {
    const { intentPolicyModelDeploymentUnit } = modelArtifact.deploymentArtifact?.inputs;
    const kwargs = modelArtifact?.deploymentArtifact?.inputs.kwargs as { [key: string]: string };
    setSubmitting(true);
    const body = {
      customer_id: customerId,
      profile_id: profileId,
      usecase_id: usecaseId,
      language_code: languageCode,
      profile_override: kwargs?.profile_override,
      skip_regression_test_check: kwargs?.skip_regression_test_check ? true : undefined,
      skip_taxonomy_compatibility_check: kwargs?.skip_taxonomy_compatibility_check ? true : undefined,
      dialogue_policy_snapshot_id: kwargs?.dialogue_policy_snapshot_id,
      commit_message: modelArtifact?.deploymentArtifact?.outputs.commitMessage,
      model_type: 'intent_policy',
      stacked_agent_model_url: intentPolicyModelDeploymentUnit.agentIntentModelUri,
      stacked_visitor_model_url: intentPolicyModelDeploymentUnit.visitorIntentModelUri,
      stacked_chat_driver_model_url: intentPolicyModelDeploymentUnit.chatDriverModelUri,
      rule_based_dialogue_policy_url: intentPolicyModelDeploymentUnit.dialoguePolicyModelUri,
      created_by: currentUser?.email,
      // Link this dry run with deployment
      dry_run: false,
      dry_run_task_id: parseInt(modelTaskId),
      deployment: {
        agent_intent_model: {
          url: getDeploymentUri(intentPolicyModelDeploymentUnit.agentIntentModelUri, servingAgentModelUri),
          operation: getDeploymentOperation(intentPolicyModelDeploymentUnit.agentIntentModelUri, servingAgentModelUri),
        },
        visitor_intent_model: {
          url: getDeploymentUri(intentPolicyModelDeploymentUnit.visitorIntentModelUri, servingVisitorModelUri),
          operation: getDeploymentOperation(intentPolicyModelDeploymentUnit.visitorIntentModelUri, servingVisitorModelUri),
        },
        chat_driver_model: {
          url: getDeploymentUri(intentPolicyModelDeploymentUnit.chatDriverModelUri, servingDriverModelUri),
          operation: getDeploymentOperation(intentPolicyModelDeploymentUnit.chatDriverModelUri, servingDriverModelUri),
        },
        dialogue_policy_model: {
          url: getDeploymentUri(intentPolicyModelDeploymentUnit.dialoguePolicyModelUri, servingPolicyModelUri),
          operation: getDeploymentOperation(intentPolicyModelDeploymentUnit.dialoguePolicyModelUri, servingPolicyModelUri),
        },
      },
    };
    createResource({
      resourceRoute: 'intents/deployment',
      createResourceBodyArgs: body,
      failedCallback: (msg) => {
        setSubmitting(false);
        if (msg === TIMEOUT_ERROR_MSG) {
          openNotification('info', 'Time-out', 'Not a failure. You may refresh the page and wait...');
        } else {
          const error: RpcError = {
            code: 0,
            message: msg,
            details: [],
          };
          openNotification('error', 'Failed to deploy', undefined, error);
        }
      },
      acceptedCallback: () => {
        // Delay redirect to prevent fetching data too soon and receiving stale serving models
        setTimeout(() => {
          openNotification('info', 'Deploy is in progress...', undefined);
          navigate(`/${path}/deployment/summary`);
          setSubmitting(false);
        }, 2000);
      },
      pollIntervalSeconds: 10,
    });
  }, [modelArtifact, setSubmitting, path, openNotification, navigate, createResource]);

  useEffect(() => {
    if (taskId) {
      pollDryRun();
      start();
    }
    return stop;
  }, [taskId]);

  // All simulation tasks if it’s new deployment.
  // For completed deployment, show the one from model artifact outputs
  const simulationTaskItems = useMemo(() => {
    const taskItems: SelectItem[] = [];
    if (!promotedTaskId) {
      simulationTasks.forEach((task) => {
        const taskId = getId('simulationTask', task.name);
        const item: SelectItem = {
          label: dayjs(task.abstractTask.createTime).format(DATE_TIME_FORMAT),
          value: taskId,
          task,
        };
        taskItems.push(item);
      });
    } else {
      const promotedTask = simulationTasks.find((task) => getId('simulationTask', task.name) === promotedTaskId);
      if (promotedTask) {
        taskItems.push({
          label: dayjs(promotedTask.abstractTask.createTime).format(DATE_TIME_FORMAT),
          value: promotedTaskId,
          task: promotedTask,
        });
      }
    }
    return taskItems;
  }, [simulationTasks, taskId, promotedTaskId]);

  const RegressionResultsSelectItem = forwardRef<HTMLDivElement, SelectItem>(
    (props, ref) => {
      const { label, value, task, ...other } = props;
      const { abstractTask } = task as SimulationTask;
      const user = usersMap.get(abstractTask.assigneeUserId);
      return (
        <div {...other} ref={ref}>
          <Group spacing="xs">
            <Group spacing="xs">
              <LetterAvatar name={user?.fullName || 'Unassigned'}/>
            </Group>
            <Text>{abstractTask.createTime ? dayjs(abstractTask.createTime).format(DATE_TIME_FORMAT) : null}</Text>
          </Group>
        </div>
      );
    },
  );

  // Icon for tasks select
  const getSimulationTaskUserIcon = (taskId: string): React.ReactNode => {
    const simulationTask: SimulationTask = simulationTasks.find((task) => getId('simulationTask', task.name) === taskId);
    if (simulationTask) {
      const user = usersMap.get(simulationTask.abstractTask.assigneeUserId);
      return <LetterAvatar name={user?.fullName || 'Unassigned'}/>;
    } else {
      return <LetterAvatar name="Unassigned"/>;
    }
  };

  const defaultSimulationTaskId = simulationTaskItems[0]?.value;

  const useStyles = createStyles(() => ({
    root: {
      background: theme.white,
      borderRadius: theme.radius.md,
    },
    control: {
      '&:hover': {
        background: 'white',
        borderRadius: theme.radius.md,
      },
    },
    item: {
      border: 'none',
    },
  }));
  const accordionStyles = useStyles();
  const { classes } = accordionStyles;

  // This hides the "Deploy" section
  const canDeploy = useMemo(() =>
    modelArtifact
    && modelArtifact.deploymentArtifact?.inputs.validateOnly, [modelArtifact]);

  const isStatusLoading = apiStatus === 'loading' || !servingModels?.length || !modelArtifact?.deploymentArtifact?.inputs?.intentPolicyModelDeploymentUnit;

  return (
    <PageContainer>
      <div>
        <Title order={1}>New deployment checklist</Title>
        <Text>Acknowledge each section and mark all sections to deploy</Text>
      </div>
      <StatusBar
        modelArtifact={modelArtifact}
        servingModelsMap={servingModelsMap}
        loading={isStatusLoading}
        submitting={submitting}
        step={activeStep}
        onSubmit={handleSubmit}
        canDeploy={canDeploy}
      />
      <Stepper
        iconSize={32}
        active={activeStep}
        onStepClick={handleStepperClick}
        orientation="vertical"
        styles={{
          root: {
            display: 'flex',
            gap: 30,
          },
          steps: {
            background: 'white',
            borderRadius: 10,
            padding: 20,
            width: 500,
          },
          content: {
            flex: 1,
            paddingTop: 'unset',
          },
        }}
      >
        <Stepper.Step label="Evaluation metrics are approved" description="I have evaluated and approved (via MLE or CD) the offline metrics with respect to common thresholds and OKRs (e.g., 0.9 WA F1 for chat). (if updating an existing model), I have manually validated (or with an MLE) that this model contains all prod intents, or only the changes that were intended.">
          {loadingModelScores ? (
            <Paper p="lg">
              <Stack>
                <Skeleton height={22} width={500}/>
                <Skeleton height={22} width={500}/>
              </Stack>
            </Paper>
          ) : (
            <Accordion
              chevronPosition="left"
              className={classes.root}
              classNames={classes}
            >
              <EvalAccordionItem accordionText="Latest evaluation on the agent model:" newModel={newAgentModel} servingModel={servingAgentModel}/>
              <EvalAccordionItem accordionText="Latest evaluation on the visitor model:" newModel={newVisitorModel} servingModel={servingVisitorModel}/>
              <EvalAccordionItem accordionText="Latest evaluation on the driver model:" newModel={newDriverModel} servingModel={servingDriverModel}/>
            </Accordion>
          )}
        </Stepper.Step>
        <Stepper.Step label="Simulator runs were completed and confirmed" description="I have validated the functional model performs to spec by using the Simulator to see *all* relevant predictions/SDX/etc.">
          <Paper py="md" px="lg">
            <Text>Complete <NavLink target="_blank" style={{ display: 'inline' }} to={`/${path}/simulator`}>simulator</NavLink> runs with a few chats and confirm the predictions</Text>
          </Paper>
        </Stepper.Step>
        <Stepper.Step label="Regression test results are reviewed" description="I have validated the technical serving ability of this model by passing regression with at least 5 chats/calls.">
          <Paper py="md" px="lg">
            <Text>Regression test results:</Text>
            {
              simulationTasks?.length > 0 ? (
                <>
                  <Select
                    mt="xs"
                    defaultValue={defaultSimulationTaskId}
                    value={selectedSimulationTaskId}
                    sx={{ width: 700 }}
                    onChange={(value) => setSelectedSimulationTaskId(value)}
                    data={simulationTaskItems}
                    itemComponent={RegressionResultsSelectItem}
                    icon={getSimulationTaskUserIcon(selectedSimulationTaskId || defaultSimulationTaskId)}
                  />
                  <Anchor href={`/${path}/simulator/simulation?taskId=${selectedSimulationTaskId || defaultSimulationTaskId}`} target="_blank" mt="sm"><img src={externalLinkIcon} alt="Open link"/>&nbsp;Open results</Anchor>
                </>
              ) : (
                <Alert color="red" mt="md" sx={{ width: 600 }}>Regression results were not found</Alert>
              )
            }
          </Paper>
        </Stepper.Step>
        <Stepper.Step label="Dry run results are reviewed" description="I have validated the technical serving ability of this model by passing a ‘dry run’ deployment.">
          <Paper py="md" px="lg">
            {modelArtifact?.status === ModelArtifactStatus.ACTIVE ? (
              <>
                <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.green[4]} radius="xl">
                  <Text>Dry run is completed. Here&apos;s summary:</Text>
                </Indicator>
                <DryRunResults modelArtifact={modelArtifact}/>
              </>
            ) : (
              <>
                {modelArtifact?.status === ModelArtifactStatus.FAILED ? (
                  <>
                    <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.red[4]} radius="xl">
                      <Text>Dry run failed.</Text>
                    </Indicator>
                    {modelArtifact?.errMsg && (
                      <Alert color="red" mt="md">{modelArtifact?.errMsg}</Alert>
                    )}
                  </>
                ) : (
                  <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.yellow[4]} radius="xl">
                    <Text>Dry run is running...</Text>
                  </Indicator>
                )}
              </>
            )}
          </Paper>
        </Stepper.Step>
      </Stepper>
    </PageContainer>
  );
}
