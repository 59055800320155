import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { CreateSetRequest, CreateSetResponse, GetSetRequest, GetSetResponse, ListSetsRequest, SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { SetApi } from 'services/setApi';
import { ListConversationsRequestFilter, ListConversationsResponse } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { ConversationApi } from 'services/conversationApi';
import { getId } from 'common/resourceName';
import { useDispatch } from 'hooks/reduxHooks';
import { listSets } from 'store/set/asyncThunks';
import { ProdUsersApi } from 'services/prodUsersApi';
import dayjs from 'dayjs';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import { AuthProtoRole, ListUsersRequest, ListUsersRequestListUsersFilter, ListUsersResponse } from '@cresta/web-client';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import { DatesRangeValue } from '@mantine/dates';
import { SetConfig as SetConfigPage } from './SetConfig';

export interface SetData {
  id:string
  name: string
  createTime: string
  convoQuantity: number
  exists?: boolean
  agentIdsSelected?: string[]
  dateRange?: DatesRangeValue
  min?: number
}
const DEFAULT_SET_DATA = { name: '', convoQuantity: 80, agentIdsSelected: [], min: 0, max: 20, createTime: '' };
export const SetConfig = ({ onNext }) => {
  const [isLoading, toggleLoading] = useState(false);
  const [allExistingSets, setAllExistingSets] = useState([]);
  const [newSetsAdded, setNewSetsAdded] = useState<SetData[]>([]);
  const [isModalOpen, toggleModal] = useState(false);
  const [existingSetsToAddToTask, setExistingSetsToAddToTask] = useState<SetData[]>([]);
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();

  // Handle Actors
  const [agents, setAgents] = useState([]);

  // Populate Agent dropdown from Actor Service
  useEffect(() => {
    const filter: ListUsersRequestListUsersFilter = {
      roles: [AuthProtoRole.AGENT],
    };
    const params: ListUsersRequest = {
      parent: `customers/${customer.customerId}`,
      pageSize: 1000,
      filter,
    };

    function alphabeticalByLabel(a: { label: string; }, b: { label: string; }) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }

    ProdUsersApi.listUsers(params).then((response: ListUsersResponse) => {
      const agentNames = response.users
        .map((agent) => ({ value: getId('prodUser', agent.name), label: agent.displayName || 'Error retrieving name' }))
        .sort(alphabeticalByLabel);
      setAgents(agentNames);
    }).catch((err) => console.error(err));
  }, []);

  // Populate existing sets
  useEffect(() => {
    const listSetsRequest: ListSetsRequest = {
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
      setType: SetType.CONVERSATION_SET_TYPE,
      languageCode: customer?.languageCode,
    };
    SetApi.listSets(listSetsRequest).then((res) => res.sets).then((sets) => Promise.all(sets.map((set) => {
      const getSetRequest: GetSetRequest = {
        name: set.name,
        usecase,
        languageCode: customer.languageCode,
      };

      return SetApi.getSet(getSetRequest).then(({ set }: GetSetResponse) => set);
    })).then((sets) => {
      const preExistingSets = sets.map((set) => ({
        createTime: set.createTime,
        id: set.name,
        name: set.setTitle,
        convoQuantity: set.setItemNames.length,
      }));
      setAllExistingSets(preExistingSets);
    })).catch((err) => {
      console.error('Error getting existing sets: ', err);
    });
  }, []);

  const dispatch = useDispatch();
  const handleReadyForTagConfig = async () => {
    toggleLoading(true);

    const newSetPromises = newSetsAdded.map((newSet) => {
      const filter: ListConversationsRequestFilter = {
        agentIds: newSet.agentIdsSelected.length ? newSet.agentIdsSelected : undefined,
        minimalConversationDuration: newSet.min ? `${newSet.min * 60}s` : undefined,
        beginTime: newSet.dateRange ? dayjs(newSet.dateRange[0]).format(ACCEPTED_DATE_FORMAT) : undefined,
        endTime: newSet.dateRange ? dayjs(newSet.dateRange[1]).format(ACCEPTED_DATE_FORMAT) : undefined,
        usecase,
        languageCode: customer.languageCode,
      };
      const pageSize = newSet.convoQuantity;
      return ConversationApi.listConversations({
        parent: customerProfile,
        pageSize,
        filter,
      }).then((res:ListConversationsResponse) => {
        const conversationNames = res.conversations.map((convo) => convo.name);
        return conversationNames;
      }).then((convoNames) => {
        const newSetRequest: CreateSetRequest = {
          parent: customerProfile,
          setId: getId('set', newSet.id),
          set: {
            usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
            name: newSet.id,
            setTitle: newSet.name,
            setItemNames: convoNames,
            setType: SetType.CONVERSATION_SET_TYPE,
            languageCode: customer?.languageCode,
          },
        };
        return SetApi.createSet(newSetRequest).then((newSetResponse: CreateSetResponse) => {
          console.info('New Set Creation Success', newSetResponse);
          dispatch(listSets({
            parent: customerProfile,
            usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
            languageCode: customer?.languageCode,
          }));
          const newlyCreatedSetName = newSetResponse.set.name;
          return newlyCreatedSetName;
        }).catch((err) => {
          console.error('New Set Creation Error', err);
          return 'ERROR';
        });
      });
    });

    // Once all new sets are created, add all setIds to parent state
    Promise.all(newSetPromises).then((newlyCreatedSetNames) => {
      const existingSetIds = existingSetsToAddToTask.map((set) => set.id);
      onNext([...newlyCreatedSetNames, ...existingSetIds]);
    }).catch((err) => {
      console.error('Error creating new sets: ', err);
    });
  };

  const onExistingSetAdd = (setData:SetData) => {
    setExistingSetsToAddToTask((current) => [...current, { ...setData, exists: true }]);
    toggleModal(false);
  };

  const onAddExistingSet = () => toggleModal(true);

  const onModalClose = () => toggleModal(false);

  const existingSetsAdded = existingSetsToAddToTask.filter((set) => set.exists);

  const onRemoveExistingSet = (index:number) => {
    setExistingSetsToAddToTask((current) => {
      const existingSetsAdded = [...current];
      existingSetsAdded.splice(index, 1);
      return existingSetsAdded;
    });
  };

  // NEW SET HANDLERS
  const onAddNewSet = () => {
    const createdId = uuid();
    const createdName = `${customerProfile}/sets/${createdId}`;
    const defaultData = { id: createdName, ...DEFAULT_SET_DATA };
    setNewSetsAdded((current) => [...current, defaultData]);
  };

  const onUpdateNewSet = (index: number, updatedData: Partial<SetData>) => {
    setNewSetsAdded((current) => {
      const newSetsAdded = [...current];
      newSetsAdded[index] = { ...newSetsAdded[index], ...updatedData };
      return newSetsAdded;
    });
  };

  const onClearNewSet = (index: number) => {
    setNewSetsAdded((current) => {
      const newSetsAdded = [...current];
      newSetsAdded[index] = { ...newSetsAdded[index], ...DEFAULT_SET_DATA };
      return newSetsAdded;
    });
  };

  const onRemoveNewSet = (index: number) => {
    setNewSetsAdded((current) => {
      const newSetsAdded = [...current];
      newSetsAdded.splice(index, 1);
      return newSetsAdded;
    });
  };

  return (
    <SetConfigPage
      // PAGE
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      // EXISTING SETS
      existingSetsAdded={existingSetsAdded}
      allExistingSets={allExistingSets}
      // existing set handlers
      onAddExistingSet={onAddExistingSet}
      onExistingSetAdd={onExistingSetAdd}
      onRemoveExistingSet={onRemoveExistingSet}
      // NEW SETS
      // new set data
      newSetsAdded={newSetsAdded}
      agents={agents}
      // new set handlers
      onAddNewSet={onAddNewSet}
      onUpdateNewSet={onUpdateNewSet}
      onClearNewSet={onClearNewSet}
      onRemoveNewSet={onRemoveNewSet}
      // SUBMIT
      handleReadyForTagConfig={handleReadyForTagConfig}
    />
  );
};
