import React from 'react';
import { Group, Text } from '@mantine/core';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { Link, useParams } from 'react-router-dom';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { getId } from 'common/resourceName';
import { DataGrid } from 'components/DataGrid';
import { ColumnDef } from '@tanstack/react-table';
import { ConceptDictionaryEntry } from '..';

const getPercent = (num: number, total: number) => Math.round((num / total) * 100);

const DataCell = ({ quantity, total }) => (

  <Group>
    <Text>{`${getPercent(quantity, total)}%`}</Text>
    <Text color="dimmed">{`${quantity}/${total}`}</Text>
  </Group>

);

interface TagsTableData {
  tag: ConceptDictionaryEntry;
  setData: {
      id: string;
      convoTotal: number;
      convoWithTag: number;
  }[];
  aggregate: {
      convoTotal: number;
      convoWithTag: number;
  };
}

export interface TagsTableProps {
  tagsTableData:TagsTableData[]
  tagsTableColumnHeaders: string[]
  tagFilter: TagTagType
}

export const TagsTable = (props: TagsTableProps) => {
  const { tagsTableData, tagsTableColumnHeaders, tagFilter } = props;
  const { taskId } = useParams<{ taskId: string }>();
  const customer = useCustomerParams();

  const getTagColums = () => tagsTableColumnHeaders.map((header, index) => ({
    accessorFn: (row) => row.setData,
    header,
    cell: (info) => {
      const data = info.getValue() as {
            id: string;
            convoTotal: number;
            convoWithTag: number;
          };
      const { id, convoWithTag, convoTotal } = data[index];
      return <DataCell key={id} quantity={convoWithTag} total={convoTotal} />;
    },
  }));

  const columns: ColumnDef<TagsTableData>[] = [
    {
      accessorFn: (row) => row.tag,
      header: 'Item',
      cell: (info) => {
        const tag = info.getValue() as ConceptDictionaryEntry;
        const conceptId = getId('concept', tag.value);
        return (
          <Link to={`/${customer.path}/analysisworkshop/${taskId}/analysis-summary/${conceptId}`}>
            <ManualAnalysisTag tagType={tag.tagType}>{tag.label}</ManualAnalysisTag>
          </Link>
        );
      },
    },
    ...getTagColums(),
    {
      accessorFn: (row) => row.aggregate,
      header: 'All',
      cell: (info) => {
        const aggregate = info.getValue() as {
          convoTotal: number;
          convoWithTag: number;
      };
        return (
          <DataCell
            quantity={aggregate.convoWithTag}
            total={aggregate.convoTotal}
          />
        );
      },
    },

  ];

  // filter props.tagsTableData by tag.tagType if tagFilter is set
  const filteredTagsTableData = tagFilter
    ? tagsTableData.filter((tag) => tag.tag.tagType === tagFilter)
    : tagsTableData;
  return (
    // @ts-ignore
    <DataGrid columns={columns} dataSource={filteredTagsTableData} initialPageSize={100} />
  );
};
