import { IntentMetadata, PreviewIntentPredictionsRequest } from '@cresta/web-client';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState, useContext } from 'react';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { CustomIntentApi } from 'services/customIntentApi';
import { AutoLabelingSessionContext } from 'context/AutoLabelingSessionContext';
import { Preview } from '../../types';
import { convertMessagePredictionsToPreviews } from './utils';

export const usePreviews: (
  intentMetadata: IntentMetadata,
  deps?: unknown[],
) => [
  {
    positivePreviews: Preview[];
    negativePreviews: Preview[];
  },
  boolean,
] = (intentMetadata, deps = []) => {
  const [previews, setPreviews] = useState<{
    positivePreviews: Preview[];
    negativePreviews: Preview[];
  }>({
    positivePreviews: [],
    negativePreviews: [],
  });
  const [isLoading, toggleLoading] = useState(false);
  const profile = useCustomerProfile();
  const { usecaseId, languageCode } = useCustomerParams();
  const { id: sessionId } = useContext(AutoLabelingSessionContext);

  const onFetchPreviews = async () => {
    toggleLoading(true);
    const request: PreviewIntentPredictionsRequest = {
      profile,
      intentMetadata,
      sessionId,
      usecaseId,
      languageCode,
    };
    const response = await CustomIntentApi.previewIntentPredictions(request).catch((e) => {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Error',
        message: e.message,
      });
      toggleLoading(false);
    });
    if (!response) return;
    const positiveMessagePredictions = response?.predictionPreview?.positiveMessagePredictions || [];
    const negativeMessagePredictions = response?.predictionPreview?.negativeMessagePredictions || [];
    const positivePreviews = convertMessagePredictionsToPreviews(positiveMessagePredictions);
    const negativePreviews = convertMessagePredictionsToPreviews(negativeMessagePredictions);
    setPreviews({
      positivePreviews,
      negativePreviews,
    });
    toggleLoading(false);
  };

  useEffect(() => {
    onFetchPreviews();
  }, deps);

  return [previews, isLoading];
};
