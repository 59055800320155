import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { Accordion, Alert, Checkbox, Group, Skeleton, Text, useMantineTheme } from '@mantine/core';
import { ModelEvaluationSummary } from 'components/ModelEvaluationSummary';
import dayjs from 'dayjs';
import { useCustomerParams } from 'hooks/useCustomerParams';
import React from 'react';
import { NavLink } from 'react-router-dom';

const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss a';

interface EvalAccordionItemProps {
  newModel: ModelArtifact;
  servingModel?: ModelArtifact;
  accordionText: string;
}

function getModelArtifactUri(modelArtifact: ModelArtifact) {
  return modelArtifact?.evaluationArtifact.inputs.modelUri;
}

function getModelArtifactScores(modelArtifact: ModelArtifact) {
  return modelArtifact?.evaluationArtifact.outputs.modelScores;
}

export default function EvalAccordionItem({
  newModel,
  servingModel,
  accordionText,
}: EvalAccordionItemProps) {
  const customer = useCustomerParams();
  const theme = useMantineTheme();

  const isSameModel = servingModel?.name === newModel?.name;

  if (!newModel) return null;

  return (
    <Accordion.Item value={accordionText}>
      <Accordion.Control>
        <Group>
          <Text>{accordionText}</Text>
          {
            newModel ? (
              <Text
                size="sm"
                py="xs"
                px="sm"
                sx={{
                  color: theme.colors.blue[5],
                  borderRadius: theme.radius.md,
                  border: `1px solid ${theme.colors.gray[1]}`,
                }}
              >{getModelArtifactUri(newModel)} ({dayjs(newModel.completeTime).format(DATE_TIME_FORMAT)})
              </Text>
              // TODO: Replace with link
              // <Anchor href={`/${composeCustomerUrl(customer)}/model-wizard/${taskId}?step=3`} target="_blank"><img src={externalLinkIcon} alt="Open model wizard"/>&nbsp;{getModelArtifactUri(newModel)}</Anchor>
            ) : (
              <Skeleton width={300} height={36} />
            )
          }
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        {newModel?.evaluationArtifact.outputs.modelScores ? (
          <ModelEvaluationSummary
            modelScores={getModelArtifactScores(newModel)}
            compareModelScores={!isSameModel && getModelArtifactScores(servingModel)}
            selectedModelName={getModelArtifactUri(newModel)}
            comparedModelName={!isSameModel && getModelArtifactUri(servingModel)}
            modelBuilderLink=""
            loading={false}
          />
        ) : (
          <>
            <Alert color="red" sx={{ width: 600 }}>
              <Text>No evaluation found.</Text>
              <Text>Please run model evaluations in Model Wizard.</Text>
              <NavLink to={`/${customer.path}/model-wizard`}>Go to Model Wizard</NavLink>
            </Alert>
            <Checkbox mt="md" label="I acknowledge and confirm to proceed without evaluation"/>
          </>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
}
