import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { State } from './state';

/** General reducer for starting get model artifact API. */
export function modelArtifactApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
}

/** General reducer for a failed get model artifact API. */
export function modelArtifactApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
    error: action.payload,
  };
}

/** Reducer for clear compared evaluation artifact. */
export function clearComparedEvaluationArtifactReducer(state: State, action: PayloadAction<void>): State {
  return {
    ...state,
    comparedEvaluationArtifact: null,
  };
}

/** Reducer for starting getEvaluationArtifact. */
export function getEvaluationArtifactPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    evaluationArtifact: null,
    comparedEvaluationArtifact: null,
    status: 'loading',
    error: null,
  };
}

/** Reducer for a failed getEvaluationArtifact. */
export function getEvaluationArtifactFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    evaluationArtifact: null,
    comparedEvaluationArtifact: null,
    status: 'failed',
    error: action.payload,
  };
}

/** Reducer for getEvaluationArtifact. */
export function getEvaluationArtifactSuccess(state: State, action: PayloadAction<ModelArtifact>): State {
  return {
    ...state,
    evaluationArtifact: action.payload,
    status: 'succeeded',
    error: null,
  };
}

/** Reducer for starting getEvaluationArtifactToCompare. */
export function getEvaluationArtifactToComparePending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    comparedEvaluationArtifact: null,
    error: null,
  };
}

/** Reducer for a failed getEvaluationArtifactToCompare. */
export function getEvaluationArtifactToCompareFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    comparedEvaluationArtifact: null,
    error: action.payload,
  };
}

/** Reducer for getEvaluationArtifactToCompare. */
export function getEvaluationArtifactToCompareSuccess(state: State, action: PayloadAction<ModelArtifact>): State {
  return {
    ...state,
    comparedEvaluationArtifact: action.payload,
    error: null,
  };
}

/** Reducer for list model artifact. */
export function listModelArtifactSuccess(state: State, action: PayloadAction<ModelArtifact[]>): State {
  return {
    ...state,
    modelArtifacts: action.payload,
    status: 'succeeded',
    error: null,
  };
}

/** Reducer for get model artifact. */
export function getModelArtifactSuccess(state: State, action: PayloadAction<ModelArtifact>): State {
  return {
    ...state,
    modelArtifacts: [...state.modelArtifacts, action.payload],
    status: 'succeeded',
    error: null,
  };
}
