import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUrlParam, getUrlParam, setUrlParam } from 'utils/query-string';

// TODO this generic typing doesn't work too well
export default function useUrlParam<T>(param: string, defaultValue: T = undefined):
    [T, (value: T) => void, () => void] {
  const navigate = useNavigate();
  const [touched, setTouched] = useState(false);
  let paramValue = getUrlParam(param, window.location.search) as unknown as T;
  if (!touched && paramValue == null) {
    paramValue = defaultValue;
  }
  const setParamValue = (value: T) => {
    setTouched(true);
    setUrlParam(param, value as unknown as string, navigate);
  };
  const deleteParam = () => deleteUrlParam(param, navigate);

  return [paramValue, setParamValue, deleteParam];
}
