import { CloseOutlined } from '@ant-design/icons';
import { MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Button } from '@mantine/core';
import classNames from 'classnames';
import CopyableValue from 'components/CopyableValue';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import Loading from 'components/Loading';
import React from 'react';

import './ConversationPreview.scss';

interface ConversationPreviewProps {
  showConversation: boolean;
  focusedMessageId: string;
  messages: Message[];
  onClose: () => void;
  customMessageTags?: (message: Message) => JSX.Element;
  loading?: boolean;
  children?: React.ReactNode;
}

export default function ConversationPreview({
  showConversation,
  focusedMessageId,
  messages,
  onClose,
  customMessageTags = () => null,
  loading = false,
  children,
}: ConversationPreviewProps) {
  const conversationId = messages?.[0]?.v2ConversationId;

  const renderHeader = () => {
    if (messages?.length > 0) {
      return (
        <>
          {conversationId && (
          <>
            <p>
              Conv. ID
            </p>
            <CopyableValue
              displayValue={`${conversationId?.substring(0, 4)}...`}
              copiedValue={conversationId}
            />
          </>
          )}
          <p>
            Message ID
          </p>
          {focusedMessageId && (
          <CopyableValue
            displayValue={`${focusedMessageId?.substring(0, 4)}...`}
            copiedValue={focusedMessageId}
          />
          )}
          <Button
            variant="subtle"
            color="gray"
            onClick={onClose}
            style={{
              marginLeft: 'auto',
            }}
          >
            <CloseOutlined/>
          </Button>
        </>
      );
    } else {
      return (
        <p>No conversation selected</p>
      );
    }
  };

  return (
    <div className={classNames(['conversation-preview', showConversation ? 'active' : 'inactive'])}>
      <div className="conversation-preview-header">
        {renderHeader()}
      </div>

      {loading ? (
        <div className="conversation-preview-loading">
          <Loading/>
        </div>
      ) : (
        <>
          <div className="conversation-preview-messages">
            <LabelingItemsDisplay
              context={MessageRawDataContextShown.FULL_CONVERSATION_SHOWN}
              messages={messages}
              targetMessageId={focusedMessageId}
              messageAnnotationsMap={new Map()}
              getConceptTitle={() => ''}
              annotationsDisplayType="concepts-colors"
              customMessageTags={customMessageTags}
            />
          </div>
          {children && (
          <div>
            {children}
          </div>
          )}
        </>
      )}
    </div>
  );
}
