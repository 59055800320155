import React from 'react';
import './LetterAvatar.scss';

interface LetterAvatarProps {
  name?: string;
  styles?: React.CSSProperties;
}

const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // eslint-disable-next-line no-bitwise
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return hexToRgb(`#${'00000'.substring(0, 6 - c.length)}${c}`);
};

const hexToRgb = (hex: string) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export default function LetterAvatar({ name, styles }: LetterAvatarProps) {
  if (!name) return null;
  const nameArr = String(name).split(' ');
  const hashColor = stringToColor(name);

  return (
    <span
      className="letter-avatar"
      style={{
        backgroundColor:
          name === 'Unassigned'
            ? 'rgba(100, 100, 100, 0.5)'
            : `rgba(${hashColor}, 0.2)`,
        color: name === 'Unassigned' ? 'white' : `rgb(${hashColor}`,
        ...styles,
      }}
    >
      {nameArr
        .map((name) => name[0])
        .join('')
        .toUpperCase()}
    </span>
  );
}
