import React from 'react';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  Annotation,
  BinaryValue,
  MessageRawData,
  MessageRawDataContextShown,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { BinaryQaView } from 'components/LabelingViews';
import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { GotoNextType } from 'pages/LabelingView';

interface MisclassificationQaViewProps {
  concepts: Concept[];
  messages: Message[];
  predictions: Prediction[];
  context: MessageRawDataContextShown,
  setContext: (context: MessageRawDataContextShown) => void;
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
}

export function MisclassificationQaView({
  concepts,
  messages,
  predictions,
  context,
  setContext,
  targetMessage,
  messageAnnotationsMap,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  createAnnotation,
  upsertAnnotations,
} : MisclassificationQaViewProps) {
  return (
    <BinaryQaView
      context={context}
      setContext={setContext}
      concepts={concepts}
      messages={messages}
      targetMessage={targetMessage}
      messageAnnotationsMap={messageAnnotationsMap}
      failedMessages={failedMessages}
      setTargetMessage={setTargetMessage}
      getConceptTitle={getConceptTitle}
      createAnnotation={createAnnotation}
      upsertAnnotations={upsertAnnotations}
      predictions={predictions}
    />
  );
}
