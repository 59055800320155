import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import './RoleIcon.scss';

interface RoleIconProps {
  conceptRole: 'agent'|'visitor'|'stage'|'stage-begin'|'stage-end'|'driver';
}

export function getConceptRole(concept: Concept) {
  switch (concept?.intent?.intentType) {
    case IntentIntentType.AGENT_INTENT:
      return 'agent';
    case IntentIntentType.VISITOR_INTENT:
      return 'visitor';
    case IntentIntentType.STAGE:
      return 'stage';
    case IntentIntentType.CONVERSATION_DRIVER:
      return 'driver';
    default:
      return 'stage';
  }
}

export function RoleIcon({
  conceptRole,
}: RoleIconProps): ReactElement {
  let symbol = '';
  switch (conceptRole) {
    case 'agent':
      symbol = 'A';
      break;
    case 'visitor':
      symbol = 'V';
      break;
    case 'stage':
      symbol = 'S';
      break;
    case 'stage-begin':
      symbol = 'SB';
      break;
    case 'stage-end':
      symbol = 'SE';
      break;
    case 'driver':
      symbol = 'D';
      break;
    default:
  }
  return <div className={classNames(['role-icon-wrapper', conceptRole])}>{symbol}</div>;
}
