import React from 'react';
import { Avatar, AvatarProps } from '@mantine/core';
import { stringToColor } from 'pages/AnalysisWorkshop/TaggingPage/utils/colors';

interface LetterAvatarProps extends AvatarProps {
  name?: string;
}

export default function MantineLetterAvatar({ name, size }: LetterAvatarProps) {
  if (!name) return null;
  const nameArr = String(name).split(' ');
  const hexColor = stringToColor(name);

  return (
    <Avatar
      styles={{
        placeholder: {
          backgroundColor:
                name === 'Unassigned'
                  ? 'rgba(100, 100, 100, 0.5)'
                  : `${hexColor}20`,
          color: name === 'Unassigned' ? 'white' : Array(10).fill(hexColor),
        },
      }}
      radius="xl"
      size={size}
    >
      {nameArr
        .map((name) => name[0])
        .join('')
        .toUpperCase()}
    </Avatar>
  );
}
