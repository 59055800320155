import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { ActionAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/action/action_annotation.pb';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/moment/moment_annotation.pb';
import { AnnotationState, BinaryValueValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { DetailedTypeFilter } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_detailed_type_filter.pb';
import {
  FetchAnnotationSummaryResponseConceptAnnotationSummary as AnnotationSummary,
  FetchAnnotationSummaryRequestFilter as SummaryFilter,
  SearchAnnotationsResponseAnnotationBundle,
  FetchCalibrationAnnotationsResponseCalibrationAnnotationTuple as CalibrationAnnotationTuple,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { ApiStatus } from 'store/types';

/** Filters for annotations. */
export interface AnnotationFilters {
  conceptIds?: string[];
  conceptType?: ConceptConceptType;
  states?: AnnotationState[];
  labels?: BinaryValueValue[];
  taskIds?: string[];
  dataSplit?: DataSplit;
  textContain?: string;
  detailedTypeFilter?: DetailedTypeFilter;
}

/** Calibration summary state. */
export interface CalibrationSummary {
  calibrationTaskId: string;
  nextPageToken: string;
  calibrationAnnotations: CalibrationAnnotationTuple[];
  mutualConsentPercentage: string;
  originalTaskConsentPercentage: string;
  dualTaskConsentPercentage: string;
}

/** Annotation state. */
export interface State {
  currentAnnotations: SearchAnnotationsResponseAnnotationBundle[];
  nextPageToken: string;
  summaryFilters: SummaryFilter;
  annotationFilters: AnnotationFilters;
  annotationSummaries: AnnotationSummary[];
  calibrationSummary: CalibrationSummary;
  trainAnnotationSummaries: AnnotationSummary[];
  testAnnotationSummaries: AnnotationSummary[];
  status: ApiStatus;
}

/** Initial annotation state. */
export const INITIAL_STATE: State = {
  currentAnnotations: [],
  nextPageToken: '',
  summaryFilters: {
    splits: [DataSplit.TRAIN],
    conceptType: ConceptConceptType.INTENT,
    detailedTypeFilter: {
      momentAnnotationTypes: [MomentAnnotationType.INTENT],
      momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
      actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
    },
  },
  annotationFilters: {
    conceptIds: [],
    conceptType: undefined,
    states: [
      AnnotationState.ACTIVE_REVIEWED,
      AnnotationState.ACTIVE_SKIP_REVIEW,
      AnnotationState.ACTIVE_MANUALLY_ADDED,
      AnnotationState.ACTIVE_AUTO_LABELED,
    ],
    labels: [],
    taskIds: [],
    dataSplit: DataSplit.TRAIN,
    textContain: '',
    detailedTypeFilter: {
      momentAnnotationTypes: [MomentAnnotationType.INTENT],
      momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
      actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
    },
  },
  annotationSummaries: [],
  calibrationSummary: {
    calibrationTaskId: '',
    nextPageToken: '',
    calibrationAnnotations: [],
    mutualConsentPercentage: '',
    originalTaskConsentPercentage: '',
    dualTaskConsentPercentage: '',
  },
  trainAnnotationSummaries: [],
  testAnnotationSummaries: [],
  status: 'idle',
};
