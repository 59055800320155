import { TextInput, Button, Group, Checkbox, Divider, useMantineTheme, Box } from '@mantine/core';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Concept, ConceptConceptSource, ConceptConceptTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { useSelector } from 'hooks/reduxHooks';
import { SearchOutlined } from '@ant-design/icons';
import { selectTestAnnotationSummaries, selectTrainAnnotationSummaries } from 'store/annotation/selectors';
import RegexTag from 'components/RegexTag';
import { selectAllConceptsMapFactory } from 'store/concept/selectors';
import MultiTags from 'components/MultiTags';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { IntentsDashboardTableRow } from '../../../models';
import styles from './styles.module.scss';

interface Props {
  onClickValidate: () => void;
  intentList: Concept[];
  selectChange: (selectedRows: IntentsDashboardTableRow[]) => void;
  selectRows: IntentsDashboardTableRow[];
}

export function IntentsDashboard({ onClickValidate, intentList, selectChange, selectRows }: Props) {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [isCrossCustomerFilter, setIsCrossCustomerFilter] = useState<boolean>(false);
  const [isOOBCapableFilter, setIsOOBCapableFilter] = useState<boolean>(false);
  const trainAnnotationSummaries = useSelector(selectTrainAnnotationSummaries);
  const testAnnotationSummaries = useSelector(selectTestAnnotationSummaries);
  const customer = useCustomerParams();
  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);
  const columns: ColumnsType<IntentsDashboardTableRow> = [
    {
      title: 'Intent',
      dataIndex: 'conceptTitle',
      key: 'conceptTitle',
      width: '30%',
      render: (conceptTitle: string, record: IntentsDashboardTableRow) => {
        const concept = allConceptsMap.get(getId('concept', record.name));
        const isCrossCustomerIntent = concept?.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
        const isOOBCapable = concept?.conceptSource === ConceptConceptSource.SHARED;
        return (
          <Group>
            <div className={styles.tableIntent}>{conceptTitle}</div>
            {isCrossCustomerIntent && (
            <MultiTags
              tags={['Cross-customer']}
              type="conceptTag"
              color="lightgreen"
              size="small"
            />
            )}
            {isOOBCapable && (
            <MultiTags
              tags={['OOB']}
              type="conceptTag"
              color="gray"
              size="small"
            />
            )}
          </Group>
        );
      },
    },
    {
      title: 'Train',
      children: [
        {
          title: 'Positive',
          dataIndex: 'trainPositive',
          key: 'trainPositive',
          sorter: (a: IntentsDashboardTableRow, b: IntentsDashboardTableRow) =>
            a.trainPositive - b.trainPositive,
          render: (trainPositive: number) => (
            <div style={{ color: trainPositive > 9 ? '#212121' : '#FF6B6B' }}>
              {trainPositive}
            </div>
          ),
        },
        {
          title: 'Negative',
          dataIndex: 'trainNegative',
          key: 'trainNegative',
          sorter: (a: IntentsDashboardTableRow, b: IntentsDashboardTableRow) =>
            a.trainNegative - b.trainNegative,
          render: (trainNegative: number) => (
            <div style={{ color: trainNegative > 9 ? '#212121' : '#FF6B6B' }}>
              {trainNegative}
            </div>
          ),
        },
      ],
    },
    {
      title: 'Test',
      children: [
        {
          title: 'Positive',
          dataIndex: 'testPositive',
          key: 'testPositive',
          sorter: (a: IntentsDashboardTableRow, b: IntentsDashboardTableRow) =>
            a.testPositive - b.testPositive,
          render: (testPositive: number) => (
            <div style={{ color: testPositive > 9 ? '#212121' : '#FF6B6B' }}>
              {testPositive}
            </div>
          ),
        },
        {
          title: 'Negative',
          dataIndex: 'testNegative',
          key: 'testNegative',
          sorter: (a: IntentsDashboardTableRow, b: IntentsDashboardTableRow) =>
            a.testNegative - b.testNegative,
          render: (testNegative: number) => (
            <div style={{ color: testNegative > 9 ? '#212121' : '#FF6B6B' }}>
              {testNegative}
            </div>
          ),
        },
      ],
    },
    {
      title: 'Regex',
      dataIndex: 'positiveRegex',
      key: 'regex',
      width: '30%',
      render: (regex: string[], record: IntentsDashboardTableRow) => (
        <div
          className={classNames([
            styles.editRegex,
            !record.positiveRegex?.length && !record.negativeRegex?.length ? styles.addRegex : '',
          ])}
          onClick={() => navigate(`/${customer.path}/edit-regex/${getId('concept', record.name)}`)}
        >
          {record.positiveRegex?.length > 0 || record.negativeRegex?.length > 0
            ? (<RegexTag positiveRegex={regex} negativeRegex={record.negativeRegex} />) : 'Add Regex'}
        </div>
      ),
    },
  ];
  const rowSelection = {
    renderCell: (checked, record, index, node) => {
      const message = validateIntent(record);
      if (message) {
        return <Tooltip title={message}>{node}</Tooltip>;
      }
      return node;
    },
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IntentsDashboardTableRow[],
    ) => {
      selectChange(selectedRows);
    },
    getCheckboxProps: (record: IntentsDashboardTableRow) => ({
      name: record.name,
      disabled: !!validateIntent(record),
    }),
    selectedRowKeys: selectRows.map((item) => item.name),
  };
  const combinationList = useMemo(() => {
    const defaultAnnotationCount = {
      modelReadyNegativeAnnotationCount: 0,
      modelReadyPositiveAnnotationCount: 0,
    };
    const concatList: IntentsDashboardTableRow[] = intentList.map((item) => {
      const conceptId = getId('concept', item.name);
      const {
        modelReadyNegativeAnnotationCount: trainModelReadyNegativeAnnotationCount,
        modelReadyPositiveAnnotationCount: trainModelReadyPositiveAnnotationCount,
      } = trainAnnotationSummaries?.find((item) => item.conceptId === conceptId) || defaultAnnotationCount;
      const {
        modelReadyNegativeAnnotationCount: testModelReadyNegativeAnnotationCount,
        modelReadyPositiveAnnotationCount: testModelReadyPositiveAnnotationCount,
      } = (testAnnotationSummaries)?.find((item) => item.conceptId === conceptId) || defaultAnnotationCount;
      const row: IntentsDashboardTableRow = {
        name: item.name,
        conceptTitle: item.conceptTitle,
        trainNegative: trainModelReadyNegativeAnnotationCount || 0,
        trainPositive: trainModelReadyPositiveAnnotationCount || 0,
        testNegative: testModelReadyNegativeAnnotationCount || 0,
        testPositive: testModelReadyPositiveAnnotationCount || 0,
        positiveRegex: item.intent.regexExpressions || [],
        negativeRegex: item.intent.negativeRegexExpressions || [],
        trainSet: trainModelReadyPositiveAnnotationCount < 10 ? 'Warning: <10 positives total' : 'Good',
        testSet: testModelReadyPositiveAnnotationCount < 10 ? 'Warning: <10 positives total' : 'Good',
      };
      if (row.positiveRegex.length + row.negativeRegex.length === 0 && row.trainPositive > 1) {
        row.model = 'neural';
      }
      if (row.positiveRegex.length + row.negativeRegex.length > 0 && row.trainPositive <= 1) {
        row.model = 'regex';
      }
      return row;
    });
    return concatList;
  }, [intentList, trainAnnotationSummaries, testAnnotationSummaries]);
  const filterTableData: IntentsDashboardTableRow[] = useMemo(
    () => combinationList.filter((row: IntentsDashboardTableRow) => {
      const concept = allConceptsMap.get(getId('concept', row.name));
      const isCrossCustomerIntent = concept?.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
      const isShared = concept?.conceptSource === ConceptConceptSource.SHARED;

      if (isOOBCapableFilter && !isShared) {
        return false;
      }
      if (isCrossCustomerFilter && !isCrossCustomerIntent) {
        return row.conceptTitle.includes(searchText);
      }
      if (!isCrossCustomerFilter && isCrossCustomerIntent) {
        return false;
      }
      return row.conceptTitle.includes(searchText);
    }),
    [searchText, combinationList, allConceptsMap, isOOBCapableFilter, isCrossCustomerFilter],
  );
  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardTitle}>
        <div className={styles.title}>
          Select intents to validate
        </div>
        <Button onClick={onClickValidate} disabled={!selectRows.length}>Validate</Button>
      </div>
      <div className={styles.dashboardSndTilte}>
        <span>{selectRows.length}</span>
        selected
      </div>
      <div className={styles.dashboardTable}>
        <Group position="left" align="center" pb="md" pt="xl">
          <Checkbox
            py="xs"
            label="Show cross-customer labeling intents"
            checked={isCrossCustomerFilter}
            onChange={(e) => setIsCrossCustomerFilter(e.target.checked)}
          />
          <Box>
            <Divider sx={{ height: 20 }} orientation="vertical" color={theme.colors.gray[1]} />
          </Box>
          <Checkbox
            label="Show OOB-capable only"
            py="xs"
            checked={isOOBCapableFilter}
            onChange={(e) => setIsOOBCapableFilter(e.target.checked)}
          />
          <TextInput
            radius="md"
            placeholder="Search"
            classNames={{
              input: styles.intentsInput,
              root: styles.intentsInputRoot,
            }}
            ml="auto"
            rightSection={<SearchOutlined />}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </Group>
        <Table
          columns={columns}
          dataSource={filterTableData}
          className={styles.tableIntent}
          rowKey="name"
          rowSelection={{
            type: 'checkbox',
            columnWidth: 80,
            ...rowSelection,
          }}
          pagination={false}
        />
      </div>

    </div>
  );
}

// Return valiation error message.
function validateIntent(record: IntentsDashboardTableRow): string {
  if (record.trainPositive <= 1
    && !record.positiveRegex?.length
    && !record.negativeRegex?.length) {
    return 'no train data or regex';
  }
  if (record.testPositive === 0 || record.testNegative === 0) {
    return 'no test data';
  }
  return '';
}
