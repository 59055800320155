import { CreateModelProjectRequest } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import {
  CreateModelWorkflowRequest,
  ListModelWorkflowsRequest,
  ListModelWorkflowsResponse,
  ModelWorkflow,
} from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification } from 'components/Notification';
import { ModelWorkflowApi } from 'services/modelWorkflowApi';

enum Action {
  MODEL_WORKFLOW_CREATE_MODEL_WORKFLOW = 'MODEL_WORKFLOW_CREATE_MODEL_WORKFLOW',
  MODEL_WORKFLOW_LIST_MODEL_WORKFLOW = 'MODEL_WORKFLOW_LIST_MODEL_WORKFLOW',
  MODEL_WORKFLOW_GET_MODEL_WORKFLOW = 'MODEL_WORKFLOW_GET_MODEL_WORKFLOW',
}
/** AsynceThunk for creating a model workflow */
export const createModelWorkflow = createAsyncThunk<ModelWorkflow, CreateModelWorkflowRequest>(Action.MODEL_WORKFLOW_CREATE_MODEL_WORKFLOW, async (requset: CreateModelProjectRequest) => {
  try {
    const createModelWorkflowResponse = await ModelWorkflowApi.createModelWorkflow(requset);
    return createModelWorkflowResponse.modelWorkflow;
  } catch (err) {
    openNotification('error', 'Failed to create model workflow', undefined, err);
    throw err;
  }
});

/** AsynceThunk for listing model workflows */
export const listModelWorkflow = createAsyncThunk<ListModelWorkflowsResponse, ListModelWorkflowsRequest>(Action.MODEL_WORKFLOW_LIST_MODEL_WORKFLOW, async (requset: ListModelWorkflowsRequest) => {
  try {
    const listModelWorkflowResponse = await ModelWorkflowApi.listModelWorkflow(requset);
    return listModelWorkflowResponse;
  } catch (err) {
    openNotification('error', 'Failed to list model workflows', undefined, err);
    throw err;
  }
});

/** AsynceThunk for get a model workflow */
export const getModelWorkflow = createAsyncThunk<ModelWorkflow, string>(Action.MODEL_WORKFLOW_GET_MODEL_WORKFLOW, async (name: string) => {
  try {
    const getModelWorkflowResponse = await ModelWorkflowApi.getModelWorkflow({ name });
    return getModelWorkflowResponse.modelWorkflow;
  } catch (err) {
    openNotification('error', 'Failed to get model workflow', undefined, err);
    throw err;
  }
});
