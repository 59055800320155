import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Radio, Select, Modal, Form, Result, FormInstance } from 'antd';
import classNames from 'classnames';
import {
  FetchAnnotationSummaryResponseConceptAnnotationSummary as AnnotationSummary,
  FetchAnnotationSummaryRequestFilter as SummaryFilter,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import {
  DataSplit,
  TargetType,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  IntentIntentType,
  Concept,
  ConceptConceptType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { ApiStatus } from 'store/types';
import {
  selectAnnotationSummaries,
  selectApiStatus,
  selectSummaryFilters,
} from 'store/annotation/selectors';
import { updateSummaryFilters } from 'store/annotation/annotationSlice';
import { toTitleCase } from 'utils';
import { getId } from 'common/resourceName';
import { fetchAnnotationSummary } from 'store/annotation/asyncThunks';
import { selectConceptsFactory } from 'store/concept/selectors';
import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { CorpusMetricType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { ActionAnnotationAdherenceType, MomentAnnotationAdherenceType } from '@cresta/web-client';
import { Button, Group, MultiSelect, Text } from '@mantine/core';
import { getConceptTypeFromTask, getMomentAnnotationType } from 'pages/LabelingView/utils';
import { PageContainer } from 'components/PageContainer';
import { orderBy } from 'lodash';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase, composeCustomerUrl } from 'hooks/useCustomerParams';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'components/Notification';
import { useApiPost } from 'hooks/network';
import { listConcepts } from 'store/concept/asyncThunks';
import ImportForm from './ImportForm';
import styles from './styles.module.scss';
import IntentLabelsTable from './IntentLabelsTable';
import EntityLabelsTable from './EntityLabelsTable';
import SummarizationLabelsTable from './SummarizationLabelsTable';

export interface AnnotationsCount {
  intentId: string;
  concepTitle: string;
  review: number;
  positive: number;
  negative: number;
  // Equals positive + negative.
  modelReady: number;
}

export interface ImportData {
  importType: string;
  momentID: string;
  datasetURI: string;
  datasetType: string;
  selectedConceptIds: ImportConceptIds;
  form: FormInstance;
  customerProfile: string;
  languageCode: string;
}

export interface AnnotationsCountWithMetrics extends AnnotationsCount {
  positiveAnnotationDiversity?: number;
  negativeAnnotationDiversity?: number;
  representativenessScore?: number;
}

export interface ImportConceptIds {
  sourceConceptId: string;
  sourceUsecaseId: string;
  destinationConceptId: string;
  destinationUsecaseId: string;
}

type LabelsProps = {
  children?: React.ReactNode | string | number;
};

export type InputFormType = 'momentID' | 'datasetURI' | 'labels';
export default function Labels({ children }: LabelsProps) {
  const customerProfile = useCustomerProfile();
  const apiPost = useApiPost(false);
  const usecase = useCustomerUsecase();
  const customer = useCustomerParams();
  const filters = useSelector<SummaryFilter>(selectSummaryFilters);
  const concepts = useSelector<Concept[]>(selectConceptsFactory(filters.conceptType));

  const intentsMap: Map<string, string> = new Map(
    concepts.map((concept) => [getId('concept', concept.name), concept.conceptTitle]),
  );
  const annotationSummeries = useSelector<AnnotationSummary[]>(selectAnnotationSummaries);
  // Create a map of concept id and summary data object
  const annotationSummariesMap = new Map<string, AnnotationsCountWithMetrics>(
    annotationSummeries.map((summary) => {
      const diversityMetric = summary.metrics.find(
        (metric) => metric.type === CorpusMetricType.INTRA_SAMPLE_DIVERSITY,
      );
      const representativenessMetric = summary.metrics.find(
        (metric) => metric.type === CorpusMetricType.INTER_SPLIT_REPRESENTATIVENESS,
      );

      const diversityScore = diversityMetric?.value ? diversityMetric.value : undefined;
      const representativenessScore = representativenessMetric?.value
        ? representativenessMetric.value
        : undefined;
      let modelReady = 0;
      switch (filters.conceptType) {
        case ConceptConceptType.SUMMARIZATION:
          modelReady = summary.modelReadyAnnotationCount;
          break;
        default:
          modelReady = summary.modelReadyNegativeAnnotationCount + summary.modelReadyPositiveAnnotationCount;
      }

      const count: AnnotationsCountWithMetrics = {
        intentId: summary.conceptId,
        concepTitle: intentsMap.get(summary.conceptId) || '',
        review: summary.needReviewAnnotationCount,
        positive: summary.modelReadyPositiveAnnotationCount,
        negative: summary.modelReadyNegativeAnnotationCount,
        modelReady,

        // METRICS
        positiveAnnotationDiversity: diversityScore,
        // unimplemented
        negativeAnnotationDiversity: undefined,
        representativenessScore,
        // onRefresh: TODO: refresh metrics
      };
      return [summary.conceptId, count];
    }),
  );

  // Table data withs annotation counts for every available concept
  const annotationsCount = useMemo(() => {
    const counts = concepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      const summary = annotationSummariesMap.get(conceptId);

      if (summary) {
        return summary;
      }

      const count: AnnotationsCountWithMetrics = {
        intentId: conceptId,
        concepTitle: concept.conceptTitle,
        review: 0,
        positive: 0,
        negative: 0,
        modelReady: 0,
        // METRICS
        positiveAnnotationDiversity: undefined,
        // unimplemented
        negativeAnnotationDiversity: undefined,
        representativenessScore: undefined,
      };
      return count;
    });

    return orderBy(counts, (summary) => summary.review + summary.modelReady, 'desc');
  }, [concepts, annotationSummariesMap]);

  const apiStatus = useSelector<ApiStatus>(selectApiStatus);
  const [{ page, pageSize }, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: 10,
  });
  const total = annotationsCount.length;
  const start = Math.min(((page - 1) * pageSize) + 1, total);
  const end = Math.min(page * pageSize, total);
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedIntentsToValidate, setSelectedIntentsToValidate] = useState<string[]>([]);
  const [creatingIntentValidationTask] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedIntentsToValidate([]);
  };

  const [form] = Form.useForm();
  const [importIsLoading, setImportIsLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [importType, setImportType] = useState<InputFormType>('momentID');
  const [momentID, setMomentID] = useState<string>('');
  const [datasetURI, setDatasetURI] = useState<string>('');
  const [datasetType, setDatasetType] = useState<string>('train');
  const [importSuccess, setImportSuccess] = useState<boolean>(false);
  const [selectedConceptIds, setSelectedConceptIds] = useState<ImportConceptIds>({
    sourceConceptId: '',
    sourceUsecaseId: '',
    destinationConceptId: '',
    destinationUsecaseId: '',
  });

  const handleSubmit = useCallback(async () => {
    console.info('Selected intents to validate', selectedIntentsToValidate);
    navigate(`/${customer.path}/annotations/intent-validation/`, {
      state: { intents: selectedIntentsToValidate },
    });
  }, [selectedIntentsToValidate, navigate, customer.path, handleModalClose]);

  const handleImportModalClose = () => {
    setImportModalOpen(false);
    setImportSuccess(false);
  };

  const handleInputFormChange = (value: InputFormType) => {
    setImportType(value);
  };
  const handleDatasetTypeChange = (value: string) => {
    setDatasetType(value);
  };
  const isInvalidForm = (importType === 'momentID' && momentID.length === 0)
    || (importType === 'datasetURI' && datasetURI.length === 0)
    || (importType === 'labels'
      && selectedConceptIds.sourceConceptId === selectedConceptIds.destinationConceptId);

  const handleImportSubmit = useCallback(
    async (importType: string, momentID: string, datasetURI: string, datasetType: string) => {
      let importData = {};
      let customerPath = '';
      switch (importType) {
        case 'momentID':
          importData = {
            moment_template_id: momentID,
          };
          customerPath = customer.path;
          break;
        case 'datasetURI':
          importData = {
            dataset_url: datasetURI,
            dataset_type: datasetType,
          };
          customerPath = customer.path;
          break;
        case 'labels':
          importData = {
            source_concept_id: selectedConceptIds.sourceConceptId,
            destination_concept_id: selectedConceptIds.destinationConceptId,
          };
          customerPath = composeCustomerUrl({
            customerId: customer.customerId,
            profileId: customer.profileId,
            usecaseId: selectedConceptIds.destinationUsecaseId,
            languageCode: customer.languageCode,
          });
          break;
        default:
      }
      try {
        setImportIsLoading(true);
        const response = await apiPost(`${customerPath}/import_dataset`, importData);
        if (response.status === 'ok') {
          setMomentID('');
          setDatasetURI('');
          setSelectedConceptIds({ sourceConceptId: '', sourceUsecaseId: '', destinationConceptId: '', destinationUsecaseId: '' });
          setImportSuccess(true);
          form.setFieldsValue({
            'Moment ID': '',
            'Training Dataset URI': '',
            'Testing Dataset URI': '',
            'Source Concept ID': '',
            'Destination Concept ID': '',
          });
        }
      } catch (error: any) {
        openNotification('error', error?.message, null, error);
      } finally {
        dispatch(
          listConcepts({
            parent: customerProfile,
            usecaseId: customer?.usecaseId,
            languageCode: customer?.languageCode,
          }),
        );
        dispatch(
          fetchAnnotationSummary({
            parent: customerProfile,
            usecase,
            languageCode: customer.languageCode,
          }),
        );
        setImportIsLoading(false);
      }
    },
    [handleImportModalClose, setImportIsLoading],
  );

  useEffect(() => {
    dispatch(
      fetchAnnotationSummary({
        parent: customerProfile,
        usecase,
        languageCode: customer.languageCode,
      }),
    );
  }, [dispatch, customer.path, filters]);

  const intentTypeValues = Object.keys(IntentIntentType).filter(
    (intentType) => intentType !== IntentIntentType.INTENT_TYPE_UNSPECIFIED,
  );

  const setSplitOrTarget = (splitOrTarget: DataSplit | TargetType.QA) => {
    if (splitOrTarget === TargetType.QA) {
      dispatch(
        updateSummaryFilters({
          ...filters,
          splits: [DataSplit.DATA_SPLIT_UNSPECIFIED],
          detailedTypeFilter: {
            ...filters.detailedTypeFilter,
            targetType: splitOrTarget,
          },
        }),
      );
    } else {
      dispatch(
        updateSummaryFilters({
          ...filters,
          splits: [splitOrTarget],
          detailedTypeFilter: {
            ...filters.detailedTypeFilter,
            // In order to include LABELING and QA (promoted) we need to reset targetType to undefined
            targetType: undefined,
          },
        }),
      );
    }
  };

  const LabelsTable = useMemo(() => {
    switch (filters?.conceptType) {
      case ConceptConceptType.INTENT:
        return IntentLabelsTable;
      case ConceptConceptType.ENTITY:
        return EntityLabelsTable;
      case ConceptConceptType.CONVERSATION_OUTCOME:
        return IntentLabelsTable;
      case ConceptConceptType.SUMMARIZATION:
        return SummarizationLabelsTable;
      default: {
        return IntentLabelsTable;
      }
    }
  }, [filters?.conceptType]);

  return (
    <PageContainer>
      <Group position="apart">
        <h1>Annotations</h1>
        <Group>
          <Button className={styles.submitButton} onClick={() => setImportModalOpen(true)}>
            Import
          </Button>
          <Button className={styles.submitButton} onClick={() => setModalOpen(true)}>
            Validate Intents
          </Button>
        </Group>
      </Group>
      <Group>
        <Select
          className={classNames([styles.filter, styles.conceptSelect])}
          showArrow
          size="large"
          style={{ width: '200px' }}
          placeholder="Concept"
          value={filters.conceptType}
          onChange={(value: ConceptConceptType) => {
            const momentAnnotationType = getMomentAnnotationType(getConceptTypeFromTask(value));
            const isUnspecified = momentAnnotationType === MomentAnnotationType.TYPE_UNSPECIFIED;

            dispatch(
              updateSummaryFilters({
                ...filters,
                conceptType: value,
                // Default to TRAIN when changing concept type
                splits: value === ConceptConceptType.ENTITY ? [DataSplit.TRAIN] : filters.splits,
                detailedTypeFilter: {
                  ...filters.detailedTypeFilter,
                  targetType: TargetType.LABELING,
                  // If type is unspecified, we send empty array
                  momentAnnotationTypes: isUnspecified ? [] : [momentAnnotationType],
                  momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
                  actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
                },
              }),
            );
          }}
        >
          <Select.Option key={ConceptConceptType.INTENT} value={ConceptConceptType.INTENT}>
            Intent
          </Select.Option>
          <Select.Option key={ConceptConceptType.ENTITY} value={ConceptConceptType.ENTITY}>
            Entity
          </Select.Option>
          <Select.Option
            key={ConceptConceptType.CONVERSATION_OUTCOME}
            value={ConceptConceptType.CONVERSATION_OUTCOME}
          >
            Conversation Outcome
          </Select.Option>
          <Select.Option
            key={ConceptConceptType.SUMMARIZATION}
            value={ConceptConceptType.SUMMARIZATION}
          >
            Summarization
          </Select.Option>
        </Select>
        <Text size="md">{`${start}-${end} of ${total} total`}</Text>
        <span className={styles.spacer} />
        <Radio.Group
          className={classNames([styles.filter])}
          style={{ marginLeft: 'auto' }}
          value={
            filters.detailedTypeFilter.targetType === TargetType.QA
              ? TargetType.QA
              : filters.splits[0]
          }
          onChange={(e) => setSplitOrTarget(e.target.value)}
        >
          {filters.conceptType === ConceptConceptType.INTENT && (
            <Radio.Button value={TargetType.QA}>QA</Radio.Button>
          )}
          <Radio.Button value={DataSplit.TRAIN}>Train</Radio.Button>
          <Radio.Button value={DataSplit.TEST}>Test</Radio.Button>
        </Radio.Group>
        {filters.conceptType === ConceptConceptType.INTENT && (
          <Select
            className={classNames([styles.filter, styles.select])}
            showArrow
            size="large"
            placeholder="Intent type"
            style={{ width: '200px' }}
            allowClear
            value={filters.intentType}
            onChange={(value: IntentIntentType) =>
              dispatch(
                updateSummaryFilters({
                  ...filters,
                  intentType: value,
                }),
              )}
          >
            {intentTypeValues.map((intentType) => (
              <Select.Option key={intentType} value={intentType}>
                {toTitleCase(intentType)}
              </Select.Option>
            ))}
          </Select>
        )}
      </Group>
      <LabelsTable
        filters={filters}
        loading={apiStatus === 'loading'}
        annotationsCount={annotationsCount}
        setPageInfo={setPageInfo}
      />
      <Modal
        className={styles.modelReadyModal}
        title="Select Intents"
        open={modalOpen}
        confirmLoading={creatingIntentValidationTask}
        width={500}
        footer={[
          <Button variant="subtle" key="back" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={creatingIntentValidationTask}
            onClick={handleSubmit}
            disabled={selectedIntentsToValidate.length === 0}
          >
            Validate
          </Button>,
        ]}
      >
        <MultiSelect
          placeholder="Select intents to validate"
          data={concepts.map((intent) => ({
            value: intent.name,
            label: intent.conceptTitle,
          }))}
          value={selectedIntentsToValidate}
          onChange={(value) => setSelectedIntentsToValidate(value)}
          clearable
        />
      </Modal>
      <Modal
        className={styles.modelReadyModal}
        title="Import Data"
        open={importModalOpen}
        confirmLoading={creatingIntentValidationTask}
        width={500}
        footer={[
          !importSuccess && (
            <Button variant="subtle" key="importBack" onClick={handleImportModalClose}>
              Cancel
            </Button>
          ),
          importSuccess ? (
            <Button
              key="importClose"
              loading={importIsLoading}
              onClick={() => handleImportModalClose()}
            >
              Close
            </Button>
          ) : (
            <Button
              key="importSubmit"
              loading={importIsLoading}
              onClick={() => handleImportSubmit(importType, momentID, datasetURI, datasetType)}
              disabled={isInvalidForm}
            >
              Submit
            </Button>
          ),
        ]}
      >
        {!importSuccess ? (
          <ImportForm
            importData={{
              importType,
              momentID,
              datasetURI,
              datasetType,
              selectedConceptIds,
              form,
              customerProfile,
              languageCode: customer?.languageCode,
            }}
            setMomentID={setMomentID}
            setDatasetURI={setDatasetURI}
            handleDatasetTypeChange={handleDatasetTypeChange}
            handleInputFormChange={handleInputFormChange}
            setSelectedConceptIds={setSelectedConceptIds}
          />
        ) : (
          <Result status="success" title="Successfully imported data!" />
        )}
      </Modal>
    </PageContainer>
  );
}
