/* eslint-disable react/no-array-index-key */
import { SuggestionActionPayloadSuggestionResult } from '@cresta/web-client/dist/cresta/ai_service/common';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { createStyles, Grid, useMantineTheme } from '@mantine/core';
import React, { useMemo } from 'react';
import { SuggestionBox } from './SuggestionBox';

interface MessageSuggestionsProps {
  predictions1: Prediction[];
  predictions2: Prediction[];
  hasSecondModel: boolean;
  selectedSuggestionIndex?: number;
  setSelectedSuggestionIndex?: (index: number) => void;
}

export default function MessageSuggestions({
  predictions1,
  predictions2,
  hasSecondModel,
  selectedSuggestionIndex,
  setSelectedSuggestionIndex,
}: MessageSuggestionsProps) {
  const theme = useMantineTheme();

  const useStyles = createStyles(() => ({
    suggestionsContainer: {
      textAlign: 'left',
      overflow: 'auto',
      padding: theme.spacing.md,
    },
    suggestionsTitle: {
      fontSize: theme.fontSizes.md,
      fontWeight: theme.other.weightBold,
      marginBottom: theme.spacing.sm,
    },
    suggestion: {
      backgroundColor: theme.colors.indigo[0],
      fontSize: theme.fontSizes.sm,
      padding: theme.spacing.xs,
      borderRadius: theme.radius.md,
      width: '100%',
      '& + &': {
        marginTop: theme.spacing.sm,
      },
    },
    suggestionModel1: {
      backgroundColor: theme.colors.orange[0],
    },
    suggestionModel2: {
      backgroundColor: theme.colors.purple[0],
    },
    selectedSuggestion: {
      border: `1px solid ${theme.colors.blue[5]}`,
    },
  }));
  const styles = useStyles();

  const suggestions1FlatList = useMemo(() => {
    const suggestions: SuggestionActionPayloadSuggestionResult[] = [];
    predictions1.forEach((prediction) => {
      prediction.action?.suggestionPayload?.results?.forEach((result) => {
        suggestions.push(result);
      });
    });
    return suggestions;
  }, [predictions1]);

  const suggestions2FlatList = useMemo(() => {
    const suggestions: SuggestionActionPayloadSuggestionResult[] = [];
    predictions2.forEach((prediction) => {
      prediction.action?.suggestionPayload?.results?.forEach((result) => {
        suggestions.push(result);
      });
    });
    return suggestions;
  }, [predictions2]);

  return (
    <div className={styles.classes.suggestionsContainer}>
      <div>
        <Grid gutter="xl">
          <Grid.Col span={hasSecondModel ? 6 : 12}>
            <p className={styles.classes.suggestionsTitle}>{hasSecondModel ? 'Suggestion Model 1' : 'Suggestions'}</p>
            {suggestions1FlatList?.map((suggestion, suggestionIndex) => (
              <SuggestionBox
                text={suggestion.text}
                key={`${suggestion.id}:${suggestionIndex}`}
                active={!hasSecondModel && selectedSuggestionIndex === suggestionIndex}
                type={hasSecondModel ? 'model-1' : 'single-model'}
                onClick={!hasSecondModel ? () => {
                  setSelectedSuggestionIndex(suggestionIndex);
                } : null}
              />
            ))}
          </Grid.Col>
          {hasSecondModel && (
            <Grid.Col span={6}>
              <p className={styles.classes.suggestionsTitle}>{hasSecondModel ? 'Suggestion Model 2' : 'Suggestions'}</p>
              {suggestions2FlatList?.map((suggestion, suggestionIndex) => (
                <SuggestionBox
                  text={suggestion.text}
                  key={`${suggestion.id}:${suggestionIndex}`}
                  type={hasSecondModel ? 'model-2' : 'single-model'}
                />
              ))}
            </Grid.Col>
          )}
        </Grid>
      </div>
    </div>
  );
}
