import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Typography } from 'antd';

type OptionsType = {
  key?: number | string;
  label: string;
  value: string;
}[];

export function SimplifiedSelectWithCreateNew({
  initialOptions,
  value,
  onChange,
}: {
  initialOptions: OptionsType;
  value?: string;
  onChange?: (value: string) => void;
}) {
  const [newValue, setNewValue] = useState('');
  const [initial, setInitial] = useState<OptionsType>([]);
  useEffect(() => setInitial(initialOptions), [initialOptions]);
  const [added, setAdded] = useState<OptionsType>([]);
  const onAdd = () => {
    setAdded([
      ...added,
      { key: newValue, label: newValue, value: newValue },
    ]);
    setNewValue('');
  };
  const [options, setOptions] = useState(initialOptions);
  useEffect(() => setOptions([...initial, ...added]), [initial, added]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  return (
    <SelectWithCreateNew
      newValue={newValue}
      onChangeNewValue={handleValueChange}
      onAdd={onAdd}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}
interface SelectWithCreateNewProps {
  newValue: string;
  onChangeNewValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  onAdd: (e: React.MouseEvent<HTMLElement>) => void;
  addPlaceholder?: string;
  options: OptionsType;
  value?: string;
  onChange?: (value: string) => void;
}

export default function SelectWithCreateNew({
  addPlaceholder = 'Add New',
  newValue,
  onAdd,
  onChangeNewValue,
  placeholder,
  options,
  value,
  onChange,
}: SelectWithCreateNewProps) {
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={placeholder}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space align="center" style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder={addPlaceholder}
              value={newValue}
              onChange={onChangeNewValue}
            />
            <Typography.Link onClick={onAdd} style={{ whiteSpace: 'nowrap' }}>
              <PlusOutlined /> Add
            </Typography.Link>
          </Space>
        </>
      )}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}
