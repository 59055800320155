export const validateCsvColumnHeaders = (row, expectedHeaders) => {
  const missingHeaders = [];

  const lowerCaseRow = {};
  for (const key in row) {
    if (Object.prototype.hasOwnProperty.call(row, key)) {
      lowerCaseRow[key.toLowerCase()] = row[key];
    }
  }

  for (const header of expectedHeaders) {
    if (lowerCaseRow[header] === undefined) {
      if (!missingHeaders.includes(header)) {
        missingHeaders.push(header);
      }
    }
  }

  if (missingHeaders.length > 0) {
    const errorMsg = `Missing headers: '${missingHeaders.join(
      ', ',
    )}'. Expected headers are: '${expectedHeaders.join(', ')}'`;
    throw new Error(errorMsg);
  }

  return lowerCaseRow;
};

export const convertRowKeysToLowercase = <T>(row: T) => {
  const lowerCaseRow: {
    [key: string]: any;
  } = {};
  for (const key in row) {
    if (Object.prototype.hasOwnProperty.call(row, key)) {
      lowerCaseRow[key.toLowerCase()] = row[key];
    }
  }
  return lowerCaseRow;
};
