import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDebouncedValue, useHotkeys } from '@mantine/hooks';
import { getId } from 'common/resourceName';
import { showNotification } from '@mantine/notifications';
import { FetchLabelingItemsRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { Notes as NotesComponent } from './Notes';
import { TaggingPageContext } from '../../TaggingPageProvider';
import { createNote, deleteNote, getFilteredNoteAnnotations } from './utils';

export enum NoteState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const Notes = ({ isComplete }: {isComplete: boolean}) => {
  const { taskName, currentSetId, activeConversationIndex, activeConversationId } = useContext(TaggingPageContext);
  const [note, setNote] = useState('');

  const noteInputRef = useRef<HTMLTextAreaElement>(null);

  const storedNote = useRef(null);
  const [debouncedNote] = useDebouncedValue(note, 1000);
  const [noteState, setNoteState] = useState<NoteState>(NoteState.IDLE);
  const [error, setError] = useState<string | null>(null);

  const labelingItemRequestConfig: FetchLabelingItemsRequest = {
    taskName,
    setIds: [currentSetId],
    conversationNumberStart: activeConversationIndex,
    conversationNumberEnd: activeConversationIndex + 1,
  };

  useEffect(() => {
    const initializeNote = async () => {
      setNoteState(NoteState.LOADING);
      const filteredAnnotations = await getFilteredNoteAnnotations(labelingItemRequestConfig);
      const mostRecentNoteAnnotation = filteredAnnotations[0];
      let originalNote = null;
      if (mostRecentNoteAnnotation) {
        originalNote = {
          id: getId('annotation', mostRecentNoteAnnotation.name),
          value: mostRecentNoteAnnotation.value.textValue.value,
        };
      }
      if (!originalNote) {
        storedNote.current = null;
        setNote('');
        setNoteState(NoteState.IDLE);
      } else {
        storedNote.current = originalNote;
        setNote(originalNote.value);
        setNoteState(NoteState.IDLE);
      }
    };
    initializeNote();
  }, [activeConversationIndex]);

  useHotkeys([
    ['tab', () => {
      if (noteInputRef.current) {
        noteInputRef.current.focus();
      }
    }],
  ]);

  const onDeleteNote = async () => {
    const filteredAnnotations = await getFilteredNoteAnnotations(labelingItemRequestConfig);
    const annotationIds = filteredAnnotations.map((annotation) => getId('annotation', annotation.name));
    console.info('Deleting note');
    const res = await deleteNote({ taskName, annotationIds });
    console.info(`Successfully deleted the following ids: ${annotationIds}`, 'res: ', res);
    storedNote.current = null;
  };

  const onCreateNote = async (config) => {
    console.info('Creating note');
    const res = await createNote(config);
    const savedNote = res.upsertedAnnotations?.[0];
    storedNote.current = {
      id: savedNote.name,
      value: savedNote.value?.textValue?.value,
    };
  };

  const handleNote = async () => {
    const config = {
      taskName,
      conversationNote: note,
      conversationId: activeConversationId,
      conversationPositionNumber: activeConversationIndex,
      setId: currentSetId,
    };

    // check if note is the same as the previous note
    if (debouncedNote === storedNote.current?.value) {
      setNoteState(NoteState.IDLE);
      return;
    }
    try {
    // if note is empty and previous one exists, delete the note
      if (!debouncedNote && storedNote.current) {
        await onDeleteNote();
        setNoteState(NoteState.IDLE);
        setError(null);
        return;
      }
      // only delete note if one previously existed
      if (storedNote.current) {
        await onDeleteNote();
      }

      // if new note is empty do nothing
      if (!debouncedNote) {
        setNoteState(NoteState.IDLE);
        setError(null);
        return;
      }

      await onCreateNote(config);
      setNoteState(NoteState.SUCCESS);
      setError(null);
    } catch (err: any) {
      console.error('Error updating note', err);
      setError(err.message);
      setNoteState(NoteState.ERROR);
      showNotification({
        color: 'red',
        title: 'Error updating note',
        message: 'Note was not updated',
      });
    }
  };

  // fire handleNote when debounce value changes
  useEffect(() => {
    handleNote();
  }, [debouncedNote]);

  return (
    <NotesComponent
      ref={noteInputRef}
      value={note}
      state={noteState}
      error={error}
      onChange={(e) => {
        setNoteState(NoteState.LOADING);
        setNote(e.currentTarget.value);
      }}
      isComplete={isComplete}
    />
  );
};
