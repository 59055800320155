/* eslint dot-notation: 0 */
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CopyableValue from 'components/CopyableValue';
import { Button } from '@mantine/core';
import {
  ModelArtifactArtifactType,
  ModelArtifactStatus,
  Intent,
  Dataset,
  DeploymentArtifactInputs,
  DeploymentArtifactOutputs,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import dayjs from 'dayjs';
import { KeyedModelArtifact } from './hooks';
import styles from './Workspace.module.scss';

interface IExistingResourceTable {
  artifactType: ModelArtifactArtifactType,
  tasks: KeyedModelArtifact[],
  selectedResources: string[],
  setSelectedResources: React.Dispatch<React.Key[]>,
  forFailedTasks?: boolean,
  onCopyToCreate: (x: KeyedModelArtifact) => void,
  onOpenTask: (x: KeyedModelArtifact) => void,
}

// Helper func to create a link to datadog with filters
function getDatadogLink(fromMinutes: number, toMinutes: number, datetime: string) {
  const t = dayjs(datetime);
  return (
    <a href={`https://app.datadoghq.com/logs?event&query=service%3Astudio&from_ts=${t.subtract(fromMinutes, 'minute').format('x')}&to_ts=${t.add(toMinutes, 'minute').format('x')}&live=false`} className={styles.inlineLink}>
      {t.format('YYYY-MM-DD HH:mm')}
    </a>
  );
}

// Tables to display ModelingTasks created. Based on input resourceType, the tables
// display various attributes of interests. For example, classifiers will show urls,
// datasets used to train, etc.
export function ExistingResourceTable(props: IExistingResourceTable) {
  const {
    artifactType,
    tasks,
    selectedResources,
    setSelectedResources,
    forFailedTasks = false,
    onCopyToCreate,
    onOpenTask,
  } = props;
  // TODO(daniel.hoske): Switch from any to a common interface type.
  const commonColumns: ColumnsType<KeyedModelArtifact> = [
    {
      title: 'TaskId',
      dataIndex: 'key',
      key: 'id',
      render: (value, row) => (<Button variant="subtle" onClick={() => onOpenTask(row)}>{value}</Button>),
      sorter: (a, b) => Number(a.key) - Number(b.key),
    },
    {
      title: 'Redo',
      key: 'copyToCreate',
      render: (value, row) => (<Button onClick={() => onCopyToCreate(row)}>Copy to create</Button>),
    },
    {
      title: 'TaskStatus',
      dataIndex: 'status',
      key: 'status',
      render: (value: ModelArtifactStatus) => {
        switch (value) {
          case ModelArtifactStatus.ACCEPTED:
            return 'TASK_IN_PROGRESS';
          case ModelArtifactStatus.ACTIVE:
            return 'TASK_COMPLETED';
          case ModelArtifactStatus.FAILED:
            return 'TASK_ERROR';
          default:
            return 'Unknown';
        }
      },
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createTime',
      key: 'createTime',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime(),
      render: (value) => (getDatadogLink(1, 15, value)),
    },
    {
      title: 'CompletedAt',
      dataIndex: 'completeTime',
      key: 'completeTime',
      sorter: (a, b) => new Date(a.completeTime).getTime() - new Date(b.completeTime).getTime(),
      render: (value) => (getDatadogLink(15, 1, value)),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedResources,
    onChange: setSelectedResources,
  };

  if (artifactType === ModelArtifactArtifactType.TAXONOMY) {
    const columns = commonColumns.concat([
      {
        title: 'Intents',
        dataIndex: ['taxonomyArtifact', 'outputs', 'taxonomy', 'intents'],
        key: 'intents',
        render: (v: Intent[]) => v?.map((i) => `${i.intentTaskType?.toLowerCase()}:${i.intentDisplayName}`).join(', '),
      },
    ]);
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.DATASET) {
    const columns = commonColumns.concat([
      {
        title: 'TaskType',
        dataIndex: ['datasetArtifact', 'inputs', 'kwargs'],
        key: 'task_type',
        render: (v) => (v ? v['intent_task_type'] : ''),
      },
      {
        title: 'TrainsetUrl',
        dataIndex: ['datasetArtifact', 'outputs', 'trainDataset', 'uri'],
        key: 'trainset_url',
        render: (value) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
              />
            )}
          </div>
        ),
      },
      {
        title: 'TestsetUrl',
        dataIndex: ['datasetArtifact', 'outputs', 'testDataset', 'uri'],
        key: 'testset_url',
        render: (value) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
              />
            )}
          </div>
        ),
      },
    ]);
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.SUGGESTIONS_DATASET) {
    let columns = commonColumns.concat([
      {
        title: 'Start date',
        dataIndex: ['suggestionsDatasetArtifact', 'inputs', 'startTime'],
        key: 'start_date',
        render: (v) => (v ? moment(v).format('YYYY-MM-DD') : ''),
      },
      {
        title: 'End date',
        dataIndex: ['suggestionsDatasetArtifact', 'inputs', 'endTime'],
        key: 'end_date',
        render: (v) => (v ? moment(v).format('YYYY-MM-DD') : ''),
      },
      {
        title: '#convs training set',
        dataIndex: ['suggestionsDatasetArtifact', 'outputs', 'convCnts', 'train_all'],
        key: 'conv_cnt_train_all',
      },
      {
        title: '#convs validation set',
        dataIndex: ['suggestionsDatasetArtifact', 'outputs', 'convCnts', 'val_all'],
        key: 'conv_cnt_val_all',
      },
      {
        title: '#convs test set',
        dataIndex: ['suggestionsDatasetArtifact', 'outputs', 'convCnts', 'test_all'],
        key: 'conv_cnt_test_all',
      },
      {
        title: 'Data',
        dataIndex: ['suggestionsDatasetArtifact', 'outputs', 'datasetPath'],
        key: 'dataset_path',
      },
      {
        title: 'WandB',
        dataIndex: ['suggestionsDatasetArtifact', 'outputs', 'wandbUri'],
        key: 'wandb_uri',
        render: (v) => (v ? <Button variant="subtle" component="a" href={v}>Link</Button> : ''),
      },
    ]);
    if (forFailedTasks) {
      columns = columns.concat([
        {
          title: 'Error',
          dataIndex: 'errMsg',
          key: 'error_message',
        },
      ]);
    }
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.NEURAL_CLASSIFIER) {
    const columns = commonColumns.concat([
      {
        title: 'TaskType',
        dataIndex: ['neuralClassifierArtifact', 'inputs', 'type'],
        key: 'task_type',
        render: (v: string) => v?.toLowerCase() || '',
      },
      {
        title: 'ModelUrl',
        dataIndex: ['neuralClassifierArtifact', 'outputs', 'modelUri'],
        key: 'model_url',
        render: (value) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
              />
            )}
          </div>
        ),
      },
    ]);
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.REGEX_CLASSIFIER) {
    const columns = commonColumns.concat([
      {
        title: 'TaskType',
        dataIndex: ['regexClassifierArtifact', 'inputs', 'type'],
        key: 'task_type',
        render: (v: string) => v?.toLowerCase() || '',
      },
      {
        title: 'ModelUrl',
        dataIndex: ['regexClassifierArtifact', 'outputs', 'modelUri'],
        key: 'regex_model_url',
        render: (value) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
              />
            )}
          </div>
        ),
      },
    ]);
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.STACKED_CLASSIFIER) {
    const columns = commonColumns.concat([
      {
        title: 'ModelUrl',
        dataIndex: ['stackedClassifierArtifact', 'outputs', 'modelUri'],
        key: 'stacked_model_url',
        render: (value) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
              />
            )}
          </div>
        ),
      },
    ]);
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.SUGGESTIONS_MODEL) {
    let columns = commonColumns.concat([
      {
        title: 'Model type',
        dataIndex: ['suggestionsModelArtifact', 'inputs', 'modelType'],
        key: 'model_type',
      },
      {
        title: 'Dataset',
        dataIndex: ['suggestionsModelArtifact', 'inputs', 'suggestionsDataset'],
        key: 'dataset',
      },
      {
        title: 'Experts fine-tuning requested',
        dataIndex: ['suggestionsModelArtifact', 'inputs', 'expertsShouldFinetune'],
        key: 'experts_should_finetune',
      },
      {
        title: 'Experts fine-tuning done',
        dataIndex: ['suggestionsModelArtifact', 'inputs', 'expertsWillFinetune'],
        key: 'experts_will_finetune',
      },
      {
        title: 'Model path',
        dataIndex: ['suggestionsModelArtifact', 'outputs', 'modelUri'],
        key: 'model_path',
      },
      {
        title: 'WandB',
        dataIndex: ['suggestionsModelArtifact', 'outputs', 'wandbUri'],
        key: 'wandb_uri',
        render: (v) => (v ? <Button component="a" variant="subtle" href={v}>Link</Button> : ''),
      },
    ]);
    if (forFailedTasks) {
      columns = columns.concat([
        {
          title: 'Error',
          dataIndex: 'errMsg',
          key: 'error_message',
        },
      ]);
    }
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.SUGGESTIONS_ENSEMBLE) {
    let columns = commonColumns;
    if (forFailedTasks) {
      columns = columns.concat([
        {
          title: 'Error',
          dataIndex: 'errMsg',
          key: 'error_message',
        },
      ]);
    } else {
      columns = commonColumns.concat([
        {
          title: 'EnsembleModelUrl',
          dataIndex: ['suggestionsEnsembleArtifact', 'outputs', 'suggestionsEnsembleModelUri'],
          key: 'ensemble_model',
          render: (value) => (
            <div className={classNames(['', styles.modelUrl])}>
              {value && (
                <CopyableValue
                  displayValue={value}
                  copiedValue={value}
                />
              )}
            </div>
          ),
        },
      ]);
    }

    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.EVALUATION) {
    let columns = commonColumns.concat([
      {
        title: 'ModelUrl',
        dataIndex: ['evaluationArtifact', 'inputs', 'modelUri'],
        key: 'model_url',
        render: (value) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
              />
            )}
          </div>
        ),
      },
      {
        title: 'TrainDatasetUrl',
        dataIndex: ['evaluationArtifact', 'inputs', 'trainDatasets'],
        key: 'train_dataset_url',
        render: (value: Dataset[]) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value?.length > 0 && value.map((v) => (
              <CopyableValue
                displayValue={v.uri}
                copiedValue={v.uri}
              />
            ))}
          </div>
        ),
      },
      {
        title: 'TestDatasetUrl',
        dataIndex: ['evaluationArtifact', 'inputs', 'testDatasets'],
        key: 'test_dataset_url',
        render: (value: Dataset[]) => (
          <div className={classNames(['', styles.modelUrl])}>
            {value?.length > 0 && value.map((v) => (
              <CopyableValue
                displayValue={v.uri}
                copiedValue={v.uri}
              />
            ))}
          </div>
        ),
      },
    ]);
    if (forFailedTasks) {
      columns = columns.concat([
        {
          title: 'Error',
          dataIndex: 'errMsg',
          key: 'error_message',
        },
      ]);
    }
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.REGRESSION) {
    let columns = commonColumns.concat([
      {
        title: 'ModelURL',
        dataIndex: ['regressionArtifact', 'inputs', 'modelUri'],
        key: 'model_url',
      },
      {
        title: 'ModelType',
        dataIndex: ['regressionArtifact', 'inputs', 'modelType'],
        key: 'model_type',
      },
      {
        title: 'Rule policy URL',
        dataIndex: ['regressionArtifact', 'inputs', 'rulePolicyUri'],
        key: 'rule_policy_url',
      },
    ]);
    if (forFailedTasks) {
      columns = columns.concat([
        {
          title: 'Error',
          dataIndex: 'errMsg',
          key: 'error_message',
        },
      ]);
    }
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }

  if (artifactType === ModelArtifactArtifactType.DEPLOYMENT) {
    const columns = commonColumns.concat([
      {
        title: 'isDryRun',
        dataIndex: ['deploymentArtifact', 'inputs', 'validateOnly'],
        key: 'dry_run',
      },
      {
        title: 'Model URL(s)',
        dataIndex: ['deploymentArtifact', 'inputs'],
        key: 'model_url',
        render: (value) => (
          <div className={classNames(['', styles.dependencyUrls])}>
            {getIntentPolicyModelDeploymentUnit(value).map((url) => (
              <div key={url} className={classNames(['', styles.modelUrl])}>
                {url && (
                  <CopyableValue
                    displayValue={url}
                    copiedValue={url}
                  />
                )}
              </div>
            ))}
          </div>
        ),
      },
      {
        title: 'Most Recent Regression Test ID',
        dataIndex: ['deploymentArtifact', 'outputs', 'checklist', 'latestRegressionTaskId'],
        key: 'most_recent_regression_test',
      },
      {
        title: 'Moment Updates',
        dataIndex: ['deploymentArtifact', 'outputs'],
        key: 'moment_update',
        render: (v) => getMomentUpdate(v),
      },
      {
        title: 'Deployment PR(s)',
        dataIndex: ['deploymentArtifact', 'outputs', 'deploymentPr'],
        key: 'deployment_pr',
        render: (value) => (
          <div className={classNames(['', styles.dependencyUrls])}>
            {value?.split(',').map((url) => (
              <div key={url} className={classNames(['', styles.modelUrl])}>
                {url && (
                  <CopyableValue
                    displayValue={url}
                    copiedValue={url}
                  />
                )}
              </div>
            )) || 'N/A'}
          </div>
        ),
      },
      {
        title: 'Error Msg',
        dataIndex: 'errMsg',
        key: 'error_message',
      },
    ]);
    return (
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tasks}
          columns={columns}
          size="small"
        />
      </div>
    );
  }
  return (
    <div>TODO
    </div>
  );
}

function getMomentUpdate(outputs: DeploymentArtifactOutputs): string {
  if (outputs?.momentUpdate) {
    return `Inserted-> ${outputs?.momentUpdate?.insertedMoments?.map((i) => `${i.taxonomy}:${i.detailedType?.slice(14)}`)?.join('  --  ') || 'none'}  &&& Activated-> ${outputs?.momentUpdate?.activatedMoments?.map((i) => `${i.taxonomy}:${i.detailedType?.slice(14)}`)?.join('  --  ') || 'none'}  &&& Deactivated-> ${outputs?.momentUpdate?.deactivatedMoments?.map((i) => `${i.taxonomy}:${i.detailedType?.slice(14)}`)?.join('  --  ') || 'none'}`;
  } else {
    return 'unknown';
  }
}

function getIntentPolicyModelDeploymentUnit(inputs: DeploymentArtifactInputs): string[] {
  if (inputs?.intentPolicyModelDeploymentUnit) {
    const urls = [];
    const modelUrls = inputs.intentPolicyModelDeploymentUnit;
    if (modelUrls?.agentIntentModelUri) {
      urls.push(modelUrls?.agentIntentModelUri);
    }
    if (modelUrls?.visitorIntentModelUri) {
      urls.push(modelUrls?.visitorIntentModelUri);
    }
    if (modelUrls?.chatDriverModelUri) {
      urls.push(modelUrls?.chatDriverModelUri);
    }
    if (modelUrls?.dialoguePolicyModelUri) {
      urls.push(modelUrls?.dialoguePolicyModelUri);
    }
    return urls;
  }
  return [];
}
