import React from 'react';
import { createStyles, Loader, MultiSelect } from '@mantine/core';

interface AgentSelectProps {
  data: { value: string, label: string }[];
  value: string[];
  handleAgentChange: (newValue?: string[]) => void;
  isLoading?: boolean;
  error?: string | undefined;
}

const useStyles = createStyles(() => ({
  input: {
    maxHeight: 40,
    overflowY: 'auto',
  },
}));

export const AgentSelect = ({ isLoading, data, value, error, handleAgentChange }: AgentSelectProps) => {
  const { classes } = useStyles();
  return (
    <MultiSelect
      classNames={{ input: classes.input }}
      searchable
      clearable
      label="Select Agents"
      data-cy="conversation-sets-type-select"
      placeholder="Select agents"
      value={value}
      onChange={handleAgentChange}
      data={data}
      error={error}
      rightSection={isLoading && <Loader size="xs" />}
    />
  );
};
