/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Select } from 'antd';

import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectApiStatus, selectConceptChanges, selectNextPageToken } from 'store/conceptChange/selectors';
import { ConceptChange, ConceptChangeChangeType } from '@cresta/web-client/dist/cresta/v1/studio/conceptchange/conceptchange_service.pb';
import { ApiStatus } from 'store/types';
import { listConceptChanges } from 'store/conceptChange/asyncThunks';
import { selectConceptsFactory } from 'store/concept/selectors';
import { Concept, ConceptConceptType, ConceptState, IntentIntentType, UpdateConceptRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { NavLink } from 'react-router-dom';
import { IntentStateIcon } from 'components/TaxonomyTree';
import DiffMatchPatch from 'diff-match-patch';
import { getId } from 'common/resourceName';
import moment from 'moment';
import { RoleIcon } from 'components/RoleIcon';
import { selectUsersMap } from 'store/user/selectors';
import { User } from '@cresta/web-client/dist/cresta/v1/user';
import { clearConceptChanges } from 'store/conceptChange/conceptChangeSlice';
import { Button, Box, Skeleton, Stack, Text } from '@mantine/core';
import { UserContext } from 'context/UserContext';
import { updateConcept } from 'store/concept/asyncThunks';
import ConceptTag from 'components/ConceptTag';
import { createConfirm } from 'components/ConfirmModal';
import styles from './styles.module.scss';
import iconDeprecate from '../../assets/svg/icon-deprecate.svg';
import iconCreate from '../../assets/svg/icon-create.svg';
import iconUpdate from '../../assets/svg/icon-update.svg';

enum CustomConceptChangeType {
  DEPRECATE = 'DEPRECATE',
  UNDEPRECATE = 'UNDEPRECATE',
}

const CHANGES_MAP = {
  [ConceptChangeChangeType.CHANGE_TYPE_UNSPECIFIED]: { text: 'Unspecified', icon: iconUpdate },
  [ConceptChangeChangeType.CREATE]: { text: 'Create', icon: iconCreate },
  [ConceptChangeChangeType.DELETE]: { text: 'Delete', icon: iconDeprecate },
  [ConceptChangeChangeType.UPDATE]: { text: 'Update', icon: iconUpdate },
  [ConceptChangeChangeType.BREAKING_CHANGE]: { text: 'Breaking change', icon: iconUpdate },
  // (Un)deprecate is the same as UPDATE from BE point of view, so we need to create a custom type for the multiselect
  [CustomConceptChangeType.DEPRECATE]: { text: 'Deprecate', icon: iconDeprecate },
  [CustomConceptChangeType.UNDEPRECATE]: { text: 'Undeprecate', icon: iconCreate },
};

// Check if the concept change was to deprecate intent
function isDeprecateConceptChange(change: ConceptChange) {
  const isChangeType = change.changeType === ConceptChangeChangeType.UPDATE;
  const isStateMask = change.updateMask === 'state';
  const isDeprecate = change.updatedConcept.state === ConceptState.DEPRECATED;
  return isChangeType && isDeprecate && isStateMask;
}

// Check if the concept change was to undeprecate intent
function isUndeprecateConceptChange(change: ConceptChange) {
  const isChangeType = change.changeType === ConceptChangeChangeType.UPDATE;
  const isStateMask = change.updateMask === 'state';
  const isUndeprecate = change.originalConcept.state === ConceptState.DEPRECATED && change.updatedConcept.state === ConceptState.CREATED;
  return isChangeType && isUndeprecate && isStateMask;
}

export default function ChangesHistory() {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedIntents, setSelectedIntents] = useState([]);
  const customer = useCustomerParams();
  const conceptChanges = useSelector<ConceptChange[]>(selectConceptChanges);
  const concepts = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT));
  const apiStatus = useSelector<ApiStatus>(selectApiStatus);
  const customerProfile = useCustomerProfile();
  const nextPageToken = useSelector<string>(selectNextPageToken);
  const dispatch = useDispatch();
  const usersMap = useSelector<Map<string, User>>(selectUsersMap);
  const currentUser = useContext(UserContext);

  const handleUpdateConcept = useCallback((concept: Concept, updateMask: string) => {
    const request: UpdateConceptRequest = {
      concept,
      updateMask,
      userResourceName: currentUser?.name,
    };
    dispatch(updateConcept(request));
  }, [dispatch, currentUser]);

  const undeprecateConcept = useCallback(async (concept: Concept) => {
    const confirm = await createConfirm<boolean>({
      title: 'Are you sure you want to undeprecate this intent?',
      content: <ConceptTag value={concept.conceptTitle}/>,
      buttons: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'Cancel',
          buttonProps: {
            variant: 'subtle',
          },
          value: false,
        },
      ],
    });

    if (confirm) {
      handleUpdateConcept({
        name: concept.name,
        state: ConceptState.CREATED,
      }, 'state');
    }
  }, [handleUpdateConcept]);

  const composeDescription = (change: ConceptChange) => {
    const user = usersMap.get(getId('user', change.userId));
    const descElements: React.ReactNode[] = [<b>{user ? user.fullName : 'Unknown'}</b>];
    const conceptLink = <NavLink to={`/${customer.path}/intent/taxonomy`}>{change.conceptTitle}</NavLink>;
    switch (change.changeType) {
      case ConceptChangeChangeType.UPDATE: {
        // Some update masks are in the format 'intent.intent_change'
        const match = change.updateMask.split('.').pop();
        if (match === 'state') {
          descElements.push(<span>changed status of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>from</span>);
          descElements.push(
            <>
              <IntentStateIcon
                intentType={IntentIntentType.AGENT_INTENT}
                state={change.originalConcept.state}
              />
              <b>{change.originalConcept.state}</b>
            </>,
          );
          descElements.push(<span>to</span>);
          descElements.push(
            <>
              <IntentStateIcon
                intentType={IntentIntentType.AGENT_INTENT}
                state={change.updatedConcept.state}
              />
              <b>{change.updatedConcept.state}</b>
            </>,
          );
        }
        if (match === 'positive_examples') {
          descElements.push(<span>changed positive examples of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'negative_examples') {
          descElements.push(<span>changed negative examples of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'regex_expressions') {
          descElements.push(<span>changed positive regex expressions of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'negative_regex_expressions') {
          descElements.push(<span>changed negative regex expressions of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'description') {
          descElements.push(<span>changed description of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'labeling_guideline') {
          descElements.push(<span>changed labeling guidelines of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'concept_title') {
          descElements.push(<span>changed concept title of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>to</span>);
        }
        if (match === 'intent_type') {
          descElements.push(<span>changed intent type of</span>);
          descElements.push(conceptLink);
          descElements.push(<span>from</span>);
          descElements.push(
            <>
              <RoleIcon conceptRole={change.originalConcept.intent.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor'}/>
              <b>{change.originalConcept.intent.intentType}</b>
            </>,
          );
          descElements.push(<span>to</span>);
          descElements.push(
            <>
              <RoleIcon conceptRole={change.updatedConcept.intent.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor'}/>
              <b>{change.updatedConcept.intent.intentType}</b>
            </>,
          );
        }
        break;
      }
      case ConceptChangeChangeType.CREATE:
        descElements.push(<span>created</span>);
        descElements.push(conceptLink);
        break;
      case ConceptChangeChangeType.DELETE:
        descElements.push(<span>deleted</span>);
        descElements.push(<b>{change.conceptTitle}</b>);
        break;
      default:
        break;
    }

    return descElements;
  };

  const fetchConceptChanges = (nextPageToken?: string | undefined) => {
    dispatch(listConceptChanges({
      parent: customerProfile,
      pageSize: 100,
      pageToken: nextPageToken,
    }));
  };

  useEffect(() => {
    fetchConceptChanges();

    return () => {
      dispatch(clearConceptChanges());
    };
  }, []);

  const renderDiffComparison = (change: ConceptChange) => {
    // Some update masks are in the format 'intent.intent_change'
    const match = change.updateMask.split('.').pop();
    let original = null;
    let updated = null;

    switch (match) {
      case 'description':
        original = change.originalConcept.description;
        updated = change.updatedConcept.description;
        break;
      case 'labeling_guideline':
        original = change.originalConcept.labelingGuideline;
        updated = change.updatedConcept.labelingGuideline;
        break;
      case 'concept_title':
        original = change.originalConcept.conceptTitle;
        updated = change.updatedConcept.conceptTitle;
        break;
      case 'regex_expressions':
        original = change.originalConcept.intent?.regexExpressions || [];
        updated = change.updatedConcept.intent?.regexExpressions || [];
        break;
      case 'negative_regex_expressions':
        original = change.originalConcept.intent?.negativeRegexExpressions || [];
        updated = change.updatedConcept.intent?.negativeRegexExpressions || [];
        break;
      case 'positive_examples':
        original = change.originalConcept.positiveExamples || [];
        updated = change.updatedConcept.positiveExamples || [];
        break;
      case 'negative_examples':
        original = change.originalConcept.negativeExamples || [];
        updated = change.updatedConcept.negativeExamples || [];
        break;
      default:
        return null;
    }

    // eslint-disable-next-line new-cap
    const diffEngine = new DiffMatchPatch.diff_match_patch();
    if (Array.isArray(original) && Array.isArray(updated)) {
      return (
        <div className={styles.diffComparison}>
          <div>
            {(original.length > updated.length ? original : updated).map((_, i) => {
              const diffs = diffEngine.diff_main(original[i] || '', updated[i] || '');
              diffEngine.diff_cleanupSemantic(diffs);
              const htmlDiff = diffEngine.diff_prettyHtml(diffs);
              return <div key={i} className={styles.diffComparisonSentence} dangerouslySetInnerHTML={{ __html: htmlDiff }} />;
            })}
          </div>
        </div>
      );
    } else {
      const diffs = diffEngine.diff_main(original, updated);
      diffEngine.diff_cleanupSemantic(diffs);
      const htmlDiff = diffEngine.diff_prettyHtml(diffs);
      return (
        <div className={styles.diffComparison}>
          <div dangerouslySetInnerHTML={{ __html: htmlDiff }} />
        </div>
      );
    }
  };

  const renderHistory = () => {
    let filteredChanges = conceptChanges;

    if (selectedTypes.length > 0) {
      filteredChanges = conceptChanges.filter((change) => {
        if (selectedTypes.includes(CustomConceptChangeType.DEPRECATE)) {
          return isDeprecateConceptChange(change);
        } else if (selectedTypes.includes(CustomConceptChangeType.UNDEPRECATE)) {
          return isUndeprecateConceptChange(change);
        } else {
          return selectedTypes.includes(change.changeType);
        }
      });
    }

    if (selectedIntents.length > 0) {
      const selectedIntentsIds = selectedIntents.map((name) => getId('concept', name));
      filteredChanges = conceptChanges.filter(
        (change) => selectedIntentsIds.includes(change.conceptIds[0]) || selectedIntentsIds.includes(change.conceptIds[1]),
      );
    }

    return filteredChanges.map((change, i) =>
      // Status is displayed in a different way
      (
      // eslint-disable-next-line react/no-array-index-key
        <div className={styles.historyRecord} key={change.name}>
          <div className={styles.changeIcon}>
            <img src={CHANGES_MAP[change.changeType].icon} alt={change.changeType} />
          </div>
          <div>
            <p className={styles.time}>{moment(change.createTime).fromNow()}</p>
            <div className={styles.changeDescription}>{composeDescription(change).map((node, i) => <React.Fragment key={i}>{node}</React.Fragment>)}</div>
            {renderDiffComparison(change)}
            {isDeprecateConceptChange(change) && (
              <Button size="sm" mt="sm" variant="outline" onClick={() => undeprecateConcept(change.updatedConcept)}>Undeprecate</Button>
            )}
          </div>
        </div>
      ));
  };

  const changeTypesOptions = Object.keys(CHANGES_MAP).map((key) => ({
    value: key,
    text: CHANGES_MAP[key].text,
  }));

  return (
    <>
      <div className={styles.filters}>
        <div>
          <Text size="md">Change Type</Text>
          <Select
            mode="multiple"
            showArrow
            className={styles.select}
            size="large"
            placeholder="All types"
            allowClear
            value={selectedTypes}
            onChange={setSelectedTypes}
          >
            {changeTypesOptions.map((changeType) => (
              <Select.Option key={changeType.value} value={changeType.value}>
                {changeType.text}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Text size="md">Intents</Text>
          <Select
            mode="multiple"
            showArrow
            className={styles.select}
            size="large"
            placeholder="All intents"
            allowClear
            value={selectedIntents}
            onChange={setSelectedIntents}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {concepts.map((concept) => (
              <Select.Option key={concept.name} value={concept.name}>
                {concept.conceptTitle}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.historyList}>{renderHistory()}</div>
      {apiStatus === 'loading' ? (
        <Box>
          <Stack spacing="xl">
            <Skeleton width={1200} height={60} />
            <Skeleton width={1200} height={40} />
            <Skeleton width={1200} height={40} />
          </Stack>
        </Box>
      ) : (
        <>
          {nextPageToken
            && (
              <div className={styles.loadMore}>
                <Button onClick={() => fetchConceptChanges(nextPageToken)} color="blue" variant="light">Load more</Button>
              </div>
            )}
        </>
      )}
    </>
  );
}
