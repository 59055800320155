import React from 'react';
import { Button, Divider, Group, Title } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { useLocation } from 'react-router-dom';
import { ControlCard, ControlItem, CreatedCard, ExistingCard } from './SetCards';
import ExistingSetsModal from './ExistingSetsModal';
import { SetData } from '.';

interface SetConfigProps {
  // PAGE
  isLoading: boolean
  isModalOpen: boolean
  onModalClose: () => void

  // EXISTING SETS
  existingSetsAdded: SetData[]
  allExistingSets: SetData[]
  // existing set handlers
  onAddExistingSet: () => void
  onExistingSetAdd: (setData: SetData) => void
  onRemoveExistingSet: (index: number) => void

  // NEW SETS
  // new set data
  newSetsAdded: SetData[]
  agents: any
  // new set handlers
  onAddNewSet: () => void
  onUpdateNewSet: (index: number, updatedData: Partial<SetData>) => void
  onClearNewSet: (index: number) => void
  onRemoveNewSet: (index: number) => void

  // SUBMIT
  handleReadyForTagConfig: () => void
}

export const SetConfig = (props: SetConfigProps) => {
  const location: {state: {taskTitle: string}} = useLocation();
  const taskTitle = location.state?.taskTitle;

  const {
    // PAGE
    isLoading,
    isModalOpen,
    onModalClose,

    // EXISTING SETS
    existingSetsAdded,
    allExistingSets,
    // existing set handlers
    onAddExistingSet,
    onExistingSetAdd,
    onRemoveExistingSet,

    // NEW SETS
    // new set data
    newSetsAdded,
    agents,
    // new set handlers
    onAddNewSet,
    onUpdateNewSet,
    onClearNewSet,
    onRemoveNewSet,

    // SUBMIT
    handleReadyForTagConfig,
  } = props;

  if (isLoading) return <div>Loading...</div>;

  return (
    <PageContainer>
      <Title data-cy="analysis-config-title">{taskTitle}</Title>
      <Group position="apart" noWrap>
        <Title style={{ flex: 1 }}>Define conversation sets</Title>
        <Button
          data-cy="conversation-sets-next-btn"
          onClick={handleReadyForTagConfig}
        >
          Next
        </Button>
      </Group>
      <Group data-cy="selected-sets-list" spacing="xl">
        <ControlCard>
          <ControlItem
            cypressId="add-set-btn"
            onClick={onAddNewSet}
          >Add New
          </ControlItem>
          <Divider />
          <ControlItem onClick={onAddExistingSet} cypressId="choose-from-existing-btn">Choose from existing sets</ControlItem>
          <ExistingSetsModal
            opened={isModalOpen}
            onClose={onModalClose}
            existingSetData={allExistingSets}
            onAdd={onExistingSetAdd}
          />

        </ControlCard>
        {
          existingSetsAdded.map((setData, index) => (
            <ExistingCard
              key={setData.id}
              setData={setData}
              onRemove={() => {
                onRemoveExistingSet(index);
              }}
            />
          ))
        }
        {
          newSetsAdded.map((setData:SetData, index: number) => (
            <CreatedCard
              key={setData.id}
              index={index}
              setData={setData}
              onDataChange={(updatedData:Partial<SetData>) => onUpdateNewSet(index, updatedData)}
              agentData={agents}
              onClearAll={() => onClearNewSet(index)}
              onRemove={() => onRemoveNewSet(index)}
            />
          ))
        }
      </Group>
    </PageContainer>
  );
};
