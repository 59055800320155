import React, { useMemo, useState } from 'react';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { getId } from 'common/resourceName';
import { Group } from '@mantine/core';
import Loader from '../Loader';
import UtteranceListItem from './UtteranceListItem';
import ChatWindow from '../ChatWindow';
import { MAX_UTTERANCES } from '.';
import { NewSetButton } from '../NewSetModal';
import { getUniqueConversationNamesFromUtterances } from './utils';

interface Props {
  utterances: Message[];
  searchText: string;
  loading: boolean;
  selectedChatId: string;
  selectedMessagePosition: number;
  handleClick: (conversationId:string, positionInConversation:number)=>void;
}

export const UtterancePanel = ({ searchText, utterances, loading, selectedChatId, selectedMessagePosition, handleClick }:Props) => {
  const [isModalOpen, toggleModal] = useState(false);
  const uniqueConversationNames = useMemo(() => getUniqueConversationNamesFromUtterances(utterances), [utterances]);

  if (loading) {
    return <Loader />;
  }
  const count = utterances.length;

  return (
    <>
      <Group>
        <div className="chat-count">{`${count === MAX_UTTERANCES ? `${count}+` : count} ${count === 1 ? 'match' : 'matches'}`}</div>
        <NewSetButton isModalOpen={isModalOpen} toggleModal={toggleModal} conversationNames={uniqueConversationNames} />
      </Group>
      <div className="panels">
        <div className="utterance-search-panel">
          {utterances.map(({ content, actorType, sequencePosition, name }) => {
            const conversationId = getId('conversation', name);
            const selected = selectedChatId && conversationId === selectedChatId && selectedMessagePosition === sequencePosition;
            return (
              <UtteranceListItem
                key={name}
                selected={selected}
                textToHighlight={searchText}
                onClick={() => {
                  handleClick(conversationId, sequencePosition);
                }}
                content={content}
                // first letter used for Letter Avatar
                adornmentString={actorType}
              />
            );
          })}
        </div>
        {selectedChatId && (
        <div className="chat-panel-holder">
          <ChatWindow />
        </div>
        )}
      </div>
    </>
  );
};
