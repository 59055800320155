import React, { ReactNode, useState } from 'react';
import { ActionIcon, Group, Stack, Text, Textarea, TextInput, Title, Tooltip } from '@mantine/core';
import { getHotkeyHandler, useInputState } from '@mantine/hooks';
import { Category, ExampleObj } from '../../types';
import { CloseIcon } from '../../components/icons';
import { Icon } from '../../components/Icon';

interface ExampleCardProps {
  category: Category;
  example: ExampleObj;
  handleChange: (value: string) => void;
  onDeleteExample: () => void;
}

const ExampleCard = (props: ExampleCardProps) => {
  const { category, example, handleChange, onDeleteExample } = props;
  const [value, setValue] = useInputState(example.value);

  const handleBlur = () => {
    if (value === example.value) return;
    if (value.length === 0) {
      setValue(example.value);
      return;
    }
    handleChange(value);
  };

  return (
    <Textarea
      key={example.value}
      icon={<Icon value={category} />}
      rightSection={(
        <ActionIcon onClick={onDeleteExample}>
          <CloseIcon />
        </ActionIcon>
      )}
      placeholder="Enter example utterance"
      value={value}
      onChange={setValue}
      onBlur={handleBlur}
      minRows={1}
      autosize
    />
  );
};

interface ExamplesContainerProps {
  category: Category;
  error: ReactNode;
  title: string;
  subtitle: string;
  examples: ExampleObj[];
  handleUpdateExamples: (indexToUpdate: number, value: string) => void;
  handleAddNewExample: (text: string) => void;
  handleDeleteExample: (index: number) => void;
}

const ConditionalTooltip = ({
  error,
  children,
}: {
  error: React.ReactNode;
  children: React.ReactNode;
}) => {
  if (error) {
    return <Tooltip label={error}>{children}</Tooltip>;
  }
  return <>{children}</>;
};

export const ExamplesContainer = (props: ExamplesContainerProps) => {
  const {
    category,
    error,
    title,
    subtitle,
    examples,
    handleUpdateExamples,
    handleAddNewExample,
    handleDeleteExample,
  } = props;
  const [newExample, setNewExample] = useInputState('');
  const [isFocused, toggleFocused] = useState(false);
  const onCreate = () => {
    if (newExample.length === 0) return;
    handleAddNewExample(newExample);
    setNewExample('');
  };

  return (
    <div>
      <Group noWrap position="apart" align="start" mb="xs">
        <div>
          <Title order={5}>
            {title}
          </Title>
          <Text color={error ? 'red' : 'dimmed'} size="xs">
            {subtitle}
          </Text>
        </div>
        <ConditionalTooltip error={error}>
          <Text size="sm" mr={5} color={error ? 'red' : undefined}>{examples.length}</Text>
        </ConditionalTooltip>
      </Group>
      <Stack>
        {examples.map((example, i) => (
          <ExampleCard
            key={example.value}
            category={category}
            example={example}
            handleChange={(value) => handleUpdateExamples(i, value)}
            onDeleteExample={() => handleDeleteExample(i)}
          />
        ))}
        <TextInput
          onFocus={() => toggleFocused(true)}
          placeholder="Enter example utterance"
          icon={<Icon value={category} inactive={!isFocused} />}
          value={newExample}
          onChange={setNewExample}
          onBlur={() => {
            toggleFocused(false);
            if (newExample.length === 0) return;
            onCreate();
          }}
          onKeyDown={getHotkeyHandler([['Enter', onCreate]])}
        />
      </Stack>
    </div>
  );
};
