import { useCallback, useState, useEffect } from 'react';
import { getErrorMessage } from 'utils';
import { PromptApi } from 'services/promptApi';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import dayjs from 'dayjs';
import { IPrompt } from './usePrompts';
import { handleSections } from '../PromptEditor';

const sortByDate = (a: IPrompt, b: IPrompt) => {
  const aDate = a.updatedAt || a.createdAt;
  const bDate = b.updatedAt || b.createdAt;
  return dayjs(bDate).diff(dayjs(aDate));
};

export const useListPrompts: () => [IPrompt[], boolean, () => void, string | null] = () => {
  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [refetch, toggleRefetch] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const customerProfile = useCustomerProfile();

  useEffect(() => {
    setLoading(true);
    setError(null);
    let retries = 0;
    const getPrompts = async (): Promise<void> => {
      try {
        // fails when customerProfile is changed as config is not immediately updated
        const res = await PromptApi.listPrompts({ parent: customerProfile });
        if (res) {
          const fetchedPrompts = res.prompts;
          const mappedPrompts: IPrompt[] = fetchedPrompts
            // if a promptgroupid exists, only return if is also the active version
            // temporary until we backfill promptgroupid and activeversion for all prompts
            .filter((p) => !p.promptGroupId || p.isActiveVersion)
            .map((p) => ({
              id: p.name.split('/').pop(),
              name: p.name,
              promptText: handleSections(p.sections),
              displayName: p.displayName,
              createdAt: p.createTime,
              updatedAt: p.updateTime,
              isDeployed: p.isDeployed,
              promptGroupId: p.promptGroupId,
              version: p.version,
              isActiveVersion: p.isActiveVersion || false,
            }));
          const sortedPrompts = mappedPrompts.sort(sortByDate);
          setPrompts(sortedPrompts);
        } else {
          setPrompts([]);
        }
        setLoading(false);
      } catch (e) {
        if (retries < 4) {
          retries++;
          // exponential backoff
          const RETRY_DELAY = retries * 200;
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          getPrompts();
        } else {
          console.error(e);
          const errorMessage = getErrorMessage(e);
          setError(errorMessage);
          setPrompts([]);
          setLoading(false);
        }
      }
    };
    getPrompts();
  }, [customerProfile, refetch]);

  const handleRefetch = useCallback(() => {
    toggleRefetch(!refetch);
  }, [refetch]);

  return [prompts, loading, handleRefetch, error];
};
