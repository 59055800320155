import React, { useEffect, useState } from 'react';
import { Button, Collapse, Group, MultiSelect, Stack, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { DataGrid } from 'components/DataGrid';
import { useApiPost } from 'hooks/network';
import { useInputState } from '@mantine/hooks';
import { useSelector } from 'hooks/reduxHooks';
import { selectDerivedQaTasks } from 'store/labelingTask/selectors';
import { getId } from 'common/resourceName';
import { labelTableColumns } from '../labelTableColumns';
import { useQALabelCounts } from '../useQALabelCounts';

const PreviewTable = ({ taskIds, toggleShowResults }) => {
  const [refresh, toggleRefresh] = useState(false);
  const [data, isLoading] = useQALabelCounts(taskIds, [refresh]);
  const apiPost = useApiPost(true, true);
  const [submittingDeprecationRequest, toggleDeprecationRequest] = useState(false);

  const handleDeprecateTasks = async () => {
    toggleDeprecationRequest(true);
    try {
      await apiPost('change_qa_label_splits', {
        task_ids: taskIds,
        revert: true,
      });
      showNotification({
        color: 'green',
        message: 'Deprecation successful',
      });
      toggleDeprecationRequest(false);
      toggleRefresh(!refresh);
    } catch (error) {
      console.error('error', error);
      showNotification({
        color: 'red',
        message: 'Promotion failed',
      });
      toggleDeprecationRequest(false);
    }
  };

  return (
    <>
      <DataGrid
        style={{ margin: '0 -16px' }}
        isLoading={isLoading}
        columns={labelTableColumns}
        dataSource={data}
      />
      <Text>Do you want to deprecate these annotations? This cannot be undone.</Text>
      <Group>
        <Button color="red" onClick={handleDeprecateTasks} loading={submittingDeprecationRequest} disabled={data.length === 0}>Yes, continue</Button>
        <Button variant="subtle" onClick={() => toggleShowResults(false)}>No, cancel</Button>
      </Group>
    </>
  );
};

export const LabelReversion = () => {
  const [showResults, toggleShowResults] = useState(false);
  const [taskIds, setTaskIds] = useInputState([]);
  const qaTasks = useSelector(selectDerivedQaTasks);
  const allQATaskIds = qaTasks.map((task) => getId('labelingTask', task.labelingTask.name)) || [];

  useEffect(() => {
    if (showResults) {
      toggleShowResults(false);
    }
  }, [taskIds]);

  return (
    <Stack>
      <Text>Revert QA labels from model-ready flow</Text>
      <MultiSelect
        searchable
        onChange={setTaskIds}
        value={taskIds}
        data={allQATaskIds}
        label="Task IDs"
        placeholder="Enter task ID"
        style={{ maxWidth: 240 }}
      />
      {showResults || (
        <Group>
          <Button
            disabled={taskIds.length === 0}
            onClick={() => {
              toggleShowResults(true);
            }}
          >Run
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setTaskIds([]);
            }}
          >Clear
          </Button>
        </Group>
      )}
      <Collapse in={showResults}>
        <Stack>
          {showResults && <PreviewTable taskIds={taskIds} toggleShowResults={toggleShowResults} />}
        </Stack>
      </Collapse>
    </Stack>
  );
};
