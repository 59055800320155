import React from 'react';
import { Button, MantineColor, Paper, TextInput } from '@mantine/core';
import { AgentSelect } from './AgentSelect';

interface ContainerProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Container = (props: ContainerProps) => (
  <Paper p="lg" style={{ display: 'flex', flexDirection: 'column', width: 280, height: 460 }} {...props} />
);

interface ConversationQuantityInputProps {
  value: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ConversationQuantityInput = ({ value, onChange = (e) => {} }: ConversationQuantityInputProps) => (
  <TextInput
    label="Max number of conversations"
    data-cy="conversation-sets-number-input"
    placeholder="Enter number of conversations"
    value={value}
    onChange={onChange}
  />
);

interface ActionButtonProps {
  fullWidth?: boolean;
  color?: MantineColor;
  onClick?: () => void;
  children: React.ReactNode;
}

export const ActionButton = ({ fullWidth = false, color = undefined, children, onClick = () => {} }: ActionButtonProps) => (
  <Button
    variant="subtle"
    color={color}
    data-cy="conversation-sets-action-btn"
    onClick={onClick}
    fullWidth={fullWidth}
    style={{ flex: 1 }}
  >
    {children}
  </Button>
);

export { AgentSelect };
