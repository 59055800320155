import React, { useEffect } from 'react';
import { Select } from 'antd';
import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core';
import { DerivedLabelingTask, QaDataSource, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { User } from 'types';
import {
  Concept,
  ConceptState,
  IntentIntentType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ModelType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';
import { NewTaskData } from '.';
import PolicyTaskForm from './TaskModalTypes/PolicyTaskForm';
import IntentPredictionRecallTaskForm from './TaskModalTypes/IntentPredictionRecallTaskForm';
import SuggestionsTaskForm from './TaskModalTypes/SuggestionsTaskForm';
import SummarizationTaskForm from './TaskModalTypes/SummarizationTaskForm';
import IntentPredictionPrecisionTaskForm from './TaskModalTypes/IntentPredictionPrecisionTaskForm';
import { MisclassificationAugmentationTaskForm } from './TaskModalTypes/MisclassificationAugmentationTaskForm';

interface NewTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleOk: (data: NewTaskData) => void;
  submitting: boolean;
  concepts: Concept[];
  users: User[];
  qaTasks: DerivedLabelingTask[];
  taskData?: NewTaskData;
  setTaskData: (value: React.SetStateAction<NewTaskData>) => void;
}

const taskTypeOptions = [
  {
    value: UserLabelingTaskType.QA_INTENT_PREDICTION,
    text: 'Intents - Precision',
  },
  {
    value: UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
    text: 'Intents - All',
  },
  {
    value: UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION,
    text: 'Intents - All (with following augmentation)',
  },
  {
    value: UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW,
    text: 'Intents Misclassification Augmentation',
  },
  {
    value: UserLabelingTaskType.QA_POLICY,
    text: 'Policy',
  },
  {
    value: UserLabelingTaskType.QA_SUGGESTIONS,
    text: 'Suggestions Model',
  },
  {
    value: UserLabelingTaskType.QA_SUMMARIZATION,
    text: 'Summarization',
  },
];

const DEFAULT_TASK_DATA: NewTaskData = {
  taskType: taskTypeOptions[0]?.value,
  conceptIds: [],
  maxPredictionCount: 50,
  maxConversationCount: 30,
  minConversationLength: 10,
  conversationCount: 10,
  regressionArtifactInputs: {
    modelType: ModelType.STACKED_CLASSIFIER,
    useGrpcIntents: true,
  },
  agentModelUri: '',
  visitorModelUri: '',
  chatDriverModelUri: '',
  modelUri: '',
  modelUri2: '',
  modelUris: [],
  preselectedCsvUri: '',
  conversations: [],
  qaSource: QaDataSource.SERVING_MODEL,
  dateRangeSelector: {
    after: null,
    before: null,
  },
  rubricTagSelections: [],
  excludeUserIds: [],
  qaTaskId: '',
};

export default function NewTaskModal({
  isOpen,
  onClose,
  handleOk,
  submitting,
  concepts,
  users,
  qaTasks,
  taskData = DEFAULT_TASK_DATA,
  setTaskData,
}: NewTaskModalProps) {
  const {
    conceptIds,
    taskType,
    maxPredictionCount,
    minConversationLength,
    qaSource,
    dateRangeSelector,
    agentModelUri,
    visitorModelUri,
    chatDriverModelUri,
    regressionArtifactInputs,
    conversations,
    conversationCount,
    maxConversationCount,
    modelUri,
    modelUri2,
    modelUris,
    preselectedCsvUri,
    rubricTagSelections,
    excludeUserIds,
    qaTaskId,
  } = taskData;

  const setTaskDataValue = (key: keyof NewTaskData, value: unknown) => {
    setTaskData((prevState: NewTaskData) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      resetFields();
    }
  }, [isOpen]);

  const submitHandler = async (data: NewTaskData) => {
    handleOk(data);
  };

  // Filter out stages
  const intentConcepts = concepts.filter((concept) =>
    concept?.state !== ConceptState.DEPRECATED
    && [IntentIntentType.AGENT_INTENT, IntentIntentType.VISITOR_INTENT, IntentIntentType.CONVERSATION_DRIVER].includes(
      concept?.intent?.intentType,
    ));

  const resetFields = () => {
    setTaskData({
      ...DEFAULT_TASK_DATA,
      taskType,
    });
  };

  const handleCancel = () => {
    onClose();
  };

  const isFormValid = () => {
    const hasConceptsAndDate = conceptIds.length > 0 && maxPredictionCount > 0;
    const intentsHasModelUri = agentModelUri || visitorModelUri || chatDriverModelUri;
    const suggestionsHasModelUri = modelUri;
    if (taskType === UserLabelingTaskType.QA_INTENT_PREDICTION) {
      return hasConceptsAndDate;
    }
    if (taskType === UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL || taskType === UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION) {
      return hasConceptsAndDate && intentsHasModelUri;
    }
    if (taskType === UserLabelingTaskType.QA_SUGGESTIONS) {
      return suggestionsHasModelUri;
    }
    if (taskType === UserLabelingTaskType.QA_POLICY) {
      // Sampling type validation
      return conversations?.length || (dateRangeSelector?.before && maxConversationCount > 0);
    }
    if (taskType === UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW) {
      return !!qaTaskId;
    }
    return true;
  };

  const dateAfterAndBefore: [Dayjs, Dayjs] = [dateRangeSelector?.after && dayjs(dateRangeSelector?.after), dateRangeSelector?.before && dayjs(dateRangeSelector?.before)];
  const setDateAfterAndBefore = (tuple) => {
    if (!tuple) return;
    setTaskDataValue('dateRangeSelector', {
      before: tuple[1],
      after: tuple[0],
    });
  };

  const renderTaskForm = () => {
    switch (taskType) {
      case UserLabelingTaskType.QA_INTENT_PREDICTION: {
        return (
          <IntentPredictionPrecisionTaskForm
            maxPredictionCount={maxPredictionCount}
            setMaxPredictionCount={(value) => setTaskDataValue('maxPredictionCount', value)}
            dateAfterAndBefore={dateAfterAndBefore}
            setDateAfterAndBefore={setDateAfterAndBefore}
            conceptIds={conceptIds}
            setConceptIds={(value) => setTaskDataValue('conceptIds', value)}
            intentConcepts={intentConcepts}
            agentModelUri={agentModelUri}
            setAgentModelUri={(value) => setTaskDataValue('agentModelUri', value)}
            visitorModelUri={visitorModelUri}
            setVisitorModelUri={(value) => setTaskDataValue('visitorModelUri', value)}
            chatDriverModelUri={chatDriverModelUri}
            setChatDriverModelUri={(value) => setTaskDataValue('chatDriverModelUri', value)}
            minConversationLength={minConversationLength}
            setMinConversationLength={(value) => setTaskDataValue('minConversationLength', value)}
            excludeUserIds={excludeUserIds}
            setExcludeUserIds={((value) => setTaskDataValue('excludeUserIds', value))}
          />
        );
      }
      case UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL:
      case UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION: {
        return (
          <IntentPredictionRecallTaskForm
            maxPredictionCount={maxPredictionCount}
            setMaxPredictionCount={(value) => setTaskDataValue('maxPredictionCount', value)}
            dateAfterAndBefore={dateAfterAndBefore}
            setDateAfterAndBefore={setDateAfterAndBefore}
            conceptIds={conceptIds}
            setConceptIds={(value) => setTaskDataValue('conceptIds', value)}
            intentConcepts={intentConcepts}
            agentModelUri={agentModelUri}
            setAgentModelUri={(value) => setTaskDataValue('agentModelUri', value)}
            visitorModelUri={visitorModelUri}
            setVisitorModelUri={(value) => setTaskDataValue('visitorModelUri', value)}
            chatDriverModelUri={chatDriverModelUri}
            setChatDriverModelUri={(value) => setTaskDataValue('chatDriverModelUri', value)}
          />
        );
      }
      case UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW: {
        return (
          <MisclassificationAugmentationTaskForm
            intentConcepts={intentConcepts}
            users={users}
            qaTasks={qaTasks}
            qaTaskId={qaTaskId}
            setQATaskId={(value) => setTaskDataValue('qaTaskId', value)}
          />
        );
      }
      case UserLabelingTaskType.QA_POLICY: {
        return (
          <PolicyTaskForm
            dateAfterAndBefore={dateAfterAndBefore}
            setDateAfterAndBefore={setDateAfterAndBefore}
            setQaSource={(value) => setTaskDataValue('qaSource', value)}
            regressionArtifactInputs={regressionArtifactInputs}
            setRegressionArtifactInputs={(value) => setTaskDataValue('regressionArtifactInputs', value)}
            conversations={conversations}
            setConversations={(value) => setTaskDataValue('conversations', value)}
            maxConversationCount={maxConversationCount}
            setMaxConversationCount={(value) => setTaskDataValue('maxConversationCount', value)}
          />
        );
      }
      case UserLabelingTaskType.QA_SUGGESTIONS: {
        return (
          <SuggestionsTaskForm
            dateAfterAndBefore={dateAfterAndBefore}
            setDateAfterAndBefore={setDateAfterAndBefore}
            maxConversationCount={maxConversationCount}
            setMaxConversationCount={(value) => setTaskDataValue('maxConversationCount', value)}
            modelUri={modelUri}
            modelUri2={modelUri2}
            setModelUri={(value) => setTaskDataValue('modelUri', value)}
            setModelUri2={(value) => setTaskDataValue('modelUri2', value)}
          />
        );
      }
      case UserLabelingTaskType.QA_SUMMARIZATION: {
        return (
          <SummarizationTaskForm
            dateAfterAndBefore={dateAfterAndBefore}
            setDateAfterAndBefore={setDateAfterAndBefore}
            modelUris={modelUris}
            preselectedCsvUri={preselectedCsvUri}
            setModelUris={(value) => setTaskDataValue('modelUris', value)}
            concepts={concepts}
            setConceptIds={(value) => setTaskDataValue('conceptIds', value)}
            conversationCount={conversationCount}
            minConversationLength={minConversationLength}
            rubricTagSelections={rubricTagSelections}
            setRubricTagSelections={(value) => setTaskDataValue('rubricTagSelections', value)}
            setMinConversationLength={(value) => setTaskDataValue('minConversationLength', value)}
            setConversationCount={(value) => setTaskDataValue('conversationCount', value)}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal
      title="New Task"
      opened={isOpen}
      size={600}
      onClose={() => onClose()}
      closeOnClickOutside={false}
      data-cy="task-modal"
    >
      <div className={styles.newTaskContainer}>
        <Text size="md">Task type</Text>
        <Select
          showArrow
          className={styles.taskType}
          size="large"
          value={taskType}
          onChange={(value) => {
            resetFields();
            setTaskDataValue('taskType', value);
          }}
        >
          {taskTypeOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
        <Box mt="lg">
          {renderTaskForm()}
        </Box>
      </div>
      <Group position="right">
        <Button key="back" onClick={handleCancel} variant="subtle">
          Cancel
        </Button>
        <Button
          key="submit"
          loading={submitting}
          onClick={() =>
            submitHandler({
              taskType,
              conceptIds,
              maxPredictionCount,
              conversationCount,
              maxConversationCount,
              minConversationLength,
              regressionArtifactInputs: {
                ...regressionArtifactInputs,
                useGrpcIntents: true,
              },
              conversations,
              dateRangeSelector,
              qaSource,
              // Intents precision QA
              agentModelUri,
              visitorModelUri,
              chatDriverModelUri,
              modelUri,
              modelUri2,
              excludeUserIds,
              // Summarization QA
              modelUris,
              preselectedCsvUri,
              rubricTagSelections,
              qaTaskId,
            })}
          disabled={!isFormValid()}
        >
          Create
        </Button>
      </Group>
      <LoadingOverlay visible={submitting}/>
    </Modal>
  );
}
