import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';

import {
  Concept,
  IntentIntentType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import TreeNodeIcon from 'components/TreeNode/TreeNodeIcon';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import { useApiGet } from 'hooks/network';
import { ModelSummary } from 'store/modelBuilder/asyncThunks';
import { openNotification } from 'components/Notification';
import { useDebounce } from 'hooks/useDebounce';
import { MultiSelect, Text } from '@mantine/core';
import { selectAgents } from 'store/agent/selectors';
import { useSelector } from 'hooks/reduxHooks';
import { Dayjs } from 'dayjs';
import { useCustomerParams } from 'hooks/useCustomerParams';
import styles from '../styles.module.scss';

interface IntentPredictionPrecisionTaskFormProps {
  intentConcepts: Concept[];
  maxPredictionCount: number;
  setMaxPredictionCount: (value: number) => void;
  minConversationLength: number;
  setMinConversationLength: (value: number) => void;
  dateAfterAndBefore: [Dayjs, Dayjs];
  setDateAfterAndBefore: (date: [Dayjs, Dayjs]) => void;
  conceptIds: string[];
  setConceptIds: (ids: string[]) => void;
  agentModelUri: string;
  setAgentModelUri: (value: string) => void;
  visitorModelUri: string;
  setVisitorModelUri: (value: string) => void;
  chatDriverModelUri: string;
  setChatDriverModelUri: (value: string) => void;
  excludeUserIds: string[];
  setExcludeUserIds: (ids: string[]) => void;
}

export default function IntentPredictionPrecisionTaskForm({
  intentConcepts,
  maxPredictionCount,
  setMaxPredictionCount,
  minConversationLength,
  setMinConversationLength,
  dateAfterAndBefore,
  setDateAfterAndBefore,
  conceptIds,
  setConceptIds,
  agentModelUri,
  visitorModelUri,
  chatDriverModelUri,
  setAgentModelUri,
  setVisitorModelUri,
  setChatDriverModelUri,
  excludeUserIds,
  setExcludeUserIds,
}: IntentPredictionPrecisionTaskFormProps) {
  const { path } = useCustomerParams();
  const apiGet = useApiGet(false);
  const onRangeChange = (val: [Dayjs, Dayjs]) => {
    setDateAfterAndBefore([val[0]?.startOf('day'), val[1]?.endOf('day')]);
  };
  const [loadingModelUri, setLoadingModelUri] = useState(true);
  const [loadingTaxonomies, setLoadingTaxonomies] = useState(false);
  const [validConceptsMap, setValidConceptsMap] = useState<Map<string, string>>();

  const hasModelUri = agentModelUri || visitorModelUri || chatDriverModelUri;
  const modelsHash = `${agentModelUri}:${visitorModelUri}:${chatDriverModelUri}`;
  const debouncedModelsHash = useDebounce(modelsHash, 500);
  const filteredConcepts = useMemo(() => intentConcepts.filter((concept) => {
    if (hasModelUri) {
      return validConceptsMap?.get(concept.conceptTitle);
    }
    return true;
  }), [intentConcepts, validConceptsMap]);

  const agents = useSelector(selectAgents);
  const agentsData = agents.map((agent) => ({
    label: agent.displayName,
    value: agent.userId,
  }));

  // Populate model uri on load
  useEffect(() => {
    populateModels();
  }, []);

  useEffect(() => {
    // Fetch taxonomies to filter out invalid intents in the select
    setValidConceptsMap(undefined);
    if (hasModelUri) {
      const modelUrls = [];
      [agentModelUri, visitorModelUri, chatDriverModelUri].forEach((url) => {
        if (url) {
          modelUrls.push(url);
        }
      });
      getModelTaxonomies({
        model_urls: modelUrls,
      });
    }
  }, [debouncedModelsHash]);

  // Fetch valid intents for set models
  const getModelTaxonomies = async (body: { model_urls: string[] }) => {
    const taxonomyPath = `${path}/get_model_taxonomies`;
    setLoadingTaxonomies(true);
    try {
      const response = await apiGet(taxonomyPath, body);
      const res: {
        [key: string]: any
      } = response.result;

      // This endpoint returns 200 even if it errors out
      if (typeof res !== 'object') {
        openNotification('error', res, undefined);
      }
      const resValues = Object.values(res);
      const validConceptsMap = new Map<string, string>();
      resValues.forEach((intents) => {
        intents.neural_intents.forEach((intent) => {
          validConceptsMap?.set(intent, 'neural');
        });
        intents.rule_intents.forEach((intent) => {
          if (validConceptsMap?.has(intent.intent)) {
            validConceptsMap?.set(intent.intent, 'neural_and_rule');
          } else {
            validConceptsMap?.set(intent.intent, 'rule');
          }
        });
      });
      setValidConceptsMap(validConceptsMap);
    } catch (err) {
      openNotification('error', 'Failed to extract and validate taxonomies', undefined, err);
      throw err;
    } finally {
      setLoadingTaxonomies(false);
    }
  };

  const populateModels = async () => {
    const shouldPopulate = !agentModelUri
      && !visitorModelUri
      && !chatDriverModelUri;

    if (!shouldPopulate) {
      setLoadingModelUri(false);
      return;
    }

    const modelPath = `${path}/get_serving_model_details`;
    setLoadingModelUri(true);
    try {
      const response = await apiGet(modelPath);
      const modelSummary: ModelSummary = response.result;
      setAgentModelUri(modelSummary?.agent_intent_model_dependency_urls?.url);
      setVisitorModelUri(modelSummary?.visitor_intent_model_dependency_urls?.url);
      setChatDriverModelUri(modelSummary?.chat_driver_model_dependency_urls?.url);
    } catch (err) {
      openNotification('error', 'Failed to auto-populate model uri', undefined, err);
      throw err;
    } finally {
      setLoadingModelUri(false);
    }
  };

  return (
    <>
      <Form.Item>
        <Text size="md">Driver model</Text>
        <Input
          size="large"
          value={chatDriverModelUri}
          placeholder={loadingModelUri ? 'Loading latest model from production…' : 'Model URL'}
          onChange={(event) =>
            setChatDriverModelUri(event.currentTarget.value)}
        />
      </Form.Item>
      <Form.Item>
        <Text size="md">Agent model</Text>
        <Input
          size="large"
          value={agentModelUri}
          placeholder={loadingModelUri ? 'Loading latest model from production…' : 'Model URL'}
          onChange={(event) =>
            setAgentModelUri(event.currentTarget.value)}
        />
      </Form.Item>
      <Form.Item>
        <Text size="md">Visitor model</Text>
        <Input
          size="large"
          value={visitorModelUri}
          placeholder={loadingModelUri ? 'Loading latest model from production…' : 'Model URL'}
          onChange={(event) =>
            setVisitorModelUri(event.currentTarget.value)}
        />
      </Form.Item>
      <Text size="md">Choose intents</Text>
      <Select
        mode="multiple"
        showArrow
        showSearch
        className={classNames([styles.fullWidth, 'with-icons'])}
        size="large"
        placeholder="Intents"
        allowClear
        disabled={loadingTaxonomies}
        loading={loadingModelUri || loadingTaxonomies}
        popupClassName="with-icons"
        value={conceptIds}
        filterOption={(input, option) =>
          option.searchvalue?.indexOf(input) !== -1}
        onChange={(value) => {
          if (value.includes('ALL')) {
            setConceptIds(filteredConcepts.map((concept) => getId('concept', concept.name)));
          } else if (Array.isArray(value)) {
            setConceptIds(value);
          } else {
            setConceptIds([value]);
          }
        }}
      >
        <Select.Option key="all" value="ALL">
          All currently active
        </Select.Option>
        {filteredConcepts.map((concept) => {
          const conceptId = getId('concept', concept.name);
          return (
            <Select.Option
              key={conceptId}
              value={conceptId}
              searchvalue={concept.conceptTitle}
            >
              <TreeNodeIcon
                type={
                  concept.intent?.intentType === IntentIntentType.AGENT_INTENT
                    ? 'agent'
                    : 'visitor'
                }
              />
              <span>{concept.conceptTitle}</span>
            </Select.Option>
          );
        })}
      </Select>
      <Text size="md" mt="sm">Conversations from this time range</Text>
      <Form.Item>
        <DatePicker.RangePicker
          value={dateAfterAndBefore}
          onChange={onRangeChange}
        />
      </Form.Item>
      <Form.Item>
        <Text size="md">Exclude user ids</Text>
        <MultiSelect
          placeholder="Select users"
          value={excludeUserIds}
          data={agentsData}
          onChange={setExcludeUserIds}
          searchable
        />
      </Form.Item>
      <Form.Item>
        <Text size="md">Max # of messages for each intent and type</Text>
        <div className={styles.chatCount}>
          <Input
            size="large"
            value={maxPredictionCount}
            type="number"
            onChange={(event) =>
              setMaxPredictionCount(Number(event.currentTarget.value))}
          />
        </div>
      </Form.Item>
      <Form.Item>
        <Text size="md">Minimum conversation length</Text>
        <div className={styles.chatCount}>
          <Input
            size="large"
            value={minConversationLength}
            type="number"
            min={5}
            onChange={(event) =>
              setMinConversationLength(Number(event.currentTarget.value))}
          />
        </div>
      </Form.Item>
    </>
  );
}
