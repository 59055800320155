import React, { useContext, useState, useEffect } from 'react';
import './Sidebar.scss';
import { NavLink } from 'react-router-dom';
import LetterAvatar from 'components/LetterAvatar';
import { Tooltip } from 'antd';
import { HotkeysHelper } from 'components/HotkeysHelper';
import { useHotkeys } from '@mantine/hooks';
import { Box, Center } from '@mantine/core';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { useSelector } from 'hooks/reduxHooks';
import { selectDebuggingMode } from 'store/app/selectors';
import { OktaAuthContext } from '../../context/OktaAuthContext';
import {
  ParrotIcon,
  ExitIcon,
  MenuOut,
  MenuIn,
  KeyboardIcon,
} from './icons';
import CustomerPicker from '../CustomerPicker';
import { NestedMenuItem } from './NestedMenuItem';
import { getMenu } from './config';

export function getActiveStatus(currentRoute: string, urls: string[]) {
  if (urls.length === 0) return false;
  if (!currentRoute) return false;
  const currentLevels = currentRoute.split('/');
  return urls.some((url) => {
    const urlLevels = url.split('/');
    return urlLevels.every((level, i) => level === currentLevels[i]);
  });
}

const Sidebar = () => {
  const { signOutOkta } = useContext(OktaAuthContext);
  const debuggingMode = useSelector<boolean>(selectDebuggingMode);
  const enableFeatures = debuggingMode;
  const [isOpen, toggleOpen] = useState(false);
  const [isHotkeysHelper, setIsHotkeysHelper] = useState(false);
  const [customerSelectionVisible, setIsCustomerSelectionVisible] = useState(false);
  const customer = useCustomerParams();
  useHotkeys([
    ['shift+?', () => setIsHotkeysHelper(!isHotkeysHelper)],
  ]);

  useEffect(() => {
    if (!isOpen) {
      setIsCustomerSelectionVisible(false);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && <div className="sidebar-backdrop" onClick={() => toggleOpen(false)}/>}
      <div
        id="sidebar"
        className={`sidebar ${isOpen && 'open'}`}
      >
        <nav>
          <div className="nav-header">
            {isOpen ? (
              <>
                <ParrotIcon className="parrot-icon" />
                <span>Studio</span>
                <Tooltip
                  title="Close Menu"
                  placement="right"
                >
                  <MenuIn className="menu-in-icon" onClick={() => toggleOpen(false)} />
                </Tooltip>
              </>
            ) : (
              <>
                <ParrotIcon className="parrot-icon" />
                <Tooltip
                  title="Open Menu"
                  placement="right"
                >
                  <MenuOut className="menu-out-icon" onClick={() => toggleOpen(true)} />
                </Tooltip>
              </>
            )}

          </div>
          <br />
          <Center onClick={isOpen ? undefined : () => { toggleOpen(true); }}>
            {isOpen && (
              <Box
                style={{ width: '100%' }}
                px="xs"
              >
                <CustomerPicker
                  opened={customerSelectionVisible}
                  setOpened={setIsCustomerSelectionVisible}
                  toggleSidebar={toggleOpen}
                />
              </Box>
            )}
            {!isOpen && (
              <LetterAvatar
                styles={{
                  width: 36,
                  height: 36,
                  fontSize: 18,
                }}
                name={`${customer.customerId} ${customer.profileId}`}
              />
            )}
          </Center>
          <Box mt="lg" style={{ overflow: 'hidden' }}>
            {getMenu({
              experimentalFeatures: enableFeatures,
            }).map((menuItem) => {
              if (menuItem.type === 'submenu') {
                const { id, label, icon, submenu } = menuItem;
                return (
                  <NestedMenuItem
                    key={id}
                    label={label}
                    icon={icon}
                    submenuItems={submenu}
                    drawerOpen={isOpen}
                    toggleOpen={toggleOpen}
                  />
                );
              }
              const { id, label, icon, to } = menuItem;
              const Icon = icon;
              return (
                <Tooltip
                  key={id}
                  title={label}
                  placement="right"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? 'active nav-link-item' : 'nav-link-item')}
                    to={to}
                  >
                    <Icon />
                    <span>{label}</span>
                  </NavLink>
                </Tooltip>
              );
            })}
          </Box>
        </nav>

        {isOpen ? (
          <>
            <div className="bottom-menu-item-container" onClick={() => setIsHotkeysHelper(!isHotkeysHelper)}>
              <KeyboardIcon />
              <span>
                Keyboard shortcuts
              </span>
            </div>
            <div className="bottom-menu-item-container" onClick={() => signOutOkta()}>
              <ExitIcon />
              <span>
                Log out
              </span>
            </div>
          </>
        ) : (
          <>
            <Tooltip
              className="bottom-menu-item-container"
              title="Keyboard shortcuts"
              placement="right"
            >
              <div className="bottom-menu-item-container" onClick={() => setIsHotkeysHelper(!isHotkeysHelper)}>
                <KeyboardIcon />
              </div>
            </Tooltip>
            <Tooltip
              className="bottom-menu-item-container"
              title="Log out"
              placement="right"
            >
              <div className="bottom-menu-item-container" onClick={() => signOutOkta()}>
                <ExitIcon />
              </div>
            </Tooltip>
          </>
        )}
      </div>
      <HotkeysHelper opened={isHotkeysHelper} setOpened={setIsHotkeysHelper}/>
    </>
  );
};

export default React.memo(Sidebar);
