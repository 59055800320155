import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { FetchManualAnalysisSummaryResponseManualAnalysisSummary } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { ConceptDictionaryEntry, ModifiedManualAnalysisSummary, PanelData } from '.';

const aggregateConcepts = (
  summaries: FetchManualAnalysisSummaryResponseManualAnalysisSummary[],
  conceptDictionary: { [id: string]: ConceptDictionaryEntry },
) => {
  const summariesWithAggregatedConcepts: ModifiedManualAnalysisSummary[] = summaries.map(
    (summary) => {
      const stages: ConceptDictionaryEntry[] = [];
      const drivers: ConceptDictionaryEntry[] = [];
      const outcomes: ConceptDictionaryEntry[] = [];
      const behaviors: ConceptDictionaryEntry[] = [];
      summary.behaviorTagIds.forEach((id) => {
        switch (conceptDictionary[id]?.tagType) {
          case TagTagType.STAGE_BEGIN:
            stages.push({
              id,
              ...conceptDictionary[id],
            });
            break;
          case TagTagType.DRIVER:
            drivers.push({
              id,
              ...conceptDictionary[id],
            });
            break;
          case TagTagType.CONVERSATION_OUTCOME:
            outcomes.push({
              id,
              ...conceptDictionary[id],
            });
            break;
          case TagTagType.AGENT_INTENT:
            behaviors.push({
              id,
              ...conceptDictionary[id],
            });
            break;
          case TagTagType.VISITOR_INTENT:
            behaviors.push({
              id,
              ...conceptDictionary[id],
            });
            break;

          default:
            break;
        }
      });
      return { ...summary, stages, drivers, outcomes, behaviors };
    },
  );
  return summariesWithAggregatedConcepts;
};

const getPanelDataForConcept = (
  id: string,
  conceptTitle: string,
  count: number,
  total: number,
) => ({
  id,
  tag: conceptTitle,
  percent: total > 0 ? Math.round((count / total) * 100) : 0,
});

const getUniqueTagTitlesAndCounts = (concepts: ModifiedManualAnalysisSummary[]) => {
  const uniqueTagTitlesAndCounts: {
    drivers: { id: string; label: string; count: number }[];
    outcomes: { id: string; label: string; count: number }[];
    behaviors: { id: string; label: string; count: number }[];
  } = {
    drivers: [],
    outcomes: [],
    behaviors: [],
  };
  concepts.forEach((concept) => {
    ['drivers', 'behaviors', 'outcomes'].forEach((key) => {
      concept[key].forEach((concept: { id: string; label: string }) => {
        const existingConcept = uniqueTagTitlesAndCounts[key].find(
          (tag: { id: string; label: string; count: number }) => tag.label === concept.label,
        );
        if (existingConcept) {
          existingConcept.count++;
        } else {
          uniqueTagTitlesAndCounts[key].push({ id: concept.id, label: concept.label, count: 1 });
        }
      });
    });
  });
  return uniqueTagTitlesAndCounts;
};

const getPanelData = (summaryData: ModifiedManualAnalysisSummary[]) => {
  const summaryCount = summaryData.length;
  const uniqueTagTitlesAndCounts: {
    drivers: { id: string; label: string; count: number }[];
    outcomes: { id: string; label: string; count: number }[];
    behaviors: { id: string; label: string; count: number }[];
  } = getUniqueTagTitlesAndCounts(summaryData);
  const driverData = uniqueTagTitlesAndCounts.drivers.map((tag) =>
    getPanelDataForConcept(tag.id, tag.label, tag.count, summaryCount));
  const outcomeData = uniqueTagTitlesAndCounts.outcomes.map((tag) =>
    getPanelDataForConcept(tag.id, tag.label, tag.count, summaryCount));
  const behaviorData = uniqueTagTitlesAndCounts.behaviors.map((tag) =>
    getPanelDataForConcept(tag.id, tag.label, tag.count, summaryCount));
  const panelData: PanelData = {
    driverData,
    outcomeData,
    behaviorData,
  };
  return panelData;
};

const getTagsTableData = (
  conceptIds: string[],
  setIds: string[],
  manualAnalysisSummaries: ModifiedManualAnalysisSummary[],
  conceptDictionary: { [x: string]: ConceptDictionaryEntry },
) =>
  conceptIds.map((conceptId: string) => {
    const setData = setIds.map((setId: string) => {
      const setConversations = manualAnalysisSummaries.filter(
        (conversation) => conversation.setId === setId,
      );
      const set = {
        id: setId,
        convoTotal: setConversations.length,
        convoWithTag: setConversations.reduce((acc, { behaviorTagIds }) => {
          if (behaviorTagIds.includes(conceptId)) {
            return acc + 1;
          }
          return acc;
        }, 0),
      };
      return set;
    });
    const aggregate = {
      convoTotal: setData.reduce(
        (acc: number, set: { convoTotal: number }) => acc + set.convoTotal,
        0,
      ),
      convoWithTag: setData.reduce(
        (acc: number, set: { convoWithTag: number }) => acc + set.convoWithTag,
        0,
      ),
    };
    return {
      tag: conceptDictionary[conceptId],
      setData,
      aggregate,
    };
  });

const filterBySet = (summary, ids) => {
  if (ids.length === 0) {
    return true;
  }
  return ids.includes(summary.setId);
};

const filterByConcept = (summary, ids) => {
  if (ids.length === 0) {
    return true;
  }
  // return true if any of the conceptFilterIds are in any of the summary.behaviorTagIds
  return ids.some((id) => summary.behaviorTagIds.includes(id));
};

const handleFiltering = (summary, { setFilter, driverFilter, outcomeFilter }) =>
  filterBySet(summary, setFilter)
  && filterByConcept(summary, driverFilter)
  && filterByConcept(summary, outcomeFilter);

export { aggregateConcepts, getPanelData, getTagsTableData, handleFiltering };
