import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useForm } from '@mantine/form';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { Concept, ConceptConceptType, ConceptState, CreateConceptRequest, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectConceptsFactory, selectConceptsMapFactory } from 'store/concept/selectors';
import { createConcept } from 'store/concept/asyncThunks';
import { v4 as uuid } from 'uuid';
import { UserContext } from 'context/UserContext';
import { AddIntentForm as AddIntentFormComponent, NewConceptData } from 'components/AddConceptModal/AddIntentModal/AddIntentForm';
import { showNotification } from '@mantine/notifications';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';

function getConceptRole(concept: Concept) {
  switch (concept?.intent?.intentType) {
    case IntentIntentType.AGENT_INTENT:
      return 'agent';
    case IntentIntentType.VISITOR_INTENT:
      return 'visitor';
    case IntentIntentType.STAGE:
      return 'stage';
    case IntentIntentType.CONVERSATION_DRIVER:
      return 'driver';
    default:
      return 'stage';
  }
}

export const AddIntentForm = ({
  onIntentChange,
}) => {
  const reduxIntents: Concept[] = useSelector(selectConceptsFactory(ConceptConceptType.INTENT));
  const [intents, setIntents] = useState(reduxIntents);
  const profile = useCustomerProfile();
  const currentUser = useContext(UserContext);
  const conceptsMap = useSelector(selectConceptsMapFactory(ConceptConceptType.INTENT));
  const dispatch = useDispatch();
  const customer = useCustomerParams();

  useEffect(() => {
    setIntents(reduxIntents);
  }, [reduxIntents]);

  const drivers = intents.filter((intent) => intent.intent.intentType === IntentIntentType.CONVERSATION_DRIVER);
  const stages = intents.filter((intent) => intent.intent.intentType === IntentIntentType.STAGE);
  const conceptForm = useForm<NewConceptData>({
    initialValues: {
      driverId: '',
      stageId: '',
      intentId: '',
      humanReadableName: '',
      intentType: null,
      conceptTags: [],
    },
  });

  const stageIntent = useMemo(() => intents.find((intent) => intent.name === conceptForm.values.stageId), [conceptForm.values.stageId, intents]);

  return (
    <AddIntentFormComponent
      drivers={drivers}
      stages={stages}
      intents={intents}
      conceptForm={conceptForm}
      conceptsMap={conceptsMap}
      createNewConcept={(inputValue: string, intentType: IntentIntentType) => {
        const id = uuid();
        const getConversationDriverNames = () => {
          if (intentType === IntentIntentType.STAGE && conceptForm.values.driverId) {
            return [conceptForm.values.driverId];
          }
          return [];
        };
        const parentConversationDriverNames = getConversationDriverNames();
        const conceptTitle = `${stageIntent.conceptTitle}.${inputValue}`;
        const concept = {
          conceptTitle,
          conceptType: ConceptConceptType.INTENT,
          state: ConceptState.CREATED,
          intent: {
            intentType,
            parentConversationDriverNames,
          },
        };
        const createConceptRequest: CreateConceptRequest = {
          parent: profile,
          conceptId: id,
          userResourceName: currentUser?.name,
          concept: {
            ...concept,
            name: `${profile}/concepts/${id}`,
            usecaseId: customer.usecaseId,
            languageCode: customer.languageCode,
          },
        };
        try {
          dispatch(createConcept(createConceptRequest)).then((res) => {
            const payload = res.payload as Concept;
            onIntentChange(payload.name);
          });
          showNotification({
            color: 'green',
            title: 'Intent created',
            message: `Intent ${conceptTitle} created successfully`,
          });
        } catch (e) {
          console.error(e);
          showNotification({
            color: 'red',
            title: 'Intent creation failed',
            message: `Intent ${conceptTitle} creation failed`,
          });
        }
        return {
          label: concept.conceptTitle,
          value: `${profile}/concepts/${id}`,
          type: getConceptRole(concept),
          concept: {
            ...concept,
            name: `${profile}/concepts/${id}`,
          },
        };
      }}
      setIntents={setIntents}
    />
  );
};
