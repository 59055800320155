import React, { useLayoutEffect, useState, useRef } from 'react';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Menu, Popover } from 'antd';
import { useWindowEventListener } from 'hooks/eventListeners';
import { useHotkeys } from 'hooks/useHotkeys';
import { EntityLabel } from '.';
import { MenuItem } from './MenuItem';
import { Popover as CustomPopover } from './Popover';
import { useNumberHotkeys } from './useNumberHotkeys';

const AddMenu = ({
  entities,
  handleAdd,
}: {
    entities: Concept[];
    handleAdd: (entityId: string) => void;
  }) => {
  const handleHotKeyPress = (key: number) => {
    const entityId = entities[key - 1].name;
    handleAdd(entityId);
  };
  const handleHotkey = (key: number) => {
    if (key > entities.length) return;
    handleHotKeyPress(key);
  };
  useNumberHotkeys(handleHotkey);
  useHotkeys('esc', () => {
    window.getSelection().removeAllRanges();
  });

  return (
    <Menu
      selectable
      onSelect={(item) => {
        handleAdd(item.key);
      }}
      items={entities.map((entity: Concept, index: number) => ({
        label: <MenuItem index={index}>{entity.conceptTitle}</MenuItem>,
        key: entity.name,
      }))}
    />
  );
};

const UpdateMenu = ({
  entityLabel,
  entities,
  handleUpdate,
  handleClose,
}: {
    entityLabel: EntityLabel;
    entities: Concept[];
    handleUpdate: (entityId: string, entityLabel: EntityLabel) => void;
    handleClose:()=>void
  }) => {
  const handleHotKeyPress = (key: number) => {
    const entityId = entities[key - 1].name;
    handleUpdate(entityId, entityLabel);
  };
  const handleHotkey = (key: number) => {
    if (key > entities.length) return;
    handleHotKeyPress(key);
  };
  useNumberHotkeys(handleHotkey);
  useHotkeys('esc', () => {
    handleClose();
  });
  return (
    <Menu
      selectable
      selectedKeys={[entityLabel.id]}
      onSelect={(item) => handleUpdate(item.key, entityLabel)}
      items={entities.map((entity: Concept, index: number) => ({
        label: <MenuItem index={index}>{entity.conceptTitle}</MenuItem>,
        key: entity.name,
      }))}
    />
  );
};

function isRegChar(char:string) {
  if (char?.length !== 1) throw new Error(`Invalid argument for 'isRegChar', expected string of length 1, received "${char}"`);
  return /[^\s\\]/.test(char);
}

function isClickedCharEmpty() {
  const s = window.getSelection();
  const newAnchorOffset = s.anchorOffset;
  return !isRegChar(s.anchorNode.textContent[newAnchorOffset]);
}

function selectEntireWord() {
  const s = window.getSelection();
  const { textContent } = s.anchorNode;
  let newAnchorOffset = s.anchorOffset;
  let newFocusOffset = s.anchorOffset;

  // subtract one from the anchorOffset until the anchorOffset represents a whitespace
  while (newAnchorOffset - 1 >= 0 && isRegChar(textContent[newAnchorOffset - 1])) {
    newAnchorOffset -= 1;
  }

  // handle offset
  while (newFocusOffset + 1 <= textContent.length && isRegChar(textContent[newFocusOffset])) {
    newFocusOffset += 1;
  }

  s.setBaseAndExtent(s.anchorNode, newAnchorOffset, s.focusNode, newFocusOffset);
}

const handleSingleClickSelectWord = () => {
  if (isClickedCharEmpty()) return;
  selectEntireWord();
};

export const UnlabeledSpan = ({
  children,
  indexStart,
  entities,
  handleAdd,
}: {
    entities: Concept[];
    handleAdd: (entityLabel: EntityLabel) => void;
    children: string;
    indexStart: number;
  }) => {
  const [ref, setRef] = useState<HTMLElement>();
  const [popoverWidth, setPopoverWidth] = useState(150);

  const handleMouseUp = () => {
    const { isCollapsed } = document.getSelection();

    // isCollapsed = nothing selected
    if (isCollapsed) {
      // Selects entire word
      handleSingleClickSelectWord();
    }
  };

  const addToBackend = (entityId:string) => {
    const { anchorOffset, focusOffset } = document.getSelection();
    const index = indexStart + anchorOffset;
    const length = Math.abs(focusOffset - anchorOffset);
    const labelToAdd: EntityLabel = {
      entityId,
      index,
      length,
    };
    handleAdd(labelToAdd);
  };
  const popoverRef = useRef<HTMLDivElement>();

  // update popoverWidthValue when popover is rendered
  useLayoutEffect(
    () => {
      if (popoverRef.current) {
        setPopoverWidth(popoverRef.current.clientWidth);
      }
    }
    , [popoverRef.current],
  );

  return (
    <>
      <CustomPopover
        target={ref}
        render={
        ({ clientRect, isCollapsed }) => {
          if (clientRect == null || isCollapsed) return null;
          return (
            <div
              ref={popoverRef}
              className="entity-selection-popover"
              style={{
                left: `${clientRect.left + (clientRect.width / 2) - (popoverWidth / 2)}px`,
                top: `${clientRect.top + 45}px`,
              }}
              // prevents deselection of text
              onMouseDown={(e) => e.preventDefault()}
            >
              <AddMenu entities={entities} handleAdd={addToBackend} />
            </div>
          );
        }
      }
      />
      <span
        ref={(el) => el != null && setRef(el)}
        index-start={indexStart}
        onMouseUp={handleMouseUp}
      >
        {children}
      </span>
    </>
  );
};
export const LabeledSpan = ({
  label,
  tagLabel,
  entities,
  labelIndex,
  handleUpdate,
  children,
}: {
    label: EntityLabel;
    tagLabel: string;
    entities: Concept[];
    labelIndex: number | string;
    handleUpdate: (entityId: string, entityLabel: EntityLabel) => void;
    children: string;
  }) => {
  const [isOpen, toggleOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>();

  // creates an onclickoutside listener
  useWindowEventListener('click', (event: { target: Node; }) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleOpen(false);
    }
  });
  return (
    <Popover
      // allows popover styling specific to this component
      getPopupContainer={() => ref.current}
      placement="bottom"
      visible={isOpen}
      content={(
        <UpdateMenu
          entityLabel={label}
          entities={entities}
          handleUpdate={handleUpdate}
          handleClose={() => toggleOpen(false)}
        />
      )}
    >
      <span
        ref={ref}
        onClick={() => toggleOpen((prev) => !prev)}
        entity-label-id={label.id}
        className={`entity label-${labelIndex} labelled`}
        data-label={tagLabel?.toUpperCase()}
      >
        {children}
      </span>
    </Popover>
  );
};
