import { Tooltip } from 'antd';
import React from 'react';
import './MultiTags.scss';

type MultiTagsProps = {
  tags: string[];
  detail?: boolean;
  type?: 'intent' | 'driver' | 'conceptTag'| 'entity';
  size?: 'default' | 'large' | 'small';
  color?: 'red' | 'green' | 'blue' | 'gray' | 'lightgreen';
};

export default function MultiTags({
  tags,
  detail = false,
  type = 'intent',
  size = 'default',
  color = 'blue',
}: MultiTagsProps) {
  if (!tags || !tags.length) {
    return <></>;
  }
  const content = () => {
    if (tags.length > 1) {
      if (detail) {
        return tags.map((tag) => <div className={`multi-tag ${type}`} key={tag}>{tag}</div>);
      }
      return (
        <>
          <div className={`multi-tag ${type} ${color}`}>{tags[0]}</div>
          <div className={`multi-tag ${type} ${color}`}>+{tags.length - 1}</div>
        </>
      );
    }
    return <div className={`multi-tag ${type} ${color}`}>{tags[0]}</div>;
  };
  return (
    <Tooltip title={tags.length > 1 && tags.join(', ')}>
      <div className={`multi-tag-container ${size}`}>
        {content()}
      </div>
    </Tooltip>
  );
}
