import { v4 as uuid } from 'uuid';
import { ExampleObj } from '../types';

export const getUpdatedExampleList = (
  examples: ExampleObj[],
  indexToUpdate: number,
  newValue: string,
) => {
  const updatedExamples: ExampleObj[] = examples.map((item, index) => {
    if (index === indexToUpdate) {
      return {
        ...item,
        value: newValue,
      };
    }
    return item;
  });
  return updatedExamples;
};

export const createNewExample: (text: string) => ExampleObj = (text) => {
  const newExample = {
    value: text,
    id: uuid(),
  };
  return newExample;
};
