import React from 'react';
import Confetti from 'react-confetti';
import hotdog from 'assets/png/hotdog.png';
import biryani from 'assets/png/biryani.png';
import { render, unmountComponentAtNode } from 'react-dom';

const IMAGES = [hotdog, biryani];
const getRandomParticle = () =>
  IMAGES[Math.floor(Math.random() * IMAGES.length)];

export function showConfetti() {
  const image = new Image();
  image.src = getRandomParticle();
  const confetti = (
    <Confetti
      recycle={false}
      numberOfPieces={1000}
      width={window.innerWidth}
      height={window.innerHeight}
      onConfettiComplete={() => {
        const target = document.getElementById('studio-confetti-container');
        if (target) {
          unmountComponentAtNode(target);
          target.parentNode.removeChild(target);
        }
      }}
      drawShape={(ctx) => ctx.drawImage(image, 0, 0)}
    />
  );
  let divTarget = document.getElementById('studio-confetti-container');
  if (divTarget) {
    render(confetti, divTarget);
  } else {
    divTarget = document.createElement('div');
    divTarget.id = 'studio-confetti-container';
    document.body.appendChild(divTarget);
    render(confetti, divTarget);
  }
}
