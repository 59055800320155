import { DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { CustomerParams } from 'hooks/useCustomerParams';
import { ApiStatus } from 'store/types';

export interface SyntheticLabelsModalData {
  visible: boolean,
  conceptId: string,
  split: DataSplit,
}

/** App state. */
export interface State {
  debuggingMode: boolean;
  syntheticLabelsModal: SyntheticLabelsModalData;
  // Customer parameters selected for task playlist
  taskPlaylistCustomers: CustomerParams[];
  // Current user ids for those selected customers
  customersCurrentUserIds: {
    // customer format: '${customerId}/${profileId}'
    [customer: string]: string
  },
  status: ApiStatus;
}

const debuggingMode = localStorage.getItem('debuggingMode');
const taskPlaylistCustomersFromStorage = localStorage.getItem('taskPlaylistCustomers');

/** Initial app state. */
export const INITIAL_STATE: State = {
  debuggingMode: debuggingMode === 'true',
  taskPlaylistCustomers: taskPlaylistCustomersFromStorage ? JSON.parse(taskPlaylistCustomersFromStorage) : [],
  customersCurrentUserIds: {},
  syntheticLabelsModal: {
    visible: false,
    conceptId: '',
    split: DataSplit.TEST,
  },
  status: 'idle',
};
