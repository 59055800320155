import React, { ReactElement } from 'react';
import { Tag, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import './PredictionMatrix.scss';

/** One prediction result. */
export interface LabelScore {
  label: string;
  score: number;
}

/** Message prediction record * */
export interface PredictionRecord {
  index: number;
  chat_id: string;
  message: string;
  predLabels: LabelScore[];
  // Max score of the predicted labels.
  maxScore: number,
}

const columns: ColumnType<PredictionRecord>[] = [
  {
    title: 'Chat Id',
    dataIndex: 'chat_id',
    key: 'chat_id',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: 'Predictions',
    dataIndex: 'predLabels',
    key: 'prediction',
    // Sorting by the highest score.
    sorter: (r1: PredictionRecord, r2: PredictionRecord) => r1.maxScore - r2.maxScore,
    // Render green color if true-positive; red if false-positive.
    render: (value: LabelScore[], record: PredictionRecord) => (
      <div className="predictions">
        {value.map((pred) => (<Tag className="tag" >{`${pred.label}: ${pred.score.toFixed(2)}`}</Tag>))}
      </div>
    ),
  },
];

interface Props {
  predictions: PredictionRecord[];
}
export function PredictionMatrix({ predictions }: Props): ReactElement {
  return (
    <Table
      className="model-builder-prediction-matrix"
      title={() => 'Chat Predictions'}
      columns={columns}
      dataSource={predictions}
      rowKey={(record: PredictionRecord) => record.index}
    />
  );
}
