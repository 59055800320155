import React, { Key } from 'react';
import { BooleanDropdown, Value as LabelValue } from 'components/BooleanDropdown';
import ConceptTypeTag from 'components/ConceptTypeTag';
import CopyableValue from 'components/CopyableValue';
import { DataGrid } from 'components/DataGrid';
import { truncateMiddle } from 'utils';

export interface MisclassificationTableRow {
  id: string;
  utterance: string;
  intent: {
    id: string;
    label: string;
  };
  label: LabelValue;
  addedToTrainSet: boolean;
}

const LabelCell = ({ value, onUpdateLabel }: {
  value: LabelValue,
  onUpdateLabel: (label: LabelValue) => void;
}) => <BooleanDropdown onChange={onUpdateLabel} value={value} />;

interface Props {
    selectedRowKeys: Key[];
    setSelectedRowKeys: (keys: Key[]) => void;
    dataSource: MisclassificationTableRow[];
    onUpdateLabel: (rowKey: Key, label: LabelValue) => void;
    onIntentClick: (id: string) => void;
}

export const MisclassificationTable = ({
  selectedRowKeys,
  setSelectedRowKeys,
  dataSource,
  onUpdateLabel,
  onIntentClick,
}: Props) => (
  <DataGrid
    dataSource={dataSource}
    rowSelection={{
      selectedRowKeys,
      onChange: setSelectedRowKeys,
    }}
    columns={[
      {
        header: 'Annotation Id',
        accessorKey: 'id',
        cell: (info) => (
          <CopyableValue
            copiedValue={info.getValue() as string}
            displayValue={`${truncateMiddle(info.getValue() as string)}`}
          />
        ),
      },
      {
        header: 'Utterance',
        accessorKey: 'utterance',
      },
      {
        header: 'Intent',
        accessorKey: 'intent',
        cell: (info) => {
          const { id, label } = info.getValue() as {
            id: string;
            label: string;
          };
          return (
            <ConceptTypeTag
              color="blue"
              variant="light"
              title={label}
              onClick={() => {
                onIntentClick(id);
              }}
            />
          );
        },
      },
      {
        header: 'Label',
        accessorKey: 'label',
        cell: (info) => (
          <LabelCell value={info.getValue()} onUpdateLabel={(label) => onUpdateLabel(info.row.id, label)} />
        ),
      },
    ]}
  />
);
