import React from 'react';
import { Button, Group, Title } from '@mantine/core';

export const ConfigStep = (props) => {
  const { onClickNext, children } = props;
  return (
    <>
      <Group position="apart" mx={40} mb="sm" style={{ background: '#fff' }}>
        <Title order={2}>
          Configure data set for this model
        </Title>
        <Button onClick={onClickNext}>
          Next
        </Button>
      </Group>
      <Group align="flex-start" style={{ background: '#F1F3F4', flex: 1 }} px={40} py="sm" position="apart">
        {children}
      </Group>
    </>
  );
};
