import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { ModelMetrics } from '@cresta/web-client/dist/cresta/v1/studio/storage/modelbuilder/modelbuilder.pb';
import { ApiStatus } from 'store/types';

// Model type.
export type ModelType = 'agent' | 'visitor' | 'driver' | 'policy' | 'ensemble';

/** Model detail. */
export interface Model {
  displayType: string;
  type: ModelType;
  url: string;
  modelMetrics?: ModelMetrics;
  evaluationArtifact?: string;
  dependencies?: string[];
}

/** Prod intent name and type. */
export interface ProdIntent {
  intent: string;
  type: IntentIntentType;
  positiveRegex: string[];
  negativeRegex: string[];
}

/** Model Builder state. */
export interface State {
  servingModels: Model[];
  servingModelsFromAIServices: Model[];
  prodIntents: ProdIntent[];
  openedArtifacts: ModelArtifact[];
  loadServingModelFromAIServicesStatus: ApiStatus;
  loadServingModelStatus: ApiStatus,
  loadProdTaxonomiesStatus: ApiStatus,
}

/** Initial model proejct state. */
export const INITIAL_STATE: State = {
  servingModels: [],
  servingModelsFromAIServices: [],
  prodIntents: [],
  openedArtifacts: [],
  loadServingModelFromAIServicesStatus: 'idle',
  loadServingModelStatus: 'idle',
  loadProdTaxonomiesStatus: 'idle',
};
