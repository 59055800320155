import React from 'react';
import { useMantineTheme } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { ConceptData, ConfigFormValues, FormValueExampleArrayType } from '../types';
import { createNewExample, getUpdatedExampleList } from './utils';
import { ConfigurationPage as ConfigurationPageComponent } from './ConfigurationPage';

interface ConfigurationPageProps {
    onCreateBtnClick: () => void;
    configForm: UseFormReturnType<ConfigFormValues>;
    conceptData: ConceptData;
    handleCancelConfig: () => void;
}

export const ConfigurationPage = (props: ConfigurationPageProps) => {
  const {
    onCreateBtnClick,
    configForm,
    conceptData,
    handleCancelConfig,
  } = props;
  const theme = useMantineTheme();

  const handleUpdateExamples = (formValue: 'positiveExamples' | 'negativeExamples', indexToUpdate: number, value: string) => {
    const updatedExampleList = getUpdatedExampleList(configForm[formValue], indexToUpdate, value);
    configForm.setFieldValue(formValue, updatedExampleList);
  };

  const handleAddNewExample = (formValue: FormValueExampleArrayType, text: string) => {
    configForm.insertListItem(formValue, createNewExample(text));
    configForm.clearFieldError(formValue);
  };

  const handleDeleteExample = (formValue: FormValueExampleArrayType, indexToDelete: number) => {
    configForm.removeListItem(formValue, indexToDelete);
    configForm.clearFieldError(formValue);
  };

  return (
    <ConfigurationPageComponent
      onCreateBtnClick={onCreateBtnClick}
      handleCancelConfig={handleCancelConfig}
      configForm={configForm}
      conceptData={conceptData}
      handleUpdateExamples={handleUpdateExamples}
      handleAddNewExample={handleAddNewExample}
      handleDeleteExample={handleDeleteExample}
      theme={theme}
    />
  );
};
