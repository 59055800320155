import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from 'antd';
import { getId } from 'common/resourceName';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import moment from 'moment';
import { Button } from '@mantine/core';
import { useAgentNames } from 'hooks/useAgentNames';
import ChatCard from '../ChatCard';
import './ChatList.scss';
import Loader from '../Loader';

interface Props {
  handleNavigation: (id: string) => {
    pathname: string;
    search: string;
  };
  chats: Conversation[];
  loadMoreChats: () => Promise<void>;
  isLoading: boolean;
  hasNextPage: boolean;
  handleFilterReset: () => void;
}

export function ChatList(props: Props) {
  const { handleNavigation, chats, isLoading, hasNextPage, handleFilterReset, loadMoreChats } = props;
  const { getAgentNameFromActorId } = useAgentNames();

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current || isLoading) return;
    ref.current.scrollTo(0, 0);
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  if (!chats.length) {
    return (
      <div className="empty-chats-container">
        <Typography>No chats to display</Typography>
        <Button color="red" onClick={() => handleFilterReset()}>
          Clear Filters
        </Button>
      </div>
    );
  }

  return (
    <div className="chat-list-container" ref={ref}>
      {chats && (
        <ul>
          {chats.map((chat) => {
            const id = getId('conversation', chat.name);
            const actorId = getAgentNameFromActorId(chat.actorIds[0]);
            return (

              <NavLink
                key={id}
                className={({ isActive }) => (isActive ? 'activeChatCard chat-list-nav-link' : 'chat-list-nav-link')}
                to={handleNavigation(id)}
              >
                <li>
                  <ChatCard
                    title={actorId}
                    copyableValue={id}
                    footer={moment(chat.openTime).format('MM/DD/YYYY h:mma')}
                  />
                </li>
              </NavLink>

            );
          })}
        </ul>
      )}
      <div className="chat-list-load-more-container">
        {hasNextPage ? (
          <Button
            key="load-more-button"
            className="load-more-button"
            loading={isLoading}
            onClick={loadMoreChats}
          >
            Load more
          </Button>
        ) : (
          'End of results'
        )}
      </div>
      <div className="filler" />
    </div>
  );
}
