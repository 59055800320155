import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import { Anchor, Text } from '@mantine/core';
import { QaDataSource } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Dayjs } from 'dayjs';
import { ModelType, RegressionArtifactInputs } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { useApiGet } from 'hooks/network';
import { ModelSummary } from 'store/modelBuilder/asyncThunks';
import { openNotification } from 'components/Notification';
import { useCustomerParams } from 'hooks/useCustomerParams';
import styles from '../styles.module.scss';

interface PolicyTaskFormProps {
  setQaSource: (source: QaDataSource) => void;
  conversations: string[];
  setConversations: (conversations: string[]) => void;
  maxConversationCount: number;
  setMaxConversationCount: (value: number) => void;
  dateAfterAndBefore: [Dayjs, Dayjs];
  setDateAfterAndBefore: (date: [Dayjs, Dayjs]) => void;
  regressionArtifactInputs: RegressionArtifactInputs;
  setRegressionArtifactInputs: (inputs: RegressionArtifactInputs) => void;
}

export default function PolicyTaskForm({
  setQaSource,
  conversations,
  setConversations,
  maxConversationCount,
  setMaxConversationCount,
  dateAfterAndBefore,
  setDateAfterAndBefore,
  regressionArtifactInputs,
  setRegressionArtifactInputs,
}: PolicyTaskFormProps) {
  const apiGet = useApiGet(false);
  const { path } = useCustomerParams();
  const hasDateRange = useMemo(() => dateAfterAndBefore?.every((date) => date), [dateAfterAndBefore]);
  const isValidDateAndCount = useMemo(() => (hasDateRange ? hasDateRange && maxConversationCount > 0 : true), [hasDateRange, maxConversationCount]);
  const onRangeChange = (val: [Dayjs, Dayjs]) => {
    setDateAfterAndBefore([val[0]?.startOf('day'), val[1]?.endOf('day')]);
  };
  const [mode] = useState<'prod' | 'pp-intent-policy' | 'pp-ensemble'>('pp-intent-policy');
  const [samplingType, setSamplingType] = useState<'random' | 'specific'>('random');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [loadingModelUri, setLoadingModelUri] = useState(true);

  const populateModels = async () => {
    const {
      stackedAgentModelUri,
      stackedVisitorModelUri,
      chatDriverModelUri,
      rulePolicyUri,
    } = regressionArtifactInputs;

    const shouldPopulate = !stackedAgentModelUri
    && !stackedVisitorModelUri
    && !chatDriverModelUri
    && !rulePolicyUri;

    if (!shouldPopulate) {
      setLoadingModelUri(false);
      return;
    }

    const modelPath = `${path}/get_serving_model_details`;
    setLoadingModelUri(true);
    try {
      const response = await apiGet(modelPath);
      const modelSummary: ModelSummary = response.result;
      setRegressionArtifactInputs({
        ...regressionArtifactInputs,
        stackedAgentModelUri: modelSummary?.agent_intent_model_dependency_urls?.url,
        stackedVisitorModelUri: modelSummary?.visitor_intent_model_dependency_urls?.url,
        chatDriverModelUri: modelSummary?.chat_driver_model_dependency_urls?.url,
        rulePolicyUri: modelSummary?.dialog_policy_url?.url,
      });
    } catch (err) {
      openNotification('error', 'Failed to auto-populate model uri', undefined, err);
      throw err;
    } finally {
      setLoadingModelUri(false);
    }
  };

  useEffect(() => {
    populateModels();
  }, []);

  // Reset data dependant on sampling type
  useEffect(() => {
    setConversations([]);
    setDateAfterAndBefore(null);
  }, [samplingType]);

  // Reset form data based on selected mode
  useEffect(() => {
    if (mode === 'prod') {
      setShowAdvancedSettings(false);
    }

    setRegressionArtifactInputs({
      ...regressionArtifactInputs,
      modelUri: regressionArtifactInputs.modelUri,
      rulePolicyUri: regressionArtifactInputs.rulePolicyUri,
      chatDriverModelUri: regressionArtifactInputs.chatDriverModelUri,
      stackedAgentModelUri: regressionArtifactInputs.stackedAgentModelUri,
      stackedVisitorModelUri: regressionArtifactInputs.stackedVisitorModelUri,
      actionsImageTag: mode === 'prod' ? undefined : regressionArtifactInputs.actionsImageTag,
      orchestratorImageTag: mode === 'prod' ? undefined : regressionArtifactInputs.orchestratorImageTag,
      chatAiImageTag: mode === 'prod' ? undefined : regressionArtifactInputs.chatAiImageTag,
      intentImageTag: mode === 'prod' ? undefined : regressionArtifactInputs.intentImageTag,
      policyImageTag: mode === 'prod' ? undefined : regressionArtifactInputs.policyImageTag,
      modelType: mode === 'pp-ensemble' ? ModelType.NEXT_AGENT_INTENT_ENSEMBLE : ModelType.STACKED_CLASSIFIER,
    });
  }, [mode]);

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  const renderTagInput = (tagKey: keyof RegressionArtifactInputs, label: string) => (
    <Input
      type="text"
      placeholder={label}
      value={regressionArtifactInputs[tagKey] as string | undefined}
      onChange={(e) => {
        setRegressionArtifactInputs({
          ...regressionArtifactInputs,
          [tagKey]: e.target.value,
        });
      }}
    />
  );

  return (
    <>
      <Form.Item>
        <Text size="md">Sampling type</Text>
        <Select
          showArrow
          className={styles.taskType}
          size="large"
          value={samplingType}
          onChange={(value) => setSamplingType(value)}
        >
          <Select.Option value="random">
            Random
          </Select.Option>
          <Select.Option value="specific">
            Specific
          </Select.Option>
        </Select>
      </Form.Item>
      {samplingType === 'random' ? (
        <Form.Item >
          <Text size="md">Conversations from this time range</Text>
          <DatePicker.RangePicker
            value={dateAfterAndBefore}
            onChange={onRangeChange}
          />
        </Form.Item>
      ) : (
        <Form.Item >
          <Text size="md">Conversation ID (separated by comma)</Text>
          <Input
            type="text"
            value={conversations.join(',')}
            onChange={(e) => {
              setConversations(e.target.value.split(','));
            }}
          />
        </Form.Item>
      )}
      {samplingType === 'random' && (
        <Form.Item
          validateStatus={(!isValidDateAndCount) && 'error'}
          help={!isValidDateAndCount && 'Max conversation count has to be greater than 0'}
        >
          <Text size="md">Max conversations count</Text>
          <div className={styles.chatCount}>
            <Input
              size="large"
              value={maxConversationCount}
              type="number"
              onChange={(event) =>
                setMaxConversationCount(Number(event.currentTarget.value))}
            />
          </div>
        </Form.Item>
      )}
      {mode === 'pp-intent-policy' && (
        <Form.Item>
          <Text size="md">Classifier type</Text>
          <Select
            showArrow
            size="large"
            value={regressionArtifactInputs.modelType}
            onChange={(value) => setRegressionArtifactInputs({
              ...regressionArtifactInputs,
              modelType: value,
            })}
          >
            <Select.Option value={ModelType.FAST_INTENT_CLASSIFIER}>
              Fast intents classifier
            </Select.Option>
            <Select.Option value={ModelType.STACKED_CLASSIFIER}>
              Stacked classifier(s)
            </Select.Option>
          </Select>
        </Form.Item>
      )}
      {mode !== 'prod' && (
        <>
          <Form.Item>
            {mode === 'pp-ensemble' || regressionArtifactInputs.modelType === ModelType.FAST_INTENT_CLASSIFIER ? (
              <>
                <Text size="md">Model URL</Text>
                <br/>
                <Input
                  type="text"
                  placeholder={mode === 'pp-ensemble' ? 'Enter NAA ensemble model URL' : 'Enter fast intents model URL'}
                  value={regressionArtifactInputs.modelUri}
                  onChange={(e) => {
                    setRegressionArtifactInputs({
                      ...regressionArtifactInputs,
                      modelUri: e.target.value,
                    });
                  }}
                />
              </>
            ) : (
              <>
                <Text weight={500}>Model URL (At least one required)</Text>
                <Text size="md" mt="sm">Chat driver</Text>
                <Input
                  type="text"
                  value={regressionArtifactInputs.chatDriverModelUri}
                  onChange={(e) => {
                    setRegressionArtifactInputs({
                      ...regressionArtifactInputs,
                      chatDriverModelUri: e.target.value,
                    });
                  }}
                  placeholder={loadingModelUri ? 'Loading latest model from production…' : 'Model URL'}
                />
                <Text size="md" mt="sm">Agent</Text>
                <Input
                  type="text"
                  value={regressionArtifactInputs.stackedAgentModelUri}
                  onChange={(e) => {
                    setRegressionArtifactInputs({
                      ...regressionArtifactInputs,
                      stackedAgentModelUri: e.target.value,
                    });
                  }}
                  placeholder={loadingModelUri ? 'Loading latest model from production…' : 'Model URL'}
                />
                <Text size="md" mt="sm">Visitor</Text>
                <Input
                  type="text"
                  value={regressionArtifactInputs.stackedVisitorModelUri}
                  onChange={(e) => {
                    setRegressionArtifactInputs({
                      ...regressionArtifactInputs,
                      stackedVisitorModelUri: e.target.value,
                    });
                  }}
                  placeholder={loadingModelUri ? 'Loading latest model from production…' : 'Model URL'}
                />
              </>
            )}
          </Form.Item>
          {mode !== 'pp-ensemble' && (
            <Form.Item>
              <Text size="md">Policy URL</Text>
              <Input
                type="text"
                placeholder={loadingModelUri ? 'Loading latest policy url from production…' : 'Policy URL'}
                value={regressionArtifactInputs.rulePolicyUri}
                onChange={(e) => {
                  setRegressionArtifactInputs({
                    ...regressionArtifactInputs,
                    rulePolicyUri: e.target.value,
                  });
                }}
              />
            </Form.Item>
          )}
        </>
      )}
      {mode !== 'prod' && (
        <>
          <Anchor
            type="submit"
            onClick={toggleAdvancedSettings}
            size="md"
          >{showAdvancedSettings ? 'Hide' : 'Show'} advanced settings
          </Anchor>
          <br/>
          {
            showAdvancedSettings && (
              <div className={styles.imageTagInputs}>
                <Text size="md">Image overrides</Text>
                {renderTagInput('chatAiImageTag', 'Chat AI image tag')}
                {renderTagInput('actionsImageTag', 'Actions image tag')}
                {renderTagInput('orchestratorImageTag', 'Orchestrator image tag')}
                {renderTagInput('intentImageTag', 'Intent image tag')}
                {renderTagInput('policyImageTag', 'Policy image tag')}
              </div>
            )
          }
        </>
      )}
    </>
  );
}
