import { ModelProject } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { State } from './state';

/** General reducer for starting a model project API. */
export function modelProjectApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed model project API. */
export function modelProjectApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** Reducer for listing model project successfully. */
export function listModelProjectsSuccess(state: State, action: PayloadAction<ModelProject[]>): State {
  return {
    ...state,
    status: 'succeeded',
    modelProjects: action.payload,
  };
}

/** Reducer for create a model project successfully. */
export function createModelProjectsSuccess(state: State, action: PayloadAction<ModelProject>): State {
  return {
    ...state,
    status: 'succeeded',
    modelProjects: [action.payload, ...state.modelProjects],
  };
}

/** Reducer for update a model project successfully. */
export function updateModelProjectsSuccess(state: State, action: PayloadAction<ModelProject>): State {
  const modelProjects = [...state.modelProjects];
  const index = modelProjects.findIndex((p) => p.name === action.payload.name);
  if (index >= 0) {
    modelProjects[index] = action.payload;
  }
  return {
    ...state,
    status: 'succeeded',
    modelProjects,
  };
}
