import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as ConcepChangeState } from './state';

const selectConceptChange = (state: State) => state.conceptChange;

/** Select intent concepts array. */
export const selectConceptChanges = createSelector(selectConceptChange, (conceptChangeState: ConcepChangeState) => conceptChangeState.conceptChanges);

/** Select next page token */
export const selectNextPageToken = createSelector(selectConceptChange, (conceptChangeState: ConcepChangeState) => conceptChangeState.nextPageToken);

/** Api status. */
export const selectApiStatus = createSelector(selectConceptChange, (conceptChangeState: ConcepChangeState) => conceptChangeState.status);
