import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { UITagEnum } from '.';
import { UITagType } from '../..';

const VISiTOR_TAG_TYPES = [
  TagTagType.STAGE,
  TagTagType.VISITOR_INTENT,
  TagTagType.DRIVER,
  TagTagType.CONVERSATION_OUTCOME,
];
const AGENT_TAG_TYPES = [
  TagTagType.STAGE,
  TagTagType.AGENT_INTENT,
  TagTagType.CONVERSATION_OUTCOME,
];

export const filterAllTagsByActortype = (tag: UITagType, actorTypeOnMessage: ActorType) => {
  if (!actorTypeOnMessage) return true;
  if (actorTypeOnMessage === ActorType.AGENT) {
    return AGENT_TAG_TYPES.includes(tag?.tagType);
  } else if (actorTypeOnMessage === ActorType.VISITOR) {
    return VISiTOR_TAG_TYPES.includes(tag?.tagType);
  } else {
    return tag?.tagType === TagTagType.AGENT_INTENT || tag?.tagType === TagTagType.VISITOR_INTENT;
  }
};

export const filterByActortype = (tag: UITagType, actorTypeOnMessage: ActorType) => {
  if (actorTypeOnMessage === ActorType.AGENT) {
    return tag?.tagType === TagTagType.AGENT_INTENT;
  } else if (actorTypeOnMessage === ActorType.BOT) {
    return tag?.tagType === TagTagType.AGENT_INTENT;
  } else if (actorTypeOnMessage === ActorType.VISITOR) {
    return tag?.tagType === TagTagType.VISITOR_INTENT;
  } else {
    return tag?.tagType === TagTagType.AGENT_INTENT || tag?.tagType === TagTagType.VISITOR_INTENT;
  }
};

const sortOrder = [
  TagTagType.STAGE_BEGIN,
  TagTagType.DRIVER,
  TagTagType.CONVERSATION_OUTCOME,
  TagTagType.AGENT_INTENT,
  TagTagType.VISITOR_INTENT,
];
export const sortByTagType = (a: UITagType, b: UITagType) => {
  const aIndex = sortOrder.indexOf(a.tagType);
  const bIndex = sortOrder.indexOf(b.tagType);
  if (aIndex < bIndex) return -1;
  if (aIndex > bIndex) return 1;
  return 0;
};

// sort alphabetically by label
export const sortByLabel = (a: UITagType, b: UITagType) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const filterByTagType = (uiTagType: UITagEnum, inverse?: boolean) => (tag: UITagType) => {
  const getResult = () => {
    switch (uiTagType) {
      case UITagEnum.STAGE:
        return tag.tagType === TagTagType.STAGE_BEGIN || tag.tagType === TagTagType.STAGE;
      case UITagEnum.DRIVER:
        return tag.tagType === TagTagType.DRIVER;
      case UITagEnum.OUTCOME:
        return tag.tagType === TagTagType.CONVERSATION_OUTCOME;
      case UITagEnum.BEHAVIOR:
        return tag.tagType === TagTagType.AGENT_INTENT || tag.tagType === TagTagType.VISITOR_INTENT;
      default:
        return true;
    }
  };
  const result = getResult();
  return inverse ? !result : result;
};
