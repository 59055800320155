import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import ModelSelect from 'components/ModelSelect';
import RuleGraphVis from 'components/RuleGraph';
import { ModelName } from 'constants/actions';
import { PolicyLocalStorageKey } from 'constants/policy';
import { useApiData } from 'hooks/network';
import { useLocalStorageArrayState } from 'hooks/useLocalStorageState';
import useUrlParam from 'hooks/useUrlParam';
import React from 'react';

import styles from './styles.module.scss';

export default function RuleGraph() {
  const [
    recentModelNames,
    ,
    insertRecentModel,
  ] = useLocalStorageArrayState(PolicyLocalStorageKey.RECENT_RULE_GRAPHS, [], true);
  const [model, setModel] = useUrlParam<string>('model', recentModelNames[0]);
  const {
    loading,
    data,
    error,
  } = useApiData(model ? `models/actions/${ModelName.RULE_GRAPH}/${model}/visualize` : null);

  return (
    <div className={styles.ruleGraphContent}>
      <label>Select Rule Graph</label>
      <ModelSelect
        value={model}
        modelName={ModelName.RULE_GRAPH}
        onSelect={(_model) => {
          setModel(_model);
          insertRecentModel(_model);
        }}
        recentlyUsed={recentModelNames}
      />
      <span className={styles.warning}>
        (Warning: these are the versions in S3, not the draft versions in Studio)
      </span>
      {loading && <Loading />}
      {error && <ErrorMessage message={(error as any).message} />}
      {data && (
        <div className={classNames(styles.container, { [styles.loading]: loading })}>
          <RuleGraphVis data={data} />
        </div>
      )}
    </div>
  );
}
