/* eslint-disable no-await-in-loop */
import {
  CreateMessageRequest,
  CreateMessageResponse,
  MessageService,
  RegexSearchMessagesRequest,
  RegexSearchMessagesResponse,
  RetrieveStudioClusteringMessagesRequest,
  RetrieveStudioClusteringMessagesResponse,
  RetrieveStudioClusteringMessageStatsRequest,
  RetrieveStudioClusteringMessageStatsResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { StudioApi } from './studioApi';

export abstract class MessageApi {
  public static createMessage(request: CreateMessageRequest): Promise<CreateMessageResponse> {
    return MessageService.CreateMessage(request, StudioApi.getHeaders().initReq);
  }

  public static regexSearchMessages(request: RegexSearchMessagesRequest): Promise<RegexSearchMessagesResponse> {
    return MessageService.RegexSearchMessages(request, StudioApi.getHeaders().initReq);
  }

  public static retrieveStudioClusteringMessageStats(request: RetrieveStudioClusteringMessageStatsRequest): Promise<RetrieveStudioClusteringMessageStatsResponse> {
    return MessageService.RetrieveStudioClusteringMessageStats(request, StudioApi.getHeaders().initReq);
  }

  public static retrieveStudioClusteringMessages(request: RetrieveStudioClusteringMessagesRequest): Promise<RetrieveStudioClusteringMessagesResponse> {
    return MessageService.RetrieveStudioClusteringMessages(request, StudioApi.getHeaders().initReq);
  }
}
