// Shared component to add concepts that only require a display name.
import React, { forwardRef, useCallback, useMemo } from 'react';
import { Group, Select, Text, Radio, SelectItem, TextInput } from '@mantine/core';
import { Concept, ConceptConceptSource, ConceptConceptTagType, ConceptConceptType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getConceptRole, RoleIcon } from 'components/RoleIcon';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';
import { UseFormReturnType } from '@mantine/form';
import { CategoryType, HIDDEN_TAGS } from 'pages/Taxonomy/TaxonomyGroup/TaxonomyTab';

// Dummy stage exists only so we can show library concepts that don't have stage in their title
export const DUMMY_STAGE: Concept = {
  labelingGuideline: '',
  description: '',
  conceptType: ConceptConceptType.INTENT,
  state: ConceptState.CREATED,
  conceptTitle: '[No stage]',
  name: 'DUMMY',
  intent: {
    intentType: IntentIntentType.STAGE,
  },
};

export interface NewConceptData {
  // Selected driver id
  driverId: string,
  // Selected stage id
  stageId: string,
  // Intents can be selected from shared library
  intentId: string,
  intentType: IntentIntentType,
  humanReadableName: string,
  conceptTags: ConceptConceptTagType[],
}

interface AddIntentFormProps {
  drivers: Concept[];
  stages: Concept[];
  intents: Concept[];
  conceptForm: UseFormReturnType<NewConceptData>;
  conceptsMap: Map<string, Concept>;
  createNewConcept: (conceptTitle: string, type: IntentIntentType) => SelectItem;
  // Needed to update intent placeholder type after it was created
  setIntents: (intents: Concept[]) => void;
}

// Map concepts to mantine select items
function toSelectItem(concept: Concept) {
  const isIntent = [IntentIntentType.AGENT_INTENT, IntentIntentType.VISITOR_INTENT].includes(concept.intent.intentType);
  let group;

  // TODO: There is not a good way to tell shared library intents apart from customer intents
  if (isIntent && concept.conceptTags) {
    group = 'Shared Library';
  }

  const item: SelectItem = {
    label: concept.conceptTitle,
    value: concept.name,
    type: getConceptRole(concept),
    concept,
    group,
  };
  return item;
}

export function AddIntentForm({
  drivers,
  stages,
  intents,
  conceptForm,
  conceptsMap,
  createNewConcept,
  setIntents,
}: AddIntentFormProps) {
  const stagesWithDummyStage = [DUMMY_STAGE, ...stages];
  const { driverId, stageId, intentId, intentType } = conceptForm.values;

  // Parent concept (driver, stage or none)
  const parent = useMemo(() => {
    let parentConcept: Concept;
    if (driverId != null) {
      parentConcept = drivers.find((driver) => driver.name === driverId);
    }
    if (stageId != null) {
      parentConcept = stagesWithDummyStage.find((stage) => stage.name === stageId);
    }
    return parentConcept;
  }, [driverId, stageId, drivers, intents]);

  // Shared library concepts + the concept/s user created
  const sharedAndCreatedConcepts = useMemo(() => intents.filter((concept) => {
    if (parent) {
      // Return library concepts that have no stage
      if (parent.name === DUMMY_STAGE.name) {
        return !concept.conceptTitle.includes('.');
      }
      // Library concepts with stages and that match the selected stage
      return concept.conceptTitle.startsWith(parent?.conceptTitle || '');
    } else {
      return false;
    }
  }), [intents, parent]);

  // When user selects an existing concept
  const selectConceptHandler = (key: keyof NewConceptData, value: string) => {
    conceptForm.clearFieldError(key);
    conceptForm.setFieldValue(key, value);

    // Set concept speaker role
    if (key === 'intentId') {
      const selectedIntent = intents.find((concept) => concept.name === value);
      if (selectedIntent) {
        conceptForm.setFieldValue('intentType', selectedIntent.intent.intentType);
      }
    }
  };

  // When user presses a key to confirm new concept
  const selectKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>, intentType: IntentIntentType) => {
    if (e.code === 'Enter') {
      createNewConcept(e.currentTarget.value, intentType);
      e.currentTarget.blur();
    }
  };

  const SelectItem = forwardRef<HTMLDivElement, any>(
    (props, ref) => {
      const { label, value, type, ...other } = props;
      const concept = props.concept as Concept;
      return (
        <div {...other} ref={ref} key={value}>
          <Group spacing="xs" position="apart">
            <Group spacing="xs">
              <RoleIcon conceptRole={type} /><Text>{label}</Text>
            </Group>
            {concept.conceptSource === ConceptConceptSource.SHARED && (
            <MultiTags
              tags={['OOB']}
              type="conceptTag"
              color="gray"
            />
            )}
            {concept.conceptTags?.length > 0 && (
            <MultiTags
              tags={concept.conceptTags.map((tag) => toTitleCase(tag))}
              type="conceptTag"
            />
            )}
          </Group>
        </div>
      );
    },
  );

  // Icon to display in intent select
  const intentRole = useMemo(() => (intentType === IntentIntentType.VISITOR_INTENT ? 'visitor' : 'agent'), [intentType]);

  const handleSelectCategory = useCallback((value: CategoryType) => {
    const { conceptTags } = conceptForm.values;
    let updatedTags = [...conceptTags];

    // Remove and add correct tags
    updatedTags = updatedTags.filter((tag) => !HIDDEN_TAGS.includes(tag));
    if (value === 'call-flow') {
      updatedTags.push(ConceptConceptTagType.CALL_FLOW);
    } else if (value === 'cross-customer') {
      updatedTags.push(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
    }
    conceptForm.setFieldValue('conceptTags', updatedTags);
  }, [conceptForm]);

  const isCallFlow = conceptForm.values.conceptTags?.includes(ConceptConceptTagType.CALL_FLOW);
  const isCrossCustomer = conceptForm.values.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
  const categoryRadioValue: CategoryType = useMemo(() => {
    if (isCallFlow) return 'call-flow';
    if (isCrossCustomer) return 'cross-customer';
    return null;
  }, [isCallFlow, isCrossCustomer]);

  return (
    <div>
      {/* DRIVERS */}
      <Select
        itemComponent={SelectItem}
        placeholder="Enter name"
        nothingFound="Nothing found"
        searchable
        creatable
        clearable
        label="Driver"
        value={driverId}
        error={conceptForm.errors.driverId}
        icon={<RoleIcon conceptRole="driver" />}
        onChange={(value) => selectConceptHandler('driverId', value)}
        onCreate={(value) => createNewConcept(value, IntentIntentType.CONVERSATION_DRIVER)}
        getCreateLabel={(query) => `+ Create ${query}`}
        onKeyDown={(e) => selectKeyHandler(e, IntentIntentType.CONVERSATION_DRIVER)}
        data={[{
          conceptTitle: 'default',
          name: null,
          intent: {
            intentType: IntentIntentType.CONVERSATION_DRIVER,
          },
        }, ...drivers].map(toSelectItem)}
        withinPortal
      />
      {/* STAGES */}
      <Select
        itemComponent={SelectItem}
        mt="lg"
        placeholder="Enter name"
        nothingFound="Nothing found"
        searchable
        creatable
        clearable
        label="Stage"
        value={stageId}
        error={conceptForm.errors.stageId}
        icon={<RoleIcon conceptRole="stage" />}
        onChange={(value) => selectConceptHandler('stageId', value)}
        onCreate={(value) => createNewConcept(value, IntentIntentType.STAGE)}
        getCreateLabel={(query) => `+ Create ${query}`}
        onKeyDown={(e) => selectKeyHandler(e, IntentIntentType.STAGE)}
        data={stagesWithDummyStage.map(toSelectItem)}
        withinPortal
      />
      {/* INTENT ROLE */}
      <Radio.Group
        label="Speaker role"
        mt="lg"
        {...conceptForm.getInputProps('intentType')}
        onChange={(value) => {
          conceptForm.setFieldValue('intentType', value as IntentIntentType);
        }}
      >
        <Group>
          <Radio value={IntentIntentType.AGENT_INTENT} label="Agent" />
          <Radio value={IntentIntentType.VISITOR_INTENT} label="Visitor" />
        </Group>
      </Radio.Group>
      {/* HUMAN READABLE NAME */}
      <TextInput mt="lg" label="Human-readable name" placeholder="Add human-readable name" {...conceptForm.getInputProps('humanReadableName')} />
      {/* INTENT TYPE (CATEGORY) */}
      <Radio.Group
        label="Select category"
        mt="lg"
        onChange={(value: CategoryType) => handleSelectCategory(value)}
        value={categoryRadioValue}
      >
        <Group>
          <Radio value="call-flow" label="Call flow" checked={isCallFlow}/>
          <Radio value="cross-customer" label="Cross customer labeling" checked={isCrossCustomer}/>
        </Group>
      </Radio.Group>
      {/* INTENTS */}
      <Select
        itemComponent={SelectItem}
        mt="lg"
        placeholder="Enter name"
        nothingFound="Nothing found"
        searchable
        creatable
        clearable
        disabled={!intentType}
        label="Intent"
        value={intentId}
        error={conceptForm.errors.intentId}
        icon={intentType && <RoleIcon conceptRole={intentRole} />}
        onChange={(value) => selectConceptHandler('intentId', value)}
        onCreate={(value) => createNewConcept(value, intentType)}
        getCreateLabel={(query) => `+ Create ${query}`}
        onKeyDown={(e) => selectKeyHandler(e, intentType)}
        data={sharedAndCreatedConcepts.map(toSelectItem)}
        withinPortal
      />
    </div>
  );
}
