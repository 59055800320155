import React from 'react';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { Anchor, Group, Text } from '@mantine/core';
import { getId } from 'common/resourceName';
import TaskStatusIndicator from 'components/TaskStatusIndicator';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { CustomerParams } from 'hooks/useCustomerParams';

const Circle = ({
  color,
  filled,
}: {
  color: string;
  filled?: boolean;
}) => (
  <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" fill={filled ? color : 'none'} stroke={color} />
  </svg>
);

const indicators = {
  complete: <Circle color="#00C48C" filled />,
  inProgress: <Circle color="#FFC107" filled />,
  uninitialized: <Circle color="#FFC107"/>,
};

export const getStatusAction = (conceptData: { conceptId: string, f1: number }, customer: CustomerParams, qaTask: DerivedLabelingTask, reviewTask: DerivedLabelingTask) => {
  const { conceptId, f1 } = conceptData;

  const qaConceptIds = qaTask?.labelingTask?.taskData?.taskDescriptor?.conceptIds || [];
  const isIncludedInQATask = qaConceptIds.includes(conceptId);

  if (!isIncludedInQATask) {
    const isF1ScoreValid = f1 >= 0.7;
    return {
      recommendedAction: isF1ScoreValid ? 'Run QA' : 'F1 score is too low; label again',
      status: (
        <Group spacing={5}>
          {indicators.uninitialized}
          <Text>{isF1ScoreValid ? 'Ready for QA' : 'Create a labeling task'}</Text>
        </Group>
      ),
    };
  }

  const qaTaskId = getId('labelingTask', qaTask?.labelingTask?.name);

  const isReviewTaskCreated = !!reviewTask;

  // handle Review Task Statuses
  if (isReviewTaskCreated) {
    const reviewTaskStatus = reviewTask?.labelingTask.abstractTask.status;
    const reviewTaskUrl = `/${customer.path}/qa/misclassification-review/${qaTaskId}`;

    switch (reviewTaskStatus) {
      case TaskStatus.TASK_COMPLETED:
        return {
          recommendedAction: 'Retrain in Wizard',
          status: <TaskStatusIndicator status={reviewTaskStatus} />,
        };
      case TaskStatus.TASK_IN_PROGRESS:
        return {
          recommendedAction: (
            <Text>
              Please Wait...
            </Text>
          ),
          status: <TaskStatusIndicator status={reviewTaskStatus} preparing />,
        };
      case TaskStatus.TASK_READY:
        return {
          recommendedAction: (
            <Anchor href={reviewTaskUrl} target="_blank">
              Start QA Task
            </Anchor>
          ),
          status: <TaskStatusIndicator status={reviewTaskStatus} />,
        };
        // case where the qa task has an error status
      case TaskStatus.TASK_ERROR || TaskStatus.TASK_ABORTED || TaskStatus.TASK_TIMEOUT:
        return {
          recommendedAction: (
            <Text>
              ---
            </Text>
          ),
          status: <TaskStatusIndicator
            status={reviewTaskStatus}
            errorMessage={qaTask?.labelingTask.abstractTask.errMessage}
          />,
        };
      case TaskStatus.TASK_CREATED:
        return {
          recommendedAction: (
            <Anchor href={reviewTaskUrl} target="_blank">
              Complete Review
            </Anchor>
          ),
          status: <TaskStatusIndicator status={reviewTaskStatus || TaskStatus.TASK_ERROR} errorMessage={reviewTask ? reviewTask?.labelingTask.abstractTask.errMessage : 'No Review Task Found'}/>,
        };
      default:
        return {
          recommendedAction: (
            <Text>
              ---
            </Text>
          ),
          status: <TaskStatusIndicator
            status={TaskStatus.TASK_ERROR}
            errorMessage={`Error, Unhandled Task Status: ${reviewTaskStatus}`}
          />,
        };
    }
  }

  // handle QA Task Statuses
  const qaTaskStatus = qaTask?.labelingTask.abstractTask.status;
  const qaTaskUrl = `/${customer.path}/qa/view/${qaTaskId}`;
  switch (qaTaskStatus) {
    case TaskStatus.TASK_COMPLETED:
      return {
        recommendedAction: '---',
        status: <TaskStatusIndicator status={TaskStatus.TASK_ERROR} errorMessage="Error in Misclassification Review Task creation" />,
      };
    case TaskStatus.TASK_IN_PROGRESS:
      return {
        recommendedAction: (
          <Anchor href={qaTaskUrl} target="_blank">
            Complete Task
          </Anchor>
        ),
        status: <TaskStatusIndicator status={qaTaskStatus} />,
      };
    case TaskStatus.TASK_READY:
      return {
        recommendedAction: (
          <Anchor href={qaTaskUrl} target="_blank">
            Start QA Task
          </Anchor>
        ),
        status: <TaskStatusIndicator status={qaTaskStatus} />,
      };
      // case where the qa task has an error status
    case TaskStatus.TASK_ERROR || TaskStatus.TASK_ABORTED || TaskStatus.TASK_TIMEOUT:
      return {
        recommendedAction: (
          <Text>
            ---
          </Text>
        ),
        status: <TaskStatusIndicator
          status={qaTaskStatus}
          errorMessage={qaTask?.labelingTask.abstractTask.errMessage}
        />,
      };
    case TaskStatus.TASK_CREATED:
      return {
        recommendedAction: (
          <Text>
            Please Wait...
          </Text>
        ),
        status: <TaskStatusIndicator status={qaTaskStatus} preparing />,
      };
    default:
      return {
        recommendedAction: (
          <Text>
            ---
          </Text>
        ),
        status: <TaskStatusIndicator
          status={TaskStatus.TASK_ERROR}
          errorMessage={`Error, Unhandled Task Status: ${qaTaskStatus}`}
        />,
      };
  }
};
