import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { Job } from '@cresta/web-client';
import { State } from './state';

/** General reducer for starting a job API. */
export function jobApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed job API. */
export function jobApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** Reducer for listing jobs successfully. */
export function listJobsSuccess(state: State, action: PayloadAction<Job[]>): State {
  return {
    ...state,
    status: 'succeeded',
    jobs: action.payload,
  };
}
