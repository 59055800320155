/* eslint-disable jsx-a11y/aria-role */
import React, { useCallback, useMemo } from 'react';
import { Stack, Box, Text, Group, useMantineTheme, createStyles } from '@mantine/core';
import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import ConceptTag from 'components/ConceptTag';
import { Moment, MomentDetailedType } from '@cresta/web-client';

function getMomentRole(detailedType: MomentDetailedType) {
  switch (detailedType) {
    case MomentDetailedType.DETAILED_TYPE_AGENT_INTENT:
      return 'agent';
    case MomentDetailedType.DETAILED_TYPE_NEXT_INTENT:
      return 'agent';
    case MomentDetailedType.DETAILED_TYPE_VISITOR_INTENT:
      return 'visitor';
    case MomentDetailedType.DETAILED_TYPE_CHAT_DRIVER:
      return 'driver';
    default:
      return null;
  }
}

export default function DryRunResults({
  modelArtifact,
}: {
  modelArtifact: ModelArtifact
}) {
  const theme = useMantineTheme();
  const useStyles = createStyles(() => ({
    runCompleteBox: {
      backgroundColor: theme.colors.blue[0],
      borderRadius: theme.radius.sm,
      width: 500,
      padding: theme.spacing.md,
    },
  }));
  const accordionStyles = useStyles();
  const { classes } = accordionStyles;

  const momentUpdate = modelArtifact?.deploymentArtifact?.outputs.momentUpdate;

  const moments = useMemo(() => {
    const nextMomentsAdded: Moment[] = [];
    const nextMomentsRemoved: Moment[] = [];
    const momentsAdded: Moment[] = [];
    const momentsRemoved: Moment[] = [];

    const insertedAndActivated = [...momentUpdate.activatedMoments, ...momentUpdate.insertedMoments];

    insertedAndActivated.forEach((moment) => {
      if (moment.detailedType === MomentDetailedType.DETAILED_TYPE_NEXT_INTENT) {
        nextMomentsAdded.push(moment);
      } else if (
        [
          MomentDetailedType.DETAILED_TYPE_AGENT_INTENT,
          MomentDetailedType.DETAILED_TYPE_CHAT_DRIVER,
          MomentDetailedType.DETAILED_TYPE_VISITOR_INTENT,
        ].includes(moment.detailedType)
      ) {
        momentsAdded.push(moment);
      }
    });

    momentUpdate.deactivatedMoments.forEach((moment) => {
      if (moment.detailedType === MomentDetailedType.DETAILED_TYPE_NEXT_INTENT) {
        nextMomentsRemoved.push(moment);
      } else if (
        [
          MomentDetailedType.DETAILED_TYPE_AGENT_INTENT,
          MomentDetailedType.DETAILED_TYPE_CHAT_DRIVER,
          MomentDetailedType.DETAILED_TYPE_VISITOR_INTENT,
        ].includes(moment.detailedType)
      ) {
        momentsRemoved.push(moment);
      }
    });

    return {
      nextMomentsAdded,
      nextMomentsRemoved,
      momentsAdded,
      momentsRemoved,
    };
  }, [momentUpdate]);

  const renderMomentConcepts = useCallback((moments: Moment[]) => {
    if (moments?.length === 0) {
      return <Text color={theme.colors.gray[3]}>None</Text>;
    }

    return (
      <Group>
        {
          moments.map((moment) => (
            <ConceptTag value={moment.taxonomy} role={getMomentRole(moment.detailedType)} />
          ))
        }
      </Group>
    );
  }, []);

  return (
    <Stack mt="lg">
      <Box className={classes.runCompleteBox}>
        <Text>Next intent moments to be added</Text>
        {renderMomentConcepts(moments.nextMomentsAdded)}
      </Box>
      <Box className={classes.runCompleteBox}>
        <Text>Next intent moments removed</Text>
        {renderMomentConcepts(moments.nextMomentsRemoved)}
      </Box>
      <Box className={classes.runCompleteBox}>
        <Text>Intent moments added</Text>
        {renderMomentConcepts(moments.momentsAdded)}
      </Box>
      <Box className={classes.runCompleteBox}>
        <Text>Intent moments removed</Text>
        {renderMomentConcepts(moments.momentsRemoved)}
      </Box>
    </Stack>
  );
}
