import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as ModelArtifactState } from './state';

const selectModelArtifact = (state: State) => state.modelArtifact;

/** Select model artifact. */
export const selectEvaluationArtifact = createSelector(selectModelArtifact, (modelArtifactState: ModelArtifactState) => modelArtifactState.evaluationArtifact);

/** Select model artifacts. */
export const selectModelArtifacts = createSelector(selectModelArtifact, (modelArtifactState: ModelArtifactState) => modelArtifactState.modelArtifacts);

/** Select model artifact to compare. */
export const selectEvaluationArtifactToCompare = createSelector(selectModelArtifact, (modelArtifactState: ModelArtifactState) => modelArtifactState.comparedEvaluationArtifact);

/** Select model artifacts status */
export const selectGetModelArtifactsStatus = createSelector(selectModelArtifact, (modelArtifactState: ModelArtifactState) => modelArtifactState.status);

/** Select artifact by name */
export const selectModelArtifactByName = (name: string) => createSelector(
  selectModelArtifact,
  (modelArtifactState: ModelArtifactState) => modelArtifactState.modelArtifacts.find((artifact) => artifact.name === name),
);
