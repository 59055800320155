import React from 'react';
import { Skeleton, Stack } from '@mantine/core';

export const Skeletons = () => (
  <>
    <Stack spacing="md" align="stretch" my="xs">
      <Skeleton height={18} width={200} />
      <Skeleton height={10} width={400} />
      <Skeleton height={10} width={400} />
    </Stack>
    <Stack align="flex-end" pt="xs" spacing="xs" justify="space-between">
      <Skeleton height={8} width={100} />
    </Stack>
  </>
);
