import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import {
  CaretDownOutlined,
  ClockCircleOutlined,
  MessageOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useHotkeys } from 'hooks/useHotkeys';
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  notification,
  Radio,
  Select,
  Tabs,
} from 'antd';
import './Filters.scss';
import { useDebounce } from 'hooks/useDebounce';
import { useWindowEventListener } from 'hooks/eventListeners';
import { RegexSearchMessagesRequestActorTypeFilter } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { getId } from 'common/resourceName';
import { SetManagementDropdown } from 'components/SetManagementDropdown/SetManagementDropdown';
import { deleteSet, listSets } from 'store/set/asyncThunks';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectApiStatus, selectConversationSets } from 'store/set/selectors';
import { AuthProtoRole, ListUsersRequest, ListUsersRequestListUsersFilter, ListUsersResponse } from '@cresta/web-client';
import { ProdUsersApi } from 'services/prodUsersApi';
import { ConversationDurationSlider } from 'components/ConversationDurationSlider';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import Loader from '../Loader';
import { SetTags } from '../ChatWindow/SetManagerSection/SetTags';

// without extensions, antd date picker breaks: https://github.com/react-component/picker/issues/123
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { RangePicker } = DatePicker;

interface FilterProps {
  searchText: string;
  speaker: string
  filterAgents: string[]
  filterDateStart: string
  filterDateEnd: string
  filterDurationMin: string
  filterChatID: string
  filterConvoID: string
  filterSetIds: string[]
  handleAgentsSelect: (agents:string[]) => void
  handleSetSelect: (sets:string[]) => void
  onChatIDFilterChange: (e: React.FormEvent<HTMLInputElement>) => void
  onConvoIDFilterChange: (e: React.FormEvent<HTMLInputElement>) => void
  onRangeFilterChange: (_: any, dates: string[]) => void
  onDurationFilterChange: (duration: number) => void
  handleUtteranceSearch: (arg0:string, arg1:string) => void
  handleUtteranceSearchReset: () => void
  handleFilterReset: () => void
}

type TabEnum = 'stringSearch' | 'regexSearch'

function alphabeticalByLabel(a: { label: string; }, b: { label: string; }) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export default function Filters(props: FilterProps) {
  const {
    searchText,
    speaker,
    filterAgents,
    filterDateStart,
    filterDateEnd,
    filterDurationMin,
    filterChatID,
    filterConvoID,
    filterSetIds,
    handleAgentsSelect,
    handleSetSelect,
    onChatIDFilterChange,
    onConvoIDFilterChange,
    onRangeFilterChange,
    onDurationFilterChange,
    handleUtteranceSearch,
    handleUtteranceSearchReset,
    handleFilterReset,
  } = props;
  const dispatch = useDispatch();
  const [utteranceMenuVisible, toggleUtteranceMenu] = useState(false);
  const [isDurationMenuActive, toggleDurationMenu] = useState(false);
  const [stringSearch, setStringSearch] = useState(searchText);
  const [regexSearch, setRegexSearch] = useState(searchText);
  const [speakerFilter, setSpeakerFilter] = useState(speaker);
  const [agents, setAgents] = useState([]);
  const [sets, setSets] = useState([]);
  const [cardData, setCardData] = useState<{
    value: string;
    label: string;
    subLabel: string;
  }[]>([]);
  const [agentSearch, setAgentSearch] = useState('');
  const [activeTab, setActiveTab] = useState<TabEnum>('stringSearch');
  const [isLoading, toggleLoading] = useState(false);
  const [search, setSearch] = useState('');
  const apiStatus = useSelector(selectApiStatus);
  const reduxSets = useSelector(selectConversationSets);
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const usecase = useCustomerUsecase();

  useHotkeys('/', () => toggleUtteranceMenu(!utteranceMenuVisible));
  useHotkeys('escape', () => handleUtteranceSearchReset());
  useHotkeys('a', () => {
    if (!utteranceMenuVisible) return;
    setSpeakerFilter('AGENT');
  });
  useHotkeys('v', () => {
    if (!utteranceMenuVisible) return;
    setSpeakerFilter('VISITOR');
  });

  const handleUtteranceSearchCancel = () => {
    // We will likely want to have 2 separate query params for search, but this should work for now
    setStringSearch(searchText);
    setRegexSearch(searchText);
    setSpeakerFilter(speaker);
    toggleUtteranceMenu(false);
  };

  useHotkeys('esc', () => {
    if (!utteranceMenuVisible) return;
    handleUtteranceSearchCancel();
  });

  const debouncedSearchTerm: string = useDebounce<string>(agentSearch, 300);
  // Popuate Agent dropdown from Actor Service
  useEffect(() => {
    toggleLoading(true);
    const filter: ListUsersRequestListUsersFilter = {
      nameContains: debouncedSearchTerm,
      roles: [AuthProtoRole.AGENT],
    };
    const params: ListUsersRequest = {
      parent: `customers/${customer.customerId}`,
      pageSize: 1000,
      filter,
    };

    ProdUsersApi.listUsers(params).then((response: ListUsersResponse) => {
      const agentNames = response.users.map((user) => {
        const value = getId('prodUser', user.name);
        const label = user?.displayName || user?.email || 'No Agent data found';
        return { value, label };
      }).sort(alphabeticalByLabel);
      setAgents(agentNames);
    }).catch((e) => notification.error({ message: 'Error retrieving Actors', description: e.message }))
      .finally(() => toggleLoading(false));
  }, [debouncedSearchTerm]);

  // Populate Set dropdown from Set Service
  useEffect(() => {
    // if (apiStatus === 'loading') return;
    dispatch(listSets({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
      languageCode: customer?.languageCode,
    }));
  }, []);

  useEffect(() => {
    const activeSets = reduxSets.filter((set) => set.state !== 'DEPRECATED');
    const fetchedCardData = activeSets.map((set) => ({ value: getId('set', set.name), label: set.setTitle, subLabel: `${set.itemCount === undefined ? 0 : set.itemCount} conversations` }));
    setSets(fetchedCardData);
    setCardData(fetchedCardData);
  }, [reduxSets]);

  const menuRef = useRef<HTMLDivElement>();
  const utteranceMenuBtnRef = useRef<HTMLButtonElement>();
  const durationMenuBtnRef = useRef<HTMLButtonElement>();
  const durationMenuRef = useRef<HTMLDivElement>();

  // creates an onclickoutside listener
  useWindowEventListener('click', (event: { target: Node; }) => {
    // only fire if current ref des not contain menuRef or utteranceMenuBtnRef
    if (!menuRef.current?.contains(event.target as Node) && !utteranceMenuBtnRef.current?.contains(event.target as Node)) {
      handleUtteranceSearchCancel();
    }
  });

  // creates an onclickoutside listener for duration menu
  useWindowEventListener('click', (event: { target: Node; }) => {
    // only fire if current ref des not contain durationMenuRef or durationMenuBtnRef
    if (!durationMenuRef.current?.contains(event.target as Node) && !durationMenuBtnRef.current?.contains(event.target as Node)) {
      toggleDurationMenu(false);
    }
  });

  const handleTextAreaKeyPresses = (e) => {
    if (e.shiftKey) return;
    const ENTER_KEY_CODE = 13;
    if (e.which === ENTER_KEY_CODE) {
      e.preventDefault();
      handleNewUtteranceSearch();
    }
  };

  const handleNewUtteranceSearch = () => {
    const searches = {
      stringSearch: escapeRegExp(stringSearch),
      regexSearch,
    };
    const query = searches[activeTab];
    handleUtteranceSearch(query, speakerFilter);
    toggleUtteranceMenu(false);
  };

  const handleUtteranceReset = () => {
    handleUtteranceSearchReset();
    setStringSearch('');
    setRegexSearch('');
    setSpeakerFilter(null);
    toggleUtteranceMenu(false);
  };

  const onStringSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStringSearch(e.currentTarget.value);
  };

  const onRegexSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRegexSearch(e.currentTarget.value);
  };

  const isUtteranceSearchActive = !!searchText || !!speaker;

  function escapeRegExp(text:string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const searches = {
    stringSearch,
    regexSearch,
  };
  const hasFormBeenEdited = searches[activeTab].length > 0;

  const filteredList = cardData
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));

  const durationMenu = (
    <div ref={durationMenuRef} className="ant-dropdown-menu" style={{ padding: '12px', background: 'white' }}>
      <ConversationDurationSlider
        min={parseInt(filterDurationMin, 10)}
        handleMinDurationChange={(value: number) => {
          onDurationFilterChange(value);
          toggleDurationMenu(false);
        }}
      />
    </div>
  );

  const items = [
    {
      key: 'stringSearch',
      label: 'Keywords',
      children: (
        <Input.TextArea
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)}
          onKeyPress={handleTextAreaKeyPresses}
          autoFocus
          style={{ resize: 'none', marginTop: 5 }}
          value={stringSearch}
          onChange={onStringSearchChange}
        />
      ),
    },
    {
      key: 'regexSearch',
      label: 'Regex',
      children: (
        <Input.TextArea
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)}
          onKeyPress={handleTextAreaKeyPresses}
          style={{ resize: 'none', marginTop: 5 }}
          value={regexSearch}
          onChange={onRegexSearchChange}
        />
      ),
    },
  ];

  const menu = (
    // Using an Antd Menu Component will disable arrow key functionality in TextAreas, using div instead
    <div ref={menuRef} className="ant-dropdown-menu" style={{ padding: '12px', background: 'white' }}>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        activeKey={activeTab}
        onTabClick={(activeKey:string) => {
          const key = activeKey as TabEnum;
          setActiveTab(key);
        }}
        items={items}
      />
      <Divider />
      <label>Search by speaker type:</label>
      <div style={{ marginBottom: '16px' }}>
        <Radio.Group
          value={speakerFilter}
          onChange={(e) => setSpeakerFilter(e.target.value)}
        >
          <Radio value={RegexSearchMessagesRequestActorTypeFilter.AGENT_MESSAGE}>Agent</Radio>
          <Radio value={RegexSearchMessagesRequestActorTypeFilter.VISITOR_MESSAGE}>Visitor</Radio>
          <Radio value={RegexSearchMessagesRequestActorTypeFilter.BOT_MESSAGE}>Bot</Radio>
        </Radio.Group>
      </div>
      <Button type="primary" onClick={handleNewUtteranceSearch} disabled={!hasFormBeenEdited}>
        Run
      </Button>
      <Button type="link" onClick={handleUtteranceSearchCancel}>
        Cancel
      </Button>
      <Button type="text" danger onClick={handleUtteranceReset} disabled={!hasFormBeenEdited}>
        Clear
      </Button>
    </div>
  );

  return (
    <Input.Group className="conversation-filters">
      <Select
        style={{ minWidth: '285px' }}
        mode="multiple"
        showSearch
        searchValue={agentSearch}
        onSearch={(val) => {
          toggleLoading(true);
          setAgentSearch(val);
        }}
        maxTagCount={0}
        loading={isLoading}
        showArrow
        className="agent-search"
        size="large"
        bordered={false}
        placeholder="All agents"
        allowClear
        value={filterAgents}
        filterOption={false}
        // Ensures dropdown appears below sidebar when expanded
        // (Sidebar zindex=10)
        dropdownStyle={{ zIndex: 5 }}
        onChange={(value:string[]) => handleAgentsSelect(value)}
      >
        {isLoading ? <Select.Option key="loading" value={null}><Loader /></Select.Option> : agents.map((agent) => (
          <Select.Option key={agent.value} value={agent.value}>
            {agent.label}
          </Select.Option>
        ))}
      </Select>
      <Input
        style={{ minWidth: '170px' }}
        bordered={false}
        allowClear
        placeholder="Engagement ID"
        prefix={<SearchOutlined />}
        value={filterChatID}
        onChange={onChatIDFilterChange}
      />
      <Input
        style={{ minWidth: '170px' }}
        bordered={false}
        allowClear
        placeholder="Conversation ID"
        prefix={<SearchOutlined />}
        value={filterConvoID}
        onChange={onConvoIDFilterChange}
      />
      <SetManagementDropdown
        isLoading={apiStatus === 'loading'}
        search={search}
        setSearch={setSearch}
        selectedValues={filterSetIds}
        onSelect={(value:string) => {
          if (filterSetIds.includes(value)) {
            // if already selected, remove from filter
            handleSetSelect(filterSetIds.filter((set) => set !== value));
          } else {
            handleSetSelect([...filterSetIds, value]);
          }
        }}
        onDelete={(value:string) => {
          const setName = value.includes('/') ? value : `${customerProfile}/sets/${value}`;
          dispatch(deleteSet({
            name: setName,
            usecase,
            languageCode: customer.languageCode,
          }));
          handleSetSelect(filterSetIds.filter((set) => set !== value));
          const updatedSetCardData = cardData.filter((set) => set.value !== value);
          setCardData(updatedSetCardData);
        }}
        cardData={filteredList}
        popoverProps={{
          position: 'bottom-start',
        }}
        buttonProps={{
          style: {
            marginRight: '8px',
            background: filterSetIds.length > 0 && 'white',
          },
          variant: filterSetIds.length > 0 ? 'outline' : 'white',
        }}
      />
      <SetTags
        conversationName=""
        badgeProps={{
          style: { backgroundColor: 'white', color: 'gray' },
          leftSection: null,
          radius: 'sm',
          size: 'lg',
        }}
        containerProps={{
          noWrap: true,
        }}
        membershipSets={sets.filter((set) => {
          const id = getId('set', set.name);
          return set.name === filterSetIds.includes(id);
        })}
        onChangeMembershipSets={() => {}}
      />
      <RangePicker
        style={{ minWidth: '250px' }}
        bordered={false}
        ranges={{
          Today: [dayjs(), dayjs()],
          Yesterday: [
            dayjs().subtract(1, 'days'),
            dayjs().subtract(1, 'days'),
          ],
          'This week': [dayjs().startOf('week'), dayjs().endOf('week')],
          'Last 7 days': [dayjs().subtract(6, 'days'), dayjs()],
          'Last 30 days': [dayjs().subtract(29, 'days'), dayjs()],
          'Last 90 days': [dayjs().subtract(89, 'days'), dayjs()],
        }}
        suffixIcon={<CaretDownOutlined />}
        value={[
          filterDateStart ? dayjs(filterDateStart) : null,
          filterDateEnd ? dayjs(filterDateEnd) : null,
        ]}
        onChange={onRangeFilterChange}
      />
      <Dropdown open={isDurationMenuActive} overlay={durationMenu} destroyPopupOnHide>
        <Button
          ref={durationMenuBtnRef}
          type={isDurationMenuActive ? 'link' : 'text'}
          icon={<ClockCircleOutlined />}
          onClick={() => toggleDurationMenu(true)}
        >
          Duration
        </Button>
      </Dropdown>
      <Dropdown open={utteranceMenuVisible} overlay={menu} destroyPopupOnHide>
        <Button
          ref={utteranceMenuBtnRef}
          type={isUtteranceSearchActive ? 'link' : 'text'}
          icon={<MessageOutlined />}
          onClick={() => toggleUtteranceMenu(true)}
        >
          Utterance Search
        </Button>
      </Dropdown>
      <Button type="text" danger onClick={() => handleFilterReset()}>
        Clear
      </Button>
    </Input.Group>
  );
}
