import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import MultiTags from 'components/MultiTags';
import { useSelector } from 'hooks/reduxHooks';
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import CopyableValue from 'components/CopyableValue';
import { clipText } from 'common/clipText';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Button, Divider, Group, Stack, Textarea, useMantineTheme } from '@mantine/core';
import { countWords } from 'components/LabelingDecision/SummarizationDecision/utils';
import { cloneDeep } from 'lodash';
import { AnnotationApi } from 'services/annotationApi';
import { UserContext } from 'context/UserContext';
import { openNotification } from 'components/Notification';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import styles from '../styles.module.scss';
import { BaseSummaryTable } from '..';

interface SummarySummarizationTableProps extends BaseSummaryTable {}

interface ResultsRow {
  name: string;
  intent: string;
  conversationPositionNumber: number;
  conversationId: string;
  topics: {
    [conceptId: string]: Annotation;
  }
}

export default function SummarySummarizationTable({
  searchTerm,
  filtersNode,
  allConceptsMap,
  fetchSummary,
}: SummarySummarizationTableProps) {
  const theme = useMantineTheme();
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const currentUser = useContext(UserContext);
  const [editingRow, setEditingRow] = useState<ResultsRow>();
  const customerProfile = useCustomerProfile();

  const summaryTopics = useMemo(() => {
    const topicsSet = new Set<string>();
    annotationBundles.forEach((bundle) => {
      topicsSet.add(bundle.annotation.value.textValue?.conceptId);
    });
    return topicsSet;
  }, [annotationBundles]);
  const [savingAnnotation, setSavingAnnotation] = useState<ApiStatus>('idle');

  const summaryResults = useMemo(() => {
    const resultsMap = new Map<number, ResultsRow>();
    annotationBundles.forEach((bundle) => {
      const intentId = bundle.annotation.value.textValue?.conceptId;
      const { conversationPositionNumber } = bundle.annotation.rawData;
      let resultRow = resultsMap.get(conversationPositionNumber);
      if (!resultRow) {
        resultRow = {
          name: allConceptsMap.get(intentId)?.name || '',
          intent: allConceptsMap.get(intentId)?.conceptTitle || '',
          conversationPositionNumber,
          conversationId: bundle.annotation.rawData.conversationRawData?.v2ConversationId,
          topics: {
            [intentId]: bundle.annotation,
          },
        };
        resultsMap.set(conversationPositionNumber, resultRow);
      } else {
        resultRow.topics = {
          ...resultRow.topics,
          [intentId]: bundle.annotation,
        };
      }
    });

    const fullSummary = Array.from(resultsMap.values());
    const filteredSummary = fullSummary.filter((row) => {
      const annotations = Object.values(row.topics);
      return annotations.some((annotation) => annotation.value.textValue.values.some((value) => value.includes(searchTerm.toLowerCase())));
    });
    return filteredSummary;
  }, [annotationBundles, searchTerm]);

  const saveAnnotations = useCallback(async () => {
    setSavingAnnotation('loading');
    const annotations = Object.values(editingRow.topics);
    const promises = annotations.map((annotation) => AnnotationApi.modifyAnnotation({
      name: annotation.name,
      newValue: annotation.value,
      modifierUserId: currentUser?.id,
    }));
    try {
      await Promise.all(promises);
      // Refetch from parent
      fetchSummary();
      setSavingAnnotation('succeeded');
      setEditingRow(null);
    } catch (err) {
      setSavingAnnotation('failed');
      openNotification('error', 'Annotations update failed', null, err);
    }
  }, [editingRow, currentUser, customerProfile, setSavingAnnotation]);

  const columns: ColumnsType<ResultsRow> = useMemo(() => {
    const dynamicColumns: ColumnsType<ResultsRow> = [
      {
        title: 'Chat ID',
        dataIndex: 'chatId',
        key: 'chatId',
        render: (value, row) => <CopyableValue displayValue={clipText(row.conversationId, 6)} copiedValue={row.conversationId} tooltip={row.conversationId} />,
      },
    ];

    const summaryTopicsList = Array.from(summaryTopics);
    summaryTopicsList.sort((a, b) => String(allConceptsMap.get(a)?.conceptTitle).localeCompare(allConceptsMap.get(b)?.conceptTitle));
    summaryTopicsList.forEach((topicId) => {
      dynamicColumns.push({
        title: () => <MultiTags tags={[allConceptsMap.get(topicId)?.conceptTitle]} />,
        key: allConceptsMap.get(topicId)?.conceptTitle,
        render: (value, row) => (
          <Stack>
            {row.topics[topicId]?.value.textValue?.values.map((value, index) => {
              // Update textarea value of editing row
              const onChangeHandler = (e) => {
                const editingRowCopy = cloneDeep(editingRow);
                editingRowCopy.topics[topicId].value.textValue.values[index] = e.target.value;
                setEditingRow(editingRowCopy);
              };
              return (
                <>
                  {index !== 0 && (
                  <Divider color={theme.colors.gray[0]} />
                  )}
                  {editingRow?.conversationPositionNumber === row.conversationPositionNumber ? (
                    <Textarea
                      value={editingRow?.topics[topicId]?.value.textValue?.values[index]}
                      onChange={onChangeHandler}
                    />
                  ) : (
                    <Group>{value}</Group>
                  )}
                </>
              );
            })}
          </Stack>
        ),
      });
    });

    dynamicColumns.push({
      title: 'Word count',
      key: 'actions',
      render: (value, row) => {
        const annotations = Object.values(row.topics);
        let allValues = [];
        annotations.forEach((annotation) => {
          allValues = [...allValues, ...annotation.value.textValue?.values || []];
        });
        return countWords(allValues.join(' '));
      },
    });

    dynamicColumns.push({
      title: '',
      key: 'actions',
      render: (value, row) => {
        if (editingRow?.conversationPositionNumber === row.conversationPositionNumber) {
          return (
            <Group>
              <Button variant="subtle" onClick={() => setEditingRow(null)}>Cancel</Button>
              <Button variant="subtle" onClick={saveAnnotations} loading={savingAnnotation === 'loading'}>Save</Button>
            </Group>
          );
        } else {
          return <Button variant="subtle" onClick={() => setEditingRow(row)}>Edit</Button>;
        }
      },
    });

    return dynamicColumns;
  }, [summaryTopics, editingRow, savingAnnotation]);

  return (
    <div style={{
      width: '100%',
      flex: 1,
    }}
    >
      <Group mb="lg" position="right">
        <>
          {filtersNode && filtersNode}
        </>
      </Group>
      <Table
        loading={{
          spinning: taskApiStatus === 'loading',
          indicator: <Loading />,
        }}
        columns={columns}
        rowKey={(row) => row.conversationPositionNumber}
        dataSource={summaryResults}
        className={styles.summaryTable}
      />
    </div>
  );
}
