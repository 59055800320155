import {
  GetModelArtifactRequest,
  GetModelArtifactResponse,
  ListModelArtifactsRequest,
  ListModelArtifactsResponse,
  ModelArtifactService,
  RetrieveModelScoresRequest,
  RetrieveModelScoresResponse,
  BatchArchiveModelArtifactsRequest,
  BatchArchiveModelArtifactsResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { StudioApi } from './studioApi';

export abstract class modelArtifactApi {
  public static getModelArtifact(request: GetModelArtifactRequest): Promise<GetModelArtifactResponse> {
    return ModelArtifactService.GetModelArtifact(request, StudioApi.getHeaders().initReq);
  }

  public static listModelArtifacts(request: ListModelArtifactsRequest): Promise<ListModelArtifactsResponse> {
    return ModelArtifactService.ListModelArtifacts(request, StudioApi.getHeaders().initReq);
  }

  public static retrieveModelScores(request: RetrieveModelScoresRequest): Promise<RetrieveModelScoresResponse> {
    return ModelArtifactService.RetrieveModelScores(request, StudioApi.getHeaders().initReq);
  }

  public static batchArchiveArtifacts(request: BatchArchiveModelArtifactsRequest): Promise<BatchArchiveModelArtifactsResponse> {
    return ModelArtifactService.BatchArchiveModelArtifacts(request, StudioApi.getHeaders().initReq);
  }
}
