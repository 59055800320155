import React from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { Message, SpeakerRole } from 'types';
import { TIME_FORMAT_SECONDS } from 'studioConstants';
import styles from './styles.module.scss';

interface State { }

interface Props {
  message: Message;
  showDate?: boolean;
  showSpeaker?: boolean;
  /* eslint-disable-next-line react/no-unused-prop-types */
  scrollId?: string; // this is used in MessageList
  handleClick?: (message) => void;
  isFocused?: boolean;
  isSelected?: boolean; // whether the message is selected by filter;
  isBlur?: boolean;
  containerClass?: string;
  adornment?: (message: Message) => React.ReactNode | string;
  dateAdornment?: (pubDate: Date) => React.ReactNode | string;
}

class MessageBubble extends React.PureComponent<Props, State> {
  public static defaultProps = {
    showDate: true,
    showSpeaker: false,
  };

  render() {
    const {
      message,
      isFocused,
      isSelected,
      isBlur,
      handleClick,
      showDate,
      showSpeaker,
      containerClass,
      adornment,
      dateAdornment,
    } = this.props;
    const { basedOnSuggestion, text, speakerRole, pubDate } = message;

    let messageClass = styles.system;
    const rolesToRightAlign = [SpeakerRole.AGENT, SpeakerRole.BOT];
    const isRightAligned = rolesToRightAlign.some((role) => role === speakerRole);
    if (isRightAligned) {
      if (basedOnSuggestion) {
        messageClass = styles.basedOnSuggestion;
      } else {
        messageClass = styles.agent;
      }
    } else if (speakerRole === SpeakerRole.VISITOR) {
      messageClass = styles.visitor;
    } else if (speakerRole === SpeakerRole.SYSTEM) {
      messageClass = styles.system;
    }

    return (
      <div className={classNames(styles.messageContainer, containerClass, { [styles.focused]: isFocused })}>
        <div
          className={classNames(styles.message, messageClass, {

            [styles.selected]: isSelected,
            [styles.blur]: isFocused && isBlur,
          })}
          onClick={() => handleClick && handleClick(message)}
        >
          {ReactHtmlParser(text)}
        </div>
        {adornment && (
          <div
            className={classNames({
              [styles.leftAdornment]: isRightAligned,
              [styles.rightAdornment]: !isRightAligned,
            })}
          >
            {adornment(message)}
          </div>
        )}
        {showDate && (
          <span
            className={classNames(styles.messageMeta, {
              [styles.agentMessageMeta]: isRightAligned,
            })}
          >
            {basedOnSuggestion && (
              <span className={styles.aiBadge}>Based on Cresta AI</span>
            )}
            {dateAdornment ? dateAdornment(pubDate) : moment(pubDate).format(TIME_FORMAT_SECONDS)}
          </span>
        )}
        {showSpeaker && (
          <span
            className={classNames(styles.messageMeta, {
              [styles.agentMessageMeta]: isRightAligned,
            })}
          >
            {speakerRole}
          </span>
        )}
      </div>
    );
  }
}

export default MessageBubble;
