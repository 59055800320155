import React, { useEffect, useMemo, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { DerivedLabelingTask, TargetType, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import UserTag from 'components/UserTag';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { Link, useParams } from 'react-router-dom';
import { fetchLabelingTaskAnnotations } from 'store/labelingTask/asyncThunks';
import { selectDerivedLabelingTasksMap } from 'store/labelingTask/selectors';
import { selectUsers } from 'store/user/selectors';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { User } from 'types';
import CopyableValue from 'components/CopyableValue';
import moment from 'moment';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConceptsMapFactory } from 'store/concept/selectors';
import Loading from 'components/Loading';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/moment/moment_annotation.pb';
import { ActionAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/action/action_annotation.pb';
import { SearchAnnotationsRequestSearchFilter } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Table } from 'antd';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { getMomentAnnotationType, getTaskConceptType } from 'pages/LabelingView/utils';
import { clearLabelingTask } from 'store/labelingTask/labelingTaskSlice';
import { Badge, Center, Divider, Group, useMantineTheme } from '@mantine/core';
import { AnnotationState } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import styles from './styles.module.scss';
import IntentPredictionTable from './SummaryTables/SummaryIntentPredictionTable';
import IntentRecallTable from './SummaryTables/SummaryIntentRecallTable';
import PolicyTable from './SummaryTables/SummaryPolicyTable';
import SummarizationTable from './SummaryTables/SummarySummarizationTable';
import SummarizationQaTable from './SummaryTables/SummarySummarizationQaTable';
import SummaryFilters from './SummaryFilters';
import SuggestionsQaTable from './SummaryTables/SummarySuggestionsQaTable';

export interface BaseSummaryTable {
  userLabelingTaskType?: UserLabelingTaskType;
  searchTerm: string;
  taskId: string;
  allConceptsMap: Map<string, Concept>;
  fetchSummary?: () => void;
  filtersNode?: React.ReactNode;
}

const TASK_TYPES_NAMES_MAP = new Map<UserLabelingTaskType, string>([
  [UserLabelingTaskType.QA_INTENT_PREDICTION, 'Intent Prediction'],
  [UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL, 'Intents - All'],
  [UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION, 'Intents - Misclassification'],
  [UserLabelingTaskType.QA_POLICY, 'Policy'],
  [UserLabelingTaskType.QA_SUGGESTIONS, 'Suggestions'],
  [UserLabelingTaskType.QA_SUMMARIZATION, 'Summarization'],
  [UserLabelingTaskType.LABELING_SUMMARIZATION, 'Summarization'],
]);

// Decides which table to show based on labeling task type
const SummaryTable = (props: BaseSummaryTable) => {
  const { userLabelingTaskType, ...other } = props;
  switch (userLabelingTaskType) {
    case UserLabelingTaskType.QA_INTENT_PREDICTION:
      return <IntentPredictionTable {...other}/>;
    case UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL:
    case UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION:
      return <IntentRecallTable {...other}/>;
    case UserLabelingTaskType.QA_POLICY:
      return <PolicyTable {...other}/>;
    case UserLabelingTaskType.QA_SUGGESTIONS:
      return <SuggestionsQaTable {...other}/>;
    case UserLabelingTaskType.QA_SUMMARIZATION:
      return <SummarizationQaTable {...other}/>;
    case UserLabelingTaskType.LABELING_SUMMARIZATION:
      return <SummarizationTable {...other}/>;
    default:
      return <Table className={styles.summaryTable} columns={[]} dataSource={[]}/>;
  }
};

export default function TaskSummaryView() {
  const dispatch = useDispatch();
  const { taskId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const theme = useMantineTheme();
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);
  // Completed, non-dual tasks.
  const tasksMap = useSelector<Map<string, DerivedLabelingTask>>(selectDerivedLabelingTasksMap);

  const task = tasksMap.get(taskId);
  const targetType = task?.labelingTask.taskData.taskDescriptor.targetType;
  const conceptType = task?.labelingTask.taskData.taskDescriptor.conceptType;

  const fetchSummary = async () => {
    const searchFilter: SearchAnnotationsRequestSearchFilter = {
      taskIds: taskId ? [taskId] : [],
      conceptType: getTaskConceptType(conceptType),
      states: [
        AnnotationState.ACTIVE_REVIEWED,
        AnnotationState.ACTIVE_SKIP_REVIEW,
        AnnotationState.ACTIVE_MANUALLY_ADDED,
        // Make summary available even whil task is still in progress
        AnnotationState.DRAFT_TASK_IN_PROGRESS,
      ],
      detailedTypeFilter: {
        targetType,
        momentAnnotationTypes: [getMomentAnnotationType(conceptType)],
        actionAnnotationTypes: [],
        actionAnnotationDetailedTypes: [],
        momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
        actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
      },
    };

    // Change adherence type filter for policy QA annotations
    if (task.userLabelingTaskType === UserLabelingTaskType.QA_POLICY) {
      searchFilter.detailedTypeFilter = {
        ...searchFilter.detailedTypeFilter,
        momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_SHOULD_DO_X],
      };
    }

    // Load all annotations in loop
    dispatch(fetchLabelingTaskAnnotations({
      parent: customerProfile,
      searchFilter,
      pageSize: 1000,
    }));
  };

  const currentTaskMetadata = useMemo(() => {
    if (!task) {
      return {
        userLabelingTaskType: UserLabelingTaskType.USER_LABELING_TASK_TYPE_UNSPECIFIED,
        assignee: '',
        dateCompleted: 'Missing',
      };
    }

    return {
      userLabelingTaskType: task.userLabelingTaskType,
      assignee: task.labelingTask.abstractTask.assigneeUserId,
      dateCompleted: task.labelingTask.abstractTask.endTime ? moment(task.labelingTask.abstractTask.endTime).format('MM/DD/YYYY h:mm a') : 'Missing',
    };
  }, [customerProfile, task, taskId]);

  useEffect(
    () =>
    // Reset annotations to empty array when component unmounts
      () => {
        dispatch(clearLabelingTask());
      }
    , [],
  );

  useEffect(() => {
    if (task) {
      fetchSummary();
    }
  }, [task?.labelingTask?.name]);

  const isSuggestionsV2 = task?.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.isSuggestionQaV2;

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <Link to={targetType === TargetType.QA ? `/${customer.path}/qa` : `/${customer.path}/labeling`} className={styles.backBtn}>
        <ArrowLeftOutlined /> Back to {targetType === TargetType.QA ? 'QA' : 'Labeling'} Tasks
      </Link>
      <div className={classNames([styles.filters, styles.header])}>
        <Group>
          <h1>{TASK_TYPES_NAMES_MAP.get(currentTaskMetadata?.userLabelingTaskType) || '[Unsupported Task Type]'} {targetType === TargetType.QA ? 'QA' : 'Labeling'} Task Summary</h1>
          {isSuggestionsV2 && <Badge color="green">V2</Badge>}
        </Group>
      </div>
      <Group align="center" mt="md">
        <Group spacing="xs">
          <span className={styles.label}>Task ID:</span>
          <CopyableValue
            displayValue={`${taskId.substring(0, 6)}...`}
            copiedValue={taskId}
            tooltip={taskId}
          />
        </Group>
        <Divider color={theme.colors.gray[1]} orientation="vertical" />
        <Group spacing="xs">
          <span className={styles.label}>Assignee:</span>
          <UserTag name={users.find((user) => user.id === currentTaskMetadata.assignee)?.full_name || 'Unassigned'} />
        </Group>
        <Divider color={theme.colors.gray[1]} orientation="vertical" />
        <Group spacing="xs">
          <span className={styles.label}>Date completed:</span>
          <span>{currentTaskMetadata.dateCompleted}</span>
        </Group>
      </Group>
      <div className={styles.content}>
        {task ? (
          <SummaryTable
            userLabelingTaskType={currentTaskMetadata?.userLabelingTaskType}
            searchTerm={searchTerm}
            taskId={taskId}
            allConceptsMap={allConceptsMap}
            fetchSummary={fetchSummary}
            filtersNode={(
              <SummaryFilters
                taskType={task?.userLabelingTaskType}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            )}
          />
        ) : (
          <Center p="lg" sx={{ height: '800px', width: '100%' }}>
            <Loading/>
          </Center>
        )}
      </div>
    </div>
  );
}
