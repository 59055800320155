import React, { useCallback, useContext } from 'react';
import { Modal, Button, Group, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { ModelProjectStatus, UpdateModelProjectRequest } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { useDispatch } from 'hooks/reduxHooks';
import { UserContext } from 'context/UserContext';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { updateModelProject } from 'store/modelProject/asyncThunks';
import { SendNotificationRequest } from '@cresta/web-client/dist/cresta/v1/studio/notification/notification_service.pb';
import { STUDIO_NOTIFICATION_CHANNEL_ID, wizardModelReadyMessage } from 'common/notification';
import { sendNotification } from 'store/notification/asyncThunks';
import { ConfirmModalTable } from './ConfirmModalTable';

interface ConfirmModalProps {
  opened: boolean;
  onCancel: () => void;
  evaluateData: ModelWorkflow;
}

export function ConfirmModal({
  opened,
  onCancel,
  evaluateData,
}: ConfirmModalProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer = useCustomerParams();
  const { currentConfig } = useContext(CustomerConfigContext);
  const customerName = `${currentConfig?.customerShortName} (${customer.usecaseId}, ${customer.languageCode})`;
  const customerProfile = useCustomerProfile();
  const currentUser = useContext(UserContext);
  const handleSaveProject = useCallback(() => {
    const request: UpdateModelProjectRequest = {
      modelProject: {
        name: evaluateData.modelProject,
        // Required in proto, won't be updated in backend.
        displayName: ' ',
        status: ModelProjectStatus.READY,
      },
      updateMask: 'status',
    };
    dispatch(updateModelProject(request));
    const notificationRequest: SendNotificationRequest = {
      profile: customerProfile,
      slackChat: {
        channelId: STUDIO_NOTIFICATION_CHANNEL_ID,
        text: wizardModelReadyMessage(customerName, currentUser?.fullName || 'Studio Parrot', window.location.href),
      },
    };
    dispatch(sendNotification(notificationRequest));
    navigate(`/${customer.path}/model-wizard`);
  }, [navigate, customer.path, evaluateData]);
  return (
    <Modal title="Confirm Model 7 is ready" size={746} opened={opened} onClose={onCancel} withCloseButton={false}>
      <Stack>
        <ConfirmModalTable evaluateData={evaluateData}/>
        <Group position="right">
          <Button variant="subtle" onClick={onCancel}>
            Cancel
          </Button>
          <Button title="Confirm" onClick={handleSaveProject}>Confirm</Button>
        </Group>
      </Stack>
    </Modal>
  );
}
