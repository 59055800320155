import React from 'react';

/*  This component will scroll:
  a) ...to the bottom when the length of this.props.children changes
  b) ...when it detects a new focusScrollId
*/
interface Props {
  focusScrollId?: string;
  children: any[]; // i have no idea how to type this
  focusBackgroundColor?: string;
  focusedVerticalPosition?: ScrollLogicalPosition;
}

const ChatItemContainer = React.forwardRef((
  props: { children: React.ReactNode; style: React.CSSProperties },
  ref: React.Ref<HTMLDivElement>,
) => (
  <div style={props.style} ref={ref}>
    {props.children}
  </div>
));

class MessageList extends React.Component<Props, {}> {
  focusedElement: React.RefObject<HTMLDivElement>;

  bottomOfList: React.RefObject<HTMLDivElement>;

  static defaultProps = { focusedVerticalPosition: 'start' };

  constructor(props) {
    super(props);
    this.focusedElement = React.createRef();
    this.bottomOfList = React.createRef();
  }

  componentDidMount() {
    if (this.focusedElement.current && this.props.focusScrollId) {
      // for some reason if we set smooth scrolling on didMount scrolling,
      // it won't scroll at all
      this.scrollToFocus(false);
    } else {
      // this.scrollToBottom(false);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const hasNewChildren = React.Children.count(this.props.children)
      !== React.Children.count(prevProps.children);
    const focusIdDidChange = prevProps.focusScrollId !== this.props.focusScrollId
      && typeof this.props.focusScrollId === 'string';
    if (hasNewChildren) {
      this.scrollToBottom();
    }
    if (focusIdDidChange) {
      // Disable smooth scroll when changing children
      this.scrollToFocus(!hasNewChildren);
    }
  }

  scrollToFocus = (isSmooth: boolean = true) => {
    if (this.focusedElement.current) {
      this.focusedElement.current.scrollIntoView({
        behavior: isSmooth === true ? 'smooth' : undefined,
        block: this.props.focusedVerticalPosition,
      });
    }
  };

  scrollToBottom = (isSmooth: boolean = true) => this.bottomOfList.current
    && this.bottomOfList.current.scrollIntoView({
      behavior: isSmooth === true ? 'smooth' : undefined,
    });

  render() {
    return (
      <div>
        {React.Children.map(this.props.children, (c) => {
          const ref = c.props.scrollId === this.props.focusScrollId
            ? this.focusedElement
            : undefined;
          return (
            <ChatItemContainer
              style={{
                background: ref ? this.props.focusBackgroundColor : undefined,
              }}
              ref={ref}
            >
              {c}
            </ChatItemContainer>
          );
        })}
        <div ref={this.bottomOfList}/>
      </div>
    );
  }
}

export default MessageList;
