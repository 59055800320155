import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { InitReq } from '@cresta/web-client/dist/fetch.pb';
import { getAccessToken } from 'common/auth';

export interface CancellableFetch<T> {
  /**
   * Will be resolved with the response if the fetch was OK or undefined if and only if
   * the fetch was cancelled.
   */
  readonly promise: Promise<T | undefined>;

  /**
   * Can be called to cancel the in-flight request.
   */
  readonly cancel: () => void;
}

export abstract class StudioApi {
  // Setter for customer config.
  private static customerConfig: Config | undefined;

  public static setCustomerConfig(config: Config) {
    this.customerConfig = config;
  }

  // Store callbacks to cancel pending API calls. key: API, value: cancel callback.
  private static cancelCallbacks: Map<string, (() => void)> = new Map();

  // Get header for the config service which returns all the customer profile configs.
  // Note:
  //   * We only call the multi-tenant endpoint.
  //   * In staging, the config service returns configs in its cluster. The corresponding cluster endpoint is used.
  //   * In prod, it returns all configs across prod clusters. We use the chat-prod endpoint.
  public static getConfigHeader(): InitReq {
    const currentHost = window.location.host;
    // Default is prod.
    let endpoint = 'https://api-cresta-api.chat-prod.internal.cresta.ai';
    // If there is any endpoint set up in the environment, it has the highest priority.
    if (process.env.REACT_APP_STUDIO_GO_SERVER_API_DOMAIN) {
      endpoint = process.env.REACT_APP_STUDIO_GO_SERVER_API_DOMAIN;
    }
    // Default chat-staging for localhost.
    if ((currentHost.includes('localhost') && process.env.REACT_APP_LOCALHOST_PRODUCTION?.toLowerCase() !== 'true')
      || currentHost.includes('staging')) {
      // Staging servers return configs across all staging clusters.
      endpoint = 'https://api-cresta-api.chat-staging.internal.cresta.ai';
    }
    return {
      pathPrefix: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAccessToken(),
      },
    };
  }

  // Get header for customer-specific APIs.
  public static getHeaders(customerConfig?: Config): { initReq: InitReq, cancel: () => void } {
    const abortController = new AbortController();
    return {
      initReq: {
        pathPrefix: StudioApi.getStudioApiEndpoint(customerConfig),
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAccessToken(true),
        },
        signal: abortController.signal,
      },
      cancel: () => abortController.abort(),
    };
  }

  public static getCancelCallback(key: string): () => void {
    return StudioApi.cancelCallbacks.get(key);
  }

  public static setCancelCallback(key: string, cancel: () => void) {
    return StudioApi.cancelCallbacks.set(key, cancel);
  }

  public static removeCancelCallback(key: string) {
    StudioApi.cancelCallbacks.delete(key);
  }

  // Get the internal API endpoint for the customer.
  public static getStudioApiEndpoint(customerConfig?: Config): string {
    // If there is any endpoint set up in the environment, it has the highest priority.
    if (process.env.REACT_APP_STUDIO_GO_SERVER_API_DOMAIN) {
      return process.env.REACT_APP_STUDIO_GO_SERVER_API_DOMAIN;
    }
    let config = customerConfig;
    if (!config) {
      config = this.customerConfig;
    }
    if (!config) {
      throw new Error('Customer config is not set.');
    }
    const { environmentId } = config;

    return `https://api-cresta-api.${environmentId}.internal.cresta.ai`;
  }

  // Get the API endpoint for the customer.
  public static getPublicApiEndpoint(customerConfig?: Config): string {
    // If there is any endpoint set up in the environment, it has the highest priority.
    if (process.env.REACT_APP_STUDIO_GO_SERVER_API_DOMAIN) {
      return process.env.REACT_APP_STUDIO_GO_SERVER_API_DOMAIN;
    }
    let config = customerConfig;
    if (!config) {
      config = this.customerConfig;
    }
    if (!config) {
      throw new Error('Customer config is not set.');
    }
    const { environmentId } = config;

    // Only voice-prod and chat-prod use irregual API endpoint.
    if (environmentId === 'voice-prod') {
      return 'https://api-voice.cresta.com';
    }
    if (environmentId === 'chat-prod') {
      return 'https://api.cresta.com';
    }
    return `https://api.${environmentId}.cresta.ai`;
  }

  // Get the python API endpoint for the customer.
  public static getPythonApiEndpoint(customerConfig: Config): string {
    // If there is any endpoint set up in the environment, it has the highest priority.
    if (process.env.REACT_APP_STUDIO_API_DOMAIN) {
      return process.env.REACT_APP_STUDIO_API_DOMAIN;
    }
    const { environmentId } = customerConfig;
    return `https://studio-py-api.${environmentId}.internal.cresta.ai/api`;
  }

  // Get the API endpoint for cresta gpt service
  public static getCrestaGptApiEndpoint(customerConfig?: Config): string {
    let config = customerConfig;
    if (!config) {
      config = this.customerConfig;
    }

    // TODO: Enable this when we have multiple environments for GPT endpoint
    // const { environmentId } = config;
    // return `https://gptdemo.${environmentId}.cresta.ai`;
    return 'https://gptdemo.chat-prod.cresta.ai';
  }
}
