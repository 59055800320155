/* eslint-disable no-await-in-loop */
import { useState, useEffect, useMemo } from 'react';
import { ListRubricsRequest, Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { RubricApi } from 'services/rubricApi';
import { RpcError } from 'components/Notification';
import { UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useCustomerParams } from './useCustomerParams';

/** Load rubrics */
export const useRubrics = (taskType?: UserLabelingTaskType): [Rubric[], boolean, RpcError] => {
  const [rubrics, setRubrics] = useState<Rubric[]>([]);
  const [isLoading, toggleLoading] = useState(true);
  const [error, setError] = useState<RpcError>(null);
  const customer = useCustomerParams();

  useEffect(() => {
    if (!customer) return;

    const fetchRubrics = async () => {
      toggleLoading(true);
      try {
        const request: ListRubricsRequest = {
          pageSize: 100,
        };
        const response = await RubricApi.listRubrics(request);
        if (JSON.stringify(rubrics) !== JSON.stringify(response?.rubrics)) {
          setRubrics(response?.rubrics);
        }
      } catch (err) {
        setError(err as RpcError);
      } finally {
        toggleLoading(false);
      }
    };

    fetchRubrics();
  }, []);

  const filteredRubrics = useMemo(() => {
    if (!taskType) {
      return rubrics;
    } else {
      return rubrics.filter((rubric) => rubric.availableTasks.includes(taskType));
    }
  }, [taskType, rubrics]);

  return [filteredRubrics, isLoading, error];
};
