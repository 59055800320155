import { BatchUpsertSharedConceptConfigsRequest, Concept, SharedConceptConfigDeploymentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Button, Group, Modal, Text } from '@mantine/core';
import ConceptTag from 'components/ConceptTag';
import { RpcError } from 'components/Notification';
import { getConceptRole } from 'components/RoleIcon';
import { useDispatch } from 'hooks/reduxHooks';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import React, { useCallback, useState } from 'react';
import { ConceptApi } from 'services/conceptApi';
import { listConcepts } from 'store/concept/asyncThunks';

interface DeactivateIntentModalProps {
  opened: boolean,
  onCancel: () => void,
  concept: Concept,
  customerId: string,
  profileId: string,
  usecaseId: string,
}

export default function DeactivateIntentModal({
  opened,
  onCancel,
  concept,
  customerId,
  profileId,
  usecaseId,
}: DeactivateIntentModalProps) {
  const [loading, setLoading] = useState(false);
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const [error, setError] = useState<RpcError>();
  const dispatch = useDispatch();

  const handleConfirm = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    setError(null);

    try {
      const request: BatchUpsertSharedConceptConfigsRequest = {
        parent: `customers/${customerId}/profiles/${profileId}`,
        usecaseId,
        concepts: [concept.name],
        config: {
          deploymentType: SharedConceptConfigDeploymentType.OFF,
        },
      };

      await ConceptApi.batchUpsertSharedConceptConfigs(request);
      dispatch(listConcepts({
        parent: customerProfile,
        usecaseId: customer?.usecaseId,
        languageCode: customer?.languageCode,
      }));
      onCancel();
    } catch (err) {
      setError(err as any);
    } finally {
      setLoading(false);
    }
  }, [loading, customerId, profileId, concept, error]);

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Modal opened={opened} onClose={handleOnCancel} size="sm" title="Confirm deactivate" withCloseButton={false}>
      <Text>Are you sure you want to deactive this intent from the following customer? This action will be reflected immediately.</Text>
      <Group mt="md">
        <Text size="md" color="dimmed">Intent</Text>
        <ConceptTag value={concept.conceptTitle} role={getConceptRole(concept)} />
      </Group>
      {error && (
        <Text color="red" size="sm" mt="lg">{error?.message}</Text>
      )}
      <Group position="right" mt="lg">
        <Button variant="subtle" onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button loading={loading} title="Confirm" onClick={() => handleConfirm()}>Deactivate</Button>
      </Group>
    </Modal>
  );
}
