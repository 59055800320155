import React from 'react';
import { SelectItem, Autocomplete } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { EnsembleConfigFormValues } from './useEnsembleConfigForm';
import { ConfigPanelContainer } from '../../components/ConfigPanelContainer';
import { QAConfigFormValues, QATaskSetup } from '../../components/QATaskSetup';

interface Props {
  generativeModelOptions: (SelectItem | string)[];
  retrievalModelOptions: (SelectItem | string)[];
  ensembleForm: UseFormReturnType<EnsembleConfigFormValues>;
  qaConfigForm: UseFormReturnType<QAConfigFormValues>;
}

export const EnsembleConfigForm = (props: Props) => {
  const { generativeModelOptions, retrievalModelOptions, ensembleForm, qaConfigForm } = props;

  return (
    <ConfigPanelContainer>
      <Autocomplete
        label="Select Generative Model"
        data={generativeModelOptions}
        placeholder="Select Generative Model"
        {...ensembleForm.getInputProps('generativeModelPath')}
      />
      <Autocomplete
        label="Select Retrieval Model"
        data={retrievalModelOptions}
        placeholder="Select Retrieval Model"
        {...ensembleForm.getInputProps('retrievalModelPath')}
      />
      <QATaskSetup configForm={qaConfigForm} />
    </ConfigPanelContainer>
  );
};
