import React, { useRef } from 'react';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Input, InputRef } from 'antd';
import { LabelValue } from 'types';
import './InputWithLabelValue.scss';
import classNames from 'classnames';
import { useHotkeys } from 'hooks/useHotkeys';
import { Button } from '@mantine/core';

export interface InputWithLabelValueData {
  value: string;
  type: LabelValue;
  hidden: boolean;
}

type InputWithLabelValueProps = {
  valueData: InputWithLabelValueData;
  number: number;
  showToggle?: boolean;
  showLabelValue?: boolean;
  onChange: (value: InputWithLabelValueData) => void;
  onDeleteHandler: () => void;
  canDelete?: boolean;
  placeholder?: string;
};

export default function InputWithLabelValue({
  valueData,
  number,
  showToggle = true,
  showLabelValue = true,
  onChange,
  onDeleteHandler,
  canDelete = true,
  placeholder,
}: InputWithLabelValueProps) {
  const { value, type, hidden } = valueData;
  const inputRef = useRef<InputRef>();

  useHotkeys('esc', () => {
    if (inputRef) {
      inputRef.current.input.blur();
    }
  }, { enableOnTags: ['INPUT'] }, [inputRef?.current]);

  const toggleType = (type: LabelValue) => {
    onChange({
      value,
      type:
        type === LabelValue.POSITIVE ? LabelValue.NEGATIVE : LabelValue.POSITIVE,
      hidden,
    });
  };

  const typeButton = (
    <Button
      color={type === LabelValue.POSITIVE ? 'green' : 'red'}
      variant="light"
      compact
      onClick={() => toggleType(type)}
    >
      {type === LabelValue.POSITIVE ? 'Positive' : 'Negative'}
    </Button>
  );

  return (
    <div className={classNames([
      'input-with-label-value',
      { 'value-hidden': hidden },
    ])}
    >
      {showToggle && (
        <div
          className="input-with-label-value-toggle"
          onClick={() =>
            onChange({
              value,
              type,
              hidden: !hidden,
            })}
        >
          {hidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      )}
      <div className="input-with-label-value-number">
        <span>{number}</span>
      </div>
      <Input
        ref={inputRef}
        value={value}
        autoFocus={value === ''}
        tabIndex={number}
        placeholder={placeholder}
        onChange={(event) =>
          onChange({
            value: event.target.value,
            type,
            hidden,
          })}
        suffix={showLabelValue ? typeButton : null}
      />
      <DeleteOutlined
        className={!canDelete ? 'disabled' : ''}
        onClick={() => {
          if (canDelete) {
            onDeleteHandler();
          }
        }}
      />
    </div>
  );
}
