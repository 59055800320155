import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from '@mantine/core';
import { DialoguePolicyInstance } from 'hooks/useDialoguePolicy';
import { PythonEditor, YamlEditor } from 'pages/DialogPolicy/';
import React, { memo, useState, useCallback } from 'react';

import styles from './EditorDrawer.module.scss';

interface Props {
  dialoguePolicy: DialoguePolicyInstance;
  handleUpdateAndRun: () => void;
  taskRunning?: boolean;
}
export const EditorDrawer = memo(({ dialoguePolicy, handleUpdateAndRun, taskRunning } : Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [editorType, setEditorType] = useState<'python' | 'yaml'>('python');
  const { sourceCode, setSourceCode, config, setConfig } = dialoguePolicy;

  const handleExpand = useCallback(() => setExpanded(!expanded), [setExpanded, expanded]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.container} aria-expanded={expanded}>
        <div className={styles.editorHeader}>
          <Button variant="subtle" onClick={handleExpand} compact>
            {expanded ? (
              <ArrowRightOutlined className={styles.icon} />
            ) : (
              <ArrowLeftOutlined className={styles.icon} />
            )}
          </Button>
          <span className={styles.spacer} />
          {/* <Select
            showArrow
            className={styles.historySelect}
            size="large"
            placeholder="detection"
            allowClear
          >
            <Select.Option key="1" value="">
              <span className={styles.historyOption} data-status="draft">
                <Badge color="#FAB005" /> Draft: 09/04/2022
              </span>
            </Select.Option>
          </Select> */}
          <Button
            variant="subtle"
            onClick={handleUpdateAndRun}
            className={styles.editorStatus}
            loading={taskRunning}
            disabled={taskRunning}
          >
            Update
          </Button>
        </div>
        <div className={styles.editor}>
          <div className={styles.buttonGroup}>
            <Button
              onClick={() => setEditorType('python')}
              variant="subtle"
              size="sm"
              color={editorType === 'python' ? undefined : 'gray'}
              compact
            >
              Dialog Policy
            </Button>
            <div className={styles.divider} />
            <Button
              onClick={() => setEditorType('yaml')}
              variant="subtle"
              size="sm"
              color={editorType === 'yaml' ? undefined : 'gray'}
              compact
            >
              Config
            </Button>
          </div>
          <div className={styles.dialogPolicyEditor} key={expanded.toString()}>
            {editorType === 'yaml' ? (
              <YamlEditor
                currentTab="draft"
                onCodeChange={setConfig}
                defaultValue={config}
                options={{
                  minimap: {
                    enabled: false,
                  },
                }}
              />
            ) : (
              <PythonEditor
                currentTab="draft"
                onCodeChange={setSourceCode}
                defaultValue={sourceCode}
                options={{
                  minimap: {
                    enabled: false,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
