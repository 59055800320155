import React from 'react';
import { Alert, Anchor, Button, Collapse, Flex, Group, Stack, Text } from '@mantine/core';
import { MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS } from '../../../config';
import { suggestionSkeletons } from './skeletonsConfig';
import {
  PositiveIcon,
  NegativeIcon,
} from '../../../components/icons';
import { MultilineSeleton } from '../../../components/MultilineSkeleton';
import { ExampleObj, FormValueExampleArrayType } from '../../../types';

interface SuggestedExampleItemProps {
    isLoading?: boolean
    handleAddPositive?: () => void
    handleAddNegative?: () => void
    children: React.ReactNode
}

const SuggestedExampleItem = (props: SuggestedExampleItemProps) => {
  const { isLoading, handleAddPositive, handleAddNegative, children } = props;
  return (
    <Group
      noWrap
      position="apart"
      p="sm"
      style={{
        border: '1px solid lightgrey',
        borderRadius: '10px',
      }}
    >
      <div style={{ flex: 1, overflow: 'auto' }}>
        {children}
      </div>
      <Stack spacing="xs" style={{ minWidth: 95 }}>
        <Button variant="light" color="green" radius="lg" onClick={handleAddPositive} compact disabled={isLoading}>
          <Group noWrap>
            <PositiveIcon />
            <Text size="xs">Positive</Text>
          </Group>
        </Button>
        <Button variant="light" color="red" radius="lg" onClick={handleAddNegative} compact disabled={isLoading}>
          <Group noWrap>
            <NegativeIcon />
            <Text size="xs">Negative</Text>
          </Group>
        </Button>
      </Stack>
    </Group>
  );
};

interface SkeletonProps {
    rows?: number;
    lastRowWidth?: string;
}

export const SuggestionsContainerSkeleton = ({
  rows = 1,
  lastRowWidth = '80%',
}: SkeletonProps) => (
  <SuggestedExampleItem isLoading>
    <Stack style={{ width: '100%' }}>
      <MultilineSeleton rows={rows} lastRowWidth={lastRowWidth} />
    </Stack>
  </SuggestedExampleItem>
);

interface SuggestionsContainerProps {
    isShowingRefreshAlert: boolean
    onCloseAlert: () => void
    hasReachedMinimumExamples: boolean
    isLoading: boolean
    suggestedExamples: ExampleObj[]
    handleAddNewExample: (formValue: FormValueExampleArrayType, text: string) => void
    handleRemoveSuggestedExample: (example: ExampleObj) => void
    handleRefresh: () => void
    openPreviewPanel: () => void
}

export const Suggestions = (props: SuggestionsContainerProps) => {
  const { isShowingRefreshAlert, onCloseAlert, hasReachedMinimumExamples, isLoading, suggestedExamples, handleAddNewExample, handleRemoveSuggestedExample, handleRefresh, openPreviewPanel } = props;
  const getContent = (isloading: boolean) => {
    if (!hasReachedMinimumExamples) {
      return (
        <Alert color="gray">
          <Text color="dimmed">{`Add at least ${MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS} positive examples to generate examples`}</Text>
        </Alert>
      );
    }
    if (isloading) {
      return (
        <>
          {suggestionSkeletons.map((skeleton) => (
            <SuggestionsContainerSkeleton
              key={skeleton.key}
              rows={skeleton.rows}
              lastRowWidth={skeleton.lastRowWidth}
            />
          ))}
        </>
      );
    } else if (suggestedExamples.length === 0) {
      return (
        <Alert color="gray">
          <Text color="dimmed">No suggestions found for given examples.</Text>
        </Alert>
      );
    } else {
      return (
        <>
          <Collapse in={isShowingRefreshAlert}>
            <Alert
              withCloseButton
              color="gray"
              closeButtonLabel="Close alert"
              onClose={onCloseAlert}
            >
              <Text component="span" color="dimmed">Looks like you&apos;ve updated the examples. To see more relevant examples, </Text>
              <Anchor component="button" onClick={handleRefresh}>click here</Anchor>
            </Alert>
          </Collapse>
          {suggestedExamples.map((example) => (
            <SuggestedExampleItem
              key={example.value}
              handleAddPositive={() => {
                handleAddNewExample(FormValueExampleArrayType.POSITIVE, example.value);
                handleRemoveSuggestedExample(example);
              }}
              handleAddNegative={() => {
                handleAddNewExample(FormValueExampleArrayType.NEGATIVE, example.value);
                handleRemoveSuggestedExample(example);
              }}
            >
              <Text size="sm">{example.value}</Text>
            </SuggestedExampleItem>
          ))}
        </>
      );
    }
  };
  const [isNudgeShown, setIsNudgeShown] = React.useState(false);
  const COPY = "Can't choose from these?";

  return (
    <Flex direction="column" gap={5} mt="xs">
      <Collapse in={isNudgeShown} my="sm">
        <Alert color="gray" withCloseButton styles={() => ({ body: { marginRight: 20 } })} onClose={() => setIsNudgeShown(false)}>
          Don’t forget to review the examples under{' '}
          <Anchor
            inline
            style={{ display: 'unset' }}
            onClick={() => {
              openPreviewPanel();
            }}
          >
            Labels Preview
          </Anchor>{' '}
          before starting off the task.
        </Alert>
      </Collapse>
      <Group noWrap>
        {hasReachedMinimumExamples && (
          <>
            <Text color="dimmed" size="xs">
              {COPY}
            </Text>
            <Button size="xs" variant="subtle" loading={isLoading} onClick={handleRefresh} compact>
              Refresh examples
            </Button>
          </>
        )}
      </Group>
      {getContent(isLoading)}
    </Flex>
  );
};
