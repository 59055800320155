import React from 'react';
import { Message as MessageType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message as MessageComponent } from './Message';
import { UITagType } from '../..';
import { PredictedTag } from '../../TaggingPage';

export type ConversationAnnotations = {
  messageId: string;
  conceptIds: string[];
}

interface MessageProps {
  index: number
  message: MessageType
  onRemoveTagFromMessage?: (tagId: string) => void
  onPredictionTagClick?: (tag: PredictedTag) => void
  handleMessageClick?: () => void
  isActive: boolean
  conversationAnnotations: ConversationAnnotations[]
  predictedTags?: PredictedTag[]
  tagsMap: Map<string, UITagType>
  savingPrediction?: string
  setSavingPrediction?: (id: string) => void
}

export const Message = (props: MessageProps) => {
  const { message, conversationAnnotations, predictedTags = [], tagsMap } = props;

  const tagIds = conversationAnnotations.find((conversationAnnotation) => conversationAnnotation.messageId === message.v2MessageId)?.conceptIds || [];
  // filter out tags that are not in the tagsMap
  const filteredTagIds = tagIds.filter((tagId) => tagsMap.has(tagId));
  const allTags = filteredTagIds.map((tagId: string) => tagsMap.get(tagId));

  // Filter predicted tags for this message
  const messagePredictedTags = predictedTags.filter((tag) => tag.messageId === message.v2MessageId);

  // handle stage tags
  // filter out STAGE_END tags
  const withoutStageEndTags = allTags.filter((tag) => tag.tagType !== TagTagType.STAGE_END);
  const onlyStageEndTags = allTags.filter((tag) => tag.tagType === TagTagType.STAGE_END);

  onlyStageEndTags.forEach((tag) => {
    const endLabel = tag.label.replace(/\.end$/, '');
    const stageBeginTag = withoutStageEndTags.find((withoutStageEndTag) => withoutStageEndTag.label.replace(/\.begin$/, '') === endLabel);
    if (stageBeginTag) {
      stageBeginTag.endStageId = tag.value;
    }
  });
  // remove .begin suffix from stage tags's label
  const removeBeginSuffix = (label: string) => label.replace(/\.begin$/, '');
  const preStageTags: UITagType[] = withoutStageEndTags.map((tag) => {
    if (tag.tagType === TagTagType.STAGE_BEGIN) {
      return { ...tag, label: removeBeginSuffix(tag.label) };
    }
    return { ...tag, label: removeBeginSuffix(tag.label) };
  });

  const stageTags: UITagType[] = preStageTags.map((tag) => {
    if (tag.tagType === TagTagType.STAGE) {
      return { ...tag, label: `${tag.label} - Start` };
    }
    return { ...tag };
  });
  if (message.actorType === ActorType.SYSTEM) {
    return (
      <MessageComponent
        tags={stageTags}
        {...props}
        messagePredictedTags={messagePredictedTags}
        letterAvatar="S"
        bg="#FFEC99"
        color="#000"
      />
    );
  }
  if (message.actorType === ActorType.AGENT) {
    return (
      <MessageComponent
        tags={stageTags}
        {...props}
        messagePredictedTags={messagePredictedTags}
        letterAvatar="A"
        bg="#304FFE"
        color="white"
        alignRight
      />
    );
  }
  if (message.actorType === ActorType.BOT) {
    return (
      <MessageComponent
        tags={stageTags}
        {...props}
        messagePredictedTags={messagePredictedTags}
        letterAvatar="B"
        bg="#304FFE"
        color="white"
        alignRight
      />
    );
  }
  return (
    <MessageComponent
      tags={stageTags}
      {...props}
      messagePredictedTags={messagePredictedTags}
      letterAvatar="V"
      bg="#F1F3F4"
    />
  );
};
