import React, { forwardRef, useEffect, useState } from 'react';
import { ActionIcon, Button, Checkbox, Divider, Group, Input, Modal, Radio, ScrollArea, Select, Stack, Text } from '@mantine/core';
import { useSelector } from 'hooks/reduxHooks';
import { selectConceptsFactory } from 'store/concept/selectors';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import ConceptTag from 'components/ConceptTag';
import { useInputState } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { CloseIcon } from '../CloseIcon';
import { useUtteranceModalFunctions } from './useUtteranceModalFunctions';
import { AddIntentForm } from './AddIntentForm';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  intent: Concept;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, intent, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <ConceptTag value={intent.conceptTitle} />
    </div>
  ),
);
interface AddUtteranceModalProps {
  isOpen: boolean;
  onClose: () => void;
  utteranceData: {
    value: string;
    label: string;
  }[];
  onRemoveUtterance: (utteranceId: string) => void;
}
export interface FormValues {
  addAsCheckboxValues: string[];
  selectedIntentName: string;
}

export const AddUtteranceModal = (props: AddUtteranceModalProps) => {
  const { isOpen, onClose, utteranceData, onRemoveUtterance } = props;
  const { addPositiveExamplesToIntent, addPositiveAnnotationsToIntent } = useUtteranceModalFunctions();
  const intents: Concept[] = useSelector(selectConceptsFactory(ConceptConceptType.INTENT));
  const [isCreatingNewIntent, setIsCreatingNewIntent] = useInputState(false);
  const [selectedIntentName, setSelectedIntentName] = useState<string>(null);
  const [isDisabledPositiveAnnotationRadio, toggleDisablePositiveAnnotationRadio] = useState<boolean>(false);

  const form = useForm<FormValues>({
    initialValues: {
      addAsCheckboxValues: ['examples', 'annotations'],
      selectedIntentName: null,
    },
  });

  // reset form on umount
  useEffect(() => () => {
    setIsCreatingNewIntent(false);
    form.reset();
  }, [isOpen]);

  // sets selectedInntentName when form value changes
  useEffect(() => {
    setSelectedIntentName(form.values.selectedIntentName);
  }, [form.values.selectedIntentName]);

  const isAddBtnDisabled = !selectedIntentName || form.values.addAsCheckboxValues.length === 0;

  const addUtteranceLabelsToIntent = async (intent: Concept, utteranceLabels: string[]) => {
    const promises = [];
    if (form.values.addAsCheckboxValues.includes('examples')) {
      // add examples to concept
      promises.push(addPositiveExamplesToIntent(intent, utteranceLabels));
    }
    if (form.values.addAsCheckboxValues.includes('annotations')) {
      // add annotations to conept
      promises.push(addPositiveAnnotationsToIntent(intent, utteranceLabels));
    }
    return Promise.all(promises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const intent = intents.find((intent) => intent.name === selectedIntentName);
    const utteranceLabels = utteranceData.map((utterance) => utterance.label);
    try {
      await addUtteranceLabelsToIntent(intent, utteranceLabels);
      onClose();
    } catch (err) {
      console.error(err);
      showNotification({
        color: 'red',
        message: 'Error adding utterances',
      });
    }
  };

  useEffect(() => {
    // check if the selected intent is a driver or stage intent
    toggleDisablePositiveAnnotationRadio(false);
    if (!form.values.selectedIntentName) return;
    const intent = intents.find((intent) => intent.name === form.values.selectedIntentName);
    if (!intent) return;
    const isDriverIntent = intent.intent.intentType === IntentIntentType.CONVERSATION_DRIVER;
    const isStageIntent = intent.intent.intentType === IntentIntentType.STAGE;
    if (isDriverIntent || isStageIntent) {
      // remove annotations from checkbox values
      form.setFieldValue('addAsCheckboxValues', form.values.addAsCheckboxValues.filter((value) => value !== 'annotations'));
      toggleDisablePositiveAnnotationRadio(true);
    } else {
      toggleDisablePositiveAnnotationRadio(false);
    }
  }, [form.values.selectedIntentName]);

  return (
    <Modal opened={isOpen} onClose={onClose} title="Add utterances" >
      <form onSubmit={handleSubmit}>
        <Stack>
          <Input.Wrapper label="Selected utterances:">
            <ScrollArea.Autosize mah={130} type="auto" scrollbarSize={8} mt={5}>
              <Stack spacing="xs">
                {utteranceData.map((utterance) => (
                  <Group
                    key={utterance.value}
                    noWrap
                    style={{
                      background: '#EDF2FF',
                    }}
                    position="apart"
                    mr="xs"
                  >
                    <Text size="sm">{utterance.label}</Text>
                    <ActionIcon onClick={() => onRemoveUtterance(utterance.value)}>
                      <CloseIcon />
                    </ActionIcon>
                  </Group>
                ))}
              </Stack>
            </ScrollArea.Autosize>
          </Input.Wrapper>
          <Checkbox.Group
            label="Add as:"
            {...form.getInputProps('addAsCheckboxValues')}
          >
            <Group>
              <Checkbox value="examples" label="Positive examples" />
              <Checkbox value="annotations" label="Positive Annotations" disabled={isDisabledPositiveAnnotationRadio} />
            </Group>
          </Checkbox.Group>
          <Divider />
          <Radio.Group
            mt="-xs"
            value={isCreatingNewIntent ? 'new' : 'existing'}
            onChange={(value) => {
              setSelectedIntentName(null);
              setIsCreatingNewIntent(value === 'new');
              form.setFieldValue('selectedIntentName', null);
            }}
          >
            <Group>
              <Radio value="existing" label="Add to Existing Intent" />
              <Radio value="new" label="Add to New Intent" />
            </Group>
          </Radio.Group>

          {!isCreatingNewIntent ? (
            <Select
              style={{ flex: 1 }}
              label="Which intent are you adding these to?"
              placeholder="Select or create new"
              data={intents.map((intent) => ({
                value: intent.name,
                label: intent.conceptTitle,
                intent,
              }))}
              searchable
              clearable
              itemComponent={SelectItem}
              {...form.getInputProps('selectedIntentName')}
            />
          ) : (
            <AddIntentForm
              onIntentChange={(intentName: string) => {
                setSelectedIntentName(intentName);
              }}
            />
          )}
          <Group position="right">
            <Button variant="subtle" onClick={onClose}>Cancel</Button>
            <Button type="submit" disabled={isAddBtnDisabled}>Add</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
