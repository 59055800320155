import React from 'react';
import { Progress } from '@mantine/core';

interface Props {
  max: number;
  current: number;
}

export const Stepper = ({ max, current }: Props) => {
  const progress = (current / max) * 100;

  return <Progress mt="xs" value={progress} />;
};
