import React from 'react';
import { Badge, Button, Group, Tooltip } from '@mantine/core';
import { SetApi } from 'services/setApi';
import { notification } from 'antd';
import { useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';

const SetIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.38509 5.075L6.10509 2.355C6.15171 2.30838 6.18869 2.25304 6.21392 2.19213C6.23915 2.13121 6.25214 2.06593 6.25214 2C6.25214 1.93407 6.23915 1.86879 6.21392 1.80788C6.18869 1.74697 6.15171 1.69162 6.10509 1.645C6.05847 1.59838 6.00313 1.5614 5.94222 1.53617C5.88131 1.51094 5.81602 1.49796 5.75009 1.49796C5.61694 1.49796 5.48924 1.55085 5.39509 1.645L3.03009 4L2.10509 3.085C2.01094 2.99085 1.88324 2.93796 1.75009 2.93796C1.61694 2.93796 1.48924 2.99085 1.39509 3.085C1.30094 3.17915 1.24805 3.30685 1.24805 3.44C1.24805 3.57315 1.30094 3.70085 1.39509 3.795L2.67509 5.075C2.72157 5.12187 2.77687 5.15906 2.8378 5.18445C2.89873 5.20983 2.96409 5.2229 3.03009 5.2229C3.0961 5.2229 3.16145 5.20983 3.22238 5.18445C3.28331 5.15906 3.33861 5.12187 3.38509 5.075ZM9.25009 3.5H6.50009C6.36748 3.5 6.24031 3.55268 6.14654 3.64645C6.05277 3.74022 6.00009 3.86739 6.00009 4C6.00009 4.13261 6.05277 4.25979 6.14654 4.35355C6.24031 4.44732 6.36748 4.5 6.50009 4.5H9.25009C9.3827 4.5 9.50988 4.55268 9.60365 4.64645C9.69741 4.74022 9.75009 4.86739 9.75009 5V9.86L8.96509 9.135C8.87288 9.04867 8.75141 9.00044 8.62509 9H4.25009C4.11748 9 3.99031 8.94732 3.89654 8.85355C3.80277 8.75979 3.75009 8.63261 3.75009 8.5V6.75C3.75009 6.61739 3.69741 6.49022 3.60365 6.39645C3.50988 6.30268 3.3827 6.25 3.25009 6.25C3.11748 6.25 2.99031 6.30268 2.89654 6.39645C2.80277 6.49022 2.75009 6.61739 2.75009 6.75V8.5C2.75009 8.89783 2.90813 9.27936 3.18943 9.56066C3.47074 9.84197 3.85227 10 4.25009 10H8.43009L9.93009 11.365C10.0223 11.4513 10.1438 11.4996 10.2701 11.5C10.3387 11.4993 10.4065 11.4857 10.4701 11.46C10.5595 11.421 10.6356 11.3566 10.6889 11.2749C10.7422 11.1931 10.7704 11.0976 10.7701 11V5C10.7701 4.80132 10.7307 4.60462 10.654 4.42132C10.5774 4.23801 10.4651 4.07176 10.3237 3.93221C10.1823 3.79265 10.0146 3.68259 9.83026 3.60839C9.64596 3.53419 9.44875 3.49735 9.25009 3.5Z"/>
  </svg>
);

export const SetTags = ({ conversationName, membershipSets, onChangeMembershipSets, badgeProps = {}, containerProps = {} }) => {
  const usecase = useCustomerUsecase();
  const { languageCode } = useCustomerParams();
  // subset of first 3 sets
  const firstThreeItems = membershipSets.slice(0, 3);
  const remainingItems = membershipSets.slice(3);

  return (
    <Group position="right" spacing="xs" {...containerProps}>
      {firstThreeItems.map((set) => (
        <Badge
          key={set.name}
          radius="md"
          size="md"
          styles={() => ({
            root: {
              textTransform: 'unset',
              fontWeight: 'unset',
            },
            leftSection: {
              display: 'flex',
              alignItems: 'center',
            },
          })}
          color="gray"
          leftSection={<SetIcon />}
          rightSection={(
            <Tooltip label="Remove from set" position="top" withArrow>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  SetApi.removeFromSet({
                    name: set.name,
                    setItemNames: [conversationName],
                    usecase,
                    languageCode,
                  }).then(() => {
                    notification.info({
                      message: 'Successfully removed from set',
                    });
                    onChangeMembershipSets(membershipSets.filter((membershipSet) => membershipSet.name !== set.name));
                  }).catch((err) => {
                    notification.error({
                      message: 'Failed to remove from set',
                      description: err.message,
                    });
                  });
                }}
              >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM6 10.8C3.03333 10.8 0.8 8.56667 0.8 5.6C0.8 2.63333 3.03333 0.4 6 0.4C8.96667 0.4 11.2 2.63333 11.2 5.6C11.2 8.56667 8.96667 10.8 6 10.8ZM8.4 4.8L7.2 3.6L6 4.8L4.8 3.6L3.6 4.8L4.8 6L3.6 7.2L4.8 8.4L6 7.2L7.2 8.4L8.4 7.2L7.2 6L8.4 4.8Z" />
                </svg>
              </div>
            </Tooltip>
        )}
          {...badgeProps}
        >{set.setTitle}
        </Badge>
      ))}
      {remainingItems.length > 0 && (
      <Tooltip
        label={(
          <div>
            {remainingItems.map((set) => (
              <div>{set.setTitle}</div>
            ))}
          </div>
      )}
        multiline
        withArrow
      >
        <Button
          variant="light"
          size="xs"
          color="gray"
          compact
        >{`+ ${remainingItems.length} more...`}
        </Button>
      </Tooltip>
      )}
    </Group>
  );
};
