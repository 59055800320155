import { BinaryValueValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import MultiTags from 'components/MultiTags';
import { useSelector } from 'hooks/reduxHooks';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { Group } from '@mantine/core';
import externalLinkIcon from 'assets/svg/icon-external-link.svg';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { Tooltip } from 'antd';
import styles from '../styles.module.scss';
import { getPrecisionPercentage } from '../utils';
import { BaseSummaryTable } from '..';

interface SummaryIntentPredictionTableProps extends BaseSummaryTable {}

export default function SummaryIntentPredictionTable({
  searchTerm,
  taskId,
  allConceptsMap,
  filtersNode,
}: SummaryIntentPredictionTableProps) {
  const navigate = useNavigate();
  const customer = useCustomerParams();
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);

  const summaryResults = useMemo(() => {
    const resultsMap = new Map<string, ResultsRow>();
    annotationBundles.forEach((bundle) => {
      const intentId = bundle.annotation.value.binaryValue.conceptId;
      const { value } = bundle.annotation.value.binaryValue;
      let resultRow = resultsMap.get(intentId);
      if (!resultRow) {
        resultRow = {
          id: intentId,
          name: allConceptsMap.get(intentId)?.name || '',
          intent: allConceptsMap.get(intentId)?.conceptTitle || '',
          correct: 0,
          incorrect: 0,
        };
        resultsMap.set(intentId, resultRow);
      }
      if (value === BinaryValueValue.VALUE_POSITIVE) {
        resultRow.correct += 1;
      } else if (value === BinaryValueValue.VALUE_NEGATIVE) {
        resultRow.incorrect += 1;
      }
    });

    const fullSummary = Array.from(resultsMap.values());
    const filteredSummary = fullSummary.filter((row) => row.intent.toLowerCase().includes(searchTerm.toLowerCase()));
    return filteredSummary;
  }, [annotationBundles, searchTerm]);

  interface ResultsRow {
    id: string;
    name: string;
    intent: string;
    correct: number;
    incorrect: number;
  }

  const columns: ColumnsType<ResultsRow> = [
    {
      title: 'Intent',
      dataIndex: 'intent',
      key: 'intent',
      render: (value, row) => (
        <Group noWrap>
          <MultiTags type="intent" tags={[value]}/>
          <Tooltip title="Open in Annotations">
            <Link
              to={`/${customer.path}/annotations/${row.id}?taskId=${taskId}&split=DATA_SPLIT_UNSPECIFIED&target=QA`}
              target="_blank"
            >
              <img src={externalLinkIcon} alt="Open link"/>
            </Link>
          </Tooltip>
        </Group>
      ),
    },
    {
      title: 'True Positive',
      width: 180,
      dataIndex: 'correct',
      key: 'correct',
      sorter: (a, b) => b.correct - a.correct,
      onCell: (record) => ({
        onClick: (e) => {
          // without stopPropogation, history.push works incorrectly (adds a second score-view directory)
          e.stopPropagation();
          return navigate(`../score-view/${taskId}/${record.id}?labelFilter=${BinaryValueValue.VALUE_POSITIVE}`);
        },
      }),
      render: (value, row) => (
        <span className={styles.linkLike}>
          {value}
        </span>
      ),
    },
    {
      title: 'False Positive',
      width: 180,
      dataIndex: 'incorrect',
      key: 'incorrect',
      sorter: (a, b) => b.incorrect - a.incorrect,
      onCell: (record) => ({
        onClick: (e) => {
          // without stopPropogation, history.push works incorrectly (adds a second score-view directory)
          e.stopPropagation();
          return navigate(`../score-view/${taskId}/${record.id}?labelFilter=${BinaryValueValue.VALUE_NEGATIVE}`);
        },
      }),
      render: (value, row) => (
        <span className={styles.linkLike}>
          {value}
        </span>
      ),
    },
    {
      title: 'Precision',
      width: 180,
      dataIndex: 'precision',
      key: 'precision',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => getPrecisionPercentage(b.correct, b.incorrect) - getPrecisionPercentage(a.correct, a.incorrect),
      render: (value, row) => (row.correct === 0 ? 'N/A' : `${getPrecisionPercentage(row.correct, row.incorrect)}%`),
    },
  ];

  return (
    <div style={{
      width: '100%',
      flex: 1,
    }}
    >
      <Group mb="lg" position="right">
        <>
          {filtersNode && filtersNode}
        </>
      </Group>
      <Table
        onRow={(record) => ({
          onClick: () => navigate(`../score-view/${taskId}/${record.id}`),
        })}
        loading={{
          spinning: taskApiStatus === 'loading',
          indicator: <Loading />,
        }}
        columns={columns}
        rowKey={(row) => row.intent}
        dataSource={summaryResults}
        className={styles.summaryTable}
      />
    </div>
  );
}
