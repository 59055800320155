/* eslint-disable react/no-array-index-key */
import { ScrollArea, Stack, Text } from '@mantine/core';
import classNames from 'classnames';
import React, { useContext, useLayoutEffect, useRef } from 'react';
import { StagesContext } from '../../StagesProvider';
import { MessageSkeletons } from './MessageSkeletons';
import { StageContainer } from './StageContainer';

interface MessageComponentProps<T> {
    message: T;
    index: number;
}

interface ChatBoxProps<T> {
    scrollPosition?: 'top' | 'bottom' | number;
    isLoading?: boolean;
    messages: T[];
    NoMessageComponent?: React.FC;
    MessageComponent: React.FC<MessageComponentProps<T>>;
    scrollAreaClass?: string;
}
const NoDataIcon = () => (
  <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7" />
      <g className="ant-empty-img-simple-g" fillRule="nonzero">
        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path" />
      </g>
    </g>
  </svg>
);

const DefaultNoMessageComponent = () => (
  <Stack align="center" justify="center" spacing="xl" py="xl" style={{ height: 500 }}>
    <NoDataIcon />
    <Text align="center" color="dimmed">
      No messages to display
    </Text>
  </Stack>
);

export const ChatBox = <T extends unknown>(props: ChatBoxProps<T>) => {
  const { isLoading, messages, NoMessageComponent, MessageComponent, scrollPosition = 'bottom', scrollAreaClass = '' } = props;
  const { stages = [] } = useContext(StagesContext);
  const viewport = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    if (messages.length === 0) return;
    const getPosition = () => {
      if (scrollPosition === 'top') return 0;
      if (scrollPosition === 'bottom') return viewport.current.scrollHeight;
      return scrollPosition;
    };
    const position = getPosition();
    const scrollToBottom = () => viewport.current.scrollTo({ top: position });
    scrollToBottom();
  }, [messages.length]);
  const getContent = () => {
    if (isLoading) {
      return <MessageSkeletons />;
    }
    if (messages.length === 0) {
      return NoMessageComponent ? <NoMessageComponent /> : <DefaultNoMessageComponent />;
    }
    return (
      <>
        {stages.length ? stages.map((stage, index) => {
          const isFirstStage = index === 0;
          const isLastStage = index === stages.length - 1;
          const messageComponents = [];

          if (isFirstStage) {
            messageComponents.push(messages.slice(0, stage.conversationIndexStart).map((message, messageIndex) => (
              <MessageComponent
                key={messageIndex}
                message={message}
                index={messageIndex}
              />
            )));
          }

          messageComponents.push((
            <StageContainer key={index} stage={stage}>
              {messages.slice(stage.conversationIndexStart, stage.conversationIndexEnd + 1).map((message, messageIndex) => (
                <MessageComponent
                  key={messageIndex + stage.conversationIndexStart}
                  message={message}
                  index={messageIndex + stage.conversationIndexStart}
                />
              ))}
            </StageContainer>
          ));
          // handle messages after stageEndIndex but before next stageStartIndex
          const nextStageStartIndex = stages[index + 1]?.conversationIndexStart;
          if (nextStageStartIndex) {
            messageComponents.push(messages.slice(stage.conversationIndexEnd + 1, nextStageStartIndex).map((message, messageIndex) => (
              <MessageComponent
                key={messageIndex + stage.conversationIndexEnd + 1}
                message={message}
                index={messageIndex + stage.conversationIndexEnd + 1}
              />
            )));
          }

          if (isLastStage) {
            messageComponents.push(messages.slice(stage.conversationIndexEnd + 1).map((message, messageIndex) => (
              <MessageComponent
                key={messageIndex + stage.conversationIndexEnd + 1}
                message={message}
                index={messageIndex + stage.conversationIndexEnd + 1}
              />
            )));
          }

          return messageComponents;
        }) : messages.map((message, index) => (
          <MessageComponent
            key={index}
            message={message}
            index={index}
          />
        ))}
      </>
    );
  };
  return (
    <ScrollArea
      className={classNames('scrolling-content', scrollAreaClass)}
      type={isLoading ? 'never' : 'hover'}
      viewportRef={viewport}
    >
      <Stack className="scrolling-inner">
        {getContent()}
      </Stack>
    </ScrollArea>
  );
};
