import { FetchEventSourceInit, fetchEventSource } from '@microsoft/fetch-event-source';
import { StudioApi } from './studioApi';

export abstract class CrestaGptApi {
  public static getHeaders(): {} {
    return {
      Authorization: `Basic ${btoa(process.env.REACT_APP_CRESTA_GPT_AUTH || '')}`,
      'Content-Type': 'application/json',
    };
  }

  public static async createChat(promptId: string): Promise<string> {
    const response = await fetch(`${StudioApi.getCrestaGptApiEndpoint()}/customers/${promptId}/chats`, {
      method: 'POST',
      headers: this.getHeaders(),
    });
    const data: { chat_id: string } = await response.json();
    return data.chat_id;
  }

  public static async sendMessage(message: {
    sid?: string,
    text: string,
    created_at: Date,
    speaker_role: string,
  }, promptId: string, chatId: string): Promise<string> {
    const response = await fetch(`${StudioApi.getCrestaGptApiEndpoint()}/customers/${promptId}/chats/${chatId}/messages`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(message),
    });
    const data: { message_id: string } = await response.json();
    return data.message_id;
  }

  public static async generateResponse(request: FetchEventSourceInit & {
    promptId: string, chatId: string
  }): Promise<void> {
    const { promptId, chatId } = request;
    return fetchEventSource(`${StudioApi.getCrestaGptApiEndpoint()}/customers/${promptId}/chats/${chatId}/generate`, {
      method: 'POST',
      headers: {
        ...this.getHeaders(),
        Accept: 'text/event-stream',
      },
      ...request,
    });
  }
}
