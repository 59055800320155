import React, { ReactElement, useCallback, useMemo } from 'react';
import { ModelScores } from '@cresta/web-client/dist/cresta/v1/studio/storage/modelbuilder/modelbuilder.pb';
import { EvaluationResultsSummaryDashboardTableRow } from 'models';
import Table, { ColumnsType } from 'antd/lib/table';
import { toPairsIn } from 'lodash';
import CopyableValue from 'components/CopyableValue';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Text } from '@mantine/core';
import { useSelector } from 'hooks/reduxHooks';
import { selectAllConcepts } from 'store/concept/selectors';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { selectDerivedQaTasks } from 'store/labelingTask/selectors';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import styles from './ModelEvaluationSummary.module.scss';
import { DataComparedTableCell } from './DataComparedTableCell';
import { getStatusAction } from './getStatusAction';

const AVERAGE_SCORES_KEY = 'weighted avg';

interface ModelEvaluationSummaryProps {
  qaTaskId?: string;
  modelScores: ModelScores | undefined;
  // If set, should comparison view.
  compareModelScores?: ModelScores;
  selectedModelName: string;
  // Should set with compareModelScores
  comparedModelName?: string;
  modelBuilderLink: string;
  loading: boolean;
}

const baseColumnConfig = {
  width: 120,
};

export function ModelEvaluationSummary({
  qaTaskId,
  modelScores,
  compareModelScores,
  selectedModelName,
  comparedModelName,
  modelBuilderLink,
  loading,
}: ModelEvaluationSummaryProps): ReactElement {
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const allConcepts: Concept[] = useSelector(selectAllConcepts);
  const getConceptIdByConceptTitle = (conceptTitle: string) => {
    const concept = allConcepts.find((c) => c.conceptTitle === conceptTitle);
    if (!concept) {
      return '';
    }
    return getId('concept', concept.name);
  };
  // handle retrieving qaTask if exists
  const qaTasks = useSelector(selectDerivedQaTasks);
  const getQATaskById = useCallback(
    (id: string) => qaTasks.find((t) => t.labelingTask.name === `${customerProfile}/labelingTasks/${id}`),
    [qaTasks],
  );
  const qaTask = qaTaskId ? getQATaskById(qaTaskId) : undefined;
  const reviewTask = qaTaskId ? getQATaskById(`augment-${qaTaskId}`) : undefined;

  const navigate = useNavigate();
  const summaryDataSource: EvaluationResultsSummaryDashboardTableRow[] = useMemo(() => {
    if (!modelScores || loading) return [];

    const testPairs = toPairsIn(modelScores.test).sort((a, b) => {
      if (a[0] === AVERAGE_SCORES_KEY) {
        return -1;
      }
      if (b[0] === AVERAGE_SCORES_KEY) {
        return 1;
      }
      return 0;
    });

    if (compareModelScores) {
      const comparedTestScoresMap = new Map(toPairsIn(compareModelScores.test));

      return testPairs.map((pair, index) => ({
        intent: pair[0],
        test: pair[1],
        test2: comparedTestScoresMap.get(pair[0]),
      }));
    }

    return testPairs.map((pair, index) => ({
      conceptId: getConceptIdByConceptTitle(pair[0]),
      intent: pair[0],
      test: pair[1],
    }));
  }, [modelScores, compareModelScores, loading]);

  const modelName = (model: string) => (
    <CopyableValue
      displayValue={`${model?.split(':')[1]?.substring(0, 6)}...`}
      copiedValue={model}
      tooltip={model}
    />
  );

  const columns: ColumnsType<EvaluationResultsSummaryDashboardTableRow> = useMemo(() => {
    const scoreColumns = (compareModelScores
      ? [
        {
          title: 'Precision',
          children: [
            {
              ...baseColumnConfig,
              title: () => modelName(selectedModelName),
              dataIndex: ['test', 'precision'],
              key: 'testPrecision',
              render: (data: number, row: EvaluationResultsSummaryDashboardTableRow) => <DataComparedTableCell originData={data} compareData={row?.test2?.precision} />,
            },
            {
              ...baseColumnConfig,
              title: () => modelName(comparedModelName),
              dataIndex: ['test2', 'precision'],
              key: 'test2Precision',
            },
          ],
        },
        {
          title: 'Recall',
          children: [
            {
              ...baseColumnConfig,
              title: () => modelName(selectedModelName),
              dataIndex: ['test', 'recall'],
              key: 'testRecall',
              render: (data: number, row: EvaluationResultsSummaryDashboardTableRow) => <DataComparedTableCell originData={data} compareData={row?.test2?.recall} />,
            },
            {
              ...baseColumnConfig,
              title: () => modelName(comparedModelName),
              dataIndex: ['test2', 'recall'],
              key: 'test2Recall',
            },
          ],
        },
        {
          title: 'F1',
          children: [
            {
              ...baseColumnConfig,
              title: () => modelName(selectedModelName),
              dataIndex: ['test', 'f1'],
              key: 'testF1',
              render: (data: number, row: EvaluationResultsSummaryDashboardTableRow) => <DataComparedTableCell originData={data} compareData={row?.test2?.f1} />,
            },
            {
              ...baseColumnConfig,
              title: () => modelName(comparedModelName),
              dataIndex: ['test2', 'f1'],
              key: 'test2F1',
            },
          ],
        },
        {
          title: 'Support',
          children: [
            {
              ...baseColumnConfig,
              title: () => modelName(selectedModelName),
              dataIndex: ['test', 'support'],
              key: 'testSupport',
              render: (data: number, row: EvaluationResultsSummaryDashboardTableRow) => (
                <Tooltip title={row?.test?.support === row?.test2?.support ? '' : 'Inconsistent test dataset. Open in Builder for further evaluation.'}>
                  <div
                    className={row?.test?.support === row?.test2?.support ? '' : styles.inconsistentSupport}
                    onClick={() => {
                      if (row?.test?.support === row?.test2?.support) {
                        return;
                      }
                      navigate(modelBuilderLink, { replace: true });
                    }}
                  >
                    <DataComparedTableCell
                      originData={data}
                      compareData={row?.test2?.support}
                      fixedLength={0}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              ...baseColumnConfig,
              title: () => modelName(comparedModelName),
              dataIndex: ['test2', 'support'],
              key: 'test2Support',
              render: (data: number, row: EvaluationResultsSummaryDashboardTableRow) => (
                <Tooltip title={row?.test?.support === row?.test2?.support ? '' : 'Inconsistent test dataset. Open in Builder for further evaluation.'}>
                  <div
                    className={row?.test?.support === row?.test2?.support ? '' : styles.inconsistentSupport}
                    onClick={() => {
                      if (row?.test?.support === row?.test2?.support) {
                        return;
                      }
                      navigate(modelBuilderLink, { replace: true });
                    }}
                  >
                    {data}
                  </div>
                </Tooltip>
              ),
            },
          ],
        },
      ]
      : [
        {
          width: 40,
          align: 'center' as const,
          title: 'Precision',
          dataIndex: ['test', 'precision'],
          key: 'testPrecision',
        },
        {
          width: 40,
          align: 'center' as const,
          title: 'Recall',
          dataIndex: ['test', 'recall'],
          key: 'testRecall',
        },
        {
          width: 40,
          align: 'center' as const,
          title: 'Support',
          dataIndex: ['test', 'support'],
          key: 'testSupport',
        },
        {
          width: 40,
          align: 'center' as const,
          title: 'F1',
          dataIndex: ['test', 'f1'],
          key: 'testF1',
          render: (data: number) => (
            <Text color={data >= 0.7 ? 'green' : 'red'}>{data}</Text>
          ),
        },
        {
          width: 150,
          title: 'Recommended Actions',
          render: (_, row: EvaluationResultsSummaryDashboardTableRow) => {
            if (row.intent === 'weighted avg') return null;
            const conceptData = {
              conceptId: row.conceptId as string,
              f1: row.test.f1,
            };
            const { recommendedAction } = getStatusAction(conceptData, customer, qaTask, reviewTask);
            return <Text>{recommendedAction}</Text>;
          },
        },
        {
          width: 150,
          title: 'Status',
          dataIndex: ['test', 'f1'],
          render: (_, row: EvaluationResultsSummaryDashboardTableRow) => {
            if (row.intent === 'weighted avg') return null;
            const conceptData = {
              conceptId: row.conceptId as string,
              f1: row.test.f1,
            };
            const { status } = getStatusAction(conceptData, customer, qaTask, reviewTask);
            return status;
          },
        },
      ]);

    return [
      {
        title: 'Intent',
        dataIndex: 'intent',
        key: 'intent',
        fixed: 'left',
        width: compareModelScores ? 240 : 120,
        render: (intent: string) => (
          <div className="tableIntent">{intent}</div>
        ),
      },
      ...scoreColumns,
    ];
  }, [modelScores, compareModelScores, qaTask?.labelingTask?.abstractTask?.status, reviewTask?.labelingTask?.abstractTask?.status]);

  return (
    <Table
      className={styles.evaluationResultsTable}
      columns={columns}
      dataSource={summaryDataSource}
      rowKey="intent"
      rowClassName={(row) => (row?.intent === AVERAGE_SCORES_KEY ? styles.averageScoresRow : '')}
      scroll={{ x: 1440 }}
      loading={loading}
      pagination={false}
    />
  );
}
