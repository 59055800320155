import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { StopOutlined } from '@ant-design/icons';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { WorkflowStatus } from '@cresta/web-client/dist/cresta/v1/studio/workflows/workflows.pb';
import React from 'react';
import dayjs from 'dayjs';
import CopyableValue from 'components/CopyableValue';
import styles from './styles.module.scss';

interface Props {
  onClickPreviewEval: (workflowName: string) => void;
  modelWorkflows: ModelWorkflow[]
}

const STATUS_MAP = {
  WORKFLOW_STATUS_UNSPECIFIED: 'Unspecified',
  WORKFLOW_ACTIVE: 'In progress',
  WORKFLOW_INACTIVE: 'Inactive',
  WORKFLOW_ACCEPTED: 'Accepted',
  WORKFLOW_FAILED: 'Failed',
  WORKFLOW_SUCCEEDED: 'Trained',
};

export function TrainingDashboard({ onClickPreviewEval, modelWorkflows }: Props) {
  const columns: ColumnsType<ModelWorkflow> = [
    {
      title: 'Model',
      dataIndex: ['abstractWorkflow', 'title'],
      width: 100,
      key: 'model',
      render: (model: string) => (
        <div className={styles.modelName}>{model}</div>
      ),
    },
    {
      title: 'URL',
      dataIndex: ['trainIntentModelOutput', 'modelUrl'],
      key: 'url',
      render: (name: string) => (name ? <CopyableValue copiedValue={name} displayValue={name.split(':').at(-1).substring(0, 6)} /> : 'generating url'),
    },
    {
      title: 'f1',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'f1'],
      key: 'f1',
      render: (f1: number | undefined) => (
        <div>{f1 ?? '--'}</div>
      ),
    },
    {
      title: 'Precision',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'precision'],
      key: 'precision',
      render: (precision: number | undefined) => (
        <div>{precision ?? '--'}</div>
      ),
    },
    {
      title: 'Recall',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'recall'],
      key: 'recall',
      render: (recall: number | undefined) => (
        <div>{recall ?? '--'}</div>
      ),
    },
    {
      title: 'Support',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'support'],
      key: 'support',
      render: (support: number | undefined) => (
        <div>{support ?? '--'}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: ['abstractWorkflow', 'status'],
      key: 'status',
      render: (status: WorkflowStatus, record: ModelWorkflow) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={status === WorkflowStatus.WORKFLOW_FAILED && record.abstractWorkflow.failedDetails.message}>
            <div className={`${styles.statusPoint} ${styles[status === 'WORKFLOW_SUCCEEDED' ? 'statusPoint__t' : 'statusPoint__i']}`} />
            {STATUS_MAP[status]}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Last updated',
      dataIndex: ['abstractWorkflow', 'updateTime'],
      key: 'updateTime',
      render: (updateTime: string | undefined) => (
        <div>{updateTime ? dayjs(updateTime).format('MM/DD/YYYY h:mm a') : '--'}</div>
      ),
    },
    {
      title: 'Action',
      key: 'name',
      dataIndex: ['abstractWorkflow', 'status'],
      render: (status: WorkflowStatus, row: ModelWorkflow) => {
        if (status === WorkflowStatus.WORKFLOW_SUCCEEDED) {
          return (
            <button
              type="button"
              className={styles.previewEvalButton}
              onClick={() => {
                onClickPreviewEval(row.name);
              }}
            >
              See eval
            </button>
          );
        }
        if (status === WorkflowStatus.WORKFLOW_ACTIVE) {
          return (
            <div style={{ color: '#757575' }}>
              Training...
            </div>
          );
        }
        return <StopOutlined />;
      },
    },
  ];

  return (
    <div className={styles.dashboard}>
      <div className={styles.trainingDashboardTitle}>
        Validate intents for training
      </div>
      <div className={styles.dashboardSndTilte}>
        Results may take up to 2 hours. You will receive a notification via Slack when ready.
      </div>
      <div className={styles.tableDiv}>
        <Table
          columns={columns}
          dataSource={modelWorkflows}
          rowKey={(row: ModelWorkflow) => `${row.name}`}
        />
      </div>

    </div>
  );
}
