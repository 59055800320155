import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { subtract } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import styles from './DataComparedTableCell.module.scss';

interface Props {
  originData: number;
  compareData: number|undefined;
  fixedLength?: number;
}

export function DataComparedTableCell({
  originData,
  compareData,
  fixedLength = 2,
}: Props): ReactElement {
  const subtractIndex: string = useMemo(
    () => subtract(originData, compareData).toFixed(fixedLength),
    [originData, compareData, fixedLength],
  );

  if (compareData === undefined) {
    return (
      <div>{originData}</div>
    );
  }

  return (
    <div className={styles.dataComparedTableCell}>
      <div>{originData}</div>
      <span className={styles.spacer} />
      <div
        className={
          originData > compareData ? styles.biggerData : styles.smallerData
        }
        hidden={originData === compareData}
      >
        {originData > compareData ? <CaretUpOutlined /> : <CaretDownOutlined />}
        {Math.abs(Number(subtractIndex))}
      </div>
    </div>
  );
}
