import React, { useState } from 'react';
import { Button, Group, Stack, Alert, Box, Radio, Flex, Text } from '@mantine/core';
import { SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { SelectCSVButton } from 'components/SelectCSVButton';
import { SetTitleInput } from './components/SetTitleInput';
import { transformSingleColumnCSVData } from './utils';

interface AlertTextProps {
  setType: SetType;
  count: number;
}

const AlertText = ({ setType, count }: AlertTextProps) => {
  let text = '';
  if (setType === SetType.CONVERSATION_SET_TYPE) {
    text = 'conversations selected currently';
  } else {
    text = 'message selected currently';
  }

  return (
    <Alert px={10} py={6} color={count === 0 ? 'red' : 'gray'}>
      <b>{count}</b> {text}
    </Alert>
  );
};

interface SetTypeRadioProps {
  setType: SetType;
  setSetType: (value: SetType) => void;
  conversationOnly?: boolean;
}

const SetTypeRadio = ({ setType, setSetType, conversationOnly }: SetTypeRadioProps) => (
  <Radio.Group
    label="Set type"
    value={setType}
    onChange={(value) => {
      if (!conversationOnly) {
        setSetType(value as SetType);
      }
    }}
  >
    <Group>
      <Radio value={SetType.CONVERSATION_SET_TYPE} label="Conversation" />
      <Radio value={SetType.MESSAGE_SET_TYPE} label="Message" disabled={conversationOnly} />
    </Group>
  </Radio.Group>
);

interface ImportCSVTabProps {
  setTitle: string;
  setSetTitle: (value: string | React.ChangeEvent<any>) => void;
  onClose: () => void;
  onCreate: (setItemNames: string[]) => Promise<void>;
  setType: SetType;
  setSetType: (value: SetType) => void;
}

export const ImportCSVTab = ({
  setTitle,
  setSetTitle,
  onClose,
  onCreate,
  setType,
  setSetType,
}: ImportCSVTabProps) => {
  const [isCreatingSet, toggleCreatingSet] = useState(false);
  // Conversations or messages imported from CSV
  const [importedIds, setImportedIds] = useState<string[]>([]);

  const handleClose = () => {
    onClose();
    setSetTitle('');
    setImportedIds([]);
  };

  const handleCreateSet = async () => {
    toggleCreatingSet(true);
    try {
      await onCreate(importedIds);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      toggleCreatingSet(false);
      handleClose();
    }
  };

  const count = importedIds.length;
  return (
    <Stack>
      <Box>
        <SelectCSVButton
          tooltipMessage={(
            <Flex direction="column">
              <Text size="xs">Accepts single column of resource names</Text>
            </Flex>
          )}
          handleRows={(rows) => {
            const arrayOfIds = transformSingleColumnCSVData(rows);
            return setImportedIds(arrayOfIds);
          }}
        />
      </Box>
      <AlertText setType={setType} count={count} />
      <SetTypeRadio setType={setType} setSetType={setSetType} />
      <SetTitleInput disabled={importedIds.length === 0} value={setTitle} onChange={setSetTitle} />
      <Group position="right">
        <Button onClick={handleClose} variant="subtle" disabled={isCreatingSet}>
          Cancel
        </Button>
        <Button
          loading={isCreatingSet}
          disabled={!setTitle || importedIds.length === 0}
          onClick={handleCreateSet}
        >
          Create Set
        </Button>
      </Group>
    </Stack>
  );
};
