import React, { useMemo } from 'react';
import { DataGrid } from 'components/DataGrid';
import { createColumnHelper } from '@tanstack/react-table';
import { useProdAgents } from '../../../useProdAgents';
import { ConversationCounts, useFoundConversations } from './useFoundConversations';

interface Props {
  agentIds: string[];
  dateStart: Date;
  dateEnd: Date;
  splitRatioTrain: number;
  testSetDaysSuffix: number;
}

const getTrainSplit = (trainCount: number, splitRatio: number) => Math.round(trainCount * splitRatio);
const getEvalSplit = (trainCount: number, splitRatio: number) => Math.round(trainCount * (1 - splitRatio));

export const FoundConversationsTable = (props: Props) => {
  const { agentIds, dateStart, dateEnd, splitRatioTrain, testSetDaysSuffix } = props;

  const splitRatioTrainFloat = splitRatioTrain === 0 ? 0 : splitRatioTrain / 100;

  const agents = useProdAgents();

  const [dataSource, isLoading] = useFoundConversations({
    agentIds,
    dateStart,
    dateEnd,
    testSetDaysSuffix,
  });
  const columnHelper = createColumnHelper<ConversationCounts>();

  const totalTrainCount = useMemo(() => dataSource.reduce((acc, row) => acc + getTrainSplit(row.trainCount, splitRatioTrainFloat), 0), [dataSource, splitRatioTrainFloat]);
  const totalEvalCount = useMemo(() => dataSource.reduce((acc, row) => acc + getEvalSplit(row.trainCount, splitRatioTrainFloat), 0), [dataSource, splitRatioTrainFloat]);
  const totalTestCount = useMemo(() => dataSource.reduce((acc, row) => acc + row.testCount, 0), [dataSource]);
  const columns = [
    columnHelper.accessor('agentId', {
      header: 'Agent',
      footer: 'Totals',
      cell: (info) => {
        const agentId = info.getValue();
        return agents.find((agent) => agent.value === agentId)?.label || agentId;
      },
    }),
    columnHelper.accessor('trainCount', {
      header: 'Train',
      footer: () => totalTrainCount,
      cell: (info) => {
        const trainCount = info.getValue();
        return getTrainSplit(trainCount, splitRatioTrainFloat);
      },
    }),
    columnHelper.accessor('trainCount', {
      header: 'Eval',
      footer: () => totalEvalCount,
      cell: (info) => {
        const trainCount = info.getValue();
        return getEvalSplit(trainCount, splitRatioTrainFloat);
      },
    }),
    columnHelper.accessor('testCount', {
      header: 'Test',
      footer: () => totalTestCount,
      cell: (info) => {
        const testCount = info.getValue();
        return testCount;
      },
    }),
  ];

  return (
    <DataGrid
      isLoading={isLoading}
      columns={columns}
      dataSource={dataSource}
    />
  );
};
