// Shared component to add concepts that only require a display name.
import React, { ReactElement, useRef, useState, useMemo, useCallback } from 'react';
import { HintTimingRuntimeConfig } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { SpeakerRole } from '@cresta/web-client/dist/cresta/v1/common/enums/enums.pb';
import { Modal, Input, Button, Flex, ActionIcon, Space } from '@mantine/core';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import Editor, { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import './HintTimingModelConfigEditor.scss';

const CONFIG_PLACEHOLDER: HintTimingRuntimeConfig = {
  hintTimings: [
    {
      intentTaxonomy: '',
      hintTimingDefinition: {
        partialChatStartingIntentTaxonomy: '',
        filterSpeakerRole: SpeakerRole.AGENT,
        partialChatMaxMessageCount: 0,
      },
    },
  ],
};

interface HintTimingModelConfigEditorProps {
  opened?: boolean;
  hintModelUriToConfig?: { [key: string]: HintTimingRuntimeConfig };
  onClose: (hintModelUriToConfig?: { [key: string]: HintTimingRuntimeConfig }) => void;
}

interface EditorButtonProps {
  value?: { [key: string]: HintTimingRuntimeConfig };
  onChange?: (value: { [key: string]: HintTimingRuntimeConfig }) => void;
}

export function EditorButton({
  value,
  onChange,
}: EditorButtonProps): ReactElement {
  const [opened, setOpened] = useState<boolean>(false);
  const handleClose = useCallback((hintModelUriToConfig?: { [key: string]: HintTimingRuntimeConfig }) => {
    if (onChange) {
      onChange(hintModelUriToConfig || {});
    }
    setOpened(false);
  }, [onChange]);
  return (
    <>
      <Button
        variant="outline"
        onClick={() => setOpened(true)}
      >
        {value && Object.keys(value).length > 0 ? 'Edit' : 'Add'} Hint Timing Model Config
      </Button>
      <HintTimingModelConfigEditor
        opened={opened}
        hintModelUriToConfig={value}
        onClose={handleClose}
      />
    </>
  );
}

interface HintTiming {
  key: string;
  hintModelUri: string;
  config: string;
}

export function HintTimingModelConfigEditor({
  opened,
  hintModelUriToConfig,
  onClose,
}: HintTimingModelConfigEditorProps): ReactElement {
  const editorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const hintTimingModels = useMemo(() => {
    const results: HintTiming[] = [];
    if (hintModelUriToConfig) {
      Object.keys(hintModelUriToConfig).forEach((hintModelUri) => {
        results.push({
          key: uuidv4(),
          hintModelUri,
          config: JSON.stringify(hintModelUriToConfig[hintModelUri], null, 4),
        });
      });
    }
    return results;
  }, [hintModelUriToConfig]);
  const [configs, setConfigs] = useState<HintTiming[]>(hintTimingModels);
  const [focusedKey, setFocusedKey] = useState<string>('');
  const [isEditingURI, setIsEditingURI] = useState<boolean>(false);
  const [uri, setUri] = useState<string>('');
  const [config, setConfig] = useState<string>('');

  const handleEditorDidMount = useCallback((editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
    editorRef.current = editor;
  }, [editorRef]);

  const handleEnterURI = useCallback((key: string) => {
    const index = configs.findIndex((hintTiming) => hintTiming.key === key);
    if (index >= 0) {
      configs[index].hintModelUri = uri;
      setConfigs([...configs]);
    }
    setIsEditingURI(false);
  }, [uri, configs]);

  const handleSaveConfig = useCallback(() => {
    const index = configs.findIndex((hintTiming) => hintTiming.key === focusedKey);
    if (index >= 0) {
      configs[index].config = editorRef.current?.getValue() || '';
      setConfigs([...configs]);
    }
  }, [configs, focusedKey]);

  const handleDeleteURI = useCallback((key: string) => {
    setConfigs([...configs.filter((hintTiming) => hintTiming.key !== key)]);
  }, [configs]);

  const handleFocusURI = useCallback((key: string) => {
    handleSaveConfig();
    setIsEditingURI(false);
    setFocusedKey(key);
    const hintTiming = configs.find((c) => c.key === key);
    if (hintTiming) {
      setUri(hintTiming.hintModelUri);
      setConfig(hintTiming.config);
    }
  }, [configs, handleSaveConfig]);

  const handleAddURI = useCallback(() => {
    handleSaveConfig();
    const key = uuidv4();
    setConfigs([...configs, { key, hintModelUri: '', config: '' }]);
    setFocusedKey(key);
    setUri('');
    setConfig(JSON.stringify(CONFIG_PLACEHOLDER, null, 4));
    setIsEditingURI(true);
  }, [configs, handleSaveConfig]);

  const handleClose = useCallback(() => {
    const index = configs.findIndex((hintTiming) => hintTiming.key === focusedKey);
    const value = editorRef.current?.getValue() || '';
    if (index >= 0) {
      configs[index].config = value;
      setConfigs([...configs]);
      setConfig(value);
    }
    const hintModelUriToConfig: { [key: string]: HintTimingRuntimeConfig } = {};
    configs.forEach((hintTiming) => {
      hintModelUriToConfig[hintTiming.hintModelUri] = JSON.parse(hintTiming.config);
    });
    onClose(hintModelUriToConfig);
  }, [configs, focusedKey, onClose]);

  return (
    <Modal
      title="Edit hint timing model config"
      opened={opened}
      onClose={handleClose}
      size="1200px"
    >
      <div className="hint-timing-model-config-editor">
        <Flex direction="row">
          <div className={classNames(['hint-timing-model-uris'])}>
            <Flex direction="column">
              {
                configs.map(({ key, hintModelUri }) => (
                  <Flex key={key} direction="row" align="center">
                    {focusedKey === key && isEditingURI ? (
                      <Input
                        placeholder="Enter hint timing model URI"
                        value={uri}
                        onChange={(e) => setUri(e.target.value)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleEnterURI(key);
                          }
                        }}
                      />
                    ) : (
                      <div
                        className={classNames([
                          'hint-timing-model-uri',
                          focusedKey === key && 'focused',
                        ])}
                        onClick={() => {
                          handleFocusURI(key);
                        }}
                      >
                        {hintModelUri}
                      </div>
                    )}
                    <Space w="sm" />
                    <ActionIcon
                      variant="light"
                      color="blue"
                      onClick={() => {
                        setIsEditingURI(true);
                      }}
                      disabled={focusedKey === key && isEditingURI}
                    ><EditOutlined />
                    </ActionIcon>
                    <Space w="sm" />
                    <ActionIcon
                      variant="light"
                      color="red"
                      onClick={() => {
                        handleDeleteURI(key);
                      }}
                    ><DeleteOutlined />
                    </ActionIcon>
                  </Flex>
                ))
              }
              <Space h="sm" />
              <ActionIcon
                variant="light"
                color="blue"
                onClick={handleAddURI}
              ><PlusOutlined />
              </ActionIcon>
            </Flex>
          </div>
          <Editor
            height="760px"
            path={focusedKey}
            defaultLanguage="json"
            defaultValue={config}
            onMount={handleEditorDidMount}
            options={{
              minimap: {
                enabled: false,
              },
            }}
          />
        </Flex>
      </div>
    </Modal>
  );
}
