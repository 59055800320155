import React, { FunctionComponent, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { useTextSelection } from './useTextSelection';

type Props = {
  target?: HTMLElement
  mount?: HTMLElement
  render: FunctionComponent<ReturnType<typeof useTextSelection>>
}

function Portal(props: PropsWithChildren<{mount?: HTMLElement}>) {
  const { mount, children } = props;
  return createPortal(
    children,
    mount || document.body,
  );
}

export function Popover(props: PropsWithChildren<Props>) {
  const { render: Render, mount, target } = props;
  const {
    clientRect,
    isCollapsed,
    textContent,
  } = useTextSelection(target);

  return (
    <Portal mount={mount}>
      <Render
        clientRect={clientRect}
        isCollapsed={isCollapsed}
        textContent={textContent}
      />
    </Portal>
  );
}
