import React from 'react';
import { Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';

interface Props {
    updateTime: string;
}

export const AccordionSubheader = (props: Props) => {
  const { updateTime } = props;
  return (
    <Group>
      <Text color="dimmed">Last updated: </Text>
      <Text>{dayjs(updateTime).format(DATE_TIME_FORMAT)}</Text>
    </Group>
  );
};
