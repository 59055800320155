import * as FullStory from '@fullstory/browser';
import { datadogLogs } from '@datadog/browser-logs';
import mixpanel from 'mixpanel-browser';
import { UserWithId } from 'context/UserContext';
import CONFIG from './index';

// @ts-ignore
const isProduction = process.env.NODE_ENV === 'production' && window.Cypress === undefined;

const initializeFullstory = () => {
  FullStory.init(
    {
      orgId: 'A0W9W',
      namespace: 'FS',
    },
    () => {
      const FSUrl = FullStory.getCurrentSessionURL();
      console.info('%cFullStory session initialized', 'background-color: #005f20', FSUrl);
      // Add FS session to datadog
      datadogLogs.addLoggerGlobalContext('FullStoryUrl', FSUrl);
    },
  );
};

const initializeDatadog = () => {
  datadogLogs.init({
    clientToken: CONFIG.datadog.clientToken,
    site: CONFIG.datadog.applicationId,
    service: 'studio-frontend',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
};

export const initializeMonitoring = () => {
  mixpanel.init(CONFIG.mixpanel.key, { debug: true });

  if (isProduction) {
    initializeDatadog();
    initializeFullstory();
  } else {
    mixpanel.disable();
  }
};

export const setMonitoringIdentity = (user: UserWithId) => {
  if (isProduction) {
    // Set user for FullStory
    FullStory.identify(user?.id, {
      displayName: user?.fullName,
      email: user?.email,
    });

    if (!FullStory.isInitialized) {
      initializeFullstory();
    } else {
      // If we don't call restart() FS init callback doesn't execute
      // and doesn't log the session url
      FullStory.restart();
    }

    // Set DataDog context
    datadogLogs.addLoggerGlobalContext('User', user?.fullName);
    datadogLogs.addLoggerGlobalContext('Email', user?.email);

    // Register Mixpanel user
    mixpanel.people.set({
      Email: String(user?.email).toLowerCase(),
      Role: user?.role,
      Name: user?.fullName,
    });
    mixpanel.register({
      Email: String(user?.email).toLowerCase(),
      Role: user?.role,
      Name: user?.fullName,
    });
    mixpanel.identify(user?.id);
  }
};

export const enableMonitoring = (user: UserWithId) => {
  // Check if FS is initialized
  if ((window as any).FS) {
    FullStory.restart();
    console.info('%cFullStory restarting', 'background-color: #005f20');
  } else {
    initializeFullstory();
  }
  // Set DataDog context
  datadogLogs.addLoggerGlobalContext('User', user?.fullName);
  datadogLogs.addLoggerGlobalContext('Email', user?.email);
};

// Disable monitoring and anonymize error logging
export const disableMonitoring = () => {
  // Check if FS is initialized
  if ((window as any).FS) {
    console.info('%cFullStory shutting down', 'background-color: #4a4a4a');
    FullStory.shutdown();
  }
  datadogLogs.removeLoggerGlobalContext('User');
  datadogLogs.removeLoggerGlobalContext('Email');
  datadogLogs.removeLoggerGlobalContext('FullStoryUrl');
};
