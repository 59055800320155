import { createSlice } from '@reduxjs/toolkit';
import {
  sendNotification,
} from './asyncThunks';
import { sendNotificationSuccess, sendNotificationFailed, sendNotificationPending } from './reducers';
import { INITIAL_STATE } from './state';

/** Notification slice. */
export const notificationSlice = createSlice({
  name: 'notification',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(sendNotification.pending, sendNotificationPending)
      .addCase(sendNotification.rejected, sendNotificationFailed)
      .addCase(sendNotification.fulfilled, sendNotificationSuccess),
});
