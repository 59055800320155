import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Radio, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import { FormInstance } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Checkbox as MantineCheckBox, Group, Stack, Tooltip, Flex } from '@mantine/core';
import { HintTimingRuntimeConfig } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import useUrlParam from 'hooks/useUrlParam';
import { useSelector } from 'hooks/reduxHooks';
import { selectGoldenSet } from 'store/set/selectors';
import { selectLoadServingModelStatus } from 'store/modelBuilder/selectors';
import Loading from 'components/Loading';
import { EditorButton as HintTimingEditorButton } from './HintTimingModelConfigEditor';
import styles from './style.module.scss';

// ModelInputs form data interface.
export interface ModelInputData {
  agentURL?: string;
  hintModelUriToConfig?: { [key: string]: HintTimingRuntimeConfig };
  visitorURL?: string;
  chatDriverURL?: string;
  policyURL?: string;
  usePolicySnapshot?: 'master' | 'draft' | '';
  ensembleURL?: string; // For prod model only.
  useActionStack?: boolean;
  orchestratorImage?: string;
  intentImage?: string;
  policyImage?: string;
  actionImage?: string;
  hintTimingImage?: string;
}

type ModelInputFieldName = keyof ModelInputData;

interface ModelInputField {
  name: ModelInputFieldName;
  label: string;
  placeholder?: string;
}

export const MODEL_URI_FIELDS: ModelInputField[] = [
  {
    name: 'agentURL',
    label: 'Agent model URL',
  },
  {
    name: 'visitorURL',
    label: 'Visitor model URL',
  },
  {
    name: 'chatDriverURL',
    label: 'Driver model URL',
  },
];

export const ORCH_STACK_IMAGE_FIELDS: ModelInputField[] = [
  {
    name: 'orchestratorImage',
    label: 'Image Overrides (optional)',
    placeholder: 'Orchestrator image tag',
  },
  {
    name: 'intentImage',
    label: '',
    placeholder: 'Intent image tag',
  },
  {
    name: 'policyImage',
    label: '',
    placeholder: 'Policy image tag',
  },
  {
    name: 'hintTimingImage',
    label: '',
    placeholder: 'Hint timing image tag',
  },
];

export const ACTION_STACK_IMAGE_FIELDS: ModelInputField[] = [
  {
    name: 'orchestratorImage',
    label: 'Image Overrides (optional)',
    placeholder: 'Orchestrator image tag',
  },
  {
    name: 'actionImage',
    label: '',
    placeholder: 'Action image tag',
  },
];

interface IProps {
  handleRegressionTestModeBtnClick: () => void;
  firstForm: FormInstance<ModelInputData>;
  secondForm: FormInstance<ModelInputData>;
  buttonLoading: boolean;
  disableAll?: boolean;
  isInRegressionTestMode?: boolean;
  handleUseProd: (firstModel: boolean) => void;
  compareModel: boolean;
  setCompareModel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModelSetUp: React.FC<IProps> = ({
  handleRegressionTestModeBtnClick,
  firstForm,
  secondForm,
  buttonLoading,
  disableAll,
  isInRegressionTestMode,
  handleUseProd,
  compareModel,
  setCompareModel,
}) => {
  const [editingMode, setEditingMode] = useUrlParam<string>('editingMode');
  const [showFirstSettings, setShowFirstSettings] = useState<boolean>(false);
  const firstActionStack = Form.useWatch<boolean>('useActionStack', firstForm);
  const firstPolicySnapshot = Form.useWatch<'master' | 'draft' | ''>('usePolicySnapshot', firstForm);
  const [showSecondSettings, setShowSecondSettings] = useState<boolean>(false);
  const secondActionStack = Form.useWatch<boolean>('useActionStack', secondForm);
  const secondPolicySnapshot = Form.useWatch<'master' | 'draft' | ''>('usePolicySnapshot', secondForm);
  const goldenSet = useSelector(selectGoldenSet);
  const loadingProdModels = useSelector(selectLoadServingModelStatus) === 'loading';

  // returns true if no golden set is found
  const isGoldenSetEmpty = !(goldenSet?.itemCount > 0);

  useEffect(() => {
    if (isInRegressionTestMode) {
      setCompareModel(true);
    }
  }, [isInRegressionTestMode]);

  const compareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompareModel(event.target.checked);
    if (event.target.checked) {
      setEditingMode('');
    }
  };

  const handleEditingModeChange = useCallback((event: RadioChangeEvent) => {
    if (event.target.value) {
      firstForm.setFieldsValue({ policyURL: '' });
    }
    setEditingMode(event.target.value);
  }, [setEditingMode, firstForm.setFieldsValue]);

  useEffect(() => {
    if (firstPolicySnapshot) {
      firstForm.setFieldsValue({ policyURL: '' });
    }
  }, [firstPolicySnapshot]);

  useEffect(() => {
    if (secondPolicySnapshot) {
      secondForm.setFieldsValue({ policyURL: '' });
    }
  }, [secondPolicySnapshot]);

  const formComponent = useCallback((first: boolean, showSettings: boolean, useActionStack: boolean, useDialoguePolicy: 'master' | 'draft' | '') => {
    const isDisabled = first && isInRegressionTestMode;
    return (
      <div style={{ position: 'relative' }}>
        {compareModel && (
          <div className={styles.simulatorSetModelCompare}>
            <span className={styles.simulatorSetModelCompareTitle}>Model {first ? '1' : '2'}</span>
            {!isInRegressionTestMode && <Button disabled={isDisabled} loading={loadingProdModels} variant="light" compact onClick={() => handleUseProd(first)}>Use prod</Button>}
          </div>
        )}
        {compareModel && (
          <div className={styles.simulatorSetModelFormTitle}>
            Enter Model URLs
          </div>
        )}
        <div className={styles.simulatorSetModelForm}>
          {MODEL_URI_FIELDS.map((item) => (
            <Form.Item
              key={item.name}
              className={styles.simulatorSetModelFormItem}
              name={item.name}
              label={item.label}
            >
              <TextArea disabled={buttonLoading || disableAll || isDisabled} allowClear placeholder="Enter model URL" />
            </Form.Item>
          ))}
          <Form.Item
            key="policyURL"
            className={classNames([styles.simulatorSetModelFormItem, styles.policyURLItem])}
            name="policyURL"
            label="Policy model URL"
          >
            <TextArea
              disabled={buttonLoading || disableAll || !!editingMode || !!useDialoguePolicy || isDisabled}
              allowClear
              placeholder="Enter model URL"
            />
          </Form.Item>
          {!compareModel && (
            <Radio.Group
              className={styles.toggleDraftPolicy}
              value={editingMode}
              onChange={handleEditingModeChange}
              disabled={isDisabled}
            >
              <Radio value="">URL</Radio>
              <Radio value="master">Master</Radio>
              <Radio value="draft">Draft</Radio>
            </Radio.Group>
          )}
          {compareModel && (
            <Form.Item
              className={styles.toggleDraftPolicy}
              name="usePolicySnapshot"
              help={useDialoguePolicy ? 'Editing dialog policy in the results view is not available in comparison mode.' : null}
              validateStatus="warning"
            >
              <Radio.Group
                className={styles.toggleDraftPolicy}
                disabled={isDisabled}
              >
                <Radio value="">URL</Radio>
                <Radio value="master">Master</Radio>
                <Radio value="draft">Draft</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Button
            className={styles.simulatorShowTagInput}
            variant="subtle"
            onClick={() => {
              if (first) {
                if (isInRegressionTestMode) return;
                setShowFirstSettings(!showSettings);
              } else {
                setShowSecondSettings(!showSettings);
              }
            }}
            disabled={isDisabled}
          >
            {showSettings ? 'Hide' : 'Show'} advanced settings
          </Button>
          {showSettings && (
            <>
              <Form.Item
                className={styles.simulatorSetModelFormItem}
                name="useActionStack"
                valuePropName="checked"
              >
                <Checkbox disabled={isDisabled}>Use action server stack</Checkbox>
              </Form.Item>
              <Form.Item
                className={styles.simulatorSetModelFormItem}
                name="hintModelUriToConfig"
              >
                <HintTimingEditorButton />
              </Form.Item>
              {(useActionStack ? ACTION_STACK_IMAGE_FIELDS : ORCH_STACK_IMAGE_FIELDS).map((imageItem) => (
                <Form.Item
                  key={imageItem.name}
                  className={styles.simulatorSetModelFormItem}
                  name={imageItem.name}
                  label={imageItem.label}
                >
                  <Input
                    disabled={buttonLoading || disableAll || isDisabled}
                    allowClear
                    placeholder={imageItem.placeholder}
                  />
                </Form.Item>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }, [
    buttonLoading,
    disableAll,
    isInRegressionTestMode,
    compareModel,
    editingMode,
    loadingProdModels,
  ]);

  return (
    <div className={styles.simulatorSetModel}>
      <div className={styles.simulatorSetModelTitle}>
        <span className={styles.simulatorSetModelTitleText}>Model Setup</span>

      </div>
      <div className={styles.simulatorSetModelError}>
        <Stack>
          <Tooltip label="No golden set available" disabled={!isGoldenSetEmpty}>
            <Flex direction="row">
              <MantineCheckBox
                label="Run regression test"
                checked={isInRegressionTestMode}
                onChange={handleRegressionTestModeBtnClick}
                disabled={isGoldenSetEmpty || loadingProdModels}
              />
              {loadingProdModels && <Loading />}
            </Flex>
          </Tooltip>
          <MantineCheckBox label="Compare two models" checked={compareModel} onChange={compareChange} disabled={isInRegressionTestMode} />
        </Stack>
      </div>
      <div className={styles.simulatorSetModelFormWrapper}>
        {!compareModel && (
          <Group position="apart">
            <div className={styles.simulatorSetModelFormTitle}>
              Enter Model URLs
            </div>
            {!buttonLoading && (
              <Button loading={loadingProdModels} onClick={() => handleUseProd(true)} variant="light" compact>Use prod</Button>
            )}
          </Group>
        )}
        <Form
          form={firstForm}
          layout="vertical"
        >
          {formComponent(true, showFirstSettings, firstActionStack, firstPolicySnapshot)}
        </Form>
        {(compareModel || isInRegressionTestMode) && (
          <Form
            form={secondForm}
            layout="vertical"
          >
            {formComponent(false, showSecondSettings, secondActionStack, secondPolicySnapshot)}
          </Form>
        )}
      </div>
    </div>
  );
};
