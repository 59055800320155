import { useEffect, useState } from 'react';
import { Conversation as StudioConversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { mapToStudioConversation, ProdConversationApi } from 'services/prodConversationApi';

export const useConversationData = (conversationName:string, deps?: any[]): [StudioConversation, boolean, string] => {
  const [conversationData, setConversationData] = useState<StudioConversation>();
  const [isLoading, toggleLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!conversationName) {
      setError('No conversation name provided');
      toggleLoading(false);
      return;
    }
    toggleLoading(true);
    try {
      const getConversationData = async (conversationName: string) => {
        const fetchedConversationData = await ProdConversationApi.getConversation({
          name: conversationName,
        });

        setConversationData(mapToStudioConversation(fetchedConversationData.conversation));
        toggleLoading(false);
      };

      getConversationData(conversationName);
    } catch (e) {
      setError(e);
      toggleLoading(false);
    }
  }, [conversationName, ...deps]);

  return [conversationData, isLoading, error];
};
