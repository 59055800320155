import React from 'react';
import { Button, Center, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

interface ConceptNotFoundProps {
    conceptName: string;
}

export const ConceptNotFound = (props: ConceptNotFoundProps) => {
  const { conceptName } = props;
  const navigate = useNavigate();
  return (
    <Center style={{ width: '100%' }}>
      <Stack align="center">
        <Title order={2}>Error: Concept not found</Title>
        <Text color="red">{conceptName}</Text>
        <Button variant="outline" onClick={() => navigate(-1)}>Back</Button>
      </Stack>
    </Center>
  );
};
