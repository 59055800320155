import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as AppState } from './state';

const selectApp = (state: State) => state.app;

/** Select synthetic labels modal object */
export const selectSyntheticLabelsModal = createSelector(selectApp, (appState: AppState) => appState.syntheticLabelsModal);

/** Debugging mode boolean */
export const selectDebuggingMode = createSelector(selectApp, (appState: AppState) => appState.debuggingMode);

/** Select the customers for which task playlist should fetch tasks */
export const selectTaskPlaylistCustomers = createSelector(selectApp, (appState: AppState) => appState.taskPlaylistCustomers);

/** Select the map object mapping customers (namespaces) to the current user id in that customer */
export const selectCustomersCurrentUserIds = createSelector(selectApp, (appState: AppState) => appState.customersCurrentUserIds);
