/* eslint-disable no-nested-ternary */
import { CSSObject, MantineColor, MantineTheme, MantineThemeOverride, TextStylesParams } from '@mantine/core';

const PRIMARY_SHADE = 6;

// Get color from string or default to primary color
function getParamColor(color: string, theme: MantineTheme) {
  return theme.colors[color || theme.primaryColor];
}

interface GetTextColor {
  theme: MantineTheme;
  color: 'dimmed' | MantineColor;
}

// copied from https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Text/Text.styles.ts
// Required since our primary shade === dimmed shade (6)
const DIMMED_SHADE_OVERRIDE = 4;
function getTextColor({ theme, color }: GetTextColor) {
  if (color === 'dimmed') {
    return theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[DIMMED_SHADE_OVERRIDE];
  }
  // eslint-disable-next-line no-nested-ternary
  return typeof color === 'string' && (color in theme.colors || color.split('.')[0] in theme.colors)
    ? theme.fn.variant({ variant: 'filled', color }).background
    : color || 'inherit';
}

export const mantineTheme: MantineThemeOverride = {
  radius: {
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
    xl: '2rem',
  },
  defaultRadius: 'md',
  transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',

  fontFamily: 'Inter, Roboto, sans-serif',
  lineHeight: 1.55,
  fontSizes: {
    xs: '0.688rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
  },
  primaryShade: PRIMARY_SHADE,

  // Black text color
  black: '#212121',

  headings: {
    fontWeight: 600,
    sizes: {
      h1: { fontSize: '2rem', lineHeight: 1.5715, fontWeight: 500 },
      h2: { fontSize: '1.5rem', lineHeight: 1.5715, fontWeight: 500 },
      h3: { fontSize: '1.125rem', lineHeight: 1.5715, fontWeight: 500 },
      h4: { fontSize: '1rem', lineHeight: 1.5715, fontWeight: 'normal' },
      h5: { fontSize: '0.875rem', lineHeight: 1.5715, fontWeight: 'normal' },
      h6: { fontSize: '0.75rem', lineHeight: 1.5715, fontWeight: 'normal' },
    },
  },

  // Other theme variables
  other: {
    weightNormal: 400,
    weightBold: 500,
    weightExtraBold: 600,
    weightSuperBold: 700,
    transitionDuration: '0.3s',
  },
  colors: {
    // can also generate from https://mdigi.tools/color-shades/#304ffe
    blue: [
      '#F1F5FE',
      '#C1CAFF',
      '#98A7FF',
      '#6E84FE',
      '#5972FE',
      '#4561FE',
      '#304FFE',
      '#2B47E5',
      '#263FCB',
      '#2237B2',
    ],
    red: [
      '#FFE6E6',
      '#FFB8B8',
      '#FE8A8A',
      '#FE5D5D',
      '#FE2F2F',
      '#FE0101',
      '#CB0101',
      '#980101',
      '#660000',
      '#330000',
    ],
    green: [
      '#EDFBEA',
      '#CDF4C3',
      '#AEEC9D',
      '#8EE576',
      '#6EDD50',
      '#4ED629',
      '#3FAB21',
      '#2F8019',
      '#1F5610',
      '#102B08',
    ],
    gray: [
      '#f2f2f2',
      '#d9d9d9',
      '#bfbfbf',
      '#a6a6a6',
      '#8c8c8c',
      '#737373',
      '#595959',
      '#404040',
      '#262626',
      '#0d0d0d',
    ],
    orange: [
      '#fff1eb',
      '#ffd5c4',
      '#ffb99d',
      '#ff9d76',
      '#ff814e',
      '#ff6527',
      '#FF8A5B',
      '#ff4900',
      '#d83e00',
      '#b13300',
    ],
    grape: [
      '#f7eafb',
      '#e8bff2',
      '#d995ea',
      '#c96ae1',
      '#ba40d9',
      '#a026bf',
      '#7d1e95',
      '#59156a',
      '#350d40',
      '#120415',
    ],
    purple: [
      '#ece6fe',
      '#c7b5fc',
      '#a284fb',
      '#7d53f9',
      '#5822f7',
      '#3f08dd',
      '#3106ac',
      '#23047b',
      '#15034a',
      '#070119',
    ],
  },
  primaryColor: 'blue',
  components: {
    Loader: {
      styles: (theme) => ({
        svg: {
          opacity: 0.1,
        },
      }),
    },
    Select: {
      styles: (theme) => ({
        item: {
          // Applies styles to hovered item (with mouse or keyboard)
          '&[data-hovered]': {
            background: 'none',
            color: theme.colors.blue[6],
          },
          // Applies styles to selected item
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: theme.colors.blue[0],
              color: theme.colors.blue[6],
            },
          },
        },
      }),
    },

    Input: {
      styles: (theme) => ({
        input: { borderColor: theme.colors.gray[1] },
      }),
    },
    Button: {
      styles: (theme, params) => {
        const paramColor = getParamColor(params.color, theme);
        const primaryShadeNumber = theme.primaryShade as number;
        let root: CSSObject = {
          fontWeight: theme.other.weightNormal,
          '&[data-disabled]': {
            opacity: 0.2,
            backgroundColor: theme.colors.gray[1],
            color: theme.colors.gray[9],
          },
          '&[data-active="true"]': {
            borderColor: paramColor?.[primaryShadeNumber],
          },
        };

        // Light style override
        if (params.variant === 'light') {
          root = {
            ...root,
            backgroundColor: paramColor?.[0],
            color: paramColor?.[primaryShadeNumber],
          };
        }

        // Subtle style override
        if (params.variant === 'subtle') {
          root['&:hover'] = {
            background: 'none',
            color: paramColor?.[(primaryShadeNumber as number) + 2],
          };
        }
        return {
          root,
        };
      },
    },
    // https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Table/Table.styles.ts
    Table: {
      styles: (theme) => ({
        root: {
          '& thead tr th, & tfoot tr th': {
            fontWeight: 500,
            fontSize: theme.fontSizes.md,
          },
          '& thead tr th': {
            borderBottom: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0]
            }`,
          },
          '&[data-hover] .data-clickable': theme.fn.hover({
            cursor: 'pointer',
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          }),

          '& tbody tr td': {
            borderBottom: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0]
            }`,
          },

          '&[data-hover] tbody tr': theme.fn.hover({
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors[theme.primaryColor][0],
          }),
        },
      }),
    },
    Modal: {
      styles: () => ({
        title: {
          fontSize: 18,
          fontWeight: 500,
          padding: '15px 15px 0',
        },
        body: {
          padding: '0 30px 30px',
        },
      }),
    },
    // https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Skeleton/Skeleton.styles.ts
    Skeleton: {
      styles: (theme) => ({
        visible: {
          '&::after': {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2],
          },
        },
      }),
    },
    Text: {
      styles: (theme, {
        color,
      }: TextStylesParams) => ({
        root: {
          color: getTextColor({ color, theme }),
        },
      }),
    },
    // https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Checkbox/Checkbox.styles.ts
    Checkbox: {
      defaultProps: {
        radius: 'sm',
      },
      styles: (theme) => ({
        label: {
          cursor: 'pointer',
        },
        input: {
          cursor: 'pointer',
          border: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
          }`,
        },
      }),
    },
    // https://github.com/mantinedev/mantine/blob/master/src/mantine-core/src/Kbd/Kbd.styles.ts
    Kbd: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
          border: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0]
          }`,
          borderBottom: `2px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
          }`,
        },
      }),
    },
    Tabs: {
      styles: (theme, params) => ({
        tabsList: {
          borderColor: theme.colors.gray[0],
        },
      }),
    },
    SegmentedControl: {
      defaultProps: {
        radius: 'md',
      },
      styles: (theme, params) => ({
        root: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
        control: {
          '&:not(:first-of-type)': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.blue[0],
          },
        },
        active: {
          boxShadow: params.color || 'none',
          backgroundColor: params.color
            ? theme.colors.background
            : theme.colorScheme === 'dark'
              ? theme.colors.dark[5]
              : theme.colors.blue[0],
        },
        labelActive: {
          '&, &:hover': {
            color: params.color || theme.colorScheme === 'dark' ? theme.white : theme.colors.blue[5],
          },
        },
      }),
    },
    Dialog: {
      styles: (theme, params) => ({
        root: {
          borderColor: theme.colors.gray[1],
        },
      }),
    },
  },
};
