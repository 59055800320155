import { Box, Text, useMantineTheme } from '@mantine/core';
import { MessageTypingSpinner } from 'components/MessageTypingSpinner';
import React from 'react';

interface VAMessageProps {
  text: string,
  createdAt: Date,
  speaker: string,
  typingAnimation?: boolean,
}

export default function VAMessage({
  text,
  createdAt,
  speaker,
  typingAnimation,
}: VAMessageProps) {
  const theme = useMantineTheme();
  return (
    <Box
      w="100%"
      style={{
        display: 'flex',
        justifyContent: speaker === 'visitor' ? 'flex-end' : 'flex-start',
      }}
    >
      <Box
        p="sm"
        maw={400}
        style={{
          display: 'inline-block',
          backgroundColor: speaker === 'visitor' ? theme.colors.gray[1] : theme.white,
          borderRadius: theme.spacing.sm,
          borderBottomRightRadius: speaker === 'visitor' ? 0 : theme.spacing.sm,
          borderBottomLeftRadius: speaker === 'agent' ? 0 : theme.spacing.sm,
        }}
      >
        {typingAnimation ? (
          <MessageTypingSpinner justify="start"/>
        ) : (
          <>
            {speaker === 'agent' && (
            <Box>
              <Text
                mb="xs"
                style={{
                  padding: '0px 4px',
                  borderRadius: 4,
                  backgroundColor: theme.colors.blue[0],
                  display: 'inline-block',
                }}
                size="xs"
                color="blue"
              >AI Generated
              </Text>
            </Box>
            )}
            <Text size="sm" color="gray.8">{text}</Text>
          </>
        )}
      </Box>
    </Box>
  );
}
