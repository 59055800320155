import React, { useCallback } from 'react';
import {
  Annotation,
  AnnotationValue,
  BinaryValueValue,
  SuggestionQaValueCompareModelValue,
  SuggestionQaValueSingleModelValue,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { ANNOTATION_BINARY_VALUE_MAP, ANNOTATION_SUGGESTION_COMPARE_VALUE_MAP, ANNOTATION_SUGGESTION_SINGLE_VALUE_MAP } from 'constants/labeling';
import { getId } from 'common/resourceName';
import AnnotationTag, { AnnotationTagType } from './AnnotationTag';

export function getAnnotationTextAndClassByValue(value: AnnotationValue): { text: string, className: AnnotationTagType } {
  if (value.suggestionQaValue) {
    const isCompare = value.suggestionQaValue?.compareModelValue && value.suggestionQaValue?.compareModelValue !== SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_UNSPECIFIED;
    if (isCompare) {
      return ANNOTATION_SUGGESTION_COMPARE_VALUE_MAP.get(value.suggestionQaValue.compareModelValue);
    } else if (value.suggestionQaValue.singleModelValue !== SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_UNSPECIFIED) {
      return ANNOTATION_SUGGESTION_SINGLE_VALUE_MAP.get(value.suggestionQaValue.singleModelValue);
    } else {
      return { text: 'Unspecified', className: 'unspecified' };
    }
  } else if (value.binaryValue) {
    return ANNOTATION_BINARY_VALUE_MAP.get(value.binaryValue.value);
  }
  return ANNOTATION_BINARY_VALUE_MAP.get(BinaryValueValue.VALUE_UNSPECIFIED);
}

// 'values-colors' shows Yes/No/Skip...
// 'concepts-colors' shows the concept names, both negative and positive
export type AnnotationsDisplayType = 'concepts-colors' | 'values-colors' | 'entity';

export type AnnotationValueType = 'binary' | 'suggestion';

type AnnotationsProps = {
  annotations?: Annotation[];
  getConceptTitle: (conceptId: string) => string;
  annotationsDisplayType: AnnotationsDisplayType;
  annotationValueType?: AnnotationValueType;
};

export default function Annotations({
  annotations,
  annotationsDisplayType = 'values-colors',
  getConceptTitle,
  annotationValueType = 'binary',
}: AnnotationsProps) {
  const annotationNameOrCount = useCallback((annotations: Annotation[]) => {
    if (annotations.length === 1) {
      const { conceptId } = annotations[0].value.binaryValue;
      return getConceptTitle(conceptId) || conceptId;
    } else {
      const suffix = annotations[0].value.binaryValue.value === BinaryValueValue.VALUE_POSITIVE ? 'positive' : 'negative';
      return `${annotations.length} ${suffix}${annotations.length > 1 && 's'}`;
    }
  }, [getId, getConceptTitle]);

  // Annotations with suggestion value type
  if (annotationValueType === 'suggestion') {
    return (
      <>
        {annotations.filter((annotation) => annotation.value.suggestionQaValue).map((annotation, i) => {
          const { text, className } = getAnnotationTextAndClassByValue(annotation.value);
          return (
            <AnnotationTag
              text={text}
              type={className}
              key={annotation.name || i}
            />
          );
        })}
      </>
    );
  }

  // Annotations with binary value type
  const firstValue = annotations[0]?.value?.binaryValue?.value;
  const isNegative = annotations.every((a) => a.value.binaryValue?.value === BinaryValueValue.VALUE_NEGATIVE);
  const isPositive = annotations.some((a) => a.value.binaryValue?.value === BinaryValueValue.VALUE_POSITIVE);

  // For non-positive and non-negatives values or single labeling
  if (annotationsDisplayType === 'values-colors' || (!isNegative && !isPositive)) {
    const annotationObject = ANNOTATION_BINARY_VALUE_MAP.get(firstValue);
    if (!annotationObject) { return null; }
    return (
      <AnnotationTag text={annotationObject?.text} type={annotationObject.className}/>
    );
  }

  // Multiple positive intents (dense labeling)
  const positiveAnnotations: Annotation[] = [];
  const negativeAnnotations: Annotation[] = [];
  annotations.forEach((annotation) => {
    if (annotation.value.binaryValue.value === BinaryValueValue.VALUE_POSITIVE) {
      positiveAnnotations.push(annotation);
    } else if (annotation.value.binaryValue.value === BinaryValueValue.VALUE_NEGATIVE) {
      negativeAnnotations.push(annotation);
    }
  });

  return (
    <>
      {positiveAnnotations.map((annotation, i) => {
        const { text, className } = getAnnotationTextAndClassByValue(annotation.value);
        return (
          <AnnotationTag
            text={annotationsDisplayType === 'concepts-colors'
              ? getConceptTitle(annotation.value.binaryValue.conceptId)
              : text}
            type={className}
            key={annotation.name || i}
          />
        );
      })}
      {negativeAnnotations?.length > 0 && (
        <AnnotationTag
          text={`${positiveAnnotations.length > 0 ? '+' : ''}${annotationNameOrCount(negativeAnnotations)}`}
          type={ANNOTATION_BINARY_VALUE_MAP.get(BinaryValueValue.VALUE_NEGATIVE)?.className}
          key="single-negative"
        />
      )}
    </>
  );
}
