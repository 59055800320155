import { createSlice } from '@reduxjs/toolkit';
import {
  getEvaluationArtifactPending,
  getEvaluationArtifactFailed,
  getEvaluationArtifactSuccess,
  getEvaluationArtifactToComparePending,
  getEvaluationArtifactToCompareFailed,
  getEvaluationArtifactToCompareSuccess,
  listModelArtifactSuccess,
  modelArtifactApiFailed,
  modelArtifactApiPending,
  clearComparedEvaluationArtifactReducer,
  getModelArtifactSuccess,
} from './reducer';
import {
  getEvaluationArtifact,
  getEvaluationArtifactToCompare,
  getModelArtifact,
  listModelArtifacts,
} from './asyncThunks';
import { INITIAL_STATE } from './state';

/** model artifact slice. */
export const modelArtifactSlice = createSlice({
  name: 'modelArtifact',
  initialState: INITIAL_STATE,
  reducers: {
    clearComparedEvaluationArtifact: clearComparedEvaluationArtifactReducer,
  },
  extraReducers: (builder) =>
    builder.addCase(getEvaluationArtifact.pending, getEvaluationArtifactPending)
      .addCase(getEvaluationArtifact.fulfilled, getEvaluationArtifactSuccess)
      .addCase(getEvaluationArtifact.rejected, getEvaluationArtifactFailed)
      .addCase(getEvaluationArtifactToCompare.pending, getEvaluationArtifactToComparePending)
      .addCase(getEvaluationArtifactToCompare.fulfilled, getEvaluationArtifactToCompareSuccess)
      .addCase(getEvaluationArtifactToCompare.rejected, getEvaluationArtifactToCompareFailed)
      .addCase(listModelArtifacts.pending, modelArtifactApiPending)
      .addCase(listModelArtifacts.fulfilled, listModelArtifactSuccess)
      .addCase(listModelArtifacts.rejected, modelArtifactApiFailed)
      .addCase(getModelArtifact.pending, modelArtifactApiPending)
      .addCase(getModelArtifact.fulfilled, getModelArtifactSuccess)
      .addCase(getModelArtifact.rejected, modelArtifactApiFailed),
});

/** Actions for sync reducers. */
export const { clearComparedEvaluationArtifact } = modelArtifactSlice.actions;
