import React, { useMemo } from 'react';
import { DataGrid } from 'components/DataGrid';
import { ColumnDef } from '@tanstack/react-table';
import { SingleFlowGraph } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { round, sortBy } from 'lodash';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Group } from '@mantine/core';

interface StagesTableData {
  stages: string[];
  conversations: number;
  total: number;
}

export interface StagesTableProps {
  flowGraphs: SingleFlowGraph[];
}

export const StagesTable = ({ flowGraphs }: StagesTableProps) => {
  const columns: ColumnDef<StagesTableData>[] = [
    {
      accessorFn: (row) => row.stages,
      header: 'Stage path',
      cell: (info) => {
        const stages = info.getValue() as string[];
        return (
          <Group spacing="xs">
            {stages.map((stage) => (
              <ManualAnalysisTag
                key={stage}
                tagType={TagTagType.STAGE_BEGIN}
              >{stage}
              </ManualAnalysisTag>
            ))}
          </Group>
        );
      },
    },
    {
      accessorFn: (row) => row.conversations,
      header: '# Conversations',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.total,
      header: '% Total',
      cell: (info) => info.getValue(),
    },
  ];

  const stagesTableData = useMemo(() => {
    const pathsMap = new Map<string, [string[], number]>();
    let totalConversations = 0;

    flowGraphs.forEach((row) => {
      const stageNames = row.flowNodes
        .filter((node) => node.conceptTitle.match('.begin'))
        .map((node) => node.conceptTitle.split('.begin')[0]);
      const rowId = stageNames.join(':');
      const mapValue = pathsMap.get(rowId);
      if (!mapValue) {
        pathsMap.set(rowId, [stageNames, 1]);
      } else {
        mapValue[1] += 1;
        pathsMap.set(rowId, mapValue);
      }
      totalConversations += 1;
    });

    const data = Array.from(pathsMap.values()).map((value) => ({
      stages: value[0],
      conversations: value[1],
      total: round(value[1] / totalConversations * 100, 2),
    }));
    const sortedData = sortBy(data, ((row) => row.stages.join('').toLowerCase()));
    return sortedData;
  }, [flowGraphs]);

  return (
    <DataGrid
      columns={columns}
      dataSource={stagesTableData}
      initialPageSize={100}
    />
  );
};
