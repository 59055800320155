import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'hooks/reduxHooks';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { Anchor, Text } from '@mantine/core';
import { useRubrics } from 'hooks/useRubrics';
import { round } from 'lodash';
import { UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import styles from '../styles.module.scss';
import { BaseSummaryTable } from '..';

interface SummarySummarizationQaTableProps extends BaseSummaryTable {}

function formatScoreAverage(sum: number, count: number) {
  return round(sum / count, 2);
}

export default function SummarySummarizationQaTable({
  searchTerm,
  taskId,
  allConceptsMap,
}: SummarySummarizationQaTableProps) {
  const customer = useCustomerParams();
  const navigate = useNavigate();
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const [rubrics, loadingRubrics] = useRubrics(UserLabelingTaskType.QA_SUMMARIZATION);

  const rubricsColumns: ColumnsType<ResultsRow> = useMemo(() => rubrics.map((rubric) => ({
    title: (title) => <span style={{ textTransform: 'capitalize' }}>{rubric.rubricTitle}</span>,
    width: 100,
    dataIndex: ['rubrics', rubric.name.split('/')[1]],
    key: rubric.name,
    render: (value, row) =>
    // Display average
      <Text>{formatScoreAverage(value, row.totalConversations)}</Text>,
    sorter: (a, b) => b.rubrics[rubric.name] - a.rubrics[rubric.name],
  })), [rubrics]);

  const summaryResults = useMemo(() => {
    // Map with keys being the model names
    const resultsMap = new Map<string, ResultsRow>();
    const rubricsKeys = (rubrics || []).map((rubric) => rubric.name.split('/')[1]).reduce((acc, key) => {
      acc[key] = 0;
      return acc;
    }, {});

    // Map with `model:conversationPosition` as keys array of scores as values
    const conversationScoresMap = new Map<string, number[]>();

    annotationBundles.forEach((bundle) => {
      const { rubricValue } = bundle.annotation.value;
      let resultRow = resultsMap.get(rubricValue.modelUri);
      if (!resultRow) {
        resultRow = {
          model: rubricValue.modelUri || 'N/A',
          gsr3: 0,
          gsr4: 0,
          gsr5: 0,
          rubrics: { ...rubricsKeys },
          totalConversations: 0,
        };
        resultsMap.set(rubricValue.modelUri, resultRow);
      }

      const convoPosition = bundle.annotation.rawData.conversationPositionNumber;
      const modelAndPositionKey = `${rubricValue.modelUri}:${convoPosition}`;
      const scores = conversationScoresMap.get(modelAndPositionKey) || [];
      conversationScoresMap.set(modelAndPositionKey, [...scores, bundle.annotation.value.rubricValue.score]);

      resultRow.rubrics[rubricValue.rubricId] += rubricValue.score;
    });

    // Iterate over map of conversations with rubric scores
    // and update table records' gsr scores
    Array.from(conversationScoresMap.keys()).forEach((key) => {
      const modelKey = key.split(':')[0];
      const modelResults = resultsMap.get(modelKey);
      const conversationScores = conversationScoresMap.get(key);
      const isGsr3 = conversationScores.every((val) => val >= 3);
      const isGsr4 = conversationScores.every((val) => val >= 4);
      const isGsr5 = conversationScores.every((val) => val >= 5);
      resultsMap.set(modelKey, {
        ...modelResults,
        gsr3: modelResults.gsr3 + Number(isGsr3),
        gsr4: modelResults.gsr4 + Number(isGsr4),
        gsr5: modelResults.gsr5 + Number(isGsr5),
        totalConversations: modelResults.totalConversations + 1,
      });
    });

    const fullSummary = Array.from(resultsMap.values());
    return fullSummary;
  }, [annotationBundles, searchTerm, rubrics]);

  interface ResultsRow {
    model: string;
    gsr3: number;
    gsr4: number;
    gsr5: number;
    rubrics: { [name: string]: number };
    totalConversations: number;
  }

  const columns: ColumnsType<ResultsRow> = [
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: 200,
      render: (value, row) => (
        <Anchor onClick={(e) => {
          // without stopPropogation, navigate works incorrectly (adds a second score-view directory)
          e.stopPropagation();
          navigate(`/${customer.path}/qa/score-view/${taskId}/model/${row.model}`);
        }}
        >{value}
        </Anchor>
      ),
    },
    {
      title: 'GSR 3',
      width: 100,
      dataIndex: 'gsr3',
      key: 'gsr4',
      render: (value, row) => (<Text>{formatScoreAverage(value, row.totalConversations)}</Text>),
      sorter: (a, b) => b.gsr3 - a.gsr3,
    },
    {
      title: 'GSR 4',
      width: 100,
      dataIndex: 'gsr4',
      key: 'gsr4',
      render: (value, row) => (<Text>{formatScoreAverage(value, row.totalConversations)}</Text>),
      sorter: (a, b) => b.gsr4 - a.gsr4,
    },
    {
      title: 'GSR 5',
      width: 100,
      dataIndex: 'gsr5',
      key: 'gsr5',
      render: (value, row) => (<Text>{formatScoreAverage(value, row.totalConversations)}</Text>),
      sorter: (a, b) => b.gsr5 - a.gsr5,
    },
    ...rubricsColumns,
    {
      title: '# QAs',
      width: 100,
      dataIndex: 'totalConversations',
      key: 'totalConversations',
      sorter: (a, b) => b.totalConversations - a.totalConversations,
    },
  ];

  return (
    <Table
      loading={{
        spinning: loadingRubrics || taskApiStatus === 'loading',
        indicator: <Loading />,
      }}
      columns={columns}
      rowKey={(row) => row.model}
      dataSource={summaryResults}
      className={styles.summaryTable}
    />
  );
}
