import { UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Group } from '@mantine/core';
import SearchInput from 'components/SearchInput';
import React from 'react';

interface SummaryFiltersProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  taskType: UserLabelingTaskType;
}

export default function SummaryFilters({
  searchTerm,
  setSearchTerm,
  taskType,
}: SummaryFiltersProps) {
  const hasIntentSearch = [
    UserLabelingTaskType.QA_INTENT_PREDICTION,
    UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
    UserLabelingTaskType.QA_POLICY,
  ].includes(taskType);
  return (
    <Group position="right" grow p={0} spacing="sm">
      {
        hasIntentSearch && (
          <SearchInput
            placeholder="Search by intent"
            clearable
            onChange={(val) => setSearchTerm(val)}
            value={searchTerm}
          />
        )
      }
      {
        taskType === UserLabelingTaskType.LABELING_SUMMARIZATION && (
          <SearchInput
            placeholder="Search summarization"
            clearable
            onChange={(val) => setSearchTerm(val)}
            value={searchTerm}
          />
        )
      }
      {
        taskType === UserLabelingTaskType.QA_SUGGESTIONS && (
          <SearchInput
            placeholder="Search"
            clearable
            onChange={(val) => setSearchTerm(val)}
            value={searchTerm}
          />
        )
      }
    </Group>
  );
}
