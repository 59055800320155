import React from 'react';
import { Text, Group, Divider, useMantineTheme, Indicator } from '@mantine/core';
import { Job, JobState } from '@cresta/web-client';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, DATE_FORMAT } from 'studioConstants';

function JobStateIndicator({ job }: { job: Job }) {
  const theme = useMantineTheme();
  const { state } = job;
  switch (state) {
    case JobState.RUNNING: {
      return (
        <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.yellow[4]} radius="xl">
          <Text size="sm" color="gray">Analysis running</Text>
        </Indicator>
      );
    }
    case JobState.PENDING: {
      return (
        <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.yellow[4]} radius="xl">
          <Text size="sm" color="gray">Analysis pending</Text>
        </Indicator>
      );
    }
    case JobState.SUCCEEDED: {
      return (
        <>
          <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.green[4]} radius="xl">
            <Text size="sm" color="gray">Analysis completed:</Text>
          </Indicator>
          <Text size="sm">{dayjs(job.updateTime).format(DATE_TIME_FORMAT)}</Text>
        </>
      );
    }
    case JobState.FAILED: {
      return (
        <>
          <Indicator zIndex={20} pl="sm" position="middle-start" size={8} color={theme.colors.red[4]} radius="xl">
            <Text size="sm" color="gray">Analysis failed:</Text>
          </Indicator>
          <Text size="sm">{dayjs(job.updateTime).format(DATE_TIME_FORMAT)}</Text>
        </>
      );
    }
    default: {
      return null;
    }
  }
}

export default function AnalysisInfoPanel({
  total,
  job,
}: {
  total: number,
  job: Job
}) {
  const theme = useMantineTheme();

  if (!job) return null;

  const { callDriverDiscoveryPayload } = job.payload;

  const startTimeFormatted = callDriverDiscoveryPayload.startTime ? dayjs(callDriverDiscoveryPayload.startTime).format(DATE_FORMAT) : 'N/A';
  const endTimeFormatted = callDriverDiscoveryPayload.endTime ? dayjs(callDriverDiscoveryPayload.endTime).format(DATE_FORMAT) : 'N/A';

  return (
    <Group px="lg" py="md">
      <Group>
        <Text size="sm" color="gray">Date range:</Text>
        <Text size="sm">{`${startTimeFormatted} - ${endTimeFormatted}`}</Text>
      </Group>
      <Divider orientation="vertical" style={{ height: 'auto' }} color={theme.colors.gray[1]} />
      <Group>
        <Text size="sm" color="gray">Total # of conversations:</Text>
        <Text size="sm">{job.state === 'SUCCEEDED' ? total : 'N/A'}</Text>
      </Group>
      <Group style={{ marginLeft: 'auto' }}>
        <JobStateIndicator job={job} />
      </Group>
    </Group>
  );
}
