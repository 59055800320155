import React, { useEffect, useMemo, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { DerivedLabelingTask, TargetType, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import UserTag from 'components/UserTag';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { fetchLabelingTaskAnnotations } from 'store/labelingTask/asyncThunks';
import { selectDerivedLabelingTasksMap } from 'store/labelingTask/selectors';
import { selectUsers } from 'store/user/selectors';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { User } from 'types';

import CopyableValue from 'components/CopyableValue';
import moment from 'moment';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConceptsMapFactory } from 'store/concept/selectors';
import Loading from 'components/Loading';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/moment/moment_annotation.pb';
import { ActionAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/action/action_annotation.pb';
import { SearchAnnotationsRequestSearchFilter } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Table } from 'antd';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { getMomentAnnotationType, getTaskConceptType } from 'pages/LabelingView/utils';
import { clearLabelingTask } from 'store/labelingTask/labelingTaskSlice';
import { AnnotationState } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Group, Select } from '@mantine/core';
import styles from './styles.module.scss';
import SummarizationQaTable from './ModelTables/ModelSummarizationQaTable';
import SummaryFilters from './SummaryFilters';

export interface BaseModelTable {
  userLabelingTaskType?: UserLabelingTaskType;
  searchTerm: string;
  taskId: string;
  modelId: string;
  allConceptsMap: Map<string, Concept>;
  fetchSummary?: () => void;
  filtersNode?: React.ReactNode;
}

const TASK_TYPES_NAMES_MAP = new Map<UserLabelingTaskType, string>([
  [UserLabelingTaskType.QA_INTENT_PREDICTION, 'Intent Prediction'],
  [UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL, 'Intents - All'],
  [UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION, 'Intents - Misclassification'],
  [UserLabelingTaskType.QA_POLICY, 'Policy'],
  [UserLabelingTaskType.QA_SUGGESTIONS, 'Suggestions'],
  [UserLabelingTaskType.QA_SUMMARIZATION, 'Summarization'],
  [UserLabelingTaskType.LABELING_SUMMARIZATION, 'Summarization'],
]);

// Decides which table to show based on labeling task type
const ModelTable = (props: BaseModelTable) => {
  const { userLabelingTaskType, ...other } = props;
  switch (userLabelingTaskType) {
    case UserLabelingTaskType.QA_SUMMARIZATION:
      return <SummarizationQaTable {...other}/>;
    default:
      return <Table className={styles.summaryTable} columns={[]} dataSource={[]}/>;
  }
};

export default function TaskModelSummaryView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskId, modelId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);
  // Completed, non-dual tasks.
  const tasksMap = useSelector<Map<string, DerivedLabelingTask>>(selectDerivedLabelingTasksMap);

  const task = tasksMap.get(taskId);
  const targetType = task?.labelingTask.taskData.taskDescriptor.targetType;
  const conceptType = task?.labelingTask.taskData.taskDescriptor.conceptType;
  const taskModels = task?.labelingTask.taskData.taskDescriptor.selectionInstruction?.summarizationInstruction?.modelUris || [];

  const fetchSummary = async () => {
    const searchFilter: SearchAnnotationsRequestSearchFilter = {
      taskIds: taskId ? [taskId] : [],
      conceptType: getTaskConceptType(conceptType),
      states: [AnnotationState.ACTIVE_REVIEWED, AnnotationState.ACTIVE_SKIP_REVIEW, AnnotationState.ACTIVE_MANUALLY_ADDED],
      detailedTypeFilter: {
        targetType,
        momentAnnotationTypes: [getMomentAnnotationType(conceptType)],
        actionAnnotationTypes: [],
        actionAnnotationDetailedTypes: [],
        momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
        actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
      },
    };

    // Change adherence type filter for policy QA annotations
    if (task.userLabelingTaskType === UserLabelingTaskType.QA_POLICY) {
      searchFilter.detailedTypeFilter = {
        ...searchFilter.detailedTypeFilter,
        momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_SHOULD_DO_X],
      };
    }

    // Load all annotations in loop
    dispatch(fetchLabelingTaskAnnotations({
      parent: customerProfile,
      searchFilter,
      pageSize: 1000,
    }));
  };

  const currentTaskMetadata = useMemo(() => {
    if (!task) {
      return {
        userLabelingTaskType: UserLabelingTaskType.USER_LABELING_TASK_TYPE_UNSPECIFIED,
        assignee: '',
        dateCompleted: 'Missing',
      };
    }

    return {
      userLabelingTaskType: task.userLabelingTaskType,
      assignee: task.labelingTask.abstractTask.assigneeUserId,
      dateCompleted: task.labelingTask.abstractTask.endTime ? moment(task.labelingTask.abstractTask.endTime).format('MM/DD/YYYY H:mma') : 'Missing',
    };
  }, [customerProfile, task, taskId]);

  useEffect(
    () =>
    // Reset annotations to empty array when component unmounts
      () => {
        dispatch(clearLabelingTask());
      }
    , [],
  );

  useEffect(() => {
    if (task) {
      fetchSummary();
    }
  }, [task?.labelingTask?.name]);

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <Link to={`/${customer.path}/qa/score-view/${taskId}`} className={styles.backBtn}>
        <ArrowLeftOutlined /> Back to Summary
      </Link>
      <div className={classNames([styles.filters, styles.header])}>
        <h1>{TASK_TYPES_NAMES_MAP.get(currentTaskMetadata?.userLabelingTaskType) || '[Unsupported Task Type]'} {targetType === TargetType.QA ? 'QA' : 'Labeling'} Task Summary</h1>
      </div>
      <div className={styles.metadataContainer}>
        <div className={styles.metadataItem}>
          <span className={styles.label}>Task ID:</span>
          <CopyableValue
            displayValue={`${taskId.substring(0, 6)}...`}
            copiedValue={taskId}
            tooltip={taskId}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.metadataItem}>
          <span className={styles.label}>Assignee:</span>
          <UserTag name={users.find((user) => user.id === currentTaskMetadata.assignee)?.full_name || 'Unassigned'} />
        </div>
        <div className={styles.divider} />
        <div className={styles.metadataItem}>
          <span className={styles.label}>Date completed:</span>
          <span>{currentTaskMetadata.dateCompleted}</span>
        </div>
      </div>
      <Group position="right">
        <Select
          value={modelId}
          data={taskModels}
          onChange={(value) => {
            navigate(`./${value}`);
          }}
        />
      </Group>
      <div className={styles.content}>
        {task ? (
          <ModelTable
            userLabelingTaskType={currentTaskMetadata?.userLabelingTaskType}
            searchTerm={searchTerm}
            taskId={taskId}
            modelId={modelId}
            allConceptsMap={allConceptsMap}
            fetchSummary={fetchSummary}
            filtersNode={(
              <SummaryFilters
                taskType={task?.userLabelingTaskType}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            )}
          />
        ) : <Loading/>}
      </div>
    </div>
  );
}
