import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './LabelingStepper.scss';
import { useHotkeys } from 'hooks/useHotkeys';
import { AnnotationValueCountEntry } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { keyBy } from 'lodash';
import { ConceptType, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Button, Text, Group } from '@mantine/core';
import { TaskProgress } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/task_progress.pb';
import { Link } from 'react-router-dom';
import { useCustomerParams } from 'hooks/useCustomerParams';

type LabelingStepperProps = {
  annotatedMessages: number;
  annotatedChats: number;
  targetChats: number;
  targetMessages: number;
  totalConversationNumber: number;
  taskProgress: TaskProgress;
  annotationValueCounts: AnnotationValueCountEntry[];
  currentIndex: number;
  nextIndex: number;
  prevIndex: number;
  hideNavigation: boolean;
  prevChat: () => void;
  nextChat: () => void;
  labelingTaskType: UserLabelingTaskType;
  conceptType: ConceptType;
  loading?: boolean;
  taskId?: string;
};

export default function LabelingStepper({
  annotatedMessages = 0,
  annotatedChats = 0,
  targetChats = 0,
  targetMessages = 0,
  totalConversationNumber = 0,
  taskProgress,
  annotationValueCounts,
  currentIndex,
  prevIndex,
  nextIndex,
  hideNavigation,
  prevChat,
  nextChat,
  labelingTaskType,
  conceptType,
  loading = false,
  taskId,
}: LabelingStepperProps) {
  useHotkeys('shift+left', () => prevChat(), {}, [prevChat]);
  useHotkeys('shift+right', () => nextChat(), {}, [nextChat]);
  const customer = useCustomerParams();

  const annotationValuesMap = keyBy(annotationValueCounts, 'strValue');
  const renderCounts = () => {
    if (labelingTaskType === UserLabelingTaskType.QA_POLICY) {
      return (
        <span>
          # of QA&apos;d conversations <b className="blue">{loading ? 'NA' : `${annotatedChats}`}</b>
        </span>
      );
    } else if ([
      UserLabelingTaskType.LABELING_SUMMARIZATION,
      UserLabelingTaskType.QA_SUMMARIZATION,
      UserLabelingTaskType.QA_SUGGESTIONS,
    ].includes(labelingTaskType)) {
      const link = `/${customer.path}/qa/score-view/${taskId}`;
      const labeledCount = taskProgress?.conversationSetProgresses[0]?.labeledSize;
      if (labeledCount > 0) {
        return (
          <Group spacing={4}>
            <Text>Convos</Text>
            <Link target="_blank" to={link} style={{ display: 'inline-block' }}>
              <b>{labeledCount}/{totalConversationNumber}</b>
            </Link>
          </Group>
        );
      } else {
        return (
          <span>
            Convos <b>{labeledCount}/{totalConversationNumber}</b>
          </span>
        );
      }
    } else if (labelingTaskType === UserLabelingTaskType.QA_SUGGESTIONS) {
      return (
        <span>
          Total # Done <b className="blue">{loading ? 'NA' : `${annotatedChats}`}</b>
        </span>
      );
    } else if ([ConceptType.INTENT, ConceptType.CONVERSATION_OUTCOME].includes(conceptType)) {
      return (
        <>
          <span>
            Yes <b>{annotationValuesMap?.VALUE_POSITIVE?.count || 0}</b>
          </span>
          <span>
            No <b>{annotationValuesMap?.VALUE_NEGATIVE?.count || 0}</b>
          </span>
          <span>
            Skip <b>{annotationValuesMap?.VALUE_SKIP?.count || 0}</b>
          </span>
          <span>
            Unsure <b>{annotationValuesMap?.VALUE_FLAG_FOR_REVIEW?.count || 0}</b>
          </span>
        </>
      );
    } else if (conceptType === ConceptType.ENTITY) {
      return (
        <span>
          # of labeled messages <b className="blue">{loading ? 'NA' : `${annotatedMessages}/${targetMessages}`}</b>
        </span>
      );
    } else {
      return (
        <span>
          # of labeled messages <b className="blue">{loading ? 'NA' : `${annotatedMessages}/${targetMessages}`}</b>
        </span>
      );
    }
  };

  return (
    <div className="labeling-stepper">
      <div className="labeling-info">
        <div className="counts">
          {renderCounts()}
        </div>
        <div className="progress-bar">
          <div
            className="bar-fill"
            style={{
              width: `${Math.min((annotatedMessages / targetMessages) * 100, 100)}%`,
            }}
          />
        </div>
      </div>
      {!hideNavigation && (
      <div className="navigator-buttons">
        {
          conceptType !== ConceptType.ENTITY && (
          <Button color="gray" variant="light" disabled={currentIndex === prevIndex} onClick={prevChat}>
            <span>
              <LeftOutlined />
            </span>
          </Button>
          )
        }
        <span className="navigator-current-chat">{currentIndex + 1}/{targetChats}</span>
        {
          conceptType !== ConceptType.ENTITY && (
          <Button color="gray" variant="light" disabled={currentIndex === nextIndex} onClick={nextChat}>
            <span>
              <RightOutlined />
            </span>
          </Button>
          )
        }
      </div>
      )}
    </div>
  );
}
