import React, { useContext, useState } from 'react';
import { ActionIcon, CheckIcon, createStyles, Group, Menu, Space, Stack, Text } from '@mantine/core';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { StagesContext } from 'pages/AnalysisWorkshop/TaggingPage/StagesProvider';
import { ManualAnalysisTag } from '../../../../components/Badge';
import { UITagType } from '../../..';
import { TagListSkeletons } from './TagListSkeletons';
import { EditTagNameModal } from './EditTagNameModal';
import { DeleteTagModal } from './DeleteTagModal';

const useStyles = createStyles((theme) => ({
  wrapper: {
    '&:hover': {
      background: theme.colors[theme.primaryColor][0],
    },
    '&[data-active="true"]': {
      cursor: 'default',
      background: theme.colors.yellow[0],
    },
  },
}));

interface TagListProps {
  updateConceptTitle: (id: string, conceptTitle: string) => Promise<void>
  highlightedTagId: string
  initializing?: boolean
  heading: string
  tags: UITagType[]
  removeTagIdFromTask: (tagId: string) => Promise<void>
  onTagClick: (tag: any) => void
  conceptIdsOnCurrentMessage: string[]
  subtitle?: string
  children?: React.ReactNode
}

const ThreeHorizontalDotsIcon = () => (
  <svg width="12" height="3" viewBox="0 0 12 3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0.166016C5.73629 0.166016 5.47851 0.244214 5.25924 0.390723C5.03998 0.537231 4.86908 0.74547 4.76816 0.989105C4.66724 1.23274 4.64084 1.50083 4.69229 1.75947C4.74373 2.01811 4.87072 2.25569 5.05719 2.44216C5.24366 2.62863 5.48124 2.75562 5.73988 2.80706C5.99852 2.85851 6.26661 2.83211 6.51025 2.73119C6.75388 2.63027 6.96212 2.45937 7.10863 2.24011C7.25514 2.02084 7.33333 1.76306 7.33333 1.49935C7.33333 1.14573 7.19286 0.806589 6.94281 0.55654C6.69276 0.306492 6.35362 0.166016 6 0.166016ZM1.33333 0.166016C1.06963 0.166016 0.811839 0.244214 0.592574 0.390723C0.373308 0.537231 0.202411 0.74547 0.101495 0.989105C0.000577708 1.23274 -0.0258267 1.50083 0.0256202 1.75947C0.0770672 2.01811 0.204055 2.25569 0.390525 2.44216C0.576995 2.62863 0.814572 2.75562 1.07321 2.80706C1.33185 2.85851 1.59994 2.83211 1.84358 2.73119C2.08721 2.63027 2.29545 2.45937 2.44196 2.24011C2.58847 2.02084 2.66667 1.76306 2.66667 1.49935C2.66667 1.14573 2.52619 0.806589 2.27614 0.55654C2.02609 0.306492 1.68696 0.166016 1.33333 0.166016ZM10.6667 0.166016C10.403 0.166016 10.1452 0.244214 9.92591 0.390723C9.70664 0.537231 9.53574 0.74547 9.43483 0.989105C9.33391 1.23274 9.30751 1.50083 9.35895 1.75947C9.4104 2.01811 9.53739 2.25569 9.72386 2.44216C9.91033 2.62863 10.1479 2.75562 10.4065 2.80706C10.6652 2.85851 10.9333 2.83211 11.1769 2.73119C11.4205 2.63027 11.6288 2.45937 11.7753 2.24011C11.9218 2.02084 12 1.76306 12 1.49935C12 1.14573 11.8595 0.806589 11.6095 0.55654C11.3594 0.306492 11.0203 0.166016 10.6667 0.166016Z"/>
  </svg>
);

const TagItem = (props) => {
  const { classes } = useStyles();
  const {
    tag,
    updateConceptTitle,
    highlightedTagId,
    onTagClick,
    conceptIdsOnCurrentMessage,
    removeTagIdFromTask,
  } = props;
  const [isEditTagModalOpen, toggleEditTagModal] = useState(false);
  const [tagTitle, setTagTitle] = useState('');
  const { stageIdToBeAdded } = useContext(StagesContext);
  const isDisabled = stageIdToBeAdded && tag.tagType === TagTagType.STAGE && tag.value !== stageIdToBeAdded;

  const [isDeleteTagModalOpen, toggleDeleteTagModal] = useState(false);
  return (
    <Group
      className={classes.wrapper}
      py={5}
      data-active={highlightedTagId === tag.value}
      style={{ width: '100%' }}
      id={`tag-${tag.value}`}
      data-selected={conceptIdsOnCurrentMessage.includes(tag.value)}
      position="apart"
      pr="xl"
    >
      <EditTagNameModal
        isOpen={isEditTagModalOpen}
        toggleOpen={toggleEditTagModal}
        tagTitle={tagTitle}
        setTagTitle={setTagTitle}
        tag={tag}
        updateConceptTitle={updateConceptTitle}
      />
      <DeleteTagModal
        tagId={tag.value}
        isOpen={isDeleteTagModalOpen}
        toggleOpen={toggleDeleteTagModal}
        removeTagIdFromTask={removeTagIdFromTask}
      />
      <ManualAnalysisTag disabled={isDisabled} onClick={() => onTagClick(tag)} key={tag.label} selected={conceptIdsOnCurrentMessage.includes(tag.value)} {...tag}>
        {tag.label}
      </ManualAnalysisTag>
      <Group spacing={5}>
        {conceptIdsOnCurrentMessage.includes(tag.value) && (
        <ActionIcon onClick={() => onTagClick(tag)}><CheckIcon height={6} /></ActionIcon>
        )}
        <Menu
          withinPortal
          withArrow
        >
          <Menu.Target>
            <ActionIcon>
              <ThreeHorizontalDotsIcon />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => {
                setTagTitle(tag.label);
                toggleEditTagModal(true);
              }}
            >Edit
            </Menu.Item>
            <Menu.Item
              color="red"
              onClick={() => {
                toggleDeleteTagModal(true);
              }}
            >Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Group>
  );
};

export const TagList = (props:TagListProps) => {
  const { updateConceptTitle, highlightedTagId, initializing, heading, tags, onTagClick, conceptIdsOnCurrentMessage, removeTagIdFromTask, subtitle, children } = props;
  const tagItemProps = {
    updateConceptTitle,
    highlightedTagId,
    onTagClick,
    conceptIdsOnCurrentMessage,
    removeTagIdFromTask,
  };

  const content = (
    <>
      {tags.map((tag) => <TagItem key={tag.value} tag={tag} {...tagItemProps} />)}
      <Space h="xs" />
      {children}
    </>
  );

  return (
    <Stack align="stretch" spacing={5}>
      <Text color="dimmed" px="5px" pt="3px" pb="1px" size="xs" style={{ width: '100%', background: '#F1F3F4', lineHeight: 'unset' }}>{heading}</Text>
      {subtitle && <Text px="5px" pt="8px" size="xs">{subtitle}</Text>}
      <Stack align="flex-start" spacing={0}>
        {initializing ? <TagListSkeletons /> : content}
      </Stack>
    </Stack>
  );
};
