import React, { ReactElement } from 'react';
import { Table, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import CopyableValue from 'components/CopyableValue';
import { StopOutlined } from '@ant-design/icons';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { ColumnsType } from 'antd/lib/table';
import { WorkflowStatus } from '@cresta/web-client/dist/cresta/v1/studio/workflows/workflows.pb';
import { getId } from 'common/resourceName';
import dayjs from 'dayjs';
import { useCustomerParams } from 'hooks/useCustomerParams';
import externalLinkIcon from 'assets/svg/icon-external-link.svg';
import styles from '../../../styles.module.scss';

interface Props {
  onClickPreviewEval: (workflowName: string) => void;
  dataSource: ModelWorkflow[]
}

const STATUS_MAP = {
  WORKFLOW_STATUS_UNSPECIFIED: 'Unspecified',
  WORKFLOW_ACTIVE: 'In progress',
  WORKFLOW_INACTIVE: 'Inactive',
  WORKFLOW_ACCEPTED: 'Accepted',
  WORKFLOW_FAILED: 'Failed',
  WORKFLOW_SUCCEEDED: 'Trained',
};

export const TrainingTable = ({ dataSource, onClickPreviewEval }: Props) => {
  const customer = useCustomerParams();
  const columns: ColumnsType<ModelWorkflow> = [
    {
      title: 'ID',
      dataIndex: 'name',
      key: 'id',
      render: (name: string) => {
        const id = getId('modelWorkflow', name);
        return <CopyableValue copiedValue={id} displayValue={`${id?.substring(0, 6)}...`} />;
      },
    },
    {
      title: 'Model URL',
      width: 100,
      key: 'model',
      render: (_: string, record: ModelWorkflow) => {
        const model = record.trainSuggestionsGenerativeModelOutput?.ensembleModelUri
          || record.trainSuggestionsRetrieverOutput?.ensembleModelUri
          || record.packageSuggestionsEnsembleOutput?.suggestionsEnsembleModelUri;
        return model ? <CopyableValue copiedValue={model} displayValue={model.split(':').at(-1)} /> : 'generating url';
      },
    },
    {
      title: 'S3',
      width: 40,
      key: 'modelLink',
      render: (_: string, record: ModelWorkflow) => {
        const model = record.trainSuggestionsGenerativeModelOutput?.ensembleModelUri
          || record.trainSuggestionsRetrieverOutput?.ensembleModelUri
          || record.packageSuggestionsEnsembleOutput?.suggestionsEnsembleModelUri;
        return model ? getModelS3Link(model) : '-';
      },
    },
    {
      title: 'QA Task URL',
      key: 'qaUrl',
      render: (_: string, record: ModelWorkflow) => {
        const taskName = record.trainSuggestionsGenerativeModelOutput?.qaTask?.labelingTask?.name
          || record.trainSuggestionsRetrieverOutput?.qaTask?.labelingTask?.name
          || record.packageSuggestionsEnsembleOutput?.qaTask?.labelingTask?.name;
        return taskName ? (
          <Link
            to={`/${customer.path}/qa/score-view/${getId('labelingTask', taskName)}`}
            target="_blank"
          >
            {`${getId('labelingTask', taskName)?.substring(0, 6)}...`}<img src={externalLinkIcon} alt="Open link" />
          </Link>
        ) : '';
      },
    },
    {
      title: '# of train conversations',
    },
    {
      title: '# QAed',
    },
    {
      title: 'QA %',
    },
    {
      title: 'Last updated',
      dataIndex: ['abstractWorkflow', 'updateTime'],
      key: 'updateTime',
      render: (updateTime: string | undefined) => (
        <div>{updateTime ? dayjs(updateTime).format('MM/DD/YYYY h:mm a') : '--'}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: ['abstractWorkflow', 'status'],
      key: 'status',
      render: (status: WorkflowStatus, record: ModelWorkflow) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={status === WorkflowStatus.WORKFLOW_FAILED && record.abstractWorkflow.failedDetails.message}>
            <div className={`${styles.statusPoint} ${styles[status === 'WORKFLOW_SUCCEEDED' ? 'statusPoint__t' : 'statusPoint__i']}`} />
            {STATUS_MAP[status]}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'name',
      dataIndex: ['abstractWorkflow', 'status'],
      render: (status: WorkflowStatus, row: ModelWorkflow) => {
        if (status === WorkflowStatus.WORKFLOW_SUCCEEDED) {
          return (
            <button
              type="button"
              className={styles.previewEvalButton}
              onClick={() => {
                onClickPreviewEval(row.name);
              }}
            >
              See eval
            </button>
          );
        }
        if (status === WorkflowStatus.WORKFLOW_ACTIVE) {
          return (
            <div style={{ color: '#757575' }}>
              Training...
            </div>
          );
        }
        return <StopOutlined />;
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey={(row: ModelWorkflow) => `${row.name}`}
    />
  );
};

function getModelS3Link(uri: string): ReactElement {
  const path = uri.replace(':', '/');
  return (
    <Link
      to={{ pathname: `https://s3.console.aws.amazon.com/s3/buckets/cresta-models-pci?region=us-west-2&prefix=${path}/&showversions=false` }}
      target="_blank"
    >
      <img src={externalLinkIcon} alt="Open link" />
    </Link>
  );
}
