import React from 'react';
import './UtteranceListItem.scss';
import ReactHtmlParser from 'react-html-parser';
import LetterAvatar from '../LetterAvatar';
import { highlightKeyWords } from '../utils';

interface Props {
  content: string;
  onClick?: () => void;
  textToHighlight: string;
  selected?: boolean;
  adornmentString:string
}

const UtteranceListItem = (props: Props) => {
  const { content, onClick, textToHighlight, selected, adornmentString } = props;

  const handleClick = () => {
    onClick();
  };

  const contentWithHighlight = highlightKeyWords(content, textToHighlight);
  const parsedContent = ReactHtmlParser(contentWithHighlight);

  return (
    <div
      className={`utterance-list-item ${selected && 'selected'}`}
      onClick={handleClick}
    >
      <LetterAvatar role={adornmentString} />
      <div className="text">
        {parsedContent}
      </div>
    </div>
  );
};

export default UtteranceListItem;
