export const eventBus = {
  listen(event, callback) {
    document.addEventListener(event, callback);
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, data));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};
