import { Job, JobJobType, ListJobsFilters } from '@cresta/web-client';
import { Group, Title } from '@mantine/core';
import { useInterval } from 'hooks/useInterval';
import { Tabs, TabsProps } from 'antd';
import { PageContainer } from 'components/PageContainer';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { listJobs } from 'store/job/asyncThunks';
import { selectAllJobs, selectFetchingJobs } from 'store/job/selectors';

const items: TabsProps['items'] = [
  {
    key: 'call-drivers',
    label: 'Call Drivers',
  },
  {
    key: 'conversation-flow',
    label: 'Conversation Flow',
  },
  {
    key: 'chat-gpt',
    label: 'ChatGPT',
  },
  {
    key: 'prompt-manager',
    label: 'Prompts',
  },
];

type AutoAnalysisContext = {
  clusterVersionName: string;
  clusterVersions: string[];
  setClusterVersionName: (clusterVersionName: string) => void;
  jobs: Job[];
};

export const autoAnalysisListJobsFilters: ListJobsFilters = {
  jobType: [{
    jobType: JobJobType.JOB_TYPE_CALL_DRIVER_DISCOVERY,
  }],
};

export const AutoAnalysis = () => {
  const [activeKey, setActiveKey] = useState('call-drivers');
  const navigate = useNavigate();
  const [clusterVersionName, setClusterVersionName] = useState<string>('');
  const customerProfile = useCustomerProfile();
  const { customerId, profileId } = useCustomerParams();
  const fetchingJobs = useSelector<boolean>(selectFetchingJobs);
  const jobs = useSelector(selectAllJobs);
  const dispatch = useDispatch();

  useEffect(() => {
    setClusterVersionName('');
    dispatch(listJobs({
      parent: customerProfile,
      filters: autoAnalysisListJobsFilters,
    }));
  }, [customerProfile]);

  // Refresh jobs every 10s.
  useInterval(() => {
    if (customerId && profileId) {
      // No refresh if there is a pending fetchLabelingTasks.
      if (!fetchingJobs) {
        dispatch(listJobs({
          parent: customerProfile,
          filters: autoAnalysisListJobsFilters,
        }));
      }
    }
  }, 10000, [customerId, profileId, fetchingJobs]);

  const clusterVersions = useMemo(() => jobs.map((job) => job.payload.callDriverDiscoveryPayload.versionName), [jobs]);
  const uniqueClustersVersions = useMemo(() => [...new Set(clusterVersions)], [clusterVersions]);

  const context: AutoAnalysisContext = {
    clusterVersionName,
    clusterVersions: uniqueClustersVersions,
    setClusterVersionName,
    jobs,
  };

  return (
    <PageContainer>
      <Group>
        <Title order={2}>Auto Analysis</Title>
      </Group>
      <div>
        <Tabs
          activeKey={activeKey}
          style={{ width: '100%', overflow: 'inherit' }}
          onChange={(value) => {
            setActiveKey(value);
            navigate(value);
          }}
          size="large"
          items={items}
        />
      </div>
      <Outlet context={context}/>
    </PageContainer>
  );
};

export function useAutoanalysisContext(): AutoAnalysisContext {
  const context = useOutletContext<AutoAnalysisContext>();
  if (!context) {
    throw new Error('useAutoanalysisContext must be used within an AutoAnalysis component');
  }
  return context;
}
