/* eslint dot-notation: 0 */
import React, { useCallback, useContext, useEffect, useState, ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import { Collapse, Divider, Drawer, Form, Input, Modal, Select, Spin, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import useUrlParam from 'hooks/useUrlParam';
import CopyableValue from 'components/CopyableValue';
import { getModelArtifact, getServingModelDetails } from 'store/modelBuilder/asyncThunks';
import { useApiGet } from 'hooks/network';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { Model, ProdIntent } from 'store/modelBuilder/state';
import {
  selectModelArtifactByName,
  selectLoadServingModelStatus,
  selectOpenedArtifacts,
  selectProdIntents,
  selectServingModels,
} from 'store/modelBuilder/selectors';
import { ApiStatus } from 'store/types';
import { getId, getParent } from 'common/resourceName';
import { createModelProject, listModelProjects } from 'store/modelProject/asyncThunks';
import { selectModelProjectStatus, selectModelProjects, selectModelProjectById } from 'store/modelProject/selectors';
import { ModelProject, ModelProjectStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { ModelArtifact, ModelArtifactArtifactType, ModelArtifactStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import moment from 'moment';
import { UserContext } from 'context/UserContext';
import { Button, Space } from '@mantine/core';
import { CheckAIServices } from 'pages/Deployments/CheckAIServices';
import { ModelingTask } from './ModelingTask';
import { useModelArtifacts, KeyedModelArtifact, ResourceCreationArgs } from './hooks';
import { ResourceCreationForm } from './ResourceCreationForm';
import { ExistingResourceTable } from './ExistingResourceTable';
import { OktaAuthContext } from '../../context/OktaAuthContext';
import styles from './Workspace.module.scss';
import { RegexTable } from './RegexTable';

const { Panel } = Collapse;

/**
 * Refer to the README for the generic task/project data model for modeling ops
 * as well as the specific intent + policy API.
 */
export function ModelBuilderWorkspace() {
  const dispatch = useDispatch();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const { currentConfig } = useContext(CustomerConfigContext);
  const { customerShortName } = currentConfig;
  const customerProfile = useCustomerProfile();
  const [projectId, setProjectId, clearProjectId] = useUrlParam<string>('project');
  const project = Number(projectId);
  const [, , clearTaskIds] = useUrlParam<string>('taskIds');
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  useEffect(() => {
    dispatch(listModelProjects({
      parent: customerProfile,
      usecaseId,
      languageCode,
    }));
  }, [path]);
  const projects = useSelector<ModelProject[]>(selectModelProjects);

  const [openArtifact, setOpenArtifact] = useState<string>('');
  const modelArtifact = useSelector<ModelArtifact>(selectModelArtifactByName(openArtifact));
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handleTaskOpen = (a: KeyedModelArtifact) => {
    setOpenArtifact(a.name);
    dispatch(getModelArtifact(a?.name));
    setDrawerOpen(true);
  };

  // Deployment summary.
  const apiGet = useApiGet(false);
  const getter = () => apiGet(`${path}/get_serving_model_details`);
  const apiStatus = useSelector<ApiStatus>(selectLoadServingModelStatus);
  useEffect(() => {
    if (customerProfile) {
      dispatch(getServingModelDetails({
        customerProfile,
        getter,
      }));
    }
  }, [customerProfile]);

  const models: Model[] = useSelector<Model[]>(selectServingModels);

  const columns: ColumnsType<Model> = [
    {
      title: 'Type',
      dataIndex: 'displayType',
      key: 'displayType',
      width: '10%',
    },
    {
      title: 'Model URL',
      dataIndex: 'url',
      key: 'url',
      render: (value) => {
        const valid = value?.startsWith(customerShortName);
        return (
          <div className={classNames([valid ? '' : styles.highlighted, styles.modelUrl])}>
            {value && (
              <CopyableValue
                displayValue={value}
                copiedValue={value}
                tooltip={valid ? '' : 'The deployed model prefix does not match the customer domain'}
              />
            )}
          </div>
        );
      },
      width: '25%',
    },
    {
      title: 'Test Weighted F1',
      dataIndex: ['modelMetrics', 'f1'],
      key: 'f1',
      render: (value, record) => (
        <Link to={`/${path}/model-builder?${getTaskParams(record.evaluationArtifact)}`}>{value}</Link>
      ),
      width: '10%',
    },
    {
      title: 'Test Weighted Precision',
      dataIndex: ['modelMetrics', 'precision'],
      key: 'precision',
      render: (value, record) => (
        <Link to={`/${path}/model-builder?${getTaskParams(record.evaluationArtifact)}`}>{value}</Link>
      ),
      width: '10%',
    },
    {
      title: 'Test Weighted Recall',
      dataIndex: ['modelMetrics', 'recall'],
      key: 'recall',
      render: (value, record) => (
        <Link to={`/${path}/model-builder?${getTaskParams(record.evaluationArtifact)}`}>{value}</Link>
      ),
      width: '10%',
    },
    {
      title: 'Test Dataset Support',
      dataIndex: ['modelMetrics', 'support'],
      key: 'support',
      render: (value, record) => (
        <Link to={`/${path}/model-builder?${getTaskParams(record.evaluationArtifact)}`}>{value}</Link>
      ),
      width: '10%',
    },
    {
      title: 'Dependencies',
      dataIndex: 'dependencies',
      key: 'dependencies',
      render: (value) => (
        <div className={classNames([styles.dependencyUrls])}>
          {value?.map((url) => {
            const valid = url?.startsWith(customerShortName);
            return (
              <div key={url} className={classNames([valid ? '' : styles.highlighted, styles.modelUrl])}>
                {url && (
                  <CopyableValue
                    displayValue={url}
                    copiedValue={url}
                    tooltip={valid ? '' : 'The deployed model prefix does not match the customer domain'}
                  />
                )}
              </div>
            );
          })}
        </div>
      ),
      width: '25%',
    },
  ];

  const renderSummary = () => {
    if (apiStatus === 'loading') {
      return <Spin />;
    }
    return (
      <>
        <Table
          title={() => 'Summary of Models in Production'}
          columns={columns}
          dataSource={models}
          size="middle"
          pagination={false}
        />
        <Space />
        <CheckAIServices />
      </>
    );
  };

  if (projectId && Number.isNaN(project)) {
    clearProjectId();
    return <></>;
  }
  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <div className={styles.header}>
        <h1 className={styles.title}>Model Builder</h1>
        <Button
          onClick={() => setCreateProjectModalOpen(true)}
        >
          New project
        </Button>
      </div>
      Project&nbsp;
      <Select
        className={classNames([styles.projectSelect])}
        value={projectId || ''}
        onChange={(projectId) => {
          clearTaskIds();
          setProjectId(projectId);
        }}
        showArrow
      >
        <Select.Option key="0" value="">Serving Model Summary</Select.Option>
        {projects?.map((project) => (
          <Select.Option
            key={project.name}
            value={getId('modelProject', project.name)}
          >
            {`${getId('modelProject', project.name)}. ${project.displayName} ${moment(project.createTime).format('YYYY-MM-DD hh:mm A')}`}
          </Select.Option>
        ))}
      </Select>
      {projectId && <ProjectWorkspace onOpenArtifact={handleTaskOpen} projectNumber={project} />}
      {!projectId && renderSummary()}
      <Drawer
        className={styles.taskDrawer}
        title={`Task ${getId('modelArtifact', openArtifact)} Detail`}
        width={1000}
        placement="right"
        closable={false}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        getContainer={false}
      >
        {modelArtifact ? (<ModelingTask modelArtifact={modelArtifact} />) : (<div>Task is null.</div>)}
      </Drawer>
      <CreateProjectModalForm
        isOpen={createProjectModalOpen}
        onClose={() => setCreateProjectModalOpen(false)}
      />
    </div>
  );
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}
export function CreateProjectModalForm({ isOpen, onClose }: IProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { languageCode, path } = useCustomerParams();
  const currentUser = useContext(UserContext);
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const handleSubmit = useCallback(() => {
    const projectName = form.getFieldValue('project-name');
    const modelProject: ModelProject = {
      displayName: projectName,
      status: ModelProjectStatus.IN_PROGRESS,
      creator: currentUser?.name,
      usecase,
      languageCode,
    };
    dispatch(createModelProject({
      parent: customerProfile,
      modelProject,
    })).then((res) => {
      const { name } = res.payload as {
        name: string
      };
      navigate(`/${path}/model-builder?project=${getId('modelProject', name)}`);
      onClose();
    });
  }, [path, currentUser, dispatch, navigate]);
  const { data: { email } } = useContext(OktaAuthContext);
  const sharedProps = {
    style: { width: '100%' },
    defaultValue: email,
  };

  return (
    <Modal
      open={isOpen}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Project Name"
          name="project-name"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[{ required: false, message: '' }]}
        >
          <Input {...sharedProps} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const RESOUCES: ModelArtifactArtifactType[] = [
  ModelArtifactArtifactType.TAXONOMY,
  ModelArtifactArtifactType.DATASET,
  ModelArtifactArtifactType.SUGGESTIONS_DATASET,
  ModelArtifactArtifactType.NEURAL_CLASSIFIER,
  ModelArtifactArtifactType.REGEX_CLASSIFIER,
  ModelArtifactArtifactType.STACKED_CLASSIFIER,
  ModelArtifactArtifactType.SUGGESTIONS_MODEL,
  ModelArtifactArtifactType.SUGGESTIONS_ENSEMBLE,
  ModelArtifactArtifactType.EVALUATION,
  ModelArtifactArtifactType.REGRESSION,
  ModelArtifactArtifactType.DEPLOYMENT,
];

interface ProjectWorkspaceProps {
  onOpenArtifact: (t: KeyedModelArtifact) => void;
  projectNumber: number;
}
/**
 * Handles data fetching and task polling for a particular project.
 */
function ProjectWorkspace({ onOpenArtifact, projectNumber }: ProjectWorkspaceProps): ReactElement {
  const [tab, setTab] = useUrlParam<ModelArtifactArtifactType>('tab', ModelArtifactArtifactType.TAXONOMY);
  const [, , clearTaskIds] = useUrlParam<string>('taskIds');
  const [artifacts, loading, createResource, archiveArtifacts] = useModelArtifacts(projectNumber, tab);
  const modelProjectStatus = useSelector<ApiStatus>(selectModelProjectStatus);
  const currentProject = useSelector<ModelProject>(selectModelProjectById(String(projectNumber)));

  const items = RESOUCES.map((r) => ({
    key: r,
    label: r.toLowerCase(),
    children: (
      <ResourceWorkspace
        projectId={projectNumber}
        currentProject={currentProject}
        artifactType={r}
        artifacts={artifacts}
        createResource={createResource}
        archiveArtifacts={archiveArtifacts}
        onOpenArtifact={onOpenArtifact}
      />
    ),
  }));

  if (loading) return <Spin />;

  if (modelProjectStatus === 'loading') return <Spin />;
  if (modelProjectStatus === 'failed') return <div>Failed to load projects!</div>;
  const namespace = 'cresta-api';
  return (
    <>
      <Divider orientation="center" plain>
        Workspace (Project {projectNumber}: {currentProject?.displayName}. Created at {moment(currentProject?.createTime).format('YYYY-MM-DD hh:mm A')}) <a target="_blank" rel="noreferrer" href={`https://app.datadoghq.com/logs?event&query=kube_namespace%3A${namespace}+service%3Astudio`} className={styles.inlineLink}>datadog event logs link </a>
      </Divider>
      <Tabs
        defaultActiveKey={tab}
        onTabClick={(key) => {
          clearTaskIds();
          setTab(key as ModelArtifactArtifactType);
        }}
        items={items}
      />
    </>
  );
}

interface IResourceWorkspace {
  projectId: number,
  currentProject: ModelProject,
  artifactType: ModelArtifactArtifactType,
  artifacts: KeyedModelArtifact[],
  createResource: (args: ResourceCreationArgs) => Promise<void>,
  archiveArtifacts: (ids: string[]) => Promise<void>,
  onOpenArtifact: (artifact: KeyedModelArtifact) => void,
}

/**
 * Project has many Tasks which encapsulate Resources.
 * This component contains creation form + displays creation jobs + results for one resource type.
 */
const ResourceWorkspace = ({
  projectId,
  currentProject,
  artifactType,
  artifacts,
  createResource,
  archiveArtifacts,
  onOpenArtifact,
}: IResourceWorkspace) => {
  const { profileId } = useCustomerParams();
  const { currentConfig: { customerShortName } } = useContext(CustomerConfigContext);
  const dispatch = useDispatch();
  const displayArtifactType = artifactType?.toLowerCase() || '';
  const [taskIds, setTaskIds] = useUrlParam<string>('taskIds', '');
  const artifactIds: string[] = useMemo(() => (taskIds ? taskIds.split(',') : []), [taskIds]);

  const [panelActiveKey, setPanelActiveKey] = useState<string[]>(['existing']);
  const onPanelActiveKeyChange = (key: string | string[]) => {
    if (typeof key === 'string') {
      setPanelActiveKey([key]);
    } else {
      setPanelActiveKey(key);
    }
  };
  // State that is extracted from the created form and can potentially be used in all resources
  // to change the form dynamically.
  const [modelType, setModelType] = useState<string>('next_agent_intent_ensemble');
  // As of 2022/06/13 we only default to the gRPC intent for voice because the new stack
  // has not been fully turned up yet in chat. customer.includes('voice')
  const initialUseGrpcIntents = profileId.includes('voice');
  const [useGrpcIntents, setUseGrpcIntents] = useState<boolean>(initialUseGrpcIntents);
  const [stackPositiveAndNegative, setStackPositiveAndNegative] = useState<boolean>(false);
  const handleValuesChange = (a: ModelArtifact) => {
    const modelType = a.suggestionsModelArtifact?.inputs?.modelType?.toLowerCase()
      || (a.regressionArtifact?.inputs?.kwargs || {})['model_type']
      || (a.deploymentArtifact?.inputs?.kwargs || {})['model_type'];
    const grpcIntents = (a.regressionArtifact?.inputs?.kwargs || {})['use_grpc_intents'];
    const stack = (a.regexClassifierArtifact?.inputs?.kwargs || {})['stack_positive_and_negative'];
    if (modelType) {
      setModelType(modelType);
    }
    if (grpcIntents !== undefined) {
      setUseGrpcIntents(grpcIntents);
    }
    if (stack !== undefined) {
      setStackPositiveAndNegative(stack);
    }
  };
  const formValuesChange = (values: any) => {
    const { model_type, use_grpc_intents, stack_positive_and_negative } = values;
    if (model_type !== undefined) {
      setModelType(model_type);
    }
    if (use_grpc_intents !== undefined) {
      setUseGrpcIntents(use_grpc_intents);
    }
    if (stack_positive_and_negative !== undefined) {
      setStackPositiveAndNegative(stack_positive_and_negative);
    }
  };
  // The form was extracted from ResourceCreationForm so we copy values from existing tasks.
  const [resourceCreationForm] = Form.useForm();
  const onCopyToCreate = (a: KeyedModelArtifact) => {
    let params = {};
    switch (a.artifactType) {
      case ModelArtifactArtifactType.TAXONOMY:
        params = a.taxonomyArtifact?.inputs?.kwargs || {};
        break;
      case ModelArtifactArtifactType.DATASET:
        params = a.datasetArtifact?.inputs?.kwargs || {};
        params['intents'] = params['actions'];
        break;
      case ModelArtifactArtifactType.SUGGESTIONS_DATASET: {
        const suggestionsDatasetInputs = a.suggestionsDatasetArtifact?.inputs;
        params = {
          start_date: moment(suggestionsDatasetInputs?.startTime),
          end_date: moment(suggestionsDatasetInputs?.endTime),
          test_set_days_suffix: suggestionsDatasetInputs?.testSetDaysSuffix,
          split_ratio_train: suggestionsDatasetInputs?.splitRatioTrain,
          add_experts: suggestionsDatasetInputs?.addExperts,
        };
        break;
      }
      case ModelArtifactArtifactType.NEURAL_CLASSIFIER: {
        const neuralInputs = a.neuralClassifierArtifact?.inputs;
        params = {
          intent_task_type: neuralInputs?.type?.toLowerCase()?.replaceAll('_', '-'),
          train_dataset_urls: neuralInputs?.trainDatasets?.map((d) => d.uri),
          test_dataset_urls: neuralInputs?.testDatasets?.map((d) => d.uri),
          model_type: (neuralInputs?.kwargs || {})['model_type'],
          is_contextual_model: (neuralInputs?.kwargs || {})['is_contextual_model'],
        };
        break;
      }
      case ModelArtifactArtifactType.REGEX_CLASSIFIER: {
        const regexInputs = a.regexClassifierArtifact?.inputs;
        params = regexInputs?.kwargs || {};
        break;
      }
      case ModelArtifactArtifactType.STACKED_CLASSIFIER: {
        const stackedInputs = a.stackedClassifierArtifact?.inputs;
        params = stackedInputs?.kwargs || {};
        break;
      }
      case ModelArtifactArtifactType.SUGGESTIONS_MODEL: {
        const suggestionsModelInputs = a.suggestionsModelArtifact?.inputs;
        params = {
          model_type: suggestionsModelInputs?.modelType?.toLowerCase(),
          suggestions_dataset: suggestionsModelInputs?.suggestionsDataset,
          experts_should_finetune: suggestionsModelInputs?.expertsShouldFinetune,
        };
        break;
      }
      case ModelArtifactArtifactType.SUGGESTIONS_ENSEMBLE: {
        const suggestionsEnsembleInputs = a.suggestionsEnsembleArtifact?.inputs;
        params = {
          generative_gpt_model_path: suggestionsEnsembleInputs?.generativeGptModelPath,
          generative_t5_model_path: suggestionsEnsembleInputs?.generativeT5ModelPath,
          retriever_model_path: suggestionsEnsembleInputs?.retrieverModelPath,
          canned_msg_trie_path: suggestionsEnsembleInputs?.cannedMsgTriePath,
          materialize_configs: suggestionsEnsembleInputs?.materializeConfigs,
          load_preprocessing_from_config_repo: suggestionsEnsembleInputs?.loadPreprocessingFromConfigRepo,
          load_postprocessing_from_config_repo: suggestionsEnsembleInputs?.loadPostprocessingFromConfigRepo,
          use_ensemble_model_urls: suggestionsEnsembleInputs?.useEnsembleModelUrls,
        };
        break;
      }
      case ModelArtifactArtifactType.EVALUATION: {
        const evalInputs = a.evaluationArtifact?.inputs;
        params = {
          model_url: evalInputs?.modelUri,
          chat_ids: evalInputs?.chatIds,
          train_dataset_urls: evalInputs?.trainDatasets?.map((d) => d.uri),
          test_dataset_urls: evalInputs?.testDatasets?.map((d) => d.uri),
        };
        break;
      }
      case ModelArtifactArtifactType.REGRESSION: {
        const regressionInputs = a.regressionArtifact?.inputs;
        params = regressionInputs?.kwargs || {};
        break;
      }
      case ModelArtifactArtifactType.DEPLOYMENT: {
        const deploymentInputs = a.deploymentArtifact?.inputs;
        params = deploymentInputs?.kwargs || {};
        break;
      }
      default:
    }

    resourceCreationForm.setFieldsValue(params);
    // antd doesn't trigger onValuesChange when using setFieldsValue, so call it manually.
    handleValuesChange(a);
    if (!('new' in panelActiveKey)) {
      setPanelActiveKey([...panelActiveKey, 'new']);
    }
  };

  const handleArchiveArtifacts = useCallback(() => archiveArtifacts(artifactIds), [artifactIds, archiveArtifacts]);

  const prodIntents = useSelector<ProdIntent[]>(selectProdIntents);

  const completedArtifacts = useMemo(() => artifacts.filter(({ status }) => (status === ModelArtifactStatus.ACTIVE)), [artifacts]);
  const completedIdsSet = useMemo(() => new Set(completedArtifacts.map((a) => a.key)), [completedArtifacts]);
  const selectedCompletedIds = useMemo(() => artifactIds.filter((n) => completedIdsSet.has(n)), [artifactIds, completedIdsSet]);

  const WIPArtifacts = useMemo(() => artifacts.filter(({ status }) => status === ModelArtifactStatus.ACCEPTED), [artifacts]);
  const wipIdsSet = useMemo(() => new Set(WIPArtifacts.map((t) => t.key)), [WIPArtifacts]);
  const selectedWIPIds = useMemo(() => artifactIds.filter((n) => wipIdsSet.has(n)), [artifactIds, wipIdsSet]);

  const failedArtifacts = useMemo(() => artifacts.filter(({ status }) => status === ModelArtifactStatus.FAILED), [artifacts]);
  const failedIdsSet = useMemo(() => new Set(failedArtifacts.map((a) => a.key)), [failedArtifacts]);
  const selectedFailedIds = useMemo(() => artifactIds.filter((n) => failedIdsSet.has(n)), [artifactIds, failedIdsSet]);

  const setSelectedInProgressIds = useCallback(
    (value: React.Key[]) => setTaskIds([...value, ...selectedCompletedIds, ...selectedFailedIds].join(',')),
    [selectedCompletedIds, selectedFailedIds],
  );
  const setSelectedFailedIds = useCallback(
    (value: React.Key[]) => setTaskIds([...value, ...selectedCompletedIds, ...selectedWIPIds].join(',')),
    [selectedCompletedIds, selectedWIPIds],
  );
  const setSelectedCompletedIds = useCallback(
    (value: React.Key[]) => setTaskIds([...value, ...selectedFailedIds, ...selectedWIPIds].join(',')),
    [selectedFailedIds, selectedWIPIds],
  );
  const selectedArtifacts = useMemo(() => artifacts.filter(
    ({ key }) => (artifactIds.includes(key)),
  ), [artifacts, artifactIds]);

  useEffect(() => {
    selectedArtifacts.forEach((a) => {
      dispatch(getModelArtifact(a.name));
    });
  }, [selectedArtifacts]);
  const openedArtifacts = useSelector<ModelArtifact[]>(selectOpenedArtifacts);

  const taskDetailPanels = useMemo(() => selectedArtifacts.map((ar) => (
    <Panel header={`Selected task ${ar.key}`} key={ar.key} >
      <ModelingTask modelArtifact={openedArtifacts.find((a) => a.name === ar.name)} />
    </Panel>
  )), [selectedArtifacts, openedArtifacts]);

  const createWarningsByResource = {
    [ModelArtifactArtifactType.SUGGESTIONS_DATASET]: (
      <>
        In order to extract chats from experts, you need to specify an
        <a href={`https://github.com/cresta/config/blob/master/chat-ai/customers/${customerShortName}/experts.yaml`} className={styles.inlineLink}> experts.yaml in the config repo </a>
        similar to
        <a href="https://github.com/cresta/config/blob/master/chat-ai/customers/chat-demo/experts.yaml" className={styles.inlineLink}> chat-demo/experts.yaml</a>.

        If this config does not exist, dataset gen will fail open and not extract experts.
      </>
    ),
    [ModelArtifactArtifactType.SUGGESTIONS_MODEL]: (
      <>
        You need to specify pre-and postprocessing configs in the config repo before training in
        <a href={`https://github.com/cresta/config/blob/master/chat-ai/customers/${customerShortName}/preprocessing-config.yaml`} className={styles.inlineLink}> preprocessing-config.yaml </a>
        and
        <a href={`https://github.com/cresta/config/blob/master/chat-ai/customers/${customerShortName}/postprocessing-config.yaml`} className={styles.inlineLink}> postprocessing-config.yaml</a>.

        If these configs do not exist yet, you can take
        <a href="https://github.com/cresta/config/blob/master/chat-ai/customers/chat-demo" className={styles.inlineLink}> chat-demo </a>
        as example.
      </>
    ),
    [ModelArtifactArtifactType.SUGGESTIONS_ENSEMBLE]: (
      <>
        Note that this will materialize any pre-and post-processing configs in the master branch of the config repo for this customer into the model.
      </>
    ),
  };
  const formArgsByResource = {
    [ModelArtifactArtifactType.TAXONOMY]: [
      { label: 'Agent Intents', name: 'agent_intents', type: 'selectAgentIntents', required: false },
      { label: 'Visitor Intents', name: 'visitor_intents', type: 'selectVisitorIntents', required: false },
      { label: 'Chat Drivers', name: 'chat_drivers', type: 'selectChatDrivers', required: false },
    ],
    [ModelArtifactArtifactType.DATASET]: [
      { label: 'Task Type', name: 'intent_task_type', type: 'selectTask', required: true },
      { label: 'Intents', name: 'intents', type: 'selectProjectIntents', required: false },
      { label: 'Version', name: 'version', type: 'input', placeholder: 'an optional string to override dataset version (cannot contain ":").' },
    ],
    [ModelArtifactArtifactType.SUGGESTIONS_DATASET]: [
      { label: 'Start date', name: 'start_date', type: 'date', required: true },
      { label: 'End date', name: 'end_date', type: 'date', required: true },
      { label: 'Test set days', name: 'test_set_days_suffix', type: 'input', placeholder: 'Days at the end of [start_date, end) to use as test set. Defaults to 7 days.' },
      { label: 'Training set ratio', name: 'split_ratio_train', type: 'input', placeholder: 'Fraction of non-test data to use as training set (split from validation set).' },
      { label: 'Extract experts', name: 'add_experts', type: 'checkbox', default: false, help: 'Whether to also extract train/eval/test sets containing just expert agent conversations. Experts are configured in the `experts.yaml` in the config repo.' },
      { label: 'Version', name: 'version', type: 'input', placeholder: 'Optional override for the dataset version (the dataset "name"). Auto-created by default.' },
      { label: 'Chat AI image tag', name: 'chat_ai_image_tag', type: 'input', placeholder: 'Optional override for dataset gen script image. Defaults to "master".' },
    ],
    [ModelArtifactArtifactType.NEURAL_CLASSIFIER]: [
      { label: 'Task Type', name: 'intent_task_type', type: 'selectTask', required: true },
      { label: 'train_dataset_urls', name: 'train_dataset_urls', type: 'SelectTrainingDatasetUrl', required: true },
      { label: 'test_dataset_urls', name: 'test_dataset_urls', type: 'SelectTestDatasetUrl', required: true },
      { label: 'model_type', name: 'model_type', type: 'radio', options: ['bert', 'fic', 'deberta', 'mdeberta'], required: true },
      { label: 'is_contextual_model', name: 'is_contextual_model', type: 'checkbox', default: false, help: 'Enable hyper parameters tuning for contextual intents', hidden: !['bert', 'deberta', 'mdeberta'].includes(modelType) },
      { label: 'chat-ai image', name: 'image', type: 'input', placeholder: 'optional. default "master".' },
      { label: 'config-override', name: 'config_override', type: 'input', placeholder: 'optional. e.g., "flag1:value1,flag2:value2".' },
    ],
    [ModelArtifactArtifactType.REGEX_CLASSIFIER]: [
      { label: 'Task Type', name: 'intent_task_type', type: 'selectTask', required: true },
      { label: 'Regex Source', name: 'regex_source', type: 'radio', options: ['studio_concept_db'], required: true },
      { label: 'Intents', name: 'intents', type: 'selectProjectIntents', required: false },
      { label: 'stack_positive_and_negative', name: 'stack_positive_and_negative', type: 'checkbox', default: false, help: 'If true, construct two regex classifiers; one from positive regex rules, one from negative, resp. Then construct a stacked one.' },
      { label: 'build_negative_cls', name: 'is_negative_classifier', type: 'checkbox', default: false, required: false, hidden: stackPositiveAndNegative, help: 'Only check this if stack_positive_and_negative is false.' },
      { label: 'chat_ids', name: 'chat_ids', type: 'SelectChatIds', required: false },
      { label: 'train_dataset_urls', name: 'train_dataset_urls', type: 'SelectTrainingDatasetUrl', placeholder: 'optional. If non-empty, eval the classifier.' },
      { label: 'test_dataset_urls', name: 'test_dataset_urls', type: 'SelectTestDatasetUrl', placeholder: 'optional. If non-empty, eval the classifier.' },
    ],
    [ModelArtifactArtifactType.STACKED_CLASSIFIER]: [
      { label: 'neural_classifier_urls', name: 'neural_classifier_urls', type: 'selectNeuralClassifier', placeholder: 'default empty', required: false },
      { label: 'regex_classifier_urls', name: 'regex_classifier_urls', type: 'SelectRuleActionClassifier', placeholder: 'default empty', required: false },
      { label: 'Task Type', name: 'intent_task_type', type: 'selectTask', required: false },
    ],
    [ModelArtifactArtifactType.SUGGESTIONS_MODEL]: [
      { label: 'Model type', name: 'model_type', type: 'radio', options: ['gpt2', 't5'], required: true },
      { label: 'Dataset', name: 'suggestions_dataset', type: 'selectSuggestionsDataset', placeholder: 'Dataset to use in s3://cresta-data-pci/gpt/...', required: true },
      { label: 'Finetune on experts if possible', name: 'experts_should_finetune', type: 'checkbox', default: true, hidden: modelType === 't5' },
      { label: 'Chat AI image tag', name: 'chat_ai_image_tag', type: 'input', placeholder: 'Optional override for dataset gen script image. Defaults to "master".' },
    ],
    [ModelArtifactArtifactType.SUGGESTIONS_ENSEMBLE]: [
      { label: 'GPT model path', name: 'generative_gpt_model_path', placeholder: 'Path', type: 'input' },
      { label: 'T5 model path', name: 'generative_t5_model_path', placeholder: 'Path', type: 'input' },
      { label: 'Retriever model path', name: 'retriever_model_path', type: 'input', placeholder: 'Path' },
      {
        label: 'Use ensemble model urls',
        name: 'use_ensemble_model_urls',
        type: 'checkbox',
        initialValue: false,
        help: 'Set to true if the input model path are ensemble model urls. If true, both generative model and retriever model are required. The pre- and postprocessing configs from the generative model will be used in the output ensemble. The following parameters are ignored.',
      },
      { label: 'Canned message trie path', name: 'canned_msg_trie_path', type: 'input', placeholder: 'Path' },
      { label: 'Load preprocessing from config repo', name: 'load_preprocessing_from_config_repo', type: 'checkbox', initialValue: false, help: 'If true, load the preprocessing config from the config repo for this customer. Otherwise, a default preprocessing config is used.' },
      { label: 'Load postprocessing from config repo', name: 'load_postprocessing_from_config_repo', type: 'checkbox', initialValue: false, help: 'If true, load the postprocessing config from the config repo for this customer. Otherwise, a default preprocessing config is used.' },
      { label: 'Materialize configs', name: 'materialize_configs', type: 'checkbox', initialValue: true, help: 'If true, materialize pre- and post-processing configs in the master branch of the config repo for this customer into the model.' },
    ],
    [ModelArtifactArtifactType.EVALUATION]: [
      { label: 'model_url', name: 'model_url', type: 'input', required: true },
      { label: 'chat_ids', name: 'chat_ids', type: 'SelectChatIds', required: false },
      { label: 'train_dataset_urls', name: 'train_dataset_urls', type: 'SelectTrainingDatasetUrl', required: false },
      { label: 'test_dataset_urls', name: 'test_dataset_urls', type: 'SelectTestDatasetUrl', required: false },
      { label: 'chat-ai image', name: 'image', type: 'input', placeholder: 'optional. default "master".' },
    ],
    [ModelArtifactArtifactType.REGRESSION]: [
      {
        label: 'model_type',
        name: 'model_type',
        type: 'radio',
        options: ['next_agent_intent_ensemble', 'fast_intent_classifier', 'stacked_classifier'],
        required: true,
      },
      {
        label: 'stacked_agent_model_url',
        name: 'stacked_agent_model_url',
        type: 'input',
        hidden: modelType !== 'stacked_classifier' && modelType !== 'next_agent_intent_ensemble',
      },
      {
        label: 'stacked_visitor_model_url',
        name: 'stacked_visitor_model_url',
        type: 'input',
        hidden: modelType !== 'stacked_classifier' && modelType !== 'next_agent_intent_ensemble',
      },
      {
        label: 'stacked_chat_driver_model_url',
        name: 'stacked_chat_driver_model_url',
        type: 'input',
        hidden: modelType !== 'stacked_classifier' && modelType !== 'next_agent_intent_ensemble',
      },
      {
        label: 'rule_policy_url',
        name: 'rule_policy_url',
        type: 'input',
        placeholder: 'Rule policy URL, e.g., `cox-sales/rule-based-dialog-policy:cf7575fba`.',
        hidden: !useGrpcIntents && modelType !== 'next_agent_intent_ensemble',
      },
      { label: 'use_grpc_intents', name: 'use_grpc_intents', type: 'checkbox', initialValue: initialUseGrpcIntents, hidden: modelType === 'next_agent_intent_ensemble', help: 'If true, use the gRPC intents + policy engine stack for intent models.' },
      { label: 'chat_ai_image_tag', name: 'chat_ai_image_tag', type: 'input', placeholder: 'Optional override for test script image. Defaults to "master".', required: false },
      { label: 'actions_image_tag', name: 'actions_image_tag', type: 'input', placeholder: 'Optional override for actions server image. Defaults to same tag as in the namespace.', required: false },
      { label: 'orchestrator_image_tag', name: 'orchestrator_image_tag', type: 'input', placeholder: 'Optional override for Orchestrator image. Defaults to same tag as in the namespace.', required: false },
      { label: 'intent_image_tag', name: 'intent_image_tag', type: 'input', placeholder: 'Optional override for Intent image. Defaults to same tag as in the namespace.', required: false },
      { label: 'policy_image_tag', name: 'policy_image_tag', type: 'input', placeholder: 'Optional override for Policy Engine image. Defaults to same tag as in the namespace.', required: false },
    ],
    [ModelArtifactArtifactType.DEPLOYMENT]: [
      { label: 'model_type', name: 'model_type', type: 'radio', options: ['next_agent_intent_ensemble', 'stacked_classifier', 'dialogue_policy'], required: true },
      {
        label: 'stacked_agent_model_url',
        name: 'stacked_agent_model_url',
        type: 'input',
        required: modelType === 'stacked_classifier' || modelType === 'next_agent_intent_ensemble',
        hidden: modelType !== 'stacked_classifier' && modelType !== 'next_agent_intent_ensemble',
      },
      {
        label: 'stacked_visitor_model_url',
        name: 'stacked_visitor_model_url',
        type: 'input',
        required: false,
        hidden: modelType !== 'stacked_classifier' && modelType !== 'next_agent_intent_ensemble',
      },
      {
        label: 'stacked_chat_driver_model_url',
        name: 'stacked_chat_driver_model_url',
        type: 'input',
        required: false,
        hidden: modelType !== 'stacked_classifier' && modelType !== 'next_agent_intent_ensemble',
      },
      {
        label: 'rule_based_dialogue_policy_url',
        name: 'rule_based_dialogue_policy_url',
        type: 'input',
        required: true,
        hidden: modelType !== 'dialogue_policy' && modelType !== 'next_agent_intent_ensemble',
        placeholder: 'e.g., [customer]/rule-based-dialogue-policy:342av12',
        help: 'url for rule based dialogue policy (currently created outside of Studio by AI delivery devs)',
      },
      { label: 'gpt_model_url', name: 'gpt_model_url', type: 'selectSuggestionsGPTModel', required: modelType === 'suggestions', hidden: modelType !== 'suggestions', help: 'GPT model to deploy for suggestions + smartcompose' },
      { label: 't5_model_url', name: 't5_model_url', type: 'selectSuggestionsT5Model', hidden: modelType !== 'suggestions', help: 'Optional T5 model to deploy only for suggestions instead of the GPT model (not deployed to smartcompose!)' },
      { label: 'dry_run', name: 'dry_run', type: 'radio', options: ['true', 'false'], required: true, help: 'if true, only verify deployable; if false, update db/taxonomy/Config/flux.' },
      { label: 'skip_regression_test_check', name: 'skip_regression_test_check', type: 'checkbox', initialValue: false, help: 'Use with caution! If true, skip checking models have a regression test task.' },
      { label: 'skip_taxonomy_compatibility_check', name: 'skip_taxonomy_compatibility_check', type: 'checkbox', initialValue: false, help: 'Use with caution! If true, skip checking models taxonomy compatibility.' },
    ],
  };

  const bodyArgs = { project_id: projectId };

  return (
    <Collapse activeKey={panelActiveKey} onChange={onPanelActiveKeyChange} ghost>
      {artifactType === ModelArtifactArtifactType.REGEX_CLASSIFIER && (
        <Panel header="Taxonomy" key="taxonomy">
          <RegexTable selectedIntents={currentProject?.metadata?.intents} />
        </Panel>
      )}
      <Panel header={`Create new ${displayArtifactType}`} key="new">
        <ResourceCreationForm
          form={resourceCreationForm}
          resourceRoute={`intents/${displayArtifactType}`}
          formBodyArgs={formArgsByResource[artifactType]}
          bodyArgs={bodyArgs}
          currentProject={currentProject}
          createResource={createResource}
          onValuesChangeCallback={formValuesChange}
          prodIntents={prodIntents}
          warning={createWarningsByResource[artifactType]}
        />
      </Panel>
      {taskDetailPanels}
      <Panel
        header={(
          <div>
            <span>Existing ${displayArtifactType} -- select rows to see details</span>
            <Button
              disabled={!artifactIds.length}
              onClick={handleArchiveArtifacts}
              style={{
                marginLeft: '10px',
              }}
              color="red"
            >
              Archive Selected Tasks
            </Button>
          </div>
        )}
        key="existing"
      >
        <h3>WIP {displayArtifactType}:</h3>
        <ExistingResourceTable
          tasks={WIPArtifacts}
          artifactType={artifactType}
          selectedResources={selectedWIPIds}
          setSelectedResources={setSelectedInProgressIds}
          onCopyToCreate={onCopyToCreate}
          onOpenTask={onOpenArtifact}
        />
        <br />
        <h3>Completed {displayArtifactType}:</h3>
        <ExistingResourceTable
          tasks={completedArtifacts}
          artifactType={artifactType}
          selectedResources={selectedCompletedIds}
          setSelectedResources={setSelectedCompletedIds}
          onCopyToCreate={onCopyToCreate}
          onOpenTask={onOpenArtifact}
        />
        <br />
        {artifactType === ModelArtifactArtifactType.TAXONOMY && (
          <RegexTable selectedIntents={currentProject?.metadata?.intents} />
        )}
        <h3>Failed tasks (if any):</h3>
        {failedArtifacts.length !== 0
          && (
            <ExistingResourceTable
              tasks={failedArtifacts}
              artifactType={artifactType}
              selectedResources={selectedFailedIds}
              setSelectedResources={setSelectedFailedIds}
              forFailedTasks
              onCopyToCreate={onCopyToCreate}
              onOpenTask={onOpenArtifact}
            />
          )}
      </Panel>
    </Collapse>
  );
};

function getTaskParams(artifactName: string): string {
  const project = getParent('modelArtifact', artifactName);
  const taskId = getId('modelArtifact', artifactName);
  const projectId = getId('modelProject', project);
  return `tab=EVALUATION&project=${projectId}&taskIds=${encodeURIComponent('||')}${taskId}`;
}
