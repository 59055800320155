import React, { FC, useEffect, useMemo, useState } from 'react';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { SimulationTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { useSelector } from 'hooks/reduxHooks';
import { selectUsers } from 'store/user/selectors';
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, UnstyledButton } from '@mantine/core';
import { Button as AntButton, Input, Select, Radio } from 'antd';
import { getId } from 'common/resourceName';
import moment from 'moment';
import { User } from 'types';
import Loader from 'pages/Conversations/Loader';
import ConceptTag from 'components/ConceptTag';
import MultiTags from 'components/MultiTags';
import UserTag from 'components/UserTag';
import CopyableValue from 'components/CopyableValue';
import { truncateMiddle } from 'utils';
import { DialoguePolicyInstance } from 'hooks/useDialoguePolicy';
import useUrlParam from 'hooks/useUrlParam';
import { SimulatorResultTable } from './SimulatorResultTable';
import { ChatCard } from '../SimulatorModel/ChatCard';
import { ActiveToggleIcon, ToggleIcon } from '../icons';
import styles from './SimulatorResult.module.scss';
import { usePredictions } from './usePredictions';
import { EditorDrawer } from './EditorDrawer';
import { SimulatorIntentTable } from './SimulatorIntentTable';
import { useConversationSelect } from './useConversationSelect';

interface Props {
  simulationTask: SimulationTask;
  handleBack: () => void;
  handleTabChange: (activeKey: string) => void;
  dialoguePolicy: DialoguePolicyInstance;
  handleUpdateAndRun: () => void;
}
export const SimulatorResult: FC<Props> = ({
  simulationTask,
  handleBack,
  handleTabChange,
  dialoguePolicy,
  handleUpdateAndRun,
}) => {
  const [showList, setShowList] = useState(false);
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const [editingMode] = useUrlParam<string>('editingMode');
  const [coachingFilter, setCoachingFilter] = useState<string>('');
  const [detectionFilter, setDetectionFilter] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [tableType, setTableType] = useUrlParam<string>('tableType', 'Utterance');

  const conversations: Conversation[] = useMemo(() => simulationTask?.taskData?.conversations || [], [simulationTask]);
  const [
    conversationId,
    currentConversationIndex,
    conversation,
    setConversationId,
    handleNextConversation,
    handlePreviousConversation,
  ] = useConversationSelect(conversations);

  const [data, dataLoading] = usePredictions(conversation?.name, simulationTask);
  useEffect(() => {
    setDetectionFilter('');
    setCoachingFilter('');
    setNameFilter('');
  }, [conversation, simulationTask, tableType]);

  const detectionSelects: { intent: string, conceptRole: 'agent' | 'visitor' | 'driver' }[] = useMemo(() => {
    const intents = new Set();
    const results: { intent: string, conceptRole: 'agent' | 'visitor' | 'driver' }[] = [];
    data.forEach((item) => {
      item.detections.forEach((detection) => {
        if (intents.has(detection.intent)) {
          return;
        }
        results.push({ intent: detection.intent, conceptRole: detection.role });
        intents.add(detection.intent);
      });
    });
    return results;
  }, [data]);

  const coachingSelects = useMemo(() => {
    const intents = new Set();
    const results = [];
    data.forEach((row) => {
      row.coaching?.forEach((item) => {
        if (!intents.has(item.intent)) {
          results.push(item.intent);
          intents.add(item.intent);
        }
      });
    });
    return results;
  }, [data]);

  const filterData = useMemo(
    () => data.filter(
      (item) => {
        if (detectionFilter && !item.detections?.some((d) => d.intent === detectionFilter)) {
          return false;
        }
        const coaching = item.coaching || [];
        if (coachingFilter && !coaching.some((c) => c.intent === coachingFilter)) {
          return false;
        }
        if (nameFilter && !JSON.stringify(item).toLowerCase().includes(nameFilter.toLowerCase())) {
          return false;
        }
        return true;
      },
    ),
    [detectionFilter, coachingFilter, nameFilter, data],
  );

  return (
    <div className={styles.simulatorResult}>
      <div className={styles.simulatorResultHeader}>
        <div className={styles.simulatorResultTitle}>
          <UnstyledButton className={styles.active}>Simulator</UnstyledButton>
          <UnstyledButton onClick={() => handleTabChange('history')}>History</UnstyledButton>
        </div>
        <Button onClick={handleBack} className={styles.simulatorModelSetupButton}>
          Change setup
        </Button>
      </div>
      <div className={styles.simulatorResultMessage}>
        <div className={styles.simulatorResultCompareTitle}>
          <span className={styles.simulatorResultCompareLabel}>Run #</span>
          <CopyableValue
            displayValue={truncateMiddle(getId('simulationTask', simulationTask.name), 0, 6)}
            copiedValue={getId('simulationTask', simulationTask.name)}
          />
        </div>
        <div className={styles.simulatorResultCreateTime}>
          <span className={styles.simulatorResultCreateTimeLabel}>Date created:</span>
          {moment(simulationTask.abstractTask.createTime).format('MM/DD/YYYY h:mmA')}
        </div>
        <div className={styles.simulatorResultCompareCreator}>
          <span className={styles.simulatorResultCompareLabel}>Auth:</span>
          <UserTag name={users.find((item) => item.id === simulationTask.abstractTask.assigneeUserId)?.full_name} />
        </div>
      </div>
      <div className={styles.buttonsGroup}>
        <Radio.Group
          onChange={(event) => setTableType(event.target.value)}
          value={tableType}
          options={['Utterance', 'Detection']}
          optionType="button"
        />
      </div>
      {tableType === 'Utterance'
        ? (
          <div className={styles.simulatorResultContentWrapper}>
            <div className={styles.simulatorResultSelect}>
              <div className={styles.simulatorResultToggle}>
                <AntButton className={showList && styles.simulatorResultActive} onClick={() => setShowList(!showList)} icon={showList ? <ActiveToggleIcon /> : <ToggleIcon />} />
                <AntButton onClick={handlePreviousConversation} icon={<LeftOutlined />} />
                <AntButton onClick={handleNextConversation} icon={<RightOutlined />} />
                <span className={styles.simulatorResultToggleTitle}>
                  Conversation {conversations?.length ? currentConversationIndex + 1 : 0} of {conversations?.length}
                </span>
              </div>
              <div className={styles.simulatorResultFilter}>
                <Select
                  showArrow
                  className={styles.simulatorResultFilterSelect}
                  size="large"
                  placeholder="detection"
                  allowClear
                  value={detectionFilter}
                  onChange={(value) => setDetectionFilter(value)}
                >
                  <Select.Option key="All detection" value="">
                    All detection
                  </Select.Option>
                  {detectionSelects.map((detection, index) => (
                    <Select.Option key={detection.intent} value={detection.intent}>
                      <div className={styles.simulatorResultFilterSelectOption}>
                        <ConceptTag role={detection.conceptRole} value={detection.intent} />
                      </div>
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  showArrow
                  className={styles.simulatorResultFilterSelect}
                  size="large"
                  placeholder="coaching"
                  allowClear
                  value={coachingFilter}
                  onChange={(value) => setCoachingFilter(value)}
                >
                  <Select.Option key="All coaching" value="">
                    All coaching
                  </Select.Option>
                  {coachingSelects.map((intent) => (
                    <Select.Option key={intent} value={intent}>
                      <MultiTags tags={[intent]} />
                    </Select.Option>
                  ))}
                </Select>
                <Input
                  className={styles.simulatorResultFilterInput}
                  suffix={<SearchOutlined />}
                  placeholder="Search"
                  value={nameFilter}
                  onChange={(event) => setNameFilter(event.target.value)}
                />
                {editingMode === 'draft' && (
                  <UnstyledButton
                    className={styles.dialogButton}
                    data-active={showEditor}
                    onClick={() => setShowEditor(!showEditor)}
                  >
                    <LeftOutlined />
                    <RightOutlined />
                    Dialog Policy
                  </UnstyledButton>
                )}
              </div>
            </div>
            <div className={styles.simulatorResultContent}>
              <div aria-expanded={showList} className={styles.simulatorConvo}>
                <div className={styles.simulatorConvoTitle}>
                  {conversations?.length || 0} convos added
                </div>
                <div className={styles.simulatorConvoList}>
                  {conversations ? conversations.map((convo) => {
                    const id = getId('conversation', convo.name);
                    const actorId = convo.actorIds[0];
                    const active: boolean = id === conversationId;
                    const inGoldenSet = convo.metadata?.inGoldenSet;
                    const hiddenDeleteIcon = true;
                    return (
                      <ChatCard
                        style={{ borderBottom: '1px solid #E0E0E0', padding: '14px' }}
                        key={id}
                        inGoldenSet={inGoldenSet}
                        handleClickCard={setConversationId}
                        chatCardTitle={actorId}
                        active={active}
                        hiddenDeleteIcon={hiddenDeleteIcon}
                        conversationId={id}
                        chatOpenTime={moment(convo.openTime).format('MM/DD/YYYY h:mma')}
                      />
                    );
                  }) : <Loader />}
                </div>
              </div>
              <div aria-expanded={showList} className={styles.tableContainer}>
                <SimulatorResultTable
                  tableData={filterData}
                  loading={dataLoading || simulationTask?.abstractTask?.status === TaskStatus.TASK_IN_PROGRESS}
                />
              </div>
              <div
                aria-expanded={showEditor}
                className={styles.editorContainer}
              >
                <EditorDrawer
                  dialoguePolicy={dialoguePolicy}
                  handleUpdateAndRun={handleUpdateAndRun}
                  taskRunning={simulationTask?.abstractTask?.status === TaskStatus.TASK_IN_PROGRESS}
                />
              </div>
            </div>
          </div>
        ) : <SimulatorIntentTable simulationTask={simulationTask} />}
    </div>
  );
};
