import React, { useMemo } from 'react';
import { Skeleton, Stack } from '@mantine/core';

export const TagListSkeletons = () => {
  // gets random number between 2 numbers
  const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

  const TAG_HEIGHT = 12;
  // get random width for skeleton

  const QUANTITY_OF_TAG_LOADERS = 4;

  // create an array of random widths
  const randomWidths = useMemo(() => Array.from({ length: QUANTITY_OF_TAG_LOADERS }, () => randomNumber(50, 100)), []);

  return (
    <Stack spacing={5} pl={5}>
      {/* create n number of skeletons */}
      {Array(QUANTITY_OF_TAG_LOADERS).fill(0).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} radius="sm" width={randomWidths[index]} height={TAG_HEIGHT} />
      ))}

      <Skeleton radius="sm" width={50} height={TAG_HEIGHT} />
    </Stack>
  );
};
