import { useCallback } from 'react';
import { CreateSetRequest, SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { openNotification } from 'components/Notification';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { SetApi } from 'services/setApi';
import { v4 as uuid } from 'uuid';

export const useSetActions: ()=>({ createSet:(setTitle: string, items: string[]) => Promise<void> }) = () => {
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const customer = useCustomerParams();

  const createSet = useCallback(async (setTitle: string, items: string[]) => {
    try {
      const setId = uuid();
      const request: CreateSetRequest = {
        parent: customerProfile,
        setId,
        set: {
          name: `${customerProfile}/sets/${setId}`,
          usecase,
          setType: SetType.CONVERSATION_SET_TYPE,
          setTitle,
          setItemNames: items,
          languageCode: customer?.languageCode,
        },
      };
      await SetApi.createSet(request);
      openNotification('success', 'Set created', undefined);
    } catch (error) {
      openNotification('error', 'Failed to create set', undefined, error);
    }
  }, [customerProfile, usecase]);
  return {
    createSet,
  };
};
