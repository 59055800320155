import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { mantineTheme } from 'assets/styles/mantine/mantineTheme';
import { ConfigProvider } from 'antd';
import { disableMonitoring, initializeMonitoring } from './configuration/monitoring';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/reset.css';
import './assets/styles/global.scss';

import App from './App';

import { store } from './store/store';

initializeMonitoring();
// We disable monitoring initially and then enable it after login to not spam
// FullStory with sessions that are not useful.
disableMonitoring();

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ReduxProvider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <MantineProvider theme={mantineTheme}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#304FFE',
              colorInfo: '#304FFE',
              borderRadius: 8,
              controlHeight: 32,
              fontSize: 14,
              // '@border-radius-base': '8px',
              // '@border-radius-sm': '4px',
              // checkboxborder-radius': '@border-radius-sm',
            },
          }}
        >
          <Notifications />
          <App />
        </ConfigProvider>
      </MantineProvider>
    </Router>
  </ReduxProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
