import { createAsyncThunk } from '@reduxjs/toolkit';
import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { modelArtifactApi } from 'services/modelArtifactApi';
import { openNotification } from 'components/Notification';

enum Action {
    MODEL_ARTIFACTS_GET_EVALUATION_ARTIFACT = 'MODEL_ARTIFACTS_GET_EVALUATION_ARTIFACT',
    MODEL_ARTIFACTS_GET_EVALUATION_ARTIFACT_TO_COMPARE = 'MODEL_ARTIFACTS_GET_EVALUATION_ARTIFACT_TO_COMPARE',
    MODEL_ARTIFACTS_LIST_MODEL_ARTIFACTS = 'MODEL_ARTIFACTS_LIST_MODEL_ARTIFACTS',
    MODEL_ARTIFACTS_GET_MODEL_ARTIFACT = 'MODEL_ARTIFACTS_GET_MODEL_ARTIFACT',
}

const DEFAULT_PAGE_SIZE = 1000;

/** AsyncThunk for get evaluation artifact. */
export const getEvaluationArtifact = createAsyncThunk<ModelArtifact, string>(Action.MODEL_ARTIFACTS_GET_EVALUATION_ARTIFACT, async (name: string) => {
  try {
    const response = await modelArtifactApi.getModelArtifact({ name });
    return response.modelArtifact;
  } catch (err) {
    openNotification('error', 'Failed to get evaluation artifact', undefined, err);
    throw err;
  }
});

/** AsyncThunk for get evaluation artifact to compare. */
export const getEvaluationArtifactToCompare = createAsyncThunk<ModelArtifact, string>(Action.MODEL_ARTIFACTS_GET_EVALUATION_ARTIFACT_TO_COMPARE, async (name: string) => {
  try {
    const response = await modelArtifactApi.getModelArtifact({ name });
    return response.modelArtifact;
  } catch (err) {
    openNotification('error', 'Failed to get evaluation artifact to compare', undefined, err);
    throw err;
  }
});

/** AsyncThunk for list model artifacts. */
export const listModelArtifacts = createAsyncThunk<ModelArtifact[], string>(Action.MODEL_ARTIFACTS_LIST_MODEL_ARTIFACTS, async (parent: string) => {
  try {
    const response = await modelArtifactApi.listModelArtifacts({
      parent,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    return response.modelArtifacts;
  } catch (err) {
    openNotification('error', 'Failed to get model artifact', undefined, err);
    throw err;
  }
});

/** AsyncThunk for getting model artifact. */
export const getModelArtifact = createAsyncThunk<ModelArtifact, string>(Action.MODEL_ARTIFACTS_GET_MODEL_ARTIFACT, async (name: string) => {
  try {
    const response = await modelArtifactApi.getModelArtifact({
      name,
    });
    return response.modelArtifact;
  } catch (err) {
    openNotification('error', 'Failed to get model artifact', undefined, err);
    throw err;
  }
});
