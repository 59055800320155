import React from 'react';
import './MessageTypingSpinner.scss';
import { Flex, MantineColor, useMantineTheme } from '@mantine/core';

export function MessageTypingSpinner({
  color,
  justify = 'center',
}: {
  color?: MantineColor,
  justify?: 'start' | 'center' | 'end',
}) {
  const theme = useMantineTheme();
  return (
    <Flex
      align="center"
      justify={justify}
    >
      <div className="dot dot-1" style={{ backgroundColor: color || theme.colors.blue[6] }} />
      <div className="dot dot-2" style={{ backgroundColor: color || theme.colors.blue[6] }} />
      <div className="dot dot-3" style={{ backgroundColor: color || theme.colors.blue[6] }} />
    </Flex>
  );
}
