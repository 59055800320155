import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { Message } from 'types';
import { CrestaGptApi } from 'services/crestaGptApi';
import { SimulatorMessage } from '.';

export function mapToMessageType(message: SimulatorMessage): Message {
  return {
    id: message.id,
    msgId: message.id,
    speakerRole: message.speaker_role,
    speakerId: '',
    pubDate: new Date(message.created_at),
    chat_name: '',
    text: message.text,
    suggestions: [],
    hints: [],
  };
}

/**
 * Fetches a response from cresta GPT API for a given customer and prompt.
 * It uses the server sent events API to stream the response.
 */
export const fetchChatGPT = async (
  promptId: string,
  chatId: string,
  prompt: string,
  messages: SimulatorMessage[],
  onNewToken?: (token: string) => void,
  onOpen?: (res: Response) => void,
) => {
  const context_prompt = prompt;
  let data = '';
  if (!chatId) return null;

  await CrestaGptApi.generateResponse(
    {
      promptId,
      chatId,
      async onopen(res: Response) {
        if (res.ok && res.status === 200) {
          console.warn('Connection made ', res);
        } else if (
          res.status >= 400
          && res.status < 500
          && res.status !== 429
        ) {
          console.warn('Client side error ', res);
        }

        if (onOpen) {
          onOpen(res);
        }
      },
      onclose() {
        console.warn('Connection closed by the server');
      },
      onerror(err: unknown) {
        console.error('There was an error from server', err);
      },
      onmessage(message: EventSourceMessage) {
        const parsedData = JSON.parse(message.data);

        if (message.event === 'token') {
          const { token } = parsedData;
          data += token;
          if (onNewToken) {
            onNewToken(token);
          }
        }
      },
      body: JSON.stringify({
        context: context_prompt,
        chat: {
          messages,
        },
        model: 'chatgpt',
      }),
    },
  );
  return data;
};
