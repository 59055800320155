import { createAsyncThunk } from '@reduxjs/toolkit';
import { Concept, ListConceptsRequest, GetConceptRequest, CreateConceptRequest, UpdateConceptRequest, SyncLibraryConceptsRequest, SyncLibraryConceptsResponse } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ConceptApi } from 'services/conceptApi';
import { openNotification } from 'components/Notification';

enum Action {
  CONCEPT_LIST_CONCEPTS = 'CONCEPT_LIST_CONCEPTS',
  CONCEPT_LIST_SHARED_CONCEPTS = 'CONCEPT_LIST_SHARED_CONCEPTS',
  CONCEPT_LIST_DEMO_CONCEPTS = 'CONCEPT_LIST_DEMO_CONCEPTS',
  CONCEPT_REFRESH_CONCEPTS = 'CONCEPT_REFRESH_CONCEPTS',
  CONCEPT_GET_CONCEPT = 'CONCEPT_GET_CONCEPT',
  CONCEPT_CREATE_CONCEPT = 'CONCEPT_CREATE_CONCEPT',
  CONCEPT_UPDATE_CONCEPT = 'CONCEPT_UPDATE_CONCEPT',
  SYNC_LIBRARY_CONCEPTS = 'SYNC_LIBRARY_CONCEPTS',
}

const DEFAULT_PAGE_SIZE = 1000;
const SHARED_CONCEPTS_PARENT = 'customers/cresta/profiles/studio-shared-library';
const DEMO_CONCEPTS_PARENT = 'customers/cresta/profiles/chat-sandbox';

/** AsyncThunk for ListConcepts API. */
export const listConcepts = createAsyncThunk<Concept[], {
  parent: string,
  usecaseId: string,
  languageCode: string,
}>(Action.CONCEPT_LIST_CONCEPTS, async ({ parent, usecaseId, languageCode }) => {
  const request: ListConceptsRequest = {
    parent,
    usecaseId,
    languageCode,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const concepts: Concept[] = [];
  try {
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const response = await ConceptApi.listConcepts(request);
      concepts.push(...response.concepts);
      if (!response.nextPageToken) {
        break;
      }
      request.pageToken = response.nextPageToken;
    }
  } catch (err) {
    openNotification('error', 'Failed to list concepts', undefined, err);
    throw err;
  }
  return concepts;
});

/** AsyncThunk for listing concepts from shared library. */
export const listSharedConcepts = createAsyncThunk<Concept[], void>(Action.CONCEPT_LIST_SHARED_CONCEPTS, async () => {
  try {
    const request: ListConceptsRequest = {
      parent: SHARED_CONCEPTS_PARENT,
      pageSize: DEFAULT_PAGE_SIZE,
    };
    const response = await ConceptApi.listConcepts(request, 'shared');
    return response.concepts;
  } catch (err) {
    openNotification('error', 'Failed to list library concepts', undefined, err);
    throw err;
  }
});

/** AsyncThunk for listing concepts from demo profile. */
export const listDemoConcepts = createAsyncThunk<Concept[], void>(Action.CONCEPT_LIST_DEMO_CONCEPTS, async () => {
  try {
    const request: ListConceptsRequest = {
      parent: DEMO_CONCEPTS_PARENT,
      pageSize: DEFAULT_PAGE_SIZE,
    };
    const response = await ConceptApi.listConcepts(request, 'demo');
    return response.concepts;
  } catch (err) {
    openNotification('error', 'Failed to list demo concepts', undefined, err);
    throw err;
  }
});

/**
 * AsyncThunk for refreshing Concepts by customer profile.
 * The API call is the same as listConcepts except:
 * 1. The action type is different. Thus handled by a different reducer.
 * 2. The error is not displayed to the user.
 */
export const refreshConcepts = createAsyncThunk<Concept[], {
  parent: string,
  usecaseId: string,
  languageCode: string,
}>(Action.CONCEPT_REFRESH_CONCEPTS, async ({
  parent,
  usecaseId,
  languageCode,
}) => {
  const request: ListConceptsRequest = {
    parent,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const response = await ConceptApi.listConcepts(request);
  return response.concepts;
});

/** AsyncThunk for GetConcept API. */
export const getConcept = createAsyncThunk<Concept, GetConceptRequest>(Action.CONCEPT_GET_CONCEPT, async (request: GetConceptRequest) => {
  try {
    const response = await ConceptApi.getConcept(request);
    return response.concept;
  } catch (err) {
    openNotification('error', 'Failed to get concept', undefined, err);
    throw err;
  }
});

/** AsyncThunk for CreateConcept API. */
export const createConcept = createAsyncThunk<Concept, CreateConceptRequest>(Action.CONCEPT_CREATE_CONCEPT, async (request: CreateConceptRequest, { rejectWithValue }) => {
  try {
    const response = await ConceptApi.createConcept(request);
    return response.concept;
  } catch (err) {
    openNotification('error', 'Failed to create concept', undefined, err);
    throw err;
  }
});

/** AsyncThunk for UpdateConcept API. */
export const updateConcept = createAsyncThunk<Concept, UpdateConceptRequest>(Action.CONCEPT_UPDATE_CONCEPT, async (request: UpdateConceptRequest) => {
  try {
    const response = await ConceptApi.updateConcept(request);
    openNotification('success', 'Concept updated successfully');
    return response.concept;
  } catch (err) {
    openNotification('error', 'Failed to update concept', undefined, err);
    throw err;
  }
});

/** AsyncThunk for SyncLibraryConcepts API. */
export const syncLibraryConcepts = createAsyncThunk<SyncLibraryConceptsResponse, string>(Action.SYNC_LIBRARY_CONCEPTS, async (profile: string) => {
  const request: SyncLibraryConceptsRequest = {
    profile,
  };
  try {
    return await ConceptApi.syncLibraryConcepts(request);
  } catch (err) {
    openNotification('error', 'Failed to sync library concepts', undefined, err);
    throw err;
  }
});
