import { createSlice } from '@reduxjs/toolkit';
import { listConceptChanges } from './asyncThunks';
import {
  conceptChangeApiPending,
  conceptChangeApiFailed,
  listConceptChangesSuccess,
  clearConceptChangesReducer,
} from './reducers';
import { INITIAL_STATE } from './state';

/** Concept slice. */
export const conceptChangeSlice = createSlice({
  name: 'concept',
  initialState: INITIAL_STATE,
  reducers: {
    clearConceptChanges: clearConceptChangesReducer,
  },
  extraReducers: (builder) =>
    builder.addCase(listConceptChanges.pending, conceptChangeApiPending)
      .addCase(listConceptChanges.rejected, conceptChangeApiFailed)
      .addCase(listConceptChanges.fulfilled, listConceptChangesSuccess),
});

/** Actions for sync reducers. */
export const { clearConceptChanges } = conceptChangeSlice.actions;
