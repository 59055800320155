import { showNotification } from '@mantine/notifications';
import { SetType } from '../TaggingPage';

const defaultSet: SetType = {
  index: 0,
  id: '',
  title: '',
  conversationCount: 0,
  conversationIndex: 0,
  conversationIndexInTask: 0,
};

export const getActiveSet = (activeConversationIndex: number, setData: SetType[]) => {
  if (!setData.length) {
    return defaultSet;
  }
  // activeSet is where activeConversationIndex is greater than or equal to conversationIndexInTask but less than that number plus conversationCount
  const activeSet = setData.find(
    (set) =>
      activeConversationIndex >= set.conversationIndexInTask
      && activeConversationIndex < set.conversationIndexInTask + set.conversationCount,
  );
  if (!activeSet) {
    const initialSet = setData[0];
    if (!initialSet) {
      showNotification({
        color: 'red',
        message: 'Error: No set data found',
      });
      return defaultSet;
    }
    showNotification({
      color: 'orange',
      message: "Warning: Couldn't find active set",
    });
    return setData[0];
  }
  return activeSet;
};
