import React, { ReactElement } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { Popover, List } from '@mantine/core';
import { WarningOutlined } from '@ant-design/icons';
import { clipText } from 'common/clipText';
import './IntentRegexDiff.scss';
import CopyableValue from 'components/CopyableValue';

interface IntentRegexDiffProps {
  studioPositive: string[];
  studioNegative: string[];
  prodPositive: string[];
  prodNegative: string[];
}

export function IntentRegexDiff({
  studioPositive,
  studioNegative,
  prodPositive,
  prodNegative,
}: IntentRegexDiffProps): ReactElement {
  const prodPositiveSet = new Set<string>(prodPositive);
  const positiveInStudio = studioPositive.filter((regex) => !prodPositiveSet.has(regex));
  const studioPositiveSet = new Set<string>(studioPositive);
  const positiveInProd = prodPositive.filter((regex) => !studioPositiveSet.has(regex));
  const prodNegativeSet = new Set<string>(prodNegative);
  const negativeInStudio = studioNegative.filter((regex) => !prodNegativeSet.has(regex));
  const studioNegativeSet = new Set<string>(studioNegative);
  const negativeInProd = prodNegative.filter((regex) => !studioNegativeSet.has(regex));
  if (!hasRegexDiff(
    { positive: studioPositive, negative: studioNegative },
    { positive: prodPositive, negative: prodNegative },
  )) {
    return <></>;
  }
  return (
    <Popover
      position="right"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <WarningOutlined className="intent-regex-diff-icon" />
      </Popover.Target>
      <Popover.Dropdown>
        <div className="intent-regex-diff-wrapper">
          {!!positiveInStudio.length && !!negativeInStudio && (
            <>
              <span className="intent-regex-diff-tip">
                Only in STUDIO
              </span>
              <DiffTable positive={positiveInStudio} negative={negativeInStudio} />
            </>
          )}
          {!!positiveInProd.length && !!negativeInProd && (
            <>
              <span className="intent-regex-diff-tip">
                Only in PROD
              </span>
              <DiffTable positive={positiveInProd} negative={negativeInProd} />
            </>
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

interface RegexData {
  positive: string[];
  negative: string[];
}

const DiffTable = (data: RegexData) => {
  const renderData = (value: string[]) => {
    if (!value?.length) {
      return <></>;
    }
    return (
      <List>
        {value.map((regex) => (
          <List.Item key={regex}>
            <CopyableValue
              displayValue={clipText(regex, 25)}
              tooltip={regex}
              copiedValue={regex}
            />
          </List.Item>
        ))}
      </List>
    );
  };
  const columns: ColumnsType<RegexData> = [
    {
      key: 'positive',
      title: 'Positive',
      dataIndex: 'positive',
      render: renderData,
    },
    {
      key: 'negative',
      title: 'Negative',
      dataIndex: 'negative',
      render: renderData,
    },
  ];
  return <Table className="intent-regex-diff-table" columns={columns} dataSource={[data]} pagination={false} bordered />;
};

export function hasRegexDiff(a: RegexData, b: RegexData) {
  if (a.positive.length !== b.positive.length || a.negative.length !== b.negative.length) {
    return true;
  }
  const sortedBPositive = b.positive.slice().sort();
  if (a.positive.slice().sort().some((value, index) => value !== sortedBPositive[index])) {
    return true;
  }
  const sortedBNegative = b.negative.slice().sort();
  if (a.negative.slice().sort().some((value, index) => value !== sortedBNegative[index])) {
    return true;
  }
  return false;
}
