import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Group, Pagination, Stack, Text, Title } from '@mantine/core';
import { Category } from '../../../types';

interface TagHeaderProps {
  color: string;
  children: React.ReactNode;
}

const TagHeader = (props: TagHeaderProps) => {
  const { color, children } = props;
  return (
    <Text
      color={color}
      size="sm"
      sx={(theme) => ({
        backgroundColor: theme.colors[color][0],
        padding: '0 6px',
        borderRadius: theme.radius.xl,
      })}
    >
      {children}
    </Text>
  );
};

interface PreviewContainerProps {
  pageSize: number;
  category: Category;
  subtitle: string;
  children: React.ReactNode[];
}

export const PreviewContainer = (props: PreviewContainerProps) => {
  const { pageSize, category, subtitle, children } = props;
  const [pageIndex, setPageIndex] = useState(0);
  const paginatedItems = useMemo(
    () => children.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    [children, pageIndex, pageSize],
  );
  const isVisibleShowMore = useMemo(
    () => children.length > paginatedItems.length,
    [children, paginatedItems],
  );
  const pageCount = useMemo(() => Math.ceil(children.length / pageSize), [children, pageSize]);

  // reset page index when page size changes
  useEffect(() => {
    setPageIndex(0);
  }, [pageSize]);

  // capitalize category
  const title = category.charAt(0).toUpperCase() + category.slice(1);

  const getColor = () => {
    if (category === Category.POSITIVE) {
      return 'green';
    }
    if (category === Category.NEGATIVE) {
      return 'red';
    }
    return 'gray';
  };

  return (
    <Stack style={{ flex: 1, overflow: 'hidden' }}>
      <div>
        <Group spacing={5} pb={5}>
          <TagHeader color={getColor()}>{title}</TagHeader>
          <Title order={6}>Samples</Title>
        </Group>
        <Text color="dimmed" size="sm">{subtitle}</Text>
      </div>
      <Stack style={{ overflowY: 'auto', flex: 1 }}>
        {paginatedItems.length > 0 ? (
          paginatedItems
        ) : (
          <Alert color="gray">
            <Text color="dimmed">{`There are no ${category} samples to show.`}</Text>
          </Alert>
        )}
      </Stack>
      {isVisibleShowMore && (
        <Pagination
          size="sm"
          style={{ alignSelf: 'flex-end' }}
          value={pageIndex + 1}
          onChange={(page) => setPageIndex(page - 1)}
          total={pageCount}
          grow
        />
      )}
    </Stack>
  );
};
