import { createSlice } from '@reduxjs/toolkit';
import { setApiFailed, setApiPending, listSetsSuccess, getOrCreateSetSuccess, deleteSetSuccess } from './reducers';
import { createSet, deleteSet, listSets } from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Set slice. */
export const setSlice = createSlice({
  name: 'set',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(listSets.pending, setApiPending)
      .addCase(listSets.rejected, setApiFailed)
      .addCase(listSets.fulfilled, listSetsSuccess)
      .addCase(createSet.pending, setApiPending)
      .addCase(createSet.rejected, setApiFailed)
      .addCase(createSet.fulfilled, getOrCreateSetSuccess)
      .addCase(deleteSet.pending, setApiPending)
      .addCase(deleteSet.rejected, setApiFailed)
      .addCase(deleteSet.fulfilled, deleteSetSuccess),
});
