import { User } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { SerializedError } from '@reduxjs/toolkit';
import { ApiStatus } from 'store/types';

/** User state. */
export interface State {
  users: User[],
  status: ApiStatus,
  error: SerializedError,
}

/** Initial user state. */
export const INITIAL_STATE: State = {
  users: [],
  status: 'idle',
  error: null,
};
