import { Button, Group, Modal, Select, SelectItem, Stack, Text, Checkbox, Tooltip, TextInput } from '@mantine/core';
import {
  BatchUpsertSharedConceptConfigsRequest,
  Concept,
  ConceptConceptSource,
  ConceptConceptTagType,
  ConceptConceptType,
  ConceptService,
  ConceptState,
  Intent,
  IntentIntentType,
  SharedConceptConfigDeploymentType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useDispatch } from 'hooks/reduxHooks';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { listConcepts } from 'store/concept/asyncThunks';
import { ConceptApi } from 'services/conceptApi';
import { RpcError } from 'components/Notification';
import { flattenCustomerConfigs } from 'components/CustomerPicker';
import { InfoCircleOutlined } from '@ant-design/icons';
import { StudioApi } from 'services/studioApi';
import { v4 as uuid } from 'uuid';
import { UserContext } from 'context/UserContext';

interface DeployIntentModalProps {
  opened: boolean,
  onCancel: () => void,
  concept: Concept,
}

export default function DeployIntentModal({
  opened,
  onCancel,
  concept,
}: DeployIntentModalProps) {
  const { allConfigs, getFirstConfig } = useContext(CustomerConfigContext);
  // Format is customerId/profileId/usecaseId
  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<RpcError>();
  const [isFinetunedOOB, setIsFinetunedOOB] = useState(true);
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode } = useCustomerParams();
  const currentUser = useContext(UserContext);
  const dispatch = useDispatch();

  const enabledCustomers = useMemo<Set<string>>(() => {
    const enabledSet = new Set<string>();
    concept.sharedConceptConfig.forEach((config) => {
      if ([
        SharedConceptConfigDeploymentType.GENERIC,
        SharedConceptConfigDeploymentType.FINETUNED,
      ].includes(config.deploymentType)) {
        enabledSet.add(`${config.customerId}/${config.profileId}/${config.usecaseId}`);
      }
    });

    return enabledSet;
  }, [concept]);

  const createStageInCustomer = useCallback(async (customerConfig: Config, title: string) => {
    const { initReq } = StudioApi.getHeaders(customerConfig);
    const profile = `customers/${customerConfig.customerId}/profiles/${customerConfig.profileId}`;
    const id = uuid();
    await ConceptService.CreateConcept({
      parent: profile,
      conceptId: id,
      userResourceName: currentUser?.name,
      concept: {
        usecaseId: customerConfig.usecaseId,
        languageCode,
        conceptSource: ConceptConceptSource.CUSTOM,
        conceptType: ConceptConceptType.INTENT,
        name: `${profile}/concepts/${id}`,
        state: ConceptState.CREATED,
        conceptTitle: title,
        conceptTags: [ConceptConceptTagType.CALL_FLOW],
        intent: {
          intentType: IntentIntentType.STAGE,
        },
      },
    }, initReq);
  }, [concept]);

  const createIntentInCustomer = useCallback(async (customerConfig: Config, title: string, intent: Intent): Promise<Concept> => {
    const { initReq } = StudioApi.getHeaders(customerConfig);
    const profile = `customers/${customerConfig.customerId}/profiles/${customerConfig.profileId}`;
    const id = uuid();
    const response = await ConceptService.CreateConcept({
      parent: profile,
      conceptId: id,
      userResourceName: currentUser?.name,
      concept: {
        usecaseId: customerConfig.usecaseId,
        languageCode,
        conceptSource: ConceptConceptSource.SHARED,
        conceptType: ConceptConceptType.INTENT,
        name: `${profile}/concepts/${id}`,
        state: ConceptState.CREATED,
        conceptTitle: title,
        conceptTags: [ConceptConceptTagType.CROSS_CUSTOMER_LABELING],
        intent,
      },
    }, initReq);
    return response.concept;
  }, [concept]);

  const handleConfirm = useCallback(async () => {
    if (loading) return;
    const tokens = selectedCustomer?.split('/');
    const customerConfig = getFirstConfig({
      customerId: tokens[0],
      profileId: tokens[1],
      usecaseId: tokens[2],
      languageCode,
    });

    setLoading(true);
    setError(null);
    let error = null;
    // Validation only
    const deploymentType = isFinetunedOOB ? SharedConceptConfigDeploymentType.FINETUNED : SharedConceptConfigDeploymentType.GENERIC;
    const request: BatchUpsertSharedConceptConfigsRequest = {
      parent: `customers/${customerConfig?.customerId}/profiles/${customerConfig?.profileId}`,
      usecaseId: customerConfig?.usecaseId,
      concepts: [concept.name],
      config: {
        deploymentType,
      },
      validateOnly: true,
    };

    try {
      await ConceptApi.batchUpsertSharedConceptConfigs(request);
    } catch (err) {
      error = err;
    }

    if (error) {
      setError(error);
      return;
    }

    // If validation succeeds, proceed without validate only flag
    try {
      request.validateOnly = false;
      // Deploy in shared library (should be current customer)
      await ConceptApi.batchUpsertSharedConceptConfigs(request);
      const intentNames = concept.conceptTitle.split('.');
      const stageName = intentNames[intentNames?.length - 2];

      // Create stage
      if (stageName) {
        try {
          await createStageInCustomer(customerConfig, stageName);
        } catch (err) {
          // This request can fail if the stage already exists, but we can still proceed with intent creation
          if ((err as any)?.status !== 'ALREADY_EXISTS') {
            setError(err as any);
          }
        }
      }

      // Create intent
      await createIntentInCustomer(customerConfig, concept.conceptTitle, concept.intent);

      dispatch(listConcepts({
        parent: customerProfile,
        usecaseId,
        languageCode,
      }));
      handleOnCancel();
    } catch (err: any) {
      if (err.status === 'ALREADY_EXISTS') {
        // If concept already exists, check if the concept has the same deployment type
        const response = await ConceptService.ListConcepts({
          parent: `customers/${customerConfig.customerId}/profiles/${customerConfig.profileId}`,
          usecaseId: customerConfig?.usecaseId,
          pageSize: 1000,
        }, StudioApi.getHeaders(customerConfig)?.initReq);
        const customerConcepts = response.concepts;
        const existingConcept = customerConcepts.find((c) => c.conceptTitle === concept.conceptTitle);
        const existingConceptConfig = existingConcept.sharedConceptConfig.find((config) => {
          const { customerId, profileId } = config;
          return customerId === customerConfig.customerId && profileId === customerConfig.profileId;
        });
        // If the deployment type matches, ignore the error
        if (existingConceptConfig?.deploymentType === deploymentType) {
          dispatch(listConcepts({
            parent: customerProfile,
            usecaseId: customerConfig?.usecaseId,
            languageCode: customerConfig?.defaultLanguageCode,
          }));
          handleOnCancel();
        } else {
          setError(err);
        }
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  }, [loading, selectedCustomer, concept, error, isFinetunedOOB]);

  const activeCustomerNames: SelectItem[] = useMemo(() => {
    const items = flattenCustomerConfigs(allConfigs);
    for (const item of items) {
      item.disabled = enabledCustomers.has(item.value);
    }
    return items;
  }, [allConfigs, enabledCustomers]);

  const handleOnCancel = useCallback(() => {
    setError(null);
    setSelectedCustomer(null);
    setIsFinetunedOOB(true);
    onCancel();
  }, [onCancel]);

  return (
    <Modal opened={opened} onClose={handleOnCancel} size="sm" title="Deploy out of box intent for a customer" withCloseButton={false}>
      <Text size="sm">This will deploy to prod directly. If you do not wish to deploy immediately, please add new intent from the customer’s taxonomy view.</Text>
      {/* Without this hidden input the select gets auto-focused */}
      <TextInput hidden />
      <Stack>
        <Select
          mt="lg"
          label="Select a customer"
          data={activeCustomerNames}
          withinPortal
          value={selectedCustomer}
          placeholder="Select"
          searchable
          onChange={(value) => setSelectedCustomer(value)}
          autoFocus={false}
        />
        <Group spacing="xs">
          <Checkbox label="Use as customer-finetuned OOB" checked={isFinetunedOOB} onChange={(e) => setIsFinetunedOOB(e.target.checked)} />
          <Tooltip multiline withArrow width={200} label="Note that you need a customer-finetuned OOB model deployed for this to work! This only enables the intent, it doesn't deploy any model.">
            <InfoCircleOutlined />
          </Tooltip>
        </Group>
      </Stack>
      {error && (
        <Text color="red" size="sm" mt="lg">{error?.message}</Text>
      )}
      <Group position="right" mt="lg">
        <Button variant="subtle" onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button title="Confirm" onClick={() => handleConfirm()} loading={loading}>Deploy</Button>
      </Group>
    </Modal>
  );
}
