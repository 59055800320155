import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { cloneDeep } from 'lodash';
import React, { Key, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectCurrentAnnotations, selectApiStatus } from 'store/annotation/selectors';
import { searchAnnotations, upsertAnnotations } from 'store/annotation/asyncThunks';
import { updateAnnotationFilters } from 'store/annotation/annotationSlice';
import { getId } from 'common/resourceName';
import { selectConceptsMapFactory } from 'store/concept/selectors';
import { ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { BinaryValueValue, AnnotationState } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { BatchUpsertAnnotationsRequest } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Value as LabelValue } from 'components/BooleanDropdown';
import { completeLabelingTask } from 'store/labelingTask/asyncThunks';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { MisclassificationReview as MisclassificationReviewComponent } from './MisclassificationReview';
import { MisclassificationTableRow } from './MisclassificationTable';

export interface PageState {
  labelFilter: 'all' | 'positive' | 'negative';
  intentFilter: string;
  search: string;
  selectedRowKeys: Key[];
}

interface SetLabelFilterAction {
  type: 'SET_LABEL_FILTER';
  payload: 'all' | 'positive' | 'negative';
}

interface SetIntentFilterAction {
  type: 'SET_INTENT_FILTER';
  payload: string;
}

interface SetSearchAction {
  type: 'SET_SEARCH';
  payload: string;
}

interface SetSelectedRowKeysAction {
  type: 'SET_SELECTED_ROW_KEYS';
  payload: Key[];
}

export type PageAction = (
  SetLabelFilterAction
  | SetIntentFilterAction
  | SetSearchAction
  | SetSelectedRowKeysAction
);

const initialState: PageState = {
  labelFilter: 'all',
  intentFilter: '',
  search: '',
  selectedRowKeys: [],
};

const filterReducer: (state: PageState, action: PageAction)=> PageState = (state, action) => {
  switch (action.type) {
    case 'SET_LABEL_FILTER':
      return {
        ...state,
        labelFilter: action.payload,
      };
    case 'SET_INTENT_FILTER':
      return {
        ...state,
        intentFilter: action.payload,
      };
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload,
      };
    case 'SET_SELECTED_ROW_KEYS':
      return {
        ...state,
        selectedRowKeys: action.payload,
      };
    default:
      return state;
  }
};
export const MisclassificationReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const annotationBundles = useSelector(selectCurrentAnnotations);
  const isLoading = useSelector(selectApiStatus) === 'loading';
  const [state, pageDispatch] = useReducer(filterReducer, initialState);
  const { taskId } = useParams<{ customer: string; taskId: string }>();
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();

  useEffect(() => {
    if (customerProfile === null) return;
    dispatch(updateAnnotationFilters({
      taskIds: [taskId],
      states: [AnnotationState.ACTIVE_MANUALLY_ADDED, AnnotationState.DRAFT_TASK_IN_PROGRESS],
    }));
    dispatch(searchAnnotations({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
    }));
  }, []);

  const conceptsMap = useSelector(selectConceptsMapFactory(ConceptConceptType.INTENT));
  const getConceptTitle = useCallback((conceptId: string) => conceptsMap.get(conceptId)?.conceptTitle, [conceptsMap]);
  const misclassificationTableRows: MisclassificationTableRow[] = useMemo(() => annotationBundles.map((bundle) => {
    const intentId = bundle.annotation?.value?.binaryValue?.conceptId;
    const intentLabel = getConceptTitle(intentId);
    const label = bundle.annotation?.value?.binaryValue?.value === BinaryValueValue.VALUE_POSITIVE ? 'positive' : 'negative';
    const addedToTrainSet = bundle.annotation?.state === AnnotationState.ACTIVE_MANUALLY_ADDED;
    const id = getId('annotation', bundle.annotation.name);
    return {
      id,
      utterance: bundle.textMessage,
      intent: {
        id: intentId,
        label: intentLabel,
      },
      label,
      addedToTrainSet,
    };
  }), [annotationBundles]);

  useEffect(() => {
    const selectedRowKeys = misclassificationTableRows.filter((item) => item.addedToTrainSet).map((item) => item.id);
    pageDispatch({ type: 'SET_SELECTED_ROW_KEYS', payload: selectedRowKeys });
  }, [misclassificationTableRows]);

  const intentData = useMemo(() => {
    const intents = new Set<string>();
    misclassificationTableRows.forEach((item) => {
      if (item.intent.id) {
        intents.add(item.intent.id);
      }
    });
    return Array.from(intents).map((id) => ({
      value: id,
      label: getConceptTitle(id),
    }));
  }, [misclassificationTableRows, getConceptTitle]);

  const filteredDataSource = useMemo(() => misclassificationTableRows.filter((item) => {
    if (state.labelFilter !== 'all' && item.label !== state.labelFilter) {
      return false;
    }
    if (!!state.intentFilter && item.intent.label !== state.intentFilter) {
      return false;
    }
    if (state.search && !item.utterance.toLowerCase().includes(state.search.toLowerCase())) {
      return false;
    }
    return true;
  }), [misclassificationTableRows, state.labelFilter, state.intentFilter, state.search]);

  // The selection is draft if the selected row keys don't equal rows being added into the train set.
  const isDraftSelection = useMemo<boolean>(() => {
    const addedRowKeys = new Set<Key>(filteredDataSource.filter((item) => item.addedToTrainSet).map((item) => item.id));
    if (addedRowKeys.size !== state.selectedRowKeys.length) {
      return true;
    }
    for (const key of state.selectedRowKeys) {
      if (!addedRowKeys.has(key)) {
        return true;
      }
    }
    return false;
  }, [state.selectedRowKeys, filteredDataSource]);

  const handleUpdateTrainSet = useCallback(() => {
    const toAdd = new Set<Key>();
    const toRemove = new Set<Key>(filteredDataSource.filter((item) => item.addedToTrainSet).map((item) => item.id));
    for (const key of state.selectedRowKeys) {
      if (toRemove.has(key)) {
        toRemove.delete(key);
      } else {
        toAdd.add(key);
      }
    }
    const request: BatchUpsertAnnotationsRequest = {
      parent: customerProfile,
      annotations: [],
    };
    for (const annotation of annotationBundles) {
      const id = getId('annotation', annotation.annotation.name);
      if (toAdd.has(id)) {
        const anno = cloneDeep(annotation.annotation);
        anno.state = AnnotationState.ACTIVE_MANUALLY_ADDED;
        request.annotations.push(anno);
      } else if (toRemove.has(id)) {
        const anno = cloneDeep(annotation.annotation);
        anno.state = AnnotationState.DRAFT_TASK_IN_PROGRESS;
        request.annotations.push(anno);
      }
    }
    dispatch(upsertAnnotations(request));
  }, [state.selectedRowKeys, filteredDataSource, customerProfile]);

  const handleUpdateLabel = useCallback((id: Key, label: LabelValue) => {
    const l = label === 'positive' ? BinaryValueValue.VALUE_POSITIVE : BinaryValueValue.VALUE_NEGATIVE;
    const annotation = cloneDeep(annotationBundles.find((item) => getId('annotation', item.annotation.name) === id).annotation);
    annotation.value.binaryValue.value = l;
    const request: BatchUpsertAnnotationsRequest = {
      parent: customerProfile,
      annotations: [annotation],
    };
    dispatch(upsertAnnotations(request));
  }, [annotationBundles, customerProfile]);

  const handleFinishReview = useCallback(() => {
    dispatch(completeLabelingTask(`${customerProfile}/labelingTasks/${taskId}`)).then(() => {
      navigate(`/${customer.path}/qa/tasks`);
    });
  }, [taskId, customer.path]);

  return (
    <MisclassificationReviewComponent
      data={filteredDataSource}
      intentData={intentData}
      pageState={state}
      pageDispatch={pageDispatch}
      onUpdateTrainSet={handleUpdateTrainSet}
      onUpdateLabel={handleUpdateLabel}
      onFinishReview={handleFinishReview}
      isDraftSelection={isDraftSelection}
      isLoading={isLoading}
    />
  );
};
