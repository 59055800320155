import React from 'react';
import './TabPanel.scss';

export interface TabPanelProps {
  fixedHeight?: number;
  active?: boolean;
  handlePanelHeight?: (height: number) => void;
  children: React.ReactChild;
}

export default function TabPanel({
  fixedHeight,
  active = true,
  handlePanelHeight = () => {},
  children,
}: TabPanelProps) {
  const ref = React.useRef<HTMLDivElement>();

  React.useLayoutEffect(() => {
    if (ref.current) {
      const { clientHeight } = ref.current;
      if (active) {
        handlePanelHeight(clientHeight);
      }
    }
  }, [active, handlePanelHeight]);

  const height: string = fixedHeight ? `${fixedHeight}px` : null;

  return (
    <>
      {active && (
      <div
        ref={ref}
        style={{ height }}
        className="tab-panel"
      >
        {children}
      </div>
      )}
    </>
  );
}
