/* eslint react-hooks/exhaustive-deps: 0 */
import { useParams } from 'react-router-dom';
import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';

export type CustomerParams = {
  customerId: string;
  profileId: string;
  usecaseId: string;
  languageCode: string;
  path?: string;
}

function isCustomerParams(customer: any): customer is CustomerParams {
  const t = (customer as CustomerParams);
  return t.customerId !== undefined && t.profileId !== undefined && t.usecaseId !== undefined && t.languageCode !== undefined;
}

/**
 * The following section is about local storage cache for the last visited customer.
 */

const LAST_VISITED_CUSTOMER_KEY = 'lastVisitedCustomer';

export function getLastVisitedCustomer(): CustomerParams|null {
  const lastVisitedCustomer = localStorage.getItem(LAST_VISITED_CUSTOMER_KEY);
  if (!lastVisitedCustomer) {
    return null;
  }
  const customerParams: CustomerParams = JSON.parse(lastVisitedCustomer);
  if (isCustomerParams(customerParams)) {
    return customerParams;
  }
  return null;
}

// Save the last visited customer to local storage.
export function setLastVisitedCustomer(customer: CustomerParams) {
  localStorage.setItem(LAST_VISITED_CUSTOMER_KEY, JSON.stringify(customer));
}

/**
 * All customer parameters related hooks are in the following section.
 */

export const DEFAULT_CUSTOMER: CustomerParams = {
  customerId: 'cresta',
  profileId: 'chat-demo',
  usecaseId: 'chat-demo',
  languageCode: 'en-US',
  path: composeCustomerUrl({
    customerId: 'cresta',
    profileId: 'chat-demo',
    usecaseId: 'chat-demo',
    languageCode: 'en-US',
  }),
};

export const DEFAULT_CUSTOMER_CONFIG: Config = {
  customerId: 'cresta',
  profileId: 'chat-demo',
  usecaseId: 'chat-demo',
  domain: 'chat-demo.cresta.com',
  environmentId: 'chat-prod',
  isHipaaRequired: true,
  defaultLanguageCode: 'en-US',
  supportedLanguageCodes: ['en-US', 'es-ES', 'es-MX'],
  customerShortName: 'chat-demo',
};

export function composeCustomerUrl(customer: Omit<CustomerParams, 'path'>) {
  return `${customer.customerId}/${customer.profileId}/${customer.usecaseId}/${customer.languageCode}`;
}

/**
 * Use the customer params from the route. If not defined, use the last visited customer from local storage.
 * NOTE: DO NOT use CustomerParams as the dependency of hooks, use the parameters values!
 */
export function useCustomerParams(): CustomerParams {
  const { customer = '', profile = '', usecase = '', lang = '' } = useParams<{ customer: string, profile: string, usecase: string, lang: string }>();
  return {
    customerId: customer,
    profileId: profile,
    usecaseId: usecase,
    languageCode: lang,
    // Path can be used in useEffect deps
    path: composeCustomerUrl({
      customerId: customer,
      profileId: profile,
      usecaseId: usecase,
      languageCode: lang,
    }),
  };
}

export function useCustomerProfile(): string {
  const { customerId, profileId } = useCustomerParams();
  return `customers/${customerId}/profiles/${profileId}`;
}

export function useCustomerUsecase(): string {
  const { customerId, profileId, usecaseId } = useCustomerParams();
  return `customers/${customerId}/profiles/${profileId}/usecases/${usecaseId}`;
}

const DEMO_TAGGING_PROFILE = 'customers/cresta/profiles/chat-sandbox';
const CHAT_DEMO_PROFILE = 'customers/cresta/profiles/chat-demo';
export const SHARED_LIBRARY_PROFILE = 'customers/cresta/profiles/studio-shared-library';

/** Whether the customer profile is the studio shared library. */
export function useIsSharedLibrary(): boolean {
  const { customerId, profileId } = useCustomerParams();
  const profile = `customers/${customerId}/profiles/${profileId}`;
  return profile === SHARED_LIBRARY_PROFILE || profile === DEMO_TAGGING_PROFILE;
}

/** Whether the customer profile is for demo purpose. */
export function useIsDemoProfile(): boolean {
  const { customerId, profileId } = useCustomerParams();
  const profile = `customers/${customerId}/profiles/${profileId}`;
  return profile === CHAT_DEMO_PROFILE;
}
