import React, { useState } from 'react';
import { Button, createStyles } from '@mantine/core';
import { TagSelect } from 'pages/AnalysisWorkshop/components/TagSelect';
import { conceptTitleFormatter } from 'pages/AnalysisWorkshop/NewTaskConfig/TagConfig/utils';
import { getHotkeyHandler, useInputState } from '@mantine/hooks';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { TagSelectItem } from '../..';

const useStyles = createStyles(() => ({
  root: {
    width: '80%',
  },
  input: {
    height: 'unset',
    lineHeight: 'unset',
    minHeight: 'unset',
  },
}));

interface AddNewTagProps {
  onAddNewTagToTask: (query: string) => Promise<void>
  tagSelectData?: TagSelectItem[]
  handleEnterPress?: (inputValue: string, type: TagTagType) => void
  tagType: TagTagType
}

export const AddNewTag = (props:AddNewTagProps) => {
  const { onAddNewTagToTask, tagSelectData = [], handleEnterPress, tagType } = props;
  const { classes } = useStyles();
  const [isAddingNewTag, toggleAddingNewTag] = useState(false);
  const [inputValue, setInputValue] = useInputState('');

  const content = () => {
    if (isAddingNewTag) {
      return (
        <TagSelect
          size="xs"
          onCreate={(query) => {
            const formattedQuery = conceptTitleFormatter(query);
            onAddNewTagToTask(formattedQuery).then(() => {
              toggleAddingNewTag(false);
            });
            return query;
          }}
          withinPortal
          classNames={{
            root: classes.root,
            input: classes.input,
          }}
          data={tagSelectData}
          value={inputValue}
          onChange={setInputValue}
          onKeyDown={getHotkeyHandler([
            ['Enter', () => {
              handleEnterPress(inputValue, tagType);
            }],
          ])}
        />
      );
    }
    return (
      <Button
        color="gray"
        size="xs"
        variant="light"
        compact
        onClick={() => toggleAddingNewTag(true)}
      >+ Add new Tag
      </Button>

    );
  };
  return (
    <>
      {content()}
    </>
  );
};
