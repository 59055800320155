import React, { useMemo, useState } from 'react';
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Conversation as TypeConversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { Button as AntButton, Input, Select } from 'antd';
import { getId } from 'common/resourceName';
import MultiTags from 'components/MultiTags';
import { RoleIcon } from 'components/RoleIcon';
import Loader from 'pages/Conversations/Loader';
import dayjs from 'dayjs';
import { ActiveToggleIcon, ToggleIcon } from '../icons';
import { ChatCard } from '../SimulatorModel/ChatCard';
import styles from './SimulatorComparedResult.module.scss';
import { SimulatorResultTable as SimulatorResultUtteranceTable } from './SimulatorResultTable';
import { useComparePredictions } from './useComparePredictions';
import { useConversationSelect } from './useConversationSelect';

interface Props {
  simulationTaskName: string;
  conversations: TypeConversation[]
}

export const SimulatorResultUtteranceTableWrapper = (props: Props) => {
  const {
    simulationTaskName,
    conversations,
  } = props;
  const [showList, setShowList] = useState(false);
  const [nextIntentFilter, setNextIntentFilter] = useState<string>('');
  const [detectionFilter, setDetectionFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [
    conversationId,
    currentConversationIndex,
    ,
    setConversationId,
    handleNextConversation,
    handlePreviousConversation,
  ] = useConversationSelect(conversations);
  const [predictionData, isLoading] = useComparePredictions(conversations[currentConversationIndex]?.name, simulationTaskName);

  const detectionSelects: { intent: string, conceptRole: 'agent' | 'visitor' | 'driver' }[] = useMemo(() => {
    const intents = new Set();
    const results: { intent: string, conceptRole: 'agent' | 'visitor' | 'driver' }[] = [];
    predictionData.forEach((item) => {
      item.detections.forEach((detection) => {
        if (intents.has(detection.intent)) {
          return;
        }
        results.push({ intent: detection.intent, conceptRole: detection.role });
        intents.add(detection.intent);
      });
    });
    return results;
  }, [predictionData]);

  const coachingSelects = useMemo(() => {
    const intents = new Set();
    const results = [];
    predictionData.forEach((row) => {
      row.coaching?.forEach((item) => {
        if (!intents.has(item.intent)) {
          results.push(item.intent);
          intents.add(item.intent);
        }
      });
    });
    return results;
  }, [predictionData]);

  const filterData = useMemo(
    () => predictionData.filter(
      (item) => {
        if (detectionFilter && !item.detections?.some((d) => d.intent === detectionFilter)) {
          return false;
        }
        const coaching = item.coaching || [];
        if (nextIntentFilter && !coaching.some((c) => c.intent === nextIntentFilter)) {
          return false;
        }
        if (nameFilter && !JSON.stringify(item).toLowerCase().includes(nameFilter.toLowerCase())) {
          return false;
        }
        // return false if any item.coaching or item.detections does not have a changeOfType equal to typeFilter
        if (typeFilter && !coaching.some((c) => c.changeOfType === typeFilter) && !item.detections?.some((d) => d.changeOfType === typeFilter)) {
          return false;
        }
        return true;
      },
    ),
    [detectionFilter, nextIntentFilter, nameFilter, typeFilter, predictionData.length],
  );

  return (
    <>
      <div className={styles.simulatorResultSelect}>
        <div className={styles.simulatorResultToggle}>
          <AntButton className={showList && styles.simulatorResultActive} onClick={() => setShowList(!showList)} icon={showList ? <ActiveToggleIcon /> : <ToggleIcon />} />
          <AntButton onClick={handlePreviousConversation} icon={<LeftOutlined />} />
          <AntButton onClick={handleNextConversation} icon={<RightOutlined />} />
          <span className={styles.simulatorResultToggleTitle}>
            Conversation {conversations?.length ? currentConversationIndex + 1 : 0} of {conversations?.length}
          </span>
        </div>
        <div className={styles.simulatorResultFilter}>
          <Select
            showArrow
            className={styles.simulatorResultFilterSelect}
            size="large"
            placeholder="Detections"
            allowClear
            value={detectionFilter}
            onChange={(value) => setDetectionFilter(value)}
          >
            <Select.Option key="All detection" value="">
              All detections
            </Select.Option>
            {detectionSelects.map((detection, index) => (
              <Select.Option key={detection.intent + detection.conceptRole} value={detection.intent}>
                <div className={styles.simulatorResultFilterSelectOption}>
                  <RoleIcon conceptRole={detection.conceptRole} />
                  <MultiTags tags={[detection.intent]} />
                </div>
              </Select.Option>
            ))}
          </Select>
          <Select
            showArrow
            className={styles.simulatorResultFilterSelect}
            size="large"
            placeholder="Next intents"
            allowClear
            value={nextIntentFilter}
            onChange={(value) => setNextIntentFilter(value)}
          >
            <Select.Option key="All coaching" value="">
              All next intents
            </Select.Option>
            {coachingSelects.map((ttType) => (
              <Select.Option key={ttType} value={ttType}>
                {ttType}
              </Select.Option>
            ))}
          </Select>
          <Select
            showArrow
            className={styles.simulatorResultNumberFilterSelect}
            size="large"
            placeholder="Change Type"
            allowClear
            value={typeFilter}
            onChange={(value) => setTypeFilter(value)}
          >
            <Select.Option key="All Results" value="">
              All results
            </Select.Option>
            <Select.Option key="same" value="same">
              <MultiTags tags={['Same']} />
            </Select.Option>
            <Select.Option key="added" value="added">
              <MultiTags tags={['Added']} color="green"/>
            </Select.Option>
            <Select.Option key="removed" value="removed">
              <MultiTags tags={['Removed']} color="red" />
            </Select.Option>
          </Select>
          <Input
            className={styles.simulatorResultFilterInput}
            suffix={<SearchOutlined />}
            placeholder="Search"
            value={nameFilter}
            onChange={(event) => setNameFilter(event.target.value)}
          />
        </div>
      </div>
      <div className={styles.simulatorResultContent}>
        <div aria-expanded={showList} className={styles.simulatorConvo}>
          <div className={styles.simulatorConvoTitle}>
            {conversations?.length || 0} convos added
          </div>
          <div className={styles.simulatorConvoList}>
            {conversations ? conversations.map((convo) => {
              const id = getId('conversation', convo.name);
              const actorId = convo.actorIds[0];
              const active: boolean = id === conversationId;
              const inGoldenSet = convo.metadata?.inGoldenSet;
              const hiddenDeleteIcon = true;
              return (
                <ChatCard
                  style={{ borderBottom: '1px solid #E0E0E0', padding: '14px' }}
                  key={id}
                  inGoldenSet={inGoldenSet}
                  handleClickCard={setConversationId}
                  chatCardTitle={actorId}
                  active={active}
                  hiddenDeleteIcon={hiddenDeleteIcon}
                  conversationId={id}
                  chatOpenTime={dayjs(convo.openTime).format('MM/DD/YYYY h:mma')}
                />
              );
            }) : <Loader />}
          </div>
        </div>
        <div aria-expanded={showList} className={styles.tableContainer}>
          <SimulatorResultUtteranceTable tableData={filterData} loading={isLoading} />
        </div>
      </div>
    </>
  );
};
