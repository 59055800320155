import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useSelector } from 'hooks/reduxHooks';
import React from 'react';
import { selectDerivedLabelingTasksMap } from 'store/labelingTask/selectors';
import { BaseSummaryTable } from '..';
import { SummarySuggestionsTableNewMultiple } from './SummarySuggestionsTableNewMultiple';
import { SummarySuggestionsTableNewSingle } from './SummarySuggestionsTableNewSingle';
import { SummarySuggestionsTableOld } from './SummarySuggestionsTableOld';

interface SummarySuggestionsTableProps extends BaseSummaryTable {}

export default function SuggestionsQaTable(props: SummarySuggestionsTableProps) {
  const tasksMap = useSelector<Map<string, DerivedLabelingTask>>(selectDerivedLabelingTasksMap);
  const { taskId } = props;

  const task = tasksMap.get(taskId);
  const hasSecondModel = task?.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.modelUri2;
  const isV2Suggestions = task?.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.isSuggestionQaV2;

  if (!isV2Suggestions) {
    return <SummarySuggestionsTableOld {...props}/>;
  }

  if (hasSecondModel) {
    return <SummarySuggestionsTableNewMultiple {...props}/>;
  } else {
    return <SummarySuggestionsTableNewSingle {...props}/>;
  }
}
