import React, { ReactElement, useCallback, useEffect, useMemo, useContext } from 'react';
import { ModelWizardProjectStep } from 'models';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { createModelWorkflow, listModelWorkflow } from 'store/modelWorkflow/asyncThunks';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { CreateModelWorkflowRequest, ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { selectModelWorkflows } from 'store/modelWorkflow/selectors';
import { UserContext } from 'context/UserContext';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';
import { Stack } from '@mantine/core';
import { SuggestionsModelArtifactInputsModelType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import ModelWizardProjectStepper from 'pages/ModelWizardProject/ModelWizardProjectStepper';
import { ConfigStep, TrainStep } from '../components/Steps';
import { convertEvaluationStatResultsToTableData, convertTestSetScoresToTableData, QATable, QuantitativeScoresTable } from '../components/Tables';
import { getSelectionInstruction } from '../../getSelectionInstruction';
import { useQAConfigForm } from '../components/QATaskSetup';
import { EnsembleConfigForm, useEnsembleConfigForm } from './Config';
import { EnsembleEvaluate } from './EnsembleEvaluate';

export function Ensemble(): ReactElement {
  const [activeStepIndex, setActiveStepIndex] = useUrlParam<number>('step', 0);
  const activeStepIndexNumber = Number(activeStepIndex);
  const [, setWorkflowId, clearWorkflowId] = useUrlParam<string>('workflowId');
  const [,, clearCompareTo] = useUrlParam<string>('compareTo', '');
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const currentUser = useContext(UserContext);
  const modelWorkflows = useSelector<ModelWorkflow[]>(selectModelWorkflows);
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    dispatch(listModelWorkflow({
      parent: customerProfile,
      pageSize: 1000,
      filter: { usecaseId, languageCode },
    }));
  }, [path]);

  const onClickPreviewEval = useCallback((workflowName: string) => {
    const id = getId('modelWorkflow', workflowName);
    if (id) {
      setWorkflowId(id);
      setActiveStepIndex(2);
    }
  }, [setActiveStepIndex, setWorkflowId]);

  const ensembleForm = useEnsembleConfigForm();
  // handle generative model selection
  const selectedGenerativeModelWorkflow: ModelWorkflow = useMemo(() => {
    if (ensembleForm.values.generativeModelPath) {
      return modelWorkflows.find((modelWorkflow) => modelWorkflow.name === ensembleForm.values.generativeModelPath);
    }
    return null;
  }, [ensembleForm.values.generativeModelPath, modelWorkflows]);
  const selectionInstructionGenerative = selectedGenerativeModelWorkflow?.trainSuggestionsGenerativeModelConfig?.selectionInstruction;

  // handle retrieval model selection
  const selectedRetrievalModelWorkflow: ModelWorkflow = useMemo(() => {
    if (ensembleForm.values.retrievalModelPath) {
      return modelWorkflows.find((modelWorkflow) => modelWorkflow.name === ensembleForm.values.retrievalModelPath);
    }
    return null;
  }, [ensembleForm.values.retrievalModelPath, modelWorkflows]);
  const selectionInstructionRetrieval = selectedRetrievalModelWorkflow?.trainSuggestionsRetrieverConfig?.selectionInstruction;

  const qaConfigForm = useQAConfigForm();

  const onClickNext = useCallback(() => {
    const modelWorkflowId = crypto.randomUUID();

    const getModelWorkflow: () => ModelWorkflow = () => {
      const modelProject = `${customerProfile}/modelProjects/${projectId}`;
      const selectionInstruction = getSelectionInstruction(qaConfigForm.values.taskOption, {
        after: qaConfigForm.values.qaConversationDates[0],
        before: qaConfigForm.values.qaConversationDates[1],
        modelUri: qaConfigForm.values.qaComparisonModelUrl,
        maxConversationCount: qaConfigForm.values.qaConversationCount,
      });
      const generativePathType: SuggestionsModelArtifactInputsModelType = selectedGenerativeModelWorkflow?.trainSuggestionsGenerativeModelConfig.modelType;
      const modelpath = selectedGenerativeModelWorkflow?.trainSuggestionsGenerativeModelOutput?.ensembleModelUri;
      const modelWorkflow: ModelWorkflow = {
        modelProject,
        packageSuggestionsEnsembleConfig: {
          suggestionsEnsembleInputs: {
            //  * Path (in format `<customer>/<type>:<version>`) to generative GPT model.
            generativeGptModelPath: generativePathType === SuggestionsModelArtifactInputsModelType.GPT2 ? modelpath : undefined,
            //   * Path (in format `<customer>/<type>:<version>`) to generative T5 model.
            generativeT5ModelPath: generativePathType === SuggestionsModelArtifactInputsModelType.T5 ? modelpath : undefined,
            //   * Path (in format `<customer>/<type>:<version>`) to retriever model.
            retrieverModelPath: ensembleForm.values.retrievalModelPath,
            //   * Path (in format `s3://...`) to canned msg trie. TODO: add back when backend supports it.
            // cannedMsgTriePath: ensembleForm.values.cannedMsgTriePath,
            useEnsembleModelUrls: true,
          },
          selectionInstruction,
        },
      };
      return modelWorkflow;
    };

    const modelWorkflow = getModelWorkflow();
    const request: CreateModelWorkflowRequest = {
      parent: customerProfile,
      modelWorkflowId,
      commonInput: {
        title: modelWorkflowId,
        assigneeUserId: currentUser?.id,
      },
      modelWorkflow,
    };
    dispatch(createModelWorkflow(request)).then(() => {
      setActiveStepIndex(1);
      dispatch(listModelWorkflow({ parent: customerProfile, filter: { usecaseId, languageCode } }));
    });
  }, [ensembleForm, path]);
  const quantitaveScoresTableDataGenerative = useMemo(() => convertTestSetScoresToTableData(selectedGenerativeModelWorkflow?.trainSuggestionsGenerativeModelOutput?.trainOutputs?.testSetScores || {}), [selectedGenerativeModelWorkflow]);
  const quantitaveScoresTableDataRetrieval = useMemo(() => convertEvaluationStatResultsToTableData(selectedRetrievalModelWorkflow?.trainSuggestionsRetrieverOutput?.trainOutputs?.evaluationStats?.results || []), [selectedRetrievalModelWorkflow]);
  const STEPS: ModelWizardProjectStep[] = [
    {
      label: 'Configure',
      children: (
        <ConfigStep onClickNext={onClickNext}>
          <EnsembleConfigForm
            generativeModelOptions={modelWorkflows.filter((modelWorkflow) => modelWorkflow.trainSuggestionsGenerativeModelOutput).map((model) => model.trainSuggestionsGenerativeModelOutput?.ensembleModelUri)}
            retrievalModelOptions={modelWorkflows.filter((modelWorkflow) => modelWorkflow.trainSuggestionsRetrieverOutput).map((model) => model.trainSuggestionsRetrieverOutput?.ensembleModelUri)}
            ensembleForm={ensembleForm}
            qaConfigForm={qaConfigForm}
          />
          <Stack style={{ flex: 1 }}>
            <QuantitativeScoresTable dataSource={quantitaveScoresTableDataGenerative} />
            {selectionInstructionGenerative && <QATable selectionInstruction={selectionInstructionGenerative} qaTask={selectedGenerativeModelWorkflow?.trainSuggestionsGenerativeModelOutput?.qaTask?.labelingTask} />}
            {ensembleForm.values.retrievalModelPath && (
              <>
                <QuantitativeScoresTable dataSource={quantitaveScoresTableDataRetrieval} />
                {selectionInstructionRetrieval && <QATable selectionInstruction={selectionInstructionRetrieval} qaTask={selectedRetrievalModelWorkflow?.trainSuggestionsRetrieverOutput?.qaTask?.labelingTask} />}
              </>
            )}
          </Stack>
        </ConfigStep>),
    },
    {
      label: 'Train',
      children: <TrainStep
        onClickPreviewEval={onClickPreviewEval}
        modelWorkflows={modelWorkflows.filter((modelWorkflow) => modelWorkflow.modelProject === `${customerProfile}/modelProjects/${projectId}`)}
      />,
    },
    {
      label: 'Evaluate',
      children: (
        <EnsembleEvaluate modelWorkflows={modelWorkflows} />),
    },
  ];

  return (
    <ModelWizardProjectStepper
      steps={STEPS}
      stepIndex={activeStepIndexNumber}
      onStepClick={(index) => {
        if (index === activeStepIndexNumber) {
          return;
        }
        setActiveStepIndex(index);
        clearWorkflowId();
        clearCompareTo();
      }}
    />
  );
}
