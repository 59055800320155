import React from 'react';
import CallDriverDiscovery from './CallDriverDiscovery';
import { CallDriverHeader } from '../components/CallDriverHeader';
import { useAutoanalysisContext } from '..';

export default function CallDrivers() {
  const { jobs, clusterVersions, clusterVersionName, setClusterVersionName } = useAutoanalysisContext();
  return (
    <>
      <CallDriverHeader
        clusterVersionName={clusterVersionName}
        setClusterVersionName={setClusterVersionName}
        clusterVersions={clusterVersions}
      />
      <CallDriverDiscovery
        clusterVersionName={clusterVersionName}
        jobs={jobs}
      />
    </>
  );
}
