import { Annotation, MessageRawData, MessageRawDataContextShown, TextValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import React, { useCallback } from 'react';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { createStyles } from '@mantine/core';
import SummarizationDecision from 'components/LabelingDecision/SummarizationDecision/SummarizationDecision';

interface SummarizationLabelingViewProps {
  concepts: Concept[];
  messages: Message[];
  conversationAnnotations: Annotation[];
  failedMessages: { [id: string]: boolean };
  createAnnotation: (
    messageRawData: MessageRawData,
    textValue: TextValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  nextChat: () => void;
  setOverviewConceptId: (id: string) => void;
}

export function SummarizationLabelingView({
  concepts,
  messages,
  conversationAnnotations,
  failedMessages,
  createAnnotation,
  upsertAnnotations,
  nextChat,
  setOverviewConceptId,
} : SummarizationLabelingViewProps) {
  const useStyles = createStyles(() => ({
    displayContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    displayChat: {
      flex: 1,
    },
    displaySuggestions: {
      maxHeight: '300px',
      overflow: 'auto',
    },
  }));

  const styles = useStyles();
  const context = MessageRawDataContextShown.FULL_CONVERSATION_SHOWN;

  const submitAnnotations = useCallback((newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => {
    upsertAnnotations(newAnnotations, gotoNext, conversationAnnotations);
  }, [conversationAnnotations, upsertAnnotations]);

  return (
    <div
      className={classNames([
        'labeling-view',
        'vertical-layout',
      ])}
    >
      <div className={styles.classes.displayContainer}>
        <div className={classNames(['labeling-items-display', styles.classes.displayChat])}>
          <LabelingItemsDisplay
            context={context}
            showContextButtonVisible={false}
            messages={messages}
            targetMessageId={null}
            // Text annotations aren't shown in this component
            messageAnnotationsMap={new Map()}
            failedMessages={failedMessages}
            annotationsDisplayType="concepts-colors"
          />
        </div>
      </div>
      <div className="labeling-decision-panel">
        <SummarizationDecision
          concepts={concepts}
          // Summarization doesn't have highlighted message but needs access to conversation id
          message={messages[0]}
          annotations={conversationAnnotations}
          createAnnotation={createAnnotation}
          submitAnnotations={submitAnnotations}
          contextShown={context}
          setOverviewConceptId={setOverviewConceptId}
          nextChat={nextChat}
        />
      </div>
    </div>
  );
}
