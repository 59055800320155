import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import './AddConceptModalWithLibrary.scss';
import { cloneDeep } from 'lodash';
import { Modal, Input, Tooltip, Button, Radio, Group, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  Concept,
  ConceptConceptType,
  ConceptState,
  ConversationOutcomeConversationOutcomeType as OutcomeType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { getId } from 'common/resourceName';
import { conceptNameRule } from 'utils/form-rules';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ConceptDetail } from 'components/ConceptEditor/ConceptDetail';
import { RoleIcon } from 'components/RoleIcon';
import { ConceptsSelect } from '../ConceptsSelect';

interface Props {
  conceptType: 'intent' | 'outcomeValue' | 'entity' | 'summarization';
  parent?: Concept;
  driver?: Concept; // Required if conceptType === 'intent'
  libraryConcepts: Concept[];
  onAdd: (concept: Concept) => void;
  onClose: () => void;
  opened: boolean;
  isSharedLibrary?: boolean;
}

export function AddConceptModalWithLibrary({
  conceptType,
  parent,
  driver,
  libraryConcepts,
  onAdd,
  onClose,
  opened,
  isSharedLibrary,
}: Props): ReactElement {
  const nameValidator = (value) => {
    if (!value?.length) {
      return 'Required';
    }
    if (conceptNameRule.pattern.test(value)) {
      return null;
    }
    return conceptNameRule.message;
  };

  // Intent type input.
  const [agentIntent, setAgentIntent] = useState<boolean>(true);

  // Concept title input.
  const [text, setText] = useState<string>('');
  const conceptTitleForm = useForm<{ conceptTitle: string }>({
    initialValues: {
      conceptTitle: '',
    },
    validate: {
      conceptTitle: nameValidator,
    },
  });
  const [formHasError, setFormHasError] = useState<boolean>(false);
  useEffect(() => {
    const nextHasErrors = conceptTitleForm.validate().hasErrors;
    if (nextHasErrors !== formHasError) {
      setFormHasError(nextHasErrors);
    }
    // This works because mantine form values reference is changed after the values are updated.
  }, [conceptTitleForm.values]);
  useEffect(() => conceptTitleForm.setFieldValue('conceptTitle', text), [text]);

  // Use library concepts.
  const [template, setTemplate] = useState<Concept>(null);

  const resetModal = useCallback(() => {
    conceptTitleForm.reset();
    setText('');
    setTemplate(null);
  }, [conceptTitleForm]);

  const handleSubmit = useCallback(() => {
    if (conceptTitleForm.validate().hasErrors) {
      return;
    }
    const drivers = driver && driver.name ? [getId('concept', driver.name)] : [];
    let concept: Concept;
    if (template) {
      concept = cloneDeep(template);
      concept.name = '';
      concept.libraryConceptName = getId('concept', template.name);
      switch (conceptType) {
        case 'intent':
          concept.intent.parentConversationDriverNames = drivers;
          break;
        case 'outcomeValue':
          concept.conversationOutcome.parentConversationOutcomeName = getId('concept', parent?.name);
          break;
        default:
      }
    } else {
      switch (conceptType) {
        case 'intent':
          concept = {
            conceptTitle: `${parent.conceptTitle}.${conceptTitleForm.values.conceptTitle}`,
            conceptType: ConceptConceptType.INTENT,
            state: ConceptState.CREATED,
            intent: {
              intentType: agentIntent ? IntentIntentType.AGENT_INTENT : IntentIntentType.VISITOR_INTENT,
              parentConversationDriverNames: drivers,
            },
          };
          break;
        case 'outcomeValue':
          concept = {
            conceptTitle: `${parent.conceptTitle}.${conceptTitleForm.values.conceptTitle}`,
            conceptType: ConceptConceptType.CONVERSATION_OUTCOME,
            state: ConceptState.CREATED,
            conversationOutcome: {
              conversationOutcomeType: OutcomeType.POSSIBLE_OUTCOME,
              parentConversationOutcomeName: getId('concept', parent.name),
            },
          };
          break;
        case 'entity':
          concept = {
            conceptTitle: conceptTitleForm.values.conceptTitle,
            conceptType: ConceptConceptType.ENTITY,
            state: ConceptState.CREATED,
            description: '',
            labelingGuideline: '',
            entity: {
              regexExpressions: [],
              negativeRegexExpressions: [],
            },
          };
          break;
        case 'summarization':
          concept = {
            conceptTitle: conceptTitleForm.values.conceptTitle,
            conceptType: ConceptConceptType.SUMMARIZATION,
            state: ConceptState.CREATED,
            description: '',
            labelingGuideline: '',
            summarization: {},
          };
          break;
        default:
      }
    }
    onAdd(concept);
    resetModal();
    onClose();
  }, [conceptTitleForm, parent, template]);

  const sharedConcepts = libraryConcepts.filter((concept) => concept.conceptTitle.startsWith(parent?.conceptTitle || ''));
  const renderConcept = (concept: Concept) => (
    <>
      <RoleIcon
        conceptRole={concept.intent.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor'}
      />
      <span>{`${concept.conceptTitle}`}</span>
    </>
  );
  const inputRole = (): 'agent' | 'visitor' => {
    if (template) {
      return template.intent?.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor';
    }
    return agentIntent ? 'agent' : 'visitor';
  };

  return (
    <Modal
      title="Add new"
      opened={opened}
      onClose={() => {
        resetModal();
        onClose();
      }}
      size="500px"
    >
      <span className="subtitle">
        {isSharedLibrary ? 'Create new' : 'Browse from shared library or create new'}
      </span>
      <div>
        {conceptType === 'outcomeValue' && (
          <div>
            {`${parent?.conceptTitle}.`}
          </div>
        )}
        {conceptType === 'intent' && (
        <Radio.Group
          label="Select type"
          mb="lg"
          value={agentIntent ? IntentIntentType.AGENT_INTENT : IntentIntentType.VISITOR_INTENT}
          onChange={(value) => {
            if (!template) {
              setAgentIntent(value === IntentIntentType.AGENT_INTENT);
            }
          }}
        >
          <Group>
            <Radio value={IntentIntentType.AGENT_INTENT} label="Agent" />
            <Radio value={IntentIntentType.VISITOR_INTENT} label="Visitor" />
          </Group>
        </Radio.Group>
        )}
        <Group mb="md" spacing="xs">
          {conceptType === 'intent' && (
            <>
              <RoleIcon conceptRole={inputRole()} />
              <Text>
                {`${parent?.conceptTitle}.`}
              </Text>
            </>
          )}
          <Input
            data-cy="concept-title-input"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="name"
            disabled={!!template}
            rightSection={formHasError && (
            <Tooltip label={conceptTitleForm.errors.conceptTitle} position="top-end">
              <ExclamationCircleOutlined />
            </Tooltip>
            )}
          />
          {!!template && (
          <Button
            variant="subtle"
            onClick={(e) => {
              e.stopPropagation();
              setTemplate(null);
              setText('');
            }}
          >
            <CloseCircleOutlined />
          </Button>
          )}
        </Group>
      </div>
      {!!template && (<ConceptDetail concept={template} showTags />)}
      {sharedConcepts?.length > 0 && !template && (
        <ConceptsSelect
          title="FROM SHARED LIBRARY"
          concepts={sharedConcepts}
          filterText={text}
          onClick={(concept: Concept) => {
            setTemplate(concept);
            setText(concept?.conceptTitle.split('.').pop());
          }}
          renderConcept={conceptType === 'intent' ? renderConcept : undefined}
          allowTag
        />
      )}
      <Group mt="lg" position="right">
        <Button
          variant="subtle"
          onClick={() => {
            resetModal();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          data-cy="add-concept-button"
          disabled={formHasError}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </Group>
    </Modal>
  );
}
