/* eslint-disable react/no-array-index-key */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ActionIcon, Button, Collapse, Group, MantineColor, Textarea, Text, useMantineTheme } from '@mantine/core';
import { getId } from 'common/resourceName';
import React from 'react';

interface RatingGroupProps {
  maxScore: number;
  selectScore: (score: number) => void;
  activeScore: number;
}

// Scores from 0 to maxScore
export function RatingGroup({ selectScore, activeScore, maxScore }: RatingGroupProps) {
  const rubricColors: MantineColor[] = ['red', 'orange', 'purple', 'green', 'teal', 'lime', 'brown'];
  return (
    <Group spacing="xs">
      {new Array(maxScore).fill(null).map((val, i) => (
        <Button
          key={i}
          color={rubricColors[i]}
          compact
          size="md"
          variant="light"
          data-active={activeScore === i + 1}
          onClick={() => selectScore(i + 1)}
        >{i + 1}
        </Button>
      ))}
    </Group>
  );
}

interface RubricProps {
  maxScore: number;
  title: string;
  opened: boolean;
  concepts: Concept[];
  selectScore: (score: number) => void;
  selectSummary?: (conceptId: string) => void;
  selectTag?: (tag: string) => void;
  updateExplanation: (text: string) => void;
  activeScore: number;
  activeConcepts?: string[];
  explanation?: string;
  infoButtonHandler: () => void;
  rubricTags: string[];
  activeTags: string[];
  hasExplanation?: boolean;
  description?: string;
}

export default function RubricGroup({
  maxScore,
  title,
  opened,
  concepts,
  selectScore,
  selectSummary,
  selectTag,
  updateExplanation,
  activeScore,
  activeConcepts = [],
  explanation,
  infoButtonHandler,
  rubricTags,
  activeTags,
  hasExplanation = true,
  description,
}: RubricProps) {
  const theme = useMantineTheme();
  return (
    <div>
      <Group
        mb="xs"
        align="center"
        spacing="xs"
      >
        <Text size="md" weight={500} transform="capitalize">{title}</Text>
        <ActionIcon
          color="gray"
          radius="sm"
          onClick={infoButtonHandler}
        >
          <InfoCircleOutlined />
        </ActionIcon>
      </Group>
      <RatingGroup selectScore={selectScore} activeScore={activeScore} maxScore={maxScore} />
      {description && (
        <Text size="sm" mt="sm" color={theme.colors.gray[5]}>{description}</Text>
      )}
      {(concepts?.length || rubricTags?.length || hasExplanation) && (
        <Collapse in={opened} pt="md" pb="md">
          {concepts?.length > 0 && (
            <>
              <Text size="sm" weight={500}>What was not optimal?</Text>
              <Group mb="sm" spacing="xs">
                {concepts.map((concept) => (
                  <Button
                    onClick={() => selectSummary(getId('concept', concept.name))}
                    size="xs"
                    compact
                    radius="sm"
                    key={concept.conceptTitle}
                    data-active={activeConcepts.includes(getId('concept', concept.name))}
                    variant="light"
                  >{concept.conceptTitle}
                  </Button>
                ))}
              </Group>
            </>
          )}
          {
            rubricTags?.length > 0 && (
              <>
                <Text size="sm" weight={500}>What was wrong with it?</Text>
                <Group spacing="xs">
                  {rubricTags?.map((tag) => (
                    <Button
                      onClick={() => selectTag(tag)}
                      size="xs"
                      compact
                      radius="sm"
                      key={tag}
                      data-active={activeTags?.includes(tag)}
                      variant="light"
                    >{tag}
                    </Button>
                  ))}
                </Group>
              </>
            )
          }
          {hasExplanation && (
            <Textarea
              mt="xs"
              value={explanation}
              placeholder="Don’t see the right tag? Add notes here"
              onChange={(e) => updateExplanation(e.target.value)}
            />
          )}
        </Collapse>
      )}
    </div>
  );
}
