import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept, ConceptConceptType, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { ConceptType, Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { capitalize } from 'lodash';

export function filterConceptsByActorType(concepts: Concept[], actorType: ActorType) {
  if (!concepts) return [];
  switch (actorType) {
    case ActorType.AGENT:
      return concepts.filter((concept) => concept.intent?.intentType === IntentIntentType.AGENT_INTENT);
    case ActorType.VISITOR:
      // We assume that CONVERSATION_DRIVER is always VISITOR type
      return concepts.filter((concept) => [IntentIntentType.VISITOR_INTENT, IntentIntentType.CONVERSATION_DRIVER].includes(concept.intent?.intentType));
    default:
      return [];
  }
}

// Previous target message in order
export function findPreviousTargetMessage(messageId: string, messages: Message[]) {
  const messageIndex = messages.findIndex((message) => message.v2MessageId === messageId);
  for (let i = messageIndex - 1; i >= 0; i--) {
    if (messages[i]?.isTargetMessage) {
      return messages[i];
    }
  }
  return null;
}

// Next target message in order
export function findNextTargetMessage(messageId: string, messages: Message[]) {
  const messageIndex = messages.findIndex((message) => message.v2MessageId === messageId);
  for (let i = messageIndex + 1; i < messages.length; i++) {
    if (messages[i]?.isTargetMessage) {
      return messages[i];
    }
  }
  return null;
}

// Next message that should be labeled but isn't
export function findFirstToLabel(messages: Message[], annotationsMap: Map<string, Annotation[]>) {
  for (const messageTuple of messages.entries()) {
    const message = messageTuple[1];
    const annotations = annotationsMap.get(message.v2MessageId) || [];

    // Exception for Policy QA where multiple annotations are expected based on policyQaContext
    const nextIntentPredictions = message?.selectionContext?.policyQaContext?.nextIntentPredictionIds;
    if (nextIntentPredictions && annotations.length < nextIntentPredictions.length) {
      return message;
    } else if (message.isTargetMessage && annotations.length === 0) {
      return message;
    }
  }
  return null;
}

// First message that is a target message
export function findFirstTargetMessage(messages: Message[]) {
  for (const message of messages.entries()) {
    if (message[1].isTargetMessage) {
      return message[1];
    }
  }
  return null;
}

// TODO: Why do we have 2 different types? Refactor?
export function getTaskConceptType(conceptType: ConceptType): ConceptConceptType {
  // Fallback to intent for unspecified type
  if (conceptType === ConceptType.CONCEPT_TYPE_UNSPECIFIED) {
    return ConceptConceptType.INTENT;
  }
  return ConceptConceptType[conceptType];
}

// TODO: Why do we have 2 different types? Refactor?
export function getConceptTypeFromTask(conceptType: ConceptConceptType): ConceptType {
  // Fallback to intent for unspecified type
  if (conceptType === ConceptConceptType.CONCEPT_TYPE_UNSPECIFIED) {
    return ConceptType.INTENT;
  }
  return ConceptType[conceptType];
}

export function getMomentAnnotationType(conceptType: ConceptType): MomentAnnotationType {
  switch (conceptType) {
    case ConceptType.INTENT: {
      return MomentAnnotationType.INTENT;
    }
    case ConceptType.ENTITY: {
      return MomentAnnotationType.ENTITY;
    }
    case ConceptType.CONVERSATION_OUTCOME: {
      return MomentAnnotationType.CONVERSATION_OUTCOME;
    }
    case ConceptType.SUMMARIZATION: {
      return MomentAnnotationType.SUMMARIZATION;
    }
    default:
      return MomentAnnotationType.TYPE_UNSPECIFIED;
  }
}

// Convert rubrics into concepts so they can be used in guidelines display in the same fashion as concepts
export function rubricsToDummyConcepts(rubrics: Rubric[]): Concept[] {
  return rubrics.map((rubric) => {
    const rubricId = rubric.name.split('/')[1];
    const guidelines = Object.keys(rubric.numericRubric.scoreGuidelines).reduce((acc, scoreKey) => `${acc}${scoreKey}: ${rubric.numericRubric.scoreGuidelines[scoreKey]}\n\n`, '');
    const concept: Concept = {
      name: `customers/cresta/profiles/placeholder/concepts/${rubricId}`,
      intent: {},
      description: rubric.rubricDefinition,
      labelingGuideline: guidelines,
      conceptTitle: `QA: ${capitalize(rubric.rubricTitle)}`,
    };
    return concept;
  });
}
