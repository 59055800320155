/* eslint-disable no-await-in-loop */
import {
  Conversation,
  ConversationMessage,
  ConversationMessageSpeakerRole,
  ConversationService,
  ConversationSource,
  GetConversationRequest,
  GetConversationResponse,
  ListConversationMessagesRequest,
  ListConversationMessagesResponse,
} from '@cresta/web-client';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { ConversationSyntheticType } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { Conversation as StudioConversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { getId } from 'common/resourceName';
import { Message as StudioMessage } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import { ProdApi } from './prodApi';

// Sort messages by their create time (= publish time)
function sortConversationMessages(messages: ConversationMessage[]) {
  return orderBy(messages, (item) => dayjs(item.createTime).unix(), 'asc');
}

/** Map conversation type from prod service to the one used by studio services */
export function mapToStudioConversation(conversation: Conversation): StudioConversation {
  if (!conversation) return null;

  let syntheticType = ConversationSyntheticType.SYNTHETIC_TYPE_UNSPECIFIED;
  switch (conversation.source) {
    case ConversationSource.STUDIO_SYNTHETIC_ANNOTATION:
      syntheticType = ConversationSyntheticType.SYNTHETIC_ANNOTATION;
      break;
    case ConversationSource.STUDIO_USER_DEFIEND:
      syntheticType = ConversationSyntheticType.USER_DEFINED_CONVERSATION;
      break;
    default:
      break;
  }

  const newMessage: StudioConversation = {
    name: conversation.name,
    legacyConversationId: conversation.platformInfo.platformConversationId,
    openTime: conversation.startTime,
    endTime: conversation.endTime,
    actorIds: [conversation.agent?.split('/')?.pop()],
    metadata: {
      displayName: conversation.displayName,
      // No longer used
      inGoldenSet: false,
      platformAccountId: conversation.platformInfo.platformAccountId,
      platformConversationId: conversation.platformInfo.platformConversationId,
      prodConversationId: conversation.legacyChatId ? String(conversation.legacyChatId) : '',
      prodConversationIdString: conversation.platformInfo.platformConversationId,
      speechRecognitionEndpoint: conversation.persistedAudioUri,
      syntheticType,
    },
  };

  return newMessage;
}

/** Map message type from prod service to the one used by studio services */
export function mapToStudioMessage(conversationMessage: ConversationMessage): StudioMessage {
  let actorType = ActorType.TYPE_UNSPECIFIED;
  switch (conversationMessage.speaker) {
    case ConversationMessageSpeakerRole.AGENT:
      actorType = ActorType.AGENT;
      break;
    case ConversationMessageSpeakerRole.VISITOR:
      actorType = ActorType.VISITOR;
      break;
    case ConversationMessageSpeakerRole.SYSTEM_MESSAGE:
      actorType = ActorType.SYSTEM;
      break;
    case ConversationMessageSpeakerRole.BOT:
      actorType = ActorType.BOT;
      break;
    default:
      break;
  }

  const messageId = getId('conversationMessage', conversationMessage.name);

  const newMessage: StudioMessage = {
    name: conversationMessage.name,
    content: conversationMessage.text,
    sequencePosition: conversationMessage.sequencePosition,
    actorType,
    actorId: conversationMessage.agent,
    publishTime: conversationMessage.createTime,
    platformMessageId: '',
    prodMessageId: messageId,
    prodMessageIdString: messageId,
  };

  return newMessage;
}

export abstract class ProdConversationApi {
  public static async getConversation(
    request: GetConversationRequest,
  ): Promise<GetConversationResponse> {
    const prodHeaders = await ProdApi.getHeaders();
    return ConversationService.GetConversation(request, prodHeaders.initReq);
  }

  public static async listMessages(
    request: ListConversationMessagesRequest,
  ): Promise<ListConversationMessagesResponse> {
    const prodHeaders = await ProdApi.getHeaders();
    const response = await ConversationService.ListConversationMessages(request, prodHeaders?.initReq);

    // Need to sort messages because their order is not guaranteed
    const sortedMessages = sortConversationMessages(response?.conversationMessages);
    return {
      ...response,
      conversationMessages: sortedMessages,
    };
  }

  public static async listAllMessages(conversation: string): Promise<ConversationMessage[]> {
    const request: ListConversationMessagesRequest = {
      parent: conversation,
      pageSize: 100,
    };
    const ret: ConversationMessage[] = [];
    while (true) {
      const response = await this.listMessages(request);
      ret.push(...response.conversationMessages);
      if (!response.nextPageToken) {
        break;
      }
      request.pageToken = response.nextPageToken;
    }
    return ret;
  }
}
