import React from 'react';
import { Box, CloseButton, Flex, Paper, Stack, Tabs, Text, Title } from '@mantine/core';
import { useSelector } from 'hooks/reduxHooks';
import { selectIntentConceptByNameFactory } from 'store/concept/selectors';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import ConceptTag from 'components/ConceptTag';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';

const TextBlock = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.colors.blue[0],
      borderRadius: theme.radius.md,
      padding: theme.spacing.md,
    })}
  >
    <Text size="sm">{children}</Text>
  </Box>
);

const TextBlockXS = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.colors.blue[0],
      borderRadius: theme.radius.md,
      paddingBlock: 5,
      paddingInline: theme.spacing.xs,
    })}
  >
    <Text size="xs">{children}</Text>
  </Box>
);

const Section = ({
  visible = true,
  title,
  children,
}: {
  visible?: boolean;
  title?: string;
  children: React.ReactNode;
}) => {
  if (!visible) return null;
  return (
    <Flex direction="column">
      {title && (
        <Title order={5} mb={5}>
          {title}
        </Title>
      )}
      {children}
    </Flex>
  );
};

export const LabelingGuidelinesPanel = ({
  intentId,
  onClose,
}: {
  intentId: string;
  onClose: () => void;
}) => {
  const customerProfile = useCustomerProfile();

  const conceptName = `${customerProfile}/concepts/${intentId}`;

  const concept = useSelector(selectIntentConceptByNameFactory(conceptName));
  const [activeTab, setActiveTab] = React.useState('positive');
  if (!concept) return null;
  let conceptRole: 'agent' | 'visitor';
  if (concept?.intent?.intentType === IntentIntentType.AGENT_INTENT) {
    conceptRole = 'agent';
  }
  if (concept?.intent?.intentType === IntentIntentType.VISITOR_INTENT) {
    conceptRole = 'visitor';
  }
  return (
    <Paper
      shadow="sm"
      style={{
        position: 'relative',
        alignSelf: 'flex-start',
        width: 500,
      }}
    >
      <CloseButton style={{ position: 'absolute', right: 10, top: 10 }} onClick={onClose} />
      <Box p="md">
        <ConceptTag role={conceptRole} value={concept.conceptTitle} />
      </Box>
      <Stack
        p="md"
        style={{
          maxHeight: 700,
          overflowY: 'auto',
        }}
      >
        <Section visible={concept.conceptTags.length > 0}>
          <MultiTags type="conceptTag" tags={concept.conceptTags.map((tag) => toTitleCase(tag))} />
        </Section>
        <Section title="Description" visible={!!concept.description}>
          <TextBlock>{concept.description}</TextBlock>
        </Section>
        <Section title="Labeling Guidelines" visible={!!concept.labelingGuideline}>
          <TextBlock>{concept.labelingGuideline}</TextBlock>
        </Section>
        <Section
          title="Canonical Examples"
          visible={concept.positiveExamples.length > 0 || concept.negativeExamples.length > 0}
        >
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List mb={5}>
              <Tabs.Tab value="positive">Positive</Tabs.Tab>
              <Tabs.Tab value="negative">Negative</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="positive">
              <Stack spacing={5}>
                {concept.positiveExamples?.map((example) => (
                  <TextBlockXS key={example}>{example}</TextBlockXS>
                ))}
              </Stack>
            </Tabs.Panel>
            <Tabs.Panel value="negative">
              <Stack spacing={5}>
                {concept.negativeExamples?.map((example) => (
                  <TextBlockXS key={example}>{example}</TextBlockXS>
                ))}
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </Section>
      </Stack>
    </Paper>
  );
};
