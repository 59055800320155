import React from 'react';
import {
  Button,
  Modal,
  Select,
  SelectItem,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import TaskStatusIndicator from 'components/TaskStatusIndicator';
import UserTag from 'components/UserTag';
import { User } from 'types';
import { ConversationSetsInstructionConversationSampleInfo, DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { AbstractTask, TaskStatus, TaskVisibility } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { DataGrid } from 'components/DataGrid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { FolderAddOutlined, FolderOpenOutlined } from '@ant-design/icons';
import ItemPicker from 'components/ItemPicker';
import { PageContainer } from 'components/PageContainer';
import TaskAction from './TaskAction';
// import { DeprecateTaskModal } from './DeprecateTaskModal';

// 05/22/2022 9:30 AM
const MOMENT_FORMAT = 'MM/DD/YYYY h:mm a';

interface TasksSummaryProps {
  handleNewAnalysisBtnClick: () => void;
  isNewAnalysisModalOpen: boolean;
  assigneeSelectData: (string | SelectItem)[]
  onAssigneeChange: (assignee: string) => void;
  statusSelectData: (string | SelectItem)[];
  onStatusChange: (status: string) => void;
  newTaskTitle: string;
  onModalClose: () => void;
  onTaskTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tasks: DerivedLabelingTask[];
  users: User[];
  isLoading: boolean;
  handleTaskArchive: (task: AbstractTask) => void;
  handleTaskUnarchive: (task: AbstractTask) => void;
  isShowingArchived: boolean;
  handleArchiveToggle: () => void;
  updateAssignee: (abstractTask: AbstractTask) => void;
}

export const TasksSummary = ({
  handleNewAnalysisBtnClick,
  isNewAnalysisModalOpen,
  assigneeSelectData,
  onAssigneeChange,
  statusSelectData,
  onStatusChange,
  newTaskTitle,
  onModalClose,
  onTaskTitleChange,
  tasks,
  users,
  isLoading,
  handleTaskArchive,
  handleTaskUnarchive,
  isShowingArchived,
  handleArchiveToggle,
  updateAssignee,
}: TasksSummaryProps) => {
  const columnHelper = createColumnHelper<DerivedLabelingTask>();

  const columns: ColumnDef<DerivedLabelingTask>[] = [

    {
      accessorFn: (row) => row.labelingTask.abstractTask.title,
      header: 'Analysis',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'totalConversationNumber',
      cell: (info) => info.getValue(),
      header: '# Conversations',
    },
    {
      accessorFn: (row) => row.labelingTask.taskData.taskDescriptor.selectionInstruction.conversationSetsInstruction.conversationSampleInfos,
      cell: (info) => {
        const val = info.getValue() as ConversationSetsInstructionConversationSampleInfo[];
        return val.length;
      },
      header: '# Sets',
    },

    {
      header: 'Assignee',
      accessorFn: (row) => row.labelingTask.abstractTask.assigneeUserId,
      cell: (info) => {
        const user = info.getValue() as string;
        const author = users.find((u) => u.id === user)?.full_name;
        return (
          <ItemPicker
            items={[{
              id: '',
              email: '',
              full_name: 'Unassigned',
              role: '',
            }, ...(users || [])]}
            value={author}
            idKeyPath="id"
            renderItem={(user) => <UserTag name={user?.full_name || user?.email} />}
            filterKey={(user) => user?.full_name || user?.email}
            placeholder="Assign to"
            onChange={
          (user) => updateAssignee({
            ...info.row.original.labelingTask.abstractTask,
            assigneeUserId: user.id,
          })
        }
          >
            <UserTag name={author || 'Unassigned'} />
          </ItemPicker>
        );
      },
    },
    {
      header: 'Status',
      accessorFn: (row) => row.labelingTask.abstractTask.status,
      cell: (info) => {
        const val = info.getValue() as TaskStatus;
        return <TaskStatusIndicator status={val} preparing={val === TaskStatus.TASK_CREATED}/>;
      },
    },
    {
      header: 'Date Created',
      accessorFn: (row) => row.labelingTask.abstractTask.createTime,
      cell: (info) => {
        const val = info.getValue() as number;
        return dayjs(val).format(MOMENT_FORMAT);
      },
    },
    {
      header: 'Task Time',
      accessorFn: (row) => row,
      cell: (info) => {
        const val = info.getValue() as DerivedLabelingTask;
        if ([TaskStatus.TASK_READY, TaskStatus.TASK_CREATED].includes(val.labelingTask.abstractTask.status)) {
          return <span>N/A</span>;
        }
        const minutes = val.labelingTimeMinute % 60;
        const hours = Math.floor(val.labelingTimeMinute / 60);
        return `${(hours ? `${hours}h ` : '')}${minutes}${minutes === 1 ? 'min' : 'mins'}`;
      },
    },
    columnHelper.display({
      header: 'Action',
      cell: (props) => {
        const task = props.row.original;
        return (
          <TaskAction derivedTask={task} />
        );
      },

    }),
    columnHelper.display({
      id: 'deprecate',
      cell: (props) => {
        const task = props.row.original;
        const isArchived = task?.labelingTask?.abstractTask.visibility === TaskVisibility.INVISIBLE;
        return (
          <div >
            <Tooltip label={isArchived ? 'Unarchive' : 'Archive'}>
              {isArchived ? (
                <FolderOpenOutlined
                  onClick={() =>
                    handleTaskUnarchive(task.labelingTask.abstractTask)}
                />
              ) : (
                <FolderAddOutlined
                  onClick={() =>
                    handleTaskArchive(task.labelingTask.abstractTask)}
                />
              )}
            </Tooltip>
          </div>
        );
      },
    }),
  ];

  return (
    <PageContainer>
      <Title order={2}>Analysis Workshop</Title>
      <Switch
        checked={isShowingArchived}
        onChange={handleArchiveToggle}
        label={<Text color="dimmed">Show Archived</Text>}
      />
      <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
        <span style={{ flex: 1 }}>{`${tasks.length} total`}</span>
        <Select placeholder="Assignee" clearable data={assigneeSelectData} onChange={onAssigneeChange} />
        <Select placeholder="Status" clearable data={statusSelectData} onChange={onStatusChange} />
        <Button onClick={handleNewAnalysisBtnClick} data-cy="new-analysis-btn">
          New analysis
        </Button>
        <Modal opened={isNewAnalysisModalOpen} onClose={onModalClose} title="New task">
          <TextInput
            data-cy="new-analysis-title-input"
            label="Task Title"
            placeholder="New Manual Analysis Task"
            data-autofocus
            value={newTaskTitle}
            onChange={onTaskTitleChange}
          />
          <Button
            component={Link}
            mt="lg"
            disabled={!newTaskTitle}
            to="create"
            state={{
              taskTitle: newTaskTitle,
            }}
            data-cy="submit-analysis-title-btn"
          >
            Configure analysis
          </Button>
        </Modal>
      </div>
      <DataGrid columns={columns} dataSource={tasks} isLoading={isLoading} />
    </PageContainer>
  );
};
