import { DateRangeSelector } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import {
  SelectionInstruction,
  SelectionMethod,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import dayjs from 'dayjs';
import { QATaskOption } from './ProjectTypes/components/QATaskSetup';

export const getSelectionInstruction: (
  qaTaskOption: QATaskOption,
  formData: {
    after?: Date;
    before?: Date;
    modelUri?: string;
    maxConversationCount?: number;
  },
) => SelectionInstruction = (qaTaskOption, formData) => {
  const { after, before, modelUri, maxConversationCount } = formData;
  const selectionMethod: SelectionMethod = SelectionMethod.SUGGESTION_QA_RANDOM;
  const dateRangeSelector: DateRangeSelector = {
    after: after ? dayjs(after).toISOString() : undefined,
    before: before ? dayjs(before).toISOString() : undefined,
  };

  switch (qaTaskOption) {
    case QATaskOption.SINGLE:
      return {
        selectionMethod,
        suggestionQaRandom: {
          dateRangeSelector,
          maxConversationCount,
        },
      };
    case QATaskOption.COMPARE:
      return {
        selectionMethod,
        suggestionQaRandom: {
          dateRangeSelector,
          modelUri,
          maxConversationCount,
        },
      };
    case QATaskOption.NO_QA:
    default:
      return null;
  }
};
