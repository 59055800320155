import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';

/**
 * A map of intent types with their user friendly names and classes
 */
export const INTENT_TYPE_MAP = new Map<IntentIntentType, {text: string, className: string}>([
  [IntentIntentType.AGENT_INTENT, { text: 'Agent', className: 'agent' }],
  [IntentIntentType.VISITOR_INTENT, { text: 'Visitor', className: 'visitor' }],
  [IntentIntentType.STAGE, { text: 'Stage', className: 'stage' }],
  [IntentIntentType.CONVERSATION_DRIVER, { text: 'Chat Driver', className: 'chat-driver' }],
]);
