import React, { useMemo, useState } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import CopyableValue from 'components/CopyableValue';
import { SimulationTask, SimulationTaskDataSimulationType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import ConceptTag from 'components/ConceptTag';
import MultiTags from 'components/MultiTags';
import useUrlParam from 'hooks/useUrlParam';
import { SimulatorResultIntentTableRow } from './rowTypes';
import { renderDetections } from './SimulatorResultTable';
import { useIntentPredictions } from './useIntentPredictions';
import styles from './SimulatorResult.module.scss';

interface IProps {
  simulationTask: SimulationTask;
}
const columns: ColumnsType<SimulatorResultIntentTableRow> = [
  {
    title: 'Convo ID',
    dataIndex: 'conversationId',
    key: 'conversationId',
    width: '10%',
    render: (conversationId, record) => (
      <div>
        <CopyableValue
          displayValue={`${conversationId.substring(0, 6)}...`}
          copiedValue={conversationId}
        />
      </div>
    ),
  },
  {
    title: 'Message ID',
    dataIndex: 'messageId',
    key: 'messageId',
    width: '10%',
    render: (messageId, record) => (
      <div>
        <CopyableValue
          displayValue={`${messageId.substring(0, 6)}...`}
          copiedValue={messageId}
        />
      </div>
    ),
  },
  {
    title: 'Utterance',
    dataIndex: 'utterance',
    key: 'Utterance',
    width: '50%',
    render: (utterance) => (
      <div>
        {utterance}
      </div>
    ),
  },
  {
    title: 'Detections',
    dataIndex: 'detections',
    key: 'detections',
    width: '30%',
    render: renderDetections,
  },
];
export function SimulatorIntentTable({
  simulationTask,
}: IProps) {
  const [intentFilter, setIntentFilter] = useUrlParam<string>('intentFilter', '');
  const [typeFilter, setTypeFilter] = useUrlParam<string>('changeTypeFilter', '');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [data, loading] = useIntentPredictions(simulationTask);

  const filterDetectionData = useMemo(
    () => data.filter(
      (item) => {
        if (intentFilter && !item.detections?.some((d) => d.intent === intentFilter)) {
          return false;
        }
        if (nameFilter && !item.utterance.toLowerCase().includes(nameFilter.toLowerCase())) {
          return false;
        }
        if (typeFilter && !item.detections?.some((d) => d.changeOfType === typeFilter)) {
          return false;
        }
        return true;
      },
    ),
    [intentFilter, nameFilter, typeFilter, data],
  );

  const intentDetectionSelects: { intent: string, conceptRole: 'agent' | 'visitor' | 'driver' }[] = useMemo(() => {
    const intents = new Set();
    const results: { intent: string, conceptRole: 'agent' | 'visitor' | 'driver' }[] = [];
    data.forEach((item) => {
      item.detections.forEach((detection) => {
        if (intents.has(detection.intent)) {
          return;
        }
        results.push({ intent: detection.intent, conceptRole: detection.role });
        intents.add(detection.intent);
      });
    });
    return results;
  }, [data]);

  return (
    <div className={styles.simulatorResultIntentWrapper}>
      <div className={styles.simulatorResultIntentSelect}>
        <div className={styles.simulatorResultIntentFilter}>
          <Select
            showArrow
            className={styles.simulatorResultFilterSelect}
            size="large"
            placeholder="detection"
            allowClear
            value={intentFilter}
            onChange={(value) => setIntentFilter(value)}
          >
            <Select.Option key="All detection" value="">
              All detection
            </Select.Option>
            {intentDetectionSelects.map((detection, index) => (
              <Select.Option key={detection.intent} value={detection.intent}>
                <div className={styles.simulatorResultFilterSelectOption}>
                  <ConceptTag role={detection.conceptRole} value={detection.intent} />
                </div>
              </Select.Option>
            ))}
          </Select>
          {simulationTask?.taskData?.simulationType !== SimulationTaskDataSimulationType.SINGLE_MODEL && (
            <Select
              showArrow
              className={styles.simulatorResultFilterSelect}
              size="large"
              placeholder="Change Type"
              allowClear
              value={typeFilter}
              onChange={(value) => setTypeFilter(value)}
            >
              <Select.Option key="All Results" value="">
                All results
              </Select.Option>
              <Select.Option key="same" value="same">
                <MultiTags tags={['Same']} />
              </Select.Option>
              <Select.Option key="added" value="added">
                <MultiTags tags={['Added']} color="green" />
              </Select.Option>
              <Select.Option key="removed" value="removed">
                <MultiTags tags={['Removed']} color="red" />
              </Select.Option>
            </Select>
          )}
          <Input
            className={styles.simulatorResultFilterInput}
            suffix={<SearchOutlined />}
            placeholder="Search"
            value={nameFilter}
            onChange={(event) => setNameFilter(event.target.value)}
          />
        </div>
      </div>
      <div className={styles.simulatorResultIntentContent}>
        <div className={styles.tableContainer}>
          <Table
            columns={columns}
            className={styles.simulatorResultIntentTable}
            dataSource={filterDetectionData}
            rowKey="id"
            scroll={{ y: '65vh' }}
            pagination={{
              style: {
                marginRight: '12px',
              },
            }}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
