/* eslint-disable no-await-in-loop */
import {
  ExampleUtterance,
  RefreshGrayAreaRequest,
  RefreshGrayAreaResponse,
} from '@cresta/web-client';
import { CustomIntentApi } from 'services/customIntentApi';
import { ExampleObj } from '../../../types';

export const convertExampleObjsToExampleUtterances = (examples: ExampleObj[]): ExampleUtterance[] =>
  examples.map((example) => ({
    exampleUtteranceId: example.id,
    text: example.value,
  }));

export const convertRefreshGrayAreaResponseToExampleObjs = (
  refreshGrayAreaResponse: RefreshGrayAreaResponse,
): ExampleObj[] => {
  const predictions = refreshGrayAreaResponse?.intentGrayArea?.messagePredictions || [];
  const examples = predictions.map((prediction) => ({
    value: prediction.message.text,
    id: prediction.exampleUtteranceId,
  }));
  return examples;
};

// This API is worth a retry cause it often fails due to slow warmup.
const RETRY_COUNT = 2;
export const fetchSuggestions = async (refreshGrayAreaRequest: RefreshGrayAreaRequest) => {
  for (let i = 0; i < RETRY_COUNT; i++) {
    try {
      return await CustomIntentApi.refreshGrayArea({
        ...refreshGrayAreaRequest,
        intentGrayAreaConfig: {
          //  The maximum number of query examples to be returned.
          maxExampleQueryCount: 4,
          // Strategy to select gray area messages.
          // intentGrayAreaStrategy?: IntentGrayAreaStrategy; // IntentGrayAreaStrategy.INTENT_GRAY_AREA_STRATEGY_UNSPECIFIED || IntentGrayAreaStrategy.LEAST_CONFIDENCE
        },
      });
    } catch (e) {
      if (i === RETRY_COUNT - 1) {
        throw e;
      }
    }
  }
  return {};
};
