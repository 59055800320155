import { ResourceUpdateChangeType } from '@cresta/web-client/dist/cresta/v1/studio/resource_update.pb';
import { ListModelWorkflowsResponse, ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { State } from './state';

/** General reducer for starting a model workflow API. */
export function modelWorkflowApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
}

/** General reducer for a failed model workflow API. */
export function modelWorkflowApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
    error: action.payload,
  };
}

/** Reducer for created model workflow. */
export function createModelWorkflowSuccess(state: State, action: PayloadAction<ModelWorkflow>): State {
  if (state.modelWorkflows.find((w) => w.name === action.payload.name)) {
    return state;
  }
  return {
    ...state,
    modelWorkflows: [action.payload, ...state.modelWorkflows],
    status: 'succeeded',
    error: null,
  };
}

/** Reducer for list model workflows. */
export function listModelWorkflowSuccess(state: State, action: PayloadAction<ListModelWorkflowsResponse>): State {
  if (isEqual(state.modelWorkflows, action.payload.modelWorkflows)) {
    return state;
  }
  return {
    ...state,
    modelWorkflows: action.payload.modelWorkflows,
    nextPageToken: action.payload.nextPageToken,
    status: 'succeeded',
    error: null,
  };
}

/** Reducer for get a model workflow. */
export function getModelWorkflowSuccess(state: State, action: PayloadAction<ModelWorkflow>): State {
  return {
    ...state,
    modelWorkflow: action.payload,
    status: 'succeeded',
    error: null,
  };
}

/** Reducer for Sudio resource update. */
export function modelWorkflowUpdateReducer(state: State, action: PayloadAction<{
  changeType: ResourceUpdateChangeType,
  modelWorkflow: ModelWorkflow,
}>): State {
  const { payload: { modelWorkflow } } = action;
  const index = state.modelWorkflows.findIndex((w) => w.name === modelWorkflow?.name);
  const modelWorkflows = [...state.modelWorkflows];
  switch (action.payload.changeType) {
    case ResourceUpdateChangeType.CREATE:
      if (state.modelWorkflows.find((w) => w.name === modelWorkflow?.name)) {
        return state; // skip update if already existing.
      }
      return {
        ...state,
        modelWorkflows: [...state.modelWorkflows, modelWorkflow],
      };
    case ResourceUpdateChangeType.UPDATE:
      if (index >= 0) {
        modelWorkflows[index] = modelWorkflow;
        return {
          ...state,
          modelWorkflows,
        };
      } else {
        return state;
      }
    case ResourceUpdateChangeType.DELETE:
      return {
        ...state,
        modelWorkflows: modelWorkflows.filter((w) => w.name !== modelWorkflow?.name),
      };
    default:
      return state;
  }
}
