import React from 'react';

const PositiveIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 5.5H6.5V2.5C6.5 2.36739 6.44732 2.24021 6.35355 2.14645C6.25979 2.05268 6.13261 2 6 2C5.86739 2 5.74021 2.05268 5.64645 2.14645C5.55268 2.24021 5.5 2.36739 5.5 2.5V5.5H2.5C2.36739 5.5 2.24021 5.55268 2.14645 5.64645C2.05268 5.74021 2 5.86739 2 6C2 6.13261 2.05268 6.25979 2.14645 6.35355C2.24021 6.44732 2.36739 6.5 2.5 6.5H5.5V9.5C5.5 9.63261 5.55268 9.75979 5.64645 9.85355C5.74021 9.94732 5.86739 10 6 10C6.13261 10 6.25979 9.94732 6.35355 9.85355C6.44732 9.75979 6.5 9.63261 6.5 9.5V6.5H9.5C9.63261 6.5 9.75979 6.44732 9.85355 6.35355C9.94732 6.25979 10 6.13261 10 6C10 5.86739 9.94732 5.74021 9.85355 5.64645C9.75979 5.55268 9.63261 5.5 9.5 5.5Z" />
  </svg>
);

const NegativeIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 5.5H2.5C2.36739 5.5 2.24021 5.55268 2.14645 5.64645C2.05268 5.74021 2 5.86739 2 6C2 6.13261 2.05268 6.25979 2.14645 6.35355C2.24021 6.44732 2.36739 6.5 2.5 6.5H9.5C9.63261 6.5 9.75979 6.44732 9.85355 6.35355C9.94732 6.25979 10 6.13261 10 6C10 5.86739 9.94732 5.74021 9.85355 5.64645C9.75979 5.55268 9.63261 5.5 9.5 5.5Z" />
  </svg>
);

const CloseIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.47138 1.4714C9.73173 1.21105 9.73173 0.78894 9.47138 0.52859C9.21103 0.268241 8.78893 0.268241 8.52858 0.52859L4.99998 4.05719L1.47138 0.52859C1.21103 0.268241 0.788925 0.268241 0.528575 0.52859C0.268226 0.78894 0.268226 1.21105 0.528575 1.4714L4.05717 5L0.528575 8.52859C0.268226 8.78894 0.268226 9.21105 0.528575 9.4714C0.788925 9.73175 1.21103 9.73175 1.47138 9.4714L4.99998 5.9428L8.52858 9.4714C8.78893 9.73175 9.21103 9.73175 9.47138 9.4714C9.73173 9.21105 9.73173 8.78894 9.47138 8.52859L5.94279 5L9.47138 1.4714Z" fill="#757575"/>
  </svg>
);

const InfoIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.3934 1.88021C10.7802 1.37593 10.0623 1.01489 9.29181 0.823438C8.52137 0.631981 7.71791 0.614934 6.94004 0.773542C5.90429 0.982756 4.95403 1.49492 4.20983 2.24506C3.46563 2.9952 2.96102 3.9495 2.76004 4.98688C2.61327 5.76469 2.63994 6.56531 2.83815 7.33163C3.03636 8.09795 3.40122 8.8111 3.90671 9.42021C4.37583 9.94959 4.64473 10.6266 4.66671 11.3335V13.3335C4.66671 13.864 4.87742 14.3727 5.2525 14.7478C5.62757 15.1228 6.13628 15.3335 6.66671 15.3335H9.33338C9.86381 15.3335 10.3725 15.1228 10.7476 14.7478C11.1227 14.3727 11.3334 13.864 11.3334 13.3335V11.4602C11.3558 10.6797 11.6425 9.92983 12.1467 9.33354C13.0301 8.24073 13.4466 6.84342 13.3055 5.44529C13.1644 4.04716 12.4773 2.7612 11.3934 1.86687V1.88021ZM10 13.3335C10 13.5104 9.9298 13.6799 9.80478 13.8049C9.67976 13.93 9.51019 14.0002 9.33338 14.0002H6.66671C6.4899 14.0002 6.32033 13.93 6.1953 13.8049C6.07028 13.6799 6.00004 13.5104 6.00004 13.3335V12.6669H10V13.3335ZM11.1134 8.50688C10.443 9.30194 10.052 10.2949 10 11.3335H8.66671V9.33354C8.66671 9.15673 8.59647 8.98716 8.47145 8.86214C8.34642 8.73711 8.17685 8.66688 8.00004 8.66688C7.82323 8.66688 7.65366 8.73711 7.52864 8.86214C7.40361 8.98716 7.33338 9.15673 7.33338 9.33354V11.3335H6.00004C5.98245 10.3123 5.60467 9.33005 4.93338 8.56021C4.49047 8.02954 4.19264 7.39316 4.06892 6.71311C3.9452 6.03306 3.99981 5.33255 4.22743 4.6799C4.45506 4.02724 4.84792 3.4447 5.36772 2.98909C5.88752 2.53348 6.51652 2.22034 7.19338 2.08021C7.77508 1.96044 8.37615 1.9717 8.95296 2.11319C9.52976 2.25467 10.0678 2.52282 10.5281 2.89818C10.9883 3.27353 11.3592 3.74666 11.6138 4.28322C11.8684 4.81978 12.0004 5.4063 12 6.00021C12.005 6.91345 11.6914 7.79985 11.1134 8.50688Z" />
  </svg>
);

export {
  PositiveIcon,
  NegativeIcon,
  CloseIcon,
  InfoIcon,
};
