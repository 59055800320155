import React, { useState } from 'react';
import { Button, Modal, Tabs } from '@mantine/core';
import { v4 as uuid } from 'uuid';
import { useInputState } from '@mantine/hooks';
import { CreateSetRequest, Set, SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { showNotification } from '@mantine/notifications';
import { createSet } from 'store/set/asyncThunks';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import { useDispatch } from 'hooks/reduxHooks';
import { ImportCSVTab } from './ImportCSVTab';
import { ManualTab } from './ManualTab';

interface NewSetButtonProps {
  isModalOpen: boolean;
  toggleModal: (value: boolean) => void;
  conversationNames: string[];
}

export const NewSetButton = ({ isModalOpen, toggleModal, conversationNames }: NewSetButtonProps) => {
  const [setTitle, setSetTitle] = useInputState('');
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const customer = useCustomerParams();
  const dispatch = useDispatch();

  const [setType, setSetType] = useState(SetType.CONVERSATION_SET_TYPE);
  const [activeTab, setActiveTab] = useState<'manual'|'import'>('manual');

  const onClose = () => {
    toggleModal(false);
    setSetTitle('');
    setActiveTab('manual');
  };

  const onCreate = async (setItemNames: string[]) => {
    const setId = uuid();
    const name = `${customerProfile}/sets/${setId}`;
    const newSetRequest: CreateSetRequest = {
      parent: customerProfile,
      setId,
      set: {
        name,
        usecase,
        setTitle,
        setItemNames,
        setType,
        languageCode: customer?.languageCode,
      },
    };

    try {
      const res = await dispatch(createSet(newSetRequest));
      const set = res.payload as Set;
      if (!set) throw new Error('Set not created');
      showNotification({
        color: 'teal',
        title: 'Success',
        message: `"${set.setTitle}" Successfully Created`,
      });
    } catch (err) {
      showNotification({
        color: 'red',
        title: 'Failed',
        message: `"${setTitle}" Failed to Create`,
      });
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        centered
        opened={isModalOpen}
        onClose={onClose}
        title="Create Set"
      >
        <Tabs
          defaultValue={activeTab}
          value={activeTab}
          onTabChange={(value) => {
            if (value === 'manual') {
              setSetType(SetType.CONVERSATION_SET_TYPE);
            }
            setActiveTab(value as 'manual' | 'import');
          }}
        >
          <Tabs.List mb="lg">
            <Tabs.Tab value="manual">Manual</Tabs.Tab>
            <Tabs.Tab value="import">Import CSV</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="manual">
            <ManualTab conversationNames={conversationNames} setTitle={setTitle} setSetTitle={setSetTitle} onClose={onClose} onCreate={onCreate} />
          </Tabs.Panel>
          <Tabs.Panel value="import">
            <ImportCSVTab
              setTitle={setTitle}
              setSetTitle={setSetTitle}
              onClose={onClose}
              onCreate={onCreate}
              setType={setType}
              setSetType={setSetType}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal>
      <Button
        compact
        variant="light"
        onClick={() => toggleModal(true)}
      >Create Set
      </Button>
    </>
  );
};
