import React, { useState } from 'react';
import {
  Box,
  Input,
  List,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import ConceptTag from 'components/ConceptTag';
import { UseFormReturnType } from '@mantine/form';
import { SpeakerRole } from '@cresta/web-client/dist/cresta/ai_service/common';
import { ConceptTagRole } from 'components/ConceptTag/ConceptTag';
import { BAD_DESCRIPTION_EXAMPLES, GOOD_DESCRIPTION_EXAMPLES } from './descriptionExamples';
import { ConceptData, ConfigFormValues } from '../../../types';
import { TipSection } from '../TipSection';

const ExampleContainer = ({ children }) => (
  <Box
    p="xs"
    sx={(theme) => ({
      border: '1px solid #e0e0e0',
      borderRadius: theme.radius.md,
    })}
  >
    {children}
  </Box>
);

interface ConfigProps {
  configForm: UseFormReturnType<ConfigFormValues>;
  conceptData: ConceptData;
}

export const Config = (props: ConfigProps) => {
  const { configForm, conceptData } = props;
  const [isHumanReadableNameInfoOpen, toggleHumanReadableNameInfo] = useState(false);
  const [isDescriptionInfoOpen, toggleDescriptionInfo] = useState(false);

  const getConceptRoleFromSpeakerRole: (speakerRole: SpeakerRole) => ConceptTagRole = (
    speakerRole,
  ) => (speakerRole === SpeakerRole.AGENT ? 'agent' : 'visitor');

  return (
    <>
      <div>
        <Input.Label>Selected intent:</Input.Label>
        <br />
        <ConceptTag
          // eslint-disable-next-line jsx-a11y/aria-role
          role={getConceptRoleFromSpeakerRole(conceptData.role)}
          value={conceptData.value}
        />
      </div>
      <TextInput
        autoFocus={configForm.getInputProps('humanReadableName').value === ''}
        label="Human-readable name:"
        placeholder="eg: Open Set Expectations"
        {...configForm.getInputProps('humanReadableName')}
        onFocus={() => {
          // re-apply onFocus property of configForm.getInputProps('description')
          configForm.getInputProps('description').onFocus();
          toggleHumanReadableNameInfo(true);
        }}
      />
      <TipSection title="Tip" isVisible={isHumanReadableNameInfoOpen}>
        <List size="sm" listStyleType="disc">
          <List.Item>Title case</List.Item>
          <List.Item>
            {'Try to keep it at around two words (e.g. Request Stop Contacting -> Stop Contacting)'}
          </List.Item>
        </List>
      </TipSection>
      <Textarea
        label="Description"
        placeholder="Describe what the agent/visitor does in this intent. Please use a full sentence."
        minRows={5}
        {...configForm.getInputProps('description')}
        onFocus={() => {
          // re-apply onFocus property of configForm.getInputProps('description')
          configForm.getInputProps('description').onFocus();
          toggleDescriptionInfo(true);
        }}
      />
      <TipSection title="What makes a good description?" isVisible={isDescriptionInfoOpen} maxHeight={55}>
        <Stack>
          <Text size="sm">
            A good description describes in detail what the agent / visitor does in the message that
            should make it count as this intent. Try to use full and grammatically correct
            sentences, and be specific and complete. It works better if you describe things that can
            be clearly deduced from the message. Try to avoid vague or objective terms, or intents
            that cannot be clearly deduced from a single message text.
          </Text>
          <div>
            <Title order={4}>Examples of a good description</Title>
            <Stack>
              {GOOD_DESCRIPTION_EXAMPLES.map((example) => (
                <ExampleContainer key={example}>
                  <Text size="sm">{example}</Text>
                </ExampleContainer>
              ))}
            </Stack>
          </div>
          <div>
            <Title order={4}>Examples of a bad description</Title>
            <Stack>
              {BAD_DESCRIPTION_EXAMPLES.map((example) => (
                <ExampleContainer key={example.text}>
                  <Text component="span" size="sm">
                    {example.text}
                  </Text>
                  <Text ml={5} component="span" color="dimmed" size="sm">
                    {`(why: ${example.reason})`}
                  </Text>
                </ExampleContainer>
              ))}
            </Stack>
          </div>
        </Stack>
      </TipSection>
    </>
  );
};
