import classNames from 'classnames';
import React from 'react';
import './AnnotationTag.scss';

export type AnnotationTagType = 'positive' | 'negative' | 'skipped' | 'flagged' | 'unspecified' | 'no-intent' | 'orange' | 'purple';

interface AnnotationTagProps {
  text: string;
  type: AnnotationTagType;
}

export default function AnnotationTag({
  text,
  type,
}: AnnotationTagProps) {
  return (
    <span
      className={classNames([
        'annotation-tag',
        type,
      ])}
    >
      {text}
    </span>
  );
}
