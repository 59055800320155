/* eslint-disable no-await-in-loop */
import { InitReq } from '@cresta/web-client';
import { exchangeToken, getOktaAccessToken, getProdAccessToken } from 'common/auth';
import { StudioApi } from './studioApi';

/**
 * This service is calling the RPC used by prod - which requires a different token than the one we use for studio
 */
export abstract class ProdApi {
  public static loadingCustomerAccessToken: boolean = false;

  // Exchange studio access token for prod access token
  public static async exchangeToken(customerId: string, profileId: string) {
    this.setLoadingCustomerAccessToken(true);
    const studioAccessToken = getOktaAccessToken();
    try {
      await exchangeToken(studioAccessToken, customerId, profileId);
    } catch (err) {
      console.error('Prod token exchange failed');
    } finally {
      this.setLoadingCustomerAccessToken(false);
    }
  }

  public static setLoadingCustomerAccessToken(value: boolean) {
    this.loadingCustomerAccessToken = value;
  }

  // If the exchange token is still happening, use interval to check for when it's finished
  public static async isTokenLoadingFinished() {
    return new Promise((resolve, reject) => {
      if (!this.loadingCustomerAccessToken) {
        resolve(true);
      } else {
        const waitInterval = setInterval(() => {
          if (!this.loadingCustomerAccessToken) {
            clearInterval(waitInterval);
            resolve(true);
          }
        }, 500);
      }
    });
  }

  // Return headers required for any RPC to prod
  public static async getHeaders(): Promise<{ initReq: InitReq; cancel: () => void; }> {
    const abortController = new AbortController();
    let prodAccessToken: string;

    // Wait in case the exchange of token is still happening
    await ProdApi.isTokenLoadingFinished();

    try {
      prodAccessToken = await getProdAccessToken();
    } catch (err) {
      console.error('Could not get prod token');
    }
    return {
      initReq: {
        pathPrefix: StudioApi.getPublicApiEndpoint(),
        headers: {
          'Content-Type': 'application/json',
          Authorization: prodAccessToken,
        },
        signal: abortController.signal,
      },
      cancel: () => abortController.abort(),
    };
  }
}
