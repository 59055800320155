import {
  CreateModelProjectRequest,
  CreateModelProjectResponse,
  ListModelProjectsRequest,
  ListModelProjectsResponse,
  ModelProjectService,
  UpdateModelProjectRequest,
  UpdateModelProjectResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { StudioApi } from './studioApi';

export abstract class ModelProjectApi {
  public static listModelProjects(request: ListModelProjectsRequest): Promise<ListModelProjectsResponse> {
    return ModelProjectService.ListModelProjects(request, StudioApi.getHeaders().initReq);
  }

  public static createModelProjects(request: CreateModelProjectRequest): Promise<CreateModelProjectResponse> {
    return ModelProjectService.CreateModelProject(request, StudioApi.getHeaders().initReq);
  }

  public static updateModelProject(request: UpdateModelProjectRequest): Promise<UpdateModelProjectResponse> {
    return ModelProjectService.UpdateModelProject(request, StudioApi.getHeaders().initReq);
  }
}
