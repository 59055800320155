import { createSlice } from '@reduxjs/toolkit';
import {
  conversationApiFailed,
  conversationApiPending,
  listConversationMessagesSuccess,
  listConversationsSuccess,
} from './reducers';
import {
  listConversationMessages,
  listConversations,
} from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Concept slice. */
export const conversationSlice = createSlice({
  name: 'conversation',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(listConversationMessages.pending, conversationApiPending)
      .addCase(listConversationMessages.rejected, conversationApiFailed)
      .addCase(listConversationMessages.fulfilled, listConversationMessagesSuccess)
      .addCase(listConversations.pending, conversationApiPending)
      .addCase(listConversations.rejected, conversationApiFailed)
      .addCase(listConversations.fulfilled, listConversationsSuccess),
});
