import React from 'react';
import {
  Annotation, MessageRawDataContextShown,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import LabelingEntity, { EntityLabel } from 'components/LabelingEntity';
import { AnnotationsDisplayType, AnnotationValueType } from 'components/Annotations';
import { Alert, Center, Button } from '@mantine/core';
import NoContextDisplay from './NoContextDisplay';
import './LabelingItemsDisplay.scss';
import ChatDisplay from './ChatDisplay';

// "show the last 3 total messages" See: https://linear.app/cresta/issue/STU-4568/[studio-feature-request]-intent-context-flag-and-labeling-ui
const PARTIAL_CHAT_TRAILING_MESSAGES = 2;
const PARTIAL_CHAT_LEADING_MESSAGES = 0;

interface LabelingItemsDisplayProps {
  messages: Message[];
  entityLabeling?: boolean;
  context: MessageRawDataContextShown,
  // This number is used when context is partial conversation
  partialChatTrailingMessages?: number,
  partialChatLeadingMessages?: number,
  setContext?: (context: MessageRawDataContextShown) => void;
  targetMessageId: string;
  setTargetMessage?: (message: Message, shouldSaveLastMessage?: boolean) => void;
  concepts?: Concept[];
  messageAnnotationsMap?: Map<string, Annotation[]>;
  getConceptTitle?: (conceptId: string) => string;
  failedMessages?: { [id: string]: boolean };
  addEntity?: (entity: EntityLabel) => void;
  updateEntity?: (entity: EntityLabel) => void;
  resetEntities?: () => void;
  showContextButtonVisible?: boolean,
  annotationsDisplayType?: AnnotationsDisplayType;
  customMessageTags?: (message: Message) => JSX.Element;
  blurredMessageId?: string;
  annotationValueType?: AnnotationValueType;
}

export default function LabelingItemsDisplay({
  messages,
  entityLabeling = false,
  context,
  partialChatTrailingMessages = PARTIAL_CHAT_TRAILING_MESSAGES,
  partialChatLeadingMessages = PARTIAL_CHAT_LEADING_MESSAGES,
  setContext = () => {},
  targetMessageId,
  setTargetMessage = () => {},
  concepts = [],
  messageAnnotationsMap = new Map<string, Annotation[]>(),
  getConceptTitle = () => null,
  failedMessages = {},
  addEntity,
  updateEntity,
  resetEntities,
  showContextButtonVisible = false,
  annotationsDisplayType = 'values-colors',
  customMessageTags,
  blurredMessageId,
  annotationValueType,
}: LabelingItemsDisplayProps) {
  const targetMessage = messages.find((message) => message?.v2MessageId === targetMessageId);
  const targetMessageAnnotations = messageAnnotationsMap.get(targetMessageId) || [];
  if (!messages.length) {
    return <Center style={{ height: '100%' }}><Alert color="yellow">No messages to label for this conversation</Alert></Center>;
  }
  const targetMessageConversationId = targetMessage ? targetMessage?.v2ConversationId : '';

  const renderLabelingItems = () => {
    switch (context) {
      case MessageRawDataContextShown.NO_CONTEXT_SHOWN:
        if (entityLabeling) {
          return (
            <LabelingEntity
              entities={concepts}
              addEntityHandler={addEntity}
              updateEntityHandler={updateEntity}
              resetEntitiesHandler={resetEntities}
              message={targetMessage}
              annotations={targetMessageAnnotations}
              error={failedMessages[targetMessage?.v2MessageId] && 'Failed to save labels'}
            />
          );
        } else {
          return (
            <NoContextDisplay
              message={targetMessage}
              annotations={targetMessageAnnotations}
              error={failedMessages[targetMessage?.v2MessageId] && 'Failed to save labels'}
              getConceptTitle={getConceptTitle}
              annotationsDisplayType={annotationsDisplayType}
              annotationValueType={annotationValueType}
            />
          );
        }
      case MessageRawDataContextShown.PARTIAL_CONVERSATION_CONTEXT_SHOWN:
        return (
          <ChatDisplay
            targetMessageId={targetMessageId}
            targetMessageConversationId={targetMessageConversationId}
            messages={messages}
            messageAnnotationsMap={messageAnnotationsMap}
            failedMessages={failedMessages}
            getConceptTitle={getConceptTitle}
            setTargetMessage={setTargetMessage}
            annotationsDisplayType={annotationsDisplayType}
            customMessageTags={customMessageTags}
            blurredMessageId={blurredMessageId}
            visibleMessagesRange={[partialChatTrailingMessages, partialChatLeadingMessages]}
            annotationValueType={annotationValueType}
          />
        );
      case MessageRawDataContextShown.FULL_CONVERSATION_SHOWN:
        return (
          <ChatDisplay
            targetMessageId={targetMessageId}
            targetMessageConversationId={targetMessageConversationId}
            messages={messages}
            messageAnnotationsMap={messageAnnotationsMap}
            failedMessages={failedMessages}
            getConceptTitle={getConceptTitle}
            setTargetMessage={setTargetMessage}
            annotationsDisplayType={annotationsDisplayType}
            customMessageTags={customMessageTags}
            blurredMessageId={blurredMessageId}
            annotationValueType={annotationValueType}
          />
        );
      default:
        return (
          <NoContextDisplay
            message={targetMessage}
            annotations={targetMessageAnnotations}
            error={failedMessages[targetMessage?.v2MessageId] && 'Failed to save labels'}
            getConceptTitle={getConceptTitle}
            annotationsDisplayType={annotationsDisplayType}
            annotationValueType={annotationValueType}
          />
        );
    }
  };

  return (
    <>
      <div className="labeling-items-scroll-wrapper">
        {renderLabelingItems()}
      </div>
      {showContextButtonVisible && (
        <div className="show-context-button">
          <Button
            onClick={() => setContext(
              context === MessageRawDataContextShown.NO_CONTEXT_SHOWN
                ? MessageRawDataContextShown.PARTIAL_CONVERSATION_CONTEXT_SHOWN
                : MessageRawDataContextShown.NO_CONTEXT_SHOWN,
            )}
            variant="subtle"
          >{context === MessageRawDataContextShown.NO_CONTEXT_SHOWN ? 'Show context' : 'Hide context'}
          </Button>
        </div>
      )}
    </>
  );
}
