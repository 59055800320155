import { useEffect, useRef, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import {
  TopicType,
  withEndpoint,
  withToken,
  MetaService,
} from '@cresta/web-client';
import { ResourceUpdateResourceType } from '@cresta/web-client/dist/cresta/v1/studio/resource_update.pb';
import {
  SubscriberClient,
  SubscriptionEvent,
} from '@cresta/web-client/dist/poc';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { getClientSubscriptionToken } from 'common/auth';
import { StudioApi } from 'services/studioApi';
import { modelWorkflowUpdate } from 'store/modelWorkflow/modelWorkflowSlice';
import { useCustomerProfile } from './useCustomerParams';
import { useDispatch } from './reduxHooks';

interface ClientSubscriptionCallback {
  (data: SubscriptionEvent): void;
}

export function useStudioSubscription(
  onSubscriptionEvent: ClientSubscriptionCallback,
): void {
  const customerProfile = useCustomerProfile();
  const { currentConfig } = useContext(CustomerConfigContext);
  const onSubscriptionEventCallback = useRef<ClientSubscriptionCallback>(onSubscriptionEvent);
  useEffect(() => {
    onSubscriptionEventCallback.current = onSubscriptionEvent;
  }, [onSubscriptionEvent]);

  useEffect(() => {
    if (currentConfig) {
      const endpoint = StudioApi.getPublicApiEndpoint(currentConfig);
      const accessToken = getClientSubscriptionToken();
      if (!accessToken) {
        return () => {};
      }
      const meta = new MetaService(
        withEndpoint(endpoint),
        withToken(accessToken),
      );
      const client = new SubscriberClient(uuid(), meta);
      const sub = client
        .subscribe$({
          resource: customerProfile,
          type: TopicType.STUDIO_RESOURCE_UPDATE,
        })
        .subscribe((data) => {
          onSubscriptionEventCallback.current(data);
        });

      return () => {
        sub.unsubscribe();
      };
    }
    return () => {};
  }, [customerProfile, currentConfig]);
}

// Default handler for Studio resource update subscription.
export function useStudioAppSubscription(): void {
  const dispatch = useDispatch();
  return useStudioSubscription((event) => {
    switch (event.type) {
      case 'reconcile':
        break;
      case 'message':
        switch (event.message.studioResourceUpdate?.resourceType) {
          case ResourceUpdateResourceType.MODEL_WORKFLOW:
            dispatch(modelWorkflowUpdate({
              changeType: event.message.studioResourceUpdate?.changeType,
              modelWorkflow: event.message.studioResourceUpdate?.modelWorkflow,
            }));
            break;
          // Add new cases here.
          default:
        }
        break;
      default:
    }
  });
}
