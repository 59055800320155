// TODO(STU-2963): Complete this lib.

/** Studio resource type. */
export type ResourceType = 'abstractTask' | 'labelingTask' | 'simulationTask' | 'annotation' | 'user' | 'prodUser' | 'conversation' |
  'message' | 'conversationMessage' | 'concept' | 'actor' | 'moment' | 'modelProject' | 'modelArtifact' | 'modelWorkflow' | 'set' | 'prediction' |
  'keyValue' | 'dialoguePolicySnapshot' | 'flowNode' | 'usecase' | 'cluster';

const ABSTRACT_TASK_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/abstractTasks\/([^/]+)/;
const LABELING_TASK_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/labelingTasks\/([^/]+)/;
const SIMULATION_TASK_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/simulationTasks\/([^/]+)/;
const ANNOTATION_NAME_REGES = /(customers\/[^/]+\/profiles\/[^/]+)\/annotations\/([^/]+)/;
const USER_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/users\/([^/]+)/;
const PROD_USER_NAME_REGEX = /(customers\/[^/]+)\/users\/([^/]+)/;
const CONVERSATION_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/conversations\/([^/]+)/;
const MESSAGE_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/messages\/([^/]+)/;
const CONVERSATION_MESSAGE_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+\/conversations\/[^/]+)\/messages\/([^/]+)/;
const CONCEPT_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/concepts\/([^/]+)/;
const ACTOR_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/actors\/([^/]+)/;
const MOMENT_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/moments\/([^/]+)/;
const MODEL_PROJECT_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/modelProjects\/([^/]+)/;
const MODEL_ARTIFACT_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+\/modelProjects\/[^/]+)\/modelArtifacts\/([^/]+)/;
const MODEL_WORKFLOW_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/modelWorkflows\/([^/]+)/;
const SET_NAME_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/sets\/([^/]+)/;
const PREDICTION_REGEX = /(customers\/[^/]+\/profiles\/[^/]+\/conversations\/[^/]+\/messages\/[^/]+)\/predictions\/([^/]+)/;
const KEY_VALUE_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/keyValues\/([^/]+)/;
const DIALOGUE_POLICY_SNAPSHOT_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/dialoguePolicySnapshots\/([^/]+)/;
const FLOW_NODE_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/flowNodes\/([^/]+)/;
const USECASE_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/usecases\/([^/]+)/;
const CLUSTER_REGEX = /(customers\/[^/]+\/profiles\/[^/]+)\/clusters\/([^/]+)/;

const resourceNameRegexMap = new Map<ResourceType, RegExp>([
  ['abstractTask', ABSTRACT_TASK_NAME_REGEX],
  ['labelingTask', LABELING_TASK_NAME_REGEX],
  ['simulationTask', SIMULATION_TASK_NAME_REGEX],
  ['annotation', ANNOTATION_NAME_REGES],
  ['user', USER_NAME_REGEX],
  ['prodUser', PROD_USER_NAME_REGEX],
  ['conversation', CONVERSATION_NAME_REGEX],
  ['message', MESSAGE_NAME_REGEX],
  ['conversationMessage', CONVERSATION_MESSAGE_NAME_REGEX],
  ['concept', CONCEPT_NAME_REGEX],
  ['actor', ACTOR_NAME_REGEX],
  ['moment', MOMENT_NAME_REGEX],
  ['modelProject', MODEL_PROJECT_NAME_REGEX],
  ['modelArtifact', MODEL_ARTIFACT_NAME_REGEX],
  ['modelWorkflow', MODEL_WORKFLOW_NAME_REGEX],
  ['set', SET_NAME_REGEX],
  ['prediction', PREDICTION_REGEX],
  ['keyValue', KEY_VALUE_REGEX],
  ['dialoguePolicySnapshot', DIALOGUE_POLICY_SNAPSHOT_REGEX],
  ['flowNode', FLOW_NODE_REGEX],
  ['usecase', USECASE_REGEX],
  ['cluster', CLUSTER_REGEX],
]);

/** Validate the resource name. */
export function validate(resource: ResourceType, name: string): boolean {
  const resourceNameRegex = resourceNameRegexMap.get(resource);
  return resourceNameRegex.test(name);
}

/** Get the parent resource name. */
export function getParent(resource: ResourceType, name: string): string {
  const resourceNameRegex = resourceNameRegexMap.get(resource);
  if (!validate(resource, name)) {
    return '';
  }
  return name.match(resourceNameRegex)[1];
}

/** Get the resource ID. */
export function getId(resource: ResourceType, name: string): string {
  const resourceNameRegex = resourceNameRegexMap.get(resource);
  if (!validate(resource, name)) {
    return '';
  }
  return name.match(resourceNameRegex)[2];
}

const PROFILE_REGEX = /customers\/([^/]+)\/profiles\/([^/]+)/;

export function extractCustomerFromResource(resource: ResourceType, name: string): { customerId: string, profileId: string } {
  const resourceNameRegex = resourceNameRegexMap.get(resource);
  if (!validate(resource, name)) {
    return { customerId: '', profileId: '' };
  }
  const profile = name.match(resourceNameRegex)[1];
  const matches = profile.match(PROFILE_REGEX);
  return { customerId: matches[1], profileId: matches[2] };
}

/** Get the labeling task name from the abstractTaskName. */
export function toLabelingTaskName(abstractTaskName: string): string {
  if (validate('abstractTask', abstractTaskName)) {
    const parent = getParent('abstractTask', abstractTaskName);
    const taskId = getId('abstractTask', abstractTaskName);
    return `${parent}/labelingTasks/${taskId}`;
  }
  return '';
}
