import { Input, Tooltip } from 'antd';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Overlay } from '@mantine/core';
import classNames from 'classnames';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import moment from 'moment';
import { useAgentNames } from 'hooks/useAgentNames';
import Loader from 'pages/Conversations/Loader';
import { getId } from 'common/resourceName';
import { SetManagementDropdown } from 'components/SetManagementDropdown/SetManagementDropdown';
import { selectApiStatus, selectConversationSets } from 'store/set/selectors';
import { useSelector } from 'hooks/reduxHooks';
import { ChatCard } from './ChatCard';
import styles from './style.module.scss';

const RegressionTestTooltipWrapper = ({ isInRegressionTestMode, children }) => {
  if (isInRegressionTestMode) {
    return (
      <Tooltip title="Not editable for regression test">
        {children}
      </Tooltip>
    );
  }
  return children;
};

interface ConvoProps {
  conversations: Conversation[];
  handleImportConversations: (options?: { goldenOnly?: boolean, setIds?: string[] }) => void;
  handleAddConversation: () => void;
  handleClickCard: (conversationId: string) => void;
  handleDeleteCard: (conversationId: string) => void;
  activeChatCardId: string;
  handleGetConversation: (conversationId: string) => void;
  disableAll?: boolean;
  isInRegressionTestMode?: boolean;
}
export const ConversationsList: FC<ConvoProps> = ({
  conversations,
  handleClickCard,
  handleDeleteCard,
  handleImportConversations,
  handleGetConversation,
  handleAddConversation,
  activeChatCardId,
  disableAll,
  isInRegressionTestMode,
}) => {
  const [convoId, setConvoId] = useState('');
  const { getAgentNameFromActorId } = useAgentNames();
  const [search, setSearch] = useState('');
  const [cardData, setCardData] = useState([]);
  const apiStatus = useSelector(selectApiStatus);
  const reduxSets = useSelector(selectConversationSets);
  const isLoading = apiStatus === 'loading';
  const [selectedSetIds, setSelectedSetIds] = useState([]);

  useEffect(() => {
    const activeSets = reduxSets.filter((set) => set.state !== 'DEPRECATED');
    const fetchedCardData = activeSets.map((set) => ({ value: getId('set', set.name), label: set.setTitle, subLabel: `${set.itemCount === undefined ? 0 : set.itemCount} conversations` }));
    setCardData(fetchedCardData);
  }, [reduxSets]);
  const filteredList = cardData
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));

  const handleSelectSetId = useCallback((setId: string) => {
    if (selectedSetIds.includes(setId)) {
      return;
    }
    const nextSelectedSetIds = [...selectedSetIds, setId];
    setSelectedSetIds(nextSelectedSetIds);
    handleImportConversations({ setIds: [setId] });
  }, [selectedSetIds, setSelectedSetIds, handleImportConversations]);

  useEffect(() => {
    if (!conversations?.length || !handleClickCard) return;
    const activeConversation = conversations.find((conv) => getId('conversation', conv.name) === activeChatCardId);
    if (!activeConversation) {
      handleClickCard(getId('conversation', conversations[0].name));
    }
  }, [conversations]);

  const handleEnterConvoId = useCallback(() => {
    handleGetConversation((convoId));
    setConvoId('');
  }, [convoId, handleGetConversation]);

  const handleClickAdd = useCallback(() => {
    if (disableAll) {
      return;
    }
    handleAddConversation();
  }, [disableAll, handleAddConversation]);
  return (
    <div className={styles.simulatorConvo}>
      <div className={styles.simulatorConvoTitle}>
        {conversations?.length || 0} convos added
      </div>
      <RegressionTestTooltipWrapper isInRegressionTestMode={isInRegressionTestMode}>
        <div className={styles.simulatorConvoImport}>
          {isInRegressionTestMode && <Overlay />}
          <Input
            onChange={(event) => setConvoId(event.target.value)}
            onPressEnter={handleEnterConvoId}
            value={convoId}
            placeholder="Enter convo ID here"
            disabled={disableAll}
            size="small"
          />
          <SetManagementDropdown
            isLoading={isLoading}
            search={search}
            setSearch={setSearch}
            selectedValues={selectedSetIds}
            onSelect={handleSelectSetId}
            cardData={filteredList}
            iconOnly
          />
        </div>
      </RegressionTestTooltipWrapper>
      <RegressionTestTooltipWrapper isInRegressionTestMode={isInRegressionTestMode}>
        <div
          onClick={isInRegressionTestMode ? null : handleClickAdd}
          className={classNames([styles.simulatorAddConvo, disableAll ? styles.disabled : ''])}
        >
          {isInRegressionTestMode && <Overlay />}
          <PlusOutlined />
          <span className={styles.simulatorAddConvoText}>Add synthetic convo</span>
        </div>
      </RegressionTestTooltipWrapper>
      <div className={styles.simulatorConvoList}>
        {conversations ? conversations.map((convo) => {
          const id = getId('conversation', convo.name);
          const title = convo.metadata?.displayName || getAgentNameFromActorId(convo.actorIds[0]) || id;
          const active: boolean = id === activeChatCardId;
          const inGoldenSet = convo.metadata?.inGoldenSet;
          return (
            <ChatCard
              key={id}
              inGoldenSet={inGoldenSet}
              handleDeleteCard={handleDeleteCard}
              handleClickCard={handleClickCard}
              chatCardTitle={title}
              active={active}
              conversationId={id}
              chatOpenTime={moment(convo.openTime).format('MM/DD/YYYY h:mma')}
              hiddenDeleteIcon={isInRegressionTestMode}
            />
          );
        }) : <Loader />}
      </div>
    </div>
  );
};
