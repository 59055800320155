import { ConfusionType, OneVsAllPredictionWithEvaluation } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { Select, Tag, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { ReactElement, useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import './ConfusionMatrix.scss';

interface Record extends OneVsAllPredictionWithEvaluation {
  key?: string;
}

const columns: ColumnType<Record>[] = [
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: 'Intent',
    dataIndex: 'label',
    key: 'label',
    sorter: (r1: Record, r2: Record) => r1.label.localeCompare(r2.label),
    render: (value: string) => (<Tag color="blue">{value}</Tag>),
  },
  {
    title: 'Label',
    dataIndex: 'labelValue',
    key: 'labelValue',
    sorter: (r1: Record, r2: Record) => r1.labelValue.toString().localeCompare(r2.labelValue.toString()),
    render: (labelValue) => String(labelValue),
  },
  {
    title: 'Pred',
    dataIndex: 'predForLabel',
    key: 'predForLabel',
    render: (predForLabel) => String(predForLabel),
  },
  {
    title: 'Pred Score',
    dataIndex: 'predScoreForLabel',
    key: 'predScoreForLabel',
    render: (predScoreForLabel) => String((Math.round(predScoreForLabel * 100) / 100).toFixed(3)),
  },
  {
    title: 'Full Predictions',
    dataIndex: 'predLabels',
    key: 'prediction',
    // Render green color if true-positive; red if false-positive.
    render: (value: string[], record: Record) => (
      <div className="predictions">
        {value.map((pred, index) => (
          <Tag
            className="tag"
            color={pred === record.label ? (record.labelValue && '#87d068') || (!record.labelValue && '#cd201f') : ''}
          >
            {`${pred}: ${record.predScores[index]?.toFixed(2)}`}
          </Tag>
        ))}
      </div>
    ),
  },
];

interface Filters {
  intents: string[];
  result?: ConfusionType;
}

interface Props {
  testPredictions: OneVsAllPredictionWithEvaluation[];
  trainPredictions: OneVsAllPredictionWithEvaluation[];
}

export function ConfusionMatrix({
  testPredictions,
  trainPredictions,
}: Props): ReactElement {
  const testRecords = useMemo(() => testPredictions.map((r) => ({ ...r, key: uuid() })), [testPredictions]);
  const trainRecords = useMemo(() => trainPredictions.map((r) => ({ ...r, key: uuid() })), [trainPredictions]);
  const [filters, setFilters] = useState<Filters>({ intents: [] });

  const allIntents = [...testRecords.map((p) => p.label), ...trainRecords.map((p) => p.label)];
  const uniqueIntents = [...new Set(allIntents)];

  const filterFn = (r: Record) => {
    if (filters.intents.length > 0 && !filters.intents.includes(r.label)) {
      return false;
    }
    if (filters.result != null && r.confusionType !== filters.result) {
      return false;
    }
    return true;
  };
  const filteredTestPredictions = testRecords.filter(filterFn);
  const filteredTrainPredictions = trainRecords.filter(filterFn);
  return (
    <div className="model-builder-confusion-matrix">
      <div className="filters">
        <Select
          mode="multiple"
          showArrow
          className="select"
          size="large"
          placeholder="Intents"
          allowClear
          value={filters.intents}
          tagRender={(props) => (
            <div style={{ paddingLeft: '5px' }}>
              <Tag color="blue">{props.value}</Tag>
            </div>
          )}
          onChange={(value) => setFilters({ ...filters, intents: value })}
        >
          {uniqueIntents.map((intent) => (
            <Select.Option key={intent} value={intent}>
              <Tag color="blue">{intent}</Tag>
            </Select.Option>
          ))}
        </Select>
        <Select
          showArrow
          className="select"
          size="large"
          placeholder="Prediction result"
          allowClear
          value={filters.result}
          onChange={(value: ConfusionType) => setFilters({ ...filters, result: value })}
        >
          <Select.Option key="true-positive" value={ConfusionType.TRUE_POSITIVE}>
            true-positive
          </Select.Option>
          <Select.Option key="true-negative" value={ConfusionType.TRUE_NEGATIVE}>
            true-negative
          </Select.Option>
          <Select.Option key="false-positive" value={ConfusionType.FALSE_POSITIVE}>
            false-positive
          </Select.Option>
          <Select.Option key="false-negative" value={ConfusionType.FALSE_NEGATIVE}>
            false-negative
          </Select.Option>
        </Select>
      </div>
      <div>
        <Table
          title={() => 'Test Predictions'}
          columns={columns}
          dataSource={filteredTestPredictions}
          rowKey={(record: Record) => record.key}
        />
        <Table
          title={() => 'Train Predictions'}
          columns={columns}
          dataSource={filteredTrainPredictions}
          rowKey={(record: Record) => record.key}
        />
      </div>
    </div>
  );
}
