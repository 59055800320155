export const createOrderedSubset = (conversationNames: string[], sampleSize: number) => {
  const subset = [];
  const limit = Math.min(sampleSize, conversationNames.length);

  for (let i = 0; i < limit; i++) {
    subset.push(conversationNames[i]);
  }
  return subset;
};

// Fisher-Yates shuffle algorithm
const shuffleStringArray = (array: string[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const createRandomSubset = (conversationNames: string[], sampleSize: number) => {
  const shuffledArray = shuffleStringArray(conversationNames);
  const subset = createOrderedSubset(shuffledArray, sampleSize);
  return subset;
};

// data will be an array of objects, as the objects will only have one key-value pair, we want to return only the value of the first key-value pair
export const transformSingleColumnCSVData: (data: Record<string, string>[]) => string[] = (
  data,
) => {
  const returnFirstColumnValue = (row: Record<string, string>) => Object.values(row)[0];

  const arrayOfIds = data.map(returnFirstColumnValue);
  return arrayOfIds;
};
