import React from 'react';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { Container, Stack, Text, Title } from '@mantine/core';
import { TrainingTable } from '../Tables/TrainingTable';

interface Props {
  onClickPreviewEval: (workflowName: string) => void;
  modelWorkflows: ModelWorkflow[]
}

export function TrainStep(props: Props) {
  const { onClickPreviewEval, modelWorkflows } = props;
  return (
    <>
      <Stack mx={40} mb="sm" style={{ background: '#fff' }} spacing={0}>
        <Title order={2}>
          Training in progress...
        </Title>
        <Text>
          Ready in about 5mins
        </Text>
      </Stack>
      <Container fluid style={{ background: '#F1F3F4', flex: 1, width: '100%' }} px={40} py="sm">
        <TrainingTable
          onClickPreviewEval={onClickPreviewEval}
          dataSource={modelWorkflows}
        />
      </Container>
    </>
  );
}
