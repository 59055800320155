import React, { ReactElement, useCallback, useEffect, useMemo, useContext } from 'react';
import { ModelWizardProjectStep } from 'models';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { createModelWorkflow, listModelWorkflow } from 'store/modelWorkflow/asyncThunks';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { CreateModelWorkflowRequest, ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { selectModelWorkflows } from 'store/modelWorkflow/selectors';
import { UserContext } from 'context/UserContext';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';
import { Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import CopyableValue from 'components/CopyableValue';
import ModelWizardStepper from 'pages/ModelWizardProject/ModelWizardProjectStepper';
import { selectDerivedLabelingTasksMap } from 'store/labelingTask/selectors';
import { ConfigStep, EvalStep, TrainStep } from '../components/Steps';
import { useQAConfigForm } from '../components/QATaskSetup';
import { getSelectionInstruction } from '../../getSelectionInstruction';
import { RetrievalConfigForm, useRetrievalConfigForm } from './Config';
import { CannedSuggestionsTable, QATable, convertEvaluationStatResultsToTableData, QuantitativeScoresTable } from '../components/Tables';
import { useModelWorkflow } from '../useModelWorkflow';

export function Retrieval(): ReactElement {
  const [activeStepIndex, setActiveStepIndex] = useUrlParam<number>('step', 0);
  const activeStepIndexNumber = Number(activeStepIndex);
  const [workflowId, setWorkflowId, clearWorkflowId] = useUrlParam<string>('workflowId');
  const [,, clearCompareTo] = useUrlParam<string>('compareTo', '');
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const currentUser = useContext(UserContext);
  const modelWorkflows = useSelector<ModelWorkflow[]>(selectModelWorkflows);
  const params = useParams<{ customer: string; projectId: string }>();
  const modelWorkflow = useModelWorkflow(workflowId);
  const qaTasksMap = useSelector(selectDerivedLabelingTasksMap);
  const qaTask = qaTasksMap.get(getId('labelingTask', modelWorkflow?.trainSuggestionsRetrieverOutput?.qaTask?.labelingTask?.name));

  useEffect(() => {
    dispatch(listModelWorkflow({
      parent: customerProfile,
      filter: { modelProjectId: params.projectId },
    }));
  }, [customerProfile, params.projectId]);

  const onClickPreviewEval = useCallback((workflowName: string) => {
    const id = getId('modelWorkflow', workflowName);
    if (id) {
      setWorkflowId(id);
      setActiveStepIndex(2);
    }
  }, [setActiveStepIndex, setWorkflowId]);

  const retrievalForm = useRetrievalConfigForm();
  const qaConfigForm = useQAConfigForm();

  const onClickNext = useCallback(() => {
    const modelWorkflowId = crypto.randomUUID();

    const getModelWorkflow: () => ModelWorkflow = () => {
      const modelProject = `${customerProfile}/modelProjects/${params.projectId}`;
      const startTime = dayjs(retrievalForm.values.conversationDates[0]).toISOString();
      const endTime = dayjs(retrievalForm.values.conversationDates[1]).toISOString();
      const testStartTime = retrievalForm.values.testConversationDates[0] ? dayjs(retrievalForm.values.testConversationDates[0]).toISOString() : null;
      const testEndTime = retrievalForm.values.testConversationDates[1] ? dayjs(retrievalForm.values.testConversationDates[1]).toISOString() : null;
      // const selectionMethod: SelectionMethod = SelectionMethod.SUGGESTION_QA_RANDOM;
      const selectionInstruction = getSelectionInstruction(qaConfigForm.values.taskOption, {
        after: qaConfigForm.values.qaConversationDates[0],
        before: qaConfigForm.values.qaConversationDates[1],
        modelUri: qaConfigForm.values.qaComparisonModelUrl,
        maxConversationCount: qaConfigForm.values.qaConversationCount,
      });

      const ret: ModelWorkflow = {
        modelProject,
        trainSuggestionsRetrieverConfig: {
          //  * Start time for training data (inclusive). Should be truncated to a day in America/Los_Angeles
          //  * timezone. If it's not, it will be truncated.
          startTime,
          //  * End time for training data (exclusive). Should be truncated to a day in America/Los_Angeles
          //  * timezone. If it's not, it will be truncated.
          endTime,
          //  * Approximate ratio of partial chats that should go into the training split rather than the
          //  * validation split, e.g., 0.9. Defaults to 0.8.
          trainSplitRatio: retrievalForm.values.trainSplitRatio === 0 ? 0 : retrievalForm.values.trainSplitRatio / 100,
          //  * Start time for test data (inclusive). Should be truncated to a day in
          //  * America/Los_Angeles timezone. If it's not, it will be truncated.
          testStartTime,
          //  * Start time for test data (exclusive). Should be truncated to a day in
          //  * America/Los_Angeles timezone. If it's not, it will be truncated.
          testEndTime,
          //  * The maximum number of canned suggestion candidates allowed for matching a particular passage.
          //  * If the number of matches exceeds this, the passage is considered ambiguous and not considered
          //  * for training. Defaults to 10.
          maxMultipleCandidatesAllowed: retrievalForm.values.maxMultipleCandidatesAllowed,
          //  * Context size in number of past messages when creating training data. Defaults to 5.
          pastNMessages: retrievalForm.values.pastNMessages,
          selectionInstruction,
          processConfigOptions: {
            loadPreprocessingFromConfigRepo: retrievalForm.values.loadPreprocessingConfig,
            loadPostprocessingFromConfigRepo: retrievalForm.values.loadPostprocessingConfig,
            materializeConfigs: retrievalForm.values.materializeConfigs,
          },
        },
      };
      return ret;
    };

    const modelWorkflow = getModelWorkflow();
    const request: CreateModelWorkflowRequest = {
      parent: customerProfile,
      modelWorkflowId,
      commonInput: {
        title: modelWorkflowId,
        assigneeUserId: currentUser?.id,
      },
      modelWorkflow,
    };
    dispatch(createModelWorkflow(request)).then(() => {
      setActiveStepIndex(1);
      dispatch(listModelWorkflow({ parent: customerProfile, filter: { modelProjectId: params.projectId } }));
    });
  }, [retrievalForm]);

  const quantitaveScoresTableData = useMemo(() => convertEvaluationStatResultsToTableData(modelWorkflow?.trainSuggestionsRetrieverOutput?.trainOutputs?.evaluationStats?.results || []), [modelWorkflow?.trainSuggestionsRetrieverOutput?.trainOutputs?.evaluationStats?.results]);
  const selectionInstruction = modelWorkflow?.trainSuggestionsRetrieverConfig?.selectionInstruction;
  const model = modelWorkflow?.trainSuggestionsRetrieverOutput?.ensembleModelUri;
  const STEPS: ModelWizardProjectStep[] = [
    {
      label: 'Configure',
      children: (
        <ConfigStep onClickNext={onClickNext}>
          <RetrievalConfigForm retrievalForm={retrievalForm} qaConfigForm={qaConfigForm} />
          <CannedSuggestionsTable />
        </ConfigStep>),
    },
    {
      label: 'Train',
      children: <TrainStep
        onClickPreviewEval={onClickPreviewEval}
        modelWorkflows={modelWorkflows}
      />,
    },
    {
      label: 'Evaluate',
      children: (
        <EvalStep
          modelWorkflows={modelWorkflows}
        >
          <Stack style={{ flex: 1 }}>
            {model && <CopyableValue copiedValue={model} displayValue={model} />}
            <Text>Quantitative Scores</Text>
            <QuantitativeScoresTable dataSource={quantitaveScoresTableData} />
            {selectionInstruction && (
              <>
                <Text>QA Scores</Text>
                <QATable selectionInstruction={selectionInstruction} qaTask={qaTask?.labelingTask} />
              </>
            )}
          </Stack>
        </EvalStep>),
    },
  ];

  return (
    <ModelWizardStepper
      steps={STEPS}
      stepIndex={activeStepIndexNumber}
      onStepClick={(index) => {
        if (index === activeStepIndexNumber) {
          return;
        }
        setActiveStepIndex(index);
        clearWorkflowId();
        clearCompareTo();
      }}
    />
  );
}
