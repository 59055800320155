import React, { useState, useEffect } from 'react';
import { Box, Center, Flex, Group, Stack, Text, Title, createStyles, keyframes } from '@mantine/core';
import { ParrotIcon } from 'components/Sidebar/icons';

const quickFade = keyframes({
  'from, to': { opacity: 0 },
  '33%': { opacity: 1 },
});

const delayedFade = keyframes({
  'from, to': { opacity: 0 },
  '66%': { opacity: 1 },
});

const Period = ({ className = '' }) => <Text className={className} size="xl" fw="bold">.</Text>;

const useStyles = createStyles((theme) => ({
  loadingPhrase: {
    // match with interval milliseconds in useEffect
    animation: `${quickFade} 8s ease-in-out infinite`,
  },
  finalPeriod: {
    animation: `${delayedFade} 1s linear infinite`,
  },
  penUltimatePeriod: {
    animation: `${quickFade} 1s linear infinite`,
  },
}));

export default function Loading() {
  const loadingPhrases = [
    'Harnessing power from ocean waves',
    'Becoming sentient',
    'Lightly toasting the bread',
    'Grilling the sausage',
    'Putting on the mustard',
    'Adding some melted Canadian cheddar cheese',
    'Grabbing some chips for on the side',
    'Sautéing the onions',
    'Shipping hotdogs',
    'Firing neurons',
    'Activating flux capacitors',
    'Decoding alien signals',
    'Changing weather',
  ];
  const getRandomPhrase = () =>
    loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];
  const [loadingPhrase, setLoadingPhrase] = useState(
    loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)],
  );
  const { classes } = useStyles();
  useEffect(() => {
    const id = setInterval(() => {
      let newRandomPhrase: string = getRandomPhrase();

      while (loadingPhrase === newRandomPhrase) {
        newRandomPhrase = getRandomPhrase();
      }

      setLoadingPhrase(newRandomPhrase);
      // match with animation keyframe in scss file
    }, 8000);
    return () => clearInterval(id);
  }, [loadingPhrase]);

  return (
    <Box
      sx={(theme) => ({
        color: theme.white,
        background: '#648ff5',
      })}
    >
      <Center h="100vh">
        <Stack align="center">
          <Flex>
            <ParrotIcon />
            <Title pl="lg">Loading</Title>
            <Group pl={5} align="flex-end" spacing={5}>
              <Period />
              <Period className={classes.penUltimatePeriod} />
              <Period className={classes.finalPeriod} />
            </Group>
          </Flex>
          <Text className={classes.loadingPhrase}>{loadingPhrase}</Text>
        </Stack>
      </Center>
    </Box>
  );
}
