import { useCallback, useContext } from 'react';
import { Concept, UpdateConceptRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { updateConcept } from 'store/concept/asyncThunks';
import { toRequestUpdateMask } from 'utils';
import { getId } from 'common/resourceName';
import { showNotification } from '@mantine/notifications';
import { v4 as uuid } from 'uuid';
import { DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { AnnotationApi } from 'services/annotationApi';
import {
  AnnotationMutability,
  AnnotationRawDataType,
  AnnotationState,
  AnnotationValuePresenceType,
  AnnotationValueType,
  BinaryValueValue,
  MessageRawDataContextShown,
  TargetType,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { getConceptTypeFromTask, getMomentAnnotationType } from 'pages/LabelingView/utils';
import { searchAnnotations } from 'store/annotation/asyncThunks';
import { useDispatch } from 'hooks/reduxHooks';
import { UserContext } from 'context/UserContext';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';

export const useUtteranceModalFunctions = () => {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const currentUser = useContext(UserContext);

  const addPositiveExamplesToIntent = (intent: Concept, examples: string[]) => {
    // get concept by id
    const previousPositiveExamples = intent?.positiveExamples || [];
    const positiveExamples = [...previousPositiveExamples, ...examples];

    const updatedConcept: UpdateConceptRequest = {
      concept: {
        name: intent.name,
        positiveExamples,
      },
      userResourceName: currentUser?.name,
      updateMask: toRequestUpdateMask(['positiveExamples']),
    };
    dispatch(updateConcept(updatedConcept));
  };
  const createRawAnnotationTuple = useCallback(
    (message: string, value: BinaryValueValue, concept: Concept, split: DataSplit) => ({
      name: `${customerProfile}/annotations/${uuid()}`,
      languageCode: customer.languageCode,
      mutability: AnnotationMutability.IMMUTABLE,
      split,
      state: AnnotationState.ACTIVE_MANUALLY_ADDED,
      targetType: TargetType.LABELING,
      momentAnnotationType: getMomentAnnotationType(getConceptTypeFromTask(concept?.conceptType)),
      rawDataType: AnnotationRawDataType.TYPE_ON_MESSAGE,
      rawData: {
        type: AnnotationRawDataType.TYPE_ON_MESSAGE,
        messageRawData: {
          synthetic: true,
          syntheticMessage: message,
          contextShown: MessageRawDataContextShown.NO_CONTEXT_SHOWN,
        },
      },
      value: {
        type: AnnotationValueType.TYPE_BINARY,
        presenceType: AnnotationValuePresenceType.PRESENCE,
        binaryValue: {
          value,
          conceptId: getId('concept', concept.name),
        },
      },
      valueType: AnnotationValueType.TYPE_BINARY,
    }),
    [getId, getMomentAnnotationType, getConceptTypeFromTask, customerProfile, customer?.path],
  );

  const addPositiveAnnotationsToIntent = async (intent: Concept, positiveAnnotations: string[]) => {
    const annotations = positiveAnnotations.map((positiveExample) =>
      createRawAnnotationTuple(
        positiveExample,
        BinaryValueValue.VALUE_POSITIVE,
        intent,
        DataSplit.TRAIN,
      ));
    try {
      await AnnotationApi.batchUpsertAnnotations({
        parent: customerProfile,
        annotations,
      });
      dispatch(searchAnnotations({
        parent: customerProfile,
        usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
        languageCode: customer.languageCode,
      }));
    } catch (err) {
      console.error(err);
      showNotification({
        color: 'red',
        message: 'Error adding synthetic labels',
      });
    }
  };
  return {
    addPositiveExamplesToIntent,
    addPositiveAnnotationsToIntent,
  };
};
