import React, { useState } from 'react';
import './Sidebar.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { Popover } from 'antd';
import { ChevronDown } from './icons';

interface NestedMenuItemProps {
  drawerOpen: boolean
  submenuItems: {
    to: string,
    label: string
  }[]
  label: string
  icon: any
  toggleOpen: (value: boolean) => void
}

export const NestedMenuItem = ({ drawerOpen, submenuItems, label, icon, toggleOpen }: NestedMenuItemProps) => {
  const location = useLocation();
  const isActiveRoute = submenuItems.some(({ to }) => location.pathname.includes(to));
  const [isExpanded, toggleExpanded] = useState(false);
  const Icon = icon;
  const subMenuContent = submenuItems.map(({ to, label }) => (
    <NavLink
      key={label}
      className={({ isActive }) => (isActive ? 'active nav-link-item' : 'nav-link-item')}
      to={to}
      onClick={() => toggleOpen(false)}
    >
      <span className="sub-item">{label}</span>
    </NavLink>
  ));

  return drawerOpen
    ? (
      <>
        <div
          className={`nav-link-item ${isActiveRoute && 'active'} parent-menu`}
          onClick={() => toggleExpanded(!isExpanded)}
        >
          <Icon />
          <span>{label}</span>
          <ChevronDown className={`chevron${isExpanded ? ' expanded' : ''}`} />
        </div>
        {isExpanded && (
          subMenuContent
        )}
      </>
    )
    : (
      <Popover
        placement="rightTop"
        title={<div className="condensed-submenu-title"><Icon /><span>{label}</span></div>}
        content={subMenuContent}
        trigger="hover"
        overlayClassName="condensed-submenu"
      >
        <div className={`nav-link-item ${isActiveRoute && 'active'}`}>
          <Icon />
        </div>
      </Popover>
    );
};
