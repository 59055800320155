import { CreatorCreatorType, DateRangeSelector } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import {
  ConceptType,
  DecisionType,
  DerivedLabelingTask,
  LabelingTask,
  LabelingViewType,
  QaDataSource,
  SelectionInstruction,
  SelectionMethod,
  SummarizationInstructionRubricTagSelection,
  UserLabelingTaskType,
  IntentMisclassificationAugmentationInstructionAugmentationMethod as AugmentationMethod,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { CreateTaskRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { CustomerParams, useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { UserContext } from 'context/UserContext';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { Concept, ConceptConceptType, IntentState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConcepts, selectConceptsFactory } from 'store/concept/selectors';
import { getId } from 'common/resourceName';
import { selectApiStatus, selectDerivedQaTasks } from 'store/labelingTask/selectors';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { selectUsers } from 'store/user/selectors';
import { User } from 'types';
import useUrlParam from 'hooks/useUrlParam';
import { AbstractTask, TaskStatus, TaskVisibility } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import Loading from 'components/Loading';
import { Dropdown, Select, Switch, Table, Tooltip } from 'antd';
import TaskStatusIndicator from 'components/TaskStatusIndicator';
import { ApiStatus } from 'store/types';
import { cloneDeep } from 'lodash';
import { createLabelingTask, fetchLabelingTasks, updateAbstractTask } from 'store/labelingTask/asyncThunks';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import CopyableValue from 'components/CopyableValue';
import { enumCompareFnFactory } from 'utils';
import TaskTypeTag, { ExtendedTaskType } from 'components/TaskTypeTag';
import MultiTags from 'components/MultiTags';
import ItemPicker from 'components/ItemPicker';
import UserTag from 'components/UserTag';
import { MoreOutlined } from '@ant-design/icons';
import { Button, UnstyledButton, Text } from '@mantine/core';
import { createConfirm } from 'components/ConfirmModal';
import moment from 'moment';
import { RegressionArtifactInputs } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { PageContainer } from 'components/PageContainer';
import TaskConfigurationDrawer from 'pages/LabelingTasks/TaskConfigurationDrawer';
import styles from './styles.module.scss';
import NewTaskModal from './NewTaskModal';

enum ModalType {
  NEW_TASK = 'NEW_TASK',
}

export interface NewTaskData {
  conceptType?: ConceptType;
  // Intent prediction QA
  taskType: UserLabelingTaskType;
  conceptIds: string[];
  maxPredictionCount: number;
  dateRangeSelector: DateRangeSelector;
  // Policy QA
  maxConversationCount: number;
  regressionArtifactInputs: RegressionArtifactInputs;
  conversations: string[];
  qaSource: QaDataSource;
  agentModelUri: string;
  visitorModelUri: string;
  chatDriverModelUri: string;
  // Suggestions, Summarization QA
  modelUri: string;
  modelUri2: string;
  modelUris: string[];
  preselectedCsvUri: string;
  conversationCount: number;
  minConversationLength: number;
  rubricTagSelections: SummarizationInstructionRubricTagSelection[];
  excludeUserIds: string[];
  // Misclassification Augmentation
  qaTaskId: string;
}

function newTaskRequest(customerProfile: string, data: NewTaskData, userId: string, customer: CustomerParams): CreateTaskRequest {
  const {
    taskType,
    conceptIds,
    maxPredictionCount,
    dateRangeSelector,
    qaSource,
    conversations,
    conversationCount,
    maxConversationCount,
    regressionArtifactInputs,
    agentModelUri,
    visitorModelUri,
    chatDriverModelUri,
    modelUri,
    modelUri2,
    modelUris,
    preselectedCsvUri,
    minConversationLength,
    rubricTagSelections,
    excludeUserIds,
    qaTaskId,
  }: NewTaskData = data;

  let selectionInstruction: SelectionInstruction = null;
  let decisionType = DecisionType.DECISION_TYPE_SINGLE_BINARY;
  let viewType = LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_NO_CONTEXT;
  let conceptType = ConceptType.INTENT;

  switch (taskType) {
    case UserLabelingTaskType.QA_INTENT_PREDICTION: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.INTENT_PREDICTION_QA_RANDOM,
        intentPredictionRandom: {
          dateRangeSelector,
          agentModelUri,
          visitorModelUri,
          chatDriverModelUri,
          maxPredictionCount,
          excludeUserIds,
          minConversationLength,
        },
      };
      break;
    }
    case UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.INTENT_PREDICTION_RECALL_QA,
        intentPredictionRandom: {
          dateRangeSelector,
          agentModelUri,
          visitorModelUri,
          chatDriverModelUri,
          maxPredictionCount,
        },
      };
      break;
    }
    case UserLabelingTaskType.QA_POLICY: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.POLICY_QA,
        policyQaInstruction: {
          qaSource,
          backtestInput: {
            ...regressionArtifactInputs,
            validateOnly: false,
          },
        },
      };
      // Specific sampling type was selected
      if (conversations?.length) {
        selectionInstruction.policyQaInstruction = {
          ...selectionInstruction.policyQaInstruction,
          conversations: conversations.map((id) => `${customerProfile}/conversations/${id}`),

        };
      } else {
        selectionInstruction.policyQaInstruction = {
          ...selectionInstruction.policyQaInstruction,
          maxConversationCount,
          dateRangeSelector,
        };
      }
      break;
    }
    case UserLabelingTaskType.QA_SUGGESTIONS: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.SUGGESTION_QA_RANDOM,
        suggestionQaRandom: {
          maxConversationCount,
          dateRangeSelector,
          modelUri,
          modelUri2,
          // Use suggestions v2 for every new task
          isSuggestionQaV2: true,
        },
      };
      decisionType = DecisionType.DECISION_TYPE_MULTIPLE_CHOICE;
      viewType = LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_PARTIAL_CONVERSATION_CONTEXT;
      break;
    }
    case UserLabelingTaskType.QA_SUMMARIZATION: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.SUMMARIZATION_SAMPLING,
        summarizationInstruction: {
          dateRangeSelector,
          modelUris,
          preselectedCsvUri,
          minConversationLength,
          conversationCount,
          rubricTagSelections,
        },
      };
      decisionType = DecisionType.DECISION_TYPE_WRITE_RUBRIC;
      viewType = LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_FULL_CONVERSATION_CONTEXT;
      conceptType = ConceptType.SUMMARIZATION;
      break;
    }
    case UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.INTENT_MISCLASSIFICATION_QA,
        intentPredictionRandom: {
          dateRangeSelector,
          agentModelUri,
          visitorModelUri,
          chatDriverModelUri,
          maxPredictionCount,
          excludeUserIds,
          minConversationLength,
        },
      };
      break;
    }
    case UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW: {
      selectionInstruction = {
        selectionMethod: SelectionMethod.INTENT_MISCLASSIFICATION_AUGMENTATION,
        intentMisclassificationAugmentationInstruction: {
          qaTaskId,
          augmentationMethod: AugmentationMethod.SYNTHESIS,
        },
      };
      break;
    }
    default:
      break;
  }

  const taskId = uuid();
  return {
    name: `${customerProfile}/labelingTasks/${taskId}`,
    commonInput: {
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
      workflowId: 'default-labeling-workflow',
      title: `Labeling Task ${taskId}`,
      assigneeUserId: userId,
      creator: {
        creatorType: CreatorCreatorType.UI_ACTION,
        userId,
      },
    },
    taskDescriptor: {
      // A concept is required. This is to bypass BE validation to make Policy, Suggestions QA work
      conceptIds: [
        UserLabelingTaskType.QA_POLICY,
        UserLabelingTaskType.QA_SUGGESTIONS,
        UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW,
      ].includes(taskType) ? ['concept_placeholder'] : conceptIds,
      targetType: TargetType.QA,
      conceptType,
      viewType,
      decisionType,
      selectionInstruction,
      useV2MsgAndConvId: true,
    },
  };
}

export default function QATasks() {
  const customer = useCustomerParams();
  const navigate = useNavigate();
  const currentUser = useContext(UserContext);
  const currentUserId = currentUser?.id;
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [drawerModal, setDrawerModal] = useState<DerivedLabelingTask | undefined>();
  const [taskData, setTaskData] = useState<NewTaskData>();
  const dispatch = useDispatch();
  const intentConcepts = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT));
  const allConcepts = useSelector(selectAllConcepts);
  const conceptIdsMap = new Map<string, Concept>(allConcepts.map((concept) => [getId('concept', concept.name), concept]));

  const customerProfile = useCustomerProfile();
  // TODO(STU-2963): Push this selector down to the user picker.
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const stringifiedValues = window.sessionStorage.getItem('taskQaFilters');
  const filterFromSessionStorage: {
    taskTypes: UserLabelingTaskType[],
    intent: string,
    assigneeUserId: string,
    status: TaskStatus[],
    showArchivedTasks: string,
    taskIds: string[],
  } = stringifiedValues ? JSON.parse(stringifiedValues) : {};

  // Filter params.
  const [taskTypes, setTaskTypes] = useUrlParam<UserLabelingTaskType[]>('taskType', filterFromSessionStorage.taskTypes);
  const [intent, setIntent] = useUrlParam<string>('intent', filterFromSessionStorage.intent);
  const [assigneeUserId, setAssigneeUserId] = useUrlParam<string>('assignee', filterFromSessionStorage.assigneeUserId);
  const [status, setStatus] = useUrlParam<TaskStatus[]>('status', filterFromSessionStorage.status);
  const [showArchivedTasks, setShowArchivedTasks] = useUrlParam<string>('archived', filterFromSessionStorage.showArchivedTasks);
  const [taskIds, setTaskIds] = useUrlParam<string[]>('taskIds', filterFromSessionStorage.taskIds);

  // Utilize session storage to preserve filter state between renders
  useEffect(() => {
    const filters = {
      taskTypes,
      intent,
      assigneeUserId,
      status,
      showArchivedTasks,
      taskIds,
    };
    window.sessionStorage.setItem('taskQaFilters', JSON.stringify(filters));
  }, [taskTypes, intent, assigneeUserId, status, showArchivedTasks, taskIds]);

  const derivedQaTasks = useSelector<DerivedLabelingTask[]>(selectDerivedQaTasks);

  const filteredDerivedQATasks = useMemo(() => derivedQaTasks.filter((task: DerivedLabelingTask) => {
    const { labelingTask: { taskData: { taskDescriptor }, abstractTask: { assigneeUserId: taskAssigneeUserId, status: taskStatus, visibility } } } = task;
    if (taskTypes?.length > 0 && !taskTypes?.includes(task.userLabelingTaskType)) {
      return false;
    }
    if (
      intent != null
      && !taskDescriptor.conceptIds.includes(intent)
    ) {
      return false;
    }
    if (assigneeUserId && taskAssigneeUserId !== assigneeUserId) {
      if (assigneeUserId === 'Unassigned') {
        return !taskAssigneeUserId;
      }
      return false;
    }
    if (status?.length && !status.includes(taskStatus)) {
      return false;
    }

    if (taskIds?.length > 0) {
      const taskId = getId('labelingTask', task.labelingTask.name);
      if (!taskIds.includes(taskId)) return false;
    }

    if (showArchivedTasks === 'true') {
      return true;
    }
    return visibility !== TaskVisibility.INVISIBLE;
  }), [derivedQaTasks, taskTypes]);
  const intentAllQATasks = useMemo(
    () => filteredDerivedQATasks.filter((t) => t.userLabelingTaskType === UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL),
    [filteredDerivedQATasks],
  );

  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const sortedTasks: DerivedLabelingTask[] = cloneDeep(filteredDerivedQATasks);
  sortedTasks.sort((a, b) => Date.parse(b.labelingTask.abstractTask.createTime) - Date.parse(a.labelingTask.abstractTask.createTime));

  const taskTypeValues: UserLabelingTaskType[] = [
    UserLabelingTaskType.QA_INTENT_PREDICTION,
    UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
    UserLabelingTaskType.QA_POLICY,
    UserLabelingTaskType.QA_SUGGESTIONS,
    UserLabelingTaskType.QA_SUMMARIZATION,
  ];
  const taskStatusValues: TaskStatus[] = Object.values(TaskStatus);

  useEffect(() => {
    dispatch(fetchLabelingTasks({
      parent: customerProfile,
      usecaseId: customer.usecaseId,
      languageCode: customer.languageCode,
    }));
  }, []);

  const updateAssignee = useCallback(
    (abstractTask: AbstractTask) => dispatch(updateAbstractTask({ abstractTask, updateMask: 'abstractTask.assigneeUserId' })),
    [dispatch],
  );

  const updateTaskVisibility = useCallback(async (abstractTask: AbstractTask, visibility: TaskVisibility) => {
    if (visibility === TaskVisibility.INVISIBLE) {
      const confirmArchive = await createConfirm<boolean>({
        title: 'Are you sure you want to archive this task?',
        content: '',
        buttons: [
          {
            text: 'Yes',
            value: true,
          },
          {
            text: 'Cancel',
            buttonProps: {
              variant: 'subtle',
            },
            value: false,
          },
        ],
      });

      if (!confirmArchive) return;
    }

    const task: AbstractTask = {
      ...abstractTask,
      visibility,
    };
    dispatch(updateAbstractTask({ abstractTask: task, updateMask: 'abstractTask.visibility' }));
  }, [dispatch]);

  const createNewTask = useCallback((data: NewTaskData) => {
    dispatch(createLabelingTask(newTaskRequest(
      customerProfile,
      data,
      String(currentUser?.id),
      customer,
    )));
    setOpenModal(null);
  }, [dispatch, currentUser?.id]);

  const startTaskHelper = (labelingTask: LabelingTask, taskUrl: string, reassignTask: boolean) => {
    if (reassignTask) {
      updateAssignee({
        ...labelingTask.abstractTask,
        assigneeUserId: currentUserId,
      });
    }

    navigate(taskUrl);
  };

  const renderTaskAction = (derivedTask: DerivedLabelingTask) => {
    if (!derivedTask) return <span />;
    if (derivedTask.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED) {
      const link = `../score-view/${getId('labelingTask', derivedTask.labelingTask.name)}`;
      return (
        <span>
          <Link to={link}>
            See results
          </Link>
        </span>
      );
    }

    const isTaskAssigneeCurrentUser = derivedTask?.labelingTask?.abstractTask.assigneeUserId === currentUserId;
    let taskUrl = `../view/${getId('labelingTask', derivedTask.labelingTask.name)}`;

    let conversationNumber = derivedTask?.currentConversationNumber + 1;

    // Misclassification Review has a separate view located at /misclassification-review
    if ([
      UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW,
    ].includes(derivedTask?.userLabelingTaskType)) {
      const labelingTaskId = getId('labelingTask', derivedTask.labelingTask.name);
      taskUrl = `../misclassification-review/${labelingTaskId}`;
    }

    // Summarization QA needs to read current conversation from task progress
    if ([
      UserLabelingTaskType.QA_SUMMARIZATION,
      UserLabelingTaskType.QA_INTENT_PREDICTION,
      UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
    ].includes(derivedTask?.userLabelingTaskType)) {
      conversationNumber = derivedTask?.taskProgress?.conversationSetProgresses[0]?.labeledSize;
    }
    const cappedConversationNumber = Math.min(conversationNumber, derivedTask?.totalConversationNumber - 1);

    if ([TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(derivedTask.labelingTask.abstractTask.status)) {
      if (cappedConversationNumber > 0) {
        return (
          <span>
            <NavLink
              to={`${taskUrl}/${cappedConversationNumber}`}
            >
              Continue
            </NavLink>
          </span>
        );
      } else {
        return (
          <span>
            <NavLink
              to="#"
              onClick={(event) => {
                event.preventDefault();
                // Reassign on start
                startTaskHelper(derivedTask.labelingTask, taskUrl, !isTaskAssigneeCurrentUser);
              }}
            >
              Start
            </NavLink>
          </span>
        );
      }
    }

    return <span />;
  };

  // Grab task config and open a new task modal with the same config
  const duplicateTaskConfig = (derivedTask: DerivedLabelingTask) => {
    const taskDescriptor = derivedTask?.labelingTask.taskData.taskDescriptor;
    const taskData: NewTaskData = {
      taskType: derivedTask?.userLabelingTaskType,
      conceptType: taskDescriptor.conceptType,
      conceptIds: taskDescriptor.conceptIds || [],
      maxPredictionCount: 0,
      conversationCount: 0,
      minConversationLength: 0,
      maxConversationCount: 0,
      regressionArtifactInputs: {},
      agentModelUri: '',
      visitorModelUri: '',
      chatDriverModelUri: '',
      modelUri: '',
      modelUri2: '',
      modelUris: [],
      preselectedCsvUri: '',
      conversations: [],
      qaSource: QaDataSource.SERVING_MODEL,
      dateRangeSelector: {
        after: null,
        before: null,
      },
      rubricTagSelections: [],
      excludeUserIds: [],
      qaTaskId: '',
    };

    // Get data from one of the selection instruction objects
    Object.keys(taskDescriptor.selectionInstruction).forEach((key) => {
      const instruction = taskDescriptor.selectionInstruction[key];
      if (instruction) {
        taskData.qaSource = instruction.qaSource;
        taskData.dateRangeSelector = instruction.dateRangeSelector;
        taskData.minConversationLength = instruction.minConversationLength || 0;
        taskData.conversationCount = instruction.conversationCount || 0;
        taskData.maxConversationCount = instruction.maxConversationCount || 0;
        taskData.conversations = instruction.conversations || [];
        taskData.maxPredictionCount = instruction.maxPredictionCount || 0;
        taskData.regressionArtifactInputs = instruction.backtestInput || {};
        taskData.agentModelUri = instruction.agentModelUri;
        taskData.visitorModelUri = instruction.visitorModelUri;
        taskData.chatDriverModelUri = instruction.chatDriverModelUri;
        taskData.modelUri = instruction.modelUri;
        taskData.modelUri2 = instruction.modelUri2;
        taskData.modelUris = instruction.modelUris;
        taskData.rubricTagSelections = instruction.rubricTagSelections || [];
        taskData.qaTaskId = instruction.qaTaskId || '';
      }
    });

    setDrawerModal(undefined);
    setTaskData(taskData);
    setOpenModal(ModalType.NEW_TASK);
  };

  const columns: ColumnType<DerivedLabelingTask>[] = [
    {
      title: 'Task ID',
      dataIndex: ['labelingTask', 'name'],
      key: 'name',
      sorter: (a, b) => {
        const valueA = getId('labelingTask', a.labelingTask.name);
        const valueB = getId('labelingTask', b.labelingTask.name);
        return valueA.localeCompare(valueB);
      },
      render: (value: string) => {
        const taskId = getId('labelingTask', value);
        return (
          <CopyableValue
            displayValue={`${taskId.substring(0, 4)}...`}
            copiedValue={taskId}
            tooltip={taskId}
          />
        );
      },
    },
    {
      title: 'QA Type',
      dataIndex: 'userLabelingTaskType',
      key: 'taskType',
      sorter: enumCompareFnFactory<UserLabelingTaskType, DerivedLabelingTask>(taskTypeValues, (t) => t.userLabelingTaskType),
      render: (value, row) => {
        const isSuggestionsCompare = !!row.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.modelUri2;
        const taskTypeTag = <TaskTypeTag taskType={isSuggestionsCompare ? ExtendedTaskType.QA_SUGGESTION_COMPARE : value} />;
        if (
          row.labelingTask.taskData.taskDescriptor.selectionInstruction
            .selectionMethod === SelectionMethod.REGEX
        ) {
          return (
            <Tooltip
              title={
                row.labelingTask.taskData.taskDescriptor.selectionInstruction
                  .regexSelectionInstruction.regexExpression
              }
            >
              <span>
                {taskTypeTag}
              </span>
            </Tooltip>
          );
        }
        return taskTypeTag;
      },
    },
    {
      title: 'Intents',
      dataIndex: ['labelingTask', 'taskData', 'taskDescriptor', 'conceptIds'],
      key: 'conceptIds',
      render: (value: string[]) => (
        <MultiTags tags={value.map((conceptId) => {
          const concept = conceptIdsMap.get(conceptId);
          return concept ? concept.conceptTitle : null;
        })}
        />
      ),
    },
    {
      title: 'Assignee',
      dataIndex: ['labelingTask', 'abstractTask', 'assigneeUserId'],
      key: 'assignee',
      sorter: (a: DerivedLabelingTask, b: DerivedLabelingTask) => {
        const valueA = a.labelingTask.abstractTask.assigneeUserId || '';
        const valueB = b.labelingTask.abstractTask.assigneeUserId || '';
        return valueA.localeCompare(valueB);
      },
      render: (value: string, row) => (
        <ItemPicker
          items={[{
            id: '',
            email: '',
            full_name: 'Unassigned',
            role: '',
          }, ...(users || [])]}
          value={value}
          idKeyPath="id"
          renderItem={(user) => <UserTag name={user?.full_name || user?.email} />}
          filterKey={(user) => user?.full_name || user?.email}
          placeholder="Assign to"
          onChange={
            (user) => updateAssignee({
              ...row.labelingTask.abstractTask,
              assigneeUserId: user.id,
            })
          }
        >
          <UserTag name={users.find((user) => user.id === value)?.full_name || 'Unassigned'} />
        </ItemPicker>
      ),
    },
    {
      title: 'Status',
      dataIndex: ['labelingTask', 'abstractTask', 'status'],
      key: 'status',
      sorter: enumCompareFnFactory<TaskStatus, DerivedLabelingTask>(taskStatusValues, (t) => t.labelingTask.abstractTask.status),
      render: (value: TaskStatus, row: DerivedLabelingTask) => (
        <TaskStatusIndicator
          status={value}
          errorMessage={row.labelingTask.abstractTask.errMessage}
          preparing={row.labelingTask.taskData.taskDescriptor.selectionInstruction.selectionMethod !== SelectionMethod.DUAL && value === TaskStatus.TASK_CREATED}
        />
      ),
    },
    {
      title: '# Done',
      key: 'annotatedTargetCount',
      render: (value, row: DerivedLabelingTask, index) => {
        let labelsString = row.annotatedTargetCount;
        // Use task progress data for Summarization QA
        if (row.userLabelingTaskType === UserLabelingTaskType.QA_SUMMARIZATION) {
          labelsString = row.taskProgress?.conversationSetProgresses[0]?.labeledSize;
        }
        return (
          <span className={row.annotatedTargetCount > 0 ? styles.labelsCount : null}>
            {[TaskStatus.TASK_COMPLETED, TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(row.labelingTask.abstractTask.status) && labelsString}
          </span>
        );
      },
    },
    {
      title: 'Date Completed',
      key: 'endTime',
      sorter: (a: DerivedLabelingTask, b: DerivedLabelingTask) => {
        const valueA = moment(a.labelingTask.abstractTask.endTime);
        const valueB = moment(b.labelingTask.abstractTask.endTime);
        return valueA.diff(valueB);
      },
      render: (_, row: DerivedLabelingTask) => {
        if (row.labelingTask.abstractTask.status !== TaskStatus.TASK_COMPLETED) {
          return <span className={styles.missingData}>N/A</span>;
        }
        if (!row.labelingTask.abstractTask.endTime) {
          return <span className={styles.missingData}>Missing</span>;
        }
        return moment(row.labelingTask.abstractTask.endTime).format('MM/DD/YYYY h:mma');
      },
    },
    {
      title: 'Task Time',
      key: 'taskTime',
      render: (_, row: DerivedLabelingTask) => {
        if ([TaskStatus.TASK_READY, TaskStatus.TASK_CREATED].includes(row.labelingTask.abstractTask.status)) {
          return <span className={styles.missingData}>N/A</span>;
        }
        const minutes = row.labelingTimeMinute % 60;
        const hours = Math.floor(row.labelingTimeMinute / 60);
        return `${(hours ? `${hours}h ` : '')}${minutes}${minutes === 1 ? 'min' : 'mins'}`;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (value, row: DerivedLabelingTask, index) => (
        <div className={styles.taskActions}>
          {renderTaskAction(row)}
        </div>
      ),
    },
    {
      title: '',
      key: 'archive',
      render: (_, row: DerivedLabelingTask) => {
        const isArchived = row?.labelingTask?.abstractTask.visibility === TaskVisibility.INVISIBLE;
        return (
          <Dropdown
            menu={{
              items: [
                {
                  label: <UnstyledButton onClick={() => updateTaskVisibility(row.labelingTask.abstractTask, isArchived ? TaskVisibility.VISIBLE : TaskVisibility.INVISIBLE)} >Archive</UnstyledButton>,
                  key: '0',
                },
                {
                  label: <UnstyledButton onClick={() => setDrawerModal(row)}>See config</UnstyledButton>,
                  key: '1',
                },
              ],
            }}
          >
            <span style={{ cursor: 'pointer' }}>
              <MoreOutlined />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  const filteredIntentConcepts = (intentConcepts || []).filter((concept) => concept.intent?.state !== IntentState.DEPRECATED);

  return (
    <PageContainer>
      <div className={styles.header}>
        <div>
          <h1>QA Tasks</h1>
          <div className={styles.showArchived}>
            <Switch
              defaultChecked={showArchivedTasks === 'true'}
              size="small"
              onChange={() =>
                setShowArchivedTasks(showArchivedTasks === 'true' ? 'false' : 'true')}
            />
            <label className={styles.showArchivedLabel}>Show archived</label>
          </div>
        </div>
        <Button
          color="#304ffe"
          onClick={() => setOpenModal(ModalType.NEW_TASK)}
        >
          Add new
        </Button>
      </div>
      <div className={styles.filters}>
        <Text size="md">{`${filteredDerivedQATasks?.length} of ${derivedQaTasks?.length} tasks`}</Text>
        <Select
          mode="multiple"
          showArrow
          className={styles.select}
          size="large"
          placeholder="Task type"
          allowClear
          value={taskTypes}
          tagRender={(props) => (
            <div style={{ paddingLeft: '5px' }}>
              <TaskTypeTag taskType={props.value as UserLabelingTaskType} />
            </div>
          )}
          onChange={(value) =>
            setTaskTypes(value || [])}
        >
          {taskTypeValues.map((taskType) => (
            <Select.Option key={taskType} value={taskType}>
              <TaskTypeTag taskType={taskType} />
            </Select.Option>
          ))}
        </Select>
        <Select
          showArrow
          showSearch
          className={styles.select}
          size="large"
          placeholder="Intent"
          allowClear
          value={intent}
          filterOption={(input, option) => option.props.searchvalue.indexOf(input) !== -1}
          onChange={(value) =>
            setIntent(value)}
        >
          {filteredIntentConcepts.map((concept) => (
            <Select.Option key={concept.conceptTitle} value={getId('concept', concept.name)} searchvalue={concept.conceptTitle}>
              {concept.conceptTitle}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          showArrow
          className={styles.select}
          size="large"
          placeholder="Assignee"
          allowClear
          value={assigneeUserId}
          filterOption={(input, option) =>
            option.props.children?.props?.name
              ?.toLowerCase()
              .includes(input.toLowerCase())}
          onChange={(value) =>
            setAssigneeUserId(value)}
        >
          {[{
            // Note that for filters we use id: Unassigned
            // because we need a value in the url param
            id: 'Unassigned',
            email: '',
            full_name: 'Unassigned',
          }, ...(users || [])].map((user) => (
            <Select.Option key={user.id} value={user.id}>
              <UserTag name={user.full_name || user.email} />
            </Select.Option>
          ))}
        </Select>

        <Select
          mode="multiple"
          showArrow
          className={styles.select}
          size="large"
          placeholder="Status"
          allowClear
          value={status}
          onChange={(value) =>
            setStatus(value)}
        >
          <Select.Option value={TaskStatus.TASK_READY}><TaskStatusIndicator status={TaskStatus.TASK_READY} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_IN_PROGRESS}><TaskStatusIndicator status={TaskStatus.TASK_IN_PROGRESS} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_CREATED}><TaskStatusIndicator status={TaskStatus.TASK_CREATED} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_COMPLETED}><TaskStatusIndicator status={TaskStatus.TASK_COMPLETED} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_ERROR}><TaskStatusIndicator status={TaskStatus.TASK_ERROR} /></Select.Option>
        </Select>

        <Select
          mode="multiple"
          showArrow
          className={styles.select}
          size="large"
          placeholder="Tasks"
          allowClear
          value={taskIds}
          maxTagCount={1}
          onChange={(value) =>
            setTaskIds(value)}
        >
          {derivedQaTasks.map((task) => {
            const taskId = getId('labelingTask', task.labelingTask.name);
            return (
              <Select.Option key={taskId} value={taskId}>{taskId}</Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={sortedTasks}
          rowKey={(record: DerivedLabelingTask) => record.labelingTask?.name}
          pagination={false}
          rowClassName={(record: DerivedLabelingTask) => {
            if (record.labelingTask?.abstractTask.visibility === TaskVisibility.INVISIBLE) return 'greyed-row';
            return null;
          }}
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
        />
      </div>
      <TaskConfigurationDrawer
        isOpen={drawerModal !== undefined}
        onClose={() => setDrawerModal(undefined)}
        derivedTask={drawerModal}
        duplicateTaskConfig={duplicateTaskConfig}
      />
      <NewTaskModal
        taskData={taskData}
        setTaskData={setTaskData}
        submitting={taskApiStatus === 'loading'}
        isOpen={openModal === ModalType.NEW_TASK}
        concepts={allConcepts}
        users={users}
        qaTasks={intentAllQATasks}
        onClose={() => setOpenModal(null)}
        handleOk={createNewTask}
      />
    </PageContainer>
  );
}
