import { createStyles, useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface SuggestionBoxProps {
  text: string;
  active?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  type?: 'single-model' | 'model-1' | 'model-2';
}

export function SuggestionBox({
  text,
  active,
  onClick,
  type = 'single-model',
}: SuggestionBoxProps) {
  const theme = useMantineTheme();
  const useStyles = createStyles(() => ({
    suggestion: {
      backgroundColor: theme.colors.indigo[0],
      fontSize: theme.fontSizes.sm,
      padding: theme.spacing.xs,
      borderRadius: theme.radius.md,
      width: '100%',
      '& + &': {
        marginTop: theme.spacing.sm,
      },
    },
    suggestionModel1: {
      backgroundColor: theme.colors.orange[0],
    },
    suggestionModel2: {
      backgroundColor: theme.colors.purple[0],
    },
    selectedSuggestion: {
      border: `1px solid ${theme.colors.blue[5]}`,
    },
    selectableSuggestion: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.indigo[1],
      },
    },
  }));
  const styles = useStyles();

  return (
    <div
      onClick={onClick}
      className={classNames([
        'suggestion-box',
        styles.classes.suggestion,
        {
          [styles.classes.suggestionModel1]: type === 'model-1',
          [styles.classes.suggestionModel2]: type === 'model-2',
          [styles.classes.selectedSuggestion]: active,
          [styles.classes.selectableSuggestion]: onClick,
        },
      ])}
    >
      {ReactHtmlParser(text)}
    </div>
  );
}
