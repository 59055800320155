import { useForm } from '@mantine/form';

export interface EnsembleConfigFormValues {
    generativeModelPath: string;
    retrievalModelPath: string;
    // form UI state
    isShowingAdvanced: boolean;
    // Advanced settings. TODO: add back when backend supports it.
    // cannedMsgTriePath: string;
  }

export const useEnsembleConfigForm = () => {
  const form = useForm<EnsembleConfigFormValues>({
    initialValues: {
      generativeModelPath: '',
      retrievalModelPath: '',
      // form UI state
      isShowingAdvanced: false,
      // Advanced settings
      // cannedMsgTriePath: '',
    },
  });

  return form;
};
