import { BinaryValueValue, SuggestionQaValue, SuggestionQaValueCompareModelValue, SuggestionQaValueSingleModelValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Button, ButtonProps } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CopyableValue from 'components/CopyableValue';
import Loading from 'components/Loading';
import { useSelector } from 'hooks/reduxHooks';
import React from 'react';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import styles from '../styles.module.scss';

interface UtteranceSuggestionsTableProps {
  searchTerm: string;
  taskId: string;
}

function getResultButtonProps(value: SuggestionQaValue): [ButtonProps['color'], string] {
  if (value.compareModelValue) {
    switch (value.compareModelValue) {
      case SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH:
        return ['green', 'Both'];
      case SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER:
        return ['red', 'Neither'];
      default:
        return ['gray', 'Unspecified'];
    }
  } else if (value.singleModelValue) {
    switch (value.singleModelValue) {
      case SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_BAD:
        return ['red', 'Bad'];
      case SuggestionQaValueSingleModelValue.SINGLE_MODEL_VALUE_GOOD:
        return ['green', 'Good'];
      default:
        return ['gray', 'Unspecified'];
    }
  }
  return ['gray', 'Unspecified'];
}

export default function UtteranceSuggestionsTable({ searchTerm, taskId }: UtteranceSuggestionsTableProps) {
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const perMessageColumns: ColumnsType<SearchAnnotationsResponseAnnotationBundle> = [
    {
      title: 'Chat ID',
      width: 120,
      dataIndex: 'chatId',
      key: 'chatId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2ConversationId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Message',
      dataIndex: ['textMessage'],
      key: 'message',
    },
    {
      title: 'Result',
      dataIndex: ['annotation', 'value', 'suggestionQaValue'],
      key: 'prediction',
      render: (value: SuggestionQaValue, record) => {
        const [color, text] = getResultButtonProps(value);
        return (
          <div>
            <Button
              variant="subtle"
              color={color}
            >
              {text}
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: ['annotation', 'value', 'binaryValue', 'note'],
      width: '20%',
      key: 'comment',
      render: (value, record) => {
        if (record.annotation.value.binaryValue.value !== BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
          return <span className={styles.missingData}>N/A</span>;
        }

        return value;
      },
    },
  ];

  return (
    <Table
      rowKey={(row) => row.annotation.name}
      loading={{
        spinning: taskApiStatus === 'loading',
        indicator: <Loading />,
      }}
      className={styles.summaryTable}
      columns={perMessageColumns}
      dataSource={annotationBundles}
    />
  );
}
