import { AppstoreAddOutlined, MessageFilled, RedoOutlined } from '@ant-design/icons';
import { Box, Col, Flex, Grid, Paper, Title, Switch, Text, Button, Stack, Group, Select, Divider, useMantineTheme } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import React, { useMemo, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import useUrlParam from 'hooks/useUrlParam';
import Loading from 'components/Loading';
import { CrestaGptApi } from 'services/crestaGptApi';
import { usePrompts } from 'pages/AutoAnalysis/Prompts/hooks/usePrompts';
import VATestCase from './VATestCase';
import VAChatCard from './VAChatCard';
import VAChat from './VAChat';
import { fetchChatGPT } from './utils';

export interface PromptRegistry {
  [key: string]: string;
}

export interface SimulatorMessage {
  id?: string;
  sid?: string;
  text: string;
  created_at: Date;
  speaker_role: string;
}

export interface SimulatorChat {
  id: string;
  messages: SimulatorMessage[];
  prompt: string;
  date: Date;
}

export function VASimulator() {
  const theme = useMantineTheme();
  const [isVARepliesVisible, setIsVARepliesVisible] = useState(true);
  const [chats, setChats] = useState<SimulatorChat[]>([]);
  const [message, setMessage] = useState<string>('');
  const [promptId, setPromptId] = useUrlParam('promptId', '');
  const [chatId, setChatId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const isGenerating = useRef(false);

  const {
    listPrompts,
  } = usePrompts();

  const [testCases] = useState([
    {
      title: 'Test Case 1',
      onRun: () => {},
      onDelete: () => {},
      status: 'idle',
    },
    {
      title: 'Return policy',
      onRun: () => {},
      onDelete: () => {},
      status: 'idle',
    },
  ]);

  const selectedChat = useMemo(() => chats.find((chat) => chat.id === chatId), [chats, chatId]);

  const promptRegistry: PromptRegistry = useMemo(() => {
    const registry: PromptRegistry = {};
    listPrompts.forEach((prompt) => {
      registry[prompt.displayName] = prompt.promptText;
    });
    return registry;
  }, [listPrompts]);

  const updateChat = (updatedChat: SimulatorChat) => {
    const clonedChats = cloneDeep(chats);
    setChats((prevChats) => {
      const index = prevChats.findIndex((chat) => chat.id === updatedChat.id);
      clonedChats[index] = updatedChat;
      return clonedChats;
    });
  };

  const sendMessage = async (message: SimulatorMessage) => CrestaGptApi.sendMessage(message, promptId, chatId);

  const handleCreateChat = async () => {
    try {
      setLoading(true);
      const chatId = await CrestaGptApi.createChat(promptId);
      const chat: SimulatorChat = {
        id: chatId,
        date: new Date(),
        messages: [],
        prompt: promptId,
      };
      const clonedChats = cloneDeep(chats);
      clonedChats.unshift(chat);
      setChats(clonedChats);
      setChatId(chatId);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async (content: string) => {
    // Return if it's currently streaming results.
    if (isGenerating.current) {
      return;
    }

    const clonedSelectedChat = cloneDeep(selectedChat);
    const simulatorMessages = selectedChat?.messages || [];
    try {
      const createdAt = new Date();
      const sid = `${simulatorMessages.length}`;
      const newMessage: SimulatorMessage = {
        sid,
        text: content,
        speaker_role: 'visitor',
        created_at: createdAt,
      };
      const nextMessages = [...simulatorMessages, newMessage];
      clonedSelectedChat.messages = nextMessages;
      updateChat(clonedSelectedChat);

      const messageId = await sendMessage(newMessage);
      // Update the message with the message id
      const updatedMessages = nextMessages.map((message) => {
        if (message.sid === sid) {
          return {
            ...message,
            id: messageId,
          };
        }
        return message;
      });
      clonedSelectedChat.messages = updatedMessages;
      updateChat(clonedSelectedChat);
      setIsBotTyping(true);
      await handleGenerateReply(nextMessages);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleGenerateReply = async (simulatorMessages: SimulatorMessage[]) => {
    // Return if it's currently streaming results.
    if (isGenerating.current) {
      return;
    }
    isGenerating.current = true;
    const lastMessages = cloneDeep(simulatorMessages);
    let completion: string = '';
    const createdAt = new Date();

    const result = await fetchChatGPT(
      promptId,
      chatId,
      promptRegistry[promptId] || '',
      simulatorMessages,
      async (token: string) => {
        completion += token;
        const nextMessages = [
          ...lastMessages,
          {
            sid: `${lastMessages.length}`,
            speaker_role: 'agent',
            text: completion,
            created_at: createdAt,
          },
        ];
        const updatedChat = cloneDeep(selectedChat);
        updatedChat.messages = nextMessages;
        updateChat(updatedChat);
      },
      async () => {
        setIsBotTyping(false);
      },
    );
    if (!result) {
      isGenerating.current = false;
      return;
    }

    const finalMessage: SimulatorMessage = {
      sid: `${simulatorMessages.length}`,
      speaker_role: 'agent',
      text: result,
      created_at: createdAt,
    };
    const messageId = await sendMessage(finalMessage);
    finalMessage.id = messageId;
    const nextMessages = [
      ...lastMessages,
      finalMessage,
    ];
    const updatedChat = cloneDeep(selectedChat);
    updatedChat.messages = nextMessages;
    updateChat(updatedChat);
    isGenerating.current = false;
  };

  const handleMessageEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (!isGenerating.current && !isBotTyping) {
        handleSendMessage(message);
        setMessage('');
      }
    }
  };

  const handleChatReset = () => {
    updateChat({
      id: chatId,
      date: new Date(),
      messages: [],
      prompt: promptId,
    });
  };

  const chatsSorted = useMemo(() => chats.sort((a, b) => b.date.getTime() - a.date.getTime()), [chats]);

  return (
    <PageContainer>
      <Group pb="sm" position="apart" align="end" noWrap>
        <Title>VA Simulator</Title>
      </Group>
      <Grid
        columns={16}
        style={{ overflow: 'hidden' }}
      >
        <Col span={3}>
          <Flex
            direction="column"
            justify="stretch"
            h="100%"
          >

            <Paper
              style={{ flex: 1 }}
            >
              <Stack p="md">
                <Select
                  label="Prompt"
                  placeholder="Prompt name"
                  value={promptId}
                  w="100%"
                  maw="400px"
                  onChange={(value) => setPromptId(value)}
                  data={Object.keys(promptRegistry).map((key) => ({
                    label: key,
                    value: key,
                  }))}
                />
                <Button
                  color="blue"
                  onClick={handleCreateChat}
                  disabled={!promptId}
                  variant="light"
                >
                  <MessageFilled/>
                  <Text ml="md">New Conversation</Text>
                </Button>
              </Stack>
              <Divider color="gray.1"/>
              {chatsSorted.map((chat) => (
                <VAChatCard
                  key={chat.id}
                  messages={chat.messages}
                  date={chat.date}
                  onClick={() => setChatId(chat.id)}
                  active={chatId === chat.id}
                  promptName={chat.prompt}
                />
              ))}
            </Paper>
          </Flex>
        </Col>
        <Col span="auto">
          <Paper
            h="calc(100vh - 200px)"
            style={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            {loading ? (
              <Flex w="100%" h="100%" align="center" justify="center">
                <Box p="lg">
                  <Loading/>
                </Box>
              </Flex>
            ) : (
              <>
                {!promptId && (
                  <Flex
                    h="100%"
                    p="xl"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    <Title mt="lg" order={3} maw="300px" align="center" color="dimmed">Select a prompt and start the conversation</Title>
                  </Flex>
                )}
                {selectedChat ? (
                  <VAChat
                    messages={selectedChat.messages}
                    promptId={selectedChat.prompt}
                    message={message}
                    setMessage={setMessage}
                    handleMessageEnter={handleMessageEnter}
                    isBotTyping={isBotTyping}
                    isVARepliesVisible={isVARepliesVisible}
                  />
                ) : (
                  <Flex
                    h="100%"
                    p="xl"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    <MessageFilled style={{ fontSize: '64px', color: theme.colors.blue[1] }}/>
                    <Title mt="lg" order={3} maw="300px" align="center" color="dimmed">Create a new conversation to start chatting</Title>
                  </Flex>
                )}
              </>
            )}
          </Paper>
        </Col>
        <Col span={3}>
          <Paper style={{
            height: '100%',
          }}
          >
            <Box p="lg">
              <Title order={3}>Conversation</Title>
              <Switch
                my="lg"
                label="Show VA replies"
                checked={isVARepliesVisible}
                onChange={() => setIsVARepliesVisible(!isVARepliesVisible)}
              />
            </Box>
            <Stack px="lg">
              <Button
                variant="light"
                color="blue"
              >
                <AppstoreAddOutlined/>
                <Text ml="xs">Add to test case</Text>
              </Button>
              <Button
                variant="light"
                color="red"
                onClick={handleChatReset}
              >
                <RedoOutlined/>
                <Text ml="xs">Reset</Text>
              </Button>
            </Stack>
            <Box p="lg" mt="lg">
              <Title order={3}>Q&A</Title>
              <Group position="apart" align="end" mt="md">
                <Text size="sm" color="dimmed">Test cases</Text>
                <Button compact size="xs" variant="light">Run all</Button>
              </Group>
            </Box>
            <Box>
              {testCases.map((testCase) => (
                <VATestCase
                  key={testCase.title}
                  title={testCase.title}
                  onRun={testCase.onRun}
                  onDelete={testCase.onDelete}
                  status={testCase.status}
                />
              ))}
            </Box>
          </Paper>
        </Col>
      </Grid>
    </PageContainer>
  );
}
