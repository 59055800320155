import { useContext, useEffect } from 'react';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { UserContext } from 'context/UserContext';
import { showNotification } from '@mantine/notifications';
import { disableMonitoring, enableMonitoring, setMonitoringIdentity } from 'configuration/monitoring';
import { useDispatch } from 'hooks/reduxHooks';
import { CustomerParams, useCustomerParams } from 'hooks/useCustomerParams';
import { getCustomersCurrentUserIds } from 'store/app/asyncThunks';
import { getUniqueConfigs } from 'pages/TasksDashboard';

export const useFullStoryMonitoring = () => {
  const dispatch = useDispatch();
  const { getFirstConfig, currentConfig } = useContext(CustomerConfigContext);
  const user = useContext(UserContext);
  const customerParams = useCustomerParams();

  useEffect(() => {
    if (user) {
      setMonitoringIdentity(user);

      // Fetch user ids for each namespace selected in task playlist
      const stringifiedValues = localStorage.getItem('taskPlaylistCustomers');
      const taskPlaylistCustomers: CustomerParams[] = stringifiedValues ? JSON.parse(stringifiedValues) : [];
      if (!taskPlaylistCustomers.length) {
        taskPlaylistCustomers.push(customerParams);
      }
      const uniqueConfigs = getUniqueConfigs(taskPlaylistCustomers, getFirstConfig);
      if (uniqueConfigs.length) {
        dispatch(getCustomersCurrentUserIds({
          customerConfigs: uniqueConfigs,
          email: user.email,
        }));
      }
    }
  }, [user]);

  useEffect(() => {
    // Enable monitoring if customer doesn't require HIPAA compliance
    if (user && !currentConfig.isHipaaRequired) {
      // FullStory: flag for enabling monitoring by default
      const IS_ENABLED_BY_DEFAULT = false;
      if (IS_ENABLED_BY_DEFAULT) {
        enableMonitoring(user);
      }
      // ctrl shift f enables monitoring
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'f' && e.ctrlKey) {
          enableMonitoring(user);
          showNotification({
            message: 'FullStory Monitoring initialized',
          });
        }
        // ctrl shift f disables monitoring
        if (e.key === 'F' && e.ctrlKey && e.shiftKey) {
          disableMonitoring();
          showNotification({
            message: 'FullStory Monitoring disabled',
          });
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    } else {
      disableMonitoring();
      return () => null;
    }
  }, [currentConfig]);
};
