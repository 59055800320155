import React from 'react';
import { Group, Skeleton, Stack, Text } from '@mantine/core';
import { Stepper } from './Stepper';
import { StepperMenu } from './StepperMenu';
import { SetType } from '../TaggingPage';
import { getActiveSet } from '../utils/getActiveSet';

const getStepperValues = (activeConversationIndex: number, activeSet: SetType): {indexInSet:number, conversationsInSet: number} => {
  const indexInSet = activeConversationIndex - activeSet.conversationIndexInTask;
  const conversationsInSet = activeSet.conversationCount;
  return { indexInSet, conversationsInSet };
};

interface FooterWidgetProps {
  setData: SetType[];
  handleSwitchSet: (setId: string) => void;
  activeConversationIndex: number;
}

export const FooterWidget = ({ activeConversationIndex, setData, handleSwitchSet }: FooterWidgetProps) => {
  const unInitialized = setData.length === 0;
  if (unInitialized) {
    return (
      <div>
        <Group>
          <Skeleton height={20} width={80} radius="sm" />
          <Skeleton height={12} radius={0} width={100} />
        </Group>
        <Skeleton height={8} mt={6} width={200} radius="xl" />
      </div>
    );
  }
  const activeSet = getActiveSet(activeConversationIndex, setData);
  const { indexInSet, conversationsInSet } = getStepperValues(activeConversationIndex, activeSet);
  return (
    <Stack style={{ display: 'inline-block', minWidth: 250 }}>
      <Group mb="xs" position="apart">
        <StepperMenu value={activeSet.id} data={setData.map((set) => ({ value: set.id, label: set?.title || '' }))} onItemClick={handleSwitchSet} />
        <Text size="sm">{`${indexInSet + 1}/${conversationsInSet} convos`}</Text>
      </Group>
      <Stepper
        max={conversationsInSet}
        current={indexInSet + 1}
      />
    </Stack>
  );
};
