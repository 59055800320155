/* eslint dot-notation: 0 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ModelArtifact,
  ModelArtifactStatus,
  RetrieveModelScoresRequest,
  RetrieveModelScoresResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { modelArtifactApi } from 'services/modelArtifactApi';
import { openNotification } from 'components/Notification';
import { StatusCodes } from 'http-status-codes';
import { ApiGet } from 'hooks/network';
import { Model, ProdIntent, State } from './state';

enum Action {
  MODEL_BUILDER_GET_SERVING_MODEL_DETAILS = 'MODEL_BUILDER_GET_SERVING_MODEL_DETAILS',
  MODEL_BUILDER_GET_SERVING_MODEL_DETAILS_FROM_AI_SERVICES = 'MODEL_BUILDER_GET_SERVING_MODEL_DETAILS_FROM_AI_SERVICES',
  MODEL_BUILDER_GET_MODEL_ARTIFACT = 'MODEL_BUILDER_GET_MODEL_ARTIFACT',
  MODEL_BUILDER_GET_PROD_TAXONOMIES = 'MODEL_BUILDER_GET_PROD_TAXONOMIES',
}

interface UrlStack {
  url: string;
  dependency_urls: any[];
}

export interface ModelSummary {
  dialog_policy_url?: UrlStack;
  chat_driver_model_dependency_urls?: UrlStack;
  agent_intent_model_dependency_urls?: UrlStack;
  visitor_intent_model_dependency_urls?: UrlStack;
  next_intent_model_url?: string;
}

/** Input params for getServingModelDetails. */
export interface ModelDetailsParams {
  customerProfile?: string;
  getter: () => Promise<{ httpStatus: StatusCodes; result: any }>;
}

/** AsyncThunk for getting serving model details and model scores. */
export const getServingModelDetails = createAsyncThunk<Model[], ModelDetailsParams>(
  Action.MODEL_BUILDER_GET_SERVING_MODEL_DETAILS,
  async (params: ModelDetailsParams) => {
    let modelSummary: ModelSummary = {};
    try {
      const response = await params.getter();
      if (response.httpStatus === StatusCodes.OK) {
        modelSummary = response.result;
      } else {
        throw new Error(JSON.stringify(response));
      }
    } catch (err) {
      openNotification('error', 'Failed to get model summary', undefined, err);
      throw err;
    }
    const models = toModels(modelSummary);
    let modelScores = new Map();
    if (params.customerProfile) {
      const request: RetrieveModelScoresRequest = {
        modelProject: `${params.customerProfile}/modelProjects/-`,
        modelUris: models.map((model) => model.url),
        latestPerModelUri: true,
      };
      let response: RetrieveModelScoresResponse = {};
      try {
        response = await modelArtifactApi.retrieveModelScores(request);
      } catch (err) {
        if (err['status'] !== 'NOT_FOUND') {
          openNotification('error', 'Failed to get model scores', undefined, err);
        } else {
          openNotification('info', 'Model scores not found.', 'No evaluations for the models.');
        }
      }
      modelScores = new Map(response.modelArtifacts?.map((artifact) => [
        artifact.evaluationArtifact?.inputs?.modelUri,
        artifact,
      ]));
    }
    return models.map((model) => {
      // eslint-disable-next-line no-param-reassign
      model.modelMetrics = modelScores.get(model.url)?.evaluationArtifact?.outputs?.modelScores?.test['weighted avg'];
      // eslint-disable-next-line no-param-reassign
      model.evaluationArtifact = modelScores.get(model.url)?.name;
      return model;
    });
  },
);

/** AsyncThunk for getting serving model details from AI services. */
export const getServingModelDetailsFromAIServices = createAsyncThunk<Model[], ModelDetailsParams>(
  Action.MODEL_BUILDER_GET_SERVING_MODEL_DETAILS_FROM_AI_SERVICES,
  async (params: ModelDetailsParams) => {
    let modelSummary: ModelSummary = {};
    try {
      const response = await params.getter();
      if (response.httpStatus === StatusCodes.OK) {
        modelSummary = response.result;
      } else {
        throw new Error(JSON.stringify(response));
      }
    } catch (err) {
      openNotification('error', 'Failed to get model summary', undefined, err);
      throw err;
    }
    return toModels(modelSummary);
  },
);

function toModels(summary: ModelSummary): Model[] {
  const models: Model[] = [];
  if (summary.dialog_policy_url?.url) {
    models.push({
      displayType: 'Dialog Policy',
      type: 'policy',
      url: summary.dialog_policy_url.url,
      dependencies: summary.dialog_policy_url.dependency_urls,
    });
  }
  if (summary.chat_driver_model_dependency_urls?.url) {
    models.push({
      displayType: 'Chat Driver',
      type: 'driver',
      url: summary.chat_driver_model_dependency_urls.url,
      dependencies: summary.chat_driver_model_dependency_urls.dependency_urls,
    });
  }
  if (summary.agent_intent_model_dependency_urls?.url) {
    models.push({
      displayType: 'Agent Intent',
      type: 'agent',
      url: summary.agent_intent_model_dependency_urls.url,
      dependencies: summary.agent_intent_model_dependency_urls.dependency_urls,
    });
  }
  if (summary.visitor_intent_model_dependency_urls?.url) {
    models.push({
      displayType: 'Visitor Intent',
      type: 'visitor',
      url: summary.visitor_intent_model_dependency_urls.url,
      dependencies: summary.visitor_intent_model_dependency_urls.dependency_urls,
    });
  }
  if (summary.next_intent_model_url) {
    models.push({
      displayType: 'Next Agent Intent Ensemble',
      type: 'ensemble',
      url: summary.next_intent_model_url,
    });
  }
  return models;
}

/** Input params for getModelTaxonomies. */
export interface ModelTaxonomiesParams {
  modelIntentType: Map<string, IntentIntentType>;
  getter: () => Promise<{ httpStatus: StatusCodes; result: any }>;
}

/** Convert payload from getServingModelDetails to ModelTaxonomiesParams */
export function chainUpModelTaxonomies(payload: Model[], apiGet: ApiGet, path: string): ModelTaxonomiesParams {
  const modelIntentType = new Map<string, IntentIntentType>();
  const modelURLs: string[] = [];
  for (const model of payload) {
    if (model.type === 'agent') {
      modelIntentType.set(model.url, IntentIntentType.AGENT_INTENT);
      modelURLs.push(model.url);
    } else if (model.type === 'visitor') {
      modelIntentType.set(model.url, IntentIntentType.VISITOR_INTENT);
      modelURLs.push(model.url);
    } else if (model.type === 'driver') {
      modelIntentType.set(model.url, IntentIntentType.CONVERSATION_DRIVER);
      modelURLs.push(model.url);
    }
  }
  const taxonomiesGetter = () => apiGet(`${path}/get_model_taxonomies`, { model_urls: modelURLs });
  return {
    getter: taxonomiesGetter,
    modelIntentType,
  };
}

/** AsyncThunk for getting model taxonomies. */
export const getModelTaxonomies = createAsyncThunk<ProdIntent[], ModelTaxonomiesParams>(
  Action.MODEL_BUILDER_GET_PROD_TAXONOMIES,
  async (params: ModelTaxonomiesParams) => {
    try {
      const response = await params.getter();
      if (response.httpStatus === StatusCodes.OK) {
        const { result } = response;
        return toProdIntents(result, params.modelIntentType);
      } else {
        throw new Error(JSON.stringify(response));
      }
    } catch (err) {
      openNotification('error', 'Failed to get model taxonomies', undefined, err);
      throw err;
    }
  },
);

function toProdIntents(result: any, modelIntentType: Map<string, IntentIntentType>): ProdIntent[] {
  if (!result) {
    return [];
  }
  const prodIntents = new Map<string, ProdIntent>();
  for (const model of Object.keys(result)) {
    const intents = result[model];
    const intentType = modelIntentType.get(model) || IntentIntentType.INTENT_TYPE_UNSPECIFIED;
    for (const intent of intents.neural_intents) {
      prodIntents.set(intent, {
        intent,
        type: intentType,
        positiveRegex: [],
        negativeRegex: [],
      });
    }
    for (const intent of intents.rule_intents) {
      if (prodIntents.has(intent.intent)) {
        prodIntents.get(intent.intent).positiveRegex = intent.positive_regexes;
        prodIntents.get(intent.intent).negativeRegex = intent.negative_regexes;
      } else {
        prodIntents.set(intent.intent, {
          intent: intent.intent,
          type: intentType,
          positiveRegex: intent.positive_regexes,
          negativeRegex: intent.negative_regexes,
        });
      }
    }
  }
  return Array.from(prodIntents.values());
}

/** AsyncThunk for getting model artifact (task detail). */
export const getModelArtifact = createAsyncThunk<ModelArtifact, string>(
  Action.MODEL_BUILDER_GET_MODEL_ARTIFACT,
  async (name: string, { getState }) => {
    const { modelBuilder: { openedArtifacts } } = getState() as { modelBuilder: State };
    // Skip query if task is completed or failed.
    const modelArtifact = openedArtifacts.find((a) => a.name === name);
    if (modelArtifact && [ModelArtifactStatus.ACTIVE, ModelArtifactStatus.FAILED].includes(modelArtifact.status)) {
      return null;
    }
    try {
      const response = await modelArtifactApi.getModelArtifact({ name });
      return response.modelArtifact;
    } catch (err) {
      openNotification('error', 'Failed to get task', undefined, err);
      throw err;
    }
  },
);
