import { CopyOutlined } from '@ant-design/icons';
import { Button, ButtonProps, CopyButton, Tooltip } from '@mantine/core';
import React from 'react';

import './CopyableValue.scss';

interface IProps {
  displayValue: string;
  copiedValue?: string;
  tooltip?: string;
  buttonProps?: ButtonProps
}

export default function CopyableValue({
  displayValue,
  copiedValue = displayValue,
  tooltip = 'Copy to clipboard',
  buttonProps = {},
}: IProps) {
  return (
    <CopyButton value={copiedValue}>
      {({ copied, copy }) => {
        const button = (
          <Button color={copied && 'green'} onClick={copy} rightIcon={copied || <CopyOutlined className="icon" />} compact variant="subtle" {...buttonProps}>
            {copied ? 'Copied!' : displayValue || 'Copy'}
          </Button>
        );
        if (!tooltip) {
          return button;
        }
        return (
          <Tooltip label={tooltip}>
            {button}
          </Tooltip>
        );
      }}
    </CopyButton>
  );
}
