import { Autocomplete, Button, Group, Kbd, Modal, Text, useMantineTheme } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { DataGrid } from 'components/DataGrid';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HotkeyListItem, hotkeysList, hotkeysRoutes } from './config';

interface HotkeysHelperProps {
  opened: boolean;
  setOpened: (val: boolean) => void;
}

export function HotkeysHelper({
  opened,
  setOpened,
}: HotkeysHelperProps) {
  const theme = useMantineTheme();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const location = useLocation();

  const tagsList: string[] = useMemo(() => hotkeysRoutes.map(([route, tag]) => tag), [hotkeysRoutes]);

  const filteredHotkeys = useMemo(() => hotkeysList.filter((item) => {
    // Return all if no filter
    if (!searchTerm) return true;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const itemTags = item.Tags.split(',').map((tag) => tag.trim().toLowerCase());
    // Check if the tags from filter overlap with the tags from the list item
    return itemTags.includes(lowerCaseSearchTerm) || item.Action?.toLowerCase().includes(lowerCaseSearchTerm);
  }), [searchTerm, hotkeysList]);

  useEffect(() => {
    if (opened) {
      // Find preselected tag by route
      const currentRoute = location.pathname;
      const matchingTag = hotkeysRoutes.find(([route]) => currentRoute.includes(route))?.[1];

      if (matchingTag) {
        setSearchTerm(matchingTag);
      } else {
        setSearchTerm('');
      }
    }
  }, [opened]);

  const columns: ColumnDef<HotkeyListItem>[] = [
    {
      accessorFn: (row) => row.Shortcut,
      header: 'Key binding',
      cell: (info) => {
        const hotkeys = info.getValue() as string;
        return (
          <Group noWrap>
            <Group spacing={4}>
              {hotkeys.split(' ').map((key) => (key === '+' ? key : <Kbd>{key}</Kbd>))}
            </Group>
          </Group>
        );
      },
    },
    {
      accessorFn: (row) => row.Action,
      header: 'Command',
      cell: (info) => {
        const command = info.getValue() as string;
        return (
          <Text color={theme.colors.gray[8]} size="md">{command}</Text>
        );
      },
    },
    {
      accessorFn: (row) => row.Tags,
      header: 'Location',
      cell: (info) => {
        const tags = info.getValue() as string[];
        return (
          <Text size="md" color="dimmed">{tags}</Text>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        withCloseButton
        onClose={() => setOpened(false)}
        size={1000}
        title="Keyboard shortcuts"
      >
        <Group>
          <Autocomplete
            placeholder="Search"
            sx={{ width: '100%' }}
            value={searchTerm}
            mb="lg"
            data={tagsList}
            onChange={setSearchTerm}
          />
        </Group>
        <DataGrid columns={columns} dataSource={filteredHotkeys}/>
        <Group mt="lg">
          <Button
            onClick={() => setOpened(false)}
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}
