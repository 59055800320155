import { SpeakerRole } from '@cresta/web-client/dist/cresta/ai_service/common';

export type PrepopulatedType = 'taxonomy' | 'previousTask' | 'none';

export enum Category {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export interface ExampleObj {
  value: string;
  id: string;
}

export interface Preview {
  agent: string;
  date: string;
  name: string;
  message: string;
  speaker: 'Visitor' | 'Agent';
}

export interface ConceptData {
  name: string;
  description?: string;
  role: SpeakerRole;
  value: string;
  positiveExamples: ExampleObj[];
  negativeExamples: ExampleObj[];
  prepopulatedType: PrepopulatedType;
}

export interface ConfigFormValues {
  description: string;
  humanReadableName: string;
  positiveExamples: ExampleObj[];
  negativeExamples: ExampleObj[];
  speakerRole: SpeakerRole;
}

export enum FormValueExampleArrayType {
  POSITIVE = 'positiveExamples',
  NEGATIVE = 'negativeExamples',
}
