import React, { ReactElement, useState, useMemo, useEffect, useCallback } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import { Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Button, Select } from '@mantine/core';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { ModelProject, ModelProjectProjectType, ModelProjectStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { User } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { selectUsers } from 'store/user/selectors';
import { listModelProjects, updateModelProject } from 'store/modelProject/asyncThunks';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { getId } from 'common/resourceName';
import ItemPicker from 'components/ItemPicker';
import UserTag from 'components/UserTag';
import { selectModelProjectsTableData } from 'store/modelProject/selectors';
import { ModelProjectTableRow } from '../../models';
import { NewProjectModal } from './NewProjectModal';
import { UpdateProjectNameModal } from './UpdateProjectNameModal';

import styles from './styles.module.scss';

const StatusEnum = [{
  key: ModelProjectStatus.ARCHIVED,
  label: 'Archived',
}, {
  key: ModelProjectStatus.DEPLOYED,
  label: 'Deployed',
}, {
  key: ModelProjectStatus.IN_PROGRESS,
  label: 'In progress',
}, {
  key: ModelProjectStatus.READY,
  label: 'Ready',
}, {
  key: ModelProjectStatus.STATUS_UNSPECIFIED,
  label: 'Unknown',
}];
const statusClassName = {
  [ModelProjectStatus.READY]: styles.statusPoint__r,
  [ModelProjectStatus.DEPLOYED]: styles.statusPoint__d,
  [ModelProjectStatus.ARCHIVED]: styles.statusPoint__a,
  [ModelProjectStatus.IN_PROGRESS]: styles.statusPoint__i,
};
const ProjectTypeEnum = [{
  key: ModelProjectProjectType.AGENT_INTENT,
  className: styles.modelWizardTableType__intent,
  text: 'Intents: Agent',
},
{
  key: ModelProjectProjectType.VISITOR_INTENT,
  className: styles.modelWizardTableType__intent,
  text: 'Intents: Visitor',
},
{
  key: ModelProjectProjectType.CONVERSATION_DRIVER,
  className: styles.modelWizardTableType__intent,
  text: 'Intents: Driver',
},
{
  key: ModelProjectProjectType.SMART_COMPOSE,
  className: styles.modelWizardTableType__smart_compose,
  text: 'Smart compose',
},
{
  key: ModelProjectProjectType.SUGGESTION_GENERATIVE,
  className: styles.modelWizardTableType__suggestion,
  text: 'Suggestions: Generative',
},
{
  key: ModelProjectProjectType.SUGGESTION_RETRIEVAL,
  className: styles.modelWizardTableType__suggestion,
  text: 'Suggestions: Retrieval',
},
{
  key: ModelProjectProjectType.SUGGESTION_ENSEMBLE,
  className: styles.modelWizardTableType__suggestion,
  text: 'Suggestions: Ensemble',
},
];
export function ModelWizard(): ReactElement {
  const [type, setType] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [newProjectModalOpen, setNewProjectModalOpen] = useState<boolean>(false);
  const [updateProjectNameModalOpen, setUpdateProjectNameModalOpen] = useState<boolean>(false);
  const [updateProjectRow, setUpdateProjectRow] = useState<ModelProjectTableRow | undefined>(undefined);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const customerProfile = useCustomerProfile();

  const modelProjects = useSelector<ModelProjectTableRow[]>(selectModelProjectsTableData);
  const users = useSelector<User[]>(selectUsers);

  useEffect(() => {
    dispatch(listModelProjects({
      parent: customerProfile,
      usecaseId,
      languageCode,
    }));
  }, [dispatch, path]);
  const handleUpdateProject = useCallback((row: ModelProject) => {
    dispatch(updateModelProject({ modelProject: row })).then((res) => {
      dispatch(listModelProjects({
        parent: customerProfile,
        usecaseId,
        languageCode,
      }));
      setUpdateProjectNameModalOpen(false);
    });
  }, [path]);
  const columns: ColumnsType<ModelProjectTableRow> = [
    {
      title: 'Type',
      dataIndex: 'projectType',
      key: 'type',
      width: '20%',
      sorter: (a: ModelProjectTableRow, b: ModelProjectTableRow) =>
        a.projectType.localeCompare(b.projectType),
      render: (type: string) => {
        const currentProject = ProjectTypeEnum.find((item) => item.key === type);
        return (
          <div className={`${styles.modelWizardTableType} ${currentProject?.className || styles.modelWizardTableType__driver}`}>{currentProject?.text || 'Unknown'}</div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'name',
      width: '30%',
      sorter: (a: ModelProjectTableRow, b: ModelProjectTableRow) =>
        a.displayName.localeCompare(b.displayName),
      render: (displayName, record) => (
        <Tooltip title="edit the project name">
          <Button
            data-testid="update-displayname-button"
            variant="subtle"
            size="sm"
            rightIcon={<EditOutlined />}
            compact
            onClick={async (e) => {
              e.stopPropagation();
              handleNameClick(e, record);
            }}
          >{displayName}
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      sorter: (a: ModelProjectTableRow, b: ModelProjectTableRow) =>
        a.status.localeCompare(b.status),
      render: (status: string, record: ModelProjectTableRow) => (
        <div
          className={styles.projectStatusPicker}
          onClick={(event) => event.stopPropagation()}
        >
          <ItemPicker
            placeholder="Select"
            onChange={(item) => {
              handleUpdateProject({ ...record, status: item.key });
            }}
            value={status}
            idKeyPath="key"
            filterKey={(item) => item.key}
            items={StatusEnum}
            renderItem={
              (item: { key: ModelProjectStatus, label: string }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={`${styles.statusPoint} ${statusClassName[item.key]}`} />
                  <span>
                    {item.label}
                  </span>
                </div>
              )
            }
          />
        </div>
      ),
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      width: '15%',
      sorter: (a: ModelProjectTableRow, b: ModelProjectTableRow) =>
        a.creator.localeCompare(b.creator),
      render: (creator) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UserTag name={users.find((user) => user.name === creator)?.fullName || 'Unknown'} />
        </div>
      ),
    },
    {
      title: 'Last updated',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '30%',
      sorter: (a: ModelProjectTableRow, b: ModelProjectTableRow) =>
        dayjs(a.updateTime).diff(dayjs(b.updateTime)),
      render: (updateTime) => (
        <div>
          {dayjs(updateTime).format('MM/DD/YYYY h:mm a')}
        </div>
      ),
    },
  ];
  const filterData: ModelProjectTableRow[] = useMemo(
    () =>
      modelProjects.filter(
        (item: ModelProjectTableRow) =>
          item.status !== ModelProjectStatus.ARCHIVED
          && item.projectType?.toLowerCase().includes(type ? type.toLowerCase() : '')
          && item.status?.toLowerCase().includes(status ? status.toLowerCase() : ''),
      ),
    [status, type, modelProjects],
  );
  const handleNameClick = (event: React.MouseEvent, projectRow: ModelProjectTableRow) => {
    event.stopPropagation();
    setUpdateProjectRow(projectRow);
    setUpdateProjectNameModalOpen(true);
  };
  const onRow = (record: ModelProjectTableRow) => {
    const actions = {
      onClick: () => {
        const selected = window.getSelection?.();
        if (!selected.isCollapsed) return;
        navigate(`${getId('modelProject', record.name)}`);
      },
    };
    return actions;
  };
  return (
    <div className={styles.modelWizard}>
      <div className={styles.modelWizardHeader}>
        <div className={styles.modelWizardTitle}>Model Wizard</div>
        <button type="button" className={styles.modelWizardButton} onClick={() => setNewProjectModalOpen(true)}>
          Add new
        </button>
      </div>
      <div className={styles.modelWizardFilter}>
        <div className={styles.modelWizardProLen}>
          {filterData.length}
          {' '}
          Projects
        </div>
        <div className={styles.modelWizardSelectGroup}>
          <Select
            searchable
            clearable
            placeholder="All types"
            nothingFound="No options"
            data={['Agent', 'Visitor', 'Driver']}
            styles={{
              root: { width: 160, marginRight: 20 },
              input: { border: 'none' },
            }}
            onChange={(value) => setType(value)}
          />
          <Select
            searchable
            clearable
            placeholder="All status"
            nothingFound="No options"
            data={['Deployed', 'In progress', 'Ready']}
            styles={{ root: { width: 160 }, input: { border: 'none' } }}
            onChange={(value) => setStatus(value)}
          />
        </div>
      </div>
      <Table
        columns={columns}
        className={styles.modelWizardTable}
        dataSource={filterData}
        rowKey={(row: ModelProjectTableRow) => `${row.name}`}
        onRow={onRow}
      />
      <NewProjectModal opened={newProjectModalOpen} onCancel={() => setNewProjectModalOpen(false)} />
      <UpdateProjectNameModal
        handleUpdateProject={handleUpdateProject}
        opened={updateProjectNameModalOpen}
        onCancel={() => setUpdateProjectNameModalOpen(false)}
        projectRow={updateProjectRow}
      />
    </div>
  );
}
