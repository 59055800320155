import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useCallback, useEffect } from 'react';
import { updateDebuggingMode } from 'store/app/appSlice';
import { selectDebuggingMode } from 'store/app/selectors';

export const useDebuggingMode = () => {
  const debuggingMode = useSelector<boolean>(selectDebuggingMode);

  const dispatch = useDispatch();

  const toggleDebuggingMode = useCallback(() => {
    dispatch(updateDebuggingMode(!debuggingMode));
    if (debuggingMode) {
      localStorage.removeItem('debuggingMode');
    } else {
      localStorage.setItem('debuggingMode', 'true');
    }
  }, [debuggingMode]);

  useEffect(() => {
    // Attach function to toggle debugging mode
    (window as any).toggleDebuggingMode = toggleDebuggingMode;
  }, [toggleDebuggingMode]);
};
