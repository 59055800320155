import { ModelProjectProjectType } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { ModelType, State as ModelBuilderState } from './state';

const selectModelBuilder = (state: State) => state.modelBuilder;

/** Api status. */
export const selectLoadServingModelStatus = createSelector(selectModelBuilder, (modelBuilderState: ModelBuilderState) => modelBuilderState.loadServingModelStatus);
export const selectLoadProdTaxonomiesStatus = createSelector(selectModelBuilder, (modelBuilderState: ModelBuilderState) => modelBuilderState.loadProdTaxonomiesStatus);
export const selectLoadServingModelFromAIServicesStatus = createSelector(selectModelBuilder, (modelBuilderState: ModelBuilderState) => modelBuilderState.loadServingModelFromAIServicesStatus);

/** Select serving models. */
export const selectServingModels = createSelector(selectModelBuilder, (modelBuilderState: ModelBuilderState) => modelBuilderState.servingModels);
export const selectServingModelsFromAIServices = createSelector(selectModelBuilder, (modelBuilderState: ModelBuilderState) => modelBuilderState.servingModelsFromAIServices);

const modelTypeMap = new Map<string, ModelType>([
  [ModelProjectProjectType.AGENT_INTENT, 'agent'],
  [ModelProjectProjectType.VISITOR_INTENT, 'visitor'],
  [ModelProjectProjectType.CONVERSATION_DRIVER, 'driver'],
]);

/** Select serving model URI given the model type. */
export const selectServingModelUri = (modelType: ModelProjectProjectType) => createSelector(
  selectModelBuilder,
  (modelBuilderState: ModelBuilderState) => {
    const t = modelTypeMap.get(modelType) || '';
    const servingModel = modelBuilderState.servingModels?.find((m) => m.type === t);
    return servingModel?.url || '';
  },
);

/** Select intents in prod */
export const selectProdIntents = createSelector(selectModelBuilder, (modelBuilderState: ModelBuilderState) => modelBuilderState.prodIntents);

/** Select artifact by name */
export const selectModelArtifactByName = (name: string) => createSelector(
  selectModelBuilder,
  (modelBuilderState: ModelBuilderState) => modelBuilderState.openedArtifacts.find((artifact) => artifact.name === name),
);

/** Select opened artifacts */
export const selectOpenedArtifacts = createSelector(
  selectModelBuilder,
  (modelBuilderState: ModelBuilderState) => modelBuilderState.openedArtifacts,
);
