import {
  BatchDeprecateAnnotationsRequest,
  BatchDeprecateAnnotationsResponse,
  ListAnnotationsRequest,
  ListAnnotationsResponse,
  SearchAnnotationsRequest,
  SearchAnnotationsResponse,
  AnnotationService,
  FetchAnnotationSummaryRequest,
  FetchAnnotationSummaryResponse,
  BatchUpsertAnnotationsResponse,
  BatchUpsertAnnotationsRequest,
  ModifyAnnotationResponse,
  ModifyAnnotationRequest,
  FetchCalibrationAnnotationsRequest,
  FetchCalibrationAnnotationsResponse,
  FetchManualAnalysisSummaryRequest,
  FetchManualAnalysisSummaryResponse,
  FetchCandidateSuggestionTextsRequest,
  FetchCandidateSuggestionTextsResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { StudioApi } from './studioApi';

export abstract class AnnotationApi {
  public static listAnnotations(
    req: ListAnnotationsRequest,
  ): Promise<ListAnnotationsResponse> {
    return AnnotationService.ListAnnotations(req, StudioApi.getHeaders().initReq);
  }

  public static fetchManualAnalysisSummary(req: FetchManualAnalysisSummaryRequest): Promise<FetchManualAnalysisSummaryResponse> {
    return AnnotationService.FetchManualAnalysisSummary(req, StudioApi.getHeaders().initReq);
  }

  public static fetchAnnotationSummary(req: FetchAnnotationSummaryRequest): Promise<FetchAnnotationSummaryResponse> {
    return AnnotationService.FetchAnnotationSummary(req, StudioApi.getHeaders().initReq);
  }

  public static searchAnnotations(
    req: SearchAnnotationsRequest,
  ): Promise<SearchAnnotationsResponse> {
    return AnnotationService.SearchAnnotations(req, StudioApi.getHeaders().initReq);
  }

  public static batchDeprecateAnnotations(
    req: BatchDeprecateAnnotationsRequest,
  ): Promise<BatchDeprecateAnnotationsResponse> {
    return AnnotationService.BatchDeprecateAnnotations(req, StudioApi.getHeaders().initReq);
  }

  public static batchUpsertAnnotations(
    req: BatchUpsertAnnotationsRequest,
  ): Promise<BatchUpsertAnnotationsResponse> {
    return AnnotationService.BatchUpsertAnnotations(req, StudioApi.getHeaders().initReq);
  }

  public static modifyAnnotation(
    req: ModifyAnnotationRequest,
  ): Promise<ModifyAnnotationResponse> {
    return AnnotationService.ModifyAnnotation(req, StudioApi.getHeaders().initReq);
  }

  public static fetchCalibrationAnnotations(
    req: FetchCalibrationAnnotationsRequest,
  ): Promise<FetchCalibrationAnnotationsResponse> {
    return AnnotationService.FetchCalibrationAnnotations(req, StudioApi.getHeaders().initReq);
  }

  public static fetchCandidateSuggestionTexts(
    req: FetchCandidateSuggestionTextsRequest,
  ): Promise<FetchCandidateSuggestionTextsResponse> {
    return AnnotationService.FetchCandidateSuggestionTexts(req, StudioApi.getHeaders().initReq);
  }
}
