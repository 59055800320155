import React from 'react';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { INTENT_TYPE_MAP } from 'constants/intent';
import './IntentTypeTag.scss';

type IntentTypeTagProps = {
  intentType: IntentIntentType;
};

export default function IntentTypeTag({
  intentType,
}: IntentTypeTagProps) {
  const intentTypeObject = INTENT_TYPE_MAP.get(intentType);
  return <div className={`intent-type-tag ${intentTypeObject?.className}`} key={intentType}>{intentTypeObject?.text}</div>;
}
