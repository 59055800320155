import { Button, Group, Modal, TextInput } from '@mantine/core';
import React, { useState } from 'react';

interface MergeClustersModalProps {
  isOpen: boolean;
  onCancel: () => void,
  onSubmit: (name: string) => void;
  submitting: boolean;
}

export default function MergeClustersModal({
  isOpen,
  onCancel,
  onSubmit,
  submitting,
}: MergeClustersModalProps) {
  const [name, setName] = useState('');

  return (
    <Modal
      opened={isOpen}
      onClose={() => onCancel()}
      title="Merge clusters"
      size="sm"
    >
      <TextInput label="New name" placeholder="E.g. Cancel upgrade" value={name} onChange={(event) => setName(event.currentTarget.value)} />
      <Group position="right" mt="lg">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button loading={submitting} title="Confirm" onClick={() => onSubmit(name)}>Merge</Button>
      </Group>
    </Modal>
  );
}
