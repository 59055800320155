import { useCallback, useState, useEffect } from 'react';
import { getErrorMessage } from 'utils';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import dayjs from 'dayjs';
import { PromptApi } from 'services/promptApi';
import { IPrompt } from './usePrompts';
import { handleSections } from '../PromptEditor';

const sortByDate = (a: IPrompt, b: IPrompt) => {
  const aDate = a.updatedAt || a.createdAt;
  const bDate = b.updatedAt || b.createdAt;
  return dayjs(bDate).diff(dayjs(aDate));
};

export const usePromptVersions: (
  promptGroupId: string,
) => [IPrompt[], boolean, () => void, string | null] = (promptGroupId) => {
  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [refetch, toggleRefetch] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { languageCode } = useCustomerParams();

  useEffect(() => {
    if (!promptGroupId) return;
    setLoading(true);
    setError(null);
    let retries = 0;
    const getAllVersionsForPromptGroup = async (promptGroupId: string) => {
      try {
        // fails when customerProfile is changed as config is not immediately updated
        const res = await PromptApi.listPrompts({
          parent: customerProfile,
          usecase,
          languageCode,
          filter: {
            promptGroupId,
          },
        });
        if (res) {
          const fetchedPrompts = res.prompts;
          const mappedPrompts = fetchedPrompts
            // temporarily needed as backend does not filter out null promptGroupId
            .filter((p) => p.promptGroupId === promptGroupId)
            .map((p) => ({
              id: p.name.split('/').pop(),
              promptText: handleSections(p.sections),
              displayName: p.displayName,
              createdAt: p.createTime,
              updatedAt: p.updateTime,
              isDeployed: p.isDeployed,
              version: p.version,
              promptGroupId: p.promptGroupId,
              isActiveVersion: p.isActiveVersion,
            }));
          const sortedPrompts = mappedPrompts.sort(sortByDate);
          setPrompts(sortedPrompts);
        } else {
          setPrompts([]);
        }
        setLoading(false);
      } catch (e) {
        if (retries < 4) {
          retries++;
          // exponential backoff
          const RETRY_DELAY = retries * 200;
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          getAllVersionsForPromptGroup(promptGroupId);
        } else {
          console.error(e);
          const errorMessage = getErrorMessage(e);
          setError(errorMessage);
          setPrompts([]);
          setLoading(false);
        }
      }
    };

    getAllVersionsForPromptGroup(promptGroupId);
  }, [customerProfile, refetch, promptGroupId]);

  const handleRefetch = useCallback(() => {
    toggleRefetch(!refetch);
  }, [refetch]);

  return [prompts, loading, handleRefetch, error];
};
