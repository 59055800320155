import React from 'react';
import { Button, Group } from '@mantine/core';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { TagSelect } from 'pages/AnalysisWorkshop/components/TagSelect';
import { createNewTagRequest } from './utils';
import './TagConfig.scss';

export const FormItem = ({ autoFocus = false, tagType, label, description, addAllButtonTestId, existingItems, addedItems, setAddedItems, newItem, setNewItem, setNewItems, children = undefined }) => {
  const customerProfile = useCustomerProfile();

  const handleAddExisting = (label) => () => {
    const selectedExistingDriver = existingItems.find((driver) => driver.conceptTitle === label);
    setAddedItems((current) => [...current, selectedExistingDriver]);
    setNewItem('');
  };

  const remainingExistingItems = existingItems.filter((item) => !addedItems.find((addedItem) => addedItem.conceptTitle === item.conceptTitle));

  const onCreate = (query:string) => {
    const createdConcept = createNewTagRequest(query, { customerProfile, tagType });
    setNewItem('');
    setNewItems((current) => [...current, createdConcept]);
    // resets the input field
    return {
      label: null,
      value: null,
    };
  };

  const data = existingItems.filter((existing) =>
    addedItems.every((added) =>
      added.name !== existing.name))
    .map(({ name, conceptTitle, tag: { tagType } }) => (
      {
        label: conceptTitle,
        value: name,
        tagType,
        onSelect: handleAddExisting(conceptTitle),
      }
    ));

  return (
    <div className="form-section">
      <label>{label}</label>
      <div className="description">{description}</div>
      <Group align="end">
        {children}
        <TagSelect
          style={{ flex: 1 }}
          autoFocus={autoFocus}
          data-cy={`${tagType}-select-input`}
          data={data}
          value={newItem}
          onChange={setNewItem}
          onCreate={onCreate}
        />
        <Button
          data-cy={`${tagType}-add-all-btn`}
          variant="light"
          onClick={() => {
            setAddedItems((items) => [...items, ...remainingExistingItems]);
            setNewItem('');
          }}
          disabled={remainingExistingItems.length === 0}
        >Add all
        </Button>
      </Group>
    </div>
  );
};
