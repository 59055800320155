import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import ConceptTag from 'components/ConceptTag';

export const labelTableColumns: ColumnDef<unknown>[] = [
  {
    header: 'Label',
    accessorKey: 'label',
    cell: (info) => {
      const label = info.getValue() as string;
      return <ConceptTag value={label} />;
    },
  },
  {
    header: 'Train Positive',
    accessorKey: 'trainPositiveCount',
  },
  {
    header: 'Train Negative',
    accessorKey: 'trainNegativeCount',
  },
  {
    header: 'Test Positive',
    accessorKey: 'testPositiveCount',
  },
  {
    header: 'Test Negative',
    accessorKey: 'testNegativeCount',
  },
  {
    header: 'QA Positive',
    accessorKey: 'qaPositiveCount',
  },
  {
    header: 'QA Negative',
    accessorKey: 'qaNegativeCount',
  },
];
