import { CreatorCreatorType } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import { ModelProjectProjectType } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import {
  ConceptType,
  DecisionType,
  LabelingViewType,
  QaDataSource,
  SelectionMethod,
  TargetType,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { CreateTaskRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { v4 as uuid } from 'uuid';

const getUriProperty = (modelUri: string, projectType: ModelProjectProjectType) => {
  switch (projectType) {
    case ModelProjectProjectType.AGENT_INTENT:
      return {
        agentModelUri: modelUri,
      };
    case ModelProjectProjectType.VISITOR_INTENT:
      return {
        visitorModelUri: modelUri,
      };
    case ModelProjectProjectType.CONVERSATION_DRIVER:
      return {
        chatDriverModelUri: modelUri,
      };
    default:
      return {};
  }
};
interface MisclassificationQATaskConfig {
  conceptIds: string[];
  customerProfile: string;
  usecaseId: string;
  languageCode: string;
  userId: string;
  modelUri: string;
  projectType: ModelProjectProjectType;
}

export const createMisclassificationQATask: (
  config: MisclassificationQATaskConfig,
) => CreateTaskRequest = ({ customerProfile, usecaseId, languageCode, userId, conceptIds, modelUri, projectType }) => {
  const taskId = uuid();
  const misclassificationQATaskData: CreateTaskRequest = {
    name: `${customerProfile}/labelingTasks/${taskId}`,
    commonInput: {
      usecase: `${customerProfile}/usecases/${usecaseId}`,
      languageCode,
      workflowId: 'default-labeling-workflow',
      title: `Labeling Task ${taskId}`,
      assigneeUserId: userId,
      creator: {
        creatorType: CreatorCreatorType.UI_ACTION,
        userId,
      },
    },
    taskDescriptor: {
      conceptType: ConceptType.INTENT,
      targetType: TargetType.QA,
      conceptIds,
      viewType: LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_NO_CONTEXT,
      decisionType: DecisionType.DECISION_TYPE_SINGLE_BINARY,
      selectionInstruction: {
        selectionMethod: SelectionMethod.INTENT_MISCLASSIFICATION_QA,
        intentPredictionRandom: {
          qaSource: QaDataSource.OFFLINE_MODEL,
          maxPredictionCount: 30,
          ...getUriProperty(modelUri, projectType),
        },
      },
      useV2MsgAndConvId: true,
    },
  };
  return misclassificationQATaskData;
};
