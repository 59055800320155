import React, { FC, ReactElement, useMemo, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { CreateMessageResponse, Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { Button, Group, Tooltip, UnstyledButton, HoverCard } from '@mantine/core';
import { Conversation as TypeConversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { SimulationTaskDataSimulationType as SimulationType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { getId } from 'common/resourceName';
import { ConversationsList } from './ConversationsList';
import { MessagesPreviewer } from './MessagesPreviewer';
import { ModelSetUp, ModelInputData } from './ModelSetUp';
import styles from './style.module.scss';

export const useModelURLs = (form: FormInstance): ModelInputData => ({
  agentURL: Form.useWatch<string>('agentURL', form),
  visitorURL: Form.useWatch<string>('visitorURL', form),
  chatDriverURL: Form.useWatch<string>('chatDriverURL', form),
  policyURL: Form.useWatch<string>('policyURL', form),
});

interface Props {
  firstForm: FormInstance<ModelInputData>;
  secondForm: FormInstance<ModelInputData>;
  previewMessages: Message[];
  conversations: TypeConversation[];
  activeChatCardId: string;
  messagesLoading: boolean;
  buttonLoading: boolean;
  runningStateDetail?: ReactElement;
  handleDeleteCard: (conversationId: string) => void;
  handleImportConversations: (options?: { goldenOnly?: boolean, setIds?: string[] }) => void;
  handleGetConversation: (conversationId: string) => void;
  handleAddConversation: () => void;
  handleUpdateConversationDisplayName: (conversationId: string, displayName: string) => void;
  handleClickCard: (conversationId: string) => void;
  handleAddMessage: (message: Message) => Promise<CreateMessageResponse>;
  handleUseProd: (firstModel: boolean) => void;
  handleRunSimulator: (simulationType: SimulationType) => void;
  handleTabChange: (activeKey: string) => void;
  disableAll?: boolean;
}
export const SimulatorModel: FC<Props> = ({
  activeChatCardId,
  previewMessages,
  conversations,
  handleDeleteCard,
  handleImportConversations,
  handleGetConversation,
  handleAddConversation,
  handleUpdateConversationDisplayName,
  handleClickCard,
  messagesLoading,
  buttonLoading,
  runningStateDetail,
  firstForm,
  secondForm,
  handleAddMessage,
  handleUseProd,
  handleRunSimulator,
  disableAll,
  handleTabChange,
}) => {
  const activeConvo: TypeConversation = useMemo(() => conversations?.find((convo) => getId('conversation', convo.name) === activeChatCardId), [activeChatCardId, conversations]);
  const firstModelURLs = useModelURLs(firstForm);
  const secondModelURLs = useModelURLs(secondForm);
  const [compareModel, setCompareModel] = useState<boolean>(false);
  const [isInRegressionTestMode, toggleRegressionTestMode] = useState(false);

  const runDisabled = useMemo(() => {
    if (!conversations.length) {
      return true;
    }
    const {
      agentURL: firstAgent,
      chatDriverURL: firstDriver,
      policyURL: firstPolicy,
      visitorURL: firstVisitor,
    } = firstModelURLs;
    const {
      agentURL: secondAgent,
      chatDriverURL: secondDriver,
      policyURL: secondPolicy,
      visitorURL: secondVisitor,
    } = secondModelURLs;
    if (isInRegressionTestMode) {
      return !(secondAgent || secondDriver || secondPolicy || secondVisitor);
    }
    if (compareModel) {
      return !(firstAgent || firstDriver || firstPolicy || firstVisitor)
        || !(secondAgent || secondDriver || secondPolicy || secondVisitor);
    }
    return !(firstAgent || firstDriver || firstPolicy || firstVisitor);
  }, [firstModelURLs, secondModelURLs, compareModel, isInRegressionTestMode, conversations]);

  const handleRegressionTestModeBtnClick = () => {
    // if already in regression test mode, then do nothing
    if (isInRegressionTestMode) {
      toggleRegressionTestMode(false);
      return;
    }
    handleUseProd(true);
    // When clicked, regression test configuration is added:
    // All golden conversations are added
    handleImportConversations({ goldenOnly: true });
    // Model set up is done (Model 1 is grayed out/disabled to prevent editing)
    // User shouldn’t be able to add more conversations or change golden set settings
    toggleRegressionTestMode(true);
  };

  const getSimulationType = () => {
    if (isInRegressionTestMode) {
      return SimulationType.REGRESSION_TEST;
    }
    if (compareModel) {
      return SimulationType.COMPARE_MODELS;
    }
    return SimulationType.SINGLE_MODEL;
  };

  const simulationType = getSimulationType();

  return (
    <div className={styles.simulatorModelSetup}>
      <Group
        className={styles.simulatorModelActionButtons}
        position="apart"
        mb={16}
      >
        <div className={styles.simulatorResultTitle}>
          <UnstyledButton className={styles.active}>Simulator</UnstyledButton>
          <UnstyledButton onClick={() => handleTabChange('history')}>History</UnstyledButton>
        </div>
        {runningStateDetail ? (
          <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <div>
                <Button loading={buttonLoading}>
                  Hover to See Running Details
                </Button>
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              {runningStateDetail}
            </HoverCard.Dropdown>
          </HoverCard>
        ) : (
          <Tooltip label="Configure model setup" disabled={!runDisabled}>
            <Button
              loading={buttonLoading}
              onClick={() => handleRunSimulator(simulationType)}
              disabled={!!runDisabled}
            >
              Run Simulator
            </Button>
          </Tooltip>
        )}
      </Group>
      <div className={styles.simulatorModelSetupContent}>
        <ConversationsList
          activeChatCardId={activeChatCardId}
          handleClickCard={handleClickCard}
          conversations={conversations}
          handleDeleteCard={handleDeleteCard}
          handleImportConversations={handleImportConversations}
          handleGetConversation={handleGetConversation}
          handleAddConversation={handleAddConversation}
          disableAll={disableAll}
          isInRegressionTestMode={isInRegressionTestMode}
        />
        <div className={styles.messageContainer}>
          <MessagesPreviewer
            conversation={activeConvo}
            messages={previewMessages}
            loading={messagesLoading}
            onAdd={handleAddMessage}
            handleUpdateConversationDisplayName={handleUpdateConversationDisplayName}
            disableAll={disableAll}
          />
        </div>
        <ModelSetUp
          handleRegressionTestModeBtnClick={handleRegressionTestModeBtnClick}
          firstForm={firstForm}
          secondForm={secondForm}
          buttonLoading={buttonLoading}
          disableAll={disableAll}
          isInRegressionTestMode={isInRegressionTestMode}
          handleUseProd={handleUseProd}
          compareModel={compareModel}
          setCompareModel={setCompareModel}
        />
      </div>
    </div>
  );
};
