import { Concept, ListConceptsRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { useCallback, useEffect, useState } from 'react';
import { ConceptApi } from 'services/conceptApi';

export const useMoments: () => [Concept[], boolean, () => void] = () => {
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();

  const [moments, setMoments] = useState<Concept[]>([]);
  const [isLoading, toggleLoading] = useState(true);
  const [refetchVal, toggleRefetch] = useState(false);

  // Fetch all moments
  const fetchMoments = useCallback(async () => {
    toggleLoading(true);

    let pageToken = '';
    const moments: Concept[] = [];
    while (true) {
      const request: ListConceptsRequest = {
        parent: customerProfile,
        usecaseId: customer?.usecaseId,
        languageCode: customer?.languageCode,
        pageSize: 1000,
        conceptTypeFilter: 5,
        pageToken,
      };
      // eslint-disable-next-line no-await-in-loop
      const response = await ConceptApi.listConcepts(request);
      moments.push(...response.concepts.filter((concept) => concept.cluster));
      if (!response.nextPageToken) {
        break;
      }
      pageToken = response.nextPageToken;
    }

    if (moments?.length > 0) {
      setMoments(moments);
    }
    toggleLoading(false);
  }, [customerProfile]);

  useEffect(() => {
    fetchMoments();
    setMoments([]);
  }, [customerProfile, refetchVal]);

  const refetch = useCallback(() => {
    toggleRefetch((prev) => !prev);
  }, []);

  return [moments, isLoading, refetch];
};
