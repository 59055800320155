import { createSlice } from '@reduxjs/toolkit';
import {
  taskApiFailed,
  taskApiPending,
  fetchDerivedLabelingTasksSuccess,
  updateAbstractTaskSuccess,
  fetchLabelingTaskDataFailed,
  fetchLabelingTaskDataPending,
  fetchLabelingTaskDataSuccess,
  createLabelingTaskSuccess,
  refreshDerivedLabelingTasksPending,
  refreshDerivedLabelingTasksSuccess,
  clearLabelingTaskReducer,
  fetchDerivedLabelingTasksPending,
  fetchDerivedLabelingTasksFailed,
  completeLabelingTaskSuccess,
  updateGhostAnnotationsReducer,
  upsertTemporalAnnotationsFailed,
  refreshDerivedLabelingTasksFailed,
  fetchLabelingTaskAnnotationsSuccess,
  upsertTemporalAnnotationsSuccess,
  fetchLabelingTaskItemsSuccess,
  selectNewBatchSuccess,
  removeTemporalAnnotationsSuccess,
  fetchConversationPredictionsSuccess,
} from './reducers';
import {
  fetchLabelingTasks,
  createLabelingTask,
  updateAbstractTask,
  fetchLabelingTaskData,
  completeLabelingTask,
  upsertTemporalAnnotation,
  refreshLabelingTasks,
  selectNewBatch,
  fetchLabelingTaskAnnotations,
  fetchLabelingTaskItems,
  removeTemporalAnnotation,
  fetchConversationPredictions,
} from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Task slice. */
export const labelingTaskSlice = createSlice({
  name: 'labelingTask',
  initialState: INITIAL_STATE,
  reducers: {
    clearLabelingTask: clearLabelingTaskReducer,
    updateGhostAnnotations: updateGhostAnnotationsReducer,
  },
  extraReducers: (builder) =>
    builder.addCase(fetchLabelingTasks.pending, fetchDerivedLabelingTasksPending)
      .addCase(fetchLabelingTasks.rejected, fetchDerivedLabelingTasksFailed)
      .addCase(fetchLabelingTasks.fulfilled, fetchDerivedLabelingTasksSuccess)
      .addCase(createLabelingTask.pending, taskApiPending)
      .addCase(createLabelingTask.rejected, taskApiFailed)
      .addCase(createLabelingTask.fulfilled, createLabelingTaskSuccess)
      .addCase(updateAbstractTask.pending, taskApiPending)
      .addCase(updateAbstractTask.rejected, taskApiFailed)
      .addCase(updateAbstractTask.fulfilled, updateAbstractTaskSuccess)
      .addCase(fetchLabelingTaskData.pending, fetchLabelingTaskDataPending)
      .addCase(fetchLabelingTaskData.rejected, fetchLabelingTaskDataFailed)
      .addCase(fetchLabelingTaskData.fulfilled, fetchLabelingTaskDataSuccess)
      .addCase(fetchLabelingTaskItems.pending, taskApiPending)
      .addCase(fetchLabelingTaskItems.rejected, taskApiFailed)
      .addCase(fetchLabelingTaskItems.fulfilled, fetchLabelingTaskItemsSuccess)
      .addCase(fetchLabelingTaskAnnotations.pending, fetchLabelingTaskDataPending)
      .addCase(fetchLabelingTaskAnnotations.rejected, fetchLabelingTaskDataFailed)
      .addCase(fetchLabelingTaskAnnotations.fulfilled, fetchLabelingTaskAnnotationsSuccess)
      .addCase(completeLabelingTask.pending, taskApiPending)
      .addCase(completeLabelingTask.rejected, taskApiFailed)
      .addCase(completeLabelingTask.fulfilled, completeLabelingTaskSuccess)
      .addCase(upsertTemporalAnnotation.pending, taskApiPending)
      .addCase(upsertTemporalAnnotation.rejected, upsertTemporalAnnotationsFailed)
      .addCase(upsertTemporalAnnotation.fulfilled, upsertTemporalAnnotationsSuccess)
      .addCase(removeTemporalAnnotation.pending, taskApiPending)
      .addCase(removeTemporalAnnotation.rejected, upsertTemporalAnnotationsFailed)
      .addCase(removeTemporalAnnotation.fulfilled, removeTemporalAnnotationsSuccess)
      .addCase(refreshLabelingTasks.rejected, refreshDerivedLabelingTasksFailed)
      .addCase(refreshLabelingTasks.pending, refreshDerivedLabelingTasksPending)
      .addCase(refreshLabelingTasks.fulfilled, refreshDerivedLabelingTasksSuccess)
      .addCase(selectNewBatch.pending, taskApiPending)
      .addCase(selectNewBatch.rejected, taskApiFailed)
      .addCase(selectNewBatch.fulfilled, selectNewBatchSuccess)
      .addCase(fetchConversationPredictions.pending, taskApiPending)
      .addCase(fetchConversationPredictions.rejected, taskApiFailed)
      .addCase(fetchConversationPredictions.fulfilled, fetchConversationPredictionsSuccess),
});

/** Actions for sync reducers. */
export const { clearLabelingTask, updateGhostAnnotations } = labelingTaskSlice.actions;
