import React from 'react';
import { Button, Menu } from '@mantine/core';
import { ChevronDown } from 'components/Sidebar/icons';

interface StepperMenuInterface {
  value: string;
  data: {
    value: string;
    label: string;
  }[];
  onItemClick: (setId: string) => void;
}

export const StepperMenu = ({ value, data, onItemClick }: StepperMenuInterface) => {
  const activeItem = data.find((item) => item.value === value);
  return (
    <Menu position="top-end">
      <Menu.Target>
        <Button variant="light" compact leftIcon={<ChevronDown />}>{activeItem.label}</Button>
      </Menu.Target>
      <Menu.Dropdown>
        {data.map((item) => (
          <Menu.Item key={item.value} onClick={() => onItemClick(item.value)} >{item.label}</Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
