import ErrorMessage from 'components/ErrorMessage';
import React from 'react';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
  message: string;
}

// https://docs.sentry.io/platforms/javascript/react/
export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error?.message || 'Unknown error' };
  }

  render() {
    const { children } = this.props;
    const { hasError, message } = this.state;

    if (hasError) {
      return (
        <ErrorMessage message={message} />
      );
    }

    return children;
  }
}
