import React from 'react';
import { Divider, NumberInput, Radio, Stack, Text, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { QATaskOption } from './useQAConfigForm';

export const QATaskSetup = ({ configForm }) => {
  const getQAOptions = (taskOptionValue: QATaskOption) => {
    switch (taskOptionValue) {
      case QATaskOption.SINGLE:
        return (
          <>
            <DatePickerInput
              type="range"
              label="QA conversations from this date range"
              placeholder="Choose Dates"
              {...configForm.getInputProps('qaConversationDates')}
            />
            <NumberInput
              label="# of conversations"
              placeholder="Max # of convos"
              {...configForm.getInputProps('qaConversationCount')}
            />
          </>
        );
      case QATaskOption.COMPARE:
        return (
          <>
            <TextInput
              label="Comparison Model"
              placeholder="Use model URL"
              {...configForm.getInputProps('qaComparisonModelUrl')}
            />
            <DatePickerInput
              type="range"
              label="QA conversations from this date range"
              placeholder="Choose Dates"
              {...configForm.getInputProps('qaConversationDates')}
            />
            <NumberInput
              label="# of conversations"
              placeholder="Max # of convos"
              min={0}
              {...configForm.getInputProps('qaConversationCount')}
            />
          </>
        );
      case QATaskOption.NO_QA:
        return null;
      default:
        return null;
    }
  };
  return (
    <Stack>
      <Divider />
      <Text>QA task set up</Text>
      <Radio.Group
        label="QA task options"
        orientation="vertical"
        {...configForm.getInputProps('taskOption')}
      >
        <Radio label="QA this model only" value={QATaskOption.SINGLE} />
        <Radio label="Compare this model with another model" value={QATaskOption.COMPARE} />
        <Radio label="No QA needed" value={QATaskOption.NO_QA} />
      </Radio.Group>
      {getQAOptions(configForm.values.taskOption)}
    </Stack>

  );
};
