import { BinaryValueValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Button } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import CopyableValue from 'components/CopyableValue';
import Loading from 'components/Loading';
import { useSelector } from 'hooks/reduxHooks';
import { formatLabelValue } from 'pages/LabelsReview';
import React from 'react';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import styles from '../styles.module.scss';

const LABEL_BUTTON_STYLES_MAP = new Map<BinaryValueValue, string>(
  [
    [BinaryValueValue.VALUE_POSITIVE, styles.positive],
    [BinaryValueValue.VALUE_NEGATIVE, styles.negative],
    [BinaryValueValue.VALUE_SKIP, styles.skipped],
    [BinaryValueValue.VALUE_FLAG_FOR_REVIEW, styles.flagged],
  ],
);

interface UtteranceIntentPredictionTableProps {
  searchTerm: string;
  taskId: string;
}

export default function UtteranceIntentPredictionTable({ searchTerm, taskId }: UtteranceIntentPredictionTableProps) {
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const perMessageColumns: ColumnsType<SearchAnnotationsResponseAnnotationBundle> = [
    {
      title: 'Chat ID',
      width: 120,
      dataIndex: 'chatId',
      key: 'chatId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2ConversationId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Message ID',
      width: 120,
      dataIndex: 'messageId',
      key: 'messageId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2MessageId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Message',
      dataIndex: ['textMessage'],
      key: 'message',
    },
    {
      title: 'Prediction',
      dataIndex: ['annotation', 'value', 'binaryValue', 'value'],
      key: 'prediction',
      render: (value, record) => (
        <div>
          <Button
            className={classNames([styles.labelButton, LABEL_BUTTON_STYLES_MAP.get(BinaryValueValue.VALUE_POSITIVE)])}
          >
            Positive
          </Button>
        </div>
      ),
    },
    {
      title: 'QA label',
      dataIndex: ['annotation', 'value', 'binaryValue', 'value'],
      key: 'qalabel',
      render: (value, record) => (
        <div>
          <Button
            className={classNames([styles.labelButton, LABEL_BUTTON_STYLES_MAP.get(value)])}
          >
            {formatLabelValue(value)}
          </Button>
        </div>
      ),
    },
    {
      title: 'Comments',
      dataIndex: ['annotation', 'value', 'binaryValue', 'note'],
      width: '20%',
      key: 'comment',
      render: (value, record) => {
        if (record.annotation.value.binaryValue.value !== BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
          return <span className={styles.missingData}>N/A</span>;
        }

        return value;
      },
    },
  ];

  return (
    <Table
      rowKey={(row) => row.annotation.name}
      loading={{
        spinning: taskApiStatus === 'loading',
        indicator: <Loading />,
      }}
      className={styles.summaryTable}
      columns={perMessageColumns}
      dataSource={annotationBundles}
    />
  );
}
