import React from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import MultiTags from 'components/MultiTags';
import ConceptTag from 'components/ConceptTag';
import MessageBubble from 'pages/Conversations/ChatWindow/Message';
import LetterAvatar from 'pages/Conversations/LetterAvatar';
import { Message } from 'types';
import { ConceptTagColor } from 'components/ConceptTag/ConceptTag';
import ReactJson from 'react-json-view';
import { Tooltip } from 'antd';
import { SimulatorTableRow, Prediction } from './rowTypes';
import styles from './SimulatorResultTable.module.scss';

interface Props {
  tableData: SimulatorTableRow[];
  loading?: boolean;
}

const getColor = (role: 'added' | 'removed' | 'same'): ConceptTagColor => {
  switch (role) {
    case 'added':
      return 'green';
    case 'removed':
      return 'red';
    case 'same':
      return 'blue';
    default:
      return null;
  }
};

export const renderDetections = (detections) => (
  detections.map((detection: Prediction) => {
    // get difference if old confidence is available
    const difference = detection.oldConfidence ? detection.oldConfidence - detection.confidence : null;
    // create span with differnence and up/down arrows, green if positive, red if negative
    const differenceSpan = difference ? <span style={difference > 0 ? { color: 'red' } : { color: 'green' }}>({difference > 0 ? '▼' : '▲'} {difference.toFixed(2)})</span> : null;

    return (
      <div key={detection.intent}>
        <div>
          <ConceptTag role={detection.role} value={detection.intent} color={getColor(detection.changeOfType)} />
        </div>
        {detection.confidence && (
        <div style={{ color: '#757575', display: 'flex', columnGap: 10 }}>
          {detection.confidence.toFixed(2)}
          {detection.oldConfidence && differenceSpan}
        </div>
        )}
      </div>
    );
  })
);

const columns: ColumnsType<SimulatorTableRow> = [
  {
    title: 'Utterance',
    dataIndex: 'utterance',
    key: 'utterance',
    width: '400px',
    render: (message: Message) => <MessageBubble message={message} adornment={() => <LetterAvatar role={message.speakerRole} />} />,
  },
  {
    title: 'Detections',
    dataIndex: 'detections',
    key: 'detections',
    width: '200px',
    render: renderDetections,
  },
  {
    title: 'Next intent',
    dataIndex: 'coaching',
    key: 'coaching',
    width: '200px',
    render: (coaching) => (
      <div
        className={styles.projectStatusPicker}
        onClick={(event) => event.stopPropagation()}
      >
        {coaching?.length > 0
          && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {coaching?.map((item) => (
                  <div key={item.intent} style={{ marginBottom: '8px' }}><MultiTags tags={[item.intent]} color={getColor(item.changeOfType)} /></div>
                ))}
            </div>
          )}
      </div>
    ),
  },
  {
    title: () => (
      <Tooltip title="You will only see debug output in this column on turns where an intent has been detected in context (in recent turns under “Detections”) and is a trigger for some dialogue policy rule.">
        <span>Policy</span>
      </Tooltip>
    ),
    dataIndex: 'policy',
    key: 'policy',
    width: '300px',
    render: (policy) => {
      const getBackgroundColor = (changeOfType) => {
        switch (changeOfType) {
          case 'added':
            return '#D3F9D8';
          case 'removed':
            return '#FADBD8';
          case 'same':
            return '#DBE4FF';
          default:
            return null;
        }
      };

      return (
        <>
          {policy.map((p, i) => (
            <ReactJson
              style={{
                background: getBackgroundColor(p.changeOfType),
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              src={p}
              collapsed
            />
          ))}
        </>
      );
    },
  },
];

export function SimulatorResultTable({
  tableData,
  loading,
}: Props) {
  return (
    <Table
      columns={columns}
      className={styles.simulatorResultTable}
      dataSource={tableData}
      rowKey={(record) => record.utterance?.id}
      scroll={{ y: '70vh', x: '800px' }}
      pagination={false}
      loading={loading}
    />
  );
}
