import React, { useState } from 'react';
import { Button, Checkbox, Group, Modal, Stack, Text } from '@mantine/core';

interface ConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { onConfirm, isOpen, onClose } = props;
  const [isCheckboxChecked, toggleCheckbox] = useState(false);
  return (
    <Modal
      size={388}
      opened={isOpen}
      centered
      title="Run autolabeling"
      onClose={onClose}
    >
      <Stack spacing="xl">
        <Text size="sm">Did you review the samples under the Preview tab and mark False Positive or False Negative?</Text>
        <Checkbox
          label="Yes, I have reviewed and am confident with the results shown in the Preview tab"
          checked={isCheckboxChecked}
          onChange={() => toggleCheckbox(!isCheckboxChecked)}
        />
        <div>
          <Text size="sm">{'This can take up to an hour. You’ll be notified in the '}
            <Text
              style={{ display: 'inline' }}
              color="blue"
              component="a"
              href="https://crestalabs.slack.com/archives/C0385UQNMQD"
              target="_blank"
            >
              #studio-notification
            </Text>
            {' slack channel once it’s finished.'}
          </Text>
        </div>
        <Group position="right">
          <Button variant="subtle" onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} disabled={!isCheckboxChecked}>Continue</Button>
        </Group>
      </Stack>
    </Modal>
  );
};
