import { Box, Text, createStyles } from '@mantine/core';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { SimulatorMessage } from '.';

export default function VAChatCard({
  date,
  onClick,
  active,
  messages,
  promptName,
}: {
  date: Date;
  onClick: () => void;
  active?: boolean;
  messages: SimulatorMessage[],
  promptName: string,
}) {
  const lastMessage = messages[messages.length - 1];
  const title = useMemo(() => {
    let lastMessageExcerpt = lastMessage?.text.slice(0, 30);
    if (lastMessage?.text.length > 30) {
      lastMessageExcerpt += '...';
    }
    return lastMessageExcerpt || `New chat with ${promptName}`;
  }, [lastMessage?.text, promptName]);

  const useStyles = createStyles((theme) => ({
    card: {
      padding: theme.spacing.md,
      color: theme.colors.gray[7],
      borderBottom: `1px solid ${theme.colors.gray[0]}`,
      cursor: 'pointer',
      fontWeight: 500,
      display: 'block',
      backgroundColor: active ? theme.colors.blue[0] : 'transparent',
      '&:hover': {
        backgroundColor: theme.colors.blue[0],
      },
    },
  }));
  const { classes } = useStyles();

  return (
    <Box className={classes.card} onClick={onClick}>
      <Text size="md">{title}</Text>
      <Text size="sm" color="dimmed">{dayjs(date).toNow()}</Text>
    </Box>
  );
}
