import React from 'react';

export const EnterIcon = () => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50001 3C9.3674 3 9.24022 3.05268 9.14646 3.14645C9.05269 3.24021 9.00001 3.36739 9.00001 3.5V5.5C9.00001 5.63261 8.94733 5.75979 8.85356 5.85355C8.7598 5.94732 8.63262 6 8.50001 6H3.70501L4.35501 5.355C4.44916 5.26085 4.50206 5.13315 4.50206 5C4.50206 4.86685 4.44916 4.73915 4.35501 4.645C4.26086 4.55085 4.13316 4.49795 4.00001 4.49795C3.86686 4.49795 3.73916 4.55085 3.64501 4.645L2.14501 6.145C2.09949 6.19255 2.06381 6.24862 2.04001 6.31C1.99 6.43173 1.99 6.56827 2.04001 6.69C2.06381 6.75138 2.09949 6.80745 2.14501 6.855L3.64501 8.355C3.69149 8.40186 3.74679 8.43906 3.80772 8.46445C3.86865 8.48983 3.934 8.5029 4.00001 8.5029C4.06602 8.5029 4.13137 8.48983 4.1923 8.46445C4.25323 8.43906 4.30853 8.40186 4.35501 8.355C4.40187 8.30852 4.43907 8.25322 4.46446 8.19229C4.48984 8.13136 4.50291 8.06601 4.50291 8C4.50291 7.93399 4.48984 7.86864 4.46446 7.80771C4.43907 7.74678 4.40187 7.69148 4.35501 7.645L3.70501 7H8.50001C8.89783 7 9.27937 6.84196 9.56067 6.56066C9.84197 6.27936 10 5.89782 10 5.5V3.5C10 3.36739 9.94733 3.24021 9.85356 3.14645C9.7598 3.05268 9.63262 3 9.50001 3Z"
      fill="#21212130"
    />
  </svg>
);
