import React from 'react';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { LabelingTaskDescriptor, Message, SelectionMethod, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { selectDebuggingMode } from 'store/app/selectors';
import { useSelector } from 'hooks/reduxHooks';
import CopyableValue from 'components/CopyableValue';
import { Group, Space, Text, Divider, useMantineTheme, Title } from '@mantine/core';
import ConceptTag from 'components/ConceptTag';
import { clipText } from 'common/clipText';
import styles from './styles.module.scss';

interface LabelingViewProps {
  labelingTaskType: UserLabelingTaskType;
  getConceptTitle: (conceptId: string) => string;
  labelingTaskConcepts: Concept[];
  currentMessage: Message;
  taskDescriptor: LabelingTaskDescriptor;
}

export default function LabelingViewHeader({
  labelingTaskType,
  getConceptTitle,
  labelingTaskConcepts,
  currentMessage,
  taskDescriptor,
}: LabelingViewProps) {
  const debuggingMode = useSelector<boolean>(selectDebuggingMode);
  const messageId = currentMessage?.v2MessageId;
  const conversationId = currentMessage?.v2ConversationId;
  const selectionMethod = taskDescriptor?.selectionInstruction.selectionMethod;
  const theme = useMantineTheme();

  const renderHeader = () => {
    switch (labelingTaskType) {
      case UserLabelingTaskType.QA_SUMMARIZATION: {
        const { modelUris } = taskDescriptor?.selectionInstruction.summarizationInstruction;
        const models = modelUris.filter((model) => model);
        return (
          <div>
            <h1>Rate predicted summarization for each model</h1>
            <Group align="middle">
              <>
                {models.map((modelUri, i) => {
                  if (!modelUri) return null;
                  return (
                    <Group key={modelUri} spacing="xs" align="middle">
                      <Text>Model {i + 1}</Text><CopyableValue copiedValue={modelUri} displayValue={modelUri}/>
                      {i < models.length - 1 && (
                      <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
                      )}
                    </Group>
                  );
                })}
              </>
            </Group>
          </div>
        );
      }
      case UserLabelingTaskType.LABELING_SUMMARIZATION: {
        return (
          <div>
            <h1>Read the conversation below and answer the questions</h1>
            <Group><span>Summarization:</span>{labelingTaskConcepts.map((concept) => <ConceptTag key={concept.name} value={concept.conceptTitle}/>)}</Group>
          </div>
        );
      }
      case UserLabelingTaskType.QA_SUGGESTIONS: {
        const { modelUri, modelUri2 } = taskDescriptor?.selectionInstruction.suggestionQaRandom;
        const models = [modelUri, modelUri2].filter((model) => model);
        return (
          <div>
            <h1>Suggestions Model QA</h1>
            <Group align="middle" mt="sm" noWrap>
              <>
                {models.map((modelUri, i) => {
                  if (!modelUri) return null;
                  return (
                    <Group key={modelUri} noWrap spacing="xs" align="middle">
                      <Text>Model&nbsp;{i + 1}</Text><CopyableValue copiedValue={modelUri} displayValue={clipText(modelUri, 60)}/>
                      {i < models.length - 1 && (
                        <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
                      )}
                    </Group>
                  );
                })}
              </>
            </Group>
          </div>
        );
      }
      case UserLabelingTaskType.LABELING_ENTITY: {
        return (
          <h1>Highlight all entities found in the utterance</h1>
        );
      }
      case UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING: {
        return (
          <div>
            <h1>Which intents does the <span className={styles.highlightedText}>highlighted message</span> contain? </h1>
            <p>
              Read all messages and note the positive or negative intents
            </p>
          </div>
        );
      }
      case UserLabelingTaskType.QA_POLICY: {
        return (
          <div>
            <h1>Please mark if the predicted next action <br/>is correct for the <span className={styles.highlightedText}>highlighted message</span></h1>
          </div>
        );
      }
      case UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION: {
        return <Title order={2}>Misclassification QA</Title>;
      }
      default: {
        return (
          <>
            {[
              SelectionMethod.INTENT_PREDICTION_QA_RANDOM,
              SelectionMethod.INTENT_PREDICTION_RECALL_QA,
            ].includes(selectionMethod) ? (
              <>
                <h1>Is the following intent prediction correct?</h1>
                <span className={styles.conceptTitle}>{getConceptTitle(currentMessage?.conceptIds[0])}</span>
              </>
              ) : (
                <>
                  <h1>Does the <span className={styles.highlightedText}>highlighted message</span> contain</h1>
                  <span className={styles.conceptTitle}>{labelingTaskConcepts[0]?.conceptTitle}</span>
                </>
              )}
          </>
        );
      }
    }
  };

  return (
    <>
      <div className={styles.labelingHeader}>
        {renderHeader()}
      </div>
      {debuggingMode && (
        <>
          <Space h="sm"/>
          <b>Debug panel:</b>
          <Group>
            <span>Conversation ID</span>
            <CopyableValue copiedValue={conversationId} displayValue={conversationId}/>
            <span>Message ID</span>
            <CopyableValue copiedValue={messageId} displayValue={messageId}/>
          </Group>
        </>
      )}
    </>
  );
}
