import React from 'react';

export const CalendarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6666 2.66671H11.3333V2.00004C11.3333 1.82323 11.263 1.65366 11.138 1.52864C11.013 1.40361 10.8434 1.33337 10.6666 1.33337C10.4898 1.33337 10.3202 1.40361 10.1952 1.52864C10.0702 1.65366 9.99992 1.82323 9.99992 2.00004V2.66671H5.99992V2.00004C5.99992 1.82323 5.92968 1.65366 5.80466 1.52864C5.67963 1.40361 5.51006 1.33337 5.33325 1.33337C5.15644 1.33337 4.98687 1.40361 4.86185 1.52864C4.73682 1.65366 4.66659 1.82323 4.66659 2.00004V2.66671H3.33325C2.80282 2.66671 2.29411 2.87742 1.91904 3.25249C1.54397 3.62757 1.33325 4.13627 1.33325 4.66671V12.6667C1.33325 13.1971 1.54397 13.7058 1.91904 14.0809C2.29411 14.456 2.80282 14.6667 3.33325 14.6667H12.6666C13.197 14.6667 13.7057 14.456 14.0808 14.0809C14.4559 13.7058 14.6666 13.1971 14.6666 12.6667V4.66671C14.6666 4.13627 14.4559 3.62757 14.0808 3.25249C13.7057 2.87742 13.197 2.66671 12.6666 2.66671V2.66671ZM13.3333 12.6667C13.3333 12.8435 13.263 13.0131 13.138 13.1381C13.013 13.2631 12.8434 13.3334 12.6666 13.3334H3.33325C3.15644 13.3334 2.98687 13.2631 2.86185 13.1381C2.73682 13.0131 2.66659 12.8435 2.66659 12.6667V8.00004H13.3333V12.6667ZM13.3333 6.66671H2.66659V4.66671C2.66659 4.4899 2.73682 4.32033 2.86185 4.1953C2.98687 4.07028 3.15644 4.00004 3.33325 4.00004H4.66659V4.66671C4.66659 4.84352 4.73682 5.01309 4.86185 5.13811C4.98687 5.26314 5.15644 5.33337 5.33325 5.33337C5.51006 5.33337 5.67963 5.26314 5.80466 5.13811C5.92968 5.01309 5.99992 4.84352 5.99992 4.66671V4.00004H9.99992V4.66671C9.99992 4.84352 10.0702 5.01309 10.1952 5.13811C10.3202 5.26314 10.4898 5.33337 10.6666 5.33337C10.8434 5.33337 11.013 5.26314 11.138 5.13811C11.263 5.01309 11.3333 4.84352 11.3333 4.66671V4.00004H12.6666C12.8434 4.00004 13.013 4.07028 13.138 4.1953C13.263 4.32033 13.3333 4.4899 13.3333 4.66671V6.66671Z" fill="#757575"/>
  </svg>
);

export const TimeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_45_2425)">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.00008 7.99996C2.00008 4.68625 4.68637 1.99996 8.00008 1.99996C11.3138 1.99996 14.0001 4.68625 14.0001 7.99996C14.0001 11.3137 11.3138 14 8.00008 14C4.68637 14 2.00008 11.3137 2.00008 7.99996ZM8.00008 0.666626C3.94999 0.666626 0.666748 3.94987 0.666748 7.99996C0.666748 12.05 3.94999 15.3333 8.00008 15.3333C12.0502 15.3333 15.3334 12.05 15.3334 7.99996C15.3334 3.94987 12.0502 0.666626 8.00008 0.666626ZM8.66675 3.99996C8.66675 3.63177 8.36827 3.33329 8.00008 3.33329C7.63189 3.33329 7.33342 3.63177 7.33342 3.99996V7.99996C7.33342 8.25247 7.47608 8.48332 7.70194 8.59624L10.3686 9.92958C10.6979 10.0942 11.0984 9.96075 11.263 9.63143C11.4277 9.30212 11.2942 8.90167 10.9649 8.73701L8.66675 7.58794V3.99996Z" fill="#757575"/>
    </g>
    <defs>
      <clipPath id="clip0_45_2425">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
