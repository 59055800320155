import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Text,
  createStyles,
  Box,
  useMantineTheme,
  SelectItem,
  Group,
  Divider,
} from '@mantine/core';
import LetterAvatar from 'components/LetterAvatar';

interface CascaderProps {
  options: SelectItem[];
  value: SelectItem;
  setValue: (value: SelectItem) => void;
}

export function Cascader({
  options,
  value,
  setValue,
}: CascaderProps) {
  const theme = useMantineTheme();
  const [expandedLevels, setExpandedLevels] = useState([]);

  // Reset expanded options whene options change
  useEffect(() => {
    setExpandedLevels([]);
  }, [options]);

  const useStyles = createStyles((theme) => ({
    textCustomer: {
      color: theme.colors.gray[7],
      fontWeight: 500,
      display: 'block',
      '&:hover': {
        color: theme.colors.blue[6],
      },
    },
    textItem: {
      color: theme.colors.gray[4],
      display: 'block',
      '&:hover': {
        color: theme.colors.blue[6],
      },
    },
    clickableItem: {
      cursor: 'pointer',
      marginRight: theme.spacing.lg,
      width: '100%',
    },
    firstLevelItem: {
      width: '100%',
      position: 'relative',
    },
    nestedItem: {
      position: 'relative',
      width: '100%',
    },
  }));
  const { classes } = useStyles();

  const handleExpand = (levelIndex) => {
    setExpandedLevels((prevExpandedLevels) => {
      if (prevExpandedLevels.includes(levelIndex)) {
        return prevExpandedLevels.filter((index) => index !== levelIndex);
      } else {
        return [...prevExpandedLevels, levelIndex];
      }
    });
  };

  const handleSelect = (option: SelectItem) => {
    setValue(option);
  };

  const renderLevel = (level: SelectItem, levelIndex: string) => {
    const isLevelExpanded = level.isExpanded || (expandedLevels.includes(levelIndex));
    const nestedLevel = (levelIndex.match(/-/g) || []).length;

    return (
      <Box
        key={levelIndex}
        className={nestedLevel === 0 ? classes.firstLevelItem : classes.nestedItem}
        py={nestedLevel === 0 ? 6 : 2}
        style={{
          marginLeft: nestedLevel !== 0 ? 25 : 0,
        }}
      >
        {isLevelExpanded && (
        <Divider orientation="vertical" color={theme.colors.gray[0]} style={{ position: 'absolute', top: 28, bottom: 12, left: 11 }} />
        )}
        <Group spacing="xs" noWrap pl={nestedLevel !== 0 ? 8 : 0}>
          {nestedLevel === 0 && (
            <LetterAvatar
              styles={{
                width: 22,
                height: 22,
                fontSize: 11,
                zIndex: 100,
              }}
              name={level?.label.replace('-', ' ')}
            />
          )}
          {nestedLevel !== 0 && (
            <Divider orientation="horizontal" w={10} color={theme.colors.gray[0]} style={{ position: 'absolute', top: 14, left: -13 }} />
          )}
          {level.children.length === 0 ? (
            <Box
              className={classes.clickableItem}
              onClick={() => handleSelect(level)}
              style={{
                fontSize: nestedLevel === 0 ? 16 : 14,
              }}
            >
              <Text
                className={nestedLevel === 0 ? classes.textCustomer : classes.textItem}
                style={level.isActive ? { color: theme.colors.blue[6], fontWeight: 500 } : {}}
              >{level?.label}
              </Text>
            </Box>
          ) : (
            <Box
              className={classes.clickableItem}
              onClick={() => {
                // If the level has only 1 child, we can select it directly
                const hasSingleChild = level.children?.length === 1;
                const childHasChildren = level.children?.[0].children?.length;
                if (hasSingleChild && !childHasChildren) {
                  handleSelect(level.children[0]);
                } else {
                  handleExpand(levelIndex);
                }
              }}
              style={{
                fontSize: nestedLevel === 0 ? 16 : 14,
              }}
            >
              <Text
                className={nestedLevel === 0 ? classes.textCustomer : classes.textItem}
                style={level.isActive ? { color: theme.colors.blue[6], fontWeight: 500 } : {}}
              >{level?.label}
              </Text>
            </Box>
          )}
        </Group>

        <Collapse
          transitionDuration={100}
          key={levelIndex}
          in={isLevelExpanded}
        >
          {level.children.map((childLevel, childIndex) =>
            renderLevel(childLevel, `${levelIndex}-${childIndex}`))}
        </Collapse>
      </Box>
    );
  };
  return (
    <>
      {options.map((option, index) => renderLevel(option, `${index}`))}
    </>
  );
}
