import React from 'react';
import { Anchor, ScrollArea, Stack, Text } from '@mantine/core';
import { Category, Preview } from '../../types';
import { PreviewContainer } from './PreviewContainer';
import { PreviewItem, SkeletonPreviewItem } from './PreviewContainer/PreviewItem';
import {
  negativePreviewSkeletons,
  positivePreviewSkeletons,
} from './PreviewContainer/skeletonsConfig';
import { TipSection } from '../components/TipSection';

interface PreviewSectionProps {
  isLoading: boolean;
  positivePreviews: Preview[];
  negativePreviews: Preview[];
  pageSize: number;
  onUpdateExamples: (preview: Preview, category: Category) => void;
  isRefreshSuggestionVisible: boolean;
  onCloseRefreshSuggestion: () => void;
  onTogglePanel: () => void;
}

export const PreviewSection = (props: PreviewSectionProps) => {
  const { isLoading, positivePreviews, negativePreviews, pageSize, onUpdateExamples, isRefreshSuggestionVisible, onCloseRefreshSuggestion, onTogglePanel } = props;

  return (
    <Stack style={{ height: '100%', overflow: 'hidden' }}>
      <TipSection
        title="Multiple edits detected"
        isVisible={isRefreshSuggestionVisible}
        onClose={onCloseRefreshSuggestion}
      >
        <Text size="xs">That was a lot of edits. Please review the examples in the left column and refine from <Anchor component="button" onClick={onTogglePanel}>AI examples</Anchor></Text>
      </TipSection>
      <ScrollArea>
        <PreviewContainer
          pageSize={pageSize}
          category={Category.POSITIVE}
          subtitle="Please review the following and mark any as False Positive"
        >
          {isLoading
            ? positivePreviewSkeletons.map((skeleton) => (
              <SkeletonPreviewItem key={skeleton.key} skeleton={skeleton} />
            ))
            : positivePreviews.map((preview) => (
              <PreviewItem
                key={preview.name}
                agent={preview.agent}
                date={preview.date}
                message={preview.message}
                speaker={preview.speaker}
                actionBtnProps={{
                  onClick: () => {
                    onUpdateExamples(preview, Category.NEGATIVE);
                  },
                  buttonText: 'False Positive',
                  hintText: 'Mark as Negative',
                  activeColor: 'green',
                }}
              />
            ))}
        </PreviewContainer>
        <PreviewContainer
          pageSize={pageSize}
          category={Category.NEGATIVE}
          subtitle="Please review the following and mark any as False Negative"
        >
          {isLoading
            ? negativePreviewSkeletons.map((skeleton) => (
              <SkeletonPreviewItem key={skeleton.key} skeleton={skeleton} />
            ))
            : negativePreviews.map((preview) => (
              <PreviewItem
                key={preview.name}
                agent={preview.agent}
                date={preview.date}
                message={preview.message}
                speaker={preview.speaker}
                actionBtnProps={{
                  onClick: () => {
                    onUpdateExamples(preview, Category.POSITIVE);
                  },
                  buttonText: 'False Negative',
                  hintText: 'Mark as Positive',
                  activeColor: 'red',
                }}
              />
            ))}
        </PreviewContainer>
      </ScrollArea>
    </Stack>
  );
};
