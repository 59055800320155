import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Annotation, BinaryValue, BinaryValueValue, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Popover, Tooltip } from 'antd';
import { useHotkeys } from 'hooks/useHotkeys';
import { Concept, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import LabelingUnsurePopover from 'components/LabelingUnsurePopover';
import SearchInput from 'components/SearchInput';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { cloneDeep } from 'lodash';
import { getId } from 'common/resourceName';
import classNames from 'classnames';
import { WarningOutlined } from '@ant-design/icons';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import './MultipleBinaryDecision.scss';
import { Button } from '@mantine/core';

interface MultipleBinaryDecisionProps {
  loading?: boolean,
  concepts: Concept[],
  selectedConceptIds: string[],
  setSelectedConceptIds: (ids: string[]) => void,
  message: Message,
  contextShown: MessageRawDataContextShown,
  annotations: Annotation[],
  createAnnotation: (messageRawData: MessageRawData, binaryValue: BinaryValue) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[]) => void;
}

/** Shows all intents that are valid for selected message. This labeling expects more than 1 intent. */
export default function MultipleBinaryDecision({
  loading,
  concepts = [],
  selectedConceptIds,
  setSelectedConceptIds,
  message,
  contextShown,
  annotations = [],
  createAnnotation,
  submitAnnotations,
}: MultipleBinaryDecisionProps) {
  const [unsurePopoverVisible, setUnsurePopoverVisible] = useState(false);
  const [unsureNote, setUnsureNote] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [hotkeyActiveConceptIndex, setHotkeyActiveConceptIndex] = useState<number>();
  const messageValue = annotations.length && annotations[0].value.binaryValue.value;

  const selectedConceptIdsMap = useMemo(() => new Set(selectedConceptIds), [selectedConceptIds]);

  useEffect(() => {
    // Reset note
    const flaggedAnnotation = annotations.find((a) => a.value.binaryValue.value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW);
    if (flaggedAnnotation) {
      setUnsureNote(flaggedAnnotation.value.binaryValue.note);
    } else {
      setUnsureNote('');
    }

    // Reset search and selected concept
    setHotkeyActiveConceptIndex(null);
    setSearchValue('');
  }, [message]);

  // Scroll concepts list when using hotkeys
  useEffect(() => {
    const concepts = document.querySelectorAll('.concepts .list .concept');
    const activeConcept = concepts[hotkeyActiveConceptIndex];
    if (activeConcept) {
      activeConcept.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [hotkeyActiveConceptIndex]);

  useEffect(() => {
    // Reset note
    const flaggedAnnotation = annotations.find((a) => a.value.binaryValue.value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW);
    if (flaggedAnnotation) {
      setUnsureNote(flaggedAnnotation.value.binaryValue.note);
    } else {
      setUnsureNote('');
    }
  }, [message]);

  const unsurePopover = (
    <LabelingUnsurePopover
      note={unsureNote}
      // Workaround to get auto focus working with popover
      key={unsurePopoverVisible ? 'visible' : 'hidden'}
      setNote={setUnsureNote}
      confirmHandler={() => {
        if (unsurePopoverVisible) {
          submitAnnotationsSingleValue(BinaryValueValue.VALUE_FLAG_FOR_REVIEW, unsureNote);
          setUnsurePopoverVisible(false);
          setUnsureNote('');
        }
      }}
      cancelHandler={() => setUnsurePopoverVisible(false)}
    />
  );

  // To determine how to display selected intents
  const isPositiveOrNegative = annotations.some((annotation) =>
    [BinaryValueValue.VALUE_NEGATIVE, BinaryValueValue.VALUE_POSITIVE].includes(annotation.value.binaryValue.value));

  // Add or remove concept
  const handleSelectConcept = (concept: string) => {
    const conceptIndex = selectedConceptIds.indexOf(concept);
    if (conceptIndex === -1) {
      setSelectedConceptIds([...selectedConceptIds, concept]);
    } else {
      const nextSelectedConceptIds = selectedConceptIds.slice();
      nextSelectedConceptIds.splice(conceptIndex, 1);
      setSelectedConceptIds(nextSelectedConceptIds);
    }
  };

  const sortedConcepts = useMemo(() =>
  // Sort alphabetially but selected first
    cloneDeep(concepts).map((concept): [Concept, number] => [concept, selectedConceptIds.indexOf(getId('concept', concept.name))])
      .sort(([a, aIndex], [b, bIndex]) => {
        if (aIndex !== -1 && bIndex !== -1) {
          return a.conceptTitle.localeCompare(b.conceptTitle);
        }
        if (aIndex !== -1 || bIndex !== -1) {
          return bIndex - aIndex;
        }
        return a.conceptTitle.localeCompare(b.conceptTitle);
      }), [concepts, selectedConceptIds]);

  const filteredConcepts = useMemo(() => sortedConcepts.filter(([concept]) => {
    if (searchValue.length > 1) {
      return concept.conceptTitle.includes(searchValue);
    } else {
      return true;
    }
  }), [sortedConcepts, searchValue]);

  // In dense labeling, 1 message can have multiple positive and negative intents
  const confirmHandler = useCallback(() => {
    const annotations: SaveTemporalAnnotationRequestRawDataValueTuple[] = concepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      const annotation = createAnnotation({
        conversationId: message.conversationId,
        messageId: message.messageId,
        v2ConversationId: message.v2ConversationId,
        v2MessageId: message.v2MessageId,
        contextShown,
        spanStart: message.targetSpanStart,
        spanEnd: message.targetSpanEnd,
      }, {
        value: selectedConceptIdsMap.has(conceptId) ? BinaryValueValue.VALUE_POSITIVE : BinaryValueValue.VALUE_NEGATIVE,
        conceptId,
      });
      return annotation;
    });
    submitAnnotations(annotations);
  }, [concepts, message, selectedConceptIds, submitAnnotations, createAnnotation]);

  const submitAnnotationsSingleValue = useCallback((value: BinaryValueValue, note?: string) => {
    const annotations: SaveTemporalAnnotationRequestRawDataValueTuple[] = concepts.map((concept) => {
      const annotation = createAnnotation({
        conversationId: message.conversationId,
        messageId: message.messageId,
        v2ConversationId: message.v2ConversationId,
        v2MessageId: message.v2MessageId,
        contextShown,
        spanStart: message.targetSpanStart,
        spanEnd: message.targetSpanEnd,
      }, {
        value,
        conceptId: getId('concept', concept.name),
        note,
      });
      return annotation;
    });
    submitAnnotations(annotations);
  }, [message, concepts, submitAnnotations, createAnnotation]);

  const upHandler = useCallback(() => {
    setHotkeyActiveConceptIndex(Math.max(hotkeyActiveConceptIndex - 1, 0));
  }, [hotkeyActiveConceptIndex]);

  const downHandler = useCallback(() => {
    if (hotkeyActiveConceptIndex == null || hotkeyActiveConceptIndex > filteredConcepts.length) {
      setHotkeyActiveConceptIndex(0);
    } else {
      setHotkeyActiveConceptIndex(Math.min(hotkeyActiveConceptIndex + 1, filteredConcepts.length - 1));
    }
  }, [hotkeyActiveConceptIndex, filteredConcepts]);

  const enterHandler = useCallback(() => {
    if (hotkeyActiveConceptIndex == null) return;
    const conceptTuple = filteredConcepts[hotkeyActiveConceptIndex];
    const conceptId = getId('concept', conceptTuple[0].name);
    if (conceptTuple[0]) {
      const conceptIndex = selectedConceptIds.findIndex((c) => c === conceptId);
      if (conceptIndex === -1) {
        setSelectedConceptIds([...selectedConceptIds, conceptId]);
      } else {
        const conceptsCopy = selectedConceptIds.slice();
        conceptsCopy.splice(conceptIndex, 1);
        setSelectedConceptIds(conceptsCopy);
      }
    }
  }, [concepts, selectedConceptIds, hotkeyActiveConceptIndex, unsurePopoverVisible]);

  const escapeHandler = useCallback(() => {
    if (hotkeyActiveConceptIndex != null) {
      setHotkeyActiveConceptIndex(null);
    }
    if (unsurePopoverVisible) {
      setUnsurePopoverVisible(false);
    }
  }, [hotkeyActiveConceptIndex]);

  // Hotkeys
  useHotkeys('up', () => upHandler(), { filterPreventDefault: true, enableOnTags: ['INPUT'] }, [upHandler]);
  useHotkeys('down', () => downHandler(), { filterPreventDefault: true, enableOnTags: ['INPUT'] }, [downHandler]);
  useHotkeys('enter', () => enterHandler(), { filterPreventDefault: true, enableOnTags: ['INPUT'] }, [enterHandler]);
  useHotkeys('escape', () => escapeHandler(), { filterPreventDefault: true, enableOnTags: ['INPUT'] }, [escapeHandler]);
  useHotkeys('c', () => confirmHandler(), { filterPreventDefault: true, enableOnTags: ['INPUT'] }, [confirmHandler]);
  useHotkeys('u', () => !loading && setUnsurePopoverVisible(true), {}, [setUnsurePopoverVisible, loading]);
  useHotkeys('s', () => !loading && submitAnnotationsSingleValue(BinaryValueValue.VALUE_SKIP), {}, [submitAnnotationsSingleValue, loading]);

  return (
    <div className="multiple-binary-decision">
      <div className="header">
        <SearchInput
          placeholder="Search intent (F)"
          value={searchValue}
          onChange={setSearchValue}
        />
      </div>
      <div className="concepts">
        <Flipper flipKey={filteredConcepts.map(([concept]) => concept.conceptTitle).join('')}>
          <ul className="list">
            {filteredConcepts.map(([concept], index) => {
              const conceptId = getId('concept', concept.name);
              return (
                <Flipped key={concept.name} flipId={concept.name}>
                  <li
                    key={concept.name}
                    className={classNames([
                      'concept',
                      { deprecated: concept.state === ConceptState.DEPRECATED },
                      { 'hotkey-active': hotkeyActiveConceptIndex === index },
                      { positive: selectedConceptIds.includes(conceptId) },
                      { negative: isPositiveOrNegative && !selectedConceptIds.includes(conceptId) },
                    ])}
                    onClick={() => handleSelectConcept(conceptId)}
                  >
                    <span>{concept.conceptTitle}</span>
                    {concept.state === ConceptState.DEPRECATED && (<Tooltip title="This intent is deprecated"><WarningOutlined /></Tooltip>)}
                  </li>
                </Flipped>
              );
            })}
          </ul>
        </Flipper>
      </div>
      <div className="buttons">
        <div>
          <Button
            variant="outline"
            color="indigo"
            disabled={loading}
            data-active={messageValue === BinaryValueValue.VALUE_POSITIVE}
            onClick={() => confirmHandler()}
          >
            Confirm (C)
          </Button>
        </div>
        <div>
          <Button
            variant="light"
            disabled={loading}
            data-active={messageValue === BinaryValueValue.VALUE_SKIP}
            onClick={() =>
              submitAnnotationsSingleValue(BinaryValueValue.VALUE_SKIP)}
          >
            Skip (S)
          </Button>
          <Popover
            trigger="click"
            placement="right"
            content={unsurePopover}
            open={unsurePopoverVisible}
            onOpenChange={(value) => setUnsurePopoverVisible(value)}
          >
            <Button
              color="purple"
              variant="light"
              disabled={loading}
              data-active={messageValue === BinaryValueValue.VALUE_FLAG_FOR_REVIEW}
            >
              Unsure (U)
            </Button>
          </Popover>
        </div>
      </div>
    </div>
  );
}
