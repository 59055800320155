import React from 'react';
import { Message } from '../../../types';
import MessageBubble from './Message';
import MessageList from './MessageList';
import './MessagesContainer.scss';
import LetterAvatar from '../LetterAvatar';

interface Props {
  conversationStartTime: string;
  messages: Message[];
  selectedMessageIds?: string[];
  focusedMessageId?: string;
  handleMessageClicked?: (message: Message) => void;
  highlight?: boolean;
}

const MessagesContainer = (props: Props) => {
  const {
    conversationStartTime,
    messages,
    handleMessageClicked,
    selectedMessageIds,
    focusedMessageId,
    highlight = true,
  } = props;

  return (
    <div className="messages-container">
      <span className="chat-time">{conversationStartTime}</span>
      <MessageList
          // we set this with query string so we can append this
          // to any URL (route params would have to be sequential)
        focusScrollId={focusedMessageId}
      >
        {messages.map((message) => {
          const messageComponent = (
            <MessageBubble
              scrollId={message.id}
              key={message.id}
              message={message}
              handleClick={() =>
                handleMessageClicked && handleMessageClicked(message)}
              isFocused={message.id === focusedMessageId}
              isSelected={
                    highlight
                    && selectedMessageIds
                    && selectedMessageIds.includes(message.id)
                  }
              adornment={() => <LetterAvatar role={message.speakerRole} />}
            />
          );

          return [messageComponent];
        })}
      </MessageList>
    </div>
  );
};

export default MessagesContainer;
