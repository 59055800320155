/* tslint:disable */
/* eslint-disable */
import { Graph } from 'components/RuleGraph/types';
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import { uniq } from 'lodash';

import './graph.css';

function addToLegend(elem, type, keys, colours, prefix_text, y) {
  if (type === 'circle') {
    elem.selectAll('legend-icon')
      .data(keys)
      .enter()
      .append('circle')
      .attr('cx', 20)
      .attr('cy', (d, i) => y + (i * 25))
      .attr('r', 7)
      .style('fill', colours);
  } else if (type === 'line') {
    elem.selectAll('legend-icon')
      .data(keys)
      .enter()
      .append('line')
      .attr('x1', 13)
      .attr('x2', 27)
      .attr('y1', (d, i) => y + (i * 25) - 1)
      .attr('y2', (d, i) => (y + (i * 25) - 1))
      .style('stroke-width', 2)
      .style('stroke', colours);
  }

  // Add legend labels
  elem.selectAll('legend-labels')
    .data(keys)
    .enter()
    .append('text')
    .attr('class', 'legend-label')
    .attr('x', 40)
    .attr('y', (d, i) => y + (i * 25))
    .style('fill', colours)
    .text((d) => prefix_text + d)
    .attr('text-anchor', 'left')
    .style('alignment-baseline', 'middle');
}

const actionToHtmlClass = (action: string) => action.replace(/\./g, '-');


export function drawRuleGraph(container: HTMLElement, data: Graph) {
  if (!data) return;
  let lastClickedNode;
  const g = new dagreD3.graphlib.Graph().setGraph({
    rankdir: 'TB',
    ranksep: 200,
    nodesep: 75,
    edgesep: 25,
    // ranker: 'longest-path',
    // acyclicer: 'greedy',
  });

  const { edges, nodes } = data;

  const nodeTypes = uniq(nodes.map((node) => node.type));
  const edgeCollections = uniq(edges.map((edge) => edge.collection));

  const nodeColors = d3.scaleOrdinal().domain(nodeTypes).range(d3.schemeDark2);
  const edgeColors = d3.scaleOrdinal().domain(edgeCollections).range(d3.schemeCategory10);

  nodes.forEach(({ name, type, conditions }) => {
    g.setNode(name, {
      labelType: 'html',
      label: `<span class="name">${name} (${conditions.length})</span>`,
      rx: 5,
      ry: 5,
      style: `fill: ${nodeColors(type)}`,
      hovertext: conditions.join('\n'),
    });
  });

  edges.forEach(({ from, to, collection, conditions }) => {
    g.setEdge(from, to, {
      labelType: 'html',
      label: `<span class="edge ${actionToHtmlClass(from)} ${actionToHtmlClass(to)}">&nbsp;</span>`,
      style: `stroke: ${edgeColors[collection]}`,
      arrowheadStyle: `fill: ${edgeColors[collection]}`,
      curve: d3.curveBasis,
      // labelClass: 'edge',
      labeltext: conditions.join('\n'),
      nconditions: conditions.length,
      // labeloffset: 2,
    });
  });

  // eslint-disable-next-line new-cap
  const render = new dagreD3.render();
  // remove old graphs when redrawing
  d3.select(container).select('svg').remove();
  const svg = d3.select(container).append('svg');
  const inner = svg.append('g');
  svg.attr('width', container.clientWidth);
  svg.attr('height', container.clientHeight);

  addToLegend(svg, 'circle', nodeTypes, nodeColors, 'Node type: ', 20);

  let minY = Number.MAX_SAFE_INTEGER;
  let maxY = 0;
  // eslint-disable-next-line func-names
  svg.selectAll('text.legend-label').each(function () {
    // this === matchingNodes[index]);
    // @ts-ignore
    const bbox = this.getBBox();
    minY = Math.min(minY, bbox.y);
    maxY = Math.max(maxY, bbox.y + bbox.height);
  });
  addToLegend(svg, 'line', edgeCollections, edgeColors, 'Collection: ', 50 + (maxY - minY));

  // Set up zoom support
  const zoom = d3.zoom().on('zoom', (e) => { inner.attr('transform', e.transform); });
  // @ts-ignore
  svg.call(zoom);

  // Run the renderer. This is what draws the final graph.
  // @ts-ignore
  render(inner, g);

  const tooltip = d3.select('body')
    .append('div')
    .attr('id', 'tooltip')
    .attr('class', 'tooltip')
    .style('display', 'none');

  inner.selectAll('g.node')
    .attr('data-hovertext', function(v) {
      // @ts-ignore
      return g.node(v).hovertext;
    });

  inner.selectAll('g.edgeLabel')
    // @ts-ignore
    .attr('data-labeltext', function(edge) {
      // @ts-ignore
      return g.edge(edge).labeltext;
    })
    .attr('data-nconditions', function(edge) {
      // @ts-ignore
      return g.edge(edge).nconditions;
    });
  inner.selectAll('g.edgePath')
    // @ts-ignore
    .attr('data-labeltext', function(edge) {
      // @ts-ignore
      return g.edge(edge).labeltext;
    })

  inner.selectAll('g.node')
    .on('click', function (name) {
      if (lastClickedNode) {
        // @ts-ignore
        const prevRect = d3.select(g.node(lastClickedNode).elem).select('rect.label-container');
        prevRect.style('fill', function() { return d3.rgb(prevRect.style('fill')).darker(0.7).hex(); });
        // @ts-ignore
        for (const edge of g.nodeEdges(lastClickedNode)) {
          const el = g.edge(edge).elem;
          d3.select(el).select('path').attr('class', null);
        }
        // @ts-ignore
        inner.selectAll(`g.edgeLabel span.edge.${actionToHtmlClass(lastClickedNode)}`).each(function () {
          // @ts-ignore
          this.textContent = '';
          // @ts-ignore
          const div = this.closest('div');
          div.classList.remove('selected');
          // const foreignObject = div.closest('foreignObject');
          // foreignObject.setAttribute('width', div.clientWidth + 2);
          // foreignObject.setAttribute('height', div.clientHeight + 2);
        });
      }
      if (lastClickedNode !== name) {
        // @ts-ignore
        const rect = d3.select().select('rect.label-container');
        const conditions = d3.select(this).select('span.conditions').style('display', 'inherit');
        // @ts-ignore
        rect.style('fill', function() { return d3.rgb(rect.style('fill')).brighter(0.7).hex(); });
        // @ts-ignore
        for (const edge of g.nodeEdges(name)) {
          const el = g.edge(edge).elem;
          const colorClass = edge.w === name ? 'inbound' : 'outbound';
          d3.select(el)
            .select('path')
            .attr('class', `selected ${colorClass}`);
        }
        // @ts-ignore
        inner.selectAll(`g.edgeLabel span.edge.${actionToHtmlClass(name)}`).each(function () {
          // @ts-ignore
          this.textContent = this.closest('g.edgeLabel').dataset.nconditions;
          // @ts-ignore
          const div = this.closest('div');
          div.classList.add('selected');
          const foreignObject = div.closest('foreignObject');
          foreignObject.setAttribute('width', div.clientWidth + 2);
          foreignObject.setAttribute('height', div.clientHeight + 2);
        });

        lastClickedNode = name;
      } else {
        lastClickedNode = null;
      }
    });

  inner.selectAll('g.edgePath')
    .on('mouseenter', function() {
      return tooltip.style('display', 'block');
    })
    .on('mousemove', function(e){
      // @ts-ignore
      tooltip.text(this.dataset.labeltext || 'No conditions.');

      tooltip
        // @ts-ignore
        .style('top', (event.pageY-10) + 'px')
        // @ts-ignore
        .style('left',(event.pageX+10) + 'px');
    })
    .on('mouseleave', function() {
      return tooltip.style('display', 'none');
    });

  inner.selectAll('g.edgeLabel')
    .on('mouseenter', function() {
      return tooltip.style('display', 'block');
    })
    .on('mousemove', function(e){
      // @ts-ignore
      tooltip.text(this.dataset.labeltext || 'No conditions.');

      tooltip
        // @ts-ignore
        .style('top', (event.pageY-10) + 'px')
        // @ts-ignore
        .style('left',(event.pageX+10) + 'px');
    })
    .on('mouseleave', function() {
      return tooltip.style('display', 'none');
    });

  inner.selectAll('g.node')
    .on('mouseenter', function(){return tooltip.style('display', 'block');})
    .on('mousemove', function(e){
      // @ts-ignore
      tooltip.text(this.dataset.hovertext || 'No conditions.');

      tooltip
        // @ts-ignore
        .style('top', (event.pageY-10) + 'px')
        // @ts-ignore
        .style('left',(event.pageX+10) + 'px');
    })
  inner.selectAll('g.node')
    .on('mouseleave', function(e) {
      return tooltip.style('display', 'none');
    });

  // Center the graph
  const initialScale = 0.75;
  svg.call(
    // @ts-ignore
    zoom.transform,
    d3.zoomIdentity.translate(
      // @ts-ignore
      `${(svg.attr('width') - (g.graph().width * initialScale)) / 2}`,
      20,
    ).scale(initialScale),
  );
}
