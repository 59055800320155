import { useEffect, useState, useCallback } from 'react';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { SimulationTask, SimulationTaskDataSimulationType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { openNotification } from 'components/Notification';
import { SimulationTaskApi } from 'services/simulationTaskApi';
import { getId, getParent } from 'common/resourceName';
import {
  PredictionsDiffMessagePredictionDiff,
  PredictionsDiffConversationPredictionDiff,
} from '@cresta/web-client/dist/cresta/v1/studio/storage/prediction/prediction.pb';
import { SimulatorResultIntentTableRow, Prediction as RowPrediction } from './rowTypes';
import { detailedTypeRoleMap } from './utils';

export const useIntentPredictions = (simulationTask: SimulationTask): [SimulatorResultIntentTableRow[], boolean] => {
  const [data, setData] = useState([]);
  const [isLoading, toggleLoading] = useState(false);

  const getPredictions = useCallback(async () => {
    if (!simulationTask || simulationTask?.abstractTask?.status !== TaskStatus.TASK_COMPLETED) {
      return;
    }
    setData([]);
    toggleLoading(true);
    let preds: Prediction[];
    let predictionsDiff: PredictionsDiffConversationPredictionDiff;
    try {
      const predictionsResponse = await SimulationTaskApi.fetchAllConversationPredictions({
        simulationTask: simulationTask?.name,
      });
      const { predictions, predictionDiff } = predictionsResponse;
      preds = predictions;
      predictionsDiff = predictionDiff || {};
    } catch (err) {
      openNotification('error', 'Failed to Get Result', undefined, err);
      return;
    } finally {
      toggleLoading(false);
    }
    if (simulationTask?.taskData?.simulationType === SimulationTaskDataSimulationType.SINGLE_MODEL) {
      const predictionsMap = new Map<string, Prediction[]>();
      preds.forEach((pred) => {
        const m = pred.moment?.metadata?.messageName || '';
        if (!predictionsMap.has(m)) {
          predictionsMap.set(m, []);
        }
        predictionsMap.get(m).push(pred);
      });
      setData(Array.from(predictionsMap.values()).map((v) => convertPredictionsToTableRow(v)));
    } else {
      setData((predictionsDiff.msgDiffs || []).map((v) => convertPredictionDiffsToTableRow(v)));
    }
  }, [simulationTask]);

  useEffect(() => {
    if (simulationTask?.name) {
      getPredictions();
    }
  }, [simulationTask]);

  return [data, isLoading];
};

function convertPredictionDiffsToTableRow(predictionDiffs: PredictionsDiffMessagePredictionDiff): SimulatorResultIntentTableRow {
  const messageId = getId('conversationMessage', predictionDiffs.name || '');
  const parent = getParent('conversationMessage', predictionDiffs.name || '');
  const conversationId = getId('conversation', parent);
  const detections: RowPrediction[] = [];
  predictionDiffs?.predsInBoth.forEach((pred) => {
    const detection: RowPrediction = {
      role: detailedTypeRoleMap.get(pred.prediction.momentDetailedType) || null,
      intent: pred.prediction.taxonomy,
      confidence: pred.newConfidence,
      oldConfidence: pred.oldConfidence,
      changeOfType: 'same',
    };
    detections.push(detection);
  });

  predictionDiffs?.predsOnlyInNew.forEach((pred) => {
    const detection: RowPrediction = {
      role: detailedTypeRoleMap.get(pred.prediction.momentDetailedType) || null,
      intent: pred.prediction.taxonomy,
      confidence: pred.newConfidence,
      oldConfidence: pred.oldConfidence,
      changeOfType: 'added',
    };
    detections.push(detection);
  });

  predictionDiffs?.predsOnlyInOld.forEach((pred) => {
    const detection: RowPrediction = {
      role: detailedTypeRoleMap.get(pred.prediction.momentDetailedType) || null,
      intent: pred.prediction.taxonomy,
      confidence: pred.newConfidence,
      oldConfidence: pred.oldConfidence,
      changeOfType: 'removed',
    };
    detections.push(detection);
  });
  return {
    id: predictionDiffs.name || '',
    conversationId,
    messageId,
    utterance: predictionDiffs.message || '',
    detections,
  };
}

function convertPredictionsToTableRow(predictions: Prediction[]): SimulatorResultIntentTableRow {
  if (!predictions.length) {
    return {
      id: '',
      conversationId: '',
      messageId: '',
      detections: [],
      utterance: '',
    };
  }
  const messageId = getId('conversationMessage', predictions[0].moment?.metadata?.messageName || '');
  const parent = getParent('conversationMessage', predictions[0].moment?.metadata?.messageName || '');
  const conversationId = getId('conversation', parent);
  const cvt = (pred: Prediction): RowPrediction => ({
    role: detailedTypeRoleMap.get(pred.moment.detailedType) || null,
    intent: pred.output,
    confidence: pred.metadata?.confidence,
  });
  const detections: RowPrediction[] = predictions.map(cvt);
  return {
    id: predictions[0].moment?.metadata?.messageName || '',
    messageId,
    utterance: predictions[0].moment?.intentPayload?.text || '',
    detections,
    conversationId,
  };
}
