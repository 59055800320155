/* eslint-disable no-await-in-loop */
import { useState, useEffect } from 'react';
import { Prediction, SearchPredictionsRequest } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { PredictionApi } from 'services/predictionApi';
import { useCustomerUsecase } from 'hooks/useCustomerParams';

/** Load prediction from supplied prediction ids */
export const usePredictions = (resource: string, predictionIds: string[]): [Prediction[], boolean, any] => {
  const usecase = useCustomerUsecase();
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [isLoading, toggleLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!resource || predictionIds?.length === 0) return;

    const fetchPredictions = async () => {
      toggleLoading(true);
      try {
        const request: SearchPredictionsRequest = {
          resource,
          pageSize: 100,
          filter: {
            usecases: [usecase],
          },
        };
        const tempPredictions: Prediction[] = [];
        for (let start = 0; start < predictionIds.length; start += 100) {
          const end = Math.min(start + 100, predictionIds.length);
          request.filter = {
            predictionIds: predictionIds.slice(start, end),
            // DISABLED (temporarily)
            // usecases: [usecase],
          };
          request.pageToken = '';
          while (true) {
            const response = await PredictionApi.searchPredictions(request);
            tempPredictions.push(...response.predictions);
            if (!response.nextPageToken) {
              break;
            }
            request.pageToken = response.nextPageToken;
          }
        }
        if (JSON.stringify(predictions) !== JSON.stringify(tempPredictions)) {
          setPredictions(tempPredictions);
        }
      } catch (err) {
        setError(err);
      } finally {
        toggleLoading(false);
      }
    };

    fetchPredictions();
  }, [resource, predictionIds]);

  return [predictions, isLoading, error];
};
