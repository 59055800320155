import React, { useEffect, useMemo } from 'react';
import { DatePicker, Input, Radio, Select } from 'antd';
import TagInput from 'components/TagInput';
import { Button, Group, LoadingOverlay, Modal, Text, Box, Collapse } from '@mantine/core';
import { ConceptType, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  Concept,
  ConceptConceptType,
  ConversationOutcomeConversationOutcomeType,
  IntentIntentType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import TreeNodeIcon from 'components/TreeNode/TreeNodeIcon';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import TaskTypeTag from 'components/TaskTypeTag';
import Pill from 'components/Pill';
import { DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import useUrlParam from 'hooks/useUrlParam';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectSets } from 'store/set/selectors';
import { listSets } from 'store/set/asyncThunks';
import { SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import dayjs from 'dayjs';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { ConversationApi } from 'services/conversationApi';
import { openNotification } from 'components/Notification';
import { NewTaskData, OPTIONS_CONCEPT_TYPES } from '.';
import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleOk: (data: NewTaskData) => void;
  submitting: boolean;
  concepts: Concept[];
  taskData: NewTaskData;
  setTaskData: (value: React.SetStateAction<NewTaskData>) => void;
}

export enum RegexType {
  KEYWORD_BASED = 'KEYWORD_BASED',
  REGULAR_EXPRESSION = 'REGULAR_EXPRESSION',
}

const OPTIONS_CONCEPT_TASK_TYPES = [
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING,
  UserLabelingTaskType.AUTO_LABELING,
];

const regexTasks: UserLabelingTaskType[] = [
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  UserLabelingTaskType.LABELING_SUMMARIZATION,
];

const OPTIONS_ENTITY_TASK_TYPES = [
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
];

const OPTIONS_CLO_TASK_TYPES = [
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING,
];

export const DEFAULT_TASK_DATA: NewTaskData = {
  conceptType: ConceptType.INTENT,
  taskType: UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  regexExpression: '',
  regexType: RegexType.KEYWORD_BASED,
  dual: false,
  conceptIds: [],
  positiveSeeds: [],
  negativeSeeds: [],
  chatCount: 10,
  minConversationLength: 5,
  numMessages: 10,
  split: DataSplit.TEST,
  dateRangeSelector: {
    after: dayjs().subtract(1, 'month').toISOString(),
    before: dayjs().toISOString(),
  },
  messageSetIds: [],
  conversationSetIds: [],
};

export default function NewTaskModal({
  isOpen,
  onClose,
  handleOk,
  submitting,
  concepts,
  taskData = DEFAULT_TASK_DATA,
  setTaskData,
}: IProps) {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const [urlConceptType, setUrlConceptType] = useUrlParam<ConceptType>('conceptType', ConceptType.INTENT);
  const allSets = useSelector(selectSets);

  // Conversation sets
  const activeConversationSets = allSets.filter((set) => set.state !== 'DEPRECATED' && set.setType === SetType.CONVERSATION_SET_TYPE);

  // Message sets
  const activeMessageSets = allSets.filter((set) => set.state !== 'DEPRECATED' && set.setType === SetType.MESSAGE_SET_TYPE);

  const {
    conceptType,
    taskType,
    dual,
    regexExpression,
    regexType,
    positiveSeeds,
    negativeSeeds,
    conceptIds,
    chatCount,
    minConversationLength,
    numMessages,
    split,
    dateRangeSelector,
  } = taskData;

  const setTaskDataValue = <T extends keyof NewTaskData>(key: T, value: NewTaskData[T]) => {
    setTaskData((prevState: NewTaskData) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Sync concept type with url param
  useEffect(() => {
    setTaskDataValue('conceptType', urlConceptType);
  }, [urlConceptType]);

  useEffect(() => {
    dispatch(listSets({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
      languageCode: customer?.languageCode,
    }));
  }, []);

  const isSummarization = taskData.conceptType === ConceptType.SUMMARIZATION;

  useEffect(() => {
    if (!isOpen) {
      resetFields();
    }
  }, [isOpen]);

  const submitHandler = async () => {
    const summarizationConceptIds = filteredConcepts.map((concept) => getId('concept', concept.name));
    handleOk({
      ...taskData,
      regexExpression: isRegex
        ? taskData.regexExpression
        : escapeIlike(taskData.regexExpression),
      dateRangeSelector: {
        after: dateRangeSelector?.after,
        before: dateRangeSelector?.before,
      },
      conceptIds: isSummarization ? summarizationConceptIds : taskData.conceptIds,
    });
  };

  const [convCount, setConvCount] = React.useState<number | null>(null);

  const getConversationsCount = async () => {
    setConvCount(null);
    try {
      const response = await ConversationApi.listConversations({
        parent: customerProfile,
        countOnly: true,
        filter: {
          beginTime: dateRangeSelector?.after,
          endTime: dateRangeSelector?.before,
          usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
          languageCode: customer.languageCode,
        },
      });
      const convCount = response.conversationsCount;
      setConvCount(convCount);
    } catch (err) {
      openNotification('error', 'Failed to load conversations', undefined, err);
    }
  };

  useEffect(() => {
    if (isOpen && dateRangeSelector?.after && dateRangeSelector?.before) {
      getConversationsCount();
    }
    setConvCount(null);
  }, [isOpen, dateRangeSelector?.after, dateRangeSelector?.before]);

  const filteredConcepts = useMemo(() => {
    switch (conceptType) {
      case ConceptType.INTENT: {
        // Filter out stages.
        return concepts.filter((concept) =>
          concept.conceptType === ConceptConceptType.INTENT
          && [
            IntentIntentType.AGENT_INTENT,
            IntentIntentType.VISITOR_INTENT,
            IntentIntentType.CONVERSATION_DRIVER,
          ].includes(
            concept?.intent?.intentType,
          ));
      }
      case ConceptType.ENTITY: {
        return concepts.filter((concept) => concept.conceptType === ConceptConceptType.ENTITY);
      }
      case ConceptType.CONVERSATION_OUTCOME: {
        return concepts.filter((concept) =>
          concept.conversationOutcome?.conversationOutcomeType === ConversationOutcomeConversationOutcomeType.POSSIBLE_OUTCOME);
      }
      case ConceptType.SUMMARIZATION: {
        return concepts.filter((concept) =>
          concept.conceptType === ConceptConceptType.SUMMARIZATION);
      }
      default:
        return [];
    }
  }, [concepts, conceptType]);

  const resetFields = () => {
    setTaskData({
      ...DEFAULT_TASK_DATA,
      conceptType: taskData.conceptType,
    });
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClose();
  };

  const removeExample = (index: number, type: 'positive' | 'negative') => {
    const nextExamples = type === 'positive' ? positiveSeeds.slice() : negativeSeeds.slice();
    nextExamples.splice(index, 1);
    if (type === 'positive') {
      setTaskDataValue('positiveSeeds', nextExamples);
    } else {
      setTaskDataValue('negativeSeeds', nextExamples);
    }
  };

  const escapeIlike = (str: string) => (str ? `%${str}%` : str);

  const isRegex = regexType === RegexType.REGULAR_EXPRESSION;

  const validateForm = () => {
    switch (taskType) {
      case UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING: {
        return conceptIds.length > 0 && numMessages > 0;
      }
      case UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING: {
        return conceptIds.length > 0 && chatCount > 0;
      }
      case UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING: {
        return (
          conceptIds.length > 0 && numMessages > 0 && positiveSeeds.length > 1
        );
      }
      case UserLabelingTaskType.LABELING_SUMMARIZATION: {
        return true;
      }
      default:
        return conceptIds.length > 0;
    }
  };

  const isValid = validateForm();

  const isDense = taskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING;
  const taskTypes: { value: UserLabelingTaskType, element: React.ReactNode }[] = useMemo(() => {
    let taskTypeOptions = null;
    switch (conceptType) {
      case ConceptType.INTENT:
        taskTypeOptions = OPTIONS_CONCEPT_TASK_TYPES;
        break;
      case ConceptType.ENTITY:
        taskTypeOptions = OPTIONS_ENTITY_TASK_TYPES;
        break;
      case ConceptType.CONVERSATION_OUTCOME:
        taskTypeOptions = OPTIONS_CLO_TASK_TYPES;
        break;
      case ConceptType.SUMMARIZATION:
        taskTypeOptions = [];
        break;
      default:
        taskTypeOptions = [];
    }
    return taskTypeOptions.map((type) => ({
      value: type,
      element: <TaskTypeTag taskType={type}/>,
    }));
  }, [conceptType]);

  return (
    <Modal
      title="New Task"
      opened={isOpen}
      size={460}
      onClose={() => onClose()}
      closeOnClickOutside={false}
      data-cy="task-modal"
    >
      <div className={styles.newTaskContainer}>
        <Text size="md">Concept type</Text>
        <Select
          data-cy="concept-type-select"
          showArrow
          className={classNames([styles.fullWidth])}
          size="large"
          value={conceptType}
          onChange={(value) => {
            resetFields();
            setTaskDataValue('conceptType', value);
            setUrlConceptType(value);
            if (value === ConceptType.SUMMARIZATION) {
              setTaskDataValue('taskType', UserLabelingTaskType.LABELING_SUMMARIZATION);
            } else {
              setTaskDataValue('taskType', UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING);
            }
          }}
        >
          {OPTIONS_CONCEPT_TYPES.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
        {conceptType === ConceptType.ENTITY && (
          <>
            <Text size="md" mt="sm">Entity set</Text>
            <Select
              data-cy="entity-set-select"
              showArrow
              size="large"
              value={conceptIds}
              onChange={(conceptIds: string[]) => setTaskDataValue('conceptIds', conceptIds)}
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.searchvalue?.indexOf(input) !== -1}
              className={classNames([styles.fullWidth])}
              placeholder="Choose entities to label"
              allowClear
            >
              {filteredConcepts.map((option) => (
                <Select.Option key={option.conceptTitle} value={getId('concept', option.name)} searchvalue={option.conceptTitle}>
                  {option.conceptTitle}
                </Select.Option>
              ))}
            </Select>
          </>
        ) }
        {taskTypes?.length > 0 && (
          <>
            <Text size="md" mt="xs">Task type</Text>
            <Select
              data-cy="task-type-select"
              showArrow
              className={classNames([styles.fullWidth, styles.taskType])}
              size="large"
              value={taskType}
              onChange={(value) => {
                const newTaskData = {
                  ...taskData,
                  messageSetIds: [],
                  conversationSetIds: [],
                  taskType: value,
                };
                if (value === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING) {
                  newTaskData.split = DataSplit.TEST;
                } else if (value === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING) {
                  newTaskData.split = DataSplit.TRAIN;
                } else if (value === UserLabelingTaskType.AUTO_LABELING) {
                  newTaskData.split = DataSplit.TRAIN;
                }

                setTaskData(newTaskData);
              }}
            >
              {taskTypes.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.element}
                </Select.Option>
              ))}
            </Select>
            {
              taskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING && (
                <>
                  <Text size="md" mt="sm">Conversations sets</Text>
                  <Select
                    className={classNames([styles.fullWidth, styles.taskType])}
                    showArrow
                    size="large"
                    placeholder="Select sets"
                    mode="multiple"
                    value={taskData.conversationSetIds}
                    onChange={(sets) => setTaskDataValue('conversationSetIds', sets)}
                  >
                    {activeConversationSets.map(((option) => <Select.Option key={option.setTitle} value={getId('set', option.name)}>{option.setTitle}</Select.Option>))}
                  </Select>
                </>
              )
            }
            {
              taskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING && (
                <>
                  <Text size="md" mt="sm">Message sets</Text>
                  <Select
                    className={classNames([styles.fullWidth, styles.taskType])}
                    showArrow
                    size="large"
                    placeholder="Select sets"
                    mode="multiple"
                    value={taskData.messageSetIds}
                    onChange={(sets) => setTaskDataValue('messageSetIds', sets)}
                  >
                    {activeMessageSets.map(((option) => <Select.Option key={option.setTitle} value={getId('set', option.name)}>{option.setTitle}</Select.Option>))}
                  </Select>
                  <br/>
                </>
              )
            }
          </>
        )}
        {conceptType === ConceptType.SUMMARIZATION && (
          <>
            <Text size="md" mt="sm">Conversations from this time range</Text>
            <DatePicker.RangePicker
              className={styles.fullWidth}
              defaultValue={[dateRangeSelector?.after && dayjs(dateRangeSelector?.after), dateRangeSelector?.before && dayjs(dateRangeSelector?.before)]}
              onChange={([after, before]) => setTaskDataValue('dateRangeSelector', {
                before: before?.toISOString(),
                after: after?.toISOString(),
              })}
              data-cy="conversations-range-picker"
            />
            <Text size="md" mt="sm">Summarization topics</Text>
            <Select
              showArrow
              mode="multiple"
              className={classNames([styles.fullWidth, styles.taskType])}
              size="large"
              value={filteredConcepts.map((concept) => concept.conceptTitle)}
              disabled
            />
          </>
        )}
        {(taskType !== UserLabelingTaskType.AUTO_LABELING && (conceptType === ConceptType.INTENT)) && (
          <Box mt="sm">
            <Checkbox
              checked={dual}
              onChange={(event) => setTaskDataValue('dual', event.target.checked)}
            >
              Make this dual
            </Checkbox>
          </Box>
        )}
        {(conceptType === ConceptType.INTENT || conceptType === ConceptType.CONVERSATION_OUTCOME) && (
          <>
            <Text size="md" mt="sm">
              {isDense
                ? 'Choose intents (select all that applies)'
                : `Choose an ${conceptType === ConceptType.INTENT ? 'intent' : 'outcome'}`}
            </Text>
            <Select
              data-cy="intent-select"
              mode={isDense ? 'multiple' : null}
              showArrow
              showSearch
              className={classNames([styles.fullWidth, 'with-icons'])}
              size="large"
              placeholder="Intents"
              allowClear
              dropdownClassName="with-icons"
              value={conceptIds}
              filterOption={(input, option) =>
                option.searchvalue?.indexOf(input) !== -1}
              onChange={(value) => {
                if (value.includes('ALL')) {
                  setTaskDataValue(
                    'conceptIds',
                    filteredConcepts.map((concept) => getId('concept', concept.name)),
                  );
                } else {
                  setTaskDataValue(
                    'conceptIds',
                    Array.isArray(value) ? value : [value],
                  );
                  if (Array.isArray(value)) return;
                  const selectedConcept = filteredConcepts.find((concept) => getId('concept', concept.name) === value);
                  setTaskDataValue(
                    'positiveSeeds',
                    selectedConcept.positiveExamples,
                  );
                  setTaskDataValue(
                    'negativeSeeds',
                    selectedConcept.negativeExamples,
                  );
                }
              }}
            >
              {isDense && (
              <Select.Option key="all" value="ALL">
                All
              </Select.Option>
              )}
              {filteredConcepts.map((concept) => {
                const conceptId = getId('concept', concept.name);
                return (
                  <Select.Option
                    key={conceptId}
                    value={conceptId}
                    searchvalue={concept.conceptTitle}
                  >
                    <TreeNodeIcon
                      type={
                    concept.intent?.intentType === IntentIntentType.AGENT_INTENT
                      ? 'agent'
                      : 'visitor'
                  }
                    />
                    <span>{concept.conceptTitle}</span>
                  </Select.Option>
                );
              })}
            </Select>
          </>
        )}
        <Box mt="sm">
          <Radio.Group
            value={split}
            onChange={(e) => setTaskDataValue('split', e.target.value)}
          >
            <Radio value={DataSplit.TEST} disabled={taskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING || taskType === UserLabelingTaskType.AUTO_LABELING}>TEST</Radio>
            <Radio value={DataSplit.TRAIN}>TRAIN</Radio>
          </Radio.Group>
        </Box>
        {regexTasks.includes(taskType) && (
          <Box mt="md">
            <div className="pills-wrapper">
              <Pill
                text="Keywords"
                value={RegexType.KEYWORD_BASED}
                active={regexType === RegexType.KEYWORD_BASED}
                onClick={() => {
                  setTaskDataValue('regexType', RegexType.KEYWORD_BASED);
                }}
              />
              <Pill
                text="Regex"
                value={RegexType.REGULAR_EXPRESSION}
                active={regexType === RegexType.REGULAR_EXPRESSION}
                onClick={() => {
                  setTaskDataValue('regexType', RegexType.REGULAR_EXPRESSION);
                }}
              />
            </div>
            <Input
              id="keywork-regex-input"
              size="large"
              value={regexExpression}
              addonBefore={!isRegex && '%'}
              addonAfter={!isRegex && '%'}
              placeholder={isRegex ? 'Insert expression' : 'Keyword'}
              onChange={(event) => setTaskDataValue('regexExpression', event.target.value)}
            />
          </Box>
        )}
        {taskType
          === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING && (
          <Box mt="sm">
            <Text size="md">Positive examples (at least 2)</Text>
            <TagInput
              tags={positiveSeeds}
              onAddTag={(tag: string) =>
                setTaskDataValue('positiveSeeds', [...positiveSeeds, tag])}
              onRemoveIndex={(index: number) =>
                removeExample(index, 'positive')}
            />
            <Text size="md" mt="sm">Negative examples (optional)</Text>
            <TagInput
              tags={negativeSeeds}
              onAddTag={(tag: string) =>
                setTaskDataValue('negativeSeeds', [...negativeSeeds, tag])}
              onRemoveIndex={(index: number) =>
                removeExample(index, 'negative')}
            />
            <Text size="md" mt="sm">Conversations from this time range</Text>
            <DatePicker.RangePicker
              className={styles.fullWidth}
              defaultValue={[dateRangeSelector?.after && dayjs(dateRangeSelector?.after), dateRangeSelector?.before && dayjs(dateRangeSelector?.before)]}
              onChange={([after, before]) => setTaskDataValue('dateRangeSelector', {
                before: before?.toISOString(),
                after: after?.toISOString(),
              })}
              data-cy="conversations-range-picker"
            />

            <Collapse in={convCount !== null}>
              <Text size="md" mt="sm">Number of conversations in date range: {convCount}</Text>
            </Collapse>
          </Box>
        )}
        {(isDense || isSummarization) && (
          <Box mt="sm">
            <div className={styles.chatCount}>
              <Text size="md">Number of conversations to label</Text>
              <Input
                size="large"
                value={regexExpression ? numMessages : chatCount}
                type="number"
                data-cy="num-conv"
                disabled={taskData.conversationSetIds?.length > 0}
                onChange={(event) => {
                  if (regexExpression) {
                    setTaskDataValue('numMessages', Number(event.currentTarget.value));
                  } else {
                    setTaskDataValue('chatCount', Number(event.currentTarget.value));
                  }
                }}
              />
            </div>
            <div className={styles.chatCount}>
              <Text size="md" mt="sm">Min conversation length</Text>
              <Input
                size="large"
                value={minConversationLength}
                type="number"
                data-cy="min-conv-length"
                disabled={taskData.conversationSetIds?.length > 0}
                onChange={(event) =>
                  setTaskDataValue('minConversationLength', Number(event.currentTarget.value))}
              />
            </div>
          </Box>
        )}
        {taskType === UserLabelingTaskType.AUTO_LABELING || (
          <>
            <Text size="md" mt="sm">Number of messages</Text>
            <div className={styles.numMessages}>
              <Input
                id="messages-number-input"
                size="large"
                value={numMessages}
                type="number"
                disabled={taskData.messageSetIds?.length > 0}
                onChange={(event) =>
                  setTaskDataValue('numMessages', Number(event.currentTarget.value))}
              />
            </div>
          </>
        )}
      </div>
      <Group position="right">
        <Button key="back" onClick={handleCancel} variant="subtle">
          Cancel
        </Button>
        <Button
          key="submit"
          loading={submitting}
          onClick={submitHandler}
          disabled={!isValid}
        >
          Create
        </Button>
      </Group>
      <LoadingOverlay visible={submitting}/>
    </Modal>
  );
}
