import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AnnotationApi } from 'services/annotationApi';
import { useSelector } from 'hooks/reduxHooks';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { getId } from 'common/resourceName';
import { ConceptConceptType, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { LabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { FetchManualAnalysisSummaryResponseManualAnalysisSummary } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { selectApiStatus as conceptApiStatus, selectConceptsFactory } from 'store/concept/selectors';
import { selectApiStatus as setApiStatus, selectSets } from 'store/set/selectors';
import { SingleFlowGraph } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { FlowNodeApi } from 'services/flowNodeApi';
import { AnalysisSummary as AnalysisSummaryView } from './AnalysisSummary';
import { aggregateConcepts, getPanelData, getTagsTableData, handleFiltering } from './utils';

export interface ConceptDictionaryEntry {
  id: string;
  value: string;
  label: string;
  tagType: TagTagType;
}

export interface TagData {
  id: string;
  tag: string;
  percent: number
}
export interface PanelData {
    driverData: TagData[];
    behaviorData: TagData[];
    outcomeData: TagData[];
}
export interface ModifiedManualAnalysisSummary extends FetchManualAnalysisSummaryResponseManualAnalysisSummary {
  stages: ConceptDictionaryEntry[];
  drivers: ConceptDictionaryEntry[];
  outcomes: ConceptDictionaryEntry[];
  behaviors: ConceptDictionaryEntry[];
}

const AnalysisSummary = () => {
  const customerProfile = useCustomerProfile();
  const params = useParams<{ taskId: string }>();
  const { taskId } = params;
  const [manualAnalysisSummaries, setManualAnalysisSummaries] = useState([]);
  const [panelData, setPanelData] = useState<PanelData>({
    driverData: [{ id: '0', tag: 'driverData', percent: 0 }],
    behaviorData: [{ id: '1', tag: 'behaviorData', percent: 0 }],
    outcomeData: [{ id: '2', tag: 'outcomeData', percent: 0 }],
  });
  const [setIds, setSetIds] = useState([]);
  const [conceptIds, setConceptIds] = useState([]);

  const [driverFilter, setDriverFilter] = useState([]);
  const [driverFilterData, setDriverFilterData] = useState([]);
  const [outcomeFilter, setOutcomeFilter] = useState([]);
  const [outcomeFilterData, setOutcomeFilterData] = useState([]);
  const [setFilter, setSetFilter] = useState([]);
  const [setFilterData, setSetFilterData] = useState([]);
  const [isLoadingSummaries, toggleLoadingSummaries] = useState(true);
  const [labelingTask, setLabelingTask] = useState<LabelingTask>();
  const conceptStatus = useSelector<string>(conceptApiStatus);
  const isLoadingConcepts = conceptStatus === 'loading';
  const tagConcepts = useSelector(selectConceptsFactory(ConceptConceptType.TAG));
  const sets = useSelector(selectSets);
  const setStatus = useSelector<string>(setApiStatus);
  const isLoadingSets = setStatus === 'loading';

  const [flowGraphs, setFlowGraphs] = useState<SingleFlowGraph[]>([]);

  const fetchAllFlowGraphsForTask = useCallback(async (taskId: string) => {
    const response = await FlowNodeApi.fetchAllFlowGraphsForTask({
      profile: customerProfile,
      taskName: `${customerProfile}/labelingTasks/${taskId}`,
      driverTagIds: driverFilter,
      outcomeTagIds: outcomeFilter,
      setsIds: setFilter,
    });
    setFlowGraphs(response?.flowGraphs);
  }, [customerProfile, setFilter, outcomeFilter, driverFilter]);

  const triggerBuildFlowNodes = useCallback(async (taskId: string) => {
    await FlowNodeApi.buildFlowNodes({
      profile: customerProfile,
      taskName: `${customerProfile}/labelingTasks/${taskId}`,
    });
    fetchAllFlowGraphsForTask(taskId);
  }, [customerProfile, fetchAllFlowGraphsForTask, taskId]);

  useEffect(() => {
    if (taskId) {
      triggerBuildFlowNodes(taskId);
    }
  }, [taskId]);

  useEffect(() => {
    fetchAllFlowGraphsForTask(taskId);
  }, [taskId, setFilter, outcomeFilter, driverFilter]);

  const setDictionary = useMemo(() => {
    if (!sets) return {};
    if (isLoadingSets) return {};
    const setDictionaryMemoized = {};
    sets.forEach((set) => {
      const setId = getId('set', set.name);
      setDictionaryMemoized[setId] = {
        title: set.setTitle,
        convoQuantity: set.setItemNames.length,
      };
    });
    return setDictionaryMemoized;
  }, [sets]);

  // need to create a dictionary for task concepts
  // can likely refactor this out to task scope
  const conceptDictionary = useMemo(() => {
    if (!tagConcepts) return {};
    if (isLoadingConcepts) return {};
    const dictionary: { [id: string]: ConceptDictionaryEntry; } = {};
    tagConcepts.forEach((concept) => {
      const conceptId = getId('concept', concept.name);
      dictionary[conceptId] = {
        id: conceptId,
        value: concept.name,
        label: concept.conceptTitle,
        tagType: concept.tag.tagType,
      };
    });
    return dictionary;
  }, [tagConcepts]);

  useEffect(() => {
    if (isLoadingConcepts) return;
    const data : {value: string, label: string}[] = conceptIds
      .filter((id) => conceptDictionary[id]?.tagType === TagTagType.DRIVER)
      .map((id) => ({
        value: id,
        label: conceptDictionary[id]?.label,
      }));
    setDriverFilterData(data);
  }, [conceptIds, conceptDictionary]);

  useEffect(() => {
    if (isLoadingConcepts) return;
    const data: {value: string, label: string}[] = conceptIds
      .filter((id) => conceptDictionary[id]?.tagType === TagTagType.CONVERSATION_OUTCOME)
      .map((id) => ({
        value: id,
        label: conceptDictionary[id]?.label,
      }));
    setOutcomeFilterData(data);
  }, [conceptIds, conceptDictionary]);

  useEffect(() => {
    if (!setDictionary) return;
    const data: {value: string, label: string}[] = setIds.map((id:string) => ({
      value: id,
      label: setDictionary[id]?.title,
    }));
    setSetFilterData(data);
  }, [setIds, setDictionary]);

  const getTagsTableColumnHeaders = () => {
    if (conceptIds.length === 0) {
      return [];
    }
    if (!setDictionary) {
      return [];
    }
    return setIds.map((setId) => setDictionary[setId]?.title || '');
  };

  const tagsTableColumnHeaders = getTagsTableColumnHeaders();

  const tagsTableData = useMemo(() => {
    if (conceptIds.length === 0) {
      return [];
    }
    if (isLoadingConcepts) {
      return [];
    }
    return getTagsTableData(conceptIds, setIds, manualAnalysisSummaries, conceptDictionary);
  }, [conceptIds, setIds, manualAnalysisSummaries, conceptDictionary]);

  // Get TaskData
  useEffect(() => {
    if (!taskId) return;
    LabelingTaskApi.fetchLabelingData({
      taskName: `${customerProfile}/labelingTasks/${taskId}`,
    }).then((taskData) => {
      setLabelingTask(taskData.labelingTask);
      const setIds = taskData.labelingTask.taskData.taskDescriptor.selectionInstruction.conversationSetsInstruction.conversationSampleInfos.map((set) => getId('set', set.conversationSetName));
      setSetIds(setIds);
      const { conceptIds } = taskData.labelingTask.taskData.taskDescriptor;
      setConceptIds(conceptIds);
    });
  }, [taskId]);

  useEffect(() => {
    if (!taskId) {
      return;
    }
    if (isLoadingConcepts) return;
    const fetchManualAnalysisSummaries = async () => {
      try {
        const { manualAnalysisSummaries } = await AnnotationApi.fetchManualAnalysisSummary({ taskId, profile: customerProfile });

        const withAllFilters = manualAnalysisSummaries.filter((summary) => handleFiltering(summary, {
          setFilter,
          driverFilter,
          outcomeFilter,
        }));

        const withAggregatedConcepts = aggregateConcepts(withAllFilters, conceptDictionary);
        setManualAnalysisSummaries(withAggregatedConcepts);
        const newPanelData = getPanelData(withAggregatedConcepts);
        setPanelData(newPanelData);
      } catch (e) {
        console.error(e);
      } finally {
        toggleLoadingSummaries(false);
      }
    };
    fetchManualAnalysisSummaries();
  }, [taskId, conceptDictionary, setFilter, driverFilter, outcomeFilter]);

  return (
    <AnalysisSummaryView
      flowGraphs={flowGraphs}
      isLoadingSummaries={isLoadingSummaries}
      conversationsTableData={manualAnalysisSummaries}
      setDictionary={setDictionary}
      panelData={panelData}
      tagsTableColumnHeaders={tagsTableColumnHeaders}
      tagsTableData={tagsTableData}
      driverFilterData={driverFilterData}
      driverFilter={driverFilter}
      setDriverFilter={setDriverFilter}
      outcomeFilterData={outcomeFilterData}
      outcomeFilter={outcomeFilter}
      setOutcomeFilter={setOutcomeFilter}
      setFilterData={setFilterData}
      setFilter={setFilter}
      setSetFilter={setSetFilter}
      labelingTask={labelingTask}
    />
  );
};

export default AnalysisSummary;
