import React from 'react';
import './Pill.scss';

export interface PillProps {
  active?: boolean;
  text: string;
  value: string;
  count?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function Pill({
  active = false,
  text,
  value,
  count,
  onClick,
}: PillProps) {
  return (
    <button
      type="button"
      className={`pill-button ${active ? 'pill-active' : 'pill-inactive'}`}
      onClick={onClick}
    >
      <span className="pill-text">
        {text}
      </span>
      {
        count != null && (
          <span className="pill-number">({count})</span>
        )
      }
    </button>
  );
}
