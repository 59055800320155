import { Grid, Title } from '@mantine/core';
import { DataGrid } from 'components/DataGrid';
import ErrorMessage from 'components/ErrorMessage';
import { PageContainer } from 'components/PageContainer';
import { PageLoader } from 'components/PageLoader';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntentValidationData } from './useIntentValidationData';

const GridItem = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Grid.Col span={12}>
    <Title order={3} mb="sm">
      {title}
    </Title>
    {children}
  </Grid.Col>
);

export const IntentValidation = () => {
  const { state } = useLocation();
  const { isLoading, error, data } = useIntentValidationData(state);
  const columns = [
    {
      accessorKey: 'intent',
      header: 'Intent',
    },
    {
      accessorKey: 'validation_type',
      header: 'Validation Type',
    },
    {
      accessorKey: 'pos_example',
      header: 'Positive Example',
    },
    {
      accessorKey: 'neg_example',
      header: 'Negative Example',
    },
    {
      accessorKey: 'similarity',
      header: 'Similarity',
    },
    {
      accessorKey: 'pos_msg_id',
      header: 'Positive Message ID',
    },
    {
      accessorKey: 'neg_msg_id',
      header: 'Negative Message ID',
    },
    {
      accessorKey: 'pos_conv_id',
      header: 'Positive Conversation ID',
    },
    {
      accessorKey: 'neg_conv_id',
      header: 'Negative Conversation ID',
    },
  ];

  if (isLoading) {
    return <PageLoader />;
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }
  if (data.result === '[]' && data.status === 'ok') {
    return (
      <PageContainer>
        <Title order={2}>Intent Validation</Title>
        <p>No issue found.</p>
      </PageContainer>
    );
  }
  if (!data) {
    return <ErrorMessage message="No data found for this intent validation task" />;
  }

  return (
    <PageContainer>
      <Title order={2}>Intent Validation</Title>
      <Grid gutter="md">
        <GridItem title="Intents Validation Result">
          <DataGrid columns={columns} dataSource={data.validated_result.result} />
        </GridItem>
      </Grid>
    </PageContainer>
  );
};
