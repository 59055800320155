export const GOOD_DESCRIPTION_EXAMPLES: string[] = [
  'Agent asks the customer for their name, email, phone number, address, or security question in order to verify the customer’s account.',
  'The customer objects to the cost of the product or recommendation.',
  "The agent asks the visitor if there's anything else they can assist with.",
];

export const BAD_DESCRIPTION_EXAMPLES: {
  text: string;
  reason: string;
}[] = [
  {
    text: 'Agent concerned',
    reason: 'not a full sentence',
  },
  {
    text: 'Concerned, angry, shouting',
    reason: 'not a full sentence; and shouting cannot be deduced from the message text',
  },
  {
    text:
      'Agent says a positive message at the end of a conversation where the visitor enquires about a new chair',
    reason: 'this relies on context earlier in the conversation',
  },
  {
    text: 'Visitor shows positive energy',
    reason: 'vague, subjective',
  },
];
