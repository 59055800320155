import { DependencyList, useCallback, useEffect } from 'react';

/** Execute the callback every delay milliseconds. */
export function useInterval(callback: () => void, delay: number, deps: DependencyList) {
  const callbackFn = useCallback(callback, deps);
  const allDeps: DependencyList = [...deps, delay];
  useEffect(() => {
    if (delay < 0) {
      return () => {};
    }
    const id = setInterval(callbackFn, delay);
    return () => clearInterval(id);
  }, allDeps);
}
