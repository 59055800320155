import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification } from 'components/Notification';
import { CreateSetRequest, DeleteSetRequest, Set } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { SetApi } from 'services/setApi';

enum Action {
  SETS_LIST_SETS = 'SETS_LIST_SETS',
  SET_CREATE_SET = 'SET_CREATE_SET',
  SET_DELETE_SET = 'SET_DELETE_SET',
}

// From set_service.pb.ts:
/**
   * The maximum number of sets to return. The service may return fewer
   * than this value.
   *
   * If unspecified, at most 50 sets will be returned. The maximum value
   * is 100; values above 100 will be coerced to 100.
   * */
const PAGE_SIZE = 100;
/** AsyncThunk for listing first PAGE_SIZE * MAX_FETCH_REQUESTS sets. */
export const listSets = createAsyncThunk<Set[], {
  parent: string,
  usecase: string,
  languageCode: string,
 }>(Action.SETS_LIST_SETS, async ({
   parent,
   usecase,
   languageCode,
 }) => {
   try {
     const MAX_FETCH_REQUESTS = 50;
     let requestNumber = 0;
     const sets: Set[] = [];
     let nextPageToken: string | undefined;
     do {
       // eslint-disable-next-line no-await-in-loop
       const response = await SetApi.listSets({
         parent,
         usecase,
         languageCode,
         pageSize: PAGE_SIZE,
         pageToken: nextPageToken,
       });
       sets.push(...response.sets);
       requestNumber++;
       // eslint-disable-next-line prefer-destructuring
       nextPageToken = response.nextPageToken;
     } while (nextPageToken && requestNumber < MAX_FETCH_REQUESTS);
     return sets;
   } catch (err) {
     openNotification('error', 'Failed to list sets', undefined, err);
     throw err;
   }
 });

/** AsyncThunk for CreateSet API. */
export const createSet = createAsyncThunk<Set, CreateSetRequest>(Action.SET_CREATE_SET, async (request: CreateSetRequest) => {
  try {
    const response = await SetApi.createSet(request);
    return response.set;
  } catch (err) {
    openNotification('error', 'Failed to create set', undefined, err);
    throw err;
  }
});

/** AsyncThunk for DeleteSet API. */
/** note this actually deprecates the set, not deletes it */
export const deleteSet = createAsyncThunk<string, DeleteSetRequest>(Action.SET_DELETE_SET, async (request: DeleteSetRequest) => {
  try {
    await SetApi.deleteSet(request);
    return request.name;
  } catch (err) {
    openNotification('error', 'Failed to delete set', undefined, err);
    throw err;
  }
});
