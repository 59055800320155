import React, { createContext, useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { PageLoader } from 'components/PageLoader';

export interface AutoLabelingSession {
  id: string;
}

export const AutoLabelingSessionContext = createContext<AutoLabelingSession>({
  id: '',
});

export const AutoLabelingSessionContextProvider = ({ children }) => {
  const sessionRef = useRef<AutoLabelingSession>({ id: '' });
  const [ready, setReady] = useState<boolean>(false);
  const { path } = useCustomerParams();

  useEffect(() => {
    sessionRef.current = { id: uuidv4() };
    setReady(true);
  }, [path]);

  if (ready) {
    return (
      <AutoLabelingSessionContext.Provider value={sessionRef.current}>
        {children}
      </AutoLabelingSessionContext.Provider>
    );
  }
  return <PageLoader />;
};
