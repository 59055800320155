/** Hash a string and convert it into a hex color code */
export function stringToColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // eslint-disable-next-line no-bitwise
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return `#${'00000'.substring(0, 6 - c.length)}${c}`;
}

/**
 * If the color is too bright, return a darker version of the provided color.
 * If it's dark, return white color.
 *
 * Color brightness is determined by the following formula:
 * ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
 * Note: This algorithm is taken from a formula for converting RGB values to YIQ values.
 * This brightness value gives a perceived brightness for a color.
 */
export function getTextColor(backgroundColorHexCode: string): string {
  const r = parseInt(backgroundColorHexCode.substring(1, 3), 16);
  const g = parseInt(backgroundColorHexCode.substring(3, 5), 16);
  const b = parseInt(backgroundColorHexCode.substring(5, 7), 16);

  // Calculate the color's perceived brightness using the formula (r*299 + g*587 + b*114) / 1000
  const perceivedBrightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // If the brightness is too low, return the same color but much darker
  if (perceivedBrightness > 128) {
    const darkerR = Math.round(r * 0.4);
    const darkerG = Math.round(g * 0.4);
    const darkerB = Math.round(b * 0.4);
    return `#${darkerR.toString(16)}${darkerG.toString(16)}${darkerB.toString(16)}`;
  }

  // If the brightness is not low, return white color
  return '#ffffff';
}
