import React from 'react';
import { Checkbox, NumberInput, Stack } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { RetrievalConfigFormValues } from './useRetrievalConfigForm';
import { ConfigPanelContainer } from '../../components/ConfigPanelContainer';
import { QAConfigFormValues, QATaskSetup } from '../../components/QATaskSetup';
import { ShowAdvanced } from '../../components/ShowAdvanced';

interface Props {
  retrievalForm: UseFormReturnType<RetrievalConfigFormValues>;
  qaConfigForm: UseFormReturnType<QAConfigFormValues>;
}

const formatter: (value: string | undefined) => string = (value) => {
  if (value === '') {
    return '';
  }
  const number = value.replace('%', '');
  return `${number}%`;
};

export const RetrievalConfigForm = (props: Props) => {
  const { retrievalForm, qaConfigForm } = props;

  return (
    <ConfigPanelContainer>
      <DatePickerInput
        type="range"
        required
        label="Conversations from this time range"
        placeholder="Choose Dates"
        {...retrievalForm.getInputProps('conversationDates')}
      />
      <DatePickerInput
        type="range"
        required
        label="Test set conversations from this time range"
        placeholder="Choose Dates"
        {...retrievalForm.getInputProps('testConversationDates')}
      />
      <ShowAdvanced>
        <Stack>
          <NumberInput
            label="Number of past messages to include"
            description="Context size in number of past messages when creating training data"
            min={0}
            {...retrievalForm.getInputProps('pastNMessages')}
          />
          <NumberInput
            label="Training set split size"
            description="Approximate ratio of partial chats that should go into the training split rather than the validation split"
            min={0}
            max={100}
            step={1}
            placeholder="Enter %"
            formatter={formatter}
            {...retrievalForm.getInputProps('trainSplitRatio')}
          />
          <NumberInput
            label="Maximum number of matches in passage"
            description="Maximum number of canned suggestion candidates allowed for matching a particular passage. If the number of matches exceeds this, the passage is considered ambiguous and not considered for training"
            min={0}
            {...retrievalForm.getInputProps('maxMultipleCandidatesAllowed')}
          />
          <Checkbox label="Load Preprocessing Config" {...retrievalForm.getInputProps('loadPreprocessingConfig', { type: 'checkbox' })} />
          <Checkbox label="Load Postprocessing Config" {...retrievalForm.getInputProps('loadPostprocessingConfig', { type: 'checkbox' })} />
          <Checkbox label="Materialize Configs" {...retrievalForm.getInputProps('materializeConfigs', { type: 'checkbox' })} />
        </Stack>
      </ShowAdvanced>
      <QATaskSetup configForm={qaConfigForm} />
    </ConfigPanelContainer>
  );
};
