import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as ConversationState } from './state';

const selectConversation = (state: State) => state.conversation;

/** Select conversations. */
export const selectConversations = createSelector(selectConversation, (conversationState: ConversationState) => conversationState.conversations);

/** Select conversation messages. */
export const selectConversationMessages = createSelector(selectConversation, (conversationState: ConversationState) => conversationState.conversationMessages);

/** Select API status. */
export const selectApiStatus = createSelector(selectConversation, (conversationState: ConversationState) => conversationState.status);
