import './EditableTagGroup.scss';
import classNames from 'classnames';
import React, { ReactElement, useCallback, useState } from 'react';
import { cloneDeep } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Checkbox, Popover, Button, Group, Text, CloseButton, Divider } from '@mantine/core';

/** Element type in the EditableTagGroup. */
export interface EditableTagType<T> {
  key: React.Key;
  displayName?: string;
  value: T;
}

interface Props<T> {
  values: EditableTagType<T>[];
  options?: EditableTagType<T>[];
  editing?: boolean;
  addButtonName?: string;
  onChange?: (values: EditableTagType<T>[]) => void;
}
export default function EditableTagGroup<T>({
  values,
  options,
  editing,
  addButtonName,
  onChange,
}: Props<T>): ReactElement {
  const [openAddNew, setOpenAddNew] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const existingTags = new Set<React.Key>(values.map((v) => v.key));
  const newTags: EditableTagType<T>[] = options?.filter((option) => !existingTags.has(option.key)) || [];
  const handleAddNew = useCallback(() => {
    const newValues = cloneDeep(values);
    for (const key of selectedTags) {
      const tag = newTags.find((t) => (String)(t.key) === key);
      if (tag) {
        newValues.push(tag);
      }
    }
    if (onChange) {
      onChange(newValues);
    }
    setOpenAddNew(false);
    setSelectedTags([]);
  }, [selectedTags]);
  const handleOpenPopover = useCallback(() => {
    if (!newTags.length) {
      return;
    }
    setOpenAddNew((o) => !o);
  }, [newTags]);
  // Popover to add new tags.
  const popover = (
    <Popover
      opened={openAddNew}
      onClose={() => {
        setOpenAddNew(false);
        setSelectedTags([]);
      }}
      width={200}
      position="bottom-start"
      withArrow
    >
      <Popover.Target>
        <div
          className={classNames(['editable-tag-group-item', 'add-new', newTags.length > 0 ? '' : 'disabled'])}
          onClick={handleOpenPopover}
        >
          {addButtonName || '+ New'}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div>
          <Group>
            <Text style={{ flex: 1 }} my="xs">Add New</Text>
            <CloseButton onClick={() => setOpenAddNew(false)} />
          </Group>
          <Divider />
          <div className="editable-tag-group-popover-content">
            <Checkbox.Group value={selectedTags} onChange={setSelectedTags}>
              <Group>{newTags.map((t) => (<Checkbox key={t.key} size="xl" value={t.key} label={t.displayName || t.key} />))}</Group>
            </Checkbox.Group>
            <div className="editable-tag-group-save-button">
              <Button disabled={selectedTags.length === 0} color="#304ffe" onClick={handleAddNew} compact>Save</Button>
            </div>
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );

  // Handler for clicking the close button on an item.
  const handleClose = useCallback((key: React.Key) => {
    const index = values.findIndex((v) => v.key === key);
    if (index < 0) {
      return;
    }
    const newValues = cloneDeep(values);
    newValues.splice(index, 1);
    if (onChange) {
      onChange(newValues);
    }
  }, [values]);

  return (
    <div className="editable-tag-group-container">
      {
        values.map((value) => (
          <div
            key={value.key}
            className="editable-tag-group-item"
          >
            {value.displayName || String(value.value)}
            {editing && (
              <div
                className="close-button"
                onClick={() => handleClose(value.key)}
              >
                <CloseCircleOutlined />
              </div>
            )}
          </div>
        ))
      }
      {editing && popover}
    </div>
  );
}
