import { Select } from 'antd';
import classNames from 'classnames';
import { isEmpty, noop, partition, sortBy } from 'lodash';
import React from 'react';

import ModelTag from 'components/ModelTag';
import { ModelName } from 'constants/actions';
import { useApiData } from 'hooks/network';
import { Model } from 'types';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';

import './ModelSelect.scss';

interface IProps {
  modelName: ModelName;
  onSelect?: (value: string) => void;
  className?: string;
  value?: string;
  models?: Model[];
  disabled?: boolean;
  defaultValue?: string;
  includeTag?: boolean;
  recentlyUsed?: string[];
}

const generateSelectModelOptions = (options: Model[], includeTag: boolean = false) => {
  if (isEmpty(options)) return [];
  return (
    options.map(({ name, lastModifiedAt, type }) => (
      <Select.Option value={name} key={name}>
        {name}
        &mdash;
        <small>{dayjs(lastModifiedAt).format(DATE_TIME_FORMAT)}</small>
        {includeTag && <ModelTag modelName={type} />}
      </Select.Option>
    ))
  );
};

export default function ModelSelect({
  modelName,
  className,
  value,
  defaultValue,
  models,
  recentlyUsed = [],
  onSelect = noop,
  disabled = false,
  includeTag = false,
}: IProps) {
  const { error, data } = useApiData(disabled || models != null ? null : `models/actions/${modelName}`);

  if (error) {
    return <div>An error occurred: {(error as any).message}</div>;
  }

  const controlled = value !== undefined;

  const allModels = models || data || [];
  const [_recentModels, otherModels] = partition(
    allModels,
    ({ name }) => recentlyUsed.includes(name),
  );
  const recentModels = sortBy(_recentModels, ({ name }) => recentlyUsed.indexOf(name));

  if (!controlled) {
    return (
      <Select
        showSearch
        className={classNames('model-select', className)}
        onChange={onSelect}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {!isEmpty(recentModels) && (
          <Select.OptGroup key="recent" label="Recent">
            {generateSelectModelOptions(recentModels, includeTag)}
          </Select.OptGroup>
        )}
        {!isEmpty(otherModels) && (
          <Select.OptGroup key="others" label={isEmpty(recentlyUsed) ? 'Models' : 'Others'}>
            {generateSelectModelOptions(otherModels, includeTag)}
          </Select.OptGroup>
        )}
      </Select>
    );
  }

  return (
    <Select
      showSearch
      className={classNames('model-select', className)}
      onChange={onSelect}
      value={value}
      disabled={disabled}
    >
      {!isEmpty(recentModels) && (
        <Select.OptGroup key="recent" label="Recent">
          {generateSelectModelOptions(recentModels, includeTag)}
        </Select.OptGroup>
      )}
      {!isEmpty(otherModels) && (
        <Select.OptGroup key="others" label={isEmpty(recentlyUsed) ? 'Models' : 'Others'}>
          {generateSelectModelOptions(otherModels, includeTag)}
        </Select.OptGroup>
      )}
    </Select>
  );
}
