import Table, { ColumnsType } from 'antd/lib/table';
import React, { memo } from 'react';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import CopyableValue from 'components/CopyableValue';
import dayjs from 'dayjs';

interface PredictionsTableProps {
  evaluateData: ModelWorkflow;
}

export const ConfirmModalTable = memo(({ evaluateData }: PredictionsTableProps) => {
  const columns: ColumnsType<ModelWorkflow> = [
    {
      title: 'Data set',
      dataIndex: ['abstractWorkflow', 'title'],
      width: 100,
      key: 'Data set',
      render: (model: string) => (
        <div>
          {evaluateData?.trainIntentModelConfig?.intentConfigs?.length}
          {evaluateData?.trainIntentModelConfig?.intentTaskType}
          s
        </div>
      ),
    },
    {
      title: 'Model URL',
      dataIndex: ['name'],
      key: 'url',
      render: (name: string) => {
        const nameArray = name?.split('/');
        return (name ? <CopyableValue copiedValue={name} displayValue={nameArray[nameArray.length - 1].substring(0, 6)} /> : 'url not found');
      },
    },
    {
      title: 'f1',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'f1'],
      key: 'f1',
      render: (f1: number | undefined) => (
        <div>{f1 ?? '--'}</div>
      ),
    },
    {
      title: 'Precision',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'precision'],
      key: 'precision',
      render: (precision: number | undefined) => (
        <div>{precision ?? '--'}</div>
      ),
    },
    {
      title: 'Recall',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'recall'],
      key: 'recall',
      render: (recall: number | undefined) => (
        <div>{recall ?? '--'}</div>
      ),
    },
    {
      title: 'Support',
      dataIndex: ['trainIntentModelOutput', 'averageModelScores', 'support'],
      key: 'support',
      render: (support: number | undefined) => (
        <div>{support ?? '--'}</div>
      ),
    },
    {
      title: 'Last updated',
      dataIndex: ['abstractWorkflow', 'updateTime'],
      key: 'updateTime',
      render: (updateTime: string | undefined) => (
        <div>{updateTime ? dayjs(updateTime).format('MM/DD/YYYY h:mm a') : '--'}</div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey="name"
      dataSource={[evaluateData]}
      pagination={false}
    />
  );
});
