import { User } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { State } from './state';

/** General reducer for starting an user API. */
export function userApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed user API. */
export function userApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** List user success. */
export function listUserSuccess(state: State, action: PayloadAction<User[]>): State {
  return {
    ...state,
    status: 'succeeded',
    users: action.payload,
  };
}

/** Cet/Create user success. */
export function getOrCreateUserSuccess(state: State, action: PayloadAction<User>): State {
  const index = state.users.findIndex((user) => user.name === action.payload.name);
  const newUsers = cloneDeep(state.users);
  if (index === -1) {
    newUsers.push(action.payload);
  } else {
    newUsers.splice(index, 1, action.payload);
  }
  return {
    ...state,
    status: 'succeeded',
    users: newUsers,
  };
}
