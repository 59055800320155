import {
  ChatIcon,
  CustomerPortalIcon,
  TaxonomyIcon,
  CommentNotesIcon,
  RobotIcon,
  RocketIcon,
  TasksDashboardIcon,
} from './icons';

interface BasicMenuProps {
    id: string
    label: string
    icon: any
}

interface MenuItemProps extends BasicMenuProps {
    type: 'single'
    to: string
}

interface NestedMenuItemProps extends BasicMenuProps{
    type: 'submenu'
    submenu: {
        to: string
        label: string
    }[]
}

export const getMenu = ({
  experimentalFeatures = false,
}: {
  experimentalFeatures: boolean,
}) => {
  const menu: (MenuItemProps | NestedMenuItemProps)[] = [
    {
      type: 'single',
      id: 'my-tasks',
      label: 'My Tasks',
      icon: TasksDashboardIcon,
      to: 'my-tasks',
    },
    {
      type: 'single',
      id: 'customer-portal',
      label: 'Customer Portal',
      icon: CustomerPortalIcon,
      to: 'hex',
    },
    {
      type: 'submenu',
      id: 'discover',
      label: 'Discover',
      icon: ChatIcon,
      submenu: [
        {
          label: 'Conversations',
          to: 'conversation',
        },
        {
          label: 'Auto Analysis',
          to: 'topics',
        },
        {
          label: 'Analysis Workshop',
          to: 'analysisworkshop',
        },
        {
          label: 'Surveyor',
          to: 'surveyor',
        },
        {
          label: 'Bot Metrics',
          to: 'bot-metrics',
        },
      ],
    },
    {
      type: 'submenu',
      id: 'tasks',
      label: 'Tasks',
      icon: CommentNotesIcon,
      submenu: [
        {
          to: 'labeling',
          label: 'Labeling',
        },
        {
          to: 'qa',
          label: 'QA',
        },
        {
          to: 'annotations',
          label: 'Annotations',
        },
      ],
    },
    {
      type: 'submenu',
      id: 'concepts',
      label: 'Concepts',
      icon: TaxonomyIcon,
      submenu: [
        {
          to: 'entity',
          label: 'Entity',
        },
        {
          to: 'intent',
          label: 'Intents',
        },
        {
          to: 'conversation-outcome',
          label: 'Conversation Outcome',
        },
        {
          to: 'summarization',
          label: 'Summary',
        },
      ],
    },
    {
      type: 'submenu',
      id: 'modeling',
      label: 'Modeling',
      icon: RobotIcon,
      submenu: [
        {
          to: 'model-builder',
          label: 'Builder',
        },
        {
          to: 'model-wizard',
          label: 'Wizard',
        },
        {
          to: 'simulator',
          label: 'Simulator',
        },
        {
          to: 'dialogue-policy',
          label: 'Dialogue Policy',
        },
      ],
    },
    {
      type: 'submenu',
      id: 'deployment',
      label: 'Deployment',
      icon: RocketIcon,
      submenu: [
        {
          to: 'deployment/summary',
          label: 'Production Summary',
        },
        {
          to: 'deployment/history',
          label: 'History',
        },
        {
          to: 'deployment/new',
          label: 'New',
        },
      ],
    },
  ];

  return menu;
};
