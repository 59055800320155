import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { Annotation, BinaryValue, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import { createConfirm } from 'components/ConfirmModal';
import BinaryDecision from 'components/LabelingDecision/BinaryDecision/BinaryDecision';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import MultiTags from 'components/MultiTags';
import { GotoNextType } from 'pages/LabelingView';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface BinaryQaViewProps {
  concepts: Concept[];
  messages: Message[];
  predictions: Prediction[];
  context: MessageRawDataContextShown,
  setContext: (context: MessageRawDataContextShown) => void;
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
}

export function BinaryQaView({
  concepts,
  messages,
  predictions,
  context,
  setContext,
  targetMessage,
  messageAnnotationsMap,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  createAnnotation,
  upsertAnnotations,
} : BinaryQaViewProps) {
  // It can happen in QA that when we fetch annotations for current conversation,
  // it may contain annotations created by different tasks. We need to filter out those annotations.
  const filteredMessageAnnotationsMap = useMemo(() => {
    const annotations = Array.from(messageAnnotationsMap.values()).reduce((acc, annotations) => [...acc, ...annotations], []);
    const filteredAnnotations = annotations.filter((annotation) => annotation.rawData.messageRawData.predictionId === targetMessage.selectionContext?.qaContext?.predictionId);

    const map = new Map<string, Annotation[]>();

    filteredAnnotations.forEach((annotation) => {
      const messageId = annotation.rawData.messageRawData.v2MessageId;
      const messageAnnotations = map.get(messageId) || [];
      map.set(messageId, [...messageAnnotations, annotation]);
    });
    return map;
  }, [messageAnnotationsMap]);
  const messageConceptsNames = targetMessage?.conceptIds.map((id) => getConceptTitle(id));

  const [lastMessageConcept, setLastMessageConcept] = useState<string>();
  const targetMessageId = targetMessage?.v2MessageId;
  const targetMessageAnnotations = filteredMessageAnnotationsMap.get(targetMessageId);

  // Inform user that QA intent has changed
  useEffect(() => {
    if (!messageConceptsNames) return;

    if (lastMessageConcept && messageConceptsNames[0] !== lastMessageConcept) {
      createConfirm<boolean>({
        title: 'Just a heads-up',
        content: <><p>New intent:</p><MultiTags size="large" type="intent" tags={messageConceptsNames} /></>,
        buttons: [
          {
            text: 'Got it!',
            value: true,
          },
        ],
      });
    }
    setLastMessageConcept(messageConceptsNames[0]);
  }, [messageConceptsNames]);

  // Replace previous annotations with new annotations
  const submitAnnotations = useCallback((newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[]) => {
    upsertAnnotations(newAnnotations, 'message', targetMessageAnnotations);
  }, [targetMessageAnnotations, upsertAnnotations]);

  return (
    <div
      className={classNames([
        'labeling-view',
        'horizontal-layout',
      ])}
    >
      <div className="labeling-items-display">
        <LabelingItemsDisplay
          context={context}
          setContext={setContext}
          showContextButtonVisible={false}
          messages={messages}
          targetMessageId={targetMessageId}
          setTargetMessage={setTargetMessage}
          messageAnnotationsMap={filteredMessageAnnotationsMap}
          getConceptTitle={getConceptTitle}
          failedMessages={failedMessages}
        />
      </div>
      <div className="labeling-decision-panel">
        <BinaryDecision
          message={targetMessage}
          predictions={predictions}
          concepts={concepts}
          annotations={targetMessageAnnotations}
          createAnnotation={createAnnotation}
          submitAnnotations={submitAnnotations}
          getConceptTitle={getConceptTitle}
          contextShown={context}
          showUnsureButton={false}
          predictionLabeling
        />
      </div>
    </div>
  );
}
