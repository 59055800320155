import React, { useState } from 'react';
import { Button, Group, Title } from '@mantine/core';
import { ConfirmModal } from 'pages/ModelWizardProject/ConfirmModal';

export const EvalStep = (props) => {
  const { workflow, children } = props;
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <>
      <Group position="apart" mx={40} mb="sm" style={{ background: '#fff' }}>
        <Title order={2}>Review the scores below</Title>
        <Button disabled onClick={() => setOpened(true)}>Ready</Button>
      </Group>
      <Group align="flex-start" style={{ background: '#F1F3F4', flex: 1 }} px={40} py="sm" position="apart">
        {children}
      </Group>
      <ConfirmModal
        evaluateData={workflow}
        opened={opened}
        onCancel={() => setOpened(false)}
      />
    </>
  );
};
