/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Annotation, AnnotationValue, AnnotationValuePresenceType, AnnotationValueType, MessageRawData, MessageRawDataContextShown, SuggestionQaValueCompareModelValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Button, Grid, Space, Textarea, Text, Box, Group, Divider, useMantineTheme, ScrollArea } from '@mantine/core';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType, SuggestionActionPayloadSuggestionResult } from '@cresta/web-client/dist/cresta/ai_service/common';
import './SuggestionsDecision.scss';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { getId } from 'common/resourceName';
import { useHotkeys } from '@mantine/hooks';
import { FetchCandidateSuggestionTextsResponseCannedSuggestion } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { SuggestionsAutocomplete } from './SuggestionsAutocomplete';

interface SuggestionsDecisionMultipleModelsProps {
  loading?: boolean,
  message: Message,
  contextShown: MessageRawDataContextShown,
  createAnnotation: (
    messageRawData: MessageRawData,
    value: AnnotationValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => void;
  hasSecondModel: boolean;
  predictions1: Prediction[];
  predictions2: Prediction[];
  annotations: Annotation[];
  nextChat: () => void;
  cannedSuggestions?: FetchCandidateSuggestionTextsResponseCannedSuggestion[];
}

const DEFAULT_OPTIMAL_SUGGESTION: FetchCandidateSuggestionTextsResponseCannedSuggestion = {
  cannedSuggestionDocName: undefined,
  cannedSuggestionDocId: undefined,
  suggestionText: '',
};

export function SuggestionsDecisionMultipleModels({
  loading,
  message,
  contextShown,
  createAnnotation,
  submitAnnotations,
  hasSecondModel,
  predictions1,
  predictions2,
  annotations,
  nextChat,
  cannedSuggestions = [],
}: SuggestionsDecisionMultipleModelsProps) {
  const theme = useMantineTheme();
  const [commentNote, setCommentNote] = useState('');
  const [compareValue, setCompareValue] = useState<SuggestionQaValueCompareModelValue>();
  const [optimalSuggestion, setOptimalSuggestion] = useState<FetchCandidateSuggestionTextsResponseCannedSuggestion>({ ...DEFAULT_OPTIMAL_SUGGESTION });
  const [suggestionsAutocompleteVisible, setSuggestionsAutocompleteVisible] = useState(false);

  const isConversationLabeled = annotations?.length > 0;

  // All predictions suggestions flattened
  const allSuggestionResults: SuggestionActionPayloadSuggestionResult[] = useMemo(() => {
    const results: SuggestionActionPayloadSuggestionResult[] = [];
    [...predictions1, ...predictions2].forEach((prediction) => {
      (prediction.action?.suggestionPayload?.results || []).forEach((result) => {
        results.push(result);
      });
    });
    return results;
  }, [predictions1, predictions2]);

  const filteredCannedSuggestions: FetchCandidateSuggestionTextsResponseCannedSuggestion[] = useMemo(() => {
    const defaultSuggestions: FetchCandidateSuggestionTextsResponseCannedSuggestion[] = allSuggestionResults.map((suggestion) => ({
      suggestionText: suggestion.text,
    }));
    if (optimalSuggestion.suggestionText?.length > 1) {
      const lowercaseText = optimalSuggestion.suggestionText.toLowerCase();
      return [...defaultSuggestions, ...cannedSuggestions].filter((suggestion) => suggestion.suggestionText.toLowerCase().includes(lowercaseText));
    } else {
      return defaultSuggestions;
    }
  }, [allSuggestionResults, cannedSuggestions, optimalSuggestion?.suggestionText]);

  useEffect(() => {
    setOptimalSuggestion({ ...DEFAULT_OPTIMAL_SUGGESTION });
    if (annotations?.length) {
      const annotation = annotations.find((annotation) => annotation.value.suggestionQaValue);
      setCompareValue(annotation?.value.suggestionQaValue.compareModelValue);
      setCommentNote(annotation?.value.suggestionQaValue.note);
    } else {
      setCompareValue(null);
      setCommentNote('');
    }
  }, [message]);

  const optimalSuggestionAnnotation = useMemo(() => annotations.find((annotation) => annotation.value.predictionValue), [message, annotations]);

  useEffect(() => {
    if (optimalSuggestionAnnotation) {
      setOptimalSuggestion({
        suggestionText: optimalSuggestionAnnotation.value.predictionValue.optimalPredictionResult.textResult,
        cannedSuggestionDocName: optimalSuggestionAnnotation.value.predictionValue.optimalPredictionResult.cannedSuggestionDocName,
        cannedSuggestionDocId: undefined,
      });
    }
  }, [optimalSuggestionAnnotation?.name]);

  const handleConfirm = useCallback(() => {
    const annotations = [];

    // Compare value with note annotation
    const annotationCompareValue = createAnnotation({
      conversationId: message.conversationId,
      messageId: message.messageId,
      v2ConversationId: message.v2ConversationId,
      v2MessageId: message.v2MessageId,
      contextShown,
      spanStart: message.targetSpanStart,
      spanEnd: message.targetSpanEnd,
    }, {
      presenceType: AnnotationValuePresenceType.PRESENCE,
      type: AnnotationValueType.TYPE_MULTIPLE_CHOICE,
      suggestionQaValue: {
        singleModelValue: undefined,
        compareModelValue: compareValue,
        predictionsIdsModel1: predictions1.map((prediction) => getId('prediction', prediction.name)),
        predictionsIdsModel2: predictions2.map((prediction) => getId('prediction', prediction.name)),
        note: commentNote || undefined,
      },
    });
    annotations.push(annotationCompareValue);

    // Optimal suggestion annotation
    const annotationOptimal = createAnnotation({
      conversationId: message.conversationId,
      messageId: message.messageId,
      v2ConversationId: message.v2ConversationId,
      v2MessageId: message.v2MessageId,
      contextShown,
      spanStart: message.targetSpanStart,
      spanEnd: message.targetSpanEnd,
    }, {
      presenceType: AnnotationValuePresenceType.PRESENCE,
      type: AnnotationValueType.TYPE_PREDICTION,
      predictionValue: {
        optimalPredictionResult: {
          textResult: optimalSuggestion?.suggestionText,
          cannedSuggestionDocName: optimalSuggestion?.cannedSuggestionDocName,
        },
      },
    });

    annotations.push(annotationOptimal);

    submitAnnotations(annotations, 'message');
  }, [message, predictions1, predictions2, getId, submitAnnotations, commentNote, compareValue, optimalSuggestion]);

  // Hotkeys
  useHotkeys([
    ['b', () => {
      if (hasSecondModel) {
        setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH);
      }
    }],
    ['n', () => {
      if (hasSecondModel) {
        setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER);
      }
    }],
    ['1', () => {
      if (hasSecondModel) {
        setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_1);
      }
    }],
    ['2', () => {
      if (hasSecondModel) {
        setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_2);
      }
    }],
    ['s', () => {
      nextChat();
    }],
    ['c', () => {
      handleConfirm();
    }],
  ]);

  // Check if all rubrics have scores
  const canConfirm = !!compareValue;

  return (
    <div className="suggestions-decision">
      <ScrollArea style={{ height: '100%' }}>
        <Box p="lg">
          <>
            <Text size="md" weight={500} mb="xs">Which model returned high-quality suggestions?</Text>
            <Grid gutter="sm">
              <Grid.Col span={6}>
                <Button
                  variant="light"
                  fullWidth
                  color="orange"
                  disabled={loading}
                  data-active={compareValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_1}
                  onClick={() => setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_1)}
                >
                  Model 1 (1)
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>

                <Button
                  variant="light"
                  fullWidth
                  color="purple"
                  disabled={loading}
                  data-active={compareValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_2}
                  onClick={() => setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_MODEL_2)}
                >
                  Model 2 (2)
                </Button>
              </Grid.Col>
            </Grid>
            <Space h="md" />
            <Button
              variant="light"
              fullWidth
              color="red"
              disabled={loading}
              data-active={compareValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER}
              onClick={() => setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_NEITHER)}
            >
              Neither (N)
            </Button>
            <Space h="md" />
            <Button
              variant="light"
              fullWidth
              color="green"
              disabled={loading}
              data-active={compareValue === SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH}
              onClick={() => setCompareValue(SuggestionQaValueCompareModelValue.COMPARE_MODEL_VALUE_BOTH)}
            >
              Both (B)
            </Button>
          </>
          <Box mt="lg" style={{ position: 'relative' }}>
            <Text size="md" weight={500}>What would be the optimal suggestion for this message? (optional)</Text>
            <Space h="xs"/>
            <SuggestionsAutocomplete
              visible={suggestionsAutocompleteVisible}
              setVisible={setSuggestionsAutocompleteVisible}
              suggestion={optimalSuggestion}
              suggestions={filteredCannedSuggestions}
              setSuggestion={setOptimalSuggestion}
            />
          </Box>
          <div className="note">
            <Text size="md" weight={500}>Notes</Text>
            <Space h="xs"/>
            <Textarea placeholder="Any comments or notes?" value={commentNote} onChange={(e) => setCommentNote(e.currentTarget.value)}/>
          </div>
          <Space h="md" />
        </Box>
      </ScrollArea>
      <Box>
        <Divider color={theme.colors.gray[1]} m={0}/>
        <Group grow p="lg">
          <Button
            variant="light"
            onClick={nextChat}
          >Skip (S)
          </Button>
          <Button
            variant="light"
            color="green"
            disabled={!canConfirm}
            data-active={canConfirm && isConversationLabeled}
            data-cy="confirm-button"
            onClick={handleConfirm}
          >Confirm (C)
          </Button>
        </Group>
      </Box>
    </div>
  );
}
