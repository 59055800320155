import {
  CustomIntentService,
  PreviewIntentPredictionsRequest,
  PreviewIntentPredictionsResponse,
  RefreshGrayAreaRequest,
  RefreshGrayAreaResponse,
  WarmupElasticSearchRequest,
  WarmupElasticSearchResponse,
  CheckDataAvailabilityRequest,
  CheckDataAvailabilityResponse,
} from '@cresta/web-client';
import { StudioApi } from './studioApi';

export abstract class CustomIntentApi {
  public static refreshGrayArea(request: RefreshGrayAreaRequest): Promise<RefreshGrayAreaResponse> {
    return CustomIntentService.RefreshGrayArea(request, StudioApi.getHeaders().initReq);
  }

  public static previewIntentPredictions(request: PreviewIntentPredictionsRequest): Promise<PreviewIntentPredictionsResponse> {
    return CustomIntentService.PreviewIntentPredictions(request, StudioApi.getHeaders().initReq);
  }

  public static warmupElasticSearch(request: WarmupElasticSearchRequest): Promise<WarmupElasticSearchResponse> {
    return CustomIntentService.WarmupElasticSearch(request, StudioApi.getHeaders().initReq);
  }

  public static checkDataAvailability(request: CheckDataAvailabilityRequest): Promise<CheckDataAvailabilityResponse> {
    return CustomIntentService.CheckDataAvailability(request, StudioApi.getHeaders().initReq);
  }
}
