import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Annotation, MessageRawData, MessageRawDataContextShown, TextValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ActionIcon, Button, Checkbox, createStyles, Group, Kbd, Space, Stack, Text, Textarea, TextareaProps, Tooltip, UnstyledButton } from '@mantine/core';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { getId } from 'common/resourceName';
import { CloseCircleOutlined, CopyOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './SummarizationDecision.scss';
import { cloneDeep } from 'lodash';
import { getHotkeyHandler, useClipboard, useHotkeys } from '@mantine/hooks';
import { countWords } from './utils';

interface SummarizationDecisionProps {
  concepts: Concept[],
  message: Message,
  annotations: Annotation[],
  contextShown: MessageRawDataContextShown,
  createAnnotation: (
    messageRawData: MessageRawData,
    textValue: TextValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => void;
  setOverviewConceptId: (id: string) => void;
  nextChat: () => void;
}

interface TopicSummary {
  values: string[]
  checkbox: boolean
}

const MAX_WORD_COUNT = 100;
const MIN_WORD_COUNT = 20;

// Popup that explains hotkeys
function HotkeysInfo() {
  return (
    <Stack p="md">
      <Group spacing="xs">
        <Kbd>Enter</Kbd><Text>Jump to next topic / Finish writing</Text>
      </Group>
      <Group spacing="xs">
        <Kbd>Shift</Kbd>+<Kbd>Enter</Kbd><Text>Add response field</Text>
      </Group>
      <Group spacing="xs">
        <Kbd>Shift</Kbd>+<Kbd>N</Kbd><Text>Mark checkbox</Text>
      </Group>
      <Group spacing="xs">
        <Kbd>Escape</Kbd><Text>Unfocus response field</Text>
      </Group>
      <Group spacing="xs">
        <Kbd>C</Kbd><Text>Confirm</Text>
      </Group>
      <Group spacing="xs">
        <Kbd>S</Kbd><Text>Skip</Text>
      </Group>
    </Stack>
  );
}

// Clickable text that autopopulates text area
function PrelabelText({ text, onClickHandler }: {
  text: string,
  onClickHandler: () => void,
}) {
  const clipboard = useClipboard();
  const useStyles = createStyles((theme) => ({
    prelabelingText: {
      fontSize: theme.fontSizes.sm,
      color: theme.colors.gray[5],
      '&:hover': {
        color: theme.primaryColor,
      },
    },
  }));
  const styles = useStyles();
  return (
    <UnstyledButton
      className={styles.classes.prelabelingText}
      onClick={() => {
        onClickHandler();
        clipboard.copy(text);
      }}
    >
      <Text size="sm" color="gray">🤖&nbsp;GPT3 says:</Text>
      {text}&nbsp;
      <CopyOutlined/>
    </UnstyledButton>
  );
}

// Textarea with delete button
function ResponseTextarea({ textAreaProps, deleteResponse, canDelete, positionTuple }: {
  textAreaProps: TextareaProps,
  deleteResponse: () => void,
  canDelete?: boolean,
  positionTuple: [number, number],
}) {
  return (
    <div style={{ position: 'relative' }} data-focus-position={positionTuple}>
      <Group position="apart">
        <Textarea
          autosize
          minRows={2}
          styles={{
            root: {
              width: '100%',
            },
            input: {
              paddingRight: canDelete ? '30px' : 0,
            },
          }}
          {...textAreaProps}
          placeholder="Type here"
        />
        {canDelete && (
          <ActionIcon
            style={{
              position: 'absolute',
              right: '5px',
            }}
            onClick={deleteResponse}
          ><CloseCircleOutlined/>
          </ActionIcon>
        )}
      </Group>
      <Space h="sm"/>
    </div>
  );
}

export default function SummarizationDecision({
  concepts = [],
  message,
  annotations = [],
  contextShown,
  createAnnotation,
  submitAnnotations,
  setOverviewConceptId,
  nextChat,
}: SummarizationDecisionProps) {
  const [summariesMap, setSummariesMap] = useState<Map<string, TopicSummary>>(new Map());
  // Focus state [Section, textarea]
  const [focusedTextareaIndex, setFocusedTextareaIndex] = useState([0, 0]);

  const conversationLabeled = annotations?.length > 0;

  const updateTopicSummary = (values: TopicSummary, conceptId: string) => {
    const summariesMapCopy = new Map(summariesMap);
    summariesMapCopy.set(conceptId, values);
    setSummariesMap(summariesMapCopy);
  };

  const conceptAnnotationMap = useMemo(() =>
    new Map<string, Annotation>(annotations.map((annotation) => [annotation.value.textValue.conceptId, annotation])), [annotations]);

  // Count words from all annotation values
  const wordCount = useMemo(() => {
    let words = 0;
    for (const [, summary] of summariesMap) {
      words += summary.values.reduce((acc, value) => {
        const valueWords = countWords(value);
        return acc + valueWords;
      }, 0);
    }

    return words;
  }, [summariesMap]);

  const isValidWordCount = useMemo(() => wordCount <= MAX_WORD_COUNT && wordCount >= MIN_WORD_COUNT, [wordCount]);

  useEffect(() => {
    const summariesMapCopy = new Map(concepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      // If this conversation is not labeled, populate with default values
      const conceptAnnotation = conceptAnnotationMap.get(conceptId);
      const conceptValues: TopicSummary = {
        values: conceptAnnotation?.value.textValue.values || [''],
        checkbox: conversationLabeled,
      };
      if (conceptAnnotation) {
        conceptValues.values = conceptAnnotation.value.textValue.values;
        conceptValues.checkbox = false;
      }

      return [conceptId, conceptValues];
    }));
    setSummariesMap(summariesMapCopy);
    setTimeout(() => {
      focusFirstTextarea();
    }, 0);
  }, [annotations]);

  // Change info panel based on focused textarea
  useEffect(() => {
    const focusedSummaryIndex = focusedTextareaIndex[0];
    const concept = concepts[focusedSummaryIndex];
    setOverviewConceptId(getId('concept', concept?.name));
  }, [focusedTextareaIndex]);

  // Focus the first text area
  const focusFirstTextarea = useCallback(() => {
    const responseElements = document.querySelectorAll('[data-focus-position]');
    responseElements[0]?.querySelector('textarea').focus();
  }, []);

  // Focus next textarea in order
  const focusNextTextarea = useCallback((e?: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const responseElements = document.querySelectorAll('[data-focus-position]');

    const focusedIndex = Array.from(responseElements).findIndex((el) => el.getAttribute('data-focus-position') === String(focusedTextareaIndex));
    const nextTextarea = responseElements[focusedIndex + 1];
    // If it's the last textarea, unfocus it and return
    if (!nextTextarea) {
      responseElements[focusedIndex].querySelector('textarea').blur();
      return;
    }

    nextTextarea.querySelector('textarea').focus();
  }, [focusedTextareaIndex]);

  // Unfocus (blur) textarea
  const unfocusTextarea = useCallback(() => {
    (document.querySelector(':focus') as HTMLElement).blur();
  }, []);

  const checkAndContinue = useCallback((summary: TopicSummary, conceptId: string) => {
    updateTopicSummary({
      ...summary,
      checkbox: true,
    }, conceptId);
    focusNextTextarea();
  }, [updateTopicSummary]);

  const addResponse = useCallback((conceptId: string) => {
    const summariesMapCopy = new Map(summariesMap);
    const summary = cloneDeep(summariesMapCopy.get(conceptId));
    summary.values = [...summary.values, ''];
    summariesMapCopy.set(conceptId, summary);
    setSummariesMap(summariesMapCopy);
  }, [summariesMap, setSummariesMap]);

  const deleteResponse = useCallback((conceptId: string, index: number) => {
    const summariesMapCopy = new Map(summariesMap);
    const summary = cloneDeep(summariesMapCopy.get(conceptId));
    summary.values.splice(index, 1);
    summariesMapCopy.set(conceptId, summary);
    setSummariesMap(summariesMapCopy);
  }, [summariesMap, setSummariesMap]);

  // User must take action for each presented question
  const canConfirm = useMemo(() => concepts.every((concept) => {
    const conceptId = getId('concept', concept.name);
    const summary = summariesMap.get(conceptId);
    const allSummariesFilled = (summary?.checkbox || summary?.values?.some((value) => value));
    return summary && isValidWordCount && allSummariesFilled;
  }), [concepts, summariesMap, getId, isValidWordCount]);

  const confirmDecision = useCallback(() => {
    if (!canConfirm) return;

    // Create annotations for every concept with text value
    const newAnnotations = concepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      const summary = summariesMap.get(conceptId);

      // No action checkbox
      if (summary.checkbox) return null;

      return createAnnotation({
        conversationId: message.conversationId,
        v2ConversationId: message.v2ConversationId,
        contextShown,
        spanStart: message.targetSpanStart,
        spanEnd: message.targetSpanEnd,
      }, {
        // Filter out empty values
        values: summary.values.filter((value) => value),
        conceptId,
      });
    }).filter((annotation) => annotation);
    submitAnnotations(newAnnotations, 'message');
  }, [canConfirm, message, concepts, summariesMap, getId, createAnnotation, submitAnnotations]);

  useHotkeys([
    ['c', confirmDecision],
    ['s', nextChat],
  ]);

  const preLabelingData = useMemo(() => {
    const summarizationContext = message?.selectionContext?.summarizationContext;
    const suggestions = { ...summarizationContext?.suggestionByTag, ...summarizationContext?.suggestionByTagModel2 };

    const conceptSuggestionsMap = new Map<string, string>();

    Object.keys(suggestions).forEach((key) => {
      const conceptTitles = key.split('/');
      conceptTitles.forEach((title) => {
        conceptSuggestionsMap.set(title, suggestions[key]);
      });
    });

    return conceptSuggestionsMap;
  }, [message, concepts]);

  const sortedConcepts = useMemo(() => cloneDeep(concepts).sort((a, b) => String(a.conceptTitle).localeCompare(b.conceptTitle)), [concepts]);

  return (
    <div className="summarization-decision">
      <Stack p="lg" style={{ overflow: 'auto', height: '100%' }}>
        <Group position="apart">
          <Group>
            <Text>Total word count:</Text>
            <Text color={isValidWordCount ? 'green' : 'red'}>{wordCount}/{MAX_WORD_COUNT}</Text>
          </Group>
          <Tooltip label={<HotkeysInfo/>}>
            <Group>
              <Kbd>⌘</Kbd>
            </Group>
          </Tooltip>
        </Group>
        {sortedConcepts.map((concept, conceptIndex) => {
          const conceptId = getId('concept', concept.name);
          const summary = summariesMap.get(conceptId);
          return (
            <div key={concept.name}>
              <Group position="apart">
                <p className="section-title" style={{ width: '80%' }}>{concept?.summarization?.question}</p>
                <ActionIcon
                  color="gray"
                  radius="sm"
                  onClick={() => setOverviewConceptId(conceptId)}
                >
                  <InfoCircleOutlined/>
                </ActionIcon>
              </Group>
              {preLabelingData?.get(concept.conceptTitle) && (
                <PrelabelText
                  text={preLabelingData?.get(concept.conceptTitle)}
                  onClickHandler={() => {
                    const updatedValues = cloneDeep(summary.values);
                    updatedValues[0] = preLabelingData?.get(concept.conceptTitle);
                    updateTopicSummary({
                      ...summary,
                      values: updatedValues,
                    }, conceptId);
                  }}
                />
              )}
              <Space h="xs" />
              {summary?.values.map((value, index) => (
                <ResponseTextarea
                  deleteResponse={() => deleteResponse(conceptId, index)}
                  canDelete={summary.values.length > 1}
                  positionTuple={[conceptIndex, index]}
                  textAreaProps={{
                    disabled: summary?.checkbox,
                    value: value || '',
                    onFocus: () => setFocusedTextareaIndex([conceptIndex, index]),
                    onKeyDown: getHotkeyHandler([
                      ['enter', focusNextTextarea],
                      ['shift+enter', () => addResponse(conceptId)],
                      // Mark as checked
                      ['shift+n', () => checkAndContinue(summary, conceptId)],
                      ['escape', () => unfocusTextarea()],
                    ]),
                    onChange: (e) => {
                      const updatedValues = cloneDeep(summary.values);
                      updatedValues[index] = e.target.value;
                      updateTopicSummary({
                        ...summary,
                        values: updatedValues,
                      }, conceptId);
                    },
                  }}
                />
              ))}
              <Group position="apart">
                <Checkbox
                  label={`No ${concept.conceptTitle}`}
                  checked={summary?.checkbox}
                  onChange={(e) => {
                    const conceptId = getId('concept', concept.name);
                    const summary = summariesMap.get(conceptId);
                    updateTopicSummary({
                      ...summary,
                      checkbox: e.currentTarget.checked,
                    }, conceptId);
                  }}
                />
                <Button
                  color="blue"
                  variant="light"
                  compact
                  size="xs"
                  onClick={() => addResponse(conceptId)}
                  data-cy="add-response-button"
                ><PlusOutlined/>
                </Button>
              </Group>
            </div>
          );
        })}
      </Stack>
      <Group grow p="lg" style={{ flex: 1 }}>
        <Button
          variant="light"
          color="green"
          onClick={() => confirmDecision()}
          disabled={!canConfirm}
          data-active={conversationLabeled}
          data-cy="confirm-button"
        >Confirm (C)
        </Button>
        <Button
          variant="light"
          disabled={conversationLabeled}
          onClick={nextChat}
        >Skip (S)
        </Button>
      </Group>
    </div>
  );
}
