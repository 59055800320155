import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { PayloadAction } from '@reduxjs/toolkit';
import { Model, ProdIntent, State } from './state';

/** Reducers for getServingModelDetailsFromAIServices. */
export function getServingModelDetailsFromAIServicesPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    loadServingModelFromAIServicesStatus: 'loading',
    servingModelsFromAIServices: [],
  };
}

export function getServingModelDetailsFromAIServicesFailed(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    loadServingModelFromAIServicesStatus: 'failed',
  };
}

export function getServingModelDetailsFromAIServicesSuccess(state: State, action: PayloadAction<Model[]>): State {
  return {
    ...state,
    loadServingModelFromAIServicesStatus: 'succeeded',
    servingModelsFromAIServices: action.payload,
  };
}

/** Reducers for getServingModelDetails. */
export function getServingModelDetailsPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    loadServingModelStatus: 'loading',
    servingModels: [],
  };
}

export function getServingModelDetailsFailed(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    loadServingModelStatus: 'failed',
  };
}

export function getServingModelDetailsSuccess(state: State, action: PayloadAction<Model[]>): State {
  return {
    ...state,
    loadServingModelStatus: 'succeeded',
    servingModels: action.payload,
  };
}

/** Reducers for getModelTaxonomies. */
export function getModelTaxonomiesPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    loadProdTaxonomiesStatus: 'loading',
    prodIntents: [],
  };
}

export function getModelTaxonomiesFailed(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    loadProdTaxonomiesStatus: 'failed',
  };
}

export function getModelTaxonomiesSuccess(state: State, action: PayloadAction<ProdIntent[]>): State {
  return {
    ...state,
    loadProdTaxonomiesStatus: 'succeeded',
    prodIntents: action.payload,
  };
}

export function getModelArtifactSuccess(state: State, action: PayloadAction<ModelArtifact>): State {
  if (!action.payload) {
    return state;
  }
  const index = state.openedArtifacts.findIndex((artifact) => artifact.name === action.payload.name);
  if (index < 0) {
    return {
      ...state,
      openedArtifacts: [...state.openedArtifacts, action.payload],
    };
  }
  const openedArtifacts = [...state.openedArtifacts];
  openedArtifacts[index] = action.payload;
  return {
    ...state,
    openedArtifacts,
  };
}
