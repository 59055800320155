import { useEffect } from 'react';
import { useWindowEventListener } from 'hooks/eventListeners';
import { useCustomerParams } from 'hooks/useCustomerParams';
import mixpanel from 'mixpanel-browser';
import { useDidUpdate } from '@mantine/hooks';
import { CLICK, CUSTOMER_CHANGE, KEY_PRESS, URL_CHANGE } from 'configuration/mixpanelEvents';
import { useLocation } from 'react-router-dom';

// TODO: add usecase/lang
export const useMixpanelTracking = () => {
  const location = useLocation();
  const { pathname } = location;
  const customerParams = useCustomerParams();
  const { customerId, profileId, path } = customerParams;

  // Mixpanel - capture clicks and key press events
  // Needed for more accurate session duration data
  useWindowEventListener('click', () => {
    mixpanel.track(CLICK);
  });
  useWindowEventListener('keypress', () => {
    mixpanel.track(KEY_PRESS);
  });

  // Track URL changes in Mixpanel (important for more accurate session lengths)
  useEffect(() => {
    mixpanel.track(URL_CHANGE, {
      Location: location,
    });
  }, [pathname]);

  // Only run when customer changes but not on inital load
  useDidUpdate(() => {
    mixpanel.track(CUSTOMER_CHANGE, {
      customerId,
      profileId,
    });
  }, [path]);
};
