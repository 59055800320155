/* eslint dot-notation: 0 */
/* eslint prefer-destructuring: 0 */
import React, { useCallback, useEffect, useRef } from 'react';
import Editor, { Monaco, DiffEditor } from '@monaco-editor/react';
import { editor, MarkerSeverity, IDisposable } from 'monaco-editor';
import { parse } from 'yaml';
import { IDPEditorProps } from './PythonEditor';
import './editor.scss';

export const YamlEditor: React.FC<IDPEditorProps> = ({
  currentTab,
  defaultValue,
  onCodeChange,
  diff,
  diffWith,
  originalValue,
  options,
}) => {
  const listenersRef = useRef<IDisposable[]>([]);
  const editorRef = useRef<editor.IStandaloneCodeEditor>(null);

  useEffect(() => (() => {
    listenersRef.current.forEach((d) => d.dispose());
    listenersRef.current = [];
  }), []);

  useEffect(() => {
    const model = editorRef.current?.getModel();
    model?.setValue(defaultValue);
  }, [currentTab]);

  const handleEditorWillMount = useCallback((monaco: Monaco) => {
    listenersRef.current.push(monaco.editor.onDidCreateModel((model: editor.ITextModel) => {
      if (model.uri.path.includes('draft')) {
        const validate = (code: string) => {
          const markers: editor.IMarkerData[] = [];
          try {
            parse(code);
          } catch (err) {
            const pos: { line: number; col: number }[] = err['linePos'];
            const code: string = err['code'];
            const name: string = err['name'];
            const message: string = err['message'];
            markers.push({
              startLineNumber: pos[0].line,
              startColumn: pos[0].col,
              endLineNumber: pos[1].line,
              endColumn: pos[1].col,
              message: `${code}: ${message}`,
              severity: name === 'YAMLParseError' ? MarkerSeverity.Error : MarkerSeverity.Warning,
            });
          }
          monaco.editor.setModelMarkers(model, 'yaml', markers);
        };

        let handler;
        listenersRef.current.push(model.onDidChangeContent(() => {
          const code = model.getValue();
          onCodeChange(code);
          clearTimeout(handler);
          handler = setTimeout(() => validate(code), 1000);
        }));
        validate(model.getValue());
      }
    }));
  }, []);

  const handleDiffEditorWillMount = useCallback((monaco: Monaco) => {
    listenersRef.current.push(monaco.editor.onDidCreateModel((model: editor.ITextModel) => {
      if (model.uri.path.includes('draft')) {
        const updateCode = () => {
          const code = model.getValue();
          onCodeChange(code);
        };
        listenersRef.current.push(model.onDidChangeContent((e: editor.IModelContentChangedEvent) => {
          updateCode();
        }));
      }
    }));
  }, []);

  return diff ? (
    <DiffEditor
      language="yaml"
      originalModelPath={diffWith}
      original={originalValue}
      modifiedModelPath={currentTab}
      modified={defaultValue}
      options={options}
      beforeMount={handleDiffEditorWillMount}
    />
  ) : (
    <Editor
      language="yaml"
      path={currentTab}
      value={defaultValue}
      beforeMount={handleEditorWillMount}
      options={options}
      onMount={(editor) => { editorRef.current = editor; }}
    />
  );
};
