import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Grid, Group, Paper, Stack, Title, Text, Divider, Box, useMantineTheme, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import { UserContext } from 'context/UserContext';
import { round } from 'lodash';
import React, { useMemo, useContext } from 'react';

type ClosedTaskStats = {
  closedToday: number;
  closed7days: number;
  closed30days: number;
  avgTaskTime: number;
}

interface TaskStatCardsProps {
  openedTasks: DerivedLabelingTask[];
  closedTasks: DerivedLabelingTask[];
  openAssigned: number;
  openUnassigned: number;
  loading: boolean;
}

function formatSecondsDiff(seconds: number) {
  let totalSeconds = seconds;
  const totalHours = Math.floor(totalSeconds / (60 * 60)); // How many hours?
  totalSeconds -= (totalHours * 60 * 60); // Pull those hours out of totalSeconds

  const totalMinutes = Math.floor(totalSeconds / 60); // With hours out this will retun minutes
  totalSeconds -= (totalMinutes * 60); // Again pull out of totalSeconds
  return `${totalHours}h ${totalMinutes}m ${round(totalSeconds)}s`;
}

export function TaskStatCards({
  openedTasks,
  closedTasks,
  openAssigned,
  openUnassigned,
  loading,
}: TaskStatCardsProps) {
  const theme = useMantineTheme();
  const currentUser = useContext(UserContext);

  const closedTaskStats: ClosedTaskStats = useMemo(() => {
    const now = dayjs();
    const ago7days = dayjs().subtract(7, 'day');
    const ago30days = dayjs().subtract(30, 'day');
    let closedToday = 0;
    let closed7days = 0;
    let closed30days = 0;
    let taskTimeSum = 0;

    closedTasks.forEach((task) => {
      const taskCreateTime = dayjs(task.labelingTask.abstractTask.createTime);
      const taskEndTime = dayjs(task.labelingTask.abstractTask.endTime);
      const timeDiff = taskEndTime.diff(taskCreateTime, 'seconds');
      taskTimeSum += timeDiff;
      const isToday = taskCreateTime.isSame(now, 'day');
      const is7days = taskCreateTime.isAfter(ago7days);
      const is30days = taskCreateTime.isAfter(ago30days);

      if (isToday) {
        closedToday += 1;
      }
      if (is7days) {
        closed7days += 1;
      }
      if (is30days) {
        closed30days += 1;
      }
    });

    const avgTaskTime = taskTimeSum / closedTasks.length;

    return {
      closed30days,
      closed7days,
      closedToday,
      avgTaskTime: Number.isNaN(avgTaskTime) ? 0 : avgTaskTime,
    };
  }, [openedTasks, closedTasks, currentUser]);

  if (loading) {
    return (
      <Grid gutter="xl">
        <Grid.Col span={4}>
          <Title order={2} mb="xs">Open tasks</Title>
          <Skeleton h={140}/>
        </Grid.Col>
        <Grid.Col span={4}>
          <Title order={2} mb="xs">Closed tasks</Title>
          <Skeleton h={140}/>
        </Grid.Col>
        <Grid.Col span={4}>
          <Title order={2} mb="xs">Other</Title>
          <Skeleton h={140}/>
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Grid gutter="xl">
      <Grid.Col span={4}>
        <Title order={2} mb="xs">Open tasks</Title>
        <Paper p="xl" h={140}>
          <Stack>
            <Group spacing="xs">
              <Text size="xl" weight={600}>{openAssigned}</Text>
              <Text color={theme.colors.gray[7]} size="xl"> Assigned</Text>
            </Group>
            <Divider color={theme.colors.gray[1]}/>
            <Group spacing="xs">
              <Text size="xl" weight={600}>{openUnassigned}</Text>
              <Text color={theme.colors.gray[7]} size="xl"> Unassigned</Text>
            </Group>
          </Stack>
        </Paper>
      </Grid.Col>
      <Grid.Col span={4}>
        <Title order={2} mb="xs">Closed tasks</Title>
        <Paper p="xl" h={140}>
          <Group>
            <Box>
              <Text size="md">Today</Text>
              <Text size={36} color="black" weight={500} mt="sm">{closedTaskStats.closedToday}</Text>
            </Box>
            <Divider orientation="vertical" color={theme.colors.gray[1]}/>
            <Box>
              <Text size="md">Last 7 days</Text>
              <Text size={36} color="black" weight={500} mt="sm">{closedTaskStats.closed7days}</Text>
            </Box>
            <Divider orientation="vertical" color={theme.colors.gray[1]}/>
            <Box>
              <Text size="md">Last 30 days</Text>
              <Text size={36} color="black" weight={500} mt="sm">{closedTaskStats.closed30days}</Text>
            </Box>
          </Group>
        </Paper>
      </Grid.Col>
      <Grid.Col span={4}>
        <Title order={2} mb="xs">Other</Title>
        <Paper p="xl" h={140}>
          <Text size="md">Average task time</Text>
          {closedTaskStats.avgTaskTime != null && (
            <Text size={36} color="black" weight={500} mt="sm">{formatSecondsDiff(closedTaskStats.avgTaskTime)}</Text>
          )}
        </Paper>
      </Grid.Col>
    </Grid>
  );
}
