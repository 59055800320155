import React, { createContext, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { openNotification } from 'components/Notification';
import { useLocation, Navigate } from 'react-router-dom';
import { CustomerParams, DEFAULT_CUSTOMER_CONFIG, setLastVisitedCustomer, useCustomerParams } from 'hooks/useCustomerParams';
import Loading from 'pages/Loading';
import { OktaAuthContext } from './OktaAuthContext';
import { StudioApi } from '../services/studioApi';
import { ConfigApi } from '../services/configApi';

// Value provided by the context.
export interface CustomerConfig {
  currentConfig: Config;
  allConfigs: Config[];
  // Get the first matching config given the customer params. Empty fields in the customer params are ignored.
  getFirstConfig: (customer: CustomerParams) => Config|undefined;
}

export const CustomerConfigContext = createContext<CustomerConfig>({
  currentConfig: DEFAULT_CUSTOMER_CONFIG,
  allConfigs: [DEFAULT_CUSTOMER_CONFIG],
  getFirstConfig: (customer: CustomerParams) => ({}),
});

export const CustomerConfigProvider = ({ children }) => {
  const { pathname } = useLocation();
  const { signOutOkta } = useContext(OktaAuthContext);
  const [currentConfig, setCurrentConfig] = useState<Config|null>(null);
  const [customerConfigs, setCustomerConfigs] = useState<Config[]>([]);

  // Get the first config that matches the customer params.
  const getFirstConfig = useCallback((customer: CustomerParams): Config|null => {
    for (const config of customerConfigs) {
      if ((!customer.customerId || config.customerId === customer.customerId)
        && (!customer.profileId || config.profileId === customer.profileId)
        && (!customer.usecaseId || config.usecaseId === customer.usecaseId)
        && (!customer.languageCode || config.defaultLanguageCode === customer.languageCode || config.supportedLanguageCodes?.includes(customer.languageCode))) {
        return config;
      }
    }
    return null;
  }, [customerConfigs]);

  const { customerId, profileId, usecaseId, languageCode } = useCustomerParams();
  const nextConfig = useMemo(() => getFirstConfig({ customerId, profileId, usecaseId, languageCode }), [customerId, profileId, usecaseId, languageCode, customerConfigs]);
  const defaultConfig = useMemo(() => (customerConfigs.length > 0 ? customerConfigs[0] : null), [customerConfigs]);
  const loadCustomerConfigs = useCallback(async () => {
    try {
      const response = await ConfigApi.listActiveConfigs();
      setCustomerConfigs(
        response.configs?.sort(
          (a, b) => (a.customerId?.localeCompare(b.customerId || '')
          || a.profileId?.localeCompare(b.profileId || '')
          || a.usecaseId?.localeCompare(b.usecaseId || '')) || 0,
        ) || [],
      );
    } catch (err) {
      openNotification('info', 'Failed to load customer configs, please log in again.');
      signOutOkta();
    }
  }, []);

  useEffect(() => {
    if (!customerConfigs.length) {
      loadCustomerConfigs();
    }
  }, []);

  useEffect(() => {
    if (!customerConfigs.length) {
      return;
    }
    if (!nextConfig) {
      openNotification(
        'info',
        `No config found for ${pathname}, route to ${defaultConfig?.customerId}/${defaultConfig?.profileId}/${defaultConfig?.usecaseId}/${defaultConfig?.defaultLanguageCode}`,
      );
    }
    if (currentConfig !== nextConfig) {
      setCurrentConfig(nextConfig);
      StudioApi.setCustomerConfig(nextConfig);
      setLastVisitedCustomer({ customerId, profileId, usecaseId, languageCode });
    }
  }, [nextConfig]);

  const value = useMemo(() => ({ currentConfig, allConfigs: customerConfigs, getFirstConfig }), [currentConfig, customerConfigs, getFirstConfig]);

  if (!customerConfigs.length || (nextConfig && currentConfig !== nextConfig)) {
    return <Loading />;
  }

  // Resolve null config.
  if (!nextConfig) {
    return (
      <Navigate
        to={{
          pathname: `/${defaultConfig.customerId}/${defaultConfig.profileId}/${defaultConfig.usecaseId}/${defaultConfig.defaultLanguageCode}`,
        }}
        state={{ from: pathname }}
        replace
      />
    );
  }
  return (
    <CustomerConfigContext.Provider value={value}>
      {children}
    </CustomerConfigContext.Provider>
  );
};

// Commonly used customer configs:
export const CHAT_SANDBOX_CONFIG: Config = {
  customerId: 'cresta',
  profileId: 'chat-sandbox',
  domain: 'chat-sandbox.cresta.com',
  environmentId: 'chat-prod',
};
export const SHARED_LIBRARY_CONFIG: Config = {
  customerId: 'cresta',
  profileId: 'studio-shared-library',
  domain: 'studio-shared-library.cresta.com',
  environmentId: 'chat-prod',
};
