import { createSlice } from '@reduxjs/toolkit';
import {
  annotationApiFailed,
  annotationApiPending,
  deprecateAnnotationsSuccess,
  listAnnotationsSuccess,
  updateSummaryFilters as updateSummaryFiltersReducer,
  updateAnnotationFilters as updateAnnotationFiltersReducer,
  resetAnnotationsReducer,
  fetchAnnotationSummarySuccess,
  searchAnnotationsSuccess,
  upsertAnnotationsSuccess,
  fetchCalibrationAnnotationsSuccess,
} from './reducers';
import {
  deprecateAnnotations,
  listAnnotations,
  fetchAnnotationSummary,
  searchAnnotations,
  upsertAnnotations,
  fetchCalibrationAnnotations,
} from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Annotation slice. */
export const annotationSlice = createSlice({
  name: 'annotation',
  initialState: INITIAL_STATE,
  reducers: {
    updateSummaryFilters: updateSummaryFiltersReducer,
    updateAnnotationFilters: updateAnnotationFiltersReducer,
    resetAnnotations: resetAnnotationsReducer,
  },
  extraReducers: (builder) =>
    builder.addCase(listAnnotations.pending, annotationApiPending)
      .addCase(listAnnotations.rejected, annotationApiFailed)
      .addCase(listAnnotations.fulfilled, listAnnotationsSuccess)
      .addCase(fetchAnnotationSummary.pending, annotationApiPending)
      .addCase(fetchAnnotationSummary.fulfilled, fetchAnnotationSummarySuccess)
      .addCase(fetchAnnotationSummary.rejected, annotationApiFailed)
      .addCase(fetchCalibrationAnnotations.pending, annotationApiPending)
      .addCase(fetchCalibrationAnnotations.rejected, annotationApiFailed)
      .addCase(fetchCalibrationAnnotations.fulfilled, fetchCalibrationAnnotationsSuccess)
      .addCase(searchAnnotations.pending, annotationApiPending)
      .addCase(searchAnnotations.fulfilled, searchAnnotationsSuccess)
      .addCase(searchAnnotations.rejected, annotationApiFailed)
      .addCase(deprecateAnnotations.fulfilled, deprecateAnnotationsSuccess)
      .addCase(upsertAnnotations.pending, annotationApiPending)
      .addCase(upsertAnnotations.rejected, annotationApiFailed)
      .addCase(upsertAnnotations.fulfilled, upsertAnnotationsSuccess),
});

/** Actions for sync reducers. */
export const {
  updateSummaryFilters,
  updateAnnotationFilters,
  resetAnnotations,
} = annotationSlice.actions;
