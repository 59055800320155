import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import classNames from 'classnames';
import React from 'react';

import './Loading.scss';

interface IProps {
  size?: SpinSize;
  text?: string;
  page?: boolean;
  inline?: boolean;
}

export default function Loading({ size = 'default', inline = true, text, page }: IProps) {
  if (page) {
    return (
      <div className="loading-component">
        <Spin size="large" tip={text || 'Loading...'} className="page" indicator={<LoadingOutlined spin/>} />
      </div>
    );
  }

  return (
    <Spin size={size} tip={text} className={classNames({ 'loading-component-inline': inline })} indicator={<LoadingOutlined spin/>} />
  );
}
