import { Set } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { ApiStatus } from 'store/types';

/** Set state. */
export interface State {
  sets: Set[];
  status: ApiStatus;
}

/** Initial set state. */
export const INITIAL_STATE: State = {
  sets: [],
  status: 'idle',
};
