import qs from 'query-string';
import { NavigateFunction } from 'react-router-dom';

const ARRAY_FORMAT = 'index';

export const getNextUrlParamStr = (params: { [key: string]: string | string[] }) => {
  const currentParams = qs.parse(window.location.search, { arrayFormat: ARRAY_FORMAT });
  const stringParams = qs.stringify({ ...currentParams, ...params }, { arrayFormat: ARRAY_FORMAT });
  return `?${stringParams}`;
};

export const setUrlParams = (
  params: { [key: string]: string | string[] },
  navigate: NavigateFunction,
) => {
  navigate(getNextUrlParamStr(params));
};

export const setUrlParam = (
  key: string,
  value: string | string[],
  navigate: NavigateFunction,
) => {
  setUrlParams({ [key]: value }, navigate);
};

export const getUrlParam = (key: string, locationSearch: string) => {
  const params = qs.parse(locationSearch, { arrayFormat: ARRAY_FORMAT });
  return params[key];
};

export const getUrlParamStrAfterDelete = (keys: string[]) => {
  const params = qs.parse(window.location.search, { arrayFormat: ARRAY_FORMAT });
  for (const key of keys) {
    delete params[key];
  }
  const stringParams = qs.stringify(params, { arrayFormat: ARRAY_FORMAT });
  return decodeURIComponent(`?${stringParams}`);
};

export const deleteUrlParams = (keys: string[], navigate: NavigateFunction) => {
  navigate(getUrlParamStrAfterDelete(keys));
};

export const deleteUrlParam = (key: string, navigate: NavigateFunction) => {
  deleteUrlParams([key], navigate);
};
