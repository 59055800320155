import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Select, Input, Tag, Tooltip, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { fetchCalibrationAnnotations } from 'store/annotation/asyncThunks';
import { CalibrationSummary } from 'store/annotation/state';
import { selectApiStatus, selectCalibrationSummary } from 'store/annotation/selectors';
import { ApiStatus, CalibrationTaskSet } from 'store/types';
import { selectCalibrationTaskSetFactory } from 'store/labelingTask/selectors';
import { LabelingTask, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { BinaryValueValue, Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import MultiTags from 'components/MultiTags';
import TaskTypeTag from 'components/TaskTypeTag';
import Loading from 'components/Loading';
import { selectConceptsMapFactory } from 'store/concept/selectors';
import { selectUserByIdFactory } from 'store/user/selectors';
import { ConceptConceptType, Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ColumnsType } from 'antd/lib/table';
import { User } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { getId } from 'common/resourceName';
import UserTag from 'components/UserTag';
import { isEqual } from 'lodash';
import { Text } from '@mantine/core';
import styles from './styles.module.scss';

type LabelFilter = 'all' | 'disagreed' | 'no-skip' | 'no-unsure';

const BINARY_TASK_TYPES = new Set([
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING,
]);

const UNSURE = 'Unsure';
const SKIPPED = 'Skipped';

interface BinaryValue {
  value: BinaryValueValue;
  note?: string;
}

interface LabeledIntentsValue {
  value: string[];
  note?: string;
}

interface Record {
  id: string;
  utterance: string;
  binary?: {
    first: BinaryValue;
    second: BinaryValue;
    final: BinaryValue;
  };
  dense?: {
    first: LabeledIntentsValue;
    second: LabeledIntentsValue;
    final: LabeledIntentsValue;
  };
}

export function CalibrationTaskSummary(): ReactElement {
  const { path } = useCustomerParams();
  const { calibrationTaskId } = useParams<{ calibrationTaskId: string }>();
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const apiStatus = useSelector<ApiStatus>(selectApiStatus);

  useEffect(() => {
    dispatch(fetchCalibrationAnnotations(`${customerProfile}/labelingTasks/${calibrationTaskId}`));
  }, [calibrationTaskId, customerProfile]);

  const intentsMap = useSelector<Map<string, Concept>>(selectConceptsMapFactory(ConceptConceptType.INTENT));
  const taskSet = useSelector<CalibrationTaskSet>(selectCalibrationTaskSetFactory(calibrationTaskId));
  const binaryView = BINARY_TASK_TYPES.has(taskSet.originalTask?.userLabelingTaskType);
  const calibrationSummary = useSelector<CalibrationSummary>(selectCalibrationSummary);
  const hasNextPage = !!calibrationSummary.nextPageToken;
  const firstUser = useSelector<User>(selectUserByIdFactory(taskSet.originalTask?.labelingTask.abstractTask.assigneeUserId));
  const secondUser = useSelector<User>(selectUserByIdFactory(taskSet.dualTask?.labelingTask.abstractTask.assigneeUserId));
  const calibraUser = useSelector<User>(selectUserByIdFactory(taskSet.calibrationTask?.labelingTask.abstractTask.assigneeUserId));

  // Filters
  const [labelFilters, setLabelFilters] = useState<LabelFilter[]>(['all']);
  const [filterText, setFilterText] = useState<string>('');

  // Convert to Record
  const data: Record[] = useMemo(() => {
    if (binaryView) {
      return calibrationSummary.calibrationAnnotations.map((annotations) => ({
        id: getId('annotation', annotations.calibrationAnnotations[0].name),
        utterance: annotations.messageContent,
        binary: {
          first: {
            value: annotations.originalAnnotations[0].value.binaryValue.value,
            note: annotations.originalAnnotations[0].value.binaryValue.note,
          },
          second: {
            value: annotations.dualAnnotations[0].value.binaryValue.value,
            note: annotations.originalAnnotations[0].value.binaryValue.note,
          },
          final: {
            value: annotations.calibrationAnnotations[0].value.binaryValue.value,
            note: annotations.originalAnnotations[0].value.binaryValue.note,
          },
        },
      }));
    }
    return calibrationSummary.calibrationAnnotations.map((annotations) => ({
      id: getId('annotation', annotations.calibrationAnnotations[0].name),
      utterance: annotations.messageContent,
      dense: {
        first: getLabeledIntents(annotations.originalAnnotations, intentsMap),
        second: getLabeledIntents(annotations.dualAnnotations, intentsMap),
        final: getLabeledIntents(annotations.calibrationAnnotations, intentsMap),
      },
    }));
  }, [calibrationSummary, binaryView]);

  const filteredData = data.filter((record) => {
    if (!labelFilterPredicate(record, labelFilters)) {
      return false;
    }
    if (filterText && !record.utterance.toLowerCase().includes(filterText.toLowerCase())) {
      return false;
    }
    return true;
  });

  // Page info and load more annotations if on the last page.
  const [{ page, pageSize }, setPageInfo] = useState<{ page: number, pageSize: number }>({ page: 1, pageSize: 10 });
  const total = filteredData.length;
  const start = Math.min(((page - 1) * pageSize) + 1, total);
  const end = Math.min(page * pageSize, total);
  useEffect(() => {
    if (page >= total / pageSize && hasNextPage) {
      dispatch(fetchCalibrationAnnotations(`${customerProfile}/labelingTasks/${calibrationTaskId}`));
    }
  }, [total, page, pageSize, hasNextPage, customerProfile, calibrationTaskId]);
  const displayTotal = hasNextPage ? 'many' : `${total} total`;

  const renderLabel = (value: BinaryValue) => (
    <button
      type="button"
      disabled
      className={classNames([styles.labelButton, getLabelClass(value?.value)])}
    >
      {formatLabelValue(value?.value)}
    </button>
  );

  const renderLabels = (value: LabeledIntentsValue, record: Record) => (
    value.value.map((label) => (
      <div>
        <Tag className={classNames([getIntentLabelClass(label, record.dense.final.value), styles.intentLabel])}>{label}</Tag>
        {label === UNSURE && (
          <Tooltip className={styles.labelNote} title={value.note}>
            {value.note?.length > 20 ? `${value.note?.substring(0, 20)}...` : value.note}
          </Tooltip>
        )}
      </div>
    ))
  );

  const binaryViewColumns: ColumnsType<Record> = [
    {
      title: 'Utterance',
      dataIndex: 'utterance',
      key: 'utterance',
      ellipsis: true,
    },
    {
      title: () => (
        <div className={styles.calibrationSummaryTableHeader}>
          <span>First labeling task</span>
          <Tooltip title="This measures what % of positive labels for this task compared to the calibrator were the same">
            <div>Calibration: {calibrationSummary.originalTaskConsentPercentage}%</div>
          </Tooltip>
          <UserTag name={firstUser?.fullName || firstUser?.email || 'Unknown'} />
        </div>
      ),
      dataIndex: ['binary', 'first'],
      key: 'first',
      render: renderLabel,
      width: 200,
    },
    {
      title: () => (
        <div className={styles.calibrationSummaryTableHeader}>
          <span>Second labeling task</span>
          <Tooltip title="This measures what % of positive labels for this task compared to the calibrator were the same">
            <div>Calibration: {calibrationSummary.dualTaskConsentPercentage}%</div>
          </Tooltip>
          <UserTag name={secondUser?.fullName || firstUser?.email || 'Unknown'} />
        </div>
      ),
      dataIndex: ['binary', 'second'],
      key: 'second',
      render: renderLabel,
      width: 200,
    },
    {
      title: () => (
        <div className={styles.calibrationSummaryTableHeader}>
          <NavLink to={getAnnotationsPath(path, taskSet.calibrationTask?.labelingTask)}> Calibration task</NavLink>
          <UserTag name={calibraUser?.fullName || calibraUser?.email || 'Unknown'} />
        </div>
      ),
      dataIndex: ['binary', 'final'],
      key: 'final',
      render: renderLabel,
      width: 200,
    },
  ];

  const denseViewColumns: ColumnsType<Record> = [
    {
      title: 'Utterance',
      dataIndex: 'utterance',
      key: 'utterance',
      ellipsis: true,
    },
    {
      title: () => (
        <div className={styles.calibrationSummaryTableHeader}>
          <span>First labeling task</span>
          <Tooltip title="This measures what % of positive labels for this task compared to the calibrator were the same">
            <div>Calibration: {calibrationSummary.originalTaskConsentPercentage}%</div>
          </Tooltip>
          <UserTag name={firstUser?.fullName || firstUser?.email || 'Unknown'} />
        </div>
      ),
      dataIndex: ['dense', 'first'],
      key: 'first',
      render: renderLabels,
      width: 200,
    },
    {
      title: () => (
        <div className={styles.calibrationSummaryTableHeader}>
          <span>Second labeling task</span>
          <Tooltip title="This measures what % of positive labels for this task compared to the calibrator were the same">
            <div>Calibration: {calibrationSummary.dualTaskConsentPercentage}%</div>
          </Tooltip>
          <UserTag name={secondUser?.fullName || firstUser?.email || 'Unknown'} />
        </div>
      ),
      dataIndex: ['dense', 'second'],
      key: 'second',
      render: renderLabels,
      width: 200,
    },
    {
      title: () => (
        <div className={styles.calibrationSummaryTableHeader}>
          <span> Calibration task</span>
          <UserTag name={calibraUser?.fullName || calibraUser?.email || 'Unknown'} />
        </div>
      ),
      dataIndex: ['dense', 'final'],
      key: 'final',
      render: renderLabels,
      width: 200,
    },
  ];

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <div className={styles.calibrationSummaryHeader}>
        <h1>Calibration Task Summary</h1>
        <div className={styles.subTitle}>
          <div className={styles.label}>
            Labeling Task Type
          </div>
          <div className={styles.value}>
            <TaskTypeTag taskType={taskSet.originalTask?.userLabelingTaskType} />
          </div>
          {binaryView && (
            <>
              <div className={styles.label}>
                Intent
              </div>
              <div className={styles.value}>
                <MultiTags
                  type="intent"
                  tags={[intentsMap.get(taskSet.originalTask?.labelingTask.taskData.taskDescriptor.conceptIds[0])?.conceptTitle]}
                />
              </div>
            </>
          )}
          <Tooltip title="This measures what % of positive labels between labelers were the same">
            <div className={styles.value}>
              Consensus: {`${calibrationSummary.mutualConsentPercentage}%`}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={styles.filters}>
        <Text size="md">{`${start}-${end} of ${displayTotal}`}</Text>
        <span className={styles.spacer} />
        <Select
          mode="multiple"
          className={styles.labelValueSelect}
          showArrow
          size="large"
          placeholder="Label values"
          value={labelFilters}
          onChange={(value: LabelFilter[]) => {
            if (!labelFilters.includes('all') && value.includes('all')) {
              setLabelFilters(['all']);
            } else {
              setLabelFilters(value.filter((v) => v !== 'all'));
            }
          }}
          maxTagCount="responsive"
        >
          <Select.Option key="all" value="all">
            All Labels
          </Select.Option>
          <Select.Option key="disagreed" value="disagreed">
            Disagreed Only
          </Select.Option>
          <Select.Option key="no-skip" value="no-skip">
            Exclude Skips
          </Select.Option>
          <Select.Option key="no-unsure" value="no-unsure">
            Exclude Unsures
          </Select.Option>
        </Select>
        <Input
          className={styles.searchTextInput}
          addonAfter={<SearchOutlined />}
          placeholder="Search"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </div>
      <div className={styles.calibrationSummaryContent}>
        <Table
          className={styles.summaryTable}
          columns={binaryView ? binaryViewColumns : denseViewColumns}
          dataSource={filteredData}
          rowKey={(record: Record) => record.id}
          loading={{
            spinning: apiStatus === 'loading',
            indicator: <Loading />,
          }}
          pagination={{
            onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
          }}
        />
      </div>
    </div>
  );
}

function hasBinaryValue(record: Record, value: BinaryValueValue): boolean {
  if (record.binary) {
    return record.binary.first.value === value
      || record.binary.second.value === value
      || record.binary.final.value === value;
  }
  if (record.dense) {
    let textValue: string = value;
    if (value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
      textValue = UNSURE;
    } else if (value === BinaryValueValue.VALUE_SKIP) {
      textValue = SKIPPED;
    }
    return record.dense.first.value.includes(textValue)
      || record.dense?.second.value.includes(textValue)
      || record.dense?.final.value.includes(textValue);
  }
  return false;
}

function mutualAgreed(record: Record): boolean {
  if (record.binary) {
    return record.binary.first.value === record.binary.second.value;
  }
  if (record.dense) {
    return isEqual(
      record.dense.first.value.sort((a, b) => a.localeCompare(b)),
      record.dense.second.value.sort((a, b) => a.localeCompare(b)),
    );
  }
  return true;
}

function labelFilterPredicate(record: Record, filters: LabelFilter[]): boolean {
  if (!filters.length || filters.includes('all')) {
    return true;
  }
  if (filters.includes('no-skip') && hasBinaryValue(record, BinaryValueValue.VALUE_SKIP)) {
    return false;
  }
  if (filters.includes('no-unsure') && hasBinaryValue(record, BinaryValueValue.VALUE_FLAG_FOR_REVIEW)) {
    return false;
  }
  if (filters.includes('disagreed') && mutualAgreed(record)) {
    return false;
  }
  return true;
}

function getLabeledIntents(annotations: Annotation[], intentsMap: Map<string, Concept>): LabeledIntentsValue {
  if (annotations[0].value.binaryValue.value === BinaryValueValue.VALUE_SKIP) {
    return { value: [SKIPPED] };
  }
  if (annotations[0].value.binaryValue.value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
    return {
      value: [UNSURE],
      note: annotations[0].value.binaryValue.note,
    };
  }
  return {
    value: annotations.filter((annotation) => annotation.value.binaryValue.value === BinaryValueValue.VALUE_POSITIVE)
      .map((annotation) => intentsMap.get(annotation.value.binaryValue.conceptId)?.conceptTitle || '<deprecated>'),
  };
}

function getAnnotationsPath(path: string, task: LabelingTask): string {
  return `/${path}/annotations/${task.taskData.taskDescriptor.conceptIds[0]}?concept=${ConceptConceptType.INTENT}&split=${task.taskData.taskDescriptor.split}&target=LABELING&taskId=${getId('labelingTask', task.name)}`;
}

function formatLabelValue(value: BinaryValueValue): string {
  if (value === BinaryValueValue.VALUE_POSITIVE) {
    return 'Positive';
  }
  if (value === BinaryValueValue.VALUE_NEGATIVE) {
    return 'Negative';
  }
  if (value === BinaryValueValue.VALUE_SKIP) {
    return 'Skipped';
  }
  if (value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
    return 'Unsure';
  }
  return value;
}

function getLabelClass(value: BinaryValueValue): string {
  if (value === BinaryValueValue.VALUE_POSITIVE) {
    return styles.positive;
  }
  if (value === BinaryValueValue.VALUE_NEGATIVE) {
    return styles.negative;
  }
  if (value === BinaryValueValue.VALUE_SKIP) {
    return styles.skipped;
  }
  if (value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW) {
    return styles.unsure;
  }
  return value;
}

function getIntentLabelClass(label: string, calibraLabels: string[]): string {
  if (label === UNSURE) {
    return styles.unsure;
  }
  if (label === SKIPPED) {
    return styles.skipped;
  }
  return calibraLabels.includes(label) ? styles.positive : styles.negative;
}
