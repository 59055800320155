import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from '@mantine/core';
import { useHotkeys } from 'hooks/useHotkeys';
import React, { useEffect, useRef } from 'react';

type SearchInputProps = {
  children?: React.ReactNode | string | number;
  placeholder?: string;
  value: string;
  clearable?: boolean;
  onChange: (value: string) => void;
  autoFocus?: boolean;
};

export default function SearchInput({
  children,
  placeholder,
  value,
  clearable = true,
  onChange,
  autoFocus = false,
}: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement>();
  useHotkeys('esc', () => {
    if (inputRef) {
      inputRef.current.blur();
    }
  }, { enableOnTags: ['INPUT'] }, [inputRef?.current]);

  useHotkeys('/', () => {
    if (inputRef) {
      inputRef?.current?.focus();
    }
  }, {}, [inputRef?.current]);

  useEffect(() => {
    if (inputRef && autoFocus) {
      // Wait for other animations (e.g. scroll focus) to finish
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 500);
    }
  }, []);

  return (
    <div className="search-input">
      <Input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={value}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        rightSection={clearable && value.length > 0 ? (
          <CloseCircleOutlined onClick={() => onChange('')} />
        ) : (
          <SearchOutlined />
        )}
      />
    </div>
  );
}
