import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApiPost } from 'hooks/network';
import { IntentValidationData } from './types';

export const useIntentValidationData = (
  concept_names?: {
    intents: string[]
  },
): {
  isLoading: boolean;
  error: Error | null;
  data: IntentValidationData | null;
} => {
  const { customerId, profileId, usecaseId, languageCode } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error] = useState<Error | null>(null);
  const [data, setData] = useState<any | null>(null);
  const apiPost = useCallback(useApiPost(true, true), []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await apiPost('validate_annotations', { concept_names: concept_names.intents });
      setData({ validated_result: response, result: response.result, status: response.status });
      setIsLoading(false);
    };

    if (concept_names) {
      fetchData();
    }
  }, [customerId, profileId, usecaseId, languageCode, concept_names, apiPost]);

  return { isLoading, error, data };
};
