import React from 'react';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';
import CopyableValue from 'components/CopyableValue';
import { getId } from 'common/resourceName';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { DataGrid } from 'components/DataGrid';
import { ActionIcon, Group, Stack, Tooltip } from '@mantine/core';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { ConceptDictionaryEntry, ModifiedManualAnalysisSummary } from '..';

export interface ConversationTableProps {
  conversationsTableData: ModifiedManualAnalysisSummary[]
  setDictionary: { [key: string]: {
    title: string
  }}
}

const ExternalLinkIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V14M11 13L20 4M20 4V9M20 4H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const ConversationsTable = (props: ConversationTableProps) => {
  const { conversationsTableData, setDictionary } = props;
  const { taskId } = useParams<{customer: string, taskId: string}>();
  const customer = useCustomerParams();
  const navigate = useNavigate();

  const getTagColums = () => ['stages', 'drivers', 'outcomes', 'behaviors'].map((type) => ({
    accessorFn: (row) => row[type],
    // capitalized type
    header: type.charAt(0).toUpperCase() + type.slice(1),
    enableSorting: false,
    cell: (info) => {
      const tags = info.getValue() as ConceptDictionaryEntry[];
      const modifiedTags = type === 'stages' ? tags.map((tag) => ({ ...tag, label: tag.label.replace('.begin', '') })) : tags;
      return (
        <Group spacing="xs">
          {
            modifiedTags.map((tag: ConceptDictionaryEntry) => (
              <ManualAnalysisTag
                key={tag.id}
                onClick={() => {
                  const id = getId('concept', tag.value);
                  navigate(`./analysis-summary/${id}`);
                }}
                tagType={tag.tagType}
              >{tag.label}
              </ManualAnalysisTag>
            ))
          }
        </Group>
      );
    },
  }));

  const columns: ColumnDef<ModifiedManualAnalysisSummary>[] = [
    {
      accessorKey: 'conversationId',
      header: 'Convo ID',
      cell: (info) => {
        const conversationId = info.getValue() as string;
        return (
          <CopyableValue
            displayValue={`${conversationId.substring(0, 4)}...`}
            copiedValue={conversationId}
          />
        );
      },
    },
    ...getTagColums(),
    {
      accessorKey: 'setId',
      header: 'Set',
      cell: (info) => {
        const setId = info.getValue() as string;
        return setDictionary[setId] ? setDictionary[setId].title : setId;
      },
    },
    {
      accessorKey: 'note',
      header: 'Notes',
    },
    {
      // allows user to open conversation in new tab
      accessorKey: 'conversationPositionNumber',
      enableSorting: false,
      header: '',
      cell: (info) => (
        <Tooltip label="Open in new tab" position="right">
          <ActionIcon
            color="blue"
            onClick={(e) => {
              const conversationPositionNumber = info.getValue() as number;
              e.stopPropagation();
              window.open(`/${customer.path}/analysisworkshop/${taskId}?conversationPositionNumber=${conversationPositionNumber}`, '_blank');
            }}
          >
            <ExternalLinkIcon />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];
  return (
    <Stack>
      <DataGrid
        columns={columns}
        dataSource={conversationsTableData}
        onRowClick={({ conversationPositionNumber }) => (
          navigate(`/${customer.path}/analysisworkshop/${taskId}?conversationPositionNumber=${conversationPositionNumber}`)
        )}
        initialPageSize={100}
      />
    </Stack>
  );
};
