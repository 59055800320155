import { ArrowLeftOutlined } from '@ant-design/icons';
import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { ActionAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/action/action_annotation.pb';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/moment/moment_annotation.pb';
import { BinaryValueValue, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Concept, ConceptConceptType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { Button } from '@mantine/core';
import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import CopyableValue from 'components/CopyableValue';
import Loading from 'components/Loading';
import MultiTags from 'components/MultiTags';
import TaskTypeTag from 'components/TaskTypeTag';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import useUrlParam from 'hooks/useUrlParam';
import { round } from 'lodash';
import { formatLabelValue } from 'pages/LabelsReview';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { selectConceptsFactory } from 'store/concept/selectors';
import { fetchLabelingTaskAnnotations } from 'store/labelingTask/asyncThunks';
import { selectAnnotationBundles, selectApiStatus, selectDerivedQaTasks } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';

import styles from './styles.module.scss';

interface ResultsRow {
  intent: string;
  correct: number;
  incorrect: number;
}

const LABEL_BUTTON_STYLES_MAP = new Map<BinaryValueValue, string>(
  [
    [BinaryValueValue.VALUE_POSITIVE, styles.positive],
    [BinaryValueValue.VALUE_NEGATIVE, styles.negative],
    [BinaryValueValue.VALUE_SKIP, styles.skipped],
    [BinaryValueValue.VALUE_FLAG_FOR_REVIEW, styles.flagged],
  ],
);

function getPrecisionPercentage(correct: number, incorrect: number) {
  return (correct === 0 && incorrect === 0) ? 0 : round(correct / (correct + incorrect) * 100, 2);
}

export default function QAScoreView() {
  // Filter params.
  const dispatch = useDispatch();
  const [taskId, setTaskId, clearTaskId] = useUrlParam<string>('taskId');
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const customerProfile = useCustomerProfile();

  // Completed, non-dual tasks.
  const tasks = useSelector<DerivedLabelingTask[]>(selectDerivedQaTasks)
    .filter((t) => t.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED);

  // IntentsMap for intents in the summary.
  const concepts = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT));
  const conceptsMap = new Map(concepts.filter((concept) => concept.intent && concept.state !== ConceptState.DEPRECATED)
    .map((concept) => {
      const intentId = getId('concept', concept.name);
      return [intentId, { intentId, intent: concept.conceptTitle }];
    }));

  const fetchSummary = async () => {
    dispatch(fetchLabelingTaskAnnotations({
      parent: customerProfile,
      searchFilter: {
        taskIds: taskId ? [taskId] : [],
        detailedTypeFilter: {
          targetType: TargetType.QA,
          momentAnnotationTypes: [MomentAnnotationType.INTENT],
          momentAdherenceTypes: [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
          actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
        },
      },
      pageSize: 500,
    }));
  };

  const setTask = useCallback((taskId: string | null) => {
    if (!taskId) {
      clearTaskId();
      return;
    }
    setTaskId(taskId);
  }, [setTaskId, clearTaskId]);

  useEffect(() => {
    fetchSummary();
  }, [taskId]);

  const summaryResults = useMemo(() => {
    const resultsMap = new Map<string, ResultsRow>();
    annotationBundles.forEach((bundle) => {
      const intentId = bundle.annotation.value.binaryValue.conceptId;
      const { value } = bundle.annotation.value.binaryValue;
      let resultRow = resultsMap.get(intentId);
      if (!resultRow) {
        resultRow = {
          intent: conceptsMap.get(intentId)?.intent,
          correct: 0,
          incorrect: 0,
        };
        resultsMap.set(intentId, resultRow);
      }
      if (value === BinaryValueValue.VALUE_POSITIVE) {
        resultRow.correct += 1;
      } else if (value === BinaryValueValue.VALUE_NEGATIVE) {
        resultRow.incorrect += 1;
      }
    });

    return Array.from(resultsMap.values());
  }, [annotationBundles]);

  const columns: ColumnsType<ResultsRow> = [
    {
      title: 'Intent',
      dataIndex: 'intent',
      key: 'intent',
      render: (value, row) => <MultiTags type="intent" tags={[value]}/>,
    },
    {
      title: '# Correct',
      dataIndex: 'correct',
      key: 'correct',
      sorter: (a, b) => b.correct - a.correct,
    },
    {
      title: '# Incorrect',
      dataIndex: 'incorrect',
      key: 'incorrect',
      sorter: (a, b) => b.incorrect - a.incorrect,
    },
    {
      title: 'Precision',
      dataIndex: 'precision',
      key: 'precision',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => getPrecisionPercentage(b.correct, b.incorrect) - getPrecisionPercentage(a.correct, a.incorrect),
      render: (value, row) => `${getPrecisionPercentage(row.correct, row.incorrect)}%`,
    },
  ];

  const perMessageColumns: ColumnsType<SearchAnnotationsResponseAnnotationBundle> = [
    {
      title: 'Chat ID',
      dataIndex: 'chatId',
      key: 'chatId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2ConversationId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Message ID',
      dataIndex: 'messageId',
      key: 'messageId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2MessageId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Message',
      dataIndex: ['textMessage'],
      key: 'message',
    },
    {
      title: 'Intent',
      dataIndex: ['annotation', 'value', 'binaryValue', 'conceptId'],
      key: 'action',
      render: (value, row) => {
        const concept = conceptsMap.get(value);
        return <MultiTags type="intent" tags={[concept?.intent]}/>;
      },
    },
    {
      title: 'Label',
      dataIndex: ['annotation', 'value', 'binaryValue', 'value'],
      key: 'label',
      render: (value, record) => (
        <div>
          <Button
            className={classNames([styles.labelButton, LABEL_BUTTON_STYLES_MAP.get(value)])}
          >
            {formatLabelValue(value)}
          </Button>
        </div>
      ),
    },
  ];

  const perMessageResults = annotationBundles;

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <Link to="../qa/tasks">
        <ArrowLeftOutlined /> Back to QA Tasks
      </Link>
      <div className={classNames([styles.filters, styles.header])}>
        <h1>QA Summary View</h1>
        <Select
          className={styles.select}
          showArrow
          size="large"
          value={taskId == null ? 'From all QA tasks' : `Task ID ${taskId.substring(0, 6)}...`}
          onChange={(id) => setTask(id)}
        >
          <Select.Option key="" value={null}>
            From all QA tasks
          </Select.Option>
          {
            tasks.map((task) => {
              const id = getId('labelingTask', task.labelingTask.name);
              return (
                <Select.Option key={id} value={id}>
                  <div className={styles.selectOption}>
                    <span>Task ID {id.substring(0, 6)}...</span>
                    <span className={styles.spacer} />
                    <TaskTypeTag taskType={task.userLabelingTaskType} />
                  </div>
                </Select.Option>
              );
            })
          }
        </Select>
      </div>
      <div className={styles.content}>
        <Table
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
          columns={columns}
          rowKey={(row) => row.intent}
          dataSource={summaryResults}
          className={styles.summaryTable}
        />
      </div>
      <h2>Per Message Results</h2>
      <div className={styles.content}>
        <Table
          rowKey={(row) => row.annotation.name}
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
          className={styles.summaryTable}
          columns={perMessageColumns}
          dataSource={perMessageResults}
        />
      </div>
    </div>
  );
}
