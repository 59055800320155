import { Table } from 'antd';
import React, { useCallback, useState, useContext } from 'react';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import classNames from 'classnames';
import { selectApiStatus } from 'store/annotation/selectors';
import { ColumnsType } from 'antd/lib/table';
import { Annotation, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { listConversationMessages } from 'store/conversation/asyncThunks';
import CopyableValue from 'components/CopyableValue';
import {
  SearchAnnotationsResponseAnnotationBundle as AnnotationBundle,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';

import { TableRowSelection } from 'antd/lib/table/interface';
import useUrlParam from 'hooks/useUrlParam';
import { selectConceptsMapFactory } from 'store/concept/selectors';
import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Button, Divider, Group, Stack, Textarea, useMantineTheme } from '@mantine/core';
import { cloneDeep } from 'lodash';
import { UserContext } from 'context/UserContext';
import { AnnotationApi } from 'services/annotationApi';
import { resetAnnotations } from 'store/annotation/annotationSlice';
import { searchAnnotations } from 'store/annotation/asyncThunks';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { AnnotationBundleWithMetrics } from '.';
import styles from './styles.module.scss';

interface SummarizationLabelsReviewTableProps {
  setAnnotation: (annotation: AnnotationBundle) => void;
  // Selected conversation in chat preview
  conversationId: string;
  currentAnnotations: AnnotationBundleWithMetrics[];
  setPageInfo: ({ page, pageSize }: { page: number, pageSize: number }) => void;
  rowSelection: TableRowSelection<AnnotationBundle>;
}

function getAnnotationConversationId(annotation: Annotation) {
  return annotation.rawData.conversationRawData?.v2ConversationId || annotation.rawData.conversationRawData?.conversationId;
}

export default function SummarizationLabelsReviewTable({
  setAnnotation,
  conversationId,
  currentAnnotations,
  setPageInfo,
  rowSelection,
}: SummarizationLabelsReviewTableProps) {
  const apiStatus = useSelector<ApiStatus>(selectApiStatus);
  const dispatch = useDispatch();
  const theme = useMantineTheme();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const [targetType] = useUrlParam<TargetType>('target');
  const summaryConceptsMap = useSelector<Map<string, Concept>>(selectConceptsMapFactory(ConceptConceptType.SUMMARIZATION));
  const [editingAnnotation, setEditingAnnotation] = useState<Annotation>();
  const currentUser = useContext(UserContext);
  const [savingAnnotation, setSavingAnnotation] = useState<ApiStatus>('idle');

  const saveAnnotation = useCallback(async () => {
    setSavingAnnotation('loading');
    try {
      await AnnotationApi.modifyAnnotation({
        name: editingAnnotation.name,
        newValue: editingAnnotation.value,
        modifierUserId: currentUser?.id,
      });
      dispatch(resetAnnotations());
      dispatch(searchAnnotations({
        parent: customerProfile,
        usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
        languageCode: customer.languageCode,
      }));
      setSavingAnnotation('succeeded');
      setEditingAnnotation(null);
    } catch (err) {
      setSavingAnnotation('failed');
    }
  }, [currentAnnotations, customerProfile, resetAnnotations, searchAnnotations, editingAnnotation]);

  const columns: ColumnsType<AnnotationBundleWithMetrics> = [
    {
      title: 'Conversation ID',
      dataIndex: ['annotation', 'rawData', 'conversationRawData', 'conversationId'],
      key: 'conversationId',
      render: (value, row) => {
        const conversationId = getAnnotationConversationId(row.annotation);
        if (!conversationId) return null;
        return (
          <CopyableValue
            displayValue={`${conversationId.substring(0, 6)}...`}
            copiedValue={conversationId}
          />
        );
      },
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (value, row) => {
        const concept = summaryConceptsMap.get(row.annotation.value.textValue?.conceptId);
        return concept?.summarization?.question;
      },
    },
    {
      title: 'Summarization',
      dataIndex: 'summarization',
      key: 'summarization',
      render: (value, row) => (
        // Stop event propagation to prevent opening conversation preview
        <Stack onClick={(e) => e.stopPropagation()}>
          {editingAnnotation?.name === row.annotation.name ? (
            <>
              {editingAnnotation?.value.textValue.values.map((value, index) => (
                <Textarea
                  value={value}
                  onChange={(e) => {
                    const editingAnnotationCopy = cloneDeep(editingAnnotation);
                    editingAnnotationCopy.value.textValue.values[index] = e.target.value;
                    setEditingAnnotation(editingAnnotationCopy);
                  }}
                />
              ))}
            </>
          ) : (
            <>
              {row.annotation.value.textValue?.values.map((value, index) => (
                <>
                  {index !== 0 && (
                    <Divider color={theme.colors.gray[0]} />
                  )}
                  <Group>
                    {value}
                  </Group>
                </>
              ))}
            </>
          )}
        </Stack>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 200,
      render: (value, row) => (
        // Stop event propagation to prevent opening conversation preview
        <Group onClick={(e) => e.stopPropagation()}>
          {editingAnnotation?.name === row.annotation.name ? (
            <>
              <Button variant="subtle" onClick={() => setEditingAnnotation(null)}>Cancel</Button>
              <Button variant="subtle" onClick={saveAnnotation} loading={savingAnnotation === 'loading'}>Save</Button>
            </>
          ) : (
            <Button variant="subtle" onClick={() => setEditingAnnotation(row.annotation)}>Edit</Button>
          )}
        </Group>
      ),
    },
  ];

  return (
    <Table
      className={styles.labelsTable}
      rowClassName={(record) => {
        const isClickable = getAnnotationConversationId(record.annotation);
        return classNames([
          { [styles.focusedRow]: getAnnotationConversationId(record.annotation) === conversationId },
          { [styles.clickableRow]: isClickable },
          styles.labelRow,
        ]);
      }}
      columns={columns}
      dataSource={currentAnnotations}
      rowSelection={targetType === TargetType.QA ? rowSelection : undefined}
      rowKey={(record: AnnotationBundle) => record.annotation.name}
      loading={{
        spinning: apiStatus === 'loading',
        indicator: <Loading />,
      }}
      onRow={(record) => ({
        onClick: (e) => {
          if (!getAnnotationConversationId(record.annotation)) return;
          setAnnotation(record);
          const conversationName = `${customerProfile}/conversations/${getAnnotationConversationId(record.annotation)}`;
          dispatch(listConversationMessages(conversationName));
        },
      })}
      pagination={{
        onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
      }}
    />
  );
}
