import React, { useCallback, useEffect, useState } from 'react';
import { Annotation, BinaryValue, BinaryValueValue, MessageRawData, MessageRawDataConfusionType, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Popover } from 'antd';
import { useHotkeys } from 'hooks/useHotkeys';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import LabelingUnsurePopover from 'components/LabelingUnsurePopover';
import MultiTags from 'components/MultiTags';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { getId } from 'common/resourceName';
import './BinaryDecision.scss';
import { Button } from '@mantine/core';
import { getPredictionValue } from 'pages/TaskSummaryView/SummaryTables/SummaryIntentRecallTable';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';

interface BinaryDecisionProps {
  loading?: boolean,
  concepts: Concept[],
  message: Message,
  predictions?: Prediction[],
  contextShown: MessageRawDataContextShown,
  predictionLabeling?: boolean,
  annotations: Annotation[],
  showUnsureButton?: boolean,
  createAnnotation: (messageRawData: MessageRawData, binaryValue: BinaryValue) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[]) => void;
  getConceptTitle: (conceptId: string) => string;
}

/** Shows Yes, No, Skip and Unsure buttons. This labeling only expects 1 intent */
export default function BinaryDecision({
  loading,
  concepts = [],
  message,
  predictions = [],
  contextShown,
  predictionLabeling = false,
  annotations = [],
  showUnsureButton = true,
  createAnnotation,
  submitAnnotations,
  getConceptTitle,
}: BinaryDecisionProps) {
  const [unsurePopoverVisible, setUnsurePopoverVisible] = useState(false);
  const [unsureNote, setUnsureNote] = useState('');

  useEffect(() => {
    // Reset note
    const flaggedAnnotation = annotations.find((a) => a.value.binaryValue.value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW);
    if (flaggedAnnotation) {
      setUnsureNote(flaggedAnnotation.value.binaryValue.note);
    } else {
      setUnsureNote('');
    }
  }, [message]);

  // For QA intent prediction
  const qaPredictionId = message?.selectionContext?.qaContext?.predictionId;
  const messageConcepts = message?.conceptIds.map((id) => getConceptTitle(id));

  // We assume that there will only be 1 concept to label in this task
  const conceptToLabelId = predictionLabeling ? message?.conceptIds[0] : getId('concept', concepts[0]?.name);
  const messageValue = annotations.length && annotations[0].value.binaryValue.value;

  // Only used in QA tasks
  const getConfusionType = useCallback((binaryValue: BinaryValueValue) => {
    const prediction = predictions.find((prediction) => getId('prediction', prediction.name) === message?.selectionContext?.qaContext.predictionId);
    const predictionBool = getPredictionValue(prediction);

    if (binaryValue === BinaryValueValue.VALUE_POSITIVE) {
      if (predictionBool) {
        return MessageRawDataConfusionType.TRUE_POSITIVE;
      } else {
        return MessageRawDataConfusionType.FALSE_NEGATIVE;
      }
    } else if (predictionBool) {
      return MessageRawDataConfusionType.FALSE_POSITIVE;
    } else {
      return MessageRawDataConfusionType.TRUE_NEGATIVE;
    }
  }, [predictions, message]);

  const submitAnnotationsSingle = ((concepts: Concept[], value: BinaryValueValue, note?: string) => {
    const newAnnotation = createAnnotation({
      conversationId: message.conversationId,
      messageId: message.messageId,
      v2ConversationId: message.v2ConversationId,
      v2MessageId: message.v2MessageId,
      contextShown,
      spanStart: message.targetSpanStart,
      spanEnd: message.targetSpanEnd,
    }, {
      value,
      conceptId: conceptToLabelId,
    });
    if (note) {
      newAnnotation.value.binaryValue.note = note;
    }
    if (qaPredictionId) {
      newAnnotation.rawData.messageRawData.predictionId = message.selectionContext?.qaContext?.predictionId;
      newAnnotation.rawData.messageRawData.confusionType = getConfusionType(value);
    }
    submitAnnotations([newAnnotation]);
  });

  useHotkeys('y', () => !loading && submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_POSITIVE), {}, [submitAnnotationsSingle, loading]);
  useHotkeys('n', () => !loading && submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_NEGATIVE), {}, [submitAnnotationsSingle, loading]);
  useHotkeys('u', () => !loading && setUnsurePopoverVisible(true), {}, [setUnsurePopoverVisible, loading]);
  useHotkeys('s', () => !loading && submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_SKIP), {}, [submitAnnotationsSingle, loading]);

  const unsurePopover = (
    <LabelingUnsurePopover
      note={unsureNote}
      // Workaround to get auto focus working with popover
      key={unsurePopoverVisible ? 'visible' : 'hidden'}
      setNote={setUnsureNote}
      confirmHandler={() => {
        if (unsurePopoverVisible) {
          submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_FLAG_FOR_REVIEW, unsureNote);
          setUnsurePopoverVisible(false);
          setUnsureNote('');
        }
      }}
      cancelHandler={() => setUnsurePopoverVisible(false)}
    />
  );

  return (
    <div className="binary-decision">
      {predictionLabeling && messageConcepts?.length > 0 && (
      <div className="prediction-panel">
        <div>
          <span>Intent:</span>
          <MultiTags size="large" tags={messageConcepts} type="intent" />
        </div>
      </div>
      )}
      <div className="buttons-group">
        <Button
          color="green"
          disabled={loading}
          variant="light"
          data-cy="button-yes"
          data-active={messageValue === BinaryValueValue.VALUE_POSITIVE}
          onClick={() =>
            submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_POSITIVE)}
        >
          Yes (Y)
        </Button>
        <Button
          color="red"
          disabled={loading}
          variant="light"
          data-cy="button-no"
          data-active={messageValue === BinaryValueValue.VALUE_NEGATIVE}
          onClick={() =>
            submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_NEGATIVE)}
        >
          No (N)
        </Button>
        <Button
          color="blue"
          disabled={loading}
          variant="light"
          data-cy="button-skip"
          data-active={messageValue === BinaryValueValue.VALUE_SKIP}
          onClick={() =>
            submitAnnotationsSingle(concepts, BinaryValueValue.VALUE_SKIP)}
        >
          Skip (S)
        </Button>
        {showUnsureButton && (
        <Popover
          trigger="click"
          placement="right"
          content={unsurePopover}
          open={unsurePopoverVisible}
          onOpenChange={(value) => setUnsurePopoverVisible(value)}
        >
          <Button
            color="purple"
            disabled={loading}
            variant="light"
            data-cy="button-unsure"
            data-active={messageValue === BinaryValueValue.VALUE_FLAG_FOR_REVIEW}
          >
            Unsure (U)
          </Button>
        </Popover>
        )}
      </div>
    </div>
  );
}
