import { Annotation, AnnotationRawData, AnnotationRawDataType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as AnnotationState } from './state';

/** Helper function to accesss correct raw data object */
export function getAnnotationRawDataKey(annotation: Annotation) {
  let rawDataKey: keyof AnnotationRawData = 'messageRawData';
  if (annotation?.rawData.type === AnnotationRawDataType.TYPE_ON_CONVERSATION) {
    rawDataKey = 'conversationRawData';
  }
  return rawDataKey;
}

const selectAnnotation = (state: State) => state.annotation;

/** Api status. */
export const selectApiStatus = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.status);

/** Select annotation summaries. */
export const selectAnnotationSummaries = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.annotationSummaries);

/** Select train annotation summaries. */
export const selectTrainAnnotationSummaries = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.trainAnnotationSummaries);

/** Select test annotation summaries. */
export const selectTestAnnotationSummaries = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.testAnnotationSummaries);

/** Select current annotations. */
export const selectCurrentAnnotations = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.currentAnnotations);

/** Select whether there is next page. */
export const selectHasNextPage = createSelector(selectAnnotation, (annotationState: AnnotationState) => !!annotationState.nextPageToken);

/** Select annotation summary filters. */
export const selectSummaryFilters = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.summaryFilters);

/** Select annotation filters. */
export const selectAnnotationFilters = createSelector(selectAnnotation, (annotationState: AnnotationState) => annotationState.annotationFilters);

/** Select calibration annotations summary. */
export const selectCalibrationSummary = createSelector(
  selectAnnotation,
  (annotationState: AnnotationState) => annotationState.calibrationSummary,
);
