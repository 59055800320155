import React, { useEffect, useState } from 'react';
import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { Group } from '@mantine/core';
import { useSelector } from 'hooks/reduxHooks';
import { selectApiStatus, selectConversationSets } from 'store/set/selectors';
import { SetApi } from 'services/setApi';
import { SetManagementDropdown } from 'components/SetManagementDropdown';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { Set } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { SetTags } from './SetTags';

export const SetManagerSection = ({ conversation }:{conversation:Conversation}) => {
  const [membershipSets, setMembershipSets] = useState([]);
  const [gatheringSetData, setGatheringSetData] = useState(true);
  const apiStatus = useSelector(selectApiStatus);

  const isLoading = apiStatus === 'loading' || gatheringSetData;

  const sets = useSelector(selectConversationSets);
  const activeSets = sets.filter((set) => set.state !== 'DEPRECATED');
  const profile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { languageCode } = useCustomerParams();

  useEffect(() => {
    if (!isLoading) return;
    setGatheringSetData(true);

    SetApi.fetchSetsForSetItem({
      profile,
      setItemName: conversation.name,
      usecase,
      languageCode,
    }).then((res) => {
      const activeSets = res.sets.filter((set) => set.state !== 'DEPRECATED');
      setMembershipSets(activeSets);
      setGatheringSetData(false);
    }).catch((err) => {
      console.error(err);
      setGatheringSetData(false);
    });
  }, [sets, conversation.name]);

  return (
    <Group position="right">
      <SetTags
        conversationName={conversation.name}
        membershipSets={membershipSets}
        onChangeMembershipSets={(membershipSets: Set[]) => {
          setMembershipSets(membershipSets);
        }}
      />
      <SetManagementDropdown
        isLoading={isLoading}
        allSets={activeSets}
        membershipSets={membershipSets}
        conversationName={conversation.name}
        onChangeMembershipSets={(membershipSets) => {
          setMembershipSets(membershipSets);
        }}
      />
    </Group>
  );
};
