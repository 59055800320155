import { QuestionCircleOutlined } from '@ant-design/icons';
import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { FetchAnnotationSummaryRequestFilter } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import Loading from 'components/Loading';
import MultiTags from 'components/MultiTags';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnnotationsCount } from '.';

import styles from './styles.module.scss';

interface SummarizationLabelsTableProps {
  filters: FetchAnnotationSummaryRequestFilter;
  loading: boolean;
  annotationsCount: AnnotationsCount[];
  setPageInfo: React.Dispatch<React.SetStateAction<{ page: number, pageSize: number }>>;
}

export default function SummarizationLabelsTable({
  filters,
  loading,
  annotationsCount,
  setPageInfo,
}: SummarizationLabelsTableProps) {
  const columns: ColumnsType<AnnotationsCount> = [
    {
      title: 'Summarization Topic',
      dataIndex: 'concepTitle',
      key: 'concepTitle',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.concepTitle.localeCompare(c2.concepTitle),
      render: (value) => (<MultiTags tags={[value]} />),
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Needs Review</span>
          <Tooltip placement="rightTop" title="Task completed, waiting for the dual/calibration tasks.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'review',
      key: 'review',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.review - c2.review,
      render: (value, record) => {
        if (filters.detailedTypeFilter.targetType === TargetType.QA) {
          return <Link to={`${record.intentId}?split=${filters.splits[0]}&target=QA`}>{value}</Link>;
        } else {
          return (
            <Link to={`../labeling/tasks?intent=${record.intentId}&split=${filters.splits[0]}&status[0]=TASK_IN_PROGRESS`}>{value}</Link>
          );
        }
      },
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Model Ready</span>
          <Tooltip placement="rightTop" title="Annotations which need no further calibration and may be used to train models.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      key: 'modelReady',
      dataIndex: 'modelReady',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.modelReady - c2.modelReady,
      render: (value, record) => (
        <div className={styles.annotationCountCell}>
          <Link to={`annotations/${record.intentId}?concept=${filters.conceptType}&split=${filters.splits[0]}&target=LABELING`}>{value}</Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        dataSource={annotationsCount}
        rowKey={(record: AnnotationsCount) => record.concepTitle}
        loading={{
          spinning: loading,
          indicator: <Loading />,
        }}
        pagination={{
          onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
        }}
      />
    </div>
  );
}
