import React, { useState } from 'react';
import { Box, Button, ButtonProps, Flex, Popover } from '@mantine/core';

const MantineSelectArrowsSvg = () => (
  <svg width="18" height="18" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" data-chevron="true" fill="currentColor">
    <path d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);

const ListItem = ({ children, onClick }) => (
  <Box
    sx={(theme) => ({
      padding: 10,
      paddingBlock: 5,
      paddingRight: 36,
      width: '100%',
      // on hover, change the color of the border to light blue
      '&:hover': {
        background: theme.colors.blue[0],
        cursor: 'pointer',
      },
    })}
    onClick={onClick}
  >
    {children}
  </Box>
);

const sharedBtnProps: ButtonProps = {
  styles: {
    root: {
      width: '100%',
    },
    label: { width: 55, justifyContent: 'center' },
  },
  variant: 'light',
  compact: true,
};

const buttons = {
  positive: <Button color="green" {...sharedBtnProps}>Positive</Button>,
  negative: <Button color="red" {...sharedBtnProps}>Negative</Button>,
  unsure: <Button color="purple" {...sharedBtnProps}>Unsure</Button>,
};
export type Value = keyof typeof buttons;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
  withUnsure?: boolean;
}

export const BooleanDropdown = ({ value, onChange, withUnsure }: Props) => {
  const [isOpen, toggleOpen] = useState(false);
  const targetButton = buttons[value];
  const handleDropdownClick = (value: Value) => {
    onChange(value);
    toggleOpen(false);
  };
  return (
    <Popover
      offset={0}
      width="target"
      opened={isOpen}
      styles={{
        dropdown: {
          padding: 0,
          paddingBlock: 5,
        },
      }}
      onClose={() => toggleOpen(false)}
      withinPortal
    >
      <Popover.Target>
        <Box
          px={10}
          py={5}
          sx={(theme) => ({
            backgroundColor: theme.colors.gray[0],
            textAlign: 'center',
            borderRadius: theme.radius.md,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'white',
            },
          })}
          onClick={() => toggleOpen((prev) => !prev)}
        >
          <Flex direction="row" align="center" gap="sm">
            {targetButton}
            <MantineSelectArrowsSvg />
          </Flex>
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <ListItem onClick={() => handleDropdownClick('positive')}>
          <Button color="green" {...sharedBtnProps}>Positive</Button>
        </ListItem>
        <ListItem onClick={() => handleDropdownClick('negative')}>
          <Button color="red" {...sharedBtnProps}>Negative</Button>
        </ListItem>
        {withUnsure && (
          <ListItem onClick={() => handleDropdownClick('unsure')}>
            <Button color="purple" {...sharedBtnProps}>Unsure</Button>
          </ListItem>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
