import { createSlice } from '@reduxjs/toolkit';
import {
  conceptApiFailed,
  conceptApiPending,
  listConceptsSuccess,
  listSharedConceptsSuccess,
  listDemoConceptsSuccess,
  getOrUpsertConceptSuccess,
  refreshConceptsSuccess,
} from './reducers';
import {
  listConcepts,
  listSharedConcepts,
  listDemoConcepts,
  getConcept,
  createConcept,
  updateConcept,
  refreshConcepts,
} from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Concept slice. */
export const conceptSlice = createSlice({
  name: 'concept',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(listConcepts.pending, conceptApiPending)
      .addCase(listConcepts.rejected, conceptApiFailed)
      .addCase(listConcepts.fulfilled, listConceptsSuccess)
      .addCase(listSharedConcepts.pending, conceptApiPending)
      .addCase(listSharedConcepts.rejected, conceptApiFailed)
      .addCase(listSharedConcepts.fulfilled, listSharedConceptsSuccess)
      .addCase(listDemoConcepts.pending, conceptApiPending)
      .addCase(listDemoConcepts.rejected, conceptApiFailed)
      .addCase(listDemoConcepts.fulfilled, listDemoConceptsSuccess)
      .addCase(getConcept.pending, conceptApiPending)
      .addCase(getConcept.rejected, conceptApiFailed)
      .addCase(getConcept.fulfilled, getOrUpsertConceptSuccess)
      .addCase(createConcept.pending, conceptApiPending)
      .addCase(createConcept.rejected, conceptApiFailed)
      .addCase(createConcept.fulfilled, getOrUpsertConceptSuccess)
      .addCase(updateConcept.pending, conceptApiPending)
      .addCase(updateConcept.rejected, conceptApiFailed)
      .addCase(updateConcept.fulfilled, getOrUpsertConceptSuccess)
      .addCase(refreshConcepts.fulfilled, refreshConceptsSuccess),
});
