import { Annotation, AnnotationValue, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import { findNextTargetMessage } from 'pages/LabelingView/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType } from '@cresta/web-client';
// import SuggestionsDecision from 'components/LabelingDecision/SuggestionsDecision/SuggestionsDecision';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import MessageSuggestions from 'components/LabelingItemsDisplay/MessageSuggestions';
import { createStyles, Divider, useMantineTheme } from '@mantine/core';
import { getId } from 'common/resourceName';
import { AnnotationApi } from 'services/annotationApi';
import { ListRubricsRequest, Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { RubricApi } from 'services/rubricApi';
import { openNotification } from 'components/Notification';
import { SuggestionsDecisionMultipleModels } from 'components/LabelingDecision/SuggestionsDecision/SuggestionsDecisionMultipleModels';
import { SuggestionsDecisionSingleModel } from 'components/LabelingDecision/SuggestionsDecision/SuggestionsDecisionSingleModel';

interface SuggestionsQaViewProps {
  messages: Message[];
  predictions: Prediction[];
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  getConceptRole: (conceptId: string) => 'agent' | 'visitor';
  createAnnotation: (
    messageRawData: MessageRawData,
    value: AnnotationValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  modelUri1: string;
  modelUri2: string;
  prevChat: () => void;
  nextChat: () => void;
  isFirstChat: boolean;
  isLastChat: boolean;
  finishLabeling: () => void;
  taskId: string;
  rubrics: Rubric[];
  setRubrics: (rubrics: Rubric[]) => void;
  setOverviewConceptId: (id: string) => void;
  customerProfile?: string;
}

export function SuggestionsQaView({
  messages,
  predictions,
  targetMessage,
  messageAnnotationsMap,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  getConceptRole,
  createAnnotation,
  upsertAnnotations,
  modelUri1,
  modelUri2,
  prevChat,
  nextChat,
  isFirstChat,
  isLastChat,
  finishLabeling,
  taskId,
  rubrics,
  setRubrics,
  setOverviewConceptId,
  customerProfile,
} : SuggestionsQaViewProps) {
  const theme = useMantineTheme();
  const useStyles = createStyles(() => ({
    displayContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    displayChat: {
      flex: 1,
    },
    displaySuggestions: {
      maxHeight: '300px',
      overflow: 'auto',
    },
  }));

  const styles = useStyles();
  const targetMessageId = targetMessage?.v2MessageId;
  const targetMessageIndex = messages.findIndex((message) => message?.v2MessageId === targetMessageId);
  const [cannedSuggestions, setCannedSuggestions] = useState([]);

  const isLastMessage = (messages.length - 1) === targetMessageIndex;
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(0);

  const fetchRubrics = useCallback(async () => {
    try {
      const request: ListRubricsRequest = {
        pageSize: 100,
      };
      const response = await RubricApi.listRubrics(request);
      const filteredRubrics = response?.rubrics.filter((rubric) => rubric.availableTasks.includes(UserLabelingTaskType.QA_SUGGESTIONS));
      if (JSON.stringify(rubrics) !== JSON.stringify(filteredRubrics)) {
        setRubrics(filteredRubrics);
      }
    } catch (err) {
      openNotification('error', 'Failed to load rubrics', null, err);
    }
  }, [setRubrics]);

  // Load rubrics if not loaded
  useEffect(() => {
    if (!rubrics?.length) {
      fetchRubrics();
    }
  }, []);

  // Blur the message after target message
  const blurredMessageId = useMemo(() => {
    const blurredMessage = messages[targetMessageIndex + 1];
    return blurredMessage ? blurredMessage?.v2MessageId : undefined;
  }, [targetMessageIndex, messages]);

  const context = MessageRawDataContextShown.FULL_CONVERSATION_SHOWN;
  const hasSecondModel = !!modelUri2;

  // Get list of annotations for target message
  const targetMessageAnnotations = useMemo(() =>
    messageAnnotationsMap.get(targetMessageId) || [], [targetMessage, messageAnnotationsMap]);

  const submitAnnotations = useCallback((newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => {
    upsertAnnotations(newAnnotations, gotoNext, targetMessageAnnotations);
  }, [targetMessageAnnotations, upsertAnnotations]);

  const getCandidateSuggestionTexts = useCallback(async () => {
    const response = await AnnotationApi.fetchCandidateSuggestionTexts({
      profile: customerProfile,
      taskId,
    });
    setCannedSuggestions(response.cannedSuggestions);
  }, [customerProfile, taskId]);

  useEffect(() => {
    if (customerProfile) {
      getCandidateSuggestionTexts();
    }
  }, [targetMessage]);

  // Split predictions based on model uri
  const [modelPredictions1, modelPredictions2] = useMemo(() => {
    const modelPredictions1: Prediction[] = [];
    const modelPredictions2: Prediction[] = [];
    const targetMessagePredictionIds = targetMessage?.selectionContext.suggestionQaContext?.predictionIds || [];
    predictions.filter((prediction) => prediction.action?.suggestionPayload).forEach((prediction) => {
      // Check if the prediction exist in message suggestion context
      if (targetMessagePredictionIds.includes(getId('prediction', prediction.name))) {
        if (prediction?.action?.suggestionPayload?.results?.map((r) => r.ensembleModelId || r.modelId).some((m) => modelUri1.includes(m))) {
          modelPredictions1.push(prediction);
        } else if (prediction?.action?.suggestionPayload?.results?.map((r) => r.ensembleModelId || r.modelId).some((m) => modelUri2.includes(m))) {
          modelPredictions2.push(prediction);
        }
      }
    });
    return [modelPredictions1, modelPredictions2];
  }, [predictions, modelUri1, modelUri2, targetMessage]);

  // Go to next message or chat
  const nextHandler = useCallback(() => {
    const nextMessage = findNextTargetMessage(targetMessageId, messages);
    if (nextMessage) {
      setTargetMessage(nextMessage);
    } else {
      nextChat();
    }
  }, [isLastMessage, targetMessageId, messages, setTargetMessage, nextChat]);

  return (
    <div
      className={classNames([
        'labeling-view',
        'vertical-layout',
      ])}
    >
      <div className={styles.classes.displayContainer}>
        <div className={classNames(['labeling-items-display', styles.classes.displayChat])}>
          <LabelingItemsDisplay
            context={MessageRawDataContextShown.PARTIAL_CONVERSATION_CONTEXT_SHOWN}
            showContextButtonVisible={false}
            messages={messages}
            targetMessageId={targetMessageId}
            setTargetMessage={setTargetMessage}
            messageAnnotationsMap={messageAnnotationsMap}
            getConceptTitle={getConceptTitle}
            failedMessages={failedMessages}
            annotationsDisplayType="concepts-colors"
            blurredMessageId={blurredMessageId}
            partialChatLeadingMessages={1}
            // Show messages from first message to target message
            partialChatTrailingMessages={targetMessageIndex}
            annotationValueType="suggestion"
          />
        </div>
        {predictions?.length > 0 && (
          <>
            <Divider color={theme.colors.gray[1]} />
            <div className={styles.classes.displaySuggestions}>
              <MessageSuggestions
                predictions1={modelPredictions1}
                predictions2={modelPredictions2}
                hasSecondModel={hasSecondModel}
                selectedSuggestionIndex={selectedSuggestionIndex}
                setSelectedSuggestionIndex={setSelectedSuggestionIndex}
              />
            </div>
          </>
        )}
      </div>
      <div className="labeling-decision-panel">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1, height: '100%' }}>
            {hasSecondModel ? (
              <SuggestionsDecisionMultipleModels
                message={targetMessage}
                createAnnotation={createAnnotation}
                submitAnnotations={submitAnnotations}
                contextShown={context}
                hasSecondModel={hasSecondModel}
                predictions1={modelPredictions1}
                predictions2={modelPredictions2}
                annotations={targetMessageAnnotations}
                nextChat={nextHandler}
                cannedSuggestions={cannedSuggestions}
              />
            ) : (
              <SuggestionsDecisionSingleModel
                message={targetMessage}
                createAnnotation={createAnnotation}
                submitAnnotations={submitAnnotations}
                contextShown={context}
                hasSecondModel={hasSecondModel}
                predictions={modelPredictions1}
                annotations={targetMessageAnnotations}
                selectedSuggestionIndex={selectedSuggestionIndex}
                setSelectedSuggestionIndex={setSelectedSuggestionIndex}
                nextChat={nextHandler}
                rubrics={rubrics}
                setOverviewConceptId={setOverviewConceptId}
                cannedSuggestions={cannedSuggestions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
