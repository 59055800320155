import React, { useMemo, useState } from 'react';
import Table, { ColumnsType } from 'antd/lib/table';
import MultiTags from 'components/MultiTags';
import { RoleIcon } from 'components/RoleIcon';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { MomentAnnotationDetailedType } from '@cresta/web-client/dist/cresta/ai_service/common';
import useUrlParam from 'hooks/useUrlParam';
import { SimulatorIntentTableRow } from './rowTypes';
import styles from './SimulatorComparedResult.module.scss';
import { momentTypeToLabel, INTENT_DETECTION_TYPES } from './utils';

interface Props {
  tableData: SimulatorIntentTableRow[];
}

const typeSelectItems = [
  { label: 'Current Agent Action', value: MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT },
  { label: 'Next Agent Action', value: MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT },
  { label: 'Current Visitor Action', value: MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT },
  { label: 'Driver', value: MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER },
];

export function SimulatorResultIntentTable({
  tableData,
}: Props) {
  const [search, setSearch] = useState('');
  const [, setTableType] = useUrlParam<string>('tableType');
  const [intentFilter, setIntentFilter] = useUrlParam<string>('intentFilter', '');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [, setChangeTypeFilter] = useUrlParam<string>('changeTypeFilter', '');

  const columns: ColumnsType<SimulatorIntentTableRow> = [
    {
      title: 'Intent Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (displayName, record) => (
        <div>
          {record.conceptRole ? (
            <>
              <RoleIcon conceptRole={record.conceptRole} />
              <MultiTags tags={[displayName]} />
            </>
          ) : displayName}
        </div>
      ),
      sorter: (a: SimulatorIntentTableRow, b: SimulatorIntentTableRow) => a.name.localeCompare(b.name),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'Type',
      width: '20%',
      render: (type, record) => (
        <div>
          {momentTypeToLabel(type)}
        </div>
      ),
      sorter: (a: SimulatorIntentTableRow, b: SimulatorIntentTableRow) => a.type.localeCompare(b.type),
    },
    {
      title: <span data-type="same" className={styles.simulatorDashboardCardTitle}>Same</span>,
      dataIndex: 'same',
      key: 'same',
      width: '20%',
      render: (v, row) => (INTENT_DETECTION_TYPES.includes(row.type) && v > 0 ? (
        <div
          className={styles.linkButton}
          onClick={() => {
            setTableType('Detection');
            setIntentFilter(row.name);
            setChangeTypeFilter('same');
          }}
        >
          {v}
        </div>
      ) : v),
      sorter: (a: SimulatorIntentTableRow, b: SimulatorIntentTableRow) => a.same - b.same,
    },
    {
      title: <span data-type="added" className={styles.simulatorDashboardCardTitle}>Added</span>,
      dataIndex: 'added',
      key: 'added',
      width: '30%',
      render: (v, row) => (INTENT_DETECTION_TYPES.includes(row.type) && v > 0 ? (
        <div
          className={styles.linkButton}
          onClick={() => {
            setTableType('Detection');
            setIntentFilter(row.name);
            setChangeTypeFilter('added');
          }}
        >
          {v}
        </div>
      ) : v),
      sorter: (a: SimulatorIntentTableRow, b: SimulatorIntentTableRow) => a.added - b.added,
    },
    {
      title: <span data-type="removed" className={styles.simulatorDashboardCardTitle}>Removed</span>,
      dataIndex: 'removed',
      key: 'removed',
      width: '30%',
      render: (v, row) => (INTENT_DETECTION_TYPES.includes(row.type) && v > 0 ? (
        <div
          className={styles.linkButton}
          onClick={() => {
            setTableType('Detection');
            setIntentFilter(row.name);
            setChangeTypeFilter('removed');
          }}
        >
          {v}
        </div>
      ) : v),
      sorter: (a: SimulatorIntentTableRow, b: SimulatorIntentTableRow) => a.removed - b.removed,
    },
  ];

  const filteredData = useMemo(() => tableData.filter((row) => {
    if (search && !row.name.toLowerCase().includes(search.toLowerCase())) {
      return false;
    }
    if (typeFilter && row.type !== typeFilter) {
      return false;
    }
    if (intentFilter && row.name !== intentFilter) {
      return false;
    }
    return true;
  }), [tableData, search, typeFilter, intentFilter]);

  const uniqueIntents = useMemo(() => tableData?.reduce(
    (acc, curr) => {
      if (!acc.find((row) => row.name === curr.name)) {
        acc.push(curr);
      }
      return acc;
    }
    , [],
  ), [tableData]);

  // shortenNames over 20 characters with ellipsis (otherwise antd dropdown breaks)
  const shortenNames = (name: string) => {
    if (name?.length > 20) {
      return `${name.substring(0, 20)}...`;
    }
    return name;
  };

  return (
    <>
      <div className={styles.simulatorResultSelect}>
        <div className={styles.simulatorResultToggle} style={{ flex: 1 }}>
          <div style={{ flex: 1 }}>
            {`${filteredData?.length} intent${filteredData?.length > 1 ? 's' : ''}`}
          </div>
          <div className={styles.simulatorResultFilter}>
            <Select
              showArrow
              className={styles.simulatorResultFilterSelect}
              size="large"
              placeholder="All intents"
              allowClear
              value={intentFilter}
              onChange={(value) => setIntentFilter(value)}
            >
              <Select.Option key="All types" value={null}>
                All intents
              </Select.Option>
              {uniqueIntents.map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.conceptRole ? (
                    <div style={{ lineHeight: '1.3em' }}>
                      <RoleIcon conceptRole={item.conceptRole} />
                      <MultiTags tags={[shortenNames(item.name)]} />
                    </div>
                  ) : item.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showArrow
              className={styles.simulatorResultFilterSelect}
              size="large"
              placeholder="All types"
              allowClear
              value={typeFilter}
              onChange={(value) => setTypeFilter(value)}
            >
              <Select.Option key="All types" value={null}>
                All types
              </Select.Option>
              {typeSelectItems.map((item) => (
                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <Input
              className={styles.simulatorResultFilterInput}
              suffix={<SearchOutlined />}
              placeholder="Search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.simulatorResultContent}>
        <div className={styles.tableContainer}>
          <Table
            columns={columns}
            className={styles.simulatorResultIntentTable}
            dataSource={filteredData}
            rowKey="id"
            scroll={{ y: '55vh' }}
            pagination={false}
            summary={(pageData) => {
              const same = pageData?.reduce((acc, curr) => acc + curr.same, 0);
              const added = pageData?.reduce((acc, curr) => acc + curr.added, 0);
              const removed = pageData?.reduce((acc, curr) => acc + curr.removed, 0);
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2}>{same}</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>{added}</Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{removed}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
