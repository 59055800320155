import { Concept, ConceptConceptType, IntentIntentType, SharedConceptConfigDeploymentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Box, Text, Select, Group, SelectItem } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { useSelector } from 'hooks/reduxHooks';
import React, { useMemo, useState, useContext, useCallback } from 'react';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { selectAllConcepts } from 'store/concept/selectors';
import { DataGrid } from 'components/DataGrid';
import ConceptTag from 'components/ConceptTag';
import { getConceptRole } from 'components/RoleIcon';
import { flattenCustomerUsecases } from 'components/CustomerPicker';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';

const conceptConfigDeploymentTypeMap = new Map([
  [SharedConceptConfigDeploymentType.GENERIC, 'OOB'],
  [SharedConceptConfigDeploymentType.FINETUNED, 'Customer OOB'],
]);

interface ConceptConfigRow {
  concept: Concept,
  type: SharedConceptConfigDeploymentType,
  customers: string[],
}

export function OutOfBoxIntents() {
  const concepts = useSelector<Concept[]>(selectAllConcepts);
  const [intentsFilter, setIntentsFilter] = useState<string>();
  // Format is customerId/profileId/usecaseId
  const [customersFilter, setCustomersFilter] = useState<string>();
  const [typesFilter, setTypesFilter] = useState<SharedConceptConfigDeploymentType>();
  const { allConfigs, getFirstConfig } = useContext(CustomerConfigContext);

  // Filter out stages
  const intentsData: SelectItem[] = useMemo(() => concepts.filter((concept) =>
    concept.conceptType === ConceptConceptType.INTENT && concept?.intent?.intentType !== IntentIntentType.STAGE).map((intent) => ({
    label: intent.conceptTitle,
    value: intent.name,
  })), [concepts]);

  const typesData = [
    {
      label: 'OOB',
      value: SharedConceptConfigDeploymentType.GENERIC,
    },
    {
      label: 'Customer OOB',
      value: SharedConceptConfigDeploymentType.FINETUNED,
    },
  ];
  const customersData: SelectItem[] = useMemo(() => flattenCustomerUsecases(allConfigs), [allConfigs]);

  const tableData: ConceptConfigRow[] = useMemo(() => {
    const genericMap = new Map<Concept, string[]>();
    const finetunedMap = new Map<Concept, string[]>();

    concepts.forEach((concept) => {
      (concept.sharedConceptConfig || []).forEach((config) => {
        if (config.deploymentType === SharedConceptConfigDeploymentType.OFF) return;
        const configMap = config.deploymentType === SharedConceptConfigDeploymentType.GENERIC ? genericMap : finetunedMap;
        const customerKey = `${config.customerId}/${config.profileId}/${config.usecaseId}`;
        const customerStrings = configMap.get(concept) || [];
        configMap.set(concept, [...customerStrings, customerKey]);
      });
    });

    const rows: ConceptConfigRow[] = [];

    // Add generic types
    Array.from(genericMap.keys()).forEach((concept) => {
      rows.push({
        concept,
        type: SharedConceptConfigDeploymentType.GENERIC,
        customers: genericMap.get(concept),
      });
    });

    // Add finetuned types
    Array.from(finetunedMap.keys()).forEach((concept) => {
      rows.push({
        concept,
        type: SharedConceptConfigDeploymentType.FINETUNED,
        customers: finetunedMap.get(concept),
      });
    });

    return rows;
  }, [concepts, allConfigs]);

  const filteredTableData = useMemo(() => tableData.filter((row) => {
    if (intentsFilter && row.concept.name !== intentsFilter) {
      return false;
    }
    if (customersFilter && !row.customers.includes(customersFilter)) {
      return false;
    }
    if (typesFilter && row.type !== typesFilter) {
      return false;
    }
    return true;
  }), [tableData, intentsFilter, customersFilter, typesFilter]);

  const getCustomerNickName = useCallback((customerKey: string) => {
    const tokens = customerKey.split('/');
    const config = getFirstConfig({
      customerId: tokens[0],
      profileId: tokens[1],
      usecaseId: tokens[2],
      languageCode: '',
    });
    return `${toTitleCase(config?.customerShortName || '', '-')} (${config?.usecaseId})`;
  }, [getFirstConfig]);

  const columns: ColumnDef<ConceptConfigRow>[] = [
    {
      accessorFn: (row) => row.concept,
      header: 'Intent name',
      cell: (info) => {
        const concept = info.getValue() as Concept;
        return (
          <ConceptTag
            role={getConceptRole(concept)}
            value={concept?.conceptTitle}
          />
        );
      },
    },
    {
      accessorFn: (row) => row.type,
      header: 'Type',
      cell: (info) => {
        const type = info.getValue() as SharedConceptConfigDeploymentType;
        return (
          <MultiTags
            type="conceptTag"
            color="gray"
            size="small"
            tags={[conceptConfigDeploymentTypeMap.get(type)]}
          />
        );
      },
    },
    {
      accessorFn: (row) => row.customers,
      header: 'Customers',
      cell: (info) => {
        const customers = info.getValue() as string[];
        const customerNames = customers.map((customerKey) => getCustomerNickName(customerKey));
        return (
          <>
            <Text size="md">{customerNames.length}</Text>
            <Text size="md" color="dimmed">{customerNames.join(', ')}</Text>
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Group position="right">
        <Select
          placeholder="All intents"
          searchable
          clearable
          value={intentsFilter}
          onChange={setIntentsFilter}
          data={intentsData}
        />
        <Select
          placeholder="All types"
          searchable
          clearable
          value={typesFilter}
          onChange={(value) => setTypesFilter(value as SharedConceptConfigDeploymentType)}
          data={typesData}
        />
        <Select
          placeholder="All customers"
          searchable
          clearable
          value={customersFilter}
          onChange={setCustomersFilter}
          data={customersData}
        />
      </Group>
      <Box mt="lg">
        <DataGrid
          columns={columns}
          dataSource={filteredTableData}
        />
      </Box>
    </Box>
  );
}
