import { createSlice } from '@reduxjs/toolkit';
import {
  modelProjectApiFailed,
  modelProjectApiPending,
  listModelProjectsSuccess,
  createModelProjectsSuccess,
  updateModelProjectsSuccess,
} from './reducers';
import { listModelProjects, createModelProject, updateModelProject } from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Model project slice. */
export const modelProjectSlice = createSlice({
  name: 'modelProject',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(listModelProjects.pending, modelProjectApiPending)
      .addCase(listModelProjects.rejected, modelProjectApiFailed)
      .addCase(listModelProjects.fulfilled, listModelProjectsSuccess)
      .addCase(createModelProject.pending, modelProjectApiPending)
      .addCase(createModelProject.rejected, modelProjectApiFailed)
      .addCase(createModelProject.fulfilled, createModelProjectsSuccess)
      .addCase(updateModelProject.pending, modelProjectApiPending)
      .addCase(updateModelProject.rejected, modelProjectApiFailed)
      .addCase(updateModelProject.fulfilled, updateModelProjectsSuccess),
});
