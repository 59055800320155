import React from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { Message as ConversationMessage } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import './LabelingMessage.scss';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import Annotations, { AnnotationsDisplayType, AnnotationValueType } from 'components/Annotations';
import { WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getId, getParent } from 'common/resourceName';
import LetterAvatar from 'pages/Conversations/LetterAvatar';

interface Props {
  message: Message;
  annotations?: Annotation[];
  showSpeaker: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  scrollId?: string; // this is used in MessageList
  handleClick?: (message) => void;
  isFocused?: boolean;
  isSelected?: boolean; // whether the message is selected by filter;
  isBlur?: boolean;
  selectable?: boolean;
  annotationsDisplayType?: AnnotationsDisplayType;
  getConceptTitle?: (conceptId: string) => string;
  customTags?: JSX.Element;
  error?: string;
  annotationValueType?: AnnotationValueType;
}

export default function LabelingMessage({
  message,
  annotations,
  showSpeaker,
  isFocused,
  isSelected,
  isBlur,
  selectable = true,
  handleClick,
  annotationsDisplayType = 'concepts-colors',
  // Function to display concept titles from annotations
  // which only contain concept ids
  getConceptTitle = (id) => id,
  customTags,
  error,
  annotationValueType,
}: Props) {
  const { actorType } = message;

  let messageClass = 'system';
  if (actorType === ActorType.AGENT) {
    messageClass = 'agent';
  } else if (actorType === ActorType.VISITOR) {
    messageClass = 'visitor';
  }

  return (
    <div
      data-message-id={message.v2MessageId}
      className={classNames('labeling-message', { selectable }, { error }, messageClass, {
        focused: isFocused,
        selected: isSelected,
        blur: isBlur,
      })}
    >
      <div className="highlight" />
      <div className="bubble-wrapper">
        <div
          className="bubble"
          onClick={(e) => {
          // Prevent redirect with href links
            e.preventDefault();
            if (handleClick && selectable) {
              handleClick(message);
            }
          }}
        >
          {ReactHtmlParser(message.messageContent)}
        </div>
        {showSpeaker && (
        <span
          className={classNames('message-avatar', {
            right: actorType === ActorType.AGENT,
          })}
        >
          <LetterAvatar role={actorType}/>
        </span>
        )}
      </div>
      {customTags ? (
        <div
          className={classNames('message-meta', {
            right: actorType === ActorType.AGENT,
          })}
        >
          {customTags}
        </div>
      ) : null}
      {annotations && (
        <div
          className={classNames('message-meta', {
            right: actorType === ActorType.AGENT,
          })}
        >
          {error && (
            <Tooltip title={error} color="red">
              <WarningOutlined className="message-error"/>
            </Tooltip>
          )}
          <Annotations
            annotations={annotations}
            annotationsDisplayType={annotationsDisplayType}
            getConceptTitle={getConceptTitle}
            annotationValueType={annotationValueType}
          />
        </div>
      )}
    </div>
  );
}

export function toMessage(message: ConversationMessage): Message {
  return {
    messageId: getId('conversationMessage', message.name),
    v2MessageId: getId('conversationMessage', message.name),
    conversationId: getId('conversation', getParent('conversationMessage', message.name)),
    v2ConversationId: getId('conversation', getParent('conversationMessage', message.name)),
    messageContent: message.content,
    actorType: message.actorType,
  };
}
