// Shared component to add concepts that only require a display name.
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Button, Group, Radio, Stack, Text } from '@mantine/core';
import { conceptNameRule } from 'utils/form-rules';
import { Concept, ConceptConceptSource, ConceptConceptTagType, ConceptConceptType, ConceptState, SharedConceptConfigDeploymentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { useForm } from '@mantine/form';
import { v4 as uuid } from 'uuid';
import { getId } from 'common/resourceName';
import { cloneDeep } from 'lodash';
import { AddIntentForm, DUMMY_STAGE, NewConceptData } from './AddIntentForm';

const DEFAULT_DEPLOYMENT_TYPE = SharedConceptConfigDeploymentType.DEPLOYMENT_TYPE_UNSPECIFIED;

interface AddIntentModalProps {
  opened: boolean;
  handleCreateConcept: (concept: Concept, deploymentType: SharedConceptConfigDeploymentType, conceptId: string) => void;
  onClose: () => void;
  drivers: Concept[];
  stages: Concept[];
  intents: Concept[];
  conceptsMap: Map<string, Concept>;
}

// Convert concept name to id
function conceptWithId(concept: Concept) {
  return {
    ...concept,
    name: getId('concept', concept.name),
  };
}

export function AddIntentModal({
  opened,
  handleCreateConcept,
  onClose,
  drivers,
  stages,
  intents,
  conceptsMap,
}: AddIntentModalProps): ReactElement {
  // Validate concept title
  const nameValidator = (value) => {
    if (conceptNameRule.pattern.test(value)) {
      return null;
    }
    return conceptNameRule.message;
  };

  const [sharedIntentType, setSharedIntentType] = useState<SharedConceptConfigDeploymentType>(DEFAULT_DEPLOYMENT_TYPE);

  // Intent list copies (needed because we create placeholder concepts)
  const [localDrivers, setLocalDrivers] = useState<Concept[]>([]);
  const [localStages, setLocalStages] = useState<Concept[]>([]);
  const [localIntents, setLocalIntents] = useState<Concept[]>([]);

  // Concept form with initial values
  const conceptForm = useForm<NewConceptData>({
    initialValues: {
      driverId: '',
      stageId: '',
      intentId: '',
      humanReadableName: '',
      intentType: IntentIntentType.AGENT_INTENT,
      conceptTags: [ConceptConceptTagType.CALL_FLOW],
    },
  });

  useEffect(() => {
    if (opened) {
      // Convert concept names to ids that can be easily used in selects
      setLocalDrivers(drivers.map(conceptWithId));
      setLocalStages(stages.map(conceptWithId));
      setLocalIntents(intents.map(conceptWithId));
    }
  }, [opened]);

  const { driverId, stageId, intentId, intentType } = conceptForm.values;
  const selectedIntent = localIntents.find((concept) => concept.name === intentId);
  const isSharedIntent = selectedIntent?.conceptSource === ConceptConceptSource.SHARED;

  const resetModal = useCallback(() => {
    conceptForm.reset();
    setSharedIntentType(DEFAULT_DEPLOYMENT_TYPE);
  }, [conceptForm]);

  const hasErrors = !!Object.values(conceptForm.errors)?.length;
  const isValid = conceptForm.values.stageId || conceptForm.values.driverId || conceptForm.values.intentId;

  const sharedLibraryStages: Concept[] = useMemo(() => {
    const stagesSet = new Set<string>();
    intents.forEach((intent) => {
      const intentNames = intent.conceptTitle.split('.');
      const stageName = intentNames[intentNames?.length - 2];
      if (stageName) {
        stagesSet.add(stageName);
      }
    });
    const stageNames = Array.from(stagesSet);

    return stageNames.map((stageTitle) => ({
      labelingGuideline: '',
      description: '',
      conceptType: ConceptConceptType.INTENT,
      state: ConceptState.CREATED,
      conceptTitle: stageTitle,
      name: uuid(),
      intent: {
        intentType: IntentIntentType.STAGE,
      },
    }));
  }, [intents]);

  // Create a selectable placeholder concept in local state
  const createNewConcept = (conceptTitle: string, type: IntentIntentType) => {
    const { humanReadableName } = conceptForm.values;
    const conceptId = uuid();
    conceptForm.clearErrors();
    const nameError = nameValidator(conceptTitle);

    const returnedConcept = {
      label: conceptTitle,
      value: conceptId,
      type,
    };

    if (nameError) {
      let field = 'intentId';
      if (type === IntentIntentType.STAGE) {
        field = 'stageId';
      } else if (type === IntentIntentType.CONVERSATION_DRIVER) {
        field = 'driverId';
      }
      conceptForm.setFieldError(field, nameError);
      // returning null ensures the onChange handler in the form does not get called which clears the error
      return null;
    }

    const commonConcept: Concept = {
      labelingGuideline: '',
      description: '',
      conceptType: ConceptConceptType.INTENT,
      state: ConceptState.CREATED,
      intent: {
        displayName: humanReadableName,
      },
    };

    const stage = localStages.find((stage) => stage.name === stageId);
    if (!stage && [IntentIntentType.AGENT_INTENT, IntentIntentType.VISITOR_INTENT].includes(type)) {
      conceptForm.setFieldError('stageId', 'Stage is missing');
      return returnedConcept;
    }

    switch (type) {
      case IntentIntentType.CONVERSATION_DRIVER: {
        const newDriver: Concept = {
          ...commonConcept,
          conceptTitle,
          name: conceptId,
          intent: {
            ...commonConcept.intent,
            intentType: IntentIntentType.CONVERSATION_DRIVER,
          },
        };
        setLocalDrivers([...localDrivers, newDriver]);
        conceptForm.setFieldValue('driverId', conceptId);
        break;
      }
      case IntentIntentType.STAGE: {
        const newStage: Concept = {
          ...commonConcept,
          conceptTitle,
          name: conceptId,
          intent: {
            ...commonConcept.intent,
            intentType: IntentIntentType.STAGE,
          },
        };
        setLocalStages([...localStages, newStage]);
        conceptForm.setFieldValue('stageId', conceptId);
        break;
      }
      case IntentIntentType.AGENT_INTENT: {
        const newIntent: Concept = {
          ...commonConcept,
          conceptTitle: `${stage.conceptTitle}.${conceptTitle}`,
          name: conceptId,
          intent: {
            ...commonConcept.intent,
            intentType: IntentIntentType.AGENT_INTENT,
          },
        };
        setLocalIntents([...localIntents, newIntent]);
        conceptForm.setFieldValue('intentId', conceptId);
        break;
      }
      case IntentIntentType.VISITOR_INTENT: {
        const newIntent: Concept = {
          ...commonConcept,
          conceptTitle: `${stage.conceptTitle}.${conceptTitle}`,
          name: conceptId,
          intent: {
            ...commonConcept.intent,
            intentType: IntentIntentType.VISITOR_INTENT,
          },
        };
        setLocalIntents([...localIntents, newIntent]);
        conceptForm.setFieldValue('intentId', conceptId);
        break;
      }
      default:
        break;
    }
    return returnedConcept;
  };

  const handleSubmit = () => {
    const selectedDriver = localDrivers.find((concept) => concept.name === driverId);
    const alreadyExistsDriver = conceptsMap.get(selectedDriver?.name);

    const selectedStage = localStages.find((concept) => concept.name === stageId);
    const alreadyExistsStage = conceptsMap.get(selectedStage?.name);

    const selectedIntent = localIntents.find((concept) => concept.name === intentId);
    const isLibraryConcept = conceptsMap.get(selectedIntent?.name);

    // Create driver if needed
    if (selectedDriver && !alreadyExistsDriver) {
      const newDriver: Concept = {
        ...selectedDriver,
      };
      newDriver.intent.intentType = IntentIntentType.CONVERSATION_DRIVER;
      newDriver.intent.parentConversationDriverNames = [];
      handleCreateConcept(newDriver, sharedIntentType, driverId);
    }

    // Create stage if needed
    if (selectedStage && !alreadyExistsStage && selectedStage?.name !== DUMMY_STAGE.name) {
      const newStage = {
        ...selectedStage,
      };
      newStage.intent.intentType = IntentIntentType.STAGE;
      if (selectedDriver) {
        newStage.intent.parentConversationDriverNames = driverId ? [driverId] : [];
      }
      handleCreateConcept(newStage, sharedIntentType, stageId);
    }

    // Create intent if needed
    if (selectedIntent) {
      const newIntent = cloneDeep({
        ...selectedIntent,
        conceptTitle: selectedIntent.conceptTitle,
        conceptTags: conceptForm.values.conceptTags,
      });

      newIntent.intent.intentType = intentType;

      if (selectedDriver) {
        newIntent.intent.parentConversationDriverNames = driverId ? [driverId] : [];
      }

      if (isLibraryConcept) {
        newIntent.libraryConceptName = selectedIntent.name;
        handleCreateConcept(newIntent, sharedIntentType, uuid());
      } else {
        handleCreateConcept(newIntent, sharedIntentType, intentId);
      }
    }

    onClose();
    resetModal();
  };

  return (
    <Modal
      title="Add new intent"
      opened={opened}
      onClose={() => {
        onClose();
        resetModal();
      }}
      size="400px"
    >
      <AddIntentForm
        intents={localIntents}
        stages={[...localStages, ...sharedLibraryStages]}
        drivers={localDrivers}
        conceptForm={conceptForm}
        conceptsMap={conceptsMap}
        createNewConcept={createNewConcept}
        setIntents={setLocalIntents}
      />
      {(isSharedIntent) && (
        <>
          <Text size="sm" mt="md" mb="xs" weight={500}>Select type</Text>
          <Stack spacing="xs">
            <Radio
              checked={sharedIntentType === SharedConceptConfigDeploymentType.DEPLOYMENT_TYPE_UNSPECIFIED}
              label="Template (labeling required)"
              value={SharedConceptConfigDeploymentType.DEPLOYMENT_TYPE_UNSPECIFIED}
              onChange={(e) => setSharedIntentType(e.target.value as SharedConceptConfigDeploymentType)}
            />
            <Radio
              checked={sharedIntentType === SharedConceptConfigDeploymentType.GENERIC}
              label="Cross-customer OOB model (no labeling)"
              disabled={!isSharedIntent}
              value={SharedConceptConfigDeploymentType.GENERIC}
              onChange={(e) => setSharedIntentType(e.target.value as SharedConceptConfigDeploymentType)}
            />
            <Radio
              checked={sharedIntentType === SharedConceptConfigDeploymentType.FINETUNED}
              label="Customer OOB model for this customer (no labeling)"
              disabled={!isSharedIntent}
              value={SharedConceptConfigDeploymentType.FINETUNED}
              onChange={(e) => setSharedIntentType(e.target.value as SharedConceptConfigDeploymentType)}
            />
          </Stack>
        </>
      )}
      <Group mt="lg" position="right">
        <Button
          variant="subtle"
          onClick={() => {
            onClose();
            resetModal();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          data-cy="add-concept-button"
          disabled={hasErrors || !isValid}
        >
          Add
        </Button>
      </Group>
    </Modal>
  );
}
