// note this is .js and not .ts so it can be used in util scripts
// w/o requiring typescript compilation

export default {
  datadog: {
    applicationId: process.env.REACT_APP_RUM_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN || '',
  },
  mixpanel: {
    key: '131e4ada7413a38195b55ba41cf96af2',
  },
};
