import React, { forwardRef } from 'react';
import { Button, Group, Indicator, Select, Text } from '@mantine/core';
import { usePromptVersions } from '../hooks/usePromptVersions';
import { IPrompt, usePrompts } from '../hooks/usePrompts';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  isActive: boolean;
  label: string;
  value: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ isActive, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Indicator disabled={!isActive}>
        <Text size="xs">{label}</Text>
      </Indicator>
    </div>
  ),
);

interface VersionSelectProps {
  promptGroupId: string;
  initialVersion: number;
  isActiveVersion: boolean;
  onSelectPrompt: (prompt: IPrompt) => void;
}

export const VersionSelect = ({
  promptGroupId,
  initialVersion,
  isActiveVersion,
  onSelectPrompt,
}: VersionSelectProps) => {
  const [prompts] = usePromptVersions(promptGroupId);
  const { updatePrompt } = usePrompts();
  const versions: ItemProps[] = prompts.map((p) => ({
    label: p.version.toString(),
    value: p.version.toString(),
    isActive: p.isActiveVersion,
  }));
  const sortedVersions = versions.sort((a, b) => +b.value - +a.value);
  const data = [...sortedVersions];
  const onActivate = () => {
    const prompt = prompts.find((p) => p.version === initialVersion);
    if (!prompt) return;
    updatePrompt({
      id: prompt.id,
      isActiveVersion: true,
    });
  };
  return (
    <Group align="end">
      {isActiveVersion || (
        <Button onClick={onActivate}>Activate</Button>
      )}
      <Select
        w={70}
        value={initialVersion.toString()}
        onChange={(val) => {
          const selectedPrompt = prompts.find((p) => p.version.toString() === val);
          return onSelectPrompt(selectedPrompt);
        }}
        label="Version"
        placeholder="Select version"
        data={data}
        itemComponent={SelectItem}
      />
    </Group>
  );
};
