import React from 'react';
import { Box, Stack } from '@mantine/core';

// TODO: Eventually we want to eleminate this component in favor of Mantine's "Appshell" component
export const PageContainer = ({ style = {}, className = '', centered = false, children }) => (
  <Box style={{ width: '100%', overflow: 'auto' }}>
    <Stack
      className={className}
      p="xl"
      style={{
        flex: 1,
        alignItems: centered ? 'center' : 'stretch',
        maxWidth: 1600,
        margin: '20px auto',
        ...style,
      }}
    >
      {children}
    </Stack>
  </Box>
);
