import { PayloadAction } from '@reduxjs/toolkit';
import { CustomerParams } from 'hooks/useCustomerParams';
import { CustomersCurrentUserIdMap } from './asyncThunks';
import { State, SyntheticLabelsModalData } from './state';

export function updateTaskPlaylistCustomersReducer(state: State, action: PayloadAction<CustomerParams[]>): State {
  return {
    ...state,
    taskPlaylistCustomers: action.payload,
  };
}

export function getCustomersCurrentUserIdsSuccess(state: State, action: PayloadAction<CustomersCurrentUserIdMap>): State {
  return {
    ...state,
    customersCurrentUserIds: action.payload,
  };
}

/** Toggle synthetic labels modal and update its values */
export function updateSyntheticLabelsModalReducer(state: State, action: PayloadAction<SyntheticLabelsModalData>): State {
  return {
    ...state,
    syntheticLabelsModal: {
      ...state.syntheticLabelsModal,
      ...action.payload,
    },
  };
}

/** Update debugging mode (used to display debugging tools and features) */
export function updateDebuggingModeReducer(state: State, action: PayloadAction<boolean>): State {
  return {
    ...state,
    debuggingMode: action.payload,
  };
}
