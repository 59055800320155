import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { SerializedError } from '@reduxjs/toolkit';
import { ApiStatus } from 'store/types';

export interface State {
  modelWorkflows: ModelWorkflow[],
  modelWorkflow: ModelWorkflow | null,
  status: ApiStatus,
  error: SerializedError,
  nextPageToken: string | null,
}

/** Initial user state. */
export const INITIAL_STATE: State = {
  modelWorkflows: [],
  modelWorkflow: null,
  status: 'idle',
  error: null,
  nextPageToken: null,
};
