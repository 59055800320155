import { createSlice } from '@reduxjs/toolkit';
import {
  getModelArtifactSuccess,
  getServingModelDetailsPending,
  getServingModelDetailsFailed,
  getServingModelDetailsSuccess,
  getModelTaxonomiesPending,
  getModelTaxonomiesFailed,
  getModelTaxonomiesSuccess,
  getServingModelDetailsFromAIServicesPending,
  getServingModelDetailsFromAIServicesSuccess,
  getServingModelDetailsFromAIServicesFailed,
} from './reducers';
import {
  getModelArtifact,
  getServingModelDetails,
  getModelTaxonomies,
  getServingModelDetailsFromAIServices,
} from './asyncThunks';
import { INITIAL_STATE } from './state';

/** model builder slice. */
export const modelBuilderSlice = createSlice({
  name: 'modelBuilder',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(getServingModelDetails.pending, getServingModelDetailsPending)
      .addCase(getServingModelDetails.fulfilled, getServingModelDetailsSuccess)
      .addCase(getServingModelDetails.rejected, getServingModelDetailsFailed)
      .addCase(getModelTaxonomies.pending, getModelTaxonomiesPending)
      .addCase(getModelTaxonomies.fulfilled, getModelTaxonomiesSuccess)
      .addCase(getModelTaxonomies.rejected, getModelTaxonomiesFailed)
      .addCase(getModelArtifact.fulfilled, getModelArtifactSuccess)
      .addCase(getServingModelDetailsFromAIServices.pending, getServingModelDetailsFromAIServicesPending)
      .addCase(getServingModelDetailsFromAIServices.fulfilled, getServingModelDetailsFromAIServicesSuccess)
      .addCase(getServingModelDetailsFromAIServices.rejected, getServingModelDetailsFromAIServicesFailed),
});
