import { EnterOutlined } from '@ant-design/icons';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { Conversation, ConversationSyntheticType } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { CreateMessageResponse, Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { Box, Button, Divider, Input, NumberInput, Textarea, UnstyledButton } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { getId } from 'common/resourceName';
import { ChatWindowTopper } from 'components/ChatWindowTopper';
import { openNotification } from 'components/Notification';
import moment from 'moment';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import MessageBubble from 'pages/Conversations/ChatWindow/Message';
import MessageList from 'pages/Conversations/ChatWindow/MessageList';
import LetterAvatar from 'pages/Conversations/LetterAvatar';
import Loader from 'pages/Conversations/Loader';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Message as PreviewMessage } from '../../../types';
import styles from './MessagesPreviewer.module.scss';

interface Props {
  conversation: Conversation;
  messages: Message[];
  loading: boolean;
  selectedMessageIds?: string[];
  handleMessageClicked?: (message: PreviewMessage) => void;
  onAdd: (message: Message) => Promise<CreateMessageResponse>;
  handleUpdateConversationDisplayName: (conversationId: string, displayName: string) => void;
  disableAll?: boolean;
}

const ROLES_LIST = [ActorType.SYSTEM, ActorType.AGENT, ActorType.VISITOR];

export const MessagesPreviewer = ({
  conversation,
  messages: originMessage,
  loading,
  selectedMessageIds,
  handleMessageClicked,
  onAdd,
  handleUpdateConversationDisplayName,
  disableAll,
}: Props) => {
  const [messages, setMessages] = useState<Message[]>(originMessage);
  const [convoName, setConvoName] = useState<string>('');
  const [mins, setMins] = useState<number | ''>(0);
  const [secs, setSecs] = useState<number | ''>(1);
  const [actorRole, setActorRole] = useState<number>(0);
  const [newUtterance, setNewUtterance] = useState<string>('');
  const [contentError, setContentError] = useState<string | undefined>();
  const [latestTime, setLatestTime] = useState<moment.Moment>(moment());
  const [addMsgLoading, setAddMsgLoading] = useState<boolean>(false);
  const conversationId = getId('conversation', conversation?.name);
  const editable = conversation?.metadata?.syntheticType === ConversationSyntheticType.USER_DEFINED_CONVERSATION;
  useEffect(() => {
    const name = conversation?.metadata?.displayName || '';
    setConvoName(name);
  }, [conversation]);

  useEffect(() => {
    setMessages(originMessage);
    setLatestTime(moment(originMessage.at(-1)?.publishTime || moment()));
  }, [originMessage]);

  useEffect(() => {
    if (!messages?.length) {
      setActorRole(0);
    } else if (messages.at(-1).actorType === ActorType.VISITOR) {
      setActorRole(1);
    } else {
      setActorRole(2);
    }
  }, [messages]);

  const handleEnterDisplayName = useCallback(() => {
    if (handleUpdateConversationDisplayName && conversationId) {
      handleUpdateConversationDisplayName(conversationId, convoName);
    }
  }, [conversationId, convoName, handleUpdateConversationDisplayName]);

  const previewMessages: PreviewMessage[] = useMemo(
    () =>
      messages.map((msg) => {
        const id = getId('conversationMessage', msg.name);
        const pubDate = new Date(moment(msg.publishTime).format(ACCEPTED_DATE_FORMAT));
        return {
          id,
          text: msg.content,
          pubDate,
          speakerId: msg.actorId,
          speakerRole: msg.actorType.toLowerCase(),
          msgId: id,
          chat_name: null,
          suggestions: null,
          hints: null,
        };
      }),
    [messages],
  );

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setConvoName(e.target.value), [setConvoName]);

  const handleSwitchRole = useCallback(() => setActorRole((actorRole + 1) % 3), [setActorRole, actorRole]);

  const handleChangeNewUtterance = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value) setContentError(undefined);
    setNewUtterance(e.target.value);
  }, [setNewUtterance, setContentError]);

  const hanldeAddNewMessage = useCallback(() => {
    if (disableAll) {
      return;
    }
    if (newUtterance) {
      const pubTime = latestTime.add(mins, 'm').add(secs, 's');
      const newMsg = {
        content: newUtterance,
        actorType: ROLES_LIST[actorRole],
        actorId: '',
        publishTime: pubTime.format(ACCEPTED_DATE_FORMAT),
        platformMessageId: 'chat',
      };
      setAddMsgLoading(true);
      onAdd(newMsg)
        .then(() => {
          setContentError(undefined);
          setNewUtterance('');
          setLatestTime(pubTime);
          setMessages([...messages, newMsg]);
        })
        .catch((error) => openNotification('error', 'getConversation', undefined, error))
        .finally(() => setAddMsgLoading(false));
    } else setContentError('message can not be empty.');
  }, [disableAll, newUtterance, messages, actorRole]);

  const messageHeader: React.ReactNode = useMemo(() => (conversation?.openTime ? moment(conversation.openTime).format('YYYY-MM-DD hh:mm:ss a') : 'No conversations'), [conversation]);

  return (
    <div className={styles.messagePreviewerContainer}>
      {editable && (
        <div className={styles.syntheticConvoHeader}>
          <div className={styles.convoName}>
            <span>Name:</span>
            <Input
              classNames={{ input: styles.convoNameInput }}
              radius="xs"
              placeholder="Display name (press 'enter' to save)"
              value={convoName}
              onChange={handleChangeName}
              onKeyDown={getHotkeyHandler([
                ['Enter', handleEnterDisplayName],
              ])}
            />
          </div>
          <div className={styles.convoTime}>
            <span>Time apart each message:</span>
            <NumberInput hideControls radius="sm" placeholder="00" min={0} max={99} value={mins} onChange={setMins} /> mins
            <NumberInput hideControls radius="sm" placeholder="01" min={0} max={59} value={secs} onChange={setSecs} /> secs
          </div>
        </div>
      )}
      <div className={styles.messagesList}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!editable && (
              <>
                <Box px="md">
                  <ChatWindowTopper conversationName={conversation?.name} />
                </Box>
                <Divider mt="md" style={{ borderTopColor: '#E0E0E0' }} />
              </>
            )}
            <div className={styles.scrollContainer}>
              <div className={styles.startTime}>{messageHeader}</div>
              <MessageList>
                {previewMessages.map((message) => {
                  const messageComponent = (
                    <MessageBubble
                      scrollId={message.id}
                      key={message.id}
                      message={message}
                      handleClick={() => handleMessageClicked && handleMessageClicked(message)}
                      isSelected={
                        selectedMessageIds
                        && selectedMessageIds.includes(message.id)
                      }
                      adornment={() => <LetterAvatar role={message.speakerRole} />}
                    />
                  );
                  return [messageComponent];
                })}
              </MessageList>
            </div>
          </>
        )}
      </div>
      {editable && (
        <div className={styles.messageEditor}>
          <div className={styles.roleButton}>
            <UnstyledButton aria-label="switch role" onClick={handleSwitchRole}><LetterAvatar role={ROLES_LIST[actorRole]} /></UnstyledButton>
          </div>
          <div className={styles.divider} />
          <Textarea
            autosize
            error={contentError}
            minRows={1}
            maxRows={5}
            radius="sm"
            classNames={{ root: styles.contentInput }}
            value={newUtterance}
            onChange={handleChangeNewUtterance}
            onKeyDown={getHotkeyHandler([
              ['shift+Enter', hanldeAddNewMessage],
            ])}
            placeholder="Enter message here, press 'shift + enter' to save"
            disabled={disableAll}
          />
          <Button
            onClick={hanldeAddNewMessage}
            disabled={addMsgLoading}
            aria-label="enter"
            radius="sm"
            variant="light"
          >
            {addMsgLoading ? <Loader /> : <EnterOutlined />}
          </Button>
        </div>
      )}
    </div>
  );
};
