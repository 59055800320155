import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { eventBus } from 'utils/event-bus';
import './IntentInfoButton.scss';

export interface IntentDetailButtonProps {
  intentName?: string;
  onClick?: (intentName: string) => void;
}

export default function IntentDetailButton({
  intentName,
  onClick,
}: IntentDetailButtonProps) {
  return (
    <button
      type="button"
      className="intent-info-button"
      onClick={() => (onClick ? onClick(intentName) : (eventBus.dispatch('intentdrawer', { detail: intentName })))}
    >
      <InfoCircleOutlined/>
      {intentName}
    </button>
  );
}
