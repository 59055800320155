import React from 'react';
import { Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import CopyableValue from 'components/CopyableValue';
import { truncateMiddle } from 'utils';
import MantineUserTag from 'components/UserTag/MantineUserTag';
import { SetData } from '.';

interface MetadataItemProps {
  label: string;
  children: React.ReactNode;
}

const MetadataItem = ({ label, children }: MetadataItemProps) => (
  <Group spacing={5}>
    <Text size="sm" color="dimmed">{label}</Text>
    {children}
  </Group>
);

interface TaskMetadataProps {
  taskId: string
  author: string
  endTime: string
  totalConversations: number
  sets: SetData[]
}

export const TaskMetadata = ({ taskId, author, endTime, totalConversations, sets }: TaskMetadataProps) => (
  <Stack spacing={0}>
    <Group>
      <MetadataItem label="Task Id:">
        <CopyableValue
          buttonProps={{
            size: 'sm',
          }}
          displayValue={truncateMiddle(taskId)}
          copiedValue={taskId}
        />
      </MetadataItem>
      <MetadataItem label="Assignee:">
        <MantineUserTag size="sm" name={author} />
      </MetadataItem>
      <MetadataItem label="Date completed:">
        <Text size="sm">{dayjs(new Date(endTime)).format('MM/DD/YYYY h:mm a')}</Text>
      </MetadataItem>
    </Group>
    <Group>
      <MetadataItem label="Total # conversations:">
        <Text size="sm">{totalConversations}</Text>
      </MetadataItem>
      {sets.map((set, index) => (
        <MetadataItem key={set.id} label={`Set ${index + 1}:`}>
          <Text size="sm">{set?.title || ''} ({set.convoQuantity} convos)</Text>
        </MetadataItem>
      ))}
    </Group>
  </Stack>
);
