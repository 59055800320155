import {
  AttachManualWorkRequest,
  AttachManualWorkResponse,
  CreateModelWorkflowRequest,
  CreateModelWorkflowResponse,
  GetModelWorkflowRequest,
  GetModelWorkflowResponse,
  ListModelWorkflowsRequest,
  ListModelWorkflowsResponse,
  ModelWorkflowService,
} from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { StudioApi } from './studioApi';

export abstract class ModelWorkflowApi {
  public static createModelWorkflow(req: CreateModelWorkflowRequest): Promise<CreateModelWorkflowResponse> {
    return ModelWorkflowService.CreateModelWorkflow(req, StudioApi.getHeaders().initReq);
  }

  public static listModelWorkflow(req: ListModelWorkflowsRequest): Promise<ListModelWorkflowsResponse> {
    return ModelWorkflowService.ListModelWorkflows(req, StudioApi.getHeaders().initReq);
  }

  public static getModelWorkflow(req: GetModelWorkflowRequest): Promise<GetModelWorkflowResponse> {
    return ModelWorkflowService.GetModelWorkflow(req, StudioApi.getHeaders().initReq);
  }

  public static attachManualWork(req: AttachManualWorkRequest): Promise<AttachManualWorkResponse> {
    return ModelWorkflowService.AttachManualWork(req, StudioApi.getHeaders().initReq);
  }
}
