import { ConversationMessageSpeakerRole, MessagePrediction } from '@cresta/web-client';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { Preview } from '../../types';

export const convertMessagePredictionsToPreviews: (
  messagePredictions: MessagePrediction[],
) => Preview[] = (messagePredictions) => {
  const previews: Preview[] = messagePredictions.map((prediction) => {
    const { name, agent, createTime, text, speaker } = prediction.message;
    const speakerRole: 'Visitor' | 'Agent' = speaker === ConversationMessageSpeakerRole.VISITOR ? 'Visitor' : 'Agent';
    const preview = {
      agent,
      date: dayjs(createTime).format(DATE_TIME_FORMAT),
      name,
      message: text,
      speaker: speakerRole,
    };
    return preview;
  });

  return previews;
};
