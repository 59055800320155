import React from 'react';
import './ErrorMessage.scss';

interface IProps {
  message?: string;
  children?: React.ReactNode;
}

export default function ErrorMessage({ message, children }: IProps) {
  return (
    <>
      <div className="error-message-wrapper">
        <h1>An error occurred</h1>
        <p className="error-message">{message}</p>
        <p>
          Please refresh the page and try again. If the error persists, please message
          the #studio Slack channel with the error.
        </p>
      </div>
      {!!children && (
        <div>
          {children}
        </div>
      )}
    </>
  );
}
