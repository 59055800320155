import { Set, SetState } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { State } from './state';

/** General reducer for starting a set API. */
export function setApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed set API. */
export function setApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** Reducer for listing sets successfully. */
export function listSetsSuccess(state: State, action: PayloadAction<Set[]>): State {
  return {
    ...state,
    status: 'succeeded',
    sets: action.payload,
  };
}

/** Get/Create set success. */
export function getOrCreateSetSuccess(state: State, action: PayloadAction<Set>): State {
  const index = state.sets.findIndex((set) => set.name === action.payload.name);
  const newSets = cloneDeep(state.sets);
  if (index === -1) {
    newSets.push(action.payload);
  } else {
    newSets.splice(index, 1, action.payload);
  }
  return {
    ...state,
    status: 'succeeded',
    sets: newSets,
  };
}

/** Reducer for deleting sets. */
export function deleteSetSuccess(state: State, action: PayloadAction<string>): State {
  const index = state.sets.findIndex((set) => set.name === action.payload);
  const newSets = cloneDeep(state.sets);
  if (index !== -1) {
    newSets[index].state = SetState.DEPRECATED;
  }

  return {
    ...state,
    status: 'succeeded',
    sets: newSets,
  };
}
