import { CreatorCreatorType } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import { ConceptType, DecisionType, LabelingViewType, SelectionMethod, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { CreateTaskRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';

interface CreateNewTaskRequest {
  taskName: string
  usecaseName: string
  languageCode: string
  title: string
  userId: string
  conceptIds: string[]
  setNames: string[]
}

const getConversationSampleInfos = (setNames:string[]) => {
  const conversationSampleInfos = setNames.map((setName) => ({
    conversationSetName: setName,
    maxConversationCount: 1000,
  }));
  return conversationSampleInfos;
};

export const createNewTaskRequest = ({
  taskName,
  title,
  userId,
  conceptIds,
  setNames,
  usecaseName,
  languageCode,
}: CreateNewTaskRequest) => {
  const conversationSampleInfos = getConversationSampleInfos(setNames);

  const newTaskRequest: CreateTaskRequest = {
    name: taskName,
    commonInput: {
      title,
      usecase: usecaseName,
      languageCode,
      creator: {
        creatorType: CreatorCreatorType.UI_ACTION,
        userId,
      },
      assigneeUserId: userId,
    },
    taskDescriptor: {
      conceptIds,
      viewType: LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_PARTIAL_CONVERSATION_CONTEXT,
      decisionType: DecisionType.DECISION_TYPE_MULTIPLE_BINARY,
      targetType: TargetType.MANUAL_ANALYSIS,
      selectionInstruction: {
        conversationSetsInstruction: {
          conversationSampleInfos,
        },
        selectionMethod: SelectionMethod.CONVERSATION_SETS,
      },
      conceptType: ConceptType.TAG,
    },
  };

  return newTaskRequest;
};
