import {
  CompleteTaskRequest,
  CompleteTaskResponse,
  CreateTaskRequest,
  CreateTaskResponse,
  DeleteTaskRequest,
  DeleteTaskResponse,
  ExtendLabelingSessionRequest,
  ExtendLabelingSessionResponse,
  FetchLabelingDataRequest,
  FetchLabelingDataResponse,
  FetchLabelingItemsRequest,
  FetchLabelingItemsResponse,
  FetchTaskAnnotationsSummaryRequest,
  FetchTaskAnnotationsSummaryResponse,
  LabelingTaskService,
  ListDerivedTasksRequest,
  ListDerivedTasksResponse,
  ModifyTaskConceptsRequest,
  ModifyTaskConceptsResponse,
  RemoveTemporalAnnotationRequest,
  RemoveTemporalAnnotationResponse,
  SaveTemporalAnnotationRequest,
  SaveTemporalAnnotationResponse,
  SelectNewBatchRequest,
  SelectNewBatchResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { CancellableFetch, StudioApi } from './studioApi';

export abstract class LabelingTaskApi {
  public static createTask(
    req: CreateTaskRequest,
  ): Promise<CreateTaskResponse> {
    return LabelingTaskService.CreateTask(req, StudioApi.getHeaders().initReq);
  }

  public static fetchLabelingData(
    req: FetchLabelingDataRequest,
  ): Promise<FetchLabelingDataResponse> {
    return LabelingTaskService.FetchLabelingData(req, StudioApi.getHeaders().initReq);
  }

  public static fetchLabelingItems(
    req: FetchLabelingItemsRequest,
  ): Promise<FetchLabelingItemsResponse> {
    return LabelingTaskService.FetchLabelingItems(req, StudioApi.getHeaders().initReq);
  }

  public static removeTemporalAnnotation(
    req: RemoveTemporalAnnotationRequest,
  ): Promise<RemoveTemporalAnnotationResponse> {
    return LabelingTaskService.RemoveTemporalAnnotation(req, StudioApi.getHeaders().initReq);
  }

  public static saveTemporalAnnotation(
    req: SaveTemporalAnnotationRequest,
  ): Promise<SaveTemporalAnnotationResponse> {
    return LabelingTaskService.SaveTemporalAnnotation(req, StudioApi.getHeaders().initReq);
  }

  public static extendLabelingSession(
    req: ExtendLabelingSessionRequest,
  ): Promise<ExtendLabelingSessionResponse> {
    return LabelingTaskService.ExtendLabelingSession(req, StudioApi.getHeaders().initReq);
  }

  public static selectNewBatch(
    req: SelectNewBatchRequest,
  ): Promise<SelectNewBatchResponse> {
    return LabelingTaskService.SelectNewBatch(req, StudioApi.getHeaders().initReq);
  }

  public static completeTask(
    req: CompleteTaskRequest,
  ): Promise<CompleteTaskResponse> {
    return LabelingTaskService.CompleteTask(req, StudioApi.getHeaders().initReq);
  }

  public static deleteTask(
    req: DeleteTaskRequest,
  ): Promise<DeleteTaskResponse> {
    return LabelingTaskService.DeleteTask(req, StudioApi.getHeaders().initReq);
  }

  public static listDerivedTasks(
    req: ListDerivedTasksRequest,
  ): CancellableFetch<ListDerivedTasksResponse> {
    const header = StudioApi.getHeaders();
    return {
      promise: LabelingTaskService.ListDerivedTasks(req, header.initReq),
      cancel: header.cancel,
    };
  }

  public static modifyTaskConcepts(
    req: ModifyTaskConceptsRequest,
  ): Promise<ModifyTaskConceptsResponse> {
    return LabelingTaskService.ModifyTaskConcepts(req, StudioApi.getHeaders().initReq);
  }

  public static fetchTaskAnnotationsSummary(
    req: FetchTaskAnnotationsSummaryRequest,
  ): Promise<FetchTaskAnnotationsSummaryResponse> {
    return LabelingTaskService.FetchTaskAnnotationsSummary(req, StudioApi.getHeaders().initReq);
  }
}
