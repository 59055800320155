import React, { FC } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './dialogPolicy.scss';

export const DialogPolicy: FC = () => (
  <div className="dialogPolicy">
    <div className="dialogPolicy-tabs">
      <NavLink
        to="edit"
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        Dialogue Policy
      </NavLink>
      <NavLink
        to="rule-graph"
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        Visualization
      </NavLink>
    </div>
    <Outlet />
  </div>
);
