import { PlusCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import React, { ReactElement, useMemo } from 'react';
import './IntentsDiff.scss';

interface IntentsDiffProps {
  studioIntents: string[];
  prodIntents: string[];
}

export function IntentsDiff({
  studioIntents,
  prodIntents,
}: IntentsDiffProps): ReactElement {
  const newIntents = useMemo(() => {
    const prodIntentsSet = new Set<string>(prodIntents);
    return studioIntents?.filter((intent) => !prodIntentsSet.has(intent));
  }, [studioIntents, prodIntents]);
  const deprecatedIntents = useMemo(() => {
    const studioIntentsSet = new Set<string>(studioIntents);
    return prodIntents?.filter((intent) => !studioIntentsSet.has(intent));
  }, [studioIntents, prodIntents]);
  if (!newIntents?.length && !deprecatedIntents?.length) {
    return <span>No Diff vs Production</span>;
  }
  return (
    <div className="intents-diff-wrapper">
      <span>Diff vs Production</span>
      <div className="intents-diff-container">
        {
          deprecatedIntents?.map((intent) => (
            <Tooltip
              key={`deprecated-${intent}`}
              title="Live in prod but not selected"
            >
              <Tag className="intents-diff-deprecated-intent">
                <StopOutlined />&nbsp;{intent}
              </Tag>
            </Tooltip>
          ))
        }
        {
          newIntents?.map((intent) => (
            <Tooltip
              key={`new-${intent}`}
              title="Intent not in production"
            >
              <Tag className="intents-diff-new-intent">
                <PlusCircleOutlined />&nbsp;{intent}
              </Tag>
            </Tooltip>
          ))
        }
      </div>
    </div>
  );
}
