import React, { useState, useContext, useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from '@mantine/core';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { DialoguePolicyDocs, useDialoguePolicyDocs } from 'hooks/useDialoguePolicy';
import { useCustomerParams } from 'hooks/useCustomerParams';
import '../dialogPolicy.scss';
import { PolicyEdit } from './PolicyEdit';

export const EditRoute = () => {
  const { currentConfig } = useContext(CustomerConfigContext);
  const { customerId, profileId, usecaseId, languageCode, path } = useCustomerParams();
  const githubLink = useMemo(() => {
    const env = currentConfig?.environmentId?.includes('staging') ? 'staging' : 'prod';
    const languageSuffix = languageCode === 'en-US' ? '' : `-${languageCode}`;
    return `https://github.com/cresta/config/commits/master/configv3/${env}/${customerId}/${profileId}/${usecaseId}/dialogue-policy${languageSuffix}.py`;
  }, [currentConfig?.environmentId, path]);
  const [editKey, setEditKey] = useState<undefined | keyof DialoguePolicyDocs>(undefined);
  const [docs, setDocs] = useDialoguePolicyDocs();

  const handleInputChange = (key: keyof DialoguePolicyDocs, value: string) => {
    const newDocs = {
      ...docs,
      [key]: value,
    };
    setDocs(newDocs);
    setEditKey(undefined);
  };

  const labelWrapper = (label: string, key: keyof DialoguePolicyDocs, value?: string) => (
    <div className="dialogPolicy-infoLabel">
      {label}:&nbsp;
      {
          key === editKey
            ? (
              <Input
                autoFocus
                defaultValue={docs[key]}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleInputChange(key, event.currentTarget.value);
                  }
                }}
                onBlur={() => setEditKey(undefined)}
              />
            )
            : (
              <>
                {value ? (
                  <a href={value} target="_blank" rel="noopener noreferrer">
                    {value.length > 30 ? `${value.substring(0, 30)}...` : value}
                  </a>
                ) : ''}
                <Tooltip label="Click to edit the link" withinPortal>
                  <Button
                    variant="subtle"
                    size="sm"
                    compact
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditKey(key);
                    }}
                  >
                    {value ? <EditOutlined /> : 'Add URL'}
                  </Button>
                </Tooltip>
              </>
            )
        }
    </div>
  );
  return (
    <>
      <div className="dialogPolicy-info">
        {labelWrapper('Notion', 'notionLink', docs.notionLink)}
        {labelWrapper('Lucid chart', 'lucidChartLink', docs.lucidChartLink)}
        <div className="dialogPolicy-infoLabel">
          Previous versions:&nbsp;<a href={githubLink} target="_blank" rel="noopener noreferrer">GitHub repo</a>
        </div>
      </div>
      <PolicyEdit />
    </>
  );
};
