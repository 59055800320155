import { ModelArtifact } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { SerializedError } from '@reduxjs/toolkit';
import { ApiStatus } from 'store/types';

export interface State {
    evaluationArtifact: ModelArtifact;
    comparedEvaluationArtifact: ModelArtifact;
    modelArtifacts: ModelArtifact[];
    status: ApiStatus;
    error: SerializedError;
}
export const INITIAL_STATE: State = {
  evaluationArtifact: null,
  comparedEvaluationArtifact: null,
  modelArtifacts: [],
  status: 'idle',
  error: null,
};
