import { Annotation, MessageRawData, MessageRawDataContextShown, RubricValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message, SummarizationInstructionRubricTagSelection, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import React, { useCallback, useEffect } from 'react';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { createStyles } from '@mantine/core';
import SummarizationQaDecision from 'components/LabelingDecision/SummarizationQaDecision/SummarizationQaDecision';
import { openNotification } from 'components/Notification';
import { RubricApi } from 'services/rubricApi';
import { ListRubricsRequest, Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';

interface SummarizationQaViewProps {
  concepts: Concept[];
  messages: Message[];
  conversationAnnotations: Annotation[];
  failedMessages: { [id: string]: boolean };
  getConceptTitle: (conceptId: string) => string;
  createAnnotation: (
    messageRawData: MessageRawData,
    rubricValue: RubricValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  prevChat: () => void;
  nextChat: () => void;
  isFirstChat: boolean;
  isLastChat: boolean;
  finishLabeling: () => void;
  modelUris: string[];
  setOverviewConceptId: (id: string) => void;
  rubricTagSelections: SummarizationInstructionRubricTagSelection[];
  rubrics: Rubric[];
  setRubrics: (rubrics: Rubric[]) => void;
}

export function SummarizationQaView({
  concepts,
  messages,
  conversationAnnotations,
  failedMessages,
  getConceptTitle,
  createAnnotation,
  upsertAnnotations,
  prevChat,
  nextChat,
  isFirstChat,
  isLastChat,
  finishLabeling,
  modelUris,
  setOverviewConceptId,
  rubricTagSelections,
  rubrics,
  setRubrics,
} : SummarizationQaViewProps) {
  const useStyles = createStyles((theme) => ({
    displayContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    displayChat: {
      flex: 1,
    },
    displaySuggestions: {
      maxHeight: '300px',
      overflow: 'auto',
    },
    decisionInner: {
      display: 'flex', flexDirection: 'column',
    },
    decisionContent: {
      flex: 1, height: '100%', overflow: 'hidden', borderBottom: `1px solid ${theme.colors.gray[0]}`,
    },
  }));

  const styles = useStyles();
  const context = MessageRawDataContextShown.FULL_CONVERSATION_SHOWN;

  const fetchRubrics = useCallback(async () => {
    try {
      const request: ListRubricsRequest = {
        pageSize: 100,
      };
      const response = await RubricApi.listRubrics(request);
      const filteredRubrics = response?.rubrics.filter((rubric) => rubric.availableTasks.includes(UserLabelingTaskType.QA_SUMMARIZATION));
      if (JSON.stringify(rubrics) !== JSON.stringify(filteredRubrics)) {
        setRubrics(filteredRubrics);
      }
    } catch (err) {
      openNotification('error', 'Failed to load rubrics', null, err);
    }
  }, [setRubrics]);

  // Load rubrics
  useEffect(() => {
    fetchRubrics();
  }, []);

  const submitAnnotations = useCallback((newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => {
    upsertAnnotations(newAnnotations, gotoNext, conversationAnnotations);
  }, [conversationAnnotations, upsertAnnotations]);

  return (
    <div
      className={classNames([
        'labeling-view',
        'vertical-layout',
      ])}
    >
      <div className={styles.classes.displayContainer}>
        <div className={classNames(['labeling-items-display', styles.classes.displayChat])}>
          <LabelingItemsDisplay
            context={context}
            showContextButtonVisible={false}
            messages={messages}
            targetMessageId={null}
            // Text annotations aren't shown in this component
            messageAnnotationsMap={new Map()}
            failedMessages={failedMessages}
            annotationsDisplayType="concepts-colors"
          />
        </div>
      </div>
      <div className="labeling-decision-panel">
        <SummarizationQaDecision
          concepts={concepts}
          message={messages[0]}
          annotations={conversationAnnotations}
          createAnnotation={createAnnotation}
          submitAnnotations={submitAnnotations}
          getConceptTitle={getConceptTitle}
          contextShown={context}
          modelUris={modelUris}
          rubrics={rubrics}
          nextChat={nextChat}
          setOverviewConceptId={setOverviewConceptId}
          rubricTagSelections={rubricTagSelections}
        />
      </div>
    </div>
  );
}
