import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { TaskVisibility } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { TaskStatus } from 'hooks/useResourceCreation';
import { User } from 'types';

const getStatusOptionsFromMap = (
  taskStatusMap: Map<
    TaskStatus,
    {
      text: string;
      className: string;
    }
  >,
) =>
  Array.from(taskStatusMap, ([key, value]) => ({
    value: key,
    label: value.text,
  }))
    .filter((option) => {
      // requested to shorten the dropdown
      const optionsToExclude = [
        TaskStatus.TASK_ABORTED,
        TaskStatus.TASK_STATUS_UNSPECIFIED,
        TaskStatus.TASK_TIMEOUT,
      ];
      return !optionsToExclude.includes(option.value);
    })
    .sort((a, b) => {
      // sort alphabetically by label
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

const getAssigneeOptions = (users: User[]) =>
  users.map((user) => ({
    value: user.id,
    label: user.full_name || user.email || user.id,
  }));

const getFilteredTasks = (
  tasks: DerivedLabelingTask[],
  status: string | null,
  assignee: string | null,
  isShowingArchived: boolean,
) => {
  const filterArchived = (task: DerivedLabelingTask) =>
    task.labelingTask.abstractTask.visibility !== TaskVisibility.INVISIBLE;
  const filterByStatus = (task: DerivedLabelingTask) =>
    task.labelingTask.abstractTask.status === status;
  const filterByAssignee = (task: DerivedLabelingTask) =>
    task.labelingTask.abstractTask.assigneeUserId === assignee;

  let filteredTasks = tasks;

  if (!isShowingArchived) {
    filteredTasks = filteredTasks.filter(filterArchived);
  }

  if (assignee) {
    filteredTasks = filteredTasks.filter(filterByAssignee);
  }

  if (status) {
    filteredTasks = filteredTasks.filter(filterByStatus);
  }
  const sortByDateCreated = (a: DerivedLabelingTask, b: DerivedLabelingTask) => {
    if (a.labelingTask.abstractTask.createTime < b.labelingTask.abstractTask.createTime) {
      return 1;
    }
    if (a.labelingTask.abstractTask.createTime > b.labelingTask.abstractTask.createTime) {
      return -1;
    }
    return 0;
  };

  const sortedTasks = filteredTasks.sort(sortByDateCreated);

  return sortedTasks;
};

export { getStatusOptionsFromMap, getAssigneeOptions, getFilteredTasks };
