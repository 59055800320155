import {
  ConversationService,
  ListConversationsRequest,
  ListConversationsResponse,
  CreateConversationRequest,
  CreateConversationResponse,
  UpdateConversationRequest,
  UpdateConversationResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { StudioApi } from './studioApi';

export abstract class ConversationApi {
  public static createConversation(request: CreateConversationRequest): Promise<CreateConversationResponse> {
    return ConversationService.CreateConversation(request, StudioApi.getHeaders().initReq);
  }

  public static updateConversation(request: UpdateConversationRequest): Promise<UpdateConversationResponse> {
    return ConversationService.UpdateConversation(request, StudioApi.getHeaders().initReq);
  }

  public static listConversations(request: ListConversationsRequest): Promise<ListConversationsResponse> {
    return ConversationService.ListConversations(request, StudioApi.getHeaders().initReq);
  }
}
