import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { conceptSlice } from './concept/conceptSlice';
import { userSlice } from './user/userSlice';
import { labelingTaskSlice } from './labelingTask/labelingTaskSlice';
import { appSlice } from './app/appSlice';
import { annotationSlice } from './annotation/annotationSlice';
import { conversationSlice } from './conversation/conversationSlice';
import { notificationSlice } from './notification/notificationSlice';
import { conceptChangeSlice } from './conceptChange/conceptChangeSlice';
import { modelProjectSlice } from './modelProject/modelProjectSlice';
import { modelWorkflowSlice } from './modelWorkflow/modelWorkflowSlice';
import { modelArtifactSlice } from './modelArtifact/modelArtifactSlice';
import { modelBuilderSlice } from './modelBuilder/modelBuilderSlice';
import { setSlice } from './set/setSlice';
import { agentSlice } from './agent/agentSlice';
import { jobSlice } from './job/jobSlice';

const rootReducer = combineReducers({
  annotation: annotationSlice.reducer,
  labelingTask: labelingTaskSlice.reducer,
  concept: conceptSlice.reducer,
  conceptChange: conceptChangeSlice.reducer,
  user: userSlice.reducer,
  app: appSlice.reducer,
  conversation: conversationSlice.reducer,
  notification: notificationSlice.reducer,
  modelProject: modelProjectSlice.reducer,
  modelWorkflow: modelWorkflowSlice.reducer,
  modelArtifact: modelArtifactSlice.reducer,
  modelBuilder: modelBuilderSlice.reducer,
  set: setSlice.reducer,
  agent: agentSlice.reducer,
  job: jobSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

/** Type of the root state of the store. */
export type State = ReturnType<typeof store.getState>;
/** Type of the store dispatch. */
export type Dispatch = typeof store.dispatch;
