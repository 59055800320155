import { Select } from 'antd';
import React, { useState, useContext } from 'react';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import styles from './styles.module.scss';

export default function HexCharts() {
  const { currentConfig } = useContext(CustomerConfigContext);
  const selectOptions = [
    {
      value:
        `https://cresta.hex.tech/global/app/438ff781-cd1b-49eb-aa5b-3915dede8926/latest?_customer_name="${currentConfig?.customerShortName}"&embedded=true&`,
      label: 'V2 Overall View',
    },
    {
      value:
        `https://cresta.hex.tech/global/app/a79ecf4c-35a0-4fa9-970d-e847c617aba8/latest?_customer_name="${currentConfig?.customerShortName}"&embedded=true&`,
      label: 'Legacy Overall View',
    },
    {
      value:
        'https://cresta.hex.tech/global/app/61bc7818-c3f4-4ac5-99bf-7e559314c090/latest?embedded=true',
      label: 'Overall View(Voice Customer)',
    },
    {
      value: 'https://cresta.hex.tech/global/app/8b8b1724-a7c5-4a3e-b6cd-6d85006dc3e4/latest?embedded=true',
      label: 'Receptivity View',
    },
    {
      value: 'https://cresta.hex.tech/global/app/2a1a1b23-c567-4375-abc0-0d6ddf07c93b/latest?embedded=true',
      label: 'Receptivity View(Voice Customer)',
    },
    {
      value: 'https://cresta.hex.tech/global/app/2e00f143-af51-474b-9d6c-732a8403a137/latest?embedded=true',
      label: 'Chat Drivers and QA View',
    },
    {
      value: 'https://cresta.hex.tech/global/app/d208e4bc-8540-4207-a68d-1f0a5eb40203/latest?embedded=true',
      label: 'Chat Drivers and QA View(Voice Customer)',
    },
    {
      value: 'https://cresta.hex.tech/global/app/e4b89ae8-04d7-472b-bc61-2a5733b8339b/latest?embedded=true',
      label: 'Overall Summary View',
    },
    {
      value: 'https://cresta.hex.tech/global/app/dea65e06-82ef-4da8-ad9a-d9a65429b43d/latest?embedded=true',
      label: 'Coach Dashboard',
    },
    {
      value: 'https://cresta.hex.tech/global/app/07da0b30-d5e7-4320-b3e6-def05b5a46f1/latest?embedded=true',
      label: 'Intent Model Quality Metrics',
    },

  ];

  const [iframeUrl, setIframeUrl] = useState(selectOptions[0].value);

  return (
    <div>
      <div className={styles.controls}>
        <Select
          defaultValue={iframeUrl}
          onChange={(value) => {
            setIframeUrl(value);
          }}
        >
          {selectOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <iframe
        style={{
          position: 'absolute',
          width: 'calc(100% - 60px)',
          height: 'calc(100% - 80px)',
          bottom: 0,
          right: 0,
          left: '60px',
          border: 'none',
        }}
        title="hex-charts"
        src={`${iframeUrl}&_customer_name="${currentConfig?.customerShortName}"`}
      />
    </div>
  );
}
