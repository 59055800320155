/* eslint dot-notation: 0 */
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from '@mantine/hooks';
import { Text } from '@mantine/core';
import { MessageApi } from 'services/messageApi';
import { useSelector } from 'hooks/reduxHooks';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import {
  CreateSimulationTaskRequest,
  RetrieveLatestSimulationTaskRequest,
  SimulationTask,
  SimulationTaskData,
  SimulationTaskDataSimulationType as SimulationType,
  ModelInputsServiceStackType as ServiceStackType,
  ModelInputs,
  SimulationTaskDataBacktestWorkflowState,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import {
  Conversation,
  CreateConversationResponse,
  ListConversationsResponse,
} from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { ResourceUpdateChangeType, ResourceUpdateResourceType } from '@cresta/web-client/dist/cresta/v1/studio/resource_update.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { ConversationSyntheticType, UpdateConversationRequest } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { ConversationApi } from 'services/conversationApi';
import { useStudioSubscription } from 'hooks/useStudioSubscription';
import { openNotification } from 'components/Notification';
import { getId } from 'common/resourceName';
import { Form, FormInstance } from 'antd';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { UserContext } from 'context/UserContext';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import useUrlParam from 'hooks/useUrlParam';
import { SimulationTaskApi } from 'services/simulationTaskApi';
import { selectServingModels } from 'store/modelBuilder/selectors';
import { useDraftDialoguePolicy, useDialoguePolicyInBranch } from 'hooks/useDialoguePolicy';
import { mapToStudioConversation, mapToStudioMessage, ProdConversationApi } from 'services/prodConversationApi';
import { ConversationMessage } from '@cresta/web-client';
import { MASTER_BRANCH_NAME } from 'pages/DialogPolicy/EditRoute/PolicyEdit';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import { SimulatorResultDrawer } from './SimulatorResult/SimulatorResultDrawer';
import { SimulatorResult, SimulatorComparedResult } from './SimulatorResult';
import { SimulatorModel } from './SimulatorModel/SimulatorModel';
import { SyntheticConversationModal } from './SyntheticConversationModal';
import styles from './style.module.scss';
import { ModelInputData } from './SimulatorModel/ModelSetUp';

export const Simulator = () => {
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const [taskId, setTaskId] = useUrlParam<string>('taskId');
  const [editingMode, setEditingMode] = useUrlParam<string>('editingMode');
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();
  const assigneeUserId = useMemo(() => currentUser?.id || '', [currentUser?.id]);
  const [simulationTask, setSimulationTask] = useState<SimulationTask>();
  const prevSimulationTask = usePrevious<SimulationTask>(simulationTask);
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchSimulationTask = useCallback(async () => {
    if (loaded) {
      return;
    }
    if (!taskId) {
      if (editingMode) {
        setLoaded(true);
        return;
      }
      if (!assigneeUserId) {
        // Not set loaded to wait for user ID.
        return;
      }
      const request: RetrieveLatestSimulationTaskRequest = {
        profile: customerProfile,
        assigneeUserId,
        usecaseId,
        languageCode,
      };
      let task;
      try {
        const response = await SimulationTaskApi.retrieveLatestSimulationTask(request);
        task = response?.simulationTask;
      } catch (err) {
        if (err['status'] !== 'NOT_FOUND') {
          throw err;
        }
      }
      setLoaded(true);
      if (task) {
        setSimulationTask(task);
        setTaskId(getId('simulationTask', task.name) || '');
      }
    } else {
      let task;
      try {
        const response = await SimulationTaskApi.getSimulationTask({
          name: `${customerProfile}/simulationTasks/${taskId}`,
        });
        task = response?.simulationTask;
      } catch (err) {
        openNotification('error', `Failed to get simulation task ${taskId}`, undefined, err);
      }
      setLoaded(true);
      if (task) {
        setSimulationTask(task);
      } else {
        setTaskId('');
      }
    }
  }, [taskId, assigneeUserId, customerProfile, simulationTask, loaded, editingMode, usecaseId, languageCode]);

  useEffect(() => {
    try {
      fetchSimulationTask();
    } catch (err) {
      openNotification('error', 'Failed to get simulation task', undefined, err);
    }
  }, [taskId, assigneeUserId]);

  useStudioSubscription(useCallback((event) => {
    switch (event.type) {
      case 'reconcile':
        break;
      case 'message':
        switch (event.message.studioResourceUpdate?.resourceType) {
          case ResourceUpdateResourceType.SIMULATION_TASK:
            if (event.message.studioResourceUpdate.changeType !== ResourceUpdateChangeType.UPDATE) {
              return;
            }
            if (simulationTask && simulationTask.name === event.message.studioResourceUpdate?.simulationTask?.name) {
              if (event.message.studioResourceUpdate?.simulationTask?.abstractTask?.status === TaskStatus.TASK_ERROR) {
                openNotification(
                  'error',
                  'Simulation Failed!',
                  event.message.studioResourceUpdate?.simulationTask?.abstractTask?.errMessage,
                );
              }
              setSimulationTask(event.message.studioResourceUpdate?.simulationTask);
            }
            break;
          default:
        }
        break;
      default:
    }
  }, [simulationTask]));

  const [previewMessages, setPreviewMessages] = useState<Message[]>([]);

  // Conversations in editing.
  const [conversations, setConversations] = useState<Conversation[] | null>([]);
  const activeSyntheticConversations = useMemo(
    () => conversations?.filter((conv) => conv.metadata?.syntheticType === ConversationSyntheticType.USER_DEFINED_CONVERSATION),
    [conversations],
  );
  const conversationNames = useMemo(
    () => new Set(conversations?.map((conv) => conv.name)),
    [conversations],
  );

  // All user defined conversations.
  const [userConversations, setUserConversations] = useState<Conversation[] | null>([]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [syntheticModalVisible, setSyntheticModalVisible] = useState<boolean>(false);

  const [activeChatCardId, setActiveChatCard] = useState<string>('');

  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);
  const [firstForm] = Form.useForm<ModelInputData>();
  const [secondForm] = Form.useForm<ModelInputData>();

  const dialoguePolicy = useDraftDialoguePolicy();
  const { snapshotId: draftSnapshotId, save, load } = dialoguePolicy;
  const [masterSnapshot] = useDialoguePolicyInBranch(MASTER_BRANCH_NAME);

  const getModelInputFormData = useCallback((inputs: ModelInputs): ModelInputData => ({
    agentURL: inputs?.agentModelUri,
    visitorURL: inputs?.visitorModelUri,
    chatDriverURL: inputs?.chatDriverModelUri,
    policyURL: inputs?.rulePolicyUri,
    hintModelUriToConfig: inputs?.hintModelUriToConfig,
    intentImage: inputs?.orchestratorStack?.intentImageTag,
    orchestratorImage: inputs?.orchestratorStack?.orchestratorImageTag || inputs?.aiAgentStack?.orchestratorImageTag,
    actionImage: inputs?.aiAgentStack?.actionsImageTag,
    policyImage: inputs?.orchestratorStack?.policyImageTag,
    hintTimingImage: inputs?.orchestratorStack?.hintTimingImageTag,
    useActionStack: inputs?.serviceStackType === ServiceStackType.AI_AGENT_STACK,
    usePolicySnapshot: inputs?.rulePolicySnapshotId?.length > 0 ? 'draft' : '',
  }), []);

  const getModelInput = useCallback((f: FormInstance<ModelInputData>): ModelInputs => {
    const modelInputData = f.getFieldsValue();
    const dpSnapshot = editingMode || modelInputData?.usePolicySnapshot;
    let snapshotId = '';
    if (dpSnapshot === 'master') {
      snapshotId = getId('dialoguePolicySnapshot', masterSnapshot?.name);
    } else if (dpSnapshot === 'draft') {
      snapshotId = draftSnapshotId;
    }
    const base: ModelInputs = {
      agentModelUri: modelInputData.agentURL?.trim(),
      visitorModelUri: modelInputData.visitorURL?.trim(),
      chatDriverModelUri: modelInputData.chatDriverURL?.trim(),
      rulePolicyUri: dpSnapshot ? '' : modelInputData.policyURL?.trim(),
      rulePolicySnapshotId: snapshotId,
      hintModelUriToConfig: modelInputData.hintModelUriToConfig,
    };
    if (modelInputData.useActionStack) {
      return {
        ...base,
        serviceStackType: ServiceStackType.AI_AGENT_STACK,
        aiAgentStack: {
          orchestratorImageTag: modelInputData.orchestratorImage?.trim(),
          actionsImageTag: modelInputData.actionImage?.trim(),
          ensembleModelUri: modelInputData.ensembleURL?.trim(),
        },
      };
    }
    return {
      ...base,
      serviceStackType: ServiceStackType.ORCHESTRATOR_STACK,
      orchestratorStack: {
        orchestratorImageTag: modelInputData.orchestratorImage?.trim(),
        intentImageTag: modelInputData.intentImage?.trim(),
        policyImageTag: modelInputData.policyImage?.trim(),
        hintTimingImageTag: modelInputData.hintTimingImage?.trim(),
      },
    };
  }, [draftSnapshotId, editingMode, masterSnapshot]);

  useEffect(() => {
    if (simulationTask && simulationTask.name !== prevSimulationTask?.name) {
      const { taskData: { targetModelInputs, baseModelInputs, simulationType } } = simulationTask;
      switch (simulationType) {
        case SimulationType.REGRESSION_TEST:
        case SimulationType.COMPARE_MODELS:
          firstForm.setFieldsValue(getModelInputFormData(baseModelInputs));
          secondForm.setFieldsValue(getModelInputFormData(targetModelInputs));
          break;
        case SimulationType.SINGLE_MODEL:
        case SimulationType.CONTINUOUS_REGRESSION_TEST:
          firstForm.setFieldsValue(getModelInputFormData(targetModelInputs));
          break;
        default: {
          console.error('Invalid Simulation Type: ', simulationType);
        }
      }
      setConversations(simulationTask.taskData?.conversations);
      if (simulationTask?.taskData?.simulationType === SimulationType.SINGLE_MODEL
        && simulationTask?.taskData?.targetModelInputs?.rulePolicySnapshotId) {
        setEditingMode('draft');
        load(simulationTask?.taskData?.targetModelInputs?.rulePolicySnapshotId);
      }
    }
    if (!simulationTask || !simulationTask?.taskData?.targetModelInputs?.rulePolicySnapshotId) {
      load();
    }
  }, [simulationTask]);

  useEffect(() => {
    if (activeChatCardId) {
      setMessagesLoading(true);
      ProdConversationApi.listAllMessages(`${customerProfile}/conversations/${activeChatCardId}`).then((messages: ConversationMessage[]) => {
        setPreviewMessages(messages.map(mapToStudioMessage));
      }).catch((error) => {
        openNotification('error', 'Error Fetching Messages', undefined, error);
      }).finally(() => setMessagesLoading(false));
    }
  }, [customerProfile, activeChatCardId]);

  const getTaskData = useCallback((simulationType: SimulationType): SimulationTaskData => {
    const firstInput = getModelInput(firstForm);
    const secondInput = getModelInput(secondForm);
    switch (simulationType) {
      case SimulationType.REGRESSION_TEST:
        return {
          simulationType: SimulationType.REGRESSION_TEST,
          targetModelInputs: secondInput,
        };
      case SimulationType.COMPARE_MODELS:
        return {
          baseModelInputs: firstInput,
          simulationType: SimulationType.COMPARE_MODELS,
          targetModelInputs: secondInput,
        };
      case SimulationType.SINGLE_MODEL:
        return {
          simulationType: SimulationType.SINGLE_MODEL,
          targetModelInputs: firstInput,
        };
      default: {
        console.error('Invalid Simulation Type: ', simulationType);
        return {};
      }
    }
  }, [firstForm, secondForm, getModelInput]);

  const createTask = useCallback((req: CreateSimulationTaskRequest) => {
    SimulationTaskApi.createSimulationTask(req).then((response) => {
      setSimulationTask(response.simulationTask);
      setTaskId(getId('simulationTask', response.simulationTask?.name));
    }).catch((err) => openNotification('error', 'Failed to Create Simulation Task', undefined, err));
  }, [setSimulationTask, setTaskId]);

  const handleCreateTask = useCallback((simulationType: SimulationType) => {
    const simulationSpecificTaskData = getTaskData(simulationType);
    const request: CreateSimulationTaskRequest = {
      parent: customerProfile,
      simulationTaskId: uuid(),
      commonInput: {
        title: `Studio simulator ${moment().format(ACCEPTED_DATE_FORMAT)}`,
        assigneeUserId,
        usecase,
        languageCode,
      },
      taskData: {
        ...simulationSpecificTaskData,
        conversations,
      },
    };
    if (!!editingMode && !request.taskData?.targetModelInputs?.rulePolicySnapshotId) {
      openNotification('error', 'No draft dialogue policy', 'Please create dialogue policy draft and config first.', undefined);
      return;
    }
    createTask(request);
  }, [getTaskData, customerProfile, assigneeUserId, conversations, editingMode, createTask, usecase, languageCode]);

  const handleUpdateAndRun = useCallback(async () => {
    if (!editingMode) {
      return;
    }
    const id = await save();
    if (id && simulationTask) {
      const request: CreateSimulationTaskRequest = {
        parent: customerProfile,
        simulationTaskId: uuid(),
        commonInput: {
          title: `Studio simulator ${moment().format(ACCEPTED_DATE_FORMAT)}`,
          assigneeUserId,
          usecase,
          languageCode,
        },
        taskData: {
          ...simulationTask.taskData,
        },
      };
      request.taskData.targetModelInputs.rulePolicySnapshotId = id;
      createTask(request);
    }
  }, [editingMode, save, simulationTask, customerProfile, assigneeUserId, createTask, usecase, languageCode]);

  const handleDeleteCard = useCallback((conversationId: string) => {
    const name = `${customerProfile}/conversations/${conversationId}`;
    const newConversations = conversations.filter((conversation) => conversation.name !== name);
    setConversations(newConversations);
  }, [setConversations, customerProfile, conversations]);

  const handleAddConversation = useCallback(() => {
    setSyntheticModalVisible(true);
    getUserDefinedConversation();
  }, [setSyntheticModalVisible]);

  const handleImportConversations = useCallback((options?: { goldenOnly?: boolean, setIds?: string[] }) => {
    const baseFilter = {
      usecase,
      languageCode,
    };

    const filter = {
      ...baseFilter,
      setIds: options?.setIds?.length > 0 ? options.setIds : ['golden_set'],
    };
    // Assuming golden conversations are less than 1000 and can be retrieved in one page.
    ConversationApi.listConversations({
      parent: customerProfile,
      filter,
    })
      .then((res) => {
        const { conversations: newConversations } = res;
        // add golden conversation
        const newConversationSet = [...newConversations, ...conversations];
        // remove duplicates
        const uniqueConversations = newConversationSet.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i);
        if (options?.goldenOnly) {
          setConversations(newConversations);
        } else {
          setConversations(uniqueConversations);
        }
      }).catch((reason) => {
        openNotification('error', 'Error Importing Golden Conversations', undefined, reason);
      });
  }, [setConversations, conversations, customerProfile, usecase, languageCode]);

  const handleGetConversation = useCallback(async (conversationId: string) => {
    const name = `${customerProfile}/conversations/${conversationId}`;
    if (conversationNames.has(name)) {
      setActiveChatCard(conversationId);
      return;
    }
    try {
      const resp = await ProdConversationApi.getConversation({ name });
      setConversations([mapToStudioConversation(resp.conversation), ...conversations]);
      setActiveChatCard(conversationId);
    } catch (err) {
      openNotification('error', 'Failed to Get Conversation', undefined, err);
    }
  }, [conversations, conversationNames, setConversations, setActiveChatCard]);

  const handleClickCard = (conversationId: string) => {
    setActiveChatCard(conversationId);
  };

  const handleCreateConversation = useCallback(() => {
    const setId = uuid();
    ConversationApi.createConversation(
      {
        parent: customerProfile,
        conversationId: setId,
        usecase,
        languageCode,
        conversation: {
          openTime: moment().format(ACCEPTED_DATE_FORMAT),
          metadata: {
            syntheticType: ConversationSyntheticType.USER_DEFINED_CONVERSATION,
          },
        },
      },
    ).then((response: CreateConversationResponse) => {
      setConversations([response.conversation, ...conversations]);
      setUserConversations([response.conversation, ...userConversations]);
      setActiveChatCard(getId('conversation', response.conversation.name));
    }).catch((error) => {
      openNotification('error', 'Failed to Create Conversation', undefined, error);
    });
  }, [customerProfile, userConversations, setUserConversations, conversations, setConversations, setActiveChatCard]);

  const handleUpdateConversationDisplayName = useCallback((conversationId: string, displayName: string) => {
    const request: UpdateConversationRequest = {
      conversation: {
        name: `${customerProfile}/conversations/${conversationId}`,
        openTime: '1970-01-01T00:00:00Z', // Value doesn't matter but cannot be null because of validation.
        metadata: {
          displayName,
        },
      },
      updateMask: 'metadata.display_name',
    };
    ConversationApi.updateConversation(request).then(() => {
      const index = conversations.findIndex((conv) => conv.name === request.conversation.name);
      if (index >= 0) {
        const newConversations = [...conversations];
        newConversations[index].metadata.displayName = displayName;
        setConversations(newConversations);
      }
      const i = userConversations.findIndex((conv) => conv.name === request.conversation.name);
      if (i >= 0) {
        const newUserConversations = [...userConversations];
        newUserConversations[i].metadata.displayName = displayName;
        setUserConversations(newUserConversations);
      }
    });
  }, [customerProfile, conversations, setConversations, setUserConversations, userConversations]);

  const handleClickSyntheticConversation = useCallback((conversation: Conversation) => {
    const index = conversations.findIndex((conv) => conv.name === conversation.name);
    if (index < 0) {
      setConversations([conversation, ...conversations]);
    } else {
      setConversations(conversations.filter((conv) => conv.name !== conversation.name));
    }
  }, [conversations, setConversations]);

  const getUserDefinedConversation = () => {
    setUserConversations(null);
    ConversationApi.listConversations(
      {
        parent:
          customerProfile,
        filter: {
          syntheticType: ConversationSyntheticType.USER_DEFINED_CONVERSATION,
          usecase,
          languageCode,
        },
      },
    ).then((response: ListConversationsResponse) => setUserConversations(response.conversations)).catch((reason) => {
    });
  };

  const handleAddMessage = useCallback((message: Message) => {
    const msgId = uuid();
    return MessageApi.createMessage({
      parent: `${customerProfile}/conversations/${activeChatCardId}`,
      messageId: msgId,
      message,
    });
  }, [activeChatCardId, customerProfile]);

  const handleBack = useCallback(() => {
    setSimulationTask(null);
    setTaskId('');
  }, [setSimulationTask, setTaskId]);

  const handleTabChange = (activeKey) => {
    navigate(`/${path}/simulator/${activeKey}`);
  };

  // Handler to populate prod model URIs.
  const prodModels = useSelector(selectServingModels);
  const handleUseProd = useCallback((firstModel?: boolean) => {
    const form: FormInstance<ModelInputData> = firstModel ? firstForm : secondForm;
    const modelInputData: ModelInputData = { agentURL: '', visitorURL: '', chatDriverURL: '', policyURL: '', ensembleURL: '' };
    for (const model of prodModels) {
      switch (model.type) {
        case 'agent':
          modelInputData.agentURL = model.url;
          break;
        case 'visitor':
          modelInputData.visitorURL = model.url;
          break;
        case 'driver':
          modelInputData.chatDriverURL = model.url;
          break;
        case 'policy':
          if (!editingMode && !form.getFieldsValue().usePolicySnapshot) {
            modelInputData.policyURL = model.url;
          }
          break;
        case 'ensemble':
          modelInputData.ensembleURL = model.url;
          if (model.url?.length > 0) {
            modelInputData.useActionStack = true;
          }
          break;
        default:
      }
    }
    form.setFieldsValue(modelInputData);
  }, [firstForm, secondForm, prodModels, editingMode]);

  const showComparedResult = [
    SimulationType.REGRESSION_TEST,
    SimulationType.COMPARE_MODELS,
    SimulationType.CONTINUOUS_REGRESSION_TEST,
  ].includes(simulationTask?.taskData?.simulationType);
  const simulatorResult = showComparedResult
    ? (
      <SimulatorComparedResult
        simulationTask={simulationTask}
        conversations={conversations}
        handleBack={handleBack}
        handleTabChange={handleTabChange}
        handleOpenDrawer={() => setDrawerVisible(true)}
      />
    ) : (
      <SimulatorResult
        simulationTask={simulationTask}
        handleBack={handleBack}
        handleTabChange={handleTabChange}
        dialoguePolicy={dialoguePolicy}
        handleUpdateAndRun={handleUpdateAndRun}
      />
    );

  const runningState = useCallback((state: SimulationTaskDataBacktestWorkflowState) => {
    switch (state) {
      case SimulationTaskDataBacktestWorkflowState.BACKTEST_WAITING_ON_ENV_SETUP:
        return 'setting up backtest environment';
      default:
        return 'running conversation prediction';
    }
  }, []);
  const runningStateDetails = useMemo(() => {
    const taskData = simulationTask?.taskData;
    if (taskData?.simulationType === SimulationType.SINGLE_MODEL) {
      return <Text>{runningState(taskData?.targetBacktestState)}</Text>;
    }
    return <Text>{`Model 1: ${runningState(taskData?.baseBacktestState)}, Model 2: ${runningState(taskData?.targetBacktestState)}`}</Text>;
  }, [simulationTask]);

  return (
    <div className={styles.simulator}>
      {simulationTask?.abstractTask?.status === TaskStatus.TASK_COMPLETED
        || (simulationTask?.abstractTask?.status === TaskStatus.TASK_IN_PROGRESS && editingMode === 'draft')
        ? simulatorResult
        : (
          <SimulatorModel
            previewMessages={previewMessages}
            activeChatCardId={activeChatCardId}
            conversations={conversations}
            messagesLoading={messagesLoading}
            handleTabChange={handleTabChange}
            handleDeleteCard={handleDeleteCard}
            handleClickCard={handleClickCard}
            buttonLoading={simulationTask?.abstractTask?.status === TaskStatus.TASK_IN_PROGRESS}
            runningStateDetail={simulationTask?.abstractTask?.status === TaskStatus.TASK_IN_PROGRESS ? runningStateDetails : undefined}
            firstForm={firstForm}
            secondForm={secondForm}
            handleImportConversations={handleImportConversations}
            handleGetConversation={handleGetConversation}
            handleAddConversation={handleAddConversation}
            handleUpdateConversationDisplayName={handleUpdateConversationDisplayName}
            handleAddMessage={handleAddMessage}
            handleUseProd={handleUseProd}
            handleRunSimulator={handleCreateTask}
            disableAll={simulationTask?.abstractTask?.status === TaskStatus.TASK_IN_PROGRESS}
          />
        )}
      <SyntheticConversationModal
        opened={syntheticModalVisible}
        syntheticConversations={userConversations}
        onClose={() => setSyntheticModalVisible(false)}
        handleCreateConversation={handleCreateConversation}
        handleClickSyntheticConversation={handleClickSyntheticConversation}
        activeSyntheticConversations={activeSyntheticConversations}
      />
      <SimulatorResultDrawer
        displayOnly
        firstForm={firstForm}
        secondForm={secondForm}
        handleUseProd={handleUseProd}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      />
    </div>
  );
};
