import React from 'react';
import { Checkbox, Collapse, Group, MultiSelect, NumberInput, Radio, Stack } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { SuggestionsDatasetArtifactInputsShardInterval } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { SmartComposeConfigFormValues } from './useSmartComposeConfigForm';
import { ConfigPanelContainer } from '../../components/ConfigPanelContainer';
import { QAConfigFormValues, QATaskSetup } from '../../components/QATaskSetup';
import { useProdAgents } from '../../useProdAgents';
import { ShowAdvanced } from '../../components/ShowAdvanced';

interface Props {
  smartComposeForm: UseFormReturnType<SmartComposeConfigFormValues>;
  qaConfigForm: UseFormReturnType<QAConfigFormValues>;
}

export const SmartComposeConfigForm = (props: Props) => {
  const { smartComposeForm, qaConfigForm } = props;
  const agents = useProdAgents();

  return (
    <ConfigPanelContainer>
      <DatePickerInput
        type="range"
        label="Conversations from this time range"
        placeholder="Choose Dates"
        {...smartComposeForm.getInputProps('conversationDates')}
      />
      <Checkbox
        label="Add Experts"
        checked={smartComposeForm.values.addExperts}
        onChange={(e) => smartComposeForm.setFieldValue('addExperts', e.currentTarget.checked)}
      />
      <Collapse in={smartComposeForm.values.addExperts}>
        <MultiSelect
          label="Agents"
          placeholder="Select agents"
          description="Agent user ids to use instead of experts from config"
          data={agents}
          clearable
          {...smartComposeForm.getInputProps('expertAgentUserIds')}
        />
      </Collapse>
      <ShowAdvanced>
        <Stack>
          <NumberInput
            label="Test Set Days Suffix"
            description="Suffix in days of time range to use as test set."
            min={0}
            {...smartComposeForm.getInputProps('testSetDaysSuffix')}
          />
          <Radio.Group
            label="Shard Interval"
            description="Interval to shard data by when splitting into train/eval/test set"
            {...smartComposeForm.getInputProps('shardInterval')}
          >
            <Group>
              <Radio label="None" value={SuggestionsDatasetArtifactInputsShardInterval.SHARD_INTERVAL_UNSPECIFIED} />
              <Radio label="Day" value={SuggestionsDatasetArtifactInputsShardInterval.DAY} />
              <Radio label="Week" value={SuggestionsDatasetArtifactInputsShardInterval.WEEK} />
              <Radio label="Month" value={SuggestionsDatasetArtifactInputsShardInterval.MONTH} />
            </Group>
          </Radio.Group>
          <NumberInput
            label="Split Ratio Train"
            min={0}
            max={100}
            step={1}
            description="Ratio of train / (train + validation) set split"
            placeholder='e.g. "80%"'
            formatter={(value) => {
              const int = parseInt(value, 10);
              return (!Number.isNaN(int) ? `${int}%` : '');
            }}
            {...smartComposeForm.getInputProps('splitRatioTrain')}
          />
          <Checkbox label="Load Preprocessing Config" {...smartComposeForm.getInputProps('loadPreprocessingConfig', { type: 'checkbox' })} />
          <Checkbox label="Load Postprocessing Config" {...smartComposeForm.getInputProps('loadPostprocessingConfig', { type: 'checkbox' })} />
          <Checkbox label="Materialize Configs" {...smartComposeForm.getInputProps('materializeConfigs', { type: 'checkbox' })} />
        </Stack>
      </ShowAdvanced>
      <QATaskSetup configForm={qaConfigForm} />
    </ConfigPanelContainer>
  );
};
