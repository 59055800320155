/* eslint dot-notation: 0 */
import { useState, useCallback, useEffect, useContext } from 'react';
import { useInterval } from '@mantine/hooks';

import { modelArtifactApi } from 'services/modelArtifactApi';
import { openNotification } from 'components/Notification';
import {
  ModelArtifactStatus,
  ModelArtifact,
  RetrieveModelScoresRequest,
  RetrieveModelScoresResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { ApiStatus } from 'store/types';
import { UserContext } from 'context/UserContext';
import { useCustomerProfile } from './useCustomerParams';
import { useApiPost } from './network';

// Get evaluation artifact given model URI and dataset URI.
export function useEvaluation(projectId: number, modelUri: string, testDatasetUri: string): [ModelArtifact, ApiStatus] {
  const apiPost = useApiPost();
  const [artifact, setArtifact] = useState<ModelArtifact>(null);
  const [status, setStatus] = useState<ApiStatus>('idle');
  const profile = useCustomerProfile();
  const currentUser = useContext(UserContext);

  const getOrCreateEvaluationArtifact = useCallback(async (model: string, dataset: string) => {
    const request: RetrieveModelScoresRequest = {
      modelProject: `${profile}/modelProjects/-`,
      modelUris: [model],
    };
    let response: RetrieveModelScoresResponse = {};
    try {
      response = await modelArtifactApi.retrieveModelScores(request);
    } catch (err) {
      if (err['status'] !== 'NOT_FOUND') {
        throw err;
      }
    }
    const existingArtifact = response.modelArtifacts?.find(
      (a) => a.evaluationArtifact?.inputs?.testDatasets?.length === 1 && a.evaluationArtifact?.inputs?.testDatasets[0].uri === dataset,
    );
    if (existingArtifact && [ModelArtifactStatus.ACTIVE, ModelArtifactStatus.ACCEPTED].includes(existingArtifact.status)) {
      const resp = await modelArtifactApi.getModelArtifact({ name: existingArtifact.name });
      setArtifact(resp.modelArtifact);
      return;
    }
    const body = {
      model_url: model,
      test_dataset_urls: [dataset],
      created_by: currentUser?.email,
      project_id: projectId,
    };
    const creationResp = await apiPost('intents/evaluation', body);
    const getResp = await modelArtifactApi.getModelArtifact({ name: `${profile}/modelProjects/${projectId}/modelArtifacts/${creationResp.result_id}` });
    setArtifact(getResp.modelArtifact);
  }, [profile, projectId, setArtifact]);

  const { start, stop } = useInterval(async () => {
    if (!artifact?.name) {
      return;
    }
    const resp = await modelArtifactApi.getModelArtifact({ name: artifact.name });
    if (resp && [ModelArtifactStatus.ACTIVE, ModelArtifactStatus.FAILED].includes(resp.modelArtifact?.status)) {
      setArtifact(resp.modelArtifact);
    }
  }, 10000);

  useEffect(() => {
    if (projectId && modelUri && testDatasetUri) {
      setArtifact(null);
      setStatus('loading');
      try {
        getOrCreateEvaluationArtifact(modelUri, testDatasetUri);
      } catch (err) {
        openNotification('error', 'Failed to get evaluation result', '', err);
        setStatus('failed');
      }
    } else {
      setStatus('idle');
      setArtifact(null);
    }
  }, [projectId, modelUri, testDatasetUri]);

  useEffect(() => {
    if (artifact) {
      if (artifact.status === ModelArtifactStatus.ACCEPTED) {
        start();
      } else {
        stop();
        if (artifact.status === ModelArtifactStatus.FAILED) {
          openNotification('error', 'Evaluation failed!');
          setStatus('failed');
        } else if (artifact.status === ModelArtifactStatus.ACTIVE) {
          setStatus('succeeded');
        }
      }
    }
    return stop;
  }, [artifact]);

  return [artifact, status];
}
