import { WarningOutlined } from '@ant-design/icons';
import { Annotation, AnnotationMutability, AnnotationRawDataType, AnnotationState, AnnotationValuePresenceType, AnnotationValueType, BinaryValueValue, DataSplit, MessageRawDataContextShown, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept, ConceptConceptType, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Modal, Button, Alert, Text, Group, useMantineTheme } from '@mantine/core';
import { Radio, Select, Table, Tabs, TabsProps, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ColumnType } from 'antd/lib/table';
import { getId } from 'common/resourceName';
import InputWithLabelValue, { InputWithLabelValueData } from 'components/InputWithLabelValue';
import { openNotification } from 'components/Notification';
import { useSelector } from 'hooks/reduxHooks';
import { cloneDeep } from 'lodash';
import { getConceptTypeFromTask, getMomentAnnotationType } from 'pages/LabelingView/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnnotationApi } from 'services/annotationApi';
import { updateAnnotationFilters } from 'store/annotation/annotationSlice';
import { searchAnnotations } from 'store/annotation/asyncThunks';
import { updateSyntheticLabelsModal } from 'store/app/appSlice';
import { selectSyntheticLabelsModal } from 'store/app/selectors';
import { selectAllConcepts } from 'store/concept/selectors';
import { LabelValue } from 'types';
import { v4 as uuid } from 'uuid';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { convertRowKeysToLowercase } from 'utils/csvImport';
import { SelectCSVButton } from 'components/SelectCSVButton';
import styles from './styles.module.scss';

interface SyntheticLabelsModalProps {
  opened: boolean;
  onClose: () => void;
}

const DEFAULT_NEW_ANNOTATIONS = [{
  type: LabelValue.POSITIVE,
  hidden: false,
  value: '',
}];

interface ImportedAnnotation {
  concept: Concept;
  conceptTitle?: string;
  utterance: string;
  value: BinaryValueValue;
  split: DataSplit;
}

export default function SyntheticLabelsModal({ opened, onClose }: SyntheticLabelsModalProps) {
  const dispatch = useDispatch();
  const concepts = useSelector<Concept[]>(selectAllConcepts);
  const customerProfile = useCustomerProfile();
  const syntheticLabelsModal = useSelector(selectSyntheticLabelsModal);
  const [submitting, setSubmitting] = useState(false);
  const [newAnnotationsList, setNewAnnotationsList] = useState<InputWithLabelValueData[]>(cloneDeep(DEFAULT_NEW_ANNOTATIONS));
  const [importedAnnotationsList, setImportedAnnotationsList] = useState<ImportedAnnotation[]>([]);
  // Active tab can be manual or import
  const [activeTab, setActiveTab] = useState<'manual'|'import'>('manual');
  const theme = useMantineTheme();
  const customer = useCustomerParams();

  // Filter out stages.
  const intentConcepts = useMemo(() => concepts.filter((concept) =>
    concept.conceptType === ConceptConceptType.INTENT && concept?.intent?.intentType !== IntentIntentType.STAGE), [concepts]);

  const intentsTitleConceptMap = useMemo(() => new Map<string, Concept>(intentConcepts.map((concept) => [concept.conceptTitle, concept])), [intentConcepts]);

  const createRawAnnotationTuple = useCallback((message: string, value: BinaryValueValue, concept: Concept, split: DataSplit) => ({
    name: `${customerProfile}/annotations/${uuid()}`,
    usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
    languageCode: customer.languageCode,
    mutability: AnnotationMutability.IMMUTABLE,
    split,
    state: AnnotationState.ACTIVE_MANUALLY_ADDED,
    targetType: TargetType.LABELING,
    momentAnnotationType: getMomentAnnotationType(getConceptTypeFromTask(concept?.conceptType)),
    rawDataType: AnnotationRawDataType.TYPE_ON_MESSAGE,
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_MESSAGE,
      messageRawData: {
        synthetic: true,
        syntheticMessage: message,
        contextShown: MessageRawDataContextShown.NO_CONTEXT_SHOWN,
      },
    },
    value: {
      type: AnnotationValueType.TYPE_BINARY,
      presenceType: AnnotationValuePresenceType.PRESENCE,
      binaryValue: {
        value,
        conceptId: getId('concept', concept.name),
      },
    },
    valueType: AnnotationValueType.TYPE_BINARY,
  }), [getId, getMomentAnnotationType, getConceptTypeFromTask, customer?.path]);

  // Add new annotations
  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const concept = concepts.find((concept) => getId('concept', concept.name) === syntheticLabelsModal.conceptId);

    let annotations: Annotation[] = [];

    if (activeTab === 'manual') {
      annotations = newAnnotationsList.map((annotationData) => createRawAnnotationTuple(
        annotationData.value,
        annotationData.type === LabelValue.POSITIVE ? BinaryValueValue.VALUE_POSITIVE : BinaryValueValue.VALUE_NEGATIVE,
        concept,
        syntheticLabelsModal.split,
      ));
    }

    if (activeTab === 'import') {
      annotations = importedAnnotationsList.map((annotationData) => createRawAnnotationTuple(
        annotationData.utterance,
        annotationData.value,
        annotationData.concept,
        annotationData.split,
      ));
    }

    try {
      await AnnotationApi.batchUpsertAnnotations({
        parent: customerProfile,
        annotations,
      });
      // Reset fields in modal and annotations array in state
      dispatch(updateAnnotationFilters({}));
      dispatch(searchAnnotations({
        parent: customerProfile,
        usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
        languageCode: customer.languageCode,
      }));
      onClose();
      resetModal();
    } catch (err) {
      openNotification('error', 'Failed to create synthetic labels', undefined, err);
    }

    setSubmitting(false);
  }, [concepts, syntheticLabelsModal, newAnnotationsList, importedAnnotationsList, customer?.path]);

  const resetModal = useCallback(() => {
    setNewAnnotationsList(cloneDeep(DEFAULT_NEW_ANNOTATIONS));
    setImportedAnnotationsList([]);
    setActiveTab('manual');
  }, []);

  const changeAnnotationHelper = (value: InputWithLabelValueData, index: number) => {
    const copiedRegexList = cloneDeep(newAnnotationsList);
    copiedRegexList[index] = value;
    setNewAnnotationsList(copiedRegexList);
  };

  const deleteAnnotationHelper = (index: number) => {
    const copiedRegexList = cloneDeep(newAnnotationsList);
    copiedRegexList.splice(index, 1);
    setNewAnnotationsList(copiedRegexList);
  };

  const addAnnotationHelper = () => {
    setNewAnnotationsList([
      ...newAnnotationsList,
      {
        value: '',
        type: LabelValue.POSITIVE,
        hidden: false,
      },
    ]);
  };

  const canSubmit = useMemo(() => {
    if (activeTab === 'manual') {
      return newAnnotationsList.length > 0;
    } else if (activeTab === 'import') {
      return importedAnnotationsList.length > 0 && importedAnnotationsList.every((annotation) => annotation.concept);
    }
    return false;
  }, [newAnnotationsList, importedAnnotationsList, activeTab]);

  const missingIntents = useMemo(() => {
    const missingIntentsMap = new Map<string, number>();
    importedAnnotationsList.forEach((annotation) => {
      if (!annotation.concept) {
        const count = missingIntentsMap.get(annotation.conceptTitle) || 0;
        missingIntentsMap.set(annotation.conceptTitle, count + 1);
      }
    });
    return Array.from(missingIntentsMap.entries());
  }, [importedAnnotationsList]);

  const columns: ColumnType<ImportedAnnotation>[] = [
    {
      title: 'Intent',
      dataIndex: 'concept',
      key: 'concept',
      render: (value, row) => {
        if (!row.concept) {
          return (
            <Tooltip title="Intent not found" color="red">
              <WarningOutlined className={styles.intentNotFound}/>
            </Tooltip>
          );
        }
        return row.concept?.conceptTitle;
      },
    },
    {
      title: 'Utterance',
      dataIndex: 'utterance',
      key: 'utterance',
      width: '350px',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (value, row) => (
        <Button
          compact
          variant="light"
          color={value === BinaryValueValue.VALUE_POSITIVE ? 'green' : 'red'}
        >
          {value === BinaryValueValue.VALUE_POSITIVE ? 'Positive' : 'Negative'}
        </Button>
      ),
    },
    {
      title: 'Split',
      dataIndex: 'split',
      key: 'split',
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: 'manual',
      label: 'Manual',
      children: (
        <div>
          <FormItem>
            <label>Intent</label>
            <br/>
            <Select
              showArrow
              className={styles.intentSelect}
              size="large"
              value={syntheticLabelsModal.conceptId}
              onChange={(conceptId) => {
                dispatch(updateSyntheticLabelsModal({
                  ...syntheticLabelsModal,
                  conceptId,
                }));
              }}
              showSearch
              placeholder="Select intent"
              allowClear
            >
              {intentConcepts.map((option) => (
                <Select.Option key={option.conceptTitle} value={getId('concept', option.name)}>
                  {option.conceptTitle}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem>
            <label>Data set</label>
            <br/>
            <Radio.Group
              value={syntheticLabelsModal.split}
              onChange={(e) => {
                dispatch(updateSyntheticLabelsModal({
                  ...syntheticLabelsModal,
                  split: e.target.value,
                }));
              }}
            >
              <Radio value={DataSplit.TEST}>TEST</Radio>
              <Radio value={DataSplit.TRAIN}>TRAIN</Radio>
            </Radio.Group>
          </FormItem>
          <div className={styles.inputsContainer}>
            {newAnnotationsList.map((annotation, i) => (
            // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                <InputWithLabelValue
                  number={i + 1}
                  valueData={annotation}
                  showToggle={false}
                  onChange={(value) => changeAnnotationHelper(value, i)}
                  onDeleteHandler={() => deleteAnnotationHelper(i)}
                  canDelete={newAnnotationsList.length > 1}
                  placeholder="Message"
                />
              </div>
            ))}
          </div>
          <div>
            <Button color="blue" variant="light" onClick={addAnnotationHelper} size="sm" compact>+ Add</Button>
          </div>
        </div>
      ),
    },
    {
      key: 'import',
      label: 'Import (CSV)',
      children: (
        <div>
          <div>
            <SelectCSVButton
              expectedHeaders={['intent', 'phrase', 'true/false', 'split']}
              handleRows={((rows) => {
                setImportedAnnotationsList(rows.map((row) => {
                  // makes column headers imported from csv case insensitive
                  const lowerCaseRow = convertRowKeysToLowercase(row);

                  const annotation: ImportedAnnotation = {
                    concept: intentsTitleConceptMap.get(lowerCaseRow.intent),
                    conceptTitle: lowerCaseRow.intent,
                    utterance: lowerCaseRow.phrase,
                    value: String(lowerCaseRow['true/false']).toLowerCase() === 'true' ? BinaryValueValue.VALUE_POSITIVE : BinaryValueValue.VALUE_NEGATIVE,
                    split: String(lowerCaseRow.split).toLowerCase() === 'test' ? DataSplit.TEST : DataSplit.TRAIN,
                  };

                  return annotation;
                }));
              })}
            />
          </div>
          <div>
            <Table columns={columns} dataSource={importedAnnotationsList} />
          </div>
        </div>
      ),
    },
  ];
  return (
    <Modal
      title="Add utterances"
      opened={opened}
      onClose={() => {
        resetModal();
        onClose();
      }}
      size="800px"
    >
      <Tabs
        activeKey={activeTab}
        style={{ width: '100%', overflow: 'inherit' }}
        onChange={(value) => setActiveTab(value as 'manual' | 'import')}
        size="large"
        items={items}
      />
      <Alert color="yellow">
        <Group spacing="xs">
          <WarningOutlined style={{ color: theme.colors.orange[4] }} />
          <Text>By continuing, you agree that you have confirmed this data with the CD or MLE on the project.</Text>
        </Group>
      </Alert>
      {missingIntents?.length > 0 && (
        <Alert color="red" mt="md">
          <Text weight={500}>Some intents from CSV could not be found</Text>
          {missingIntents.map(([intentName, count]) => <Text>{intentName} ({count})</Text>)}
        </Alert>
      )}
      <div className="action-button-container">
        <Button
          disabled={!canSubmit}
          onClick={handleSubmit}
          loading={submitting}
        >
          Confirm and add
        </Button>
        <Button
          variant="subtle"
          onClick={() => {
            resetModal();
            onClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
