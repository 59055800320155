import { notification } from 'antd';
import React from 'react';
import CopyableValue from 'components/CopyableValue';
import { SerializedError } from '@reduxjs/toolkit';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface RpcError {
  code: number;
  message: string;
  details: RpcError[];
}

function isRpcError(e: SerializedError|RpcError): e is RpcError {
  return ('message' in e) && ('code' in e) && ('details' in e);
}

function rpcErrorMessage(e: RpcError, prefix?: string): string {
  const pre = prefix ? `${prefix}: ${e.message}` : e.message;
  if (e.details.length === 0) {
    return pre;
  }
  return rpcErrorMessage(e.details[0], pre);
}

function isSerializedError(e: SerializedError|RpcError): e is SerializedError {
  return ('message' in e) && ('code' in e) && !('details' in e);
}

function serializedErrorMessage(e: SerializedError, prefix?: string): string {
  return prefix ? `${prefix}: ${e.message}` : e.message;
}

export const openNotification = (t: NotificationType, title: string, description?: string, err?: SerializedError|RpcError) => {
  let message = description;
  if (t === 'error' && err) {
    if (isRpcError(err)) {
      message = rpcErrorMessage(err, description);
    }
    if (isSerializedError(err)) {
      message = serializedErrorMessage(err, description);
    }
  }
  notification[t]({
    message: title,
    description: (
      <div>
        {message}
        <CopyableValue
          displayValue=""
          copiedValue={err ? JSON.stringify(err) : message}
        />
      </div>
    ),
    placement: 'bottomRight',
  });
};
