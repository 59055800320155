import { Tag } from 'antd';
import React from 'react';

import { Color } from 'constants/index';
import { ModelName } from 'constants/actions';
import './ModelTag.scss';

interface IProps {
  modelName: ModelName;
}

const DISPLAY_INFO = {
  [ModelName.BERT_ACTION_CLASSIFIER]: { abbr: 'BERT', color: 'purple' },
  [ModelName.ONEVSALL_AGENT_ACTION_CLASSIFIER]: { abbr: 'AA', color: Color.AGENT },
  [ModelName.ONEVSALL_VISITOR_ACTION_CLASSIFIER]: { abbr: 'VA', color: Color.VISITOR },
  [ModelName.NEXT_AGENT_ACTION_ENSEMBLE]: { abbr: 'ENS', color: 'magenta' },
  [ModelName.RULE_BASED_DIALOGUE_POLICY]: { abbr: 'RuDP', color: 'gold' },
  [ModelName.NEURAL_DIALOGUE_POLICY]: { abbr: 'NeDP', color: 'cyan' },
};

export default function ModelTag({ modelName }: IProps) {
  const { color, abbr } = DISPLAY_INFO[modelName];

  return (
    <Tag color={color} className="model-tag">{abbr}</Tag>
  );
}
