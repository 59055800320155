import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'hooks/reduxHooks';
import { SetApi } from 'services/setApi';
import { Set, SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { notification } from 'antd';
import { createSet, deleteSet, listSets } from 'store/set/asyncThunks';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { SetManagementDropdown as SetManagementDropdownComponent } from './SetManagementDropdown';

interface SetManagementDropdownProps {
  isLoading?: boolean;
  allSets: Set[];
  membershipSets: Set[];
  conversationName: string;
  onChangeMembershipSets: (membershipSets: Set[]) => void;
}

export const SetManagementDropdown = ({ isLoading, allSets, membershipSets, conversationName, onChangeMembershipSets }: SetManagementDropdownProps) => {
  const [search, setSearch] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [cardData, setCardData] = useState([]);
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { languageCode } = useCustomerParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listSets({
      parent: customerProfile,
      usecase,
      languageCode,
    }));
  }, []);

  useEffect(() => {
    if (isLoading) return;
    setSelectedValues(membershipSets.map((set) => set.name));
    setCardData(allSets.map((set) => ({
      value: set.name,
      label: set.setTitle,
      subLabel: `${set.itemCount === undefined ? 0 : set.itemCount} conversations`,
    })));
  }, [isLoading, membershipSets]);

  const onCreate = () => {
    const setId = uuid();
    dispatch(createSet({
      parent: customerProfile,
      setId,
      set: {
        name: `${customerProfile}/sets/${setId}`,
        usecase,
        setType: SetType.CONVERSATION_SET_TYPE,
        setTitle: search,
        setItemNames: [conversationName],
        languageCode,
      },
    })).then((res) => {
      if (!res.payload) throw new Error('Set not created');
      const set = res.payload as Set;
      setCardData((prev) => [...prev, { value: set.name, label: set.setTitle, subLabel: '1 conversation' }]);
      setSelectedValues((prev) => [...prev, search]);
      setSearch('');
      onChangeMembershipSets([...membershipSets, set]);
      notification.success({
        message: 'Set created',
        description: `Set ${set.setTitle} created successfully`,
      });
    }).catch((error) => {
      console.error('error', error);
      notification.error({
        message: 'Set creation failed',
        description: `Set ${search} could not be created`,
      });
    });
  };

  const onDelete = (value) => {
    dispatch(deleteSet({ name: value, usecase, languageCode })).then(() => {
      setCardData((prev) => [...prev.filter((item) => item.value !== value)]);
      onChangeMembershipSets(membershipSets.filter((set) => set.name !== value));
      notification.success({
        message: 'Set deleted',
        description: 'Set deleted successfully',
      });
    }).catch((err) => {
      console.error('err', err);
      notification.error({
        message: 'Set deletion failed',
        description: `Set ${value} could not be deleted`,
      });
    });
  };

  const filteredList = cardData
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));

  const handleSelect = (value) => {
    if (selectedValues.includes(value)) {
      SetApi.removeFromSet({
        name: value,
        setItemNames: [conversationName],
        usecase,
        languageCode,
      }).then(() => {
        notification.info({
          message: 'Successfully removed from set',
        });
        setSelectedValues((prev) => prev.filter((item) => item !== value));
        onChangeMembershipSets(membershipSets.filter((set) => set.name !== value));
      }).catch((err) => {
        notification.error({
          message: 'Failed to remove from set',
          description: err.message,
        });
      });
    } else {
      SetApi.addToSet({
        name: value,
        setItemNames: [conversationName],
        usecase,
        languageCode,
      }).then((result) => {
        notification.info({
          message: 'Successfully added to set',
        });
        setSelectedValues((prev) => [...prev, value]);
        onChangeMembershipSets([...membershipSets, result.set]);
      }).catch((err) => {
        notification.error({
          message: 'Failed to add to set',
          description: err.message,
        });
      });
    }
  };

  return (
    <SetManagementDropdownComponent
      isLoading={isLoading}
      search={search}
      setSearch={setSearch}
      onCreate={onCreate}
      onDelete={onDelete}
      selectedValues={selectedValues}
      onSelect={handleSelect}
      cardData={filteredList}
      creatable
      popoverProps={{
        position: 'bottom-end',
      }}
      buttonProps={{
        variant: 'light',
      }}
    />
  );
};
