// eslint-disable no-unused-vars
import React, { useMemo } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { WarningOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import IntentTag from 'components/MultiTags';
import { selectConceptsFactory } from 'store/concept/selectors';
import { Concept, ConceptConceptType, ConceptState, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useSelector } from 'hooks/reduxHooks';
import RegexTag from 'components/RegexTag';
import IntentTypeTag from 'components/IntentTypeTag';
import { getId } from 'common/resourceName';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { cloneDeep } from 'lodash';
import { INTENT_TYPE_MAP } from 'constants/intent';
import { ProdIntent } from 'store/modelBuilder/state';
import { selectProdIntents } from 'store/modelBuilder/selectors';
import styles from './styles.module.scss';
import { hasRegexDiff, IntentRegexDiff } from './IntentRegexDiff';

interface Props {
  selectedIntents?: string[]
}

export function RegexTable(props:Props) {
  const { selectedIntents } = props;
  const customer = useCustomerParams();
  const concepts = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT));

  // Hide deprecated intents
  const filteredConcepts = cloneDeep(concepts).filter((concept) => concept.state !== ConceptState.DEPRECATED);
  filteredConcepts.sort((a, b) => {
    const aCount = (a.intent?.regexExpressions?.length || 0) + (a.intent?.negativeRegexExpressions?.length || 0);
    const bCount = (b.intent?.regexExpressions?.length || 0) + (b.intent?.negativeRegexExpressions?.length || 0);
    return bCount - aCount;
  });

  const currentlySelectedIntents = selectedIntents && filteredConcepts.filter((concept) => selectedIntents.includes(concept.conceptTitle));

  const dataSource = selectedIntents && selectedIntents.length > 0 ? currentlySelectedIntents : filteredConcepts;

  // Diff vs. intents in prod.
  const prodIntents = useSelector<ProdIntent[]>(selectProdIntents);
  const prodIntentsMap = useMemo(() => new Map<string, ProdIntent>(prodIntents.map(((intent) => [intent.intent, intent]))), [prodIntents]);
  const diffCount = useMemo(() => dataSource.map((concept): number => {
    const prodIntent = prodIntentsMap.get(concept.conceptTitle);
    if (!prodIntent) {
      if (!concept?.intent?.regexExpressions?.length && !concept?.intent?.negativeRegexExpressions?.length) {
        return 0; // No diff
      }
      return 1; // Diff
    }
    return hasRegexDiff(
      { positive: concept?.intent?.regexExpressions || [], negative: concept?.intent?.negativeRegexExpressions || [] },
      { positive: prodIntent.positiveRegex || [], negative: prodIntent.negativeRegex || [] },
    ) ? 1 : 0;
  }).reduce((acc, curr) => acc + curr, 0), [dataSource, prodIntentsMap]);

  const allTypes = [];
  INTENT_TYPE_MAP.forEach(({ text }, key) => allTypes.push({ text, value: key }));

  const columns: ColumnsType<Concept> = [
    {
      title: 'Intent',
      dataIndex: 'conceptTitle',
      key: 'intents',
      sorter: (a, b) => {
        if (a.conceptTitle < b.conceptTitle) {
          return 1;
        }
        if (a.conceptTitle > b.conceptTitle) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['descend'],
      render: (value: string, record: Concept) => (
        <div className={styles.regexTableIntent}>
          <IntentTag tags={[value]}/>
          <span className={styles.spacer} />
          <IntentRegexDiff
            studioPositive={record.intent?.regexExpressions || []}
            studioNegative={record.intent?.negativeRegexExpressions || []}
            prodPositive={prodIntentsMap.get(record.conceptTitle)?.positiveRegex || []}
            prodNegative={prodIntentsMap.get(record.conceptTitle)?.negativeRegex || []}
          />
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: ['intent', 'intentType'],
      filters: allTypes,
      onFilter: (value, record) => record.intent.intentType === value,
      key: 'type',
      render: (value: IntentIntentType) => <IntentTypeTag intentType={value}/>,
    },
    {
      title: 'Regex',
      dataIndex: 'intent',
      key: 'regex',
      render: (value, row) => <RegexTag positiveRegex={row.intent.regexExpressions} negativeRegex={row.intent.negativeRegexExpressions}/>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, row, index) => (
        <div>
          <span>
            <NavLink
              to={`/${customer.path}/edit-regex/${getId('concept', row.name)}`}
            >
              Edit
            </NavLink>
          </span>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'extra',
      render: (_, row) => (
        <div className={styles.regexTableArchive}>
          {/* <Tooltip title="Archive">
            <InboxOutlined onClick={() => {}} />
          </Tooltip> */}
        </div>
      ),
    },
  ];

  return (
    <>
      {diffCount > 0 && (
        <div className={styles.intentRegexDiffBanner}>
          <WarningOutlined className={styles.intentRegexDiffIcon} />
          &nbsp;{`${diffCount} differences in PROD vs. Studio`}
        </div>
      )}
      <Table columns={columns} dataSource={dataSource} />
    </>
  );
}
