import React, { useState, useContext } from 'react';
import { Button, Divider, ScrollArea, Select, Title } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Concept, CreateConceptRequest, CreateConceptResponse, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConcepts } from 'store/concept/selectors';
import { useSelector } from 'hooks/reduxHooks';
import './TagConfig.scss';
import { Tag } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { getId } from 'common/resourceName';
import { ConceptApi } from 'services/conceptApi';
import { UserContext } from 'context/UserContext';
import { PageContainer } from 'components/PageContainer';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { TagList } from './TagList';
import { FormItem } from './FormItem';

interface TagConfigProps {
  onSubmit: (conceptIds: string[]) => void;
}

enum UITagEnum {
  'DRIVER',
  'OUTCOME',
  'BEHAVIOR'
}

const filterByTagType = (uiTagType: UITagEnum, inverse?:boolean) => ({ tag }:{tag?:Tag}) => {
  const getResult = () => {
    switch (uiTagType) {
      case UITagEnum.DRIVER:
        return tag.tagType === TagTagType.DRIVER;
      case UITagEnum.OUTCOME:
        return tag.tagType === TagTagType.CONVERSATION_OUTCOME;
      case UITagEnum.BEHAVIOR:
        return tag.tagType === TagTagType.AGENT_INTENT || tag.tagType === TagTagType.VISITOR_INTENT;
      default:
        return true;
    }
  };
  const result = getResult();
  return inverse ? !result : result;
};

export const TagConfig = ({ onSubmit }: TagConfigProps) => {
  const parent = useCustomerProfile();
  const currentUser = useContext(UserContext);
  const concepts = useSelector<Concept[]>(selectAllConcepts);
  // TODO: create selector specifically for TAG concepts
  const tagConcepts = concepts.filter((concept) => concept.conceptType === 'TAG');
  const customer = useCustomerParams();

  const [newTags, setNewTags] = useState([]);
  const [addedExistingTags, setAddedExistingTags] = useState([]);

  // DRIVERS
  const newDrivers = newTags.filter(filterByTagType(UITagEnum.DRIVER));
  const [newDriver, setNewDriver] = useInputState('');
  const addedExistingDrivers = addedExistingTags.filter((filterByTagType(UITagEnum.DRIVER)));
  const allAddedDrivers = [...addedExistingDrivers, ...newDrivers];
  const existingDrivers = tagConcepts.filter(filterByTagType(UITagEnum.DRIVER));

  // OUTCOMES
  const newOutcomes = newTags.filter(filterByTagType(UITagEnum.OUTCOME));
  const [newOutcome, setNewOutcome] = useInputState('');
  const addedExistingOutcomes = addedExistingTags.filter(filterByTagType(UITagEnum.OUTCOME));
  const allAddedOutcomes = [...addedExistingOutcomes, ...newOutcomes];
  const existingOutcomes = tagConcepts.filter(filterByTagType(UITagEnum.OUTCOME));

  // BEHAVIORS
  const newBehaviors = newTags.filter(filterByTagType(UITagEnum.BEHAVIOR));
  const [newBehavior, setNewBehavior] = useInputState('');
  const addedExistingBehaviors = addedExistingTags.filter(filterByTagType(UITagEnum.BEHAVIOR));
  const allAddedBehaviors = [...addedExistingBehaviors, ...newBehaviors];
  const existingBehaviors = tagConcepts.filter(filterByTagType(UITagEnum.BEHAVIOR));
  const [intentType, setIntentType] = useState(TagTagType.AGENT_INTENT);

  const allNewConcepts = newTags;
  const allAddedConcepts = addedExistingTags;

  const allTaskTags = [...allAddedConcepts, ...allNewConcepts];

  const onCreateAnalysisTaskBtnClick = () => {
    // // Handle New Tag Creation
    Promise.all(newTags.map((concept) => {
      const request: CreateConceptRequest = {
        parent,
        conceptId: getId('concept', concept.name),
        userResourceName: currentUser?.name,
        concept: {
          ...concept,
          usecaseId: customer.usecaseId,
          languageCode: customer.languageCode,
        },
      };
      return ConceptApi.createConcept(request);
    })).then((conceptResponses:CreateConceptResponse[]) => {
      const newConceptIds = conceptResponses.map((response) => getId('concept', response.concept.name));

      const addedExistingConceptIds = addedExistingTags.map((concept) => {
        const conceptId = getId('concept', concept.name);
        return conceptId;
      });

      const allConceptIds = [...newConceptIds, ...addedExistingConceptIds];

      onSubmit(allConceptIds);
    });
  };

  const onDeleteFromTagList = (tagName:string) => {
    setNewTags((current) => current.filter((tag) => tag.name !== tagName));
    setAddedExistingTags((current) => current.filter((tag) => tag.name !== tagName));
  };

  const onClearList = (uiTagType:UITagEnum) => {
    setNewTags((current) => current.filter(filterByTagType(uiTagType, true)));
    setAddedExistingTags((current) => current.filter((filterByTagType(uiTagType, true))));
  };

  return (
    <PageContainer centered className="tag-config-container">
      <div className="max-width">
        <div className="title-section">
          <Title className="title">Configure tags</Title>
          <Button data-cy="tag-configuration-next-btn" disabled={allTaskTags.length === 0} onClick={onCreateAnalysisTaskBtnClick}>Create Analysis Task</Button>
        </div>
        <div className="content">
          <div className="form-container panel" >
            <ScrollArea className="scroll-area">
              {/* DRIVER FORM SECTION */}
              <FormItem
                autoFocus
                tagType={TagTagType.DRIVER}
                label="Add drivers"
                description="Select type and enter name"
                addAllButtonTestId="add-all-drivers-btn"
                existingItems={existingDrivers}
                addedItems={addedExistingDrivers}
                setAddedItems={setAddedExistingTags}
                newItem={newDriver}
                setNewItem={setNewDriver}
                setNewItems={setNewTags}
              />
              <Divider my="xl" />
              {/* OUTCOME FORM SECTION */}
              <FormItem
                tagType={TagTagType.CONVERSATION_OUTCOME}
                label="Add outcomes"
                description="Select type and enter name"
                addAllButtonTestId="add-all-outcomes-btn"
                existingItems={existingOutcomes}
                addedItems={addedExistingOutcomes}
                setAddedItems={setAddedExistingTags}
                newItem={newOutcome}
                setNewItem={setNewOutcome}
                setNewItems={setNewTags}
              />
              <Divider my="xl" />
              {/* BEHAVIOR FORM SECTION */}
              <FormItem
                tagType={intentType}
                label="Add behaviors"
                description="Add new or choose from shared library intents"
                addAllButtonTestId="add-all-behaviors-btn"
                existingItems={existingBehaviors}
                addedItems={addedExistingBehaviors}
                setAddedItems={setAddedExistingTags}
                newItem={newBehavior}
                setNewItem={setNewBehavior}
                setNewItems={setNewTags}
              >
                <Select
                  style={{ width: 110 }}
                  data={[
                    { label: 'Agent', value: TagTagType.AGENT_INTENT },
                    { label: 'Visitor', value: TagTagType.VISITOR_INTENT },
                  ]}
                  value={intentType}
                  onChange={(val:TagTagType) => setIntentType(val)}
                />
              </FormItem>
            </ScrollArea>
          </div>
          <div className="added-tag-container panel">
            <div className="list-header">Added Tags</div>
            <ScrollArea className="scroll-area" style={{ width: '100%' }}>
              {/* DRIVER TAGS */}
              <TagList
                subheader="Drivers"
                tags={allAddedDrivers}
                onDelete={onDeleteFromTagList}
                onClear={() => onClearList(UITagEnum.DRIVER)}
              />
              {/* OUTCOME TAGS */}
              <TagList
                subheader="Outcomes"
                tags={allAddedOutcomes}
                onDelete={onDeleteFromTagList}
                onClear={() => onClearList(UITagEnum.OUTCOME)}
              />
              {/* BEHAVIOR TAGS */}
              <TagList
                subheader="Behaviors"
                tags={allAddedBehaviors}
                onDelete={onDeleteFromTagList}
                onClear={() => {
                  onClearList(UITagEnum.BEHAVIOR);
                }}
              />
            </ScrollArea>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
