import React, { useEffect, useState, useContext } from 'react';
import { Anchor, Group, Paper, Text } from '@mantine/core';
import { ActionButton } from 'pages/AnalysisWorkshop/NewTaskConfig/SetConfig/SetCards/components';
import { DataGrid } from 'components/DataGrid';
import { ColumnDef } from '@tanstack/react-table';
import { ProdDocumentReleaseApi } from 'services/prodDocumentReleaseApi';
import { showNotification } from '@mantine/notifications';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { CustomerConfigContext } from 'context/CustomerConfigContext';

const RefreshIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.47493 1.5003C8.37655 1.25178 7.23311 1.28555 6.1513 1.59846C5.07336 1.91026 4.09162 2.48908 3.29701 3.28122L1.33333 5.11205V2.66764C1.33333 2.29945 1.03486 2.00098 0.666667 2.00098C0.298477 2.00098 0 2.29945 0 2.66764V6.66764C0 7.03583 0.298477 7.33431 0.666667 7.33431H4.66667C5.03486 7.33431 5.33333 7.03583 5.33333 6.66764C5.33333 6.29945 5.03486 6.00098 4.66667 6.00098H2.33514L4.21462 4.24864C4.22035 4.2433 4.22599 4.23785 4.23153 4.23231C4.86841 3.59511 5.65634 3.12962 6.52178 2.87929C7.38723 2.62896 8.30198 2.60194 9.18069 2.80076C10.0594 2.99958 10.8734 3.41776 11.5468 4.01626C12.2202 4.61477 12.731 5.37411 13.0315 6.22342C13.1544 6.57052 13.5353 6.75233 13.8824 6.6295C14.2295 6.50668 14.4113 6.12573 14.2885 5.77863C13.9128 4.71699 13.2743 3.76782 12.4326 3.01968C11.5909 2.27154 10.5733 1.74883 9.47493 1.5003ZM2.96848 9.77863C2.84565 9.43153 2.4647 9.24972 2.11761 9.37255C1.77051 9.49538 1.5887 9.87633 1.71153 10.2234C2.0872 11.2851 2.7257 12.2342 3.56743 12.9824C4.40915 13.7305 5.42668 14.2532 6.52507 14.5018C7.62346 14.7503 8.7669 14.7165 9.8487 14.4036C10.9261 14.092 11.9074 13.5136 12.7018 12.722L14.6667 10.9083V13.3343C14.6667 13.7025 14.9651 14.001 15.3333 14.001C15.7015 14.001 16 13.7025 16 13.3343V9.33431C16 8.96612 15.7015 8.66764 15.3333 8.66764H11.3333C10.9651 8.66764 10.6667 8.96612 10.6667 9.33431C10.6667 9.7025 10.9651 10.001 11.3333 10.001H13.6838L11.7878 11.7512C11.7812 11.7572 11.7748 11.7634 11.7685 11.7697C11.1316 12.4069 10.3437 12.8724 9.47822 13.1228C8.61278 13.3731 7.69803 13.4001 6.81932 13.2013C5.94061 13.0025 5.12658 12.5843 4.4532 11.9858C3.77982 11.3873 3.26902 10.6279 2.96848 9.77863Z" fill="#304FFE"/>
  </svg>
);

export const CannedSuggestionsTable = () => {
  const parent = useCustomerProfile();
  const { currentConfig: { customerShortName } } = useContext(CustomerConfigContext);
  const [cannedSuggestions, setCannedSuggestions] = useState([]);
  const [isLoading, toggleLoading] = useState(false);
  const [refresh, toggleRefresh] = useState(false);

  useEffect(() => {
    toggleLoading(true);
    const getDocumentReleases = async () => {
      try {
        const documentReleases = await ProdDocumentReleaseApi.listDocumentReleases(parent);
        const fetchedCannedSuggestions = documentReleases.map((dr) => ({
          title: dr.document?.title,
          content: dr.document?.agentResponse?.agentResponseContent?.blob?.content,
        }));
        setCannedSuggestions(fetchedCannedSuggestions);
        toggleLoading(false);
      } catch (e) {
        console.error(e);
        toggleLoading(false);
        showNotification({
          title: 'Error',
          message: 'There was an error loading the canned suggestions',
          color: 'red',
        });
      }
    };
    getDocumentReleases();
  }, [refresh]);

  const columns: ColumnDef<{
    title: string;
    content: string;
  }>[] = [
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Text',
      accessorKey: 'content',
    },
  ];
  return (
    <Paper style={{ flex: 1 }}>
      <Group position="apart" p="sm">
        <Text>Number of canned suggestions in Director</Text>
        <Group>
          <Anchor href={`https://${customerShortName}.cresta.com/director/admin/canned-suggestions`} target="_blank">
            See in Director
          </Anchor>
          <ActionButton onClick={() => toggleRefresh(!refresh)}>
            <RefreshIcon />
          </ActionButton>
        </Group>
      </Group>
      <DataGrid
        isLoading={isLoading}
        columns={columns}
        dataSource={cannedSuggestions}
      />
    </Paper>
  );
};
