import { Concept, SharedConceptConfigDeploymentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Anchor, createStyles, Table } from '@mantine/core';
import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import DeactivateIntentModal from 'components/ConceptsView/DeactivateIntentModal/DeactivateIntentModal';
import MultiTags from 'components/MultiTags';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { capitalize } from 'lodash';
import React, { useMemo, useState, useContext, useCallback } from 'react';
import { toTitleCase } from 'utils';

interface ConceptCustomersTableProps {
  concept: Concept;
}

type ConceptCustomersTableRow = {
  customerId: string;
  profileId: string;
  usecaseId: string;
  deploymentType: SharedConceptConfigDeploymentType;
  status: 'active' | 'inactive';
}

export default function ConceptCustomersTable({
  concept,
}: ConceptCustomersTableProps) {
  const useStyles = createStyles((theme) => ({
    grid: {
      borderRadius: theme.radius.lg,
    },
  }));
  const styles = useStyles();
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Config>();
  const { getFirstConfig } = useContext(CustomerConfigContext);
  const getCustomerNickName = useCallback((row: ConceptCustomersTableRow) => {
    const config = getFirstConfig({
      customerId: row.customerId,
      profileId: row.profileId,
      usecaseId: row.usecaseId,
      languageCode: '',
    });
    return `${toTitleCase(config?.customerShortName || '', '-')} (${config?.usecaseId})`;
  }, [getFirstConfig]);

  const rows = useMemo(() => (concept.sharedConceptConfig || []).map((config) => {
    const row: ConceptCustomersTableRow = {
      customerId: config.customerId,
      profileId: config.profileId,
      usecaseId: config.usecaseId,
      deploymentType: config.deploymentType,
      status: config.deploymentType === SharedConceptConfigDeploymentType.OFF ? 'inactive' : 'active',
    };
    const customerFriendlyName = getCustomerNickName(row);
    return (
      <tr key={customerFriendlyName}>
        <td>{customerFriendlyName}</td>
        <td>
          <MultiTags
            tags={[row.deploymentType === SharedConceptConfigDeploymentType.FINETUNED ? 'Customer OOB' : 'OOB']}
            type="conceptTag"
            color="gray"
            size="small"
          />
        </td>
        <td>{capitalize(row.status)}</td>
        <td>{row.deploymentType !== SharedConceptConfigDeploymentType.OFF && (
          <Anchor onClick={() => {
            const customer = getFirstConfig({
              customerId: row.customerId,
              profileId: row.profileId,
              usecaseId: row.usecaseId,
              languageCode: '',
            });
            setSelectedCustomer(customer);
            setIsDeactivateModalVisible(true);
          }}
          >Deactivate
          </Anchor>
        )}
        </td>
      </tr>
    );
  }), [concept, getFirstConfig]);

  return (
    <>
      <Table className={styles.classes.grid}>
        <thead>
          <tr>
            <th>Customer</th>
            <th>Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <DeactivateIntentModal
        opened={isDeactivateModalVisible}
        concept={concept}
        onCancel={() => setIsDeactivateModalVisible(false)}
        customerId={selectedCustomer?.customerId}
        profileId={selectedCustomer?.profileId}
        usecaseId={selectedCustomer?.usecaseId}
      />
    </>
  );
}
