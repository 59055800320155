import {
  AbstractTaskService,
  ListAbstractTasksRequest,
  ListAbstractTasksResponse,
  UpdateAbstractTaskRequest,
  UpdateAbstractTaskResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { StudioApi } from './studioApi';

export abstract class AbstractTaskApi {
  public static listAbstractTasks(
    req: ListAbstractTasksRequest,
  ): Promise<ListAbstractTasksResponse> {
    return AbstractTaskService.ListAbstractTasks(req, StudioApi.getHeaders().initReq);
  }

  public static updateAbstractTask(
    req: UpdateAbstractTaskRequest,
  ): Promise<UpdateAbstractTaskResponse> {
    return AbstractTaskService.UpdateAbstractTask(req, StudioApi.getHeaders().initReq);
  }
}
