import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateModelProjectRequest, ModelProject, UpdateModelProjectRequest } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { ModelProjectApi } from 'services/modelProjectApi';
import { openNotification } from 'components/Notification';

enum Action {
  MODEL_PROJECT_LIST_MODEL_PROJECTS = 'MODEL_PROJECT_LIST_MODEL_PROJECTS',
  MODEL_PROJECT_CREATE_MODEL_PROJECTS = 'MODEL_PROJECT_CREATE_MODEL_PROJECTS',
  MODEL_PROJECT_UPDATE_MODEL_PROJECT = 'MODEL_PROJECT_UPDATE_MODEL_PROJECT'
}

const DEFAULT_PAGE_SIZE = 1000;

/** AsyncThunk for listing (one page of) model projects. */
export const listModelProjects = createAsyncThunk<ModelProject[], {
  parent: string,
  usecaseId: string,
  languageCode: string,
}>(Action.MODEL_PROJECT_LIST_MODEL_PROJECTS, async ({ parent, usecaseId, languageCode }) => {
  try {
    const response = await ModelProjectApi.listModelProjects({
      parent,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: {
        usecaseId,
        languageCode,
      },
    });
    return response.modelProjects;
  } catch (err) {
    openNotification('error', 'Failed to list model projects', undefined, err);
    throw err;
  }
});

/** AsyncThunk for creating a model project. */
export const createModelProject = createAsyncThunk<ModelProject, CreateModelProjectRequest>(Action.MODEL_PROJECT_CREATE_MODEL_PROJECTS, async (request: CreateModelProjectRequest) => {
  try {
    const response = await ModelProjectApi.createModelProjects(request);
    return response.modelProject;
  } catch (err) {
    openNotification('error', 'Failed to create model project', undefined, err);
    throw err;
  }
});

/** AsyncThunk for update ModelProject. */
export const updateModelProject = createAsyncThunk<ModelProject, UpdateModelProjectRequest>(Action.MODEL_PROJECT_UPDATE_MODEL_PROJECT, async (request: UpdateModelProjectRequest) => {
  try {
    const response = await ModelProjectApi.updateModelProject(request);
    return response.modelProject;
  } catch (err) {
    openNotification('error', 'Failed to create model project', undefined, err);
    throw err;
  }
});
