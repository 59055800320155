export const positivePreviewSkeletons = [
  { key: 'a', rows: 1, lastRowWidth: '23%' },
  { key: 'b', rows: 2, lastRowWidth: '83%' },
  { key: 'c', rows: 1, lastRowWidth: '15%' },
  { key: 'd', rows: 0, lastRowWidth: '20%' },
  { key: 'e', rows: 1, lastRowWidth: '83%' },
];
export const negativePreviewSkeletons = [
  { key: 'a', rows: 1, lastRowWidth: '15%' },
  { key: 'b', rows: 2, lastRowWidth: '83%' },
  { key: 'c', rows: 2, lastRowWidth: '83%' },
  { key: 'd', rows: 0, lastRowWidth: '20%' },
  { key: 'e', rows: 2, lastRowWidth: '13%' },
];
