import React from 'react';
import { useMantineTheme } from '@mantine/core';
import { Chart } from 'react-google-charts';

export const data = [
  ['From', 'To', 'Weight'],
  ['Authenticated User', 'Cancel Authority', 3150],
  ['Authenticated User', 'No Cancel Authority', 200],
  ['Unauthenticated User', 'User Authenticated', 1380],
  ['Unauthenticated User', 'Cannot Authenticate', 100],
  ['Unauthenticated User', 'Misc', 40],
  ['User Authenticated', 'Cancel Authority', 1150],
  ['User Authenticated', 'No Cancel Authority', 100],
  ['Cancel Authority', 'Perm Move', 3400],
  ['Cancel Authority', 'Temp Move', 720],
  ['No Cancel Authority', 'Account Management', 192],
  ['No Cancel Authority', 'User Dropoff', 300],
  ['Perm Move', 'Replacement Unknown', 2000],
  ['Perm Move', 'Replacement Known', 1380],
  ['Temp Move', 'Replacement Unknown', 250],
  ['Temp Move', 'Replacement Known', 120],
  ['Temp Move', 'Seasonal Hold', 530],
  ['Temp Move', 'User Dropoff', 21],
  ['Replacement Unknown', 'Prorated Billing Address', 2000],
  ['Replacement Unknown', 'Just Cancel', 1],
  ['Replacement Unknown', 'User Dropoff', 1],
  ['Replacement Known', 'Prorated Billing Address', 100],
  ['Replacement Known', 'Referral Discount', 750],
  ['Replacement Known', 'Just Cancel', 50],
  ['Replacement Known', 'User Dropoff', 3],
  ['Replacement Known', 'Misc.', 5],
  ['Prorated Billing Address', 'Service Transferred', 1800],
  ['Prorated Billing Address', 'Cancelled No Lead', 500],
  ['Referral Discount', 'Cancelled lead generated', 500],
  ['Referral Discount', 'Just Cancel', 1],
  ['Referral Discount', 'User Dropoff', 1],
];

export const SankeyGoogleChart = () => {
  const theme = useMantineTheme();
  const options = {
    sankey: {
      link: { color: { fill: theme.colors.blue[1] } },
    },
  };
  return <Chart chartType="Sankey" width={900} height={500} data={data} options={options} />;
};
