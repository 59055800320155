import { AbstractTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { FetchLabelingDataResponse, FetchLabelingItemsResponse, SaveTemporalAnnotationResponse, SelectNewBatchResponse } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';
import { getId, toLabelingTaskName } from 'common/resourceName';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { getAnnotationRawDataKey } from 'store/annotation/selectors';
import { stripHTMLFromString } from 'utils';
import { INITIAL_STATE, State } from './state';

/** General reducer for starting a task API. */
export function taskApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
    error: null,
  };
}

/** General reducer for a failed task API. */
export function taskApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
    error: action.payload,
  };
}

/** Reducer for pending fetch labeling tasks. */
export function fetchDerivedLabelingTasksPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
    fetchingTasks: true,
    derivedLabelingTasks: [],
    error: null,
  };
}

/** Reducer for rejected fetch labeling tasks. */
export function fetchDerivedLabelingTasksFailed(state: State, action: PayloadAction<SerializedError>): State {
  const castAction = action as { error?: { name?: string } };
  if (castAction.error.name === 'AbortError') {
    return state;
  }
  return {
    ...state,
    status: 'failed',
    fetchingTasks: false,
    error: action.payload,
  };
}

/** General reducer for fetching labeling tasks. */
export function fetchDerivedLabelingTasksSuccess(state: State, action: PayloadAction<DerivedLabelingTask[] | undefined>): State {
  if (action.payload === undefined) {
    return state;
  }
  return {
    ...state,
    status: 'succeeded',
    fetchingTasks: false,
    error: null,
    derivedLabelingTasks: action.payload,
  };
}
/** Reducer for refreshing labeling tasks rejected. */
export function refreshDerivedLabelingTasksFailed(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    fetchingTasks: false,
  };
}

/** Reducer for refreshing labeling tasks pending. */
export function refreshDerivedLabelingTasksPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    fetchingTasks: true,
  };
}

/** Reducer for refreshing labeling tasks fulfilled. */
export function refreshDerivedLabelingTasksSuccess(state: State, action: PayloadAction<DerivedLabelingTask[] | undefined>): State {
  // Prevent updating tasks if not updated.
  if (action.payload === undefined || isEqual(state.derivedLabelingTasks, action.payload)) {
    return {
      ...state,
      fetchingTasks: false,
    };
  }
  return {
    ...state,
    fetchingTasks: false,
    derivedLabelingTasks: action.payload,
  };
}
/** Reducer for created labeling task. */
export function createLabelingTaskSuccess(state: State, action: PayloadAction<DerivedLabelingTask>): State {
  const index = state.derivedLabelingTasks.findIndex((t: DerivedLabelingTask) => t.labelingTask.name === action.payload.labelingTask.name);
  const newTasks = cloneDeep(state.derivedLabelingTasks);
  if (index === -1) {
    newTasks.push(action.payload);
  } else {
    newTasks[index] = action.payload;
  }
  return {
    ...state,
    status: 'succeeded',
    error: null,
    derivedLabelingTasks: newTasks,
  };
}
/** Reducer for updated abstract task. */
export function updateAbstractTaskSuccess(state: State, action: PayloadAction<AbstractTask>): State {
  const name = toLabelingTaskName(action.payload.name);
  // TODO(Matus): Refactor this when we merge QA and Labeling in state
  const index = state.derivedLabelingTasks.findIndex((t: DerivedLabelingTask) => t?.labelingTask?.name === name);
  if (index === -1) {
    // The labeling task doesn't exist in the state any more.
    return {
      ...state,
      status: 'succeeded',
      error: null,
    };
  }
  const newTasks = cloneDeep(state.derivedLabelingTasks);
  newTasks[index].labelingTask.abstractTask = action.payload;

  const newState: State = {
    ...state,
    status: 'succeeded',
    error: null,
    derivedLabelingTasks: newTasks,
  };

  return newState;
}

/** Fetch labeling task data failure reducer. */
export function fetchLabelingTaskDataFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
    error: action.payload,
    currentLabelingTask: null,
    currentTaskProgress: null,
    temporalAnnotations: [],
    fetchingAnnotations: false,
  };
}

/** Fetch labeling task data pending reducer. */
export function fetchLabelingTaskDataPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
    error: null,
    currentLabelingTask: null,
    currentTaskProgress: null,
    fetchingAnnotations: true,
    temporalAnnotations: [],
  };
}

/** Fetch labeling task data reducer. */
export function fetchLabelingTaskDataSuccess(state: State, action: PayloadAction<FetchLabelingDataResponse>): State {
  return {
    ...state,
    status: 'succeeded',
    error: null,
    currentLabelingTask: action.payload.labelingTask,
    currentTaskProgress: action.payload.taskProgress,
  };
}

/** Fetch new batch reducer. */
export function selectNewBatchSuccess(state: State, action: PayloadAction<SelectNewBatchResponse>): State {
  // Replace derived task
  const { derivedLabelingTask } = action.payload;
  const index = state.derivedLabelingTasks.findIndex((t: DerivedLabelingTask) => t.labelingTask.name === derivedLabelingTask.labelingTask.name);
  const newTasks = cloneDeep(state.derivedLabelingTasks);
  if (index === -1) {
    newTasks.push(derivedLabelingTask);
  } else {
    newTasks[index] = derivedLabelingTask;
  }

  const canSampleMore = derivedLabelingTask.currentConversationNumber < (derivedLabelingTask.totalConversationNumber - 1);

  return {
    ...state,
    status: 'succeeded',
    error: null,
    currentLabelingTask: action.payload.derivedLabelingTask.labelingTask,
    derivedLabelingTasks: newTasks,
    canSampleMore,
  };
}

/** Fetch labeling task data reducer. */
export function fetchLabelingTaskItemsSuccess(state: State, action: PayloadAction<FetchLabelingItemsResponse>): State {
  const rawConversationLabelingItems = action.payload.conversationLabelingItems[0];

  const htmlStrippedMessagesAndContexts = (action.payload.conversationLabelingItems[0]?.messagesAndContexts || []).map(
    (m) => ({
      ...m,
      messageContent: stripHTMLFromString(m.messageContent),
    }),
  );
  const conversationLabelingItems = {
    ...rawConversationLabelingItems,
    messagesAndContexts: htmlStrippedMessagesAndContexts,
  };

  return {
    ...state,
    status: 'succeeded',
    error: null,
    temporalAnnotations: action.payload.temporalAnnotations,
    // We only fetch 1 conversation labeling items at a time
    conversationLabelingItems,
    fetchingAnnotations: false,
    // Only override annotation value counts if null
    taskTemporalAnnotationValueCounts: state.taskTemporalAnnotationValueCounts ? state.taskTemporalAnnotationValueCounts : action.payload.taskTemporalAnnotationValueCounts,
  };
}

/** Fetch labeling task data reducer. */
export function completeLabelingTaskSuccess(state: State, action: PayloadAction<FetchLabelingDataResponse>): State {
  return {
    ...state,
    status: 'succeeded',
    error: null,
    taskCompleted: true,
  };
}

/** Reducer for clearing (resetting) labeling task data */
export function clearLabelingTaskReducer(state: State): State {
  return INITIAL_STATE;
}

/** Fetch annotations. */
export function fetchLabelingTaskAnnotationsSuccess(state: State, action: PayloadAction<SearchAnnotationsResponseAnnotationBundle[]>): State {
  return {
    ...state,
    status: 'succeeded',
    error: null,
    fetchingAnnotations: false,
    temporalAnnotations: action.payload.map((bundle) => bundle.annotation),
    annotationBundles: action.payload,
  };
}

/** Remove or add (ghost) annotations unconfirmed by server */
export function updateGhostAnnotationsReducer(state: State, action: PayloadAction<{ existing: Annotation[], new: Annotation[] }>): State {
  const existingIds = (action.payload.existing || []).map((annotation) => annotation.name);
  const updatedAnnotations = cloneDeep(state.temporalAnnotations).filter((annotation) => !existingIds.includes(annotation.name));

  const updatedFailedToSaveMessages = cloneDeep(state.failedToSaveMessages);
  action.payload.new.forEach((annotation) => {
    const rawDataKey = getAnnotationRawDataKey(annotation);
    const messageId = annotation.rawData[rawDataKey]?.v2MessageId;
    if (updatedFailedToSaveMessages[messageId]) {
      delete updatedFailedToSaveMessages[messageId];
    }
  });

  return {
    ...state,
    temporalAnnotations: [...updatedAnnotations, ...action.payload.new],
    failedToSaveMessages: updatedFailedToSaveMessages,
  };
}

/** Annotations should already exist in the local state by now
 * we just need to update them with the ones returned from the server */
export function upsertTemporalAnnotationsSuccess(state: State, action: PayloadAction<SaveTemporalAnnotationResponse>): State {
  const updatedAnnotations = cloneDeep(state.temporalAnnotations);
  const annotationsMap = new Map<string, Annotation>(
    updatedAnnotations.map((annotation) => [annotation.name, annotation]),
  );

  // Replace annotations in local state
  action.payload.upsertedAnnotations.forEach((annotation) => {
    const annotationId = getId('annotation', annotation.name);
    if (annotationsMap.get(annotationId)) {
      annotationsMap.set(annotationId, annotation);
    }
  });

  // Replace derived labeling / qa tasks
  const newTasks = cloneDeep(state.derivedLabelingTasks);
  const index = newTasks.findIndex((t: DerivedLabelingTask) => t.labelingTask.name === action.payload.derivedLabelingTask?.labelingTask?.name);

  if (action.payload.derivedLabelingTask) {
    if (index === -1) {
      newTasks.push(action.payload.derivedLabelingTask);
    } else {
      newTasks[index] = action.payload.derivedLabelingTask;
    }
  }

  const newState: State = {
    ...state,
    status: 'succeeded',
    error: null,
    temporalAnnotations: Array.from(annotationsMap.values()),
    taskTemporalAnnotationValueCounts: action.payload.valueCounts,
    derivedLabelingTasks: newTasks,
  };

  return newState;
}

/** Mark annotations that weren't confirmed by server as failed */
export function upsertTemporalAnnotationsFailed(state: State): State {
  const updatedFailedToSaveMessages = cloneDeep(state.failedToSaveMessages);
  state.temporalAnnotations.forEach((annotation) => {
    // If annotation doesn't have createTime, it means it
    // wasn't confirmed by server
    if (!annotation.createTime) {
      const rawDataKey = getAnnotationRawDataKey(annotation);
      const V1OrV2MessageId = annotation.rawData[rawDataKey]?.v2MessageId;
      updatedFailedToSaveMessages[V1OrV2MessageId] = true;
    }
  });

  return {
    ...state,
    failedToSaveMessages: updatedFailedToSaveMessages,
    status: 'failed',
  };
}

/** Annotations should already exist in the local state by now
 * we just need to update them with the ones returned from the server */
export function removeTemporalAnnotationsSuccess(state: State, action: PayloadAction<string[]>): State {
  const updatedAnnotations = cloneDeep(state.temporalAnnotations);

  const removedAnnotationsSet = new Set(action.payload);

  const newState: State = {
    ...state,
    status: 'succeeded',
    error: null,
    // TODO: Should also update value counts and derived task
    temporalAnnotations: updatedAnnotations.filter((annotation) => !removedAnnotationsSet.has(getId('annotation', annotation.name))),
  };

  return newState;
}

/** Fetch labeling task data reducer. */
export function fetchConversationPredictionsSuccess(state: State, action: PayloadAction<Prediction[]>): State {
  return {
    ...state,
    status: 'succeeded',
    predictions: action.payload,
  };
}
