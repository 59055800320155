import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  GetModelArtifactResponse,
  ListModelArtifactsResponse,
  ModelArtifact,
  ModelArtifactArtifactType,
  ModelArtifactStatus,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { StatusCodes } from 'http-status-codes';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { modelArtifactApi } from 'services/modelArtifactApi';
import { openNotification } from 'components/Notification';
import { getId } from 'common/resourceName';
import { useInterval } from '@mantine/hooks';
import { useApiPost } from '../../hooks/network';

export type KeyedModelArtifact = ModelArtifact & {
  key: string;
}

export interface ResourceCreationArgs {
  resourceRoute: string,
  createResourceBodyArgs: object,
  acceptedCallback?: Function,
  completedCallback?: Function,
}

export const useModelArtifacts = (projectId?: number, artifactType?: ModelArtifactArtifactType): [
  KeyedModelArtifact[], boolean, (args: ResourceCreationArgs) => Promise<void>, (artifactIds: string[]) => Promise<void>,
] => {
  const [artifacts, setArtifacts] = useState<KeyedModelArtifact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const customerProfile = useCustomerProfile();

  const listModelArtifacts = useCallback(async () => {
    if (!projectId || !artifactType || !customerProfile) {
      return;
    }
    setArtifacts([]);
    setLoading(true);
    let resp: ListModelArtifactsResponse = {};
    try {
      resp = await modelArtifactApi.listModelArtifacts({
        parent: `${customerProfile}/modelProjects/${projectId}`,
        filter: { artifactType },
      });
    } catch (err) {
      openNotification('error', `Failed to list ${artifactType} tasks`, undefined, err);
    } finally {
      setLoading(false);
    }
    setArtifacts((resp?.modelArtifacts || []).map((a) => ({ key: getId('modelArtifact', a.name), ...a })));
  }, [projectId, artifactType, customerProfile]);

  useEffect(() => {
    listModelArtifacts();
  }, [projectId, artifactType, customerProfile]);

  const runningArtifactIds: number[] = useMemo(() => artifacts.filter((a) => {
    const createdAt = new Date(a.createTime);
    const now = new Date();
    return a.status === ModelArtifactStatus.ACCEPTED && now.getTime() - createdAt.getTime() < 12 /* hour */ * 3600 * 1000;
  }).map((a) => Number(a.key)), [artifacts]);

  const pollRunningTasks = async () => {
    if (!projectId || !artifactType || !customerProfile || !runningArtifactIds?.length) {
      return;
    }
    let resp: ListModelArtifactsResponse = {};
    try {
      resp = await modelArtifactApi.listModelArtifacts({
        parent: `${customerProfile}/modelProjects/${projectId}`,
        filter: {
          artifactType,
          artifactIds: runningArtifactIds,
        },
      });
    } catch (err) {
      openNotification('error', `Failed to poll tasks ${runningArtifactIds} status`, undefined, err);
    }
    const updatedArtifacts = (resp.modelArtifacts || []).filter(
      (a) => a.status !== ModelArtifactStatus.ACCEPTED,
    ).map((a) => ({ key: getId('modelArtifact', a.name), ...a }));
    if (updatedArtifacts.length === 0) {
      return;
    }
    const nextArtifacts = artifacts.filter((a) => updatedArtifacts.findIndex((ua) => ua.key === a.key) < 0);
    setArtifacts([...updatedArtifacts, ...nextArtifacts]);
  };
  const { start, stop } = useInterval(pollRunningTasks, 5000);

  useEffect(() => {
    start();
    return stop;
  }, [runningArtifactIds]);

  // Resource creation.
  const apiPost = useApiPost();
  const createResourceFn = async (args: ResourceCreationArgs) => {
    const {
      resourceRoute,
      createResourceBodyArgs,
      acceptedCallback,
      completedCallback,
    } = args;

    let response;
    try {
      response = await apiPost(resourceRoute, createResourceBodyArgs);
    } catch (error) {
      openNotification('error', `Failed to create ${resourceRoute}`, JSON.stringify(error));
      return;
    } finally {
      if (completedCallback) {
        completedCallback();
      }
    }
    if (response.httpStatus === StatusCodes.ACCEPTED) {
      if (!response.result_id) {
        console.warn('Creation response had no resource id to retrieve the async result.');
      }
      if (acceptedCallback) {
        console.info(`Accepted. Triggering call back with response.result_id ${response.result_id}`);
        acceptedCallback(response.result_id);
      }
    }
    let got: GetModelArtifactResponse = {};
    try {
      got = await modelArtifactApi.getModelArtifact({
        name: `${customerProfile}/modelProjects/${projectId}/modelArtifacts/${response.result_id}`,
      });
    } catch (err) {
      openNotification('error', `Failed to get the created task ${response.result_id}`, undefined, err);
      return;
    }
    setArtifacts([{ key: response.result_id.toString(), ...got.modelArtifact }, ...artifacts]);
  };

  // Archive artifacts.
  const archiveArtifacts = async (artifactIds: string[]) => {
    try {
      await modelArtifactApi.batchArchiveArtifacts({
        modelProject: `${customerProfile}/modelProjects/${projectId}`,
        modelArtifacts: artifactIds.map((a) => `${customerProfile}/modelProjects/${projectId}/modelArtifacts/${a}`),
      });
    } catch (err) {
      openNotification('error', `Failed to archive the tasks ${artifactIds}`, undefined, err);
      return;
    }
    const nextArtifacts = artifacts.filter(({ key }) => !artifactIds.includes(key));
    setArtifacts(nextArtifacts);
  };

  return [artifacts, loading, createResourceFn, archiveArtifacts];
};
