/* eslint-disable no-return-assign */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Flex } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import IntentDetailDrawer from 'components/IntentDetailDrawer';
import { KBar } from 'components/KBar';
import { useStudioAppSubscription } from 'hooks/useStudioSubscription';
import { useFullStoryMonitoring } from 'hooks/useFullStoryMonitoring';
import { useMixpanelTracking } from 'hooks/useMixpanelTracking';
import { useDebuggingMode } from 'hooks/useDebuggingMode';
import { useReduxInit } from 'hooks/useReduxInit';
import Sidebar from 'components/Sidebar';
import { UserContextProvider } from 'context/UserContext';
import { OktaAuthProvider } from 'context/OktaAuthContext';
import { PageLoader } from 'components/PageLoader';
import { Routes } from './Routes';
import { DEFAULT_TITLE } from './constants/index';

// A wrapper is required to set up hooks that are contingent on redux being initialized.
const HookWrapper = ({ children }) => {
  useDebuggingMode();
  useMixpanelTracking();
  useStudioAppSubscription();
  useFullStoryMonitoring();
  return children;
};

export const AppRoutesContainer = () => {
  const initialized = useReduxInit();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {initialized ? (
        <HookWrapper>
          <Flex
            wrap="nowrap"
            pl={64}
            style={{
              width: '100%',
              height: '100vh',
              maxHeight: '100vh',
            }}
          >
            <ErrorBoundary>
              <Sidebar />
              <UserContextProvider>
                <Outlet />
              </UserContextProvider>
              <IntentDetailDrawer />
            </ErrorBoundary>
          </Flex>
          <KBar />
        </HookWrapper>
      ) : (
        <Flex
          wrap="nowrap"
          pl={64}
          style={{
            width: '100%',
            height: '100vh',
            maxHeight: '100vh',
          }}
        >
          <PageLoader />
        </Flex>
      )}
    </>
  );
};

const App = () => (
  <Flex wrap="nowrap" className="App">
    <Helmet defaultTitle={DEFAULT_TITLE} titleTemplate={`%s | ${DEFAULT_TITLE}`} />
    <div style={{ flex: 1 }}>
      <OktaAuthProvider>
        <Routes />
      </OktaAuthProvider>
    </div>
  </Flex>
);

export default App;
