/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { Box, createStyles, Group, keyframes, Stack, Tooltip } from '@mantine/core';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message as MessageType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { sortBy } from 'lodash';
import classNames from 'classnames';
import { Stage, UITagType } from '../..';
import { StagesContext } from '../../StagesProvider';
import { PredictedTag } from '../../TaggingPage';

export const pulsate = keyframes({
  'from 0%, to': { opacity: 0.2 },
  '50%': { opacity: 0.8 },
});

const useStyles = createStyles((theme) => ({
  wrapper: {
    // if data-active attribute is present, set background to yellow
    '&:hover': {
      cursor: 'pointer',
      background: theme.colors[theme.primaryColor][0],
    },
    '&[data-active="true"]': {
      cursor: 'default',
      background: theme.colors.yellow[0],
    },
  },
  prediction: {
    opacity: 0.5,
  },
  predictionSaving: {
    animation: `${pulsate} 0.5s ease-in-out infinite`,
  },
}));

interface Props {
  index: number
  tags: UITagType[]
  messagePredictedTags?: PredictedTag[]
  letterAvatar: string
  isActive?: boolean
  message: MessageType
  onRemoveTagFromMessage?: (tagId: string, index?: number) => void
  onPredictionTagClick?: (tag: PredictedTag) => void
  bg: string
  color?: string
  alignRight?: boolean
  handleMessageClick?: () => void
  savingPrediction?: string
  setSavingPrediction?: (id: string) => void
}

export const Message = ({
  index,
  letterAvatar,
  messagePredictedTags = [],
  isActive = false,
  message,
  tags,
  onRemoveTagFromMessage,
  onPredictionTagClick,
  bg,
  color = undefined,
  alignRight = undefined,
  handleMessageClick,
  savingPrediction,
  setSavingPrediction,
}: Props) => {
  const { classes } = useStyles();

  const { onRemoveStageEnd, stages, resetStageTagging } = useContext(StagesContext);
  const stageEndTags: Stage[] = [];
  stages.forEach((stage) => {
    if (stage.conversationIndexEnd === index && stage.annotationId !== 'temp') {
      stageEndTags.push(stage);
    }
  });

  const tempStartTags: Stage[] = [];
  stages.forEach((stage) => {
    if (stage.conversationIndexStart === index && stage.annotationId === 'temp') {
      tempStartTags.push(stage);
    }
  });

  // Filter out predicted tags that are already present
  const dedupedMessagePredictedTags = messagePredictedTags.filter((predictedTag) => {
    const isStageBegin = predictedTag.tagType === TagTagType.STAGE_BEGIN;
    const isStageEnd = predictedTag.tagType === TagTagType.STAGE_END;
    if (isStageBegin) {
      return !tempStartTags.some((stage) => stage.stageId === predictedTag.value);
    } else if (isStageEnd) {
      return !stageEndTags.some((stage) => stage.stageId === predictedTag.value);
    } else {
      return !tags.some((tag) => tag.value === predictedTag.value);
    }
  });

  return (
    <Stack
      data-active={isActive}
      className={classes.wrapper}
      p="xs"
      align={alignRight ? 'flex-end' : 'flex-start'}
      onClick={handleMessageClick}
    >
      <Group
        key={message.v2MessageId}
        spacing="xs"
        style={{
          maxWidth: 402,
          flexDirection: alignRight ? 'row-reverse' : 'row',
        }}
      >
        <div
          style={{
            background: bg,
            color,
            alignSelf: 'flex-end',
            height: 24,
            width: 24,
            fontSize: 12,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          title={message.actorType}
        >{letterAvatar}
        </div>
        <Stack style={{ flex: 1 }} align={alignRight ? 'flex-end' : 'flex-start'} spacing={2}>
          <Box
            mb={2}
            p="sm"
            bg={bg}
            sx={(theme) => ({
              fontSize: theme.fontSizes.sm,
              color,
              borderRadius: alignRight ? '12px 12px 4px 12px' : '12px 12px 12px 4px',
            })}
          >
            {message.messageContent}
          </Box>
          <Group className="tag-container" spacing={2} style={{ flexDirection: alignRight ? 'row-reverse' : 'row' }}>
            {
              tempStartTags.map((stage) => (
                <Tooltip label="Cancel Stage Tagging" withArrow key={stage.annotationId}>
                  <div>
                    <ManualAnalysisTag
                      key={stage.annotationId}
                      tagType={TagTagType.STAGE_BEGIN}
                      isCloseable
                      onClick={() => resetStageTagging()}
                    >
                      {`${stage.stageName} - Start`}
                    </ManualAnalysisTag>
                  </div>
                </Tooltip>
              ))
            }
            {
              stageEndTags.map((stage) => (
                <Tooltip label="Update End Tag Message" withArrow key={stage.annotationId}>
                  <div>
                    <ManualAnalysisTag
                      key={stage.annotationId}
                      tagType={TagTagType.STAGE_BEGIN}
                      isCloseable
                      onClick={() => onRemoveStageEnd(stage)}
                    >
                      {`${stage.stageName} - End`}
                    </ManualAnalysisTag>
                  </div>
                </Tooltip>
              ))
            }
            {[...sortBy(tags, (el) => (el.tagType === TagTagType.STAGE ? 0 : 1))].map((tag, i) => {
              const content = <ManualAnalysisTag key={`${tag.value}:${i}`} tagType={tag.tagType} isCloseable onClick={() => onRemoveTagFromMessage(tag.value, index)}>{tag.label}</ManualAnalysisTag>;
              if (tag.tagType === TagTagType.STAGE) {
                return (
                  <Tooltip label="Untags entire stage" color="red" withArrow key={tag.value}>
                    <div>
                      {content}
                    </div>
                  </Tooltip>
                );
              } else {
                return <div key={tag.value}>{content}</div>;
              }
            })}
            <Group spacing={2}>
              {dedupedMessagePredictedTags.map((tag, i) => {
                let { label } = tag;
                if (tag.tagType === TagTagType.STAGE_BEGIN) {
                  label += ' - Start';
                } else if (tag.tagType === TagTagType.STAGE_END) {
                  label += ' - End';
                }
                return (
                  <div
                    key={`${tag.value}:${i}`}
                    className={classNames([classes.prediction, {
                      [classes.predictionSaving]: savingPrediction === tag.value,
                    }])}
                  >
                    <ManualAnalysisTag
                      onClick={() => {
                        onPredictionTagClick(tag);
                        if (setSavingPrediction) {
                          setSavingPrediction(tag.value);
                        }
                      }}
                      tagType={tag.tagType}
                    >{label}
                    </ManualAnalysisTag>
                  </div>
                );
              })}
            </Group>
          </Group>
        </Stack>
      </Group>
    </Stack>
  );
};
