import React, { useContext, useEffect, useState } from 'react';
import { Button, Center, Divider, Group, Stack, Text, Title } from '@mantine/core';
import './TaggingPage.scss';
import { Message as MessageType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Concept, ConceptConceptType, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { PageContainer } from 'components/PageContainer';
import { ChatWindowTopper } from 'components/ChatWindowTopper';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { showNotification } from '@mantine/notifications';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectConceptsFactory } from 'store/concept/selectors';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { getId } from 'common/resourceName';
import { getConcept } from 'store/concept/asyncThunks';
import { Message } from './components/Message';
import { ChatBox } from './components/ChatBox';
import { FooterWidget } from './components/FooterWidget';
import { TagSelection } from './components/TagSelection';
import { UITagType } from '.';
import { Notes } from './components/Notes';
import { TaggingPageContext } from './TaggingPageProvider';
import { handleNewAnnotations, TagData } from './utils/handleAnnotations';
import { StagesContext } from './StagesProvider';

export interface TagAnnotation {
  tagType: TagTagType,
  label: string,
  value: string,
}

export interface PredictedTag extends TagAnnotation {
  messageId: string;
}

export interface SetType {
  index: number
  id: string
  title: string
  conversationCount: number
  conversationIndex: number
  conversationIndexInTask: number
}

export interface MessageData {
  taskName: string,
  setId: string,
  conversationPositionNumber: number,
  conversationId: string;
  messageId: string;
}

interface TaggingPageProps {
  conversationAnnotations: {
    messageId: string,
    conceptIds: string[],
  }[],
  predictedTags: PredictedTag[],
  tagsMap: Map<string, UITagType>,
  isComplete: boolean
  isLoading: boolean
  messages: MessageType[]
  handleMessageClick: (index: number) => void
  handleBackBtnClick: () => void
  handleNextBtnClick: () => void
  onRemoveTagFromMessage: (tagId: string) => void
  onFinishLaterBtnClick: () => void
  onDoneBtnClick: () => void
  setData: SetType[]
  handleSwitchSet: (setId: string) => void
  isBackBtnDisabled: boolean
  isNextBtnDisabled: boolean
  isLastConversationInSet: boolean
  conversationName: string
  activeConversationIndex: number
}

export const TaggingPage = (props: TaggingPageProps) => {
  const {
    conversationAnnotations,
    predictedTags,
    tagsMap,
    isComplete,
    isLoading,
    messages,
    handleMessageClick,
    handleBackBtnClick,
    handleNextBtnClick,
    onRemoveTagFromMessage,
    onFinishLaterBtnClick,
    onDoneBtnClick,
    setData,
    handleSwitchSet,
    isBackBtnDisabled,
    isNextBtnDisabled,
    isLastConversationInSet,
    conversationName,
    activeConversationIndex,
  } = props;

  const tagsInitializing = tagsMap.size === 0;
  const tagConcepts = useSelector(selectConceptsFactory(ConceptConceptType.TAG));
  const customerProfile = useCustomerProfile();
  const dispatch = useDispatch();

  // To show pulsating animation for saving prediction
  const [savingPrediction, setSavingPrediction] = useState<string>();

  const {
    handleCreateAnnotationResponse,
    taskName,
    taskConceptIds,
    activeConversationId,
    activeSetId,
    onUpdateTagIdsInTask,
    tagsInProfile,
    activeMessage,
  } = useContext(TaggingPageContext);

  const {
    handleStageBeginSelection,
    handleStageEndSelection,
  } = useContext(StagesContext);

  useEffect(() => {
    // Reset prediction saving state
    setSavingPrediction(null);
  }, [predictedTags]);

  const getConceptById = async (id: string) => {
    const concept = tagConcepts.find((concept) => getId('concept', concept.name) === id);
    if (!concept) {
      // if no tag found, fetch from server
      const conceptName = `${customerProfile}/concepts/${id}`;
      const res = await dispatch(getConcept({ name: conceptName }));
      const fetchedConcept = res.payload as Concept;
      return fetchedConcept;
    }
    return concept;
  };

  const getStageEndTagIdFromStageBeginConceptTitle = (conceptTitle: string) => {
    // split string by '.' and remove last element
    const stageEndTagLabel = `${conceptTitle.split('.').slice(0, -1).join('.')}.end`;
    const stageEndTag = tagsInProfile.find((t) => t.label === stageEndTagLabel);
    if (stageEndTag) {
      return stageEndTag.value;
    } else {
      console.error(`Stage end tag not found for ${conceptTitle}`);
      return null;
    }
  };

  const addTagIdToMessage = async (tagId: string, messageData: MessageData, createdTags?: TagData[]) => {
    const { taskName, setId, conversationId, conversationPositionNumber, messageId } = messageData;
    if (createdTags) {
      try {
        const newAnnotationsData = {
          taskName,
          setId,
          tags: createdTags,
          conversationId,
          messageId,
          conversationPositionNumber,
        };
        const res = await handleNewAnnotations(newAnnotationsData);

        showNotification({
          color: 'green',
          message: 'Tag added to message',
        });
        handleCreateAnnotationResponse(res.upsertedAnnotations);
      } catch (err) {
        console.error(err);
        throw err;
      }
    } else {
      const concept = await getConceptById(tagId);
      const tags = [{
        id: tagId,
        title: concept.conceptTitle,
        type: concept.tag.tagType,
      }];

      // handle stages
      if (concept.tag.tagType === TagTagType.STAGE_BEGIN) {
        const stageEndTagId = getStageEndTagIdFromStageBeginConceptTitle(concept.conceptTitle);
        if (!stageEndTagId) {
          console.warn('No stage end tag found for tag', concept);
        } else {
          const stageEndTagConcept = await getConceptById(stageEndTagId);
          const stageEndTagTitle = stageEndTagConcept.conceptTitle;
          const stageEndTagData = {
            id: stageEndTagId,
            title: stageEndTagTitle,
            type: stageEndTagConcept.tag.tagType,
          };
          tags.push(stageEndTagData);
        }
      }
      try {
        const newAnnotationsData = {
          taskName,
          setId,
          tags,
          conversationId,
          messageId,
          conversationPositionNumber,
        };
        const res = await handleNewAnnotations(newAnnotationsData);
        showNotification({
          color: 'green',
          message: 'Tag added to message',
        });
        handleCreateAnnotationResponse(res.upsertedAnnotations);
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  };

  const updateTagsInTask = async (conceptIds: string[]) => {
    try {
      await LabelingTaskApi.modifyTaskConcepts({
        name: taskName,
        conceptIds,
      });
      onUpdateTagIdsInTask(conceptIds);
      showNotification({
        color: 'green',
        message: 'Tags in task successfully updated',
      });
    } catch (err) {
      console.error(err);
      showNotification({
        color: 'red',
        message: 'Error updating tags in task',
      });
    }
  };

  const addTagIdsToTask = async (tagIds: string[]) => {
    const newConceptIds = [...taskConceptIds, ...tagIds];
    await updateTagsInTask(newConceptIds);
  };

  const handleAddExistingTagId = async (tagId: string) => {
    // add concept to task
    await addTagIdsToTask([tagId]);
    // create annotation for current task
    await addTagIdToMessage(
      tagId,
      {
        taskName,
        setId: activeSetId,
        conversationId: activeConversationId,
        conversationPositionNumber: activeConversationIndex,
        messageId: activeMessage.id,
      },
    );
  };

  const onPredictionTagClick = (tag: PredictedTag) => {
    const message = messages[activeMessage.index];
    if (message && tag.tagType === TagTagType.STAGE_BEGIN) {
      handleStageBeginSelection(tag.value, message.v2MessageId);
    } else if (message && tag.tagType === TagTagType.STAGE_END) {
      handleStageEndSelection(activeMessage.index);
    } else {
      handleAddExistingTagId(tag.value);
    }
  };

  return (
    <PageContainer className="analysis-workshop-container">
      <Title>Conversations</Title>
      <div className="content">
        <div className="primary-content">
          {!isLoading && messages.length === 0 ? (
            <Center className="chatbox-header">
              <Stack>
                <Text color="red">Conversation has no messages to display</Text>
              </Stack>
            </Center>
          ) : <ChatWindowTopper className="chatbox-header" conversationName={conversationName} isLoading={isLoading} />}
          <ChatBox
            isLoading={tagsInitializing}
            messages={messages}
            scrollPosition="top"
            MessageComponent={({ message, index }) => (
              <Message
                conversationAnnotations={conversationAnnotations}
                predictedTags={predictedTags}
                tagsMap={tagsMap}
                key={message.v2MessageId}
                index={index}
                message={message}
                onRemoveTagFromMessage={isComplete ? () => null : onRemoveTagFromMessage}
                onPredictionTagClick={onPredictionTagClick}
                isActive={index === activeMessage.index}
                handleMessageClick={() => handleMessageClick(index)}
                savingPrediction={savingPrediction}
                setSavingPrediction={setSavingPrediction}
              />
            )}
          />
        </div>
        <Stack className="secondary-content" style={{ position: 'relative' }}>
          <TagSelection
            handleAddExistingTagId={handleAddExistingTagId}
            getConceptById={getConceptById}
            getStageEndTagIdFromStageBeginConceptTitle={getStageEndTagIdFromStageBeginConceptTitle}
            updateTagsInTask={updateTagsInTask}
            addTagIdsToTask={addTagIdsToTask}
            addTagIdToMessage={addTagIdToMessage}
          />
          <Divider mt="sm" />
          <Notes isComplete={isComplete} />
          <Group grow>
            <Button variant="light" onClick={handleBackBtnClick} disabled={isBackBtnDisabled}>Back</Button>
            <Button variant={isLastConversationInSet ? 'filled' : 'light'} onClick={handleNextBtnClick} disabled={isNextBtnDisabled}>{isLastConversationInSet ? 'Next Set' : 'Next'}</Button>
          </Group>
        </Stack>
      </div>
      <div className="page-footer">
        <div className="stepper-container">
          <FooterWidget activeConversationIndex={activeConversationIndex} setData={setData} handleSwitchSet={handleSwitchSet} />
        </div>
        <Button variant="subtle" onClick={onFinishLaterBtnClick}>{isComplete ? 'Back to Analysis Workshop' : 'Finish later'}</Button>
        <Button onClick={onDoneBtnClick} data-cy="tagging-page-done-btn">{isComplete ? 'Back to Results' : 'Done'}</Button>
      </div>
    </PageContainer>
  );
};
