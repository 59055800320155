import React, { Key, useState } from 'react';
import { Alert, Button, Collapse, Group, Modal, Stack, Text } from '@mantine/core';
import { DataGrid } from 'components/DataGrid';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    tableData: {
        conceptId: string;
        intent: string;
        f1: number;
    }[];
    onConfirm: (selectedConceptIds: string[]) => Promise<{
        success: boolean;
        message: string;
    }>;
}

export const ConfirmationModal = ({ isOpen, onClose: onCloseModal, tableData, onConfirm }: Props) => {
  const initiallySelectedIntents: Key[] = tableData.filter((row) => row.f1 >= 0.7).map((readyRow) => tableData.findIndex((row) => row.intent === readyRow.intent));
  const [selectedIntents, setSelectedIntents] = useState(initiallySelectedIntents);
  const [error, setError] = useState<string | null>(null);
  const [isPending, togglePending] = useState(false);

  const selectedConceptIds = selectedIntents.map((selectedIntentIndex) => tableData[selectedIntentIndex].conceptId);

  const onClose = () => {
    togglePending(false);
    setError(null);
    onCloseModal();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmAndContinue = async () => {
    togglePending(true);
    const res = await onConfirm(selectedConceptIds);
    if (res.success) {
      onClose();
    } else {
      setError(res.message);
    }
    togglePending(false);
  };

  return (
    <Modal
      centered
      title="Run QA task with these intents"
      opened={isOpen}
      onClose={onClose}
    >
      <Stack>
        <div>
          <Text size="xs" color="dimmed" mb={5}>{`${selectedIntents.length} intents selected`}</Text>
          <DataGrid
            tableProps={{
              fontSize: 'xs',
              verticalSpacing: 5,
              withBorder: true,
            }}
            rowSelection={{
              selectedRowKeys: selectedIntents,
              onChange: setSelectedIntents,
            }}
            dataSource={tableData}
            columns={[
              {
                header: 'Intents',
                accessorKey: 'intent',
              },
              {
                header: 'F1',
                accessorKey: 'f1',
                cell: (row) => {
                  const value = row.getValue().toFixed(2);
                  return (
                    <Text color={value < 0.7 ? 'red' : undefined}>
                      {value}
                    </Text>
                  );
                },
              },
            ]}
          />
        </div>
        <Collapse in={!!error}>
          <Alert
            color="red"
            title="Error Creating Misclassification QA Task"
          >{error}
          </Alert>
        </Collapse>
        <Group>
          {/* <Tooltip label="Coming soon" position="top"> */}
          <Button
            loading={isPending}
            onClick={onConfirmAndContinue}
          >Confirm and continue
          </Button>
          {/* </Tooltip> */}
          <Button variant="subtle" onClick={onClose}>Cancel</Button>
        </Group>
      </Stack>
    </Modal>
  );
};
