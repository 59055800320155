import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { ActionIcon, Group, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

interface VATestCaseProps {
  title: string;
  onRun: () => void;
  onDelete: () => void;
  status: string;
}

export default function VATestCase({
  title,
  onRun,
  onDelete,
  status,
}: VATestCaseProps) {
  const theme = useMantineTheme();
  return (
    <Group
      position="right"
      px="lg"
      py={4}
      spacing={0}
      style={{
        borderTop: `1px solid ${theme.colors.gray[0]}`,
      }}
    >
      <Text mr="auto" size="sm">{title}</Text>
      <ActionIcon color="blue">
        <PlayCircleOutlined />
      </ActionIcon>
      <ActionIcon>
        <DeleteOutlined />
      </ActionIcon>
    </Group>
  );
}
