import { DerivedLabelingTask, LabelingTask, MessageLabelingViewData } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SerializedError } from '@reduxjs/toolkit';
import { ApiStatus } from 'store/types';
import { AnnotationValueCountEntry } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { TaskProgress } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/task_progress.pb';

/** Labeling task state. */
export interface State {
  // DerivedLabelingTask.labeling_task.name to DerivedLabelingTask.
  derivedLabelingTasks: DerivedLabelingTask[],
  fetchingTasks: boolean,
  currentLabelingTask: LabelingTask,
  currentTaskProgress: TaskProgress,
  conversationLabelingItems: MessageLabelingViewData,
  taskTemporalAnnotationValueCounts: AnnotationValueCountEntry[],
  // Annotation.name to Annotation.
  temporalAnnotations: Annotation[],
  predictions: Prediction[],
  annotationBundles: SearchAnnotationsResponseAnnotationBundle[],
  fetchingAnnotations: boolean,
  // Message ids
  failedToSaveMessages: {[id: string]: boolean},
  taskCompleted: boolean,
  status: ApiStatus,
  error: SerializedError,
  // Check is sampling more messages was already tried
  canSampleMore: boolean,
}

/** Initial user state. */
export const INITIAL_STATE: State = {
  derivedLabelingTasks: [],
  fetchingTasks: false,
  currentLabelingTask: null,
  currentTaskProgress: null,
  conversationLabelingItems: null,
  taskTemporalAnnotationValueCounts: null,
  temporalAnnotations: [],
  predictions: [],
  annotationBundles: [],
  fetchingAnnotations: false,
  failedToSaveMessages: {},
  taskCompleted: false,
  status: 'idle',
  error: null,
  canSampleMore: true,
};
