import { Anchor, Collapse } from '@mantine/core';
import React, { useState } from 'react';

export const ShowAdvanced = ({ children }) => {
  const [isShowingAdvanced, setIsShowingAdvanced] = useState(false);
  return (
    <>
      <Anchor onClick={() => setIsShowingAdvanced(!isShowingAdvanced)}>
        {isShowingAdvanced ? 'Hide advanced settings' : 'Show advanced settings'}
      </Anchor>
      <Collapse in={isShowingAdvanced}>
        {children}
      </Collapse>
    </>
  );
};
