import React from 'react';

export const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1085_24658)">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5ZM0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6ZM7.85355 4.14645C8.04882 4.34171 8.04882 4.65829 7.85355 4.85355L6.70711 6L7.85355 7.14645C8.04882 7.34171 8.04882 7.65829 7.85355 7.85355C7.65829 8.04882 7.34171 8.04882 7.14645 7.85355L6 6.70711L4.85355 7.85355C4.65829 8.04882 4.34171 8.04882 4.14645 7.85355C3.95118 7.65829 3.95118 7.34171 4.14645 7.14645L5.29289 6L4.14645 4.85355C3.95118 4.65829 3.95118 4.34171 4.14645 4.14645C4.34171 3.95118 4.65829 3.95118 4.85355 4.14645L6 5.29289L7.14645 4.14645C7.34171 3.95118 7.65829 3.95118 7.85355 4.14645Z" fill="#212121"/>
    </g>
    <defs>
      <clipPath id="clip0_1085_24658">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);
