import { Dropdown } from 'antd';
import React, { useRef, useState } from 'react';
import './ItemPicker.scss';

export interface ItemPickerProps<ItemType> {
  value: string | number;
  idKeyPath: string;
  renderItem: (item: ItemType) => React.ReactNode | string | number;
  filterKey: (item: ItemType) => string;
  items: ItemType[];
  placeholder: string;
  onChange: (item: ItemType) => void;
  children?: React.ReactNode | string | number;
}

export default function ItemPicker<ItemType>({
  idKeyPath,
  value,
  renderItem,
  filterKey,
  items,
  onChange,
  placeholder = 'Select',
  children,
}: ItemPickerProps<ItemType>) {
  const [searchString, setSearchString] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  const onSelect = (option: ItemType) => {
    onChange(option);
  };

  const filteredItems = items
    .filter((item) => {
      if (!searchString) {
        return item;
      }

      const filterValue = filterKey(item);
      return (
        filterValue
        && filterValue.toLowerCase().includes(searchString.toLowerCase())
      );
    });
  const menuItems = [
    {
      key: 'input',
      label: (
        <input
          ref={inputRef}
          value={searchString}
          className="item-picker-input"
          placeholder={placeholder}
          onChange={(event) => setSearchString(event.target.value)}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      ),
    },
    ...filteredItems.map((option, i) => ({
      // eslint-disable-next-line react/no-array-index-key
      key: `${option[idKeyPath]}:${i}`,
      label: (<div onClick={() => onSelect(option)}>{renderItem(option)}</div>),
    })),
  ];

  const item = items.find(
    (option) => option && String(option[idKeyPath]) === String(value),
  );
  return (
    <div className="item-picker">
      <Dropdown
        menu={{ items: menuItems }}
        trigger={['click']}
        overlayClassName="item-picker-dropdown"
        onOpenChange={(value) => {
          if (value) {
            setSearchString('');
            setTimeout(() => {
              inputRef?.current?.focus();
            }, 0);
          }
        }}
      >
        <div>
          {children || renderItem(item)}
        </div>
      </Dropdown>
    </div>
  );
}
