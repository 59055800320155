import { CheckOutlined } from '@ant-design/icons';
import { Stepper } from '@mantine/core';
import { ModelWizardProjectStep } from 'models';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  stepIndex: number;
  steps: ModelWizardProjectStep[];
  onStepClick: (stepIndex: number) => void;
}

export function ModelWizardProjectStepper(props: Props): React.ReactElement {
  const { stepIndex, onStepClick, steps } = props;
  return (
    <Stepper
      classNames={{
        root: styles.modelWizardProjectStepper,
        content: styles.content,
        steps: styles.steps,
        step: styles.step,
        stepIcon: styles.stepIcon,
        separator: styles.separator,
      }}
      iconSize={24}
      active={stepIndex}
      onStepClick={onStepClick}
    >
      {steps.map((step, index) => (
        <Stepper.Step
          key={step.label}
          label={step.label}
          description={step.description}
          completedIcon={<CheckOutlined />}
        >
          {step.children}
        </Stepper.Step>
      ))}
    </Stepper>
  );
}

export default ModelWizardProjectStepper;
