import {
  PromptsService,
  ListPromptsRequest,
  ListPromptsResponse,
  CreatePromptRequest,
  CreatePromptResponse,
  UpdatePromptRequest,
  UpdatePromptResponse,
  GetPromptRequest,
  GetPromptResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/prompt/prompt_service.pb';
import { StudioApi } from './studioApi';

export abstract class PromptApi {
  public static listPrompts(request: ListPromptsRequest): Promise<ListPromptsResponse> {
    return PromptsService.ListPrompts(request, StudioApi.getHeaders().initReq);
  }

  public static createPrompt(request: CreatePromptRequest): Promise<CreatePromptResponse> {
    return PromptsService.CreatePrompt(request, StudioApi.getHeaders().initReq);
  }

  public static updatePrompt(request: UpdatePromptRequest): Promise<UpdatePromptResponse> {
    return PromptsService.UpdatePrompt(request, StudioApi.getHeaders().initReq);
  }

  public static getPrompt(request: GetPromptRequest): Promise<GetPromptResponse> {
    return PromptsService.GetPrompt(request, StudioApi.getHeaders().initReq);
  }
}
