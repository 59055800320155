import { Dropdown, Select, Switch, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { CreatorCreatorType, DateRangeSelector } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import { showNotification } from '@mantine/notifications';
import {
  AbstractTask,
  TaskStatus,
  TaskVisibility,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import {
  ConceptType,
  DataSplit,
  DecisionType,
  DerivedLabelingTask,
  LabelingTask,
  LabelingViewType,
  SelectionInstruction,
  SelectionMethod,
  TargetType,
  UserLabelingTaskType,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { CreateTaskRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { getId, getParent } from 'common/resourceName';
import TaskTypeTag, { ExtendedTaskType, UnionTaskType } from 'components/TaskTypeTag';
import { Button, UnstyledButton, Text } from '@mantine/core';
import Loading from 'components/Loading';
import TaskStatusIndicator from 'components/TaskStatusIndicator';
import UserTag from 'components/UserTag';
import CopyableValue from 'components/CopyableValue';
import { CustomerParams, useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { UserContext } from 'context/UserContext';
import { ApiStatus } from 'store/types';
import { selectApiStatus, selectApiError, selectDerivedLabelingTasks } from 'store/labelingTask/selectors';
import { createLabelingTask, fetchLabelingTasks, updateAbstractTask } from 'store/labelingTask/asyncThunks';
import { selectUsers } from 'store/user/selectors';
import { User } from 'types';
import { enumCompareFnFactory } from 'utils';
import { SerializedError } from '@reduxjs/toolkit';
import { openNotification } from 'components/Notification';
import { selectAllConcepts, selectAllConceptsMapFactory } from 'store/concept/selectors';
import { Concept, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import useUrlParam from 'hooks/useUrlParam';
import ItemPicker from 'components/ItemPicker';
import MultiTags from 'components/MultiTags';
import { PageContainer } from 'components/PageContainer';
import { CustomIntentApi } from 'services/customIntentApi';
import NewTaskModal, { DEFAULT_TASK_DATA, RegexType } from './NewTaskModal';
import styles from './styles.module.scss';
import { ExpandIcon, MinusIcon, PlusIcon } from './icons';
import TaskConfigurationDrawer from './TaskConfigurationDrawer';
import { ArchiveTaskButton } from './ArchiveTaskButton';

type LabelingTasksProps = {
  children?: React.ReactNode | string | number;
};

enum ModalType {
  NEW_TASK = 'NEW_TASK',
}

const FILTERS_INTENT_TASK_TYPES: UnionTaskType[] = [
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_CALIBRATION,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_UNSPECIFIED,
  UserLabelingTaskType.AUTO_LABELING,
  ExtendedTaskType.TASK_TYPE_DUAL,
];

const FILTERS_ENTITY_TASK_TYPES: UserLabelingTaskType[] = [
  UserLabelingTaskType.LABELING_ENTITY,
];

const FILTERS_CLO_TASK_TYPES: UserLabelingTaskType[] = [
  UserLabelingTaskType.LABELING_SPARSE_CLO,
  UserLabelingTaskType.LABELING_DENSE_CLO,
];

const FILTERS_SUMMARIZATION_TASK_TYPES: UserLabelingTaskType[] = [
  UserLabelingTaskType.LABELING_SUMMARIZATION,
];

export const OPTIONS_CONCEPT_TYPES = [
  {
    value: ConceptType.INTENT,
    text: 'Intent',
  },
  {
    value: ConceptType.ENTITY,
    text: 'Entity',
  },
  {
    value: ConceptType.CONVERSATION_OUTCOME,
    text: 'Conversation Outcome',
  },
  {
    value: ConceptType.SUMMARIZATION,
    text: 'Summarization',
  },
];

export interface NewTaskData {
  conceptType: ConceptType;
  taskType: UserLabelingTaskType;
  regexExpression: string;
  regexType: RegexType;
  dual: boolean;
  conceptIds: string[];
  positiveSeeds: string[];
  negativeSeeds: string[];
  chatCount: number;
  minConversationLength: number;
  numMessages: number;
  split: DataSplit;
  dateRangeSelector: DateRangeSelector;
  messageSetIds: string[];
  conversationSetIds: string[];
}

// The table shows reference to tasks "parent" in case the task is calibration or dual
interface DerivedLabelingTaskRow extends Omit<DerivedLabelingTask, 'userLabelingTaskType'> {
  userLabelingTaskType?: UnionTaskType;
  isChild?: boolean;
  children?: DerivedLabelingTaskRow[];
}

function newTaskRequest(customerProfile: string, data: NewTaskData, userId: string, customer: CustomerParams): CreateTaskRequest {
  const {
    conceptType,
    taskType,
    regexExpression,
    positiveSeeds,
    negativeSeeds,
    dual,
    conceptIds,
    chatCount,
    minConversationLength,
    numMessages,
    split,
    dateRangeSelector,
    messageSetIds,
    conversationSetIds,
  }: NewTaskData = data;

  // Default values that will be set and populated based
  // on selected labeling type
  let selectionInstruction: SelectionInstruction;
  let allowMultiRoundLabeling = false;
  let decisionType = conceptType === ConceptType.ENTITY ? DecisionType.DECISION_TYPE_ENTITY_LIKE : DecisionType.DECISION_TYPE_SINGLE_BINARY;
  let viewType = LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_PARTIAL_CONVERSATION_CONTEXT;

  switch (taskType) {
    case UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING:
      selectionInstruction = {
        selectionMethod: SelectionMethod.DYNAMIC_LEGACY,
        dynamicLegacySelectionInstruction: {
          dateRangeSelector,
          positiveSeedExamples: positiveSeeds,
          negativeSeedExamples: negativeSeeds,
          initialSelectCount: 20,
        },
      };
      allowMultiRoundLabeling = true;
      break;
    case UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING:
      selectionInstruction = {
        selectionMethod: SelectionMethod.REGEX,
        regexSelectionInstruction: {
          numMessages,
          messageSetIds,
        },
      };
      break;
    case UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING:
      selectionInstruction = {
        selectionMethod: SelectionMethod.RANDOM,
        randomSelectionInstruction: {
          chatCount,
          minConversationLength,
          conversationSetIds,
        },
      };

      decisionType = DecisionType.DECISION_TYPE_MULTIPLE_BINARY;
      break;
    case UserLabelingTaskType.LABELING_SUMMARIZATION:
      selectionInstruction = {
        selectionMethod: SelectionMethod.SUMMARIZATION_SAMPLING,
        summarizationInstruction: {
          dateRangeSelector,
          minConversationLength,
          conversationCount: chatCount,
          // Hardcoded uri because it cannot be empty
          modelUris: ['cox-voice/summarization:test1'],
        },
      };
      decisionType = DecisionType.DECISION_TYPE_WRITE_TEXT;
      viewType = LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_FULL_CONVERSATION_CONTEXT;
      if (regexExpression) {
        selectionInstruction.summarizationInstruction.regexExpression = regexExpression;
      }
      break;
    default:
      break;
  }

  // Regex can only be used for some task types
  const regexTaskTypes: UserLabelingTaskType[] = [
    UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  ];
  if (regexExpression && regexTaskTypes.includes(taskType)) {
    selectionInstruction = {
      selectionMethod: SelectionMethod.REGEX,
      regexSelectionInstruction: {
        regexExpression,
        numMessages,
        minConversationLength,
      },
    };
  }

  const taskId = uuid();
  return {
    name: `${customerProfile}/labelingTasks/${taskId}`,
    commonInput: {
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
      workflowId: 'default-labeling-workflow',
      title: `Labeling Task ${taskId}`,
      assigneeUserId: userId,
      creator: {
        creatorType: CreatorCreatorType.UI_ACTION,
        userId,
      },
    },
    taskDescriptor: {
      split,
      conceptIds,
      viewType,
      decisionType,
      targetType: TargetType.LABELING,
      selectionInstruction,
      allowMultiRoundLabeling,
      showLabelSuggestion: false,
      dualTaskId: dual ? uuid() : undefined,
      conceptType,
    },
  };
}

export default function LabelingTasks({ children }: LabelingTasksProps) {
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode } = customer;
  const navigate = useNavigate();
  const currentUser = useContext(UserContext);
  const currentUserId = currentUser?.id;
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [drawerModal, setDrawerModal] = useState<DerivedLabelingTaskRow | undefined>();
  const [taskData, setTaskData] = useState<NewTaskData>();
  const dispatch = useDispatch();
  const apiError = useSelector<SerializedError>(selectApiError);
  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);

  const handleTaskTypeChange = useCallback(async () => {
    switch (taskData?.taskType) {
      case UserLabelingTaskType.AUTO_LABELING: {
        // Check data availability and warm up ES cache.
        const resp = await CustomIntentApi.checkDataAvailability({ profile: customerProfile, usecaseId, languageCode });
        if (!resp.hasData) {
          showNotification({
            color: 'red',
            title: 'Error',
            message: 'No data available for auto labeling',
          });
          return;
        }
        await CustomIntentApi.warmupElasticSearch({ profile: customerProfile, usecaseId, languageCode });
        break;
      }
      default:
    }
  }, [taskData?.taskType, customerProfile, usecaseId, languageCode]);

  // Task type change.
  useEffect(() => {
    handleTaskTypeChange();
  }, [taskData?.taskType]);

  // TODO(STU-2963): Push this selector down to the user picker.
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const stringifiedValues = window.sessionStorage.getItem('taskLabelingFilters');
  const filterFromSessionStorage: {
    conceptType: string,
    taskTypes: UnionTaskType[],
    intent: string,
    assigneeUserId: string,
    status: TaskStatus[],
    dataSplit: DataSplit,
    showArchivedTasks: string,
    taskIds: string[],
  } = stringifiedValues ? JSON.parse(stringifiedValues) : {};

  // Filter params.
  const [conceptType, setConceptType] = useUrlParam<string>('conceptType', filterFromSessionStorage.conceptType || ConceptType.INTENT);
  const [taskTypes, setTaskTypes] = useUrlParam<UnionTaskType[]>('taskType', filterFromSessionStorage.taskTypes);
  const [intent, setIntent] = useUrlParam<string>('intent', filterFromSessionStorage.intent);
  const [assigneeUserId, setAssigneeUserId] = useUrlParam<string>('assignee', filterFromSessionStorage.assigneeUserId);
  const [status, setStatus] = useUrlParam<TaskStatus[]>('status', filterFromSessionStorage.status);
  const [dataSplit, setDataSplit] = useUrlParam<DataSplit>('split', filterFromSessionStorage.dataSplit);
  const [showArchivedTasks, setShowArchivedTasks] = useUrlParam<string>('archived', filterFromSessionStorage.showArchivedTasks);
  const [taskIds, setTaskIds] = useUrlParam<string[]>('taskIds', filterFromSessionStorage.taskIds);

  const derivedLabelingTasks = useSelector<DerivedLabelingTask[]>(selectDerivedLabelingTasks);

  // utilize session storage to preserve filter state between renders
  useEffect(() => {
    const filters = {
      conceptType,
      taskTypes,
      intent,
      assigneeUserId,
      status,
      dataSplit,
      showArchivedTasks,
      taskIds,
    };
    window.sessionStorage.setItem('taskLabelingFilters', JSON.stringify(filters));
  }, [conceptType, taskTypes, intent, assigneeUserId, status, dataSplit, showArchivedTasks, taskIds]);

  const taskRows: DerivedLabelingTaskRow[] = useMemo(() => {
    // Map of <dual / calib / original task id, original task id>
    const originalTaskRefMap = new Map<string, string>();
    for (const task of derivedLabelingTasks) {
      const taskId = getId('labelingTask', task.labelingTask.name);
      // FIXME(Matus): Don't rely on 'calibra-' to get original task id
      const splitTaskId = taskId.split('calibra-');
      if (splitTaskId.length === 2) {
        const origTaskId = splitTaskId.pop();
        originalTaskRefMap.set(taskId, origTaskId);
      }

      if (task.labelingTask.taskData.taskDescriptor.dualTaskId) {
        originalTaskRefMap.set(task.labelingTask.taskData.taskDescriptor.dualTaskId, taskId);
        originalTaskRefMap.set(taskId, taskId);
      }
    }

    const taskRowsMap = new Map<string, DerivedLabelingTaskRow>();
    for (const task of derivedLabelingTasks) {
      const taskId = getId('labelingTask', task.labelingTask.name);
      // Whether the task belongs to a dual task set.
      if (originalTaskRefMap.has(taskId)) {
        const dualTaskId = `dual-${originalTaskRefMap.get(taskId)}`;
        if (!taskRowsMap.has(dualTaskId)) {
          taskRowsMap.set(dualTaskId, {
            userLabelingTaskType: ExtendedTaskType.TASK_TYPE_DUAL,
            labelingTask: {
              name: `${getParent('labelingTask', task.labelingTask.name)}/labelingTasks/${dualTaskId}`,
              abstractTask: {
                createTime: task.labelingTask?.abstractTask?.createTime,
                assigneeUserId: task.labelingTask?.abstractTask?.assigneeUserId,
              },
              taskData: {
                taskDescriptor: {
                  conceptType: task.labelingTask?.taskData?.taskDescriptor?.conceptType,
                  conceptIds: task.labelingTask?.taskData?.taskDescriptor?.conceptIds,
                  split: task.labelingTask?.taskData?.taskDescriptor?.split,
                },
              },
            },
            children: [],
          });
        }
        const parent = taskRowsMap.get(dualTaskId);
        parent.children.push({ ...task, isChild: true });
        if (Date.parse(task.labelingTask?.abstractTask?.createTime) > Date.parse(parent.labelingTask?.abstractTask?.createTime)) {
          parent.labelingTask.abstractTask.createTime = task.labelingTask?.abstractTask?.createTime;
        }
      } else {
        taskRowsMap.set(taskId, task);
      }
    }
    return Array.from(taskRowsMap.values()).sort(
      (a, b) => Date.parse(b.labelingTask.abstractTask.createTime) - Date.parse(a.labelingTask.abstractTask.createTime),
    );
  }, [derivedLabelingTasks]);

  const filteredTaskRows: DerivedLabelingTaskRow[] = useMemo(() => taskRows.filter((row: DerivedLabelingTaskRow) => {
    const { labelingTask: { taskData: { taskDescriptor }, abstractTask: { assigneeUserId: taskAssigneeUserId, status: taskStatus, visibility } }, children } = row;

    if (conceptType != null) {
      // TODO(Matus): Remove UNSPECIFIED type when no longer needed
      if (taskDescriptor.conceptType !== ConceptType.CONCEPT_TYPE_UNSPECIFIED && taskDescriptor.conceptType !== conceptType) return false;
    }
    if (taskTypes?.length > 0 && !taskTypes?.includes(row.userLabelingTaskType) && !children?.some((t) => taskTypes?.includes(t.userLabelingTaskType))) {
      return false;
    }
    if (
      intent != null
      && !taskDescriptor.conceptIds.includes(intent)
    ) {
      return false;
    }
    if (dataSplit != null && taskDescriptor.split !== dataSplit) {
      return false;
    }

    if (assigneeUserId) {
      if (children) {
        // Handle grouped tasks here
        if (assigneeUserId === 'Unassigned') {
          const areChildrenTasksAssigned = children.every((t) => t.labelingTask?.abstractTask?.assigneeUserId);
          if (areChildrenTasksAssigned) return false;
        } else {
          const areChildrenTasksNotAssigned = children.every((t) => t.labelingTask?.abstractTask?.assigneeUserId !== assigneeUserId);
          if (areChildrenTasksNotAssigned) return false;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (assigneeUserId === 'Unassigned' && taskAssigneeUserId) {
          return false;
        } else if (assigneeUserId && taskAssigneeUserId !== assigneeUserId) {
          return false;
        }
      }
    }
    if (status?.length && !status.includes(taskStatus) && !children?.some((t) => status.includes(t.labelingTask?.abstractTask?.status))) {
      return false;
    }

    if (taskIds?.length > 0) {
      if (children) {
        const someChildrenIncludeTaskId = children.some((t) => taskIds.includes(getId('labelingTask', t.labelingTask.name)));
        if (!someChildrenIncludeTaskId) return false;
      } else {
        const taskId = getId('labelingTask', row.labelingTask.name);
        if (!taskIds.includes(taskId)) return false;
      }
    }

    if (showArchivedTasks === 'true') {
      return true;
    }

    if (children) {
      return children.every((t) => t.labelingTask?.abstractTask?.visibility !== TaskVisibility.INVISIBLE);
    }

    return visibility !== TaskVisibility.INVISIBLE;
  }), [taskRows, dataSplit, intent, conceptType, taskTypes, status, assigneeUserId, showArchivedTasks, taskIds]);

  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const concepts = useSelector<Concept[]>(selectAllConcepts);

  useEffect(() => {
    if (apiError != null) {
      openNotification('error', 'Tasks API failed', undefined, apiError);
    }
  }, [apiError]);

  const taskStatusValues: TaskStatus[] = Object.values(TaskStatus);

  useEffect(() => {
    dispatch(fetchLabelingTasks({
      parent: customerProfile,
      usecaseId: customer.usecaseId,
      languageCode: customer.languageCode,
    }));
  }, []);

  const updateAssignee = useCallback(
    (abstractTask: AbstractTask) => dispatch(updateAbstractTask({ abstractTask, updateMask: 'abstractTask.assigneeUserId' })),
    [dispatch],
  );

  const filterTaskTypes = useMemo(() => {
    switch (conceptType) {
      case ConceptType.INTENT:
        return FILTERS_INTENT_TASK_TYPES;
      case ConceptType.ENTITY:
        return FILTERS_ENTITY_TASK_TYPES;
      case ConceptType.CONVERSATION_OUTCOME:
        return FILTERS_CLO_TASK_TYPES;
      case ConceptType.SUMMARIZATION:
        return FILTERS_SUMMARIZATION_TASK_TYPES;
      default:
        return [];
    }
  }, [conceptType]);

  const createNewTask = useCallback((data: NewTaskData) => {
    dispatch(createLabelingTask(newTaskRequest(customerProfile, data, String(currentUserId), customer)));
    setOpenModal(null);
  }, [dispatch, currentUserId]);

  const startTaskHelper = (labelingTask: LabelingTask, taskUrl: string, reassignTask: boolean) => {
    if (reassignTask) {
      updateAssignee({
        ...labelingTask.abstractTask,
        assigneeUserId: currentUserId,
      });
    }

    navigate(taskUrl);
  };

  const renderTaskAction = (derivedTask: DerivedLabelingTaskRow) => {
    if (!derivedTask || derivedTask.children?.length) return <span />;
    if (derivedTask.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED) {
      const concepts = derivedTask.labelingTask.taskData.taskDescriptor.conceptIds.map((id) => allConceptsMap.get(id));
      const { split } = derivedTask.labelingTask.taskData.taskDescriptor;
      // Dual labeling task labels are aggregated in the calibration task.
      if (derivedTask.labelingTask.taskData.taskDescriptor.dualTaskId
        || derivedTask.labelingTask.taskData.taskDescriptor.selectionInstruction?.selectionMethod === SelectionMethod.DUAL) {
        return (
          <Tooltip title="See labels in the corresponding calibration task.">
            <InfoCircleOutlined />
          </Tooltip>
        );
      }
      let link = concepts.length > 1
        ? '../../annotations'
        : `../../annotations/${getId('concept', concepts[0]?.name)}?conceptType=${derivedTask.labelingTask.taskData.taskDescriptor.conceptType}&split=${split}&taskId=${getId('labelingTask', derivedTask.labelingTask.name)}&target=LABELING`;

      if (derivedTask.userLabelingTaskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_CALIBRATION) {
        link = `../calibration/${getId('labelingTask', derivedTask.labelingTask.name)}`;
      }

      if (derivedTask.userLabelingTaskType === UserLabelingTaskType.LABELING_SUMMARIZATION) {
        link = `../../qa/score-view/${getId('labelingTask', derivedTask.labelingTask.name)}`;
      }
      return (
        <span>
          <NavLink
            to={link}
          >
            See labels
          </NavLink>
        </span>
      );
    }

    const isTaskAssigneeCurrentUser = derivedTask?.labelingTask?.abstractTask.assigneeUserId === currentUserId;
    const taskUrl = `../view/${getId('labelingTask', derivedTask.labelingTask.name)}`;

    if ([TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(derivedTask.labelingTask.abstractTask.status)) {
      let doneCount = derivedTask.annotatedTargetCount;

      // Summarization gets these values from different source
      if (derivedTask.userLabelingTaskType === UserLabelingTaskType.LABELING_SUMMARIZATION) {
        doneCount = derivedTask.taskProgress.conversationSetProgresses[0]?.labeledSize;
      }

      if (doneCount > 0) {
        return (
          <span>
            <NavLink
              to={`${taskUrl}/${Math.min(derivedTask?.currentConversationNumber + 1, derivedTask?.totalConversationNumber - 1)}`}
            >
              Continue
            </NavLink>
          </span>
        );
      } else {
        return (
          <span>
            <NavLink
              data-cy="start-task-link"
              to="#"
              onClick={(event) => {
                event.preventDefault();
                // Reassign on start
                startTaskHelper(derivedTask.labelingTask, taskUrl, !isTaskAssigneeCurrentUser);
              }}
            >
              Start
            </NavLink>
          </span>
        );
      }
    }

    return <span />;
  };

  // Grab task config and open a new task modal with the same config
  const duplicateTaskConfig = (derivedTask: DerivedLabelingTask) => {
    const taskDescriptor = derivedTask?.labelingTask.taskData.taskDescriptor;
    const taskData: NewTaskData = {
      conceptType: taskDescriptor.conceptType,
      taskType: derivedTask?.userLabelingTaskType,
      regexExpression: '',
      dual: !!taskDescriptor.dualTaskId,
      conceptIds: taskDescriptor.conceptIds || [],
      positiveSeeds: [],
      negativeSeeds: [],
      chatCount: 0,
      minConversationLength: 0,
      numMessages: 0,
      split: taskDescriptor.split,
      // Keywords are turned to regex in backend, so we choose regex as default
      // when populating this field from backend
      regexType: RegexType.REGULAR_EXPRESSION,
      dateRangeSelector: {
        after: null,
        before: null,
      },
      messageSetIds: [],
      conversationSetIds: [],
    };

    // Get data from one of the selection instruction objects
    Object.keys(taskDescriptor.selectionInstruction).forEach((key) => {
      const instruction = taskDescriptor.selectionInstruction[key];
      if (instruction) {
        taskData.dateRangeSelector = instruction.dateRangeSelector;
        taskData.regexExpression = instruction.regexExpression || '';
        taskData.chatCount = instruction.chatCount || DEFAULT_TASK_DATA.chatCount;
        taskData.numMessages = instruction.numMessages || DEFAULT_TASK_DATA.numMessages;
        taskData.minConversationLength = instruction.minConversationLength || DEFAULT_TASK_DATA.minConversationLength;
        taskData.positiveSeeds = instruction.positiveSeedExamples || [];
        taskData.negativeSeeds = instruction.negativeSeedExamples || [];
        taskData.messageSetIds = instruction.messageSetIds || [];
        taskData.conversationSetIds = instruction.conversationSetIds || [];
      }
    });

    setDrawerModal(undefined);
    setTaskData(taskData);
    setOpenModal(ModalType.NEW_TASK);
  };

  const columns: ColumnType<DerivedLabelingTaskRow>[] = [
    {
      title: 'Task ID',
      dataIndex: ['labelingTask', 'name'],
      key: 'name',
      sorter: (a, b) => {
        const valueA = getId('labelingTask', a.labelingTask.name);
        const valueB = getId('labelingTask', b.labelingTask.name);
        return valueA.localeCompare(valueB);
      },
      render: (value: string) => {
        const taskId = getId('labelingTask', value);
        return (
          <CopyableValue
            displayValue={`${taskId.substring(0, 4)}...`}
            copiedValue={taskId}
            tooltip={taskId}
          />
        );
      },
    },
    {
      title: 'Task Type',
      dataIndex: 'userLabelingTaskType',
      key: 'taskType',
      sorter: enumCompareFnFactory<UnionTaskType, DerivedLabelingTaskRow>(filterTaskTypes, (t) => t.userLabelingTaskType),
      render: (value, row) => {
        if (
          row.labelingTask.taskData.taskDescriptor.selectionInstruction?.selectionMethod === SelectionMethod.REGEX
        ) {
          return (
            <Tooltip
              title={
                row.labelingTask.taskData.taskDescriptor.selectionInstruction?.regexSelectionInstruction.regexExpression
              }
            >
              <span>
                <TaskTypeTag taskType={value} />
              </span>
            </Tooltip>
          );
        }
        return <TaskTypeTag taskType={value} />;
      },
    },
    {
      title: 'Title',
      dataIndex: ['labelingTask', 'taskData', 'taskDescriptor', 'conceptIds'],
      key: 'conceptIds',
      render: (value: string[], row) => (
        <MultiTags
          type={row.labelingTask.taskData.taskDescriptor.conceptType === ConceptType.ENTITY ? 'entity' : 'intent'}
          tags={value.map((conceptId) => {
            const concept = allConceptsMap.get(conceptId);
            return concept ? concept.conceptTitle : null;
          })}
        />
      ),
    },
    {
      title: 'Test/Train',
      dataIndex: ['labelingTask', 'taskData', 'taskDescriptor', 'split'],
      key: 'testTrain',
      sorter: enumCompareFnFactory<DataSplit, DerivedLabelingTaskRow>(Object.values(DataSplit), (t) => t.labelingTask.taskData.taskDescriptor.split),
    },
    {
      title: 'Assignee',
      dataIndex: ['labelingTask', 'abstractTask', 'assigneeUserId'],
      key: 'assignee',
      sorter: (a: DerivedLabelingTaskRow, b: DerivedLabelingTaskRow) => {
        const valueA = a.labelingTask.abstractTask.assigneeUserId || '';
        const valueB = b.labelingTask.abstractTask.assigneeUserId || '';
        return valueA.localeCompare(valueB);
      },
      render: (value: string, row) => {
        if (row.children?.length) {
          return (
            <div>
              <UserTag
                name={`${users.find((user) => user.id === row.children[0].labelingTask?.abstractTask?.assigneeUserId)?.full_name || 'Unassigned'}+${row.children.length - 1}`}
              />
            </div>
          );
        }
        return (
          <ItemPicker
            items={[{
              id: '',
              email: '',
              full_name: 'Unassigned',
              role: '',
            }, ...(users || [])]}
            value={value}
            idKeyPath="id"
            renderItem={(user) => <UserTag name={user?.full_name || user?.email} />}
            filterKey={(user) => user?.full_name || user?.email}
            placeholder="Assign to"
            onChange={
              (user) => updateAssignee({
                ...row.labelingTask.abstractTask,
                assigneeUserId: user.id,
              })
            }
          >
            <UserTag name={users.find((user) => user.id === value)?.full_name || 'Unassigned'} />
          </ItemPicker>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: ['labelingTask', 'abstractTask', 'status'],
      key: 'status',
      sorter: enumCompareFnFactory<TaskStatus, DerivedLabelingTaskRow>(taskStatusValues, (t) => t.labelingTask.abstractTask.status),
      render: (value: TaskStatus, row: DerivedLabelingTaskRow) => {
        if (row.children?.length) {
          const done = row.children.map((t): number => (t.labelingTask?.abstractTask?.status === TaskStatus.TASK_COMPLETED ? 1 : 0)).reduce((sum, v) => sum + v, 0);
          return <TaskStatusIndicator status={TaskStatus.TASK_COMPLETED} count={`${done}/${row.children.length} `} />;
        }
        return (
          <TaskStatusIndicator
            status={value}
            errorMessage={row.labelingTask.abstractTask.errMessage}
            preparing={row.labelingTask.taskData.taskDescriptor.selectionInstruction?.selectionMethod !== SelectionMethod.DUAL && value === TaskStatus.TASK_CREATED}
          />
        );
      },
    },
    {
      title: '# of Messages',
      key: 'annotatedTargetCount',
      render: (value, row: DerivedLabelingTaskRow, index) => {
        if (row.children?.length) {
          const total = row.children.map((t) => t.annotatedTargetCount).reduce((s, v) => s + v, 0);
          return (<span className={total > 0 ? styles.labelsCount : null}>{total}</span>);
        }
        let firstValue = row.annotatedTargetCount;
        let secondValue = row.targetCount;
        // Summarization gets these values from different source
        if (row.userLabelingTaskType === UserLabelingTaskType.LABELING_SUMMARIZATION) {
          firstValue = row.taskProgress.conversationSetProgresses[0]?.labeledSize;
          secondValue = row.taskProgress.conversationSetProgresses[0]?.conversationSampledSize;
        }
        const labelsString = secondValue === null ? firstValue : `${firstValue}/${secondValue}`;
        return (
          <span className={firstValue > 0 ? styles.labelsCount : null}>
            {[TaskStatus.TASK_COMPLETED, TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(row.labelingTask.abstractTask.status) && labelsString}
          </span>
        );
      },
    },
    {
      title: 'Task Time',
      key: 'taskTime',
      render: (_, row: DerivedLabelingTaskRow) => {
        if ([TaskStatus.TASK_READY, TaskStatus.TASK_CREATED].includes(row.labelingTask.abstractTask.status)
          || row?.children?.every((t) => [TaskStatus.TASK_READY, TaskStatus.TASK_CREATED].includes(t.labelingTask.abstractTask.status))) {
          return <span className={styles.missingData}>N/A</span>;
        }
        const totalTime = row.children?.length ? row.children.map((t) => t.labelingTimeMinute).reduce((s, v) => s + v, 0) : row.labelingTimeMinute;
        const minutes = totalTime % 60;
        const hours = Math.floor(totalTime / 60);
        return `${(hours ? `${hours}h ` : '')}${minutes}${minutes === 1 ? 'min' : 'mins'}`;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (value, row: DerivedLabelingTaskRow, index) => (
        <div className={styles.taskActions}>
          {renderTaskAction(row)}
        </div>
      ),
    },
    {
      title: '',
      key: 'archive',
      render: (_, row: DerivedLabelingTaskRow) => {
        if (row.children?.length) {
          return <></>;
        }
        return (
          <Dropdown
            menu={{
              items: [
                {
                  label: <ArchiveTaskButton labelingTask={row.labelingTask} />,
                  key: '0',
                },
                {
                  label: <UnstyledButton onClick={() => setDrawerModal(row)}>See config</UnstyledButton>,
                  key: '1',
                },
              ],
            }}
          >
            <span style={{ cursor: 'pointer' }}>
              <MoreOutlined />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  const filteredConcepts = (concepts || []).filter((concept) => concept?.state !== ConceptState.DEPRECATED && concept?.conceptType === conceptType);

  return (
    <PageContainer>
      <div className={styles.header}>
        <div>
          <h1>Tasks</h1>
          <div className={styles.showArchived}>
            <Switch
              defaultChecked={showArchivedTasks === 'true'}
              size="small"
              onChange={() =>
                setShowArchivedTasks(showArchivedTasks === 'true' ? 'false' : 'true')}
            />
            <label className={styles.showArchivedLabel}>Show archived</label>
          </div>
        </div>
        <Button
          color="#304ffe"
          onClick={() => setOpenModal(ModalType.NEW_TASK)}
        >
          Add new
        </Button>
      </div>
      <div className={styles.filters}>
        <Text size="md">{`${filteredTaskRows?.length} of ${derivedLabelingTasks?.length} tasks`}</Text>
        <Select
          showArrow
          className={styles.select}
          size="large"
          placeholder="Concept type"
          value={conceptType}
          onChange={(value) =>
            setConceptType(value)}
        >
          {OPTIONS_CONCEPT_TYPES.map((conceptType) => (
            <Select.Option key={conceptType.value} value={conceptType.value}>
              {conceptType.text}
            </Select.Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          showArrow
          className={styles.select}
          size="large"
          placeholder="Task type"
          allowClear
          value={taskTypes}
          tagRender={(props) => (
            <div style={{ paddingLeft: '5px' }}>
              <TaskTypeTag taskType={props.value as UnionTaskType} />
            </div>
          )}
          onChange={(value) =>
            setTaskTypes(value || [])}
        >
          {filterTaskTypes.map((taskType) => (
            <Select.Option key={taskType} value={taskType}>
              <TaskTypeTag taskType={taskType} />
            </Select.Option>
          ))}
        </Select>
        <Select
          showArrow
          showSearch
          className={styles.select}
          size="large"
          placeholder="Intent"
          allowClear
          value={intent}
          filterOption={(input, option) => option.searchvalue.indexOf(input) !== -1}
          onChange={(value) =>
            setIntent(value)}
        >
          {filteredConcepts.map((concept) => (
            <Select.Option key={concept.conceptTitle} value={getId('concept', concept.name)} searchvalue={concept.conceptTitle}>
              {concept.conceptTitle}
            </Select.Option>
          ))}
        </Select>
        <Select
          showArrow
          className={styles.select}
          size="large"
          placeholder="Test/Train"
          allowClear
          value={dataSplit}
          onChange={(value) =>
            setDataSplit(value)}
        >
          {[DataSplit.TEST, DataSplit.TRAIN].map((ttType) => (
            <Select.Option key={ttType} value={ttType}>
              {ttType}
            </Select.Option>
          ))}
        </Select>
        <Select
          showSearch
          showArrow
          className={styles.select}
          size="large"
          placeholder="Assignee"
          allowClear
          value={assigneeUserId}
          filterOption={(input, option) =>
            option.props.children?.props?.name
              ?.toLowerCase()
              .includes(input.toLowerCase())}
          onChange={(value) =>
            setAssigneeUserId(value)}
        >
          {[{
            // Note that for filters we use id: Unassigned
            // because we need a value in the url param
            id: 'Unassigned',
            email: '',
            full_name: 'Unassigned',
          }, ...(users || [])].map((user) => (
            <Select.Option key={user.id} value={user.id}>
              <UserTag name={user.full_name || user.email} />
            </Select.Option>
          ))}
        </Select>

        <Select
          mode="multiple"
          showArrow
          className={styles.select}
          size="large"
          placeholder="Status"
          allowClear
          value={status}
          onChange={(value) =>
            setStatus(value)}
        >
          <Select.Option value={TaskStatus.TASK_READY}><TaskStatusIndicator status={TaskStatus.TASK_READY} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_IN_PROGRESS}><TaskStatusIndicator status={TaskStatus.TASK_IN_PROGRESS} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_CREATED}><TaskStatusIndicator status={TaskStatus.TASK_CREATED} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_COMPLETED}><TaskStatusIndicator status={TaskStatus.TASK_COMPLETED} /></Select.Option>
          <Select.Option value={TaskStatus.TASK_ERROR}><TaskStatusIndicator status={TaskStatus.TASK_ERROR} /></Select.Option>
        </Select>

        <Select
          mode="multiple"
          showArrow
          className={styles.select}
          size="large"
          placeholder="Tasks"
          allowClear
          value={taskIds}
          maxTagCount={1}
          onChange={(value) =>
            setTaskIds(value)}
        >
          {derivedLabelingTasks.map((task) => {
            const taskId = getId('labelingTask', task.labelingTask.name);
            return (
              <Select.Option key={taskId} value={taskId}>{taskId}</Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={filteredTaskRows}
          rowKey={(record: DerivedLabelingTaskRow) => record.labelingTask?.name}
          rowClassName={(record: DerivedLabelingTaskRow) => {
            if (record.labelingTask?.abstractTask.visibility === TaskVisibility.INVISIBLE) return 'greyed-row';
            if (record.isChild) return 'child-row';
            return null;
          }}
          pagination={false}
          expandable={{
            indentSize: 0,
            expandIcon: ({ expanded, onExpand, record }) => {
              if (record.isChild) {
                return <ExpandIcon />;
              }
              if (!record.children || !record.children.length) {
                return null;
              }
              return (
                <span style={{ cursor: 'pointer' }} onClick={(e) => onExpand(record, e)} >
                  {expanded ? (
                    <MinusIcon />
                  ) : (
                    <PlusIcon />
                  )}
                </span>
              );
            },
          }}
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
        />
      </div>
      <TaskConfigurationDrawer
        isOpen={drawerModal !== undefined}
        onClose={() => setDrawerModal(undefined)}
        derivedTask={drawerModal}
        duplicateTaskConfig={duplicateTaskConfig}
      />
      <NewTaskModal
        taskData={taskData}
        setTaskData={setTaskData}
        submitting={taskApiStatus === 'loading'}
        concepts={concepts}
        isOpen={openModal === ModalType.NEW_TASK}
        onClose={() => setOpenModal(null)}
        handleOk={(res) => {
          if (taskData.taskType === UserLabelingTaskType.AUTO_LABELING) {
            navigate(`/${customer.path}/labeling/autolabeling-config/${taskData.conceptIds[0]}`);
          } else {
            createNewTask(res);
          }
        }}
      />
    </PageContainer>
  );
}
