import React, { useState } from 'react';
import { RangeSlider, Text, Stack } from '@mantine/core';

interface Props {
    min?: number;
    max?: number;
    handleMinDurationChange?: (duration: number) => void;
    error?: string;
}

export const ConversationDurationSlider = ({
  min = 0,
  max = 20,
  handleMinDurationChange = (duration: number) => {},
  error = '',
}: Props) => {
  const toMins = (val: number) => val / 5;
  const [minTemp, setMinTemp] = useState(min);

  return (
    <Stack mb="xs">
      <Text size="sm">Conversation duration (mins)</Text>
      <RangeSlider
        color={error && 'red'}
        // marginRight added to prevent upperbound label from peaking out from existing set mask
        style={{ marginRight: 5 }}
        label={null}
        defaultValue={[0, 100]}
        min={0}
        max={100}
        value={[minTemp * 5, max * 5]}
        onChangeEnd={(value) => {
          handleMinDurationChange(toMins(value[0]));
          // handleMaxDurationChange(toMins(value[1]));
        }}
        onChange={(value) => {
          setMinTemp(toMins(value[0]));
        }}
        step={5}
        marks={[
          { value: 0, label: min > 0 ? `< ${minTemp}` : '' },
          { value: 25, label: '' },
          { value: 50, label: '' },
          { value: 75, label: '' },
          { value: 100, label: max < 20 ? `> ${max}` : '20+' },
        ]}
      />
      {error && <Text mt={4} size={12} color="red">{error}</Text>}
    </Stack>
  );
};
