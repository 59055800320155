import React from 'react';
import { Box, Center, Flex, Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PromptIcon } from '../assets/PromptIcon';
import { IPrompt } from '../hooks/usePrompts';

dayjs.extend(relativeTime);

interface DeployedPromptProps {
   deployedPrompt: IPrompt
}

const Container = ({ children }) => (
  <Box
    pos="relative"
    p="md"
    w={310}
    sx={(theme) => ({
      backgroundColor: theme.colors.blue[6],
      color: theme.white,
      borderRadius: 5,
    })}
  >
    {children}
  </Box>
);

export const DeployedPrompt = ({ deployedPrompt }: DeployedPromptProps) => {
  if (!deployedPrompt) {
    return (
      <Container>
        <Center>
          <Text size="sm">No deployed Prompt</Text>
        </Center>
      </Container>
    );
  }
  const { displayName, createdAt, updatedAt } = deployedPrompt;
  const timestamp = updatedAt || createdAt;
  const timeSinceDeployed = dayjs(timestamp).fromNow();
  const dateTooltip = dayjs(timestamp).format(DATE_TIME_FORMAT);
  return (
    <Container>
      <Group align="center">
        <PromptIcon />
        <Flex direction="column" style={{ flex: 1, overflow: 'hidden' }}>
          <Text size="sm" truncate >{displayName}</Text>
          <Text fs="italic" title={dateTooltip} size="xs">
            {timeSinceDeployed}
          </Text>
        </Flex>
      </Group>
    </Container>
  );
};
