import { useEffect } from 'react';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { getModelWorkflow } from 'store/modelWorkflow/asyncThunks';
import { selectSpecificModelWorkflow } from 'store/modelWorkflow/selectors';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';

export const useModelWorkflow: (workflowId: string) => ModelWorkflow = (workflowId) => {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const modelWorkflow = useSelector<ModelWorkflow>(selectSpecificModelWorkflow);

  useEffect(() => {
    if (workflowId) {
      const name = `${customerProfile}/modelWorkflows/${workflowId}`;
      dispatch(getModelWorkflow(name));
    }
  }, [workflowId]);
  return modelWorkflow;
};
