import {
  CreateDialoguePolicySnapshotRequest,
  CreateDialoguePolicySnapshotResponse,
  DialoguePolicySnapshotService,
  GetDialoguePolicySnapshotRequest,
  GetDialoguePolicySnapshotResponse,
  RetrieveLatestSnapshotRequest,
  RetrieveLatestSnapshotResponse,
  BuildDialoguePolicyModelResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/dialoguepolicysnapshot/dialogue_policy_snapshot_service.pb';
import { StudioApi } from './studioApi';

export abstract class DialoguePolicySnapshotApi {
  public static createDialoguePolicySnapshot(request: CreateDialoguePolicySnapshotRequest): Promise<CreateDialoguePolicySnapshotResponse> {
    return DialoguePolicySnapshotService.CreateDialoguePolicySnapshot(request, StudioApi.getHeaders().initReq);
  }

  public static getDialoguePolicySnapshot(request: GetDialoguePolicySnapshotRequest): Promise<GetDialoguePolicySnapshotResponse> {
    return DialoguePolicySnapshotService.GetDialoguePolicySnapshot(request, StudioApi.getHeaders().initReq);
  }

  public static buildModel(snapshot: string): Promise<BuildDialoguePolicyModelResponse> {
    return DialoguePolicySnapshotService.BuildDialoguePolicyModel({ snapshot }, StudioApi.getHeaders().initReq);
  }

  public static retrieveLatestSnapshot(request: RetrieveLatestSnapshotRequest): Promise<RetrieveLatestSnapshotResponse> {
    return DialoguePolicySnapshotService.RetrieveLatestSnapshot(request, StudioApi.getHeaders().initReq);
  }
}
