import React, { useMemo } from 'react';
import {
  Annotation,
  BinaryValueValue,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import './LabelingEntity.scss';
import { Button } from '@mantine/core';
import { LabeledSpan, UnlabeledSpan } from './Spans';

export interface EntityLabel {
  id?: string;
  entityId: string;
  index: number;
  length: number;
}

interface LabelingEntityProps {
  entities: Concept[];
  message: Message;
  annotations: Annotation[];
  error: string;
  addEntityHandler: (entity: EntityLabel) => void;
  updateEntityHandler: (entity: EntityLabel) => void;
  resetEntitiesHandler: () => void;
}

function LabelingEntity(props: LabelingEntityProps) {
  const {
    message,
    annotations,
    entities,
    error,
    addEntityHandler,
    resetEntitiesHandler,
    updateEntityHandler,
  } = props;

  const labeledEntities: EntityLabel[] = useMemo(
    () =>
      annotations
        .filter(
          (annotation) =>
            annotation.value.binaryValue.value
            === BinaryValueValue.VALUE_POSITIVE,
        )
        .map((annotation) => ({
          id: annotation.name,
          entityId: annotation.value.binaryValue.conceptId,
          index: annotation.rawData.messageRawData.spanStart,
          length:
            annotation.rawData.messageRawData.spanEnd
            - annotation.rawData.messageRawData.spanStart,
        })),
    [annotations],
  );

  const handleReset = () => {
    resetEntitiesHandler();
  };

  const handleAdd = (labelToAdd: EntityLabel) => {
    addEntityHandler(labelToAdd);
  };

  const handleUpdate = (entityId: string, entityLabel: EntityLabel) => {
    const labelToUpdate: EntityLabel = {
      ...entityLabel,
      id: getId('annotation', entityLabel.id),
      entityId,
    };
    updateEntityHandler(labelToUpdate);
  };

  const sortByIndex = (a: EntityLabel, b: EntityLabel) => b.index - a.index;

  const getLabeledMessage = (
    msg: string,
    labeledEntities: EntityLabel[],
    entities: Concept[],
  ) => {
    const spans = [];

    // Handle 0 entities labeled
    if (!labeledEntities.length) { return <UnlabeledSpan key="unlabled msh" indexStart={0} handleAdd={handleAdd} entities={entities}>{msg}</UnlabeledSpan>; }

    // reverse sorted so string manipulations do not impact earlier indices
    const sortedLabels = labeledEntities.sort(sortByIndex);

    // Handle trailing unlabeled string
    const lastEntity = sortedLabels[0];
    const lastCharacterOfLastEntity = lastEntity.index + lastEntity.length;

    if (lastCharacterOfLastEntity !== msg.length) {
      const trailingString = msg.substring(lastCharacterOfLastEntity);
      spans.unshift(
        <UnlabeledSpan
          key="initial"
          indexStart={lastEntity.index + lastEntity.length}
          handleAdd={handleAdd}
          entities={entities}
        >
          {trailingString}
        </UnlabeledSpan>,
      );
    }

    // Handle Entities
    sortedLabels.forEach((label, index) => {
      const textToLabel = msg.substring(
        label.index,
        label.index + label.length,
      );
      const getEntityForLabel = () =>
        entities.find(
          (entity) => label.entityId === getId('concept', entity.name),
        );
      const entity = getEntityForLabel();

      spans.unshift(
        <LabeledSpan
          key={label.id}
          label={label}
          labelIndex={entities.indexOf(entity)}
          entities={entities}
          tagLabel={entity?.conceptTitle}
          handleUpdate={handleUpdate}
        >
          {textToLabel}
        </LabeledSpan>,
      );

      // Handle interim string without Entity
      if (index === sortedLabels.length - 1) return;
      const previousEntity = sortedLabels[index + 1];

      const positionOfFinalCharInPreviousEntity = previousEntity.index + previousEntity.length;
      const intersticedString = msg.substring(
        positionOfFinalCharInPreviousEntity,
        label.index,
      );
      spans.unshift(
        <UnlabeledSpan
          handleAdd={handleAdd}
          entities={entities}
          key={`unlabeled-${label.index}`}
          indexStart={positionOfFinalCharInPreviousEntity}
        >
          {intersticedString}
        </UnlabeledSpan>,
      );
    });

    // Handle initial unlabled string
    const firstEntityInString = sortedLabels[sortedLabels.length - 1];
    if (firstEntityInString.index !== 0) {
      const initialString = msg.substring(0, firstEntityInString.index);
      spans.unshift(
        <UnlabeledSpan key="final" indexStart={0} handleAdd={handleAdd} entities={entities}>
          {initialString}
        </UnlabeledSpan>,
      );
    }

    return spans;
  };

  return (
    <div
      className={classNames('labeling-entity', { error })}
      key={message?.messageContent}
      // overrides padding from parent
      style={{ padding: '20px 40px' }}
    >
      <div id="label-container">
        {getLabeledMessage(
          message?.messageContent,
          labeledEntities,
          entities,
        )}
      </div>
      <br />
      <Button
        variant="outline"
        color="red"
        onClick={handleReset}
        style={{
          visibility: labeledEntities.length === 0 ? 'hidden' : 'visible',
        }}
      >
        Reset
      </Button>
    </div>
  );
}

export default React.memo(LabelingEntity);
