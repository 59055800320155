import { Button } from '@mantine/core';
import React, { useContext } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { getLastVisitedCustomer, DEFAULT_CUSTOMER } from 'hooks/useCustomerParams';
import { ParrotIcon } from 'components/Sidebar/icons';
import { OktaAuthContext } from 'context/OktaAuthContext';
import styles from './styles.module.scss';

const Login = () => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { signInWithOkta, data: oktaAuthData } = useContext(OktaAuthContext);
  const { customerId, profileId, usecaseId, languageCode } = getLastVisitedCustomer() || DEFAULT_CUSTOMER;
  const message = searchParams.get('message');
  const to = (!state?.from || state?.from === '/login') ? `/${customerId}/${profileId}/${usecaseId}/${languageCode}` : state?.from;

  if (oktaAuthData.status === 'in') {
    return <Navigate to={to} replace />;
  }

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginContainer}>
        <div className={styles.logo}>
          <span>
            <ParrotIcon />
          </span>
          <span className={styles.titleText}>Cresta Studio</span>
        </div>

        {message && (
          <div className={styles.expiredMessage}>
            <p>Your session has expired</p>
          </div>
        )}
        <div className={styles.loginControls}>
          <Button onClick={signInWithOkta}>Sign in with Okta</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
