/* eslint-disable react/no-array-index-key */
import React from 'react';

import './HighlightedText.scss';

interface HighlightedTextProps {
  text: string;
  regexStrings: string[];
  color?: string;
}

// Regex to split custom mark tags
const MARK_REGEX = /(\[mark\])|(\[\/mark\])/;

export default function HighlightedText({
  text = '',
  regexStrings = [],
  color,
}: HighlightedTextProps) {
  if (regexStrings.length === 0) {
    return <span>{text}</span>;
  }

  // All regex joined together
  let joinedRegex: RegExp;
  try {
    joinedRegex = new RegExp(`(${regexStrings.join('|')})`, 'gi');
  } catch (err) {
    console.error(err);
    return <span>{text}</span>;
  }

  // Replace regex matches with custom mark tags
  // (we cannot replace text with JSX here)
  const textWithMarkTags = text.replace(
    joinedRegex,
    (match) => `[mark]${match}[/mark]`,
  );

  // Split by mark tags
  const splitText = textWithMarkTags.split(MARK_REGEX).filter((str) => str);

  const textAndJsxList: React.ReactNode[] = [];
  for (let i = splitText.length - 1; i >= 0; i--) {
    if (splitText[i] === '[/mark]') {
      textAndJsxList.unshift(
        <span
          className="highlighted-text-mark"
          style={{
            backgroundColor: color,
          }}
          key={i}
        >{splitText[i - 1]}
        </span>,
      );
      i = Math.max(i - 2, 0);
    } else {
      textAndJsxList.unshift(<span>{splitText[i]}</span>);
    }
  }

  return <span className="highlighted-text">{textAndJsxList}</span>;
}
