import { SuggestionsDatasetArtifactInputsShardInterval } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { DatesRangeValue } from '@mantine/dates';
import { useForm } from '@mantine/form';

export interface SmartComposeConfigFormValues {
  conversationDates: DatesRangeValue;
  addExperts: boolean;
  expertAgentUserIds: string[];
  // advanced settings
  testSetDaysSuffix: number;
  shardInterval: SuggestionsDatasetArtifactInputsShardInterval;
  splitRatioTrain: number;
  loadPreprocessingConfig: boolean,
  loadPostprocessingConfig: boolean,
  materializeConfigs: boolean,
}

export const useSmartComposeConfigForm = () => {
  const form = useForm<SmartComposeConfigFormValues>({
    initialValues: {
      conversationDates: [null, null],
      addExperts: false,
      expertAgentUserIds: [],
      // advanced settings
      testSetDaysSuffix: 7,
      shardInterval: SuggestionsDatasetArtifactInputsShardInterval.SHARD_INTERVAL_UNSPECIFIED,
      splitRatioTrain: 95,
      loadPreprocessingConfig: false,
      loadPostprocessingConfig: false,
      materializeConfigs: true,
    },
  });

  return form;
};
