enum Split {
  TRAIN = 'train',
  VALIDATION = 'validation',
  TEST = 'test',
}

enum TaskType {
  AGENT_ACTION = 'agent-action',
  VISITOR_ACTION = 'visitor-action',
  CURRENT_ACTION = 'current-action',
  NEXT_ACTION = 'next-action',
  CHAT_DRIVER = 'chat-driver'
}

enum ReviewStatusEnum {
  NOT_REVIEWED = 'NOT_REVIEWED',
  REVIEWED_AGREE = 'REVIEWED_AGREE',
  REVIEWED_DISAGREE = 'REVIEWED_DISAGREE',
}

// these should match the `base_name` of the models in chat-ai
enum ModelName {
  BERT_ACTION_CLASSIFIER = 'bert-action-classifier',
  ONEVSALL_AGENT_ACTION_CLASSIFIER = 'onevsall-agent-action-classifier',
  ONEVSALL_VISITOR_ACTION_CLASSIFIER = 'onevsall-visitor-action-classifier',
  RULE_GRAPH = 'rule-graph',
  RULE_BASED_DIALOGUE_POLICY = 'rule-based-dialog-policy',
  NEURAL_DIALOGUE_POLICY = 'neural_dialog-policy',
  NEXT_AGENT_ACTION_ENSEMBLE = 'next-agent-action-ensemble',
}

export {
  Split,
  TaskType,
  ModelName,
  ReviewStatusEnum,
};
