import React, { Dispatch, Key, useMemo } from 'react';
import { Button, Flex, Divider, Group, SegmentedControl, Select, Stack, Text, TextInput, Title } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { SearchIcon } from 'components/SetManagementDropdown/icons';
import { Link, useLocation } from 'react-router-dom';
import { Value as LabelValue } from 'components/BooleanDropdown';
import { MisclassificationTable, MisclassificationTableRow } from './MisclassificationTable';
import { PageAction, PageState } from '.';
import { LabelingGuidelinesPanel } from './LabelingGuidelinesPanel';

interface Props {
  data: MisclassificationTableRow[];
  intentData: { label: string; value: string }[];
  pageState: PageState;
  pageDispatch: Dispatch<PageAction>;
  isDraftSelection: boolean;
  onUpdateTrainSet: () => void;
  onUpdateLabel: (rowKey: Key, label: LabelValue) => void;
  onFinishReview: () => void;
  isLoading: boolean;
}

const Counters = ({ addedCount, totalCount, onUpdateTrainSet, isDraftSelection, isLoading }: {
  addedCount: number;
  totalCount: number;
  onUpdateTrainSet: () => void;
  isDraftSelection: boolean;
  isLoading: boolean;
}) => (
  <Flex direction="row" align="center" gap="md">
    <Text>{`${addedCount} Added`}</Text>
    <Divider orientation="vertical" my="xs" />
    <Text>{totalCount} Total</Text>
    <Button
      loading={isLoading}
      styles={(theme) => (
        {
          root: {
            fontSize: theme.fontSizes.md,
            color: theme.colors.blue[5],
          },
        })}
      variant="subtle"
      onClick={onUpdateTrainSet}
      disabled={!isDraftSelection}
    >
      Update Train Set
    </Button>
  </Flex>
);

export const MisclassificationReview = ({
  data,
  intentData,
  pageState,
  pageDispatch,
  onUpdateTrainSet,
  onUpdateLabel,
  onFinishReview,
  isDraftSelection,
  isLoading,
}: Props) => {
  const { pathname } = useLocation();
  const totalAdded = useMemo(() => data.filter((row) => row.addedToTrainSet).length, [data]);
  const [selectedIntentId, setSelectedIntentId] = React.useState<string | null>(null);
  return (
    <PageContainer>
      <Group align="flex-start">
        <Stack style={{ flex: 1 }}>
          <Title order={2}>Misclassification Review</Title>
          <Text>Here are more labels model predicted based on the QA task. Select and add all that can be used for the train set.</Text>
        </Stack>
        <Group>
          <Button
            variant="subtle"
            component={Link}
            // navigate to parent path
            to={`${pathname.split('/').slice(0, -2).join('/')}/tasks`}
          >Continue later
          </Button>
          <Button onClick={onFinishReview}>Finish</Button>
        </Group>
      </Group>
      <Group align="flex-end" position="apart">
        <Group align="center">
          <Counters
            totalCount={data.length}
            addedCount={totalAdded}
            onUpdateTrainSet={onUpdateTrainSet}
            isDraftSelection={isDraftSelection}
            isLoading={isLoading}
          />
        </Group>
        <Group>
          <SegmentedControl
            value={pageState.labelFilter}
            onChange={(value) => pageDispatch({ type: 'SET_LABEL_FILTER', payload: value as 'all' | 'positive' | 'negative' })}
            data={[
              { label: 'All', value: 'all' },
              { label: 'Positive', value: 'positive' },
              { label: 'Negative', value: 'negative' },
            ]}
          />
          <Select
            value={pageState.intentFilter}
            onChange={(value) => pageDispatch({ type: 'SET_INTENT_FILTER', payload: value })}
            placeholder="All intents"
            clearable
            data={intentData}
          />
          <TextInput
            placeholder="Search"
            value={pageState.search}
            onChange={(event) => pageDispatch({ type: 'SET_SEARCH', payload: event.currentTarget.value })}
            rightSection={<SearchIcon />}
          />
        </Group>
      </Group>
      <Group align="stretch" noWrap>
        <MisclassificationTable
          dataSource={data}
          selectedRowKeys={pageState.selectedRowKeys}
          setSelectedRowKeys={(keys) => pageDispatch({ type: 'SET_SELECTED_ROW_KEYS', payload: keys })}
          onUpdateLabel={onUpdateLabel}
          onIntentClick={(intentId) => setSelectedIntentId(intentId)}
        />
        <LabelingGuidelinesPanel
          onClose={() => setSelectedIntentId(null)}
          intentId={selectedIntentId}
        />
      </Group>
    </PageContainer>
  );
};
