import {
  MomentAnnotationDetailedType,
  MomentAnnotationType,
} from '@cresta/web-client/dist/cresta/ai_service/common';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { getParent } from 'common/resourceName';

export const momentTypeToLabel = (type: MomentAnnotationDetailedType) => {
  switch (type) {
    case MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT:
      return 'Current Agent Action';
    case MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT:
      return 'Next Agent Action';
    case MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT:
      return 'Current Visitor Action';
    case MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER:
      return 'Driver';
    default:
      return type;
  }
};

export const INTENT_DETECTION_TYPES = [
  MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT,
  MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT,
  MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER,
];

export const INTENT_TYPES = [
  MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT,
  MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER,
  MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT,
  MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT,
  MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT_TRACE,
];

export const detailedTypeRoleMap = new Map<
  MomentAnnotationDetailedType,
  'agent' | 'driver' | 'visitor'
>([
  [MomentAnnotationDetailedType.DETAILED_TYPE_AGENT_INTENT, 'agent'],
  [MomentAnnotationDetailedType.DETAILED_TYPE_CHAT_DRIVER, 'driver'],
  [MomentAnnotationDetailedType.DETAILED_TYPE_VISITOR_INTENT, 'visitor'],
  [MomentAnnotationDetailedType.DETAILED_TYPE_NEXT_INTENT, 'agent'],
]);

export const getMessagePredictionMap = (preds: Prediction[]) => {
  const messagePredictionMap = new Map<string, Prediction[]>();
  for (const prediction of preds) {
    if (
      prediction.moment
      && prediction.moment.type === MomentAnnotationType.INTENT
      && INTENT_TYPES.includes(prediction.moment.detailedType)
    ) {
      const message = getParent('prediction', prediction.name);
      if (!messagePredictionMap.has(message)) {
        messagePredictionMap.set(message, []);
      }
      messagePredictionMap.get(message).push(prediction);
    }
  }
  return messagePredictionMap;
};
