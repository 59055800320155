import React from 'react';
import './ChatCard.scss';
import { truncateMiddle } from 'utils';
import CopyableValue from 'components/CopyableValue';
import { useMantineTheme } from '@mantine/core';

interface Props {
  title: React.ReactNode;
  copyableValue: string;
  footer?: string;
  children?: JSX.Element;
  bordered?: boolean;
}

export default function ChatCard({ title, copyableValue, footer, children, bordered }: Props) {
  const theme = useMantineTheme();
  return (
    <div
      className="chat-card"
      style={{
        border: bordered && `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      }}
    >
      <div className="card-content">
        <p className="agent-name">
          {title}
        </p>
        <CopyableValue
          displayValue={truncateMiddle(copyableValue, 0, 6)}
          copiedValue={copyableValue}
        />
        {footer && (
          <span className="date-time">
            {footer}
          </span>
        )}
        {children}
      </div>
    </div>
  );
}
