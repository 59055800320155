import { createSlice } from '@reduxjs/toolkit';
import {
  createModelWorkflowSuccess,
  listModelWorkflowSuccess,
  modelWorkflowApiFailed,
  modelWorkflowApiPending,
  getModelWorkflowSuccess,
  modelWorkflowUpdateReducer,
} from './reducer';
import { createModelWorkflow, getModelWorkflow, listModelWorkflow } from './asyncThunks';
import { INITIAL_STATE } from './state';

/** model workflow slice. */
export const modelWorkflowSlice = createSlice({
  name: 'modelWorkflow',
  initialState: INITIAL_STATE,
  reducers: {
    modelWorkflowUpdate: modelWorkflowUpdateReducer,
  },
  extraReducers: (builder) =>
    builder.addCase(createModelWorkflow.pending, modelWorkflowApiPending)
      .addCase(createModelWorkflow.rejected, modelWorkflowApiFailed)
      .addCase(createModelWorkflow.fulfilled, createModelWorkflowSuccess)
      .addCase(listModelWorkflow.pending, modelWorkflowApiPending)
      .addCase(listModelWorkflow.rejected, modelWorkflowApiFailed)
      .addCase(listModelWorkflow.fulfilled, listModelWorkflowSuccess)
      .addCase(getModelWorkflow.pending, modelWorkflowApiPending)
      .addCase(getModelWorkflow.rejected, modelWorkflowApiFailed)
      .addCase(getModelWorkflow.fulfilled, getModelWorkflowSuccess),
});

/** Actions for sync reducers. */
export const { modelWorkflowUpdate } = modelWorkflowSlice.actions;
