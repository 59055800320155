import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Button, Divider, Group, Title, Text, useMantineTheme, createStyles, Space, Alert, Paper, Skeleton, Stack, Box } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import UserTag from 'components/UserTag';
import { NavLink, useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectServingModels, selectLoadServingModelStatus } from 'store/modelBuilder/selectors';
import { Model } from 'store/modelBuilder/state';
import CopyableValue from 'components/CopyableValue';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import Loading from 'components/Loading';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { ListModelArtifactsRequest, ModelArtifact, ModelArtifactArtifactType, ModelArtifactStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { modelArtifactApi } from 'services/modelArtifactApi';
import { ApiStatus } from 'store/types';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { getServingModelDetails } from 'store/modelBuilder/asyncThunks';
import { useApiGet } from 'hooks/network';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { getDeploymentStatusElement } from './utils';
import { CheckAIServices } from './CheckAIServices';

export function DeploymentSummary() {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const { currentConfig } = useContext(CustomerConfigContext);
  const { usecaseId, languageCode, path } = useCustomerParams();
  const apiGet = useApiGet(false);
  const getter = () => apiGet(`${path}/get_serving_model_details`);
  const models: Model[] = useSelector<Model[]>(selectServingModels);
  const loadServingModelStatus = useSelector<ApiStatus>(selectLoadServingModelStatus);
  const modelsMap: Map<string, Model> = useMemo(() => new Map(models.map((model) => ([model.type, model]))), [models]);
  const [modelArtifacts, setModelArtifacts] = useState<ModelArtifact[]>();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();

  const listModelArtifacts = useCallback(async () => {
    const request: ListModelArtifactsRequest = {
      parent: `${customerProfile}/modelProjects/-`,
      filter: {
        artifactType: ModelArtifactArtifactType.DEPLOYMENT,
        usecaseId,
        languageCode,
      },
    };
    setLoading(true);
    try {
      const response = await modelArtifactApi.listModelArtifacts(request);
      setModelArtifacts(response.modelArtifacts);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [path]);

  useEffect(() => {
    listModelArtifacts();
    dispatch(getServingModelDetails({
      customerProfile,
      getter,
    }));
  }, []);

  // Find artifacts that match the uris
  const deployedArtifacts = modelArtifacts?.filter((artifact) => {
    const unit = artifact.deploymentArtifact.inputs?.intentPolicyModelDeploymentUnit;
    const dryRun = artifact.deploymentArtifact?.inputs.validateOnly;
    if (!unit) return false;
    const agentModel = modelsMap.get('agent');
    const visitorModel = modelsMap.get('visitor');
    const policyModel = modelsMap.get('policy');
    const driverModel = modelsMap.get('driver');
    if (
      unit.agentIntentModelUri === (agentModel?.url || '')
      && unit.visitorIntentModelUri === (visitorModel?.url || '')
      && unit.dialoguePolicyModelUri === (policyModel?.url || '')
      && unit.chatDriverModelUri === (driverModel?.url || '')
      && !dryRun
    ) {
      return true;
    }
    return false;
  });

  // Find the latest deployed artifact
  const latestDeployedArtifact = orderBy(deployedArtifacts, (artifact) => dayjs(artifact.completeTime).unix(), 'desc')?.[0];

  const useStyles = createStyles((theme) => ({
    label: {
      color: theme.colors.gray[4],
    },
  }));
  const { classes } = useStyles();

  const columns: ColumnsType<Model> = [
    {
      title: 'Type',
      dataIndex: 'displayType',
      key: 'displayType',
    },
    {
      title: 'Model URL',
      dataIndex: 'url',
      key: 'url',
      render: (value: string, row) => <CopyableValue copiedValue={value} displayValue={value} />,
    },
    {
      title: 'Test weighted',
      children: [
        {
          title: 'F1',
          dataIndex: ['modelMetrics', 'f1'],
          key: 'f1',
        },
        {
          title: 'Precision',
          dataIndex: ['modelMetrics', 'precision'],
          key: 'precision',
        },
        {
          title: 'Recall',
          dataIndex: ['modelMetrics', 'recall'],
          key: 'recall',
        },
        {
          title: 'Support',
          dataIndex: ['modelMetrics', 'support'],
          key: 'support',
        },
      ],
    },
    {
      title: 'Test Dataset',
      dataIndex: 'test',
      key: 'test',
      children: [
        {
          title: 'Support',
          dataIndex: 'support',
          key: 'support',
        },
      ],
    },
    {
      title: 'Dependencies',
      dataIndex: 'dependencies',
      key: 'dependencies',
      render: (value) => (
        <Box>
          {value?.map((url) => {
            const valid = url?.startsWith(currentConfig?.customerShortName || '');
            return (
              <>
                {url && (
                  <CopyableValue
                    displayValue={url}
                    copiedValue={url}
                    tooltip={valid ? '' : 'The deployed model prefix does not match the customer domain'}
                  />
                )}
              </>
            );
          })}
        </Box>
      ),
    },
  ];

  return (
    <PageContainer>
      <Group position="apart">
        <Title order={1}>Production Summary</Title>
        <Button onClick={() => navigate(`/${path}/deployment/new`)}>New deployment</Button>
      </Group>
      {
        (loadServingModelStatus === 'succeeded' && !latestDeployedArtifact && modelArtifacts) ? (
          <Alert color="red">
            <Text size="md" color={theme.colors.red[3]}>No Studio deployment for the currently deployed models can be found.</Text>
          </Alert>
        ) : (
          <Paper p="lg" sx={{ backgroundColor: latestDeployedArtifact?.status === ModelArtifactStatus.FAILED ? theme.colors.red[0] : theme.white }}>
            {latestDeployedArtifact && (
              <>
                {latestDeployedArtifact?.status === ModelArtifactStatus.FAILED && (
                  <Text color={theme.colors.red[4]} mb="sm">Only found a failed Studio deployment for the currently deployed models.</Text>
                )}
                <Group>
                  <Group spacing="sm">
                    <Text className={classes.label}>Date deployed:</Text>
                    <Text>{dayjs(latestDeployedArtifact.completeTime).format(DATE_TIME_FORMAT)}</Text>
                  </Group>
                  <Divider sx={{ height: 20 }} orientation="vertical" color={theme.colors.gray[1]} />
                  <Group spacing="sm">
                    <Text className={classes.label}>Author:</Text>
                    <UserTag name={latestDeployedArtifact.creator || 'Unassigned'} />
                  </Group>
                  <Divider sx={{ height: 20 }} orientation="vertical" color={theme.colors.gray[1]} />
                  <Group spacing="sm">
                    <Text className={classes.label}>Status:</Text>
                    {getDeploymentStatusElement(latestDeployedArtifact.status, theme)}
                  </Group>
                  <Divider sx={{ height: 20 }} orientation="vertical" color={theme.colors.gray[1]} />
                  <Group spacing="sm">
                    <NavLink to={`/${path}/deployment/history`}>See past deployments</NavLink>
                  </Group>
                </Group>
                <Group mt="sm" noWrap align="top">
                  <Text className={classes.label}>Commit message:</Text>
                  <Text sx={{ maxWidth: '800px' }}>{latestDeployedArtifact.deploymentArtifact.outputs.commitMessage || '-'}</Text>
                </Group>
              </>
            )}
            {(loadServingModelStatus === 'loading' || loading) && (
              <Stack spacing="sm">
                <Skeleton height={23} width="50%" />
                <Skeleton height={23} width="20%" />
              </Stack>
            )}
          </Paper>
        )
      }

      <Space h="lg" />
      <Table
        columns={columns}
        dataSource={models}
        loading={{
          spinning: loadServingModelStatus === 'loading' || loading,
          indicator: <Loading />,
        }}
      />
      <Space h="lg" />
      <CheckAIServices />
    </PageContainer>
  );
}
