import React, { useCallback, useMemo, useState, useContext } from 'react';
import { Title, Text, Paper, Tabs, TextInput, Textarea, Group, Button, Input, Checkbox, Space, Anchor, Alert } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useResourceCreation } from 'hooks/useResourceCreation';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { TIMEOUT_ERROR_MSG } from 'hooks/network';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'hooks/reduxHooks';
import { selectServingModels } from 'store/modelBuilder/selectors';
import { Model } from 'store/modelBuilder/state';
import { UserContext } from 'context/UserContext';
import { useDialoguePolicyInBranch } from 'hooks/useDialoguePolicy';
import { MASTER_BRANCH_NAME } from 'pages/DialogPolicy/EditRoute/PolicyEdit';
import { DialoguePolicySnapshotApi } from 'services/dialoguePolicySnapshotApi';
import { useStudioSubscription } from 'hooks/useStudioSubscription';
import { ResourceUpdateResourceType } from '@cresta/web-client/dist/cresta/v1/studio/resource_update.pb';
import { getId } from 'common/resourceName';
import Loading from 'components/Loading';
import { openNotification } from 'components/Notification';
import { DialoguePolicySnapshot } from '@cresta/web-client/dist/cresta/v1/studio/dialoguepolicysnapshot/dialogue_policy_snapshot_service.pb';
import externalLinkIcon from 'assets/svg/icon-external-link.svg';

type ModelType = 'intent_policy' | 'next_agent_intent_ensemble';
export type DeploymentOperation = 'update' | 'remove' | 'none';

interface DeploymentModelFormItem {
  url: string,
  operation: DeploymentOperation,
  keep_current?: boolean,
  remove_prod?: boolean,
}
interface IntentsDeploymentRequestForm {
  model_type: ModelType,
  deployment: {
    agent_intent_model: DeploymentModelFormItem,
    visitor_intent_model: DeploymentModelFormItem,
    chat_driver_model: DeploymentModelFormItem,
    dialogue_policy_model: DeploymentModelFormItem,
  },
  dry_run: boolean,
  skip_taxonomy_compatibility_check: boolean,
  skip_regression_test_check: boolean,
  agent_intent_model_url_prod: string,
  visitor_intent_model_url_prod: string,
  chat_driver_model_url_prod: string,
  dialogue_policy_model_url_prod: string,
  dialogue_policy_snapshot_id: string,
  commit_message: string,
}

function IntentPolicyForm({ form, prodUris, toggleUseDraftHandler, isBuildingDP, buildingDPError }: {
  form: UseFormReturnType<IntentsDeploymentRequestForm>,
  prodUris: {
    agent: string,
    visitor: string,
    driver: string,
    policy: string,
  },
  toggleUseDraftHandler: (value: boolean) => void,
  isBuildingDP: boolean,
  buildingDPError: string,
}) {
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  // Disabled state of inputs
  const disabledInputs = useMemo(() => {
    const {
      agent_intent_model,
      visitor_intent_model,
      chat_driver_model,
      dialogue_policy_model,
    } = form.values.deployment;
    return {
      agent: agent_intent_model.keep_current || agent_intent_model.remove_prod,
      visitor: visitor_intent_model.keep_current || visitor_intent_model.remove_prod,
      driver: chat_driver_model.keep_current || chat_driver_model.remove_prod,
      policy: dialogue_policy_model.keep_current || dialogue_policy_model.remove_prod || !!form.values.dialogue_policy_snapshot_id,
    };
  }, [form.values]);

  // Add/remove model uri based on toggled checkbox
  const toggleCheckboxHandler = useCallback((checkbox: 'keep_current' | 'remove_prod', value: boolean, type: 'agent' | 'visitor' | 'driver' | 'policy') => {
    let prodModelUri = '';
    let formPath = '';
    switch (type) {
      case 'agent':
        formPath = 'agent_intent_model';
        prodModelUri = prodUris.agent;
        break;
      case 'visitor':
        formPath = 'visitor_intent_model';
        prodModelUri = prodUris.visitor;
        break;
      case 'driver':
        formPath = 'chat_driver_model';
        prodModelUri = prodUris.driver;
        break;
      case 'policy':
        formPath = 'dialogue_policy_model';
        prodModelUri = prodUris.policy;
        // Toggling either checkbox means we're not using draft, so we should reset snapshot id
        form.setFieldValue('dialogue_policy_snapshot_id', '');
        break;
      default:
        break;
    }

    const formItem: DeploymentModelFormItem = form.values[formPath];
    if (checkbox === 'keep_current') {
      const newFormItem: DeploymentModelFormItem = {
        ...formItem,
        keep_current: value,
      };
      if (value) {
        newFormItem.remove_prod = false;
        newFormItem.url = prodModelUri || '';
      } else {
        newFormItem.url = '';
      }
      form.setFieldValue(`deployment.${formPath}`, newFormItem);
    }

    if (checkbox === 'remove_prod') {
      const newFormItem: DeploymentModelFormItem = {
        ...formItem,
        remove_prod: value,
      };
      if (value) {
        newFormItem.keep_current = false;
        newFormItem.url = '';
      } else {
        newFormItem.url = '';
      }
      form.setFieldValue(`deployment.${formPath}`, newFormItem);
    }
  }, [form]);

  return (
    <>
      <Input.Wrapper
        sx={{ width: 300 }}
        mt="md"
        label="Agent model"
      >
        <Space h="xs"/>
        <Checkbox
          label="Keep prod"
          checked={form.values.deployment.agent_intent_model.keep_current}
          onChange={(e) => toggleCheckboxHandler('keep_current', e.target.checked, 'agent')}
        />
        <Space h="xs"/>
        <TextInput
          {...form.getInputProps('deployment.agent_intent_model.url')}
          placeholder="Choose a model"
          disabled={disabledInputs.agent}
          value={form.values.deployment.agent_intent_model.url}
        />
      </Input.Wrapper>
      <Input.Wrapper
        sx={{ width: 300 }}
        mt="md"
        label="Visitor model"
      >
        <Group my="xs">
          <Checkbox
            label="Keep prod"
            checked={form.values.deployment.visitor_intent_model.keep_current}
            onChange={(e) => toggleCheckboxHandler('keep_current', e.target.checked, 'visitor')}
          />
          <Checkbox
            label="Remove prod"
            checked={form.values.deployment.visitor_intent_model.remove_prod}
            onChange={(e) => toggleCheckboxHandler('remove_prod', e.target.checked, 'visitor')}
          />
        </Group>
        <TextInput
          {...form.getInputProps('deployment.visitor_intent_model.url')}
          placeholder="Choose a model"
          disabled={disabledInputs.visitor}
          value={form.values.deployment.visitor_intent_model.url}
        />
      </Input.Wrapper>
      <Input.Wrapper
        sx={{ width: 300 }}
        mt="md"
        label="Chat driver model"
      >
        <Group my="xs">
          <Checkbox
            label="Keep prod"
            checked={form.values.deployment.chat_driver_model.keep_current}
            onChange={(e) => toggleCheckboxHandler('keep_current', e.target.checked, 'driver')}
          />
          <Checkbox
            label="Remove prod"
            checked={form.values.deployment.chat_driver_model.remove_prod}
            onChange={(e) => toggleCheckboxHandler('remove_prod', e.target.checked, 'driver')}
          />
        </Group>
        <TextInput
          {...form.getInputProps('deployment.chat_driver_model.url')}
          placeholder="Choose a model"
          disabled={disabledInputs.driver}
          value={form.values.deployment.chat_driver_model.url}
        />
      </Input.Wrapper>
      <Input.Wrapper
        sx={{ width: 300 }}
        mt="md"
        label="Dialog policy"
      >
        <Group my="xs">
          <Checkbox
            label="Keep prod"
            checked={form.values.deployment.dialogue_policy_model.keep_current}
            onChange={(e) => toggleCheckboxHandler('keep_current', e.target.checked, 'policy')}
          />
          <Checkbox
            label="Remove prod"
            checked={form.values.deployment.dialogue_policy_model.remove_prod}
            onChange={(e) => toggleCheckboxHandler('remove_prod', e.target.checked, 'policy')}
          />
        </Group>
        <TextInput
          {...form.getInputProps('deployment.dialogue_policy_model.url')}
          placeholder="Choose a model"
          disabled={disabledInputs.policy || isBuildingDP}
          value={form.values.deployment.dialogue_policy_model.url}
          error={buildingDPError}
        />
        {isBuildingDP && (
          <Group spacing={5} style={{ position: 'relative', left: -5 }} mt="xs">
            <Loading size="small"/>
            <Text color="blue" size="sm">Building model (this may take a few minutes)...</Text>
          </Group>
        )}
        <Checkbox
          label="Use master"
          mt="sm"
          checked={!!form.values.dialogue_policy_snapshot_id}
          onChange={(e) => toggleUseDraftHandler(e.target.checked)}
        />
      </Input.Wrapper>
      <Textarea
        {...form.getInputProps('commit_message')}
        mt="md"
        minRows={4}
        sx={{ width: 600 }}
        label="Commit message"
        placeholder="Describe what changes are made with this deployment"
      />
      <Anchor
        size="sm"
        mt="lg"
        type="submit"
        onClick={toggleAdvancedSettings}
      >{showAdvancedSettings ? 'Hide' : 'Show'} advanced settings
      </Anchor>
      {showAdvancedSettings && (
        <Input.Wrapper>
          <Checkbox mt="sm" label="Skip SDX/DDX compatibility verification" {...form.getInputProps('skip_taxonomy_compatibility_check')}/>
          <Checkbox mt="sm" label="Skip regression test" {...form.getInputProps('skip_regression_test_check')}/>
          <Space h="xl"/>
          <Link
            to="https://studio.chat-staging.cresta.ai"
            target="_blank"
          >
            <img src={externalLinkIcon} alt="Open link"/>&nbsp;Deploy in Staging Environment
          </Link>
        </Input.Wrapper>
      )}
    </>
  );
}

// NOT USED YET
function SharedForm({ form }) {
  return (
    <>
      <TextInput
        placeholder="Choose a model"
        {...form.getInputProps('modelUrl')}
        sx={{ width: 300 }}
        mt="md"
        label="Model URL"
      />
      <Textarea
        {...form.getInputProps('commitMessage')}
        mt="md"
        minRows={4}
        sx={{ width: 600 }}
        label="Commit message"
        placeholder="Describe what changes are made with this deployment"
      />
      <Link
        to="https://studio.chat-staging.cresta.ai"
        target="_blank"
      >
        <img src={externalLinkIcon} alt="Open link"/>&nbsp;Deploy in Staging Environment
      </Link>
    </>
  );
}

// Helper function to get form data for deployment type
function getDeploymentPayload(modelForm: DeploymentModelFormItem, prodUri: string) {
  const payload = {
    url: (modelForm.keep_current || modelForm.remove_prod) ? (prodUri || '') : modelForm.url,
    operation: 'update',
  };
  if (modelForm.keep_current) {
    payload.operation = 'none';
  }
  if (modelForm.remove_prod) {
    payload.operation = 'remove';
  }
  return payload;
}

function isValidDeploymentModel(modelForm: DeploymentModelFormItem) {
  return modelForm.keep_current || modelForm.remove_prod || modelForm.url;
}

export function NewDeployment() {
  const [createResource] = useResourceCreation();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const customer = useCustomerParams();
  const navigate = useNavigate();
  const servingModels: Model[] = useSelector<Model[]>(selectServingModels);
  const servingModelsMap = new Map<string, Model>(servingModels.map((model) => [model.type, model]));
  const currentUser = useContext(UserContext);
  const [isBuildingDP, setIsBuildingDP] = useState(false);
  const [buildingDPError, setBuildingDPError] = useState('');
  const [snapshot, setSnapshot] = useState<DialoguePolicySnapshot>();

  const prodUris = {
    agent: servingModelsMap.get('agent')?.url,
    visitor: servingModelsMap.get('visitor')?.url,
    driver: servingModelsMap.get('driver')?.url,
    policy: servingModelsMap.get('policy')?.url,
  };

  const [masterSnapshot] = useDialoguePolicyInBranch(MASTER_BRANCH_NAME);

  // Load and build if needed dialogue policy snapshot
  const loadAndBuildSnapshot = useCallback(async () => {
    if (isBuildingDP) return;
    setBuildingDPError('');

    if (!masterSnapshot?.name) {
      openNotification('error', 'Snapshot in \'master\' branch was not found', undefined);
      return;
    }

    setSnapshot(masterSnapshot);

    if (masterSnapshot?.modelUri) {
      intentPolicyForm.setFieldValue('deployment.dialogue_policy_model.url', masterSnapshot.modelUri);
      intentPolicyForm.setFieldValue('dialogue_policy_snapshot_id', getId('dialoguePolicySnapshot', masterSnapshot.name));
    } else {
      setIsBuildingDP(true);
      try {
        await DialoguePolicySnapshotApi.buildModel(masterSnapshot.name);
      } catch (err) {
        openNotification('error', 'Failed to build dialogue policy from draft', undefined, err);
        setIsBuildingDP(false);
      }
    }
  }, [masterSnapshot, isBuildingDP]);

  useStudioSubscription(useCallback((event) => {
    switch (event.type) {
      case 'message':
        switch (event.message.studioResourceUpdate?.resourceType) {
          case ResourceUpdateResourceType.DIALOGUE_POLICY_SNAPSHOT:
            if (snapshot && snapshot.name === event.message.studioResourceUpdate?.dialoguePolicySnapshot?.name) {
              if (event.message.studioResourceUpdate?.dialoguePolicySnapshot?.buildErr) {
                setBuildingDPError(event.message.studioResourceUpdate?.dialoguePolicySnapshot?.buildErr);
              } else {
                intentPolicyForm.setFieldValue('deployment.dialogue_policy_model.url', event.message.studioResourceUpdate.dialoguePolicySnapshot.modelUri);
                intentPolicyForm.setFieldValue('dialogue_policy_snapshot_id', getId('dialoguePolicySnapshot', event.message.studioResourceUpdate.dialoguePolicySnapshot.name));
              }
              setIsBuildingDP(false);
            }
            break;
          default:
        }
        break;
      default:
    }
  }, [customer.path, snapshot]));

  const intentPolicyForm = useForm<IntentsDeploymentRequestForm>({
    initialValues: {
      agent_intent_model_url_prod: '',
      visitor_intent_model_url_prod: '',
      chat_driver_model_url_prod: '',
      dialogue_policy_model_url_prod: '',
      commit_message: '',
      model_type: 'intent_policy',
      dry_run: true,
      skip_taxonomy_compatibility_check: false,
      skip_regression_test_check: false,
      dialogue_policy_snapshot_id: '',
      deployment: {
        agent_intent_model: {
          url: '',
          operation: 'none',
          keep_current: false,
        },
        visitor_intent_model: {
          url: '',
          operation: 'none',
          keep_current: false,
          remove_prod: false,
        },
        chat_driver_model: {
          url: '',
          operation: 'none',
          keep_current: false,
          remove_prod: false,
        },
        dialogue_policy_model: {
          url: '',
          operation: 'none',
          keep_current: false,
          remove_prod: false,
        },
      },
    },
  });

  // NOT USED YET
  const suggestionsForm = useForm({
    initialValues: {
      modelUrl: '',
      profileOverride: '',
      commitMessage: '',
    },
  });

  const smartComposeForm = useForm({
    initialValues: {
      modelUrl: '',
      profileOverride: '',
      commitMessage: '',
    },
  });

  const summarizationForm = useForm({
    initialValues: {
      modelUrl: '',
      profileOverride: '',
      commitMessage: '',
    },
  });

  // Submit the deployment dry run form
  const handleSubmit = useCallback(() => {
    setError(null);
    const formValues = intentPolicyForm.values;
    const {
      deployment,
      commit_message,
      skip_taxonomy_compatibility_check,
      skip_regression_test_check,
      dialogue_policy_snapshot_id,
    } = formValues;

    const agent = getDeploymentPayload(deployment.agent_intent_model, prodUris.agent);
    const visitor = getDeploymentPayload(deployment.visitor_intent_model, prodUris.visitor);
    const driver = getDeploymentPayload(deployment.chat_driver_model, prodUris.driver);
    const policy = getDeploymentPayload(deployment.dialogue_policy_model, prodUris.policy);

    const body = {
      customer_id: customer?.customerId,
      profile_id: customer?.profileId,
      usecase_id: customer?.usecaseId,
      language_code: customer?.languageCode,
      commit_message,
      dry_run: true,
      model_type: 'intent_policy',
      stacked_agent_model_url: agent.url,
      stacked_visitor_model_url: visitor.url,
      stacked_chat_driver_model_url: driver.url,
      rule_based_dialogue_policy_url: policy.url,
      created_by: currentUser?.email,
      skip_taxonomy_compatibility_check,
      dialogue_policy_snapshot_id,
      skip_regression_test_check,
      deployment: {
        agent_intent_model: agent,
        visitor_intent_model: visitor,
        chat_driver_model: driver,
        dialogue_policy_model: policy,
      },
    };
    setSubmitting(true);
    createResource({
      resourceRoute: 'intents/deployment',
      createResourceBodyArgs: body,
      failedCallback: (msg) => {
        setSubmitting(false);
        if (msg === TIMEOUT_ERROR_MSG) {
          openNotification('info', 'Time-out', 'Not a failure. Please open the deployment from the history page and wait...');
          navigate(`/${customer.path}/deployment/history`);
        } else {
          setError(msg);
        }
      },
      acceptedCallback: (resourceId: number) => {
        setSubmitting(false);
        navigate(`/${customer.path}/deployment/checklist/${resourceId}`);
      },
      pollIntervalSeconds: 10,
    });
  }, [intentPolicyForm, createResource]);

  // Toggle the "Use draft" checkbox
  const toggleUseDraft = (value: boolean) => {
    if (value) {
      loadAndBuildSnapshot();
    } else {
      intentPolicyForm.setFieldValue('dialogue_policy_snapshot_id', '');
      intentPolicyForm.setFieldValue('deployment.dialogue_policy_model.url', '');
    }
    intentPolicyForm.setFieldValue('deployment.dialogue_policy_model.keep_current', false);
    intentPolicyForm.setFieldValue('deployment.dialogue_policy_model.remove_prod', false);
  };

  const canSubmit = () => {
    const { agent_intent_model, visitor_intent_model, chat_driver_model, dialogue_policy_model } = intentPolicyForm.values?.deployment;
    const agentCheck = isValidDeploymentModel(agent_intent_model);
    const visitorCheck = isValidDeploymentModel(visitor_intent_model);
    const driverCheck = isValidDeploymentModel(chat_driver_model);
    const policyCheck = isValidDeploymentModel(dialogue_policy_model) && !isBuildingDP;
    return agentCheck && visitorCheck && driverCheck && policyCheck;
  };

  return (
    <PageContainer>
      <div>
        <Title order={1}>New deployment setup</Title>
        <Text>Select the type and fill in the details below</Text>
      </div>
      <Paper p="lg" my="lg">
        <Tabs defaultValue="intent-policy" onChange={() => setError(null)}>
          <Tabs.List>
            <Tabs.Tab value="intent-policy">Intent and policy</Tabs.Tab>
            <Tabs.Tab value="suggestions" disabled>Suggestions</Tabs.Tab>
            <Tabs.Tab value="smart-compose" disabled>Smart compose</Tabs.Tab>
            <Tabs.Tab value="summarization" disabled>Summarization</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="intent-policy" pt="xs">
            <IntentPolicyForm
              form={intentPolicyForm}
              prodUris={prodUris}
              toggleUseDraftHandler={toggleUseDraft}
              isBuildingDP={isBuildingDP}
              buildingDPError={buildingDPError}
            />
          </Tabs.Panel>
          <Tabs.Panel value="suggestions" pt="xs">
            <SharedForm form={suggestionsForm}/>
          </Tabs.Panel>
          <Tabs.Panel value="smart-compose" pt="xs">
            <SharedForm form={smartComposeForm}/>
          </Tabs.Panel>
          <Tabs.Panel value="summarization" pt="xs">
            <SharedForm form={summarizationForm}/>
          </Tabs.Panel>
        </Tabs>
        {error && (
        <Alert mt="md" title="Deployment failed" color="red">
          {error}
        </Alert>
        )}
        <Group mt="lg">
          <Button onClick={handleSubmit} loading={submitting} disabled={!canSubmit()}>Start Dry Run</Button>
          <Button variant="subtle" disabled={submitting} onClick={() => intentPolicyForm.reset()}>Reset all</Button>
        </Group>
      </Paper>
    </PageContainer>
  );
}
