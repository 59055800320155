import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useCustomerProfile, useCustomerUsecase, useCustomerParams } from 'hooks/useCustomerParams';
import { UserContext } from 'context/UserContext';
import { updateConcept } from 'store/concept/asyncThunks';
import { FormErrors, isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { v4 as uuid } from 'uuid';
import { getId } from 'common/resourceName';
import { toRequestUpdateMask } from 'utils';
import { UpdateConceptRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ConceptType, DataSplit, DecisionType, LabelingViewType, SelectionMethod } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { CreatorCreatorType } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import { createLabelingTask } from 'store/labelingTask/asyncThunks';
import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { CreateTaskRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { ConceptData, ConfigFormValues } from './types';
import { ConfigurationPage } from './ConfigurationPage';
import { ConfirmModal } from './components/ConfirmModal';

interface AutoLabelingProps {
  conceptData: ConceptData;
}

export const AutoLabeling = ({ conceptData }: AutoLabelingProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useCustomerParams();
  const usecase = useCustomerUsecase();
  const currentUser = useContext(UserContext);
  const customerProfile = useCustomerProfile();
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);

  const handleCancelConfig = () => navigate(`/${customer.path}/labeling/tasks`);

  const configForm = useForm<ConfigFormValues>({
    initialValues: {
      humanReadableName: '',
      description: conceptData.description || '',
      positiveExamples: conceptData.positiveExamples || [],
      negativeExamples: conceptData.negativeExamples || [],
      speakerRole: conceptData.role,
    },
    validate: {
      humanReadableName: isNotEmpty('Please enter a name for this task'),
    },
  });

  const handleErrors = (errors: FormErrors) => {
    if (errors.positiveExamples) {
      showNotification({ message: errors.positiveExamples, title: 'Error', color: 'red' });
    }
    if (errors.negativeExamples) {
      showNotification({ message: errors.negativeExamples, title: 'Error', color: 'red' });
    }
  };

  const createNewTask = useCallback(() => {
    const taskId = uuid();
    const userId = currentUser?.id || '';
    const conceptType = ConceptType.INTENT;
    const intentMetadata = {
      intentDisplayName: configForm.values.humanReadableName,
      intentDescription: configForm.values.description,
      positiveExamples: configForm.values.positiveExamples.map((example) => ({
        text: example.value,
        exampleUtteranceId: example.id,
      })),
      negativeExamples: configForm.values.negativeExamples.map((example) => ({
        text: example.value,
        exampleUtteranceId: example.id,
      })),
      speakerRole: configForm.values.speakerRole,
    };
    const updateConceptRequest: UpdateConceptRequest = {
      concept: {
        name: conceptData.name,
        intent: {
          intentMetadata,
        },
      },
      userResourceName: currentUser?.name,
      updateMask: toRequestUpdateMask(['intent.intentMetadata']),
    };
    dispatch(updateConcept(updateConceptRequest));
    const createTaskRequest: CreateTaskRequest = {
      name: `${customerProfile}/labelingTasks/${taskId}`,
      commonInput: {
        usecase,
        languageCode: customer.languageCode,
        title: `Labeling Task ${taskId}`,
        assigneeUserId: userId,
        creator: {
          creatorType: CreatorCreatorType.UI_ACTION,
          userId,
        },
      },
      taskDescriptor: {
        conceptType,
        conceptIds: [getId('concept', conceptData.name)],
        targetType: TargetType.LABELING,
        viewType: LabelingViewType.LABELING_VIEW_TYPE_MESSAGE_PARTIAL_CONVERSATION_CONTEXT,
        decisionType: DecisionType.DECISION_TYPE_SINGLE_BINARY,
        split: DataSplit.TRAIN,
        selectionInstruction: {
          selectionMethod: SelectionMethod.INTENT_PSEUDO_LABELING,
          intentPseudoLabelingInstruction: {
            intentMetadata,
          },
        },
        useV2MsgAndConvId: true,
      },
    };
    dispatch(createLabelingTask(createTaskRequest));
  }, [dispatch, currentUser?.name, configForm.values, customer?.path]);
  const handleSubmitConfig = async (values: ConfigFormValues) => {
    try {
      await createNewTask();
      navigate(`/${customer.path}/labeling/tasks`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form
      style={{
        overflow: 'hidden',
        flex: 1,
        display: 'flex',
      }}
    >
      <ConfirmModal
        onConfirm={() => {
          configForm.validate();
          if (configForm.isValid()) {
            handleSubmitConfig(configForm.values);
          } else {
            handleErrors(configForm.errors);
          }
          toggleConfirmModal(false);
        }}
        onClose={() => toggleConfirmModal(false)}
        isOpen={isConfirmModalOpen}
      />
      <ConfigurationPage
        configForm={configForm}
        conceptData={conceptData}
        handleCancelConfig={handleCancelConfig}
        onCreateBtnClick={() => toggleConfirmModal(true)}
      />
    </form>
  );
};
