import React from 'react';
import { Anchor, Button, Group, Loader, Stack, Text } from '@mantine/core';
import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import CopyableValue from 'components/CopyableValue';
import externalLinkIcon from 'assets/svg/external-link.svg';
import { truncateMiddle } from 'utils';
import { Skeletons } from './Skeletons';

interface HeaderIDItemProps {
  id: string;
  label?: string;
  externalLink?: string;
  linkLabel?: string;
}

const HeaderIDItem = ({ id, label, externalLink, linkLabel = 'More information' }: HeaderIDItemProps) => (
  <Group>
    <Text size="xs">{`${label ? `${label}:` : ''}`}</Text>
    <CopyableValue
      displayValue={truncateMiddle(id)}
      copiedValue={id}
    />
    {externalLink && (
      <Button
        compact
        variant="light"
      >
        <Anchor
          href={externalLink}
          target="_blank"
        >{`${linkLabel} `}<img src={externalLinkIcon} alt="external-link" height={10} />
        </Anchor>
      </Button>
    )}
  </Group>
);

interface ChatWindowTopperProps {
  className?: string;
  isLoading: boolean;
  title: string;
  agentsLoading: boolean;
  durationMins: number;
  openTime: string;
  engagementIdData: HeaderIDItemProps;
  studioIdData: HeaderIDItemProps;
  actionComponent?: React.ReactElement<Conversation>;
}

export const ChatWindowTopper = (props: ChatWindowTopperProps) => {
  const {
    className,
    isLoading,
    title,
    agentsLoading,
    durationMins,
    openTime,
    engagementIdData,
    studioIdData,
    actionComponent,
  } = props;

  return (
    <Group position="apart" className={className} noWrap align="stretch">
      {isLoading ? <Skeletons /> : (
        <>
          <Stack spacing="xs" align="stretch">
            <Group>
              <Text>{title}</Text>
              {agentsLoading && <Loader size="xs" />}
            </Group>
            {studioIdData && (
              <HeaderIDItem
                id={studioIdData.id}
                label={studioIdData.label}
                externalLink={studioIdData.externalLink}
                linkLabel={studioIdData.linkLabel}
              />
            )}
            {engagementIdData && (
              <HeaderIDItem
                id={engagementIdData.id}
                label={engagementIdData.label}
                externalLink={engagementIdData.externalLink}
                linkLabel={engagementIdData.linkLabel}
              />
            )}
          </Stack>
          <Stack align="flex-end" pt="xs" spacing="xs" justify="space-between">
            <Text color="dimmed" size="xs">{`${durationMins ? `${durationMins}mins | ` : ''}${openTime || ''}`}</Text>
            {actionComponent && actionComponent}
          </Stack>
        </>
      )}
    </Group>
  );
};
