import { useEffect, useState } from 'react';
import { openNotification } from 'components/Notification';
import { ConceptApi } from 'services/conceptApi';
import {
  Concept,
  ListConceptsRequest,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';

const DEFAULT_PAGE_SIZE = 1000;

interface UseListIntentsArgs {
  parent: string;
  usecaseId?: string;
  languageCode: string;
}

export const useListIntents = ({
  parent,
  usecaseId,
  languageCode,
}: UseListIntentsArgs): Concept[] => {
  const [intents, setIntents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const concepts = [];
      try {
        let nextPageToken: string | undefined;
        do {
          const request: ListConceptsRequest = {
            parent,
            ...(usecaseId ? { usecaseId } : {}), // Include usecaseId only if it's provided
            languageCode,
            pageSize: DEFAULT_PAGE_SIZE,
            pageToken: nextPageToken,
          };
          // eslint-disable-next-line no-await-in-loop
          const response = await ConceptApi.listConcepts(request);
          concepts.push(...response.concepts);
          ({ nextPageToken } = response);
        } while (nextPageToken);
      } catch (err) {
        openNotification('error', 'Failed to list concepts', undefined, err);
        throw err;
      }
      const intents = concepts.filter((obj) => obj.conceptType === 'INTENT');
      setIntents(intents);
    };

    fetchData();
  }, [parent, usecaseId, languageCode]); // Ensure dependencies are included in the dependency array

  return intents;
};
