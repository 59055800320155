import React, { useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import { truncateMiddle } from 'utils';
import CopyableValue from 'components/CopyableValue';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { SimulatorConvoTableRow } from './rowTypes';
import styles from './SimulatorComparedResult.module.scss';
import tableStyles from './SimulatorResultTable.module.scss';

interface Props {
  tableData: SimulatorConvoTableRow[];
  onRowClick: (convoId:string) => void;
}

const columns: any = [
  {
    title: 'Convo ID',
    dataIndex: 'convoId',
    key: 'convoId',
    width: '10%',
    render: (convoId: string, record: SimulatorConvoTableRow) => (
      <div className={styles.tableIntent}>
        <CopyableValue
          displayValue={truncateMiddle(convoId, 0, 6)}
          copiedValue={convoId}
        />
      </div>
    ),
  },
  {
    title: 'Convo date',
    dataIndex: 'convoDate',
    key: 'convoDate',
    width: '10%',
    render: (convoDate: string, record: SimulatorConvoTableRow) => (
      <div className={styles.tableIntent}>{convoDate}</div>
    ),
  },
  {
    title: <div data-type="convo-same" className={styles.simulatorDashboardCardTitle}>Same</div>,
    children: [
      {
        title: '# Predictions',
        dataIndex: 'convo-same-predictions',
        key: 'convo-same-predictions',
        render: (predictions: number, record: SimulatorConvoTableRow) => (
          <div>
            {record.same.predictions}
          </div>
        ),
        sorter: (a: SimulatorConvoTableRow, b: SimulatorConvoTableRow) => a.same.predictions - b.same.predictions,
      },
      {
        title: '# Intents',
        dataIndex: 'convo-same-intents',
        key: 'convo-same-intents',
        render: (intents: number, record: SimulatorConvoTableRow) => (
          <div>
            {record.same.intents}
          </div>
        ),
        sorter: (a: SimulatorConvoTableRow, b: SimulatorConvoTableRow) => a.same.intents - b.same.intents,
      },
    ],
  },
  {
    title: <div data-type="convo-added" className={styles.simulatorDashboardCardTitle}>Added</div>,
    children: [
      {
        title: '# Predictions',
        dataIndex: 'convo-added-predictions',
        key: 'convo-added-predictions',
        render: (predictions: number, record: SimulatorConvoTableRow) => (
          <div>
            {record.added.predictions}
          </div>
        ),
        sorter: (a: SimulatorConvoTableRow, b: SimulatorConvoTableRow) => a.added.predictions - b.added.predictions,
      },
      {
        title: '# Intents',
        dataIndex: 'convo-added-predictions',
        key: 'convo-added-predictions',
        render: (intents: number, record: SimulatorConvoTableRow) => (
          <div>
            {record.added.intents}
          </div>
        ),
        sorter: (a: SimulatorConvoTableRow, b: SimulatorConvoTableRow) => a.added.intents - b.added.intents,
      },
    ],
  },
  {
    title: <div data-type="convo-removed" className={styles.simulatorDashboardCardTitle}>Removed</div>,
    children: [
      {
        title: '# Predictions',
        dataIndex: 'convo-removed-predictions',
        key: 'convo-removed-predictions',
        render: (predictions: number, record: SimulatorConvoTableRow) => (
          <div>
            {record.removed.predictions}
          </div>
        ),
        sorter: (a: SimulatorConvoTableRow, b: SimulatorConvoTableRow) => a.removed.predictions - b.removed.predictions,
      },
      {
        title: '# Intents',
        dataIndex: 'convo-removed-intents',
        key: 'convo-removed-intents',
        render: (intents: number, record: SimulatorConvoTableRow) => (
          <div>
            {record.removed.intents}
          </div>
        ),
        sorter: (a: SimulatorConvoTableRow, b: SimulatorConvoTableRow) => a.removed.intents - b.removed.intents,
      },
    ],
  },
];
export function SimulatorResultConvoTable({
  tableData,
  onRowClick,
}: Props) {
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(tableData);

  useEffect(() => {
    if (search) {
      const filtered = tableData.filter((row) => row.convoId.includes(search));
      setFilteredData(filtered);
    } else {
      setFilteredData(tableData);
    }
  }, [search, tableData]);
  return (
    <>
      <div className={styles.simulatorResultSelect}>
        <div className={styles.simulatorResultToggle} style={{ flex: 1 }}>
          <div style={{ flex: 1 }}>
            {`${filteredData.length} conversation${filteredData.length > 1 ? 's' : ''}`}
          </div>
          <div className={styles.simulatorResultFilter}>
            <Input
              className={styles.simulatorResultFilterInput}
              suffix={<SearchOutlined />}
              placeholder="Conversation ID"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.simulatorResultContent}>
        <div className={styles.tableContainer}>
          <Table
            columns={columns}
            className={tableStyles.simulatorResultIntentTable}
            dataSource={filteredData}
            rowKey="id"
            scroll={{ y: '70vh' }}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                onRowClick(record.convoId);
              },
            })}
          />
        </div>
      </div>
    </>
  );
}
