import { InfoCircleOutlined } from '@ant-design/icons';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { StartAutoAnalysisRequest, StartAutoAnalysisRequestAutoAnalysisType, StartAutoAnalysisResponse } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Alert, Anchor, Button, Group, Loader, LoadingOverlay, Modal, NumberInput, TextInput, Tooltip, Radio, Text } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { SerializedError } from '@reduxjs/toolkit';
import Loading from 'components/Loading';
import { openNotification } from 'components/Notification';
import dayjs from 'dayjs';
import { UserContext } from 'context/UserContext';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import React, { useEffect, useState, useContext } from 'react';
import { ConceptApi } from 'services/conceptApi';
import { ConversationApi } from 'services/conversationApi';
import { ApiStatus } from 'store/types';
import { useDispatch } from 'hooks/reduxHooks';
import { listJobs } from 'store/job/asyncThunks';
import { autoAnalysisListJobsFilters } from '..';

const DATE_RANGE_DIFF: { val: number, unit: dayjs.ManipulateType } = {
  val: 1,
  unit: 'month',
};

type AnalysisFormData = {
  conversationSetIds: string[];
  elasticSearchVersion: string;
  prevRegexPattern: string;
  hdbscanMinClusterSize: number;
  speakerRoles: ActorType[];
  autoAnalysisType: StartAutoAnalysisRequestAutoAnalysisType | undefined;
  maxConversationCount: number;
  minConversationLength: number;
  minAgentTurnCount: number;
  minVisitorTurnCount: number;
  retryPreviousWorkflow: 'true' | 'false';
}

const INITIAL_FORM_VALUES: AnalysisFormData = {
  conversationSetIds: [],
  elasticSearchVersion: '',
  prevRegexPattern: '',
  hdbscanMinClusterSize: 50,
  speakerRoles: [],
  autoAnalysisType: undefined,
  maxConversationCount: 1000,
  minConversationLength: 10,
  minAgentTurnCount: 5,
  minVisitorTurnCount: 5,
  retryPreviousWorkflow: 'false',
};

interface AutoAnalysisModalProps {
  onClose: () => void;
  visible?: boolean;
}

function esVersionFormatter(text: string) {
  return text.split(' ').join('_').toLowerCase();
}

const getFirstName = (fullName: string) => {
  const names = fullName?.split(' ');
  return names?.[0] || '';
};

const getDateRangeString = (from: Date, to: Date) => {
  if (from && to) {
    return `${dayjs(from).format('D-M')}-${dayjs(to).format('D-M-YYYY')}`;
  }
  return '';
};

export default function AutoAnalysisModal({
  onClose,
  visible,
}: AutoAnalysisModalProps) {
  const dateNow = new Date();
  const currentUser = useContext(UserContext);
  const [analysisStatus, setAnalysisStatus] = useState<ApiStatus>('idle');
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    dayjs(dateNow).subtract(DATE_RANGE_DIFF.val, DATE_RANGE_DIFF.unit).toDate(),
    dateNow,
  ]);
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const [convCount, setConvCount] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [response, setResponse] = useState<StartAutoAnalysisResponse>();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible && dateRange[0] && dateRange[1]) {
      getConversationsCount();
    }
  }, [dateRange, visible]);

  useEffect(() => {
    if (visible && currentUser?.fullName && dateRange?.[0] && dateRange?.[1]) {
      const name = getFirstName(currentUser?.fullName);
      const date = getDateRangeString(dateRange[0], dateRange[1]);
      form.setFieldValue('elasticSearchVersion', `${name}_version_${date}`.toLowerCase());
    }
  }, [dateRange?.[0], dateRange?.[1], visible]);

  const form = useForm<AnalysisFormData>({
    initialValues: { ...INITIAL_FORM_VALUES },
    validate: {},
  });

  const resetModal = () => {
    form.setValues(INITIAL_FORM_VALUES);
    setConvCount(null);
    setShowAdvancedSettings(false);
  };

  const hasConversationsSets = form.values.conversationSetIds?.length;

  const getConversationsCount = async () => {
    setConvCount(null);
    try {
      const response = await ConversationApi.listConversations({
        parent: customerProfile,
        countOnly: true,
        filter: {
          beginTime: dateRange[0].toISOString(),
          endTime: dateRange[1].toISOString(),
          usecase,
          languageCode: customer.languageCode,
        },
      });
      const convCount = response.conversationsCount;
      setConvCount(convCount);
      if (form.values.maxConversationCount == null) {
        form.setFieldValue('maxConversationCount', convCount);
      }
    } catch (err) {
      openNotification('error', 'Failed to load conversations', undefined, err);
    }
  };

  const handleSubmit = async () => {
    if (form.validate().hasErrors) {
      return;
    }
    setAnalysisStatus('loading');
    setErrorMessage(null);
    try {
      const request: StartAutoAnalysisRequest = {
        profile: customerProfile,
        usecaseId: customer.usecaseId,
        languageCode: customer.languageCode,
        conversationSetIds: form.values.conversationSetIds,
        elasticSearchVersion: form.values.elasticSearchVersion,
        speakerRoles: form.values.speakerRoles,
        prevRegexPattern: hasConversationsSets ? undefined : form.values.prevRegexPattern,
        hdbscanMinClusterSize: form.values.hdbscanMinClusterSize,
        startTime: hasConversationsSets ? dayjs().subtract(1, 'years').toISOString() : dateRange[0].toISOString(),
        endTime: hasConversationsSets ? dayjs().toISOString() : dateRange[1].toISOString(),
        autoAnalysisType: form.values.autoAnalysisType,
        maxConversationCount: form.values.maxConversationCount,
        minConversationLength: form.values.minConversationLength,
        minAgentTurnCount: form.values.minAgentTurnCount,
        minVisitorTurnCount: form.values.minVisitorTurnCount,
        retryPreviousWorkflow: form.values.retryPreviousWorkflow === 'true',
      };
      const response = await ConceptApi.startAutoAnalysis(request);
      dispatch(listJobs({
        parent: customerProfile,
        filters: autoAnalysisListJobsFilters,
      }));
      setResponse(response);
      setAnalysisStatus('succeeded');
    } catch (err) {
      setAnalysisStatus('failed');
      setErrorMessage((err as SerializedError)?.message);
    }
  };

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  const formHasError = false;
  const profileType = customer.profileId.includes('voice') ? 'voice' : 'chat';
  const link = `https://temporal.${profileType}-prod.internal.cresta.ai/namespaces/${response?.nameSpace}/workflows/${response?.workflowId}/${response?.runId}/summary`;

  return (
    <Modal
      title="New analysis"
      opened={visible}
      onClose={() => {
        resetModal();
        onClose();
      }}
      size="500px"
    >
      {analysisStatus === 'succeeded' && (
        <Alert title="Autoanalysis Started" color="green" mb="lg">
          <Anchor href={link} target="_blank">{link}</Anchor>
        </Alert>
      )}
      {analysisStatus === 'failed' && (
        <Alert title="Error" color="red" mb="lg">
          {errorMessage}
        </Alert>
      )}
      <form>
        <TextInput
          label="Version name"
          placeholder="Version"
          {...form.getInputProps('elasticSearchVersion')}
          onChange={(e) => {
            form.setFieldValue('elasticSearchVersion', esVersionFormatter(e.target.value));
          }}
        />
        <DatePickerInput
          type="range"
          mt="md"
          label="Conversations from this time range"
          placeholder="Pick dates range"
          value={dateRange}
          onChange={setDateRange}
        />

        <Text size="md" mt="lg">Total # of conversations in selected date range:</Text>
        <Text size="md">{convCount != null ? Number(convCount).toLocaleString() : <Loading/>}</Text>
        <NumberInput
          mt="lg"
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          label="Max number of conversations"
          {...form.getInputProps('maxConversationCount')}
          formatter={(value) =>
            (!Number.isNaN(parseFloat(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '')}
        />
        {
          form.values.maxConversationCount > 10000 && (
            <Alert color="orange" mt="xs">
              For best performance, please limit the number of max. conversations to 10,000.
            </Alert>
          )
        }
        {
          form.values.maxConversationCount < 100 && (
            <Alert color="orange" mt="xs">
              To get a representative result, please choose a number that’s larger than 100.
            </Alert>
          )
        }
        <Anchor
          size="sm"
          mt="lg"
          type="submit"
          onClick={toggleAdvancedSettings}
        >{showAdvancedSettings ? 'Hide' : 'Show'} advanced settings
        </Anchor>

        {
          showAdvancedSettings && (
            <>
              <Radio.Group
                label="Retry previous workflow"
                mt="lg"
                {...form.getInputProps('retryPreviousWorkflow')}
              >
                <Group>
                  <Radio value="false" label="False" />
                  <Radio value="true" label="True" />
                </Group>
              </Radio.Group>

              <NumberInput
                mt="lg"
                label="Min # of turns in convo"
                {...form.getInputProps('minConversationLength')}
              />

              <Group grow mt="lg">
                <NumberInput
                  label="Min # of visitor turns"
                  {...form.getInputProps('minAgentTurnCount')}
                />
                <NumberInput
                  label="Min # of agent turns"
                  {...form.getInputProps('minVisitorTurnCount')}
                />
              </Group>

              <NumberInput
                mt="md"
                label={(
                  <div>
                    <span>Min. cluster size for HDBSCAN</span>
                    <Tooltip
                      width={300}
                      multiline
                      label="HDBSCAN is the primary parameter to effect the resulting clustering. Ideally this is a relatively intuitive parameter to select – set it to the smallest size grouping that you wish to consider a cluster."
                    ><InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </div>
                )}
                placeholder=""
                {...form.getInputProps('hdbscanMinClusterSize')}
              />
              {!hasConversationsSets && (
                <TextInput
                  mt="md"
                  label={(
                    <div>
                      <span>Prev. regex pattern</span>
                      <Tooltip
                        width={300}
                        multiline
                        label="Prev regex pattern is the previous regex pattern you want to match. In the case of topic, we use (how\s+(may\can)\s+i\s+(assist\help))|looking for"
                      ><InfoCircleOutlined style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </div>
                    )}
                  placeholder="Regex"
                  {...form.getInputProps('prevRegexPattern')}
                />
              )}
            </>
          )
        }

        <Group position="right" mt="xl">
          <Button
            variant="subtle"
            onClick={() => {
              resetModal();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={formHasError}
            onClick={handleSubmit}
          >
            Start
          </Button>
        </Group>
      </form>
      <LoadingOverlay loader={<Loader size="sm" />} visible={analysisStatus === 'loading'} overlayBlur={2} />
    </Modal>
  );
}
