import React, { useMemo } from 'react';
import { Accordion, Stack, Text } from '@mantine/core';
import useUrlParam from 'hooks/useUrlParam';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import CopyableValue from 'components/CopyableValue';
import { selectDerivedLabelingTasksMap } from 'store/labelingTask/selectors';
import { useSelector } from 'hooks/reduxHooks';
import { getId } from 'common/resourceName';
import { EvalStep } from '../components/Steps';
import { convertEvaluationStatResultsToTableData, convertTestSetScoresToTableData, QATable, QuantitativeScoresTable } from '../components/Tables';
import { AccordionSubheader } from './AccordionSubheader';
import { useModelWorkflow } from '../useModelWorkflow';

interface Props {
  modelWorkflows: ModelWorkflow[]
}

export const EnsembleEvaluate = (props: Props) => {
  const [workflowId] = useUrlParam<string>('workflowId');
  const { modelWorkflows } = props;
  const modelWorkflow = useModelWorkflow(workflowId);
  const qaTasksMap = useSelector(selectDerivedLabelingTasksMap);

  const generativeModel = useMemo(() => {
    if (!modelWorkflow) return null;
    const generativeModel = modelWorkflows.find((model) => {
      const generativeModelPath = model.trainSuggestionsGenerativeModelOutput?.trainOutputs?.modelUri;
      const generativeGptModelPath = modelWorkflow?.packageSuggestionsEnsembleConfig?.suggestionsEnsembleInputs?.generativeGptModelPath;
      const generativeT5ModelPath = modelWorkflow?.packageSuggestionsEnsembleConfig?.suggestionsEnsembleInputs?.generativeT5ModelPath;
      const isGPT = generativeModelPath === generativeGptModelPath;
      const isT5 = generativeModelPath === generativeT5ModelPath;
      return isGPT || isT5;
    });
    return generativeModel;
  }, [modelWorkflow]);

  const retrievalModel = useMemo(() => {
    if (!modelWorkflow) return null;
    const retrievalModel = modelWorkflows.find((model) => {
      const retrievalModelPath = model.trainSuggestionsRetrieverOutput?.ensembleModelUri;
      return retrievalModelPath;
    });
    return retrievalModel;
  }, [modelWorkflow]);

  const quantitaveScoresTableDataGenerative = useMemo(() => convertTestSetScoresToTableData(generativeModel?.trainSuggestionsGenerativeModelOutput?.trainOutputs?.testSetScores || {}), [generativeModel]);
  const selectionInstructionGenerative = generativeModel?.trainSuggestionsGenerativeModelConfig?.selectionInstruction;
  const generativeQaTask = qaTasksMap.get(getId('labelingTask', generativeModel?.trainSuggestionsGenerativeModelOutput?.qaTask?.labelingTask?.name));

  const quantitaveScoresTableDataRetrieval = useMemo(() => convertEvaluationStatResultsToTableData(retrievalModel?.trainSuggestionsRetrieverOutput?.trainOutputs?.evaluationStats?.results || []), [retrievalModel]);
  const selectionInstructionRetrieval = retrievalModel?.trainSuggestionsRetrieverConfig?.selectionInstruction;
  const model = modelWorkflow.packageSuggestionsEnsembleOutput.suggestionsEnsembleModelUri;
  const retrievalQaTask = qaTasksMap.get(getId('labelingTask', retrievalModel?.trainSuggestionsRetrieverOutput?.qaTask?.labelingTask?.name));

  return (
    <EvalStep
      modelWorkflows={modelWorkflows}
    >
      <Stack style={{ flex: 1 }}>
        {model && <CopyableValue copiedValue={model} displayValue={model} />}
        <Text>Submodel scores</Text>
        <Accordion
          chevronPosition="left"
          styles={{
            item: {
              marginBottom: '10px',
              borderRadius: '5px',
              backgroundColor: '#fff',
            },
          }}
        >
          <Accordion.Item value="customization">
            <Accordion.Control>{generativeModel?.trainSuggestionsGenerativeModelOutput?.trainOutputs?.modelUri}</Accordion.Control>
            <Accordion.Panel>
              <Stack>
                <AccordionSubheader updateTime={generativeModel?.abstractWorkflow?.updateTime} />
                <Text>Quantitative scores</Text>
                <QuantitativeScoresTable dataSource={quantitaveScoresTableDataGenerative} />
                {selectionInstructionGenerative && (
                  <>
                    <Text>QA Scores</Text>
                    <QATable selectionInstruction={selectionInstructionGenerative} qaTask={generativeQaTask?.labelingTask} />
                  </>
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          {modelWorkflow?.packageSuggestionsEnsembleConfig?.suggestionsEnsembleInputs?.retrieverModelPath && (
          <Accordion.Item value="customization">
            <Accordion.Control>{retrievalModel?.trainSuggestionsRetrieverOutput?.ensembleModelUri}</Accordion.Control>
            <Accordion.Panel>
              <Stack>
                <AccordionSubheader updateTime={retrievalModel?.abstractWorkflow?.updateTime} />
                <Text>Quantitative scores</Text>
                <QuantitativeScoresTable dataSource={quantitaveScoresTableDataRetrieval} />
                {selectionInstructionRetrieval && (
                  <>
                    <Text>QA Scores</Text>
                    <QATable selectionInstruction={selectionInstructionRetrieval} qaTask={retrievalQaTask?.labelingTask} />
                  </>
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          )}
        </Accordion>
      </Stack>
    </EvalStep>
  );
};
