import React from 'react';
import { Overlay, ScrollArea, Stack, TextInput, Title } from '@mantine/core';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { AddNewTag } from './AddNewTag';
import { TagList } from './TagList';
import { TagSelectItem, UITagType } from '../..';

interface Props {
  handleStageSelection: (tagId: string) => void;
  handleTagSelection: (tagId: string) => void;
  isComplete: boolean;
  searchInputRef: React.RefObject<HTMLInputElement>;
  search: string;
  setSearch: (search: string) => void;
  highlightedTagId: string;
  tagsInitializing: boolean;
  conceptIdsOnCurrentMessage: string[];
  onAddNewTagToTask: (query: string, tagType: TagTagType) => Promise<void>;
  removeTagIdFromTask: (tagId: string) => Promise<void>;
  handleEnterPress: (inputValue: string, type: TagTagType) => void;
  // Tags selected for task
  stageTags: UITagType[];
  driverTags: UITagType[];
  outcomeTags: UITagType[];
  behaviorTags: UITagType[];
  // Tags created for profile but not added to task
  stageTagSelectData: TagSelectItem[];
  driverTagSelectData: TagSelectItem[];
  outcomeTagSelectData: TagSelectItem[];
  behaviorTagSelectData: TagSelectItem[];
  searchInputOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  behaviorTagType: TagTagType;
}

export const TagSelection = (props: Props) => {
  const {
    handleStageSelection,
    handleTagSelection,
    isComplete,
    searchInputRef,
    search,
    setSearch,
    highlightedTagId,
    tagsInitializing,
    conceptIdsOnCurrentMessage,
    onAddNewTagToTask,
    removeTagIdFromTask,
    handleEnterPress,
    // Tags selected for task
    stageTags,
    driverTags,
    outcomeTags,
    behaviorTags,
    // Tags created for profile but not added to task
    stageTagSelectData,
    driverTagSelectData,
    outcomeTagSelectData,
    behaviorTagSelectData,
    searchInputOnKeyDown,
    behaviorTagType,
  } = props;
  return (
    <>
      <Title order={6}>Select all relevant tags for this message</Title>
      <TextInput
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        placeholder="Search"
        onKeyDown={searchInputOnKeyDown}
        ref={searchInputRef}
      />
      <ScrollArea style={{ flex: 1 }}>
        {isComplete && <Overlay />}
        <Stack>
          <TagList
            initializing={tagsInitializing}
            heading="Stages"
            onTagClick={(tag) => handleStageSelection(tag.value)}
            tags={stageTags}
            conceptIdsOnCurrentMessage={conceptIdsOnCurrentMessage}
            removeTagIdFromTask={removeTagIdFromTask}
            highlightedTagId={highlightedTagId}
          >
            <AddNewTag
              onAddNewTagToTask={(query) => onAddNewTagToTask(query, TagTagType.STAGE)}
              tagSelectData={stageTagSelectData}
              handleEnterPress={handleEnterPress}
              tagType={TagTagType.STAGE}
            />
          </TagList>
          {behaviorTagType === TagTagType.VISITOR_INTENT && (
          <TagList
            initializing={tagsInitializing}
            heading="Drivers"
            onTagClick={(tag) => handleTagSelection(tag.value)}
            tags={driverTags}
            conceptIdsOnCurrentMessage={conceptIdsOnCurrentMessage}
            removeTagIdFromTask={removeTagIdFromTask}
            highlightedTagId={highlightedTagId}
          >
            <AddNewTag
              onAddNewTagToTask={(query) => onAddNewTagToTask(query, TagTagType.DRIVER)}
              tagSelectData={driverTagSelectData}
              handleEnterPress={handleEnterPress}
              tagType={TagTagType.DRIVER}
            />
          </TagList>
          )}
          <TagList
            initializing={tagsInitializing}
            heading="Outcomes"
            onTagClick={(tag) => handleTagSelection(tag.value)}
            tags={outcomeTags}
            conceptIdsOnCurrentMessage={conceptIdsOnCurrentMessage}
            removeTagIdFromTask={removeTagIdFromTask}
            highlightedTagId={highlightedTagId}
          >
            <AddNewTag
              onAddNewTagToTask={(query) => onAddNewTagToTask(query, TagTagType.CONVERSATION_OUTCOME)}
              tagSelectData={outcomeTagSelectData}
              handleEnterPress={handleEnterPress}
              tagType={TagTagType.CONVERSATION_OUTCOME}
            />
          </TagList>
          <TagList
            initializing={tagsInitializing}
            heading="Behaviors"
            tags={behaviorTags}
            onTagClick={(tag) => handleTagSelection(tag.value)}
            conceptIdsOnCurrentMessage={conceptIdsOnCurrentMessage}
            removeTagIdFromTask={removeTagIdFromTask}
            highlightedTagId={highlightedTagId}
          >
            <AddNewTag
              onAddNewTagToTask={(query) => onAddNewTagToTask(query, behaviorTagType)}
              tagSelectData={behaviorTagSelectData}
              handleEnterPress={handleEnterPress}
              tagType={behaviorTagType}
            />
          </TagList>
        </Stack>
      </ScrollArea>
    </>
  );
};
