import { Concept, ExportConceptToCXRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Alert, Button, Group, Modal, Select, SelectItem, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { ConceptApi } from 'services/conceptApi';
import { ApiStatus } from 'store/types';

interface ExportConceptModalProps {
  isOpen: boolean;
  onClose: () => void;
  concepts: Concept[];
  concept: Concept;
}

export default function ExportConceptModal({ isOpen, onClose, concept, concepts }: ExportConceptModalProps) {
  const [exportConceptStatus, setExportConceptStatus] = useState<ApiStatus>('idle');
  const exportConceptForm = useForm<ExportConceptToCXRequest>({
    initialValues: {
      concept: '',
      cxProjectId: '',
      cxLocationId: '',
      cxAgentId: '',
    },
  });

  // Reset on close
  useEffect(() => {
    if (!isOpen) {
      exportConceptForm.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    exportConceptForm.setFieldValue('concept', concept?.name);
  }, [concept?.name]);

  const exportConceptToCX = async () => {
    const request: ExportConceptToCXRequest = {
      ...exportConceptForm.values,
    };
    setExportConceptStatus('loading');
    try {
      await ConceptApi.exportConceptToCX(request);
      setExportConceptStatus('succeeded');
    } catch {
      setExportConceptStatus('failed');
    }
  };

  return (
    <Modal
      title="Export to Dialogflow"
      opened={isOpen}
      onClose={onClose}
      centered
    >
      {exportConceptStatus === 'failed' && (
        <Alert title="Error" color="red" mb="lg">
          Export to Dialogflow failed
        </Alert>
      )}
      {exportConceptStatus === 'succeeded' && (
        <Alert title="Success!" color="green" mb="lg">
          Intent has been exported to Dialogflow
        </Alert>
      )}
      <Select
        label="Concept"
        {...exportConceptForm.getInputProps('concept')}
        data={concepts.map((concept) => {
          const item: SelectItem = {
            label: concept.conceptTitle,
            value: concept.name,
          };
          return item;
        })}
      />
      <TextInput
        mt="lg"
        label="CX Project ID"
        placeholder="Type here"
        {...exportConceptForm.getInputProps('cxProjectId')}
      />
      <TextInput
        mt="lg"
        label="CX Location ID"
        placeholder="Type here"
        {...exportConceptForm.getInputProps('cxLocationId')}
      />
      <TextInput
        mt="lg"
        label="CX Agent ID"
        placeholder="Type here"
        {...exportConceptForm.getInputProps('cxAgentId')}
      />
      <Group position="right" mt="lg">
        <Button variant="subtle" onClick={onClose} disabled={exportConceptStatus === 'loading'}>Cancel</Button>
        <Button onClick={exportConceptToCX} loading={exportConceptStatus === 'loading'}>Export</Button>
      </Group>
    </Modal>
  );
}
