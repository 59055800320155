import React, { useCallback, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Form, FormInstance, Input, Checkbox, Radio } from 'antd';
import { Button, UnstyledButton } from '@mantine/core';
import TextArea from 'antd/lib/input/TextArea';
import useUrlParam from 'hooks/useUrlParam';
import classNames from 'classnames';
import { EditorButton as HintTimingEditorButton } from '../SimulatorModel/HintTimingModelConfigEditor';
import {
  ModelInputData,
  MODEL_URI_FIELDS,
  ACTION_STACK_IMAGE_FIELDS,
  ORCH_STACK_IMAGE_FIELDS,
} from '../SimulatorModel/ModelSetUp';
import styles from '../SimulatorModel/style.module.scss';

interface IProps {
  displayOnly?: boolean;
  onClose: () => void;
  visible: boolean;
  handleUseProd: (firstModel: boolean) => void;
  firstForm: FormInstance<ModelInputData>;
  secondForm: FormInstance<ModelInputData>;
}
export const SimulatorResultDrawer: React.FC<IProps> = ({
  displayOnly,
  onClose,
  visible,
  handleUseProd,
  firstForm,
  secondForm,
}) => {
  const [editingMode] = useUrlParam<string>('editingMode');
  const [showFirstSettings, setShowFirstSettings] = useState<boolean>(false);
  const firstActionStack = Form.useWatch('useActionStack', firstForm);
  const firstPolicySnapshot = Form.useWatch<'master' | 'draft' | ''>('usePolicySnapshot', firstForm);
  const [showSecondSettings, setShowSecondSettings] = useState<boolean>(false);
  const secondActionStack = Form.useWatch('useActionStack', secondForm);
  const secondPolicySnapshot = Form.useWatch<'master' | 'draft' | ''>('usePolicySnapshot', secondForm);

  const formComponent = useCallback((first: boolean, showSettings: boolean, useActionStack: boolean, useDialoguePolicy: 'master' | 'draft' | '') => (
    <>
      <div className={styles.simulatorSetModelCompare}>
        <span className={styles.simulatorSetModelCompareTitle}>Model {first ? '1' : '2'}</span>
        {!displayOnly && <div onClick={() => handleUseProd(first)} className={styles.simulatorSetModelProd}>Use prod</div>}
      </div>
      <div className={styles.simulatorSetModelFormTitle}>
        Enter Model URLs
      </div>
      <div className={styles.simulatorSetModelForm}>
        {MODEL_URI_FIELDS.map((item) => (
          <Form.Item key={item.name} className={styles.simulatorSetModelFormItem} name={item.name} label={item.label}>
            <TextArea allowClear placeholder="Enter model URL" disabled={displayOnly} />
          </Form.Item>
        ))}
        <Form.Item
          key="policyURL"
          className={classNames([styles.simulatorSetModelFormItem, styles.policyURLItem])}
          name="policyURL"
          label="Policy model URL"
        >
          <TextArea
            disabled={displayOnly || !!editingMode || !!useDialoguePolicy}
            allowClear
            placeholder="Enter model URL"
          />
        </Form.Item>
        <Form.Item
          className={styles.toggleDraftPolicy}
          name="usePolicySnapshot"
        >
          <Radio.Group
            className={styles.toggleDraftPolicy}
            disabled={displayOnly}
          >
            <Radio value="">URL</Radio>
            <Radio value="master">Master</Radio>
            <Radio value="draft">Draft</Radio>
          </Radio.Group>
        </Form.Item>
        <Button
          className={styles.simulatorShowTagInput}
          variant="subtle"
          onClick={() => {
            if (first) {
              setShowFirstSettings(!showSettings);
            } else {
              setShowSecondSettings(!showSettings);
            }
          }}
        >
          {showSettings ? 'Hide' : 'Show'} image overrides
        </Button>
        {showSettings && (
          <>
            {firstForm.getFieldsValue().useActionStack}
            <Form.Item
              className={styles.simulatorSetModelFormItem}
              name="useActionStack"
              valuePropName="checked"
            >
              <Checkbox disabled={displayOnly}>Use action server stack</Checkbox>
            </Form.Item>
            <Form.Item
              className={styles.simulatorSetModelFormItem}
              name="hintModelUriToConfig"
            >
              <HintTimingEditorButton />
            </Form.Item>
            {(useActionStack ? ACTION_STACK_IMAGE_FIELDS : ORCH_STACK_IMAGE_FIELDS).map((imageItem) => (
              <Form.Item
                key={imageItem.label}
                className={styles.simulatorSetModelFormItem}
                name={imageItem.name}
                label={imageItem.label}
              >
                <Input allowClear placeholder={imageItem.placeholder} disabled={displayOnly} />
              </Form.Item>
            ))}
          </>
        )}
      </div>
    </>
  ), [
    handleUseProd,
    displayOnly,
    editingMode,
  ]);

  return (
    <Drawer
      title={(
        <div className={styles.simulatorResultDrawerTitle}>
          <UnstyledButton onClick={onClose}>
            <CloseOutlined />
          </UnstyledButton>
          <span className={styles.simulatorResultDrawerTitleText}>Model Setup</span>
        </div>
      )}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={664}
    >
      <div className={styles.simulatorResultDrawer}>
        <Form
          form={firstForm}
          layout="vertical"
          className={styles.simulatorResultFormWrapper}
        >
          <div className={classNames([styles.simulatorResultForm, styles.left])}>
            {formComponent(true, showFirstSettings, firstActionStack, firstPolicySnapshot)}
          </div>
        </Form>
        <Form
          form={secondForm}
          layout="vertical"
          className={styles.simulatorResultFormWrapper}
        >
          <div className={styles.simulatorResultForm}>
            {formComponent(false, showSecondSettings, secondActionStack, secondPolicySnapshot)}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};
