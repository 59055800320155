import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import './Intent.scss';
import { Input, Card, Tooltip, Button, Checkbox, Divider, useMantineTheme, Group, Accordion, Box, ActionIcon } from '@mantine/core';
import {
  Concept,
  ConceptConceptSource,
  ConceptConceptTagType,
  IntentIntentType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { useExpansionState } from 'hooks/useExpansionState';
import { CaretDownOutlined, CaretUpOutlined, ControlOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import ConceptTag from 'components/ConceptTag';
import { ModalType } from 'components/AddConceptModal';
import { isIntent } from 'components/TaxonomyTree';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';
import useUrlParam from 'hooks/useUrlParam';
import { HIDDEN_TAGS } from 'pages/Taxonomy/TaxonomyGroup/TaxonomyTab';
import { ProdIntent } from 'store/modelBuilder/state';
import Loading from 'components/Loading';

/** Filters for conversation outcome concepts. */
export interface IntentFilters {
  type?: IntentIntentType;
  tags?: ConceptConceptTagType[];
  isOOBCapable?: boolean;
  isCrossCustomer?: boolean;
  isInactive?: boolean;
}

export const DEFAULT_DRIVER_ID = 'default_driver_id';
const DEFAULT_DRIVER_CONCEPT: Concept = {
  conceptTitle: 'default',
  intent: {
    intentType: IntentIntentType.CONVERSATION_DRIVER,
  },
};

interface Node {
  id: string;
  concept: Concept;
  class: 'driver' | 'stage' | 'intent';
  parent?: Node;
  children: Node[];
  hidden: boolean;
}

interface Props {
  filters: IntentFilters;
  setFilters: (filter: IntentFilters) => void;
  data: Concept[];
  prodIntents: ProdIntent[];
  prodIntentsLoading?: boolean;
  onFocus: (concept: Concept) => void;
  onAdd: (modalType: ModalType, parent?: Concept, driver?: Concept) => void;
  showTags?: boolean;
}
export function Intent({
  filters,
  setFilters,
  data,
  prodIntents,
  prodIntentsLoading,
  onFocus,
  onAdd,
  showTags,
}: Props): ReactElement {
  const [searchText, setSearchText] = useState<string>('');
  const theme = useMantineTheme();
  const [visibleFilters, setVisibleFilters] = useState<'intentFilters' | null>(null);

  // Prod intents from serving model
  const prodIntentsMap = useMemo(() => new Map<string, ProdIntent>(
    prodIntents.map((prodIntent) => ([prodIntent.intent, prodIntent])),
  ), [prodIntents]);

  // Check if intent is inactive (not in prod)
  const isInactive = useCallback((concept: Concept) => {
    const isIntent = [IntentIntentType.AGENT_INTENT, IntentIntentType.VISITOR_INTENT].includes(concept.intent.intentType);
    if (!isIntent || prodIntents?.length === 0) return false;

    return !prodIntentsMap?.get(concept.conceptTitle);
  }, [prodIntents, prodIntentsMap]);

  // Build nodes from the input data.
  const nodes = useMemo(() => {
    const hiddenPredicate = (concept: Concept) => {
      const isShared = concept?.conceptSource === ConceptConceptSource.SHARED;
      const isCrossCustomer = concept?.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
      const isInactiveIntent = isInactive(concept);

      if (filters.isOOBCapable && !isShared) {
        return true;
      }
      if (!filters.isCrossCustomer && isCrossCustomer) {
        return true;
      }
      if (!filters.isInactive && isInactiveIntent) {
        return true;
      }
      if (searchText && !concept.conceptTitle.includes(searchText)) {
        return true;
      }
      if (filters.type && concept.intent.intentType !== filters.type) {
        return true;
      }
      if (filters.tags
        && filters.tags.length > 0
        && !concept.conceptTags?.filter((value) => filters.tags.includes(value)).length) {
        return true;
      }
      return false;
    };
    // Drivers.
    const driverNodes: Node[] = [
      {
        id: DEFAULT_DRIVER_ID,
        concept: DEFAULT_DRIVER_CONCEPT,
        class: 'driver',
        hidden: hiddenPredicate(DEFAULT_DRIVER_CONCEPT),
        children: [],
      },
    ];
    data.filter((concept) =>
      concept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER)
      .map((concept) => ({
        id: getId('concept', concept.name),
        concept,
        class: 'driver',
        children: [],
        hidden: hiddenPredicate(concept),
      } as Node)).forEach((node) => driverNodes.push(node));
    // Stages.
    data.filter((concept) => concept.intent.intentType === IntentIntentType.STAGE).forEach((concept) => {
      const id = getId('concept', concept.name);
      const parentDriverNodes = driverNodes.filter((node) => concept.intent.parentConversationDriverNames.includes(node.id));
      if (!parentDriverNodes.length) {
        parentDriverNodes.push(driverNodes[0]);
      }
      parentDriverNodes.forEach((parent) => {
        const stageNode: Node = {
          id: `${parent.id}|${id}`,
          concept,
          class: 'stage',
          parent,
          children: [],
          hidden: hiddenPredicate(concept),
        };
        parent.children.push(stageNode);
      });
    });
    const orders = new Map<string, number>([['open', -1], ['close', 1], ['inform', 2]]);
    driverNodes.forEach((driver) => {
      driver.children.sort((s1, s2) => {
        const v1 = orders.get(s1.concept.conceptTitle) || 0;
        const v2 = orders.get(s2.concept.conceptTitle) || 0;
        if (v1 !== v2) {
          return v1 - v2;
        }
        // v1 === v2
        if (v1 === 0) {
          return s1.concept.conceptTitle.localeCompare(s2.concept.conceptTitle);
        }
        return 0;
      });
    });
    // Intents.
    data.filter((concept) => isIntent(concept) && !hiddenPredicate(concept)).forEach((concept) => {
      const id = getId('concept', concept.name);
      const parentDriverNodes = driverNodes.filter((node) => concept.intent.parentConversationDriverNames.includes(node.id));
      if (!parentDriverNodes.length) {
        parentDriverNodes.push(driverNodes[0]);
      }
      parentDriverNodes.forEach((driver) => {
        const parent = driver.children?.find((node) => concept.conceptTitle.split('.')[0] === node.concept.conceptTitle);
        const intentNode: Node = {
          id: `${driver.id}|${id}`,
          concept,
          parent: parent || driver,
          class: 'intent',
          children: [],
          hidden: hiddenPredicate(concept),
        };
        if (!parent) {
          driver.children.unshift(intentNode);
        } else {
          parent.children.push(intentNode);
        }
      });
    });
    return driverNodes;
  }, [data, searchText, filters]);

  // Track the expansion state.
  const [isExpanded, flipExpansionState] = useExpansionState(nodes);

  // Focus a conversation outcome concept.
  const [focusedId, setFocusedId] = useUrlParam<string>('node', '');
  useEffect(() => {
    let id = focusedId;
    if (focusedId.includes('|')) {
      id = focusedId.split('|')[1];
    }
    const focusedConcept = data.find((c) => getId('concept', c.name) === id);
    if (focusedConcept) {
      onFocus(focusedConcept);
    }
  }, [data, focusedId]);
  const [hoverId, setHoverId] = useState<string>('');

  const conceptRole = (node: Node) => {
    if (node.class === 'intent') {
      if (node.concept.intent.intentType === IntentIntentType.AGENT_INTENT) {
        return 'agent';
      }
      return 'visitor';
    }
    // eslint-disable-next-line consistent-return
    return undefined;
  };

  const renderNode = (node: Node) => (
    <>
      <div
        key={node.id}
        className={classNames([
          'intent-concepts-item',
          node.id === focusedId ? 'focused' : '',
          node.class,
        ])}
        onClick={(e) => {
          e.stopPropagation();
          setFocusedId(node.id);
        }}
        onMouseEnter={() => setHoverId(node.id)}
        onMouseLeave={() => setHoverId('')}
      >
        {node.class !== 'intent' && (
          <div
            className="expansion-button"
            onClick={(e) => {
              e.stopPropagation();
              flipExpansionState(node);
            }}
          >
            {isExpanded(node) ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
        )}
        <ConceptTag
          role={conceptRole(node)}
          value={node.class === 'driver' ? `Driver: ${node.concept.conceptTitle}` : node.concept.conceptTitle}
          count={node.class === 'stage' ? node.children.length : undefined}
        />
        <Group spacing="sm" ml="xs">
          {isInactive(node.concept) && (
          <MultiTags
            tags={['Inactive']}
            color="red"
            size="small"
            type="conceptTag"
          />
          )}
          {node.concept.conceptSource === ConceptConceptSource.SHARED && (
          <MultiTags
            tags={['OOB']}
            type="conceptTag"
            color="gray"
            size="small"
          />
          )}
          {node.concept.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING) && (
          <MultiTags
            tags={['Cross-customer']}
            type="conceptTag"
            color="lightgreen"
            size="small"
          />
          )}
          {showTags && (
          <MultiTags
            tags={node.concept.conceptTags?.filter((tag) => !HIDDEN_TAGS.includes(tag)).map((tag) => toTitleCase(tag))}
            type="conceptTag"
            size="small"
          />
          )}
        </Group>
        <Box ml="auto">
          {node.class !== 'intent' && node.id === hoverId && (
            <Tooltip label={node.class === 'driver' ? 'Add stage' : 'Add intent'}>
              <Button
                variant="subtle"
                data-cy="plus-button"
                onClick={() => onAdd(
                  node.class === 'driver' ? 'simple' : 'library',
                  node.concept,
                  node.parent?.concept,
                )}
              >
                <PlusCircleOutlined />
              </Button>
            </Tooltip>
          )}
        </Box>
      </div>
      {isExpanded(node) && !!node.children.length && node.children.map((child) => renderNode(child))}
    </>
  );
  return (
    <Card radius="lg" p="lg">
      <Accordion
        unstyled
        value={visibleFilters}
        disableChevronRotation
        chevron={null}
        styles={{
          control: {
            width: '100%',
            border: 'none',
            background: 'none',
            padding: 0,
          },
        }}
      >
        <Accordion.Item value="intentFilters">
          <Accordion.Control onClick={(e) => e.stopPropagation()}>
            <Group position="apart" pb={6} noWrap>
              <Input
                icon={<SearchOutlined />}
                variant="unstyled"
                placeholder="Search"
                style={{ width: '100%' }}
                onChange={(e) => setSearchText(e.target.value)}
                onClick={() => setVisibleFilters('intentFilters')}
              />
              {prodIntentsLoading ? (
                <Loading/>
              ) : (
                <ActionIcon
                  variant="light"
                  color="blue"
                  onClick={() => {
                    setVisibleFilters(visibleFilters === 'intentFilters' ? null : 'intentFilters');
                  }}
                ><ControlOutlined style={{ fontSize: 20 }} />
                </ActionIcon>
              )}
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Divider color={theme.colors.gray[1]}/>
            <Checkbox
              label="Show OOB-capable only"
              py={8}
              styles={{
                root: {
                  lineHeight: 1,
                },
              }}
              checked={filters.isOOBCapable}
              onChange={(e) => setFilters({
                ...filters,
                isOOBCapable: e.target.checked,
              })}
            />
            <Divider color={theme.colors.gray[1]}/>
            <Checkbox
              label="Show cross-customer labeling intents"
              py={8}
              styles={{
                root: {
                  lineHeight: 1,
                },
              }}
              checked={filters.isCrossCustomer}
              onChange={(e) => setFilters({
                ...filters,
                isCrossCustomer: e.target.checked,
              })}
            />
            <Divider color={theme.colors.gray[1]}/>
            <Checkbox
              label="Show inactive intents"
              py={8}
              styles={{
                root: {
                  lineHeight: 1,
                },
              }}
              checked={filters.isInactive}
              onChange={(e) => setFilters({
                ...filters,
                isInactive: e.target.checked,
              })}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Divider color={theme.colors.gray[1]}/>
      <div className="intent-concepts-content">
        {nodes.length === 0 && (<div className="placeholder">No intent.</div>)}
        {nodes.map((node) => renderNode(node))}
        <div
          className="add-new-button"
          onClick={() => onAdd('simple')}
        >
          <PlusCircleOutlined />&nbsp;New Driver
        </div>
      </div>
    </Card>
  );
}
