import { VerticalAlignTopOutlined } from '@ant-design/icons';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Center, Stack, Text, Button, Box } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  ConceptType,
  DerivedLabelingTask,
  LabelingTask,
  Message,
  MessageLabelingViewData,
  TargetType,
  UserLabelingTaskType,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  Annotation,
  AnnotationRawDataType,
  AnnotationValue,
  AnnotationValuePresenceType,
  AnnotationValueType,
  BinaryValue,
  BinaryValueValue,
  MessageRawData,
  MessageRawDataContextShown,
  RubricValue,
  TextValue,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { useSelector, useDispatch } from 'hooks/reduxHooks';
import LabelingDetailsOverview from 'components/LabelingDetailsOverview';
import LabelingStepper from 'components/LabelingStepper';
import { ApiStatus } from 'store/types';
import {
  selectApiStatus,
  selectCurrentLabelingTask,
  selectTemporalAnnotations,
  selectDerivedLabelingTaskFactory,
  selectTemporalAnnotationsMap,
  selectFailedToSaveMessages,
  selectFetchingAnnotations,
  selectOriginalLabelingTaskFactory,
  selectDerivedLabelingTasks,
  selectConversationLabelingItems,
  selectTaskTemporalAnnotationValueCounts,
  selectTaskCompleted,
  selectPredictions,
  selectCanSampleMore,
} from 'store/labelingTask/selectors';
import {
  completeLabelingTask,
  fetchConversationPredictions,
  fetchLabelingTaskData,
  fetchLabelingTaskItems,
  removeTemporalAnnotation,
  selectNewBatch,
  updateAbstractTask,
  upsertTemporalAnnotation,
} from 'store/labelingTask/asyncThunks';

import { AnnotationValueCountEntry, SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';

import { v4 as uuid } from 'uuid';
import Loading from 'components/Loading';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { selectUsers } from 'store/user/selectors';
import { getId } from 'common/resourceName';
import { User } from 'types';
import { selectConceptsFactory, selectConceptsMapFactory } from 'store/concept/selectors';
import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { clearLabelingTask, updateGhostAnnotations } from 'store/labelingTask/labelingTaskSlice';
import { AbstractTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { useHotkeys } from 'hooks/useHotkeys';
import { UserContext } from 'context/UserContext';
import { useLabelingSession } from 'hooks/useLabelingSession';
import { createConfirm } from 'components/ConfirmModal';
import UserTag from 'components/UserTag';
import { showConfetti } from 'components/Confetti/showConfetti';
import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { BinaryLabelingView, BinaryQaView, MisclassificationQaView, MultipleBinaryLabelingView, PolicyQaView, SpanLabelingView, SuggestionsQaView, SummarizationLabelingView, SummarizationQaView } from 'components/LabelingViews';
import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { listConversationMessages } from 'store/conversation/asyncThunks';
import { selectConversationMessages } from 'store/conversation/selectors';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { POLICY_QA_GUIDELINES_CONCEPTS } from 'components/LabelingViews/PolicyQa/guidelines';
import { Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import styles from './styles.module.scss';
import { filterConceptsByActorType, findFirstTargetMessage, findFirstToLabel, findNextTargetMessage, getMomentAnnotationType, getTaskConceptType, rubricsToDummyConcepts } from './utils';
import LabelingViewHeader from './LabelingViewHeader';
import { TaskLabeled } from './TaskLabeled';
import { TaskCompleted } from './TaskCompleted';

type LabelingViewProps = {
  children?: React.ReactNode | string | number;
};

export type GotoNextType = 'message' | 'chat' | 'noop';

/* Main labeling UI page */
export default function LabelingView({ children }: LabelingViewProps) {
  const { taskId, labelingDataIndex } = useParams<{ taskId: string, labelingDataIndex?: string }>();
  const currentUser = useContext(UserContext);
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  useLabelingSession(`${customerProfile}/labelingTasks/${taskId}`);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // The index of item (chat) that's being labeled
  const chatIndex = Number(labelingDataIndex) || 0;
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const labelingTask = useSelector<LabelingTask>(selectCurrentLabelingTask);
  const temporalAnnotations = useSelector<Annotation[]>(selectTemporalAnnotations);
  const derivedLabelingTask = useSelector<DerivedLabelingTask>(
    selectDerivedLabelingTaskFactory(labelingTask?.name),
  );
  // In case this is a Dual or calibration task
  const originalLabelingTask = useSelector<DerivedLabelingTask>(
    selectOriginalLabelingTaskFactory(derivedLabelingTask),
  );

  // Shows/hides more information about task
  const [labelingOverview, setLabelingOverview] = useState(true);
  // When user finished labeling but wants to review/edit labels
  const [userRevisitingLabels, setUserRevisitingLabels] = useState(false);
  // User selected target message
  const [selectedMessage, setSelectedMessage] = useState<Message>();
  const [submitting, setSubmitting] = useState(false);
  const [canSkip, setCanSkip] = useState(true);
  // Selected concept in the overview component
  const [overviewConceptId, setOverviewConceptId] = useState<string>();
  const toggleLabelingOverview = () => setLabelingOverview((prev: boolean) => !prev);
  const [rubrics, setRubrics] = useState<Rubric[]>([]);

  useHotkeys('g', toggleLabelingOverview, {}, []);
  // TODO: (FORREST) - defaultContext should be derived from concept metadata
  // https://linear.app/cresta/issue/STU-4568/[studio-feature-request]-intent-context-flag-and-labeling-ui
  const defaultContext = MessageRawDataContextShown.NO_CONTEXT_SHOWN;
  const [context, setContext] = useState<MessageRawDataContextShown>(defaultContext);
  const labelingConceptType = getTaskConceptType(labelingTask?.taskData?.taskDescriptor?.conceptType);
  const allConcepts = useSelector(selectConceptsFactory(labelingConceptType));
  const conceptsMap = useSelector<Map<string, Concept>>(selectConceptsMapFactory(labelingConceptType));
  const currentChat = useSelector<MessageLabelingViewData>(selectConversationLabelingItems);
  const messages = currentChat?.messagesAndContexts || [];
  const messageAnnotationsMap = useSelector<Map<string, Annotation[]>>(selectTemporalAnnotationsMap);
  const predictions = useSelector<Prediction[]>(selectPredictions);
  const firstTargetMessage = findFirstTargetMessage(messages);
  const nextToLabelMessage = findFirstToLabel(messages, messageAnnotationsMap);
  const currentMessage = selectedMessage || nextToLabelMessage || firstTargetMessage;
  const nextTargetMessage = findNextTargetMessage(currentMessage?.v2MessageId, messages);
  const failedToSaveMessages = useSelector<{ [id: string]: boolean }>(selectFailedToSaveMessages);
  const fetchingAnnotations = useSelector<boolean>(selectFetchingAnnotations);
  const derivedLabelingTasks = useSelector<DerivedLabelingTask[]>(selectDerivedLabelingTasks);
  const taskCompleted = useSelector<boolean>(selectTaskCompleted);
  const temporalAnnotationValueCounts = useSelector<AnnotationValueCountEntry[]>(selectTaskTemporalAnnotationValueCounts);
  const canSampleMore = useSelector<boolean>(selectCanSampleMore);
  const currentTaskFromTasksList = derivedLabelingTasks.find((task) => task.labelingTask.name === `${customerProfile}/labelingTasks/${taskId}`);
  const taskAssigneeUserId = currentTaskFromTasksList?.labelingTask.abstractTask.assigneeUserId;
  const lastChatIndex = derivedLabelingTask?.totalConversationNumber - 1;
  const prevChatIndex = Math.max(chatIndex - 1, 0);
  const nextChatIndex = Math.min(chatIndex + 1, lastChatIndex);
  const taskDescriptor = labelingTask?.taskData.taskDescriptor;
  const isSampleMoreVisible = taskDescriptor?.allowMultiRoundLabeling && !taskCompleted;
  const isQaLabeling = taskDescriptor?.targetType === TargetType.QA;
  const isEntityLabeling = taskDescriptor?.conceptType === ConceptType.ENTITY;
  const tasksQueueLink = isQaLabeling ? `/${customer.path}/qa/tasks` : `/${customer.path}/labeling/tasks`;

  const calibrationOriginalTask = useMemo(() => {
    const originalTaskId = labelingTask?.taskData.taskDescriptor.selectionInstruction.calibrationSelectionInstruction?.dualTaskIds?.[0];
    if (!originalTaskId) return null;

    return derivedLabelingTasks?.find((task) => getId('labelingTask', task.labelingTask.name) === originalTaskId);
  }, [labelingTask, derivedLabelingTasks]);

  // Needed in some cases e.g. when we need message timestamps
  const conversationMessages = useSelector(selectConversationMessages);

  // Get annotation type from task concept type
  const momentAnnotationType = useMemo(() => {
    if (!labelingTask) return MomentAnnotationType.TYPE_UNSPECIFIED;
    return getMomentAnnotationType(labelingTask.taskData.taskDescriptor.conceptType);
  }, [labelingTask, getMomentAnnotationType]);

  // Concepts that should be labeled in this task
  const labelingConcepts = useMemo(() => {
    const messageConceptIds = currentMessage?.conceptIds || [];
    const taskConceptIds = labelingTask?.taskData?.taskDescriptor?.conceptIds || [];
    const conceptsSource = taskConceptIds?.length ? taskConceptIds : messageConceptIds;

    return (conceptsSource || []).map((id) => {
      // Some concepts might be deprecated, but we still need to include labels for them
      // thus we create dummy concept objects for them
      const concept: Concept = conceptsMap.get(id) || {
        name: `${customerProfile}/concepts/${id}`,
        conceptTitle: `${id} (Deprecated)`,
      };
      return concept;
    });
  }, [currentMessage, labelingTask, conceptsMap]);

  // sets the context to the default state of concept
  useEffect(() => {
    setContext(labelingConcepts[0]?.intent?.needsContext ? MessageRawDataContextShown.PARTIAL_CONVERSATION_CONTEXT_SHOWN : MessageRawDataContextShown.NO_CONTEXT_SHOWN);
  }, [labelingConcepts[0]?.intent?.needsContext]);

  const userTaskType = derivedLabelingTask?.userLabelingTaskType;

  // Concepts displayed in the overview panel
  const overviewPanelConcepts = useMemo(() => {
    if (userTaskType === UserLabelingTaskType.QA_POLICY) {
      return [...POLICY_QA_GUIDELINES_CONCEPTS, ...allConcepts];
    } else if (
      [
        UserLabelingTaskType.QA_SUMMARIZATION,
        UserLabelingTaskType.QA_SUGGESTIONS,
      ].includes(userTaskType)
    ) {
      // We need to convert rubrics into dummy concepts
      // so they can be displayed the same way as other concepts in the panel
      const rubricGuidelineDummyConcepts = rubricsToDummyConcepts(rubrics);
      return [...rubricGuidelineDummyConcepts, ...allConcepts];
    } else {
      return allConcepts;
    }
  }, [allConcepts, userTaskType, rubrics]);

  const labelingFinished = useMemo(() => {
    let targetCount = derivedLabelingTask?.targetCount;
    if ([UserLabelingTaskType.LABELING_SUMMARIZATION, UserLabelingTaskType.QA_SUMMARIZATION].includes(userTaskType)) {
      // Summarization labeling uses
      targetCount = derivedLabelingTask?.totalConversationNumber;
    }
    return derivedLabelingTask?.annotatedTargetCount === targetCount;
  }, [userTaskType, derivedLabelingTask]);

  // Fetch predictions for conversation if needed
  const fetchPredictions = async () => {
    // Predictions are only needed for the below listed task types
    if (![
      UserLabelingTaskType.QA_POLICY,
      UserLabelingTaskType.QA_SUGGESTIONS,
      UserLabelingTaskType.QA_INTENT_PREDICTION,
      UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
    ].includes(userTaskType)) {
      return;
    }

    const conversationId = messages[0]?.v2ConversationId;
    if (conversationId) {
      dispatch(fetchConversationPredictions({
        resource: `${customerProfile}/conversations/${conversationId}`,
        filter: {
          usecases: [
            `${customerProfile}/usecases/${customer.usecaseId}`,
          ],
        },
      }));
    }
  };

  // Fetch messages that have data like publish time
  const fetchConversationMessages = () => {
    if (messages.length && userTaskType === UserLabelingTaskType.QA_POLICY) {
      const conversationId = messages[0]?.v2ConversationId;
      dispatch(listConversationMessages(`${customerProfile}/conversations/${conversationId}`));
    }
  };

  // Fetch labeling task data on initial page load
  useEffect(() => {
    dispatch(fetchLabelingTaskData(`${customerProfile}/labelingTasks/${taskId}`));
    return () => {
      dispatch(clearLabelingTask());
    };
  }, [taskId]);

  // Fetch labeling task items when chat index changes
  useEffect(() => {
    dispatch(fetchLabelingTaskItems({
      taskName: `${customerProfile}/labelingTasks/${taskId}`,
      conversationNumberStart: chatIndex,
      conversationNumberEnd: chatIndex + 1,
    }));
  }, [chatIndex]);

  // Prevent skipping chats without waiting for new chat messages
  useEffect(() => {
    setCanSkip(true);
    // Reset context
    setContext(MessageRawDataContextShown.NO_CONTEXT_SHOWN);

    // Reset selected message
    setSelectedMessage(undefined);

    // Policy QA needs predictions to display rules
    fetchPredictions();

    // Policy QA needs message timestamps that we need to get from a different service
    fetchConversationMessages();
  }, [messages[0]?.v2ConversationId, userTaskType]);

  // Show confetti after completion
  useEffect(() => {
    if (taskCompleted) {
      showConfetti();
    }
  }, [taskCompleted]);

  // Reset submitting status when the derivedLabelingTask is updated or last submitting is failed.
  useEffect(() => setSubmitting(false), [derivedLabelingTask]);
  useEffect(() => {
    if (taskApiStatus === 'failed') {
      setSubmitting(false);
    }
  }, [taskApiStatus]);

  // Users list
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  // Call this function to check if current user matches with assigned user
  const checkTaskAssignee = async (): Promise<boolean> => {
    const currentUserId = currentUser?.id;
    // The latest information about assignee is computed from 2 sources (derivedTask, tasks queue)
    const latestTaskAssigneeUserId = taskAssigneeUserId != null ? taskAssigneeUserId : derivedLabelingTask?.labelingTask?.abstractTask.assigneeUserId;

    if (latestTaskAssigneeUserId !== currentUserId) {
      // Show confirmation prompt
      const user = users.find((user) => user.id === latestTaskAssigneeUserId);
      return createConfirm<boolean>({
        title: "Looks like you're not the assignee of this task.",
        content: (
          <div>
            <p className={styles.assigneeWarningText}>Currently assigned to</p>
            <UserTag name={user?.full_name || 'Unassigned'} />
            <p className={styles.assigneeWarningText}>If multiple people are labeling this task at once, errors could occur. If you are the right assignee, please re-assign the task to yourself from the task queue.</p>
          </div>),
        buttons: [
          {
            text: 'Proceed anyway',
            value: true,
          },
          {
            text: 'Cancel',
            buttonProps: {
              variant: 'subtle',
              dataCy: 'button-cancel',
            },
            value: false,
          },
        ],
      });
    }

    return Promise.resolve(true);
  };

  // Update task assignee
  const updateAssignee = useCallback(
    (abstractTask: AbstractTask) => dispatch(updateAbstractTask({ abstractTask, updateMask: 'abstractTask.assigneeUserId' })),
    [dispatch],
  );

  // Helper func to get annotations for message
  const getMessageAnnotations = useCallback(
    (message: Message) =>
      messageAnnotationsMap.get(message?.v2MessageId) || [],
    [messageAnnotationsMap],
  );

  // Annotation with unspecified value type template passed down to decision component
  const createValueAnnotation = useCallback((
    messageRawData: MessageRawData,
    value: AnnotationValue,
    momentAdherenceType?: MomentAnnotationAdherenceType,
  ): SaveTemporalAnnotationRequestRawDataValueTuple => ({
    annotationId: uuid(),
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_MESSAGE,
      messageRawData,
      conversationPositionNumber: chatIndex,
    },
    value,
    targetType: labelingTask?.taskData.taskDescriptor.targetType,
    momentAnnotationType,
    split: labelingTask.taskData.taskDescriptor.split,
    momentAdherenceType,
  }), [chatIndex, labelingTask, momentAnnotationType]);

  // Binary annotation "master" template passed down to decision component
  const createBinaryAnnotation = useCallback((
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType,
  ): SaveTemporalAnnotationRequestRawDataValueTuple => ({
    annotationId: uuid(),
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_MESSAGE,
      messageRawData,
      conversationPositionNumber: chatIndex,
    },
    value: {
      presenceType: AnnotationValuePresenceType.PRESENCE,
      binaryValue,
      type: AnnotationValueType.TYPE_BINARY,
    },
    targetType: labelingTask?.taskData.taskDescriptor.targetType,
    momentAnnotationType,
    split: labelingTask.taskData.taskDescriptor.split,
    momentAdherenceType,
  }), [chatIndex, labelingTask, momentAnnotationType]);

  // Text annotation "master" template passed down to decision component
  const createTextAnnotation = useCallback((
    conversationRawData: MessageRawData,
    textValue: TextValue,
    momentAdherenceType?: MomentAnnotationAdherenceType,
  ): SaveTemporalAnnotationRequestRawDataValueTuple => ({
    annotationId: uuid(),
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_CONVERSATION,
      conversationRawData,
      conversationPositionNumber: chatIndex,
    },
    value: {
      presenceType: AnnotationValuePresenceType.PRESENCE,
      textValue,
      type: AnnotationValueType.TYPE_TEXT,
    },
    targetType: labelingTask?.taskData.taskDescriptor.targetType,
    momentAnnotationType,
    split: labelingTask.taskData.taskDescriptor.split,
    momentAdherenceType,
  }), [chatIndex, labelingTask, momentAnnotationType]);

  // Text annotation "master" template passed down to decision component
  const createRubricAnnotation = useCallback((
    conversationRawData: MessageRawData,
    rubricValue: RubricValue,
    momentAdherenceType?: MomentAnnotationAdherenceType,
  ): SaveTemporalAnnotationRequestRawDataValueTuple => ({
    annotationId: uuid(),
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_CONVERSATION,
      conversationRawData,
      conversationPositionNumber: chatIndex,
    },
    value: {
      presenceType: AnnotationValuePresenceType.PRESENCE,
      rubricValue,
      type: AnnotationValueType.TYPE_RUBRIC,
    },
    targetType: labelingTask?.taskData.taskDescriptor.targetType,
    momentAnnotationType,
    split: labelingTask.taskData.taskDescriptor.split,
    momentAdherenceType,
  }), [chatIndex, labelingTask, momentAnnotationType]);

  // Update annotations and move to the next message/convo
  const upsertAnnotations = useCallback(async (
    newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    next: GotoNextType,
    toBeRemovedAnnotations?: Annotation[],
  ) => {
    if (fetchingAnnotations || submitting || taskApiStatus === 'loading') return;

    const check = await checkTaskAssignee();
    if (!check) return;

    const userLabelingTaskType = derivedLabelingTask?.userLabelingTaskType;
    let existingAnnotations: Annotation[] = [];
    // In case of Policy QA we don't replace annotations on message
    // but rather add new ones
    if (userLabelingTaskType !== UserLabelingTaskType.QA_POLICY) {
      existingAnnotations = getMessageAnnotations(currentMessage);
    }

    setSubmitting(true);

    // Ghost annotations are not confirmed by server but are displayed
    // under the message. They will be updated with real data returned from server response
    const newGhostAnnotations = newAnnotations.map((annotationTuple) => {
      const annotation: Annotation = {
        name: annotationTuple.annotationId,
        rawData: annotationTuple.rawData,
        value: annotationTuple.value,
      };
      return annotation;
    });

    // Updates state with "ghost" annotations that aren't yet confirmed by server
    dispatch(
      updateGhostAnnotations({ existing: existingAnnotations, new: newGhostAnnotations }),
    );
    // (Re)assign task to whoever submits first label
    const taskAssignee = labelingTask.abstractTask.assigneeUserId;
    const userId = currentUser?.id;
    const nothingLabeled = derivedLabelingTask?.annotatedTargetCount === 0;
    if (nothingLabeled || !taskAssignee) {
      updateAssignee({
        ...labelingTask.abstractTask,
        assigneeUserId: userId,
      });
    }

    // Finally send request to remove and submit annotations
    dispatch(
      upsertTemporalAnnotation({
        derivedLabelingTask,
        existingAnnotations: toBeRemovedAnnotations,
        annotationValueTuples: newAnnotations,
      }),
    );

    // Go to the next target message or chat
    if (next === 'chat' || (next === 'message' && !nextTargetMessage)) {
      navigate(
        `/${customer.path}/${isQaLabeling ? 'qa' : 'labeling'}/view/${taskId}/${nextChatIndex}`,
        { replace: true },
      );
    } else if (next === 'message' && nextTargetMessage) {
      setSelectedMessage(nextTargetMessage);
    }
  }, [
    fetchingAnnotations,
    derivedLabelingTask,
    currentMessage,
    nextTargetMessage,
    getMessageAnnotations,
    nextChatIndex,
    submitting,
    taskApiStatus,
    checkTaskAssignee,
  ]);

  // Sometimes we need to only remove annotations (e.g. Policy QA)
  const removeAnnotations = useCallback(async (annotations: Annotation[]) => {
    const check = await checkTaskAssignee();
    if (!check) return;

    const annotationIds = annotations.map((annotation) => getId('annotation', annotation.name));
    dispatch(
      removeTemporalAnnotation({
        taskName: labelingTask?.name,
        annotationIds,
      }),
    );
  }, [labelingTask]);

  // Finish task
  const completeTask = useCallback(() => {
    if (taskApiStatus === 'loading') return;
    dispatch(completeLabelingTask(labelingTask?.name));
  }, [dispatch, labelingTask, taskApiStatus]);

  const getConceptTitle = useCallback((conceptId: string) => conceptsMap.get(conceptId)?.conceptTitle || `${conceptId} (Not found)`, [conceptsMap]);
  const getConceptRole = useCallback((conceptId: string): 'agent' | 'visitor' | null => {
    const concept = conceptsMap.get(conceptId);
    switch (concept?.intent.intentType) {
      case IntentIntentType.AGENT_INTENT:
        return 'agent';
      case IntentIntentType.VISITOR_INTENT:
        return 'visitor';
      default:
        return null;
    }
  }, [conceptsMap]);

  const createSkipAnnotationTuples = (message: Message, conceptIds: string[]): SaveTemporalAnnotationRequestRawDataValueTuple[] =>
    conceptIds.map((conceptId) => {
      const annotationValueTuple = createBinaryAnnotation({
        conversationId: message.conversationId,
        messageId: message.messageId,
        v2ConversationId: message.v2ConversationId,
        v2MessageId: message.v2MessageId,
        contextShown: context,
        spanStart: message.targetSpanStart,
        spanEnd: message.targetSpanEnd,
        predictionId: message.selectionContext?.qaContext?.predictionId,
      }, {
        value: BinaryValueValue.VALUE_SKIP,
        conceptId,
      });

      return annotationValueTuple;
    });

  // Skip all unlabeled messages in current chat
  const skipUnlabeledMessages = async (chat: MessageLabelingViewData, nextIndex: number) => {
    if (fetchingAnnotations || submitting || taskApiStatus === 'loading' || !canSkip) return;

    // Policy QA, Suggestions QA doesn't save skip annotations
    if ([UserLabelingTaskType.QA_POLICY, UserLabelingTaskType.QA_SUGGESTIONS].includes(userTaskType)) {
      navigate(
        `/${customer.path}/${isQaLabeling ? 'qa' : 'labeling'}/view/${taskId}/${nextIndex}`,
        { replace: true },
      );
      return;
    }

    setCanSkip(false);
    const unlabeledMessages = (chat.messagesAndContexts || []).filter((message) => {
      if (!message.isTargetMessage) return false;
      const annotations = getMessageAnnotations(message);
      return annotations.length === 0;
    });

    // If all messages in chat labeled no need to upsert
    if (unlabeledMessages.length === 0) {
      navigate(
        `/${customer.path}/${isQaLabeling ? 'qa' : 'labeling'}/view/${taskId}/${nextIndex}`,
        { replace: true },
      );
      return;
    }

    const annotationTuples: SaveTemporalAnnotationRequestRawDataValueTuple[] = unlabeledMessages.reduce((acc, message) => {
      let concepts = labelingConcepts;
      if (labelingConceptType === ConceptConceptType.INTENT) {
        concepts = filterConceptsByActorType(labelingConcepts, message?.actorType);
      }
      const conceptIds = isQaLabeling ? message?.conceptIds : concepts.map((concept) => getId('concept', concept.name));
      return [...acc, ...createSkipAnnotationTuples(message, conceptIds)];
    }, []);
    upsertAnnotations(annotationTuples, 'chat');
  };

  // Navigate to conversation index
  const goToChat = useCallback(async (nextIndex: number) => {
    if (nextIndex > chatIndex) {
      skipUnlabeledMessages(currentChat, nextIndex);
    } else {
      navigate(`/${customer.path}/${isQaLabeling ? 'qa' : 'labeling'}/view/${taskId}/${nextIndex}`, { replace: true });
    }
  }, [taskId, currentChat, chatIndex, canSkip, skipUnlabeledMessages]);

  // Show loading if required task data not loaded
  if (!labelingTask || !derivedLabelingTask) {
    return (
      <div className={classNames(['studio-page', styles.wrapper])}>
        <div className={styles.labelingWrapper}>
          <Loading />
        </div>
      </div>
    );
  }

  // Fetch more labels for dynamic labeling
  const sampleNewBatch = () => {
    dispatch(selectNewBatch({
      labelingTask,
      selectCount: 20,
    }));
    setUserRevisitingLabels(false);
  };

  // When user clicks another message
  const setTargetMessage = (message: Message) => {
    if (!message.isTargetMessage) return;
    setSelectedMessage(message);
  };

  // Choose the approporiate view component
  const renderLabelingView = () => {
    // In case of a calibration task
    const isCalibrationDense = calibrationOriginalTask?.userLabelingTaskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING;
    const isCalibrationSparse = [
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING,
    ].includes(calibrationOriginalTask?.userLabelingTaskType);

    // Single binary labeling
    if (isCalibrationSparse || [
      UserLabelingTaskType.LABELING_SPARSE_CLO,
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING,
    ].includes(userTaskType)) {
      return (
        <BinaryLabelingView
          context={context}
          setContext={setContext}
          concepts={labelingConcepts}
          messages={messages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          targetType={labelingTask?.taskData.taskDescriptor.targetType}
          momentAnnotationType={momentAnnotationType}
          split={labelingTask?.taskData.taskDescriptor.split}
          createAnnotation={createBinaryAnnotation}
          upsertAnnotations={upsertAnnotations}
        />
      );
    }

    // Multiple binary labeling
    if (isCalibrationDense || [
      UserLabelingTaskType.LABELING_DENSE_CLO,
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING,
    ].includes(userTaskType)) {
      return (
        <MultipleBinaryLabelingView
          concepts={labelingConcepts}
          messages={messages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          createAnnotation={createBinaryAnnotation}
          upsertAnnotations={upsertAnnotations}
          conceptType={labelingTask?.taskData.taskDescriptor.conceptType}
        />
      );
    }

    // Single binary QA
    if ([
      UserLabelingTaskType.QA_INTENT_PREDICTION,
      UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
      UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION,
    ].includes(userTaskType)) {
      return (
        <BinaryQaView
          context={context}
          setContext={setContext}
          concepts={labelingConcepts}
          messages={messages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          createAnnotation={createBinaryAnnotation}
          upsertAnnotations={upsertAnnotations}
          predictions={predictions}
        />
      );
    }

    // Entity (span) labeling
    if ([
      UserLabelingTaskType.LABELING_ENTITY,
    ].includes(userTaskType)) {
      return (
        <SpanLabelingView
          context={context}
          setContext={setContext}
          entities={labelingConcepts}
          messages={messages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          createAnnotation={createBinaryAnnotation}
          upsertAnnotations={upsertAnnotations}
          prevChat={() => goToChat(prevChatIndex)}
          nextChat={() => goToChat(nextChatIndex)}
          isFirstChat={chatIndex === 0}
          isLastChat={chatIndex === nextChatIndex}
          finishLabeling={completeTask}
        />
      );
    }

    // Policy QA
    if ([
      UserLabelingTaskType.QA_POLICY,
    ].includes(userTaskType)) {
      return (
        <PolicyQaView
          concepts={allConcepts}
          messages={messages}
          conversationMessages={conversationMessages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          predictions={predictions}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          getConceptRole={getConceptRole}
          createAnnotation={createBinaryAnnotation}
          upsertAnnotations={upsertAnnotations}
          removeAnnotations={removeAnnotations}
          setOverviewConceptId={setOverviewConceptId}
        />
      );
    }

    // Suggestions QA
    if ([
      UserLabelingTaskType.QA_SUGGESTIONS,
    ].includes(userTaskType)) {
      const suggestionInstruction = labelingTask?.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom;
      return (
        <SuggestionsQaView
          messages={messages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          predictions={predictions}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          getConceptRole={getConceptRole}
          createAnnotation={createValueAnnotation}
          upsertAnnotations={upsertAnnotations}
          modelUri1={suggestionInstruction.modelUri}
          modelUri2={suggestionInstruction.modelUri2}
          prevChat={() => goToChat(prevChatIndex)}
          nextChat={() => goToChat(nextChatIndex)}
          isFirstChat={chatIndex === 0}
          isLastChat={chatIndex === nextChatIndex}
          finishLabeling={completeTask}
          taskId={taskId}
          setRubrics={setRubrics}
          rubrics={rubrics}
          customerProfile={customerProfile}
          setOverviewConceptId={(id: string) => {
            setLabelingOverview(true);
            setOverviewConceptId(id);
          }}
        />
      );
    }

    // Summarization Labeling
    if ([
      UserLabelingTaskType.LABELING_SUMMARIZATION,
    ].includes(userTaskType)) {
      return (
        <SummarizationLabelingView
          concepts={labelingConcepts}
          messages={messages}
          conversationAnnotations={temporalAnnotations}
          failedMessages={failedToSaveMessages}
          createAnnotation={createTextAnnotation}
          upsertAnnotations={upsertAnnotations}
          nextChat={() => goToChat(nextChatIndex)}
          setOverviewConceptId={(id: string) => {
            setLabelingOverview(true);
            setOverviewConceptId(id);
          }}
        />
      );
    }

    // Summarization QA
    if ([
      UserLabelingTaskType.QA_SUMMARIZATION,
    ].includes(userTaskType)) {
      const summarizationInstruction = labelingTask?.taskData.taskDescriptor.selectionInstruction.summarizationInstruction;
      return (
        <SummarizationQaView
          concepts={labelingConcepts}
          messages={messages}
          conversationAnnotations={temporalAnnotations}
          getConceptTitle={getConceptTitle}
          failedMessages={failedToSaveMessages}
          createAnnotation={createRubricAnnotation}
          upsertAnnotations={upsertAnnotations}
          prevChat={() => goToChat(prevChatIndex)}
          nextChat={() => goToChat(nextChatIndex)}
          modelUris={summarizationInstruction.modelUris}
          isFirstChat={chatIndex === 0}
          isLastChat={chatIndex === nextChatIndex}
          finishLabeling={completeTask}
          setRubrics={setRubrics}
          rubrics={rubrics}
          rubricTagSelections={summarizationInstruction.rubricTagSelections}
          setOverviewConceptId={setOverviewConceptId}
        />
      );
    }

    // Misclassification QA
    if ([
      UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION,
    ].includes(userTaskType)) {
      return (
        <MisclassificationQaView
          context={context}
          setContext={setContext}
          concepts={labelingConcepts}
          messages={messages}
          targetMessage={currentMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          failedMessages={failedToSaveMessages}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          createAnnotation={createBinaryAnnotation}
          upsertAnnotations={upsertAnnotations}
          predictions={predictions}
        />
      );
    }

    return taskApiStatus === 'loading' ? (
      <Loading/>
    ) : (
      <Center>
        <Stack align="center">
          <Text weight={500} color="red">Error</Text>
          <Text color="gray">Misconfigured task or unsupported task type</Text>
        </Stack>
      </Center>
    );
  };

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <div className={styles.labelingWrapper}>
        <LabelingViewHeader
          labelingTaskType={userTaskType}
          getConceptTitle={getConceptTitle}
          labelingTaskConcepts={labelingConcepts}
          currentMessage={currentMessage}
          taskDescriptor={labelingTask?.taskData.taskDescriptor}
        />
        <div>
          <button
            type="button"
            className={classNames([
              styles.guidelinesButton,
              { [styles.active]: labelingOverview },
            ])}
            onClick={toggleLabelingOverview}
          >
            <VerticalAlignTopOutlined />
            Guidelines
          </button>
        </div>
        <div>
          <div className={styles.boxContainer}>
            {taskCompleted || (!isEntityLabeling && (labelingFinished && !userRevisitingLabels)) ? (
              <div className={styles.labelingFinished}>
                {
                    taskCompleted ? (
                      <TaskCompleted
                        tasksQueueLink={tasksQueueLink}
                      />
                    ) : (
                      <TaskLabeled
                        completeTask={completeTask}
                        setUserRevisitingLabels={setUserRevisitingLabels}
                      >
                        {isSampleMoreVisible && (
                        <Box mb="lg">
                          {canSampleMore ? (
                            <Button color="green" variant="subtle" onClick={() => sampleNewBatch()}>Sample more messages</Button>
                          ) : (
                            <Text>No more messages</Text>
                          )}
                        </Box>
                        )}
                      </TaskLabeled>
                    )
                  }
              </div>
            ) : (
              renderLabelingView()
            )}
            {
              (taskApiStatus === 'loading' || fetchingAnnotations) && (
                <div className={styles.loadingMask} data-cy="loading-mask">
                  <Loading />
                </div>
              )
            }
          </div>
          <LabelingDetailsOverview
            selectedConceptId={overviewConceptId}
            setSelectedConceptId={setOverviewConceptId}
            visible={labelingOverview}
            task={labelingTask}
            derivedTask={derivedLabelingTask}
            originalTask={originalLabelingTask}
            message={currentMessage}
            users={users}
            concepts={overviewPanelConcepts}
            getConceptTitle={getConceptTitle}
          />
        </div>
        <div className={styles.labelingFooter}>
          {
            !taskCompleted && (
              <LabelingStepper
                loading={!derivedLabelingTask}
                annotatedMessages={derivedLabelingTask?.annotatedTargetCount}
                annotatedChats={derivedLabelingTask?.annotatedTargetCount}
                targetChats={derivedLabelingTask?.totalConversationNumber}
                targetMessages={derivedLabelingTask?.targetCount}
                totalConversationNumber={derivedLabelingTask?.totalConversationNumber}
                taskProgress={derivedLabelingTask?.taskProgress}
                annotationValueCounts={temporalAnnotationValueCounts}
                currentIndex={chatIndex}
                hideNavigation={labelingFinished && !userRevisitingLabels}
                prevChat={() => goToChat(prevChatIndex)}
                nextChat={() => goToChat(nextChatIndex)}
                nextIndex={nextChatIndex}
                prevIndex={prevChatIndex}
                labelingTaskType={userTaskType}
                conceptType={labelingTask?.taskData.taskDescriptor.conceptType}
                taskId={taskId}
              />
            )
          }
          {(!taskCompleted) && (
            <div className={styles.footerButtons}>
              <Button variant="subtle" onClick={() => navigate(isQaLabeling ? `/${customer.path}/qa/tasks` : `/${customer.path}/labeling/tasks`)}>Continue later</Button>
              <Button onClick={completeTask} data-cy="finish-btn">
                Finish
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
