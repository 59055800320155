import { Job } from '@cresta/web-client';
import { ApiStatus } from 'store/types';

/** Job state. */
export interface State {
  jobs: Job[];
  fetchingJobs: boolean;
  status: ApiStatus;
}

/** Initial job state. */
export const INITIAL_STATE: State = {
  jobs: [],
  fetchingJobs: false,
  status: 'idle',
};
