import { Checkbox, Radio, Table, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ColumnsType } from 'antd/lib/table';
import { Button, Space, Flex } from '@mantine/core';
import React, { useState } from 'react';
import { IntentsDashboardTableRow } from '../../../models';
import styles from './styles.module.scss';

interface Props {
  onClickTrain: (modelType: ''|'deberta'|'mdeberta', isContextualModel: boolean) => void;
  selectedRows: IntentsDashboardTableRow[];
  selectChange: (selectedRows: IntentsDashboardTableRow[]) => void;
}

export function ValidationDashboard({ onClickTrain, selectedRows, selectChange }: Props) {
  const [modelType, setModelType] = useState<''|'deberta'|'mdeberta'>('');
  const [isContextualModel, setIsContextualModel] = useState<boolean>(false);
  const columns: ColumnsType<IntentsDashboardTableRow> = [
    {
      title: 'Intent',
      dataIndex: 'conceptTitle',
      key: 'conceptTitle',
      width: '30%',
      render: (intent: string) => (
        <div className={styles.tableIntent}>{intent}</div>
      ),
    },
    {
      title: 'Train set',
      dataIndex: 'trainSet',
      key: 'trainSet',
      render: (trainSet: string) => (
        <div style={{ color: trainSet?.includes('Good') ? '#212121' : '#FF6B6B' }}>{trainSet}</div>
      ),
    },
    {
      title: 'Test set',
      dataIndex: 'testSet',
      key: 'testSet',
      render: (testSet: string) => (
        <div style={{ color: testSet?.includes('Good') ? '#212121' : '#FF6B6B' }}>{testSet}</div>
      ),
    },
    {
      title: '# Regex',
      dataIndex: 'positiveRegex',
      key: 'regex',
      render: (positiveRegex: string[], record: IntentsDashboardTableRow) => (
        <div style={{ color: positiveRegex.length + record.negativeRegex.length > 0 ? '#212121' : '#474747' }}>
          {positiveRegex.length + record.negativeRegex.length || 'No Regex'}
        </div>
      ),
    },
    {
      title: 'Condition',
      dataIndex: 'positiveRegex',
      key: 'condition',
      render: (positiveRegex: string[], record: IntentsDashboardTableRow) => (
        <div>
          <Checkbox
            checked={record.model === 'regex'}
            disabled={!positiveRegex.length && !record.negativeRegex.length}
            onChange={(e: CheckboxChangeEvent) => {
              const updatedRows = selectedRows.map((item) => {
                if (item.name === record.name) {
                  return {
                    ...item,
                    model: e.target.checked ? 'regex' : undefined,
                  } as IntentsDashboardTableRow;
                }
                return item;
              });
              selectChange(updatedRows);
            }}
          >
            <Tooltip title={!positiveRegex.length && !record.negativeRegex.length && 'No regex to use'}>
              <span>Use Regex only</span>
            </Tooltip>
          </Checkbox>
          <Checkbox
            checked={record.model === 'neural'}
            disabled={record.trainPositive <= 1}
            onChange={(e: CheckboxChangeEvent) => {
              const updatedRows = selectedRows.map((item) => {
                if (item.name === record.name) {
                  return {
                    ...item,
                    model: e.target.checked ? 'neural' : undefined,
                  } as IntentsDashboardTableRow;
                }
                return item;
              });
              selectChange(updatedRows);
            }}
          >
            <Tooltip title={record.trainPositive <= 1 && 'Few train data to use'}>
              <span>Use Neural only</span>
            </Tooltip>
          </Checkbox>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardTitle}>
        <div className={styles.title}>
          Validate intents for training
        </div>
        <Button disabled={selectedRows.length === 0} onClick={() => onClickTrain(modelType, isContextualModel)}>Continue and train</Button>
      </div>
      <div className={styles.dashboardSndTilte}>
        <span>{selectedRows.length}</span>
        intents
      </div>
      <div className={styles.tableDiv}>
        <Flex direction="column">
          <Radio.Group
            onChange={(e) => {
              setModelType(e.target.value);
            }}
            value={modelType}
          >
            <Radio value="">Default</Radio>
            <Radio value="deberta">DeBERTa</Radio>
            <Radio value="mdeberta">Multi-lingual DeBERTa</Radio>
          </Radio.Group>
          {modelType !== '' && (
            <>
              <Space h="sm" />
              <Checkbox
                checked={isContextualModel}
                onChange={(e: CheckboxChangeEvent) => {
                  setIsContextualModel(e.target.checked);
                }}
              >
                <Tooltip title="determines how hyperparameters sweeping is done">
                  <span>is contextual model</span>
                </Tooltip>
              </Checkbox>
            </>
          )}
          <Space h="sm" />
          <Table
            columns={columns}
            dataSource={selectedRows}
            rowKey={(row: IntentsDashboardTableRow) => `${row.name}`}
            pagination={false}
          />
        </Flex>
      </div>
    </div>
  );
}
