import { useEffect } from 'react';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useInterval } from '@mantine/hooks';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { fetchLabelingTasks } from 'store/labelingTask/asyncThunks';
import { selectDerivedLabelingTaskFactory } from 'store/labelingTask/selectors';
import { useDispatch, useSelector } from './reduxHooks';

async function extendSession(taskName: string) {
  if (!taskName) return;
  await LabelingTaskApi.extendLabelingSession({
    taskName,
  });
}

export const useLabelingSession = (taskName: string) => {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customerParams = useCustomerParams();
  const { usecaseId, languageCode } = customerParams;

  const derivedLabelingTask = useSelector<DerivedLabelingTask>(
    selectDerivedLabelingTaskFactory(taskName),
  );

  // Extend user session every 10 second and only if the document is focused
  const sessionInterval = useInterval(async () => {
    const isWindowActive = document.hasFocus();
    if (taskName && isWindowActive) {
      console.info('Extending Task Time');
      extendSession(taskName);
    }
  }, 10000);

  // Fetch derived tasks if they're not loaded
  useEffect(() => {
    if (taskName && !derivedLabelingTask) {
      // Derived task needed for overview panel
      dispatch(fetchLabelingTasks({
        parent: customerProfile,
        usecaseId,
        languageCode,
      }));
    }
    // Start session tracking
    if (taskName) {
      sessionInterval.start();
      if (document.hasFocus()) {
        extendSession(taskName);
      }
    }

    return sessionInterval.stop;
  }, [taskName]);
};
