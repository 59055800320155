import React, { useEffect, useMemo, useState, createContext, useContext } from 'react';
import { getId, extractCustomerFromResource } from 'common/resourceName';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { CustomerParams } from 'hooks/useCustomerParams';
import { LabelingTaskService, ListDerivedTasksRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { flatten } from 'lodash';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useSelector } from 'hooks/reduxHooks';
import { selectCustomersCurrentUserIds } from 'store/app/selectors';
import { StudioApi } from 'services/studioApi';

interface TaskPlaylistProviderValues {
  openedTasks: DerivedLabelingTask[],
  closedTasks: DerivedLabelingTask[],
  isLoading: boolean,
  currentUserCount: number,
  unassignedCount: number,
}

interface TaskPlaylistProviderProps {
  selectedCustomers: CustomerParams[],
  children: React.ReactNode,
  excludedTaskIds?: string[],
}

export const TaskPlaylistContext = createContext<TaskPlaylistProviderValues>(null);

export function TaskPlaylistProvider({
  children,
  selectedCustomers = [],
  excludedTaskIds = [],
}: TaskPlaylistProviderProps) {
  const { getFirstConfig } = useContext(CustomerConfigContext);
  const [tasks, setTasks] = useState<DerivedLabelingTask[]>([]);
  const [fetchingTasks, setFetchingTasks] = useState<boolean>(true);
  const customersCurrentUserIds = useSelector(selectCustomersCurrentUserIds);

  const fetchCurrentUserTasks = async () => {
    setFetchingTasks(true);
    const promises = selectedCustomers.map((customer) => {
      const request: ListDerivedTasksRequest = {
        parent: `customers/${customer.customerId}/profiles/${customer.profileId}`,
        filter: {
          usecaseId: customer.usecaseId,
          languageCode: customer.languageCode,
        },
      };
      const customerConfig = getFirstConfig(customer);
      const { initReq } = StudioApi.getHeaders(customerConfig);
      return LabelingTaskService.ListDerivedTasks(request, initReq);
    });

    const responses = await Promise.all(promises);
    const tasks = flatten(responses.map((response) => response.derivedTasks));
    setTasks(tasks);
    setFetchingTasks(false);
  };

  useEffect(() => {
    fetchCurrentUserTasks();
  }, [selectedCustomers?.length]);

  const [openedTasks, closedTasks, currentUserCount, unassignedCount] = useMemo(() => {
    let currentUserCount = 0;
    let unassignedCount = 0;
    const openedTasks = [];
    const closedTasks = [];
    tasks.forEach((task) => {
      // Filter out excluded tasks
      const taskId = getId('labelingTask', task.labelingTask.name);
      const { customerId, profileId } = extractCustomerFromResource('labelingTask', task.labelingTask.name);
      if (excludedTaskIds.includes(taskId)) return;

      const currentUserId = customersCurrentUserIds[`${customerId}/${profileId}`];

      const isReadyOrStarted = [TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(task.labelingTask.abstractTask.status);
      const isClosed = task.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED;

      const isCurrentUser = task.labelingTask.abstractTask.assigneeUserId === currentUserId;
      const isUnassigned = !task.labelingTask.abstractTask.assigneeUserId;
      if (isCurrentUser && isReadyOrStarted) {
        currentUserCount += 1;
      } else if (isUnassigned && isReadyOrStarted) {
        unassignedCount += 1;
      }
      if ((isCurrentUser || isUnassigned) && isReadyOrStarted) {
        openedTasks.push(task);
      } else if (isCurrentUser && isClosed) {
        closedTasks.push(task);
      }
    });

    return [openedTasks, closedTasks, currentUserCount, unassignedCount];
  }, [tasks, excludedTaskIds]);

  const value = useMemo(() => ({
    openedTasks,
    closedTasks,
    isLoading: fetchingTasks,
    currentUserCount,
    unassignedCount,
  }), [openedTasks, closedTasks, fetchingTasks, currentUserCount, unassignedCount]);

  return (
    <TaskPlaylistContext.Provider value={value}>
      {children}
    </TaskPlaylistContext.Provider>
  );
}
