import React from 'react';
import { Text, Flex, Group, UnstyledButton } from '@mantine/core';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PromptIcon } from '../assets/PromptIcon';
import { IPrompt } from '../hooks/usePrompts';

dayjs.extend(relativeTime);

interface ListItemProps {
    isSelected: boolean;
    onClick: (prompt: IPrompt) => void;
    title: string;
    timestamp: Date;
    prompt: IPrompt;
  }

export const ListItem = ({ isSelected, onClick, title, timestamp, prompt }: ListItemProps) => (
  <UnstyledButton
    onClick={() => onClick(prompt)}
    w={310}
    p="md"
    sx={(theme) => ({
      display: 'block',
      width: '100%',
      backgroundColor: isSelected ? theme.colors.blue[0] : theme.white,
      borderRadius: 5,
      color: isSelected ? theme.colors.blue[6] : theme.black,
      '&:hover': {
        backgroundColor:
            isSelected ? theme.colors.blue[1] : theme.colors.gray[0],
      },
    })}
  >
    <Group>
      <PromptIcon />
      <Flex direction="column" style={{ flex: 1, overflow: 'hidden' }} align="stretch">
        <Text size="sm" truncate >{title}</Text>
        <Text color="dimmed" fs="italic" title={dayjs(timestamp).format(DATE_TIME_FORMAT)} size="xs">
          {dayjs(timestamp).fromNow()}
        </Text>
      </Flex>
    </Group>
  </UnstyledButton>
);
