import React from 'react';
import './ChatCard.scss';
import { truncateMiddle } from 'utils';
import CopyableValue from 'components/CopyableValue';
import { DeleteIcon, VectorIcon } from 'pages/Simulator/icons';
import classNames from 'classnames';

interface Props {
  chatCardTitle: string;
  conversationId:string;
  chatOpenTime:string;
  active: boolean;
  hiddenDeleteIcon?: boolean;
  inGoldenSet: boolean;
  handleClickCard: (conversationId: string) => void;
  handleDeleteCard?: (conversationId: string) => void
  style?: React.CSSProperties;
}

export function ChatCard({
  chatCardTitle,
  inGoldenSet,
  conversationId,
  chatOpenTime,
  active,
  handleClickCard,
  handleDeleteCard,
  hiddenDeleteIcon,
  style,
}: Props) {
  return (
    <div style={style} onClick={() => handleClickCard(conversationId)} className={classNames(['chat-card', active ? 'active' : ''])}>
      <div className="card-content">
        <p className="agent-name">
          {chatCardTitle}
        </p>
        <CopyableValue
          displayValue={truncateMiddle(conversationId, 0, 6)}
          copiedValue={conversationId}
        />
        <span className="date-time">
          {chatOpenTime}
        </span>
      </div>
      <div className="card-right">
        {
          !hiddenDeleteIcon
          && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteCard(conversationId);
            }}
            className="card-delete"
          >
            <DeleteIcon/>
          </div>
          )
        }
        <div className="card-message">
          <span className="card-time" style={{ marginRight: `${inGoldenSet ? '10px' : '0px'}` }}/>{inGoldenSet && <VectorIcon/>}
        </div>
      </div>
    </div>
  );
}
