import { ArrowLeftOutlined } from '@ant-design/icons';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Box, Image, Group, Text, Title, Anchor, Stack, Flex } from '@mantine/core';
import { UserContext } from 'context/UserContext';
import { useSelector } from 'hooks/reduxHooks';
import React, { useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectTaskPlaylistCustomers } from 'store/app/selectors';
import { TaskPlaylistTable } from './TaskPlaylistTable';
import { TaskPlaylistProvider, TaskPlaylistContext } from './TaskPlaylistProvider';

const GIF_URLS = [
  'https://i.giphy.com/media/ZdUnQS4AXEl1AERdil/giphy.webp',
  'https://i.giphy.com/media/8VrtCswiLDNnO/giphy.webp',
  'https://i.imgur.com/lNUh0uV.gif',
  'https://i.giphy.com/media/atfHlwAhizfxdtdw60/giphy.webp',
  'https://i.giphy.com/media/NJZMSqRY3rG9i/giphy.webp',
  'https://i.giphy.com/media/iziNajLRbDcIy9f1uN/giphy.webp',
  'https://i.giphy.com/media/3o72FcJmLzIdYJdmDe/giphy.webp',
  'https://i.giphy.com/media/xUPGcMzwkOY01nj6hi/giphy.webp',
  'https://i.giphy.com/media/l3q2K2I2vCZqvaqoE/giphy.webp',
  'https://i.giphy.com/media/l49JHLpRSLhecYEmI/giphy.webp',
  'https://i.giphy.com/media/aNqEFrYVnsS52/giphy.webp',
  'https://i.giphy.com/media/xHMIDAy1qkzNS/giphy.webp',
  'https://i.giphy.com/media/ndeihy7lvAL9C/giphy.webp',
  'https://i.giphy.com/media/l6Td5sKDNmDGU/giphy.webp',
  'https://i.giphy.com/media/WAGYH6TFa8E9O/giphy.webp',
  'https://media3.giphy.com/media/DffShiJ47fPqM/giphy.gif',
  'https://media0.giphy.com/media/R6gvnAxj2ISzJdbA63/giphy.gif',
  'https://i.giphy.com/media/dkGhBWE3SyzXW/giphy.webp',
  'https://i.giphy.com/media/26ufdipQqU2lhNA4g/giphy.webp',
  'https://i.giphy.com/media/S3Ot3hZ5bcy8o/giphy.webp',
  'https://i.giphy.com/media/11ZAUfeJHojWlW/giphy.webp',
  'https://i.giphy.com/media/5tiG8KMfk8h7Dabth8/giphy.webp',
  'https://i.giphy.com/media/slOhiKAVFgwr6/giphy-downsized-large.gif',
  'https://i.giphy.com/media/13DrdRI2JqdoLC/giphy.webp',
  'https://i.giphy.com/media/9g8PH1MbwTy4o/giphy.webp',
  'https://i.giphy.com/media/Mp4hQy51LjY6A/giphy.webp',
  'https://i.giphy.com/media/iurIHLBxms7UQ/giphy-downsized-large.gif',
];

interface TaskCompletedProps {
  tasksQueueLink: string;
}

const countAssigned = (tasks: DerivedLabelingTask[], currentUserId: string) => tasks.filter((task) => task.labelingTask.abstractTask.assigneeUserId === currentUserId)?.length;
const countUnassigned = (tasks: DerivedLabelingTask[]) => tasks.filter((task) => !task.labelingTask.abstractTask.assigneeUserId)?.length;

export function TaskCompleted({
  tasksQueueLink,
}: TaskCompletedProps) {
  const currentUser = useContext(UserContext);
  const currentUserId = currentUser?.id;
  const navigate = useNavigate();
  const randomGifUrl = useRef(GIF_URLS[Math.floor(Math.random() * GIF_URLS.length)]);
  const { taskId } = useParams<{ taskId: string }>();
  const taskPlaylistCustomers = useSelector(selectTaskPlaylistCustomers);

  return (
    <Stack>
      <Flex
        justify="center"
        align="center"
        direction="column"
        mt="xl"
      >
        <Image my="xl" width={300} radius="md" src={randomGifUrl?.current} alt="Funny gif!" />
        <Title>Congrats!</Title>
        <Text mb="md" color="gray">You have labeled everything</Text>
        <Anchor onClick={() => navigate(tasksQueueLink)} data-cy="back-to-tasks-btn">
          <Group spacing="xs">
            <ArrowLeftOutlined />
            <Text>Back to tasks</Text>
          </Group>
        </Anchor>
      </Flex>
      <Box p="xl" mt="md">
        <TaskPlaylistProvider selectedCustomers={taskPlaylistCustomers} excludedTaskIds={[taskId]}>
          <TaskPlaylistContext.Consumer>
            {
            ({ openedTasks, isLoading }) => (
              <>
                {isLoading ? (
                  <Text mb="sm">Loading your next tasks...</Text>
                ) : (
                  <Text mb="sm">There are {countAssigned(openedTasks, currentUserId)} other tasks assigned to you and {countUnassigned(openedTasks)} unassigned tasks you can jump to:</Text>
                )}
                <TaskPlaylistTable
                  tasks={openedTasks}
                  loading={isLoading}
                />
              </>
            )
          }
          </TaskPlaylistContext.Consumer>
        </TaskPlaylistProvider>
      </Box>
    </Stack>
  );
}
