import { Select, Table, Tooltip } from 'antd';
import React from 'react';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import classNames from 'classnames';
import { selectApiStatus } from 'store/annotation/selectors';
import { ColumnsType } from 'antd/lib/table';
import { DeleteTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import { Annotation, AnnotationState, BinaryValueValue, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { listConversationMessages } from 'store/conversation/asyncThunks';
import CopyableValue from 'components/CopyableValue';
import { Button } from '@mantine/core';
import { ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { deprecateAnnotations } from 'store/annotation/asyncThunks';
import {
  SearchAnnotationsResponseAnnotationBundle as AnnotationBundle,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { getId, getParent } from 'common/resourceName';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { TableRowSelection } from 'antd/lib/table/interface';
import { createConfirm } from 'components/ConfirmModal';
import useUrlParam from 'hooks/useUrlParam';
import styles from './styles.module.scss';
import { DIVERSITY_TOOLTIP_COPY } from '../Labels/IntentLabelsTable';
import { AnnotationBundleWithMetrics, formatLabelValue } from '.';

interface BinaryLabelsReviewTableProps {
  changeAnnotation: (annotation: Annotation, value: BinaryValueValue) => void;
  messageId: string;
  setAnnotation: (annotation: AnnotationBundle) => void;
  currentAnnotations: AnnotationBundleWithMetrics[];
  conceptType: ConceptConceptType;
  setPageInfo: ({ page, pageSize }: { page: number, pageSize: number }) => void;
  rowSelection: TableRowSelection<AnnotationBundle>;
}

function getAnnotationConversationId(annotation: Annotation) {
  return annotation.rawData.messageRawData?.v2ConversationId || annotation.rawData.messageRawData?.conversationId;
}

function getAnnotationMessageId(annotation: Annotation) {
  return annotation.rawData.messageRawData?.v2MessageId || annotation.rawData.messageRawData?.messageId;
}

export default function BinaryLabelsReviewTable({
  changeAnnotation,
  messageId,
  setAnnotation,
  currentAnnotations,
  conceptType,
  setPageInfo,
  rowSelection,
}: BinaryLabelsReviewTableProps) {
  const apiStatus = useSelector<ApiStatus>(selectApiStatus);
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const [targetType] = useUrlParam<TargetType>('target');
  const [entityMessageDisplay] = useUrlParam<'span' | 'utterance'>('messageDisplay');

  const columns: ColumnsType<AnnotationBundleWithMetrics> = [
    {
      title: 'Message ID',
      dataIndex: ['annotation', 'rawData', 'messageRawData', 'messageId'],
      key: 'messageId',
      render: (value, row) => {
        const messageId = getAnnotationMessageId(row.annotation);
        if (!messageId) return null;
        return (
          <CopyableValue
            displayValue={`${messageId.substring(0, 6)}...`}
            copiedValue={messageId}
          />
        );
      },
      width: 120,
    },
    {
      title: 'Message',
      dataIndex: 'textMessage',
      key: 'textMessage',
      render: (value, row) => {
        if (conceptType === ConceptConceptType.ENTITY) {
          const spanText = row.textMessage.substring(row.annotation.rawData.messageRawData?.spanStart, row.annotation.rawData.messageRawData?.spanEnd);
          if (entityMessageDisplay === 'span') {
            return spanText;
          } else {
            return (
              <>
                <span>{row.textMessage.slice(0, row.annotation.rawData.messageRawData?.spanStart)}</span>
                <mark>{spanText}</mark>
                <span>{row.textMessage.slice(row.annotation.rawData.messageRawData?.spanEnd)}</span>
              </>
            );
          }
        } else {
          return value;
        }
      },
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Diversity Score</span>
          <Tooltip
            placement="rightTop"
            title={() => (
              <div>
                <b>Diversity Score</b>
                <div>{DIVERSITY_TOOLTIP_COPY}</div>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      width: 180,
      dataIndex: ['diversityScore'],
      sorter: ((a, b) => Number(a.diversityScore || 0) - Number(b.diversityScore || 0)),
      defaultSortOrder: 'descend',
      key: 'state',
      render: (value: number, row) => {
        if (!value) {
          return '--';
        } else {
          return value.toFixed(2);
        }
      },
    },
    {
      title: 'Source',
      dataIndex: ['annotation', 'state'],
      sorter: ((a, b) => String(a.annotation.state).localeCompare(b.annotation.state)),
      defaultSortOrder: 'ascend',
      key: 'state',
      render: (value: AnnotationState, row) => {
        if (value === AnnotationState.ACTIVE_AUTO_LABELED) {
          return 'Auto';
        }
        if (value === AnnotationState.ACTIVE_MANUALLY_ADDED && row.annotation.rawData.messageRawData?.synthetic) {
          return 'Manual';
        }
        return 'Labeling';
      },
    },
    {
      title: conceptType === ConceptConceptType.ENTITY ? '' : 'Label',
      dataIndex: ['annotation', 'value', 'binaryValue', 'value'],
      key: 'label',
      render: (value, record) => (
        <div>
          {conceptType !== ConceptConceptType.ENTITY && (
          <Select
            className={classNames([styles.select, styles.valueSelect])}
            showArrow
            size="large"
            placeholder="Intent type"
            value={value}
            onClick={(e) => e.stopPropagation()}
            onChange={(value: BinaryValueValue) => changeAnnotation(record.annotation, value)}
          >
            <Select.Option key={BinaryValueValue.VALUE_POSITIVE} value={BinaryValueValue.VALUE_POSITIVE}>
              <button
                type="button"
                className={classNames([styles.labelButton, styles.positive])}
              >{formatLabelValue(BinaryValueValue.VALUE_POSITIVE)}
              </button>
            </Select.Option>
            <Select.Option key={BinaryValueValue.VALUE_NEGATIVE} value={BinaryValueValue.VALUE_NEGATIVE}>
              <button
                type="button"
                className={classNames([styles.labelButton, styles.negative])}
              >{formatLabelValue(BinaryValueValue.VALUE_NEGATIVE)}
              </button>
            </Select.Option>
            <Select.Option key={BinaryValueValue.VALUE_FLAG_FOR_REVIEW} value={BinaryValueValue.VALUE_FLAG_FOR_REVIEW}>
              <button
                type="button"
                className={classNames([styles.labelButton, styles.unsure])}
              >{formatLabelValue(BinaryValueValue.VALUE_FLAG_FOR_REVIEW)}
              </button>
            </Select.Option>
          </Select>
          )}
          <Tooltip title="Deprecate the label">
            <Button
              variant="subtle"
              leftIcon={<DeleteTwoTone />}
              onClick={async (e) => {
                e.stopPropagation();

                const confirmDeprecate = await createConfirm<boolean>({
                  title: 'Are you sure you want to deprecate this label?',
                  content: '',
                  buttons: [
                    {
                      text: 'Yes',
                      value: true,
                    },
                    {
                      text: 'Cancel',
                      buttonProps: {
                        variant: 'subtle',
                      },
                      value: false,
                    },
                  ],
                });

                if (!confirmDeprecate) return;

                const parent = getParent('annotation', record.annotation.name);
                const id = getId('annotation', record.annotation.name);
                dispatch(deprecateAnnotations({ parent, annotationIds: [id] }));
              }}
            />
          </Tooltip>
        </div>
      ),
      width: conceptType === ConceptConceptType.ENTITY ? undefined : 220,
    },
  ];

  return (
    <Table
      className={styles.labelsTable}
      rowClassName={(record) => {
        const isClickable = getAnnotationMessageId(record.annotation);
        return classNames([
          { [styles.activeRow]: getAnnotationMessageId(record.annotation) === messageId },
          { [styles.clickableRow]: isClickable },
          styles.labelRow,
        ]);
      }}
      columns={columns}
      dataSource={currentAnnotations}
      rowSelection={targetType === TargetType.QA ? rowSelection : undefined}
      rowKey={(record: AnnotationBundle) => record.annotation.name}
      loading={{
        spinning: apiStatus === 'loading',
        indicator: <Loading />,
      }}
      onRow={(record) => ({
        onClick: (e) => {
          if (!getAnnotationMessageId(record.annotation)) return;
          setAnnotation(record);
          const conversationName = `${customerProfile}/conversations/${getAnnotationConversationId(record.annotation)}`;
          dispatch(listConversationMessages(conversationName));
        },
      })}
      pagination={{
        onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
      }}
    />
  );
}
