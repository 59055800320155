import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './NavigateDecision.scss';
import { Button, Group } from '@mantine/core';

interface NavigateDecisionProps {
  loading?: boolean,
  showBackButton?: boolean,
  showNextButton?: boolean,
  labelingFinished?: boolean,
  prevHandler: () => void,
  nextHandler: () => void,
  finishLabeling: () => void,
  fullWidth?: boolean,
}

/** Only show buttons to navigate back and forward through labeling items */
export default function NavigateDecision({
  loading,
  showBackButton = true,
  showNextButton = true,
  labelingFinished = false,
  prevHandler,
  nextHandler,
  finishLabeling,
  fullWidth,
}: NavigateDecisionProps) {
  return (
    <div className="navigate-decision">
      <Group grow={fullWidth} position="center">
        <Button
          color="blue"
          variant="light"
          disabled={!showBackButton || loading}
          onClick={prevHandler}
        >
          <LeftOutlined/>
          Back
        </Button>
        {labelingFinished && !showNextButton
          ? (
            <Button
              color="primary"
              disabled={loading}
              onClick={finishLabeling}
              data-cy="finish-btn"
            >
              Finish
            </Button>
          )
          : (
            <Button
              color="blue"
              variant="light"
              disabled={!showNextButton || loading}
              onClick={nextHandler}
            >
              Next
              <RightOutlined/>
            </Button>
          )}
      </Group>
    </div>
  );
}
