import { useState } from 'react';

import { useCustomerParams } from 'hooks/useCustomerParams';
import { insertArrayIfNotExists } from 'utils';

export function useLocalStorageState<T>(key: string, defaultValue: T = null):
    [T, (value: T) => void, () => void] {
  const customer = useCustomerParams();
  const keyWithCustomer = `${customer.path}.${key}`;
  const [_value, _setValue] = useState(() => {
    try {
      const savedValue = window.localStorage.getItem(keyWithCustomer);
      return savedValue != null ? JSON.parse(savedValue) : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  });

  const setValue = (nextValue) => {
    try {
      _setValue(nextValue);
      if (nextValue === undefined) {
        window.localStorage.removeItem(keyWithCustomer);
      } else {
        window.localStorage.setItem(keyWithCustomer, JSON.stringify(nextValue));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearValue = () => {
    setValue(undefined);
  };

  return [_value, setValue, clearValue];
}

// TODO support removing from array
export function useLocalStorageArrayState<T>(
  key: string,
  defaultValue: T[] = null,
  prepend: boolean = false,
): [T[], (value: T[]) => void, (value: T) => void, () => void] {
  const [value, setValue, clearValue] = useLocalStorageState(key, defaultValue);

  const insertValue = (newValue: T) => {
    const nextValue = insertArrayIfNotExists(value, newValue, prepend, false);
    setValue(nextValue);
  };

  return [value, setValue, insertValue, clearValue];
}
