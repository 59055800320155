import { useEffect, useState } from 'react';
import { LabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { getId } from 'common/resourceName';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { ComparisonModelSummary } from './QAComparisonTable';
import { QAScoreSummary } from './QAScoreTable';

interface QATableData {
  singleModelSummary: QAScoreSummary;
  comparisonModelSummary: Omit<ComparisonModelSummary, 'model2Uri'>;
}

interface ErrorObj {
  code: number;
  message: string;
  status: string;
  httpStatus: number;
}

export const useQaTableData: (qaTask: LabelingTask) => [QATableData, boolean, string] = (
  qaTask: LabelingTask,
) => {
  const taskSummary = getId('abstractTask', qaTask?.abstractTask?.name);
  const defaultSingleModelData = {
    qaCount: null,
    goodCount: null,
    badCount: null,
    neutralCount: null,
    taskSummary,
    dateCompleted: null,
  };
  const defaultComparisonModelData = {
    model1Count: null,
    model2Count: null,
    neitherCount: null,
    bothCount: null,
    model2Uri: null,
    taskSummary,
    dateCompleted: null,
  };
  const [data, setData] = useState<QATableData>({
    singleModelSummary: defaultSingleModelData,
    comparisonModelSummary: defaultComparisonModelData,
  });
  const [isLoading, toggleLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  useEffect(() => {
    const getData = async () => {
      toggleLoading(true);
      try {
        const result = await LabelingTaskApi.fetchTaskAnnotationsSummary({
          taskName: qaTask.name,
        });
        const singleModelSummary = result?.suggestionsQaSummary?.singleModelSummary;
        let singleModelData: QAScoreSummary = defaultSingleModelData;
        if (singleModelSummary) {
          singleModelData = {
            qaCount: singleModelSummary.total,
            goodCount: singleModelSummary.goodCount,
            badCount: singleModelSummary.badCount,
            neutralCount: singleModelSummary.neutralCount,
            taskSummary,
            dateCompleted: new Date(qaTask?.abstractTask?.updateTime),
          };
        }
        const comparisonModelSummary = result?.suggestionsQaSummary?.compareModelSummary;
        let comparisonModelData: Omit<
          ComparisonModelSummary,
          'model2Uri'
        > = defaultComparisonModelData;
        if (comparisonModelSummary) {
          comparisonModelData = {
            model1Count: comparisonModelSummary.model1Count,
            model2Count: comparisonModelSummary.model2Count,
            neitherCount: comparisonModelSummary.neitherCount,
            bothCount: comparisonModelSummary.bothCount,
            taskSummary,
            dateCompleted: new Date(qaTask?.abstractTask?.updateTime),
          };
        }
        setData({
          singleModelSummary: singleModelData,
          comparisonModelSummary: comparisonModelData,
        });
        toggleLoading(false);
        setError(null);
      } catch (e) {
        const err = e as ErrorObj;
        toggleLoading(false);
        setError(err.message);
        setData({
          singleModelSummary: defaultSingleModelData,
          comparisonModelSummary: defaultComparisonModelData,
        });
      }
    };
    getData();
  }, [qaTask.name]);

  return [data, isLoading, error];
};
