import React, { useEffect, useMemo, useState } from 'react';
import { Annotation, BinaryValue, BinaryValueValue, MessageRawData, MessageRawDataConfusionType, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message, MessageSelectionContextPolicyQaContextTrigger } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Input, Select } from 'antd';
import { Concept, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { Button, Tooltip } from '@mantine/core';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/ai_service/common';
import './PolicyDecision.scss';
import ConceptTag from 'components/ConceptTag';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { PolicyDebugOutput } from 'types';
import { useHotkeys } from '@mantine/hooks';
import PolicyDecisionListItem, { PolicyDecisionListItemType } from './PolicyDecisionListItem';

interface PolicyDecisionProps {
  loading?: boolean,
  concepts: Concept[],
  message: Message,
  contextShown: MessageRawDataContextShown,
  annotations: Annotation[],
  predictions?: Prediction[],
  getConceptTitle: (conceptId: string) => string;
  getConceptRole: (conceptId: string) => 'agent' | 'visitor';
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => void;
  removeAnnotations: () => void;
  showRedoButton: boolean;
}

/** Shows all intents that are valid for selected message. This labeling expects more than 1 intent. */
export default function PolicyDecision({
  loading,
  concepts = [],
  message,
  contextShown,
  annotations = [],
  predictions = [],
  getConceptTitle,
  getConceptRole,
  createAnnotation,
  submitAnnotations,
  removeAnnotations,
  showRedoButton,
}: PolicyDecisionProps) {
  const [note, setNote] = useState('');
  const [tempValue, setTempValue] = useState<BinaryValueValue>();
  const [triggeringIntents, setTriggeringIntents] = useState<{[key: string]: MessageSelectionContextPolicyQaContextTrigger}>();
  const [incorrectTriggeringIntents, setIncorrectTriggeringIntents] = useState<string[]>([]);
  const [allTriggeringIntentsCorrect, setAllTriggeringIntentsCorrect] = useState(false);
  const [selectedNextIntentConceptId, setSelectedNextIntentConceptId] = useState<string>();

  const predictionsMap = useMemo(() => new Map<string, Prediction>(predictions.map((prediction) =>
    [getId('prediction', prediction.name), prediction])), [predictions]);

  // Only Concepts that are agent intents
  const intents = useMemo(() => concepts.filter((concept) => concept.intent?.intentType === IntentIntentType.AGENT_INTENT), [concepts]);

  useEffect(() => {
    if (message) {
      setTempValue(null);
      setSelectedNextIntentConceptId(null);
      setIncorrectTriggeringIntents([]);
      setAllTriggeringIntentsCorrect(null);
      setTriggeringIntents(message.selectionContext?.policyQaContext?.triggers);
      // Reset note
      const flaggedAnnotation = annotations.find((a) => a.value.binaryValue.value === BinaryValueValue.VALUE_FLAG_FOR_REVIEW);
      if (flaggedAnnotation) {
        setNote(flaggedAnnotation.value.binaryValue.note);
      } else {
        setNote('');
      }
    }
  }, [message]);

  const nextIntentPredictionIds = message?.selectionContext?.policyQaContext?.nextIntentPredictionIds || [];
  const nextIntentConceptIds = message?.selectionContext?.policyQaContext?.nextIntentConceptIds || [];
  const showingNextIntentIndex: number = nextIntentPredictionIds?.findIndex((nextIntentPredictionId, i) => {
    const predictionAnnotation = annotations.find((annotation) => annotation.rawData.messageRawData.predictionId === nextIntentPredictionId);
    // If prediction doesn't have annotation, it's the next to be displayed
    if (!predictionAnnotation) return true;
    // If we get here, it means all predictions have annotations, then display the last
    if (i === nextIntentPredictionIds.length - 1) return true;
    return false;
  });
  const showingNextIntentPredictionId = nextIntentPredictionIds?.[showingNextIntentIndex];
  const showingNextIntentConceptId = nextIntentConceptIds?.[showingNextIntentIndex];

  const saveIncorrect = () => {
    const confusionType = incorrectTriggeringIntents?.length
      ? MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER
      : MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE;

    const annotations = [];
    // Only add if user selected different next predicted intent
    if (selectedNextIntentConceptId) {
      const falseNegativeAnnotation = createAnnotation(
        {
          conversationId: message.conversationId,
          messageId: message.messageId,
          v2ConversationId: message.v2ConversationId,
          v2MessageId: message.v2MessageId,
          contextShown,
          spanStart: message.targetSpanStart,
          spanEnd: message.targetSpanEnd,
          predictionId: showingNextIntentPredictionId,
          confusionType: MessageRawDataConfusionType.FALSE_NEGATIVE,
        },
        {
          value: BinaryValueValue.VALUE_POSITIVE,
          conceptId: selectedNextIntentConceptId,
          note,
        },
        MomentAnnotationAdherenceType.ADHERENCE_TYPE_SHOULD_DO_X,
      );
      annotations.push(falseNegativeAnnotation);
    }

    // Only add if next predicted intent is not "None"
    if (showingNextIntentConceptId) {
      const falsePositiveAnnotation = createAnnotation(
        {
          conversationId: message.conversationId,
          messageId: message.messageId,
          v2ConversationId: message.v2ConversationId,
          v2MessageId: message.v2MessageId,
          contextShown,
          spanStart: message.targetSpanStart,
          spanEnd: message.targetSpanEnd,
          predictionId: showingNextIntentPredictionId,
          confusionType,
        },
        {
          value: BinaryValueValue.VALUE_NEGATIVE,
          conceptId: showingNextIntentConceptId,
          note,
        },
        MomentAnnotationAdherenceType.ADHERENCE_TYPE_SHOULD_DO_X,
      );
      annotations.push(falsePositiveAnnotation);
    }

    const triggeringIntentsAnnotations = nextIntentPredictionIds.map((id) => {
      const trigger = triggeringIntents[id];
      const isIncorrect = incorrectTriggeringIntents.includes(id);
      const value = isIncorrect ? BinaryValueValue.VALUE_NEGATIVE : BinaryValueValue.VALUE_POSITIVE;
      const annotation = createAnnotation(
        {
          conversationId: message.conversationId,
          messageId: message.messageId,
          v2ConversationId: message.v2ConversationId,
          v2MessageId: message.v2MessageId,
          contextShown,
          spanStart: message.targetSpanStart,
          spanEnd: message.targetSpanEnd,
          predictionId: trigger.predictionId,
        },
        {
          value,
          conceptId: trigger.conceptId,
          note,
        },
        MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED,
      );
      return annotation;
    });

    submitAnnotations([...annotations, ...triggeringIntentsAnnotations], 'chat');
  };

  const saveCorrect = () => {
    // Prevent creating duplicate correct annotations
    const hasCorrectAnnotations = annotations.some((annotation) => {
      const isTP = annotation.rawData.messageRawData.confusionType === MessageRawDataConfusionType.TRUE_POSITIVE;
      const isSamePredictionId = annotation.rawData.messageRawData.predictionId === showingNextIntentPredictionId;
      return isTP && isSamePredictionId;
    });
    setTempValue(BinaryValueValue.VALUE_POSITIVE);
    const policyQaContext = message.selectionContext?.policyQaContext;

    // Determine whether to stay or move to next message
    const isLastNextIntentPrediction = nextIntentPredictionIds?.length - 1 === showingNextIntentIndex;
    const gotoNext = isLastNextIntentPrediction ? 'message' : 'noop';

    if (policyQaContext?.nextIntentConceptIds?.length && !hasCorrectAnnotations) {
      const annotation = createAnnotation(
        {
          conversationId: message.conversationId,
          messageId: message.messageId,
          v2ConversationId: message.v2ConversationId,
          v2MessageId: message.v2MessageId,
          contextShown,
          spanStart: message.targetSpanStart,
          spanEnd: message.targetSpanEnd,
          predictionId: showingNextIntentPredictionId,
          confusionType: MessageRawDataConfusionType.TRUE_POSITIVE,
        },
        {
          value: BinaryValueValue.VALUE_POSITIVE,
          conceptId: showingNextIntentConceptId,
        },
        MomentAnnotationAdherenceType.ADHERENCE_TYPE_SHOULD_DO_X,
      );
      submitAnnotations([annotation], gotoNext);
    } else {
      submitAnnotations([], gotoNext);
    }
  };

  // Hotkeys
  useHotkeys([
    ['c', saveCorrect],
    ['i', () => setTempValue(BinaryValueValue.VALUE_NEGATIVE)],
    ['n', () => {
      if (tempValue === BinaryValueValue.VALUE_NEGATIVE) {
        saveIncorrect();
      }
    }],
  ]);

  const hasTriggeringIntents = triggeringIntents && Object.keys(triggeringIntents).length > 0;
  const areTriggeringIntentsSelected = incorrectTriggeringIntents?.length > 0 || allTriggeringIntentsCorrect;

  // In case there are 0 triggering intents,
  // we have to require from the user to select next intent
  const canSubmit = useMemo(() => {
    if (hasTriggeringIntents) {
      return areTriggeringIntentsSelected && note;
    } else {
      return selectedNextIntentConceptId && note;
    }
  }, [hasTriggeringIntents, areTriggeringIntentsSelected, selectedNextIntentConceptId, note]);

  if (showRedoButton) {
    return (
      <div className="policy-decision">
        <p>
          This conversation has been QAed.
        </p>
        <br/>
        <Button color="purple" variant="light" onClick={() => removeAnnotations()}>Redo</Button>
      </div>
    );
  }

  const messagePrediction = predictionsMap.get(showingNextIntentPredictionId);
  const debugOutput = messagePrediction?.moment?.intentPayload?.dialoguePolicyDebugOutput as PolicyDebugOutput;

  const renderRules = () => {
    if (debugOutput) {
      return (
        <>
          {debugOutput.rule_evaluation.conditions.map((condition) => (
            <PolicyDecisionListItem checkbox={false} key={condition.condition_as_str}>
              <b>{debugOutput.rule_evaluation.edge_as_str}</b>
              <br/>
              <span>{condition.condition_as_str}</span>
            </PolicyDecisionListItem>
          ))}
          {debugOutput.rule_evaluation.conditions?.length === 0 && (
            <span>None</span>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <div className="policy-decision">
      <p className="section-title">Predicted SDX:</p>
      {showingNextIntentConceptId ? (
        <ConceptTag
          role={getConceptRole(showingNextIntentConceptId)}
          value={getConceptTitle(showingNextIntentConceptId) || showingNextIntentConceptId}
        />
      ) : (
        <p className="predicted-sdx-none">None</p>
      )}
      <div className="buttons">
        <div>
          <Button
            variant="light"
            color="green"
            disabled={loading}
            onClick={() => saveCorrect()}
            data-active={tempValue === BinaryValueValue.VALUE_POSITIVE}
          >
            Correct (C)
          </Button>
          <Button
            variant="light"
            color="red"
            disabled={loading}
            onClick={() => setTempValue(BinaryValueValue.VALUE_NEGATIVE)}
            data-active={tempValue === BinaryValueValue.VALUE_NEGATIVE}
          >
            Incorrect (I)
          </Button>
        </div>
      </div>
      {
        showingNextIntentPredictionId && (
          <>
            <p className="section-title">Rules</p>
            <div className="policy-items">
              {renderRules()}
            </div>
          </>
        )
      }
      {tempValue === BinaryValueValue.VALUE_NEGATIVE && (
        <>
          {nextIntentPredictionIds?.length > 0 && (
          <>
            <p className="section-title">Mark incorrect triggers?</p>
            <div className="policy-items">
              <PolicyDecisionListItem
                checkbox
                onClick={() => {
                  setIncorrectTriggeringIntents([]);
                  setAllTriggeringIntentsCorrect(!allTriggeringIntentsCorrect);
                }}
                type={allTriggeringIntentsCorrect ? 'positive' : 'default'}
              >
                All correct
              </PolicyDecisionListItem>
              {nextIntentPredictionIds.filter((predictionId) => {
                // Trigger object might have empty id values, that means there are no triggers
                const triggeringIntent = triggeringIntents[predictionId];
                return triggeringIntent?.conceptId;
              }).map((predictionId) => {
                const triggeringIntent = triggeringIntents[predictionId];
                if (!triggeringIntent) return null;
                const isIncorrect = incorrectTriggeringIntents?.length && incorrectTriggeringIntents.includes(predictionId);
                let itemType: PolicyDecisionListItemType = 'default';
                if (isIncorrect) {
                  itemType = 'negative';
                } else if (incorrectTriggeringIntents?.length > 0 && !isIncorrect) {
                  itemType = 'positive';
                }
                return (
                  <PolicyDecisionListItem
                    key={predictionId}
                    checkbox
                    onClick={() => {
                      setAllTriggeringIntentsCorrect(false);
                      if (incorrectTriggeringIntents.includes(predictionId)) {
                        setIncorrectTriggeringIntents(incorrectTriggeringIntents.filter((id) => id !== predictionId));
                      } else {
                        setIncorrectTriggeringIntents([...incorrectTriggeringIntents, predictionId]);
                      }
                    }}
                    type={itemType}
                  >
                    <ConceptTag
                      role={getConceptRole(triggeringIntent.conceptId)}
                      value={getConceptTitle(triggeringIntent.conceptId)}
                    />
                  </PolicyDecisionListItem>
                );
              })}
            </div>
          </>
          )}
          <p className="section-title">Incorrect reason</p>
          <Input.TextArea
            placeholder="Please explain why it's incorrect"
            autoFocus
            value={note}
            rows={3}
            style={{ flexShrink: 0 }}
            onChange={(e) => setNote(e.target.value)}
          />
          <br/>
          <p className="section-title">What should SDX be instead?</p>
          <Select
            onChange={(value) => setSelectedNextIntentConceptId(value)}
            placeholder="Select an intent"
            className="expected-intent-select"
            allowClear
            value={selectedNextIntentConceptId}
            showSearch
            filterOption={(input, option) => option.searchvalue.indexOf(input) !== -1}
          >
            {intents.map((concept) => {
              const conceptTitle = getConceptTitle(getId('concept', concept.name));
              return (
                <Select.Option
                  value={getId('concept', concept.name)}
                  key={concept.name}
                  searchvalue={conceptTitle}
                >
                  <ConceptTag
                    key={conceptTitle}
                    role={concept.intent.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : undefined}
                    value={conceptTitle}
                  />
                </Select.Option>
              );
            })}
          </Select>
          <br/>
          <Tooltip label="Proceed to next conversation" position="right">
            <div className="proceed-button">
              <Button
                variant="outline"
                color="indigo"
                disabled={!canSubmit}
                onClick={() => saveIncorrect()}
              >Next (N)
              </Button>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
}
