import React from 'react';
import './ChatWindow.scss';
import dayjs from 'dayjs';
import { Message } from 'types';
import { ChatWindowTopper } from 'components/ChatWindowTopper';
import { DATE_TIME_FORMAT } from 'studioConstants';
import MessagesContainer from './MessagesContainer';
import Loader from '../Loader';
import { SetManagerSection } from './SetManagerSection';

interface Props {
  highlight?: boolean;
  customerProfile?: string;
  chatId:string;
  loading:boolean;
  focusedMessageId?:string;
  handleMessageClicked?: (message: Message) => void;
  messages: Message[];
}

export const ChatWindow = (props: Props) => {
  const {
    customerProfile,
    chatId,
    highlight,
    loading,
    messages,
    focusedMessageId,
    handleMessageClicked,
  } = props;

  if (loading) {
    return <Loader />;
  }

  if (messages.length === 0) {
    return (
      <div className="no-chat-container">
        <h2>No chat selected</h2>
      </div>
    );
  }
  const conversationStartTime = dayjs(messages[0].pubDate).format(DATE_TIME_FORMAT);

  return (
    <div className="chat-panel">
      <ChatWindowTopper
        className="chat-panel-head"
        conversationName={`${customerProfile}/conversations/${chatId}`}
        actionComponent={(conversation) => (
          <SetManagerSection conversation={conversation} />
        )}
      />
      <div style={{ overflowY: 'auto' }}>
        <MessagesContainer
          conversationStartTime={conversationStartTime}
          messages={messages}
          selectedMessageIds={[]}
          focusedMessageId={focusedMessageId}
          handleMessageClicked={handleMessageClicked}
          highlight={highlight}
        />
      </div>
    </div>
  );
};
