import { PayloadAction } from '@reduxjs/toolkit';
import { State } from './state';

/** Reducer for sending notification is pending. */
export function sendNotificationPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    pending: true,
  };
}

/** Reducer for sending notification is failed. */
export function sendNotificationFailed(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    pending: false,
  };
}

/** Reducer for sending notification is completed. */
export function sendNotificationSuccess(state: State, action: PayloadAction<void>): State {
  return {
    ...state,
    pending: false,
  };
}
