import { Center, createStyles, Group, LoadingOverlay, Text, Paper, useMantineTheme, Tooltip } from '@mantine/core';
import React from 'react';
import { CaretDownFilled, CaretUpFilled, EyeInvisibleFilled, MessageFilled } from '@ant-design/icons';
import * as d3 from 'd3';
import { round } from 'lodash';
import Loading from 'components/Loading';
import { TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { RoleIcon } from 'components/RoleIcon';
import classNames from 'classnames';
import { FlowNodesTreeNode } from './utils';

export const NODE_ACTIVE_BORDER_WIDTH = 3;

// Min transparency (alpha) value for stages
const scaleAlpha = d3.scaleLinear([0, 1], [0.2, 1]);

interface TreeBlockProps {
  nodeDatum: FlowNodesTreeNode & TreeNodeDatum;
  foreignObjectProps: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  beginBlockHeight: number;
  blockHeight: number;
  blockWidth: number;
  onNodeExpand?: () => void;
  onNodePlusClick?: () => void;
  onNodeMinusClick?: () => void;
  onHideNodeClick?: () => void;
  onMessagesClick?: () => void;
  active?: boolean;
  loading?: boolean;
  showMinus?: boolean;
  showPlus?: boolean;
  isRootBlock?: boolean;
  rootElement?: React.ReactNode;
}

export function getTagIcon(tagType: TagTagType) {
  switch (tagType) {
    case TagTagType.AGENT_INTENT:
      return <RoleIcon conceptRole="agent"/>;
    case TagTagType.VISITOR_INTENT:
      return <RoleIcon conceptRole="visitor"/>;
    case TagTagType.DRIVER:
      return <RoleIcon conceptRole="driver"/>;
    case TagTagType.STAGE_BEGIN:
      return 'SB';
    case TagTagType.STAGE_END:
      return 'SE';
    default:
      return null;
  }
}

export function TreeBlock({
  nodeDatum,
  foreignObjectProps,
  beginBlockHeight,
  blockHeight,
  blockWidth,
  onNodeExpand,
  onNodePlusClick,
  onNodeMinusClick,
  onHideNodeClick,
  onMessagesClick,
  active,
  loading,
  showMinus,
  showPlus,
  isRootBlock,
  rootElement,
}: TreeBlockProps) {
  const percentage = nodeDatum.count / (nodeDatum.parent?.getChildrenTotalCount() || 1);
  const theme = useMantineTheme();
  const bcg = `rgba(${100 - (percentage * 100)}, 5, ${230 - (percentage * 100)}, ${scaleAlpha(percentage)})`;

  const nodeMessageCount = nodeDatum.flowNodeCollection.taskMessages?.length;

  const useStyles = createStyles({
    blockPaper: {
      maxWidth: blockWidth,
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: theme.radius.lg,
      border: active ? `${NODE_ACTIVE_BORDER_WIDTH}px solid ${theme.colors.orange[4]}` : `${NODE_ACTIVE_BORDER_WIDTH}px solid transparent`,
      '&:hover': {
        border: active ? `${NODE_ACTIVE_BORDER_WIDTH}px solid ${theme.colors.orange[4]}` : `${NODE_ACTIVE_BORDER_WIDTH}px solid ${theme.colors.orange[2]}`,
      },
    },
    block: {
      backgroundColor: bcg,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 12,
      height: blockHeight,
      width: '100%',
      padding: '0 12px',
      boxSizing: 'border-box',
    },
    blockBegin: {
      height: beginBlockHeight,
    },
    blockIcon: {
      fontSize: 17,
      color: `${theme.colors.blue[4]} !important`,
      '&:hover': {
        color: `${theme.colors.blue[9]} !important`,
      },
    },
    blockMessageCount: {
      background: 'white',
      marginLeft: -4,
      color: theme.colors.gray[4],
      padding: '3px 6px',
      borderRadius: theme.radius.md,
      flexShrink: 0,
      '&:hover': {
        color: theme.colors.gray[6],
      },
    },
    blockRangeIcons: {
      borderRadius: theme.radius.md,
      position: 'relative',
      top: -10,
      background: 'white',
      border: `${NODE_ACTIVE_BORDER_WIDTH}px solid ${theme.colors.orange[4]}`,
      padding: 5,
    },
    hidenNodeIcon: {
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
  });

  const styles = useStyles();

  const foreignObjectPropsMerged = {
    ...foreignObjectProps,
    height: foreignObjectProps.height + (NODE_ACTIVE_BORDER_WIDTH * 2),
    width: foreignObjectProps.width + (NODE_ACTIVE_BORDER_WIDTH * 2),
  };

  return (
    <g>
      <foreignObject {...foreignObjectPropsMerged}>
        <Paper
          className={styles.classes.blockPaper}
          onClick={() => onNodeExpand()}
        >
          {(isRootBlock && rootElement) ? (
            <div className={classNames(styles.classes.block, styles.classes.blockBegin)}>
              {rootElement}
            </div>
          ) : (
            <div className={styles.classes.block}>
              <Group spacing="xs" noWrap>
                <Group
                  spacing={3}
                  className={styles.classes.blockMessageCount}
                  onClick={(e) => {
                    e.stopPropagation();
                    onMessagesClick();
                  }}
                >
                  <MessageFilled/>
                  <Text weight={500}>{nodeMessageCount}</Text>
                  {getTagIcon(nodeDatum.flowNodeCollection.tagType)}
                </Group>
                <Tooltip label={`${nodeDatum.name} (${round(percentage * 100, 2)}%)`} withinPortal withArrow>
                  <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 140 }}>
                    {nodeDatum.name}
                  </Text>
                </Tooltip>
              </Group>
              {!isRootBlock && (
              <div className={styles.classes.hidenNodeIcon}>
                <EyeInvisibleFilled onClick={(e) => {
                  e.stopPropagation();
                  onHideNodeClick();
                }}
                />
              </div>
              )}
            </div>
          )}
        </Paper>
        {(showMinus || showPlus) && (
          <Center>
            <Group
              spacing="xs"
              className={styles.classes.blockRangeIcons}
            >
              {
                showMinus && (
                  <CaretDownFilled
                    onClick={(e) => {
                      e.stopPropagation();
                      onNodeMinusClick();
                    }}
                    className={styles.classes.blockIcon}
                  />
                )
              }
              {
                showPlus && (
                  <CaretUpFilled
                    onClick={(e) => {
                      e.stopPropagation();
                      onNodePlusClick();
                    }}
                    className={styles.classes.blockIcon}
                  />
                )
              }
            </Group>
          </Center>
        )}
        <LoadingOverlay visible={loading} loader={<Loading/>}/>
      </foreignObject>
    </g>
  );
}
