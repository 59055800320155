import React from 'react';
import { Group, Skeleton } from '@mantine/core';

export const MessageSkeletons = () => (
  <>
    <Group align="baseline" spacing="sm">
      <Skeleton height={25} circle mb="xl" />
      <Skeleton height={30} width={200} />
    </Group>
    <Group align="baseline" spacing="sm" style={{ flexDirection: 'row-reverse' }}>
      <Skeleton height={25} circle mb="xl" />
      <Skeleton height={100} width={300} />

    </Group>
    <Group align="baseline" spacing="sm">
      <Skeleton height={25} circle mb="xl" />
      <Skeleton height={30} width={120} />

    </Group>
    <Group align="baseline" spacing="sm">
      <Skeleton height={25} circle mb="xl" />
      <Skeleton height={30} width={240} />
    </Group>
    <Group align="baseline" spacing="sm" style={{ flexDirection: 'row-reverse' }}>
      <Skeleton height={25} circle mb="xl" />
      <Skeleton height={80} width={300} />

    </Group>
    <Group align="baseline" spacing="sm">
      <Skeleton height={25} circle mb="xl" />
      <Skeleton height={240} width={300} />
    </Group>
  </>
);
