import { Button, Group, MantineProvider, Modal, Space } from '@mantine/core';
import { mantineTheme } from 'assets/styles/mantine/mantineTheme';
import React, { ComponentProps, useEffect, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

export interface ConfirmModalButton<T> {
  buttonProps?: ComponentProps<typeof Button>;
  text: string;
  value: T;
}

type ConfirmModalProps<T> = {
  children?: React.ReactNode | string | number;
  title: React.ReactNode | string,
  buttons: ConfirmModalButton<T>[];
  width?: number;
  resolveAndUnmount: (value: T) => void
};

// TODO: Refactor this component using portals if possible
function ConfirmModal<T>({ children, title, buttons, resolveAndUnmount, width = 600 }: ConfirmModalProps<T>) {
  const [modalVisible, setModalVisible] = useState(false);
  const close = (value: T) => {
    setModalVisible(false);
    resolveAndUnmount(value);
  };

  useEffect(() => {
    // Workaround to show fade in animation
    setTimeout(() => {
      setModalVisible(true);
    }, 0);
  }, []);

  return (
    <MantineProvider theme={mantineTheme}>
      <div>
        <Modal
          title={title}
          size={width}
          opened={modalVisible}
          onClose={() => {}}
          withCloseButton={false}
        >
          <div>
            {children}
          </div>
          <Space h="lg"/>
          <Group position="right">
            {buttons.map((modalButton, i) => (
              <Button
                {...modalButton.buttonProps}
                key={modalButton.text}
                onClick={() => close(modalButton.value)}
              >{modalButton.text}
              </Button>
            ))}
          </Group>
        </Modal>
      </div>
    </MantineProvider>
  );
}

interface CreateConfirmProps<T> {
  buttons: ConfirmModalButton<T>[];
  content: React.ReactNode | string | number;
  title: string;
  width?: number;
}

export function createConfirm<T>({ buttons, title, content: children, width }: CreateConfirmProps<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const resolveAndUnmount = (value: T) => {
      // Wait for modal close animation
      setTimeout(() => {
        resolve(value);
        const target = document.getElementById('confirm-modal');
        if (target) {
          unmountComponentAtNode(target);
          target.parentNode.removeChild(target);
        }
      }, 300);
    };
    const confirmModal = (
      <ConfirmModal
        title={title}
        buttons={buttons}
        resolveAndUnmount={resolveAndUnmount}
        width={width}
      >{children}
      </ConfirmModal>
    );
    let divTarget = document.getElementById('confirm-modal');
    if (divTarget) {
      // Rerender - the mounted confirm modal
      render(confirmModal, divTarget);
    } else {
      // Mount the confirm modal component
      divTarget = document.createElement('div');
      divTarget.id = 'confirm-modal';
      document.body.appendChild(divTarget);
      render(confirmModal, divTarget);
    }
  });
}
