import React, { useEffect, useState } from 'react';
import { Loader, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { selectSets } from 'store/set/selectors';
import { useSelector } from 'hooks/reduxHooks';

interface SetTitleProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const SetTitleInput = ({ value, onChange, disabled }: SetTitleProps) => {
  const [isError, toggleError] = useState(false);
  const [isCheckingSetNames, toggleCheckingSetNames] = useState(false);
  const [debouncedSetTitle] = useDebouncedValue(value, 500);
  const existingSets = useSelector(selectSets);

  useEffect(() => {
    if (!value) {
      toggleCheckingSetNames(false);
    } else {
      toggleError(false);
      toggleCheckingSetNames(true);
    }
  }, [value]);

  useEffect(() => {
    if (!value) return;
    const allSetTitles = existingSets.map((set) => set.setTitle);
    if (allSetTitles.includes(debouncedSetTitle)) {
      toggleError(true);
    } else {
      toggleError(false);
    }
    toggleCheckingSetNames(false);
  }, [debouncedSetTitle]);

  return (
    <TextInput error={isError && 'Set Title exists'} rightSection={isCheckingSetNames && <Loader size="xs" />} disabled={disabled} label="Set Title" value={value} onChange={onChange} />
  );
};
