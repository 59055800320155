import React, { useState, useEffect } from 'react';
import { LabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { getId } from 'common/resourceName';
import { useSelector } from 'hooks/reduxHooks';
import { User } from 'types';
import { selectUsers } from 'store/user/selectors';
import { GetSetRequest, GetSetResponse } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { SetApi } from 'services/setApi';
import { useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import { TaskMetadata as TaskMetadataComponent } from './TaskMetadata';

interface TaskMetadataProps {
    labelingTask: LabelingTask
}

export interface SetData {
    id: string
    createTime: string
    title: string
    convoQuantity: number
}

export const TaskMetadata = ({ labelingTask }: TaskMetadataProps) => {
  const usecase = useCustomerUsecase();
  const { languageCode } = useCustomerParams();

  const users = useSelector(selectUsers).map((user) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  // Populate existing sets
  const [sets, setSets] = useState<SetData[]>([]);
  useEffect(() => {
    if (!labelingTask) return;
    const { taskData: { taskDescriptor: { selectionInstruction } } } = labelingTask;
    const { conversationSampleInfos } = selectionInstruction?.conversationSetsInstruction;
    Promise.all(conversationSampleInfos.map((set) => {
      const getSetRequest: GetSetRequest = {
        name: set.conversationSetName,
        usecase,
        languageCode,
      };

      return SetApi.getSet(getSetRequest).then(({ set }: GetSetResponse) => set);
    })).then((sets) => {
      const populatedSets = sets.map((set) => ({
        createTime: set.createTime,
        id: set.name,
        title: set.setTitle,
        convoQuantity: set.setItemNames.length,
      }));
      setSets(populatedSets);
    });
  }, [labelingTask]);

  if (!labelingTask) return null;

  const { abstractTask: { endTime, assigneeUserId, name } } = labelingTask;
  const taskId = getId('abstractTask', name);
  const author = users.find((u) => u.id === assigneeUserId)?.full_name;

  const totalConversations = sets.reduce((acc, set) => acc + set.convoQuantity, 0);

  return (
    <TaskMetadataComponent
      taskId={taskId}
      author={author}
      endTime={endTime}
      totalConversations={totalConversations}
      sets={sets}
    />
  );
};
