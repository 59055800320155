import React, { useCallback, useState, useContext } from 'react';
import { Group, Modal, Radio, Select, Stack, TextInput, Button } from '@mantine/core';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { UserContext } from 'context/UserContext';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { createModelProject } from 'store/modelProject/asyncThunks';
import { ModelProject, ModelProjectProjectType, ModelProjectStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { useNavigate } from 'react-router-dom';
import { selectModelProjectStatus } from 'store/modelProject/selectors';
import { ApiStatus } from 'store/types';
import { getId } from 'common/resourceName';

interface NewProjectModalProps {
  opened: boolean;
  onCancel: () => void;
}

enum ConceptType {
  INTENTS = 'INTENTS',
  SMART_COMPOSE = 'SMART_COMPOSE',
  SUGGESTIONS = 'SUGGESTIONS',
}

export function NewProjectModal({
  opened,
  onCancel,
}: NewProjectModalProps) {
  const [projectType, setProjectType] = useState<ModelProjectProjectType>(ModelProjectProjectType.AGENT_INTENT);
  const [ProjectDisplayName, setProjectDisplayName] = useState<string>('');
  const [conceptType, setConceptType] = useState(ConceptType.INTENTS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const currentUser = useContext(UserContext);
  const status = useSelector<ApiStatus>(selectModelProjectStatus);

  const handleSaveProject = useCallback(() => {
    const modelProject: ModelProject = {
      displayName: ProjectDisplayName,
      status: ModelProjectStatus.IN_PROGRESS,
      creator: currentUser?.name,
      projectType,
      usecase,
      languageCode: customer?.languageCode,
    };
    dispatch(createModelProject({
      parent: customerProfile,
      modelProject,
    })).then((res) => {
      const payload = res.payload as {
        name: string
      };
      navigate(`${getId('modelProject', payload.name || '')}`, {
        state: {
          intentType: projectType,
          projectName: ProjectDisplayName,
        },
      });
      onCancel();
    });
  }, [projectType, ProjectDisplayName, customer?.path, dispatch, navigate, onCancel]);

  const getConceptTypeSpecificFields = (conceptType: ConceptType) => {
    switch (conceptType) {
      case ConceptType.INTENTS:
        return (
          <Radio.Group
            label="Model Type"
            required
            onChange={(value: ModelProjectProjectType) => { setProjectType(value); }}
            value={projectType}
          >
            <Group>
              <Radio value={ModelProjectProjectType.AGENT_INTENT} label="Agent" />
              <Radio value={ModelProjectProjectType.VISITOR_INTENT} label="Visitor" />
              <Radio value={ModelProjectProjectType.CONVERSATION_DRIVER} label="Driver" />
            </Group>
          </Radio.Group>
        );
      case ConceptType.SUGGESTIONS:
        return (
          <Radio.Group
            label="Model Type"
            required
            onChange={(value: ModelProjectProjectType) => { setProjectType(value); }}
            value={projectType}
          >
            <Group>
              <Radio value={ModelProjectProjectType.SUGGESTION_GENERATIVE} label="Generative" />
              <Radio value={ModelProjectProjectType.SUGGESTION_RETRIEVAL} label="Retrieval" />
              <Radio value={ModelProjectProjectType.SUGGESTION_ENSEMBLE} label="Ensemble" />
            </Group>
          </Radio.Group>
        );
      case ConceptType.SMART_COMPOSE:
        return null;
      default:
        return null;
    }
  };

  // updates model type when concept type changes
  const handleConceptChange = (conceptType: ConceptType) => {
    if (conceptType === ConceptType.INTENTS) {
      setProjectType(ModelProjectProjectType.AGENT_INTENT);
    }
    if (conceptType === ConceptType.SUGGESTIONS) {
      setProjectType(ModelProjectProjectType.SUGGESTION_GENERATIVE);
    }
    if (conceptType === ConceptType.SMART_COMPOSE) {
      setProjectType(ModelProjectProjectType.SMART_COMPOSE);
    }
    setConceptType(conceptType as ConceptType);
  };

  return (
    <Modal title="New project" opened={opened} onClose={onCancel} size="sm" withCloseButton={false}>
      <Stack>
        <Select
          label="Concept"
          value={conceptType}
          onChange={(value) => handleConceptChange(value as ConceptType)}
          data={[
            { label: 'Intents', value: ConceptType.INTENTS },
            { label: 'Smart componse', value: ConceptType.SMART_COMPOSE },
            { label: 'Suggestions', value: ConceptType.SUGGESTIONS },
          ]}
        />
        {getConceptTypeSpecificFields(conceptType)}
        <TextInput label="Project Name" onChange={(evt) => setProjectDisplayName(evt.currentTarget.value)} />
        <Group position="right">
          <Button variant="subtle" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={!ProjectDisplayName} className={ProjectDisplayName} title="create" loading={status === 'loading'} onClick={handleSaveProject}>Create</Button>
        </Group>
      </Stack>
    </Modal>
  );
}
