/* eslint dot-notation: 0 */
import React, { ReactElement } from 'react';
import { Alert, Descriptions, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Button } from '@mantine/core';
import { getId } from 'common/resourceName';
import { DatasetCount } from '@cresta/web-client/dist/cresta/v1/studio/storage/modelbuilder/modelbuilder.pb';
import {
  ModelArtifact,
  ModelArtifactArtifactType as ArtifactType,
  ModelArtifactStatus,
  MessagePrediction,
  ModelType,
} from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { ConfusionMatrix } from './ConfusionMatrix';
import { PredictionMatrix, LabelScore, PredictionRecord } from './PredictionMatrix';
import { ModelScoresTable } from './ModelScoresTable';
import { RegressionCountTable } from './RegressionCountTable';
import { RegressionTaxonomyTable } from './RegressionTaxonomyTable';
import { RegressionTaskTable } from './RegressionTaskTable';
import { formatDate } from './utils';

interface Props {
  modelArtifact: ModelArtifact
}

export function ModelingTask({
  modelArtifact,
}: Props): ReactElement {
  const {
    name,
    completeTime,
    creator,
    status,
    errMsg,
    artifactType,
  } = modelArtifact || {};
  const taskId = getId('modelArtifact', name);
  const completeAt = moment(completeTime).format('YYYY-MM-DD h:mma');

  if (status === ModelArtifactStatus.FAILED) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span>Task {getId('modelArtifact', name)} failed!</span>
        <span>Error: {errMsg}</span>
      </div>
    );
  }

  if (!(status === ModelArtifactStatus.ACTIVE)) {
    return (
      <div>
        Task {taskId} has not completed yet! Status: {status}.
      </div>
    );
  }

  if (artifactType === ArtifactType.DATASET) {
    const { inputs, outputs } = modelArtifact?.datasetArtifact || {};
    if (!outputs) {
      throw Error('We shouldn\'t expect an empty artifacts for any completed tasks.');
    }
    if (!outputs.testDataset) {
      throw Error('We shouldn\'t expect a missing test datasets in artifacts for any completed tasks.');
    }
    interface DatasetCountRow {
      intent: string;
      train: DatasetCount;
      test: DatasetCount;
    }
    // Cannot use intents set in the project taxonomy
    // as these be changed without updating dataset or model
    // For now we use testsset_counts to infer intent name spaces.
    const trainsetUrl = outputs.trainDataset?.uri || '';
    const testsetUrl = outputs.testDataset?.uri || '';
    const intents = Object.keys(outputs?.testDataset?.counts || {});
    const trainCounts = outputs.trainDataset?.counts;
    const testCounts = outputs.testDataset?.counts;
    const counts: DatasetCountRow[] = [];
    intents.forEach((intent) => {
      counts.push({ intent, train: trainCounts[intent], test: testCounts[intent] });
    });
    if (counts.length === 0) {
      return (
        <div>
          Task {taskId} does not have stats. Likely due to timerange no data or a bug.
        </div>
      );
    }
    const width = 45;
    const align = 'center';
    const testColumns: ColumnsType<DatasetCountRow> = [
      { title: 'positive', dataIndex: 'test', render: (v) => v?.positive || '-', width, align },
      { title: 'negative', dataIndex: 'test', render: (v) => v?.negative || '-', width, align },
    ];
    const datasetCountsColumns: ColumnsType<DatasetCountRow> = [
      { title: '', dataIndex: 'intent', width },
      {
        title: 'train',
        children: [
          { title: 'positive', dataIndex: 'train', render: (v) => v?.positive || '-', width, align },
          { title: 'negative', dataIndex: 'train', render: (v) => v?.negative || '-', width, align },
        ],
      },
      { title: 'validation', children: testColumns },
      { title: 'test', children: testColumns },
    ];
    return (
      <div>
        <Descriptions title="" bordered>
          <Descriptions.Item label="Trainset url">{trainsetUrl || 'No training dataset.'}</Descriptions.Item>
          <Descriptions.Item label="Testset url">{testsetUrl || 'No test dataset.'}</Descriptions.Item>
          <Descriptions.Item label="Task type">{inputs?.type?.toLowerCase() || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Completed at">{completeAt || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Intents">{inputs.kwargs['actions'].join('\t') || 'Unknown.'}</Descriptions.Item>
        </Descriptions>
        <Table columns={datasetCountsColumns} dataSource={counts} size="small" />
      </div>
    );
  }

  if (artifactType === ArtifactType.SUGGESTIONS_DATASET) {
    const { inputs, outputs } = modelArtifact?.suggestionsDatasetArtifact || {};
    interface Row {
      type: string;
      train: number;
      val: number;
      test: number;
    }
    const columns: ColumnsType<Row> = [
      { title: 'Type', dataIndex: 'type', sorter: (a, b) => a.type.localeCompare(b.type), defaultSortOrder: 'ascend' },
      { title: '#convs training set', dataIndex: 'train' },
      { title: '#convs validation set', dataIndex: 'val' },
      { title: '#convs test set', dataIndex: 'test' },
    ];
    const convCnts = outputs?.convCnts || {};
    const rows: Row[] = [
      {
        type: 'All conversations',
        train: convCnts['train_all'] || 0,
        val: convCnts['val_all'] || 0,
        test: convCnts['test_all'] || 0,
      },
      {
        type: 'Expert conversations',
        train: convCnts['train_expert'] || 0,
        val: convCnts['val_expert'] || 0,
        test: convCnts['test_expert'] || 0,
      },
    ];

    return (
      <>
        <Descriptions title="" bordered>
          <Descriptions.Item label="Start date">{formatDate(inputs?.startTime) || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="End date">{formatDate(inputs?.endTime) || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Test set days">{inputs?.testSetDaysSuffix || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Training set ratio">{inputs?.splitRatioTrain || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Extract experts">{inputs?.addExperts ? 'True' : 'False'}</Descriptions.Item>
          <Descriptions.Item label="Dataset">{outputs?.datasetPath || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="WandB">
            {outputs?.wandbUri ? (<Button variant="subtle" component="a" href={outputs?.wandbUri}>Link</Button>) : ''}
          </Descriptions.Item>
        </Descriptions>
        <Table title={() => 'Datasets'} columns={columns} dataSource={rows} size="middle" />;
      </>
    );
  }

  if (artifactType === ArtifactType.NEURAL_CLASSIFIER) {
    const { inputs, outputs } = modelArtifact?.neuralClassifierArtifact || {};
    return (
      <div>
        <Descriptions title="" bordered>
          <Descriptions.Item label="CrestaModel url">{outputs?.modelUri || 'No trained model.'}</Descriptions.Item>
          <Descriptions.Item label="Trainset url">{inputs?.trainDatasets[0]?.uri || 'No training dataset.'}</Descriptions.Item>
          <Descriptions.Item label="Testset url">{inputs.testDatasets[0]?.uri || 'No test dataset.'}</Descriptions.Item>
          <Descriptions.Item label="Model Action Space">{outputs?.taxonomy?.intents?.map((i) => i.intentDisplayName || '')?.join('\t') || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Completed at">{completeAt || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown.'}</Descriptions.Item>
        </Descriptions>
        <ModelScoresTable scores={outputs?.modelScores} />
      </div>
    );
  }

  const messagePredictionConvertor = (pred: MessagePrediction, index: number): PredictionRecord => {
    const predLabels = pred.predictions && pred.predictions.length > 0 ? pred.predictions : [];
    let predScores = pred.predictionScores || [];
    if (predScores.length !== predLabels.length) {
      predScores = Array(predLabels.length).fill(-1);
    }
    const preds: LabelScore[] = predLabels.map((p, i) => ({ label: p, score: predScores[i] }));
    return {
      index,
      message: pred.message,
      chat_id: pred.chatId || '',
      predLabels: preds,
      maxScore: predScores.reduce((p, c) => (c > 0 && c > p ? c : p), -1),
    };
  };

  if (artifactType === ArtifactType.REGEX_CLASSIFIER) {
    const { outputs } = modelArtifact?.regexClassifierArtifact || {};
    const messagePredictions: PredictionRecord[] = outputs?.messagePredictions?.messagePredictions.map(messagePredictionConvertor) || [];
    const testPredictions = outputs?.datasetPredictions?.testPredictions || [];
    const trainPredictions = outputs?.datasetPredictions?.trainPredictions || [];
    return (
      <div>
        <Descriptions title="" bordered>
          <Descriptions.Item label="CrestaModel url">{outputs?.modelUri || 'No model url found.'}</Descriptions.Item>
          <Descriptions.Item label="Completed at">{completeAt || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Model Action Space">{outputs?.taxonomy?.intents?.map((i) => i.intentDisplayName).join('---') || 'Unknown.'}</Descriptions.Item>
        </Descriptions>
        {messagePredictions.length > 0 ? <PredictionMatrix predictions={messagePredictions} /> : <div>No chat level evaluation results.</div>}
        {outputs?.modelScores ? <ModelScoresTable scores={outputs.modelScores} /> : <div>No model scores found. </div>}
        {testPredictions.length > 0
          ? (
            <ConfusionMatrix
              testPredictions={testPredictions}
              trainPredictions={trainPredictions}
            />
          )
          : <div> No database predictions</div>}
      </div>
    );
  }

  if (artifactType === ArtifactType.STACKED_CLASSIFIER) {
    const { inputs, outputs } = modelArtifact?.stackedClassifierArtifact || {};
    return (
      <div>
        <Descriptions title="" bordered>
          <Descriptions.Item label="CrestaModel url">{outputs?.modelUri || 'No model url found.'}</Descriptions.Item>
          <Descriptions.Item label="Completed at">{completeAt || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Base classifiers">{inputs?.modelUris?.join('\n') || 'Unknown.'}</Descriptions.Item>
          <Descriptions.Item label="Model Action Space">{outputs?.taxonomy?.intents?.map((i) => i.intentDisplayName).join('---') || 'Unknown.'}</Descriptions.Item>

        </Descriptions>
        {outputs?.modelScores ? <ModelScoresTable scores={outputs.modelScores} /> : <div> No model scores found. </div>}
      </div>
    );
  }

  if (artifactType === ArtifactType.SUGGESTIONS_MODEL) {
    const { inputs, outputs } = modelArtifact?.suggestionsModelArtifact || {};
    interface Row {
      metric: string;
      score: number;
    }
    const columns: ColumnsType<Row> = [
      { title: 'Metric (with `-k` suffix for top-k)', dataIndex: 'metric', sorter: (a, b) => a.metric.localeCompare(b.metric), defaultSortOrder: 'ascend' },
      { title: 'Score', dataIndex: 'score' },
    ];
    const rows: Row[] = Object.entries(outputs?.testSetScores).map(
      ([metric, score]) => ({ metric, score }),
    );

    return (
      <>
        <Descriptions title="" bordered>
          <Descriptions.Item label="Model type">{inputs?.modelType || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Dataset">{inputs?.suggestionsDataset || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Experts fine-tuning requested">{inputs?.expertsShouldFinetune ? 'True' : 'False'}</Descriptions.Item>
          <Descriptions.Item label="Experts fine-tuning done">{inputs?.expertsWillFinetune ? 'True' : 'False'}</Descriptions.Item>
          <Descriptions.Item label="Servable model path">{outputs?.modelUri || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="WandB">
            {outputs?.wandbUri ? (<Button component="a" variant="subtle" href={outputs?.wandbUri}>Link</Button>) : ''}
          </Descriptions.Item>
        </Descriptions>
        <Table title={() => 'Test set evaluation'} columns={columns} dataSource={rows} size="middle" />;
      </>
    );
  }

  if (artifactType === ArtifactType.TAXONOMY) {
    const { outputs } = modelArtifact?.taxonomyArtifact || {};
    return (
      <div>
        <h3>For now, please go to Labels tab to find the numbers of labels for each intent. TODO: design and add sumary views here.</h3>
        <Descriptions title="" bordered>
          <Descriptions.Item label="Intents">{outputs?.taxonomy?.intents?.map((i) => `${i.intentTaskType?.toLowerCase()}:${i.intentDisplayName}`).join(',') || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown.'}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  if (artifactType === ArtifactType.REGRESSION) {
    const { inputs, outputs } = modelArtifact?.regressionArtifact || {};
    const modelType = inputs?.modelType;
    const alert = `
      The regression test will turn up a local Orchestrator and one type of AI server for the new
      model. Thus, the new predictions will only contain one type of moment as well as the actions
      induced from this moment type. In contrast, the old predictions contain prod predictions with
      all moment and action types that were active for the customer.

      For example, when you test an intent model it is expected that the old predictions can contain
      ENTITY moments that will not occur in the new predictions. You can either ignore these
      moments/actions or use the filter on the old predictions column to turn them invisible.
    `;
    return (
      <>
        <Alert message={alert} type="warning" showIcon />
        <Descriptions title="" bordered>
          {modelType === ModelType.STACKED_CLASSIFIER
            ? (
              <>
                <Descriptions.Item label="Agent model url">{inputs?.stackedAgentModelUri || 'Unknown'}</Descriptions.Item>
                <Descriptions.Item label="Visitor model url">{inputs?.stackedVisitorModelUri || 'Unknown'}</Descriptions.Item>
              </>
            )
            : <Descriptions.Item label="Model url">{inputs?.modelUri || 'Unknown'}</Descriptions.Item>}
          <Descriptions.Item label="Model type">{modelType.toLowerCase() || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Rule policy URL">{inputs?.rulePolicyUri || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Actions image tag">{inputs?.actionsImageTag || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Chat AI image tag">{inputs?.chatAiImageTag || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Orchestrator image tag">{inputs?.orchestratorImageTag || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Intent image tag">{inputs?.intentImageTag || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Policy image tag">{inputs?.policyImageTag || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Completed at">{completeAt || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown'}</Descriptions.Item>
        </Descriptions>
        <RegressionCountTable diff={outputs?.predictionsDiff} />
        <RegressionTaxonomyTable diff={outputs?.predictionsDiff} />
        <RegressionTaskTable diff={outputs?.predictionsDiff} />
      </>
    );
  }

  if (artifactType === ArtifactType.DEPLOYMENT) {
    return (
      <></>
    );
  }
  if (artifactType === ArtifactType.EVALUATION) {
    const { inputs, outputs } = modelArtifact?.evaluationArtifact || {};
    const messagePredictions: PredictionRecord[] = outputs?.messagePredictions?.messagePredictions.map(messagePredictionConvertor) || [];
    const testPredictions = outputs?.datasetPredictions?.testPredictions || [];
    const trainPredictions = outputs?.datasetPredictions?.trainPredictions || [];
    return (
      <>
        <Descriptions title="" bordered>
          <Descriptions.Item label="Model url">{inputs?.modelUri || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Train dataset url">{inputs?.trainDatasets?.map((dataset) => (dataset?.uri)) || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Test dataset url">{inputs?.testDatasets?.map((dataset) => (dataset?.uri)) || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Completed at">{completeAt || 'Unknown'}</Descriptions.Item>
          <Descriptions.Item label="Created by">{creator || 'Unknown'}</Descriptions.Item>
        </Descriptions>
        {messagePredictions.length > 0
          ? (
            <PredictionMatrix
              predictions={messagePredictions}
            />
          )
          : <div> No chat level evaluation results.</div>}
        {outputs?.modelScores ? <ModelScoresTable scores={outputs?.modelScores} /> : <div> No model scores found. </div>}
        {testPredictions.length > 0
          ? (
            <ConfusionMatrix
              testPredictions={testPredictions}
              trainPredictions={trainPredictions}
            />
          )
          : <div> No database predictions</div>}
      </>
    );
  }
  return (
    <div>
      {JSON.stringify(modelArtifact)}
    </div>
  );
}
