import React, { useEffect } from 'react';
import { LabelingTask, SelectionInstruction } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { showNotification } from '@mantine/notifications';
import { QAComparisonTable } from './QAComparisonTable';
import { QAScoreTable } from './QAScoreTable';
import { useQaTableData } from './useQaTableData';

interface Props {
    selectionInstruction: SelectionInstruction;
    qaTask: LabelingTask;
}

// depending on config of current workflow, show different tables
export const QATable = ({ selectionInstruction, qaTask }: Props) => {
  const [data, isLoading, error] = useQaTableData(qaTask);
  useEffect(() => {
    if (error) {
      console.error(error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: error,
      });
    }
  }, [error]);
  if (selectionInstruction?.suggestionQaRandom?.modelUri) {
    return (
      <QAComparisonTable dataSource={[{
        ...data.comparisonModelSummary,
        model2Uri: selectionInstruction.suggestionQaRandom.modelUri,
      }]}
      />
    );
  }

  return <QAScoreTable dataSource={[data.singleModelSummary]} isLoading={isLoading} />;
};
