import React, { useEffect, useRef } from 'react';

import { drawRuleGraph } from './drawRuleGraph';
import { Graph } from './types';
import styles from './styles.module.css';

interface IProps {
  data: Graph;
}

export default function RuleGraph({ data }: IProps) {
  const containerRef = useRef<HTMLDivElement>();
  useEffect(() => drawRuleGraph(containerRef.current, data), [data]);
  return <div className={styles.container} ref={(ref) => { containerRef.current = ref; }} />;
}
