import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ActionIcon, Button, ButtonProps, Popover, PopoverProps, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { createConfirm } from 'components/ConfirmModal';
import { useClickOutside } from '@mantine/hooks';
import { getId } from 'common/resourceName';
import { CardListItem } from './CardListItem';
import { PlusIcon, SearchIcon, SetIcon } from './icons';

interface SetManagementDropdownProps {
  isLoading?: boolean;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>
  onCreate?: () => void;
  onDelete?: (value: string) => void;
  selectedValues: string[];
  onSelect: (value: string) => void;
  cardData: any[];
  creatable?: boolean;
  popoverProps?: Omit<PopoverProps, 'children'>;
  buttonProps?: Omit<ButtonProps, 'children'>;
  iconOnly?: boolean;
}

export const SetManagementDropdown = ({
  isLoading,
  search,
  setSearch,
  onCreate,
  onDelete,
  selectedValues,
  onSelect,
  cardData,
  creatable = false,
  popoverProps,
  buttonProps,
  iconOnly = false,
}: SetManagementDropdownProps) => {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));

  // clear search when popover is closed
  useEffect(() => {
    if (!opened) {
      setSearch('');
    }
  }, [opened, setSearch]);

  const handleCreateSet = () => {
    createConfirm<boolean>({
      title: `New set: ${search}`,
      content: <Text>This will create a new set.</Text>,
      buttons: [
        {
          text: 'Create',
          value: true,
        },
        {
          text: 'Cancel',
          value: false,
          buttonProps: {
            variant: 'outline',
            color: 'gray',
          },
        },
      ],
    }).then((confirmed) => {
      if (!confirmed) return;
      onCreate();
    });
  };

  const handleDeleteSet = (value) => {
    createConfirm<boolean>({
      title: 'Are you sure?',
      content: <Text>This will permanently delete the set.</Text>,
      buttons: [
        {
          text: 'Confirm Delete',
          value: true,
          buttonProps: {
            color: 'red',
          },
        },
        {
          text: 'Cancel',
          value: false,
          buttonProps: {
            variant: 'outline',
            color: 'gray',
          },
        },
      ],
    }).then((confirmed) => {
      if (!confirmed) return;
      onDelete(value);
    });
  };

  const sortedCardList = cardData
    .sort((a, b) => {
    // sorted alphabetically by label
      const aLabel = a.label.toLowerCase();
      const bLabel = b.label.toLowerCase();
      if (aLabel < bLabel) return -1;
      if (aLabel > bLabel) return 1;
      return 0;
    })
    .sort((a, b) => {
      // all selected values should be at the top
      if (selectedValues.includes(a.value) && !selectedValues.includes(b.value)) return -1;
      if (!selectedValues.includes(a.value) && selectedValues.includes(b.value)) return 1;
      return 0;
    })
    .sort((a, b) => {
      // set with value including 'golden_set' should be at the top
      // hack to handle both name and id cases
      if (a.value.includes('golden_set') && !b.value.includes('golden_set')) return -1;
      if (!a.value.includes('golden_set') && b.value.includes('golden_set')) return 1;
      return 0;
    });

  return (
    <div>
      <Popover
        trapFocus
        shadow="md"
        opened={opened}
        styles={() => ({
          dropdown: {
            width: 260,
          },
        })}
        zIndex={100}
        {...popoverProps}
      >
        <Popover.Target>
          {iconOnly ? (
            <ActionIcon
              loading={isLoading}
              variant={opened ? 'light' : 'subtle'}
              ml="xs"
              onClick={() => {
                setOpened((prev) => !prev);
              }}
            >
              <SetIcon />
            </ActionIcon>
          ) : (
            <Button
              loading={isLoading}
              variant={opened ? 'light' : 'white'}
              compact
              leftIcon={<SetIcon />}
              onClick={() => {
                setOpened((prev) => !prev);
              }}
              {...buttonProps}
            >Select set
            </Button>
          )}
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <div ref={ref}>
            <TextInput
              autoComplete="off"
              icon={<SearchIcon />}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              mt="xs"
              mx="xs"
            />
            {creatable && (
            <Button
              styles={(theme) => ({
                root: {
                  width: `calc(100% - ${theme.spacing.xs}px * 2)`,
                },
                inner: {
                  justifyContent: 'flex-start',
                },
              })}
              mt="xs"
              mx="xs"
              variant="light"
              compact
              disabled={!search}
              leftIcon={<PlusIcon />}
              onClick={handleCreateSet}
            >{search ? `Save into new set "${search}"` : 'Start typing to create a new set'}
            </Button>
            )}
            {cardData.length === 0 && (
            <Text
              color="dimmed"
              size="sm"
              mt="xs"
              mx="xs"
            >{`No match found for "${search}"`}
            </Text>
            )}
            <ScrollArea style={{ height: 300 }} type="auto" scrollbarSize={6} mt="xs">
              <Stack spacing="xs" px="xs" mb="xs">
                {sortedCardList.map((item) => {
                  const id = getId('set', item.value);
                  return (
                    <CardListItem
                      key={item.value}
                      onClick={() => {
                        if (item.disabled) return;
                        onSelect(item.value);
                      }}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleDeleteSet(item.value);
                      }}
                      noIcon={id === 'golden_set' || !onDelete}
                      selected={selectedValues.includes(item.value)}
                      {...item}
                    />
                  );
                })}
              </Stack>
            </ScrollArea>
          </div>
        </Popover.Dropdown>
      </Popover>

    </div>
  );
};
