import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import Annotations, { AnnotationsDisplayType, AnnotationValueType } from 'components/Annotations';
import ReactHtmlParser from 'react-html-parser';

interface NoContextDisplayProps {
  message: Message,
  annotations: Annotation[],
  error: string,
  getConceptTitle: (conceptId: string) => string;
  annotationsDisplayType: AnnotationsDisplayType;
  annotationValueType?: AnnotationValueType;
}

/** Shows single message displayed as block of text */
export default function NoContextDisplay({ message, annotations, error, getConceptTitle, annotationsDisplayType, annotationValueType }: NoContextDisplayProps) {
  return (
    <div className={classNames('no-context-display', { error })} key={message?.messageContent}>
      {ReactHtmlParser(message?.messageContent)}
      <br/>
      {annotations?.length > 0 && (
        <div>
          {error && (
          <Tooltip title={error} color="red">
            <WarningOutlined className="message-error"/>
          </Tooltip>
          )}
          <Annotations annotationValueType={annotationValueType} annotations={annotations} annotationsDisplayType={annotationsDisplayType} getConceptTitle={getConceptTitle}/>
        </div>
      )}
    </div>
  );
}
