import {
  ConceptService,
  GetConceptRequest,
  GetConceptResponse,
  ListConceptsRequest,
  ListConceptsResponse,
  CreateConceptRequest,
  CreateConceptResponse,
  UpdateConceptRequest,
  UpdateConceptResponse,
  StartAutoAnalysisResponse,
  StartAutoAnalysisRequest,
  FetchAllESVersionsRequest,
  FetchAllESVersionsResponse,
  ExportConceptToCXRequest,
  ExportConceptToCXResponse,
  BatchUpsertSharedConceptConfigsRequest,
  BatchUpsertSharedConceptConfigsResponse,
  FetchSharedConceptsRequest,
  FetchSharedConceptsResponse,
  SyncLibraryConceptsRequest,
  SyncLibraryConceptsResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { CHAT_SANDBOX_CONFIG, SHARED_LIBRARY_CONFIG } from 'context/CustomerConfigContext';
import { StudioApi } from './studioApi';

export abstract class ConceptApi {
  public static listConcepts(request: ListConceptsRequest, source?: 'demo'|'shared'): Promise<ListConceptsResponse> {
    let customerConfig;
    if (source === 'demo') {
      customerConfig = CHAT_SANDBOX_CONFIG;
    } else if (source === 'shared') {
      customerConfig = SHARED_LIBRARY_CONFIG;
    }
    return ConceptService.ListConcepts(request, StudioApi.getHeaders(customerConfig).initReq);
  }

  public static getConcept(request: GetConceptRequest): Promise<GetConceptResponse> {
    return ConceptService.GetConcept(request, StudioApi.getHeaders().initReq);
  }

  public static createConcept(request: CreateConceptRequest): Promise<CreateConceptResponse> {
    return ConceptService.CreateConcept(request, StudioApi.getHeaders().initReq);
  }

  public static updateConcept(request: UpdateConceptRequest): Promise<UpdateConceptResponse> {
    return ConceptService.UpdateConcept(request, StudioApi.getHeaders().initReq);
  }

  public static startAutoAnalysis(request: StartAutoAnalysisRequest): Promise<StartAutoAnalysisResponse> {
    return ConceptService.StartAutoAnalysis(request, StudioApi.getHeaders().initReq);
  }

  public static exportConceptToCX(request: ExportConceptToCXRequest): Promise<ExportConceptToCXResponse> {
    return ConceptService.ExportConceptToCX(request, StudioApi.getHeaders().initReq);
  }

  public static fetchAllESVersions(request: FetchAllESVersionsRequest): Promise<FetchAllESVersionsResponse> {
    return ConceptService.FetchAllESVersions(request, StudioApi.getHeaders().initReq);
  }

  public static batchUpsertSharedConceptConfigs(request: BatchUpsertSharedConceptConfigsRequest): Promise<BatchUpsertSharedConceptConfigsResponse> {
    return ConceptService.BatchUpsertSharedConceptConfigs(request, StudioApi.getHeaders().initReq);
  }

  public static fetchSharedConcepts(request: FetchSharedConceptsRequest): Promise<FetchSharedConceptsResponse> {
    return ConceptService.FetchSharedConcepts(request, StudioApi.getHeaders().initReq);
  }

  public static syncLibraryConcepts(request: SyncLibraryConceptsRequest): Promise<SyncLibraryConceptsResponse> {
    return ConceptService.SyncLibraryConcepts(request, StudioApi.getHeaders().initReq);
  }
}
