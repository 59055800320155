import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { createSelector } from '@reduxjs/toolkit';
import { getId } from 'common/resourceName';
import type { State } from '../store';
import type { State as ConceptState } from './state';

const selectConcept = (state: State) => state.concept;

/** Api status. */
export const selectApiStatus = createSelector(selectConcept, (conceptState: ConceptState) => conceptState.status);

/** All concepts selector */
export const selectAllConcepts = createSelector(selectConcept, (conceptState: ConceptState) => Object.values(conceptState.concepts).reduce((acc, concepts) => ([...acc, ...concepts]), []));

/** Select all concepts map. */
export const selectAllConceptsMapFactory = createSelector(selectConcept, (conceptState: ConceptState) => new Map<string, Concept>(
  Object.keys(conceptState.concepts)
    .reduce((acc, key) => [...acc, ...conceptState.concepts[key]?.map((concept) => [getId('concept', concept.name), concept])], []),
));

/** Select concepts array by type. */
export const selectConceptsFactory = (type: ConceptConceptType) => createSelector(selectConcept, (conceptState: ConceptState) => conceptState.concepts[type] || []);
/** Select concepts map by type. */
export const selectConceptsMapFactory = (type: ConceptConceptType) => createSelector(selectConcept, (conceptState: ConceptState) => new Map<string, Concept>(conceptState.concepts[type]?.map((concept) => [getId('concept', concept.name), concept])));
/** Select shared concepts by type. */
export const selectSharedConceptsFactory = (type: ConceptConceptType) => createSelector(selectConcept, (conceptState: ConceptState) => conceptState.sharedConcepts[type] || []);
/** Select demo concepts by type. */
export const selectDemoConceptsFactory = (type: ConceptConceptType) => createSelector(selectConcept, (conceptState: ConceptState) => conceptState.demoConcepts[type] || []);

/** Select concept by its resource name. */
export const selectIntentConceptByNameFactory = (name: string) => createSelector(selectConceptsFactory(ConceptConceptType.INTENT), (concepts: Concept[]) => concepts.find((concept) => concept.name === name));
/** Select concept by its concept title. */
export const selectIntentConceptByTitleFactory = (conceptTitle: string) => createSelector(selectConceptsFactory(ConceptConceptType.INTENT), (concepts: Concept[]) => concepts.find((concept) => concept.conceptTitle === conceptTitle));
