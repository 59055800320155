import {
  CreateUserRequest,
  CreateUserResponse,
  GetUserRequest,
  GetUserResponse,
  ListUsersRequest,
  ListUsersResponse,
  UserService,
} from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { StudioApi } from './studioApi';

export abstract class UserApi {
  public static createUser(
    req: CreateUserRequest,
  ): Promise<CreateUserResponse> {
    return UserService.CreateUser(req, StudioApi.getHeaders().initReq);
  }

  public static listUsers(req: ListUsersRequest): Promise<ListUsersResponse> {
    return UserService.ListUsers(req, StudioApi.getHeaders().initReq);
  }

  public static getUser(req: GetUserRequest): Promise<GetUserResponse> {
    return UserService.GetUser(req, StudioApi.getHeaders().initReq);
  }
}
