import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Group, Loader, Modal, Stack, Text, UnstyledButton } from '@mantine/core';
import { useDispatch } from 'hooks/reduxHooks';
import { getErrorMessage } from 'utils';
import { updateAbstractTask } from 'store/labelingTask/asyncThunks';
import { AbstractTask, TaskVisibility } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { showNotification } from '@mantine/notifications';
import { LabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { getAnnotationsByLabelingTaskName, handleRemoveAnnotations } from './utils';

const useAnnotations = ({
  taskName,
  usecase,
  languageCode,
}: {
  taskName: string,
  usecase: string,
  languageCode: string,
}) => {
  const [annotations, setAnnotations] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchAnnotations = async () => {
      setIsLoading(true);
      try {
        const annotations = await getAnnotationsByLabelingTaskName({
          taskName,
          usecase,
          languageCode,
        });
        setAnnotations(annotations);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAnnotations();
  }, [taskName, usecase, languageCode]);
  return [annotations, isLoading, error];
};

const ArchiveTaskModal = ({ isOpen, onClose, labelingTask }) => {
  const { abstractTask } = labelingTask;
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const [annotations, isLoading, error] = useAnnotations({
    taskName: labelingTask.name,
    usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
    languageCode: customer.languageCode,
  });
  const [isChecked, toggleChecked] = useState(false);
  const isArchived = abstractTask.visibility === TaskVisibility.INVISIBLE;

  const newArchiveState = isArchived ? TaskVisibility.VISIBLE : TaskVisibility.INVISIBLE;

  const handleClose = () => {
    onClose();
    toggleChecked(false);
  };

  const toggleTaskVisibility = async () => {
    const task: AbstractTask = {
      ...abstractTask,
      visibility: newArchiveState,
    };
    await dispatch(updateAbstractTask({ abstractTask: task, updateMask: 'abstractTask.visibility' }));
  };

  const onSubmit = async ({
    shouldRemoveAnnotations,
    taskName,
  }: {
    shouldRemoveAnnotations: boolean;
    taskName: string;
  }) => {
    if (isArchived) {
      await toggleTaskVisibility();
      handleClose();
      return;
    }
    if (shouldRemoveAnnotations) {
      try {
        const res = await handleRemoveAnnotations(taskName);
        showNotification({
          color: 'green',
          title: 'Success',
          message: `Successfully removed ${res.deprecatedAnnotations.length} annotations`,
        });
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        console.error(errorMessage);
        showNotification({
          color: 'red',
          title: 'Error removing annotations',
          message: errorMessage,
        });
      }
    }
    await toggleTaskVisibility();
    handleClose();
  };
  const showCheckBox = !isArchived && !isLoading && !error && annotations?.annotationBundles.length > 0;
  return (
    <Modal
      title={`Are you sure you want to ${isArchived ? 'unarchive' : 'archive'} this task?`}
      opened={isOpen}
      onClose={handleClose}
    >
      <Stack>
        {error && <Alert color="yellow">{getErrorMessage(error)}</Alert>}
        {isLoading && <Group><Loader size="xs"/><Text>Remove annotations</Text></Group>}
        {showCheckBox && (
          <Checkbox
            label={`Remove all annotations (${annotations?.annotationBundles?.length} annotations)`}
            labelPosition="right"
            checked={isChecked}
            disabled={isArchived}
            onChange={() => toggleChecked(!isChecked)}
          />
        )}
        <Group position="right">
          <Button onClick={() => onSubmit({
            shouldRemoveAnnotations: isChecked,
            taskName: labelingTask.name,
          })}
          >Yes
          </Button>
          <Button variant="subtle" onClick={handleClose}>Cancel</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

interface Props {
    labelingTask: LabelingTask;
}

export const ArchiveTaskButton = ({
  labelingTask,
}: Props) => {
  const [isModalOpen, toggleModal] = useState(false);
  const onClose = () => {
    toggleModal(false);
  };

  return (
    <>
      <UnstyledButton onClick={() => toggleModal(true)} >{labelingTask.abstractTask.visibility === TaskVisibility.INVISIBLE ? 'Unarchive' : 'Archive'}</UnstyledButton>
      <ArchiveTaskModal isOpen={isModalOpen} onClose={onClose} labelingTask={labelingTask} />
    </>
  );
};
