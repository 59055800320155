import React from 'react';
import { DataGrid } from 'components/DataGrid';

interface Props {
  dataSource: {
    metric: string;
    [key: string]: string | number;
  }[];
}
export const convertEvaluationStatResultsToTableData = (results: unknown) => {
  const tableData: {
    metric: string;
    [key: string]: string | number;
  }[] = [];
  Object.entries(results).forEach(([key, value]) => {
    const [metric, at] = key.split('@');
    if (at) {
      const row = tableData.find((row) => row.metric === metric);
      if (row) {
        row[`@${at}`] = value;
      } else {
        tableData.push({
          metric,
          [`@${at}`]: value,
        });
      }
    }
  });
  return tableData;
};

export const convertTestSetScoresToTableData = (results: {[key: string]: number}) => {
  const tableData: {
    metric: string;
    [key: string]: string | number;
  }[] = [];
  const keys = Object.keys(results);
  keys.forEach((key) => {
    const stringArray = key.split('-');
    const lastIndexOfArray = stringArray.length - 1;
    const metric = stringArray.slice(0, lastIndexOfArray).join('-');
    const index = tableData.findIndex((item) => item.metric === metric);
    if (index === -1) {
      tableData.push({
        metric,
        [`@${key.split('-')[lastIndexOfArray]}`]: results[key],
      });
    } else {
      tableData[index][`@${key.split('-')[lastIndexOfArray]}`] = results[key];
    }
  });

  return tableData;
};

const getUniqueAtColumns = (dataSource: Props['dataSource']) => {
  const atColumns: Set<string> = new Set();
  dataSource.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (key.startsWith('@')) {
        atColumns.add(key);
      }
    });
  });
  return atColumns;
};
const sortInIncreasingOrder = (a: string, b: string) => {
  const aNum = Number(a.replace('@', ''));
  const bNum = Number(b.replace('@', ''));
  return aNum - bNum;
};
export const QuantitativeScoresTable = ({ dataSource }: Props) => {
  const columns = [
    {
      header: 'Metric',
      accessorFn: (row) => row.metric,
    },
    // create column for each @ value
    ...Array.from(getUniqueAtColumns(dataSource))
      .sort(sortInIncreasingOrder)
      .map((at) => ({
        header: at,
        accessorFn: (row) => row[at],
        // round to 3 decimal places
        cell: (info) => {
          const value = info.getValue();
          return typeof value === 'number' ? Math.round(value * 1000) / 1000 : value;
        },
      })),
  ];
  return (
    <DataGrid
      style={{
        border: '1px solid #eaeaea',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
      columns={columns}
      dataSource={dataSource}
    />
  );
};
