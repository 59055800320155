import { Tooltip } from 'antd';
import React from 'react';
import './RegexTag.scss';

type RegexTagProps = {
  positiveRegex?: string[];
  negativeRegex?: string[];
};

export default function RegexTag({
  positiveRegex,
  negativeRegex,
}: RegexTagProps) {
  const renderRegexTags = () => {
    const mergedRegex = [...positiveRegex, ...negativeRegex];
    if (mergedRegex?.length > 1) {
      return (
        <span>
          {mergedRegex.slice(0, 1).map((id) => <span className="regex-tag" key={id}>{id}</span>)}
          <span className="regex-tag">+{mergedRegex.length - 1}</span>
        </span>
      );
    } else if (mergedRegex?.length === 1) {
      return <span className="regex-tag" key={mergedRegex[0]}>{mergedRegex[0]}</span>;
    }
    return null;
  };

  const renderTooltip = () => {
    const positive = positiveRegex.map((str) => <div className="regex-tag-tooltip-item positive" key={str}><span>{str}</span></div>);
    const negative = negativeRegex.map((str) => <div className="regex-tag-tooltip-item negative" key={str}><span>{str}</span></div>);
    return [...positive, ...negative];
  };

  return (
    <Tooltip
      title={renderTooltip()}
      color="#4A4A4A"
    >
      {renderRegexTags()}
    </Tooltip>
  );
}
