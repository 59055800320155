import { Button, Variants, createStyles, DefaultMantineColor, useMantineTheme } from '@mantine/core';
import React from 'react';

interface ConceptConceptTypeProps {
  title: string;
  color?: DefaultMantineColor;
  variant?: Variants<'filled' | 'outline' | 'light' | 'white' | 'default' | 'subtle' | 'gradient'>;
  onClick?: () => void;
}

export function ConceptTypeTag({
  title,
  color,
  variant,
  onClick,
}: ConceptConceptTypeProps) {
  const theme = useMantineTheme();

  const useStyles = createStyles({
    base: {
      fontSize: theme.fontSizes.sm,
      padding: '0 8px',
      borderRadius: theme.radius.lg,
      display: 'inline-block',
    },
  });

  const styles = useStyles();

  return (
    <Button
      onClick={onClick}
      size="xs"
      color={color || 'gray'}
      variant={variant}
      compact
      className={styles.classes.base}
    >{title}
    </Button>
  );
}
