import React, { useEffect, useState } from 'react';
import { Button, Collapse, Group, MultiSelect, NumberInput, NumberInputProps, Stack, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useInputState } from '@mantine/hooks';
import { useSelector } from 'hooks/reduxHooks';
import { getId } from 'common/resourceName';
import { selectDerivedQaTasks } from 'store/labelingTask/selectors';
import { DataGrid } from 'components/DataGrid';
import { useApiPost } from 'hooks/network';
import { useQALabelCounts } from '../useQALabelCounts';
import { labelTableColumns } from '../labelTableColumns';

const PreviewTable = ({ taskIds, splitRatio, toggleShowResults }) => {
  const apiPost = useApiPost(true, true);
  const [submittingPromotionRequest, togglePromotionRequest] = useState(false);
  const [refresh, toggleRefresh] = useState(false);
  const [data, isLoading] = useQALabelCounts(taskIds, [refresh]);

  const handlePromoteTasks = async () => {
    togglePromotionRequest(true);
    try {
      await apiPost('change_qa_label_splits', {
        task_ids: taskIds,
        revert: false,
        split_ratio: splitRatio,
      });
      showNotification({
        color: 'green',
        message: 'Promotion successful',
      });
      togglePromotionRequest(false);
      toggleRefresh(!refresh);
    } catch (error) {
      console.error('error', error);
      showNotification({
        color: 'red',
        message: 'Promotion failed',
      });
    }
    togglePromotionRequest(false);
  };

  return (
    <>
      <DataGrid
        style={{ margin: '0 -16px' }}
        isLoading={isLoading}
        columns={labelTableColumns}
        dataSource={data}
      />
      <Text>Do you want to promote these labels to QA ready? This cannot be undone.</Text>
      <Group>
        <Button color="red" onClick={handlePromoteTasks} loading={submittingPromotionRequest} disabled={data.length === 0}>Yes, continue</Button>
        <Button variant="subtle" onClick={() => toggleShowResults(false)}>No, cancel</Button>
      </Group>
    </>
  );
};

export const LabelPromotion = () => {
  const [showResults, toggleShowResults] = useState(false);

  const [ratioTestValue, setRatioTestValue] = useState(0);
  const [ratioTrainValue, setRatioTrainValue] = useState(100);
  const [taskIds, setTaskIds] = useInputState([]);
  const qaTasks = useSelector(selectDerivedQaTasks);
  const allQATaskIds = qaTasks.map((task) => getId('labelingTask', task.labelingTask.name)) || [];

  const handleRatioChange = (value: number, type: 'train' | 'test') => {
    let coercedValue = value;
    // value should never exceed 100
    if (value > 100) {
      coercedValue = 100;
    }
    // value should never be negative
    if (value < 0) {
      coercedValue = 0;
    }
    // values should always sum up to 100
    if (type === 'train') {
      setRatioTrainValue(coercedValue);
      setRatioTestValue(100 - coercedValue);
    } else {
      setRatioTestValue(coercedValue);
      setRatioTrainValue(100 - coercedValue);
    }
  };

  const formatter: (value: string | undefined) => string = (value) => {
    if (value === '') {
      return '';
    }
    const number = value.replace('%', '');
    return `${number}%`;
  };

  const sharedNumberInputProps: NumberInputProps = {
    placeholder: 'Enter %',
    min: 0,
    max: 100,
    step: 1,
    required: true,
    formatter,
  };
  const getSplitRatio = () => ratioTrainValue / 100;
  const splitRatio = getSplitRatio();

  useEffect(() => {
    if (showResults) {
      toggleShowResults(false);
    }
  }, [taskIds, splitRatio]);

  return (
    <Stack>
      <Text>Promote labels from QA to model ready</Text>
      <Group>
        <MultiSelect
          searchable
          onChange={setTaskIds}
          value={taskIds}
          data={allQATaskIds}
          label="Task IDs"
          placeholder="Enter task ID"
          style={{ maxWidth: 240 }}
        />
        <NumberInput
          {...sharedNumberInputProps}
          label="Test ratio"
          value={ratioTestValue}
          onChange={(value) => handleRatioChange(+value, 'test')}
        />
        <NumberInput
          {...sharedNumberInputProps}
          label="Train ratio"
          value={ratioTrainValue}
          onChange={(value) => handleRatioChange(+value, 'train')}
        />
      </Group>
      {showResults || (
        <Group>
          <Button
            disabled={taskIds.length === 0}
            onClick={() => {
              toggleShowResults(true);
            }}
          >Run
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setTaskIds([]);
            }}
          >Clear
          </Button>
        </Group>
      )}
      <Collapse in={showResults}>
        <Stack>
          {showResults && <PreviewTable taskIds={taskIds} splitRatio={splitRatio} toggleShowResults={toggleShowResults} />}
        </Stack>
      </Collapse>
    </Stack>
  );
};
