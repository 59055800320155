/* eslint-disable no-await-in-loop */
import { Annotation, BinaryValueValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Prediction, SearchPredictionsRequest } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import CopyableValue from 'components/CopyableValue';
import Loading from 'components/Loading';
import { openNotification } from 'components/Notification';
import { useSelector } from 'hooks/reduxHooks';
import useUrlParam from 'hooks/useUrlParam';
import { formatLabelValue } from 'pages/LabelsReview';
import React, { useEffect, useMemo, useState } from 'react';
import { PredictionApi } from 'services/predictionApi';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import { ConfustionTypeFilter } from 'types';
import styles from '../styles.module.scss';

interface QARecallTableProps {
  searchTerm: string;
  taskId: string;
  conceptId: string;
}

interface ResultsRow {
  annotation: Annotation;
  textMessage: string;
  prediction: Prediction;
  predictionValue: boolean;
}

const LABEL_BUTTON_STYLES_MAP = new Map<BinaryValueValue, string>(
  [
    [BinaryValueValue.VALUE_POSITIVE, styles.positive],
    [BinaryValueValue.VALUE_NEGATIVE, styles.negative],
    [BinaryValueValue.VALUE_SKIP, styles.skipped],
    [BinaryValueValue.VALUE_FLAG_FOR_REVIEW, styles.flagged],
  ],
);

export default function QARecallTable({ searchTerm, taskId, conceptId }: QARecallTableProps) {
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const customerProfile = useCustomerProfile();
  // DISABLED (temporarily)
  // const usecase = useCustomerUsecase();
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [loadingPredictions, setLoadingPredictions] = useState(true);
  const [confusionTypeFilter] = useUrlParam<ConfustionTypeFilter | ''>('confusionTypeFilter', '');

  const predictionsMap = useMemo(() => new Map<string, Prediction>(
    predictions.map((prediction) => [getId('prediction', prediction.name), prediction]),
  ), [predictions]);

  const annotationsAndPredictions: ResultsRow[] = useMemo(() => annotationBundles.map((bundle) => {
    const prediction = predictionsMap.get(bundle.annotation.rawData.messageRawData.predictionId);
    return {
      annotation: bundle.annotation,
      textMessage: bundle.textMessage,
      prediction,
      predictionValue: prediction?.metadata ? prediction.metadata.confidence >= prediction.metadata.threshold : true,
    };
  }).filter((row) => {
    const intentId = getId('concept', row.prediction?.metadata.concept);
    const { value } = row.annotation.value.binaryValue;
    const { predictionValue } = row;
    let confusionType: ConfustionTypeFilter | null = null;
    if (intentId !== conceptId) {
      return false;
    }
    if (predictionValue && value === BinaryValueValue.VALUE_POSITIVE) {
      confusionType = 'TP';
    }
    if (predictionValue && value === BinaryValueValue.VALUE_NEGATIVE) {
      confusionType = 'FP';
    }
    if (!predictionValue && value === BinaryValueValue.VALUE_NEGATIVE) {
      confusionType = 'TN';
    }
    if (!predictionValue && value === BinaryValueValue.VALUE_POSITIVE) {
      confusionType = 'FN';
    }
    if (confusionTypeFilter) {
      if (confusionTypeFilter === 'CORRECT' && !(['TP', 'TN'].includes(confusionType))) {
        return false;
      } else if (confusionTypeFilter === 'INCORRECT' && !(['FP', 'FN'].includes(confusionType))) {
        return false;
      } else if (!['CORRECT', 'INCORRECT'].includes(confusionTypeFilter) && confusionTypeFilter !== confusionType) {
        return false;
      }
    }
    return true;
  }), [annotationBundles, predictionsMap, conceptId, confusionTypeFilter]);

  const fetchPredictions = async () => {
    setLoadingPredictions(true);
    const predictionIds = annotationBundles.filter((bundle) => bundle.annotation.rawData.messageRawData.predictionId)
      .map((bundle) => bundle.annotation.rawData.messageRawData.predictionId);
    try {
      const request: SearchPredictionsRequest = {
        resource: `${customerProfile}/conversations/-`,
        pageSize: 100,
      };
      const predictions: Prediction[] = [];
      for (let start = 0; start < predictionIds.length; start += 100) {
        const end = Math.min(start + 100, predictionIds.length);
        request.filter = {
          predictionIds: predictionIds.slice(start, end),
          // DISABLED (temporarily)
          // usecases: [usecase],
        };
        request.pageToken = '';
        while (true) {
          const response = await PredictionApi.searchPredictions(request);
          predictions.push(...response.predictions);
          if (!response.nextPageToken) {
            break;
          }
          request.pageToken = response.nextPageToken;
        }
      }
      setPredictions(predictions);
    } catch (error) {
      openNotification('error', 'Predictions failed to load', '', error);
    } finally {
      setLoadingPredictions(false);
    }
  };

  useEffect(() => {
    if (annotationBundles.length) {
      fetchPredictions();
    }
  }, [annotationBundles]);

  const columns: ColumnsType<ResultsRow> = [
    {
      title: 'Chat ID',
      dataIndex: 'chatId',
      key: 'chatId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2ConversationId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id} />;
      },
    },
    {
      title: 'Message ID',
      dataIndex: 'messageId',
      key: 'messageId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2MessageId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id} />;
      },
    },
    {
      title: 'Message',
      dataIndex: ['textMessage'],
      key: 'message',
    },
    {
      title: 'Prediction',
      dataIndex: ['predictionValue'],
      key: 'prediction',
      sorter: (a, b) => Number(a.predictionValue) - Number(b.predictionValue),
      render: (value, record) => (
        <div>
          <Button
            className={classNames([styles.labelButton, value ? styles.positive : styles.negative])}
            type="primary"
          >
            {value ? 'Positive' : 'Negative'}
          </Button>
        </div>
      ),
    },
    {
      title: 'QA Label',
      dataIndex: ['annotation', 'value', 'binaryValue', 'value'],
      key: 'label',
      sorter: (a, b) => a.annotation.value.binaryValue.value.localeCompare(b.annotation.value.binaryValue.value),
      render: (value, record) => (
        <div>
          <Button
            className={classNames([styles.labelButton, LABEL_BUTTON_STYLES_MAP.get(value)])}
            type="primary"
          >
            {formatLabelValue(value)}
          </Button>
        </div>
      ),
    },
    {
      title: 'Comments',
      dataIndex: ['annotation', 'value', 'binaryValue', 'note'],
      width: '20%',
      key: 'comment',
      render: (value, record) => {
        if (!value) {
          return <span className={styles.missingData}>N/A</span>;
        }

        return value;
      },
    },
  ];

  return (
    <Table
      rowKey={(row) => row.annotation.name}
      loading={{
        spinning: taskApiStatus === 'loading' || loadingPredictions,
        indicator: <Loading />,
      }}
      className={styles.summaryTable}
      columns={columns}
      dataSource={annotationsAndPredictions}
    />
  );
}
