import { useCallback } from 'react';
import { useSelector } from 'hooks/reduxHooks';
import { selectAgents, selectApiStatus } from 'store/agent/selectors';
import { getId } from 'common/resourceName';
import { CustomerParams, useCustomerParams } from './useCustomerParams';

function capitalize(string: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDefaultAgentName(customer: CustomerParams) {
  let defaultAgentName = 'Agent';
  try {
    defaultAgentName = `${capitalize(customer.customerId)} Agent`;
  } catch (e) {
    console.error(e);
  }
  return defaultAgentName;
}

export const useAgentNames = () => {
  const agents = useSelector(selectAgents);
  const agentsLoadingStatus = useSelector(selectApiStatus);
  const customer = useCustomerParams();

  const getAgentNameFromActorId = useCallback((actorId: string) => {
    if (agentsLoadingStatus === 'loading') {
      // still loading agent data for profile, so show agent id in interim
      return actorId;
    }
    if (!actorId) {
      return getDefaultAgentName(customer);
    }
    const agentData = agents.find((a) => getId('prodUser', a.name) === actorId);
    if (!agentData) {
      return actorId;
    }
    return agentData?.displayName || agentData?.email || getId('prodUser', agentData.name);
  }, [agents, agentsLoadingStatus]);

  return { getAgentNameFromActorId };
};
