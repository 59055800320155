import React, { useState } from 'react';
import { Anchor, Box, Button, Divider, Group, MultiSelect, Paper, ScrollArea, SegmentedControl, Select, SelectItem, Skeleton, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { LabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { PageContainer } from 'components/PageContainer';
import * as CSV from 'csv-string';
import { downloadDataString } from 'utils/data-export';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { NewSetButton } from 'pages/Conversations/NewSetModal';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { TagsTable, TagsTableProps } from './TagsTable/TagsTable';
import { ConversationsTable, ConversationTableProps } from './ConversationsTable/ConversationsTable';
import { PanelData, TagData } from '.';
import { TaskMetadata } from './TaskMetadata';
import { StagesTable, StagesTableProps } from './StagesTable/StagesTable';
import StagesTree from './StagesTree';

const renderTable = (currentTable, props) => {
  switch (currentTable) {
    case 'tags  ':
      return <TagsTable {...props} />;
    case 'stages':
      return <StagesTable {...props} />;
    case 'conversations':
      return <ConversationsTable {...props} />;
    default:
      return <TagsTable {...props} />;
  }
};

const PercentileRow = ({ tag, percent, dotColor }) => (

  <Group align="center" position="apart">
    <Group>
      <div style={{ background: dotColor, height: 12, width: 12, borderRadius: '50%' }} />
      <Text size="sm">{tag}</Text>
    </Group>
    <Text size="sm">{`${percent}%`}</Text>
  </Group>
);

interface DashboardCardProps {
  isLoading?: boolean;
  title: string;
  data: TagData[];
  baseColor: string;
}

const loadingContent = (
  <>
    {/* create n number of skeletons */}
    {Array(10).fill(0).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Group key={index} position="apart">
        <Group>
          <Skeleton circle height={12} />
          <Skeleton radius="sm" height={15} width={180} />
        </Group>
        <Skeleton radius="sm" height={15} width={40} />
      </Group>
    ))}

  </>
);

const DashboardCard = (props: DashboardCardProps) => {
  const { isLoading, title, data, baseColor, ...rest } = props;

  const sortByPercent = (a: TagData, b: TagData) => {
    if (a.percent < b.percent) {
      return 1;
    }
    if (a.percent > b.percent) {
      return -1;
    }
    return 0;
  };

  const getContent = () => {
    const clonedArray = JSON.parse(JSON.stringify(data));
    if (isLoading) {
      return loadingContent;
    }
    return clonedArray.sort(sortByPercent).map((item) => {
      const dotColor = `${baseColor}${item.percent === 100 ? 'ff' : item.percent}`;
      return (
        <PercentileRow {...item} dotColor={dotColor} key={item.id}/>
      );
    });
  };
  return (
    <Paper px="xl" {...rest} >
      <Stack style={{ height: 350 }}>
        <Title mt="xl" order={4}>{title}</Title>
        <ScrollArea mx="-sm" px="sm" type={isLoading ? 'never' : 'auto'}>
          <Stack justify="center" mb="xl">
            {getContent()}
          </Stack>
        </ScrollArea>
      </Stack>
    </Paper>
  );
};

interface AnalysisSummaryProps extends ConversationTableProps, StagesTableProps, Partial<TagsTableProps> {
  isLoadingSummaries: boolean;
  driverFilterData: (string | SelectItem)[];
  driverFilter: string[];
  setDriverFilter: (driverFilter: string[]) => void;
  outcomeFilterData: (string | SelectItem)[];
  outcomeFilter: string[];
  setOutcomeFilter: (outcomeFilter: string[]) => void;
  setFilterData: SelectItem[];
  setFilter: string[];
  setSetFilter: (setFilter: string[]) => void;
  panelData: PanelData
  labelingTask: LabelingTask
}
const data = [
  {
    label: 'Both agent and visitor',
    value: null,
  },
  {
    label: 'Agent only',
    value: TagTagType.AGENT_INTENT,
  },
  {
    label: 'Visitor only',
    value: TagTagType.VISITOR_INTENT,
  },
];

export const AnalysisSummary = (props: AnalysisSummaryProps) => {
  const customerProfile = useCustomerProfile();
  const [currentTable, setCurrentTable] = useState('conversations');
  const { taskId } = useParams<{ taskId: string }>();
  const customer = useCustomerParams();
  const [tagFilter, setTagFilter] = useState<TagTagType>(null);
  const { isLoadingSummaries, conversationsTableData, driverFilterData, driverFilter, setDriverFilter, outcomeFilterData, outcomeFilter, setOutcomeFilter, setFilterData, setFilter, setSetFilter, panelData, labelingTask } = props;
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const [isModalOpen, toggleModal] = useState(false);

  // Map to conversations for set creation button
  const conversations = props.conversationsTableData.map((row) => {
    const conversation: Conversation = {
      name: `${customerProfile}/conversations/${row.conversationId}`,
    };
    return conversation;
  });

  // Export utterances as CSV.
  const exportCSV = () => {
    const headerRow = [
      'Conversation ID',
      'Stages',
      'Drivers',
      'Outcomes',
      'Agent Behaviors',
      'Visitor Behaviors',
      'Set Name',
      'Notes',
    ];

    const rows = conversationsTableData.map((item) => {
      const { conversationId, setId, note, stages, drivers, outcomes, behaviors } = item;
      const stageNames = stages.map((stage) => stage.label);
      const driverNames = drivers.map((driver) => driver.label);
      const outcomeNames = outcomes.map((outcome) => outcome.label);
      const agentBehaviorNames = behaviors.filter((behavior) => behavior.tagType === TagTagType.AGENT_INTENT).map((behavior) => behavior.label);
      const visitorBehaviorNames = behaviors.filter((behavior) => behavior.tagType === TagTagType.VISITOR_INTENT).map((behavior) => behavior.label);
      const setName = setFilterData.find((item) => item.value === setId)?.label;
      return [
        conversationId,
        stageNames.join(', '),
        driverNames.join(', '),
        outcomeNames.join(', '),
        agentBehaviorNames.join(', '),
        visitorBehaviorNames.join(', '),
        setName,
        note,
      ];
    });

    const csvData = [headerRow, ...rows];

    const csvString = CSV.stringify(csvData);
    const dataString = `data:text/tab-separated-values;charset=utf-8,${encodeURIComponent(csvString)}`;
    downloadDataString(dataString, `conversations_${taskId}.csv`);
  };

  return (
    <PageContainer className="analysis-summary-page">
      <Stack spacing={0} align="flex-start">
        <Button onClick={() => navigate(`/${customer.path}/analysisworkshop/${taskId}`)} variant="subtle" compact>
          <ArrowLeftOutlined style={{ marginRight: '4px' }} />
          Review Task
        </Button>
        <Title>Analysis Summary</Title>
        <TaskMetadata labelingTask={labelingTask} />
      </Stack>
      <Group position="right">
        <MultiSelect
          placeholder="All drivers"
          clearable
          data={driverFilterData}
          value={driverFilter}
          onChange={setDriverFilter}
          maxSelectedValues={1}
        />
        <MultiSelect
          placeholder="All outcomes"
          clearable
          data={outcomeFilterData}
          value={outcomeFilter}
          onChange={setOutcomeFilter}
          maxSelectedValues={1}
        />
        <MultiSelect
          placeholder="All sets"
          clearable
          data={setFilterData}
          value={setFilter}
          onChange={setSetFilter}
          maxSelectedValues={1}
        />
      </Group>
      <Group grow align="stretch">
        <DashboardCard isLoading={isLoadingSummaries} title="Drivers" data={panelData.driverData} baseColor="#FF8A5B" />
        <DashboardCard isLoading={isLoadingSummaries} title="Behaviors" data={panelData.behaviorData} baseColor="#63A375" />
        <DashboardCard isLoading={isLoadingSummaries} title="Outcomes" data={panelData.outcomeData} baseColor="#EA526F" />
      </Group>
      <Box>
        <Title order={2} mb="sm">Stages tree</Title>
        <Anchor href={`/${customer.path}/surveyor?tasks[0]=${taskId}`} mt={0} mb="sm">Open in Surveyor</Anchor>
        <StagesTree flowGraphs={props.flowGraphs}/>
      </Box>
      <Divider color={theme.colors.gray[2]}/>
      <Group position="apart">
        <Group>
          <SegmentedControl
            value={currentTable}
            onChange={setCurrentTable}
            data={[
              { value: 'conversations', label: 'Conversations' },
              { value: 'stages', label: 'Stages' },
              { value: 'tags', label: 'Tags' },
            ]}
          />

          <NewSetButton isModalOpen={isModalOpen} toggleModal={toggleModal} conversationNames={conversations.map((convo) => convo.name)} />
        </Group>
        {currentTable === 'conversations' && (
          <Button
            color="green"
            size="xs"
            compact
            variant="outline"
            onClick={exportCSV}
          >Export CSV
          </Button>
        )}
        {currentTable === 'tags' && (
          <Select
            placeholder="Both agent and visitor"
            value={tagFilter}
            onChange={(val: TagTagType) => setTagFilter(val)}
            data={data}
          />
        )}
      </Group>
      <Box pb="xl">
        {renderTable(currentTable, { ...props, tagFilter })}
      </Box>
    </PageContainer>
  );
};
