import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification } from 'components/Notification';
import { ConceptChangeApi } from 'services/conceptChangeApi';
import { ListConceptChangesRequest, ListConceptChangesResponse } from '@cresta/web-client/dist/cresta/v1/studio/conceptchange/conceptchange_service.pb';

enum Action {
  CONCEPT_CHANGE_LIST_CONCEPT_CHANGES = 'CONCEPT_CHANGE_LIST_CONCEPT_CHANGES',
}

/** AsyncThunk for ListConceptChanges API. */
export const listConceptChanges = createAsyncThunk<ListConceptChangesResponse & { loadNew: boolean }, ListConceptChangesRequest>(Action.CONCEPT_CHANGE_LIST_CONCEPT_CHANGES, async (request: ListConceptChangesRequest) => {
  try {
    const resp = await ConceptChangeApi.listConceptChanges(request);
    return {
      ...resp,
      loadNew: !request.pageToken,
    };
  } catch (err) {
    openNotification('error', 'Failed to list concept changes', undefined, err);
    throw err;
  }
});
