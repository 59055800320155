import { GetJobRequest, GetJobResponse, JobService, ListJobsRequest, ListJobsResponse } from '@cresta/web-client';
import { ProdApi } from './prodApi';

export abstract class JobApi {
  public static async listJobs(requst: ListJobsRequest): Promise<ListJobsResponse> {
    const header = await ProdApi.getHeaders();
    return JobService.ListJobs(requst, header.initReq);
  }

  public static async getJob(request: GetJobRequest): Promise<GetJobResponse> {
    const header = await ProdApi.getHeaders();
    return JobService.GetJob(request, header.initReq);
  }
}
