import React, { createContext, MutableRefObject, useState, useRef, useEffect, useContext, useMemo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { openNotification } from 'components/Notification';
import Loading from 'pages/Loading';
import { OAuthClient, OAuthSessionInfo, OAuthFlowType } from '@cresta/client-auth';
import {
  Auth,
  setOktaAccessToken,
  getAuth,
  removeOktaAccessToken,
} from '../common/auth';

// Initialize OAuth client
const url = window.location.host;
const isStaging = url.includes('staging') || url.includes('localhost');
const client = new OAuthClient({
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  authUri: `https://${isStaging ? 'login.chat-staging.cresta.ai' : 'login.cresta.com'}/authorize`,
  tokenUri: `https://${isStaging ? 'auth.chat-staging.cresta.ai' : 'auth.cresta.com'}/v1/oauth/token`,
});

interface OktaAuthContextState {
  data: Auth;
  tokenRef: MutableRefObject<string>,
  signInWithOkta: () => void;
  signOutOkta: () => void;
}

export const OktaAuthContext = createContext<OktaAuthContextState>({
  data: { status: 'loading' },
  tokenRef: { current: null },
  signInWithOkta: () => {},
  signOutOkta: () => {},
});

export const OktaAuthProvider = ({ children }) => {
  const [oktaAuthState, setOktaAuthState] = useState<Auth>({ status: 'loading' });
  const tokenRef = useRef<string>();

  const signInWithOkta = () => {
    // The trailing slash is needed because the possible redirect uris are hardcoded and have to match exactly.
    const redirectUri = `${window.location.origin}/`;

    const { authorizationUrl, sessionInfo } = client.startAuth(
      // The redirect URI to use. The login app will redirect back to this
      // URL with the response (code) appended in the query string.
      redirectUri,
      // OAuth flow type. Only PKCE currently supported.
      OAuthFlowType.PKCE_CODE,
      // Additional OAuth parameters.
      // To pre-populate the customer ID, use the customer_id query parameter.
      { customer_id: 'cresta', auth_config_id: 'cresta' },
    );

    // Store session info as it will be needed after the login app redirects back.
    sessionStorage.setItem(
      'cresta-code-flow-session-info',
      JSON.stringify(sessionInfo),
    );

    window.location.assign(authorizationUrl);
  };

  const signOutOkta = async () => {
    try {
      setOktaAuthState({ status: 'loading' });
      removeOktaAccessToken();
      tokenRef.current = undefined;
      setOktaAuthState({ status: 'out' });
      mixpanel.reset();
    } catch (error) {
      openNotification('error', 'Failed to sign out', JSON.stringify(error));
    }
  };

  const getTokensFromUrl = async () => {
    const url = window.location.href;

    if (client.isPendingSignIn(url)) {
      const sessionInfo = JSON.parse(
        sessionStorage.getItem('cresta-code-flow-session-info'),
      ) as OAuthSessionInfo;

      try {
        // Finish the auth flow by exchanging the code for an access token.
        const { accessToken } = await client.finishAuth(url, sessionInfo);
        setOktaAccessToken(accessToken);

        // Remove the session info from storage.
        sessionStorage.removeItem('cresta-code-flow-session-info');

        // Refresh the auth state and update the token ref
        const auth = getAuth();
        if (auth.token) {
          tokenRef.current = auth.token;
        }
        setOktaAuthState(auth);
      } catch (e) {
        console.warn(e);
      }
    }
  };

  useEffect(() => {
    getTokensFromUrl();

    const auth = getAuth();
    if (auth.token) {
      tokenRef.current = auth.token;
    }
    setOktaAuthState(auth);
  }, []);

  const value = useMemo(() => ({ data: oktaAuthState, tokenRef, signInWithOkta, signOutOkta }), [oktaAuthState]);

  return (
    <OktaAuthContext.Provider value={value}>
      {oktaAuthState.status === 'loading' ? <Loading /> : children}
    </OktaAuthContext.Provider>
  );
};

export const AuthenticatedRoute = ({ children }) => {
  const { pathname } = useLocation();
  const { data: { status } } = useContext(OktaAuthContext);
  if (status === 'loading') {
    return <Loading />;
  }
  if (status !== 'in') {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: pathname }}
        replace
      />
    );
  }
  return children;
};
