import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, DatePicker, Form, Input } from 'antd';
import { Text } from '@mantine/core';

import { Dayjs } from 'dayjs';
import styles from '../styles.module.scss';

interface SuggestionsTaskFormProps {
  modelUri: string;
  setModelUri: (value: string) => void;
  modelUri2: string;
  setModelUri2: (value: string) => void;
  maxConversationCount: number;
  setMaxConversationCount: (value: number) => void;
  dateAfterAndBefore: [Dayjs, Dayjs];
  setDateAfterAndBefore: (date: [Dayjs, Dayjs]) => void;
}

export default function SuggestionsTaskForm({
  modelUri,
  modelUri2,
  setModelUri,
  setModelUri2,
  maxConversationCount,
  setMaxConversationCount,
  dateAfterAndBefore,
  setDateAfterAndBefore,
}: SuggestionsTaskFormProps) {
  const hasDateRange = useMemo(() => dateAfterAndBefore?.every((date) => date), [dateAfterAndBefore]);
  const isValidDateAndCount = useMemo(() => (hasDateRange ? hasDateRange && maxConversationCount > 0 : true), [hasDateRange, maxConversationCount]);
  const onRangeChange = (val: [Dayjs, Dayjs]) => {
    setDateAfterAndBefore([val[0]?.startOf('day'), val[1]?.endOf('day')]);
  };

  useEffect(() => {
    if (modelUri2) {
      setCompareModels(true);
    }
  }, [modelUri2]);

  const [compareModels, setCompareModels] = useState(false);
  return (
    <>
      <Form.Item>
        <Text size="md">Model {compareModels && '1'}</Text>
        <Input
          value={modelUri}
          placeholder="Model URI"
          onChange={(e) => setModelUri(e.target.value)}
        />
        {/* SELECT DISABLED UNTIL API IS AVAILABLE */}
        {/* <Select
          showArrow
          size="large"
          value={modelUri}
          placeholder="Select model URL"
          onChange={(value) => setModelUri(value)}
        >
          {modelUriList.map((item) => <Select.Option key={item} value={item}>{item}</Select.Option>)}
        </Select> */}
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={compareModels}
          onChange={(event) => setCompareModels(event.target.checked)}
        >
          Compare to another model
        </Checkbox>
      </Form.Item>
      {compareModels && (
      <Form.Item>
        <Text size="md">Model 2</Text>
        <Input
          value={modelUri2}
          placeholder="Model URI"
          onChange={(e) => setModelUri2(e.target.value)}
        />
        {/* SELECT DISABLED UNTIL API IS AVAILABLE */}
        {/* <Select
          showArrow
          size="large"
          value={modelUri2}
          placeholder="Select or model URL"
          onChange={(value) => setModelUri2(value)}
        >
          {modelUriList.map((item) => <Select.Option key={item} value={item}>{item}</Select.Option>)}
        </Select> */}
      </Form.Item>
      )}
      <Form.Item >
        <Text size="md">Conversations from this time range</Text>
        <DatePicker.RangePicker
          value={dateAfterAndBefore}
          onChange={onRangeChange}
        />
      </Form.Item>
      <Form.Item
        validateStatus={(!isValidDateAndCount) && 'error'}
        help={!isValidDateAndCount && 'Max conversation count has to be greater than 0'}
      >
        <Text size="md">Max conversations count</Text>
        <div className={styles.chatCount}>
          <Input
            size="large"
            value={maxConversationCount}
            type="number"
            onChange={(event) =>
              setMaxConversationCount(Number(event.currentTarget.value))}
          />
        </div>
      </Form.Item>
    </>
  );
}
