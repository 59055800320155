/* eslint-disable no-await-in-loop */
import { Annotation, BinaryValueValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import MultiTags from 'components/MultiTags';
import { useSelector } from 'hooks/reduxHooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { selectAnnotationBundles, selectApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import Loading from 'components/Loading';
import { PredictionApi } from 'services/predictionApi';
import { Prediction, SearchPredictionsRequest } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { openNotification } from 'components/Notification';
import { getId } from 'common/resourceName';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Group } from '@mantine/core';
import externalLinkIcon from 'assets/svg/icon-external-link.svg';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import styles from '../styles.module.scss';
import { getPrecisionPercentage } from '../utils';
import { BaseSummaryTable } from '..';

export const ALL_SCORE_TOOLTIP_COPY = 'This score is calculated by the following formula: True Positives / (True Positives + False Negatives), assuming (True Positives + False Negatives) > 30. This is an approximation for recall / missed opportunities.';
export const PRECISION_TOOLTIP_COPY = 'This score is calculated by the following formula: True Positives / (True Positives + False Positives), assuming (True Positives + False Positives) > 30.';

// Convert prediction to boolean value
export function getPredictionValue(prediction): boolean {
  return prediction?.metadata ? prediction?.metadata.confidence >= prediction?.metadata.threshold : true;
}

interface SummaryIntentRecallTableProps extends BaseSummaryTable {}

interface TableRowData {
  annotation: Annotation;
  prediction: Prediction;
  id: string;
  intent: string;
  truePositive: number;
  falsePositive: number
  correct: number;
  trueNegative: number;
  falseNegative: number;
  incorrect: number;
}

export default function SummaryIntentRecallTable({
  searchTerm,
  taskId,
  allConceptsMap,
  filtersNode,
}: SummaryIntentRecallTableProps) {
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const [loadingPredictions, setLoadingPredictions] = useState(true);
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const customerProfile = useCustomerProfile();
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const customer = useCustomerParams();

  const predictionsMap = useMemo(() => new Map<string, Prediction>(
    predictions.map((prediction) => [getId('prediction', prediction.name), prediction]),
  ), [predictions]);

  const summaryResults = useMemo(() => {
    // Map of intent id and calculated row data
    const resultsMap = new Map<string, TableRowData>();
    annotationBundles.forEach((bundle) => {
      const { value } = bundle.annotation.value.binaryValue;
      const prediction = predictionsMap.get(bundle.annotation.rawData.messageRawData.predictionId);
      const intentId = getId('concept', prediction?.metadata.concept);
      // Only show annotations for selected intent
      if (!intentId) return;

      // Filter out skip annotations
      if (value === BinaryValueValue.VALUE_SKIP) return;

      let resultRow = resultsMap.get(intentId);
      if (!resultRow) {
        resultRow = {
          id: intentId,
          annotation: bundle.annotation,
          prediction,
          intent: allConceptsMap.get(intentId)?.conceptTitle || '',
          truePositive: 0,
          falsePositive: 0,
          correct: 0,
          trueNegative: 0,
          falseNegative: 0,
          incorrect: 0,
        };
        resultsMap.set(intentId, resultRow);
      }

      const predictionBool = getPredictionValue(prediction);
      if (value === BinaryValueValue.VALUE_POSITIVE) {
        if (predictionBool) {
          resultRow.truePositive += 1;
          resultRow.correct += 1;
        } else {
          resultRow.falseNegative += 1;
          resultRow.incorrect += 1;
        }
      } else if (predictionBool) {
        resultRow.falsePositive += 1;
        resultRow.incorrect += 1;
      } else {
        resultRow.trueNegative += 1;
        resultRow.correct += 1;
      }
    });

    // Turn map to list
    const fullSummary = Array.from(resultsMap.values());
    const filteredSummary = fullSummary.filter((row) => row.intent.toLowerCase().includes(searchTerm.toLowerCase()));
    return filteredSummary;
  }, [annotationBundles, predictionsMap, searchTerm]);

  // Fetch predictions for annotations
  const fetchPredictions = async () => {
    setLoadingPredictions(true);
    const predictionIds = annotationBundles.filter((bundle) => bundle.annotation.rawData.messageRawData.predictionId)
      .map((bundle) => bundle.annotation.rawData.messageRawData.predictionId);
    try {
      const request: SearchPredictionsRequest = {
        resource: `${customerProfile}/conversations/-`,
        pageSize: 100,
      };
      const predictions: Prediction[] = [];
      for (let start = 0; start < predictionIds.length; start += 100) {
        const end = Math.min(start + 100, predictionIds.length);
        request.filter = {
          predictionIds: predictionIds.slice(start, end),
          // DISABLED (temporary)
          // usecases: [
          //   `${customerProfile}/usecases/${customer.usecaseId}`,
          // ],
        };
        request.pageToken = '';
        while (true) {
          const response = await PredictionApi.searchPredictions(request);
          predictions.push(...response.predictions);
          if (!response.nextPageToken) {
            break;
          }
          request.pageToken = response.nextPageToken;
        }
      }
      setPredictions(predictions);
    } catch (error) {
      openNotification('error', 'Predictions failed to load', '', error);
    } finally {
      setLoadingPredictions(false);
    }
  };

  // Predictions can only be fetched when we know their ids
  useEffect(() => {
    if (annotationBundles.length) {
      fetchPredictions();
    }
  }, [annotationBundles]);

  const columns: ColumnsType<TableRowData> = [
    {
      title: 'Intent',
      dataIndex: 'intent',
      key: 'intent',
      sorter: (a, b) => String(a).localeCompare(String(b)),
      render: (value, row) => (
        <Group noWrap>
          <MultiTags type="intent" tags={[value]}/>
          <Tooltip title="Open in Annotations">
            <Link
              to={`/${customer.path}/annotations/${row.id}?taskId=${taskId}&split=DATA_SPLIT_UNSPECIFIED&target=QA`}
              target="_blank"
            >
              <img src={externalLinkIcon} alt="Open link"/>
            </Link>
          </Tooltip>
        </Group>
      ),
    },
    {
      title: 'True Positive',
      width: 180,
      dataIndex: 'truePositive',
      key: 'truePositive',
      sorter: (a, b) => b.truePositive - a.truePositive,
      render: (value, row) => (
        <span className={styles.linkLike}>
          <NavLink to={`../score-view/${taskId}/${row.id}?confusionTypeFilter=TP`}>{value}</NavLink>
        </span>
      ),
    },
    {
      title: 'True Negative',
      width: 180,
      dataIndex: 'trueNegative',
      key: 'trueNegative',
      sorter: (a, b) => b.trueNegative - a.trueNegative,
      render: (value, row) => (
        <span className={styles.linkLike}>
          <NavLink to={`../score-view/${taskId}/${row.id}?confusionTypeFilter=TN`}>{value}</NavLink>
        </span>
      ),
    },
    {
      title: 'Total Correct',
      width: 180,
      dataIndex: 'correct',
      key: 'correct',
      sorter: (a, b) => b.correct - a.correct,
      render: (value, row) => (
        <span className={styles.linkLike}>
          <NavLink to={`../score-view/${taskId}/${row.id}?confusionTypeFilter=CORRECT`}>{value}</NavLink>
        </span>
      ),
    },
    {
      title: 'False Positive',
      width: 180,
      dataIndex: 'falsePositive',
      key: 'falsePositive',
      sorter: (a, b) => b.falsePositive - a.falsePositive,
      render: (value, row) => (
        <span className={styles.linkLike}>
          <NavLink to={`../score-view/${taskId}/${row.id}?confusionTypeFilter=FP`}>{value}</NavLink>
        </span>
      ),
    },
    {
      title: 'False Negative',
      width: 180,
      dataIndex: 'falseNegative',
      key: 'falseNegative',
      sorter: (a, b) => b.falseNegative - a.falseNegative,
      render: (value, row) => (
        <span className={styles.linkLike}>
          <NavLink to={`../score-view/${taskId}/${row.id}?confusionTypeFilter=FN`}>{value}</NavLink>
        </span>
      ),
    },
    {
      title: 'Total Incorrect',
      width: 180,
      dataIndex: 'incorrect',
      key: 'incorrect',
      sorter: (a, b) => b.incorrect - a.incorrect,
      render: (value, row) => (
        <span className={styles.linkLike}>
          <NavLink to={`../score-view/${taskId}/${row.id}?confusionTypeFilter=INCORRECT`}>{value}</NavLink>
        </span>
      ),
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Precision</span>
          <Tooltip
            placement="rightTop"
            title={() => (
              <div>
                <b>Precision</b>
                <div>{PRECISION_TOOLTIP_COPY}</div>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      width: 180,
      dataIndex: 'precision',
      key: 'precision',
      sorter: (a, b) => getPrecisionPercentage(b.truePositive, b.falsePositive) - getPrecisionPercentage(a.truePositive, a.falsePositive),
      render: (value, row) => (
        <span>
          {(row.truePositive === 0) || ((row.truePositive + row.falsePositive) < 30) ? 'N/A' : `${getPrecisionPercentage(row.truePositive, row.falsePositive)}%`}
        </span>
      ),
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>All Score</span>
          <Tooltip
            placement="rightTop"
            title={() => (
              <div>
                <b>All Score</b>
                <div>{ALL_SCORE_TOOLTIP_COPY}</div>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      width: 180,
      dataIndex: 'recall',
      key: 'recall',
      sorter: (a, b) => getPrecisionPercentage(b.truePositive, b.falseNegative) - getPrecisionPercentage(a.truePositive, a.falseNegative),
      render: (value, row) => (
        <span>
          {(row.truePositive === 0) || ((row.truePositive + row.falseNegative) < 30) ? 'N/A' : `${getPrecisionPercentage(row.truePositive, row.falseNegative)}%`}
        </span>
      ),
    },
  ];

  return (
    <div style={{
      width: '100%',
      flex: 1,
    }}
    >
      <Group mb="lg" position="right">
        <>
          {filtersNode && filtersNode}
        </>
      </Group>
      <Table
        loading={{
          spinning: loadingPredictions || taskApiStatus === 'loading',
          indicator: <Loading />,
        }}
        columns={columns}
        rowKey={(row) => row.intent}
        dataSource={summaryResults}
        className={styles.summaryTable}
        pagination={{
        // Hide pagination - all concepts should be visible at once
          pageSize: 1000,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
}
