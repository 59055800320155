import { openNotification } from 'components/Notification';
import { useCallback, useState, useEffect } from 'react';
import { useCustomerProfile } from './useCustomerParams';

const DEFAULT_LIST_SIZE = 500;

// General paginated list API type.
type ListFunc<
  Req extends {parent?: string, pageSize?: number, pageToken?: string},
  Resp extends {nextPageToken?: string}
> = (request: Req) => Promise<Resp>;

/**
 * Hook to list resources data using a general paginated grpc list API with auto loading.
 *
 * @params listFunc: list function, request should contain parent, pageSize, and pageToken,
 * response should contain nextPageToken.
 * @params request: the initial request which may contain necessary filters.
 * @params key: the key to retrieve the resources array from the response.
 */
export function useListResources<
  T,
  Req extends {parent?: string, pageSize?: number, pageToken?: string},
  Resp extends {nextPageToken?: string}
>(
  listFunc: ListFunc<Req, Resp>,
  req: Req,
  key: string,
): [
  setPageInfo: (p: { page: number; pageSize: number; }) => void,
  data: T[],
  loading: boolean,
] {
  const customerProfile = useCustomerProfile();
  const [{ page, pageSize }, setPageInfo] = useState<{ page: number, pageSize: number }>({ page: 1, pageSize: 10 });
  const [data, setData] = useState<T[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const listNextPage = useCallback(async () => {
    const request: Req = {
      ...req,
      parent: customerProfile,
      pageToken: nextPageToken,
    };
    if (!req.pageSize) {
      request.pageSize = DEFAULT_LIST_SIZE;
    }
    setLoading(true);
    let response: Resp;
    try {
      response = await listFunc(request);
    } catch (err) {
      openNotification('error', `Failed to list ${key}`, undefined, err);
    } finally {
      setLoading(false);
    }
    if (response) {
      setData([...data, ...response[key]]);
      setNextPageToken(response?.nextPageToken);
    }
  }, [customerProfile, nextPageToken, req, data]);

  useEffect(() => {
    if (customerProfile && listFunc && req) {
      setData([]);
      setNextPageToken('');
      listNextPage();
    }
  }, [customerProfile, req]);

  useEffect(() => {
    if (page >= data.length / pageSize && nextPageToken.length > 0) {
      listNextPage();
    }
  }, [page, pageSize, data, nextPageToken]);

  return [setPageInfo, data, loading];
}
