import React from 'react';

export const PlusIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 5.5H4.5V6.5C4.5 6.63261 4.55268 6.75979 4.64645 6.85355C4.74021 6.94732 4.86739 7 5 7C5.13261 7 5.25979 6.94732 5.35355 6.85355C5.44732 6.75979 5.5 6.63261 5.5 6.5V5.5H6.5C6.63261 5.5 6.75979 5.44732 6.85355 5.35355C6.94732 5.25979 7 5.13261 7 5C7 4.86739 6.94732 4.74021 6.85355 4.64645C6.75979 4.55268 6.63261 4.5 6.5 4.5H5.5V3.5C5.5 3.36739 5.44732 3.24021 5.35355 3.14645C5.25979 3.05268 5.13261 3 5 3C4.86739 3 4.74021 3.05268 4.64645 3.14645C4.55268 3.24021 4.5 3.36739 4.5 3.5V4.5H3.5C3.36739 4.5 3.24021 4.55268 3.14645 4.64645C3.05268 4.74021 3 4.86739 3 5C3 5.13261 3.05268 5.25979 3.14645 5.35355C3.24021 5.44732 3.36739 5.5 3.5 5.5ZM9.5 0L0.5 0C0.367392 0 0.240215 0.0526784 0.146447 0.146447C0.0526784 0.240215 0 0.367392 0 0.5L0 9.5C0 9.63261 0.0526784 9.75979 0.146447 9.85355C0.240215 9.94732 0.367392 10 0.5 10L9.5 10C9.63261 10 9.75979 9.94732 9.85355 9.85355C9.94732 9.75979 10 9.63261 10 9.5L10 0.5C10 0.367392 9.94732 0.240215 9.85355 0.146447C9.75979 0.0526784 9.63261 0 9.5 0ZM9 9L1 9L1 1L9 1L9 9Z" fill="#212121"/>
  </svg>
);

export const MinusIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 5.5H6.5C6.63261 5.5 6.75979 5.44732 6.85355 5.35355C6.94732 5.25979 7 5.13261 7 5C7 4.86739 6.94732 4.74021 6.85355 4.64645C6.75979 4.55268 6.63261 4.5 6.5 4.5H3.5C3.36739 4.5 3.24021 4.55268 3.14645 4.64645C3.05268 4.74021 3 4.86739 3 5C3 5.13261 3.05268 5.25979 3.14645 5.35355C3.24021 5.44732 3.36739 5.5 3.5 5.5ZM9.5 0L0.5 0C0.367392 0 0.240215 0.0526784 0.146447 0.146447C0.0526784 0.240215 0 0.367392 0 0.5L0 9.5C0 9.63261 0.0526784 9.75979 0.146447 9.85355C0.240215 9.94732 0.367392 10 0.5 10L9.5 10C9.63261 10 9.75979 9.94732 9.85355 9.85355C9.94732 9.75979 10 9.63261 10 9.5L10 0.5C10 0.367392 9.94732 0.240215 9.85355 0.146447C9.75979 0.0526784 9.63261 0 9.5 0ZM9 9L1 9L1 1L9 1L9 9Z" fill="#212121"/>
  </svg>
);

export const ExpandIcon = () => (
  <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.80477 7C7.77759 6.93923 7.7404 6.88346 7.69476 6.835L5.37977 4.5C5.28411 4.41809 5.16108 4.37528 5.03524 4.38014C4.9094 4.385 4.79003 4.43717 4.70098 4.52622C4.61194 4.61527 4.55977 4.73464 4.55491 4.86047C4.55005 4.98631 4.59285 5.10935 4.67477 5.205L6.13477 6.665L2.63477 6.665C2.23694 6.665 1.85541 6.50696 1.57411 6.22566C1.2928 5.94436 1.13477 5.56282 1.13477 5.165L1.13477 0.5C1.13477 0.367392 1.08209 0.240215 0.988319 0.146447C0.894551 0.0526784 0.767374 0 0.634766 0C0.502157 0 0.374981 0.0526784 0.281212 0.146447C0.187444 0.240215 0.134766 0.367392 0.134766 0.5L0.134766 5.185C0.134766 5.84804 0.398158 6.48393 0.866999 6.95277C1.33584 7.42161 1.97172 7.685 2.63477 7.685L6.13477 7.685L4.67477 9.145C4.6279 9.19148 4.5907 9.24678 4.56532 9.30771C4.53994 9.36864 4.52687 9.43399 4.52687 9.5C4.52687 9.56601 4.53994 9.63136 4.56532 9.69229C4.5907 9.75322 4.6279 9.80852 4.67477 9.855C4.72149 9.90134 4.77689 9.938 4.83781 9.96289C4.89873 9.98777 4.96396 10.0004 5.02977 10C5.16094 9.99945 5.28663 9.94737 5.37977 9.855L7.69476 7.54C7.74208 7.4929 7.77948 7.43679 7.80477 7.375C7.85342 7.25473 7.85342 7.12027 7.80477 7Z" fill="#212121"/>
  </svg>
);
