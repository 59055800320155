import React, { ReactElement, useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Button, Group, SegmentedControl } from '@mantine/core';
import {
  Concept,
  ConceptConceptType,
  ConceptState,
  ConversationOutcomeConversationOutcomeType as OutcomeType,
  UpdateConceptRequest,
  CreateConceptRequest,
  IntentIntentType,
  ConceptConceptTagType,
  SharedConceptConfigDeploymentType,
  ConceptConceptSource,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import {
  selectConceptsFactory,
  selectConceptsMapFactory,
  selectDemoConceptsFactory,
  selectSharedConceptsFactory,
} from 'store/concept/selectors';
import { isIntent } from 'components/TaxonomyTree';
import { ConversationOutcome, ConceptsList, Intent, IntentFilters } from 'components/ConceptsView';
import { SimpleConceptEditor } from 'components/ConceptEditor';
import { listDemoConcepts, updateConcept, listSharedConcepts, createConcept } from 'store/concept/asyncThunks';
import { AddIntentModal, AddConceptModalWithLibrary, ModalType, SimpleAddConceptModal } from 'components/AddConceptModal';
import { useIsSharedLibrary, useIsDemoProfile, useCustomerParams, useCustomerProfile, SHARED_LIBRARY_PROFILE } from 'hooks/useCustomerParams';
import { UserContext } from 'context/UserContext';
import { IntentConceptEditor } from 'components/ConceptEditor/IntentConceptEditor/IntentConceptEditor';
import { getId } from 'common/resourceName';
import { toTitleCase } from 'utils';
import ConceptTypeTag from 'components/ConceptTypeTag';
import { ConceptApi } from 'services/conceptApi';
import { ProdIntent } from 'store/modelBuilder/state';
import { selectLoadProdTaxonomiesStatus, selectProdIntents } from 'store/modelBuilder/selectors';
import { ApiStatus } from 'store/types';
import styles from './styles.module.scss';
import ExportConceptModal from './ExportConceptModal';

export type CategoryType = 'call-flow' | 'cross-customer';

// Exclude unspecified, call flow and cross customer tags
export const HIDDEN_TAGS = [
  ConceptConceptTagType.CALL_FLOW,
  ConceptConceptTagType.CROSS_CUSTOMER_LABELING,
  ConceptConceptTagType.CONCEPT_TAG_TYPE_UNSPECIFIED,
];

export const AVAILABLE_TAGS = Object.keys(ConceptConceptTagType).filter((key) => {
  const conceptTag = key as ConceptConceptTagType;
  return !HIDDEN_TAGS.includes(conceptTag);
});

export function TaxonomyTab(): ReactElement {
  const customer = useCustomerParams();
  const conceptType = useConceptType();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listSharedConcepts());
  }, [dispatch]);
  const currentUser = useContext(UserContext);
  const profile = useCustomerProfile();
  const concepts = useSelector<Concept[]>(selectConceptsFactory(conceptType)).filter((concept) => concept.state !== ConceptState.DEPRECATED);
  const conceptsMap = useSelector<Map<string, Concept>>(selectConceptsMapFactory(conceptType));
  const libraryConcepts = useSelector<Concept[]>(selectSharedConceptsFactory(conceptType))
    .filter(libraryConceptsFilter);
  const isSharedLibrary = useIsSharedLibrary();
  const isDemoProfile = useIsDemoProfile();
  useEffect(() => {
    if (isDemoProfile) {
      dispatch(listDemoConcepts());
    }
  }, [isDemoProfile]);
  const demoConcepts = useSelector<Concept[]>(selectDemoConceptsFactory(conceptType))
    .filter(libraryConceptsFilter);

  const isSharedLibraryProfile = profile === SHARED_LIBRARY_PROFILE;

  const [drivers, stages] = useMemo(() => {
    const drivers = [];
    const stages = [];
    const intents = [];

    concepts.forEach((concept) => {
      switch (concept.intent?.intentType) {
        case IntentIntentType.CONVERSATION_DRIVER:
          drivers.push(concept);
          break;
        case IntentIntentType.STAGE:
          stages.push(concept);
          break;
        case IntentIntentType.AGENT_INTENT:
          intents.push(concept);
          break;
        case IntentIntentType.VISITOR_INTENT:
          intents.push(concept);
          break;
        default:
          break;
      }
    });

    return [drivers, stages, intents];
  }, [concepts]);

  const [focusedConcept, setFocusedConcept] = useState<Concept>(null);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [parentConcept, setParentConcept] = useState<Concept>(null);
  // Set driver when adding an intent.
  const [intentDriver, setIntentDriver] = useState<Concept>(null);

  // Intent filters
  const stringFiltersFromStorage = sessionStorage.getItem('intentFilters');
  const defaultFilters: IntentFilters = stringFiltersFromStorage && JSON.parse(stringFiltersFromStorage);
  const [filters, setFilters] = useState<IntentFilters>({
    isOOBCapable: defaultFilters?.isOOBCapable ?? false,
    isCrossCustomer: defaultFilters?.isCrossCustomer ?? false,
    isInactive: defaultFilters?.isInactive ?? true,
  });

  const [showExportContentModal, setShowExportContentModal] = useState<boolean>(false);
  const prodIntents = useSelector<ProdIntent[]>(selectProdIntents);
  const prodIntentsStatus = useSelector<ApiStatus>(selectLoadProdTaxonomiesStatus);
  const prodIntentsLoading = prodIntentsStatus === 'loading';
  const filterTagsSet = new Set(filters?.tags);

  // Utilize session storage to preserve filter state between renders
  useEffect(() => {
    sessionStorage.setItem('intentFilters', JSON.stringify(filters));
  }, [filters]);

  const handleAddConcept = useCallback((modalType: ModalType, parent?: Concept, driver?: Concept) => {
    setModalType(modalType);
    setParentConcept(parent);
    setIntentDriver(driver);
  }, []);

  const handleCreateConcept = useCallback(async (concept: Concept, deploymentType?: SharedConceptConfigDeploymentType, conceptId?: string) => {
    const id = conceptId || uuid();
    let conceptSource = ConceptConceptSource.CUSTOM;
    if ([SharedConceptConfigDeploymentType.FINETUNED, SharedConceptConfigDeploymentType.GENERIC].includes(deploymentType)) {
      conceptSource = ConceptConceptSource.SHARED;
    }
    const request: CreateConceptRequest = {
      parent: profile,
      conceptId: id,
      userResourceName: currentUser?.name,
      concept: {
        ...concept,
        conceptSource,
        name: `${profile}/concepts/${id}`,
        usecaseId: customer.usecaseId,
        languageCode: customer.languageCode,
      },
    };

    const response = await dispatch(createConcept(request));

    if (response.payload && deploymentType && deploymentType !== SharedConceptConfigDeploymentType.DEPLOYMENT_TYPE_UNSPECIFIED) {
      // Update shared concept config
      await ConceptApi.batchUpsertSharedConceptConfigs({
        parent: profile,
        concepts: [`${profile}/concepts/${id}`],
        config: {
          deploymentType,
        },
      });
    }
  }, [dispatch, profile, currentUser, isSharedLibraryProfile]);

  const handleCreateConceptFromTitle = useCallback((conceptTitle: string, parent: Concept = parentConcept) => {
    let rawConcept: Concept;
    switch (conceptType) {
      case ConceptConceptType.ENTITY:
        rawConcept = {
          entity: {},
        };
        break;
      case ConceptConceptType.CONVERSATION_OUTCOME:
        rawConcept = {
          conversationOutcome: {
            conversationOutcomeType: OutcomeType.AGENT_CONVERSATION_OUTCOME,
          },
        };
        break;
      case ConceptConceptType.INTENT:
        rawConcept = {
          intent: {
            intentType: parent ? IntentIntentType.STAGE : IntentIntentType.CONVERSATION_DRIVER,
            parentConversationDriverNames: parent && parent.name ? [getId('concept', parent.name)] : [],
          },
        };
        break;
      case ConceptConceptType.SUMMARIZATION:
        rawConcept = {
          summarization: {},
        };
        break;
      default:
        rawConcept = {};
    }
    const concept: Concept = {
      ...rawConcept,
      conceptTitle,
      conceptType,
      description: '',
      labelingGuideline: '',
      state: ConceptState.CREATED,
      usecaseId: customer.usecaseId,
      languageCode: customer.languageCode,
    };
    handleCreateConcept(concept);
  }, [dispatch, conceptType, parentConcept]);

  const handleUpdateConcept = useCallback((concept: Concept, updateMask: string) => {
    const request: UpdateConceptRequest = {
      concept,
      updateMask,
      userResourceName: currentUser?.name,
    };
    dispatch(updateConcept(request));
  }, [dispatch, currentUser]);

  const useSimpleConceptEditor = conceptType === ConceptConceptType.CONVERSATION_OUTCOME
    || conceptType === ConceptConceptType.ENTITY
    || conceptType === ConceptConceptType.SUMMARIZATION
    || focusedConcept?.intent?.intentType === IntentIntentType.CONVERSATION_DRIVER;

  const simpleModalConceptType = () => {
    if (conceptType === ConceptConceptType.CONVERSATION_OUTCOME) {
      return 'outcome';
    }
    if (conceptType === ConceptConceptType.INTENT) {
      if (!parentConcept) {
        return 'driver';
      }
      if (parentConcept.intent.intentType === IntentIntentType.CONVERSATION_DRIVER) {
        return 'stage';
      }
    }
    // eslint-disable-next-line consistent-return
    return undefined;
  };

  const libraryModalConceptType = () => {
    switch (conceptType) {
      case ConceptConceptType.CONVERSATION_OUTCOME:
        return 'outcomeValue';
      case ConceptConceptType.ENTITY:
        return 'entity';
      case ConceptConceptType.INTENT:
        return 'intent';
      case ConceptConceptType.SUMMARIZATION:
        return 'summarization';
      default:
        // eslint-disable-next-line consistent-return
        return undefined;
    }
  };

  // Add or remove tag filter
  const handleToggleTag = useCallback((tag) => {
    const nextTags = filters.tags || [];
    const tagIndex = nextTags.findIndex((t) => t === tag);

    if (tagIndex === -1) {
      nextTags.push(tag);
    } else {
      nextTags.splice(tagIndex, 1);
    }

    setFilters({
      ...filters,
      tags: nextTags,
    });
  }, [filters]);

  const isFilterEmpty = useMemo(() => (filters.tags || []).every((tag) => !tag), [filters]);

  return (
    <>
      {conceptType === ConceptConceptType.INTENT && (
        <Group position="apart" mb="lg">
          <Group>
            <SegmentedControl
              value={filters.type || 'both'}
              onChange={(value) => setFilters({
                ...filters,
                type: value === 'both' ? undefined : value as IntentIntentType,
              })}
              data={[
                { label: 'Both', value: 'both' },
                { label: 'Visitor', value: IntentIntentType.VISITOR_INTENT },
                { label: 'Agent', value: IntentIntentType.AGENT_INTENT },
              ]}
            />
          </Group>
          <Group>
            {focusedConcept && (
              <Button
                variant="light"
                color="orange"
                onClick={() => setShowExportContentModal(true)}
              >
                Export to Dialogflow
              </Button>
            )}
            {
              conceptType === ConceptConceptType.INTENT && (
                <Button
                  onClick={() => setModalType('intent')}
                >
                  Add new intent
                </Button>
              )
            }
          </Group>
        </Group>
      )}
      <Group spacing="xs" mb="lg">
        {isSharedLibrary && (
          <>
            <ConceptTypeTag
              color="blue"
              variant={isFilterEmpty ? 'filled' : 'outline'}
              title="Show all"
              onClick={() => setFilters({
                type: filters.type,
              })}
            />
            {AVAILABLE_TAGS
              .map((tag) => tag as ConceptConceptTagType)
              .filter((tag) => tag !== ConceptConceptTagType.CONCEPT_TAG_TYPE_UNSPECIFIED)
              .map((tag) => (
                <ConceptTypeTag key={tag} color="blue" variant={filterTagsSet.has(tag) ? 'filled' : 'outline'} title={toTitleCase(tag)} onClick={() => handleToggleTag(tag)} />
              ))}
          </>
        )}
      </Group>
      <div className={styles.conceptContent}>
        <div className={styles.list}>
          {conceptType === ConceptConceptType.CONVERSATION_OUTCOME && (
            <ConversationOutcome
              data={concepts}
              onFocus={setFocusedConcept}
              onAdd={handleAddConcept}
            />
          )}
          {conceptType === ConceptConceptType.ENTITY && (
            <ConceptsList
              name="Entity"
              data={concepts}
              onFocus={setFocusedConcept}
              onAdd={handleAddConcept}
              showTags={isSharedLibrary}
            />
          )}
          {conceptType === ConceptConceptType.INTENT && (
            <Intent
              filters={filters}
              data={concepts}
              prodIntents={prodIntents}
              prodIntentsLoading={prodIntentsLoading}
              onFocus={setFocusedConcept}
              onAdd={handleAddConcept}
              showTags={isSharedLibrary}
              setFilters={setFilters}
            />
          )}
          {conceptType === ConceptConceptType.SUMMARIZATION && (
            <ConceptsList
              name="Summarization"
              data={concepts}
              onFocus={setFocusedConcept}
              onAdd={handleAddConcept}
              showTags={isSharedLibrary}
            />
          )}
        </div>
        <div className={styles.editor}>
          {useSimpleConceptEditor && (
            <SimpleConceptEditor
              concept={focusedConcept}
              onUpdate={handleUpdateConcept}
              showTags={isSharedLibrary}
            />
          )}
          {!useSimpleConceptEditor && (
            <IntentConceptEditor
              drivers={drivers}
              concept={focusedConcept}
              onUpdate={handleUpdateConcept}
              showTags={isSharedLibrary}
              setShowExportContentModal={setShowExportContentModal}
            />
          )}
        </div>
      </div>
      <SimpleAddConceptModal
        opened={modalType === 'simple'}
        conceptType={simpleModalConceptType()}
        onClose={() => setModalType(null)}
        onAdd={handleCreateConceptFromTitle}
      />
      <AddIntentModal
        opened={modalType === 'intent'}
        onClose={() => setModalType(null)}
        handleCreateConcept={handleCreateConcept}
        conceptsMap={conceptsMap}
        drivers={drivers}
        stages={stages}
        intents={isDemoProfile ? demoConcepts : libraryConcepts}
      />
      <AddConceptModalWithLibrary
        conceptType={libraryModalConceptType()}
        parent={parentConcept}
        driver={intentDriver}
        libraryConcepts={isDemoProfile ? demoConcepts : libraryConcepts}
        onAdd={handleCreateConcept}
        onClose={() => setModalType(null)}
        opened={modalType === 'library'}
        isSharedLibrary={isSharedLibrary}
      />
      <ExportConceptModal
        concept={focusedConcept}
        concepts={concepts}
        isOpen={showExportContentModal}
        onClose={() => setShowExportContentModal(false)}
      />
    </>
  );
}

function useConceptType(): ConceptConceptType {
  const location = useLocation();
  if (location.pathname.includes('conversation-outcome/taxonomy')) {
    return ConceptConceptType.CONVERSATION_OUTCOME;
  }
  if (location.pathname.includes('entity/taxonomy')) {
    return ConceptConceptType.ENTITY;
  }
  if (location.pathname.includes('intent/taxonomy')) {
    return ConceptConceptType.INTENT;
  }
  if (location.pathname.includes('summarization/taxonomy')) {
    return ConceptConceptType.SUMMARIZATION;
  }
  return ConceptConceptType.CONCEPT_TYPE_UNSPECIFIED;
}

function libraryConceptsFilter(concept: Concept): boolean {
  return concept.state !== ConceptState.DEPRECATED
    && (concept.conversationOutcome?.conversationOutcomeType === OutcomeType.POSSIBLE_OUTCOME
      || isIntent(concept)
      || concept.conceptType === ConceptConceptType.ENTITY
      || concept.conceptType === ConceptConceptType.SUMMARIZATION);
}
