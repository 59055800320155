import {
  AnnotationRawDataType,
  AnnotationValuePresenceType,
  AnnotationValueType,
  BinaryValueValue,
  MessageRawDataContextShown,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  RemoveTemporalAnnotationRequest,
  SaveTemporalAnnotationRequest,
  SaveTemporalAnnotationRequestRawDataValueTuple,
  SaveTemporalAnnotationResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { v4 as uuid } from 'uuid';

export interface TagData {
  id: string;
  title: string;
  type: TagTagType;
}

interface BaseConfig {
  setId: string;
  conversationId: string;
  messageId: string;
  tagData: TagData;
  conversationPositionNumber: number;
}

interface BaseStageConfig {
  setId: string;
  conversationId: string;
  stageBeginMessageId: string;
  stageEndMessageId: string;
  tagData: TagData;
  conversationPositionNumber: number;
}

const createStageRawDataValueTuple: (
  config: BaseStageConfig,
) => SaveTemporalAnnotationRequestRawDataValueTuple = (config) => {
  const {
    setId,
    conversationId,
    stageBeginMessageId,
    stageEndMessageId,
    tagData,
    conversationPositionNumber,
  } = config;
  const annotationId = uuid();
  return {
    annotationId,
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_MESSAGE,
      messageRawData: {
        conversationId,
        messageId: '0',
        v2ConversationId: conversationId,
        v2MessageId: '0',
        contextShown: MessageRawDataContextShown.FULL_CONVERSATION_SHOWN,
      },
      conversationPositionNumber,
    },
    value: {
      setId,
      presenceType: AnnotationValuePresenceType.PRESENCE,
      conversationStageValue: {
        stageConceptId: tagData.id,
        stageBeginMessageId,
        stageBeginSpanStart: 0,
        stageEndMessageId,
        stageEndSpanEnd: 0,
      },
      type: AnnotationValueType.TYPE_CONVERSATION_STAGE,
    },
    targetType: TargetType.MANUAL_ANALYSIS,
  };
};

const createRawDataValueTuple: (
  config: BaseConfig,
) => SaveTemporalAnnotationRequestRawDataValueTuple = (config) => {
  const { setId, conversationId, messageId, tagData, conversationPositionNumber } = config;
  const annotationId = uuid();
  return {
    annotationId,
    rawData: {
      type: AnnotationRawDataType.TYPE_ON_MESSAGE,
      messageRawData: {
        conversationId,
        messageId,
        v2ConversationId: conversationId,
        v2MessageId: messageId,
        contextShown: MessageRawDataContextShown.FULL_CONVERSATION_SHOWN,
      },
      conversationPositionNumber,
    },
    valueType: AnnotationValueType.TYPE_BINARY,
    value: {
      setId,
      presenceType: AnnotationValuePresenceType.PRESENCE,
      binaryValue: {
        value: BinaryValueValue.VALUE_POSITIVE,
        conceptId: tagData.id,
        conceptTitle: tagData.title,
      },
      type: AnnotationValueType.TYPE_BINARY,
    },
    targetType: TargetType.MANUAL_ANALYSIS,
  };
};

interface NewAnnotationConfig extends Omit<BaseConfig, 'tagData'> {
  taskName: string;
  tags: TagData[];
}

interface NewStageAnnotationConfig extends Omit<BaseStageConfig, 'tagData'> {
  taskName: string;
  tags: TagData[];
}

export const handleNewAnnotations: (
  config: NewAnnotationConfig,
) => Promise<SaveTemporalAnnotationResponse> = (config) => {
  const { taskName, setId, conversationId, messageId, tags, conversationPositionNumber } = config;
  const rawDataValueTuples = tags.map((tagData) => {
    if (tagData.type === TagTagType.STAGE) {
      return createStageRawDataValueTuple({
        setId,
        conversationId,
        stageBeginMessageId: messageId,
        stageEndMessageId: messageId,
        tagData,
        conversationPositionNumber,
      });
    }
    return createRawDataValueTuple({
      setId,
      conversationId,
      messageId,
      tagData,
      conversationPositionNumber,
    });
  });

  const newAnnotation: SaveTemporalAnnotationRequest = {
    taskName,
    rawDataValueTuples,
  };
  return LabelingTaskApi.saveTemporalAnnotation(newAnnotation);
};

export const handleNewStageAnnotations: (
  config: NewStageAnnotationConfig,
) => Promise<SaveTemporalAnnotationResponse> = (config) => {
  const {
    taskName,
    setId,
    conversationId,
    stageBeginMessageId,
    stageEndMessageId,
    tags,
    conversationPositionNumber,
  } = config;
  const rawDataValueTuples = tags.map((tagData) =>
    createStageRawDataValueTuple({
      setId,
      conversationId,
      stageBeginMessageId,
      stageEndMessageId,
      tagData,
      conversationPositionNumber,
    }));

  const newAnnotation: SaveTemporalAnnotationRequest = {
    taskName,
    rawDataValueTuples,
  };
  return LabelingTaskApi.saveTemporalAnnotation(newAnnotation);
};

export const handleDeleteAnnotation = ({ taskName, annotationIds }) => {
  const deletedAnnotation: RemoveTemporalAnnotationRequest = {
    taskName,
    annotationIds,
  };
  return LabelingTaskApi.removeTemporalAnnotation(deletedAnnotation);
};
