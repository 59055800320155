/**
 * This file contains configs and message templates for sending notifications externally.
 */

// ********** Notification for annotations ready for modeling *******************
const CHEERING_MESSAGES = [
  '\\( ﾟヮﾟ)/ Huzzah!',
  'I like my steak rare, but this job was well-done!',
  'Cowabunga!',
  'How `bout them hotdogs?',
  'Fly Parrot Fly!',
  'Awwwwwww yeah!',
];
const getRandomCheeringMessage = () =>
  CHEERING_MESSAGES[Math.floor(Math.random() * CHEERING_MESSAGES.length)];

/** The Slack Channel #studio-notification ID. */
export const STUDIO_NOTIFICATION_CHANNEL_ID = 'C0385UQNMQD';

/** Generate the message for annotations ready for modeling. */
export function modelReadyMessage(customer: string, user: string, link: string): string {
  return `<!subteam^S03DPTV3S2Z|ai-delivery> ${customer} is ready for modeling per ${user}!!\n${link}\n${getRandomCheeringMessage()}`;
}

/** Generate the message for ModelWizard model ready for deployment. */
export function wizardModelReadyMessage(customer: string, user: string, link: string): string {
  return `<!subteam^S03DPTV3S2Z|ai-delivery> Model Wizard has a model for ${customer} per ${user}!!\n${link}\n${getRandomCheeringMessage()}`;
}
