import { SegmentedControl, Select, Button, Loader, Space } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { ConfusionType, ModelArtifact, ModelArtifactArtifactType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { ModelWorkflow } from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { WorkflowStatus } from '@cresta/web-client/dist/cresta/v1/studio/workflows/workflows.pb';
import { selectModelProjectById } from 'store/modelProject/selectors';
import { ModelProject } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useEvaluation } from 'hooks/useEvaluation';
import { selectAllConcepts } from 'store/concept/selectors';
import {
  selectEvaluationArtifact,
  selectEvaluationArtifactToCompare,
  selectGetModelArtifactsStatus,
} from 'store/modelArtifact/selectors';
import { ApiStatus } from 'store/types';
import { ModelEvaluationPredictions } from 'components/EvaluationPredictions/EvaluationPredictions';
import { ModelEvaluationSummary } from 'components/ModelEvaluationSummary';
import { clearComparedEvaluationArtifact } from 'store/modelArtifact/modelArtifactSlice';
import { getEvaluationArtifact, getEvaluationArtifactToCompare } from 'store/modelArtifact/asyncThunks';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';
import { selectServingModelUri } from 'store/modelBuilder/selectors';
import { listModelWorkflow } from 'store/modelWorkflow/asyncThunks';
import { toPairsIn } from 'lodash';
import { createLabelingTask } from 'store/labelingTask/asyncThunks';
import { UserContext } from 'context/UserContext';
import { getErrorMessage } from 'utils';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { showNotification } from '@mantine/notifications';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { ConfirmModal } from '../ConfirmModal';
import styles from './styles.module.scss';
import { QATaskAlert } from './QATaskAlert';
import { createMisclassificationQATask } from './createMisclassificationQATask';
import { attachQATaskIdToWorkflowName } from './utils';

type TableType = 'summary' | 'predictions'

interface Props {
  modelWorkflows: ModelWorkflow[];
}

export function EvaluationResultsDashboard({ modelWorkflows }: Props) {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const modelProject = useSelector<ModelProject>(selectModelProjectById(projectId));
  const prodModelUri = useSelector<string>(selectServingModelUri(modelProject?.projectType));
  const status = useSelector<ApiStatus>(selectGetModelArtifactsStatus);
  const allConcepts = useSelector(selectAllConcepts);
  const [workflowId, setWorkflowId] = useUrlParam<string>('workflowId');
  const workflow = modelWorkflows.find((w) => getId('modelWorkflow', w.name) === workflowId);
  const customerProfile = useCustomerProfile();
  const currentUser = useContext(UserContext);

  const qaTaskId = workflow?.manualWork?.qaTaskId;

  useEffect(() => {
    if (!modelWorkflows?.length) {
      return;
    }
    if (!workflowId) {
      const successWorkflows = modelWorkflows.filter((w) => w.abstractWorkflow.status === WorkflowStatus.WORKFLOW_SUCCEEDED);
      if (successWorkflows?.length > 0) {
        setWorkflowId(getId('modelWorkflow', successWorkflows[0].name));
      }
    } else {
      const modelWorkflow = modelWorkflows.find((w) => getId('modelWorkflow', w.name) === workflowId);
      if (modelWorkflow) {
        const output = modelWorkflow.trainIntentModelOutput?.modelbuilderOutputs.find((o) => o.modelArtifact.artifactType === ModelArtifactArtifactType.EVALUATION);
        if (output) {
          dispatch(getEvaluationArtifact(output.modelArtifact.name));
        }
      }
    }
  }, [workflowId, modelWorkflows]);
  const [compareWorkflowId, setCompareWorkflowId] = useUrlParam<string>('compareTo', '');
  useEffect(() => {
    if (compareWorkflowId && compareWorkflowId !== 'prod' && modelWorkflows?.length > 0) {
      const modelWorkflow = modelWorkflows.find((w) => getId('modelWorkflow', w.name) === compareWorkflowId);
      if (modelWorkflow) {
        const output = modelWorkflow.trainIntentModelOutput?.modelbuilderOutputs.find((o) => o.modelArtifact.artifactType === ModelArtifactArtifactType.EVALUATION);
        if (output) {
          dispatch(getEvaluationArtifactToCompare(output.modelArtifact.name));
          return;
        }
      }
    }
    dispatch(clearComparedEvaluationArtifact());
  }, [compareWorkflowId, modelWorkflows]);
  const [table, setTable] = useState<TableType>('summary');
  const [filterIntent, setFilterIntent] = useState<string>('All');
  const [predictionType, setPredictionType] = useState<ConfusionType | 'All'>('All');
  const [evaluateType, setEvaluateType] = useState<'Train' | 'Test'>('Test');
  const [opened, setOpened] = useState<boolean>(false);

  const handleTableTypeChange = useCallback((type: TableType) => {
    setTable(type);
  }, [setTable]);

  const modelArtifact = useSelector<ModelArtifact>(selectEvaluationArtifact);
  const testDatasetUri = useMemo(() => modelArtifact?.evaluationArtifact?.inputs?.testDatasets[0]?.uri || '', [modelArtifact]);
  const comparedEvaluationArtifact = useSelector<ModelArtifact>(selectEvaluationArtifactToCompare);
  const modelUri = useMemo(() => {
    if (compareWorkflowId === 'prod') {
      return prodModelUri;
    }
    return comparedEvaluationArtifact?.evaluationArtifact?.inputs?.modelUri || '';
  }, [comparedEvaluationArtifact, compareWorkflowId, prodModelUri]);
  const [evaluation, evaluationStatus] = useEvaluation(Number(projectId), modelUri, testDatasetUri);

  const modelBuilderLink = useMemo(() => {
    const taskId1 = getId('modelArtifact', modelArtifact?.name);
    const taskId2 = getId('modelArtifact', comparedEvaluationArtifact?.name);
    return `/${path}/model-builder?project=${projectId}&tab=EVALUATION&taskIds=||${taskId1},${taskId2}`;
  }, [modelArtifact, comparedEvaluationArtifact, projectId]);

  const modelWorkflowOptions = useMemo(
    () => modelWorkflows
      .filter((modelWorkflow) => modelWorkflow.abstractWorkflow?.status === WorkflowStatus.WORKFLOW_SUCCEEDED)
      .map((modelWorkflow) => ({
        value: getId('modelWorkflow', modelWorkflow?.name),
        label: modelWorkflow?.trainIntentModelOutput?.modelUrl,
      })),
    [modelWorkflows],
  );

  const compareModelWorkflowOptions = useMemo(
    () => [
      { value: 'prod', label: 'Prod model' },
      ...modelWorkflowOptions
        .filter((option) => option.value !== workflowId),
      { value: '', label: 'None' },
    ],
    [modelWorkflowOptions, workflowId],
  );

  const intentOptions = useMemo(() => {
    const predictions = modelArtifact?.evaluationArtifact?.outputs.datasetPredictions;
    if (!predictions) {
      return ['All'];
    }
    if (evaluateType === 'Train') {
      return [...new Set(predictions.trainPredictions.map((p) => p.label)), 'All'];
    }
    if (evaluateType === 'Test') {
      return [...new Set(predictions.testPredictions.map((p) => p.label)), 'All'];
    }
    return ['All'];
  }, [evaluateType, modelArtifact]);

  const intentF1TableRows = useMemo(() => {
    const scores = modelArtifact?.evaluationArtifact?.outputs?.modelScores?.test;
    if (!scores) {
      return [];
    }
    const getConceptIdFromConceptTitle = (conceptTitle: string) => {
      const concept = allConcepts.find((c) => c.conceptTitle === conceptTitle);
      if (!concept) {
        return '';
      }
      return getId('concept', concept.name);
    };
    const intentData = toPairsIn(scores)
      .map((p) => ({
        conceptId: getConceptIdFromConceptTitle(p[0]),
        intent: p[0],
        f1: p[1].f1,
      })).filter((p) => p.intent !== 'weighted avg');
    return intentData;
  }, [modelArtifact]);

  const hasIntentsReadyForQA = useMemo(
    () =>
      // an intent is ready for QA if it has an F1 score >= 0.7
      intentF1TableRows.filter((p) => p.f1 >= 0.7).length > 0
    , [intentF1TableRows],
  );

  const onCreateMisclassificationQATask = async (conceptIds: string[]) => {
    const userId = getId('user', currentUser.id);
    const taskData = createMisclassificationQATask({
      conceptIds,
      customerProfile,
      usecaseId,
      languageCode,
      userId,
      modelUri: workflow?.trainIntentModelOutput?.modelUrl,
      projectType: modelProject?.projectType,
    });
    try {
      const res = await dispatch(createLabelingTask(taskData));
      const derivedLabelingTask: DerivedLabelingTask = res?.payload;
      const workflowName = workflow?.name;
      const qaTaskId = getId('labelingTask', derivedLabelingTask.labelingTask.name);
      await attachQATaskIdToWorkflowName(workflowName, qaTaskId);
      // need to refetch the workflows to get the updated QA task id
      dispatch(listModelWorkflow({
        parent: customerProfile,
        pageSize: 1000,
        filter: {
          usecaseId,
          languageCode,
        },
      }));
      showNotification({
        color: 'blue',
        message: 'Creating QA Task...',
      });
      return {
        success: true,
        message: 'Task created successfully',
      };
    } catch (e) {
      return {
        success: false,
        message: getErrorMessage(e),
      };
    }
  };

  return (
    <div className={styles.dashboard}>
      <div className={styles.dashboardTitle}>
        <div className={styles.title}>Evaluate</div>
        <Button onClick={() => setOpened(true)}>Ready for review</Button>
      </div>
      <div className={styles.dashboardSndTilte}>
        Model:&nbsp;&nbsp;
        <Select
          data={modelWorkflowOptions}
          value={workflowId}
          onChange={setWorkflowId}
          styles={{
            root: { width: '400px', height: 42, marginBottom: '8px' },
            input: { borderRadius: '8px', height: 42 },
          }}
        />
      </div>
      <div className={styles.tableDiv}>
        <div style={{ display: 'flex', alignItems: table === 'summary' && 'center', justifyContent: table !== 'summary' && 'space-between' }}>
          <SegmentedControl
            data={[
              { label: 'Summary', value: 'summary' },
              { label: 'Predictions', value: 'predictions' },
            ]}
            value={table}
            onChange={handleTableTypeChange}
            classNames={{
              label: styles.segmentedControlLabel,
              root: styles.segmentedControlRoot,
              controlActive: styles.segmentedControlActive,
            }}
          />
          {table === 'summary' && (
            <>
              <div className={styles.compare}>
                Compare with:
              </div>
              <Select
                defaultValue="none"
                data={compareModelWorkflowOptions}
                value={compareWorkflowId}
                onChange={setCompareWorkflowId}
                styles={{
                  root: { width: '400px', height: 42, marginBottom: '8px' },
                  input: { border: 'none', borderRadius: '8px', height: 42 },
                }}
              />
              {evaluationStatus === 'loading' && (
                <>
                  <Space w="md" />
                  <Loader size="sm" />
                  <Space w="xs" />
                  <span className={styles.statusMessage}>
                    Running eval... can take up to 5 mins
                  </span>
                </>
              )}
              {evaluationStatus === 'failed' && (
                <>
                  <Space w="md" />
                  <ExclamationCircleOutlined />
                  <Space w="xs" />
                  <span className={styles.statusMessage}>
                    Evaluation failed!
                  </span>
                </>
              )}
            </>
          )}
          {table === 'predictions' && (
            <div style={{ display: 'flex' }}>
              <Select
                searchable
                placeholder="Data Split"
                data={['Train', 'Test']}
                styles={{
                  root: { width: 160, marginRight: 20 },
                  input: { border: 'none', borderRadius: '8px' },
                }}
                value={evaluateType}
                onChange={(value: 'Train' | 'Test') => setEvaluateType(value)}
              />
              <Select
                searchable
                placeholder="All intents"
                nothingFound="No options"
                data={intentOptions}
                styles={{
                  root: { width: 160, marginRight: 20 },
                  input: { border: 'none', borderRadius: '8px' },
                }}
                value={filterIntent}
                onChange={(value) => setFilterIntent(value)}
              />
              <Select
                searchable
                placeholder="All status"
                nothingFound="No options"
                data={[
                  'All',
                  ConfusionType.FALSE_NEGATIVE,
                  ConfusionType.FALSE_POSITIVE,
                  ConfusionType.TRUE_NEGATIVE,
                  ConfusionType.TRUE_POSITIVE,
                ]}
                styles={{ root: { width: 160 }, input: { border: 'none', borderRadius: '8px' } }}
                onChange={(value: ConfusionType) => setPredictionType(value)}
                value={predictionType}
              />
            </div>
          )}
        </div>
        {table === 'summary' && hasIntentsReadyForQA && (
          <QATaskAlert
            intentF1TableRows={intentF1TableRows}
            onConfirm={onCreateMisclassificationQATask}
          />
        )}
        {
          table === 'summary' ? (
            <ModelEvaluationSummary
              qaTaskId={qaTaskId}
              modelScores={modelArtifact?.evaluationArtifact?.outputs.modelScores}
              compareModelScores={evaluation?.evaluationArtifact?.outputs.modelScores}
              selectedModelName={workflow?.trainIntentModelOutput?.modelUrl}
              comparedModelName={modelUri}
              modelBuilderLink={modelBuilderLink}
              loading={status === 'loading'}
            />
          )
            : (
              <ModelEvaluationPredictions
                predictions={modelArtifact?.evaluationArtifact?.outputs.datasetPredictions}
                filters={{
                  intent: filterIntent,
                  dataSplit: evaluateType,
                  confusionType: predictionType,
                }}
                loading={status === 'loading'}
              />
            )
        }
      </div>
      <ConfirmModal
        evaluateData={workflow}
        opened={opened}
        onCancel={() => setOpened(false)}
      />
    </div>
  );
}
