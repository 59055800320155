import React, { useContext, useEffect, useState } from 'react';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { TaggingPageContext } from 'pages/AnalysisWorkshop/TaggingPage/TaggingPageProvider';
import { showNotification } from '@mantine/notifications';
import { getId } from 'common/resourceName';
import { AnnotationApi } from 'services/annotationApi';
import { AnnotationValueType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';

interface Props {
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  tagId: string;
  removeTagIdFromTask: (tagId: string) => Promise<void>;
}

export const DeleteTagModal = (props: Props) => {
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const { isOpen, toggleOpen, tagId, removeTagIdFromTask } = props;
  const { taskName, removeAnnotationIds } = useContext(TaggingPageContext);
  const [annotationsIdsToDelete, setAnnotationsIdsToDelete] = useState([]);
  const [isLoading, toggleLoading] = useState(false);

  // get all TASK annotations (annotations from context is only for current conversation)
  // get all annotations with this tagName
  useEffect(() => {
    const getTaskAnnotationIds = async () => {
      if (isOpen) {
        toggleLoading(true);
        try {
          const res = await AnnotationApi.listAnnotations({
            parent: customerProfile,
            usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
            languageCode: customer.languageCode,
            taskId: getId('labelingTask', taskName),
          });
          const annotationIds = res.annotations.filter((annotation) => {
            // handle stage
            if (annotation.valueType === AnnotationValueType.TYPE_CONVERSATION_STAGE) {
              return annotation?.value?.conversationStageValue?.stageConceptId === tagId;
            } else {
              return annotation?.value?.binaryValue?.conceptId === tagId;
            }
          }).map((annotation) => getId('annotation', annotation.name));
          setAnnotationsIdsToDelete(annotationIds);
          toggleLoading(false);
        } catch (e) {
          console.error(e);
          showNotification({
            title: 'Error',
            message: 'Error fetching annotations for current task',
            color: 'red',
          });
          toggleLoading(false);
        }
      }
    };
    getTaskAnnotationIds();
  }, [isOpen]);

  const removeAllAnnotationsFromTask = async () => {
    await removeAnnotationIds(annotationsIdsToDelete);
  };

  const removeTagFromTask = async () => {
    await removeTagIdFromTask(tagId);
  };

  const handleProceed = async () => {
    try {
      await removeAllAnnotationsFromTask();
      await removeTagFromTask();
      toggleOpen(false);
    } catch (e) {
      console.error(e);
      showNotification({
        title: 'Error',
        message: 'There was an error removing tags from this task',
      });
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => toggleOpen(false)}
      title="Are you sure?"
    >
      <Stack>
        <Text>
          This tag will be removed from this task. <b>{annotationsIdsToDelete.length}</b> tagged instances in this task will be removed.
        </Text>
        <Group position="right">
          <Button
            onClick={() => {
              toggleOpen(false);
            }}
            variant="subtle"
          >Cancel
          </Button>
          <Button
            color="red"
            onClick={handleProceed}
            loading={isLoading}
          >
            Proceed
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
