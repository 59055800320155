import { FlowNodesCollection } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';

/** Class for stages tree chart */
export class FlowNodesTreeNode {
  public name: string = '';

  public count: number = 1;

  public children: FlowNodesTreeNode[] = [];

  public parent: FlowNodesTreeNode;

  public flowNodeCollection: FlowNodesCollection;

  constructor(stageNode: { nodeName: string, flowNode: FlowNodesCollection }, parent: FlowNodesTreeNode) {
    this.name = stageNode.nodeName;
    this.flowNodeCollection = stageNode.flowNode;
    this.parent = parent;
  }

  public static findNode(name: string, node: FlowNodesTreeNode): FlowNodesTreeNode | null {
    if (node?.name === name) {
      return node;
    }

    if (node?.children.length === 0) {
      return null;
    }

    let foundNode = null;
    node.children.forEach((childNode) => {
      foundNode = FlowNodesTreeNode.findNode(name, childNode);
    });

    return foundNode;
  }

  public getChildrenTotalCount() {
    return this.children.reduce((acc, childNode) => acc + childNode.count, 0);
  }

  public addChildOrIncreaseCount(stageNode: { nodeName: string, flowNode: FlowNodesCollection }): FlowNodesTreeNode {
    let child = this.children.find((childNode) => childNode.name === stageNode.nodeName);
    if (child) {
      child.increaseCount();
    } else {
      child = new FlowNodesTreeNode(stageNode, this);
      this.children.push(child);
    }

    return child;
  }

  public increaseCount() {
    this.count += 1;
  }

  public addStages(stageNodes: { nodeName: string, flowNode: FlowNodesCollection }[]) {
    let root: FlowNodesTreeNode = this;
    stageNodes.forEach((stage) => {
      root = root.addChildOrIncreaseCount(stage);
    });
  }

  public removeStages() {
    this.children = [];
  }

  public findRoot() {
    let node: FlowNodesTreeNode = this;
    while (node.parent) {
      node = node.parent;
    }
    return node;
  }

  // Returns an array of strings with nodes
  public getPath() {
    let node: FlowNodesTreeNode = this;
    const nodesList = [node];
    while (node.parent) {
      node = node.parent;
      nodesList.unshift(node);
    }
    return nodesList;
  }

  public removeNode() {
    this.parent.children = this.parent.children.filter((node) => node.name !== this.name);
  }
}
