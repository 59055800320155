import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { Annotation, BinaryValue, DataSplit, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import BinaryDecision from 'components/LabelingDecision/BinaryDecision/BinaryDecision';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import { GotoNextType } from 'pages/LabelingView';
import React, { useCallback } from 'react';

interface BinaryLabelingViewProps {
  concepts: Concept[];
  messages: Message[];
  context: MessageRawDataContextShown,
  setContext: (context: MessageRawDataContextShown) => void;
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  split: DataSplit;
  momentAnnotationType: MomentAnnotationType;
  targetType: TargetType;
}

export function BinaryLabelingView({
  concepts,
  messages,
  context,
  setContext,
  targetMessage,
  messageAnnotationsMap,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  createAnnotation,
  upsertAnnotations,
  split,
  momentAnnotationType,
  targetType,
} : BinaryLabelingViewProps) {
  const targetMessageId = targetMessage?.v2MessageId;
  const targetMessageAnnotations = messageAnnotationsMap.get(targetMessageId) || [];

  // Replace previous annotations with new annotations
  const submitAnnotations = useCallback((newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[]) => {
    upsertAnnotations(newAnnotations, 'message', targetMessageAnnotations);
  }, [targetType, momentAnnotationType, split, targetMessageAnnotations]);

  return (
    <div
      className={classNames([
        'labeling-view',
        'horizontal-layout',
      ])}
    >
      <div className="labeling-items-display">
        <LabelingItemsDisplay
          context={context}
          setContext={setContext}
          showContextButtonVisible
          messages={messages}
          targetMessageId={targetMessageId}
          setTargetMessage={setTargetMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          getConceptTitle={getConceptTitle}
          failedMessages={failedMessages}
        />
      </div>
      <div className="labeling-decision-panel">
        <BinaryDecision
          message={targetMessage}
          concepts={concepts}
          annotations={targetMessageAnnotations}
          createAnnotation={createAnnotation}
          submitAnnotations={submitAnnotations}
          getConceptTitle={getConceptTitle}
          contextShown={context}
          showUnsureButton
          predictionLabeling={false}
        />
      </div>
    </div>
  );
}
