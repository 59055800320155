export const refineExamplesHintCopy = {
  title: 'Selected examples often need quick revisions',
  listItems: [
    'Complete a broken sentence',
    'Correct obvious grammar mistakes',
    'Remove irrelevant parts, especially the transcription errors',
  ],
};

export const goodExamplesHintCopy = {
  title: 'What makes good examples?',
  listItems: [
    'Simple and concise',
    'Punctuation matters. Do not omit “?”, “.”, “!”',
    'When choosing from the AI examples, choose those “novel” ones compared to the rest',
    'Don’t be afraid of making edits and trying things out!',
  ],
};
