import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { selectApiStatus, selectManualAnalysisLabelingTasks } from 'store/labelingTask/selectors';
import { selectUsers } from 'store/user/selectors';
import { getId } from 'common/resourceName';
import { User } from 'types';
import { TASK_STATUS_MAP } from 'constants/labeling';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { AbstractTask, TaskVisibility } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { createConfirm } from 'components/ConfirmModal';
import { fetchLabelingTasks, updateAbstractTask } from 'store/labelingTask/asyncThunks';
import { ApiStatus } from 'store/types';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { getAssigneeOptions, getFilteredTasks, getStatusOptionsFromMap } from './utils';
import { TasksSummary as TasksSummaryView } from './TasksSummary';

export const TasksSummary = () => {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const derivedLabelingTasks = useSelector(selectManualAnalysisLabelingTasks);
  const [newTaskTitle, setNewTaskTitle] = useState<string>(null);
  const [assigneeFilterValue, setAssigneeFilterValue] = useState<string>(null);
  const [statusFilterValue, setStatusFilterValue] = useState<string>(null);
  const [isModalOpen, toggleModal] = useState(false);
  const [isShowingArchived, toggleShowArchived] = useState(false);

  const users = useSelector(selectUsers).map((user) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });

  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  useEffect(() => {
    dispatch(fetchLabelingTasks({
      parent: customerProfile,
      usecaseId: customer.usecaseId,
      languageCode: customer.languageCode,
    }));
  }, []);

  const assigneeSelectData = getAssigneeOptions(users);

  const statusValues = getStatusOptionsFromMap(TASK_STATUS_MAP);

  const filteredTasks = useMemo(() => getFilteredTasks(derivedLabelingTasks, statusFilterValue, assigneeFilterValue, isShowingArchived), [derivedLabelingTasks, assigneeFilterValue, statusFilterValue, isShowingArchived]);

  const updateTaskVisibility = useCallback(async (abstractTask: AbstractTask, visibility: TaskVisibility) => {
    if (visibility === TaskVisibility.INVISIBLE) {
      const confirmArchive = await createConfirm<boolean>({
        title: 'Are you sure you want to archive this task?',
        content: '',
        buttons: [
          {
            text: 'Yes',
            value: true,
          },
          {
            text: 'Cancel',
            buttonProps: {
              variant: 'subtle',
            },
            value: false,
          },
        ],
      });

      if (!confirmArchive) return;
    }

    const task: AbstractTask = {
      ...abstractTask,
      visibility,
    };
    dispatch(updateAbstractTask({ abstractTask: task, updateMask: 'abstractTask.visibility' }));
  }, [dispatch]);

  const handleTaskArchive = (task: AbstractTask) => {
    updateTaskVisibility(task, TaskVisibility.INVISIBLE);
  };
  const handleTaskUnarchive = (task: AbstractTask) => {
    updateTaskVisibility(task, TaskVisibility.VISIBLE);
  };

  const updateAssignee = useCallback(
    (abstractTask: AbstractTask) => dispatch(updateAbstractTask({ abstractTask, updateMask: 'abstractTask.assigneeUserId' })),
    [dispatch],
  );

  return (
    <TasksSummaryView
      isShowingArchived={isShowingArchived}
      handleArchiveToggle={() => toggleShowArchived((val) => !val)}
      assigneeSelectData={assigneeSelectData}
      onAssigneeChange={(assigneeId) => setAssigneeFilterValue(assigneeId)}
      handleNewAnalysisBtnClick={() => toggleModal(true)}
      isNewAnalysisModalOpen={isModalOpen}
      newTaskTitle={newTaskTitle}
      onTaskTitleChange={(e) => setNewTaskTitle(e.target.value)}
      onModalClose={() => toggleModal(false)}
      tasks={filteredTasks}
      users={users}
      isLoading={taskApiStatus === 'loading'}
      handleTaskArchive={handleTaskArchive}
      handleTaskUnarchive={handleTaskUnarchive}
      statusSelectData={statusValues}
      onStatusChange={(status) => setStatusFilterValue(status)}
      updateAssignee={updateAssignee}
    />
  );
};
