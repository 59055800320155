import { createSlice } from '@reduxjs/toolkit';
import { setApiFailed, setApiPending, listAgentsSuccess } from './reducers';
import { listAgents } from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Actor slice. */
export const agentSlice = createSlice({
  name: 'agent',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(listAgents.pending, setApiPending)
      .addCase(listAgents.rejected, setApiFailed)
      .addCase(listAgents.fulfilled, listAgentsSuccess),
});
