import React, { ReactElement, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { Table, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  PredictionsDiff,
  PredictionsDiffPredictionPair,
  PredictionsDiffMessagePredictionDiff,
} from '@cresta/web-client/dist/cresta/v1/studio/storage/prediction/prediction.pb';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Key, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { getPredictionType } from './utils';

interface TableRow {
  idx: number;
  convId: string;
  convLink: ReactNode;
  message: string;
  predsOnlyInNew: ReactNode;
  predsOnlyInNewData?: PredictionsDiffPredictionPair[];
  predsOnlyInOld: ReactNode;
  predsOnlyInOldData?: PredictionsDiffPredictionPair[];
  predsInBoth: ReactNode;
  predsInBothData?: PredictionsDiffPredictionPair[];
  key: string;
}

interface Props {
  diff?: PredictionsDiff;
}
export function RegressionTaskTable({ diff }: Props): ReactElement {
  const [onlyOldFilter, setOnlyOldFilter] = useState(undefined);
  const [bothFilter, setBothFilter] = useState(undefined);
  const [onlyNewFilter, setOnlyNewFilter] = useState(undefined);
  const customer = useCustomerParams();

  if (diff === undefined || diff.convDiffs === undefined) {
    return <span>Both the old and the new predictions in the golden conversations were empty.</span>;
  }

  // Extract filter expressions from the Table so we can actually filter out row contents.
  const getStringSet = (as: (Key | boolean)[] | null) => {
    const out = new Set<string>();
    if (as === null) {
      return out;
    }
    as.forEach((a) => {
      if (typeof a === 'string') {
        out.add(a);
      }
    });
    return out;
  };

  const onChange = (pagination: TablePaginationConfig, filters: Record<string, (Key | boolean)[] | null>, sorter: SorterResult<TableRow> | SorterResult<TableRow>[], extra: TableCurrentDataSource<TableRow>) => {
    if (extra.action !== 'filter') {
      return;
    }
    if ('predsOnlyInOld' in filters) {
      setOnlyOldFilter(getStringSet(filters.predsOnlyInOld));
    }
    if ('predsInBoth' in filters) {
      setBothFilter(getStringSet(filters.predsInBoth));
    }
    if ('predsOnlyInNew' in filters) {
      setOnlyNewFilter(getStringSet(filters.predsOnlyInNew));
    }
  };

  // Get all moment & action types for a diff type.
  const getPredictionTypes = (diff: PredictionsDiff, predsGetter: (a: PredictionsDiffMessagePredictionDiff) => (PredictionsDiffPredictionPair[] | undefined)) => {
    const predictionTypes = new Set<string>();
    for (const convDiff of Object.values(diff.convDiffs)) {
      if (convDiff.msgDiffs === undefined) {
        // eslint-disable-next-line no-continue
        continue;
      }
      convDiff.msgDiffs.forEach((diff) => {
        const preds = predsGetter(diff);
        if (preds === undefined) {
          return;
        }
        preds.forEach((both) => {
          predictionTypes.add(getPredictionType(both.output));
        });
      });
    }
    return Array.from(predictionTypes.values()).sort().map(
      (type) => ({ text: type, value: type }),
    );
  };

  const filterByPredictionTypes = (preds: PredictionsDiffPredictionPair[] | undefined, filter: Set<string> | undefined) => {
    if (preds === undefined) {
      return [];
    }
    if (filter === undefined) {
      return preds;
    }
    return preds.filter((p) => filter.has(getPredictionType(p.output)));
  };

  const typesOld = getPredictionTypes(diff, (a) => a.predsOnlyInOld);
  const typesBoth = getPredictionTypes(diff, (a) => a.predsInBoth);
  const typesNew = getPredictionTypes(diff, (a) => a.predsOnlyInNew);
  const columns: ColumnsType<TableRow> = [
    { title: 'Index', dataIndex: 'idx', sorter: (a, b) => (a.idx - b.idx), defaultSortOrder: 'ascend' },
    { title: 'Conversation', dataIndex: 'convLink', sorter: (a, b) => a.convId.localeCompare(b.convId) },
    { title: 'Message', dataIndex: 'message' },
    {
      title: 'Old predictions',
      dataIndex: 'predsOnlyInOld',
      filters: typesOld,
      defaultFilteredValue: typesOld.map((t) => t.value),
    },
    {
      title: 'Common predictions',
      dataIndex: 'predsInBoth',
      filters: typesBoth,
      defaultFilteredValue: typesBoth.map((t) => t.value),
    },
    {
      title: 'New predictions',
      dataIndex: 'predsOnlyInNew',
      filters: typesNew,
      defaultFilteredValue: typesNew.map((t) => t.value),
    },
  ];

  const flatDiff: TableRow[] = [];
  let idx = 1;
  Object.entries(diff.convDiffs).forEach(
    ([convName, convDiff]) => {
      if (convDiff.msgDiffs === undefined) {
        return;
      }
      for (const msgDiff of convDiff.msgDiffs) {
        const onlyInNew = filterByPredictionTypes(msgDiff.predsOnlyInNew, onlyNewFilter);
        const onlyInOld = filterByPredictionTypes(msgDiff.predsOnlyInOld, onlyOldFilter);
        const inBoth = filterByPredictionTypes(msgDiff.predsInBoth, bothFilter);
        if (onlyInNew.length === 0 && onlyInOld.length === 0 && inBoth.length === 0) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const convId = convName.substring(convName.lastIndexOf('/') + 1);
        flatDiff.push({
          idx,
          convId,
          convLink: <Link to={{ pathname: `/${customer.path}/conversation/${convId}` }}>{convId}</Link>,
          message: msgDiff.message,
          predsOnlyInNew: <PredsDiffList preds={onlyInNew} tagIcon={<ExclamationCircleOutlined />} tagColor="warning" />,
          predsOnlyInNewData: msgDiff.predsOnlyInNew,
          predsOnlyInOld: <PredsDiffList preds={onlyInOld} tagIcon={<CloseCircleOutlined />} tagColor="error" />,
          predsOnlyInOldData: msgDiff.predsOnlyInOld,
          predsInBoth: <PredsDiffList preds={inBoth} tagIcon={<CheckCircleOutlined />} tagColor="success" />,
          predsInBothData: msgDiff.predsInBoth,
          // eslint-disable-next-line quotes
          key: `${convName}: ${msgDiff.message}`,
        });
        ++idx;
      }
    },
  );
  return <Table onChange={onChange} title={() => 'Prediction details'} columns={columns} dataSource={flatDiff} size="middle" />;
}

const PredsDiffList = ({ preds, tagIcon, tagColor }: { preds: PredictionsDiffPredictionPair[] | undefined, tagIcon: ReactNode, tagColor: string }) => (
  <span>
    {
      preds?.map((pred) => {
        // eslint-disable-next-line quotes
        const text = `${pred.output}: Confidence ${pred.oldConfidence?.toFixed(2) || 'N/A'} \u2192 ${pred.newConfidence?.toFixed(2) || 'N/A'}`;
        return <Tag icon={tagIcon} color={tagColor} key={pred.output}>{text}</Tag>;
      })
    }
  </span >
);
