import React, { useEffect, useState } from 'react';
import { ListConversationsRequestFilter } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { useDebounce } from 'hooks/useDebounce';
import { ConversationApi } from 'services/conversationApi';
import { SetApi } from 'services/setApi';

import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { SetData } from '../..';
import { CreatedCard as CreatedCardComponent } from './CreatedCard';

interface CreatedCardProps {
    index: number;
    setData: SetData;
    onDataChange: (updatedData: Partial<SetData>) => void
    agentData: { value: string, label: string }[];
    onClearAll: () => void
    onRemove: () => void
}

export const CreatedCard = (props: CreatedCardProps) => {
  const { setData } = props;
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const usecase = useCustomerUsecase();
  const [nameError, setNameError] = useState<string | undefined>();
  const [isNameErrorChecking, toggleNameErrorChecking] = useState(false);
  const [conversationFilterError, setConversationFilterError] = useState<string | undefined>();
  const [isConversationsErrorChecking, toggleConversationsErrorChecking] = useState(false);

  const debouncedSetName = useDebounce(setData.name, 500);

  useEffect(() => {
    const getConversations = async () => {
      const filter: ListConversationsRequestFilter = {
        usecase,
        languageCode: customer.languageCode,
        agentIds: setData.agentIdsSelected.length ? setData.agentIdsSelected : undefined,
        minimalConversationDuration: setData.min ? `${setData.min * 60}s` : undefined,
      };
      const pageSize = setData.convoQuantity;
      const res = await ConversationApi.listConversations({
        parent: customerProfile,
        pageSize,
        filter,
      });
      const conversationNames = res.conversations.map((convo) => convo.name);
      const isSetEmpty = conversationNames.length === 0;

      if (isSetEmpty) {
        setConversationFilterError('No conversations found');
      } else {
        setConversationFilterError(undefined);
      }
      toggleConversationsErrorChecking(false);
      return conversationNames;
    };
    getConversations();
  }, [setData.agentIdsSelected, setData.min, setData.convoQuantity]);

  // check for for set name duplication
  useEffect(() => {
    if (!debouncedSetName) return;
    const getAllSets = async () => {
      const res = await SetApi.listSets({
        parent: customerProfile,
        usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
        languageCode: customer.languageCode,
      });
      const setNames = res.sets.map((set) => set.setTitle.toLowerCase());
      if (setNames.includes(debouncedSetName.toLowerCase())) {
        setNameError('Set name already exists');
      } else {
        setNameError(undefined);
      }
      toggleNameErrorChecking(false);
    };
    getAllSets();
  }, [debouncedSetName]);

  useEffect(() => {
    setNameError(undefined);
    if (!setData.name) return;
    toggleNameErrorChecking(true);
  }, [setData.name]);

  useEffect(() => {
    setConversationFilterError(undefined);
    toggleConversationsErrorChecking(true);
  }, [setData.agentIdsSelected, setData.min]);

  return (
    <CreatedCardComponent
      nameError={nameError}
      isNameErrorChecking={isNameErrorChecking}
      isConversationsErrorChecking={isConversationsErrorChecking}
      conversationFilterError={conversationFilterError}
      {...props}
    />
  );
};
