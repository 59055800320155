import React from 'react';
import {
  Alert,
  Box,
  Collapse,
  Flex,
  Spoiler,
  Text,
} from '@mantine/core';
import { InfoIcon } from '../../components/icons';

interface WrapperProps {
    title: string;
    isVisible: boolean;
    onClose?: () => void;
    children: React.ReactNode;
}

const Title = ({ title }: {title: string}) => (
  <Flex>
    <InfoIcon />
    <Text size="sm" ml={5} fw={600}>
      {title}
    </Text>
  </Flex>
);

const Wrapper = ({ title, isVisible, onClose, children }: WrapperProps) => (
  <Collapse in={isVisible}>
    <Alert
      title={<Title title={title} />}
      color="gray"
      p="xs"
      withCloseButton={!!onClose}
      onClose={() => {
        if (onClose) onClose();
      }}
    >
      <Box px={5}>
        {children}
      </Box>
    </Alert>
  </Collapse>
);

interface WithSpoilerProps {
    maxHeight?: number;
    children: React.ReactNode;
}
const WithSpoiler = ({ maxHeight, children }: WithSpoilerProps) => (
  <Spoiler
    maxHeight={maxHeight}
    showLabel={<Text size="xs">Show More</Text>}
    hideLabel={<Text size="xs">Show Less</Text>}
  >
    {children}
  </Spoiler>
);
interface TipSectionProps {
    title: string;
    isVisible: boolean;
    maxHeight?: number;
    onClose?: () => void;
    children: React.ReactNode;
}

export const TipSection = ({ title, isVisible, maxHeight, onClose, children }: TipSectionProps) => {
  // NOTE: if spoiler is enabled, onClose will be unclickable due to how the Mantine spoiler component works
  if (maxHeight) {
    return (
      <Wrapper title={title} isVisible={isVisible} onClose={onClose}>
        <WithSpoiler maxHeight={maxHeight}>
          {children}
        </WithSpoiler>
      </Wrapper>
    );
  }

  return (
    <Wrapper title={title} isVisible={isVisible} onClose={onClose}>
      {children}
    </Wrapper>
  );
};
