import { Annotation, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { DerivedLabelingTask, TargetType as TaskTargetType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { createSelector } from '@reduxjs/toolkit';
import { getId } from 'common/resourceName';
import { getAnnotationRawDataKey } from 'store/annotation/selectors';
import { CalibrationTaskSet } from 'store/types';
import type { State } from '../store';
import type { State as LabelingTaskState } from './state';

const selectLabelingTask = (state: State) => state.labelingTask;

/** Api status. */
export const selectApiStatus = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.status);

/** Api error. */
export const selectApiError = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.error);

/** Derived analysis tasks array. */
export const selectManualAnalysisLabelingTasks = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) =>
  labelingTaskState.derivedLabelingTasks.filter((task) => task.labelingTask.taskData.taskDescriptor.targetType === TaskTargetType.MANUAL_ANALYSIS));

/** Derived labeling tasks array. */
export const selectDerivedLabelingTasks = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) =>
  labelingTaskState.derivedLabelingTasks.filter((task) => task.labelingTask.taskData.taskDescriptor.targetType === TargetType.LABELING));

/** Derived QA tasks array. */
export const selectDerivedQaTasks = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) =>
  labelingTaskState.derivedLabelingTasks.filter((task) => task.labelingTask.taskData.taskDescriptor.targetType === TargetType.QA));

/** Derived labeling task by name selector and target type factory. */
export const selectDerivedLabelingTaskFactory = (taskName: string) =>
  createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.derivedLabelingTasks.find((task) => task.labelingTask.name === taskName));

/** Derived labeling tasks map. */
export const selectDerivedLabelingTasksMap = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => new Map<string, DerivedLabelingTask>(labelingTaskState.derivedLabelingTasks.map((task) => [getId('labelingTask', task.labelingTask.name), task])));

/** Original labeling task by calibration or dual task selector factory. */
export const selectOriginalLabelingTaskFactory = (derivedTask: DerivedLabelingTask) => createSelector(selectDerivedLabelingTasksMap, (labelingTasksMap: Map<string, DerivedLabelingTask>) => {
  const selectionInstruction = derivedTask?.labelingTask?.taskData.taskDescriptor.selectionInstruction;

  // Find dual task if this is calibration task
  const dualTaskId = selectionInstruction?.calibrationSelectionInstruction?.dualTaskIds[0];
  const dualTask = labelingTasksMap.get(dualTaskId);

  // Find original task if this is dual task
  const originalTaskId = selectionInstruction?.dualSelectionInstruction?.originTaskId
  || dualTask?.labelingTask.taskData.taskDescriptor.selectionInstruction?.dualSelectionInstruction?.originTaskId;
  const originalTask = labelingTasksMap.get(originalTaskId);
  return originalTask;
});

/** Current labeling task. */
export const selectCurrentLabelingTask = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.currentLabelingTask);

/** Current task progress. */
export const selectCurrentTaskProgress = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.currentTaskProgress);

/** Temporal annotations. */
export const selectTemporalAnnotations = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.temporalAnnotations);

/** Temporal annotations. */
export const selectTaskTemporalAnnotationValueCounts = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.taskTemporalAnnotationValueCounts);

/** Annotation bundles */
export const selectAnnotationBundles = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.annotationBundles);

/** Temporal annotations map. */
export const selectTemporalAnnotationsMap = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => {
  const map = new Map<string, Annotation[]>();
  labelingTaskState.temporalAnnotations.forEach((annotation) => {
    const rawDataKey = getAnnotationRawDataKey(annotation);
    const messageId = annotation.rawData[rawDataKey]?.v2MessageId;
    const messageAnnotations = map.get(messageId) || [];
    map.set(messageId, [...messageAnnotations, annotation]);
  });
  return map;
});

/** Fetching tasks boolean */
export const selectFetchingTasks = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.fetchingTasks);

/** Failed to save messages */
export const selectFailedToSaveMessages = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.failedToSaveMessages);

/** Fetching annotations boolean */
export const selectFetchingAnnotations = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.fetchingAnnotations);

/** Current conversation labeling items (chat) */
export const selectConversationLabelingItems = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.conversationLabelingItems);

/** Current task completed boolean */
export const selectTaskCompleted = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.taskCompleted);

/** Current conversation predictions */
export const selectPredictions = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.predictions);

const CALIBRATION_TASK_ID = /^calibra-(.*)$/;

/** Select a set of calibration task, dual task and original task given the ID of the calibration task. */
export const selectCalibrationTaskSetFactory = (calibraTaskId: string) =>
  createSelector(selectDerivedLabelingTasksMap, (labelingTasksMap: Map<string, DerivedLabelingTask>): CalibrationTaskSet => {
    const calibrationTask = labelingTasksMap.get(calibraTaskId);
    const dualTaskId = calibrationTask?.labelingTask?.taskData?.taskDescriptor?.selectionInstruction?.calibrationSelectionInstruction?.dualTaskIds[0];
    const dualTask = labelingTasksMap.get(dualTaskId);
    const match = calibraTaskId.match(CALIBRATION_TASK_ID);
    const origTaskId = match[1];
    const originalTask = labelingTasksMap.get(origTaskId);
    return {
      originalTask,
      dualTask,
      calibrationTask,
    };
  });

/** User sampled more messages */
export const selectCanSampleMore = createSelector(selectLabelingTask, (labelingTaskState: LabelingTaskState) => labelingTaskState.canSampleMore);
