/* Subnav is the holistic view of different related pages */
import { Tabs } from 'antd';
import { PageContainer } from 'components/PageContainer';
import { Flex, Title } from '@mantine/core';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import './SubNavigation.scss';
import { useIsSharedLibrary } from 'hooks/useCustomerParams';

interface ConsoleTab {
  tab: any;
  key: string;
}

interface Props {
  tabs: ConsoleTab[];
  title: string;
}

const SubNavigation = (props: Props) => {
  const { tabs, title } = props;
  const params = useParams();
  const navigate = useNavigate();

  const handleTabChange = (activeKey: string) => {
    navigate(activeKey.toLowerCase());
  };

  const items = tabs.map(({ key, tab }) => ({
    key,
    label: tab,
  }));

  return (
    <PageContainer>
      <div>
        <Title order={1} mb="lg">
          {title}
        </Title>
        <Tabs
          activeKey={params.tab}
          style={{ width: '100%', overflow: 'inherit' }}
          onChange={handleTabChange}
          size="large"
          className="sub-navigation-tabs"
          items={items}
        />
        <Flex direction="column">
          <Outlet />
        </Flex>
      </div>
    </PageContainer>
  );
};

export const IntentSubNavigation = () => {
  const isSharedLibrary = useIsSharedLibrary();

  const intentTabPanes = [
    {
      tab: 'Taxonomy',
      key: 'taxonomy',
    },
    {
      tab: 'History',
      key: 'history',
    },
  ];

  if (isSharedLibrary) {
    intentTabPanes.push({
      tab: 'Out of box intents',
      key: 'oob-intents',
    });
  }
  return <SubNavigation tabs={intentTabPanes} title="Intents" />;
};

export default SubNavigation;
