import { ClusterService, DeleteClusterRequest, DeleteClusterResponse, GetClusterRequest, GetClusterResponse, ListClustersRequest, ListClustersResponse, MergeClustersRequest, MergeClustersResponse, UpdateClusterRequest, UpdateClusterResponse } from '@cresta/web-client/dist/cresta/v1/studio/cluster/cluster_service.pb';
import { StudioApi } from './studioApi';

export abstract class ClusterApi {
  public static listClusters(requst: ListClustersRequest): Promise<ListClustersResponse> {
    return ClusterService.ListClusters(requst, StudioApi.getHeaders().initReq);
  }

  public static getCluster(request: GetClusterRequest): Promise<GetClusterResponse> {
    return ClusterService.GetCluster(request, StudioApi.getHeaders().initReq);
  }

  public static mergeClusters(request: MergeClustersRequest): Promise<MergeClustersResponse> {
    return ClusterService.MergeClusters(request, StudioApi.getHeaders().initReq);
  }

  public static updateCluster(request: UpdateClusterRequest): Promise<UpdateClusterResponse> {
    return ClusterService.UpdateCluster(request, StudioApi.getHeaders().initReq);
  }

  public static deleteCluster(request: DeleteClusterRequest): Promise<DeleteClusterResponse> {
    return ClusterService.DeleteCluster(request, StudioApi.getHeaders().initReq);
  }
}
