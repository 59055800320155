import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification } from 'components/Notification';
import { Job, ListJobsFilters, ListJobsRequest } from '@cresta/web-client';
import { JobApi } from 'services/jobApi';

enum Action {
  LIST_JOBS = 'LIST_JOBS',
}

/** AsyncThunk for listing auto analysis jobs */
export const listJobs = createAsyncThunk<Job[], {
  parent: string,
  filters?: ListJobsFilters,
}>(Action.LIST_JOBS, async ({
  parent,
  filters,
}) => {
  try {
    const request: ListJobsRequest = {
      parent,
      filters,
    };
    const response = await JobApi.listJobs(request);
    return response?.jobs ?? [];
  } catch (err) {
    if ((err as { name: string }).name !== 'AbortError') {
      openNotification('error', 'Failed to get jobs', undefined, err);
    }
    throw err;
  }
});
