import React, { useEffect, useMemo } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';

import {
  Concept,
  ConceptConceptType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { getId } from 'common/resourceName';
import { SummarizationInstructionRubricTagSelection, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { useRubrics } from 'hooks/useRubrics';
import { Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { MultiSelect, Text } from '@mantine/core';
import styles from '../styles.module.scss';

interface SummarizationTaskFormProps {
  concepts: Concept[];
  conversationCount: number;
  setConversationCount: (value: number) => void;
  minConversationLength: number;
  setMinConversationLength: (value: number) => void;
  dateAfterAndBefore: [Dayjs, Dayjs];
  setDateAfterAndBefore: (date: [Dayjs, Dayjs]) => void;
  modelUris: string[];
  preselectedCsvUri: string;
  setModelUris: (uris: string[]) => void;
  setConceptIds: (ids: string[]) => void;
  rubricTagSelections: SummarizationInstructionRubricTagSelection[];
  setRubricTagSelections: (tags: SummarizationInstructionRubricTagSelection[]) => void;
}

export default function SummarizationTaskForm({
  concepts,
  conversationCount,
  setConversationCount,
  minConversationLength,
  setMinConversationLength,
  dateAfterAndBefore,
  setDateAfterAndBefore,
  modelUris,
  preselectedCsvUri,
  setModelUris,
  setConceptIds,
  rubricTagSelections,
  setRubricTagSelections,
}: SummarizationTaskFormProps) {
  const onRangeChange = (val: [Dayjs, Dayjs]) => {
    setDateAfterAndBefore([val[0]?.startOf('day'), val[1]?.endOf('day')]);
  };

  const filteredConcepts = useMemo(() => concepts.filter((concept) => concept.conceptType === ConceptConceptType.SUMMARIZATION), [concepts]);

  const [rubrics] = useRubrics(UserLabelingTaskType.QA_SUMMARIZATION);

  const rubricsWithTags = rubrics.filter((rubric) => rubric.availableTags?.length);

  // The key is the rubric resource name
  const rubricTagSelectionMap = new Map(rubricTagSelections.map((selection) => [selection.rubricName, selection]));

  // The key is the rubric title
  const rubricMap: Map<string, Rubric> = useMemo(() => new Map(rubrics.map((rubric) => [rubric.rubricTitle, rubric])), [rubrics, rubricTagSelections]);

  const getRubricSelectedTags = (title: string): string[] => {
    const rubric = rubricMap?.get(title);
    return rubric ? rubricTagSelectionMap?.get(rubric.name)?.tagsUsed : [];
  };

  const getRubricAvailableTags = (title: string): string[] => {
    const rubric = rubricMap?.get(title);
    return rubric?.availableTags || [];
  };

  // Auto update concept ids
  useEffect(() => {
    const summaryIds = filteredConcepts.map((concept) => getId('concept', concept.name));
    setConceptIds(summaryIds);
  }, [filteredConcepts]);

  const handleUpdateTags = (rubricTitle: string, tags: string[]) => {
    const rubric = rubricMap.get(rubricTitle);
    let rubricSelection: SummarizationInstructionRubricTagSelection = rubricTagSelectionMap.get(rubric.name);
    if (!rubricSelection) {
      // If tag selection for this rubric doesn't exist yet, create it
      rubricSelection = {
        rubricName: rubric.name,
        tagsUsed: tags,
      };
      setRubricTagSelections([...rubricTagSelections, rubricSelection]);
    } else {
      rubricSelection = {
        ...rubricSelection,
        tagsUsed: tags,
      };
      // Otherwise add it to the selection
      setRubricTagSelections(rubricTagSelections.map((selection) => {
        if (selection.rubricName === rubric.name) {
          return rubricSelection;
        } else {
          return selection;
        }
      }));
    }
  };

  return (
    <>
      <Form.Item>
        <Text size="md">Conversations from this time range</Text>
        <DatePicker.RangePicker
          value={dateAfterAndBefore}
          onChange={onRangeChange}
        />
      </Form.Item>
      <Text size="md">Summarization topics</Text>
      <Select
        showArrow
        mode="multiple"
        className={classNames([styles.fullWidth, styles.taskType])}
        size="large"
        value={filteredConcepts.map((concept) => concept.conceptTitle)}
        disabled
      />
      <Form.Item>
        <Text mt="lg" size="md">Model 1</Text>
        <Input
          size="large"
          value={modelUris?.[0]}
          placeholder="Model URL"
          onChange={(event) => {
            const modelUrisCopy = modelUris.slice();
            modelUrisCopy[0] = event.target.value;
            setModelUris(modelUrisCopy);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Text size="md">Model 2 (Optional)</Text>
        <Input
          size="large"
          value={modelUris?.[1]}
          placeholder="Model URL"
          onChange={(event) => {
            const modelUrisCopy = modelUris.slice();
            modelUrisCopy[1] = event.target.value;
            setModelUris(modelUrisCopy);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Text size="md">Number of conversations to QA</Text>
        <div className={styles.chatCount}>
          <Input
            size="large"
            type="number"
            value={conversationCount}
            onChange={(event) =>
              setConversationCount(Number(event.currentTarget.value))}
          />
        </div>
      </Form.Item>
      <Form.Item>
        <Text size="md">Min number of turns</Text>
        <div className={styles.chatCount}>
          <Input
            size="large"
            value={minConversationLength}
            type="number"
            min={5}
            onChange={(event) =>
              setMinConversationLength(Number(event.currentTarget.value))}
          />
        </div>
      </Form.Item>
      {rubricsWithTags.map((rubric) => (
        <Form.Item>
          <Text size="md">Explanation tags for Factuality</Text>
          <MultiSelect
            data={getRubricAvailableTags(rubric.rubricTitle)}
            searchable
            placeholder="Select tags"
            value={getRubricSelectedTags(rubric.rubricTitle)}
            onChange={(tags) => handleUpdateTags(rubric.rubricTitle, tags)}
          />
        </Form.Item>
      ))}
    </>
  );
}
