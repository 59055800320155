import dayjs from 'dayjs';

export function getPredictionType(s?: string): string {
  return !s || s.indexOf(' ') === -1 ? '' : s.substring(0, s.indexOf(' '));
}

export function getPredictionName(s?: string): string {
  return !s || s.indexOf("'") === -1 ? '' : s.substring(s.indexOf("'") + 1, s.indexOf("'", s.indexOf("'") + 1));
}

export function formatDate(ts: string): string {
  if (!ts) {
    return '';
  }
  return dayjs(ts).format('YYYY-MM-DD');
}
