import { useEffect } from 'react';
import { SuggestionsDatasetArtifactInputsShardInterval, SuggestionsModelArtifactInputsModelType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { useForm } from '@mantine/form';
import { DatesRangeValue } from '@mantine/dates';

export interface GenerativeConfigFormValues {
    conversationDates: DatesRangeValue;
    modelType: SuggestionsModelArtifactInputsModelType;
    addExperts: boolean;
    expertAgentUserIds: string[];
    // advanced settings
    testSetDaysSuffix: number;
    shardInterval: SuggestionsDatasetArtifactInputsShardInterval;
    splitRatioTrain: number;
    loadPreprocessingConfig: boolean;
    loadPostprocessingConfig: boolean;
    materializeConfigs: boolean;
  }

export const useGenerativeConfigForm = () => {
  const form = useForm<GenerativeConfigFormValues>({
    initialValues: {
      conversationDates: [null, null],
      modelType: SuggestionsModelArtifactInputsModelType.GPT2,
      addExperts: false,
      expertAgentUserIds: [],
      // advanced settings
      testSetDaysSuffix: 7,
      shardInterval: SuggestionsDatasetArtifactInputsShardInterval.SHARD_INTERVAL_UNSPECIFIED,
      splitRatioTrain: 95,
      loadPreprocessingConfig: false,
      loadPostprocessingConfig: false,
      materializeConfigs: true,
    },
  });
  useEffect(() => {
    if (form.values.modelType === SuggestionsModelArtifactInputsModelType.T5) {
      form.setFieldValue('addExperts', false);
      form.setFieldValue('expertAgentUserIds', []);
    }
  }, [form.values.modelType]);

  return form;
};
