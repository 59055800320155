import {
  BatchGetKeyValuesRequest,
  BatchDeleteKeyValuesRequest,
  BatchDeleteKeyValuesResponse,
  BatchGetKeyValuesResponse,
  BatchUpsertKeyValuesRequest,
  BatchUpsertKeyValuesResponse,
  KeyValueService,
} from '@cresta/web-client/dist/cresta/v1/studio/keyvalue/key_value_service.pb';
import { StudioApi } from './studioApi';

export abstract class KeyValueApi {
  public static batchUpsertKeyValues(request: BatchUpsertKeyValuesRequest): Promise<BatchUpsertKeyValuesResponse> {
    return KeyValueService.BatchUpsertKeyValues(request, StudioApi.getHeaders().initReq);
  }

  public static batchGetKeyValues(request: BatchGetKeyValuesRequest): Promise<BatchGetKeyValuesResponse> {
    return KeyValueService.BatchGetKeyValues(request, StudioApi.getHeaders().initReq);
  }

  public static batchDeleteKeyValues(request: BatchDeleteKeyValuesRequest): Promise<BatchDeleteKeyValuesResponse> {
    return KeyValueService.BatchDeleteKeyValues(request, StudioApi.getHeaders().initReq);
  }
}
