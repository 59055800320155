import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { selectApiStatus, selectIntentConceptByNameFactory } from 'store/concept/selectors';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { SpeakerRole } from '@cresta/web-client/dist/cresta/ai_service/common';
import { Center, Loader } from '@mantine/core';
import { AutoLabelingSessionContextProvider } from 'context/AutoLabelingSessionContext';
import { Category, ConceptData, ExampleObj, PrepopulatedType } from './types';
import { ConceptNotFound } from './components/ConceptNotFound';
import { AutoLabeling as AutoLabelingComponent } from './AutoLabeling';

export const AutoLabeling = () => {
  const params = useParams<{
    conceptId: string;
  }>();
  const customerProfile = useCustomerProfile();
  const { conceptId } = params;
  const conceptName = `${customerProfile}/concepts/${conceptId}`;
  const concept = useSelector(selectIntentConceptByNameFactory(conceptName));
  const apiStatus = useSelector(selectApiStatus);
  const isLoading = apiStatus === 'loading';

  const conceptData: ConceptData = useMemo(() => {
    let positiveExamples: ExampleObj[] = [];
    let negativeExamples: ExampleObj[] = [];
    let prepopulatedType: PrepopulatedType = 'none';

    const hasPreviousTaskExamples = concept?.intent?.intentMetadata?.positiveExamples?.length > 0
      || concept?.intent?.intentMetadata?.negativeExamples?.length > 0;
    const hasTaxonomyExamples = concept?.positiveExamples?.length > 0 || concept?.negativeExamples?.length > 0;
    // check for examples set by previous task
    if (hasPreviousTaskExamples) {
      positiveExamples = concept?.intent?.intentMetadata?.positiveExamples?.map((example) => ({
        value: example?.text || '',
        id: example?.exampleUtteranceId || uuid(),
        category: Category.POSITIVE,
      })) || [];
      negativeExamples = concept?.intent?.intentMetadata?.negativeExamples?.map((example) => ({
        value: example?.text || '',
        id: example?.exampleUtteranceId || uuid(),
        category: Category.NEGATIVE,
      })) || [];
      prepopulatedType = 'previousTask';
    } else if (hasTaxonomyExamples) {
      // check for examples set in taxonomy
      positiveExamples = concept?.positiveExamples?.map((example) => ({
        value: example,
        id: uuid(),
        category: Category.POSITIVE,
      })) || [];
      negativeExamples = concept?.negativeExamples?.map((example) => ({
        value: example,
        id: uuid(),
        category: Category.NEGATIVE,
      })) || [];
      prepopulatedType = 'taxonomy';
    }
    const intentMetaData = concept?.intent?.intentMetadata;
    return {
      name: concept?.name || '',
      description: intentMetaData?.intentDescription || concept?.description || '',
      role: intentMetaData?.speakerRole
        || (concept?.intent?.intentType === IntentIntentType.AGENT_INTENT
          ? SpeakerRole.AGENT
          : SpeakerRole.VISITOR),
      value: concept?.conceptTitle || '',
      humanReadableName: intentMetaData?.intentDisplayName || '',
      positiveExamples,
      negativeExamples,
      prepopulatedType,
    };
  }, [concept]);

  if (isLoading) {
    return (
      <Center style={{ flex: 1 }}>
        <Loader />
      </Center>
    );
  }

  if (!concept) {
    return <ConceptNotFound conceptName={conceptName} />;
  }

  return (
    <AutoLabelingSessionContextProvider>
      <AutoLabelingComponent conceptData={conceptData} />;
    </AutoLabelingSessionContextProvider>
  );
};
