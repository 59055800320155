import { useState, useEffect } from 'react';
import { FetchLabelingDataRequest, FetchLabelingDataResponse } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { LabelingTaskApi } from 'services/labelingTaskApi';

export const useLabelingData = (taskName: string, deps?: any[]) => {
  const [labelingData, setLabelingData] = useState<FetchLabelingDataResponse>(null);
  const [isLoading, toggleLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (!taskName) {
      const err = 'No Task Name Provided to useLabelingData';
      console.error(err);
      setError(err);
      toggleLoading(false);
      return;
    }
    const fetchLabelingData = async () => {
      toggleLoading(true);
      const fetchLabelingDataRequest: FetchLabelingDataRequest = {
        taskName,
      };
      try {
        const response = await LabelingTaskApi.fetchLabelingData(fetchLabelingDataRequest);
        setLabelingData(response);
      } catch (e) {
        setError(e);
      } finally {
        toggleLoading(false);
      }
    };
    fetchLabelingData();
  }, [taskName, ...(deps || [])]);

  return [labelingData, isLoading, error];
};
