import './TagInput.scss';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Input, Tag, TagProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { EnterIcon } from './EnterIcon';

interface TagInputProps {
  tags: string[];
  inputProps?: TextAreaProps;
  tagProps?: TagProps;
  onAddTag: (arg: string) => void;
  onRemoveIndex: (arg: number) => void;
}

export default function TagInput(props: TagInputProps) {
  const [tag, setTag] = useState('');

  const addTag = () => {
    if (!tag.length) return;
    if (tag.match(/^s+$/)) return;
    props.onAddTag(tag);
    setTag('');
  };

  const addTagExampleHelper = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key !== 'Enter') return;
    // Prevents line breaks from being added to textarea
    event.preventDefault();
    addTag();
  };

  const handleChange = (event) => {
    setTag(event.currentTarget.value);
  };

  const removeTag = (index: number) => {
    props.onRemoveIndex(index);
  };

  return (
    <div className="tag-input">
      <div className="input-container">
        <Input.TextArea
          placeholder="Add an example and hit enter"
          value={tag}
          onBlur={() => addTag()}
          onKeyPress={(event) => addTagExampleHelper(event)}
          rows={2}
          onChange={(event) => handleChange(event)}
          {...props.inputProps}
        />
        <div className="enter-icon-container">
          <EnterIcon />
        </div>
      </div>

      {props.tags.length > 0 && (
        <div className="tags-container">
          {props.tags.map((seed, i) => (
            <Tag
              closable
              color="#EDF2FF"
              key={uuid()}
              className="studio-tag"
              onClose={() => removeTag(i)}
              {...props.tagProps}
            >
              {seed}
            </Tag>
          ))}
        </div>
      )}
    </div>
  );
}
