import { Button, Flex, Group, Text, Title } from '@mantine/core';
import React from 'react';

interface TaskLabeledProps {
  completeTask: () => void;
  setUserRevisitingLabels: (value: boolean) => void;
  children: React.ReactNode;
}

export function TaskLabeled({
  completeTask,
  setUserRevisitingLabels,
  children,
}: TaskLabeledProps) {
  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
    >
      <Title>Congrats!</Title>
      <Text mb="md" color="gray">You have labeled everything</Text>
      {children}
      <Group spacing="sm">
        <Button variant="subtle" onClick={() => setUserRevisitingLabels(true)}>Revisit labels</Button>
        <Button onClick={completeTask} data-cy="finish-btn">Finish</Button>
      </Group>
    </Flex>
  );
}
