import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';
import { State } from './state';

const SORTER = (a: Concept, b: Concept) => a.name.localeCompare(b.name);

/** General reducer for starting a concept API. */
export function conceptApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed concept API. */
export function conceptApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** List concept success. */
export function listConceptsSuccess(state: State, action: PayloadAction<Concept[]>): State {
  const concepts = {};
  for (const concept of action.payload) {
    if (!concepts[concept.conceptType]) {
      concepts[concept.conceptType] = [];
    }
    concepts[concept.conceptType].push(concept);
  }
  Object.keys(concepts).forEach((key) => concepts[key].sort(SORTER));
  return {
    ...state,
    status: 'succeeded',
    concepts,
  };
}

/** List shared concept success. */
export function listSharedConceptsSuccess(state: State, action: PayloadAction<Concept[]>): State {
  const sharedConcepts = {};
  for (const concept of action.payload) {
    if (!sharedConcepts[concept.conceptType]) {
      sharedConcepts[concept.conceptType] = [];
    }
    sharedConcepts[concept.conceptType].push(concept);
  }
  return {
    ...state,
    status: 'succeeded',
    sharedConcepts,
  };
}

/** List demo concept success. */
export function listDemoConceptsSuccess(state: State, action: PayloadAction<Concept[]>): State {
  const demoConcepts = {};
  for (const concept of action.payload) {
    if (!demoConcepts[concept.conceptType]) {
      demoConcepts[concept.conceptType] = [];
    }
    demoConcepts[concept.conceptType].push(concept);
  }
  return {
    ...state,
    status: 'succeeded',
    demoConcepts,
  };
}

/** Reducer for refreshing concepts. */
export function refreshConceptsSuccess(state: State, action: PayloadAction<Concept[]>): State {
  const newConcepts = {};
  for (const concept of action.payload) {
    if (!newConcepts[concept.conceptType]) {
      newConcepts[concept.conceptType] = [];
    }
    newConcepts[concept.conceptType].push(concept);
  }
  Object.keys(newConcepts).forEach((key) => newConcepts[key].sort(SORTER));
  // Prevent updating concepts if not updated.
  if (isEqual(newConcepts, state.concepts)) {
    return state;
  }
  return {
    ...state,
    concepts: newConcepts,
  };
}

/** Cet/Create/Update concept success. */
export function getOrUpsertConceptSuccess(state: State, action: PayloadAction<Concept>): State {
  const newConcepts = cloneDeep(state.concepts);
  if (!newConcepts[action.payload.conceptType]) {
    newConcepts[action.payload.conceptType] = [];
  }
  const index = state.concepts[action.payload.conceptType]?.findIndex((concept) => concept.name === action.payload.name);
  if (index == null || index === -1) {
    newConcepts[action.payload.conceptType].push(action.payload);
  } else {
    newConcepts[action.payload.conceptType].splice(index, 1, action.payload);
  }
  return {
    ...state,
    status: 'succeeded',
    concepts: newConcepts,
  };
}
