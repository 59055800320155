import React, { ReactElement, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { IntentsDashboardTableRow, ModelWizardProjectStep } from 'models';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { createModelWorkflow, listModelWorkflow } from 'store/modelWorkflow/asyncThunks';

import {
  CreateModelWorkflowRequest,
  ModelWorkflow,
  TrainIntentModelConfigNeuralModelType as NeuralModelType,
} from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { selectModelWorkflows } from 'store/modelWorkflow/selectors';
import { UserContext } from 'context/UserContext';
import { getId } from 'common/resourceName';
import useUrlParam from 'hooks/useUrlParam';
import { fetchAnnotationSummary } from 'store/annotation/asyncThunks';
import { updateSummaryFilters } from 'store/annotation/annotationSlice';
import { DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { listConcepts } from 'store/concept/asyncThunks';
import { ModelProject, ModelProjectProjectType } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import ModelWizardProjectStepper from 'pages/ModelWizardProject/ModelWizardProjectStepper';
import { selectConceptsFactory } from 'store/concept/selectors';
import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/intent.pb';
import { selectModelProjectById } from 'store/modelProject/selectors';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { IntentsDashboard } from '../IntentsDashboard';
import { ValidationDashboard } from '../ValidationDashboard';
import { TrainingDashboard } from '../TrainingDashboard';
import { EvaluationResultsDashboard } from '../EvaluationResultsDashboard';

export function Intents(): ReactElement {
  const [activeStepIndex, setActiveStepIndex] = useUrlParam<number>('step', 0);
  const activeStepIndexNumber = Number(activeStepIndex);
  const [, setWorkflowId, clearWorkflowId] = useUrlParam<string>('workflowId');
  const [,, clearCompareTo] = useUrlParam<string>('compareTo', '');
  const [selectedRows, setSelectRows] = useState<IntentsDashboardTableRow[]>([]);
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const currentUser = useContext(UserContext);
  const modelWorkflows = useSelector<ModelWorkflow[]>(selectModelWorkflows);
  const intentList = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT));
  const params = useParams<{ customer: string; projectId: string }>();
  const modelProject = useSelector<ModelProject>(selectModelProjectById(params.projectId));
  const customer = useCustomerParams();

  useEffect(() => {
    dispatch(listConcepts({
      parent: customerProfile,
      usecaseId: customer?.usecaseId,
      languageCode: customer?.languageCode,
    }));
    dispatch(updateSummaryFilters({ splits: [DataSplit.TRAIN] }));
    dispatch(fetchAnnotationSummary({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
    }));
    dispatch(updateSummaryFilters({ splits: [DataSplit.TEST] }));
    dispatch(fetchAnnotationSummary({
      parent: customerProfile,
      usecase: `${customerProfile}/usecases/${customer.usecaseId}`,
      languageCode: customer.languageCode,
    }));
  }, [dispatch, customer?.path, activeStepIndexNumber]);

  useEffect(() => {
    dispatch(listModelWorkflow({
      parent: customerProfile,
      filter: { modelProjectId: params.projectId },
    }));
  }, [customerProfile, params.projectId]);

  const onClickValidate = useCallback(() => {
    setActiveStepIndex(1);
  }, [setActiveStepIndex]);

  const onClickPreviewEval = useCallback((workflowName: string) => {
    const id = getId('modelWorkflow', workflowName);
    if (id) {
      setWorkflowId(id);
      setActiveStepIndex(3);
    }
  }, [setActiveStepIndex, setWorkflowId]);

  const filterTypeList = useMemo(() => {
    const filterList = intentList.filter((item) => item.intent?.intentType === (modelProject?.projectType as unknown as IntentIntentType));
    return filterList;
  }, [intentList, modelProject]);

  const onClickTrain = useCallback((modelType: ''|'deberta'|'mdeberta', isContextualModel: boolean) => {
    const modelWorkflowId = crypto.randomUUID();
    if (selectedRows.length > 0) {
      const request: CreateModelWorkflowRequest = {
        parent: customerProfile,
        modelWorkflowId,
        commonInput: {
          title: modelWorkflowId,
          assigneeUserId: currentUser?.id,
        },
        modelWorkflow: {
          modelProject: `${customerProfile}/modelProjects/${params.projectId}`,
          trainIntentModelConfig: {
            intentConfigs: selectedRows.map((row) => ({
              intent: row.conceptTitle,
              buildNeuralClassifier: row.model !== 'regex',
              buildRegexClassifier: row.model !== 'neural',
            })),
            intentTaskType: toIntentTaskType(modelProject?.projectType),
            isContextualModel,
          },
        },
      };
      if (modelType === 'deberta') {
        request.modelWorkflow.trainIntentModelConfig.neuralModelType = NeuralModelType.DEBERTA;
      } else if (modelType === 'mdeberta') {
        request.modelWorkflow.trainIntentModelConfig.neuralModelType = NeuralModelType.MULTILINGUAL_DEBERTA;
      }
      // todo - use StudioSubscription from redux
      dispatch(createModelWorkflow(request)).then(() => {
        setActiveStepIndex(2);
        setSelectRows([]);
        dispatch(listModelWorkflow({ parent: customerProfile, filter: { modelProjectId: params.projectId } }));
      });
    }
  }, [dispatch, customerProfile, selectedRows, setActiveStepIndex, params]);

  const STEPS: ModelWizardProjectStep[] = [
    {
      label: 'Select intents',
      children: <IntentsDashboard selectRows={selectedRows} selectChange={setSelectRows} intentList={filterTypeList} onClickValidate={onClickValidate} />,
    },
    { label: 'Validate', children: <ValidationDashboard selectedRows={selectedRows} selectChange={setSelectRows} onClickTrain={onClickTrain} /> },
    { label: 'Train', children: <TrainingDashboard onClickPreviewEval={onClickPreviewEval} modelWorkflows={modelWorkflows} /> },
    { label: 'Evaluate', children: <EvaluationResultsDashboard modelWorkflows={modelWorkflows} /> },
  ];

  return (
    <ModelWizardProjectStepper
      steps={STEPS}
      stepIndex={activeStepIndexNumber}
      onStepClick={(index) => {
        if (index === activeStepIndexNumber) {
          return;
        }
        setActiveStepIndex(index);
        clearWorkflowId();
        clearCompareTo();
      }}
    />
  );
}

function toIntentTaskType(t: ModelProjectProjectType): string {
  switch (t) {
    case ModelProjectProjectType.AGENT_INTENT:
      return 'agent-intent';
    case ModelProjectProjectType.VISITOR_INTENT:
      return 'visitor-intent';
    case ModelProjectProjectType.CONVERSATION_DRIVER:
      return 'chat-driver';
    default:
      return '';
  }
}
