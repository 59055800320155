import { User } from '@cresta/web-client';
import { ApiStatus } from 'store/types';

/** Actor state. */
export interface State {
  agents: User[];
  status: ApiStatus;
}

/** Initial actor state. */
export const INITIAL_STATE: State = {
  agents: [],
  status: 'idle',
};
