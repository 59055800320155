import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectConceptsFactory } from 'store/concept/selectors';
import { useSelector } from 'hooks/reduxHooks';
import { Text } from '@mantine/core';
import { LabelValue } from '../../types';
import Pill from '../Pill';
import TabPanel from '../TabPanel';
import { eventBus } from '../../utils/event-bus';

import './IntentDetailDrawer.scss';

export default function IntentDetailDrawer() {
  const [activeExamples, setActiveExamples] = useState(LabelValue.POSITIVE);
  const [intentName, setIntentName] = useState(null);
  const allConcepts = useSelector(selectConceptsFactory(ConceptConceptType.INTENT));
  const intent: Concept | undefined = allConcepts.find((concept) => concept.conceptTitle === intentName);

  const updateIntent = (event) => {
    setIntentName(event?.detail || null);
  };

  useEffect(() => {
    eventBus.listen('intentdrawer', updateIntent);

    return function cleanup() {
      eventBus.remove('intentdrawer', updateIntent);
    };
  }, []);

  const onCloseHandler = () => {
    setIntentName(null);
  };

  const examples = {
    positive: intent?.positiveExamples || [],
    negative: intent?.negativeExamples || [],
  };

  const renderSentences = (category: string) => (
    <>
      {examples[category].map((sentence, i) => (
        <div className="sentence" key={sentence}>
          <span>{sentence}</span>
        </div>
      ))}
    </>
  );

  return (
    <Drawer
      title="Intent Detail"
      placement="right"
      onClose={onCloseHandler}
      open={intent != null}
      mask={false}
      width={400}
      className="intent-drawer"
    >
      <div>
        <h1>{intent?.conceptTitle || '-'}</h1>
        <Text size="md" color="gray">Type</Text>
        <Text size="md">{intent?.intent?.intentType || '-'}</Text>
        <Text mt="md" size="md">Description</Text>
        <div>
          <Text size="md">{intent?.description || '-'}</Text>
        </div>
        <Text size="md" mt="md">Labeling Guidelines</Text>
        <div>
          <Text size="md">{intent?.labelingGuideline || '-'}</Text>
        </div>
        <Text size="md" mt="md">Canonical Examples</Text>
        <div>
          <div className="sentence-pills">
            <Pill
              text="Positive"
              count={examples.positive.length}
              value={LabelValue.POSITIVE}
              active={activeExamples === LabelValue.POSITIVE}
              onClick={() => { setActiveExamples(LabelValue.POSITIVE); }}
            />
            <Pill
              text="Negative"
              count={examples.negative.length}
              value={LabelValue.NEGATIVE}
              active={activeExamples === LabelValue.NEGATIVE}
              onClick={() => { setActiveExamples(LabelValue.NEGATIVE); }}
            />
          </div>
        </div>
        <div className="sentence-examples">
          <TabPanel active={activeExamples === LabelValue.POSITIVE}>
            {renderSentences(LabelValue.POSITIVE)}
          </TabPanel>
          <TabPanel active={activeExamples === LabelValue.NEGATIVE}>
            {renderSentences(LabelValue.NEGATIVE)}
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
}
