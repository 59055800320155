import { useHotkeys } from 'hooks/useHotkeys';

export const useNumberHotkeys = (callback: { (key: number): void }) => {
  useHotkeys('1', ({ key }) => {
    callback(+key);
  });
  useHotkeys('2', ({ key }) => {
    callback(+key);
  });
  useHotkeys('3', ({ key }) => {
    callback(+key);
  });
  useHotkeys('4', ({ key }) => {
    callback(+key);
  });
  useHotkeys('5', ({ key }) => {
    callback(+key);
  });
  useHotkeys('6', ({ key }) => {
    callback(+key);
  });
  useHotkeys('7', ({ key }) => {
    callback(+key);
  });
  useHotkeys('8', ({ key }) => {
    callback(+key);
  });
  useHotkeys('9', ({ key }) => {
    callback(+key);
  });
  return null;
};
