import { ConceptChangeService, ListConceptChangesRequest, ListConceptChangesResponse } from '@cresta/web-client/dist/cresta/v1/studio/conceptchange/conceptchange_service.pb';
import { StudioApi } from './studioApi';

export abstract class ConceptChangeApi {
  public static listConceptChanges(
    req: ListConceptChangesRequest,
  ): Promise<ListConceptChangesResponse> {
    return ConceptChangeService.ListConceptChanges(req, StudioApi.getHeaders().initReq);
  }
}
