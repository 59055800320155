import { Concept, SharedConceptConfigDeploymentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import MultiTags from 'components/MultiTags';
import { useCustomerParams } from 'hooks/useCustomerParams';
import React from 'react';

const conceptConfigDeploymentTypeMap = new Map([
  [SharedConceptConfigDeploymentType.GENERIC, 'OOB'],
  [SharedConceptConfigDeploymentType.FINETUNED, 'Customer OOB'],
]);

export function ConceptConfigTags({
  concept,
}: {
  concept: Concept,
 }) {
  const { customerId, profileId } = useCustomerParams();
  const conceptCustomerConfigs = concept.sharedConceptConfig.filter((config) => {
    if (customerId === config.customerId && profileId === config.profileId) {
      return true;
    }
    return false;
  });

  return (
    <MultiTags
      tags={conceptCustomerConfigs?.map((config) => conceptConfigDeploymentTypeMap.get(config.deploymentType))}
      type="conceptTag"
      color="gray"
      size="small"
    />
  );
}
