import { createAsyncThunk } from '@reduxjs/toolkit';
import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { ConversationApi } from 'services/conversationApi';
import { openNotification } from 'components/Notification';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { mapToStudioMessage, ProdConversationApi } from 'services/prodConversationApi';

enum Action {
  CONVERSATIONS_LIST_CONVERSATION_MESSAGES = 'CONVERSATIONS_LIST_CONVERSATION_MESSAGES',
  CONVERSATIONS_LIST_CONVERSATIONS = 'CONVERSATIONS_LIST_CONVERSATIONS',
}

const DEFAULT_PAGE_SIZE = 1000;

/** AsyncThunk for listing (one page of) conversations. */
export const listConversations = createAsyncThunk<Conversation[], {
  parent: string;
  usecase: string;
  languageCode: string;
}>(Action.CONVERSATIONS_LIST_CONVERSATIONS, async ({
  parent,
  usecase,
  languageCode,
}) => {
  try {
    const response = await ConversationApi.listConversations({
      parent,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: { usecase, languageCode },
    });
    return response.conversations;
  } catch (err) {
    openNotification('error', 'Failed to list conversations', undefined, err);
    throw err;
  }
});

/** AsyncThunk for listing messages from the given conversation. */
export const listConversationMessages = createAsyncThunk<Message[], string>(Action.CONVERSATIONS_LIST_CONVERSATION_MESSAGES, async (parent: string) => {
  try {
    const response = await ProdConversationApi.listMessages({
      parent,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    return response.conversationMessages.map(mapToStudioMessage);
  } catch (err) {
    openNotification('error', 'Failed to list conversation messages', undefined, err);
    throw err;
  }
});
