import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import {
  DerivedLabelingTask,
  LabelingTask,
  Message,
  SelectionMethod,
  UserLabelingTaskType,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Select } from 'antd';
import { getId } from 'common/resourceName';
import Annotations from 'components/Annotations';
import CopyableValue from 'components/CopyableValue';
import Pill from 'components/Pill';
import TabPanel from 'components/TabPanel';
import TaskTypeTag from 'components/TaskTypeTag';
import React, { useMemo, useState } from 'react';
import { User } from 'types';
import './LabelingDetailsOverview.scss';

type LabelingDetailsOverviewProps = {
  visible: boolean;
  task: LabelingTask;
  derivedTask: DerivedLabelingTask;
  originalTask: DerivedLabelingTask;
  message: Message;
  users: User[];
  concepts: Concept[];
  getConceptTitle: (conceptId: string) => string;
  setSelectedConceptId: (id: string) => void;
  selectedConceptId: string;
};

type Category = 'positive' | 'negative';

function formatGuidelines(text: string, taskType: UserLabelingTaskType) {
  if (!text) return '';
  // Hide 'UNSURE' and everything after in labeling guidelines for QA Intents tasks
  if ([UserLabelingTaskType.QA_INTENT_PREDICTION, UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL].includes(taskType)) {
    return text.replace(/(UNSURE.*)/s, '');
  }
  return text;
}

export default function LabelingDetailsOverview({
  visible,
  task,
  derivedTask,
  originalTask,
  message,
  users,
  concepts,
  selectedConceptId,
  setSelectedConceptId,
  getConceptTitle,
}: LabelingDetailsOverviewProps) {
  const [activeExamples, setActiveExamples] = useState<Category>('positive');
  const [activeUtterances, setActiveUtterances] = useState<Category>('positive');

  const taskType = derivedTask?.userLabelingTaskType || UserLabelingTaskType.USER_LABELING_TASK_TYPE_UNSPECIFIED;

  const concept = useMemo(() => {
    let selectedConcept = concepts[0];

    if ([
      UserLabelingTaskType.QA_INTENT_PREDICTION,
      UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
      UserLabelingTaskType.USER_LABELING_TASK_TYPE_CALIBRATION,
    ].includes(taskType)) {
      // Source of concept is message concept ids
      const firstQaIntentId = message?.conceptIds[0];
      selectedConcept = concepts.find((concept) => getId('concept', concept.name) === firstQaIntentId);
    }

    if (taskType === UserLabelingTaskType.QA_POLICY && !selectedConcept) {
      // Source of concept for Policy QA
      const firstSDXConceptId = message?.selectionContext?.policyQaContext?.nextIntentConceptIds?.[0];
      selectedConcept = concepts.find((concept) => getId('concept', concept.name) === firstSDXConceptId);
    }

    if (selectedConceptId) {
      selectedConcept = concepts.find((concept) => getId('concept', concept.name) === selectedConceptId);
    }

    return selectedConcept || concepts[0];
  }, [taskType, concepts, selectedConceptId, message]);

  const taskId = task ? getId('abstractTask', task.abstractTask.name) : null;
  const examples = {
    positive: concept?.positiveExamples || [],
    negative: concept?.negativeExamples || [],
  };

  // Only the original task contains utterances,
  // duals task needs to find the original first
  const taskWithSeedExamples = originalTask || derivedTask;
  const utterances = {
    positive: taskWithSeedExamples?.labelingTask.taskData.taskDescriptor.selectionInstruction.dynamicLegacySelectionInstruction?.positiveSeedExamples || [],
    negative: taskWithSeedExamples?.labelingTask.taskData.taskDescriptor.selectionInstruction.dynamicLegacySelectionInstruction?.negativeSeedExamples || [],
  };

  const renderSentences = (category: 'positive' | 'negative') => (
    <>
      {examples[category].map((sentence, i) => (
        <div className="sentence" key={sentence}>
          <span>{sentence}</span>
        </div>
      ))}
    </>
  );

  const renderUtterances = (category: 'positive' | 'negative') => (
    <>
      {utterances[category].map((sentence, i) => (
        <div className="sentence" key={sentence}>
          <span>{sentence}</span>
        </div>
      ))}
    </>
  );

  const renderCalibrationLabel = (
    annotations: Annotation[],
    user: User,
    isMultipleConceots: boolean,
  ) => {
    const note = annotations.length > 0 && annotations[0].value.binaryValue.note;
    return (
      <div className="calibration-context">
        <label>{user?.full_name || 'Unassigned'} labeled it</label>
        <Annotations
          getConceptTitle={getConceptTitle}
          annotations={annotations}
          annotationsDisplayType={isMultipleConceots ? 'concepts-colors' : 'values-colors'}
        />
        <br />
        {note && (
          <>
            <label>Comment:</label>
            <p className="calibration-comment">{note}</p>
          </>
        )}
      </div>
    );
  };

  const selectionContext = message?.selectionContext;
  const selectionInstruction = derivedTask?.labelingTask.taskData.taskDescriptor.selectionInstruction;
  const disagreedAnnotationsContext = selectionContext?.disagreedAnnotationsContext;
  const unsureAnnotationsContext = selectionContext?.unsureAnnotationsContext;
  const isMultipleConceots = concepts.length > 1;

  // Select the right context data (disagreed vs unsure)
  const messageDualAnnotations = (
    disagreedAnnotationsContext
      ? disagreedAnnotationsContext?.dualAnnotations
      : unsureAnnotationsContext?.dualFlaggedAnnotations
  ) || [];
  const messageOriginalAnnotations = (
    disagreedAnnotationsContext
      ? disagreedAnnotationsContext?.originalAnnotations
      : unsureAnnotationsContext?.originalFlaggedAnnotations
  ) || [];
  const assigneeOriginal = users.find(
    (user) =>
      user.id === (disagreedAnnotationsContext
        ? disagreedAnnotationsContext?.originalTaskAssigneeUserId
        : unsureAnnotationsContext?.originalTaskAssigneeUserId),
  );
  const assigneeDual = users.find(
    (user) => user.id === (disagreedAnnotationsContext
      ? disagreedAnnotationsContext?.dualTaskAssigneeUserId
      : unsureAnnotationsContext?.dualTaskAssigneeUserId),
  );

  return (
    <div
      className={`labeling-details-overview ${visible ? 'active' : 'inactive'}`}
    >
      <div className="labeling-details-overview-container">
        {task && derivedTask && (
          <div>
            <div className="overview-header">
              <p>Task ID</p>
              <CopyableValue
                displayValue={`${taskId.substring(0, 4)}...`}
                copiedValue={taskId}
              />
              <TaskTypeTag taskType={derivedTask.userLabelingTaskType} />
            </div>
              {(concepts.length > 1) && (
                <div className="overview-intent-select">
                  <Select
                    showArrow
                    showSearch
                    placeholder="Select intent"
                    size="large"
                    dropdownMatchSelectWidth
                    value={getId('concept', concept?.name)}
                    onChange={(value) => setSelectedConceptId(value)}
                    filterOption={(input, option) =>
                      option.props.searchvalue.indexOf(input) !== -1}
                  >
                    {concepts.map((concept) => (
                      <Select.Option
                        key={concept.name}
                        value={getId('concept', concept.name)}
                        searchvalue={concept.conceptTitle}
                      >
                        <span>{concept.conceptTitle}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {(selectionInstruction?.selectionMethod === SelectionMethod.REGEX
              && selectionInstruction?.regexSelectionInstruction.regexExpression.length > 0) && (
                <div className="overview-section">
                  <p className="section-title">Regex</p>
                  <span className="overview-regex">{derivedTask.labelingTask.taskData.taskDescriptor.selectionInstruction.regexSelectionInstruction.regexExpression}</span>
                </div>
              )}
              {derivedTask.userLabelingTaskType
                === UserLabelingTaskType.USER_LABELING_TASK_TYPE_CALIBRATION && (
                <div className="overview-section">
                  <p className="section-title">Context</p>
                  {messageOriginalAnnotations.length > 0
                    && renderCalibrationLabel(
                      messageOriginalAnnotations,
                      assigneeOriginal,
                      isMultipleConceots,
                    )}
                  {messageDualAnnotations.length > 0
                    && renderCalibrationLabel(
                      messageDualAnnotations,
                      assigneeDual,
                      isMultipleConceots,
                    )}
                </div>
              )}
              {
                concept?.description && (
                <div className="overview-section">
                  <p className="section-title">Description</p>
                  <p>{concept?.description}</p>
                </div>
                )
              }
            <div className="overview-section">
              <p className="section-title">Labeling Guidelines</p>
              <p>{concept?.labelingGuideline ? formatGuidelines(concept?.labelingGuideline, taskType) : '-'}</p>
            </div>
              {
                (examples.positive?.length > 0 || examples.negative?.length > 0) && (
                <div className="overview-section">
                  <p className="section-title">Examples</p>
                  <div>
                    <div className="sentence-pills">
                      <Pill
                        text="Positive"
                        count={examples.positive.length}
                        value="positive"
                        active={activeExamples === 'positive'}
                        onClick={() => {
                          setActiveExamples('positive');
                        }}
                      />
                      <Pill
                        text="Negative"
                        count={examples.negative.length}
                        value="negative"
                        active={activeExamples === 'negative'}
                        onClick={() => {
                          setActiveExamples('negative');
                        }}
                      />
                    </div>
                  </div>
                  <div className="sentence-examples">
                    <TabPanel active={activeExamples === 'positive'}>
                      {renderSentences('positive')}
                    </TabPanel>
                    <TabPanel active={activeExamples === 'negative'}>
                      {renderSentences('negative')}
                    </TabPanel>
                  </div>
                </div>
                )
              }
              {
                (utterances.positive.length > 0 || utterances.negative.length > 0) && (
                <div className="overview-section">
                  <p className="section-title">Seed utterances</p>
                  <div>
                    <div className="sentence-pills">
                      <Pill
                        text="Positive"
                        count={utterances.positive.length}
                        value="positive"
                        active={activeUtterances === 'positive'}
                        onClick={() => {
                          setActiveUtterances('positive');
                        }}
                      />
                      <Pill
                        text="Negative"
                        count={utterances.negative.length}
                        value="negative"
                        active={activeUtterances === 'negative'}
                        onClick={() => {
                          setActiveUtterances('negative');
                        }}
                      />
                    </div>
                  </div>
                  <div className="sentence-examples">
                    <TabPanel active={activeUtterances === 'positive'}>
                      {renderUtterances('positive')}
                    </TabPanel>
                    <TabPanel active={activeUtterances === 'negative'}>
                      {renderUtterances('negative')}
                    </TabPanel>
                  </div>
                </div>
                )
              }
          </div>
        )}
      </div>
    </div>
  );
}
