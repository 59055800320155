import React, { useEffect, useState } from 'react';
import { Modal, TextInput, Button, Group, Stack } from '@mantine/core';
import { ModelProjectTableRow } from 'models/ModelProjectTableRow';

interface UpdateProjectNameModalProps {
  opened: boolean;
  onCancel: () => void;
  projectRow: ModelProjectTableRow;
  handleUpdateProject: (row: ModelProjectTableRow) => void;
}

export function UpdateProjectNameModal({
  opened,
  onCancel,
  projectRow,
  handleUpdateProject,
}: UpdateProjectNameModalProps) {
  const [projectDisplayName, setProjectDisplayName] = useState<string>(projectRow?.displayName || '');
  useEffect(() => {
    setProjectDisplayName(projectRow?.displayName || '');
  }, [projectRow]);
  return (
    <Modal title="Change project name" opened={opened} onClose={onCancel} size="sm" withCloseButton={false}>
      <Stack>
        <TextInput label="Project Name" onChange={(evt) => setProjectDisplayName(evt.currentTarget.value)} value={projectDisplayName} />
        <Group position="right">
          <Button variant="subtle" onClick={onCancel}>Cancel</Button>
          <Button onClick={() => { handleUpdateProject({ ...projectRow, displayName: projectDisplayName }); }}>Save</Button>
        </Group>
      </Stack>
    </Modal>
  );
}
