import React from 'react';
import { Anchor, Badge } from '@mantine/core';
import { DataGrid } from 'components/DataGrid';
import dayjs from 'dayjs';
import externalLinkIcon from 'assets/svg/external-link.svg';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { truncateMiddle } from 'utils';
import { useCustomerParams } from 'hooks/useCustomerParams';

export interface QAScoreSummary {
  qaCount: number | null;
  goodCount: number | null;
  badCount: number | null;
  neutralCount: number | null;
  taskSummary: string;
  dateCompleted: Date | null;
}

interface Props {
    dataSource: QAScoreSummary[]
    isLoading: boolean;
}

export const QAScoreTable = ({ dataSource, isLoading }: Props) => {
  const customer = useCustomerParams();
  const columns = [
    {
      header: 'Task Summary',
      accessorKey: 'taskSummary',
      cell: (info) => {
        const taskSummary = info.getValue() as string;
        return (
          <Anchor
            href={`/${customer.path}/qa/score-view/${taskSummary}`}
            target="_blank"
          >{`${truncateMiddle(taskSummary)} `}<img src={externalLinkIcon} alt="external-link" height={10} />
          </Anchor>
        );
      },
    },
    {
      header: 'Date completed',
      accessorKey: 'dateCompleted',
      cell: (info) => {
        const date = info.getValue() as string;
        if (!date) return 'Incomplete';
        return dayjs(date).format(DATE_TIME_FORMAT);
      },
    },
    {
      header: '# QAed',
      accessorKey: 'qaCount',
      cell: (info) => {
        const qaCount = info.getValue() as number;
        if (!qaCount) return '--';
        return qaCount;
      },
    },
    {
      id: 'good',
      header: () => <Badge color="green">Good</Badge>,
      accessorKey: 'goodCount',
      cell: (info) => {
        const good = info.getValue() as number;
        if (!good) return '--';
        const qaCount = info.row.original.qaCount as number;
        return `${good} (${(good / qaCount * 100).toFixed(0)}%)`;
      },
    },
    {
      id: 'bad',
      header: () => <Badge color="red">Bad</Badge>,
      accessorKey: 'badCount',
      cell: (info) => {
        const bad = info.getValue() as number;
        if (!bad) return '--';
        const qaCount = info.row.original.qaCount as number;
        return `${bad} (${(bad / qaCount * 100).toFixed(0)}%)`;
      },
    },
    {
      id: 'neutral',
      header: () => <Badge color="grape">Neutral</Badge>,
      accessorKey: 'neutralCount',
      cell: (info) => {
        const neutral = info.getValue() as number;
        if (!neutral) return '--';
        const qaCount = info.row.original.qaCount as number;
        return `${neutral} (${(neutral / qaCount * 100).toFixed(0)}%)`;
      },
    },
  ];

  return (
    <DataGrid
      isLoading={isLoading}
      style={{
        border: '1px solid #eaeaea',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
      columns={columns}
      dataSource={dataSource}
    />
  );
};
