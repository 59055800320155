import React from 'react';
import { Group, MantineSize, Text } from '@mantine/core';
import MantineLetterAvatar from 'components/LetterAvatar/MantineLetterAvatar';

interface MantineUserTagProps {
  name: string;
  size: MantineSize;
}

export default function MantineUserTag({ name, size = 'md' }: MantineUserTagProps) {
  return (
    <Group spacing={10}>
      <MantineLetterAvatar name={name} size={size} />
      <Text size={size}>{name}</Text>
    </Group>
  );
}
