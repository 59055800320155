import { AnnotationState } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { getId, getParent } from 'common/resourceName';
import { AnnotationApi } from 'services/annotationApi';

export const getAnnotationsByLabelingTaskName = async ({
  taskName,
  usecase,
  languageCode,
}: {
  taskName: string,
  usecase: string,
  languageCode: string,
}) => {
  const parent = getParent('labelingTask', taskName);
  const taskId = getId('labelingTask', taskName);
  return AnnotationApi.searchAnnotations({
    parent,
    pageSize: 1000,
    searchFilter: {
      taskIds: [taskId],
      usecase,
      languageCode,
      states: [
        AnnotationState.ACTIVE_REVIEWED,
        AnnotationState.ACTIVE_SKIP_REVIEW,
        AnnotationState.ACTIVE_MANUALLY_ADDED,
        AnnotationState.ACTIVE_AUTO_LABELED,
      ],
    },
  });
};

export const handleRemoveAnnotations = async (taskName: string) => {
  const parent = getParent('labelingTask', taskName);
  return AnnotationApi.batchDeprecateAnnotations({
    parent,
    filter: {
      taskIds: [getId('labelingTask', taskName)],
    },
  });
};
