import { BuildFlowNodesRequest, BuildFlowNodesResponse, ExpandNextFlowNodesDistributionRequest, ExpandNextFlowNodesDistributionResponse, FetchAllFlowGraphsForTaskRequest, FetchAllFlowGraphsForTaskResponse, FlowNodeService } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { StudioApi } from './studioApi';

export abstract class FlowNodeApi {
  public static buildFlowNodes(
    req: BuildFlowNodesRequest,
  ): Promise<BuildFlowNodesResponse> {
    return FlowNodeService.BuildFlowNodes(req, StudioApi.getHeaders().initReq);
  }

  public static fetchAllFlowGraphsForTask(
    req: FetchAllFlowGraphsForTaskRequest,
  ): Promise<FetchAllFlowGraphsForTaskResponse> {
    return FlowNodeService.FetchAllFlowGraphsForTask(req, StudioApi.getHeaders().initReq);
  }

  public static expandNextFlowNodesDistribution(
    req: ExpandNextFlowNodesDistributionRequest,
  ): Promise<ExpandNextFlowNodesDistributionResponse> {
    return FlowNodeService.ExpandNextFlowNodesDistribution(req, StudioApi.getHeaders().initReq);
  }
}
