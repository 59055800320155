import React, { useCallback } from 'react';
import { Alert, Button, Paper, Skeleton, Stack, Space, Text } from '@mantine/core';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import CopyableValue from 'components/CopyableValue';
import { selectServingModelsFromAIServices, selectLoadServingModelFromAIServicesStatus } from 'store/modelBuilder/selectors';
import { Model } from 'store/modelBuilder/state';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { ApiStatus } from 'store/types';
import { getServingModelDetailsFromAIServices } from 'store/modelBuilder/asyncThunks';
import { useApiGet } from 'hooks/network';

export function CheckAIServices() {
  const dispatch = useDispatch();
  const { path } = useCustomerParams();
  const apiGet = useApiGet(false);
  const getter = () => apiGet(`${path}/get_serving_model_details_from_ai_services`);
  const models: Model[] = useSelector<Model[]>(selectServingModelsFromAIServices);
  const loadServingModel = useSelector<ApiStatus>(selectLoadServingModelFromAIServicesStatus) === 'loading';

  const get = useCallback(() => {
    dispatch(getServingModelDetailsFromAIServices({
      getter,
    }));
  }, [path]);

  return (
    <Paper p="lg" >
      <Button loading={loadServingModel} onClick={get} >Check Deployed Models in AI Services</Button>
      <Space h="lg" />
      <Alert color="red">
        <Text size="md">For dev use only! This section is to query deployed models directly from AI services.</Text>
        <Text size="md">It may not work during off hours (18:00 - 3:30 PST) when scale-to-0 is enabled for the intent service.</Text>
      </Alert>
      <Space h="lg" />
      {models.length > 0 && (
        <Stack spacing="sm">
          {models.map((model) => (
            <div>{model.displayType}: <CopyableValue displayValue={model.url} copiedValue={model.url} /></div>
          ))}
        </Stack>
      )}
      {loadServingModel && (
        <Stack spacing="sm">
          <Skeleton height={23} width="50%" />
          <Skeleton height={23} width="20%" />
        </Stack>
      )}
    </Paper>
  );
}
