import React, { useState, ReactElement } from 'react';
import './ConceptDetail.scss';
import {
  Concept,
  ConceptConceptSource,
  ConceptConceptTagType,
  ConceptConceptType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import Pill from 'components/Pill';
import MultiTags from 'components/MultiTags';
import { toTitleCase } from 'utils';
import { Button, Flex, Group, Radio, Text, TextInput } from '@mantine/core';
import DeployIntentModal from 'components/ConceptsView/DeployIntentModal/DeployIntentModal';
import { HIDDEN_TAGS } from 'pages/Taxonomy/TaxonomyGroup/TaxonomyTab';
import ConceptCustomersTable from './ConceptCustomersTable';

interface ConceptDetailProps {
  concept: Concept;
  showTags?: boolean;
}

export function ConceptDetail({
  concept,
  showTags,
}: ConceptDetailProps): ReactElement {
  const [activeExamples, setActiveExamples] = useState<'positive' | 'negative'>('positive');
  const [activeRegex, setActiveRegex] = useState<'positive' | 'negative'>('positive');

  const [deployIntentModalVisible, setDeployIntentModalVisible] = useState(false);
  const isCallFlow = concept?.conceptTags?.includes(ConceptConceptTagType.CALL_FLOW);
  const isCrossCustomer = concept?.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
  const isOOB = concept?.conceptSource === ConceptConceptSource.SHARED;

  let positiveRegexes: string[] = [];
  let negativeRegexes: string[] = [];
  switch (concept.conceptType) {
    case ConceptConceptType.CONVERSATION_OUTCOME:
      positiveRegexes = concept.conversationOutcome.regexExpressions;
      negativeRegexes = concept.conversationOutcome.negativeRegexExpressions;
      break;
    case ConceptConceptType.ENTITY:
      positiveRegexes = concept.entity.regexExpressions;
      negativeRegexes = concept.entity.negativeRegexExpressions;
      break;
    case ConceptConceptType.INTENT:
      positiveRegexes = concept.intent.regexExpressions;
      negativeRegexes = concept.intent.negativeRegexExpressions;
      break;
    default:
  }

  return (
    <div className="concept-detail-wrapper">
      <div hidden={concept.conceptType !== ConceptConceptType.SUMMARIZATION} className="concept-field-name">
        Question
      </div>
      <div className="concept-field">
        <p>{concept.summarization?.question}</p>
      </div>
      {isOOB && (
        <div hidden={!showTags} className="concept-field">
          <Group position="apart" mb="sm" align="center">
            <Text weight={500}>Customers</Text>
            <Button variant="light" onClick={() => setDeployIntentModalVisible(true)}>Deploy to prod for a customer</Button>
          </Group>
          {concept?.sharedConceptConfig?.length > 0 && (
          <ConceptCustomersTable concept={concept}/>
          )}
        </div>
      )}
      <div hidden={!showTags} className="concept-field-name">
        Shared Library Tags
      </div>
      <div hidden={!showTags} className="concept-field">
        <MultiTags
          tags={concept?.conceptTags?.filter((tag) => !HIDDEN_TAGS.includes(tag)).map((tag) => toTitleCase(tag))}
          detail
          type="conceptTag"
        />
      </div>
      <div className="concept-field-name">
        Human-readable name:
      </div>
      <div className="concept-field">
        <TextInput readOnly value={concept?.intent?.displayName || ''} />
      </div>
      <div className="concept-field-name">
        Category
      </div>
      <div className="concept-field">
        <Group spacing="sm">
          <Radio value="call-flow" label="Call flow" checked={isCallFlow} readOnly/>
          <Radio value="cross-customer" label="Cross customer labeling" checked={isCrossCustomer} readOnly/>
        </Group>
      </div>
      <div className="concept-field-name">
        Description
      </div>
      <div className="concept-field">
        <p>{concept.description}</p>
      </div>
      <div className="concept-field-name">
        Needs Context
      </div>
      <div className="concept-field">
        <Radio.Group value={concept.intent?.needsContext ? 'true' : 'false'} onChange={(value) => value === 'true'}>
          <Flex gap="xs">
            <Radio value="true" label="Yes" checked={concept.intent?.needsContext} />
            <Radio value="false" label="No" checked={!concept.intent?.needsContext} />
          </Flex>
        </Radio.Group>
      </div>
      <div className="concept-field-name">
        Labeling Guidelines
      </div>
      <div className="concept-field">
        <p>{concept.labelingGuideline}</p>
      </div>
      <div className="concept-field-name">
        Canonical Examples
      </div>
      <div className="pills-wrapper">
        <Pill
          text="Positive"
          count={concept.positiveExamples?.length || 0}
          value="positive"
          active={activeExamples === 'positive'}
          onClick={() => {
            setActiveExamples('positive');
          }}
        />
        <Pill
          text="Negative"
          count={concept.negativeExamples?.length || 0}
          value="negative"
          active={activeExamples === 'negative'}
          onClick={() => {
            setActiveExamples('negative');
          }}
        />
      </div>
      <div className="concept-example-wrapper">
        {
          activeExamples === 'positive'
            ? concept.positiveExamples?.map((example) => <div className="concept-example">{example}</div>)
            : concept.negativeExamples?.map((example) => <div className="concept-example">{example}</div>)
        }
      </div>
      {concept.conceptType !== ConceptConceptType.SUMMARIZATION && (
        <>
          <div className="concept-field-name">
            Regex
          </div>
          <div className="pills-wrapper">
            <Pill
              text="Positive"
              count={positiveRegexes?.length || 0}
              value="positive"
              active={activeRegex === 'positive'}
              onClick={() => {
                setActiveRegex('positive');
              }}
            />
            <Pill
              text="Negative"
              count={negativeRegexes?.length || 0}
              value="negative"
              active={activeRegex === 'negative'}
              onClick={() => {
                setActiveRegex('negative');
              }}
            />
          </div>
          <div className="concept-regex-wrapper">
            {
              activeRegex === 'positive'
                ? positiveRegexes?.map((expr) => <div className="concept-regex">{expr}</div>)
                : negativeRegexes?.map((expr) => <div className="concept-regex">{expr}</div>)
            }
          </div>
        </>
      )}
      <DeployIntentModal
        opened={deployIntentModalVisible}
        onCancel={() => setDeployIntentModalVisible(false)}
        concept={concept}
      />
    </div>
  );
}
