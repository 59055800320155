import React, { forwardRef, Ref, useState } from 'react';
import { Button, Collapse, Group, Indicator, Loader, Overlay, Textarea, Title, Tooltip } from '@mantine/core';
import { NoteState } from '.';

interface NotesProps {
    value: string;
    state: NoteState;
    error?: string;
    onChange: (e: any) => void;
    isComplete: boolean;
}

const ToggleBtn = ({ opened, toggle }: {
    opened: boolean;
    toggle: (opened: boolean) => void;
}) => (
  <Button
    compact
    variant="subtle"
    onClick={() => toggle(!opened)}
    data-cy="tagging-page-notes-btn"
  >
    {opened ? 'Hide' : 'Show'}
  </Button>
);

const GreenCheckIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    width="16"
    height="16"
  >
    <circle style={{ fill: '#25AE88' }} cx="25" cy="25" r="25"/>
    <polyline
      style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }}
      points="38,15 22,33 12,25 "
    />
  </svg>
);

const ErrorIcon = () => (
  <svg
    height="16"
    style={{ overflow: 'visible' }}
    viewBox="0 0 32 32"
    width="32"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"

  >
    <g>
      <g id="Error_1_">
        <g id="Error">
          <circle cx="16" cy="16" id="BG" r="16" style={{ fill: '#D72828' }}/>
          <path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" style={{ fill: '#fff' }}/>
        </g>
      </g>
    </g>
  </svg>
);

export const Notes = forwardRef((props: NotesProps, ref: Ref<HTMLTextAreaElement>) => {
  const { value, state, error, onChange, isComplete } = props;
  const [areNotesShowing, toggleNotesVisibility] = useState(false);

  const getRightSection = (state: NoteState) => {
    if (state === NoteState.LOADING) {
      return <Loader size="xs"/>;
    }
    if (state === NoteState.SUCCESS) {
      return <GreenCheckIcon />;
    }
    if (state === NoteState.ERROR) {
      return (
        <Tooltip label={error}>
          {/* tooltip requires a div wrapper */}
          <div>
            <ErrorIcon />
          </div>
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <>
      <Group position="apart">
        <Title order={3}>Notes for this conversation</Title>
        <Indicator disabled={!value}>
          <ToggleBtn opened={areNotesShowing} toggle={toggleNotesVisibility}/>
        </Indicator>
      </Group>
      <Collapse in={areNotesShowing}>
        <div style={{ position: 'relative' }}>
          {isComplete && <Overlay />}
          <Textarea
            ref={ref}
            placeholder="Add notes here"
            value={value}
            onChange={onChange}
            onKeyDown={(e) => {
              // blur the input when escape is pressed
              if (e.key === 'Escape') {
                e.currentTarget.blur();
              }
            }}
            rightSection={getRightSection(state)}
          />
        </div>
      </Collapse>
    </>
  );
});
