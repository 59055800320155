import { SuggestionActionPayloadSuggestionResult } from '@cresta/web-client/dist/cresta/ai_service/common';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { DerivedLabelingTask, Message, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { FetchLabelingItemsRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { Space, Text, Group, Divider, useMantineTheme, Select, SelectItem, Button, Box, MantineColor, MultiSelect } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { clipText } from 'common/clipText';
import { getId } from 'common/resourceName';
import ConversationPreview from 'components/ConversationPreview';
import CopyableValue from 'components/CopyableValue';
import MessageSuggestions from 'components/LabelingItemsDisplay/MessageSuggestions';
import Loading from 'components/Loading';
import MultiTags from 'components/MultiTags';
import { useSelector } from 'hooks/reduxHooks';
import { useLabelingItems } from 'hooks/useLabelingItems';
import { usePredictions } from 'hooks/usePredictions';
import { useRubrics } from 'hooks/useRubrics';
import { capitalize, round, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { selectAnnotationBundles, selectApiStatus, selectDerivedLabelingTaskFactory } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import { BaseSummaryTable } from '..';

type SuggestionAnnotationsData = {
  key: string;
  conversationId: string;
  utterance: string;
  order: number | 'optimal';
  suggestion: string;
  messageId: string;
  conversationPosition: number;
  rubricsScores: {
    [key: string]: number;
  };
  rubricsTags: {
    [key: string]: string[];
  },
  note: string;
}

interface MetaInfoProps {
  suggestionsAnnotationsData: SuggestionAnnotationsData[],
  model: string,
  rubrics: Rubric[],
}

const rubricColors: MantineColor[] = ['red', 'orange', 'purple', 'green', 'teal', 'lime', 'brown'];

// Model name, score counts and other info
function MetaInfo({ suggestionsAnnotationsData, rubrics, model }: MetaInfoProps) {
  const theme = useMantineTheme();
  const availableRubricIdsSet = new Set(rubrics.map((rubric) => rubric.name.split('/')[1]));
  const rubricsScoresMap = new Map<string, number>(rubrics.map((rubric) => ([rubric.name.split('/')[1], 0])));
  let totalScore = 0;
  let count = 0;
  suggestionsAnnotationsData.forEach((suggestionAnnotations) => {
    if (suggestionAnnotations.rubricsScores) {
      const rubricScoresList = Object.keys(suggestionAnnotations.rubricsScores);
      rubricScoresList.forEach((rubricId) => {
        const rubricScoreTotal = rubricsScoresMap.get(rubricId) || 0;
        const annotationsScore = suggestionAnnotations.rubricsScores[rubricId];
        rubricsScoresMap.set(rubricId, rubricScoreTotal + annotationsScore);
        if (availableRubricIdsSet.has(rubricId)) {
          totalScore += annotationsScore;
        }
      });
      // Exclude optimal as they don't have rubric scores
      if (suggestionAnnotations.order !== 'optimal') {
        count += 1;
      }
    }
  });

  return (
    <Group mt="sm">
      <Group spacing="xs">
        <Text color={theme.colors.gray[5]}>Model:</Text>
        <MultiTags tags={[model]} detail/>
      </Group>
      {suggestionsAnnotationsData?.length > 0 && (
        <>
          <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
          <Group spacing="xs">
            <Text color={theme.colors.gray[5]}>Weighted Score:</Text>
            <Text>{round(totalScore / count / rubrics.length, 2)}</Text>
          </Group>
          {rubrics.map((rubric) => (
            <>
              <Divider style={{ height: '20px' }} color={theme.colors.gray[1]} orientation="vertical" />
              <Group spacing="xs" key={rubric.name}>
                <Text color={theme.colors.gray[5]}>{capitalize(rubric.rubricTitle)}:</Text>
                <Text>{round(rubricsScoresMap?.get(rubric.name.split('/')[1]) / count, 2)}</Text>
              </Group>
            </>
          ))}
        </>
      )}
    </Group>
  );
}

interface SummarySuggestionsTableNewSingleProps extends BaseSummaryTable {}

export function SummarySuggestionsTableNewSingle({ searchTerm, taskId, filtersNode }: SummarySuggestionsTableNewSingleProps) {
  const theme = useMantineTheme();
  const taskApiStatus = useSelector<ApiStatus>(selectApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );

  // Conversations from labeling task
  const [labelingMessages, setLabelingMessages] = useState<Message[]>();

  // For conversation preview
  const [showConversation, setShowConversation] = useState(false);
  const customerProfile = useCustomerProfile();
  const task = useSelector<DerivedLabelingTask>(
    selectDerivedLabelingTaskFactory(`${customerProfile}/labelingTasks/${taskId}`),
  );
  const modelUri = task?.labelingTask.taskData.taskDescriptor.selectionInstruction.suggestionQaRandom?.modelUri;
  const [selectedRowRecord, setSelectedRowRecord] = useState<SuggestionAnnotationsData>();

  // Rubrics for suggestions QA
  const [rubrics] = useRubrics(UserLabelingTaskType.QA_SUGGESTIONS);

  // Conv. position of selected row / message
  const conversationPosition = selectedRowRecord?.conversationPosition;

  // Messages for chat preview
  // We need messages from labeling items because only they have suggestion context
  const [labelingItemData, isLoadingLabelingItemData] = useLabelingItems(`${customerProfile}/labelingTasks/${taskId}`, conversationPosition);
  const messages = labelingItemData?.conversationLabelingItems?.[0]?.messagesAndContexts || [];

  // Filters
  const [orderFilter, setOrderFilter] = useState<string>();
  const [rubricFilters, setRubricFilters] = useState({});
  const [tagsFilters, setTagsFilters] = useState<string[]>();

  const [orderOptions, tagsOptions]: [SelectItem[], SelectItem[]] = useMemo(() => {
    let highestFoundOrder = 0;
    let tags: string[] = [];
    annotationBundles.forEach((bundle) => {
      const { suggestionPosition } = bundle.annotation.rawData.messageRawData;
      if (suggestionPosition && suggestionPosition > highestFoundOrder) {
        highestFoundOrder = suggestionPosition;
      }

      if (bundle.annotation.value.rubricValue?.rubricTags) {
        tags = [...tags, ...bundle.annotation.value.rubricValue?.rubricTags];
      }
    });

    const ordersRange = highestFoundOrder + 1;

    const ordersArray = Array.from(Array(ordersRange), (_, i) => ({
      // + 1 because we want to display order starting from 1, not 0
      label: String(i + 1),
      value: String(i),
    }));
    const ordersOptions = [
      ...ordersArray,
      {
        label: 'Optimal',
        value: 'optimal',
      },
    ];

    const tagsOptions = uniq(tags).map((tag) => ({
      label: tag,
      value: tag,
    }));

    return [ordersOptions, tagsOptions];
  }, [annotationBundles]);

  // Message from selected table row
  const focusedMessage = useMemo(() => messages.find((message) => {
    if (!selectedRowRecord) return null;
    const messageId = message?.v2MessageId;
    return messageId === selectedRowRecord?.messageId;
  }), [messages, selectedRowRecord]);

  const [predictionIds, labelingConversationPositions] = useMemo(() => {
    const ids: string[] = [];
    const convPositions: number[] = [];

    annotationBundles.forEach((bundle) => {
      const { messageRawData } = bundle.annotation.rawData;
      if (messageRawData.predictionId) {
        ids.push(messageRawData.predictionId);
      }

      convPositions.push(bundle.annotation.rawData.conversationPositionNumber);
    });
    return [uniq(ids), uniq(convPositions)];
  }, [annotationBundles]);

  const [predictions] = usePredictions(`${customerProfile}/conversations/-`, predictionIds);

  const fetchLabelingItemsByConversationPositions = useCallback(async () => {
    const promises = labelingConversationPositions.map((position) => {
      const fetchLabelingDataRequest: FetchLabelingItemsRequest = {
        taskName: task?.labelingTask.name,
        conversationNumberStart: position,
        conversationNumberEnd: position + 1,
      };
      return LabelingTaskApi.fetchLabelingItems(fetchLabelingDataRequest);
    });

    const responses = await Promise.all(promises);
    const messagesAndContexts: Message[] = responses.reduce((acc, response) => ([...acc, ...response.conversationLabelingItems?.[0].messagesAndContexts]), []);
    setLabelingMessages(messagesAndContexts);
  }, [task?.labelingTask?.name, labelingConversationPositions]);

  useEffect(() => {
    if (labelingConversationPositions?.length) {
      fetchLabelingItemsByConversationPositions();
    }
  }, [labelingConversationPositions]);

  const labelingMessagesMap = useMemo(() => {
    const convMessageMap = new Map<string, Message>(
      (labelingMessages || []).map((message) => ([`${message.v2ConversationId}:${message.v2MessageId}`, message])),
    );

    return convMessageMap;
  }, [labelingMessages]);

  const predictionsMap = useMemo(() => new Map<string, Prediction>(
    predictions.map((prediction) => [getId('prediction', prediction.name), prediction]),
  ), [predictions]);

  // Table data
  const suggestionsAnnotationsData: SuggestionAnnotationsData[] = useMemo(() => {
    const suggestionAnnotationsMap = new Map<string, Annotation[]>();
    annotationBundles.forEach((bundle) => {
      const { annotation } = bundle;
      const { messageRawData } = annotation.rawData;

      let suggestionUniquePath = `${messageRawData.v2ConversationId}:${messageRawData.v2MessageId}`;

      // Optimal suggestion
      if (!annotation.rawData.messageRawData.predictionId && annotation.value.predictionValue) {
        suggestionUniquePath += ':optimal';
      } else {
        // Rubrics and note annotations
        suggestionUniquePath += `:${messageRawData.predictionId}:${messageRawData.suggestionPosition}`;
      }

      const suggestionAnnotations = suggestionAnnotationsMap.get(suggestionUniquePath);
      if (suggestionAnnotations) {
        suggestionAnnotationsMap.set(suggestionUniquePath, [...suggestionAnnotations, annotation]);
      } else {
        suggestionAnnotationsMap.set(suggestionUniquePath, [annotation]);
      }
    });

    return Array.from(suggestionAnnotationsMap.keys()).map((key) => {
      const [convId, messageId, predictionId, suggestionPosition] = key.split(':');
      const suggestionIndex = suggestionPosition != null ? Number(suggestionPosition) : null;
      const annotations = suggestionAnnotationsMap.get(key);
      const message = labelingMessagesMap?.get(`${convId}:${messageId}`);

      if (predictionId === 'optimal') {
        const predictionValueAnnotation = annotations[0];
        return {
          key,
          conversationId: convId,
          messageId,
          utterance: message?.messageContent,
          order: 'optimal',
          suggestion: predictionValueAnnotation.value.predictionValue.optimalPredictionResult.textResult,
          rubricsScores: {},
          rubricsTags: {},
          note: '',
          conversationPosition: annotations?.[0]?.rawData.conversationPositionNumber,
        };
      }

      const rubricAnnotations = annotations.filter((annotation) => annotation.value.rubricValue);
      const noteAnnotation = annotations.find((value) => value.value.textValue);
      const suggestion: SuggestionActionPayloadSuggestionResult = predictionsMap?.get(predictionId)?.action.suggestionPayload?.results[suggestionIndex];
      const rubricsScores = {};
      const rubricsTags = {};
      rubricAnnotations.forEach((annotation) => {
        rubricsScores[annotation.value.rubricValue.rubricId] = annotation.value.rubricValue.score;
        rubricsTags[annotation.value.rubricValue.rubricId] = annotation.value.rubricValue.rubricTags;
      });
      return {
        key,
        conversationId: convId,
        messageId,
        utterance: message?.messageContent,
        order: suggestionIndex,
        suggestion: suggestion?.text,
        rubricsScores,
        rubricsTags,
        note: noteAnnotation?.value.textValue.value,
        conversationPosition: annotations?.[0]?.rawData.conversationPositionNumber,
      };
    });
  }, [annotationBundles, labelingMessagesMap, predictionsMap, rubrics]);

  const filteredSuggestionsAnnotationsData = useMemo(() => suggestionsAnnotationsData.filter((data) => {
    if (orderFilter && orderFilter !== String(data.order)) {
      return false;
    }

    let shouldHide = false;
    rubrics.forEach((rubric) => {
      const rubricId = rubric.name.split('/')[1];
      const filterScore = rubricFilters[rubricId];
      if (filterScore != null) {
        shouldHide = String(filterScore) !== String(data.rubricsScores[rubricId]);
      }
    });

    if (shouldHide) {
      return false;
    }

    if (tagsFilters?.length > 0) {
      const suggestionAllTags: string[] = Object.values(data.rubricsTags || {}).reduce((acc, tags) => ([...acc, ...tags]), []);
      const hasTagsIntersection = tagsFilters.some((tag) => suggestionAllTags.includes(tag));
      if (!hasTagsIntersection) return false;
    }

    if (searchTerm?.length > 1) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return data.suggestion?.toLowerCase().includes(lowerCaseSearchTerm) || data.utterance?.toLowerCase().includes(lowerCaseSearchTerm);
    }

    return true;
  }), [suggestionsAnnotationsData, orderFilter, rubricFilters, tagsFilters, searchTerm]);

  const columns = useMemo(() => {
    const rubricColumns: ColumnsType<SuggestionAnnotationsData> = (rubrics || []).map((rubric) => {
      const rubricId = rubric.name.split('/')[1];
      return {
        title: capitalize(rubric.rubricTitle),
        dataIndex: ['rubricsScores', rubric.name.split('/')[1]],
        width: '120px',
        key: rubric.rubricTitle,
        sorter: (a, b) => Number(a.rubricsScores[rubricId] || 0) - Number(b.rubricsScores[rubricId] || 0),
        render: (value, row) => {
          if (!value) return <Text color={theme.colors.gray[5]}>N/A</Text>;
          return (
            <Box>
              <Button
                key={value}
                color={rubricColors[value]}
                compact
                size="md"
                variant="light"
              >{value}
              </Button>
              {(row.rubricsTags[rubricId] || []).map((tag) => (
                <Button
                  mt="md"
                  mr="md"
                  size="xs"
                  compact
                  radius="sm"
                  key={tag}
                  variant="light"
                >{tag}
                </Button>
              ))}
            </Box>
          );
        },
      };
    });

    const columns: ColumnsType<SuggestionAnnotationsData> = [
      {
        title: 'Convo ID',
        width: 120,
        dataIndex: 'conversationId',
        key: 'conversationId',
        render: (value, row) => {
          const id = row.conversationId;
          return <CopyableValue displayValue={clipText(id, 6)} copiedValue={id} tooltip={id}/>;
        },
      },
      {
        title: 'Utterance',
        dataIndex: ['utterance'],
        width: '20%',
        key: 'utterance',
      },
      {
        title: 'Order',
        dataIndex: ['order'],
        key: 'order',
        // Start indexes with 1 instead of 0
        render: (value) => (value === 'optimal' ? value : value + 1),
        sorter: (a, b) => {
          const valA = a.order === 'optimal' ? Infinity : a.order;
          const valB = b.order === 'optimal' ? Infinity : b.order;
          return valA - valB;
        },
      },
      {
        title: 'Suggestion',
        dataIndex: ['suggestion'],
        width: '20%',
        key: 'suggestion',
      },
      ...rubricColumns,
      {
        title: 'Weighted score',
        width: '20%',
        key: 'weightedScore',
        render: (value, row) => {
          const values: number[] = row.rubricsScores ? Object.values(row.rubricsScores) : [];
          if (!values.length) return <Text color={theme.colors.gray[5]}>N/A</Text>;

          return round(values.reduce((acc, val) => acc + val, 0) / values.length, 2);
        },
        sorter: (a, b) => {
          const scoresA = a.rubricsScores ? Object.values(a.rubricsScores) : [];
          const scoresB = b.rubricsScores ? Object.values(b.rubricsScores) : [];
          const valA = scoresA.length ? scoresA.reduce((acc, val) => acc + val, 0) / scoresA.length : 0;
          const valB = scoresB.length ? scoresB.reduce((acc, val) => acc + val, 0) / scoresB.length : 0;
          return Number(valA) - Number(valB);
        },
      },
      {
        title: 'Notes',
        key: 'notes',
        width: '20%',
        dataIndex: ['note'],
        render: (value) => <Text color={theme.colors.gray[6]}>{value}</Text>,
      },
    ];

    return columns;
  }, [rubrics]);

  // Predictions for selected message
  const modelPredictions = useMemo(() => {
    const targetMessagePredictionIds = focusedMessage?.selectionContext.suggestionQaContext?.predictionIds || [];
    return predictions.filter((prediction) => prediction.action?.suggestionPayload).filter((prediction) => {
      // Check if the prediction exist in message suggestion context
      if (targetMessagePredictionIds.includes(getId('prediction', prediction.name))) {
        if (prediction?.action?.suggestionPayload?.results?.map((r) => r.ensembleModelId || r.modelId).some((m) => modelUri.includes(m))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }, [predictions, focusedMessage, modelUri]);

  // Table data
  return (
    <div style={{
      width: '100%',
      flex: 1,
    }}
    >
      <MetaInfo
        suggestionsAnnotationsData={suggestionsAnnotationsData}
        model={modelUri}
        rubrics={rubrics}
      />
      <Space h="xl"/>
      <Group mb="lg" position="apart" align="end">
        <>
          <Text>{`${filteredSuggestionsAnnotationsData?.length} total`}</Text>
          <Group>
            <Select
              data={orderOptions}
              value={orderFilter}
              placeholder="All orders"
              clearable
              onChange={(value) => setOrderFilter(value)}
            />
            {rubrics.map((rubric) => {
              const rubricOptions: SelectItem[] = Array.from(Array(rubric.numericRubric.maxScore), (_, i) => ({
                label: String(i + 1),
                value: String(i + 1),
              }));

              return (
                <Select
                  data={rubricOptions}
                  value={rubricFilters[rubric.name]}
                  placeholder={capitalize(rubric.rubricTitle)}
                  clearable
                  style={{
                    width: 140,
                  }}
                  onChange={(value) => setRubricFilters({
                    ...rubricFilters,
                    [rubric.name.split('/')[1]]: value,
                  })}
                />
              );
            })}
            <MultiSelect
              data={tagsOptions}
              value={tagsFilters}
              style={{
                width: 140,
              }}
              placeholder="All tags"
              onChange={(tags) => setTagsFilters(tags)}
            />
            {filtersNode && filtersNode}
          </Group>
        </>
      </Group>
      <div style={{
        display: 'flex',
      }}
      >
        <Table
          rowKey={(row) => row.key}
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
          style={{
            width: '100%',
          }}
          columns={columns}
          dataSource={filteredSuggestionsAnnotationsData}
          onRow={(record) => ({
            onClick: (e) => {
              if (!record.messageId) return;
              setSelectedRowRecord(record);
              setShowConversation(true);
            },
          })}
        />
        <ConversationPreview
          messages={messages}
          focusedMessageId={focusedMessage?.v2MessageId}
          onClose={() => setShowConversation(false)}
          showConversation={showConversation}
          loading={isLoadingLabelingItemData}
        >
          <div>
            <MessageSuggestions
              predictions1={modelPredictions}
              predictions2={[]}
              hasSecondModel={false}
            />
          </div>
        </ConversationPreview>
      </div>
    </div>
  );
}
