import { CrestaAuthImpl } from '@cresta/client-auth';
import { AuthProtoScope, AuthServiceClient, MetaService, TokenService, TokenState, withServiceConfig } from '@cresta/web-client';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { map, of, first } from 'rxjs';

type AuthStatus = 'in' | 'out' | 'loading';

interface JwtTokenData extends JwtPayload {
  data: {
    username: string,
    firstname: string,
    lastname: string,
    displayname: string,
  },
}

/** Authentication info. */
export interface Auth {
  status: AuthStatus;
  email?: string;
  firstname?: string;
  lastname?: string;
  display_name?: string;
  token?: string;
}

enum AppType {
  STUDIO,
  DIRECTOR
}

const ACCESS_TOKEN_KEY = 'oktaAccessToken';
const DIRECTOR_ACCESS_TOKEN_KEY = 'oktaProdAccessToken';

export function isProd() {
  const url = window.location.host;
  return !url.includes('localhost') && !url.includes('staging');
}

export function getAuthEndpoint() {
  if (process.env.REACT_APP_LOCALHOST_PRODUCTION?.toLowerCase() === 'true' || isProd()) {
    return 'https://auth.cresta.com';
  }
  return 'https://auth.chat-staging.cresta.ai'; // All staging clusters share the same auth server.
}

export function getOktaAccessToken(app: AppType = AppType.STUDIO): string {
  return localStorage.getItem(app === AppType.DIRECTOR ? DIRECTOR_ACCESS_TOKEN_KEY : ACCESS_TOKEN_KEY);
}

/** Set Okta access token in localStorage. */
export function setOktaAccessToken(token: string, app: AppType = AppType.STUDIO) {
  localStorage.setItem(app === AppType.DIRECTOR ? DIRECTOR_ACCESS_TOKEN_KEY : ACCESS_TOKEN_KEY, token);
}

/** Remove Okta access token from localStorage. */
export function removeOktaAccessToken(app: AppType = AppType.STUDIO) {
  localStorage.removeItem(app === AppType.DIRECTOR ? DIRECTOR_ACCESS_TOKEN_KEY : ACCESS_TOKEN_KEY);
}

/** Get Auth from Okta access token in localStorage. */
export function getAuth(): Auth {
  if (process.env.REACT_APP_USE_API_KEY && process.env.REACT_APP_API_KEY) {
    return { status: 'in' };
  }
  const token = getOktaAccessToken();
  if (token) {
    // Extract user information from the token.
    const { data: { username, firstname, lastname, displayname } } = jwt_decode<JwtTokenData>(token);
    return {
      status: 'in',
      token,
      firstname,
      lastname,
      display_name: displayname,
      email: username,
    };
  } else {
    return {
      status: 'out',
    };
  }
}

function isTokenExpired(app: AppType = AppType.STUDIO): boolean {
  const token = getOktaAccessToken(app);
  if (!token) {
    return true;
  }
  const { exp } = jwt_decode<JwtTokenData>(token);
  return Date.now() >= exp * 1000;
}

export function signOut() {
  removeOktaAccessToken();
  removeOktaAccessToken(AppType.DIRECTOR);
  window.location.href = `/login?message=expired&redirect=${window.location.pathname}`;
}

/** Get access token or logout if it's expired. */
/** AppType.Director sets the token to a customer-scoped token. */
export function getAccessToken(customerSpecific?: boolean) {
  if (process.env.REACT_APP_USE_API_KEY) {
    return `ApiKey ${process.env.REACT_APP_API_KEY}`;
  }
  if (isTokenExpired()) {
    signOut();
  }
  if (customerSpecific) {
    return `Bearer ${getOktaAccessToken(AppType.DIRECTOR)}`;
  }
  return `Bearer ${getOktaAccessToken()}`;
}

export function getClientSubscriptionToken() {
  if (process.env.REACT_APP_USE_API_KEY) {
    return '';
  }
  if (isTokenExpired()) {
    signOut();
  }
  return getOktaAccessToken();
}

/** Get access token for calling requests from director */
export async function getProdAccessToken() {
  if (isTokenExpired(AppType.DIRECTOR)) {
    throw new Error('Prod token expired');
  } else {
    return `Bearer ${getOktaAccessToken(AppType.DIRECTOR)}`;
  }
}

/** Exchange Studio access token for prod access token */
export async function exchangeToken(studioAccessToken: string, customerId: string, profileId: string) {
  return new Promise((resolve, reject) => {
    const tokenService = new TokenService();

    // Create MetaService
    const metaService = new MetaService(
      withServiceConfig(
        {
          authenticated$: () =>
            tokenService.token$.pipe(
              map((token) => token.state === TokenState.VALID),
            ),
          endpoint$: () => of(getAuthEndpoint()),
          token$: () => tokenService.validToken$().pipe(map((t) => t.accessToken)),
        },
      ),
    );

    // Create AuthClient
    const authClient = new AuthServiceClient(metaService);

    // Exchange token
    const observable = authClient.exchangeCrestaToken$({
      customerId,
      accessToken: studioAccessToken,
      authConfigId: `profile-${profileId}`,
      metadata: {
        scopes: [
          AuthProtoScope.CHAT,
          AuthProtoScope.VOICE,
          AuthProtoScope.INTERNAL_ONLY,
        ],
      },
    });

    observable.pipe(first()).subscribe({
      next: (token) => {
        setOktaAccessToken(token?.authToken?.accessToken, AppType.DIRECTOR);
        return resolve(true);
      },
      error: (err) => {
        signOut();
        return reject(err);
      },
    });
  });
}

export async function signInPopup(customerId: string, profileId: string): Promise<boolean> {
  try {
    const auth = new CrestaAuthImpl({
      name: 'default',
      config: {
        // The customer ID that you will be signing in to. We use Cresta as to exchange a Cresta token for a customer token later on.
        customerId: 'cresta',
        // This doesn't need to be specified. By default this URL is used.
        authEndpoint: getAuthEndpoint(),
        customerOrigin: isProd() ? 'https://login.cresta.com' : 'https://login.chat-staging.cresta.ai',
      },
    });

    // If token isn't expired, return true so that navigation can continue.
    if (!isTokenExpired()) {
      return true;
    }

    await auth.signInWithPopup({
      // Optional width and height parameters for the popup.
      popupWidth: 600,
      popupHeight: 800,
      // Additional Auth parameters to append to the authorization URL.
      additionalAuthUriParameters: {
        prompt: 'login',
      },
    });

    const accessToken = await auth.getAccessToken();
    setOktaAccessToken(accessToken);
    return true;
  } catch (error) {
    signOut();
    return false;
  }
}
