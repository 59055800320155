/* eslint-disable no-await-in-loop */
import {
  DocumentKBDocumentType,
  DocumentRelease,
  DocumentReleaseService,
  DocumentReleaseState,
  ListDocumentReleasesRequest,
  ListDocumentReleasesRequestSortDirection,
  ListDocumentReleasesRequestSortField,
} from '@cresta/web-client';
import { ProdApi } from './prodApi';

export abstract class ProdDocumentReleaseApi {
  public static async listDocumentReleases(parent: string): Promise<DocumentRelease[]> {
    const request: ListDocumentReleasesRequest = {
      parent,
      includedStates: [DocumentReleaseState.STATE_ACTIVE, DocumentReleaseState.STATE_INACTIVE],
      includedTypes: [DocumentKBDocumentType.KB_DOCUMENT_TYPE_AGENT_RESPONSE],
      sortField: ListDocumentReleasesRequestSortField.SORT_FIELD_UPDATE_TIME,
      sortDirection: ListDocumentReleasesRequestSortDirection.SORT_DIRECTION_DESCENDING,
    };
    const prodHeaders = await ProdApi.getHeaders();
    const response = await DocumentReleaseService.ListDocumentReleases(
      request,
      prodHeaders?.initReq,
    );

    return response.documentReleases;
  }
}
