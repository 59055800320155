import {
  AttachManualWorkRequest,
  AttachManualWorkResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/workflows/modelworkflow/model_workflow.pb';
import { ModelWorkflowApi } from 'services/modelWorkflowApi';
import { getErrorMessage } from 'utils/getErrorMessage';

export const attachQATaskIdToWorkflowName = async (workflowName: string, qaTaskId: string) => {
  let res: AttachManualWorkResponse | { error?: string };
  try {
    if (!workflowName) {
      throw new Error('Workflow name is required');
    }
    if (!qaTaskId) {
      throw new Error('QA task ID is required');
    }
    const attachManualWorkRequest: AttachManualWorkRequest = {
      workflow: workflowName,
      manualWork: {
        qaTaskId,
      },
    };
    res = await ModelWorkflowApi.attachManualWork(attachManualWorkRequest);
  } catch (e) {
    const error = getErrorMessage(e);
    console.warn('Error attaching QA task to workflow', error);
    res = { error };
  }
  return res;
};
