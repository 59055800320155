import { TagTagType, ConceptConceptType, CreateConceptRequest, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { v4 as uuid } from 'uuid';

export function conceptTitleFormatter(conceptName:string) {
  return conceptName.trim().split(' ').join('_').toLowerCase();
}

interface NewTagConfig {
  customerProfile: string;
  tagType:TagTagType
}

export const createNewTagRequest = (query:string, { customerProfile, tagType }: NewTagConfig) => {
  const conceptId = uuid();
  const conceptTitle = conceptTitleFormatter(query);
  const conceptName = `${customerProfile}/concepts/${conceptId}`;

  const concept = {
    name: conceptName,
    conceptTitle,
    conceptType: ConceptConceptType.TAG,
    state: ConceptState.CREATED,
    description: '',
    labelingGuideline: '',
    tag: {
      tagType,
    },
  };
  return concept;
};

export const createNewConceptRequest = (concept, customerProfile, customer, currentUser) => {
  const request: CreateConceptRequest = {
    parent: customerProfile,
    conceptId: getId('concept', concept.name),
    userResourceName: currentUser?.name,
    concept: {
      ...concept,
      usecaseId: customer.usecaseId,
      languageCode: customer.languageCode,
    },
  };
  return request;
};
