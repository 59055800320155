import { createAsyncThunk } from '@reduxjs/toolkit';
import { User, GetUserRequest, CreateUserRequest, ListUsersRequest } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { UserApi } from 'services/userApi';
import { openNotification } from 'components/Notification';

enum Action {
  USER_LIST_USER = 'USER_LIST_USER',
  USER_GET_USER = 'USER_GET_USER',
  USER_CREATE_USER = 'USER_CREATE_USER',
}

// Assuming that users per profile doesn't exceed 1000.
const DEFAUL_PAGE_SIZE = 1000;

/** AsyncThunk for ListUsers API. */
export const listUser = createAsyncThunk<User[], string>(Action.USER_LIST_USER, async (profile: string) => {
  try {
    const request: ListUsersRequest = { parent: profile, pageSize: DEFAUL_PAGE_SIZE };
    const response = await UserApi.listUsers(request);
    return response.users;
  } catch (err) {
    openNotification('error', 'Failed to list users', undefined, err);
    throw err;
  }
});

/** AsyncThunk for GetUser API. */
export const getUser = createAsyncThunk<User, string>(Action.USER_GET_USER, async (name: string) => {
  try {
    const request: GetUserRequest = { name };
    const response = await UserApi.getUser(request);
    return response.user;
  } catch (err) {
    openNotification('error', 'Failed to get user', undefined, err);
    throw err;
  }
});

/** AsyncThunk for CreateUser API. */
export const createUser = createAsyncThunk<User, CreateUserRequest>(Action.USER_CREATE_USER, async (request: CreateUserRequest) => {
  try {
    const response = await UserApi.createUser(request);
    return response.user;
  } catch (err) {
    openNotification('error', 'Failed to create user', undefined, err);
    throw err;
  }
});
