import { Text, Box, Stack, Flex, createStyles, Group, Button } from '@mantine/core';
import React, { useMemo } from 'react';
import { Cluster } from '@cresta/web-client/dist/cresta/v1/studio/cluster/cluster_service.pb';
import SidebarCluster from './SidebarClusterItem';

interface DiscoverySidebarProps {
  clusters: Cluster[];
  rootSum: number;
  isEditingMoments: boolean;
  checkedClusterIds: string[];
  handleHoverCluster: (cluster: Cluster) => void;
  handleSelectCluster: (cluster: Cluster) => void;
  handleHideCluster: (cluster: Cluster, value: boolean) => void;
  handleCheckCluster: (cluster: Cluster, value: boolean) => void;
  handleMergeClusters: () => void;
  handleUnmergeClusters: (cluster: Cluster) => void;
  setIsEditingMoments: (value: boolean) => void;
}

export function DiscoverySidebar({
  clusters,
  isEditingMoments,
  rootSum,
  checkedClusterIds,
  handleHoverCluster,
  handleSelectCluster,
  handleHideCluster,
  handleCheckCluster,
  handleMergeClusters,
  handleUnmergeClusters,
  setIsEditingMoments,
}: DiscoverySidebarProps) {
  const useStyles = createStyles((theme) => ({
    flexContainer: {
      position: 'relative',
      overflow: 'auto',
      height: '100%',
    },
    hoverableItem: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.blue[0],
      },
    },
  }));
  const { classes } = useStyles();

  // Ids of clusters that were merged into one
  const mergedClusterIdsSet = useMemo(() => {
    const set = new Set<string>();
    clusters.forEach((cluster) => {
      if (cluster.childClustersReferenceIds) {
        cluster.childClustersReferenceIds.forEach((id) => set.add(id));
      }
    });
    return set;
  }, [clusters]);

  // Filtered clusters are clusters that are not hidden, not merged and have more than 0 item,
  // Hidden clusters are those that have isHidden set to true
  const [filteredClusters, hiddenClusters] = useMemo(() => {
    const filtered = [];
    const hidden = [];
    clusters.forEach((cluster) => {
      if (cluster.clusterItemCount > 0 && !mergedClusterIdsSet.has(cluster.name)) {
        if (cluster.isHidden) {
          hidden.push(cluster);
        } else {
          filtered.push(cluster);
        }
      }
    });

    return [filtered, hidden];
  }, [clusters]);

  const checkedClusterIdsSet = new Set(checkedClusterIds);

  const clustersMap = useMemo(() => {
    const map = new Map<string, Cluster>();
    clusters.forEach((cluster) => {
      map.set(cluster.name, cluster);
    });
    return map;
  }, [clusters]);

  return (
    <Flex className={classes.flexContainer} direction="column">
      <Box>
        {filteredClusters?.length > 0 && (
          <Group position="apart" px="md" py="xs">
            {isEditingMoments ? (
              <>
                <Text size="md">Edit clusters</Text>
                <Group spacing="xs">
                  <Button compact color="gray" variant="subtle" onClick={() => setIsEditingMoments(false)}>Cancel</Button>
                  {checkedClusterIds.length > 1 ? (
                    <Button compact variant="light" onClick={() => handleMergeClusters()}>Merge</Button>
                  ) : (
                    <Button compact variant="light" onClick={() => setIsEditingMoments(false)}>Save</Button>
                  )}
                </Group>
              </>
            ) : (
              <>
                <Text size="md">{filteredClusters?.length} call drivers</Text>
                <Button compact variant="light" onClick={() => setIsEditingMoments(true)}>Edit</Button>
              </>
            )}
          </Group>
        )}
        <Flex
          px="md"
          py="xs"
          justify="flex-end"
          sx={(theme) => ({
            borderTop: `solid 1px ${theme.colors.gray[0]}`,
            borderBottom: `solid 1px ${theme.colors.gray[0]}`,
          })}
        >
          <Text size="md" color="dimmed" style={{ flex: 1 }}>Call Driver</Text>
          <Text size="md" color="dimmed" align="right" style={{ width: 60 }}>Count</Text>
          <Text size="md" color="dimmed" align="right" style={{ width: 60 }}>%</Text>
        </Flex>
      </Box>
      <Box py="md" style={{ flex: 1, overflow: 'auto' }}>
        <Stack spacing={0}>
          {filteredClusters.map((cluster) => (
            <SidebarCluster
              clustersMap={clustersMap}
              key={cluster.name}
              cluster={cluster}
              isEditingMoments={isEditingMoments}
              rootSum={rootSum}
              handleHoverCluster={handleHoverCluster}
              handleSelectCluster={handleSelectCluster}
              handleHideCluster={handleHideCluster}
              handleCheckCluster={handleCheckCluster}
              handleUnmergeClusters={handleUnmergeClusters}
              isChecked={checkedClusterIdsSet.has(cluster.name)}
            />
          ))}
        </Stack>
        {hiddenClusters?.length > 0 && (
          <Box>
            <Flex
              px="md"
              py="xs"
              justify="flex-end"
              sx={(theme) => ({
                borderTop: `solid 1px ${theme.colors.gray[0]}`,
                borderBottom: `solid 1px ${theme.colors.gray[0]}`,
                backgroundColor: theme.colors.gray[0],
              })}
            >
              <Text size="md" color="dimmed" style={{ flex: 1 }}>{hiddenClusters?.length} hidden items</Text>
              <Text size="md" color="dimmed" align="right" style={{ width: 60 }}>Count</Text>
              <Text size="md" color="dimmed" align="right" style={{ width: 60 }}>%</Text>
            </Flex>
            <Stack spacing={0}>
              {hiddenClusters.map((cluster) => (
                <SidebarCluster
                  clustersMap={clustersMap}
                  key={cluster.name}
                  cluster={cluster}
                  isEditingMoments={isEditingMoments}
                  rootSum={rootSum}
                  handleHoverCluster={handleHoverCluster}
                  handleSelectCluster={handleSelectCluster}
                  handleHideCluster={handleHideCluster}
                  handleCheckCluster={handleCheckCluster}
                  handleUnmergeClusters={handleUnmergeClusters}
                  isChecked={checkedClusterIdsSet.has(cluster.name)}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>
    </Flex>
  );
}
