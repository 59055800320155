import React, { useCallback, useMemo } from 'react';
import { DerivedLabelingTask, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Drawer } from 'antd';
import TaskTypeTag, { UnionTaskType } from 'components/TaskTypeTag';
import { Concept, ConceptConceptType, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConceptsMapFactory } from 'store/concept/selectors';
import { useSelector } from 'hooks/reduxHooks';
import ConceptTag from 'components/ConceptTag';
import { Button, Group, Space, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { DateRangeSelector } from '@cresta/web-client/dist/cresta/v1/studio/common/common.pb';
import CopyableValue from 'components/CopyableValue';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { selectSets } from 'store/set/selectors';
import { getId } from 'common/resourceName';
import { Set } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import styles from './styles.module.scss';

const EXAMPLES_VIEW_TASK_TYPES: UnionTaskType[] = [
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING,
  UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING,
];

interface LabelingTask extends Omit<DerivedLabelingTask, 'userLabelingTaskType'> {
  userLabelingTaskType?: UnionTaskType;
}
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  derivedTask: LabelingTask | undefined;
  duplicateTaskConfig?: (task: DerivedLabelingTask) => void;
}

function DataRow({ label, value }) {
  return (
    <div>
      <Text
        sx={(theme) => ({
          color: theme.colors.gray[5],
        })}
        size="md"
      >{label}
      </Text>
      <Text size="md" color="black">{value || 'N/A'}</Text>
    </div>
  );
}

// Display dump of key and values
function TableDisplay({ dataObject }: { dataObject: { [key: string]: unknown } }) {
  const records = Object.keys(dataObject || {}).map((key) => <DataRow key={key} label={key} value={String(dataObject[key])} />);
  return <Stack>{records}</Stack>;
}

// Display from and to datetimes
function DateRangeDisplay({ dateRangeSelector }: { dateRangeSelector: DateRangeSelector }) {
  if (dateRangeSelector) {
    return (
      <>
        <DataRow label="From" value={dayjs(dateRangeSelector.after).format(DATE_TIME_FORMAT)} />
        <DataRow label="To" value={dayjs(dateRangeSelector.before).format(DATE_TIME_FORMAT)} />
      </>
    );
  } else {
    return null;
  }
}

// Display data based on selection instruction type
function TaskDataDisplay({ derivedTask, setsMap }: { derivedTask: LabelingTask, setsMap?: Map<string, Set> }) {
  const selectionInstruction = derivedTask?.labelingTask.taskData.taskDescriptor.selectionInstruction;
  if (!selectionInstruction) return null;

  if (selectionInstruction.policyQaInstruction) {
    const instruction = selectionInstruction.policyQaInstruction;
    return (
      <>
        {instruction.conversations?.length > 0 && (
          <DataRow label="Conversations" value={instruction.conversations.join('\n')} />
        )}
        <Stack>
          <DateRangeDisplay dateRangeSelector={instruction.dateRangeSelector} />
          <DataRow label="Max conversation count" value={instruction.maxConversationCount} />
          <TableDisplay dataObject={instruction.backtestInput} />
        </Stack>
      </>
    );
  }

  if (selectionInstruction.intentPredictionRandom) {
    const instruction = selectionInstruction.intentPredictionRandom;
    return (
      <Stack>
        <DateRangeDisplay dateRangeSelector={instruction.dateRangeSelector} />
        <DataRow label="Chat driver model URI" value={instruction.chatDriverModelUri} />
        <DataRow label="Agent model URI" value={instruction.agentModelUri} />
        <DataRow label="Visitor model URI" value={instruction.visitorModelUri} />
        <DataRow label="Max prediction count" value={instruction.maxPredictionCount} />
      </Stack>
    );
  }

  if (selectionInstruction.suggestionQaRandom) {
    const instruction = selectionInstruction.suggestionQaRandom;
    return (
      <Stack>
        <DateRangeDisplay dateRangeSelector={instruction.dateRangeSelector} />
        <DataRow label="Max conversation count" value={instruction.maxConversationCount} />
        <DataRow label="Model URI" value={instruction.modelUri} />
        <DataRow label="Model 2 URI" value={instruction.modelUri2} />
      </Stack>
    );
  }

  if (selectionInstruction.regexSelectionInstruction) {
    const instruction = selectionInstruction.regexSelectionInstruction;
    return (
      <Stack>
        {instruction.dateRangeSelector && (
        <>
          <DataRow label="From" value={dayjs(instruction.dateRangeSelector.after).format(DATE_TIME_FORMAT)} />
          <DataRow label="To" value={dayjs(instruction.dateRangeSelector.before).format(DATE_TIME_FORMAT)} />
        </>
        )}
        <DataRow label="Min conversation length" value={instruction.minConversationLength} />
        <DataRow label="Number of messages" value={instruction.numMessages} />
        <DataRow label="Regex" value={instruction.regexExpression} />
        <DataRow label="Message sets" value={instruction.messageSetIds.map((setId) => setsMap.get(setId)?.setTitle).join(',')} />
      </Stack>
    );
  }

  if (selectionInstruction.randomSelectionInstruction) {
    const instruction = selectionInstruction.randomSelectionInstruction;
    return (
      <Stack>
        {instruction.dateRangeSelector && (
        <>
          <DataRow label="From" value={dayjs(instruction.dateRangeSelector.after).format(DATE_TIME_FORMAT)} />
          <DataRow label="To" value={dayjs(instruction.dateRangeSelector.before).format(DATE_TIME_FORMAT)} />
        </>
        )}
        <DataRow label="Min conversation length" value={instruction.minConversationLength} />
        <DataRow label="Chat count" value={instruction.chatCount} />
        <DataRow label="Conversations sets" value={instruction.conversationSetIds.map((setId) => setsMap.get(setId)?.setTitle).join(',')} />
      </Stack>
    );
  }

  if (selectionInstruction.summarizationInstruction) {
    const instruction = selectionInstruction.summarizationInstruction;
    return (
      <Stack>
        {instruction.dateRangeSelector && (
        <>
          <DataRow label="From" value={dayjs(instruction.dateRangeSelector.after).format(DATE_TIME_FORMAT)} />
          <DataRow label="To" value={dayjs(instruction.dateRangeSelector.before).format(DATE_TIME_FORMAT)} />
        </>
        )}
        <DataRow label="Min conversation length" value={instruction.minConversationLength} />
        <DataRow label="Conversation count" value={instruction.conversationCount} />
        {instruction.modelUris && (
        <>
          <Text
            sx={(theme) => ({
              color: theme.colors.gray[5],
            })}
            size="md"
          >Models
          </Text>
          {instruction.modelUris.map((modelUri) =>
            <div><CopyableValue copiedValue={modelUri} displayValue={modelUri} /></div>)}
        </>
        )}
        {instruction.modelUri && <DataRow label="Model A" value={instruction.modelUri} />}
        {instruction.modelUri2 && <DataRow label="Model B" value={instruction.modelUri2} />}
        <DataRow label="Preselected CSV Uri" value={instruction.preselectedCsvUri} />
      </Stack>
    );
  }

  return null;
}

const TaskConfigurationDrawer: React.FC<IProps> = ({ isOpen, onClose, derivedTask, duplicateTaskConfig }) => {
  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);
  const allSets = useSelector(selectSets);
  const allSetsMap = useMemo(() => new Map(allSets.map((set) => [getId('set', set.name), set])), [allSets]);
  const concepts = (derivedTask?.labelingTask.taskData.taskDescriptor.conceptIds || []).map((conceptId) => {
    const concept = allConceptsMap.get(conceptId);
    return concept;
  }).filter((concept) => concept);

  const taskError = derivedTask?.labelingTask.abstractTask.errMessage;

  const canDuplicateTask = derivedTask?.userLabelingTaskType !== UserLabelingTaskType.USER_LABELING_TASK_TYPE_CALIBRATION;

  const getConceptRole = useCallback((concept: Concept): 'agent' | 'visitor' =>
    (concept?.intent.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor'), [allConceptsMap]);

  const TextWrapper = (text: string) => (
    <div className={styles.textWrapper} key={text}>
      {text}
    </div>
  );

  const positiveExamples = useMemo(() => {
    if (derivedTask?.userLabelingTaskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING) {
      const positive = derivedTask.labelingTask.taskData.taskDescriptor.selectionInstruction.dynamicLegacySelectionInstruction?.positiveSeedExamples;
      if (positive?.length) {
        return positive;
      }
    }
    return [];
  }, [derivedTask, concepts]);

  const negativeExamples = useMemo(() => {
    if (derivedTask?.userLabelingTaskType === UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING) {
      const negative = derivedTask.labelingTask.taskData.taskDescriptor.selectionInstruction.dynamicLegacySelectionInstruction?.negativeSeedExamples;
      if (negative?.length) {
        return negative;
      }
    }
    return [];
  }, [derivedTask, concepts]);

  return (
    <Drawer
      title={null}
      open={isOpen}
      onClose={onClose}
      closable={false}
      className={styles.taskConfigDrawer}
    >
      <div className={styles.taskConfigDrawerWrapper}>
        <div className={styles.taskConfigDrawerTitle}>Task configuration</div>
        <div className={styles.taskConfigDrawerType}>
          <div className={styles.taskConfigDrawerTypeTitle}>
            Task type
          </div>
          <TaskTypeTag taskType={derivedTask?.userLabelingTaskType} />
        </div>
        {concepts.length > 0 && (
          <div className={styles.taskConfigDrawerIntent}>
            <div className={styles.taskConfigDrawerIntentTitle}>
              Intents
            </div>
            <Space h="sm" />
            <Group spacing="xs">
              {concepts.map((concept) => (
                <ConceptTag
                  key={concept?.name}
                  role={concept?.conceptType === ConceptConceptType.INTENT ? getConceptRole(concept) : undefined}
                  value={concept?.conceptTitle}
                />
              ))}
            </Group>
          </div>
        )}
        {EXAMPLES_VIEW_TASK_TYPES.includes(derivedTask?.userLabelingTaskType) && (
          <>
            {positiveExamples?.length > 0 && (
              <div className={styles.taskConfigDrawerExamples}>
                <div className={styles.taskConfigDrawerExamplesTitle}>
                  Positive examples
                </div>
                {positiveExamples.map((item) => TextWrapper(item))}
              </div>
            )}
            {negativeExamples?.length > 0 && (
              <div className={styles.taskConfigDrawerExamples}>
                <div className={styles.taskConfigDrawerExamplesTitle}>
                  Negative examples
                </div>
                {negativeExamples.map((item) => TextWrapper(item))}
              </div>
            )}
          </>
        )}
        {derivedTask?.labelingTask.abstractTask.endTime && (
          <DataRow label="Date Completed" value={dayjs(derivedTask?.labelingTask.abstractTask.endTime).format('MM/DD/YYYY h:mma')} />
        )}
        <Space h="lg" />
        <TaskDataDisplay derivedTask={derivedTask} setsMap={allSetsMap}/>
        {taskError && (
          <Stack spacing="xs" mt="lg">
            <Text color="red">Error</Text>
            <Text color="black">{taskError}</Text>
          </Stack>
        )}
        {canDuplicateTask && (
          <div>
            <Button mt="lg" variant="outline" onClick={() => duplicateTaskConfig(derivedTask as DerivedLabelingTask)}>Clone task</Button>
          </div>
        )}
      </div>
    </Drawer>
  );
};
export default TaskConfigurationDrawer;
