import React from 'react';
import { Button, Modal, Stack, Text, Group } from '@mantine/core';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'studioConstants';
import { SetData } from '.';

const ExistingSetListItem = ({ setData, onClick }: {setData: SetData, onClick:()=>void}) => (
  <Button
    p="sm"
    styles={{
      root: {
        height: 'unset',
        color: 'unset',
        textAlign: 'unset',
        display: 'flex',
      },
      inner: {
        flex: 1,
        justifyContent: 'unset',
        minWidth: 0,
      },
      label: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        whiteSpace: 'unset',
      },
    }}
    variant="outline"
    onClick={onClick}
  >
    <Text mb="xs" size="lg">{setData.name}</Text>
    <Group position="apart">
      <Text color="dimmed" size="sm">{dayjs(setData.createTime).format(DATE_TIME_FORMAT)}</Text>
      <Text color="dimmed" size="sm">{`${setData.convoQuantity} convo${setData.convoQuantity === 1 ? '' : 's'}`}</Text>
    </Group>
  </Button>
);

interface ExistingSetsModalProps {
  opened: boolean;
  onClose: () => void;
  onAdd: (sets: SetData) => void;
  existingSetData: SetData[];
}

const ExistingSetsModal = ({ opened, onClose, existingSetData, onAdd }:ExistingSetsModalProps) => (
  <Modal
    title="Existing Sets"
    opened={opened}
    onClose={onClose}
  >
    <Stack data-cy="existing-sets-list">
      {existingSetData.map((setData) => (
        <ExistingSetListItem setData={setData} onClick={() => onAdd(setData)} />
      ))}
    </Stack>
  </Modal>
);

export default ExistingSetsModal;
