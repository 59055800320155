import React, { ReactElement, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { useSelector } from 'hooks/reduxHooks';
import { selectDebuggingMode } from 'store/app/selectors';
import { getMenu } from 'components/Sidebar/config';
import LetterAvatar from 'components/LetterAvatar';
import { CustomerParams, composeCustomerUrl } from 'hooks/useCustomerParams';
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  KBarResults,
  useMatches,
  Action,
} from 'kbar';

export function KBar(): ReactElement {
  const debuggingMode = useSelector<boolean>(selectDebuggingMode);
  const { allConfigs } = useContext(CustomerConfigContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const splitPath = pathname?.split('/');
  // The 5th element of the url is the page route
  const makePath = (customer: CustomerParams) => [customer.path, splitPath[5]].join('/');

  // Prepare kbar actions from sidebar items
  const menuItems = getMenu({
    experimentalFeatures: debuggingMode,
  });
  const kbarMenuItems: Action[] = useMemo(() => {
    const items = [];
    menuItems.forEach((menuItem) => {
      if (menuItem.type === 'single') {
        items.push({
          ...menuItem,
          perform: () => navigate(menuItem.to),
          section: {
            name: menuItem.label,
            priority: 1,
          },
          name: menuItem.label,
        });
      }
      if (menuItem.type === 'submenu') {
        menuItem.submenu.forEach((submenuItem, i) => {
          if (submenuItem.to) {
            items.push({
              ...submenuItem,
              perform: () => navigate(submenuItem.to),
              section: {
                name: menuItem.label,
                priority: 1,
              },
              name: submenuItem.label,
              id: `${menuItem.id}-${i}`,
            });
          }
        });
      }
    });

    return items;
  }, [menuItems, navigate, makePath]);

  // Prepare kbar actions from customers list
  const kbarCustomers: Action[] = useMemo(() => {
    const list = [];
    allConfigs.forEach((config) => {
      const langs = config.supportedLanguageCodes || [];
      if (!langs.includes(config.defaultLanguageCode)) {
        langs.push(config.defaultLanguageCode);
      }
      langs.forEach((languageCode) => {
        const customer: CustomerParams = {
          customerId: config.customerId,
          profileId: config.profileId,
          usecaseId: config.usecaseId,
          languageCode,
          path: '',
        };
        customer.path = composeCustomerUrl(customer);
        const { path } = customer;
        const item = {
          id: path,
          priority: 1,
          keywords: [config.customerId, config.profileId, config.usecaseId, languageCode],
          name: path,
          section: 'Customers',
          perform: () => navigate(`/${makePath(customer)}`),
          icon: <LetterAvatar name={config.customerShortName} />,
        };
        list.push(item);
      });
    });

    return list;
  }, [allConfigs, makePath]);

  const kbarIcons = useMemo(() => new Map<string, JSX.Element>(menuItems.map((item) => ([item.label, item.icon]))), [menuItems]);
  const kbarActions: Action[] = [...kbarMenuItems, ...kbarCustomers];

  return (
    <KBarProvider actions={kbarActions} key={pathname}>
      <KBarPortal >
        <KBarPositioner className="kbar">
          <KBarAnimator>
            <KBarSearch />
            <RenderResults kbarIcons={kbarIcons} />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
    </KBarProvider>
  );
}

function RenderResults({ kbarIcons }) {
  const { results } = useMatches();
  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) => {
        const icon = kbarIcons.get(item)?.();
        // Group name
        if (typeof item === 'string') {
          return (
            <div className="group">
              {icon && <div>{icon}</div>}
              <span>{item}</span>
            </div>
          );
        }
        return (
          <div
            className={active ? 'option active' : 'option'}
          >
            {item.section === 'Customers' && (
              <div>{item.icon}</div>
            )}
            <span>{item.name}</span>
          </div>
        );
      }}
    />
  );
}
