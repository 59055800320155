import React, { useEffect, useState } from 'react';
import {
  Anchor,
  Button,
  Divider,
  Group,
  List,
  MantineTheme,
  Overlay,
  Paper,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import { UseFormReturnType } from '@mantine/form';
import { ExamplesContainer } from './components/ExamplesContainer';
import { MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS } from '../config';
import { Config } from './components/Config';
import {
  Category,
  ConceptData,
  ConfigFormValues,
  FormValueExampleArrayType,
  PrepopulatedType,
  Preview,
} from '../types';
import { Suggestions } from './components/Suggestions';
import { PreviewSection } from './PreviewSection';
import { TipSection } from './components/TipSection';
import { goodExamplesHintCopy, refineExamplesHintCopy } from './hintCopy';

interface ConfigurationPageProps {
  handleCancelConfig: () => void;
  configForm: UseFormReturnType<ConfigFormValues>;
  conceptData: ConceptData;
  handleUpdateExamples: (
    formValue: FormValueExampleArrayType,
    indexToUpdate: number,
    value: string,
  ) => void;
  handleAddNewExample: (formValue: FormValueExampleArrayType, text: string) => void;
  handleDeleteExample: (formValue: FormValueExampleArrayType, indexToDelete: number) => void;
  theme: MantineTheme;
  onCreateBtnClick: () => void;
}

const PrepopulatedAlert = ({
  prepopulatedType,
  onRemoveAll,
  isVisible,
  onClose,
}: {
  prepopulatedType: PrepopulatedType;
  onRemoveAll: () => void;
  isVisible: boolean;
  onClose: () => void;
}) => {
  if (prepopulatedType === 'none') {
    return null;
  }
  const PREV_TASK_COPY = 'Here, we’ve pre-populated with examples from last auto-labeling task.';
  const CANONICAL_COPY = 'Here, we’ve pre-populated with canonical examples from the taxonomy page.';
  const copy = prepopulatedType === 'previousTask' ? PREV_TASK_COPY : CANONICAL_COPY;
  return (
    <TipSection title="Prepopulated examples" isVisible={isVisible} onClose={onClose}>
      <Text>
        {copy} Are these not useful?
        <Anchor
          ml="xs"
          component="button"
          style={{
            display: 'unset',
          }}
          onClick={onRemoveAll}
        >
          Remove All
        </Anchor>
      </Text>
    </TipSection>
  );
};

const ExampleHints = ({ configForm, prepopulatedType }) => {
  const { humanReadableName } = configForm.values;
  // opens after human readable name is set or if prepopulatedType is not none
  const [isPrepopulatedAlertOpen, togglePrepopulatedAlert] = useState(false);
  // opens after human readable name is set or if prepopulatedType is not none and prepopulatedAler has been closed
  const [isExamplesHintOpen, toggleExamplesHint] = useState(false);

  // disable this effect if it has already been triggered
  const [hasTriggeredInitialAlert, toggleHasTriggeredInitialAlert] = useState(false);
  useEffect(() => {
    if (humanReadableName.length > 0 && !hasTriggeredInitialAlert) {
      toggleHasTriggeredInitialAlert(true);
      if (prepopulatedType === 'none') {
        toggleExamplesHint(true);
      } else {
        togglePrepopulatedAlert(true);
      }
    }
  }, [humanReadableName, hasTriggeredInitialAlert, prepopulatedType]);

  const onClosePrepopulatedAlert = async () => {
    // 500ms delay to allow for animation to finish
    togglePrepopulatedAlert(false);
    await new Promise((resolve) => setTimeout(resolve, 500));
    toggleExamplesHint(true);
  };
  return (
    <>
      <PrepopulatedAlert
        isVisible={isPrepopulatedAlertOpen}
        onClose={onClosePrepopulatedAlert}
        prepopulatedType={prepopulatedType}
        onRemoveAll={() => {
          configForm.setValues({
            positiveExamples: [],
            negativeExamples: [],
          });
        }}
      />
      <TipSection
        title={goodExamplesHintCopy.title}
        isVisible={isExamplesHintOpen}
        onClose={() => toggleExamplesHint(false)}
      >
        <List size="sm" listStyleType="disc">
          {goodExamplesHintCopy.listItems.map((item) => (
            <List.Item key={item}>{item}</List.Item>
          ))}
        </List>
      </TipSection>
    </>
  );
};

export const ConfigurationPage = (props: ConfigurationPageProps) => {
  const {
    handleCancelConfig,
    configForm,
    conceptData,
    handleUpdateExamples,
    handleAddNewExample,
    handleDeleteExample,
    theme,
    onCreateBtnClick,
  } = props;

  const { positiveExamples, negativeExamples } = configForm.values;
  const handleOverlayClick = () => {
    configForm.setFieldError('humanReadableName', 'Required for suggestion generation');
  };
  const [activeTab, setActiveTab] = useState('examples');
  const hasReachedMinSubmitRequirements = positiveExamples.length >= MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS;
  const [isRevisionHintOpen, toggleRevisionHint] = useState(false);

  return (
    <PageContainer
      style={{ display: 'flex', overflow: 'hidden', flex: 1, height: '100%', margin: '0 auto' }}
    >
      <Group>
        <Title order={2} style={{ flex: 1 }}>
          Add details for Auto Label task
        </Title>
        <Group>
          <Button variant="subtle" onClick={handleCancelConfig}>
            Cancel
          </Button>
          <Button
            onClick={onCreateBtnClick}
            disabled={!hasReachedMinSubmitRequirements}
          >
            Create
          </Button>
        </Group>
      </Group>
      <Group align="stretch" style={{ flex: 1, overflow: 'hidden' }}>
        <Paper p="xl" style={{ width: 400, overflow: 'auto', height: '100%' }}>
          <Title order={2} mb="md">
            Configure basic details
          </Title>
          <Stack>
            <Config configForm={configForm} conceptData={conceptData} />
          </Stack>
        </Paper>
        <Paper
          p="xl"
          style={{
            position: 'relative',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
          }}
        >
          {configForm.values.humanReadableName.length === 0 && (
            <Overlay onClick={handleOverlayClick} />
          )}
          <Group mb="md" noWrap position="apart">
            <Title order={2}>Refine examples</Title>
          </Group>
          <Group noWrap spacing="xl" align="stretch" style={{ height: '100%', overflow: 'hidden' }}>
            <Stack style={{ flex: 1, overflow: 'auto' }}>
              <ExampleHints
                configForm={configForm}
                prepopulatedType={conceptData.prepopulatedType}
              />
              <TipSection
                title={refineExamplesHintCopy.title}
                isVisible={isRevisionHintOpen}
                onClose={() => toggleRevisionHint(false)}
              >
                <List size="sm" listStyleType="disc">
                  {refineExamplesHintCopy.listItems.map((item) => (
                    <List.Item key={item}>{item}</List.Item>
                  ))}
                </List>
              </TipSection>
              <ExamplesContainer
                category={Category.POSITIVE}
                error={configForm.errors.positiveExamples}
                title="Enter positive examples"
                subtitle={`Utterances we’re targeting. Add at least ${MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS} examples`}
                examples={positiveExamples}
                handleUpdateExamples={(indexToUpdate: number, value: string) =>
                  handleUpdateExamples(FormValueExampleArrayType.POSITIVE, indexToUpdate, value)}
                handleAddNewExample={(text) =>
                  handleAddNewExample(FormValueExampleArrayType.POSITIVE, text)}
                handleDeleteExample={(index: number) =>
                  handleDeleteExample(FormValueExampleArrayType.POSITIVE, index)}
              />
              <ExamplesContainer
                category={Category.NEGATIVE}
                error={configForm.errors.negativeExamples}
                title="Enter negative examples"
                subtitle="Utterances to filter out."
                examples={negativeExamples}
                handleUpdateExamples={(indexToUpdate: number, value: string) =>
                  handleUpdateExamples(FormValueExampleArrayType.NEGATIVE, indexToUpdate, value)}
                handleAddNewExample={(text) =>
                  handleAddNewExample(FormValueExampleArrayType.NEGATIVE, text)}
                handleDeleteExample={(index: number) =>
                  handleDeleteExample(FormValueExampleArrayType.NEGATIVE, index)}
              />
            </Stack>
            <Divider
              orientation="vertical"
              style={{ height: 'auto' }}
              color={theme.colors.gray[1]}
            />
            <Stack style={{ flex: 1, height: '100%', overflow: 'hidden' }}>
              <Tabs
                // allows for scroll inside of panel
                styles={{
                  root: {
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    flex: 1,
                  },
                  panel: {
                    overflow: 'hidden',
                  },
                }}
                // to fetch suggestions only when user is on the tab
                keepMounted={false}
                value={activeTab}
                onTabChange={(value) => {
                  if (value === 'preview') {
                    // TODO: add validation
                    if (!hasReachedMinSubmitRequirements) {
                      configForm.setFieldError(
                        'positiveExamples',
                        `Minimum examples(${MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS}) not reached`,
                      );
                      return;
                    }
                  }
                  configForm.setFieldError('positiveExamples', '');
                  setActiveTab(value);
                }}
              >
                <Tabs.List grow>
                  <Tabs.Tab value="examples">Generative AI examples</Tabs.Tab>
                  <Tabs.Tab title={hasReachedMinSubmitRequirements ? '' : 'Add more examples to see preview'} value="preview" disabled={!hasReachedMinSubmitRequirements}>Labels Preview</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="examples">
                  <Suggestions
                    configForm={configForm}
                    handleAddNewExample={handleAddNewExample}
                    onFirstExampleAdded={() => toggleRevisionHint(true)}
                    openPreviewPanel={() => setActiveTab('preview')}
                  />
                </Tabs.Panel>
                <Tabs.Panel value="preview" pt="xs">
                  <PreviewSection
                    configForm={configForm}
                    onTogglePanel={() => setActiveTab('examples')}
                    onUpdateExamples={(preview: Preview, category: Category) => {
                      if (category === Category.POSITIVE) {
                        handleAddNewExample(FormValueExampleArrayType.POSITIVE, preview.message);
                      } else {
                        handleAddNewExample(FormValueExampleArrayType.NEGATIVE, preview.message);
                      }
                    }}
                  />
                </Tabs.Panel>
              </Tabs>
            </Stack>
          </Group>
        </Paper>
      </Group>
    </PageContainer>
  );
};
