/* eslint-disable no-await-in-loop */
import {
  SimulationTaskService,
  CreateSimulationTaskRequest,
  CreateSimulationTaskResponse,
  GetSimulationTaskRequest,
  GetSimulationTaskResponse,
  FetchConversationPredictionsRequest,
  FetchConversationPredictionsResponse,
  RetrieveLatestSimulationTaskRequest,
  RetrieveLatestSimulationTaskResponse,
  ListSimulationTasksRequest,
  ListSimulationTasksResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { StudioApi } from './studioApi';

const DEFAULT_PAGE_SIZE = 100;

export abstract class SimulationTaskApi {
  public static listSimulationTasks(request: ListSimulationTasksRequest): Promise<ListSimulationTasksResponse> {
    return SimulationTaskService.ListSimulationTasks(request, StudioApi.getHeaders().initReq);
  }

  public static retrieveLatestSimulationTask(request: RetrieveLatestSimulationTaskRequest): Promise<RetrieveLatestSimulationTaskResponse> {
    return SimulationTaskService.RetrieveLatestSimulationTask(request, StudioApi.getHeaders().initReq);
  }

  public static createSimulationTask(request: CreateSimulationTaskRequest): Promise<CreateSimulationTaskResponse> {
    return SimulationTaskService.CreateSimulationTask(request, StudioApi.getHeaders().initReq);
  }

  public static getSimulationTask(request: GetSimulationTaskRequest): Promise<GetSimulationTaskResponse> {
    return SimulationTaskService.GetSimulationTask(request, StudioApi.getHeaders().initReq);
  }

  public static fetchConversationPredictions(request: FetchConversationPredictionsRequest): Promise<FetchConversationPredictionsResponse> {
    return SimulationTaskService.FetchConversationPredictions(request, StudioApi.getHeaders().initReq);
  }

  public static async fetchAllConversationPredictions(request: FetchConversationPredictionsRequest): Promise<FetchConversationPredictionsResponse> {
    const result: FetchConversationPredictionsResponse = {
      predictions: [],
      oldPredictions: [],
      predictionDiff: {
        msgDiffs: [],
      },
    };
    request.pageSize = DEFAULT_PAGE_SIZE;
    let nextPageToken = '';
    while (true) {
      request.pageToken = nextPageToken;
      const resp = await SimulationTaskService.FetchConversationPredictions(request, StudioApi.getHeaders().initReq);
      result.predictions.push(...(resp?.predictions || []));
      result.oldPredictions.push(...(resp?.oldPredictions || []));
      result.predictionDiff.msgDiffs.push(...(resp?.predictionDiff?.msgDiffs || []));
      if (!resp.nextPageToken) {
        result.simulationTask = resp?.simulationTask;
        result.conversation = resp?.conversation;
        break;
      } else {
        nextPageToken = resp?.nextPageToken;
      }
    }
    return result;
  }
}
