import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'antd';
import InputWithLabelValue, { InputWithLabelValueData } from 'components/InputWithLabelValue';
import { LabelValue } from 'types';
import { MessageApi } from 'services/messageApi';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { useSelector } from 'hooks/reduxHooks';
import {
  Message,
  RegexSearchMessagesRequestActorTypeFilter,
  RegexSearchMessagesResponseRegexMatch,
} from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { cloneDeep, uniq, uniqBy } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { selectApiStatus, selectConceptsMapFactory } from 'store/concept/selectors';
import { Concept, ConceptConceptType, UpdateConceptRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import IntentDetailButton from 'components/IntentInfoButton';
import HighlightedText from 'components/HighlightedText';
import moment from 'moment';
import { openNotification } from 'components/Notification';
import { EyeInvisibleOutlined, EyeOutlined, WarningOutlined } from '@ant-design/icons';
import { updateConcept } from 'store/concept/asyncThunks';
import { useDispatch } from 'react-redux';
import { useHotkeys } from 'hooks/useHotkeys';
import { IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { UserContext } from 'context/UserContext';
import { Button } from '@mantine/core';
import styles from './styles.module.scss';

type EditRegexProps = {
  children?: React.ReactNode | string | number;
};

const HIGHLIGHT_COLORS = new Map<LabelValue, string>([
  [LabelValue.POSITIVE, 'rgba(81, 207, 102, 0.5)'],
  [LabelValue.NEGATIVE, 'rgba(255, 107, 107, 0.5)'],
]);

export default function EditRegex({ children }: EditRegexProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { intentId } = useParams<{ intentId: string }>();
  // Loading status for fetching messages
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [lastRunText, setLastRunText] = useState<string>('');
  const [lastRunTime, setLastRunTime] = useState<number>();
  const [lastRunRegexList, setLastRunRegexList] = useState<InputWithLabelValueData[]>(
    [],
  );
  const [regexList, setRegexList] = useState<InputWithLabelValueData[]>([]);
  const [regexMatches, setRegexMatches] = useState<
    RegexSearchMessagesResponseRegexMatch[]
  >([]);
  const [hoveredMessageMatch, setHoveredMessageMatch] = useState<[number, number]>();
  const [toggledAll, setToggledAll] = useState(false);
  const customerProfile = useCustomerProfile();
  const concepts = useSelector<Map<string, Concept>>(selectConceptsMapFactory(ConceptConceptType.INTENT));
  const concept = concepts.get(intentId);
  const apiStatus = useSelector(selectApiStatus);
  const textRefreshInterval = useRef<ReturnType<typeof setInterval>>();
  const currentUser = useContext(UserContext);
  const customer = useCustomerParams();
  const usecase = useCustomerUsecase();

  const updateLastRunText = useCallback(() => {
    setLastRunText(moment(lastRunTime).fromNow());
  }, [lastRunTime]);

  // Update time counter from last run
  useEffect(() => {
    if (textRefreshInterval.current) {
      clearInterval(textRefreshInterval.current);
    }
    textRefreshInterval.current = setInterval(updateLastRunText, 10000);
    return () => {
      clearInterval(textRefreshInterval.current);
    };
  }, [lastRunTime]);

  useEffect(() => {
    if (!concept) return;

    const positiveList: InputWithLabelValueData[] = concept.intent.regexExpressions.map(
      (str) => ({
        value: str,
        type: LabelValue.POSITIVE,
        hidden: false,
      }),
    );
    const negativeList: InputWithLabelValueData[] = concept.intent.negativeRegexExpressions.map(
      (str) => ({
        value: str,
        type: LabelValue.NEGATIVE,
        hidden: false,
      }),
    );
    setRegexList([...positiveList, ...negativeList]);
  }, [concept?.name]);

  const fetchRegexMessages = async (positiveRegex: string[], negativeRegex: string[]) => {
    setLoadingMessages(true);
    try {
      let actorTypeFilter = RegexSearchMessagesRequestActorTypeFilter.ALL;
      switch (concept?.intent?.intentType) {
        case IntentIntentType.AGENT_INTENT:
          actorTypeFilter = RegexSearchMessagesRequestActorTypeFilter.AGENT_MESSAGE;
          break;
        case IntentIntentType.VISITOR_INTENT:
        case IntentIntentType.CONVERSATION_DRIVER:
          actorTypeFilter = RegexSearchMessagesRequestActorTypeFilter.VISITOR_MESSAGE;
          break;
        default:
      }
      const messages = await MessageApi.regexSearchMessages({
        resource: customerProfile,
        regexExpressions: positiveRegex,
        negativeRegexExpressions: negativeRegex,
        minimalMatchNumber: 5,
        pageSize: 100,
        actorTypeFilter,
        usecase,
        languageCode: customer?.languageCode,
      });
      setLastRunRegexList(cloneDeep(regexList));
      setLastRunTime(Date.now());
      setLastRunText(moment(Date.now()).fromNow());
      setRegexMatches(messages.regexMatches);
    } catch (err) {
      openNotification('error', 'Failed to fetch messages', undefined, err);
    }
    setLoadingMessages(false);
  };

  const changeRegexHelper = (value: InputWithLabelValueData, index: number) => {
    const copiedRegexList = cloneDeep(regexList);
    copiedRegexList[index] = value;
    setRegexList(copiedRegexList);
  };

  const deleteRegexHelper = (index: number) => {
    const copiedRegexList = cloneDeep(regexList);
    copiedRegexList.splice(index, 1);
    setRegexList(copiedRegexList);
  };

  const addRegexHelper = () => {
    setRegexList([
      ...regexList,
      {
        value: '',
        type: LabelValue.POSITIVE,
        hidden: false,
      },
    ]);
  };

  const rerunSamples = () => {
    const positiveRegex = [];
    const negativeRegex = [];
    regexList.forEach((item) => {
      if (!item.hidden) {
        if (item.type === LabelValue.POSITIVE) {
          positiveRegex.push(item.value);
        } else {
          negativeRegex.push(item.value);
        }
      }
    });
    setRegexMatches([]);
    fetchRegexMessages(positiveRegex, negativeRegex);
  };

  const isRegexValid = (str: string) => {
    const isValid: boolean = !!str.trim();
    if (!isValid) return false;

    try {
      // eslint-disable-next-line no-new
      new RegExp(str);
    } catch (e) {
      return false;
    }

    // There shouldn't be duplicated regex
    if (regexList.filter((item) => item.value === str && !item.hidden)?.length !== 1) {
      return false;
    }

    // Regex shouldn't contain these substrings
    return !['(?=', '(?!', '(?<=', '(?<!'].some((expression) => str.includes(expression));
  };

  const saveIntent = () => {
    try {
      const request: UpdateConceptRequest = {
        concept: {
          name: concept.name,
          intent: {
            regexExpressions: regexList.filter((item) => item.type === LabelValue.POSITIVE).map((item) => item.value),
            negativeRegexExpressions: regexList.filter((item) => item.type === LabelValue.NEGATIVE).map((item) => item.value),
          },
        },
        userResourceName: currentUser?.name,
        updateMask: 'intent.regex_expressions,intent.negative_regex_expressions',
      };
      dispatch(updateConcept(request));
    } catch (error) {
      openNotification('error', 'Failed to add regex', undefined, error);
    }
  };

  const toggleAllHelper = () => {
    const copiedRegex = cloneDeep(regexList);
    setRegexList(copiedRegex.map((item) => ({
      ...item,
      hidden: !toggledAll,
    })));
    setToggledAll(!toggledAll);
  };

  useHotkeys('enter', () => addRegexHelper(), { enableOnTags: ['INPUT'] }, [addRegexHelper]);
  useHotkeys('r', () => rerunSamples(), {}, [rerunSamples]);

  const hasRegexListChanged = JSON.stringify(regexList) !== JSON.stringify(lastRunRegexList);

  const messagesMap = useMemo(() => {
    const messagesMap = new Map<
      string,
      { message: Message; matchIndices: number[] }
    >();
    regexMatches.forEach((match) => {
      const regexIndex = lastRunRegexList.findIndex((item) => item.value === match.regexExpression && !item.hidden);
      match.messages.forEach((message) => {
        const messageMatchIndices: number[] = messagesMap.get(message.name)?.matchIndices || [];
        messagesMap.set(message.name, {
          message,
          matchIndices: uniq([...messageMatchIndices, regexIndex]),
        });
      });
    });
    return messagesMap;
  }, [regexMatches]);

  const messagesList = Array.from(messagesMap).map((m) => m[1]);
  const dedupedMessagesList = uniqBy(messagesList, 'message.content');
  dedupedMessagesList.sort(
    (a, b) => b.matchIndices.length - a.matchIndices.length,
  );

  const isRegexListValid = regexList.every((item) => {
    if (item.hidden) return true;
    const isValid = isRegexValid(item.value);
    return isValid;
  });

  const renderMessageMatchIndices = (matchIndices: number[], messageIndex: number) => {
    let showWillBeRemovedText = false;
    const indices = matchIndices.map((matchIndex) => {
      const isNegative = lastRunRegexList[matchIndex].type
      === LabelValue.NEGATIVE;

      if (isNegative) {
        showWillBeRemovedText = true;
      }

      return (
        <span
          onMouseOver={() => setHoveredMessageMatch([messageIndex, matchIndex])}
          onFocus={() => setHoveredMessageMatch([messageIndex, matchIndex])}
          onMouseOut={() => setHoveredMessageMatch(null)}
          onBlur={() => setHoveredMessageMatch(null)}
          key={matchIndex}
          className={classNames([
            styles.regexIndex,
            {
              [styles.negative]: isNegative,
            },
          ])}
        >
          {matchIndex + 1}
        </span>
      );
    });

    return (
      <>
        {indices}
        {showWillBeRemovedText && (
          <span className={styles.greyText}>(will be removed)</span>
        )}
      </>
    );
  };

  const isHovered = hoveredMessageMatch && hoveredMessageMatch[0] != null;

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <div className={styles.editRegexWrapper}>
        <div className={styles.wrapperHeader}>
          <h1>Edit Regex for</h1>
          <IntentDetailButton intentName={concept?.conceptTitle} />
          <div>
            <Button variant="subtle" onClick={() => navigate(-1)}>Back</Button>
            <Button loading={apiStatus === 'loading'} onClick={() => saveIntent()}>Save</Button>
          </div>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <div className={styles.regex}>
              <div>
                <div>
                  <h2>Regex</h2>
                  <span className={styles.greyText}>{regexList.length} total</span>
                </div>
                <Button color="gray" variant="outline" onClick={toggleAllHelper}>{toggledAll ? <EyeInvisibleOutlined /> : <EyeOutlined />}&nbsp;Toggle all</Button>
              </div>
              <div className={classNames(styles.regexInputList, { [styles.isHovered]: isHovered })}>
                {regexList.map((item, i) => (
                  <div className={isHovered && hoveredMessageMatch[1] === i ? styles.hoveredMatchRegex : null}>
                    <InputWithLabelValue
                      number={i + 1}
                      valueData={item}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      onChange={(value) => changeRegexHelper(value, i)}
                      onDeleteHandler={() => deleteRegexHelper(i)}
                    />
                  </div>
                ))}
              </div>
              <Button color="blue" variant="light" compact onClick={() => addRegexHelper()}>
                + Add
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.messages}>
              <h2>Samples</h2>
              <div className={styles.messagesHeader}>
                <Button
                  color="blue"
                  variant="light"
                  onClick={() => rerunSamples()}
                  loading={loadingMessages}
                  disabled={!isRegexListValid}
                >
                  Run Test (R)
                </Button>
                {lastRunTime && !loadingMessages && (
                  <span className={styles.greyText}>
                    Last run: {lastRunText}
                  </span>
                )}
              </div>
              {regexList.filter((item) => !item.hidden).length >= 10 && (
                <div className={styles.regexWarningMessage}>
                  <WarningOutlined/> Too many regular expressions might take a long time to process or result in a failed request.
                </div>
              )}
              <div className={styles.messagesSubheader}>
                {!loadingMessages && (
                  <span className={styles.greyText}>
                    {messagesList.length} matches
                  </span>
                )}
                {(hasRegexListChanged && messagesList.length > 0) && (
                  <span className={styles.redText}>
                    <WarningOutlined /> Outdated results
                  </span>
                )}
              </div>
              <div className={styles.messageList}>
                {dedupedMessagesList.map((message, messageIndex) => (
                  <div className={styles.message} key={message.message.name}>
                    <div>
                      <HighlightedText
                        text={message.message.content}
                        color={isHovered && hoveredMessageMatch[0] === messageIndex ? HIGHLIGHT_COLORS.get(lastRunRegexList[hoveredMessageMatch[1]].type) : null}
                        regexStrings={
                          // Show hovered match
                          isHovered && hoveredMessageMatch[0] === messageIndex
                            ? [lastRunRegexList[hoveredMessageMatch[1]].value]
                            : message.matchIndices.map(
                              (matchIndex) => lastRunRegexList[matchIndex].value,
                            )
                      }
                      />

                    </div>
                    <div>
                      <div>
                        <span className={styles.greyText}>Match:</span>
                        <div className={styles.matchIndicesContainer}>
                          {renderMessageMatchIndices(message.matchIndices, messageIndex)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
