import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import { Annotation, BinaryValue, BinaryValueValue, MessageRawData, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import NavigateDecision from 'components/LabelingDecision/NavigateDecision/NavigateDecision';
import { EntityLabel } from 'components/LabelingEntity';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import { cloneDeep } from 'lodash';
import { GotoNextType } from 'pages/LabelingView';
import React, { useMemo, useState } from 'react';

interface SpanLabelingViewProps {
  entities: Concept[];
  messages: Message[];
  context: MessageRawDataContextShown,
  setContext: (context: MessageRawDataContextShown) => void;
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  prevChat: () => void;
  nextChat: () => void;
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  isFirstChat: boolean;
  isLastChat: boolean;
  finishLabeling: () => void;
}

export function SpanLabelingView({
  entities,
  messages,
  context,
  setContext,
  targetMessage,
  messageAnnotationsMap,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  prevChat,
  nextChat,
  createAnnotation,
  upsertAnnotations,
  isFirstChat,
  isLastChat,
  finishLabeling,
} : SpanLabelingViewProps) {
  const targetMessageId = targetMessage?.v2MessageId;

  const messageAnnotations = useMemo(() => messageAnnotationsMap.get(targetMessageId) || [], [messageAnnotationsMap, targetMessageId]);
  // Filter out annotations not confirmed by server
  const savedMessageAnnotations = messageAnnotations.filter((annotation) => annotation.createTime);
  const [finalChatLabeled, setFinalChatLabeled] = useState(false);

  // Add entity annotation by merging it with existing ones and resaving them all at once
  const addEntity = (entityLabel: EntityLabel) => {
    if (isLastChat) {
      setFinalChatLabeled(true);
    }
    const newAnnotation = createAnnotation({
      conversationId: targetMessage.conversationId,
      messageId: targetMessage.messageId,
      v2ConversationId: targetMessage.v2ConversationId,
      v2MessageId: targetMessage.v2MessageId,
      contextShown: context,
      spanStart: entityLabel.index,
      spanEnd: entityLabel.index + entityLabel.length,
    }, {
      value: BinaryValueValue.VALUE_POSITIVE,
      conceptId: getId('concept', entityLabel.entityId),
    });

    const existingAnnotationsTuples: SaveTemporalAnnotationRequestRawDataValueTuple[] = messageAnnotations.map((annotation) => {
      const annotationRawDataTuple = createAnnotation(annotation.rawData.messageRawData, annotation.value.binaryValue);
      annotationRawDataTuple.annotationId = getId('annotation', annotation.name);
      return annotationRawDataTuple;
    });
    upsertAnnotations([...existingAnnotationsTuples, newAnnotation], 'noop');
  };

  // Update single annotation, then resave all annotations
  const updateEntity = (entityLabel: EntityLabel) => {
    const existingAnnotationsTuples: SaveTemporalAnnotationRequestRawDataValueTuple[] = messageAnnotations.map((annotation) => {
      const annotationRawDataTuple = createAnnotation(annotation.rawData.messageRawData, annotation.value.binaryValue);
      annotationRawDataTuple.annotationId = getId('annotation', annotation.name);
      return annotationRawDataTuple;
    });
    const newAnnotationTuples = existingAnnotationsTuples.map((annotation) => {
      const annotationCopy = cloneDeep(annotation);
      if (annotation.annotationId === entityLabel.id) {
        annotationCopy.value.binaryValue.conceptId = getId('concept', entityLabel.entityId);
      }
      return annotationCopy;
    });
    upsertAnnotations(newAnnotationTuples, 'noop', savedMessageAnnotations);
  };

  // Remove all annotations while adding none
  const resetEntities = () => {
    if (isLastChat) {
      setFinalChatLabeled(false);
    }
    upsertAnnotations([], 'noop', savedMessageAnnotations);
  };

  return (
    <div
      className={classNames([
        'labeling-view',
        'horizontal-layout',
      ])}
    >
      <div className="labeling-items-display">
        <LabelingItemsDisplay
          context={context}
          setContext={setContext}
          showContextButtonVisible
          messages={messages}
          targetMessageId={targetMessageId}
          setTargetMessage={setTargetMessage}
          messageAnnotationsMap={messageAnnotationsMap}
          getConceptTitle={getConceptTitle}
          failedMessages={failedMessages}
          entityLabeling
          concepts={entities}
          addEntity={addEntity}
          resetEntities={resetEntities}
          updateEntity={updateEntity}
        />
      </div>
      <div className="labeling-decision-panel">
        <NavigateDecision
          showBackButton={!isFirstChat}
          showNextButton={!isLastChat}
          prevHandler={prevChat}
          nextHandler={nextChat}
          finishLabeling={finishLabeling}
          labelingFinished={finalChatLabeled}
        />
      </div>
    </div>
  );
}
