import React from 'react';
import { Paper, Stack, Text } from '@mantine/core';

export const ConfigPanelContainer = ({ children }) => (
  <div style={{ alignSelf: 'stretch' }}>
    <Paper style={{ width: 450, height: '100%' }} p="lg">
      <Stack>
        <Text>Data set configuration</Text>
        {children}
      </Stack>
    </Paper>
  </div>
);
