import React, { useState, useEffect } from 'react';
import './ChatWindow.scss';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';
import { notification } from 'antd';
import moment from 'moment';
import { getId } from 'common/resourceName';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { ListConversationMessagesRequest } from '@cresta/web-client';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { mapToStudioMessage, ProdConversationApi } from 'services/prodConversationApi';
import { ChatWindow as ChatWindowComponent } from './ChatWindow';

interface ComponentMessage {
    id: string;
    text: string;
    pubDate: Date;
    speakerId: string;
    speakerRole: string;
    msgId: string;
    chat_name: any;
    suggestions: any;
    hints: any;
}

function getFocusedMessageIdFromMessages(messages: ComponentMessage[], messagePosition:string | string[]) {
  if (Array.isArray(messagePosition)) {
    console.warn(`Multiple query parameters found for message position. ScrollTo deactivated. Values: ${messagePosition}`);
    return null;
  }
  const positionAsNumber = parseInt(messagePosition);
  if (Number.isNaN(positionAsNumber)) {
    console.warn('Message position returned NaN: ', messagePosition);
    return null;
  }
  if (positionAsNumber > messages.length) {
    console.warn(`Message position is out of bounds. Position: ${positionAsNumber} Messages: ${messages.length}`);
    return null;
  }
  if (!messages[positionAsNumber]) {
    console.warn('Message not found');
    return null;
  }
  return messages[positionAsNumber].id;
}

const ChatWindow = () => {
  const { pathname, search } = useLocation();
  const { chatId: conversationId } = useParams<{ chatId: string }>();
  const customerProfile = useCustomerProfile();
  const [loading, toggleLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [focusedMessageId, setFocusedMessageId] = useState(null);

  useEffect(() => {
    if (!conversationId) return;
    toggleLoading(true);

    const conversationName = `${customerProfile}/conversations/${conversationId}`;

    async function getMessagesByConversationName(name: string) {
      const messages: Message[] = [];
      let pageToken = '';
      while (true) {
        const request: ListConversationMessagesRequest = {
          parent: name,
          pageToken,
        };

        // eslint-disable-next-line no-await-in-loop
        const response = await ProdConversationApi.listMessages(request);
        messages.push(...response.conversationMessages.map(mapToStudioMessage));
        if (!response.nextPageToken) {
          break;
        }
        pageToken = response.nextPageToken;
      }
      return messages;
    }

    getMessagesByConversationName(conversationName).then((messages) => {
      const mappedMessages: ComponentMessage[] = messages.map((message) => {
        const id = getId('conversationMessage', message.name);
        const pubDate = new Date(`${moment(message.publishTime).format('YYYY-MM-DDTHH:mm:ss')}+0000`);
        return {
          id,
          text: message.content,
          pubDate,
          speakerId: message.actorId,
          speakerRole: message.actorType.toLowerCase(),
          msgId: id,
          chat_name: null,
          suggestions: null,
          hints: null,
        };
      });

      // Handle Focused Message
      const { messagePosition } = qs.parse(search);
      if (messagePosition) {
        const focusedMessageId = getFocusedMessageIdFromMessages(mappedMessages, messagePosition);
        setFocusedMessageId(focusedMessageId);
      }
      setChatMessages(mappedMessages);
    }).catch((err) => {
      notification.error({
        message: 'Error Fetching Data',
        description: err.message,
      });
    }).finally(() => {
      toggleLoading(false);
    });
  }, [pathname, search]);

  return (
    <ChatWindowComponent
      customerProfile={customerProfile}
      chatId={conversationId}
      loading={loading}
      messages={chatMessages}
      focusedMessageId={focusedMessageId}
    />
  );
};

export default ChatWindow;
