import React, { useMemo, useContext } from 'react';
import { Title, MultiSelect, Group, Box, Divider, useMantineTheme } from '@mantine/core';
import { ListActiveConfigsResponseConfig as Config } from '@cresta/web-client/dist/cresta/v1/studio/config/config_service.pb';
import { flattenCustomerConfigs } from 'components/CustomerPicker';
import { PageContainer } from 'components/PageContainer';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { TaskPlaylistTable } from 'pages/LabelingView/TaskPlaylistTable';
import { TaskPlaylistContext, TaskPlaylistProvider } from 'pages/LabelingView/TaskPlaylistProvider';
import { UserContext } from 'context/UserContext';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectTaskPlaylistCustomers } from 'store/app/selectors';
import { updateTaskPlaylistCustomers } from 'store/app/appSlice';
import { getCustomersCurrentUserIds } from 'store/app/asyncThunks';
import { CustomerParams } from 'hooks/useCustomerParams';
import { TaskStatCards } from './TaskStatCards';

const getFirstName = (fullName: string) => {
  const names = fullName?.split(' ');
  return names?.[0] || '';
};

export default function TasksDashboard() {
  const theme = useMantineTheme();
  const { allConfigs, getFirstConfig } = useContext(CustomerConfigContext);
  const currentUser = useContext(UserContext);
  const dispatch = useDispatch();

  const taskPlaylistCustomers = useSelector<CustomerParams[]>(selectTaskPlaylistCustomers);
  const selectedCustomers = useMemo(() => taskPlaylistCustomers.map((c) => `${c.customerId}/${c.profileId}/${c.usecaseId}/${c.languageCode}`), [taskPlaylistCustomers]);

  const customersData = useMemo(() => flattenCustomerConfigs(allConfigs), [allConfigs]);

  const handleChangeSelectedCustomers = (selectedCustomers: string[]) => {
    const taskPlaylistCustomers: CustomerParams[] = selectedCustomers.map((v) => {
      const [customerId, profileId, usecaseId, languageCode] = v.split('/');
      return { customerId, profileId, usecaseId, languageCode };
    });
    dispatch(updateTaskPlaylistCustomers(taskPlaylistCustomers));
    localStorage.setItem('taskPlaylistCustomers', JSON.stringify(taskPlaylistCustomers));
    const uniqueConfigs = getUniqueConfigs(taskPlaylistCustomers, getFirstConfig);
    if (uniqueConfigs.length) {
      dispatch(getCustomersCurrentUserIds({
        customerConfigs: uniqueConfigs,
        email: currentUser.email,
      }));
    }
  };

  return (
    <PageContainer>
      <Title>{!!currentUser && `Hi, ${getFirstName(currentUser?.fullName)}!`}</Title>
      <Divider color={theme.colors.gray[1]}/>

      <TaskPlaylistProvider selectedCustomers={taskPlaylistCustomers}>
        <TaskPlaylistContext.Consumer>
          {
              ({ openedTasks, closedTasks, currentUserCount, unassignedCount, isLoading }) => (
                <>
                  <TaskStatCards
                    openedTasks={openedTasks}
                    closedTasks={closedTasks}
                    openAssigned={currentUserCount}
                    openUnassigned={unassignedCount}
                    loading={isLoading}
                  />
                  <Box mt="lg">
                    <Title order={2} mb="sm">My tasks</Title>
                    <Group mb="lg">
                      <MultiSelect
                        searchable
                        placeholder="Select customers"
                        data={customersData}
                        value={selectedCustomers}
                        onChange={handleChangeSelectedCustomers}
                      />
                    </Group>
                    <TaskPlaylistTable
                      tasks={openedTasks}
                      loading={isLoading}
                    />
                  </Box>
                </>
              )
            }
        </TaskPlaylistContext.Consumer>
      </TaskPlaylistProvider>
    </PageContainer>
  );
}

// Get unique configs given a list of customer parameters.
export function getUniqueConfigs(customers: CustomerParams[], getConfig: (c: CustomerParams) => Config): Config[] {
  const dedupSet = new Set<string>();
  const customerConfigs = [];
  customers.forEach((customer) => {
    const { customerId, profileId } = customer;
    if (!dedupSet.has(`${customerId}/${profileId}`)) {
      dedupSet.add(`${customerId}/${profileId}`);
      customerConfigs.push(getConfig({ customerId, profileId, usecaseId: '', languageCode: '' }));
    }
  });
  return customerConfigs;
}
