import React, { useState, useCallback, ReactElement, useEffect, useMemo } from 'react';
import './ConceptForm.scss';
import { FormInstance, Form, Input, Select, Radio } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/lib/form/interface';
import {
  Concept,
  ConceptConceptTagType,
  IntentIntentType,
  ConceptConceptType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { conceptNameRule } from 'utils/form-rules';
import { toTitleCase } from 'utils';
import { isIntent } from 'components/TaxonomyTree';
import { RoleIcon } from 'components/RoleIcon';
import Pill from 'components/Pill';
import { cloneDeep } from 'lodash';
import { AVAILABLE_TAGS, CategoryType, HIDDEN_TAGS } from 'pages/Taxonomy/TaxonomyGroup/TaxonomyTab';
import { Flex, TextInput } from '@mantine/core';

export const EDITABLE_CONCEPT_FIELDS: NamePath[] = [
  'conceptTitle',
  ['intent', 'intentType'],
  'conceptTags',
  'description',
  'labelingGuideline',
  'positiveExamples',
  'negativeExamples',
  ['summarization', 'question'],
  ['intent', 'displayName'],
  ['intent', 'regexExpressions'],
  ['intent', 'negativeRegexExpressions'],
  ['intent', 'needsContext'],
  ['entity', 'regexExpressions'],
  ['entity', 'negativeRegexExpressions'],
  ['conversationOutcome', 'regexExpressions'],
  ['conversationOutcome', 'negativeRegexExpressions'],
];

interface ConceptFormProps {
  form: FormInstance<Concept>;
  concept: Concept;
  onFieldsChange: () => void;
  showTags?: boolean;
  setDisabledSave: (value: boolean) => void;
}

export function ConceptForm({
  form,
  concept,
  onFieldsChange,
  showTags,
  setDisabledSave,
}: ConceptFormProps): ReactElement {
  const { resetFields } = form;

  // Reset fields when concept changes.
  useEffect(() => resetFields(), [concept]);

  // Concept type display name.
  const conceptType = toTitleCase(concept.conceptType);

  // Only the last segment of concept title is editable.
  const segments = concept.conceptTitle.split('.');
  const conceptName = segments.pop();
  const conceptPrefix = segments.length ? `${segments.join('.')}.` : '';

  // Active (positive/negative) examples/regex being edited.
  const [activeExamples, setActiveExamples] = useState<'positive' | 'negative'>('positive');
  const [activeRegex, setActiveRegex] = useState<'positive' | 'negative'>('positive');

  const handleSelectCategory = useCallback((value: CategoryType) => {
    const conceptTags: ConceptConceptTagType[] = form.getFieldValue('conceptTags');
    let updatedTags = [...conceptTags];

    // Remove and add correct tags
    updatedTags = updatedTags.filter((tag) => !HIDDEN_TAGS.includes(tag));
    if (value === 'call-flow') {
      updatedTags.push(ConceptConceptTagType.CALL_FLOW);
    } else if (value === 'cross-customer') {
      updatedTags.push(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
    }
    form.setFieldsValue({
      ...form.getFieldsValue(),
      conceptTags: updatedTags,
    });
    setDisabledSave(false);
  }, [form]);

  const isCrossCustomer = concept?.conceptTags?.includes(ConceptConceptTagType.CROSS_CUSTOMER_LABELING);
  const isCallFlow = concept?.conceptTags?.includes(ConceptConceptTagType.CALL_FLOW);
  const categoryRadioValue: CategoryType = useMemo(() => {
    if (isCallFlow) return 'call-flow';
    if (isCrossCustomer) return 'cross-customer';
    return null;
  }, [isCallFlow, isCrossCustomer]);

  return (
    <Form
      form={form}
      layout="vertical"
      labelCol={{ span: 11 }}
      onFieldsChange={onFieldsChange}
    >
      <Form.Item
        label={`${conceptType} Name`}
        name="conceptTitle"
        initialValue={conceptName}
        rules={[
          { required: true, message: 'Required' },
          conceptNameRule,
        ]}
      >
        <Input
          placeholder="enter name"
          addonBefore={conceptPrefix}
          autoComplete="off"
        />
      </Form.Item>
      {isIntent(concept) && (
        <Form.Item
          label="Intent type"
          name={['intent', 'intentType']}
          initialValue={concept.intent?.intentType}
        >
          <div className="intent-type">
            <RoleIcon conceptRole={concept.intent?.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor'} />
            <span>{concept.intent?.intentType === IntentIntentType.AGENT_INTENT ? 'Agent' : 'Visitor'}</span>
          </div>
        </Form.Item>
      )}
      {concept.conceptType === ConceptConceptType.SUMMARIZATION && (
        <Form.Item
          label="Question"
          name={['summarization', 'question']}
          initialValue={concept.summarization?.question}
        >
          <Input.TextArea
            placeholder="Add question"
            rows={2}
          />
        </Form.Item>
      )}
      <Form.Item
        label="Shared Library Tags"
        name="conceptTags"
        initialValue={concept.conceptTags}
        hidden={!showTags}
      >
        <Select
          showArrow
          allowClear
          mode="multiple"
          placeholder="Select tags"
          size="large"
          tagRender={(props) => {
            // Hide tags we don't want to display
            if (HIDDEN_TAGS.includes(props.value)) return null;

            return (
              <div className="concept-tag-pill">
                {toTitleCase(props.value as string)}
                <div
                  className="tag-close-button"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={props.onClose}
                >
                  <CloseCircleOutlined />
                </div>
              </div>
            );
          }}
        >
          {AVAILABLE_TAGS
            .map((tag) => tag as ConceptConceptTagType)
            .map((tag) => (
              <Select.Option key={tag} value={tag}>
                <div className="concept-tag-option">{toTitleCase(tag)}</div>
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['intent', 'displayName']}
        label="Human-readable name"
        initialValue={concept?.intent?.displayName}
      >
        <TextInput
          placeholder="Add human-readable name"
        />
      </Form.Item>
      <Form.Item
        label="Category"
      >
        <Radio.Group
          onChange={(e) => handleSelectCategory(e.target.value)}
          value={categoryRadioValue}
        >
          <Radio value="call-flow" checked={isCallFlow}>Call flow</Radio>
          <Radio value="cross-customer" checked={isCrossCustomer}>Cross customer labeling</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        initialValue={concept.description}
      >
        <Input.TextArea
          placeholder="Add description"
          rows={6}
        />
      </Form.Item>
      <Form.Item
        label="Needs Context"
        name={['intent', 'needsContext']}
      >
        <Radio.Group>
          <Flex gap="xs">
            <Radio value="show">Yes</Radio>
            <Radio value="hide">No</Radio>
          </Flex>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Labeling Guidelines"
        name="labelingGuideline"
        initialValue={concept.labelingGuideline}
      >
        <Input.TextArea
          placeholder="Add labeling guidelines"
          rows={6}
        />
      </Form.Item>
      <div>
        <label>Canonical Examples</label>
        <div className="pills-wrapper">
          <Pill
            text="Positive"
            count={Form.useWatch('positiveExamples', form)?.length || 0}
            value="positive"
            active={activeExamples === 'positive'}
            onClick={() => {
              setActiveExamples('positive');
            }}
          />
          <Pill
            text="Negative"
            count={Form.useWatch('negativeExamples', form)?.length || 0}
            value="negative"
            active={activeExamples === 'negative'}
            onClick={() => {
              setActiveExamples('negative');
            }}
          />
        </div>
        <div className="examples-wrapper">
          <Form.Item
            name="positiveExamples"
            initialValue={concept.positiveExamples}
            hidden={activeExamples !== 'positive'}
          >
            <ListInput />
          </Form.Item>
          <Form.Item
            name="negativeExamples"
            initialValue={concept.negativeExamples}
            hidden={activeExamples !== 'negative'}
          >
            <ListInput />
          </Form.Item>
        </div>
      </div>
      <div hidden={concept.conceptType === ConceptConceptType.SUMMARIZATION}>
        <label>Regex</label>
        <div className="pills-wrapper">
          <Pill
            text="Positive"
            count={Form.useWatch(getPositiveRegexPath(concept), form)?.length || 0}
            value="positive"
            active={activeRegex === 'positive'}
            onClick={() => {
              setActiveRegex('positive');
            }}
          />
          <Pill
            text="Negative"
            count={Form.useWatch(getNegativeRegexPath(concept), form)?.length || 0}
            value="negative"
            active={activeRegex === 'negative'}
            onClick={() => {
              setActiveRegex('negative');
            }}
          />
        </div>
        <div className="examples-wrapper">
          <Form.Item
            name={getPositiveRegexPath(concept)}
            initialValue={getPositiveRegex(concept)}
            hidden={activeRegex !== 'positive'}
          >
            <ListInput />
          </Form.Item>
          <Form.Item
            name={getNegativeRegexPath(concept)}
            initialValue={getNegativeRegex(concept)}
            hidden={activeRegex !== 'negative'}
          >
            <ListInput />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

function getPositiveRegexPath(concept: Concept): NamePath {
  switch (concept.conceptType) {
    case ConceptConceptType.CONVERSATION_OUTCOME:
      return ['conversationOutcome', 'regexExpressions'];
    case ConceptConceptType.ENTITY:
      return ['entity', 'regexExpressions'];
    case ConceptConceptType.INTENT:
      return ['intent', 'regexExpressions'];
    default:
      return [];
  }
}

function getPositiveRegex(concept: Concept): string[] {
  switch (concept.conceptType) {
    case ConceptConceptType.CONVERSATION_OUTCOME:
      return concept.conversationOutcome.regexExpressions;
    case ConceptConceptType.ENTITY:
      return concept.entity.regexExpressions;
    case ConceptConceptType.INTENT:
      return concept.intent.regexExpressions;
    default:
      return [];
  }
}

function getNegativeRegexPath(concept: Concept): NamePath {
  switch (concept.conceptType) {
    case ConceptConceptType.CONVERSATION_OUTCOME:
      return ['conversationOutcome', 'negativeRegexExpressions'];
    case ConceptConceptType.ENTITY:
      return ['entity', 'negativeRegexExpressions'];
    case ConceptConceptType.INTENT:
      return ['intent', 'negativeRegexExpressions'];
    default:
      return [];
  }
}

function getNegativeRegex(concept: Concept): string[] {
  switch (concept.conceptType) {
    case ConceptConceptType.CONVERSATION_OUTCOME:
      return concept.conversationOutcome.negativeRegexExpressions;
    case ConceptConceptType.ENTITY:
      return concept.entity.negativeRegexExpressions;
    case ConceptConceptType.INTENT:
      return concept.intent.negativeRegexExpressions;
    default:
      return [];
  }
}

interface ListInputProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

function ListInput({
  value,
  onChange,
}: ListInputProps): ReactElement {
  const [listItem, setListItem] = useState<string>('');

  const addListItem = useCallback(() => {
    if (!listItem.length) return;
    if (listItem.match(/^\s+$/)) return;
    const newList = cloneDeep(value) || [];
    newList.push(listItem);
    if (onChange) {
      onChange(newList);
    }
    setListItem('');
  }, [listItem]);

  const addListItemByKeyboard = useCallback((
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key !== 'Enter') return;
    // Prevents line breaks from being added to textarea
    event.preventDefault();
    addListItem();
  }, [addListItem]);

  const handleInputChange = (event) => {
    setListItem(event.currentTarget.value);
  };

  const removeListItem = (index: number) => {
    const newList = cloneDeep(value) || [];
    newList.splice(index, 1);
    if (onChange) {
      onChange(newList);
    }
  };

  return (
    <div className="list-input-wrapper">
      <Input.TextArea
        placeholder="Enter value and hit 'enter'"
        value={listItem}
        onBlur={() => addListItem()}
        onKeyPress={(event) => addListItemByKeyboard(event)}
        rows={2}
        onChange={(event) => handleInputChange(event)}
      />

      {value?.length > 0 && (
        <div className="list-items-container">
          {value.map((item, i) => (
            <div key={item} className="list-item">
              <span>{item}</span>
              <span className="spacer" />
              <div
                onClick={() => removeListItem(i)}
                className="close-list-item-button"
              >
                <CloseCircleOutlined />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
