import { Textarea, Group, Button, TextInput, Center, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getErrorMessage } from 'utils';
import { showNotification } from '@mantine/notifications';
import { PromptSection } from '@cresta/web-client/dist/cresta/v1/studio/prompt/prompt_service.pb';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { PromptApi } from 'services/promptApi';
import { IPrompt, usePrompts } from './hooks/usePrompts';
import { VersionSelect } from './components/VersionSelect';

export const handleSections = (sections: PromptSection[]): string => {
  let promptText = '';
  // concat all sections into one string with newLines
  sections.forEach((section) => {
    promptText += section.promptTemplate;
    promptText += '\n';
  });
  return promptText;
};

export const PromptEditor = (props) => {
  const { promptId, updateActivePromptIds } = props;

  const { customerId, profileId } = useCustomerParams();

  // form state
  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [promptText, setPromptText] = useState('');
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedPromptGroupId, setSelectedPromptGroupId] = useState<string | null>(null);
  const [selectedVersionIsActive, setSelectedVersionIsActive] = useState(false);

  const { deployedPrompt, listPrompts, createPrompt, updatePrompt, deployPrompt } = usePrompts();

  const resetForm = () => {
    setDisplayName('');
    setPromptText('');
    setSelectedPromptGroupId(null);
    setSelectedVersion(null);
    setSelectedVersionIsActive(false);
  };

  // fetch prompt info on mount
  useEffect(() => {
    if (!promptId) {
      resetForm();
      return;
    }
    setIsLoading(true);
    const fetchPrompt = async () => {
      const { prompt } = await PromptApi.getPrompt({
        name: `customers/${customerId}/profiles/${profileId}/prompts/${promptId}`,
      });

      setPromptText(handleSections(prompt.sections));
      setDisplayName(prompt.displayName);
      setSelectedVersion(prompt.version);
      setSelectedPromptGroupId(prompt.promptGroupId);
      setSelectedVersionIsActive(prompt.isActiveVersion);
      setIsLoading(false);
    };
    fetchPrompt();
  }, [promptId]);

  const onUpdate = () => {
    setIsLoading(true);
    try {
      updatePrompt({
        id: promptId,
        displayName,
        promptText,
      });
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessage(e);
      showNotification({
        title: 'Error updating prompt',
        message: errorMessage,
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCreate = async () => {
    try {
      if (!displayName) throw new Error('Display name is required');
      if (!promptText) throw new Error('Prompt text is required');
      const newPrompt = await createPrompt({
        displayName,
        promptText,
      });
      updateActivePromptIds(newPrompt.id, newPrompt.promptGroupId);
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessage(e);
      showNotification({
        title: 'Error creating prompt',
        message: errorMessage,
        color: 'red',
      });
    }
  };

  const onCreateNewVersion = async () => {
    try {
      if (!displayName) throw new Error('Display name is required');
      if (!promptText) throw new Error('Prompt text is required');
      const newPrompt = await createPrompt({
        displayName,
        promptText,
        promptGroupId: selectedPromptGroupId,
      });
      updateActivePromptIds(newPrompt.id, newPrompt.promptGroupId);
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessage(e);
      showNotification({
        title: 'Error creating prompt',
        message: errorMessage,
        color: 'red',
      });
    }
  };

  const originalPrompt = listPrompts.find((p) => p.id === promptId);
  const isDisplayNameChanged = originalPrompt?.displayName !== displayName;
  const isPromptTextChanged = originalPrompt?.promptText !== promptText;
  const haveFormValuesChanged = isDisplayNameChanged || isPromptTextChanged;

  const getUpdateDisabledStatus = () => {
    if (!promptId) return false;
    if (!originalPrompt) return false;
    return !isDisplayNameChanged && !isPromptTextChanged;
  };
  const isUpdateDisabled = getUpdateDisabledStatus();

  const onDeploy = () => {
    if (!isUpdateDisabled) return;
    const promptToDeploy = listPrompts.find((p) => p.id === promptId);
    deployPrompt(promptToDeploy);
  };

  const isAlreadyDeployed = deployedPrompt && deployedPrompt.id === promptId;

  const isDeployDisabled = !promptId || !isUpdateDisabled || isAlreadyDeployed;

  const onSetPrompt = (prompt: IPrompt) => {
    updateActivePromptIds(prompt.id, prompt.promptGroupId);
  };

  const isVersioningEnabled = selectedPromptGroupId && selectedVersion;

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader size="lg" />
      </Center>
    );
  }

  return (
    <>
      <Group>
        <TextInput
          style={{ flex: 1 }}
          value={displayName}
          onChange={(e) => setDisplayName(e.currentTarget.value)}
          label="Display Name"
          placeholder="Enter display name here..."
        />
        {isVersioningEnabled && (
          <VersionSelect
            isActiveVersion={selectedVersionIsActive}
            promptGroupId={selectedPromptGroupId}
            initialVersion={selectedVersion}
            onSelectPrompt={onSetPrompt}
          />
        )}
      </Group>
      <Textarea
        value={promptText}
        onChange={(e) => setPromptText(e.currentTarget.value)}
        label="Prompt"
        placeholder="Enter prompt here..."
        mb="lg"
        styles={() => ({
          root: { flex: 1 },
          wrapper: { height: '100%' },
          input: { height: '100%' },
        })}
      />
      <Group style={{ alignSelf: 'flex-end' }}>
        {isVersioningEnabled && (
          <Button variant="outline" onClick={onCreateNewVersion} disabled={!haveFormValuesChanged}>
            Create New Version
          </Button>
        )}
        {promptId ? (
          <Button variant="outline" onClick={onUpdate} disabled={isUpdateDisabled}>
            Update Version
          </Button>
        ) : (
          <Button variant="outline" onClick={onCreate}>
            Create
          </Button>
        )}
        <Button disabled={isDeployDisabled} onClick={onDeploy}>
          Deploy
        </Button>
      </Group>
    </>
  );
};
