/**
 * When doing a policy QA task, we need to display these placeholder concepts in the dropdown.
 * Their sole purpose is to show labeling guidelines.
 */

import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';

export const SDXNegative: Concept = {
  intent: {},
  name: 'customers/cresta/profiles/placeholder/concepts/policy_qa_placeholder_sdx_negative',
  conceptTitle: 'Policy QA: SDX Negative',
  labelingGuideline: `
Please decide if a SDX should have shown here. 

CORRECT - no SDX should have shown. 
INCORRECT - a SDX should have shown. Please choose this and indicate which SDX should be here.    
  `,
};

export const SDXPositive: Concept = {
  intent: {},
  name: 'customers/cresta/profiles/placeholder/concepts/policy_qa_placeholder_sdx_positive',
  conceptTitle: 'Policy QA: SDX Positive',
  labelingGuideline: `
Please decide if the SDX shown here is correct. If it is incorrect, it can be because of 

(1) the rules as implemented -> please indicate why the rule is wrong in the reason field and mark intents as all correct
  
(2) the trigger intents -> choose the incorrect intent(s)
  
(3) both the rules + trigger intents are correct but it is still wrong -> please indicate why this is still wrong in the reason field and mark intents as correct
  
After choosing incorrect reason, please indicate if another SDX should have shown here instead.    
  `,
};

export const POLICY_QA_GUIDELINES_CONCEPTS = [SDXPositive, SDXNegative];
