import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Button, Group, Stack, Text } from '@mantine/core';
import Table, { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import ConversationPreview from 'components/ConversationPreview';
import CopyableValue from 'components/CopyableValue';
import { toMessage } from 'components/LabelingMessage';
import Loading from 'components/Loading';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import React, { useMemo, useState } from 'react';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { listConversationMessages } from 'store/conversation/asyncThunks';
import { selectConversationMessages, selectApiStatus as selectConversationApiStatus } from 'store/conversation/selectors';
import { selectAnnotationBundles, selectApiStatus as selectTaskApiStatus } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import * as CSV from 'csv-string';
import { downloadDataString } from 'utils/data-export';
import { useHotkeys } from '@mantine/hooks';
import { AddUtteranceModal } from './AddUtteranceModal';
import styles from './styles.module.scss';

interface AnalysisUtteranceTableProps {
  searchTerm: string;
  taskId: string;
  conceptId: string;
  getConceptTitle: (id: string) => string;
}

export default function AnalysisUtteranceTable({
  searchTerm,
  taskId,
  conceptId,
  getConceptTitle,
}: AnalysisUtteranceTableProps) {
  const dispatch = useDispatch();
  const taskApiStatus = useSelector<ApiStatus>(selectTaskApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const customerProfile = useCustomerProfile();

  // For conversation preview
  const [showConversation, setShowConversation] = useState(false);
  const messages = useSelector<Message[]>(selectConversationMessages).map(toMessage);
  const [selectedRowRecord, setSelectedRowRecord] = useState<SearchAnnotationsResponseAnnotationBundle>();
  const conversationApiStatus = useSelector<ApiStatus>(selectConversationApiStatus);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpen, toggleOpen] = useState(false);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns: ColumnsType<SearchAnnotationsResponseAnnotationBundle> = [
    {
      title: 'Chat ID',
      dataIndex: 'chatId',
      key: 'chatId',
      render: (value, row) => {
        const id = row.annotation.rawData.messageRawData.v2ConversationId;
        return <CopyableValue displayValue="" copiedValue={id} tooltip={id}/>;
      },
    },
    {
      title: 'Utterance',
      dataIndex: ['textMessage'],
      key: 'message',
      width: '50%',
    },
    {
      title: 'Set',
      dataIndex: ['annotation', 'value', 'setId'],
      key: 'set',
      render: (value, row) => <CopyableValue displayValue={value} copiedValue={value} tooltip={value}/>,
    },
  ];

  // Message from selected table row
  const focusedMessage = useMemo(() => messages.find(
    (message) => message.messageId === selectedRowRecord?.annotation.rawData.messageRawData.v2MessageId,
  ), [messages, selectedRowRecord]);

  // Export utterances as CSV.
  const exportCSV = () => {
    const csvData = annotationBundles.map((annotationBundle) => ([
      annotationBundle?.textMessage,
    ]));
    const csvString = CSV.stringify(csvData);
    const dataString = `data:text/tab-separated-values;charset=utf-8,${encodeURIComponent(csvString)}`;
    downloadDataString(dataString, `utterances_${taskId}_${conceptId}.csv`);
  };
  const [isExportHidden, toggleExportHidden] = useState(true);

  useHotkeys([
    ['ctrl + E', () => toggleExportHidden((prev) => !prev)],
  ]);

  return (
    <>
      <AddUtteranceModal
        isOpen={isOpen}
        onClose={() => toggleOpen(false)}
        utteranceData={selectedRowKeys.map((key) => {
          const bundle = annotationBundles.find((bundle) => bundle.annotation.name === key);
          const data = {
            label: bundle?.textMessage,
            value: bundle.annotation.name,
          };
          return data;
        })}
        onRemoveUtterance={(utteranceId) => {
          const newSelectedRowKeys = selectedRowKeys.filter((key) => key !== utteranceId);
          setSelectedRowKeys(newSelectedRowKeys);
          // if no more utterances, close modal
          if (newSelectedRowKeys.length === 0) {
            toggleOpen(false);
          }
        }}
      />
      <Stack align="stretch" style={{ flex: 1 }}>
        <Group>
          <Text>{selectedRowKeys.length} selected</Text>
          {hasSelected && <Button compact variant="subtle" onClick={() => toggleOpen(true)}>Add these utterances</Button>}
          {!isExportHidden && (
            <Button
              className={styles.filter}
              color="green"
              size="xs"
              compact
              variant="outline"
              onClick={exportCSV}
            >Export CSV
            </Button>
          )}
        </Group>
        <Table
          rowSelection={rowSelection}
          rowKey={(row) => row.annotation.name}
          loading={{
            spinning: taskApiStatus === 'loading',
            indicator: <Loading />,
          }}
          className={classNames([styles.summaryTable, styles.policyTable])}
          columns={columns}
          dataSource={annotationBundles}
          onRow={(record) => ({
            onClick: (e) => {
              if (!record.annotation.rawData.messageRawData.v2MessageId) return;
              setSelectedRowRecord(record);
              setShowConversation(true);
              const conversationName = `${customerProfile}/conversations/${record.annotation.rawData.messageRawData.v2ConversationId}`;
              dispatch(listConversationMessages(conversationName));
            },
          })}
        />
      </Stack>
      <ConversationPreview
        messages={messages}
        focusedMessageId={focusedMessage?.messageId}
        onClose={() => setShowConversation(false)}
        showConversation={showConversation}
        loading={conversationApiStatus === 'loading'}
      />
    </>
  );
}
