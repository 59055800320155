export const dig = (o: object, property: string): any => {
  const props = property.split('.');
  let val = o;
  for (const p of props) {
    val = val[p];
    if (val == null) return null;
  }
  return val;
};

export const truncateMiddle = (
  s: string,
  keepBefore: number = 6,
  keepAfter: number = 6,
): string => {
  if (!s) {
    return s;
  }
  if (s.length < keepBefore + keepAfter) {
    return s;
  }
  return `${s.slice(0, keepBefore)}...${s.slice(-keepAfter)}`;
};

export function insertArrayIfNotExists<T>(
  array_: T[],
  value: T,
  prepend: boolean = false,
  inPlace: boolean = true,
): T[] {
  let a = array_;
  const index = array_.indexOf(value);
  if (index !== -1) {
    // move to front/back of array
    if (!inPlace) {
      const filtered = a.filter((el) => el !== value);
      if (prepend) {
        return [value].concat(filtered);
      }
      return filtered.concat([value]);
    } else {
      const removed = a.splice(index, 1);
      if (prepend) {
        return removed.concat(a);
      } else {
        return a.concat(removed);
      }
    }
  }

  if (!inPlace) {
    a = array_.slice();
  }
  if (prepend) {
    a.unshift(value);
  } else {
    a.push(value);
  }
  return a;
}

export function isEqual(obj1, obj2, keys?: string[]): boolean {
  const _keys = keys || Object.keys(obj1);
  for (const key of _keys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/** Enum CompareFn factory. T: enum type, E: entity type. */
export function enumCompareFnFactory<T, E>(values: T[], accessor: (e: E) => T): (a: E, b: E) => number {
  return (a: E, b: E) => {
    const valueA = accessor(a);
    const valueB = accessor(b);
    return values.indexOf(valueA) - values.indexOf(valueB);
  };
}

/** Convert to 'Title Case' by the split character. */
export function toTitleCase(value: string, split = '_'): string {
  return value?.split(split)
    .map((seg) => seg.substring(0, 1).toUpperCase() + seg.substring(1).toLowerCase())
    .join(' ') || '';
}

/** Convert update mask paths array with camel case to comma joined snake case. */
export function toRequestUpdateMask(paths: string[]): string {
  return paths.map((field) => {
    let res = '';
    for (const c of field) {
      if (c.toLowerCase() === c) {
        res = `${res}${c}`;
      } else {
        res = `${res}_${c.toLowerCase()}`;
      }
    }
    return res;
  }).join(',');
}

export function stripHTMLFromString(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export { getErrorMessage } from './getErrorMessage';
