import React from 'react';
import { DisconnectOutlined, EyeOutlined } from '@ant-design/icons';
import { ActionIcon, Box, Checkbox, createStyles, Flex, Group, Text, useMantineTheme } from '@mantine/core';
import { stringToColor } from 'pages/AnalysisWorkshop/TaggingPage/utils/colors';
import { Cluster } from '@cresta/web-client/dist/cresta/v1/studio/cluster/cluster_service.pb';
import { getPercent } from '../CallDriverDiscovery';

interface SidebarClusterItemProps {
  clustersMap: Map<string, Cluster>;
  cluster: Cluster;
  rootSum: number;
  isEditingMoments: boolean;
  isChecked?: boolean;
  handleHoverCluster: (cluster: Cluster) => void;
  handleSelectCluster: (cluster: Cluster) => void;
  handleHideCluster: (cluster: Cluster, value: boolean) => void;
  handleCheckCluster: (cluster: Cluster, value: boolean) => void;
  handleUnmergeClusters: (cluster: Cluster) => void;
}

export default function SidebarClusterItem({
  clustersMap,
  cluster,
  isChecked,
  isEditingMoments,
  handleHoverCluster,
  handleSelectCluster,
  handleHideCluster,
  handleCheckCluster,
  handleUnmergeClusters,
  rootSum,
}: SidebarClusterItemProps) {
  const useStyles = createStyles((theme) => ({
    hoverableItem: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.blue[0],
      },
    },
    actionIcon: {
      fontSize: 16,
      color: theme.colors.gray[0],
      '&:hover': {
        color: theme.colors.gray[6],
      },
    },
  }));
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <Box
      className={classes.hoverableItem}
      onMouseEnter={() => handleHoverCluster(cluster)}
      onMouseLeave={() => handleHoverCluster(null)}
    >
      <Flex
        justify="flex-end"
        px="md"
        py="xs"
        className={classes.hoverableItem}
        key={cluster.name}
      >
        <Group style={{ flex: 1 }} noWrap spacing="xs">
          {isEditingMoments && (
          <>
            <ActionIcon color="gray" variant="subtle" size="sm">
              <EyeOutlined
                style={{
                  fontSize: 16,
                  color: theme.colors.gray[4],
                }}
                onClick={() => handleHideCluster(cluster, !cluster.isHidden)}
              />
            </ActionIcon>
            <Checkbox
              styles={{
                body: {
                  display: 'flex',
                },
              }}
              checked={isChecked}
              onChange={(event) => handleCheckCluster(cluster, event.currentTarget.checked)}
            />
          </>
          )}
          <Box style={{
            width: 20,
            height: 20,
            flexShrink: 0,
            borderRadius: 4,
            backgroundColor: stringToColor(cluster.clusterTitle),
          }}
          />
          <Text
            onClick={() => handleSelectCluster(cluster)}
            size="md"
          >{cluster.clusterTitle}
          </Text>
        </Group>
        <Text size="md" align="right" style={{ width: 60 }}>{cluster.clusterItemCount}</Text>
        <Text size="md" align="right" style={{ width: 60 }}>{getPercent(cluster.clusterItemCount, rootSum)}%</Text>
      </Flex>
      {cluster.childClustersReferenceIds.map((childClusterId) => {
        const childCluster = clustersMap.get(childClusterId);
        if (!childCluster) return null;
        return (
          <Flex
            justify="flex-end"
            px="md"
            py="xs"
            key={childCluster.name}
          >
            <Group style={{ flex: 1 }} noWrap spacing="xs">
              {isEditingMoments && (
                <ActionIcon
                  onClick={() => handleUnmergeClusters(cluster)}
                  color="gray"
                  variant="subtle"
                  size="sm"
                >
                  <DisconnectOutlined/>
                </ActionIcon>
              )}
              <Text
                size="md"
                color="dimmed"
              >{childCluster.clusterTitle}
              </Text>
            </Group>
            <Text size="md" align="right" color="dimmed" w={60}>{childCluster.clusterItemCount}</Text>
            <Text size="md" align="right" color="dimmed" w={60}>{getPercent(childCluster.clusterItemCount, rootSum)}%</Text>
          </Flex>
        );
      })}
    </Box>
  );
}
