import React, { useCallback, useEffect, useRef } from 'react';
import Editor, { Monaco, DiffEditor } from '@monaco-editor/react';
import { editor, MarkerSeverity, IDisposable } from 'monaco-editor';
import { Python3Parser } from 'dt-python-parser';
import './editor.scss';

export interface IDPEditorProps {
  currentTab?: string;
  defaultValue: string;
  onCodeChange: (value: string) => void;
  diff?: boolean;
  diffWith?: string;
  originalValue?: string;
  options?: editor.IStandaloneEditorConstructionOptions;
}
export const PythonEditor: React.FC<IDPEditorProps> = ({
  currentTab,
  defaultValue,
  onCodeChange,
  diff,
  diffWith,
  originalValue,
  options,
}) => {
  const parser = new Python3Parser();
  const listenersRef = useRef<IDisposable[]>([]);
  const editorRef = useRef<editor.IStandaloneCodeEditor>(null);

  useEffect(() => (() => {
    listenersRef.current.forEach((d) => d.dispose());
    listenersRef.current = [];
  }), []);

  useEffect(() => {
    const model = editorRef.current?.getModel();
    model?.setValue(defaultValue);
  }, [currentTab]);

  const handleEditorWillMount = useCallback((monaco: Monaco) => {
    listenersRef.current.push(monaco.editor.onDidCreateModel((model: editor.ITextModel) => {
      if (model.uri.path.includes('draft')) {
        const validate = (code: string) => {
          const errors = parser.validate(code);
          const markers = [];
          errors.forEach((err) => {
            const range = {
              startLineNumber: err.startLine,
              startColumn: err.startCol + 1,
              endLineNumber: err.endLine,
              endColumn: err.endCol + 1,
              message: err.message,
              severity: MarkerSeverity.Error,
            };
            markers.push(range);
          });
          monaco.editor.setModelMarkers(model, 'python', markers);
        };

        let handler;
        listenersRef.current.push(model.onDidChangeContent(() => {
          const code = model.getValue();
          onCodeChange(code);
          clearTimeout(handler);
          handler = setTimeout(() => validate(code), 1000);
        }));
        validate(model.getValue());
      }
    }));
  }, []);

  const handleDiffEditorWillMount = useCallback((monaco: Monaco) => {
    listenersRef.current.push(monaco.editor.onDidCreateModel((model: editor.ITextModel) => {
      if (model.uri.path.includes('draft')) {
        const updateCode = () => {
          const code = model.getValue();
          onCodeChange(code);
        };
        listenersRef.current.push(model.onDidChangeContent((e: editor.IModelContentChangedEvent) => {
          updateCode();
        }));
      }
    }));
  }, []);

  return diff ? (
    <DiffEditor
      originalModelPath={diffWith}
      original={originalValue}
      modifiedModelPath={currentTab}
      modified={defaultValue}
      language="python"
      options={options}
      beforeMount={handleDiffEditorWillMount}
    />
  ) : (
    <Editor
      path={currentTab}
      language="python"
      value={defaultValue}
      beforeMount={handleEditorWillMount}
      options={options}
      onMount={(editor) => { editorRef.current = editor; }}
    />
  );
};
