import { Message } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import uniq from 'lodash/uniq';

const getConversationNamesFromMessageNames = (messageNames: string[]) => {
  // Format: "customers/cresta/profiles/chat-demo/conversations/4e55e6fd-b2ef-41ee-affe-6bf085399a09/messages/16718765ffd7b8a5ed6f42d33101da49527fe195"
  // remove /messages/*
  const conversationNames = [];
  messageNames.forEach((messageName) => {
    const conversationName = messageName.split('/messages')[0];
    conversationNames.push(conversationName);
  });
  return conversationNames;
};

export const getUniqueConversationNamesFromUtterances = (utterances: Message[]): string[] => {
  const conversationNames = getConversationNamesFromMessageNames(
    utterances.map((utterance) => utterance.name),
  );
  return uniq(conversationNames);
};
