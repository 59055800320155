import React from 'react';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { getId } from 'common/resourceName';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { Link, useNavigate } from 'react-router-dom';
import { Anchor } from '@mantine/core';

const TaskAction = ({ derivedTask }: {derivedTask:DerivedLabelingTask}) => {
  const customer = useCustomerParams();
  const navigate = useNavigate();

  if (!derivedTask) return <span />;
  if (derivedTask.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED) {
    const link = `/${customer.path}/analysisworkshop/${getId(
      'labelingTask',
      derivedTask.labelingTask.name,
    )}/analysis-summary`;
    return (
      <span>
        <Anchor
          size="sm"
          component={Link}
          to={link}
        >
          See results
        </Anchor>
      </span>
    );
  }
  const taskUrl = `/${customer.path}/analysisworkshop/${getId(
    'labelingTask',
    derivedTask.labelingTask.name,
  )}`;

  if ([TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(derivedTask.labelingTask.abstractTask.status)) {
    if (derivedTask.annotatedTargetCount > 0) {
      return (
        <span>
          <Anchor
            size="sm"
            component={Link}
            to={taskUrl}
          >
            Continue
          </Anchor>
        </span>
      );
    } else {
      return (
        <span>
          <Anchor
            size="sm"
            onClick={(event) => {
              event.preventDefault();
              // Reassign on start
              navigate(taskUrl);
            }}
          >
            Start
          </Anchor>
        </span>
      );
    }
  }

  return <span />;
};

export default TaskAction;
