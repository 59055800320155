import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Concept, ConceptConceptType, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Set } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useHotkeys } from '@mantine/hooks';

import { Message, MessageLabelingViewData } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Annotation, AnnotationValueType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { getId } from 'common/resourceName';
import { selectConceptsFactory, selectApiStatus as selectTaskApiStatus, selectAllConceptsMapFactory } from 'store/concept/selectors';
import { selectSets } from 'store/set/selectors';
import { useLabelingSession } from 'hooks/useLabelingSession';
import { useLabelingItems } from 'hooks/useLabelingItems';
import { useLabelingData } from 'hooks/useLabelingData';
import { TaskProgressConversationSetProgress } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/task_progress.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { showNotification } from '@mantine/notifications';
import { fetchLabelingTaskData, fetchLabelingTaskItems } from 'store/labelingTask/asyncThunks';
import { clearLabelingTask } from 'store/labelingTask/labelingTaskSlice';
import { selectApiStatus as selectConceptApiStatus } from 'store/labelingTask/selectors';
import { EdgeEdgeConditionType, ExpandNextFlowNodesDistributionRequest, FlowNodesCollection } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { FlowNodeApi } from 'services/flowNodeApi';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { PredictedTag, TaggingPage as TaggingPageComponent } from './TaggingPage';
import { handleDeleteAnnotation, handleNewStageAnnotations } from './utils/handleAnnotations';
import { handleCompleteTask } from './utils/handleCompleteTask';
import { createSetsMap } from './utils/handleSets';
import { getActiveSet } from './utils/getActiveSet';
import { TaggingPageProvider } from './TaggingPageProvider';
import { createDoneConfirmation } from './utils/createDoneConfirmation';
import { filterAnnotationsForMessageByTagId, filterPredictedFlowNodes, toFlowNodesCollection } from './utils';
import { StagesProvider } from './StagesProvider';

export interface Stage {
    annotationId: string;
    stageId: string;
    stageName: string;
    conversationIndexStart: number;
    conversationIndexEnd: number;
}

export interface UITagType {
    value: string;
    label: string;
    tagType: TagTagType;
    endStageId?: string;
}

interface SetProgressData {
  index: number
  id: string
  title: string
  conversationIndex: number
  conversationIndexInTask: number
  conversationCount: number
}

export interface TagSelectItem {
  label: string
  value: string
  onSelect: () => void
}

const determineInitialActiveSet = (setProgresses: TaskProgressConversationSetProgress[]) => setProgresses.find((setProgress) => setProgress.labeledSize < setProgress.conversationSampledSize);

const determineInitialActiveIndex = (setProgresses: TaskProgressConversationSetProgress[]) => {
  const activeSet = determineInitialActiveSet(setProgresses);
  const activeIndex = activeSet ? activeSet.beginPosition + activeSet.labeledSize : 0;
  return activeIndex;
};

export const TaggingPage = () => {
  const params = useParams<{customer: string, taskId: string}>();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const conversationPositionStr = new URLSearchParams(search).get('conversationPositionNumber');
  const conversationPositionNumber = conversationPositionStr
    ? parseInt(conversationPositionStr)
    : 0;
  const { taskId } = params;
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const taskName = `${customerProfile}/labelingTasks/${taskId}`;
  useLabelingSession(taskName);
  const [setData, setSetData] = useState<SetProgressData[]>([]);
  const dispatch = useDispatch();
  const taskApiStatus = useSelector(selectTaskApiStatus);
  const conceptApiStatus = useSelector(selectConceptApiStatus);

  const [messages, setMessages] = useState<Message[]>([]);
  const [predictedTags, setPredictedTags] = useState<PredictedTag[]>([]);
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);
  const [tempStage, setTempStage] = useState<Stage | null>(null);
  const activeMessageId = messages[activeMessageIndex]?.v2MessageId;

  const [conversations, setConversations] = useState<MessageLabelingViewData[]>([]);
  const [activeConversationIndex, setActiveConversationIndex] = useState<number>(conversationPositionNumber);
  const activeConversationId = conversations[0]?.messagesAndContexts[0]?.v2ConversationId;

  const [labelingData, isLoadingLabelingData] = useLabelingData(taskName, [activeConversationId]);
  const totalConversationsInTask = labelingData?.taskProgress?.conversationSetProgresses?.reduce((acc, setProgress) => acc + setProgress.conversationSampledSize, 0);

  const [labelingItemData, isLoadingLabelingItemData] = useLabelingItems(taskName, activeConversationIndex);

  const activeSet = useMemo(() => getActiveSet(activeConversationIndex, setData), [activeConversationIndex, setData]);

  // TODO: we still need to create filters for each annotation type so we can properly apply the "selected" value to each the tag lists
  const [annotations, setAnnotations] = useState<Annotation[]>([]);

  // Used when updating task annotations in Taglist
  const [taskConceptIds, setTaskConceptIds] = useState<string[]>([]);

  const tagConcepts = useSelector(selectConceptsFactory(ConceptConceptType.TAG));
  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);

  // Fetch labeling task data on initial page load
  useEffect(() => {
    dispatch(fetchLabelingTaskData(taskName));
    return () => {
      dispatch(clearLabelingTask());
    };
  }, [taskName]);

  // Fetch labeling task items when chat index changes
  useEffect(() => {
    dispatch(fetchLabelingTaskItems({
      taskName,
      conversationNumberStart: activeConversationIndex,
      conversationNumberEnd: activeConversationIndex + 1,
    }));
  }, [activeConversationIndex]);

  // Fetch predicted tags when temp/confirmed annotations change
  useEffect(() => {
    predictNextAnnotations(activeMessageIndex);
  }, [tempStage, annotations?.length]);

  // sets all tags in profile to be selected
  const tagsInProfile = useMemo(() => {
    if (tagConcepts.length === 0) return [];
    const mappedTags: UITagType[] = tagConcepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      return {
        value: conceptId,
        label: concept.conceptTitle,
        tagType: concept.tag.tagType,
      };
    });
    return mappedTags;
  }, [tagConcepts]);

  useHotkeys([
    // Handle Message Navigation
    ['ArrowUp', () => {
      if (activeMessageIndex > 0) {
        setActiveMessageIndex(activeMessageIndex - 1);
      }
    }],
    ['ArrowDown', () => {
      if (activeMessageIndex < messages.length - 1) {
        setActiveMessageIndex(activeMessageIndex + 1);
      }
    }],
    // Handle Conversation Navigation
    ['shift+ArrowLeft', () => {
      if (activeConversationIndex > 0) {
        setActiveConversationIndex(activeConversationIndex - 1);
      }
    }],
    ['shift+ArrowRight', () => {
      if (activeConversationIndex < totalConversationsInTask - 1) {
        setActiveConversationIndex(activeConversationIndex + 1);
      }
    }],
  ]);

  // sets all annotations for current Conversation
  const conversationAnnotations = useMemo(() => {
    const filteredAnnotations = annotations.filter((annotation) => {
      const isCurrentSet = annotation?.value?.setId === activeSet.id;
      const isCurrentConversation = annotation?.rawData?.messageRawData?.conversationId === activeConversationId;
      return isCurrentSet && isCurrentConversation;
    });
    const reducedAnnotations: {
      messageId: string;
      conceptIds: string[]
    }[] = filteredAnnotations.reduce((acc, annotation) => {
      const conceptType = annotation.value?.type;
      if (conceptType === AnnotationValueType.TYPE_CONVERSATION_STAGE) {
        // handle Stage Annotations
        const conceptId = annotation.value.conversationStageValue?.stageConceptId;
        const beginMessageId = annotation.value.conversationStageValue?.stageBeginMessageId;
        const existingAnnotation = acc.find((a) => a.messageId === beginMessageId);
        if (existingAnnotation) {
          existingAnnotation.conceptIds.push(conceptId);
        } else {
          acc.push({ messageId: beginMessageId, conceptIds: [conceptId] });
        }
      } else {
        const messageId = annotation.rawData?.messageRawData?.messageId;
        const conceptId = annotation.value?.binaryValue?.conceptId;
        const existingAnnotation = acc.find((a) => a.messageId === messageId);
        if (existingAnnotation) {
          existingAnnotation.conceptIds.push(conceptId);
        } else {
          acc.push({ messageId, conceptIds: [conceptId] });
        }
      }
      return acc;
    }, []);
    return reducedAnnotations;
  }, [annotations]);

  // sets conceptIdsOnCurrentMessage on activemessageIndex or activeConversatonIndex change
  const conceptIdsOnCurrentMessage = useMemo(() => {
    const getConceptIdsForMessageId = (messageId: string) => conversationAnnotations.find((message) => message.messageId === messageId)?.conceptIds || [];
    const currentMessage = messages[activeMessageIndex];
    if (!currentMessage) return [];
    const initialConceptIdsOnCurrentMessage = getConceptIdsForMessageId(activeMessageId);
    return initialConceptIdsOnCurrentMessage;
  }, [activeConversationIndex, activeMessageId, conversationAnnotations]);

  const allSets = useSelector<Set[]>(selectSets);
  const setMap = useMemo(() => createSetsMap(allSets), [allSets.length]);

  const [isTaskComplete, toggleTaskComplete] = useState(true);

  // resets activeMessageIndex when activeConversationIndex changes
  useEffect(() => {
    setActiveMessageIndex(0);
    setPredictedTags([]);
  }, [activeConversationIndex]);

  // fetch initial labeling data for the current task
  useEffect(() => {
    let attempts = 0;
    let activeIndex = activeConversationIndex;
    const deriveTaskData = () => {
      if (Object.keys(setMap).length === 0) return;
      if (isLoadingLabelingData) return;
      if (!labelingData) return;
      toggleTaskComplete(labelingData.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED);
      const { taskProgress } = labelingData;
      // !activeConversationIndex will not work with 0
      if (attempts > 1) {
        console.error('Too many attempts to find active conversation index');
        return;
      }
      if (activeIndex === undefined) {
      // First time loading, we need to determine where the user left off
        const initialActiveIndex = determineInitialActiveIndex(taskProgress.conversationSetProgresses);
        activeIndex = initialActiveIndex;
        attempts += 1;
        setActiveConversationIndex(initialActiveIndex);
        // run this again with new activeIndex
        deriveTaskData();
      } else {
        // We need to fetch new conversation data for the new activeConversationIndex
        const setProgress = taskProgress.conversationSetProgresses.map((progress, index: number) => ({
          index,
          id: progress.conversationSetId,
          title: setMap[progress.conversationSetId]?.title || 'Error retrieving set title',
          conversationIndex: progress.labeledSize,
          conversationIndexInTask: progress.beginPosition,
          conversationCount: progress.conversationSampledSize,
        }));
        setTaskConceptIds(labelingData.labelingTask.taskData.taskDescriptor.conceptIds);
        setSetData(setProgress);
      }
    };
    deriveTaskData();
  }, [isLoadingLabelingData, setMap]);

  // handle TagList updates
  useEffect(() => {
    if (isLoadingLabelingData) return;
    if (!labelingData) return;
    setTaskConceptIds(labelingData.labelingTask.taskData.taskDescriptor.conceptIds);
  }, [isLoadingLabelingData]);

  // fetch labeling items for the current Conversation
  useEffect(() => {
    if (isLoadingLabelingItemData) return;
    if (!labelingItemData) return;
    const messageQuantity = labelingItemData.conversationLabelingItems.length;
    if (messageQuantity === 0) return;
    setConversations(labelingItemData.conversationLabelingItems);
    setMessages(labelingItemData.conversationLabelingItems[0]?.messagesAndContexts);
    setAnnotations(labelingItemData.temporalAnnotations);
  }, [isLoadingLabelingItemData]);

  // Get message begin and end stages
  const getMessageStageAnnotations = (message: Message): [FlowNodesCollection[], FlowNodesCollection[]] => {
    const stageBeginNodes: FlowNodesCollection[] = [];
    const stageEndNodes: FlowNodesCollection[] = [];
    // TODO: Optimize this using a memoized map
    annotations.forEach((annotation) => {
      if (annotation.value.conversationStageValue) {
        const { stageBeginMessageId, stageEndMessageId, stageConceptId } = annotation.value.conversationStageValue;
        const isPreviousMessageStageBegin = stageBeginMessageId === message.v2MessageId;
        const isPreviousMessageStageEnd = stageEndMessageId === message.v2MessageId;
        const stageConcept = allConceptsMap.get(stageConceptId);

        if (isPreviousMessageStageBegin) {
          stageBeginNodes.push(toFlowNodesCollection(stageConcept, TagTagType.STAGE_BEGIN));
        } else if (isPreviousMessageStageEnd) {
          stageEndNodes.push(toFlowNodesCollection(stageConcept, TagTagType.STAGE_END));
        }
      }
    });

    return [stageBeginNodes, stageEndNodes];
  };

  // Get message binary value annotations (tags)
  const getMessageTagAnnotations = (message: Message): FlowNodesCollection[] => {
    const tagNodes: FlowNodesCollection[] = [];
    // TODO: Optimize this using a memoized map
    annotations.forEach((annotation) => {
      if (annotation.value.binaryValue) {
        const { conceptId } = annotation.value.binaryValue;
        const isMessageTag = annotation.rawData.messageRawData.v2MessageId === message.v2MessageId;
        if (isMessageTag) {
          const tagConcept = allConceptsMap.get(conceptId);
          const tag = tagsMap.get(conceptId);
          tagNodes.push(toFlowNodesCollection(tagConcept, tag.tagType));
        }
      }
    });

    return tagNodes;
  };

  // Get tags from message
  const getMessageFlowNodeCollections = (messageIndex: number) => {
    const message = messages[messageIndex];
    const [messageBeginStages, messageEndStages] = getMessageStageAnnotations(message);

    // Get served confirmed annotations
    const messageTags = getMessageTagAnnotations(message);

    // Also include temp stage (not server confirmed) annotation if it exists on message, and add it as flow node
    if (tempStage) {
      if (tempStage.conversationIndexStart === messageIndex) {
        const tagConcept = allConceptsMap.get(tempStage.stageId);
        messageTags.push(toFlowNodesCollection(tagConcept, TagTagType.STAGE_BEGIN));
      }
    }

    return [...messageBeginStages, ...messageTags, ...messageEndStages];
  };

  // Get tags from the previous message, if it has no tags, keep searching
  // past messages until we find a message with tags
  const getPrevMessageFlowNodeCollections = (messageIndex: number) => {
    let prevMessageIndex = messageIndex - 1;
    let previousMessage = messages[prevMessageIndex];
    let prevMessageFlowNodeCollections: FlowNodesCollection[] = [];

    while (prevMessageFlowNodeCollections?.length === 0 && previousMessage) {
      const [prevMessageBeginStages, prevMessageEndStages] = getMessageStageAnnotations(previousMessage);

      // Get served confirmed annotations
      const prevMessageTags = getMessageTagAnnotations(previousMessage);

      // Also include temp stage (not server confirmed) annotation if it exists on message, and add it as flow node
      if (tempStage) {
        if (tempStage.conversationIndexStart === prevMessageIndex) {
          const tagConcept = allConceptsMap.get(tempStage.stageId);
          prevMessageTags.push(toFlowNodesCollection(tagConcept, TagTagType.STAGE_BEGIN));
        }
      }

      prevMessageFlowNodeCollections = [...prevMessageFlowNodeCollections, ...prevMessageBeginStages, ...prevMessageTags, ...prevMessageEndStages];
      prevMessageIndex -= 1;
      previousMessage = messages[prevMessageIndex];
    }

    // If we didn't find any annotations, use the hardcoded begin node
    if (prevMessageFlowNodeCollections.length === 0) {
      prevMessageFlowNodeCollections = [
        {
          conceptName: `${customerProfile}/concepts/begin_concept_id`,
          nextEdge: {
            conditionType: EdgeEdgeConditionType.NEXT_IMMEDIATE,
          },
        },
      ];
    }

    return prevMessageFlowNodeCollections;
  };

  // Fetch predicted annotations for a message
  const predictNextAnnotations = async (messageIndex: number) => {
    const message = messages[messageIndex];
    if (!message) return;

    const prevMessageFlowNodesCollections = getPrevMessageFlowNodeCollections(messageIndex);
    const messageFlowNodesCollections = getMessageFlowNodeCollections(messageIndex);
    const flowNodesCollectionsBefore = [...prevMessageFlowNodesCollections, ...messageFlowNodesCollections];
    const request: ExpandNextFlowNodesDistributionRequest = {
      profile: customerProfile,
      flowNodesCollectionsBefore,
      filter: {
        taskIds: [taskId],
      },
    };

    try {
      const response = await FlowNodeApi.expandNextFlowNodesDistribution(request);
      const predictedFlowNodes = response.flowNodesCollections || [];
      const filteredPredictedFlowNodes = filterPredictedFlowNodes(predictedFlowNodes, message, tempStage);
      const predictedTags: PredictedTag[] = filteredPredictedFlowNodes.map((flowNode) => ({
        value: getId('concept', flowNode.conceptName),
        label: flowNode.conceptTitle,
        tagType: flowNode.tagType,
        messageId: message.v2MessageId,
      }));
      setPredictedTags(predictedTags);
    } catch (err) {
      console.error(err);
    }
  };

  const handleMessageClick = useCallback((index: number) => {
    if (index === activeMessageIndex) return;
    setActiveMessageIndex(index);
    predictNextAnnotations(index);
  }, [activeMessageIndex, predictNextAnnotations]);

  const getActiveMessageInfo = useCallback((messageIndex?: number) => {
    // can be 0, so check for undefined
    const activeMessage = messageIndex !== undefined ? messages[messageIndex] : messages[activeMessageIndex];
    const messageId = activeMessage?.v2MessageId;
    const activeMessageInfo = {
      setId: activeSet.id,
      conversationId: activeConversationId,
      messageId,
    };
    return activeMessageInfo;
  }, [activeConversationId, activeMessageIndex, activeSet.id, messages]);

  const removeAnnotationIds = useCallback(async (annotationIdsToRemove: string[]) => {
    try {
    // delete each annotation for current concept from db
      const deleteAnnotationData = {
        taskName,
        annotationIds: annotationIdsToRemove,
      };
      await handleDeleteAnnotation(deleteAnnotationData);

      const newAnnotations = annotations.filter((annotation) => !annotationIdsToRemove.includes(getId('annotation', annotation.name)));

      setAnnotations(newAnnotations);
      showNotification({
        color: 'green',
        message: 'Tag removed from message',
      });
    } catch (err) {
      console.error(err);
      showNotification({
        color: 'red',
        message: 'Error removing tag from message',
      });
    }
  }, [annotations, taskName]);

  const removeTagFromMessage = async (tagId: string, messageIndex?: number) => {
    const activeMessageInfo = getActiveMessageInfo(messageIndex);
    const annotationsToRemove = annotations.filter(filterAnnotationsForMessageByTagId(tagId, activeMessageInfo));
    // handle stages
    const tag = tagsInProfile.find((t) => t.value === tagId);
    if (tag.tagType === TagTagType.STAGE_BEGIN) {
      // split string by '.' and remove last element
      const stageEndTagLabel = `${tag.label.split('.').slice(0, -1).join('.')}.end`;
      const stageEndTag = tagsInProfile.find((t) => t.label === stageEndTagLabel);
      if (!stageEndTag) {
        console.error('No stage end tag found for tag', tag);
      } else {
        const stageEndAnnotationsToRemove = annotations.filter(filterAnnotationsForMessageByTagId(stageEndTag.value, activeMessageInfo));
        annotationsToRemove.push(...stageEndAnnotationsToRemove);
      }
    }
    const annotationIdsToRemove = annotationsToRemove.map((annotation) => getId('annotation', annotation.name));
    return removeAnnotationIds(annotationIdsToRemove);
  };

  const onDoneBtnClick = () => {
    if (isTaskComplete) {
      navigate(`/${customer.path}/analysisworkshop/${taskId}/analysis-summary`);
    } else {
      createDoneConfirmation().then((isConfirmed) => {
        if (!isConfirmed) return;
        handleCompleteTask(taskName).then((res) => {
          const taskId = getId('labelingTask', res.derivedLabelingTask.labelingTask.name);
          navigate(`/${customer.path}/analysisworkshop/${taskId}/analysis-summary`);
        }).catch((err) => showNotification({
          color: 'red',
          message: `Error completing task: ${err.message}`,
        }));
      });
    }
  };

  const handleSwitchSet = (setId:string) => {
    const newActiveSet = setData.find((set) => set.id === setId);
    const newActiveConversationIndex = newActiveSet.conversationIndexInTask + newActiveSet.conversationIndex;
    setActiveConversationIndex(newActiveConversationIndex);
  };
  const isFirstConversationInSet = setData.map((set) => set.conversationIndexInTask).includes(activeConversationIndex);

  const handleBackBtnClick = () => {
    if (activeConversationIndex === 0) return;
    if (isFirstConversationInSet) {
      handleSwitchSet(setData[activeSet.index - 1].id);
    } else {
      setActiveConversationIndex((prev) => prev - 1);
    }
  };
  const isLastConversationInTask = setData.reduce((acc, set) => acc + set.conversationCount, 0) - 1 === activeConversationIndex;
  const isLastConversationInSet = setData.map((set) => set.conversationIndexInTask + set.conversationCount - 1).includes(activeConversationIndex);

  const handleNextBtnClick = () => {
    if (isLastConversationInTask) return;
    if (isLastConversationInSet) {
      handleSwitchSet(setData[activeSet.index + 1].id);
    } else {
      setActiveConversationIndex((prev) => prev + 1);
    }
  };

  const onUpdateTagIdsInTask = (updateTagIds: string[]) => {
    setTaskConceptIds(updateTagIds);
  };

  const onFinishLaterBtnClick = () => {
    if (isTaskComplete) {
      navigate(`/${customer.path}/analysisworkshop`);
    } else {
      navigate(`/${customer.path}/analysisworkshop/${taskId}/analysis-summary`);
    }
  };

  const tagsMap = new Map<string, UITagType>(tagsInProfile.map((tag) => [tag.value, tag]));
  const getActorTypeOnMessage = () => {
    const message = messages[activeMessageIndex];
    if (!message) return null;
    const { actorType } = message;
    return actorType;
  };
  const actorTypeOnMessage = getActorTypeOnMessage();

  const handleCreateAnnotationResponse = (newAnnotations: Annotation[]) => {
    if (newAnnotations.length === 0) throw new Error('Tag not added to message, please refresh and try again');
    setAnnotations((prev) => [...prev, ...newAnnotations]);
  };

  const handleStageAnnotationCreation = (stageTagAnnotationData:{
    tag:{
      id: string;
      title:string;
      type: TagTagType;
    },
    stageBeginMessageId: string,
    stageEndMessageId: string,
  }) => handleNewStageAnnotations({
    taskName,
    setId: activeSet.id,
    conversationId: activeConversationId,
    stageBeginMessageId: stageTagAnnotationData.stageBeginMessageId,
    stageEndMessageId: stageTagAnnotationData.stageEndMessageId,
    tags: [stageTagAnnotationData.tag],
    conversationPositionNumber: activeConversationIndex,
  }).then((res) => {
    const newlyAddedStageAnnotation = res.upsertedAnnotations[0];
    setAnnotations((prevAnnotations) => [...prevAnnotations, newlyAddedStageAnnotation]);
  });

  return (
    <StagesProvider
      messages={messages}
      annotations={annotations}
      handleStageAnnotationCreation={handleStageAnnotationCreation}
      removeAnnotationIds={removeAnnotationIds}
      tempStage={tempStage}
      setTempStage={setTempStage}
    >
      <TaggingPageProvider
        value={{
          tagsInProfile,
          taskConceptIds,
          conceptIdsOnCurrentMessage,
          annotations,
          removeTagFromMessage,
          removeAnnotationIds,
          // Required by notes, tagging
          taskName,
          // Required by notes component
          currentSetId: activeSet.id,
          activeConversationIndex,
          activeConversationId,
          // Required by tagging component
          activeSetId: activeSet.id,
          handleCreateAnnotationResponse,
          onUpdateTagIdsInTask,
          activeMessage: {
            id: activeMessageId,
            index: activeMessageIndex,
            actorType: actorTypeOnMessage,
          },
        }}
      >
        <TaggingPageComponent
          conversationAnnotations={conversationAnnotations}
          predictedTags={predictedTags}
          tagsMap={tagsMap}
          isComplete={isTaskComplete}
          isLoading={taskApiStatus === 'loading' || conceptApiStatus === 'loading'}
          conversationName={activeConversationId ? `${customerProfile}/conversations/${activeConversationId}` : null}
          messages={messages}
          handleMessageClick={handleMessageClick}
          handleBackBtnClick={handleBackBtnClick}
          handleNextBtnClick={handleNextBtnClick}
          onRemoveTagFromMessage={removeTagFromMessage}
          onFinishLaterBtnClick={onFinishLaterBtnClick}
          onDoneBtnClick={onDoneBtnClick}
          setData={setData}
          handleSwitchSet={handleSwitchSet}
          isBackBtnDisabled={activeConversationIndex === 0 || isFirstConversationInSet}
          isNextBtnDisabled={isLastConversationInTask}
          isLastConversationInSet={isLastConversationInSet}
          activeConversationIndex={activeConversationIndex}
        />
      </TaggingPageProvider>
    </StagesProvider>
  );
};
