import { createSelector } from '@reduxjs/toolkit';
import { GenerateModelProjectTableRow } from 'utils/generateModelProjectTableRow';
import { getId } from 'common/resourceName';
import type { State } from '../store';
import type { State as ModelProjectState } from './state';

const selectModelProject = (state: State) => state.modelProject;

/** Select model projects. */
export const selectModelProjects = createSelector(selectModelProject, (modelProjectState: ModelProjectState) => modelProjectState.modelProjects);

/** Find the model project by ID. */
export const selectModelProjectById = (projectId: string) => createSelector(
  selectModelProject,
  (modelProjectState: ModelProjectState) => modelProjectState.modelProjects.find((p) => getId('modelProject', p.name) === projectId),
);

/** Select model projects table data */
export const selectModelProjectsTableData = createSelector(selectModelProject, (modelProjectState: ModelProjectState) => GenerateModelProjectTableRow(modelProjectState.modelProjects));

/** Select model projects table data */
export const selectModelProjectStatus = createSelector(selectModelProject, (modelProjectState: ModelProjectState) => modelProjectState.status);
