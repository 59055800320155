import { useLabelingItems } from 'hooks/useLabelingItems';
import React, { useMemo } from 'react';
import { Text, Space } from '@mantine/core';

export default function ModelSummaryCell({ taskName, activeConversationIndex }) {
  const [labelingItems] = useLabelingItems(
    taskName,
    activeConversationIndex,
  );

  const messageWithContext = useMemo(() => (labelingItems?.conversationLabelingItems[0]?.messagesAndContexts || []).find((item) => item.selectionContext?.summarizationContext), [labelingItems]);
  const suggestionByTag = messageWithContext?.selectionContext?.summarizationContext?.suggestionByTag;
  // TODO: Proper multiple models support
  const summaries = Object.keys(suggestionByTag || {}).map((key) => ({
    title: key,
    text: suggestionByTag[key],
  }));
  return (
    <div>
      {summaries.map((summary, index) => (
        <div key={summary.title}>
          {index !== 0 && <Space h="sm" />}
          <Text size="md" color="gray">{summary.title}</Text>
          <Text size="sm">{summary.text}</Text>
        </div>
      ))}
    </div>
  );
}
