import React, { useState } from 'react';
import { Button, Group, Select } from '@mantine/core';
import AutoAnalysisModal from './AutoAnalysisModal';

export const CallDriverHeader = ({ clusterVersionName, setClusterVersionName, clusterVersions }) => {
  const [autoanalysisVisible, setAutoanalysisVisible] = useState<boolean>(false);

  return (
    <>
      <Group position="left" align="flex-end" spacing="lg">
        <div>
          <Select
            label="Versions"
            placeholder="Select version"
            value={clusterVersionName}
            w="600px"
            searchable
            onChange={(value) => setClusterVersionName(value)}
            data={clusterVersions.map((version) => ({
              value: version,
              label: version,
            }))}
          />
        </div>
        <Button style={{ marginLeft: 'auto' }} onClick={() => setAutoanalysisVisible(true)}>New analysis</Button>
      </Group>
      <AutoAnalysisModal
        visible={autoanalysisVisible}
        onClose={() => setAutoanalysisVisible(false)}
      />
    </>
  );
};
