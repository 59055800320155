import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Annotation, MessageRawData, MessageRawDataContextShown, RubricValue } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Button, createStyles, Text, SimpleGrid, Space, Group, Stack, useMantineTheme } from '@mantine/core';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { GotoNextType } from 'pages/LabelingView';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/ai_service/common';
import './SummarizationQaDecision.scss';
import { Message, SummarizationInstructionRubricTagSelection } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Rubric } from '@cresta/web-client/dist/cresta/v1/studio/rubric/rubric_service.pb';
import { cloneDeep } from 'lodash';
import RubricGroup from '../RubricGroup';

// Local computated state of models and their rubrics, scores, tags...
interface ModelState {
  name: string;
  summaries: {
    title: string;
    text: string;
  }[],
  rubrics: Rubric[];
  rubricsScores: number[];
  rubricsExplanations: string[];
  rubricsConcepts: string[][];
  rubricsTags: string[][];
}

interface ModelSummaryRubricsProps {
  model: ModelState;
  concepts: Concept[];
  rubricTagSelections: SummarizationInstructionRubricTagSelection[];
  selectScore: (rubricsIndex: number, score: number) => void;
  selectSummary: (rubricsIndex: number, conceptId: string) => void;
  selectTag: (rubricsIndex: number, tag: string) => void;
  updateExplanation: (rubricsIndex: number, text: string) => void;
  selectRubric: (rubricName: string) => void;
}

// Column display of model summary with its rubrics
function ModelSummaryRubrics({
  model,
  selectScore,
  selectSummary,
  updateExplanation,
  selectTag,
  concepts,
  selectRubric,
  rubricTagSelections,
}: ModelSummaryRubricsProps) {
  const rubricTagsMap = useMemo(() => new Map<string, string[]>(rubricTagSelections.map((selection) => [selection.rubricName, selection.tagsUsed])), [rubricTagSelections]);

  const useStyles = createStyles((theme) => ({
    modelSummary: {
      marginBottom: theme.spacing.md,
      borderBottom: `1px solid ${theme.colors.gray[0]}`,
      paddingBottom: theme.spacing.md,
    },
  }));
  const styles = useStyles();
  const { summaries } = model;

  return (
    <>
      <div className={styles.classes.modelSummary}>
        {summaries.map((summary, index) => (
          <div key={summary.title}>
            {index !== 0 && <Space h="sm" />}
            <Text color="blue">{summary.title}</Text>
            <Text mt={5} size="sm">{summary.text}</Text>
          </div>
        ))}
      </div>
      <Stack>
        {model.rubrics.map((rubric, i) => (
          <RubricGroup
            key={rubric.name}
            opened={model.rubricsScores[i] && model.rubricsScores[i] < 5}
            title={rubric.rubricTitle}
            concepts={concepts}
            selectScore={(value) => selectScore(i, value)}
            selectSummary={(value) => selectSummary(i, value)}
            selectTag={(value) => selectTag(i, value)}
            updateExplanation={(value) => updateExplanation(i, value)}
            activeScore={model.rubricsScores[i]}
            activeConcepts={model.rubricsConcepts[i]}
            explanation={model.rubricsExplanations[i]}
            maxScore={model.rubrics[i].numericRubric.maxScore}
            activeTags={model.rubricsTags[i]}
            rubricTags={rubricTagsMap?.get(rubric.name)}
            infoButtonHandler={() => selectRubric(rubric?.name.split('/')?.[1])}
          />
        ))}
      </Stack>
    </>
  );
}

interface SummarizationQaDecisionProps {
  loading?: boolean,
  concepts: Concept[],
  message: Message,
  annotations: Annotation[],
  contextShown: MessageRawDataContextShown,
  getConceptTitle: (conceptId: string) => string;
  createAnnotation: (
    messageRawData: MessageRawData,
    rubricValue: RubricValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  submitAnnotations: (annotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => void;
  modelUris: string[];
  rubrics: Rubric[];
  nextChat: () => void;
  setOverviewConceptId: (id: string) => void;
  rubricTagSelections: SummarizationInstructionRubricTagSelection[];
}

export default function SummarizationQaDecision({
  loading,
  concepts = [],
  message,
  annotations = [],
  contextShown,
  getConceptTitle,
  modelUris,
  createAnnotation,
  submitAnnotations,
  rubrics,
  nextChat,
  setOverviewConceptId,
  rubricTagSelections,
}: SummarizationQaDecisionProps) {
  const theme = useMantineTheme();

  const conversationLabeled = annotations?.length > 0;
  const summarizationContext = useMemo(() => message?.selectionContext?.summarizationContext, [message]);
  const [modelsState, setModelsState] = useState<ModelState[]>([]);

  // Compute local state of rubrics based on annotations
  useEffect(() => {
    const models: ModelState[] = [];
    // TODO: Proper multiple models support
    modelUris.forEach((modelUri, i) => {
      const summaries = Object.keys(summarizationContext?.modelPredictions?.[i]?.predictionByTag || {}).map((key) => ({
        title: key,
        text: summarizationContext?.modelPredictions?.[i]?.predictionByTag[key],
      }));
      if (modelUri) {
        const newModel = {
          name: modelUri,
          summaries,
          rubrics,
          rubricsScores: models[i]?.rubricsScores || new Array(rubrics.length).fill(0),
          rubricsConcepts: models[i]?.rubricsConcepts || new Array(rubrics.length).fill(null).map(() => new Array(0)),
          rubricsTags: models[i]?.rubricsTags || new Array(rubrics.length).fill(null).map(() => new Array(0)),
          rubricsExplanations: models[i]?.rubricsExplanations || new Array(rubrics.length).fill(''),
        };

        // Update rubrics from annotations
        annotations.forEach((annotation) => {
          const annotationRubricIndex = newModel.rubrics.findIndex((rubric) => rubric.name.split('/')?.[1] === annotation.value.rubricValue.rubricId);
          const annotationModelUri = annotation.value.rubricValue.modelUri;
          if (annotationRubricIndex !== -1 && annotationModelUri === newModel.name) {
            newModel.rubricsScores[annotationRubricIndex] = annotation.value.rubricValue.score;
            newModel.rubricsConcepts[annotationRubricIndex] = annotation.value.rubricValue.conceptIds;
            newModel.rubricsExplanations[annotationRubricIndex] = annotation.value.rubricValue.explanation;
            newModel.rubricsTags[annotationRubricIndex] = annotation.value.rubricValue.rubricTags;
          }
        });

        models.push(newModel);
      }
    });
    setModelsState(models);
  }, [message, modelUris, rubrics, annotations]);

  // Add or remove selected rubrics concept
  const selectSummary = useCallback((modelIndex: number, rubricsIndex: number, conceptId: string) => {
    const modelsCopy = cloneDeep(modelsState);
    if (modelsCopy[modelIndex].rubricsConcepts[rubricsIndex].includes(conceptId)) {
      const toBeRemovedIndex = modelsCopy[modelIndex].rubricsConcepts[rubricsIndex].findIndex((summary) => summary === conceptId);
      modelsCopy[modelIndex].rubricsConcepts[rubricsIndex].splice(toBeRemovedIndex, 1);
    } else {
      modelsCopy[modelIndex].rubricsConcepts[rubricsIndex].push(conceptId);
    }
    setModelsState(modelsCopy);
  }, [modelsState]);

  // Add or remove selected tags
  const selectTag = useCallback((modelIndex: number, rubricsIndex: number, tagName: string) => {
    const modelsCopy = cloneDeep(modelsState);
    if (modelsCopy[modelIndex].rubricsTags[rubricsIndex].includes(tagName)) {
      const toBeRemovedIndex = modelsCopy[modelIndex].rubricsTags[rubricsIndex].findIndex((tag) => tag === tagName);
      modelsCopy[modelIndex].rubricsTags[rubricsIndex].splice(toBeRemovedIndex, 1);
    } else {
      modelsCopy[modelIndex].rubricsTags[rubricsIndex].push(tagName);
    }
    setModelsState(modelsCopy);
  }, [modelsState]);

  // Select rubrics score
  const selectScore = useCallback((modelIndex: number, rubricsIndex: number, score: number) => {
    const modelsCopy = cloneDeep(modelsState);
    modelsCopy[modelIndex].rubricsScores[rubricsIndex] = score;
    setModelsState(modelsCopy);
  }, [modelsState]);

  // Select rubrics score
  const updateExplanation = useCallback((modelIndex: number, rubricsIndex: number, explanation: string) => {
    const modelsCopy = cloneDeep(modelsState);
    modelsCopy[modelIndex].rubricsExplanations[rubricsIndex] = explanation;
    setModelsState(modelsCopy);
  }, [modelsState]);

  // Check if all rubrics have scores
  const canConfirm = useMemo(() => modelsState.every((model) => model.rubricsScores.every((score) => score > 0)), [modelsState]);

  const confirmDecision = useCallback(() => {
    const newAnnotations = [];
    modelsState.forEach((model) => {
      model.rubrics.forEach((rubric, i) => {
        if (model.rubricsScores[i] !== 0) {
          // Rubric has annotation
          const annotation = createAnnotation({
            conversationId: message.conversationId,
            v2ConversationId: message.v2ConversationId,
            contextShown,
          }, {
            // Filter out empty values
            rubricId: rubric.name?.split('/')[1],
            conceptIds: model.rubricsConcepts[i],
            rubricTags: model.rubricsTags[i],
            modelUri: model.name,
            explanation: model.rubricsExplanations[i],
            score: model.rubricsScores[i],
          });
          newAnnotations.push(annotation);
        }
      });
    });
    submitAnnotations(newAnnotations, 'message');
  }, [modelsState, message, createAnnotation]);

  return (
    <div
      className="summarization-qa-decision"
      style={{
        width: modelsState?.length === 1 ? 400 : 640,
      }}
    >
      <SimpleGrid
        cols={modelsState?.length || 1}
        spacing={0}
        sx={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        {modelsState.map((model, modelIndex) => (
          <div
            key={model.name}
            style={{
              padding: theme.spacing.md,
              flex: 1,
              overflow: 'auto',
            }}
          >
            <Text weight={500} mb="xs">Model {modelIndex + 1}</Text>
            <ModelSummaryRubrics
              model={model}
              concepts={concepts}
              selectScore={(rubricsIndex, score) => selectScore(modelIndex, rubricsIndex, score)}
              selectSummary={(rubricsIndex, conceptId) => selectSummary(modelIndex, rubricsIndex, conceptId)}
              selectTag={(rubricsIndex, tag) => selectTag(modelIndex, rubricsIndex, tag)}
              updateExplanation={(rubricsIndex, explanation) => updateExplanation(modelIndex, rubricsIndex, explanation)}
              selectRubric={setOverviewConceptId}
              rubricTagSelections={rubricTagSelections}
            />
          </div>
        ))}
      </SimpleGrid>
      <Group position="center" p="lg" style={{ height: 'auto', borderTop: `1px solid ${theme.colors.gray[0]}` }}>
        <Button
          variant="light"
          color="green"
          onClick={() => confirmDecision()}
          data-active={conversationLabeled}
          data-cy="confirm-button"
          disabled={!canConfirm}
        >Confirm (C)
        </Button>
        <Button
          variant="light"
          disabled={conversationLabeled}
          onClick={nextChat}
        >Skip (S)
        </Button>
      </Group>
    </div>
  );
}
