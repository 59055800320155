import './TreeNodeIcon.scss';
import { Tooltip } from 'antd';
import React from 'react';
import classNames from 'classnames';

const tooltipText = {
  'chat-driver': 'Chat Driver',
  stage: 'Stage',
  agent: 'Agent Intent',
  visitor: 'Visitor Intent',
};

export type TreeNodeIconType = 'stage' | 'agent' | 'visitor' | 'chat-driver';

export interface TreeNodeProps {
  type: TreeNodeIconType;
  deprecated?: boolean;
  fullName?: boolean;
}

export default function TreeNodeIcon({ type, deprecated = false, fullName = false }: TreeNodeProps) {
  return (
    <Tooltip title={tooltipText[type]}>
      <div className={classNames([
        'tree-node-icon',
        type,
        { deprecated },
        { fullname: fullName },
      ])}
      />
    </Tooltip>
  );
}
