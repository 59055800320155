/* eslint-disable no-await-in-loop */
/**
 * Refer to chat-ai/app/studio/services/intents/README.md.
 */
import { useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { useApiGet, useApiPost } from './network';

// Copied from cresta-proto/cresta/v1/studio/tasks/tasks.proto.
export enum TaskStatus {
  TASK_STATUS_UNSPECIFIED = 'TASK_STATUS_UNSPECIFIED',
  TASK_CREATED = 'TASK_CREATED',
  TASK_READY = 'TASK_READY',
  TASK_IN_PROGRESS = 'TASK_IN_PROGRESS',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_ERROR = 'TASK_ERROR',
  TASK_ABORTED = 'TASK_ABORTED',
  TASK_TIMEOUT = 'TASK_TIMEOUT',
}

export const runningStatuses = new Set<TaskStatus>([
  TaskStatus.TASK_CREATED,
  TaskStatus.TASK_READY,
  TaskStatus.TASK_IN_PROGRESS]);

// TODO type these!
interface IResourceCreationArgs {
  resourceRoute: string,
  createResourceBodyArgs: object,
  failedCallback?: (msg: string) => void,
  // On 202
  acceptedCallback?: Function,
  // On 200
  completedCallback?: Function,
  pollIntervalSeconds?: number,
}

interface IResourceStatusArgs {
  resourceRoute: string,
  resourceId: number,
  failedCallback?: Function,
  // On 202
  acceptedCallback?: Function,
  // 201
  createdCallback?: Function,
  pollIntervalSeconds?: number,
}

type createResourceFn = (resourceCreationArgs: IResourceCreationArgs) => Promise<any>;
type pollResourceStatusFn = (resourceStatusArgs: IResourceStatusArgs) => Promise<any>

export const useResourceCreation = (): [createResourceFn, TaskStatus] => {
  const apiPost = useApiPost();

  const [status, setStatus] = useState(TaskStatus.TASK_STATUS_UNSPECIFIED);

  const createResourceFn = async (args: IResourceCreationArgs) => {
    const {
      resourceRoute,
      createResourceBodyArgs,
      failedCallback,
      completedCallback,
      acceptedCallback,
    } = args;

    let response;
    try {
      response = await apiPost(resourceRoute, createResourceBodyArgs);
    } catch (error) {
      setStatus(TaskStatus.TASK_ERROR);
      if (failedCallback) failedCallback((error as Error).message as string);
      return;
    }

    if (response.httpStatus === StatusCodes.OK) {
      setStatus(TaskStatus.TASK_COMPLETED);
      if (completedCallback) completedCallback();
    } else if (response.httpStatus === StatusCodes.ACCEPTED) {
      setStatus(TaskStatus.TASK_IN_PROGRESS);
      if (!response.result_id) {
        console.warn('Creation response had no resource id to retrieve the async result.');
      }
      if (acceptedCallback) {
        console.info(`Accepted. Triggering call back with response.result_id ${response.result_id}`);
        acceptedCallback(response.result_id);
      }
    }
  };
  return [createResourceFn, status];
};

export const useResourceStatusPolling = (): pollResourceStatusFn => {
  const apiGet = useApiGet();

  const pollResourceStatusFn = async (args: IResourceStatusArgs) => {
    const {
      resourceRoute,
      resourceId,
      failedCallback,
      acceptedCallback,
      createdCallback,
      pollIntervalSeconds,
    } = args;

    const wait = () => new Promise((resolve) => {
      setTimeout(resolve, pollIntervalSeconds * 1e3);
    });

    const resourceStatusRoute = `${resourceRoute}/${resourceId}`;

    let response = await apiGet(resourceStatusRoute);
    if (response.httpStatus >= 400) {
      console.error('Failed to poll resource status for Task {resourceId}.');
      if (failedCallback) failedCallback();
      return;
    }

    if (resourceRoute && response.httpStatus === StatusCodes.ACCEPTED) {
      while (response.httpStatus === StatusCodes.ACCEPTED) {
        if (acceptedCallback) acceptedCallback();
        await wait();
        response = await apiGet(resourceStatusRoute);
        if (response.msg) console.info(response.msg);
      }
      if (response.httpStatus >= 400) {
        if (failedCallback) failedCallback();
        return;
      }
    }

    if (response.httpStatus === StatusCodes.CREATED) {
      if (createdCallback) createdCallback();
    }
  };
  return pollResourceStatusFn;
};
