import { SuggestionsModelArtifactInputsModelType } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { DatesRangeValue } from '@mantine/dates';
import { useForm } from '@mantine/form';

export interface RetrievalConfigFormValues {
    conversationDates: DatesRangeValue;
    modelType: SuggestionsModelArtifactInputsModelType;
    addExperts: boolean;
    maxConversationCount: number;
    pastNMessages: number;
    trainSplitRatio: number;
    maxMultipleCandidatesAllowed: number;
    testConversationDates: DatesRangeValue;
    loadPreprocessingConfig: boolean;
    loadPostprocessingConfig: boolean;
    materializeConfigs: boolean;
  }

export const useRetrievalConfigForm = () => {
  const form = useForm<RetrievalConfigFormValues>({
    initialValues: {
      conversationDates: [null, null],
      modelType: SuggestionsModelArtifactInputsModelType.GPT2,
      addExperts: false,
      maxConversationCount: null,
      pastNMessages: 5,
      trainSplitRatio: 80,
      maxMultipleCandidatesAllowed: 10,
      testConversationDates: [null, null],
      loadPreprocessingConfig: false,
      loadPostprocessingConfig: false,
      materializeConfigs: true,
    },
  });

  return form;
};
