import React, { useState } from 'react';
import { Box, Button, Group, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import { MultilineSeleton } from '../../../components/MultilineSkeleton';

interface ActionBtnProps {
  onClick: () => void;
  buttonText: string;
  hintText: string;
  activeColor: string;
}

function ActionBtn({ onClick, buttonText, hintText, activeColor }: ActionBtnProps) {
  const [isActive, toggleActive] = useState(false);
  const handleClick = () => {
    if (isActive) return;
    toggleActive(true);
    onClick();
  };
  return (
    <Tooltip label={hintText} position="top">
      <Button
        // this prevents a strange behavior where an overflow container is created onMouseDown
        my={1}
        onClick={handleClick}
        compact
        size="xs"
        color={isActive ? activeColor : 'gray'}
        variant="light"
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
}

interface PreviewItemProps {
  agent: string;
  date: string;
  message: string;
  actionBtnProps: ActionBtnProps;
  speaker: 'Visitor' | 'Agent';
}

export function PreviewItem(props: PreviewItemProps) {
  const { agent, date, message, actionBtnProps, speaker } = props;
  const { onClick, buttonText, hintText, activeColor } = actionBtnProps;
  return (
    <div>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          padding: theme.spacing.xs,
          borderRadius: theme.radius.md,
        })}
      >
        <Group align="start">
          <Text style={{ flex: 0 }} size="sm">{speaker}:</Text>
          <Text style={{ flex: 1 }} size="sm">{message}</Text>
        </Group>
      </Box>
      <Group mt={3} position="apart">
        <Text size="sm" style={{ flex: 1 }} color="dimmed">
          {`${agent && `${agent} • `}${date}`}
        </Text>
        <ActionBtn
          onClick={onClick}
          buttonText={buttonText}
          hintText={hintText}
          activeColor={activeColor}
        />
      </Group>
    </div>
  );
}

interface SkeletonPreviewItemProps {
  skeleton: {
    rows?: number;
    lastRowWidth?: string;
  };
}

export function SkeletonPreviewItem({ skeleton }: SkeletonPreviewItemProps) {
  return (
    <div>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          paddingTop: theme.spacing.md,
          paddingBottom: theme.spacing.md,
          paddingLeft: theme.spacing.xs,
          paddingRight: theme.spacing.xs,
          borderRadius: theme.radius.md,
        })}
      >
        <Group align="start">
          <Skeleton width={50} height={10} />
          <Stack style={{ flex: 1 }}>
            <MultilineSeleton {...skeleton} />
          </Stack>
        </Group>
      </Box>
      <Group position="apart">
        <Group style={{ flex: 1 }} spacing={5}>
          <Skeleton width={90} height="8px" />
          <Text size="sm" color="dimmed">
            •
          </Text>
          <Skeleton width={70} height="8px" />
        </Group>
        <Skeleton width={120} height="8px" />
      </Group>
    </div>
  );
}
