import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import { notification, Typography } from 'antd';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { getId } from 'common/resourceName';
import {
  ListConversationsRequestFilter,
  ListConversationsResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { Conversation } from '@cresta/web-client/src/cresta/v1/studio/conversation/conversation_service.pb';
import { PageContainer } from 'components/PageContainer';
import { ConversationApi } from 'services/conversationApi';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import { Button, Group, Title } from '@mantine/core';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import ChatWindow from './ChatWindow';
import Filters from './Filters';
import './Conversations.scss';
import UtterancePanel from './UtterancePanel';
import ChatList from './ChatList';
import { NewSetButton } from './NewSetModal';

const DEFAULT_PARAMS = {
  filterAgents: [] as string[],
  filterDateStart: null,
  filterDateEnd: null,
  filterDurationMin: null,
  filterChatID: '',
  filterConvoID: '',
  filterSetIds: [] as string[],
  searchText: '',
  speaker: null,
};

const Conversations = () => {
  const { chatId: existingId } = useParams<{chatId?:string}>();
  const customer = useCustomerParams();
  const parent = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, toggleLoading] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  // Get URL Params
  const agentIds = searchParams.getAll('agentIds');
  const chatId = searchParams.get('chatId');
  const convoId = searchParams.get('convoId');
  const setIds = searchParams.getAll('setIds');
  const after = searchParams.get('after');
  const before = searchParams.get('before');
  const durationMin = searchParams.get('durationMin');
  const text = searchParams.get('text');
  const speakerParam = searchParams.get('speaker');

  // Filter State
  const [filterAgents, setFilterAgents] = useState(agentIds || DEFAULT_PARAMS.filterAgents);
  const [filterChatID, setFilterChatID] = useState(chatId || DEFAULT_PARAMS.filterChatID);
  const [filterConvoID, setFilterConvoID] = useState(convoId || DEFAULT_PARAMS.filterConvoID);
  const [filterSetIds, setFilterSetIds] = useState(setIds || DEFAULT_PARAMS.filterSetIds);
  const [filterDateStart, setFilterDateStart] = useState(after || DEFAULT_PARAMS.filterDateStart);
  const [filterDateEnd, setFilterDateEnd] = useState(before || DEFAULT_PARAMS.filterDateEnd);
  const [filterDurationMin, setFilterDurationMin] = useState(durationMin || DEFAULT_PARAMS.filterDurationMin);
  const [searchText, setSearchText] = useState(text || DEFAULT_PARAMS.searchText);
  const [speaker, setSpeaker] = useState(speakerParam || DEFAULT_PARAMS.speaker);

  const [isModalOpen, toggleModal] = useState(false);
  const filter: ListConversationsRequestFilter = {
    usecase,
    languageCode: customer.languageCode,
    agentIds: filterAgents.length ? filterAgents : undefined,
    beginTime: filterDateStart ? dayjs(filterDateStart).format(ACCEPTED_DATE_FORMAT) : undefined,
    endTime: filterDateEnd ? dayjs(filterDateEnd).format(ACCEPTED_DATE_FORMAT) : undefined,
    minimalConversationDuration: filterDurationMin ? `${parseInt(filterDurationMin, 10) * 60}s` : undefined,
    legacyConversationIds: filterChatID ? [filterChatID] : undefined,
    conversationIds: filterConvoID ? [filterConvoID] : undefined,
    setIds: filterSetIds.length ? filterSetIds : undefined,
  };

  function handleConversationListResponse(response: ListConversationsResponse) {
    if (!response) return;
    setNextPageToken(response.nextPageToken);
    setConversations(response.conversations);
  }

  // Data Fetching
  useEffect(() => {
    toggleLoading(true);
    updateURLQueryParams();
    const getConversations = async (filter: ListConversationsRequestFilter) => {
      ConversationApi.listConversations(
        {
          parent,
          filter,
          pageSize: 1000,
        },
      ).then((response: ListConversationsResponse) => {
        handleConversationListResponse(response);

        const firstConversation = response.conversations[0];
        if (!!existingId || !firstConversation) return;
        const newConversationId = getId('conversation', firstConversation.name);
        navigate(`/${customer.path}/conversation/${newConversationId}${search}`);
      }).catch((err) => {
        notification.error({
          message: 'Error Fetching Conversations',
          description: err.message,
        });
      }).finally(() => {
        toggleLoading(false);
      });
    };

    getConversations(filter);
  }, [
    parent,
    customer.usecaseId,
    customer.languageCode,
    filterAgents.toString(),
    filterChatID,
    filterConvoID,
    filterSetIds.toString(),
    filterDateStart,
    filterDateEnd,
    filterDurationMin,
    searchText,
    speaker,
  ]);

  // Filter handlers
  const handleAgentsSelect = (agents: string[]) => {
    setFilterAgents(agents);
  };

  const onChatIDFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFilterChatID(e.currentTarget.value);
  };

  const onConvoIDFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFilterConvoID(e.currentTarget.value);
  };

  const handleSetSelect = (sets:string[]) => {
    setFilterSetIds(sets);
  };

  const onRangeFilterChange = (_: any, dates: string[]) => {
    // if empty string set to null
    if (!dates[0] || !dates[1]) {
      setFilterDateStart(null);
      setFilterDateEnd(null);
      return;
    }
    setFilterDateStart(dayjs(dates[0]).startOf('day').format(ACCEPTED_DATE_FORMAT));
    setFilterDateEnd(dayjs(dates[1]).endOf('day').format(ACCEPTED_DATE_FORMAT));
  };

  const onDurationFilterChange = (min: number) => {
    setFilterDurationMin(min.toString());
  };

  const handleUtteranceSearch = (
    searchTextFilter: React.SetStateAction<string>,
    speakerFilter: React.SetStateAction<string>,
  ) => {
    setSearchText(searchTextFilter);
    setSpeaker(speakerFilter);
  };

  const handleUtteranceSearchReset = () => {
    setSearchText(DEFAULT_PARAMS.searchText);
    setSpeaker(DEFAULT_PARAMS.speaker);
  };

  const onFilterReset = () => {
    setFilterAgents(DEFAULT_PARAMS.filterAgents);
    setFilterChatID(DEFAULT_PARAMS.filterChatID);
    setFilterSetIds(DEFAULT_PARAMS.filterSetIds);
    setFilterDateStart(null);
    setFilterDateEnd(null);
    setFilterDurationMin(DEFAULT_PARAMS.filterDurationMin);
    handleUtteranceSearchReset();
  };

  function updateURLQueryParams() {
    const newSearchParams = new URLSearchParams();
    if (filterAgents.length) newSearchParams.set('agentIds', filterAgents.toString());
    if (filterChatID) newSearchParams.set('chatId', filterChatID);
    if (filterConvoID) newSearchParams.set('convoId', filterConvoID);
    if (filterSetIds.length) newSearchParams.set('setIds', filterSetIds.toString());
    if (filterDateStart) newSearchParams.set('after', filterDateStart);
    if (filterDateEnd) newSearchParams.set('before', filterDateEnd);
    if (filterDurationMin) newSearchParams.set('durationMin', filterDurationMin);
    if (searchText) newSearchParams.set('text', searchText);
    if (speaker) newSearchParams.set('speaker', speaker);

    setSearchParams(newSearchParams);
  }

  const handleLoadMore = async () => {
    toggleLoading(true);
    await ConversationApi.listConversations(
      {
        parent,
        filter,
        pageToken: nextPageToken,
      },
    ).then((response: ListConversationsResponse) => {
      handleConversationListResponse(response);
    }).catch((err) => {
      notification.error({
        message: 'Error Fetching Conversations',
        description: err.message,
      });
    }).finally(() => {
      toggleLoading(false);
    });
  };

  return (
    <PageContainer className="conversations">
      <Helmet>
        <title>Conversations</title>
      </Helmet>
      <Title order={2}>Conversations</Title>
      <div className="filter-controls">
        <Filters
          searchText={searchText}
          speaker={speaker}
          filterAgents={filterAgents}
          filterDateStart={filterDateStart}
          filterDateEnd={filterDateEnd}
          filterDurationMin={filterDurationMin}
          filterChatID={filterChatID}
          filterConvoID={filterConvoID}
          filterSetIds={filterSetIds}
          handleAgentsSelect={handleAgentsSelect}
          handleSetSelect={handleSetSelect}
          handleUtteranceSearch={handleUtteranceSearch}
          handleUtteranceSearchReset={handleUtteranceSearchReset}
          onRangeFilterChange={onRangeFilterChange}
          onDurationFilterChange={onDurationFilterChange}
          onChatIDFilterChange={onChatIDFilterChange}
          onConvoIDFilterChange={onConvoIDFilterChange}
          handleFilterReset={onFilterReset}
        />
      </div>
      {searchText ? (
        <UtterancePanel
          searchText={searchText}
          speaker={speaker}
          filterAgents={filterAgents}
          filterDateStart={filterDateStart}
          filterDateEnd={filterDateEnd}
        />
      ) : (
        <>
          <Group>
            <div className="chat-count">{`${conversations.length}${nextPageToken ? '+' : ''} conversations`}</div>
            <NewSetButton isModalOpen={isModalOpen} toggleModal={toggleModal} conversationNames={conversations.map((convo) => convo.name)} />
          </Group>
          <div className="panels">
            <div className="chats-list-holder">
              <ChatList
                chats={conversations}
                isLoading={isLoading}
                hasNextPage={!!nextPageToken}
                handleFilterReset={onFilterReset}
                handleLoadMore={handleLoadMore}
              />
            </div>
            <div className="chat-panel-holder">
              {conversations.length === 0 ? (
                <div className="empty-chats-container">
                  <Typography>No chat to display</Typography>
                  <Button color="red" onClick={onFilterReset}>
                    Clear Filters
                  </Button>
                </div>
              )
                : <ChatWindow />}
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default Conversations;
