import React, { useMemo, useContext } from 'react';
import { ConceptType, DataSplit, DerivedLabelingTask, SelectionMethod, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Group, Tooltip, Text } from '@mantine/core';
import Table, { ColumnType } from 'antd/lib/table';
import { getId, extractCustomerFromResource } from 'common/resourceName';
import MultiTags from 'components/MultiTags';
import TaskTypeTag from 'components/TaskTypeTag';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { enumCompareFnFactory, toTitleCase } from 'utils';
import UserTag from 'components/UserTag';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import TaskStatusIndicator from 'components/TaskStatusIndicator';
import { NavLink } from 'react-router-dom';
import { CustomerParams, composeCustomerUrl, useCustomerParams } from 'hooks/useCustomerParams';
import Loader from 'pages/Conversations/Loader';
import LetterAvatar from 'components/LetterAvatar';
import { useSelector } from 'hooks/reduxHooks';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConceptsMapFactory } from 'store/concept/selectors';
import { UserContext } from 'context/UserContext';
import { selectCustomersCurrentUserIds } from 'store/app/selectors';
import { sortBy } from 'lodash';
import { clipText } from 'common/clipText';

interface TaskPlaylistTableProps {
  customerIconHidden?: boolean;
  tasks: DerivedLabelingTask[];
  loading?: boolean;
}

interface DerivedLabelingTaskWithCustomer extends DerivedLabelingTask {
  customerParams: CustomerParams;
}

export function TaskPlaylistTable({
  customerIconHidden,
  tasks,
  loading,
}: TaskPlaylistTableProps) {
  const { getFirstConfig } = useContext(CustomerConfigContext);
  const customer = useCustomerParams();
  const allConceptsMap = useSelector<Map<string, Concept>>(selectAllConceptsMapFactory);
  const currentUser = useContext(UserContext);
  const customersCurrentUserIds = useSelector(selectCustomersCurrentUserIds);

  const tasksWithCustomers: DerivedLabelingTaskWithCustomer[] = useMemo(() => {
    const tasksWithCustomer = tasks.map((task) => {
      const { customerId, profileId } = extractCustomerFromResource('labelingTask', task.labelingTask.name);
      const usecaseId = getId('usecase', task.labelingTask.abstractTask.usecase);
      const customerParams: CustomerParams = {
        customerId,
        profileId,
        usecaseId,
        languageCode: task.labelingTask.abstractTask.languageCode,
      };
      customerParams.path = composeCustomerUrl(customerParams);

      // Active customer first
      return {
        ...task,
        customerParams,
      };
    });

    return sortBy(tasksWithCustomer, (task) => (task.customerParams.customerId === customer.customerId ? 0 : 1));
  }, [tasks]);

  const renderTaskAction = (derivedTask: DerivedLabelingTaskWithCustomer) => {
    if (!derivedTask) return <span />;

    const taskUrl = `/${derivedTask.customerParams.path}/labeling/view/${getId('labelingTask', derivedTask.labelingTask.name)}`;

    if ([TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(derivedTask.labelingTask.abstractTask.status)) {
      let doneCount = derivedTask.annotatedTargetCount;

      // Summarization gets these values from different source
      if (derivedTask.userLabelingTaskType === UserLabelingTaskType.LABELING_SUMMARIZATION) {
        doneCount = derivedTask.taskProgress.conversationSetProgresses[0]?.labeledSize;
      }

      if (doneCount > 0) {
        return (
          <span>
            <NavLink
              to={`${taskUrl}/${Math.min(derivedTask?.currentConversationNumber + 1, derivedTask?.totalConversationNumber - 1)}`}
            >
              Continue
            </NavLink>
          </span>
        );
      } else {
        return (
          <span>
            <NavLink
              data-cy="start-task-link"
              to={taskUrl}
            >
              Start
            </NavLink>
          </span>
        );
      }
    }

    return <span />;
  };

  const taskStatusValues: TaskStatus[] = Object.values(TaskStatus);

  const columns: ColumnType<DerivedLabelingTaskWithCustomer>[] = [
    {
      title: 'Concept',
      dataIndex: ['labelingTask', 'taskData', 'taskDescriptor', 'conceptType'],
      key: 'concept',
      sorter: enumCompareFnFactory<ConceptType, DerivedLabelingTaskWithCustomer>(Object.values(ConceptType), (t) => t.labelingTask.taskData.taskDescriptor.conceptType),
      render: (value) => clipText(value, 6),
    },
    {
      title: 'Task Type',
      dataIndex: 'userLabelingTaskType',
      key: 'taskType',
      sorter: enumCompareFnFactory<UserLabelingTaskType, DerivedLabelingTaskWithCustomer>(Object.values(UserLabelingTaskType), (t) => t.userLabelingTaskType),
      render: (value, row) => {
        if (
          row.labelingTask.taskData.taskDescriptor.selectionInstruction?.selectionMethod === SelectionMethod.REGEX
        ) {
          return (
            <Tooltip
              label={
                row.labelingTask.taskData.taskDescriptor.selectionInstruction?.regexSelectionInstruction.regexExpression
              }
            >
              <TaskTypeTag taskType={value} />
            </Tooltip>
          );
        }
        return <TaskTypeTag taskType={value} />;
      },
    },
    {
      title: 'Title',
      dataIndex: ['labelingTask', 'taskData', 'taskDescriptor', 'conceptIds'],
      key: 'conceptIds',
      render: (value: string[], row) => (
        <MultiTags
          type={row.labelingTask.taskData.taskDescriptor.conceptType === ConceptType.ENTITY ? 'entity' : 'intent'}
          tags={value.map((conceptId) => {
            const concept = allConceptsMap.get(conceptId);
            return concept ? concept.conceptTitle : null;
          })}
        />
      ),
    },
    {
      title: 'Test/Train',
      dataIndex: ['labelingTask', 'taskData', 'taskDescriptor', 'split'],
      key: 'testTrain',
      width: 100,
      sorter: enumCompareFnFactory<DataSplit, DerivedLabelingTaskWithCustomer>(Object.values(DataSplit), (t) => t.labelingTask.taskData.taskDescriptor.split),
      render: (value) => {
        if (value === DataSplit.DATA_SPLIT_UNSPECIFIED) {
          return '-';
        } else {
          return value;
        }
      },
    },
    {
      title: 'Assignee',
      dataIndex: ['labelingTask', 'abstractTask', 'assigneeUserId'],
      key: 'assignee',
      sorter: (a: DerivedLabelingTaskWithCustomer, b: DerivedLabelingTaskWithCustomer) => {
        const valueA = a.labelingTask.abstractTask.assigneeUserId || '';
        const valueB = b.labelingTask.abstractTask.assigneeUserId || '';
        return valueA.localeCompare(valueB);
      },
      render: (value: string, row) => {
        const { customerId, profileId } = row.customerParams;
        const key = `${customerId}/${profileId}`;
        const isCurrentCustomer = customersCurrentUserIds[key] === value;
        const userName = isCurrentCustomer ? currentUser?.fullName : 'Unassigned';
        return (
          <UserTag name={userName} />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: ['labelingTask', 'abstractTask', 'status'],
      key: 'status',
      sorter: enumCompareFnFactory<TaskStatus, DerivedLabelingTaskWithCustomer>(taskStatusValues, (t) => t.labelingTask.abstractTask.status),
      render: (value: TaskStatus, row: DerivedLabelingTaskWithCustomer) => (
        <TaskStatusIndicator
          status={value}
          errorMessage={row.labelingTask.abstractTask.errMessage}
          preparing={row.labelingTask.taskData.taskDescriptor.selectionInstruction?.selectionMethod !== SelectionMethod.DUAL && value === TaskStatus.TASK_CREATED}
        />
      ),
    },
    {
      title: '# of Done',
      key: 'annotatedTargetCount',
      render: (value, row: DerivedLabelingTaskWithCustomer, index) => {
        let firstValue = row.annotatedTargetCount;
        let secondValue = row.targetCount;
        // Summarization gets these values from different source
        if (row.userLabelingTaskType === UserLabelingTaskType.LABELING_SUMMARIZATION) {
          firstValue = row.taskProgress.conversationSetProgresses[0]?.labeledSize;
          secondValue = row.taskProgress.conversationSetProgresses[0]?.conversationSampledSize;
        }
        const labelsString = secondValue === null ? firstValue : `${firstValue}/${secondValue}`;
        return (
          <Text color={firstValue > 0 ? 'blue' : 'gray'}>
            {[TaskStatus.TASK_COMPLETED, TaskStatus.TASK_READY, TaskStatus.TASK_IN_PROGRESS].includes(row.labelingTask.abstractTask.status) && labelsString}
          </Text>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, row: DerivedLabelingTaskWithCustomer, index) => (
        <Group>
          {renderTaskAction(row)}
        </Group>
      ),
    },
  ];

  // Conditionally display customer icon column
  if (!customerIconHidden) {
    columns.unshift({
      title: '',
      dataIndex: ['labelingTask', 'name'],
      width: 100,
      key: 'status',
      sorter: (a: DerivedLabelingTask, b: DerivedLabelingTask) => {
        const valueA = getId('labelingTask', a.labelingTask.name);
        const valueB = getId('labelingTask', b.labelingTask.name);
        return valueA.localeCompare(valueB);
      },
      render: (value: string, row: DerivedLabelingTaskWithCustomer) => {
        const config = getFirstConfig(row.customerParams);
        return (
          <Tooltip label={toTitleCase(config?.customerShortName)} withinPortal>
            <div>
              <LetterAvatar name={toTitleCase(config?.customerShortName)} />
            </div>
          </Tooltip>
        );
      },
    });
  }

  return (
    <Table
      columns={columns}
      dataSource={tasksWithCustomers}
      loading={{
        spinning: !!loading,
        indicator: <Loader />,
      }}
    />
  );
}
