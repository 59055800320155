import { ConversationMessageStats, Job, Moment, RetrieveConversationMessageStatsResponse } from '@cresta/web-client';
import { RetrieveStudioClusteringMessageStatsRequest } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { Flex, Paper, Center } from '@mantine/core';
import { getId } from 'common/resourceName';
import Loading from 'components/Loading';
import { first } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageApi } from 'services/messageApi';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import AnalysisInfoPanel from '../AnalysisInfoPanel';
import { SankeyGoogleChart } from './SankeyGoogleChart';
import { useMoments } from '../hooks/useMoments';

export function SankeyChart({
  elasticSearchName,
  job,
}: {
  elasticSearchName: string,
  job: Job,
}) {
  const [moments] = useMoments();
  const customerProfile = useCustomerProfile();
  const [statsLoading, setStatsLoading] = useState(false);
  const [conversationMessageStats, setConversationMessageStats] = useState<RetrieveConversationMessageStatsResponse>(null);

  // Fetch stats
  const fetchClusteringStats = useCallback(async () => {
    setStatsLoading(true);
    setConversationMessageStats(null);
    try {
      const request: RetrieveStudioClusteringMessageStatsRequest = {
        parent: customerProfile,
        filter: {
          actorIds: [],
        },
        moments: moments.map((moment) => getId('concept', moment.name)),
        elasticSearchName,
      };
      const statsResponse = await MessageApi.retrieveStudioClusteringMessageStats(request);
      setConversationMessageStats(statsResponse);
    } catch (error) {
      // Todo
    } finally {
      setStatsLoading(false);
    }
  }, [moments, elasticSearchName]);

  const momentsStatsMap = useMemo(() => {
    const statsMap = new Map<string, ConversationMessageStats>();
    if (!conversationMessageStats) return statsMap;
    conversationMessageStats.conversationMessageStats.forEach((stats) => {
      const moment: Moment | undefined = first(first(stats.attribute.momentGroups)?.moments);
      statsMap.set(getId('moment', moment.name), stats);
    });
    return statsMap;
  }, [conversationMessageStats]);

  const rootSum = moments.reduce((acc, moment) => {
    const momentId = getId('concept', moment.name);
    const momentConvCount = momentsStatsMap.get(momentId)?.aggregatedConversationMessageStats.totalConversationCount || 0;
    return acc + momentConvCount;
  }, 0);

  // Fetch clustering stats for treemap
  useEffect(() => {
    if (!elasticSearchName) return;

    if (moments.length) {
      fetchClusteringStats();
    }
  }, [moments, elasticSearchName]);

  return (
    <Paper>
      {statsLoading ? (
        <Center style={{ minHeight: 800 }}>
          <Loading />
        </Center>
      ) : (
        <>
          <AnalysisInfoPanel total={rootSum} job={job} />
          <Flex justify="center" p={50} align="center">
            <SankeyGoogleChart />
          </Flex>
        </>
      )}
    </Paper>
  );
}
