import { Paper, Tooltip } from '@mantine/core';
import React from 'react';
import { clipText } from 'common/clipText';
import * as d3 from 'd3';
import { round } from 'lodash';
import { TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { FlowNodesTreeNode } from 'pages/Surveyor/utils';

export const NODE_BORDER_WIDTH = 4;

// Min transparency (alpha) value for stages
const scaleAlpha = d3.scaleLinear([0, 1], [0.2, 1]);

interface StageBlockProps {
  nodeDatum: FlowNodesTreeNode & TreeNodeDatum;
  foreignObjectProps: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  nodeSize: {
    width: number;
    height: number;
  }
  active?: boolean;
}

export function StageBlock({
  nodeDatum,
  foreignObjectProps,
  nodeSize,
  active,
}: StageBlockProps) {
  const percentage = nodeDatum.count / (nodeDatum.parent?.getChildrenTotalCount() || 1);
  return (
    <g>
      <foreignObject {...foreignObjectProps}>
        <Paper
          radius="md"
          style={{
            maxWidth: nodeSize.width,
            display: 'inline-flex',
            overflow: 'hidden',
            border: active ? `${NODE_BORDER_WIDTH}px solid orange` : `${NODE_BORDER_WIDTH}px solid transparent`,
          }}
        >
          <Tooltip label={nodeDatum.name} withinPortal withArrow>
            <div
              style={{
                backgroundColor: `rgba(${100 - (percentage * 100)}, 5, ${230 - (percentage * 100)}, ${scaleAlpha(percentage)})`,
                color: 'white',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: 12,
                maxWidth: nodeSize.width,
                height: nodeSize.height,
                padding: '0 10px',
              }}
            >

              <span style={{ whiteSpace: 'nowrap' }}>{`${clipText(nodeDatum.name, 12)} (${round(percentage * 100, 2)}%)`}</span>
            </div>
          </Tooltip>
        </Paper>
      </foreignObject>
    </g>
  );
}
