import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { FetchAnnotationSummaryRequestFilter } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import Table, { ColumnsType } from 'antd/lib/table';
import Loading from 'components/Loading';
import MultiTags from 'components/MultiTags';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnnotationsCount } from '.';

import styles from './styles.module.scss';

interface EntityLabelsTableProps {
  filters: FetchAnnotationSummaryRequestFilter;
  loading: boolean;
  annotationsCount: AnnotationsCount[];
  setPageInfo: React.Dispatch<React.SetStateAction<{ page: number, pageSize: number }>>;
}

export default function EntityLabelsTable({
  filters,
  loading,
  annotationsCount,
  setPageInfo,
}: EntityLabelsTableProps) {
  const columns: ColumnsType<AnnotationsCount> = [
    {
      title: 'Entity',
      dataIndex: 'concepTitle',
      key: 'concepTitle',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.concepTitle.localeCompare(c2.concepTitle),
      render: (value) => (<MultiTags tags={[value]} />),
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Model Ready</span>
        </div>
      ),
      dataIndex: 'modelReady',
      key: 'modelReady',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.modelReady - c2.modelReady,
      render: (value, record) => {
        if (filters.detailedTypeFilter.targetType === TargetType.QA) {
          return 'N/A';
        } else {
          return <Link to={`annotations/${record.intentId}?concept=ENTITY&split=${filters.splits[0]}`}>{value}</Link>;
        }
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        dataSource={annotationsCount}
        rowKey={(record: AnnotationsCount) => record.concepTitle}
        loading={{
          spinning: loading,
          indicator: <Loading />,
        }}
        pagination={{
          onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
        }}
      />
    </div>
  );
}
