import { FetchNoMatchSummaryRequest, FetchNoMatchSummaryResponse, GetPredictionRequest, GetPredictionResponse, PredictionService, SearchPredictionsRequest, SearchPredictionsResponse } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { StudioApi } from './studioApi';

export abstract class PredictionApi {
  public static getPrediction(
    req: GetPredictionRequest,
  ): Promise<GetPredictionResponse> {
    return PredictionService.GetPrediction(req, StudioApi.getHeaders().initReq);
  }

  public static searchPredictions(
    req: SearchPredictionsRequest,
  ): Promise<SearchPredictionsResponse> {
    return PredictionService.SearchPredictions(req, StudioApi.getHeaders().initReq);
  }

  public static fetchNoMatchSummary(
    req: FetchNoMatchSummaryRequest,
  ): Promise<FetchNoMatchSummaryResponse> {
    return PredictionService.FetchNoMatchSummary(req, StudioApi.getHeaders().initReq);
  }
}
