import { ModelProject } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { ApiStatus } from 'store/types';

/** Model project state. */
export interface State {
  modelProjects: ModelProject[];
  status: ApiStatus;
}

/** Initial model proejct state. */
export const INITIAL_STATE: State = {
  modelProjects: [],
  status: 'idle',
};
