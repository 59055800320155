import React, { useContext, useMemo } from 'react';
import { Box, Group, Tooltip, useMantineTheme } from '@mantine/core';
import { StagesContext } from '../../StagesProvider';

interface Props {
  children: React.ReactNode;
  stage: { stageName: string; annotationId: string };
}

export const StageContainer = (props: Props) => {
  const theme = useMantineTheme();
  const { selectedStageAnnotationId, onStageSelection } = useContext(StagesContext);
  const { children, stage } = props;
  const { stageName } = stage;
  const isSelected = selectedStageAnnotationId === stage.annotationId;

  const isTempStage = stage.annotationId === 'temp';

  const handleClick = () => {
    onStageSelection(stage.annotationId);
  };

  const verticalBarColor = useMemo(() => {
    if (isTempStage) {
      return theme.colors.purple[1];
    }
    if (isSelected) {
      return theme.colors.purple[4];
    }
    return theme.colors.purple[2];
  }, [isSelected, isTempStage, theme]);

  return (
    <Group align="stretch">
      <Tooltip.Floating label={stageName} color="purple">
        <Box
          onClick={handleClick}
          sx={(theme) => ({
            width: '8px',
            backgroundColor: verticalBarColor,
            borderRadius: isTempStage ? '10px 10px 0 0' : theme.radius.md,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor:
            theme.colors.purple[4],
            },
          })}
        />
      </Tooltip.Floating>
      {isSelected
        ? (
          <div style={{
            background: '#845EF738',
            border: '1px solid #845EF7',
            borderBottom: isTempStage ? '' : '1px solid #845EF7',
            borderRadius: isTempStage ? '8px 8px 0 0' : '8px',
            flex: 1,
            overflow: 'hidden',
          }}
          >
            {children}
          </div>
        )
        : <div style={{ flex: 1 }}>{children}</div>}
    </Group>
  );
};
