import { UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Upload, UploadProps } from 'antd';
import React from 'react';
import * as csv from 'csv-string';
import { validateCsvColumnHeaders } from 'utils/csvImport';

interface SelectedCSVButtonProps {
  handleRows: (rows: Record<string, string>[]) => void;
  expectedHeaders?: string[];
  tooltipMessage?: React.ReactNode;
}

export const SelectCSVButton = ({
  handleRows,
  expectedHeaders,
  tooltipMessage,
}: SelectedCSVButtonProps) => {
  const [opened, { close, open }] = useDisclosure(false);

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const csvText = e.target.result;
        const csvArray = csv.parse(String(csvText), { output: 'objects' });

        try {
          if (expectedHeaders) validateCsvColumnHeaders(csvArray[0], expectedHeaders); // Validate headers using the first row

          handleRows(csvArray);
        } catch (error: unknown) {
          let errMessage = 'Unknown error';
          if (error instanceof Error) {
            const { message } = error;
            errMessage = message;
          }
          showNotification({
            title: 'Import error',
            message: errMessage,
            color: 'red',
          });
        }
      };
      reader.readAsText(file);

      return false;
    },
  };

  return (
    <Upload
      beforeUpload={uploadProps.beforeUpload}
      accept=".txt, .csv"
      multiple={false}
      maxCount={1}
    >
      <Popover opened={opened} withArrow>
        <Popover.Target>
          <Button
            onMouseEnter={open}
            onMouseLeave={close}
            variant="subtle"
            leftIcon={<UploadOutlined />}
          >
            Select CSV
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          {tooltipMessage || (
            <Flex direction="column">
              <Text size="xs" color="dimmed" mb={5}>
                Expected Headers
              </Text>
              <Divider />
              <Flex wrap="nowrap" gap="xs" mt="md">
                {expectedHeaders.map((header, i) => (
                  <>
                    {i !== 0 && <Divider orientation="vertical" />}
                    <Text key={header} weight="bold" size="xs">
                      {header}
                    </Text>
                  </>
                ))}
              </Flex>
            </Flex>
          )}
        </Popover.Dropdown>
      </Popover>
    </Upload>
  );
};
