import {
  Concept,
  ConceptConceptType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Button, Flex, Loader, Modal, Select, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import React, { useContext, useEffect, useState } from 'react';
import { ConceptApi } from 'services/conceptApi';

const importLabelsForIntentName = async (intentName: string) => {
  console.info(`Importing labels for intent ${intentName}`);
  // UNIMPLEMENTED: import labels from another intent: https://linear.app/cresta/issue/STU-4606/be-copy-to-intent
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Not implemented'));
    }, 1000);
  });
};

const useUsecases = (): string[] => {
  const { allConfigs } = useContext(CustomerConfigContext);
  const { customerId, profileId } = useCustomerParams();
  const usecases = allConfigs
    .filter((config) => config.customerId === customerId && config.profileId === profileId)
    .map((config) => config.usecaseId);
  return usecases;
};

const useConceptForProfile = (usecaseId: string): [Concept[], boolean] => {
  const { customerId, profileId, languageCode } = useCustomerParams();

  const customerProfile = useCustomerProfile();
  const [concepts, setConcepts] = useState<Concept[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    ConceptApi.listConcepts({
      parent: customerProfile,
      conceptTypeEnumFilter: ConceptConceptType.INTENT,
      usecaseId,
      languageCode,
      pageSize: 1000,
    })
      .then((res) => {
        setConcepts(res.concepts);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          title: 'Error',
          message: err.message,
          color: 'red',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customerId, profileId, usecaseId]);
  return [concepts, isLoading];
};

export const ImportLabelsButton = ({ children }) => {
  const [isImportModalOpen, handleImportModal] = useDisclosure();
  const { usecaseId } = useCustomerParams();
  const usecases = useUsecases();
  const [selectedUsecase, setSelectedUsecase] = useState(usecaseId);
  const [concepts, isLoading] = useConceptForProfile(selectedUsecase);
  const [isImporting, setImporting] = useState(false);

  const [intentName, setIntentName] = useState<string>();

  const onClose = () => {
    setIntentName(null);
    handleImportModal.close();
  };

  const handleImport = async () => {
    if (!intentName) return;
    // UNIMPLEMENTED: import labels from another intent: https://linear.app/cresta/issue/STU-4606/be-copy-to-intent
    setImporting(true);
    importLabelsForIntentName(intentName)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Labels imported successfully',
          color: 'green',
        });
        setImporting(false);
        onClose();
      })
      .catch((err) => {
        showNotification({
          title: 'Error',
          message: err.message,
          color: 'red',
        });
        setImporting(false);
      });
  };

  return (
    <>
      <Tooltip label="Import labels from another intent">
        <Button ml="lg" onClick={() => handleImportModal.open()}>
          {children}
        </Button>
      </Tooltip>
      <Modal title="Import Labels" opened={isImportModalOpen} onClose={onClose} centered>
        <Flex direction="column" gap="md">
          {usecases.length > 1 && (
            <Select
              withinPortal
              label="Select usecase"
              placeholder="Select usecase"
              data={usecases.map((usecase) => ({ value: usecase, label: usecase }))}
              value={selectedUsecase}
              onChange={(value) => setSelectedUsecase(value)}
            />
          )}
          {isLoading ? (
            <Flex justify="center">
              <Loader size="sm" />
            </Flex>
          ) : (
            <Select
              withinPortal
              label="Select intent"
              placeholder="Select intent"
              data={concepts.map((intent) => ({
                value: intent.name,
                label: intent.conceptTitle,
              }))}
              value={intentName}
              onChange={(value) => setIntentName(value)}
            />
          )}
          <Flex justify="end" gap="md">
            <Button variant="subtle" onClick={() => handleImportModal.close()}>
              Cancel
            </Button>
            <Button loading={isImporting} onClick={handleImport} disabled={!intentName}>
              Import
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
