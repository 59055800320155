import { ListConceptChangesResponse } from '@cresta/web-client/dist/cresta/v1/studio/conceptchange/conceptchange_service.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { State } from './state';

/** General reducer for starting a concept change API. */
export function conceptChangeApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed concept change API. */
export function conceptChangeApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** List concept changes success. */
export function listConceptChangesSuccess(state: State, action: PayloadAction<ListConceptChangesResponse & {loadNew: boolean}>): State {
  const updatedConceptChanges = action.payload.loadNew ? action.payload.conceptChanges : [...state.conceptChanges, ...action.payload.conceptChanges];
  return {
    ...state,
    status: 'succeeded',
    conceptChanges: updatedConceptChanges,
    nextPageToken: action.payload.nextPageToken,
  };
}

/** Reducer for clearing (resetting) concept changes data  */
export function clearConceptChangesReducer(state: State): State {
  return {
    ...state,
    status: 'succeeded',
    conceptChanges: [],
    nextPageToken: null,
  };
}
