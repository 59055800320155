import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { ProdApi } from 'services/prodApi';
import { fetchLabelingTasks, refreshLabelingTasks } from 'store/labelingTask/asyncThunks';
import { listUser } from 'store/user/asyncThunks';
import { listConcepts, syncLibraryConcepts } from 'store/concept/asyncThunks';
import { listSets } from 'store/set/asyncThunks';
import { listAgents } from 'store/agent/asyncThunks';
import {
  getServingModelDetails,
  getModelTaxonomies,
  chainUpModelTaxonomies,
} from 'store/modelBuilder/asyncThunks';
import { Model } from 'store/modelBuilder/state';
import { useApiGet } from 'hooks/network';
import { useInterval } from 'hooks/useInterval';
import { selectFetchingTasks } from 'store/labelingTask/selectors';

export const useReduxInit = () => {
  const dispatch = useDispatch();
  const customerParams = useCustomerParams();
  const { customerId, profileId, usecaseId, languageCode, path } = customerParams;
  const apiGet = useApiGet(false);
  const getter = () => apiGet(`${path}/get_serving_model_details`);
  const fetchingTasks = useSelector<boolean>(selectFetchingTasks);
  const [initialized, setInitialized] = useState(false);

  // Setup API and fetch necessary resources.
  useEffect(() => {
    // A valid key is required to fetch resources, so we need to exchange the token first.
    const fetchData = async () => {
      const customerProfile = `customers/${customerId}/profiles/${profileId}`;
      await ProdApi.exchangeToken(customerId, profileId);
      dispatch(
        fetchLabelingTasks({
          parent: customerProfile,
          usecaseId,
          languageCode,
        }),
      );
      dispatch(listUser(customerProfile));
      dispatch(syncLibraryConcepts(customerProfile));
      dispatch(
        listConcepts({
          parent: customerProfile,
          usecaseId,
          languageCode,
        }),
      );
      dispatch(
        listSets({
          parent: customerProfile,
          usecase: `${customerProfile}/usecases/${usecaseId}`,
          languageCode,
        }),
      );
      dispatch(listAgents(`customers/${customerId}`));
      const resp = await dispatch(getServingModelDetails({ getter }));
      dispatch(getModelTaxonomies(chainUpModelTaxonomies(resp.payload as Model[], apiGet, path)));
      setInitialized(true);
    };

    fetchData();
  }, [path]);

  // Refresh resources every 10s.
  useInterval(
    () => {
      if (customerId && profileId) {
        const customerProfile = `customers/${customerId}/profiles/${profileId}`;
        // No refresh if there is a pending fetchLabelingTasks.
        if (!fetchingTasks) {
          dispatch(
            refreshLabelingTasks({
              parent: customerProfile,
              usecaseId,
              languageCode,
            }),
          );
        }
      }
    },
    10000,
    [customerId, profileId, fetchingTasks],
  );
  return initialized;
};
