import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Group, Title, Text, Box, Grid, Paper, Center, Drawer, Input, useMantineTheme, Stack, Skeleton, Flex, Alert } from '@mantine/core';
import Loading from 'components/Loading';
import { CustomerParams, useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { round, sortBy } from 'lodash';
import ChatCard from 'pages/Conversations/ChatCard';
import { NavLink } from 'react-router-dom';
import { useAgentNames } from 'hooks/useAgentNames';
import { Cluster, ClusterItem, DeleteClusterRequest, GetClusterRequest, ListClustersRequest, MergeClustersRequest, UpdateClusterRequest } from '@cresta/web-client/dist/cresta/v1/studio/cluster/cluster_service.pb';
import { ClusterApi } from 'services/clusterApi';
import { openNotification } from 'components/Notification';
import { Job } from '@cresta/web-client';
import Treemap2, { TreemapNode } from './Treemap2';
import AnalysisInfoPanel from '../AnalysisInfoPanel';
import { DiscoverySidebar } from './DiscoverySidebar';
import { useSetActions } from '../hooks/useSetActions';
import MergeClustersModal from './MergeClustersModal';

const TREEMAP_HEIGHT = 748;

export function ClusterItemsList({
  clusterItems,
  customer,
  getAgentNameFromActorId,
}: {
  clusterItems: ClusterItem[],
  customer: CustomerParams,
  getAgentNameFromActorId: (actorId: string) => string
}) {
  return (
    <Stack>
      {clusterItems.map((clusterItem, i) => {
        const summary = clusterItem.clusterContent;
        const convId = clusterItem.conversationId;

        const agentName = getAgentNameFromActorId(clusterItem.agentId);
        const isAgentNameLoading = agentName === clusterItem.agentId;

        let title: React.ReactNode = 'N/A';
        if (clusterItem.agentId) {
          if (isAgentNameLoading) {
            title = <Skeleton w={130} h={16}/>;
          } else {
            title = agentName;
          }
        }

        return (
          <NavLink to={{ pathname: `/${customer.path}/conversation/${convId}` }} target="_blank">
            <ChatCard
              bordered
              title={title}
              copyableValue={convId}
            >
              <Text color="gray" size="sm">{summary}</Text>
            </ChatCard>
          </NavLink>
        );
      })}
    </Stack>
  );
}

interface CallDriverDiscoveryProps {
  clusterVersionName: string;
  jobs: Job[];
}

export function getPercent(num: number, total: number) { return round(num / total * 100, 2); }

function clusterToTreemapNode(cluster: Cluster): TreemapNode {
  return {
    id: cluster.name,
    name: cluster.clusterTitle,
    value: cluster.clusterItemCount,
    children: [],
  };
}

export default function CallDriverDiscovery({ clusterVersionName, jobs }: CallDriverDiscoveryProps) {
  const theme = useMantineTheme();
  const customerProfile = useCustomerProfile();
  const containerRef = useRef<HTMLDivElement>();
  const customer = useCustomerParams();
  const { getAgentNameFromActorId } = useAgentNames();
  const { createSet } = useSetActions();
  const [isClusterLoading, setIsClusterLoading] = useState(false);

  // Treemap container dimensions
  const [width, setWidth] = useState(0);
  const [, setHeight] = useState(0);

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isEditingMoments, setIsEditingMoments] = useState(false);

  const [selectedCluster, setSelectedCluster] = useState<Cluster>(null);
  const [hoveredCluster, setHoveredCluster] = useState<Cluster>(null);
  const [clusterItems, setClusterItems] = useState<ClusterItem[]>([]);
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [areClustersLoading, setAreClustersLoading] = useState(false);
  const [checkedClusterIds, setCheckedClusterIds] = useState<string[]>([]);
  const [isMergeModalVisible, setMergeModalVisible] = useState(false);

  // Job selected from dropdown
  const selectedJob = useMemo(() => jobs.find((job) => job.payload.callDriverDiscoveryPayload.versionName === clusterVersionName), [jobs, clusterVersionName]);

  // Hide clusters that were merged
  const filteredClusters = useMemo(() => {
    if (!selectedJob) return [];

    const mergedClusterNames = new Set();
    clusters.forEach((cluster) => {
      if (cluster.childClustersReferenceIds) {
        cluster.childClustersReferenceIds.forEach((name) => mergedClusterNames.add(name));
      }
    });
    return clusters.filter((cluster) => !mergedClusterNames.has(cluster.name));
  }, [clusters, selectedJob]);

  useEffect(() => {
    setSelectedCluster(null);
    setClusterItems([]);
    setClusters([]);
    if (selectedJob && selectedJob.state === 'SUCCEEDED') {
      listClusters();
    }
  }, [clusterVersionName]);

  // Fetch clusters
  const listClusters = useCallback(async () => {
    try {
      setAreClustersLoading(true);
      const request: ListClustersRequest = {
        parent: customerProfile,
        clusterVersionName,
      };
      const response = await ClusterApi.listClusters(request);
      setClusters(response.clusters);
    } catch (error) {
      openNotification('error', 'Failed to get clusters', null, error);
    } finally {
      setAreClustersLoading(false);
    }
  }, [customerProfile, clusterVersionName]);

  // For conversation set
  const [setTitle, setSetTitle] = useState('');

  // Resize treemap
  const resizeHandler = useCallback(
    () => {
      if (!containerRef.current) return;
      // Width minus padding
      setWidth(containerRef.current.offsetWidth);
      setHeight(Math.min(containerRef.current.offsetHeight, 1000));
    },
    [containerRef],
  );

  // Get container width for svg
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  // Get cluster
  const getCluster = useCallback(async (name: string) => {
    try {
      setIsClusterLoading(true);
      const request: GetClusterRequest = {
        name,
      };

      const clusterResponse = await ClusterApi.getCluster(request);
      setClusterItems(clusterResponse.clusterItems);
    } catch (error) {
      openNotification('error', 'Failed to get cluster', null, error);
    } finally {
      setIsClusterLoading(false);
    }
  }, [customerProfile]);

  // Update cluster
  const updateCluster = useCallback(async (cluster: Cluster, updateMask: string) => {
    try {
      setIsClusterLoading(true);
      const request: UpdateClusterRequest = {
        cluster,
        updateMask,
      };

      await ClusterApi.updateCluster(request);
      listClusters();
    } catch (error) {
      openNotification('error', 'Failed to update cluster', null, error);
    } finally {
      setIsClusterLoading(false);
    }
  }, [customerProfile]);

  // Hide cluster
  const handleHideCluster = useCallback(async (cluster: Cluster, isHidden: boolean) => {
    await updateCluster({
      ...cluster,
      isHidden,
    }, 'is_hidden');
  }, [updateCluster]);

  const rootSum = useMemo(() => filteredClusters.reduce((acc, cluster) => acc + cluster.clusterItemCount, 0), [clusters]);

  const clustersTree = useMemo(() => ({
    id: 'root',
    name: 'Clusters',
    // Root node value must be 0 because it will be updated with the sum of its children
    value: 0,
    children: filteredClusters.map((cluster) => ({
      id: cluster.name,
      name: cluster.clusterTitle,
      value: cluster.clusterItemCount,
      children: [],
    })),
  }), [filteredClusters]);

  const sortedClusters = sortBy(clusters, ((cluster) => -cluster.clusterItemCount));

  // When cluster is clicked
  const handleSelectCluster = useCallback((cluster: Cluster) => {
    if (cluster) {
      setSetTitle(`${cluster.clusterTitle} set`);
    }
    setSelectedCluster(cluster);
    setDrawerVisible(true);
    getCluster(cluster.name);
  }, [setSetTitle, setSelectedCluster, setDrawerVisible]);

  // When cluster is checked from the sidebar
  const handleCheckCluster = useCallback((cluster: Cluster, checked: boolean) => {
    if (checked) {
      setCheckedClusterIds([...checkedClusterIds, cluster.name]);
    } else {
      setCheckedClusterIds(checkedClusterIds.filter((id) => id !== cluster.name));
    }
  }, [checkedClusterIds]);

  // Open merge modal
  const handleMergeClusters = useCallback(async () => {
    setMergeModalVisible(true);
  }, []);

  // Merge clusters
  const mergeClusters = useCallback(async (newClusterName: string) => {
    try {
      setIsClusterLoading(true);
      const request: MergeClustersRequest = {
        profile: customerProfile,
        clusterVersionName,
        clusterNames: checkedClusterIds,
        mergedClusterTitle: newClusterName,
      };

      await ClusterApi.mergeClusters(request);
      listClusters();
    } catch (error) {
      openNotification('error', 'Failed to merge clusters', null, error);
    } finally {
      setIsClusterLoading(false);
      setMergeModalVisible(false);
    }
  }, [checkedClusterIds, clusterVersionName, customerProfile, listClusters]);

  // Split clusters back into individual clusters (delete handles this)
  const handleUnmergeClusters = useCallback(async (cluster: Cluster) => {
    try {
      setIsClusterLoading(true);
      const request: DeleteClusterRequest = {
        name: cluster.name,
      };

      await ClusterApi.deleteCluster(request);
      listClusters();
    } catch (error) {
      openNotification('error', 'Failed to unmerge clusters', null, error);
    } finally {
      setIsClusterLoading(false);
    }
  }, [listClusters]);

  const handleDrawerClose = useCallback(() => {
    setDrawerVisible(false);
    setSelectedCluster(null);
    setClusterItems([]);
  }, [setDrawerVisible, setSelectedCluster]);

  const renderSuccessContent = () => {
    if (areClustersLoading) {
      return (
        <Center>
          <Loading />
        </Center>
      );
    }

    return (
      <Treemap2
        data={clustersTree}
        width={width}
        height={TREEMAP_HEIGHT}
        rootSum={rootSum}
        hoveredNode={hoveredCluster && clusterToTreemapNode(hoveredCluster)}
        selectedNode={selectedCluster && clusterToTreemapNode(selectedCluster)}
        handleSelectNode={(node) => {
          const cluster = clusters.find((c) => c.name === node.id);
          handleSelectCluster(cluster);
        }}
      />
    );
  };

  return (
    <>
      <Grid columns={7}>
        <Grid.Col span={5}>
          <Paper
            ref={containerRef}
            style={{ minHeight: 800, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Flex
              direction="column"
            >
              {!clusterVersionName ? (
                <Flex justify="center">
                  <Text>Select cluster version to load data</Text>
                </Flex>
              ) : (
                <>
                  <AnalysisInfoPanel total={rootSum} job={selectedJob}/>
                  <Flex style={{ height: TREEMAP_HEIGHT, width: '100%' }} direction="column" justify="center">
                    {selectedJob?.state === 'SUCCEEDED' && renderSuccessContent()}
                    {(selectedJob?.state === 'RUNNING' || selectedJob?.state === 'PENDING') && (
                    <Center>
                      <Loading />
                    </Center>
                    )}
                    {selectedJob?.state === 'FAILED' && (
                    <Center>
                      <Alert color="red" title="Job failed">
                        Try running the job again with different parameters or contact Studio support
                      </Alert>
                    </Center>
                    )}
                  </Flex>
                </>
              )}
            </Flex>
          </Paper>
        </Grid.Col>
        <Grid.Col span={2}>
          <Paper style={{ height: 800, overflow: 'hidden' }}>
            <DiscoverySidebar
              clusters={sortedClusters}
              checkedClusterIds={checkedClusterIds}
              handleHoverCluster={setHoveredCluster}
              handleSelectCluster={handleSelectCluster}
              handleCheckCluster={handleCheckCluster}
              handleHideCluster={handleHideCluster}
              rootSum={rootSum}
              isEditingMoments={isEditingMoments}
              setIsEditingMoments={setIsEditingMoments}
              handleMergeClusters={handleMergeClusters}
              handleUnmergeClusters={handleUnmergeClusters}
            />
          </Paper>
        </Grid.Col>
      </Grid>
      <Drawer
        opened={isDrawerVisible}
        onClose={handleDrawerClose}
        position="right"
        styles={{
          header: {
            display: 'none',
          },
          body: {
            overflow: 'hidden',
            height: '100%',
            marginLeft: -theme.spacing.lg,
            marginRight: -theme.spacing.lg,
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            paddingBottom: theme.spacing.lg,
          },
          title: {
            color: theme.colors.gray[5],
            fontSize: theme.fontSizes.sm,
          },
        }}
      >
        <Box px="lg">
          <Title order={2} pt="lg">{selectedCluster?.clusterTitle}</Title>
          <Text my="md" size="sm">{selectedCluster?.clusterItemCount} conversations ({getPercent(selectedCluster?.clusterItemCount, rootSum)}% of total conversations)</Text>
          <Group spacing="xs" position="apart">
            <Input
              value={setTitle}
              onChange={(event) => setSetTitle(event.target.value)}
              style={{ flex: 1 }}
            />
            <Button
              color="blue"
              variant="light"
              onClick={() => createSet(setTitle, clusterItems.map((clusterItem) => `${customerProfile}/conversations/${clusterItem.conversationId}`))}
            >Create Set
            </Button>
          </Group>
        </Box>
        <Box
          mt="md"
          p="lg"
          style={{
            flex: 1,
            overflow: 'auto',
            borderTop: `1px solid ${theme.colors.gray[1]}`,
          }}
        >
          {isClusterLoading ? (
            <Stack>
              <Skeleton w="100%" h={100} />
              <Skeleton w="100%" h={100} />
              <Skeleton w="100%" h={100} />
            </Stack>
          ) : (
            <ClusterItemsList
              clusterItems={clusterItems}
              customer={customer}
              getAgentNameFromActorId={getAgentNameFromActorId}
            />
          )}
        </Box>
      </Drawer>
      <MergeClustersModal
        isOpen={isMergeModalVisible}
        onCancel={() => setMergeModalVisible(false)}
        onSubmit={mergeClusters}
        submitting={isClusterLoading}
      />
    </>
  );
}
