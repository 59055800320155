import { EdgeEdgeConditionType, ExpandNextFlowNodesDistributionRequest, FlowNodesCollection } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { Paper, createStyles, SegmentedControl, Center, Box } from '@mantine/core';
import Tree from 'react-d3-tree';
import React, { LegacyRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Point } from '@nivo/line';
import { FlowNodeApi } from 'services/flowNodeApi';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { getId } from 'common/resourceName';
import { cloneDeep, isEqual } from 'lodash';
import { Orientation, TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ApartmentOutlined } from '@ant-design/icons';
import { FlowNodesTreeNode } from './utils';
import { NODE_ACTIVE_BORDER_WIDTH, TreeBlock } from './TreeBlock';

export enum PathConditionType {
  FULL_PATH = 'FULL_PATH',
  SINGLE_NODE = 'SINGLE_NODE'
}

// Dimensions required to properly calculate node positions within react-d3-tree
const NODE_WIDTH = 240;
const NODE_HEIGHT = 40;
const NODE_BUTTONS_HEIGHT = 35;

// Join node path with this character (needed for path selection, highlighting...)
const PATH_DELIMETER = ':';

// Center the tree horizontally
export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width } = containerElem.getBoundingClientRect();
      // Offset from top
      setTranslate({ x: width / 2, y: 400 });
    }
  }, []);
  return [translate, containerRef];
};

interface StagesTreeProps {
  width: number,
  height: number,
  taskIds: string[],
  driverTagIds: string[],
  outcomeTagIds: string[],
  setIds: string[],
  selectedNodePath: FlowNodesTreeNode[],
  setSelectedNodePath: (path: FlowNodesTreeNode[]) => void;
  handleLoadMessages: (path: FlowNodesTreeNode) => void;
  nextEdgeType: EdgeEdgeConditionType;
  rootElement?: React.ReactNode;
  root: FlowNodesTreeNode,
  setRoot: (node: FlowNodesTreeNode) => void,
  pathCondition?: PathConditionType;
  autoIncludeStageEnd?: boolean;
}

export default function StagesTree({
  width,
  height,
  taskIds,
  driverTagIds,
  outcomeTagIds,
  setIds,
  selectedNodePath,
  setSelectedNodePath,
  handleLoadMessages,
  nextEdgeType,
  rootElement,
  root,
  setRoot,
  pathCondition,
  autoIncludeStageEnd,
}: StagesTreeProps) {
  const customerProfile = useCustomerProfile();
  const [flowNodesLoading, setFlowNodesLoading] = useState<boolean>(false);
  const [translate, containerRef] = useCenteredTree();
  const [orientation, setOrientation] = useState<Orientation>('horizontal');
  const isPathConditionSingleMode = pathCondition === PathConditionType.SINGLE_NODE;

  // The number of nodes selected. If the selected node is n, the collection will be a list of n - nodesRange nodes
  const [nodesRange, setNodesRange] = useState(1);

  const nodesRangeOverride: number = useMemo(() => {
    if (pathCondition) {
      switch (pathCondition) {
        case PathConditionType.FULL_PATH:
          return selectedNodePath.length;
        case PathConditionType.SINGLE_NODE:
          return nodesRange;
        default:
          return 1;
      }
    } else {
      return nodesRange;
    }
  }, [nodesRange, pathCondition, selectedNodePath]);

  // Required for d3 calculations (the actual node height has to be bigger to accommodate the buttons)
  const nodeSize = { width: NODE_WIDTH, height: NODE_HEIGHT };
  const foreignObjectProps = {
    width: nodeSize.width,
    height: nodeSize.height + NODE_BUTTONS_HEIGHT,
    x: (nodeSize.width + (NODE_ACTIVE_BORDER_WIDTH * 2)) / 2 * -1,
    y: (nodeSize.height + (NODE_ACTIVE_BORDER_WIDTH * 2)) / 2 * -1,
  };

  // Collection of nodes
  const flowNodesCollectionsBefore = useMemo(() => selectedNodePath.slice(-1 * nodesRangeOverride), [selectedNodePath, nodesRangeOverride]);

  const useStyles = createStyles((theme) => ({
    treePath: {
      stroke: 'rgba(180, 180, 220, 1) !important',
      strokeWidth: 2,
      opacity: '1 !important',
    },
    treeNodeSvg: {
      svg: {
        // Override react-d3-tree svg style
        strokeWidth: '0 !important',
      },
    },
  }));
  const styles = useStyles();

  // Use this string in deps instead of arrays which change and cause useEffect to run
  const filtersHash = useMemo(() => JSON.stringify(
    [taskIds, driverTagIds, outcomeTagIds, setIds, autoIncludeStageEnd],
  ), [taskIds, driverTagIds, outcomeTagIds, setIds, autoIncludeStageEnd]);

  useEffect(() => {
    if (flowNodesCollectionsBefore?.length) {
      callFlowNodes();
    }
  }, [flowNodesCollectionsBefore, nextEdgeType, filtersHash]);

  // Reset tree when root changes
  useEffect(() => {
    if (root.flowNodeCollection?.tagType) {
      setSelectedNodePath([root]);
    }
  }, [root?.name]);

  const callFlowNodes = useCallback(async () => {
    const request: ExpandNextFlowNodesDistributionRequest = {
      profile: customerProfile,
      filter: {
        taskIds,
        driverTagIds,
        outcomeTagIds,
        setIds,
      },
      flowNodesCollectionsBefore: flowNodesCollectionsBefore.map((treeNode, i) => {
        const prevFlowNodeName = flowNodesCollectionsBefore[i - 1]?.flowNodeCollection?.conceptName;
        const collection: FlowNodesCollection = {
          conceptName: treeNode.flowNodeCollection.conceptName,
          tagType: treeNode.flowNodeCollection.tagType,
          nextEdge: {
            conditionType: nextEdgeType,
            prevFlowNodeId: prevFlowNodeName ? getId('concept', prevFlowNodeName) : undefined,
          },
        };
        return collection;
      }),
    };

    const collectionLastNode = flowNodesCollectionsBefore[flowNodesCollectionsBefore.length - 1];
    const isBeginBlockStage = root.flowNodeCollection.tagType === TagTagType.STAGE_BEGIN;
    // If the last node is stage, and the toggle is on, add stage end to flow nodes collection after
    if (autoIncludeStageEnd && isBeginBlockStage) {
      request.flowNodesCollectionsAfter = [
        {
          conceptName: root.flowNodeCollection.conceptName,
          tagType: TagTagType.STAGE_END,
          prevEdge: {
            conditionType: EdgeEdgeConditionType.NEXT_STAGE_END,
          },
        },
      ];
    }

    setFlowNodesLoading(true);
    try {
      const response = await FlowNodeApi.expandNextFlowNodesDistribution(request);

      const selectedNodeCopy = cloneDeep(collectionLastNode);
      selectedNodeCopy.removeStages();
      response.flowNodesCollections.forEach((flowNodeCollection) => {
        selectedNodeCopy.addStages([{
          nodeName: flowNodeCollection.conceptTitle,
          flowNode: flowNodeCollection,
        }]);
      });
      const rootCopy = selectedNodeCopy.findRoot();

      setRoot(rootCopy);
    } catch (err) {
      console.error(err);
    } finally {
      setFlowNodesLoading(false);
    }
  }, [flowNodesCollectionsBefore, nextEdgeType, customerProfile, root, taskIds, driverTagIds, outcomeTagIds, setIds, autoIncludeStageEnd]);

  const increaseNodesRange = () => {
    setNodesRange((prev) => prev + 1);
  };

  const decreaseNodesRange = () => {
    setNodesRange((prev) => Math.max(prev - 1, 1));
  };

  const removeNode = (node: FlowNodesTreeNode) => {
    const selectedNodeCopy = cloneDeep(node);
    selectedNodeCopy.removeNode();
    const rootCopy = selectedNodeCopy.findRoot();

    setRoot(rootCopy);
  };

  const selectedNodeStringPath = selectedNodePath.map((node) => node.name).join(PATH_DELIMETER) + PATH_DELIMETER;
  const selectedNodeLevel = selectedNodePath?.length;

  return (
    <Paper style={{ height: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 20, left: 20 }}>
        <SegmentedControl
          onChange={(value) => setOrientation(value as Orientation)}
          value={orientation}
          data={[
            {
              value: 'horizontal',
              label: (
                <Center>
                  <ApartmentOutlined style={{ transform: 'rotate(-90deg)' }} />
                  <Box ml={10}>Horizontal</Box>
                </Center>
              ),
            },
            {
              value: 'vertical',
              label: (
                <Center>
                  <ApartmentOutlined />
                  <Box ml={10}>Vertical</Box>
                </Center>
              ),
            },
          ]}
        />
      </div>
      <div style={{ height, width: '100%' }} ref={containerRef as LegacyRef<HTMLDivElement>}>
        <Tree
          data={root}
          pathFunc="step"
          nodeSize={{
            x: NODE_WIDTH,
            y: NODE_HEIGHT + NODE_BUTTONS_HEIGHT,
          }}
          pathClassFunc={() => styles.classes.treePath}
          translate={translate as Point}
          depthFactor={orientation === 'vertical' ? 100 : 265}
          collapsible
          enableLegacyTransitions
          transitionDuration={200}
          hasInteractiveNodes={false}
          centeringTransitionDuration={300}
          dimensions={{
            width,
            height,
          }}
          separation={{
            siblings: 1.2,
            nonSiblings: 1.2,
          }}
          orientation={orientation}
          svgClassName={styles.classes.treeNodeSvg}
          renderCustomNodeElement={(rd3tProps) => {
            const flowTreeNode = (rd3tProps.nodeDatum as unknown as FlowNodesTreeNode & TreeNodeDatum);

            const nodePath = flowTreeNode.getPath();
            const nodeStringPath = nodePath.map((node) => node.name).join(PATH_DELIMETER);
            const nodeLevel = nodePath.length;
            const isActive = selectedNodeStringPath?.includes(nodeStringPath + PATH_DELIMETER) && nodeLevel > (selectedNodeLevel - nodesRangeOverride);
            const isLastNode = selectedNodeStringPath === nodeStringPath + PATH_DELIMETER;
            const isRootBlock = nodeLevel === 1;

            return (
              <TreeBlock
                {...rd3tProps}
                nodeDatum={flowTreeNode}
                foreignObjectProps={foreignObjectProps}
                beginBlockHeight={NODE_HEIGHT + NODE_BUTTONS_HEIGHT}
                blockHeight={NODE_HEIGHT}
                blockWidth={NODE_WIDTH}
                active={isActive}
                showPlus={isPathConditionSingleMode && isLastNode && nodePath?.length > nodesRangeOverride}
                showMinus={isPathConditionSingleMode && isLastNode && nodesRangeOverride > 1}
                loading={isActive && flowNodesLoading}
                onNodeExpand={() => {
                  const newNodePath = flowTreeNode.getPath();
                  if (isEqual(selectedNodePath, newNodePath)) {
                    setSelectedNodePath([]);
                  } else {
                    setSelectedNodePath(newNodePath);
                  }
                  rd3tProps.toggleNode();
                  setNodesRange(1);
                }}
                onNodePlusClick={() => increaseNodesRange()}
                onNodeMinusClick={() => decreaseNodesRange()}
                onHideNodeClick={() => removeNode(flowTreeNode)}
                onMessagesClick={() => handleLoadMessages(flowTreeNode)}
                isRootBlock={isRootBlock}
                rootElement={rootElement}
              />
            );
          }}
        />
      </div>
    </Paper>
  );
}
