import React, { useEffect, useRef } from 'react';
import { Input } from 'antd';
import './LabelingUnsurePopover.scss';
import { useHotkeys } from 'hooks/useHotkeys';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { Button } from '@mantine/core';

type LabelingUnsurePopoverProps = {
  note: string;
  setNote: (note: string) => void;
  confirmHandler: (note: string) => void;
  cancelHandler: () => void
};

export default function LabelingUnsurePopover({ note, confirmHandler, cancelHandler, setNote }: LabelingUnsurePopoverProps) {
  useHotkeys('enter', () => confirmHandler(note), { enableOnTags: ['TEXTAREA'], keydown: true }, [confirmHandler]);
  useHotkeys('escape', () => cancelHandler(), { enableOnTags: ['TEXTAREA'], keydown: true }, [cancelHandler]);
  const inputRef = useRef<TextAreaRef>();
  // Auto focus textarea and place cursor at the end
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.resizableTextArea.textArea.setSelectionRange(-1, -1);
    }, 0);
  }, []);

  return (
    <div className="labeling-unsure-popover" onKeyUp={(e) => { e.stopPropagation(); }}>
      <p>Comments</p>
      <Input.TextArea
        ref={inputRef}
        rows={4}
        placeholder="Note"
        value={note}
        maxLength={200}
        onChange={(event) => setNote(event.currentTarget.value)}
      />
      <div>
        <Button variant="subtle" onClick={() => cancelHandler()}>Cancel</Button>
        <Button onClick={() => confirmHandler(note)}>Add</Button>
      </div>
    </div>
  );
}
