import { createSlice } from '@reduxjs/toolkit';
import { jobApiFailed, jobApiPending, listJobsSuccess } from './reducers';
import { listJobs } from './asyncThunks';
import { INITIAL_STATE } from './state';

/** Job slice. */
export const jobSlice = createSlice({
  name: 'job',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(listJobs.pending, jobApiPending)
      .addCase(listJobs.rejected, jobApiFailed)
      .addCase(listJobs.fulfilled, listJobsSuccess),
});
