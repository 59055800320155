/* eslint dot-notation: 0 */
import { useState, useCallback, useEffect, useContext } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import moment from 'moment';
import dayjs from 'dayjs';
import { ungzip, gzip } from 'pako';
import CryptoJS from 'crypto-js';
import { getId } from 'common/resourceName';
import { openNotification } from 'components/Notification';
import {
  BatchGetKeyValuesRequest,
  BatchUpsertKeyValuesRequest,
} from '@cresta/web-client/dist/cresta/v1/studio/keyvalue/key_value_service.pb';
import { KeyValueApi } from 'services/keyValueApi';
import {
  CreateDialoguePolicySnapshotRequest,
  CreateDialoguePolicySnapshotResponse,
  GetDialoguePolicySnapshotRequest,
  GetDialoguePolicySnapshotResponse,
  RetrieveLatestSnapshotRequest,
  DialoguePolicySnapshot,
} from '@cresta/web-client/dist/cresta/v1/studio/dialoguepolicysnapshot/dialogue_policy_snapshot_service.pb';
import { DialoguePolicySnapshotApi } from 'services/dialoguePolicySnapshotApi';
import { useApiGet } from 'hooks/network';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { useCustomerParams, useCustomerProfile, useCustomerUsecase } from 'hooks/useCustomerParams';
import { UserContext } from 'context/UserContext';

// Dialogue policy control instance.
export interface DialoguePolicyInstance {
  snapshotId: string;
  sourceCode: string;
  timestamp: string;
  setSourceCode: (v: string) => void;
  config: string;
  setConfig: (v: string) => void;
  loading: boolean;
  // Save the dialogue policy and get the snapshot ID.
  save: () => Promise<string>;
  // Load dialogue policy by the snapshot ID. Returns
  // the original (not the cached one) retrieved snapshot.
  load: (id?: string, disableCache?: boolean) => Promise<DialoguePolicySnapshot>;
}

export function useDraftDialoguePolicy(): DialoguePolicyInstance {
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const { currentConfig } = useContext(CustomerConfigContext);
  const currentUser = useContext(UserContext);
  // Snapshot ID.
  const [snapshotId, setSnapshotId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  // DP source code in Python.
  const [sourceCode, setSourceCode] = useState<string>('');
  const [debouncedSourceCode] = useDebouncedValue<string>(sourceCode, 1000);
  // DP config in YAML.
  const [config, setConfig] = useState<string>('');
  const [debouncedConfig] = useDebouncedValue<string>(config, 1000);
  const [timestamp, setTimestamp] = useState<string>('');
  const apiGet = useApiGet(false);

  // Cache the code/config into the local storage.
  useEffect(() => {
    if (debouncedSourceCode || debouncedConfig) {
      const t = moment().format('MM/DD/YYYY h:mma');
      const dp: DialoguePolicy = {
        id: snapshotId,
        sourceCode: debouncedSourceCode,
        config: debouncedConfig,
        timestamp: t,
      };
      const prevDP = getCachedDialoguePolicy(path);
      if (dp.sourceCode !== prevDP?.sourceCode || dp.config !== prevDP?.config) {
        setTimestamp(t);
        setCachedDialoguePolicy(path, dp);
      }
    }
  }, [debouncedSourceCode, debouncedConfig, path]);

  const getSnapshot = useCallback(async (id?: string, disableCache?: boolean) => {
    let snapshot: DialoguePolicySnapshot = {};
    setLoading(true);
    setSourceCode('');
    setConfig('');
    setTimestamp('');
    if (id) {
      const request: GetDialoguePolicySnapshotRequest = {
        name: `${customerProfile}/dialoguePolicySnapshots/${id}`,
        usecaseId,
        languageCode,
      };
      try {
        const response = await DialoguePolicySnapshotApi.getDialoguePolicySnapshot(request);
        snapshot = response?.dialoguePolicySnapshot;
      } catch (err) {
        setLoading(false);
        throw err;
      }
    } else {
      const request: RetrieveLatestSnapshotRequest = {
        profile: customerProfile,
        filter: {
          creatorUserId: currentUser?.id,
          usecaseId,
          languageCode,
        },
      };
      try {
        const response = await DialoguePolicySnapshotApi.retrieveLatestSnapshot(request);
        snapshot = response?.dialoguePolicySnapshot;
      } catch (err) {
        if (err['status'] !== 'NOT_FOUND') {
          setLoading(false);
          throw err;
        }
      }
    }
    setLoading(false);
    const newSnapshotId = snapshot?.name?.length ? getId('dialoguePolicySnapshot', snapshot.name) : '';
    setSnapshotId(newSnapshotId);
    // Check cache freshness.
    const cachedDP = getCachedDialoguePolicy(path);
    if (disableCache || !cachedDP || cachedDP.id !== newSnapshotId) { // Disable cache, no cache or stale cache.
      if (snapshot?.sourceCode) {
        setSourceCode(decompress(snapshot?.sourceCode));
      }
      if (snapshot?.config) {
        setConfig(decompress(snapshot?.config));
      }
      setTimestamp(dayjs(snapshot?.createTime).format('MM/DD/YYYY h:mma'));
    } else { // Fresh cache.
      setSourceCode(cachedDP.sourceCode);
      setConfig(cachedDP.config);
      setTimestamp(cachedDP.timestamp);
    }
    return snapshot;
  }, [path, currentUser?.id]);

  const load = useCallback(async (id?: string, disableCache?: boolean) => {
    let snapshot: DialoguePolicySnapshot = {};
    try {
      snapshot = await getSnapshot(id, disableCache);
    } catch (err) {
      openNotification('error', 'Failed to get draft dialogue policy', undefined, err);
    }
    return snapshot;
  }, [getSnapshot]);

  // Create snapshot in backend.
  const save = useCallback(async () => {
    const request: CreateDialoguePolicySnapshotRequest = {
      parent: customerProfile,
      dialoguePolicySnapshotId: '',
      dialoguePolicySnapshot: {
        sourceCode: compress(sourceCode),
        config: compress(config),
        sha256: generateSHA256(`${sourceCode}${config}`),
        creator: currentUser?.name,
        usecase,
        languageCode,
      },
    };
    let response: CreateDialoguePolicySnapshotResponse = {};
    try {
      response = await DialoguePolicySnapshotApi.createDialoguePolicySnapshot(request);
    } catch (err) {
      openNotification('error', 'Failed to save dialogue policy', undefined, err);
      return '';
    }
    deleteCachedDialoguePolicy(path);
    const newSnapshotId = getId('dialoguePolicySnapshot', response.dialoguePolicySnapshot?.name);
    setSnapshotId(newSnapshotId);

    // Pre-compile the dialogue policy and throw error if failed.
    const validateAPIPath = `${currentConfig?.customerShortName}/dp/${newSnapshotId}/validate`;
    const validation = await apiGet(validateAPIPath);
    if (validation?.status !== 'ok') {
      openNotification('error', 'Error', validation?.message);
      throw new Error(validation?.message);
    }
    return newSnapshotId;
  }, [path, currentConfig, currentUser?.name, sourceCode, config]);

  return {
    snapshotId,
    sourceCode,
    setSourceCode,
    timestamp,
    config,
    setConfig,
    loading,
    save,
    load,
  };
}

type DecompressedDialoguePolicySnapshot = DialoguePolicySnapshot & {
  decompressedSourceCode?: string;
  decompressedConfig?: string;
  formatedCreateTime?: string;
};

// Return dialogue policy in a specific branch.
export function useDialoguePolicyInBranch(branch: string): [
  snapshot: DecompressedDialoguePolicySnapshot,
  loading: boolean,
  // Save the dialogue policy and get the snapshot ID.
  save: (sourceCode: string, config: string) => Promise<string>,
] {
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const currentUser = useContext(UserContext);
  // DialoguePolicySnapshot with decompressed sourceCode and config.
  const [snapshot, setSnapshot] = useState<DecompressedDialoguePolicySnapshot>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getSnapshot = useCallback(async () => {
    const request: RetrieveLatestSnapshotRequest = {
      profile: customerProfile,
      filter: {
        branch,
        usecaseId,
        languageCode,
      },
    };
    let snapshot: DecompressedDialoguePolicySnapshot = {};
    setLoading(true);
    setSnapshot(null);
    try {
      const response = await DialoguePolicySnapshotApi.retrieveLatestSnapshot(request);
      snapshot = response?.dialoguePolicySnapshot;
    } catch (err) {
      if (err['status'] !== 'NOT_FOUND') {
        setLoading(false);
        throw err;
      }
    }
    if (snapshot?.sourceCode) {
      snapshot.decompressedSourceCode = decompress(snapshot.sourceCode);
    }
    if (snapshot?.config) {
      snapshot.decompressedConfig = decompress(snapshot.config);
    }
    if (snapshot?.createTime) {
      snapshot.formatedCreateTime = dayjs(snapshot?.createTime).format('MM/DD/YYYY h:mma');
    }
    setLoading(false);
    setSnapshot(snapshot);
  }, [path, branch]);

  // Update snapshot when switching profile or branch.
  useEffect(() => {
    try {
      getSnapshot();
    } catch (err) {
      openNotification('error', `Failed to get dialogue policy in branch ${branch}`, undefined, err);
    }
  }, [customerProfile, branch]);

  // Create snapshot in this branch.
  const save = useCallback(async (sourceCode: string, config: string) => {
    if (!sourceCode || !config) {
      throw new Error('Source code and config are required.');
    }
    const request: CreateDialoguePolicySnapshotRequest = {
      parent: customerProfile,
      dialoguePolicySnapshotId: '',
      dialoguePolicySnapshot: {
        sourceCode: compress(sourceCode),
        config: compress(config),
        sha256: generateSHA256(`${sourceCode}${config}`),
        creator: currentUser?.name,
        branch,
        usecase,
        languageCode,
      },
    };
    let response: CreateDialoguePolicySnapshotResponse = {};
    setLoading(true);
    try {
      response = await DialoguePolicySnapshotApi.createDialoguePolicySnapshot(request);
    } catch (err) {
      openNotification('error', `Failed to save dialogue policy into branch ${branch}`, undefined, err);
      return '';
    } finally {
      setLoading(false);
    }
    deleteCachedDialoguePolicy(path);
    const newSnapshotId = getId('dialoguePolicySnapshot', response.dialoguePolicySnapshot?.name);
    const newSnapshot: DecompressedDialoguePolicySnapshot = {
      ...response.dialoguePolicySnapshot,
      decompressedSourceCode: sourceCode,
      decompressedConfig: config,
    };
    setSnapshot(newSnapshot);
    return newSnapshotId;
  }, [path, currentUser?.name, branch]);

  return [snapshot, loading, save];
}

// Return deployed dialogue policy in prod.
export function useDeployedDialoguePolicy(): [sourceCode: string, config: string, loading: boolean, buildTime: string] {
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode } = useCustomerParams();
  // DP source code in Python.
  const [sourceCode, setSourceCode] = useState<string>('');
  // DP config in YAML.
  const [config, setConfig] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<string>('');

  const getDeployedSnapshot = useCallback(async () => {
    const request: GetDialoguePolicySnapshotRequest = {
      name: `${customerProfile}/dialoguePolicySnapshots/deployed`,
      usecaseId,
      languageCode,
    };
    let response: GetDialoguePolicySnapshotResponse = {};
    setLoading(true);
    setSourceCode('');
    setConfig('');
    setTimestamp('');
    try {
      response = await DialoguePolicySnapshotApi.getDialoguePolicySnapshot(request);
    } catch (err) {
      if (err['status'] !== 'NOT_FOUND') {
        setLoading(false);
        throw err;
      }
    }
    if (response.dialoguePolicySnapshot?.sourceCode) {
      setSourceCode(decompress(response.dialoguePolicySnapshot?.sourceCode));
    }
    if (response.dialoguePolicySnapshot?.config) {
      setConfig(decompress(response.dialoguePolicySnapshot?.config));
    }
    setLoading(false);
    setTimestamp(dayjs(response.dialoguePolicySnapshot?.buildTime).format('MM/DD/YYYY h:mma'));
  }, [customerProfile, usecaseId, languageCode]);

  // Update snapshot when switching profile.
  useEffect(() => {
    try {
      getDeployedSnapshot();
    } catch (err) {
      openNotification('error', 'Failed to get deployed dialogue policy', undefined, err);
    }
  }, [customerProfile]);

  return [sourceCode, config, loading, timestamp];
}

const DIALOGUE_POLICY_DOCS_KEY = 'DIALOGUE_POLICY_DOCUMENTS';
// Doc links for dialogue policy design.
export interface DialoguePolicyDocs {
  notionLink?: string;
  lucidChartLink?: string;
}

export function useDialoguePolicyDocs(): [DialoguePolicyDocs, (docs: DialoguePolicyDocs) => void] {
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode } = useCustomerParams();
  const [docs, setDocs] = useState<DialoguePolicyDocs>({});

  const getDocs = useCallback(async () => {
    const request: BatchGetKeyValuesRequest = {
      parent: customerProfile,
      names: [`${customerProfile}/keyValues/${usecaseId}-${languageCode}-${DIALOGUE_POLICY_DOCS_KEY}`],
    };
    const response = await KeyValueApi.batchGetKeyValues(request);
    if (!response?.keyValues?.length) {
      setDocs({});
    } else {
      setDocs(response.keyValues[0].value as DialoguePolicyDocs);
    }
  }, [customerProfile, usecaseId, languageCode]);

  useEffect(() => {
    try {
      getDocs();
    } catch (err) {
      openNotification('error', 'Failed to get doc links', undefined, err);
    }
  }, [customerProfile]);

  const updateDocs = useCallback((docs: DialoguePolicyDocs) => {
    setDocs(docs);
    const request: BatchUpsertKeyValuesRequest = {
      parent: customerProfile,
      keyValues: [{
        name: `${customerProfile}/keyValues/${DIALOGUE_POLICY_DOCS_KEY}`,
        value: docs,
      }],
    };
    KeyValueApi.batchUpsertKeyValues(request).catch((err) => {
      openNotification('error', 'Failed to save doc links', undefined, err);
    });
  }, [customerProfile]);

  return [docs, updateDocs];
}

function decompress(data: Uint8Array): string {
  // The conversion is needed because Uint8Array generated from proto is actually base64 encoded.
  const ungzipedData = ungzip(Uint8Array.from(atob(data as unknown as string), (c) => c.charCodeAt(0)));
  return new TextDecoder().decode(ungzipedData);
}

function compress(data: string): Uint8Array {
  return Uint8Array.from(gzip(data));
}

function generateSHA256(data: string): Uint8Array {
  const hex = CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
  return Uint8Array.from(hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
}

const DIALOGUE_POLICY_CACHE_KEY = 'dialoguePolicyCache';

// Cached DP in the local storage.
interface DialoguePolicy {
  // UUID of the base DP snapshot.
  id: string;
  // Python code.
  sourceCode: string;
  // YAML config.
  config: string;
  // Timestamp when the cache is created.
  timestamp: string;
}

type DialoguePolicyCache = { [key: string]: DialoguePolicy }

function getDialoguePolicyCache(): DialoguePolicyCache {
  const value = localStorage.getItem(DIALOGUE_POLICY_CACHE_KEY);
  if (!value) {
    return {};
  }
  try {
    const dpCache = JSON.parse(value);
    return dpCache;
  } catch (e) {
    return {};
  }
}

function setDialoguePolicyCache(dp: DialoguePolicyCache) {
  localStorage.setItem(DIALOGUE_POLICY_CACHE_KEY, JSON.stringify(dp));
}

function getCachedDialoguePolicy(key: string): DialoguePolicy | null {
  const cache = getDialoguePolicyCache();
  return cache[key] || null;
}

function deleteCachedDialoguePolicy(key: string) {
  const cache = getDialoguePolicyCache();
  delete cache[key];
  setDialoguePolicyCache(cache);
}

function setCachedDialoguePolicy(key: string, dp: DialoguePolicy) {
  const cache = getDialoguePolicyCache();
  cache[key] = dp;
  setDialoguePolicyCache(cache);
}
