import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { ApiStatus } from 'store/types';

/** Concept state. */
export interface State {
  demoConcepts: {[type: string]: Concept[]},
  sharedConcepts: {[type: string]: Concept[]},
  concepts: {[type: string]: Concept[]},
  status: ApiStatus,
}

/** Initial concept state. */
export const INITIAL_STATE: State = {
  demoConcepts: {},
  sharedConcepts: {},
  concepts: {},
  status: 'idle',
};
