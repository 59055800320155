import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { updateConcept } from 'store/concept/asyncThunks';
import { toRequestUpdateMask } from 'utils';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { ConceptApi } from 'services/conceptApi';
import { TagTagType, UpdateConceptRequest } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConceptsMapFactory } from 'store/concept/selectors';
import { UserContext } from 'context/UserContext';
import { StagesContext } from 'pages/AnalysisWorkshop/TaggingPage/StagesProvider';
import { TagList as TagListComponent } from './TagList';
import { UITagType } from '../../..';

interface TagListProps {
  highlightedTagId: string
  initializing?: boolean
  heading: string
  tags: UITagType[]
  removeTagIdFromTask: (tagId: string) => Promise<void>
  onTagClick: (tag: any) => void
  conceptIdsOnCurrentMessage: string[]
  children?: React.ReactNode
}

export const getStageEndIdByConceptTitle = (stageTitle: string, conceptsMap) => {
  let stageEndId: string;
  for (const [key, value] of conceptsMap.entries()) {
    if (value.conceptTitle === `${stageTitle}.end`) {
      stageEndId = key;
    }
  }
  return stageEndId;
};

export const TagList = (props: TagListProps) => {
  const { stageIdToBeAdded } = useContext(StagesContext);
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const conceptsMap = useSelector(selectAllConceptsMapFactory);
  const currentUser = useContext(UserContext);

  const getConceptNameFromId = (conceptId: string) => `${customerProfile}/concepts/${conceptId}`;
  const updateConceptTitleForConceptName = async (conceptName: string, conceptTitle: string) => {
    const updatedConcept: UpdateConceptRequest = {
      concept: {
        name: conceptName,
        conceptTitle,
      },
      userResourceName: currentUser?.name,
      updateMask: toRequestUpdateMask(['conceptTitle']),
    };
    try {
      await dispatch(updateConcept(updatedConcept));
    } catch (e) {
      console.error(e);
    }
  };

  const updateConceptTitle = async (id: string, conceptTitle: string) => {
    const conceptName = conceptsMap.get(id).name;
    const prevStageName = conceptsMap.get(id).conceptTitle.replace('.begin', '');

    const res = await ConceptApi.getConcept({
      name: conceptName,
    });
    const type = res?.concept?.tag?.tagType;
    if (type === TagTagType.STAGE_BEGIN) {
      const stageBeginId = id;
      const stageBaginConceptTitle = `${conceptTitle}.begin`;

      const stageEndId = getStageEndIdByConceptTitle(prevStageName, conceptsMap);
      const stageEndConceptTitle = `${conceptTitle}.end`;

      await Promise.all([updateConceptTitleForConceptName(getConceptNameFromId(stageBeginId), stageBaginConceptTitle), updateConceptTitleForConceptName(getConceptNameFromId(stageEndId), stageEndConceptTitle)]);
    } else {
      await updateConceptTitleForConceptName(conceptName, conceptTitle);
    }
  };

  return (
    <TagListComponent
      updateConceptTitle={updateConceptTitle}
      subtitle={props.heading === 'Stages' && stageIdToBeAdded && 'Mark end of selected stage'}
      {...props}
    />
  );
};
