/* eslint-disable no-await-in-loop */
import { Annotation, BinaryValueValue, MessageRawDataConfusionType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Concept, ConceptConceptType, IntentIntentType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Prediction, SearchPredictionsRequest } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { DerivedLabelingTask, Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import { Button } from '@mantine/core';
import Table, { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import ConceptTag from 'components/ConceptTag';
import ConversationPreview from 'components/ConversationPreview';
import Loading from 'components/Loading';
import { openNotification } from 'components/Notification';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import useUrlParam from 'hooks/useUrlParam';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PredictionApi } from 'services/predictionApi';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { listConversationMessages } from 'store/conversation/asyncThunks';
import { selectApiStatus as selectConversationApiStatus } from 'store/conversation/selectors';
import { selectConceptsMapFactory } from 'store/concept/selectors';
import { selectAnnotationBundles, selectApiStatus as selectTaskApiStatus, selectDerivedQaTasks } from 'store/labelingTask/selectors';
import { ApiStatus } from 'store/types';
import { ConfustionTypeFilter, PolicyDebugOutput } from 'types';
import { LabelingTaskService } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { StudioApi } from 'services/studioApi';
import styles from '../styles.module.scss';

interface UtterancePolicyTableProps {
  searchTerm: string;
  taskId: string;
  conceptId: string;
}

const LABEL_BUTTON_TEXT_MAP = new Map<MessageRawDataConfusionType, string>(
  [
    [MessageRawDataConfusionType.TRUE_POSITIVE, 'Correct'],
    [MessageRawDataConfusionType.FALSE_NEGATIVE, 'Incorrect: FN'],
    [MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE, 'Incorrect: FP (rules)'],
    [MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER, 'Incorrect: FP (triggers)'],
  ],
);

export default function UtterancePolicyTable({ searchTerm, taskId, conceptId }: UtterancePolicyTableProps) {
  const dispatch = useDispatch();
  const taskApiStatus = useSelector<ApiStatus>(selectTaskApiStatus);
  const annotationBundles = useSelector<SearchAnnotationsResponseAnnotationBundle[]>(
    selectAnnotationBundles,
  );
  const customerProfile = useCustomerProfile();
  // DISABLED: (temporarily)
  // const usecase = useCustomerUsecase();
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [loadingPredictions, setLoadingPredictions] = useState(true);
  const [confusionTypeFilter] = useUrlParam<ConfustionTypeFilter | ''>('confusionTypeFilter', '');

  // For conversation preview
  const [showConversation, setShowConversation] = useState(false);
  // const messages = useSelector<Message[]>(selectConversationMessages).map(toMessage);
  const [selectedRowRecord, setSelectedRowRecord] = useState<SearchAnnotationsResponseAnnotationBundle>();
  const conversationApiStatus = useSelector<ApiStatus>(selectConversationApiStatus);
  // We need messages from labeling items because only they have policyQaContext
  const [messages, setMessages] = useState<Message[]>([]);

  const predictionsMap = useMemo(() => new Map<string, Prediction>(
    predictions.map((prediction) => [getId('prediction', prediction.name), prediction]),
  ), [predictions]);

  // Completed, non-dual tasks.
  const tasks = useSelector<DerivedLabelingTask[]>(selectDerivedQaTasks)
    .filter((t) => t.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED);
  const task = tasks.find((t) => getId('labelingTask', t.labelingTask.name) === taskId);

  const conceptsMap = useSelector<Map<string, Concept>>(selectConceptsMapFactory(ConceptConceptType.INTENT));

  // Filter annotations by confusion type
  const filteredAnnotationBundles: SearchAnnotationsResponseAnnotationBundle[] = useMemo(() => annotationBundles.filter((bundle) => {
    const { confusionType } = bundle.annotation.rawData.messageRawData;

    if (bundle.annotation.value.binaryValue.conceptId !== conceptId) {
      return false;
    }
    if (confusionTypeFilter) {
      if (confusionTypeFilter === 'CORRECT' && confusionType !== MessageRawDataConfusionType.TRUE_POSITIVE) {
        return false;
      } else if (confusionTypeFilter === 'INCORRECT' && !([
        MessageRawDataConfusionType.FALSE_NEGATIVE,
        MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE,
        MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER,
      ].includes(confusionType))) {
        return false;
      } else if (confusionTypeFilter === 'FP_RULES' && confusionType !== MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE) {
        return false;
      } else if (confusionTypeFilter === 'FP_TRIGGERS' && confusionType !== MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER) {
        return false;
      } else if (confusionTypeFilter === 'TP' && confusionType !== MessageRawDataConfusionType.TRUE_POSITIVE) {
        return false;
      } else if (confusionTypeFilter === 'FN' && confusionType !== MessageRawDataConfusionType.FALSE_NEGATIVE) {
        return false;
      } else if (confusionTypeFilter === 'TN' && confusionType !== MessageRawDataConfusionType.TRUE_NEGATIVE) {
        return false;
      }
    }
    return true;
  }), [annotationBundles, predictionsMap, conceptId, confusionTypeFilter]);

  // Get policy debug output object
  const getPredictionPolicyDebugOutput = useCallback((predictionId: string) => {
    const prediction = predictionsMap.get(predictionId);
    return (prediction?.moment?.intentPayload?.dialoguePolicyDebugOutput as PolicyDebugOutput);
  }, [predictionsMap]);

  const annotations = useMemo(() => annotationBundles.map((bundle) => bundle.annotation), [annotationBundles]);
  const conversationPosition = useMemo(
    () =>
      selectedRowRecord?.annotation.rawData.conversationPositionNumber
    , [selectedRowRecord],
  );
  const messageAnnotationsMap = useMemo(() => {
    const map = new Map<string, Annotation[]>();
    annotations.forEach((annotation) => {
      const messageId = annotation.rawData.messageRawData.v2MessageId;
      const messageAnnotations = map.get(messageId) || [];
      map.set(messageId, [...messageAnnotations, annotation]);
    });
    return map;
  }, [annotations]);

  // Fetch predictions
  const fetchLabelingItems = async () => {
    if (!task || conversationPosition === undefined) return;

    try {
      const response = await LabelingTaskService.FetchLabelingItems({
        taskName: task.labelingTask.name,
        conversationNumberStart: conversationPosition,
        conversationNumberEnd: conversationPosition + 1,
      }, StudioApi.getHeaders().initReq);
      setMessages(response.conversationLabelingItems?.[0]?.messagesAndContexts || []);
    } catch (error) {
      openNotification('error', 'Labeling items failed to load', '', error);
    }
  };

  // Fetch predictions
  const fetchPredictions = async () => {
    setLoadingPredictions(true);
    const predictionIds = annotations.filter((annotation) => annotation.rawData.messageRawData.predictionId)
      .map((annotation) => annotation.rawData.messageRawData.predictionId);
    try {
      const request: SearchPredictionsRequest = {
        resource: `${customerProfile}/conversations/-`,
        pageSize: 100,
      };
      const predictions: Prediction[] = [];
      for (let start = 0; start < predictionIds.length; start += 100) {
        const end = Math.min(start + 100, predictionIds.length);
        request.filter = {
          predictionIds: predictionIds.slice(start, end),
          // DISABLED (temporarily)
          // usecases: [usecase],
        };
        request.pageToken = '';
        while (true) {
          const response = await PredictionApi.searchPredictions(request);
          predictions.push(...response.predictions);
          if (!response.nextPageToken) {
            break;
          }
          request.pageToken = response.nextPageToken;
        }
      }
      setPredictions(predictions);
    } catch (error) {
      openNotification('error', 'Predictions failed to load', '', error);
    } finally {
      setLoadingPredictions(false);
    }
  };

  useEffect(() => {
    if (annotationBundles.length) {
      fetchPredictions();
    } else {
      // No annotations, show empty data in table
      setLoadingPredictions(false);
    }
  }, [annotationBundles]);

  useEffect(() => {
    fetchLabelingItems();
  }, [conversationPosition]);

  const perMessageColumns: ColumnsType<SearchAnnotationsResponseAnnotationBundle> = [
    {
      title: 'Utterance',
      dataIndex: ['textMessage'],
      key: 'message',
      width: '50%',
    },
    {
      title: 'Result',
      dataIndex: ['annotation', 'rawData', 'messageRawData', 'confusionType'],
      key: 'prediction',
      sorter: ((a, b) => a.annotation.rawData.messageRawData.confusionType.localeCompare(b.annotation.rawData.messageRawData.confusionType)),
      render: (value, record) => (
        <div>
          <Button
            className={classNames([styles.labelButton, value === MessageRawDataConfusionType.TRUE_POSITIVE ? styles.positive : styles.negative])}
          >
            {LABEL_BUTTON_TEXT_MAP.get(value)}
          </Button>
        </div>
      ),
    },
    {
      title: 'Note',
      key: 'note',
      render: (value, record) => record.annotation.value.binaryValue.note || 'N/A',
    },
    {
      title: 'Potential Reason',
      key: 'potentialReason',
      width: 300,
      render: (value, record) => {
        const { confusionType } = record.annotation.rawData.messageRawData;
        if (confusionType === MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER) {
          return (
            <div className={styles.reasonDebugOutput}>
              <p><b>Trigger</b></p>
              <ConceptTag value={getPredictionPolicyDebugOutput(record.annotation.rawData.messageRawData.predictionId)?.trigger_intent || ''} />
            </div>
          );
        }
        const debugOutput = getPredictionPolicyDebugOutput(record.annotation.rawData.messageRawData.predictionId);
        if (debugOutput) {
          return (
            <div className={styles.reasonDebugOutput}>
              <p><b>Rules</b></p>
              <ol>
                <li>
                  <p>{debugOutput.rule_evaluation?.edge_as_str}</p>
                  <p><b>Conditions:</b></p>
                  <ol>
                    {debugOutput.rule_evaluation?.conditions.map((rule, i) => (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className={classNames([
                          styles.policyRule,
                          { [styles.ruleFalse]: !rule.value },
                        ])}
                      >{rule?.condition_as_str}
                      </li>
                    ))}
                  </ol>
                </li>
              </ol>
              <p><b>Filter</b></p>
              <ol>
                {debugOutput.filter_evaluation?.conditions.map((condition, i) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className={classNames([
                      styles.policyRule,
                      { [styles.ruleFalse]: !condition.value },
                    ])}
                  >{condition?.condition_as_str}
                  </li>
                ))}
              </ol>
            </div>
          );
        }
        return 'N/A';
      },
    },
  ];

  // Message from selected table row
  const focusedMessage = useMemo(() => messages.find((message) => {
    if (!selectedRowRecord) return null;
    const messageId = message?.v2MessageId;
    const { messageRawData } = selectedRowRecord?.annotation.rawData;
    return messageId === messageRawData.v2MessageId;
  }), [messages, selectedRowRecord]);

  const getConceptTitle = useCallback((conceptId: string) => conceptsMap.get(conceptId)?.conceptTitle, [conceptsMap]);
  const getConceptRole = useCallback((conceptId: string): 'agent' | 'visitor' => {
    const concept = conceptsMap.get(conceptId);
    return concept?.intent.intentType === IntentIntentType.AGENT_INTENT ? 'agent' : 'visitor';
  }, [conceptsMap]);

  // This is for conversation preview to show policy QA SDX and intents on messages
  const customMessageTags = (message: Message) => {
    const messageId = message?.v2MessageId;

    const messageAnnotations = messageAnnotationsMap.get(messageId) || [];
    const nextIntentPredictionIds = message?.selectionContext?.policyQaContext?.nextIntentPredictionIds || [];
    const nextIntentConceptIds = message?.selectionContext?.policyQaContext?.nextIntentConceptIds || [];
    const currentIntentIds = message.selectionContext?.policyQaContext?.currentIntentConceptIds || [];
    const currentIntentPredictionIds = message.selectionContext?.policyQaContext?.currentIntentPredictionIds || [];

    const currentIntentNameRoleTuples = currentIntentIds.map((conceptId) => {
      const tuple: [string, 'agent' | 'visitor'] = [getConceptTitle(conceptId), getConceptRole(conceptId)];
      return tuple;
    });
    const incorrectCurrentIntentAnnotations = annotations.filter((annotation) =>
      currentIntentPredictionIds.includes(annotation.rawData.messageRawData.predictionId));

    const truePositives: Annotation[] = [];
    const falseNegatives: Annotation[] = [];
    const falsePositives: Annotation[] = [];
    messageAnnotations.forEach((annotation) => {
      if (annotation.rawData.messageRawData.confusionType === MessageRawDataConfusionType.TRUE_POSITIVE) {
        truePositives.push(annotation);
      }
      if (annotation.rawData.messageRawData.confusionType === MessageRawDataConfusionType.FALSE_NEGATIVE) {
        falseNegatives.push(annotation);
      }
      if ([
        MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE,
        MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER,
      ].includes(annotation.rawData.messageRawData.confusionType)) {
        falsePositives.push(annotation);
      }
    });

    if (currentIntentIds?.length || nextIntentPredictionIds?.length) {
      return (
        <div className="labeling-items-display">
          <div className="policy-qa-message-intents">
            <span>Current: {currentIntentIds?.length === 0 && <span>None</span>}</span>
            {incorrectCurrentIntentAnnotations?.length > 0 ? (
              <>
                {incorrectCurrentIntentAnnotations.map((annotation) => (
                  <ConceptTag
                    key={annotation.name}
                    role={getConceptRole(annotation.value.binaryValue.conceptId)}
                    value={getConceptTitle(annotation.value.binaryValue.conceptId)}
                    color={annotation.value.binaryValue.value === BinaryValueValue.VALUE_SKIP ? 'green' : 'red'}
                  />
                ))}
              </>
            ) : (
              <>
                {currentIntentNameRoleTuples.map(([intentName, intentRole]) => (
                  <ConceptTag
                    key={intentName}
                    role={intentRole}
                    value={intentName}
                  />
                ))}
              </>
            )}
            <div>
              <span>SDX: {nextIntentPredictionIds?.length === 0
            && (<span className={falseNegatives?.length > 0 ? 'policy-qa-none-incorrect' : ''}>None</span>)}
              </span>
              {nextIntentPredictionIds.map((nextIntentPredictionId, i) => {
                const nextIntentConceptId = nextIntentConceptIds[i];
                const isFalse = falsePositives.map((annotation) => annotation.value.binaryValue.conceptId).includes(nextIntentConceptId);
                const isTrue = truePositives.map((annotation) => annotation.value.binaryValue.conceptId).includes(nextIntentConceptId);
                let conceptColor: 'blue' | 'green' | 'red' = 'blue';
                if (isTrue) { conceptColor = 'green'; }
                if (isFalse) { conceptColor = 'red'; }
                return (
                  <ConceptTag
                    key={nextIntentPredictionId}
                    value={getConceptTitle(nextIntentConceptId)}
                    role={getConceptRole(nextIntentConceptId)}
                    color={conceptColor}
                  />
                );
              })}
              {falseNegatives.map((annotation) => (
                <ConceptTag
                  key={annotation.name}
                  value={getConceptTitle(annotation.value.binaryValue.conceptId)}
                  role={getConceptRole(annotation.value.binaryValue.conceptId)}
                  color="green"
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Table
        rowKey={(row) => row.annotation.name}
        loading={{
          spinning: taskApiStatus === 'loading' || loadingPredictions,
          indicator: <Loading />,
        }}
        className={classNames([styles.summaryTable, styles.policyTable])}
        columns={perMessageColumns}
        dataSource={filteredAnnotationBundles}
        onRow={(record) => ({
          onClick: (e) => {
            if (!record.annotation.rawData.messageRawData.v2MessageId) return;
            setSelectedRowRecord(record);
            setShowConversation(true);
            const conversationName = `${customerProfile}/conversations/${record.annotation.rawData.messageRawData.v2ConversationId}`;
            dispatch(listConversationMessages(conversationName));
          },
        })}
      />
      <ConversationPreview
        messages={messages}
        focusedMessageId={focusedMessage?.v2MessageId}
        onClose={() => setShowConversation(false)}
        showConversation={showConversation}
        loading={conversationApiStatus === 'loading'}
        customMessageTags={customMessageTags}
      />
    </>
  );
}
