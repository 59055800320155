import React from 'react';
import { Overlay, Stack, Text, Tooltip } from '@mantine/core';
import { ConversationDurationSlider } from 'components/ConversationDurationSlider';
import { truncateMiddle } from 'utils';
import { DatePickerInput } from '@mantine/dates';
import * as SetCard from './components';
import { SetData } from '..';

interface ExistingCardProps {
  setData: SetData;
  onRemove: () => void;
}

const MINIMUM_SETNAME_LENGTH = 16;

export const ExistingCard = ({ setData = { id: '', name: '', convoQuantity: 80, createTime: '' }, onRemove = () => {} }: ExistingCardProps) => (
  <SetCard.Container>
    <Stack>
      <Text size="sm">Conversation Set Name</Text>
      <Tooltip label={setData.name} disabled={setData.name.length <= MINIMUM_SETNAME_LENGTH}>
        <Text mb="lg">{truncateMiddle(setData.name, MINIMUM_SETNAME_LENGTH, 10)}</Text>
      </Tooltip>
    </Stack>
    <Stack style={{ position: 'relative', flex: 1 }}>
      <Overlay opacity={0.7} color="#fff" zIndex={5} />
      <SetCard.ConversationQuantityInput
        value={setData.convoQuantity}
      />
      <SetCard.AgentSelect
        data={[]}
        value={[]}
        handleAgentChange={() => []}
      />
      <DatePickerInput
        type="range"
        label="Date Range"
        placeholder="Pick dates range"
      />
      <ConversationDurationSlider />
    </Stack>
    <div>
      <SetCard.ActionButton color="red" onClick={onRemove} fullWidth>Remove</SetCard.ActionButton>
    </div>
  </SetCard.Container>
);
