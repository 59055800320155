import React, { ReactElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { User as ApiUser } from '@cresta/web-client/dist/cresta/v1/studio/users/users.pb';
import { List, Tooltip, UnstyledButton } from '@mantine/core';
import {
  ListSimulationTasksRequest,
  ListSimulationTasksResponse,
  ModelInputs,
  SimulationTask,
  SimulationTaskDataSimulationType as SimulationType,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/simulationtask/simulation_task_service.pb';
import { selectUsers } from 'store/user/selectors';
import { User } from 'types';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import moment from 'moment';
import CopyableValue from 'components/CopyableValue';
import Loading from 'components/Loading';
import { toTitleCase, truncateMiddle } from 'utils';
import UserTag from 'components/UserTag';
import { useSelector } from 'hooks/reduxHooks';
import { getId } from 'common/resourceName';
import { SimulationTaskApi } from 'services/simulationTaskApi';
import { useListResources } from 'hooks/useListResources';
import { useCustomerParams } from 'hooks/useCustomerParams';
import { SimulatorHistoryTableRow } from './rowTypes';
import styles from './SimulatorHistory.module.scss';

export const SimulatorHistory = () => {
  const navigate = useNavigate();
  const users = useSelector<ApiUser[]>(selectUsers).map((user: ApiUser) => {
    const u: User = {
      id: getId('user', user.name),
      email: user.email,
      full_name: user.fullName,
      role: user.role.toString(),
    };
    return u;
  });
  const { usecaseId, languageCode, path } = useCustomerParams();
  const [request] = useState<ListSimulationTasksRequest>({
    filter: { usecaseId, languageCode },
  });
  const [setPageInfo, data, loading] = useListResources<
    SimulationTask,
    ListSimulationTasksRequest,
    ListSimulationTasksResponse
  >(SimulationTaskApi.listSimulationTasks, request, 'simulationTasks');
  const rows = useMemo(() => data.map((t) => convertToRow(t)), [data]);

  const handleOpen = (taskId: string) => {
    navigate(`/${path}/simulator/simulation?taskId=${taskId}`);
  };
  const columns: ColumnsType<SimulatorHistoryTableRow> = [
    {
      title: '#',
      dataIndex: 'taskId',
      key: 'taskId',
      width: '10%',
      render: (taskId, record) => (
        <div>
          <CopyableValue
            displayValue={truncateMiddle(taskId, 0, 6)}
            copiedValue={taskId}
          />
        </div>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'createTime',
      key: 'createTime',
      width: '10%',
      render: (createTime, record) => (
        <div>
          {moment(createTime).format('MM/DD/YYYY h:mmA')}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status, record) => {
        if (status === TaskStatus.TASK_ERROR) {
          return (
            <Tooltip
              multiline
              width={600}
              label={record.errMessage}
            >
              <div>
                <Badge status="error" />
                Failed
                <CopyableValue
                  displayValue="|"
                  copiedValue={record.errMessage}
                />
              </div>
            </Tooltip>
          );
        } else {
          return (
            <div>
              <Badge status={status === TaskStatus.TASK_COMPLETED ? 'success' : 'processing'} />
              {toTitleCase(status?.substring(5) || '')}
            </div>
          );
        }
      },
    },
    {
      title: 'Type',
      dataIndex: 'simulationType',
      key: 'simulationType',
      width: '10%',
      render: (t: SimulationType) => toTitleCase(t),
    },
    {
      title: 'Author',
      dataIndex: 'assigneeUserId',
      key: 'assigneeUserId',
      width: '10%',
      render: (assigneeUserId: string) => <UserTag name={users.find((item) => item.id === assigneeUserId)?.full_name || 'Unknown'} />,
    },
    {
      title: 'Number of convos',
      dataIndex: 'convos',
      key: 'convos',
      width: '10%',
    },
    {
      title: 'Models used',
      dataIndex: 'models',
      key: 'models',
      width: '30%',
      render: (models) => {
        if (models.length === 1) {
          return renderModelInputs(models[0].inputs);
        }
        return (
          <div className={styles.simulatorModelInputsContainer}>
            {models.map((model) => (
              <div className={styles.modelInputs} key={model.name}>
                <span>{model.name}</span>
                {renderModelInputs(model.inputs)}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'taskId',
      key: 'duplicate',
      width: '10%',
      render: (taskId) => <Button type="link" onClick={() => handleOpen(taskId)}>Open</Button>,
    },
  ];
  const handleTabChange = (activeKey) => {
    navigate(`/${path}/simulator/${activeKey}`);
  };
  return (
    <div className={styles.simulatorHistoryWrapper}>
      <div className={styles.simulatorHistory}>
        <div className={styles.simulatorHistoryHeader}>
          <div className={styles.simulatorHistoryTitle}>
            <UnstyledButton onClick={() => handleTabChange('simulation')}>Simulator</UnstyledButton>
            <UnstyledButton className={styles.active}>History</UnstyledButton>
          </div>
        </div>
        <Table
          columns={columns}
          className={styles.simulatorHistoryTable}
          dataSource={rows}
          rowKey="taskId"
          loading={{
            spinning: loading,
            indicator: <Loading />,
          }}
          pagination={{
            onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
          }}
        />
      </div>
    </div>
  );
};

const renderModelInputs = (inputs: ModelInputs): ReactElement => (
  <List>
    {inputs?.chatDriverModelUri && (
      <List.Item>
        Driver:&nbsp;
        <CopyableValue
          displayValue={truncateMiddle(inputs?.chatDriverModelUri || '', 0, 10)}
          copiedValue={inputs?.chatDriverModelUri || ''}
        />
      </List.Item>
    )}
    {inputs?.agentModelUri && (
      <List.Item>
        Agent:&nbsp;
        <CopyableValue
          displayValue={truncateMiddle(inputs?.agentModelUri || '', 0, 10)}
          copiedValue={inputs?.agentModelUri || ''}
        />
      </List.Item>
    )}
    {inputs?.visitorModelUri && (
      <List.Item>
        Visitor:&nbsp;
        <CopyableValue
          displayValue={truncateMiddle(inputs?.visitorModelUri || '', 0, 10)}
          copiedValue={inputs?.visitorModelUri || ''}
        />
      </List.Item>
    )}
    {inputs?.rulePolicyUri && (
      <List.Item>
        Policy:&nbsp;
        <CopyableValue
          displayValue={truncateMiddle(inputs?.rulePolicyUri || '', 0, 10)}
          copiedValue={inputs?.rulePolicyUri || ''}
        />
      </List.Item>
    )}
  </List>
);

function convertToRow(task: SimulationTask): SimulatorHistoryTableRow {
  const models = [];
  switch (task.taskData?.simulationType) {
    case SimulationType.SINGLE_MODEL:
    case SimulationType.CONTINUOUS_REGRESSION_TEST:
      models.push({ inputs: task.taskData?.targetModelInputs });
      break;
    case SimulationType.COMPARE_MODELS:
      models.push(
        { name: 'Model 1', inputs: task.taskData?.baseModelInputs },
        { name: 'Model 2', inputs: task.taskData?.targetModelInputs },
      );
      break;
    case SimulationType.REGRESSION_TEST:
      models.push(
        { name: 'Prod', inputs: task.taskData?.baseModelInputs },
        { name: 'Target', inputs: task.taskData?.targetModelInputs },
      );
      break;
    default:
      console.error('unknown simulation type', task.taskData?.simulationType);
  }
  return {
    ...task.abstractTask,
    taskId: getId('simulationTask', task.name),
    convos: task.taskData?.conversations?.length || 0,
    simulationType: task.taskData?.simulationType,
    models,
  };
}
