/* eslint-disable no-await-in-loop */
/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState, useContext } from 'react';
import qs from 'query-string';
import { isEmpty } from 'lodash';
import { CustomerConfigContext } from 'context/CustomerConfigContext';
import { getAccessToken } from '../common/auth';
import { StudioApi } from '../services/studioApi';
import { useCustomerParams } from './useCustomerParams';

export const TIMEOUT_ERROR_MSG = 'Gateway Timeout';
export type ApiGet = (path: string, params?: { [key: string]: any }) => Promise<any>;

export function useApiPost(isCustomerSpecificRoute = true, isCustomerIDsRoute = false):
  (path: string, body: object) => Promise<any> {
  const { customerId, profileId, usecaseId, languageCode } = useCustomerParams();
  const { currentConfig } = useContext(CustomerConfigContext);
  let routePrefix = '';
  if (isCustomerSpecificRoute) {
    routePrefix = isCustomerIDsRoute ? `${customerId}/${profileId}/${usecaseId}/${languageCode}/` : `${currentConfig?.customerShortName}/`;
  }
  const token = getAccessToken();

  // eslint-disable-next-line consistent-return
  return async (path: string, body: { [key: string]: any }) => {
    const response = await fetch(`${StudioApi.getPythonApiEndpoint(currentConfig)}/${routePrefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 504) {
      throw new Error(TIMEOUT_ERROR_MSG);
    } else if (response.status >= 400) {
      let error = await response.json();
      if (!(error as any).message) {
        error = { message: typeof error === 'string' && error.length !== 0 ? error : 'Unhandled exception on the backend.' };
      }
      console.error(`api POST error: ${(error as any).message}`);
      throw error;
    } else if (response.ok) {
      return (async () => {
        const body = await response.json();
        // use "httpStatus" b/c server returns customs status in body, unfortunately
        return { ...body, httpStatus: response.status };
      })();
    }
  };
}

export function useApiGet(isCustomerSpecificRoute: boolean = true): ApiGet {
  const { currentConfig } = useContext(CustomerConfigContext);
  const routePrefix = isCustomerSpecificRoute ? `${currentConfig?.customerShortName}/` : '';
  const token = getAccessToken();

  // eslint-disable-next-line consistent-return
  return async (path: string, params = {}) => {
    const fullPath = routePrefix + (isEmpty(params) ? path : `${path}?${qs.stringify(params)}`);
    const response = await fetch(`${StudioApi.getPythonApiEndpoint(currentConfig)}/${fullPath}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (response.status >= 400) {
      let error = await response.json();
      if (!(error as any).message) {
        error = { message: typeof error === 'string' && error.length !== 0 ? error : 'Unhandled exception on the backend.' };
      }
      console.error(`api GET error: ${(error as any).message}`);
      throw error;
    } else if (response.ok) {
      const responseBody = await response.json();
      return { ...responseBody, httpStatus: response.status };
    }
  };
}

// TODO refetch, fetch interval, client cache
export function useApiData(
  path: string,
  isCustomerSpecificRoute: boolean = true,
) {
  const [rtn, setRtn] = useState({ loading: true, data: null, error: null });
  const apiGet = useApiGet(isCustomerSpecificRoute);

  const refetchKey = `${path}`;

  useEffect(() => {
    const fetchData = async () => {
      if (path != null) {
        try {
          setRtn({ ...rtn, loading: true });
          const result = await apiGet(path);
          setRtn({ loading: false, data: result, error: null });
        } catch (e) {
          setRtn({ loading: false, data: null, error: e });
        }
      } else if (rtn.loading) {
        setRtn((prev) => ({ ...prev, loading: false }));
      }
    };
    fetchData();
  }, [refetchKey]);

  return rtn;
}
