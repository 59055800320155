export interface HotkeyListItem {
  Location: string;
  Tags: string;
  Component: string;
  Action: string;
  Shortcut: string;
}

// TODO: Possible imporvement - convert tags to enum
export const hotkeysRoutes = [
  ['/analysisworkshop', 'Analysis'],
  ['/conversation', 'Conversations'],
  ['/edit-regex', 'Edit Regex'],
  ['/simulator', 'Simulator'],
  ['/labeling/view', 'Labeling'],
  ['/qa/view', 'QA'],
];

export const hotkeysList: HotkeyListItem[] = [
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Utterance View',
    Component: 'AnalysisUtteranceTable',
    Action: "Toggle visibility of 'export to csv' button (hidden by default)",
    Shortcut: 'Ctrl + e',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TagSelection',
    Action: 'Unfocus new tag select',
    Shortcut: 'Esc',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TagSelection',
    Action: 'Navigate new tag select',
    Shortcut: '↑',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TagSelection',
    Action: 'Navigate new tag select',
    Shortcut: '↓',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TagSelection',
    Action: 'Select/deselect tag in tag select',
    Shortcut: 'Enter',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TaggingPage',
    Action: 'Next Conversation',
    Shortcut: 'Shift + →',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TaggingPage',
    Action: 'Previous Conversation',
    Shortcut: 'Shift + ←',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TaggingPage',
    Action: 'Next Message',
    Shortcut: '↓',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TaggingPage',
    Action: 'Previous Message',
    Shortcut: '↑',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'TaggingPage',
    Action: 'Focus search input',
    Shortcut: '/',
  },
  {
    Location: 'Analysis Workshop',
    Tags: 'Analysis, Tagging',
    Component: 'Notes',
    Action: 'Focus notes textarea',
    Shortcut: 'Tab',
  },
  {
    Location: 'Conversations',
    Tags: 'Conversations',
    Component: 'Filters',
    Action: 'Cancel/close utterance search modal',
    Shortcut: 'Esc',
  },
  {
    Location: 'Conversations',
    Tags: 'Conversations',
    Component: 'Filters',
    Action: 'Open utterance search',
    Shortcut: '/',
  },
  {
    Location: 'Conversations',
    Tags: 'Conversations',
    Component: 'Filters',
    Action: 'Reset utterance search',
    Shortcut: 'Esc',
  },
  {
    Location: 'Conversations',
    Tags: 'Conversations',
    Component: 'Filters',
    Action: 'Filter by agent',
    Shortcut: 'a',
  },
  {
    Location: 'Conversations',
    Tags: 'Conversations',
    Component: 'Filters',
    Action: 'Filter by visitor',
    Shortcut: 'v',
  },
  {
    Location: 'Builder',
    Tags: 'Edit Regex',
    Component: 'EditRegex',
    Action: 'Add regex helper',
    Shortcut: 'Enter',
  },
  {
    Location: 'Builder',
    Tags: 'Edit Regex',
    Component: 'EditRegex',
    Action: 'Rerun samples',
    Shortcut: 'r',
  },
  {
    Location: 'Simulator',
    Tags: 'Simulator',
    Component: 'MessagesPreviewer',
    Action: 'Synthetic conversation - input display name',
    Shortcut: 'Enter',
  },
  {
    Location: 'Simulator',
    Tags: 'Simulator',
    Component: 'MessagesPreviewer',
    Action: 'Synthetic conversation - input message',
    Shortcut: 'Shift + Enter',
  },
  {
    Location: 'Simulator',
    Tags: 'Simulator',
    Component: 'N/A',
    Action: 'Synthetic conversation - toggle message role',
    Shortcut: '(TODO)',
  },
  {
    Location: 'Simulator',
    Tags: 'Simulator',
    Component: 'N/A',
    Action: 'Dialogue Policy Editing - open/close editor',
    Shortcut: '(TODO)',
  },
  {
    Location: 'Simulator',
    Tags: 'Simulator',
    Component: 'N/A',
    Action: 'Dialogue Policy Editing - update and run',
    Shortcut: '(TODO)',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling',
    Component: 'LabelingView',
    Action: 'Toggle labeling guidelines view',
    Shortcut: 'g',
  },
  {
    Location: 'Labeling',
    Tags: 'Summarization, Labeling',
    Component: 'SummarizationDecision',
    Action: 'Jump to next topic / Finish writing',
    Shortcut: 'Enter',
  },
  {
    Location: 'Labeling',
    Tags: 'Summarization, Labeling',
    Component: 'SummarizationDecision',
    Action: 'Add response field',
    Shortcut: 'Shift + Enter',
  },
  {
    Location: 'Labeling',
    Tags: 'Summarization, Labeling',
    Component: 'SummarizationDecision',
    Action: 'Skip topic',
    Shortcut: 'Shift + n',
  },
  {
    Location: 'Labeling',
    Tags: 'Summarization, Labeling',
    Component: 'SummarizationDecision',
    Action: 'Unfocus response field',
    Shortcut: 'Esc',
  },
  {
    Location: 'Labeling',
    Tags: 'Summarization, Labeling',
    Component: 'SummarizationDecision',
    Action: 'Confirm',
    Shortcut: 'c',
  },
  {
    Location: 'Labeling',
    Tags: 'Summarization, Labeling',
    Component: 'SummarizationDecision',
    Action: 'Skip',
    Shortcut: 's',
  },
  {
    Location: 'Labeling',
    Tags: 'Entity, Labeling',
    Component: 'Spans',
    Action: "Select 'N' entity",
    Shortcut: '1 - 9',
  },
  {
    Location: 'Labeling',
    Tags: 'Entity, Labeling',
    Component: 'Spans',
    Action: 'Exit entity selection dropdown',
    Shortcut: 'Esc',
  },
  {
    Location: 'Labeling',
    Tags: 'Sparse Intent, QA, Labeling',
    Component: 'BinaryDecision',
    Action: 'Yes - add positive annotation',
    Shortcut: 'y',
  },
  {
    Location: 'Labeling',
    Tags: 'Sparse Intent, QA, Labeling',
    Component: 'BinaryDecision',
    Action: 'No - add negative annotation',
    Shortcut: 'n',
  },
  {
    Location: 'Labeling',
    Tags: 'Sparse Intent, QA, Labeling',
    Component: 'BinaryDecision',
    Action: 'Unsure - add unsure annotation (flag for review)',
    Shortcut: 'u',
  },
  {
    Location: 'Labeling',
    Tags: 'Sparse Intent, QA, Labeling',
    Component: 'BinaryDecision',
    Action: 'Skip - add skip annotation',
    Shortcut: 's',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Navigate up in intents list',
    Shortcut: '↑',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Navigate down in intents list',
    Shortcut: '↓',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Select intent from intents list',
    Shortcut: 'Enter',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Unfocus intent search',
    Shortcut: 'Esc',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Confirm - save selected annotations',
    Shortcut: 'c',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Unsure - open the popup for unsure annotation',
    Shortcut: 'u',
  },
  {
    Location: 'Labeling',
    Tags: 'Dense Intent, Labeling',
    Component: 'MultipleBinaryDecision',
    Action: 'Skip - add skip annotation to the message',
    Shortcut: 's',
  },
  {
    Location: 'Labeling',
    Tags: 'Policy, QA',
    Component: 'PolicyDecision',
    Action: 'Correct - save correct annotation',
    Shortcut: 'c',
  },
  {
    Location: 'Labeling',
    Tags: 'Policy, QA',
    Component: 'PolicyDecision',
    Action: 'Incorrect - save incorrect annotation',
    Shortcut: 'i',
  },
  {
    Location: 'Labeling',
    Tags: 'Policy, QA',
    Component: 'PolicyDecision',
    Action: 'Next - save selection and proceed to next conversation',
    Shortcut: 'n',
  },
  {
    Location: 'Labeling',
    Tags: 'Suggestions, QA',
    Component: 'SuggestionsDecision',
    Action: 'Single: bad annotation / Compare: both models annotation',
    Shortcut: 'b',
  },
  {
    Location: 'Labeling',
    Tags: 'Suggestions, QA',
    Component: 'SuggestionsDecision',
    Action: 'Single: neutral annotation / Compare: neither model annotation',
    Shortcut: 'n',
  },
  {
    Location: 'Labeling',
    Tags: 'Suggestions, QA',
    Component: 'SuggestionsDecision',
    Action: 'Single: good annotation',
    Shortcut: 'g',
  },
  {
    Location: 'Labeling',
    Tags: 'Suggestions, QA',
    Component: 'SuggestionsDecision',
    Action: 'Compare: 1st model annotation',
    Shortcut: '1',
  },
  {
    Location: 'Labeling',
    Tags: 'Suggestions, QA',
    Component: 'SuggestionsDecision',
    Action: 'Compare: 2nd model annotation',
    Shortcut: '2',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling, QA',
    Component: 'LabelingStepper',
    Action: 'Previous conversation',
    Shortcut: 'Shift + ←',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling, QA',
    Component: 'LabelingStepper',
    Action: 'Next conversation',
    Shortcut: 'Shift + →',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling, QA',
    Component: 'LabelingUnsurePopover',
    Action: 'Close popover',
    Shortcut: 'Esc',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling, QA',
    Component: 'LabelingUnsurePopover',
    Action: 'Save unsure annotation',
    Shortcut: 'Enter',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling, QA',
    Component: 'SearchInput',
    Action: 'Focus search input',
    Shortcut: '/',
  },
  {
    Location: 'Labeling',
    Tags: 'Labeling, QA',
    Component: 'SearchInput',
    Action: 'Unfocus search input',
    Shortcut: 'Esc',
  },
];
