import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as ModelWorkflowState } from './state';

const selectModelWorkflow = (state: State) => state.modelWorkflow;

/** Select model workflows. */
export const selectModelWorkflows = createSelector(selectModelWorkflow, (modelWorkflowState: ModelWorkflowState) => modelWorkflowState.modelWorkflows);

/** Select model workflows' nextPageToken */
export const selectModelWorkflowsNextPageToken = createSelector(selectModelWorkflow, (modelWorkflowState: ModelWorkflowState) => modelWorkflowState.nextPageToken);

/** Select specific model workflow */
export const selectSpecificModelWorkflow = createSelector(selectModelWorkflow, (modelWorkflowState: ModelWorkflowState) => modelWorkflowState.modelWorkflow);
