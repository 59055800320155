import { QuestionCircleOutlined } from '@ant-design/icons';
import { TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { FetchAnnotationSummaryRequestFilter } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import Loading from 'components/Loading';
import MultiTags from 'components/MultiTags';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnnotationsCount, AnnotationsCountWithMetrics } from '.';

import styles from './styles.module.scss';

export const DIVERSITY_TOOLTIP_COPY = `Within-sample diversity measures the overlap of labeled messages for a given split, label, and value of that label. It ranges from 0.0 to 1.0.
Adding a message that is highly distinct from already labeled messages increases the diversity statistic more substantively and will lead to a larger model recall.
The "optimal" diversity statistic depends on the underlying distribution of the intent, but from calibration, we should watch out for samples where the statistic falls below 0.05.`;

const REPRESENTATIVENESS_TOOLTIP_COPY = `Train-test representativeness measures the similarity of messages with test labels to messages with train labels and ranges from 0.0 to 1.0.
A test sample that is highly distinct from any train sample may be problematic for a model to predict. We may use this measure to diagnose whether our train and test tasks are set up to sample from the same distribution.
From calibration, we found representativeness of 0.86 correlates with F1 score of 0.9 and 0.68 with 0.7 respectively.`;

interface IntentLabelsTableProps {
  filters: FetchAnnotationSummaryRequestFilter;
  loading: boolean;
  annotationsCount: AnnotationsCount[];
  setPageInfo: React.Dispatch<React.SetStateAction<{ page: number, pageSize: number }>>;
}

export default function IntentLabelsTable({
  filters,
  loading,
  annotationsCount,
  setPageInfo,
}: IntentLabelsTableProps) {
  const columns: ColumnsType<AnnotationsCountWithMetrics> = [
    {
      title: 'Intent',
      dataIndex: 'concepTitle',
      key: 'concepTitle',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.concepTitle.localeCompare(c2.concepTitle),
      render: (value) => (<MultiTags tags={[value]} />),
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Needs Review</span>
          <Tooltip placement="rightTop" title="Task completed, waiting for the dual/calibration tasks.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'review',
      key: 'review',
      sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.review - c2.review,
      render: (value, record) => {
        if (filters.detailedTypeFilter.targetType === TargetType.QA) {
          return <Link to={`${record.intentId}?split=${filters.splits[0]}&target=QA`}>{value}</Link>;
        } else {
          return (
            <Link to={`../labeling/tasks?intent=${record.intentId}&split=${filters.splits[0]}&status[0]=TASK_IN_PROGRESS`}>{value}</Link>
          );
        }
      },
    },
    {
      title: () => (
        <div className={styles.columnTitle}>
          <span>Model Ready</span>
          <Tooltip placement="rightTop" title="Annotations which need no further calibration and may be used to train models.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      children: [
        {
          title: () => (
            <div className={styles.columnTitle}>
              <span>Positive</span>
              <Tooltip
                placement="bottomRight"
                title={() => (
                  <div>
                    <b>Diversity</b>
                    <div>{DIVERSITY_TOOLTIP_COPY}</div>
                  </div>
                )}
              >
                <span className={styles.secondaryTitle}>Diversity</span>
              </Tooltip>
            </div>
          ),
          dataIndex: 'positive',
          key: 'positive',
          sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.positive - c2.positive,
          render: (value, record) => {
            if (filters.detailedTypeFilter.targetType === TargetType.QA) {
              return 'N/A';
            } else {
              return (
                <div className={styles.annotationCountCell}>
                  <Link to={`${record.intentId}?concept=${filters.conceptType}&split=${filters.splits[0]}&label=VALUE_POSITIVE&target=LABELING`}>{value}</Link>
                  {record.positiveAnnotationDiversity && <span className={styles.secondaryContent}>{`${record.positiveAnnotationDiversity > 0 ? '+' : ''}${record.positiveAnnotationDiversity.toFixed(2)}`}</span>}
                </div>
              );
            }
          },
        },
        {
          title: 'Negative',
          dataIndex: 'negative',
          key: 'negative',
          sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.negative - c2.negative,
          render: (value, record) => {
            if (filters.detailedTypeFilter.targetType === TargetType.QA) {
              return 'N/A';
            } else {
              return <Link to={`${record.intentId}?concept=${filters.conceptType}&split=${filters.splits[0]}&label=VALUE_NEGATIVE&target=LABELING`}>{value}</Link>;
            }
          },
        },
        {
          title: () => (
            <div className={styles.columnTitle}>
              <span>Total</span>
              <Tooltip
                placement="bottomRight"
                title={() => (
                  <div>
                    <b>Representativeness</b>
                    <div>{REPRESENTATIVENESS_TOOLTIP_COPY}</div>
                  </div>
                )}
              >
                <span className={styles.secondaryTitle}>Representativeness</span>
              </Tooltip>
            </div>
          ),
          dataIndex: 'modelReady',
          key: 'modelReady',
          sorter: (c1: AnnotationsCount, c2: AnnotationsCount) => c1.modelReady - c2.modelReady,
          render: (value, record) => {
            if (filters.detailedTypeFilter.targetType === TargetType.QA) {
              return 'N/A';
            } else {
              const isScoreNaN = Number.isNaN(Number(record.representativenessScore));
              return (
                <div className={styles.annotationCountCell}>
                  <Link to={`${record.intentId}?concept=${filters.conceptType}&split=${filters.splits[0]}&target=LABELING`}>{value}</Link>
                  {isScoreNaN ? (
                    <span>NaN</span>
                  ) : (
                    <span>
                      {record.representativenessScore && <span className={styles.secondaryContent}>{`${record.representativenessScore > 0 ? '+' : ''}${record.representativenessScore.toFixed(2)}`}</span>}
                    </span>
                  )}
                </div>
              );
            }
          },
        },
      ],
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        dataSource={annotationsCount}
        rowKey={(record: AnnotationsCount) => record.concepTitle}
        loading={{
          spinning: loading,
          indicator: <Loading />,
        }}
        pagination={{
          onChange: (page, pageSize) => setPageInfo({ page, pageSize }),
        }}
      />
    </div>
  );
}
