import React, { FC, useCallback, useMemo, useState } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';
import { Modal, UnstyledButton } from '@mantine/core';
import { Conversation } from '@cresta/web-client/dist/cresta/v1/studio/conversation/conversation_service.pb';
import { getId } from 'common/resourceName';
import Loader from 'pages/Conversations/Loader';
import { ConversationCard } from './SimulatorModel/ConversationCard';
import styles from './modals.module.scss';

interface Props {
  opened: boolean;
  onClose: () => void;
  handleCreateConversation: () => void;
  syntheticConversations: Conversation[];
  handleClickSyntheticConversation: (syntheticConversation: Conversation) => void;
  activeSyntheticConversations: Conversation[];
}

export const SyntheticConversationModal: FC<Props> = ({
  opened,
  onClose,
  handleCreateConversation,
  syntheticConversations,
  handleClickSyntheticConversation,
  activeSyntheticConversations,
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const activeIds = useMemo(
    () => activeSyntheticConversations?.map((item) => getId('conversation', item.name)),
    [activeSyntheticConversations],
  );

  const handleClickCard = useCallback((conversationId: string) => {
    const conversation = syntheticConversations.find((conv) => getId('conversation', conv.name) === conversationId);
    if (!conversation) {
      return;
    }
    handleClickSyntheticConversation(conversation);
  }, [syntheticConversations]);

  const filterConversationData = useMemo(() => {
    if (!syntheticConversations) {
      return null;
    }
    const conversationData = searchText ? syntheticConversations.filter((item) => item.name.indexOf(searchText) >= 0) : syntheticConversations;
    return conversationData;
  }, [syntheticConversations, searchText, setSearchText]);

  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      onClose={onClose}
      size={416}
      className={styles.syntheticConversationModal}
      title="Choose from saved synthetic conversations"
    >
      <Input
        suffix={<SearchOutlined />}
        placeholder="Search"
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
      />
      <UnstyledButton
        onClick={() => {
          handleCreateConversation();
          onClose();
        }}
        className={styles.syntheticConversationModalButton}
      >
        <PlusOutlined style={{ color: '#304ffe' }} />
        <span className={styles.syntheticConversationModalButtonText}>
          New synthetic conversation
        </span>
      </UnstyledButton>
      <div className={styles.syntheticConversationModalModalList}>
        {filterConversationData ? filterConversationData.map((convo) => {
          const id = getId('conversation', convo.name);
          const title = convo.metadata?.displayName || convo.actorIds[0] || getId('conversation', convo.name);
          const active: boolean = activeIds.indexOf(id) >= 0;
          return (
            <ConversationCard
              key={id}
              conversationCardTitle={title}
              conversationId={id}
              conversationCreator=""
              conversationCreatedTime={moment(convo.openTime).format('MM/DD/YYYY h:mma')}
              active={active}
              handleClickCard={handleClickCard}
            />
          );
        }) : <Loader />}
      </div>
    </Modal>
  );
};
