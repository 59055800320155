import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import './ConversationOutcome.scss';
import { Input, Card, Button } from '@mantine/core';
import {
  Concept,
  ConceptConceptType,
  ConversationOutcomeConversationOutcomeType as ConversationOutcomeType,
} from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { useExpansionState } from 'hooks/useExpansionState';
import { CaretDownOutlined, CaretUpOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import ConceptTag from 'components/ConceptTag';
import { ModalType } from 'components/AddConceptModal';
import useUrlParam from 'hooks/useUrlParam';

interface OutcomeNode {
  id: string;
  concept: Concept;
  possibleOutcomes?: OutcomeNode[];
  hidden: boolean;
}

interface Props {
  data: Concept[];
  onFocus: (concept: Concept) => void;
  onAdd: (modalType: ModalType, parent?: Concept) => void;
}
export function ConversationOutcome({
  data,
  onFocus,
  onAdd,
}: Props): ReactElement {
  const [searchText, setSearchText] = useState<string>('');

  // Build nodes from the input data.
  const nodes = useMemo(() => {
    const hiddenPredicate = (concept: Concept) => {
      if (searchText && !concept.conceptTitle.includes(searchText)) {
        return true;
      }
      return false;
    };
    const outcomeNodes = data.filter((concept) => concept.conceptType === ConceptConceptType.CONVERSATION_OUTCOME);
    const parentNodes: OutcomeNode[] = outcomeNodes
      .filter((concept) =>
        concept.conversationOutcome.conversationOutcomeType !== ConversationOutcomeType.POSSIBLE_OUTCOME)
      .map((concept) => ({
        id: getId('concept', concept.name),
        concept,
        hidden: hiddenPredicate(concept),
      }));
    outcomeNodes.filter((concept) => concept.conversationOutcome.conversationOutcomeType === ConversationOutcomeType.POSSIBLE_OUTCOME).forEach((concept) => {
      const id = getId('concept', concept.name);
      const parentNode = parentNodes.find((node) => node.id === concept.conversationOutcome.parentConversationOutcomeName);
      if (parentNode) {
        const possibleOutcome: OutcomeNode = {
          id,
          concept,
          hidden: hiddenPredicate(concept),
        };
        if (!parentNode.possibleOutcomes) {
          parentNode.possibleOutcomes = [];
        }
        parentNode.possibleOutcomes.push(possibleOutcome);
      }
    });
    return parentNodes;
  }, [data, searchText]);

  // Track the expansion state.
  const [isExpanded, flipExpansionState] = useExpansionState(nodes);

  // Focus a conversation outcome concept.
  const [focusedId, setFocusedId] = useUrlParam<string>('node', '');
  useEffect(() => {
    if (focusedId) {
      const concept = data.find((concept) => getId('concept', concept.name) === focusedId);
      if (concept) {
        onFocus(concept);
      }
    }
  }, [data, focusedId]);
  const [hoverId, setHoverId] = useState<string>('');

  return (
    <Card radius="lg" p="lg">
      <div className="conversation-outcome-concepts-header">
        <span>Name</span>
        <span className="spacer" />
        {/* <span>Labels</span> */}
      </div>
      <div className="conversation-outcome-concepts-search">
        <Input
          icon={<SearchOutlined />}
          variant="unstyled"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="conversation-outcome-concepts-content">
        {nodes.length === 0 && (<div className="placeholder">No conversation outcome.</div>)}
        {nodes.map((node) => (
          <>
            <div
              key={node.id}
              className={classNames([
                'conversation-outcome-concepts-item',
                node.id === focusedId ? 'focused' : '',
              ])}
              hidden={node.hidden}
              onClick={(e) => {
                e.stopPropagation();
                setFocusedId(node.id);
              }}
              onMouseEnter={() => setHoverId(node.id)}
              onMouseLeave={() => setHoverId('')}
            >
              <div
                className="expansion-button"
                onClick={() => flipExpansionState(node)}
              >
                {isExpanded(node) ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </div>
              <ConceptTag value={node.concept.conceptTitle} count={node.possibleOutcomes?.length || 0} />
              <span className="spacer" />
              {node.id === hoverId && (
                <Button
                  variant="subtle"
                  onClick={() => onAdd('library', node.concept)}
                >
                  <PlusCircleOutlined />
                </Button>
              )}
            </div>
            {
              isExpanded(node) && node.possibleOutcomes?.map((outcome) => (
                <div
                  key={outcome.id}
                  className={classNames([
                    'conversation-outcome-concepts-item',
                    'possible-outcome',
                    outcome.id === focusedId ? 'focused' : '',
                  ])}
                  hidden={outcome.hidden}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFocusedId(outcome.id);
                    onFocus(outcome.concept);
                  }}
                >
                  <ConceptTag value={outcome.concept.conceptTitle} />
                </div>
              ))
            }
          </>
        ))}
        <div
          className="add-new-button"
          onClick={() => onAdd('simple')}
        >
          <PlusCircleOutlined />&nbsp;New Outcome
        </div>
      </div>
    </Card>
  );
}
