import { createSlice } from '@reduxjs/toolkit';
import { getCustomersCurrentUserIds } from './asyncThunks';
import { updateSyntheticLabelsModalReducer, updateDebuggingModeReducer, getCustomersCurrentUserIdsSuccess, updateTaskPlaylistCustomersReducer } from './reducers';
import { INITIAL_STATE } from './state';

/** App slice. */
export const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    updateSyntheticLabelsModal: updateSyntheticLabelsModalReducer,
    updateDebuggingMode: updateDebuggingModeReducer,
    updateTaskPlaylistCustomers: updateTaskPlaylistCustomersReducer,
  },
  extraReducers: (builder) =>
    builder.addCase(getCustomersCurrentUserIds.fulfilled, getCustomersCurrentUserIdsSuccess),
});

/** Actions for sync reducers. */
export const { updateSyntheticLabelsModal, updateDebuggingMode, updateTaskPlaylistCustomers } = appSlice.actions;
