import React, { useEffect, useState, useCallback, useContext } from 'react';
import { ConfigFormValues, ExampleObj, FormValueExampleArrayType } from 'pages/AutoLabeling/types';
import { UseFormReturnType } from '@mantine/form';
import { MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS, SUGGESTION_REFRESH_ALERT_THRESHHOLD } from 'pages/AutoLabeling/config';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { ExampleUtterance } from '@cresta/web-client';
import { showNotification } from '@mantine/notifications';
import { useDebouncedValue } from '@mantine/hooks';
import { AutoLabelingSessionContext } from 'context/AutoLabelingSessionContext';
import { fetchSuggestions, convertRefreshGrayAreaResponseToExampleObjs } from './utils';
import { convertExampleObjsToExampleUtterances } from '../../../utils';
import { Suggestions as SuggestionsComponent } from './Suggestions';

interface SuggestionsProps {
  configForm: UseFormReturnType<ConfigFormValues>;
  handleAddNewExample: (formValue: FormValueExampleArrayType, text: string) => void
  onFirstExampleAdded: () => void;
  openPreviewPanel: () => void;
}

export const Suggestions = (props: SuggestionsProps) => {
  const {
    configForm,
    handleAddNewExample,
    onFirstExampleAdded,
    openPreviewPanel,
  } = props;
  const [suggestedExamples, setSuggestedExamples] = useState<ExampleObj[]>([]);
  const isMinimumCountReached = configForm.values.positiveExamples.length >= MINIMUM_EXAMPLES_BEFORE_FETCHING_SUGGESTIONS;
  const [isLoading, toggleLoading] = useState(false);
  const [exampleChangeCount, setExampleChangeCount] = useState(0);
  const [isShowingRefreshAlert, toggleRefreshAlert] = useState(false);
  const profile = useCustomerProfile();
  const { usecaseId, languageCode } = useCustomerParams();
  const { id: sessionId } = useContext(AutoLabelingSessionContext);
  const hasExceededChangeCount = exampleChangeCount >= SUGGESTION_REFRESH_ALERT_THRESHHOLD;

  // handle when to show alert to refresh suggestions (after 2 changes to examples)
  useEffect(() => {
    if (hasExceededChangeCount) {
      toggleRefreshAlert(true);
    }
  }, [exampleChangeCount]);

  const examplesAddedFromSuggestionsCount = React.useRef(0);

  const onNewExampleAdded = (formValue: FormValueExampleArrayType, text: string) => {
    const isFirstExampleAdded = examplesAddedFromSuggestionsCount.current === 0;
    if (isFirstExampleAdded) {
      onFirstExampleAdded();
    }
    examplesAddedFromSuggestionsCount.current += 1;
    handleAddNewExample(formValue, text);
  };

  const handleFetchSuggestions = useCallback(async () => {
    toggleLoading(true);
    const positiveExampleUtterances: ExampleUtterance[] = convertExampleObjsToExampleUtterances(configForm.values.positiveExamples);
    const negativeExampleUtterances: ExampleUtterance[] = convertExampleObjsToExampleUtterances(configForm.values.negativeExamples);

    const config = {
      profile,
      usecaseId,
      languageCode,
      sessionId,
      intentMetadata: {
        intentDisplayName: configForm.values.humanReadableName,
        intentDescription: configForm.values.description,
        positiveExamples: positiveExampleUtterances,
        negativeExamples: negativeExampleUtterances,
        speakerRole: configForm.values.speakerRole,
      },
    };
    const response = await fetchSuggestions(config).catch((err) => {
      toggleLoading(false);
      showNotification({
        title: 'Error',
        message: err.message,
        color: 'red',
      });
    });
    if (!response) return;
    const exampleObjs = convertRefreshGrayAreaResponseToExampleObjs(response);
    // only return the first 4 predictions
    const slicedExamples = exampleObjs.slice(0, 4);
    setSuggestedExamples(slicedExamples);
    toggleRefreshAlert(false);
    setExampleChangeCount(0);
    toggleLoading(false);
  }, [configForm.values, profile, usecaseId, languageCode, sessionId]);

  const [debouncedHumanReadableName] = useDebouncedValue(configForm.values.humanReadableName, 1000);
  const [debouncedDescription] = useDebouncedValue(configForm.values.description, 1000);

  useEffect(() => {
    // toggle fetch when human readable name is entered or changed
    if (debouncedHumanReadableName.length && debouncedDescription) {
      handleFetchSuggestions();
    }
  }, [debouncedHumanReadableName, debouncedDescription]);

  const handleRemoveSuggestedExample = (example: ExampleObj) => {
    const updatedSuggestedExamples = suggestedExamples.filter((item) => item.value !== example.value);
    setSuggestedExamples(updatedSuggestedExamples);
  };

  const handleRefresh = async () => {
    const emptyFields: string[][] = [];
    if (!configForm.values.humanReadableName) {
      emptyFields.push(['humanReadableName', 'name']);
    }
    if (!configForm.values.description) {
      emptyFields.push(['description', 'description']);
    }
    if (emptyFields.length > 0) {
      for (const field of emptyFields) {
        configForm.setFieldError(field[0], `Please enter a ${field[1]} for your intent before refreshing suggestions as it greatly improves the quality of the suggestions`);
      }
      return;
    }
    toggleLoading(true);
    handleFetchSuggestions();
  };
  return (
    <SuggestionsComponent
      isShowingRefreshAlert={isShowingRefreshAlert}
      onCloseAlert={() => toggleRefreshAlert(false)}
      handleRemoveSuggestedExample={handleRemoveSuggestedExample}
      hasReachedMinimumExamples={isMinimumCountReached}
      handleRefresh={handleRefresh}
      suggestedExamples={suggestedExamples}
      isLoading={isLoading}
      handleAddNewExample={onNewExampleAdded}
      openPreviewPanel={openPreviewPanel}
    />
  );
};
