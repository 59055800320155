import React, { useState, useContext } from 'react';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { CreateTaskRequest } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { UserContext } from 'context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getId } from 'common/resourceName';
import { showNotification } from '@mantine/notifications';
import { v4 as uuid } from 'uuid';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { SetConfig } from './SetConfig';
import { TagConfig } from './TagConfig';
import { createNewTaskRequest } from './createNewTaskRequest';

export const NewTaskConfig = () => {
  const currentUser = useContext(UserContext);
  const customer = useCustomerParams();
  const location:{state:{taskTitle:string}} = useLocation();

  const taskTitle = location.state?.taskTitle;
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const customerProfile = useCustomerProfile();
  const [setNames, setSetNames] = useState([]);

  const handleTaskCreation = (conceptIds: string[]) => {
    const userId = getId('user', currentUser.id);
    const newId = uuid();

    const newTaskRequest:CreateTaskRequest = createNewTaskRequest({
      taskName: `${customerProfile}/labelingTasks/${newId}`,
      title: taskTitle,
      setNames,
      conceptIds,
      userId,
      usecaseName: `${customerProfile}/usecases/${customer?.usecaseId}`,
      languageCode: customer?.languageCode,
    });
    return LabelingTaskApi.createTask(newTaskRequest);
  };

  const handleSubmit = (conceptIds: string[]) =>
    handleTaskCreation(conceptIds)
      .then((res) => {
        console.info('Successfult Task Creation: ', res);
        const taskId = getId('labelingTask', res.derivedLabelingTask.labelingTask.name);
        navigate(`/${customer.path}/analysisworkshop/${taskId}`);
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          color: 'red',
          title: 'Failed to create task',
          message: err.message,
        });
      });

  switch (step) {
    case 0:
      return (
        <SetConfig onNext={(setNamesToAdd:string[]) => {
          setSetNames(setNamesToAdd);
          setStep(1);
        }}
        />
      );
    case 1:
      return (
        <TagConfig onSubmit={handleSubmit}/>
      );
    default:
      return <div>Configuration step not found</div>;
  }
};
