import { createConfirm } from 'components/ConfirmModal';

export const createDoneConfirmation = () =>
  createConfirm({
    title: 'Are you sure you want to finish this task?',
    content: 'By clicking done, this task will finish and you will be unable to go back.',
    buttons: [
      {
        text: 'Yes',
        value: true,
        buttonProps: {
          'data-cy': 'confirm-done-btn',
        },
      },
      {
        text: 'Cancel',
        buttonProps: {
          variant: 'subtle',
        },
        value: false,
      },
    ],
  });
