import React, { useState } from 'react';
import { Button, Group, Paper, Text, useMantineTheme } from '@mantine/core';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConfirmationModal } from './ConfirmationModal';

interface Props {
    intentF1TableRows: {
        conceptId: string;
        intent: string;
        f1: number;
    }[];
    onConfirm: (selectedConceptIds: string[]) => Promise<{
        success: boolean;
        message: string;
    }>;
}

export const QATaskAlert = ({ intentF1TableRows, onConfirm }: Props) => {
  const intentsReadyForQACount = intentF1TableRows.filter((row) => row.f1 >= 0.7).length;
  const theme = useMantineTheme();
  const [isOpen, toggleOpen] = useState(false);

  const onClose = () => {
    toggleOpen(false);
  };

  return (
    <Paper p="xs" mb="sm">
      <Group position="apart">
        <Group>
          <ExclamationCircleOutlined style={{ color: theme.fn.primaryColor() }} />
          <Text color="blue">{`${intentsReadyForQACount} intents are ready for QA. Run QA Task`}</Text>
        </Group>
        <Button
          variant="light"
          compact
          onClick={() => toggleOpen(true)}
        >
          Run QA
        </Button>
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          tableData={intentF1TableRows}
          onConfirm={onConfirm}
        />
      </Group>
    </Paper>
  );
};
