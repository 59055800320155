import React, { useMemo, useState, useCallback } from 'react';
import { Form, AutoComplete, Input } from 'antd';
import TaskTypeTag from 'components/TaskTypeTag';
import MultiTags from 'components/MultiTags';
import UserTag from 'components/UserTag';
import { User } from 'types';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { DerivedLabelingTask } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { getId } from 'common/resourceName';
import { Text, Flex } from '@mantine/core';

interface MisclassificationAugmentationTaskFormProps {
  intentConcepts: Concept[];
  users: User[];
  qaTasks: DerivedLabelingTask[];
  qaTaskId: string;
  setQATaskId: (value: string) => void;
}

export function MisclassificationAugmentationTaskForm({
  intentConcepts,
  users,
  qaTasks,
  qaTaskId,
  setQATaskId,
}: MisclassificationAugmentationTaskFormProps) {
  const conceptIdsMap = useMemo(
    () => new Map<string, Concept>(intentConcepts.map((concept) => [getId('concept', concept.name), concept])),
    [intentConcepts],
  );
  const initialOptions = useMemo(() => qaTasks.map((task) => {
    const taskId = getId('labelingTask', task?.labelingTask?.name);
    const taskDescriptor = task?.labelingTask?.taskData?.taskDescriptor;
    return ({
      id: taskId,
      value: taskId,
      label: (
        <Flex direction="row" align="center" gap="sm">
          <Text size="sm">{`${taskId?.substring(0, 4)}...`}</Text>
          <TaskTypeTag taskType={task?.userLabelingTaskType} />
          <MultiTags
            tags={taskDescriptor?.conceptIds?.map((conceptId) => {
              const concept = conceptIdsMap.get(conceptId);
              return concept ? concept.conceptTitle : null;
            })}
          />
          <UserTag name={users.find((user) => user.id === task?.labelingTask?.abstractTask?.assigneeUserId)?.full_name || 'Unassigned'} />
        </Flex>
      ),
    });
  }), [qaTasks, conceptIdsMap]);
  const [options, setOptions] = useState(initialOptions);
  const handleValueChange = useCallback((value: string) => {
    setOptions(initialOptions.filter((option) => option.id?.startsWith(value)));
  }, []);
  return (
    <Form.Item>
      <Text size="md">QA Task ID</Text>
      <AutoComplete
        value={qaTaskId}
        onSelect={(value) => setQATaskId(value)}
        onSearch={handleValueChange}
        options={options}
        allowClear
      >
        <Input value={qaTaskId} onChange={(e) => setQATaskId(e.target.value)} />
      </AutoComplete>
    </Form.Item>
  );
}
