import { Set } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { getId } from 'common/resourceName';

export const createSetsMap = (sets: Set[]) => {
  const setsLookupObj = {};
  sets.forEach((set) => {
    const setId = getId('set', set.name);
    setsLookupObj[setId] = {
      title: set.setTitle,
    };
  });
  return setsLookupObj;
};
