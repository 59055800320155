import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import qs from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getId } from 'common/resourceName';
import {
  RegexSearchMessagesRequest,
  RegexSearchMessagesRequestActorTypeFilter,
  RegexSearchMessagesResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import { MessageApi } from 'services/messageApi';
import { ACCEPTED_DATE_FORMAT } from 'studioConstants';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { UtterancePanel as UtterancePanelComponent } from './UtterancePanel';

interface UtterancePanelProps{
  searchText: string;
  speaker: RegexSearchMessagesRequestActorTypeFilter | null
  filterAgents: string[]
  filterDateStart: string
  filterDateEnd: string
}
export const MAX_UTTERANCES = 1000;

const UtterancePanel = (props: UtterancePanelProps) => {
  const { searchText, speaker, filterAgents, filterDateStart, filterDateEnd } = props;
  const { search } = useLocation();
  const navigate = useNavigate();
  const { chatId: existingId } = useParams<{chatId: string}>();
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedMessagePosition, setSelectedMessagePosition] = useState(null);
  const [utterances, setUtterances] = useState([]);
  const [loading, toggleLoading] = useState(false);
  const customer = useCustomerParams();
  const customerProfile = useCustomerProfile();

  const updateConversationId = (conversationId: string, messagePosition?:number) => {
    setSelectedChatId(conversationId);
    setSelectedMessagePosition(messagePosition);
    const previousSearch = qs.parse(search);
    const newSearch = qs.stringify({ ...previousSearch, messagePosition });
    navigate(`/${customer.path}/conversation/${conversationId}?${newSearch}`);
  };

  //   Opens the chat conversation for the first utterance in the list
  useEffect(() => {
    if (selectedChatId) return;
    if (utterances.length === 0) return;
    let newId:string;
    if (existingId) {
      newId = existingId;
    } else {
      newId = getId('conversation', utterances[0].name);
    }
    updateConversationId(newId, utterances[0].sequencePosition);
  }, [selectedChatId, selectedMessagePosition, utterances]);

  useEffect(() => {
    setSelectedChatId(null);
    setSelectedMessagePosition(null);
    const params: RegexSearchMessagesRequest = {
      pageSize: MAX_UTTERANCES,
      resource: customerProfile,
      regexExpressions: [searchText],
      beginTime: filterDateStart ? moment(filterDateStart).format(ACCEPTED_DATE_FORMAT) : undefined,
      endTime: filterDateEnd ? moment(filterDateEnd).format(ACCEPTED_DATE_FORMAT) : undefined,
      agentIds: filterAgents,
      actorTypeFilter: speaker || undefined,
      usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
      languageCode: customer?.languageCode,
    };
    toggleLoading(true);

    MessageApi.regexSearchMessages(params).then(({ regexMatches }: RegexSearchMessagesResponse) => {
      console.info('Regex Match Response:', regexMatches);
      setUtterances(regexMatches[0].messages);
    }).catch((err) => {
      notification.error({
        message: 'Error With Regex Search',
        description: err.message,
      });
    }).finally(() => {
      toggleLoading(false);
    });
  }, [filterAgents, filterDateStart, filterDateEnd, searchText, speaker]);
  return <UtterancePanelComponent searchText={searchText} utterances={utterances} loading={loading} selectedChatId={selectedChatId} selectedMessagePosition={selectedMessagePosition} handleClick={updateConversationId} />;
};

export default UtterancePanel;
