import { ModelProject, ModelProjectProjectType } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { ModelProjectTableRow } from 'models';

export const GenerateModelProjectTableRow = (modelProjects: ModelProject[]): ModelProjectTableRow[] =>
  modelProjects.filter((p) => p.projectType !== ModelProjectProjectType.PROJECT_TYPE_UNSPECIFIED)
    .sort((a, b) => a.updateTime.localeCompare(b.updateTime))
    .reverse()
    .map((item: ModelProject) => ({
      name: item.name,
      displayName: item.displayName,
      updateTime: item.updateTime,
      creator: item.creator,
      projectType: item.projectType,
      status: item.status,
    } as ModelProjectTableRow));
