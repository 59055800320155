import { EditFilled } from '@ant-design/icons';
import { ActionIcon, Box, Divider, Flex, Group, Stack, Textarea, Title, Tooltip, useMantineTheme } from '@mantine/core';
import React, { useLayoutEffect } from 'react';
import useStayScrolled from 'react-stay-scrolled';
import { SimulatorMessage } from '.';
import VAMessage from './VAMessage';

export default function VAChat({
  messages,
  promptId,
  message,
  setMessage,
  handleMessageEnter,
  isBotTyping,
  isVARepliesVisible = true,
}: {
  messages: SimulatorMessage[],
  promptId: string,
  message: string,
  setMessage: (message: string) => void,
  handleMessageEnter: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void,
  isBotTyping: boolean,
  isVARepliesVisible?: boolean,
}) {
  const theme = useMantineTheme();
  const listRef = React.useRef<HTMLDivElement>(null);
  const { stayScrolled } = useStayScrolled(listRef);

  const lastMessage = messages[messages.length - 1];
  useLayoutEffect(() => {
    stayScrolled();
  }, [messages.length, lastMessage?.text, isBotTyping]);

  return (
    <Box
      p="xl"
      style={{
        height: '100%',
      }}
    >
      <Flex
        direction="column"
        justify="flex-start"
        align="stretch"
        maw="600px"
        mx="auto"
        style={{
          borderRadius: theme.spacing.sm,
          height: '100%',
          overflow: 'hidden',
          boxShadow: theme.shadows.sm,
        }}
      >
        <Flex
          direction="column"
          justify="start"
          style={{
            flex: 4,
            overflow: 'hidden',
          }}
        >
          <Flex
            align="center"
            justify="space-between"
            p="lg"
            style={{
              backgroundColor: theme.colors.blue[6],
            }}
          >
            <Group spacing="xs">
              <Title size="xl" color="white">Chat with: {promptId}</Title>
              <Tooltip label="Edit prompt">
                <ActionIcon color="blue" variant="light" size="sm" disabled={!promptId}>
                  <EditFilled/>
                </ActionIcon>
              </Tooltip>
            </Group>
          </Flex>
          <Divider color="gray.1"/>
          <div
            ref={listRef}
            style={{
              overflow: 'auto',
              height: '100%',
              padding: theme.spacing.xl,
              backgroundColor: theme.colors.gray[0],
            }}
          >
            <Stack py="sm">
              {messages.filter((message) => {
                if (isVARepliesVisible) {
                  return true;
                } else {
                  return message.speaker_role !== 'agent';
                }
              }).map((message) => (
                <VAMessage
                  key={message.sid}
                  text={message.text}
                  createdAt={message.created_at}
                  speaker={message.speaker_role}
                />
              ))}
              {isBotTyping && (
                <VAMessage
                  key="typing"
                  text=""
                  typingAnimation
                  createdAt={new Date()}
                  speaker="agent"
                />
              )}
            </Stack>
          </div>
        </Flex>
        <Box style={{ flex: 1, overflow: 'hidden' }}>
          <Textarea
            value={message}
            minRows={7}
            disabled={!promptId}
            placeholder="Ask something"
            styles={{
              input: {
                resize: 'none',
                border: 0,
                fontSize: 14,
                padding: '20px !important',
                borderTop: `1px solid ${theme.colors.gray[1]}`,
                borderRadius: 0,
              },
            }}
            onKeyDown={handleMessageEnter}
            onChange={(event) => setMessage(event.currentTarget.value)}
          />
        </Box>
      </Flex>
    </Box>
  );
}
