import { Annotation, DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import {
  SearchAnnotationsResponseAnnotationBundle as AnnotationBundle,
  FetchAnnotationSummaryResponseConceptAnnotationSummary as AnnotationSummary,
  FetchAnnotationSummaryRequestFilter as SummaryFilter,
  SearchAnnotationsResponse,
  FetchCalibrationAnnotationsResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { AnnotationFilters, INITIAL_STATE, State } from './state';

/** General reducer for starting an annotation API. */
export function annotationApiPending(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    status: 'loading',
  };
}

/** General reducer for a failed annotation API. */
export function annotationApiFailed(state: State, action: PayloadAction<SerializedError>): State {
  return {
    ...state,
    status: 'failed',
  };
}

/** Reducer for ListAnnotations success. */
export function listAnnotationsSuccess(state: State, action: PayloadAction<Annotation[]>): State {
  return {
    ...state,
    status: 'succeeded',
  };
}

/** Update annotation summary filters reducer. */
export function updateSummaryFilters(state: State, action: PayloadAction<SummaryFilter>): State {
  return {
    ...state,
    annotationSummaries: [],
    summaryFilters: {
      splits: action.payload.splits || [],
      intentType: action.payload.intentType || undefined,
      detailedTypeFilter: action.payload.detailedTypeFilter || {},
      conceptType: action.payload.conceptType || ConceptConceptType.INTENT,
    },
  };
}

/** Update customer profile reducer. */
export function updateCustomerProfileReducer(state: State, action: PayloadAction<{}>): State {
  return {
    ...state,
    currentAnnotations: [],
    annotationSummaries: [],
    nextPageToken: '',
    annotationFilters: INITIAL_STATE.annotationFilters,
    summaryFilters: INITIAL_STATE.summaryFilters,
  };
}

/** Update annotation filters reducer. */
export function updateAnnotationFilters(state: State, action: PayloadAction<AnnotationFilters>): State {
  return {
    ...state,
    currentAnnotations: [],
    nextPageToken: '',
    annotationFilters: {
      ...state.annotationFilters,
      ...action.payload,
    },
  };
}

/** Fetch annotation summary fulfilled reducers. */
export function fetchAnnotationSummarySuccess(state: State, action: PayloadAction<{ dataSplit: DataSplit, summary: AnnotationSummary[] }>): State {
  return {
    ...state,
    status: 'succeeded',
    annotationSummaries: action.payload.summary,
    trainAnnotationSummaries: action.payload.dataSplit === DataSplit.TRAIN ? action.payload.summary : state.trainAnnotationSummaries,
    testAnnotationSummaries: action.payload.dataSplit === DataSplit.TEST ? action.payload.summary : state.testAnnotationSummaries,
  };
}

export function fetchCalibrationAnnotationsPending(
  state: State,
  action: PayloadAction<string>,
): State {
  if (state.calibrationSummary?.calibrationTaskId !== action.payload) {
    return {
      ...state,
      status: 'loading',
      calibrationSummary: {
        calibrationTaskId: action.payload,
        nextPageToken: '',
        calibrationAnnotations: [],
        mutualConsentPercentage: '',
        originalTaskConsentPercentage: '',
        dualTaskConsentPercentage: '',
      },
    };
  }
  return {
    ...state,
    status: 'loading',
  };
}

export function fetchCalibrationAnnotationsSuccess(
  state: State,
  action: PayloadAction<{ taskId: string; response: FetchCalibrationAnnotationsResponse }>,
): State {
  return {
    ...state,
    status: 'succeeded',
    calibrationSummary: {
      calibrationTaskId: action.payload.taskId,
      nextPageToken: action.payload.response.nextPageToken,
      calibrationAnnotations: action.payload.taskId === state.calibrationSummary.calibrationTaskId
        ? [...state.calibrationSummary.calibrationAnnotations, ...action.payload.response.calibrationAnnotationTuples]
        : action.payload.response.calibrationAnnotationTuples,
      mutualConsentPercentage: (action.payload.response.originalDualConsensusRatio * 100).toFixed(0),
      originalTaskConsentPercentage: (action.payload.response.originalCalibrationConsensusRatio * 100).toFixed(0),
      dualTaskConsentPercentage: (action.payload.response.dualCalibrationConsensusRatio * 100).toFixed(0),
    },
  };
}

/** Search annotations fulfilled reducers. */
export function searchAnnotationsSuccess(state: State, action: PayloadAction<SearchAnnotationsResponse>): State {
  const nextAnnotations = cloneDeep(state.currentAnnotations);
  nextAnnotations.push(...action.payload.annotationBundles);
  return {
    ...state,
    status: 'succeeded',
    nextPageToken: action.payload.nextPageToken,
    currentAnnotations: nextAnnotations,
  };
}

/** Deprecate annotations reducer. */
export function deprecateAnnotationsSuccess(state: State, action: PayloadAction<Annotation[]>): State {
  const annotations: AnnotationBundle[] = [];
  const deprecatedAnnotations = new Map<string, Annotation>(action.payload.map((annotation) => [annotation.name, annotation]));
  for (let i = 0; i < state.currentAnnotations.length; i++) {
    if (!deprecatedAnnotations.has(state.currentAnnotations[i].annotation.name)) {
      annotations.push(state.currentAnnotations[i]);
    }
  }
  return {
    ...state,
    currentAnnotations: annotations,
  };
}

/** Upsert annotations reducer. */
export function upsertAnnotationsSuccess(state: State, action: PayloadAction<Annotation[]>): State {
  const annotations = cloneDeep(state.currentAnnotations);
  const upsertedAnnotations = new Map<string, Annotation>(action.payload.map((annotation) => [annotation.name, annotation]));
  // We can only update existing annotations because we don't know textMessage
  // (we're working here with Annotation[] and not AnnotationBundle[])
  for (let i = 0; i < annotations.length; i++) {
    const upsertedAnnotation = upsertedAnnotations.get(annotations[i].annotation.name);
    if (upsertedAnnotation) {
      annotations[i].annotation = upsertedAnnotation;
    }
  }
  return {
    ...state,
    status: 'succeeded',
    currentAnnotations: annotations,
  };
}

/** Reset annotations */
export function resetAnnotationsReducer(state: State): State {
  return {
    ...state,
    status: 'succeeded',
    nextPageToken: null,
    currentAnnotations: [],
  };
}
