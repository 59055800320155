import React, { forwardRef, Ref } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { ManualAnalysisTag } from '../Badge';

function conceptTitleFormatter(conceptName:string) {
  return conceptName.trim().split(' ').join('_').toLowerCase();
}

const SelectItem = forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const { tagType, label, onSelect, onMouseDown, dataHovered, ...other } = props;
    return (
      <div
        ref={ref}
        style={{
          background: other['data-hovered'] ? '#EDF2FF' : 'transparent',
        }}
        onMouseDown={(e) => {
          onMouseDown(e);
          onSelect();
        }}
        {...other}
      >
        <ManualAnalysisTag tagType={tagType}>{label}</ManualAnalysisTag>
      </div>
    );
  },
);

export const TagSelect = forwardRef((props: SelectProps, ref: Ref<HTMLInputElement>) => {
  const { onChange, ...other } = props;
  const getCreateLabel = (query: string) => `+ Create ${conceptTitleFormatter(query)}`;
  return (
    <Select
      ref={ref}
      itemComponent={SelectItem}
      placeholder="Enter name"
      nothingFound="Nothing found"
      searchable
      creatable
      onSearchChange={onChange}
      getCreateLabel={getCreateLabel}
      withinPortal
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.currentTarget.blur();
        }
        return props.onKeyDown && props.onKeyDown(e);
      }}
      {...other}
    />
  );
});
