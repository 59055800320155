import { useEffect, useState } from 'react';
import { PromptApi } from 'services/promptApi';
import { CreatePromptResponse, UpdatePromptResponse } from '@cresta/web-client/dist/cresta/v1/studio/prompt/prompt_service.pb';
import { toRequestUpdateMask } from 'utils';
import { v4 as uuid } from 'uuid';
import { useCustomerProfile, useCustomerParams, useCustomerUsecase } from 'hooks/useCustomerParams';
import { useListPrompts } from './useListPrompts';
import { handleSections } from '../PromptEditor';

export interface IArgument {
    key?: string;
    value?: string;
}
export interface ISection {
    promptTemplate?: string;
    displayName?: string;
    argumentList?: IArgument[];
}
export interface IPrompt {
    id: string;
    name?: string;
    promptText: string;
    displayName: string;
    createdAt: string;
    updatedAt?: string;
    isDeployed?: boolean;
    promptGroupId?: string;
    version?: number;
    isActiveVersion?: boolean;
}

export const usePrompts: () => {
    error: string | null;
    refetch: () => void;
    deployedPrompt: IPrompt | undefined;
    status: 'loading' | 'success';
    listPrompts: IPrompt[];
    createPrompt: ({
      displayName,
      promptText,
      promptGroupId,
    }: {
      displayName: string,
      promptText: string,
      promptGroupId?: string,
    }) => Promise<IPrompt>;
    updatePrompt: (prompt: Partial<IPrompt>) => Promise<IPrompt>;
    deployPrompt: (prompt: IPrompt) => Promise<IPrompt>;
} = () => {
  const [loading, setLoading] = useState(false);
  const [prompts, loadingPrompts, refetch, error] = useListPrompts();
  const { languageCode } = useCustomerParams();
  const customerProfile = useCustomerProfile();
  const usecase = useCustomerUsecase();
  const [deployedPrompt, setDeployedPrompt] = useState<IPrompt | null>(null);

  useEffect(() => {
    // on prompt list change, check if there is a deployed prompt
    const deployedPrompt = prompts.find((p) => p.isDeployed);
    setDeployedPrompt(deployedPrompt);
  }, [prompts]);

  const createPrompt = async (newPrompt: {
    displayName: string;
    promptText: string;
    promptGroupId?: string;
  }): Promise<IPrompt> => {
    setLoading(true);
    let res: CreatePromptResponse;
    const id = uuid();
    const newPromptGroupId = uuid();
    try {
      res = await PromptApi.createPrompt({
        parent: customerProfile,
        prompt: {
          name: `${customerProfile}/prompts/${id}`,
          displayName: newPrompt.displayName,
          // required until non-null is aded
          promptText: 'to be removed',
          usecase,
          languageCode,
          sections: [{
            promptTemplate: newPrompt.promptText,
            displayName: newPrompt.displayName,
            argumentList: [],
          }],
          promptGroupId: newPrompt.promptGroupId || newPromptGroupId,
        },
      });
    } catch (e) {
      setLoading(false);
      throw e;
    }
    const createdPrompt = res.prompt;
    const prompt: IPrompt = {
      id: createdPrompt.name.split('/').pop(),
      promptText: handleSections(createdPrompt.sections),
      displayName: createdPrompt.displayName,
      createdAt: createdPrompt.createTime,
      updatedAt: createdPrompt.updateTime,
      isDeployed: createdPrompt.isDeployed,
      promptGroupId: createdPrompt.promptGroupId,
      version: createdPrompt.version,
      isActiveVersion: createdPrompt.isActiveVersion,
    };
    refetch();
    setLoading(false);
    return prompt;
  };

  const updatePrompt = async (prompt: Partial<IPrompt>): Promise<IPrompt> => {
    setLoading(true);
    const updateMask = [];
    if (prompt.displayName) updateMask.push('display_name');
    if (prompt.displayName || prompt.promptText) updateMask.push('sections');
    if (prompt.isActiveVersion) updateMask.push('is_active_version');

    let res: UpdatePromptResponse;

    const getSections = (displayName:string, promptText:string) => {
      if (!displayName && !promptText) return null;
      return [{
        promptTemplate: promptText,
        displayName,
        argumentList: [],
      }];
    };

    try {
      res = await PromptApi.updatePrompt({
        prompt: {
          name: `${customerProfile}/prompts/${prompt.id}`,
          displayName: prompt.displayName,
          sections: getSections(prompt.displayName, prompt.promptText),
          isActiveVersion: prompt.isActiveVersion,
        },
        updateMask: toRequestUpdateMask(updateMask),
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
    const updatedPrompt = res.prompt;
    const promptToUpdate: IPrompt = {
      id: updatedPrompt.name.split('/').pop(),
      promptText: handleSections(updatedPrompt.sections),
      displayName: updatedPrompt.displayName,
      createdAt: updatedPrompt.createTime,
      updatedAt: updatedPrompt.updateTime,
      isDeployed: updatedPrompt.isDeployed,
    };
    refetch();
    return promptToUpdate;
  };

  const deployPrompt = async (prompt: IPrompt): Promise<IPrompt> => {
    setLoading(true);
    let res: UpdatePromptResponse;
    try {
      res = await PromptApi.updatePrompt({
        prompt: {
          name: `${customerProfile}/prompts/${prompt.id}`,
          isDeployed: true,
        },
        updateMask: toRequestUpdateMask(['isDeployed']),
      });
    } catch (e) {
      setLoading(false);
      throw e;
    }
    const updatedPrompt = res.prompt;
    const promptToDeploy: IPrompt = {
      id: updatedPrompt.name.split('/').pop(),
      promptText: handleSections(updatedPrompt.sections),
      displayName: updatedPrompt.displayName,
      createdAt: updatedPrompt.createTime,
      updatedAt: updatedPrompt.updateTime,
      isDeployed: updatedPrompt.isDeployed,
    };
    refetch();
    setLoading(false);
    return promptToDeploy;
  };

  return {
    error,
    refetch,
    deployedPrompt,
    status: (loading || loadingPrompts) ? 'loading' : 'success',
    listPrompts: prompts,
    createPrompt,
    updatePrompt,
    deployPrompt,
  };
};
