import { SetType } from '@cresta/web-client/dist/cresta/v1/studio/set/set_service.pb';
import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as SetState } from './state';

const selectSet = (state: State) => state.set;

/** Select sets. */
export const selectSets = createSelector(selectSet, (setState: SetState) => setState.sets);

/** Select golden set. */
export const selectGoldenSet = createSelector(selectSet, (setState: SetState) => setState.sets.find((set) => set.setTitle === 'Golden Set'));

/** Select conversation sets only. */
export const selectConversationSets = createSelector(selectSet, (setState: SetState) => setState.sets.filter((set) => set.setType === SetType.CONVERSATION_SET_TYPE));

/** Select message sets only. */
export const selectMessageSets = createSelector(selectSet, (setState: SetState) => setState.sets.filter((set) => set.setType === SetType.MESSAGE_SET_TYPE));

/** Select API status. */
export const selectApiStatus = createSelector(selectSet, (setState: SetState) => setState.status);
