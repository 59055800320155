import React from 'react';
import { Button } from '@mantine/core';
import './TagConfig.scss';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';

export const TagList = ({ subheader, tags, onDelete, onClear }) => (
  <>
    <div className="list-subheader-container">
      <div className="list-subheader">{subheader}</div>
      {tags.length > 0 && <Button variant="subtle" color="red" compact size="xs" onClick={onClear}>Clear</Button>}
    </div>
    <ul data-cy={`${subheader.toLowerCase()}-list`} className="list-container">
      {tags.map(({ conceptTitle, name, tag }) => (
        <li key={name}>
          <ManualAnalysisTag
            tagType={tag.tagType}
            isCloseable
            onClose={() => {
              onDelete(name);
            }}
          >
            {conceptTitle}
          </ManualAnalysisTag>
        </li>
      ))}
    </ul>
  </>
);
