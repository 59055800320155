import React from 'react';
import { Button, createStyles } from '@mantine/core';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { CloseOutlined } from '@ant-design/icons';

const useStyles = createStyles((theme) => ({
  root: {
    height: 16,
  },
  letterChip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: theme.radius.sm,
    borderBottomLeftRadius: theme.radius.sm,
    fontWeight: theme.other.weightExtraBold,
    height: 16,
    width: 18,
    marginLeft: -5,
    marginRight: 5,
    color: theme.white,
  },
  visitorChip: {
    background: theme.colors.gray[1],
  },
  agentChip: {
    background: theme.colors[theme.primaryColor][6],
  },
  label: {
    overflow: 'unset',
  },
  rightIcon: {
    marginLeft: 5,
  },
}));

interface BadgeBaseProps {
  pl?: number;
  isCloseable?: boolean;
  color?: string;
  onClose?: () => void;
  onClick?: () => void;
  leftSection?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
}

export const BadgeBase = ({
  pl = 5,
  color,
  isCloseable = false,
  onClose = undefined,
  onClick = undefined,
  leftSection = undefined,
  disabled = undefined,
  selected = false,
  children,
}: BadgeBaseProps) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      classNames={{
        root: classes.root,
        label: classes.label,
        rightIcon: classes.rightIcon,
        leftIcon: cx(classes.letterChip, {
          [classes.visitorChip]: leftSection === 'V',
          [classes.agentChip]: leftSection === 'A' || leftSection === 'B',
        }),
      }}
      style={
        selected ? { border: `1px solid ${color}` } : undefined
      }
      compact
      size="xs"
      data-disable={disabled}
      className="tag-badge"
      color={color}
      pl={pl}
      radius="sm"
      variant="light"
      disabled={disabled}
      onClick={onClick}
      leftIcon={leftSection}
      rightIcon={isCloseable ? (
        <CloseOutlined
          data-cy="tag-close-button"
          onClick={onClose}
        />
      ) : undefined}
    >
      {children}
    </Button>
  );
};

export interface BadgeProps {
  isCloseable?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const StageBadge = (props: BadgeProps) => (
  <BadgeBase
    color="purple"
    {...props}
  />
);

const DriverBadge = (props: BadgeProps) => (
  <BadgeBase
    color="orange"
    {...props}
  />
);

const OutcomeBadge = (props:BadgeProps) => (
  <BadgeBase
    color="red"
    {...props}
  />
);

interface BehaviorBadgeProps extends BadgeProps {
  tagType: TagTagType;
}

const BehaviorBadge = ({ tagType, ...rest }: BehaviorBadgeProps) => {
  const leftSectionMap = {
    [TagTagType.AGENT_INTENT]: 'A',
    [TagTagType.VISITOR_INTENT]: 'V',
  };
  const leftSection = leftSectionMap[tagType] || 'V';
  return (
    <BadgeBase
      color="green"
      leftSection={leftSection}
      {...rest}
    />
  );
};

export type ManualAnalysisTagProps = (BadgeProps | BehaviorBadgeProps) & {
  tagType: TagTagType;
  selected?: boolean;
  onClick?: () => void;
};

export const ManualAnalysisTag = ({ tagType, ...props }:ManualAnalysisTagProps) => {
  switch (tagType) {
    case TagTagType.STAGE:
    case TagTagType.STAGE_BEGIN:
    case TagTagType.STAGE_END:
      return <StageBadge {...props} />;
    case TagTagType.DRIVER:
      return <DriverBadge {...props} />;
    case TagTagType.CONVERSATION_OUTCOME:
      return <OutcomeBadge {...props} />;
    case TagTagType.AGENT_INTENT:
      return <BehaviorBadge tagType={tagType} {...props} />;
    case TagTagType.VISITOR_INTENT:
      return <BehaviorBadge tagType={tagType} {...props} />;
    default:
      return <DriverBadge {...props} />;
  }
};
