import * as d3 from 'd3';
import React, { useRef } from 'react';
import { Box, Center, createStyles, getStylesRef, Flex, Paper, Text } from '@mantine/core';
import { getTextColor, stringToColor } from 'pages/AnalysisWorkshop/TaggingPage/utils/colors';
import { getPercent } from './CallDriverDiscovery';

export interface TreemapNode {
  id: string,
  name: string,
  value: number,
  children?: TreemapNode[],
}
interface Treemap2Props {
  data: TreemapNode,
  width: number,
  height: number,
  rootSum?: number,
  selectedNode: TreemapNode,
  hoveredNode: TreemapNode,
  handleSelectNode: (node: TreemapNode) => void,
  handleHoverNode?: (node: TreemapNode) => void,
}

/**
 *
 * Component based on https://observablehq.com/@d3/zoomable-treemap
 */
export default function Treemap2({ data, width, height, rootSum, selectedNode, hoveredNode, handleHoverNode, handleSelectNode }: Treemap2Props) {
  const useStyles = createStyles(() => ({
    blockTintDefault: {
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      position: 'absolute',
      opacity: 0.3,
      ref: getStylesRef('blockTint'),
    },
    blockTintReduced: {
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      position: 'absolute',
      opacity: 0.7,
      ref: getStylesRef('blockTint'),
    },
    block: {
      border: '1px solid #fff',
      overflow: 'hidden',
      position: 'relative',
      cursor: 'pointer',
      [`&:hover .${getStylesRef('blockTint')}`]: {
        opacity: 0,
      },
    },
  }));
  const styles = useStyles();
  const { classes } = styles;

  const treeRef = useRef(null);
  const svgRef = useRef(null);
  const gRef = useRef(null);

  const x = d3.scaleLinear().rangeRound([0, width]);
  const y = d3.scaleLinear().rangeRound([0, height]);

  const treemap = (data: TreemapNode) => d3.treemap<TreemapNode>()
    .tile(tile)(d3.hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.height - a.height || b.data.value - a.data.value));

  const treemapData = treemap(data);

  function tile(node, x0, y0, x1, y1) {
    d3.treemapResquarify(node, 0, 0, width, height);
    for (const child of node.children) {
      child.x0 = x0 + ((child.x0 / width) * (x1 - x0));
      child.x1 = x0 + ((child.x1 / width) * (x1 - x0));
      child.y0 = y0 + ((child.y0 / height) * (y1 - y0));
      child.y1 = y0 + ((child.y1 / height) * (y1 - y0));
    }
  }

  const zoominOrFetchMessages = (d: d3.HierarchyRectangularNode<TreemapNode>) => {
    if (!d.children?.length) {
      handleSelectNode(d.data);
    }
  };

  const root = treemapData;

  return (
    <Flex
      ref={treeRef}
      align="center"
      justify="center"
      style={{
        width: '100%',
        flex: 1,
        position: 'relative',
      }}
    >
      {root.value === 0 ? (
        <Center style={{ height: '100%' }}><Text>No data</Text></Center>
      ) : (
        <svg ref={svgRef} viewBox={`0, 0, ${width}, ${height}`} style={{ display: 'block' }}>
          <g ref={gRef}>
            {root.children?.filter((d) => d.value).map((d, i) => {
              const isReducedOpacity = (selectedNode && selectedNode?.id !== d.data.id) || (hoveredNode && hoveredNode?.id !== d.data.id);
              const bcgColor = stringToColor(d.data.name);
              const txtColor = getTextColor(bcgColor);
              return (
                <g
                  data-node-type="type"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  transform={`translate(${x(d.x0 || 0)},${y(d.y0 || 0)})`}
                  onClick={() => zoominOrFetchMessages(d)}
                >
                  <foreignObject
                    id={d.data.name}
                    width={d === root ? width : x(d.x1) - x(d.x0)}
                    height={d === root ? 60 : y(d.y1) - y(d.y0)}
                    onMouseEnter={() => handleHoverNode && handleHoverNode(d.data)}
                    onMouseLeave={() => handleHoverNode && handleHoverNode(null)}
                  >
                    <Paper
                      className={classes.block}
                      style={{
                        width: d === root ? width : x(d.x1) - x(d.x0),
                        height: d === root ? 60 : y(d.y1) - y(d.y0),
                        backgroundColor: bcgColor,
                      }}
                    >
                      <Box
                        className={isReducedOpacity ? classes.blockTintReduced : classes.blockTintDefault}
                      />
                      <Text
                        style={{
                          color: txtColor,
                          padding: 12,
                          position: 'relative',
                          zIndex: 1,
                        }}
                        size="sm"
                      >{getPercent(d.data.value, rootSum)}% {d.data.name}
                      </Text>
                    </Paper>
                  </foreignObject>
                </g>
              );
            })}
          </g>
        </svg>
      )}
    </Flex>
  );
}
