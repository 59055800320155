import { ConceptChange } from '@cresta/web-client/dist/cresta/v1/studio/conceptchange/conceptchange_service.pb';
import { ApiStatus } from 'store/types';

/** Concept state. */
export interface State {
  conceptChanges?: ConceptChange[];
  nextPageToken?: string;
  status: ApiStatus,
}

/** Initial concept state. */
export const INITIAL_STATE: State = {
  conceptChanges: [],
  nextPageToken: null,
  status: 'idle',
};
