import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/storage/concept/concept.pb';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { selectApiStatus, selectConceptsFactory } from 'store/concept/selectors';
import { getHotkeyHandler, useHotkeys } from '@mantine/hooks';
import { createNewConceptRequest, createNewTagRequest } from 'pages/AnalysisWorkshop/NewTaskConfig/TagConfig/utils';
import { showNotification } from '@mantine/notifications';
import { Concept, ConceptConceptType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { getId } from 'common/resourceName';
import { createConcept } from 'store/concept/asyncThunks';
import { UserContext } from 'context/UserContext';
import { ActorType } from '@cresta/web-client/dist/cresta/v1/studio/actors/actors.pb';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import { TagSelection as TagSelectionComponent } from './TagSelection';
import { TaggingPageContext } from '../../TaggingPageProvider';
import { filterAllTagsByActortype, filterByActortype, filterByTagType, sortByLabel, sortByTagType } from './utils';
import { StagesContext } from '../../StagesProvider';
import { MessageData } from '../../TaggingPage';
import { TagData } from '../../utils/handleAnnotations';

export enum UITagEnum {
  'STAGE',
  'DRIVER',
  'OUTCOME',
  'BEHAVIOR'
}

interface TagSelectionProps {
  handleAddExistingTagId : (tagId: string) => Promise<void>,
  getConceptById: (id: string) => Promise<Concept>,
  getStageEndTagIdFromStageBeginConceptTitle: (conceptTitle: string) => string,
  updateTagsInTask: (conceptIds: string[]) => Promise<void>,
  addTagIdsToTask: (tagIds: string[]) => Promise<void>,
  addTagIdToMessage: (tagId: string, messageData: MessageData, createdTags?: TagData[]) => Promise<void>,
}

export const TagSelection = ({
  handleAddExistingTagId,
  getConceptById,
  getStageEndTagIdFromStageBeginConceptTitle,
  updateTagsInTask,
  addTagIdsToTask,
  addTagIdToMessage,
}: TagSelectionProps) => {
  const dispatch = useDispatch();
  const customerProfile = useCustomerProfile();
  const customer = useCustomerParams();
  const currentUser = useContext(UserContext);
  const {
    taskName,
    tagsInProfile,
    taskConceptIds,
    conceptIdsOnCurrentMessage,
    removeTagFromMessage,
    activeConversationIndex,
    activeConversationId,
    activeSetId,
    activeMessage,
  } = useContext(TaggingPageContext);
  const {
    handleStageBeginSelection,
    handleStageEndSelection,
    stageIdToBeAdded,
  } = useContext(StagesContext);

  const [search, setSearch] = useState('');
  const conceptApiStatus = useSelector(selectApiStatus);
  const tagsInTask = tagsInProfile.filter((tag) => taskConceptIds.includes(tag.value));
  const [highlightedIndex, setHighlightedIndex] = useState<number>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const tagConcepts = useSelector(selectConceptsFactory(ConceptConceptType.TAG));

  useEffect(() => {
    if (tagsInTask.length > 0) {
      setHighlightedIndex(0);
    }
  }, [search]);
  const tagsFilteredByActiveActorType = tagsInTask.filter((tag) => filterAllTagsByActortype(tag, activeMessage.actorType));
  const tagsFilteredBySearch = tagsFilteredByActiveActorType.filter((tag) => (search.length === 0 || tag.label.toLowerCase().includes(search)));
  const sortedTags = tagsFilteredBySearch.sort(sortByLabel).sort(sortByTagType);
  const highlightedTag = tagsFilteredBySearch[highlightedIndex];

  const newStageTags = tagsFilteredBySearch.filter((tag) => tag?.tagType === TagTagType.STAGE);

  const stageTagsWithSuffix = tagsFilteredBySearch.filter((tag) => tag?.tagType === TagTagType.STAGE_BEGIN);
  // remove .begin suffix from stage tags's label
  const removeBeginSuffix = (label: string) => label.replace(/\.begin$/, '');
  const stageTags = [...stageTagsWithSuffix.map((tag) => ({ ...tag, label: removeBeginSuffix(tag.label) })), ...newStageTags];
  const driverTags = tagsFilteredBySearch.filter((tag) => tag?.tagType === TagTagType.DRIVER);
  const outcomeTags = tagsFilteredBySearch.filter((tag) => tag?.tagType === TagTagType.CONVERSATION_OUTCOME);
  const behaviorTags = tagsFilteredBySearch.filter((tag) => filterByActortype(tag, activeMessage.actorType));

  const withOnSelect = (tag) => ({
    ...tag,
    onSelect: () => handleAddExistingTagId(tag.value),
  });

  const deriveSelectData = (type: UITagEnum) => {
    const existingTags = tagsInProfile.filter(filterByTagType(type));
    const filteredTags = existingTags.filter((tag) => !taskConceptIds.includes(tag.value));
    return filteredTags.map(withOnSelect);
  };

  const stageTagSelectData = deriveSelectData(UITagEnum.STAGE).map((tag) => ({ ...tag, label: removeBeginSuffix(tag.label) }));
  const driverTagSelectData = deriveSelectData(UITagEnum.DRIVER);
  const outcomeTagSelectData = deriveSelectData(UITagEnum.OUTCOME);
  const behaviorTagSelectData = deriveSelectData(UITagEnum.BEHAVIOR);

  // brings highlighted tag into view
  useEffect(() => {
    const highlightedTagElement = document.getElementById(`tag-${highlightedTag?.value}`);
    if (highlightedTagElement) {
      highlightedTagElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [highlightedTag]);

  // remove highlight if search input is empty
  useEffect(() => {
    if (search.length === 0) {
      setHighlightedIndex(null);
    }
  }, [search]);

  const searchInputOnKeyDown = getHotkeyHandler([
    // blur input on escape
    ['Escape', () => {
      setSearch('');
      searchInputRef.current.blur();
    }],
    ['ArrowUp', () => {
      if (highlightedTag === null) return;
      if (highlightedIndex === 0) {
        setHighlightedIndex(null);
      } else {
        setHighlightedIndex((prev) => prev - 1);
      }
    }],
    ['ArrowDown', () => {
      if (highlightedIndex === sortedTags.length - 1) return;
      if (highlightedIndex === null) {
        setHighlightedIndex(0);
      } else {
        setHighlightedIndex((prev) => prev + 1);
      }
    }],
    ['Enter', () => {
      if (!highlightedTag) return;
      setSearch('');
      handleTagSelection(highlightedTag.value);
      searchInputRef.current.blur();
    }],
  ]);

  useHotkeys([['/', () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }]]);

  const createTag = async (conceptTitle: string, tagType: TagTagType) => {
    try {
      const newTagRequest = createNewTagRequest(conceptTitle, {
        customerProfile,
        tagType,
      });
      const newConceptRequest = createNewConceptRequest(newTagRequest, customerProfile, customer, currentUser);
      // create new concept
      const res = await dispatch(createConcept(newConceptRequest));
      const concept = res.payload as Concept;
      return concept;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removeTagIdFromTask = async (tagId: string) => {
    // handle stage tags
    const tagIds = [tagId];
    const concept = await getConceptById(tagId);
    if (concept.tag.tagType === TagTagType.STAGE_BEGIN) {
      const stageEndTagId = getStageEndTagIdFromStageBeginConceptTitle(concept.conceptTitle);
      if (stageEndTagId) {
        tagIds.push(stageEndTagId);
      }
    }
    const newConceptIds = taskConceptIds.filter((id) => !tagIds.includes(id));
    await updateTagsInTask(newConceptIds);
  };

  const onAddNewTagToTask = async (query: string, tagType: TagTagType) => {
    // handle stages
    if (tagType === TagTagType.STAGE_BEGIN) {
      const beginQuery = `${query}.begin`;
      const endQuery = `${query}.end`;
      try {
        const beginTag = await createTag(beginQuery, TagTagType.STAGE_BEGIN);
        const beginTagId = getId('concept', beginTag.name);
        const beginTitle = beginTag.conceptTitle;
        const endTag = await createTag(endQuery, TagTagType.STAGE_END);
        const endTitle = endTag.conceptTitle;
        const endTagId = getId('concept', endTag.name);
        await addTagIdsToTask([beginTagId, endTagId]);
        await addTagIdToMessage(
          beginTagId,
          {
            taskName,
            setId: activeSetId,
            conversationId: activeConversationId,
            conversationPositionNumber: activeConversationIndex,
            messageId: activeMessage.id,
          },
          [{
            id: beginTagId,
            title: beginTitle,
            type: TagTagType.STAGE_BEGIN,
          },
          {
            id: endTagId,
            title: endTitle,
            type: TagTagType.STAGE_END,
          }],
        );
      } catch (err) {
        console.error(err);
        showNotification({
          color: 'red',
          message: 'Error adding tag to task',
        });
      }
    } else {
      try {
        const newTag = await createTag(query, tagType);
        const newTagId = getId('concept', newTag.name);
        await addTagIdsToTask([newTagId]);
        if (tagType === TagTagType.STAGE) {
          handleStageBeginSelection(newTagId, activeMessage.id);
        } else {
          await addTagIdToMessage(
            newTagId,
            {
              taskName,
              setId: activeSetId,
              conversationId: activeConversationId,
              conversationPositionNumber: activeConversationIndex,
              messageId: activeMessage.id,
            },
          );
        }
      } catch (err) {
        console.error(err);
        showNotification({
          color: 'red',
          message: 'Error adding tag to task',
        });
      }
    }
  };

  const handleEnterPress = (inputValue: string, type: TagTagType) => {
    console.warn('unimplemented');
    // const existingTagLabels = tagConcepts.map(({ conceptTitle }) => conceptTitle);
    // const formattedInputValue = conceptTitleFormatter(inputValue);
    // if (existingTagLabels.includes(formattedInputValue)) {
    //   console.error('tag already exists');
    //   showNotification({
    //     color: 'red',
    //     message: 'Tag name already exists',
    //   });
    //   return;
    // }
    // onAddNewTagToTask(formattedInputValue, type);
  };

  const onTagSelection = (tagId: string) => addTagIdToMessage(
    tagId,
    {
      taskName,
      setId: activeSetId,
      conversationId: activeConversationId,
      conversationPositionNumber: activeConversationIndex,
      messageId: activeMessage.id,
    },
  );

  const onStageSelection = async (stageId: string) => {
    // temporarily handle previous stages
    const stageConcept = await getConceptById(stageId);
    const stageTagType = stageConcept.tag.tagType;
    if (stageTagType === TagTagType.STAGE_BEGIN) {
      return addTagIdToMessage(
        stageId,
        {
          taskName,
          setId: activeSetId,
          conversationId: activeConversationId,
          conversationPositionNumber: activeConversationIndex,
          messageId: activeMessage.id,
        },
      );
    }
    // toggle stage end selection state
    if (stageIdToBeAdded) {
      handleStageEndSelection(activeMessage.index);
      return null;
    } else {
      handleStageBeginSelection(stageId, activeMessage.id);
      return null;
    }
  };

  const handleStageSelection = (stageId: string) => {
    const isStageSelected = conceptIdsOnCurrentMessage.includes(stageId);
    if (isStageSelected) {
      removeTagFromMessage(stageId);
    } else {
      onStageSelection(stageId);
    }
  };

  const handleTagSelection = (tagId: string) => {
    const concept = tagConcepts.find((concept) => getId('concept', concept.name) === tagId);
    const conceptType = concept?.tag.tagType;
    if (conceptType === TagTagType.STAGE) {
      handleStageSelection(tagId);
      return;
    }
    const isTagSelected = conceptIdsOnCurrentMessage.includes(tagId);
    if (isTagSelected) {
      removeTagFromMessage(tagId);
    } else {
      onTagSelection(tagId);
    }
  };

  const behaviorTagType = useMemo(() => {
    switch (activeMessage.actorType) {
      case ActorType.AGENT:
        return TagTagType.AGENT_INTENT;
      case ActorType.BOT:
        return TagTagType.AGENT_INTENT;
      case ActorType.VISITOR:
        return TagTagType.VISITOR_INTENT;
      default:
        return TagTagType.TAG_TYPE_UNSPECIFIED;
    }
  }, [activeMessage.actorType]);

  return (
    <TagSelectionComponent
      handleStageSelection={handleStageSelection}
      handleTagSelection={handleTagSelection}
      isComplete={false}
      searchInputRef={searchInputRef}
      // Search Input
      search={search}
      setSearch={setSearch}
      tagsInitializing={conceptApiStatus === 'loading'}
      conceptIdsOnCurrentMessage={conceptIdsOnCurrentMessage}
      onAddNewTagToTask={onAddNewTagToTask}
      removeTagIdFromTask={removeTagIdFromTask}
      handleEnterPress={handleEnterPress}
      highlightedTagId={highlightedTag?.value}
      // Tags selected for task
      stageTags={stageTags}
      driverTags={driverTags}
      outcomeTags={outcomeTags}
      behaviorTags={behaviorTags}
      // Tags created for profile but not added to task
      stageTagSelectData={stageTagSelectData}
      driverTagSelectData={driverTagSelectData}
      outcomeTagSelectData={outcomeTagSelectData}
      behaviorTagSelectData={behaviorTagSelectData}
      searchInputOnKeyDown={searchInputOnKeyDown}
      behaviorTagType={behaviorTagType}
    />
  );
};
