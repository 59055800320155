import React from 'react';
import './ConversationCard.scss';
import UserTag from 'components/UserTag';
import classNames from 'classnames';

interface Props {
  conversationCardTitle: string;
  conversationId: string;
  conversationCreator: string;
  conversationCreatedTime: string;
  active: boolean;
  handleClickCard: (conversationId: string) => void;
}

export function ConversationCard({
  conversationCardTitle,
  conversationCreator,
  conversationId,
  conversationCreatedTime,
  active,
  handleClickCard,
}: Props) {
  return (
    <div onClick={() => handleClickCard(conversationId)} className={classNames(['conversation-card', active ? 'active' : ''])}>
      <div className="conversation-content">
        <p className="conversation-title">
          {conversationCardTitle}
        </p>
        <div className="conversation-time">
          <span>
            Created: {conversationCreatedTime}
          </span>
          {/* TODO: add when available
          <span className="conversation-message">
            0 messages
          </span> */}
        </div>
        <UserTag name={conversationCreator} />
      </div>
    </div>
  );
}
