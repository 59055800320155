import { SingleFlowGraph } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { Paper, createStyles } from '@mantine/core';
import Tree from 'react-d3-tree';
import React, { LegacyRef, useCallback, useState } from 'react';
import { Point } from '@nivo/line';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { FlowNodesTreeNode } from 'pages/Surveyor/utils';
import { TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { StageBlock } from './StageBlock';

const NODE_WIDTH = 160;
const NODE_HEIGHT = 25;

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { height } = containerElem.getBoundingClientRect();
      // Offset 100 px from left
      setTranslate({ x: 100, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};

export default function StagesTree({
  flowGraphs,
  height = 300,
}: {
  flowGraphs: SingleFlowGraph[],
  height?: number
}) {
  const customerProfile = useCustomerProfile();
  const root = new FlowNodesTreeNode({
    nodeName: 'BEGIN',
    flowNode: {
      conceptName: `${customerProfile}/concepts/begin_concept_id`,
    },
  }, null);
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { width: NODE_WIDTH, height: NODE_HEIGHT };
  const foreignObjectProps = {
    width: nodeSize.width,
    height: nodeSize.height + 20,
    x: nodeSize.width / 2 * -1,
    y: nodeSize.height / 2 * -1,
  };

  const useStyles = createStyles((theme) => ({
    treePath: {
      stroke: 'rgba(50, 100, 200, 0.2) !important',
      strokeWidth: 2,
    },
  }));
  const styles = useStyles();

  flowGraphs.forEach((graph) => {
    const stages = graph.flowNodes.map((flowNode) => ({
      nodeName: flowNode.conceptTitle.split('.begin')?.[0],
      flowNode,
    }));
    root.addStages(stages);
  });

  return (
    <Paper>
      <div style={{ height, width: '100%' }} ref={containerRef as LegacyRef<HTMLDivElement>}>
        <Tree
          data={root}
          nodeSize={{
            x: NODE_WIDTH,
            y: NODE_HEIGHT,
          }}
          pathClassFunc={() => styles.classes.treePath}
          translate={translate as Point}
          depthFactor={200}
          zoomable={false}
          separation={{
            siblings: 1.2,
            nonSiblings: 1.4,
          }}
          renderCustomNodeElement={(rd3tProps) => (
            <StageBlock
              nodeDatum={rd3tProps.nodeDatum as unknown as FlowNodesTreeNode & TreeNodeDatum}
              foreignObjectProps={foreignObjectProps}
              nodeSize={nodeSize}
            />
          )}
        />
      </div>
    </Paper>
  );
}
