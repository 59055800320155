/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AbstractTask,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import {
  DerivedLabelingTask,
  LabelingTask,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import {
  CompleteTaskRequest,
  CreateTaskRequest,
  FetchLabelingDataRequest,
  FetchLabelingDataResponse,
  FetchLabelingItemsRequest,
  FetchLabelingItemsResponse,
  ListDerivedTasksRequest,
  RemoveTemporalAnnotationRequest,
  SaveTemporalAnnotationRequest,
  SaveTemporalAnnotationRequestRawDataValueTuple,
  SaveTemporalAnnotationResponse,
  SelectNewBatchResponse,
} from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import { Annotation } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { getId } from 'common/resourceName';
import { openNotification } from 'components/Notification';
import { AbstractTaskApi } from 'services/abstractTaskApi';
import { LabelingTaskApi } from 'services/labelingTaskApi';
import { StudioApi } from 'services/studioApi';
import { AnnotationApi } from 'services/annotationApi';
import { SearchAnnotationsRequest, SearchAnnotationsResponseAnnotationBundle } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { cloneDeep, flatten, groupBy } from 'lodash';
import { SearchPredictionsRequest, Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { PredictionApi } from 'services/predictionApi';
import mixpanel from 'mixpanel-browser';
import { COMPLETE_TASK, CREATE_TASK } from 'configuration/mixpanelEvents';
import { getAnnotationRawDataKey } from 'store/annotation/selectors';
import { State } from './state';

enum Action {
  TASK_FETCH_DERIVED_LABELING_TASKS = 'TASK_FETCH_DERIVED_LABELING_TASK',
  TASK_REFRESH_DERIVED_LABELING_TASKS = 'TASK_REFRESH_DERIVED_LABELING_TASKS',
  TASK_CREATE_LABELING_TASK = 'TASK_CREATE_LABELING_TASK',
  TASK_UPDATE_ABSTRACT_TASK = 'TASK_UPDATE_ABSTRACT_TASK',
  TASK_UPDATE_TEMPORAL_ANNOTATION = 'TASK_UPDATE_TEMPORAL_ANNOTATION',
  TASK_FETCH_LABELING_TASK_DATA = 'TASK_FETCH_LABELING_TASK_DATA',
  TASK_FETCH_LABELING_TASK_ITEMS = 'TASK_FETCH_LABELING_TASK_ITEMS',
  TASK_FETCH_LABELING_TASK_ANNOTATIONS = 'TASK_FETCH_LABELING_TASK_ANNOTATIONS',
  TASK_COMPLETE_LABELING_TASK = 'TASK_COMPLETE_LABELING_TASK',
  TASK_UPSERT_TEMPORAL_ANNOTATION = 'TASK_UPSERT_TEMPORAL_ANNOTATION',
  TASK_REMOVE_TEMPORAL_ANNOTATION = 'TASK_REMOVE_TEMPORAL_ANNOTATION',
  TASK_SELECT_NEW_BATCH = 'TASK_SELECT_NEW_BATCH',
  TASK_SEARCH_PREDICTIONS = 'TASK_SEARCH_PREDICTIONS',
}

const CANCEL_TASKS_FETCH_CALLBACK_KEY = 'LabelingTaskApi.listDerivedTasks.Labeling';
// const QA_CANCEL_TASKS_FETCH_CALLBACK_KEY = 'LabelingTaskApi.listDerivedTasks.QA';

const MAX_ANNOTATIONS_PER_REQUEST = 1500;
// E.g. 30 messages each with 50 intents can produce 1500 annotations
const MAX_MESSAGE_PER_REQUEST = 30;

/** Helper func for splitting saveTemporalAnnotation request into smaller ones to avoid sending large payloads */
async function splitSaveTemporalAnnotationHelper(request: SaveTemporalAnnotationRequest, messageBatchSize: number = 30) {
  const requests: SaveTemporalAnnotationRequest[] = [];
  const copiedTuples = request.rawDataValueTuples.slice();

  // Group by messages to avoid sending fragmented annotations
  const messagesGroups = Object.values(groupBy(copiedTuples, (tuple) => tuple.rawData.messageRawData.v2MessageId));
  while (messagesGroups.length) {
    requests.push({
      ...request,
      rawDataValueTuples: flatten(messagesGroups.splice(0, messageBatchSize)),
    });
  }
  let allResponses: SaveTemporalAnnotationResponse;
  // Fire requests in order so the last resolved can return up-to-date data
  requests.forEach(async (request) => {
    const res = await LabelingTaskApi.saveTemporalAnnotation(request);
    allResponses = {
      derivedLabelingTask: res.derivedLabelingTask,
      upsertedAnnotations: [...allResponses.upsertedAnnotations, ...res.upsertedAnnotations],
      valueCounts: res.valueCounts,
    };
  });
  return allResponses;
}

/** AsyncThunk for fetching labeling DerivedLabelingTasks by customer profile. */
export const fetchLabelingTasks = createAsyncThunk<DerivedLabelingTask[] | undefined, {
  parent: string,
  usecaseId: string,
  languageCode: string,
}>(
  Action.TASK_FETCH_DERIVED_LABELING_TASKS,
  async ({
    parent,
    usecaseId,
    languageCode,
  }, { getState }) => {
    try {
      const { labelingTask } = getState() as { labelingTask: State };
      if (labelingTask.fetchingTasks) {
        const cancel = StudioApi.getCancelCallback(CANCEL_TASKS_FETCH_CALLBACK_KEY);
        if (cancel) {
          cancel();
        }
      }
      const request: ListDerivedTasksRequest = {
        parent,
        filter: {
          usecaseId,
          languageCode,
        },
      };
      const cancelableFetch = LabelingTaskApi.listDerivedTasks(request);
      StudioApi.setCancelCallback(CANCEL_TASKS_FETCH_CALLBACK_KEY, cancelableFetch.cancel);
      const response = await cancelableFetch.promise;
      return response?.derivedTasks;
    } catch (err) {
      if ((err as { name: string }).name !== 'AbortError') {
        openNotification('error', 'Failed to get labeling tasks', undefined, err);
      }
      throw err;
    }
  },
);

/**
 * AsyncThunk for refreshing DerivedLabelingTasks by customer profile.
 * The API call is the same as fetchLabelingTasks except:
 * 1. The action type is different. Thus handled by a different reducer.
 * 2. The error is not displayed to the user.
 */
export const refreshLabelingTasks = createAsyncThunk<DerivedLabelingTask[] | undefined, {
  parent: string,
  usecaseId: string,
  languageCode: string,
}>(Action.TASK_REFRESH_DERIVED_LABELING_TASKS, async ({
  parent,
  usecaseId,
  languageCode,
}) => {
  const request: ListDerivedTasksRequest = {
    parent,
    filter: {
      usecaseId,
      languageCode,
    },
  };
  const cancelableFetch = LabelingTaskApi.listDerivedTasks(request);
  StudioApi.setCancelCallback(CANCEL_TASKS_FETCH_CALLBACK_KEY, cancelableFetch.cancel);
  const response = await cancelableFetch.promise;
  // eslint-disable-next-line consistent-return
  return response?.derivedTasks;
});

/** AsyncThunk for creating LabelingTask. */
export const createLabelingTask = createAsyncThunk<DerivedLabelingTask, CreateTaskRequest>(Action.TASK_CREATE_LABELING_TASK, async (request: CreateTaskRequest) => {
  try {
    const derivedLabelingTaskResponse = await LabelingTaskApi.createTask(request);
    mixpanel.track(CREATE_TASK, {
      taskDescriptor: request.taskDescriptor,
    });
    return derivedLabelingTaskResponse.derivedLabelingTask;
  } catch (err) {
    openNotification('error', 'Failed to create labeling task', undefined, err);
    throw err;
  }
});

/** AsyncThunk for updating abstract task. */
export const updateAbstractTask = createAsyncThunk<AbstractTask, { abstractTask: AbstractTask, updateMask: string }>(
  Action.TASK_UPDATE_ABSTRACT_TASK,
  async (params: { abstractTask: AbstractTask, updateMask: string }) => {
    try {
      const response = await AbstractTaskApi.updateAbstractTask(params);
      return response.abstractTask;
    } catch (err) {
      openNotification('error', 'Failed to update labeling task', undefined, err);
      throw err;
    }
  },
);

/** AsyncThunk for fetching labeling task data. */
export const fetchLabelingTaskData = createAsyncThunk<FetchLabelingDataResponse, string>(Action.TASK_FETCH_LABELING_TASK_DATA, async (taskName: string) => {
  try {
    const request: FetchLabelingDataRequest = { taskName };
    return LabelingTaskApi.fetchLabelingData(request);
  } catch (err) {
    openNotification('error', 'Failed to get labeling task', undefined, err);
    throw err;
  }
});

/** AsyncThunk for fetching labeling task items */
export const fetchLabelingTaskItems = createAsyncThunk<FetchLabelingItemsResponse, FetchLabelingItemsRequest>(
  Action.TASK_FETCH_LABELING_TASK_ITEMS,
  async (request: FetchLabelingItemsRequest) => {
    try {
      return LabelingTaskApi.fetchLabelingItems(request);
    } catch (err) {
      openNotification('error', 'Failed to get labeling task', undefined, err);
      throw err;
    }
  },
);

/** AsyncThunk for fetching task annotations. */
export const fetchLabelingTaskAnnotations = createAsyncThunk<SearchAnnotationsResponseAnnotationBundle[], SearchAnnotationsRequest>(Action.TASK_FETCH_LABELING_TASK_ANNOTATIONS, async (request: SearchAnnotationsRequest) => {
  try {
    const clonedRequest = cloneDeep(request);
    const annotationBundles: SearchAnnotationsResponseAnnotationBundle[] = [];
    let pageToken = '';
    while (true) {
      const request: SearchAnnotationsRequest = {
        ...clonedRequest,
        pageToken,
      };
      // eslint-disable-next-line no-await-in-loop
      const response = await AnnotationApi.searchAnnotations(request);
      annotationBundles.push(...response.annotationBundles);
      if (!response.nextPageToken) {
        break;
      }
      pageToken = response.nextPageToken;
    }
    return annotationBundles;
  } catch (err) {
    openNotification('error', 'Failed to get labeling task annotations', undefined, err);
    throw err;
  }
});

/** AsyncThunk for completing labeling task. */
export const completeLabelingTask = createAsyncThunk<DerivedLabelingTask, string>(Action.TASK_COMPLETE_LABELING_TASK, async (taskName: string) => {
  try {
    const request: CompleteTaskRequest = { taskName, earlyComplete: true };
    const response = await LabelingTaskApi.completeTask(request);

    mixpanel.track(COMPLETE_TASK);
    return response.derivedLabelingTask;
  } catch (err) {
    openNotification('error', 'Failed to complete labeling task', undefined, err);
    throw err;
  }
});

interface UpsertTemporalAnnotationParams {
  derivedLabelingTask: DerivedLabelingTask,
  annotationValueTuples: SaveTemporalAnnotationRequestRawDataValueTuple[],
  existingAnnotations?: Annotation[],
}

/** AsyncThunk for upserting temporal annotation. */
export const upsertTemporalAnnotation = createAsyncThunk<SaveTemporalAnnotationResponse, UpsertTemporalAnnotationParams>(
  Action.TASK_UPSERT_TEMPORAL_ANNOTATION,
  async (params: UpsertTemporalAnnotationParams) => {
    const { derivedLabelingTask, annotationValueTuples, existingAnnotations } = params;
    try {
      if (existingAnnotations && existingAnnotations.length) {
        const annotationIds = existingAnnotations
          .map((existingAnnotation) => getId('annotation', existingAnnotation.name))
          .filter((id) => id);

        if (annotationIds.length) {
          await LabelingTaskApi.removeTemporalAnnotation({
            taskName: derivedLabelingTask.labelingTask.name,
            annotationIds,
          });
        }
      }

      if (annotationValueTuples.length === 0) {
        return {
          upsertedAnnotations: [],
        };
      }

      const request: SaveTemporalAnnotationRequest = {
        taskName: derivedLabelingTask.labelingTask.name,
        rawDataValueTuples: annotationValueTuples,
      };

      // Avoid sending large payloads resulting in 413 error
      if (annotationValueTuples.length > MAX_ANNOTATIONS_PER_REQUEST) {
        return await splitSaveTemporalAnnotationHelper(request, MAX_MESSAGE_PER_REQUEST);
      } else {
        return await LabelingTaskApi.saveTemporalAnnotation(request);
      }
    } catch (err) {
      const { rawData } = annotationValueTuples[0];
      const rawDataKey = getAnnotationRawDataKey(annotationValueTuples[0]);
      openNotification('error', `Failed to save annotation for message ${rawData[rawDataKey]?.v2MessageId}`, undefined, err);
      throw err;
    }
  },
);

/** AsyncThunk for removing temporal annotation. */
export const removeTemporalAnnotation = createAsyncThunk<string[], RemoveTemporalAnnotationRequest>(
  Action.TASK_REMOVE_TEMPORAL_ANNOTATION,
  async ({ annotationIds, taskName }) => {
    try {
      await LabelingTaskApi.removeTemporalAnnotation({
        taskName,
        annotationIds,
      });
      return annotationIds;
    } catch (err) {
      openNotification('error', 'Failed to remove annotation', undefined, err);
      throw err;
    }
  },
);

/** AsyncThunk for updating existing temporal annotation. */
export const updateTemporalAnnotation = createAsyncThunk<SaveTemporalAnnotationResponse, SaveTemporalAnnotationRequest>(
  Action.TASK_UPDATE_TEMPORAL_ANNOTATION,
  async (request: SaveTemporalAnnotationRequest) => {
    try {
      return await LabelingTaskApi.saveTemporalAnnotation(request);
    } catch (err) {
      openNotification('error', 'Failed to update annotations', undefined, err);
      throw err;
    }
  },
);

interface SelectNewBatchParams {
  labelingTask: LabelingTask,
  selectCount: number,
}

/** AsyncThunk for selectin new batch of messages for dynamic labeling. */
export const selectNewBatch = createAsyncThunk<SelectNewBatchResponse, SelectNewBatchParams>(Action.TASK_SELECT_NEW_BATCH, async (params: SelectNewBatchParams) => {
  try {
    const { labelingTask, selectCount } = params;
    return await LabelingTaskApi.selectNewBatch({
      taskName: labelingTask.name,
      selectCount,
    });
  } catch (err) {
    openNotification('error', 'Failed to select new batch', undefined, err);
    throw err;
  }
});

/** AsyncThunk for selectin new batch of messages for dynamic labeling. */
export const fetchConversationPredictions = createAsyncThunk<Prediction[], SearchPredictionsRequest>(Action.TASK_SEARCH_PREDICTIONS, async (params: SearchPredictionsRequest) => {
  try {
    const { resource, filter } = params;
    const request: SearchPredictionsRequest = {
      resource,
      filter,
      pageSize: 1000,
    };
    const predictions = [];
    while (true) {
      const response = await PredictionApi.searchPredictions(request);
      predictions.push(...response.predictions);
      if (!response.nextPageToken) {
        return predictions;
      }
      const { nextPageToken } = response;
      request.pageToken = nextPageToken;
    }
  } catch (err) {
    openNotification('error', 'Failed to load predictions', undefined, err);
    throw err;
  }
});
