import { createSelector } from '@reduxjs/toolkit';
import type { State } from '../store';
import type { State as JobState } from './state';

const selectJob = (state: State) => state.job;

/** Select jobs. */
export const selectAllJobs = createSelector(selectJob, (jobState: JobState) => jobState.jobs);

/** Select API status. */
export const selectApiStatus = createSelector(selectJob, (jobState: JobState) => jobState.status);

/** Fetching jobs boolean */
export const selectFetchingJobs = createSelector(selectJob, (jobState: JobState) => jobState.fetchingJobs);
