import React from 'react';
import {
  Navigate,
  Route,
  Routes as RRRoutes,
} from 'react-router-dom';
import { ModelBuilderWorkspace } from 'pages/ModelBuilder';
import { ModelWizard } from 'pages/ModelWizard/ModelWizard';
import { ModelWizardProject } from 'pages/ModelWizardProject/ModelWizardProject';
import EditRegex from 'pages/EditRegex';
import Labels from 'pages/Labels';
import LabelsReview from 'pages/LabelsReview';
import { DialogPolicy } from 'pages/DialogPolicy/DialogPolicy';
import { DeploymentSummary } from 'pages/Deployments/DeploymentSummary';
import { NewDeployment } from 'pages/Deployments/NewDeployment';
import { NewDeploymentChecklist } from 'pages/Deployments/NewDeploymentChecklist';
import { DeploymentHistory } from 'pages/Deployments/DeploymentHistory';
import { Surveyor } from 'pages/Surveyor';
import { BotMetrics } from 'pages/BotMetrics/BotMetrics';
import { VASimulator } from 'pages/VASimulator';
import TasksDashboard from 'pages/TasksDashboard';
import { NotFound } from 'pages/NotFound';
import { CustomerConfigProvider } from 'context/CustomerConfigContext';
import SubNavigation, { IntentSubNavigation } from 'components/SubNavigation';
import { TaxonomyTab } from 'pages/Taxonomy/TaxonomyGroup/TaxonomyTab';
import ChangesHistory from 'pages/ChangesHistory';
import { OutOfBoxIntents } from 'pages/OutOfBoxIntents';
import Conversations from 'pages/Conversations';
import Login from 'pages/Login';
import HexCharts from 'pages/HexCharts';
import { Versioning } from 'pages/Versioning';
import { AutoAnalysis } from 'pages/AutoAnalysis';
import CallDrivers from 'pages/AutoAnalysis/CallDrivers';
import ConversationFlow from 'pages/AutoAnalysis/ConversationFlow';
import ChatGpt from 'pages/AutoAnalysis/ChatGptPage';
import { Prompts } from 'pages/AutoAnalysis/Prompts';
import RuleGraph from 'pages/DialogPolicy/RuleGraph';
import { EditRoute } from 'pages/DialogPolicy/EditRoute';
import { Simulator } from 'pages/Simulator/Simulator';
import { SimulatorHistory } from 'pages/Simulator/SimulatorResult/SimulatorHistory';
import { TasksSummary } from 'pages/AnalysisWorkshop/TasksSummary';
import { TaggingPage } from 'pages/AnalysisWorkshop/TaggingPage';
import { NewTaskConfig } from 'pages/AnalysisWorkshop/NewTaskConfig';
import AnalysisSummary from 'pages/AnalysisWorkshop/AnalysisSummary';
import AnalysisUtteranceView from 'pages/AnalysisUtteranceView';
import LabelingTasks from 'pages/LabelingTasks';
import LabelingView from 'pages/LabelingView';
import { CalibrationTaskSummary } from 'pages/CalibrationTaskSummary';
import { AutoLabeling } from 'pages/AutoLabeling';
import QATasks from 'pages/QATasks';
import TaskModelSummaryView from 'pages/TaskModelSummaryView';
import QAUtteranceView from 'pages/QAUtteranceView';
import TaskSummaryView from 'pages/TaskSummaryView';
import QAScoreView from 'pages/QAScoreView';
import { MisclassificationReview } from 'pages/MisclassificationReview';
import { AuthenticatedRoute } from 'context/OktaAuthContext';
import { AppRoutesContainer } from 'App';
import { LastVisitedCustomerReroute } from 'pages/LastVisitedCustomerReroute';
import { IntentValidation } from 'pages/IntentValidation';

export const Routes = () => (
  <RRRoutes>
    <Route path="login" element={<Login />} />
    <Route
      path="/:customer/:profile/:usecase/:lang"
      element={(
        <AuthenticatedRoute>
          <CustomerConfigProvider>
            <AppRoutesContainer />
          </CustomerConfigProvider>
        </AuthenticatedRoute>
      )}
    >
      <Route index element={<Navigate to="my-tasks" replace />} />
      <Route path="hex" element={<HexCharts />} />
      <Route path="my-tasks" element={<TasksDashboard />} />
      <Route path="labeling">
        <Route path="tasks" element={<LabelingTasks />} />
        <Route path="view/:taskId/:labelingDataIndex" element={<LabelingView />} />
        <Route path="view/:taskId" element={<LabelingView />} />
        <Route path="calibration/:calibrationTaskId" element={<CalibrationTaskSummary />} />
        <Route path="autolabeling-config/:conceptId" element={<AutoLabeling />} />
        <Route index element={<Navigate to="tasks" replace />} />
      </Route>
      <Route path="annotations" element={<Labels />} />
      <Route path="annotations/:intentId" element={<LabelsReview />} />
      <Route path="annotations/intent-validation" element={<IntentValidation />} />
      <Route path="conversation-outcome" element={(<SubNavigation title="Conversation Outcome" tabs={[{ tab: 'Taxonomy', key: 'taxonomy' }]} />)}>
        <Route path="taxonomy" element={<TaxonomyTab />} />
        <Route index element={<Navigate to="taxonomy" />} />
      </Route>
      <Route path="entity" element={<SubNavigation title="Entity" tabs={[{ tab: 'Taxonomy', key: 'taxonomy' }]} />}>
        <Route path="taxonomy" element={<TaxonomyTab />} />
        <Route index element={<Navigate to="taxonomy" />} />
      </Route>
      <Route path="summarization" element={<SubNavigation title="Summary" tabs={[{ tab: 'Taxonomy', key: 'taxonomy' }]} />}>
        <Route path="taxonomy" element={<TaxonomyTab />} />
        <Route index element={<Navigate to="taxonomy" />} />
      </Route>
      <Route path="intent" element={<IntentSubNavigation />}>
        <Route path="taxonomy" element={<TaxonomyTab />} />
        <Route path="history" element={<ChangesHistory />} />
        <Route path="oob-intents" element={<OutOfBoxIntents />} />
        <Route index element={<Navigate to="taxonomy" replace />} />
      </Route>
      <Route path="model-builder" element={<ModelBuilderWorkspace />} />
      <Route path="model-wizard" element={<ModelWizard />} />
      <Route path="model-wizard/:projectId" element={<ModelWizardProject />} />
      <Route path="dialogue-policy" element={<DialogPolicy />}>
        <Route path="edit" element={<EditRoute />} />
        <Route path="rule-graph" element={<RuleGraph />} />
        <Route index element={<Navigate to="edit" replace />} />
      </Route>
      <Route path="qa">
        <Route path="tasks" element={<QATasks />} />
        <Route path="view/:taskId/:labelingDataIndex" element={<LabelingView />} />
        <Route path="view/:taskId" element={<LabelingView />} />
        <Route path="score-view/:taskId/model/:modelId" element={<TaskModelSummaryView />} />
        <Route path="score-view/:taskId/:conceptId" element={<QAUtteranceView />} />
        <Route path="score-view/:taskId" element={<TaskSummaryView />} />
        <Route path="score-view" element={<QAScoreView />} />
        <Route path="misclassification-review/:taskId" element={<MisclassificationReview />} />
        <Route index element={<Navigate to="tasks" replace />} />
      </Route>
      <Route path="simulator">
        <Route path="history" element={<SimulatorHistory />} />
        <Route path="simulation" element={<Simulator />} />
        <Route index element={<Navigate to="simulation" replace />} />
      </Route>
      <Route path="edit-regex/:intentId" element={<EditRegex />} />
      <Route path="deployment/summary" element={<DeploymentSummary />} />
      <Route path="deployment/history" element={<DeploymentHistory />} />
      <Route path="deployment/new" element={<NewDeployment />} />
      <Route path="deployment/checklist/:taskId" element={<NewDeploymentChecklist />} />
      <Route path="deployment" element={<Navigate to="summary" replace />} />
      <Route path="surveyor" element={<Surveyor />} />
      <Route path="bot-metrics" element={<BotMetrics/>} />
      <Route path="conversation/:chatId?" element={<Conversations />} />
      <Route path="topics" element={<AutoAnalysis />}>
        <Route path="call-drivers" element={<CallDrivers />} />
        <Route path="conversation-flow" element={<ConversationFlow />} />
        <Route path="chat-gpt" element={<ChatGpt />} />
        <Route path="prompt-manager" element={<Prompts />} />
        <Route index element={<Navigate to="call-drivers" replace />} />
      </Route>
      <Route path="analysisworkshop">
        <Route path=":taskId/analysis-summary/:conceptId" element={<AnalysisUtteranceView />} />
        <Route path=":taskId/analysis-summary" element={<AnalysisSummary />} />
        <Route path="create" element={<NewTaskConfig />} />
        <Route path=":taskId" element={<TaggingPage />} />
        <Route index element={<TasksSummary />} />
      </Route>
      <Route path="surveyor" element={<Surveyor />} />
      <Route path="bot-metrics" element={<Surveyor />} />
      <Route path="versioning" element={<Versioning />} />
      <Route path="va-simulator" element={<VASimulator/>} />
    </Route>
    <Route index element={<LastVisitedCustomerReroute />} />
    <Route path="*" element={<NotFound />} />
  </RRRoutes>
);
