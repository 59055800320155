import React from 'react';
import { Avatar } from '@mantine/core';
import { Category } from '../types';
import { NegativeIcon, PositiveIcon } from './icons';

interface IconProps {
    value: Category;
    inactive?: boolean;
}

export const Icon = ({ value, inactive }: IconProps) => {
  const getColor = () => {
    if (inactive) return 'gray';
    if (value === Category.POSITIVE) return 'green';
    if (value === Category.NEGATIVE) return 'red';
    return 'gray';
  };

  const getIcon = () => {
    if (value === Category.POSITIVE) return <PositiveIcon />;
    if (value === Category.NEGATIVE) return <NegativeIcon />;
    return null;
  };
  return (
    <Avatar
      size="xs"
      radius="xl"
      color={getColor()}
    >
      {getIcon()}
    </Avatar>
  );
};
