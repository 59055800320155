import { createAsyncThunk } from '@reduxjs/toolkit';
import { Annotation, AnnotationValueType, DataSplit } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import {
  BatchDeprecateAnnotationsRequest,
  ListAnnotationsRequest,
  SearchAnnotationsRequest,
  SearchAnnotationsRequestSearchFilter as SearchFilters,
  SearchAnnotationsResponse,
  FetchAnnotationSummaryResponseConceptAnnotationSummary as AnnotationSummary,
  FetchAnnotationSummaryRequest,
  BatchUpsertAnnotationsRequest,
  FetchCalibrationAnnotationsResponse,
  FetchCalibrationAnnotationsRequest,
} from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { AnnotationApi } from 'services/annotationApi';
import { openNotification } from 'components/Notification';
import { getId, getParent } from 'common/resourceName';
import { State } from './state';

enum Action {
  ANNOTATION_LIST_ANNOTATIONS = 'ANNOTATION_LIST_ANNOTATIONS',
  ANNOTATION_SEARCH_ANNOTATIONS = 'ANNOTATION_SEARCH_ANNOTATIONS',
  ANNOTATION_FETCH_ANNOTATION_SUMMARY = 'ANNOTATION_FETCH_ANNOTATION_SUMMARY',
  ANNOTATION_FETCH_CALIBRATION_ANNOTATIONS = 'ANNOTATION_FETCH_CALIBRATION_ANNOTATIONS',
  ANNOTATION_DEPRECATE_ANNOTATIONS = 'ANNOTATION_DEPRECATE_ANNOTATIONS',
  ANNOTATION_UPSERT_ANNOTATIONS = 'ANNOTATION_UPSERT_ANNOTATIONS',
  ANNOTATION_MODIFY_ANNOTATION = 'ANNOTATION_MODIFY_ANNOTATION',
}

const PAGE_SIZE = 500;

/** AsyncThunk for ListAnnotations API. */
export const listAnnotations = createAsyncThunk<Annotation[], {
  parent: string,
  usecase: string,
  languageCode: string,
}>(Action.ANNOTATION_LIST_ANNOTATIONS, async ({
  parent,
  usecase,
  languageCode,
}) => {
  try {
    const request: ListAnnotationsRequest = {
      parent,
      usecase,
      languageCode,
    };
    const response = await AnnotationApi.listAnnotations(request);
    return response.annotations;
  } catch (err) {
    openNotification('error', 'Failed to list annotations', undefined, err);
    throw err;
  }
});

/** AsyncThunk for FetchAnnotationSummary. */
export const fetchAnnotationSummary = createAsyncThunk<{ dataSplit: DataSplit, summary: AnnotationSummary[] }, {
  parent: string,
  usecase: string,
  languageCode: string,
}>(
  Action.ANNOTATION_FETCH_ANNOTATION_SUMMARY,
  async ({
    parent,
    usecase,
    languageCode,
  }, { getState }) => {
    const { annotation: { summaryFilters } } = getState() as { annotation: State };
    const request: FetchAnnotationSummaryRequest = {
      parent,
      filter: {
        ...summaryFilters,
        usecase,
        languageCode,
      },
      includeCorpusMetrics: true,
    };
    let dataSplit = DataSplit.DATA_SPLIT_UNSPECIFIED;
    if (summaryFilters.splits?.length === 1) {
      const { splits: [split] } = summaryFilters;
      dataSplit = split;
    }
    try {
      const response = await AnnotationApi.fetchAnnotationSummary(request);
      return { dataSplit, summary: response.conceptAnnotationSummaries };
    } catch (err) {
      openNotification('error', 'Failed to search annotations', undefined, err);
      throw err;
    }
  },
);

/** AsyncThunk for FetchCalibrationAnnotations given the calibration task name. */
export const fetchCalibrationAnnotations = createAsyncThunk<{ taskId: string; response: FetchCalibrationAnnotationsResponse }, string>(
  Action.ANNOTATION_FETCH_CALIBRATION_ANNOTATIONS,
  async (name: string, { getState }) => {
    const { annotation: { calibrationSummary } } = getState() as { annotation: State};
    const taskId = getId('labelingTask', name);
    const parent = getParent('labelingTask', name);
    const request: FetchCalibrationAnnotationsRequest = {
      parent,
      calibrationTaskId: taskId,
      pageToken: taskId === calibrationSummary.calibrationTaskId ? calibrationSummary.nextPageToken : undefined,
      pageSize: PAGE_SIZE,
    };
    try {
      const response = await AnnotationApi.fetchCalibrationAnnotations(request);
      return {
        taskId,
        response,
      };
    } catch (err) {
      openNotification('error', 'Failed to fetch calibration annotations', undefined, err);
      throw err;
    }
  },
);

/** AsyncThunk for SearchAnnotations. */
export const searchAnnotations = createAsyncThunk<SearchAnnotationsResponse, {
  parent,
  usecase,
  languageCode,
}>(Action.ANNOTATION_SEARCH_ANNOTATIONS, async ({
  parent,
  usecase,
  languageCode,
}, { getState }) => {
  const { annotation: { annotationFilters, nextPageToken } } = getState() as { annotation: State };
  const filters: SearchFilters = {
    conceptIds: annotationFilters.conceptIds,
    conceptType: annotationFilters.conceptType,
    states: annotationFilters.states,
    values: annotationFilters.labels?.map((value) => ({ valueType: AnnotationValueType.TYPE_BINARY, binaryValue: value })),
    taskIds: annotationFilters.taskIds,
    split: annotationFilters.dataSplit,
    textContain: annotationFilters.textContain,
    detailedTypeFilter: annotationFilters.detailedTypeFilter,
  };
  const request: SearchAnnotationsRequest = {
    parent,
    searchFilter: {
      ...filters,
      usecase,
      languageCode,
    },
    pageSize: PAGE_SIZE,
    pageToken: nextPageToken,
    includeCorpusMetrics: true,
  };
  try {
    return await AnnotationApi.searchAnnotations(request);
  } catch (err) {
    openNotification('error', 'Failed to search annotations', undefined, err);
    throw err;
  }
});

/** AsyncThunk for deprecating annotations. */
export const deprecateAnnotations = createAsyncThunk<Annotation[], BatchDeprecateAnnotationsRequest>(Action.ANNOTATION_DEPRECATE_ANNOTATIONS, async (request: BatchDeprecateAnnotationsRequest) => {
  try {
    const response = await AnnotationApi.batchDeprecateAnnotations(request);
    return response.deprecatedAnnotations;
  } catch (err) {
    openNotification('error', 'Failed to deprecate annotations', undefined, err);
    throw err;
  }
});

/** AsyncThunk for upserting annotations. */
export const upsertAnnotations = createAsyncThunk<Annotation[], BatchUpsertAnnotationsRequest>(Action.ANNOTATION_UPSERT_ANNOTATIONS, async (request: BatchUpsertAnnotationsRequest) => {
  try {
    const { parent, annotations } = request;
    await AnnotationApi.batchUpsertAnnotations({
      parent,
      annotations,
    });
    return annotations;
  } catch (err) {
    openNotification('error', 'Failed to upsert annotations', undefined, err);
    throw err;
  }
});
