import { Box, Group, MultiSelect, SelectItem, Title, Paper, Button, Text, createStyles, Select, Switch } from '@mantine/core';
import { PageContainer } from 'components/PageContainer';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'hooks/reduxHooks';
import { selectManualAnalysisLabelingTasks } from 'store/labelingTask/selectors';
import { getId } from 'common/resourceName';
import { Concept, ConceptConceptType, TagTagType } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { selectAllConcepts, selectConceptsFactory } from 'store/concept/selectors';
import { selectSets } from 'store/set/selectors';
import { ChatBox } from 'pages/AnalysisWorkshop/TaggingPage/components/ChatBox';
import { ConversationAnnotations, Message } from 'pages/AnalysisWorkshop/TaggingPage/components/Message';
import { useLabelingItems } from 'hooks/useLabelingItems';
import { useCustomerProfile } from 'hooks/useCustomerParams';
import { selectDebuggingMode } from 'store/app/selectors';
import { UITagType } from 'pages/AnalysisWorkshop/TaggingPage';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { EdgeEdgeConditionType, FlowNodesCollectionTaskMessage } from '@cresta/web-client/dist/cresta/v1/studio/flownode/flownode_service.pb';
import { StagesProvider } from 'pages/AnalysisWorkshop/TaggingPage/StagesProvider';
import { AnnotationValueType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import useUrlParam from 'hooks/useUrlParam';
import { ManualAnalysisTag } from 'pages/AnalysisWorkshop/components/Badge';
import { RoleIcon } from 'components/RoleIcon';
import { FlowNodesTreeNode } from './utils';
import FlowNodesTree, { PathConditionType } from './FlowNodesTree';

export function getSelectTagIcon(tagType: TagTagType) {
  switch (tagType) {
    case TagTagType.DRIVER:
      return <RoleIcon conceptRole="driver"/>;
    case TagTagType.STAGE:
      return <RoleIcon conceptRole="stage"/>;
    case TagTagType.STAGE_BEGIN:
      return <RoleIcon conceptRole="stage-begin"/>;
    case TagTagType.STAGE_END:
      return <RoleIcon conceptRole="stage-end"/>;
    default:
      return null;
  }
}

const nextEdgeData: SelectItem[] = [
  {
    label: 'Next immediate',
    value: EdgeEdgeConditionType.NEXT_IMMEDIATE,
  },
  {
    label: 'Next stage begin',
    value: EdgeEdgeConditionType.NEXT_STAGE_BEGIN,
  },
  {
    label: 'Next stage end',
    value: EdgeEdgeConditionType.NEXT_STAGE_END,
  },
  {
    label: 'Next agent intent',
    value: EdgeEdgeConditionType.NEXT_AGENT_INTENT,
  },
  {
    label: 'Next visitor intent',
    value: EdgeEdgeConditionType.NEXT_VISITOR_INTENT,
  },
];

const PATH_CONDITION_OPTIONS: SelectItem[] = [
  {
    label: 'Full path',
    value: PathConditionType.FULL_PATH,
  },
  {
    label: 'Selected node',
    value: PathConditionType.SINGLE_NODE,
  },
];

// Tag select item
const BlockSelectItem = forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const { label, value, type, ...other } = props;
    const tag = props.tag as UITagType;
    return (
      <div {...other} ref={ref} key={value}>
        <Group spacing="xs" noWrap>
          {getSelectTagIcon(tag?.tagType)}
          <ManualAnalysisTag tagType={tag?.tagType}>{label}</ManualAnalysisTag>
        </Group>
      </div>
    );
  },
);

// Tag select passed to the root (begin) node
function BeginNode({
  beginNodeTags,
  handleSelectTag,
  tagsMap,
}: {
  beginNodeTags: UITagType[],
  handleSelectTag?: (tag: UITagType) => void,
  tagsMap: Map<string, UITagType>,
}) {
  const selectTagOptions: SelectItem[] = useMemo(() => beginNodeTags?.map((tag) => ({
    label: tag.label,
    value: tag.value,
    tag,
  })) || [], [beginNodeTags]);

  return (
    <Group style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
      <Select
        size="sm"
        style={{ width: '100%' }}
        data={selectTagOptions}
        placeholder="Select begin block"
        withinPortal
        searchable
        onChange={(option) => handleSelectTag(tagsMap?.get(option))}
        itemComponent={BlockSelectItem}
      />
    </Group>
  );
}

export function Surveyor() {
  const featureFlag = useSelector<boolean>(selectDebuggingMode);
  const derivedLabelingTasks = useSelector(selectManualAnalysisLabelingTasks);
  const tasksData: SelectItem[] = derivedLabelingTasks.map((task) => ({
    label: task.labelingTask.abstractTask.title,
    value: getId('labelingTask', task.labelingTask.name),
  }));
  const [selectedTasksFilter, setSelectedTasksFilter] = useUrlParam<string[]>('tasks', []);
  const [selectedDriversFilter, setSelectedDriversFilter] = useState<string[]>([]);
  const [selectedOutcomesFilter, setSelectedOutcomesFilter] = useState<string[]>([]);
  const [selectedSetsFilter, setSelectedSetsFilter] = useState<string[]>([]);
  const sets = useSelector(selectSets);
  const customerProfile = useCustomerProfile();
  const [chatIndex, setChatIndex] = useState(0);
  const [nextEdgeType, setNextEdgeType] = useState<EdgeEdgeConditionType>(EdgeEdgeConditionType.NEXT_STAGE_BEGIN);
  const [taskMessages, setTaskMessages] = useState<FlowNodesCollectionTaskMessage[]>();
  const [pathCondition, setPathCondition] = useState<PathConditionType>(PathConditionType.SINGLE_NODE);
  const [chatPreviewVisible, setChatPreviewVisible] = useState(false);

  // If on and selected tag is STAGE_BEGIN, it adds STAGE_END to flow nodes collection after
  const [autoIncludeStageEnd, setAutoIncludeStageEnd] = useState(false);
  const treeContainerRef = useRef<HTMLDivElement>(null);

  const treeContainerDimensions = useMemo(() => {
    if (!treeContainerRef?.current) {
      return { width: 0, height: 0 };
    } else {
      return {
        width: treeContainerRef.current.clientWidth,
        height: treeContainerRef.current.clientHeight,
      };
    }
  }, [treeContainerRef?.current]);

  // Tree root node
  const [root, setRoot] = useState<FlowNodesTreeNode>(
    new FlowNodesTreeNode({
      nodeName: 'BEGIN',
      flowNode: {
        conceptName: `${customerProfile}/concepts/begin_concept_id`,
      },
    }, null),
  );

  const useStyles = createStyles((theme) => ({
    scrollAreaClass: {
      padding: theme.spacing.md,
    },
    chatBoxHeader: {
      borderBottom: `1px solid ${theme.colors.gray[0]}`,
    },
  }));
  const styles = useStyles();

  const tagConcepts = useSelector(selectConceptsFactory(ConceptConceptType.TAG));

  // Sets all tags in profile to be selected
  const tagsInProfile = useMemo(() => {
    if (tagConcepts.length === 0) return [];
    const mappedTags: UITagType[] = tagConcepts.map((concept) => {
      const conceptId = getId('concept', concept.name);
      return {
        value: conceptId,
        label: concept.conceptTitle,
        tagType: concept.tag.tagType,
      };
    });
    return mappedTags;
  }, [tagConcepts]);

  // All namespace tags map
  const tagsMap = new Map<string, UITagType>(tagsInProfile.map((tag) => [tag.value, tag]));

  // Tasks selected in the filter
  const selectedDerivedTasks = useMemo(() => derivedLabelingTasks.filter(
    (task) => (selectedTasksFilter || []).includes(getId('labelingTask', task.labelingTask.name)),
  ), [selectedTasksFilter, derivedLabelingTasks]);

  // Tags of the tasks selected in the filter
  const selectedDerivedTasksTags = useMemo(() => {
    const selectedTagsMap = new Map<string, UITagType>();
    selectedDerivedTasks.forEach((task) => {
      task.labelingTask.taskData.taskDescriptor.conceptIds?.forEach((conceptId) => {
        const tag = tagsMap.get(conceptId);
        if (tag) {
          selectedTagsMap.set(conceptId, tag);
        }
      });
    });

    return Array.from(selectedTagsMap.values());
  }, [tagsMap, selectedDerivedTasks]);

  const concepts = useSelector<Concept[]>(selectAllConcepts);

  const [selectedNodePath, setSelectedNodePath] = useState<FlowNodesTreeNode[]>([]);

  const handleSelectNodePath = (path: FlowNodesTreeNode[]) => {
    setSelectedNodePath(path);
  };

  const handleLoadMessages = (node: FlowNodesTreeNode) => {
    setChatIndex(0);
    setTaskMessages(node.flowNodeCollection?.taskMessages);
    setChatPreviewVisible(true);
  };

  const displayedChat = useMemo(() => (taskMessages ? taskMessages[chatIndex] : null), [taskMessages, chatIndex]);

  const [labelingItems, labelingItemsLoading] = useLabelingItems(`${customerProfile}/labelingTasks/${displayedChat?.taskId}`, displayedChat?.conversationPositionNumber);

  const messages = useMemo(() => labelingItems?.conversationLabelingItems[0]?.messagesAndContexts || [], [labelingItems]);

  // Scroll to active message
  useEffect(() => {
    setTimeout(() => {
      const messageElement = document.querySelector('[data-active="true"]');
      if (messageElement) {
        messageElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'end',
        });
      }
    }, 0);
  }, [messages]);

  // Convert annotations to ConversationAnnotations type required by the Message component
  const conversationAnnotations: ConversationAnnotations[] = useMemo(() => {
    const annotations = labelingItems?.temporalAnnotations || [];
    const filteredAnnotations = annotations.filter((annotation) => {
      const isCurrentSet = annotation?.value?.setId === taskMessages?.[chatIndex]?.setId;
      const activeConversationId = getId('conversation', taskMessages?.[chatIndex]?.messageNames);
      const isCurrentConversation = annotation?.rawData?.messageRawData?.conversationId === activeConversationId;
      return isCurrentSet && isCurrentConversation;
    });
    const reducedAnnotations: {
      messageId: string;
      conceptIds: string[]
    }[] = filteredAnnotations.reduce((acc, annotation) => {
      const conceptType = annotation.value?.type;
      if (conceptType === AnnotationValueType.TYPE_CONVERSATION_STAGE) {
        // handle Stage Annotations
        const conceptId = annotation.value.conversationStageValue?.stageConceptId;
        const beginMessageId = annotation.value.conversationStageValue?.stageBeginMessageId;
        const existingAnnotation = acc.find((a) => a.messageId === beginMessageId);
        if (existingAnnotation) {
          existingAnnotation.conceptIds.push(conceptId);
        } else {
          acc.push({ messageId: beginMessageId, conceptIds: [conceptId] });
        }
      } else {
        const messageId = annotation.rawData?.messageRawData?.messageId;
        const conceptId = annotation.value?.binaryValue?.conceptId;
        const existingAnnotation = acc.find((a) => a.messageId === messageId);
        if (existingAnnotation) {
          existingAnnotation.conceptIds.push(conceptId);
        } else {
          acc.push({ messageId, conceptIds: [conceptId] });
        }
      }
      return acc;
    }, []);
    return reducedAnnotations;
  }, [labelingItems?.temporalAnnotations]);

  // For multiselect
  const setsData: SelectItem[] = sets.map((set) => ({
    label: set.setTitle,
    value: getId('set', set.name),
  }));
  const [driversData, outcomesData] = useMemo(() => {
    const driverConcepts: SelectItem[] = [];
    const outcomeConcepts: SelectItem[] = [];

    (concepts || []).forEach((concept) => {
      if (concept.tag?.tagType === TagTagType.DRIVER) {
        driverConcepts.push({
          label: concept.conceptTitle,
          value: getId('concept', concept.name),
        });
      }
      if (concept.tag?.tagType === TagTagType.CONVERSATION_OUTCOME) {
        outcomeConcepts.push({
          label: concept.conceptTitle,
          value: getId('concept', concept.name),
        });
      }
    });
    return [driverConcepts, outcomeConcepts];
  }, [concepts]);

  const prevChat = () => {
    setChatIndex((prev) => Math.max(prev - 1, 0));
  };

  const nextChat = () => {
    setChatIndex((prev) => Math.min(prev + 1, taskMessages?.length - 1));
  };

  const handleSetRootTag = (tag: UITagType) => {
    const newRoot = new FlowNodesTreeNode({
      nodeName: tag.label,
      flowNode: {
        conceptName: `${customerProfile}/concepts/${tag.value}`,
        tagType: tag.tagType === TagTagType.STAGE ? TagTagType.STAGE_BEGIN : tag.tagType,
      },
    }, null);

    setRoot(newRoot);
  };

  return (
    <PageContainer>
      <Title>Surveyor</Title>
      <Group position="apart">
        <Select
          value={nextEdgeType}
          data={nextEdgeData}
          onChange={(value: EdgeEdgeConditionType) => setNextEdgeType(value)}
        />
        <Group>
          {featureFlag && (
            <Switch label="Stage behaviors" checked={autoIncludeStageEnd} onChange={(e) => setAutoIncludeStageEnd(e.target.checked)} />
          )}
          <Select
            data={PATH_CONDITION_OPTIONS}
            value={pathCondition}
            style={{ width: 140 }}
            onChange={(value) => setPathCondition(value as PathConditionType)}
          />
          <MultiSelect
            placeholder="All drivers"
            clearable
            searchable
            style={{ width: 180 }}
            data={driversData}
            value={selectedDriversFilter}
            onChange={setSelectedDriversFilter}
          />
          <MultiSelect
            placeholder="All outcomes"
            clearable
            searchable
            style={{ width: 180 }}
            data={outcomesData}
            value={selectedOutcomesFilter}
            onChange={setSelectedOutcomesFilter}
          />
          <MultiSelect
            placeholder="All sets"
            clearable
            searchable
            style={{ width: 180 }}
            data={setsData}
            value={selectedSetsFilter}
            onChange={setSelectedSetsFilter}
          />
          <MultiSelect
            placeholder="Tasks"
            clearable
            data={tasksData}
            searchable
            style={{ width: 240 }}
            value={selectedTasksFilter}
            onChange={setSelectedTasksFilter}
          />
        </Group>
      </Group>
      <Group
        noWrap
        align="top"
        style={{
          overflow: 'hidden',
        }}
      >
        <Box style={{ width: '100%', height: 900, overflow: 'hidden' }} ref={treeContainerRef}>
          <FlowNodesTree
            width={treeContainerDimensions.width}
            height={treeContainerDimensions.height}
            taskIds={selectedTasksFilter}
            driverTagIds={selectedDriversFilter}
            outcomeTagIds={selectedOutcomesFilter}
            setIds={selectedSetsFilter}
            selectedNodePath={selectedNodePath}
            setSelectedNodePath={handleSelectNodePath}
            handleLoadMessages={handleLoadMessages}
            nextEdgeType={nextEdgeType}
            root={root}
            setRoot={setRoot}
            pathCondition={pathCondition}
            autoIncludeStageEnd={autoIncludeStageEnd}
            rootElement={(
              <BeginNode
                beginNodeTags={selectedDerivedTasksTags}
                tagsMap={tagsMap}
                handleSelectTag={(tag) => handleSetRootTag(tag)}
              />
            )}
          />
        </Box>
        <Paper
          style={{
            width: 750,
            maxHeight: 900,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {chatPreviewVisible && (
            <>
              {taskMessages?.length > 0 && (
              <Group p="lg" className={styles.classes.chatBoxHeader} position="apart">
                <Group>
                  <Button size="sm" color="gray" variant="light" disabled={chatIndex === 0} onClick={prevChat}>
                    <span>
                      <LeftOutlined />
                    </span>
                  </Button>
                  <Text>{`${chatIndex + 1} / ${taskMessages?.length}`}</Text>
                  <Button size="sm" color="gray" variant="light" disabled={chatIndex === taskMessages?.length - 1} onClick={nextChat}>
                    <span>
                      <RightOutlined />
                    </span>
                  </Button>
                </Group>
                <Button
                  variant="subtle"
                  color="gray"
                  onClick={() => setChatPreviewVisible(false)}
                >
                  <CloseOutlined/>
                </Button>
              </Group>
              )}
              <StagesProvider
                messages={messages}
                annotations={labelingItems?.temporalAnnotations || []}
              >
                <ChatBox
                  isLoading={labelingItemsLoading}
                  messages={messages}
                  scrollAreaClass={styles.classes.scrollAreaClass}
                  scrollPosition="top"
                  MessageComponent={({ message, index }) => (
                    <Message
                      conversationAnnotations={conversationAnnotations}
                      tagsMap={tagsMap}
                      key={`${message.v2ConversationId}:${message.v2MessageId}`}
                      index={index}
                      message={message}
                      isActive={getId('conversationMessage', displayedChat?.messageNames) === message.v2MessageId}
                    />
                  )}
                />
              </StagesProvider>
            </>
          )}
        </Paper>
      </Group>
    </PageContainer>
  );
}
