/* eslint-disable react/no-array-index-key */
import { FetchCandidateSuggestionTextsResponseCannedSuggestion } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation_service.pb';
import { Popover, Box, Textarea } from '@mantine/core';
import { getHotkeyHandler, useHotkeys } from '@mantine/hooks';
import { SuggestionBox } from 'components/LabelingItemsDisplay/SuggestionBox';
import React, { useCallback, useEffect, useState } from 'react';

interface SuggestionsAutocompleteProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  suggestion: FetchCandidateSuggestionTextsResponseCannedSuggestion;
  setSuggestion: (suggestion: FetchCandidateSuggestionTextsResponseCannedSuggestion) => void;
  suggestions: FetchCandidateSuggestionTextsResponseCannedSuggestion[];
}

export function SuggestionsAutocomplete({
  visible,
  setVisible,
  suggestion,
  setSuggestion,
  suggestions,
}: SuggestionsAutocompleteProps) {
  const [navigateCursor, setNavigateCursor] = useState(null);

  const handlePopoverNavigate = useCallback((direction: number) => {
    if (!visible) return;
    const nextDirection = navigateCursor === null && direction === 1 ? 0 : navigateCursor + direction;
    if (nextDirection < 0) {
      setNavigateCursor(null);
    } else if (nextDirection <= suggestions?.length) {
      setNavigateCursor(nextDirection);
    }
  }, [navigateCursor, setNavigateCursor, suggestions, visible]);

  const handleClose = useCallback(() => {
    setNavigateCursor(null);
    setVisible(false);
  }, [setNavigateCursor, setVisible]);

  const handleConfirmSelection = useCallback(() => {
    const suggestion = suggestions[navigateCursor];
    if (suggestion) {
      setSuggestion(suggestion);
      handleClose();
    }
  }, [navigateCursor]);

  useHotkeys([
    ['ArrowUp', (e) => {
      e.preventDefault();
      handlePopoverNavigate(1);
    }],
    ['ArrowDown', (e) => {
      e.preventDefault();
      handlePopoverNavigate(-1);
    }],
    ['Enter', (e) => {
      e.preventDefault();
      handleConfirmSelection();
    }],
  ]);

  // Scroll concepts list when using hotkeys
  useEffect(() => {
    const suggestions = document.querySelectorAll('.suggestions-autocomplete .suggestion-box');
    const activeSuggestion = suggestions[navigateCursor];
    if (activeSuggestion) {
      activeSuggestion.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [navigateCursor]);

  return (
    <Popover
      styles={{
        dropdown: {
          maxHeight: 300,
          overflowY: 'auto',
          overflowX: 'hidden',
        },
      }}
      opened={visible}
      position="bottom"
      width="100%"
      shadow="md"
    >
      <Popover.Target>
        <Box
          onFocusCapture={() => setVisible(true)}
        >
          <Textarea
            mb="lg"
            placeholder="Enter an ideal suggestion that should appear at this message"
            value={suggestion?.suggestionText}
            autosize
            onBlurCapture={handleClose}
            onKeyDown={getHotkeyHandler([
              ['ArrowUp', () => handlePopoverNavigate(-1)],
              ['ArrowDown', () => {
                if (!visible) {
                  setVisible(true);
                } else {
                  handlePopoverNavigate(1);
                }
              }],
              ['Enter', () => handleConfirmSelection()],
              ['Escape', () => handleClose()],
            ])}
            onChange={(e) => setSuggestion({
              cannedSuggestionDocId: undefined,
              cannedSuggestionDocName: undefined,
              suggestionText: e.currentTarget.value,
            })}
          />
        </Box>
      </Popover.Target>
      {suggestions?.length > 0 && (
        <Popover.Dropdown onBlurCapture={handleClose} className="suggestions-autocomplete">
          {suggestions.map((suggestion, index) => (
            <SuggestionBox
              key={`${suggestion.cannedSuggestionDocId}:${index}`}
              text={suggestion.suggestionText}
              active={index === navigateCursor}
              onClick={() => {
                setSuggestion(suggestion);
                handleClose();
              }}
            />
          ))}
        </Popover.Dropdown>
      )}
    </Popover>
  );
}
