import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { MomentAnnotationType } from '@cresta/web-client/dist/cresta/ai_service/common';
import { AnnotationValueType, BinaryValueValue, TargetType } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { DerivedLabelingTask, UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { Table, Select } from 'antd';
import classNames from 'classnames';
import { getId } from 'common/resourceName';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { selectConceptsFactory } from 'store/concept/selectors';
import { fetchLabelingTaskAnnotations } from 'store/labelingTask/asyncThunks';
import { selectDerivedQaTasks } from 'store/labelingTask/selectors';
import TaskTypeTag from 'components/TaskTypeTag';
import useUrlParam from 'hooks/useUrlParam';
import SearchInput from 'components/SearchInput';
import ItemPicker from 'components/ItemPicker';
import IntentTag from 'components/MultiTags';
import { Concept, ConceptConceptType, ConceptState } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { useDebounce } from 'hooks/useDebounce';
import { TaskStatus } from '@cresta/web-client/dist/cresta/v1/studio/tasks/tasks.pb';
import Loading from 'components/Loading';
import { MomentAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/moment/moment_annotation.pb';
import { ActionAnnotationAdherenceType } from '@cresta/web-client/dist/cresta/v1/action/action_annotation.pb';
import { Button } from '@mantine/core';
import { ConfustionTypeFilter } from 'types';
import { useCustomerProfile, useCustomerParams } from 'hooks/useCustomerParams';
import styles from './styles.module.scss';
import UtteranceIntentPredictionTable from './UtteranceTables/UtteranceIntentPredictionTable';
import UtteranceIntentRecallTable from './UtteranceTables/UtteranceIntentRecallTable';
import UtterancePolicyTable from './UtteranceTables/UtterancePolicyTable';
import UtteranceSuggestionsTable from './UtteranceTables/UtteranceSuggestionsTable';

export function getAdherenceTypes(taskType: UserLabelingTaskType): MomentAnnotationAdherenceType[] {
  switch (taskType) {
    case UserLabelingTaskType.QA_POLICY: {
      return [MomentAnnotationAdherenceType.ADHERENCE_TYPE_SHOULD_DO_X];
    }
    default: {
      return [MomentAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED];
    }
  }
}

export default function QAUtteranceView() {
  const dispatch = useDispatch();
  const params = useParams<{taskId: string, conceptId: string}>();
  const { taskId, conceptId } = params;
  const customer = useCustomerParams();

  const [searchTerm, setSearchTerm] = useState('');
  const customerProfile = useCustomerProfile();
  const [labelFilter, setLabelFilter, clearLabelFilter] = useUrlParam<BinaryValueValue | ''>('labelFilter', '');
  const [confusionTypeFilter, setConfustionTypeFilter, clearConfustionTypeFilter] = useUrlParam<ConfustionTypeFilter | ''>('confusionTypeFilter', '');
  const navigate = useNavigate();

  // Handle search text change with debounce.
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 300);

  // Completed, non-dual tasks.
  const tasks = useSelector<DerivedLabelingTask[]>(selectDerivedQaTasks)
    .filter((t) => t.labelingTask.abstractTask.status === TaskStatus.TASK_COMPLETED);
  const task = tasks.find((t) => getId('labelingTask', t.labelingTask.name) === taskId);
  // IntentsMap for intents in the summary.
  const concepts = useSelector<Concept[]>(selectConceptsFactory(ConceptConceptType.INTENT));
  const conceptsMap = new Map<string, {conceptId: string, conceptTitle: string}>(concepts.filter((concept) => {
    // Some tasks have empty concept ids in task descriptor (e.g. policy, recall)
    if ([
      UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
      UserLabelingTaskType.QA_POLICY,
      UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION,
    ].includes(task?.userLabelingTaskType)) {
      return true;
    }

    // Only concepts for this task
    const conceptId = getId('concept', concept.name);
    const isConceptInActiveTask = task?.labelingTask.taskData.taskDescriptor.conceptIds.includes(conceptId);
    const isActiveConcept = concept.intent && concept.state !== ConceptState.DEPRECATED;
    return isActiveConcept && isConceptInActiveTask;
  })
    .map((concept) => {
      const conceptId = getId('concept', concept.name);
      return [conceptId, { conceptId, conceptTitle: concept.conceptTitle }];
    }));

  // Current selected intent.
  const intent = conceptsMap.get(conceptId)?.conceptTitle || '';
  const intents = Array.from(conceptsMap.values());

  const showingSDX = task?.userLabelingTaskType === UserLabelingTaskType.QA_POLICY;

  // Some QA task (recall, policy) use confusion types (combination of predictions and labels)
  const isConfusionTypeTask = [
    UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL,
    UserLabelingTaskType.QA_POLICY,
    UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION,
  ].includes(task?.userLabelingTaskType);

  const searchFilter = {
    taskIds: taskId ? [taskId] : [],
    conceptIds: conceptId ? [conceptId] : [],
    textContain: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
    values: labelFilter ? [{
      valueType: AnnotationValueType.TYPE_BINARY,
      binaryValue: labelFilter,
    }] : [],
    detailedTypeFilter: {
      targetType: TargetType.QA,
      momentAnnotationTypes: [MomentAnnotationType.INTENT],
      momentAdherenceTypes: getAdherenceTypes(task?.userLabelingTaskType),
      actionAdherenceTypes: [ActionAnnotationAdherenceType.ADHERENCE_TYPE_UNSPECIFIED],
    },
  };

  // Special case for intents recall QA - annotations have empty conceptId
  if (task?.userLabelingTaskType === UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL
      || task?.userLabelingTaskType === UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION) {
    searchFilter.conceptIds = [];
  }

  const fetchSummary = async () => {
    dispatch(fetchLabelingTaskAnnotations({
      parent: customerProfile,
      searchFilter,
      pageSize: 1000,
    }));
  };

  useEffect(() => {
    fetchSummary();
  }, [taskId, conceptId, labelFilter, debouncedSearchTerm]);

  const renderTable = () => {
    switch (task?.userLabelingTaskType) {
      case UserLabelingTaskType.QA_INTENT_PREDICTION:
        return <UtteranceIntentPredictionTable searchTerm={searchTerm} taskId={taskId}/>;
      case UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL:
      case UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION:
        return <UtteranceIntentRecallTable searchTerm={searchTerm} taskId={taskId} conceptId={conceptId}/>;
      case UserLabelingTaskType.QA_POLICY:
        return <UtterancePolicyTable searchTerm={searchTerm} taskId={taskId} conceptId={conceptId}/>;
      case UserLabelingTaskType.QA_SUGGESTIONS:
        return <UtteranceSuggestionsTable searchTerm={searchTerm} taskId={taskId}/>;
      default:
        return <Table className={styles.summaryTable} columns={[]} dataSource={[]}/>;
    }
  };

  const selectFilterOptions = [
    {
      label: 'All results',
      value: '',
    },
    {
      label: 'True Positive',
      value: BinaryValueValue.VALUE_POSITIVE,
    },
    {
      label: 'False Positive',
      value: BinaryValueValue.VALUE_NEGATIVE,
    },
    {
      label: 'Skip',
      value: BinaryValueValue.VALUE_SKIP,
    },
    {
      label: 'Unsure',
      value: BinaryValueValue.VALUE_FLAG_FOR_REVIEW,
    },
  ];

  // Some QA types have different filters - filter on both QA and prediction value
  const selectConfusionTypeFilterOptions: {label: string, value: ConfustionTypeFilter | ''}[] = [
    {
      label: 'All results',
      value: '',
    },
    {
      label: 'True Positive',
      value: 'TP',
    },
    {
      label: 'False Positive',
      value: 'FP',
    },
    {
      label: 'True Negative',
      value: 'TN',
    },
    {
      label: 'False Negative',
      value: 'FN',
    },
    {
      label: 'Correct',
      value: 'CORRECT',
    },
    {
      label: 'Incorrect',
      value: 'INCORRECT',
    },
  ];

  return (
    <div className={classNames(['studio-page', styles.wrapper])}>
      <Link to=".." relative="path" className={styles.backBtn}>
        <ArrowLeftOutlined /> Back to Summary
      </Link>
      {showingSDX && (
        <h1>Predicted SDX:</h1>
      )}
      <div className={styles.header}>
        <ItemPicker
          items={intents}
          idKeyPath="intentId"
          value=""
          renderItem={(item) => <IntentTag tags={[item?.conceptTitle]} />}
          filterKey={(item) => item.conceptTitle}
          placeholder="Select"
          onChange={(item) => navigate({ pathname: item.conceptId.toString() })}
        >
          <Button className={styles.dropdownIcon} compact size="lg" variant="white"><UnorderedListOutlined /></Button>
        </ItemPicker>
        <h2>{intent}</h2>
        <div className={styles.taskSelectContainer}>
          <Select
            className={styles.select}
            showArrow
            size="large"
            value={taskId == null ? 'From all QA tasks' : `Task ID ${taskId.substring(0, 12)}...`}
            onChange={(id) => {
              const url = `/${customer.path}/qa/score-view/${id}/${conceptId}`;
              navigate(url);
            }}
          >
            {
            tasks.map((task) => {
              const id = getId('labelingTask', task.labelingTask.name);
              return (
                <Select.Option key={id} value={id}>
                  <div className={styles.selectOption}>
                    <span>Task ID {id.substring(0, 6)}...</span>
                    <span className={styles.spacer} />
                    <TaskTypeTag taskType={task.userLabelingTaskType} />
                  </div>
                </Select.Option>
              );
            })
          }
          </Select>
        </div>
      </div>
      <div className={styles.filters}>
        <div className={styles.selectContainer}>
          {isConfusionTypeTask ? (
            <Select
              size="large"
              className={styles.select}
              showArrow
              options={selectConfusionTypeFilterOptions}
              defaultValue=""
              value={confusionTypeFilter}
              onChange={(val: (ConfustionTypeFilter | '')) => {
                if (val === '') {
                  clearConfustionTypeFilter();
                } else {
                  setConfustionTypeFilter(val);
                }
              }}
            />
          ) : (
            <Select
              size="large"
              className={styles.select}
              showArrow
              options={selectFilterOptions}
              defaultValue=""
              value={labelFilter}
              onChange={(val: (BinaryValueValue | '')) => {
                if (val === '') {
                  clearLabelFilter();
                } else {
                  setLabelFilter(val);
                }
              }}
            />
          )}
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            placeholder="Search by Message"
            clearable
            onChange={(val) => setSearchTerm(val)}
            value={searchTerm}
          />
        </div>
      </div>
      <div className={styles.content}>
        {task ? renderTable() : <Loading/>}
      </div>
    </div>
  );
}
