import { FetchNoMatchSummaryResponseNoMatchDetail } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { Box, Text, Group, Select, SimpleGrid, Title, Button } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { ColumnDef } from '@tanstack/react-table';
import { DataGrid } from 'components/DataGrid';
import { PageContainer } from 'components/PageContainer';
import dayjs from 'dayjs';
import { round } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PredictionApi } from 'services/predictionApi';
import { downloadDataString } from 'utils/data-export';
import * as CSV from 'csv-string';
import { openNotification } from 'components/Notification';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';

interface NoMatchTableDataRow {
  flow: string;
  version: string;
  page: string;
  utterance: string;
  count: number;
  percentage: number;
}

const DATE_RANGE_DIFF: { val: number, unit: dayjs.ManipulateType } = {
  val: 1,
  unit: 'years',
};

export function BotMetrics() {
  const customer = useCustomerParams();
  const dateNow = new Date();
  const customerProfile = useCustomerProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    dayjs(dateNow).subtract(DATE_RANGE_DIFF.val, DATE_RANGE_DIFF.unit).toDate(),
    dateNow,
  ]);
  const [noMatchDetail, setNoMatchDetail] = useState<FetchNoMatchSummaryResponseNoMatchDetail[]>([]);
  const [filter, setFilter] = useState<{
    flow: string;
    version: string;
    page: string;
  }>({
    flow: '',
    version: '',
    page: '',
  });

  // Page filter options
  const pageFilterData = useMemo(() => {
    const pageNames = new Set<string>();
    noMatchDetail.forEach((detail) => pageNames.add(detail.pageName));
    return Array.from(pageNames).map((pageName) => ({
      value: pageName,
      label: pageName,
    }));
  }, [noMatchDetail]);

  // Version filter options
  const versionFilterData = useMemo(() => {
    const versionNames = new Set<string>();
    noMatchDetail.forEach((detail) => versionNames.add(detail.version));
    return Array.from(versionNames).map((pageName) => ({
      value: pageName,
      label: pageName,
    }));
  }, [noMatchDetail]);

  // Flow filter options
  const flowFilterData = useMemo(() => {
    const flowNames = new Set<string>();
    noMatchDetail.forEach((detail) => flowNames.add(detail.flowId));
    return Array.from(flowNames).map((pageName) => ({
      value: pageName,
      label: pageName,
    }));
  }, [noMatchDetail]);

  // Fetch no match summary
  const fetchNoMatchSummary = useCallback(async () => {
    setIsLoading(true);
    if (dateRange?.[0] === undefined || dateRange?.[1] === undefined) {
      return;
    }

    try {
      const response = await PredictionApi.fetchNoMatchSummary({
        profile: customerProfile,
        usecase: `${customerProfile}/usecases/${customer?.usecaseId}`,
        filter: {
          beginTime: dateRange[0].toISOString(),
          endTime: dateRange[1].toISOString(),
        },
      });

      if (response) {
        setNoMatchDetail(response.noMatchDetails);
      }
    } catch (err) {
      openNotification('error', 'Error', undefined, err);
    } finally {
      setIsLoading(false);
    }
  }, [customerProfile, dateRange]);

  useEffect(() => {
    fetchNoMatchSummary();
  }, [customer.path, dateRange]);

  const versionTableColumns: ColumnDef<{
    title: string;
    noMatches: number;
    percentage: number;
  }>[] = [
    {
      accessorFn: (row) => row.title,
      header: 'Version',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.noMatches,
      header: 'No-Matches',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.percentage,
      header: '%',
      cell: (info) => round(Number(info.getValue()) * 100, 2),
    },
  ];

  const flowSummaryTableColumns: ColumnDef<{
    title: string;
    noMatches: number;
    percentage: number;
  }>[] = [
    {
      accessorFn: (row) => row.title,
      header: 'Flow',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.noMatches,
      header: 'No-Matches',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.percentage,
      header: '%',
      cell: (info) => round(Number(info.getValue()) * 100, 2),
    },
  ];

  const pageSummaryTableColumns: ColumnDef<{
    title: string;
    noMatches: number;
    percentage: number;
  }>[] = [
    {
      accessorFn: (row) => row.title,
      header: 'Page',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.noMatches,
      header: 'No-Matches',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.percentage,
      header: '%',
      cell: (info) => round(Number(info.getValue()) * 100, 2),
    },
  ];

  const noMatchData: NoMatchTableDataRow[] = useMemo(() => {
    const total = noMatchDetail.reduce((acc, detail) => acc + detail.noMatchCount, 0);
    return noMatchDetail.map((detail) => ({
      flow: detail.flowId,
      version: detail.version,
      page: detail.pageName,
      utterance: detail.utterance,
      count: detail.noMatchCount,
      percentage: round(detail.noMatchCount / total, 2),
    }));
  }, [noMatchDetail]);

  const filteredNoMatchData = useMemo(() => {
    let data = noMatchData;
    if (filter.flow) {
      data = data.filter((row) => row.flow === filter.flow);
    }
    if (filter.version) {
      data = data.filter((row) => row.version === filter.version);
    }
    if (filter.page) {
      data = data.filter((row) => row.page === filter.page);
    }
    return data;
  }, [noMatchData, filter]);

  const { flowSummaryData, pageSummaryData, versionSummaryData } = useMemo(() => {
    const flowMap = new Map<string, number>();
    const pageMap = new Map<string, number>();
    const versionMap = new Map<string, number>();
    filteredNoMatchData.forEach((row) => {
      const flowCount = flowMap.get(row.flow) || 0;
      flowMap.set(row.flow, flowCount + row.count);
      const pageCount = pageMap.get(row.page) || 0;
      pageMap.set(row.page, pageCount + row.count);
      const versionCount = versionMap.get(row.version) || 0;
      versionMap.set(row.version, versionCount + row.count);
    });
    const totalCount = filteredNoMatchData.reduce((acc, row) => acc + row.count, 0);
    const flowSummaryData = Array.from(flowMap.entries()).map(([flow, count]) => ({
      title: flow,
      noMatches: count,
      percentage: count / totalCount,
    }));
    const pageSummaryData = Array.from(pageMap.entries()).map(([page, count]) => ({
      title: page,
      noMatches: count,
      percentage: count / totalCount,
    }));
    const versionSummaryData = Array.from(versionMap.entries()).map(([version, count]) => ({
      title: version,
      noMatches: count,
      percentage: count / totalCount,
    }));
    return {
      flowSummaryData,
      pageSummaryData,
      versionSummaryData,
    };
  }, [filteredNoMatchData]);

  const nomatchTableColumns: ColumnDef<NoMatchTableDataRow>[] = [
    {
      accessorFn: (row) => row.flow,
      header: 'Flow',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.version,
      header: 'Version',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.page,
      header: 'Page',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.utterance,
      header: 'No-Match Utterance',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.count,
      header: '#',
      cell: (info) => info.getValue(),
    },
    {
      accessorFn: (row) => row.percentage,
      header: '%',
      cell: (info) => `${info.getValue()}%`,
    },
  ];

  // Export utterances as CSV.
  const exportCSV = () => {
    const headerRow = [
      'Flow',
      'Version',
      'Page',
      'No-Match Utterance',
      '#',
      '%',
    ];

    const rows = filteredNoMatchData.map((item) => {
      const row = nomatchTableColumns.map((col: any) => {
        const value = col.accessorFn ? col.accessorFn(item) : '';
        return value;
      });
      return row;
    });
    const csvData = [headerRow, ...rows];

    const csvString = CSV.stringify(csvData);
    const dataString = `data:text/tab-separated-values;charset=utf-8,${encodeURIComponent(csvString)}`;
    downloadDataString(dataString, 'nomatch.csv');
  };

  return (
    <PageContainer>
      <Title>Bot Metrics</Title>
      <Group>
        <Select
          data={flowFilterData}
          placeholder="Flow filter"
          value={filter.flow}
          clearable
          onChange={(value) => setFilter((prevFilter) => ({
            ...filter,
            flow: value,
          }))}
        />
        <Select
          data={pageFilterData}
          value={filter.page}
          clearable
          placeholder="Page filter"
          onChange={(value) => setFilter((prevFilter) => ({
            ...filter,
            page: value,
          }))}
        />
        <Select
          data={versionFilterData}
          placeholder="Version filter"
          value={filter.version}
          clearable
          onChange={(value) => setFilter((prevFilter) => ({
            ...filter,
            version: value,
          }))}
        />
        <DatePickerInput
          type="range"
          style={{ width: 300 }}
          placeholder="Pick dates range"
          value={dateRange}
          onChange={setDateRange}
        />
      </Group>
      <SimpleGrid mt="sm" cols={3} spacing="xl">
        <Box>
          <Text size="xl" mb="xs">Flow Summary</Text>
          <DataGrid
            columns={flowSummaryTableColumns}
            dataSource={flowSummaryData}
            initialPageSize={5}
            isLoading={isLoading}
          />
        </Box>
        <Box>
          <Text size="xl" mb="xs">Page Summary</Text>
          <DataGrid
            columns={pageSummaryTableColumns}
            dataSource={pageSummaryData}
            initialPageSize={5}
            isLoading={isLoading}
          />
        </Box>
        <Box>
          <Text size="xl" mb="xs">Version Summary</Text>
          <DataGrid
            columns={versionTableColumns}
            dataSource={versionSummaryData}
            initialPageSize={5}
            isLoading={isLoading}
          />
        </Box>
      </SimpleGrid>
      <Box mt="lg">
        <Group position="apart" align="center">
          <Text size="xl" mb="xs">Specific no-matches</Text>
          <Button size="xs" compact color="green" variant="outline" onClick={exportCSV}>Export</Button>
        </Group>
        <DataGrid
          columns={nomatchTableColumns}
          dataSource={filteredNoMatchData}
          isLoading={isLoading}
        />
      </Box>
    </PageContainer>
  );
}
