import { useState, useEffect, useCallback } from 'react';

interface Node {
  id: string;
  children?: Node[];
}
/**
 * Tracks the node expansion state by their IDs.
 */
export function useExpansionState(nodes: Node[], defaultState = true): [
  (node: { id: string }) => boolean, // isExpanded
  (node: { id: string }) => void, // flipExpansionState
  boolean, // initialized
] {
  const [expanded, setExpanded] = useState<Map<string, boolean>>(new Map());
  // Signal that the expansion state is initialized.
  const [initialized, setInitialized] = useState(false);
  const traverseNodes = (nodes: Node[], callback: (node: Node) => void) => {
    if (!nodes) {
      return;
    }
    for (const node of nodes) {
      callback(node);
      traverseNodes(node.children, callback);
    }
  };
  useEffect(() => {
    const nextExpanded = new Map<string, boolean>();
    traverseNodes(nodes, (node) => {
      if (expanded.has(node.id)) {
        nextExpanded.set(node.id, expanded.get(node.id));
      } else {
        nextExpanded.set(node.id, defaultState);
      }
    });
    setExpanded(nextExpanded);
    setInitialized(true);
  }, [nodes, defaultState]);

  const isExpanded = useCallback((node: { id: string }) => !!expanded.get(node.id), [expanded]);

  const flipExpansionState = useCallback((node: { id: string }) => {
    if (expanded.has(node.id)) {
      const nextExpanded = new Map(expanded);
      nextExpanded.set(node.id, !expanded.get(node.id));
      setExpanded(nextExpanded);
    }
  }, [expanded]);

  return [isExpanded, flipExpansionState, initialized];
}
