import { User } from '@cresta/web-client/dist/cresta/v1/user';
import { createSelector } from '@reduxjs/toolkit';
import { getId } from 'common/resourceName';
import type { State } from '../store';
import type { State as UserState } from './state';

const selectUser = (state: State) => state.user;

/** Select users arrays. */
export const selectUsers = createSelector(selectUser, (userState: UserState) => userState.users);

/** Select users map. */
export const selectUsersMap = createSelector(selectUser, (userState: UserState) => new Map<string, User>(userState.users.map((user) => [getId('user', user.name), user])));

/** Select user by its resource name. */
export const selectUserByNameFactory = (userName: string) => createSelector(selectUser, (userState: UserState) => userState.users.find((user) => user.name === userName));

/** Select user by id. */
export const selectUserByIdFactory = (id: string) => createSelector(selectUser, (userState: UserState) => userState.users.find((user) => getId('user', user.name) === id));
