import { createSlice } from '@reduxjs/toolkit';
import { userApiFailed, userApiPending, listUserSuccess, getOrCreateUserSuccess } from './reducers';
import { listUser, createUser, getUser } from './asyncThunks';
import { INITIAL_STATE } from './state';

/** User slice. */
export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(listUser.pending, userApiPending)
      .addCase(listUser.rejected, userApiFailed)
      .addCase(listUser.fulfilled, listUserSuccess)
      .addCase(getUser.pending, userApiPending)
      .addCase(getUser.rejected, userApiFailed)
      .addCase(getUser.fulfilled, getOrCreateUserSuccess)
      .addCase(createUser.pending, userApiPending)
      .addCase(createUser.rejected, userApiFailed)
      .addCase(createUser.fulfilled, getOrCreateUserSuccess),
});
