import { Annotation, BinaryValue, BinaryValueValue, MessageRawData, MessageRawDataConfusionType, MessageRawDataContextShown } from '@cresta/web-client/dist/cresta/v1/studio/annotations/annotation.pb';
import { Concept } from '@cresta/web-client/dist/cresta/v1/studio/concept/concept.pb';
import { Message } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';
import { SaveTemporalAnnotationRequestRawDataValueTuple } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task_service.pb';
import classNames from 'classnames';
import LabelingItemsDisplay from 'components/LabelingItemsDisplay';
import React, { useCallback, useEffect, useMemo } from 'react';
import { GotoNextType } from 'pages/LabelingView';
import PolicyDecision from 'components/LabelingDecision/PolicyDecision/PolicyDecision';
import { MomentAnnotationAdherenceType } from '@cresta/web-client';
import ConceptTag from 'components/ConceptTag';
import { Message as ConversationMessage } from '@cresta/web-client/dist/cresta/v1/studio/message/message_service.pb';
import moment from 'moment';
import { getId } from 'common/resourceName';
import { Prediction } from '@cresta/web-client/dist/cresta/v1/studio/prediction/prediction_service.pb';
import { ConceptTagColor } from 'components/ConceptTag/ConceptTag';
import { SDXNegative, SDXPositive } from './guidelines';

interface PolicyQaViewProps {
  concepts: Concept[];
  messages: Message[];
  conversationMessages?: ConversationMessage[];
  predictions: Prediction[];
  targetMessage: Message;
  messageAnnotationsMap: Map<string, Annotation[]>;
  failedMessages: { [id: string]: boolean };
  setTargetMessage: (message: Message) => void;
  getConceptTitle: (conceptId: string) => string;
  getConceptRole: (conceptId: string) => 'agent' | 'visitor';
  createAnnotation: (
    messageRawData: MessageRawData,
    binaryValue: BinaryValue,
    momentAdherenceType?: MomentAnnotationAdherenceType
  ) => SaveTemporalAnnotationRequestRawDataValueTuple;
  upsertAnnotations: (
    annotations: SaveTemporalAnnotationRequestRawDataValueTuple[],
    gotoNext: GotoNextType,
    toBeRemovedAnnotations?: Annotation[]) => void;
  removeAnnotations: (annotations: Annotation[]) => void;
  setOverviewConceptId?: (id: string) => void;
}

export function PolicyQaView({
  concepts,
  messages,
  conversationMessages = [],
  targetMessage,
  messageAnnotationsMap,
  predictions,
  failedMessages,
  setTargetMessage,
  getConceptTitle,
  getConceptRole,
  createAnnotation,
  upsertAnnotations,
  removeAnnotations,
  setOverviewConceptId = () => null,
} : PolicyQaViewProps) {
  const targetMessageId = targetMessage?.v2MessageId;
  const targetMessageAnnotations = messageAnnotationsMap.get(targetMessageId) || [];
  const targetMessageIndex = messages.findIndex((message) => message?.v2MessageId === targetMessageId);

  useEffect(() => {
    const nextIntentPredictionIds = targetMessage?.selectionContext?.policyQaContext?.nextIntentPredictionIds || [];
    if (nextIntentPredictionIds.length === 0) {
      // No SDX
      setOverviewConceptId(getId('concept', SDXNegative.name));
    } else {
      // Has at least 1 SDX
      setOverviewConceptId(getId('concept', SDXPositive.name));
    }
  }, [targetMessage]);

  const submitAnnotations = useCallback((newAnnotations: SaveTemporalAnnotationRequestRawDataValueTuple[], gotoNext: GotoNextType) => {
    upsertAnnotations(newAnnotations, gotoNext);
  }, [upsertAnnotations]);

  const removeAnnotationsHandler = useCallback(() => {
    const allConversationAnnotations = Array.from(messageAnnotationsMap.values()).reduce((acc, annotations) => ([...acc, ...annotations]), []);
    removeAnnotations(allConversationAnnotations);
  }, [upsertAnnotations, messageAnnotationsMap]);

  const annotationsList = useMemo(() => Array.from(messageAnnotationsMap.values()).reduce((acc, annotationsArray) => ([...acc, ...annotationsArray]), []), [messageAnnotationsMap]);

  const conversationMessagesMap = useMemo(() => new Map<string, ConversationMessage>(conversationMessages.map((convMessage) => [
    getId('conversationMessage', convMessage.name),
    convMessage,
  ])), [conversationMessages]);

  // Instead of using annotations we compose custom tags
  const customMessageTags = (message: Message) => {
    const messageId = message?.v2MessageId;

    const messageAnnotations = messageAnnotationsMap.get(messageId) || [];
    const nextIntentPredictionIds = message?.selectionContext?.policyQaContext?.nextIntentPredictionIds || [];
    const nextIntentConceptIds = message?.selectionContext?.policyQaContext?.nextIntentConceptIds || [];
    const currentIntentIds = message.selectionContext?.policyQaContext?.currentIntentConceptIds || [];
    const currentIntentPredictionIds = message.selectionContext?.policyQaContext?.currentIntentPredictionIds || [];

    const currentIntentNameRoleTuples = currentIntentIds.map((conceptId) => {
      const tuple: [string, 'agent' | 'visitor'] = [getConceptTitle(conceptId), getConceptRole(conceptId)];
      return tuple;
    });
    const incorrectCurrentIntentAnnotations = annotationsList.filter((annotation) =>
      currentIntentPredictionIds.includes(annotation.rawData.messageRawData.predictionId));

    const truePositives: Annotation[] = [];
    const falseNegatives: Annotation[] = [];
    const falsePositives: Annotation[] = [];
    messageAnnotations.forEach((annotation) => {
      if (annotation.rawData.messageRawData.confusionType === MessageRawDataConfusionType.TRUE_POSITIVE) {
        truePositives.push(annotation);
      }
      if (annotation.rawData.messageRawData.confusionType === MessageRawDataConfusionType.FALSE_NEGATIVE) {
        falseNegatives.push(annotation);
      }
      if ([
        MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE,
        MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER,
      ].includes(annotation.rawData.messageRawData.confusionType)) {
        falsePositives.push(annotation);
      }
    });

    // Get message datetime info
    const conversationMessage = conversationMessagesMap.get(messageId);
    const timeInfo = conversationMessage ? <p className="appear-fade">{moment(conversationMessage.publishTime).format('hh:mm:ss a')}</p> : <p>&nbsp;</p>;

    if (currentIntentIds?.length || nextIntentPredictionIds?.length) {
      return (
        <div className={classNames(['policy-qa-message-intents'])}>
          {timeInfo}
          <span>Current: {currentIntentIds?.length === 0 && <span>None</span>}</span>
          {incorrectCurrentIntentAnnotations?.length > 0 ? (
            <>
              {incorrectCurrentIntentAnnotations.map((annotation) => (
                <ConceptTag
                  key={annotation.name}
                  role={getConceptRole(annotation.value.binaryValue.conceptId)}
                  value={getConceptTitle(annotation.value.binaryValue.conceptId)}
                  color={annotation.value.binaryValue.value === BinaryValueValue.VALUE_SKIP ? 'green' : 'red'}
                />
              ))}
            </>
          ) : (
            <>
              {currentIntentNameRoleTuples.map(([intentName, intentRole]) => (
                <ConceptTag
                  key={intentName}
                  role={intentRole}
                  value={intentName}
                />
              ))}
            </>
          )}
          <div>
            <span>SDX: {nextIntentPredictionIds?.length === 0
            && (<span className={falseNegatives?.length > 0 ? 'policy-qa-none-incorrect' : ''}>None</span>)}
            </span>
            {nextIntentPredictionIds.map((nextIntentPredictionId, i) => {
              const nextIntentConceptId = nextIntentConceptIds[i];
              const isFalse = falsePositives.map((annotation) => annotation.value.binaryValue.conceptId).includes(nextIntentConceptId);
              const isTrue = truePositives.map((annotation) => annotation.value.binaryValue.conceptId).includes(nextIntentConceptId);
              let conceptColor: ConceptTagColor = 'blue';
              if (isTrue) { conceptColor = 'green'; }
              if (isFalse) { conceptColor = 'red'; }
              return (
                <ConceptTag
                  key={nextIntentPredictionId}
                  value={getConceptTitle(nextIntentConceptId)}
                  role={getConceptRole(nextIntentConceptId)}
                  color={conceptColor}
                />
              );
            })}
            {falseNegatives.map((annotation) => (
              <ConceptTag
                key={annotation.name}
                value={getConceptTitle(annotation.value.binaryValue.conceptId)}
                role={getConceptRole(annotation.value.binaryValue.conceptId)}
                color="green"
              />
            ))}
          </div>
        </div>
      );
    }
    return <div className={classNames(['policy-qa-message-intents'])}>{ timeInfo }</div>;
  };

  const hasFalseAnnotations = annotationsList.some((annotation) => [
    MessageRawDataConfusionType.FALSE_NEGATIVE,
    MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_RULE,
    MessageRawDataConfusionType.FALSE_POSITIVE_CAUSED_BY_TRIGGER,
  ].includes(annotation.rawData.messageRawData.confusionType));

  // Only update context when messages change (moving to another chat)
  const context = useMemo(
    () => (
      hasFalseAnnotations ? MessageRawDataContextShown.FULL_CONVERSATION_SHOWN : MessageRawDataContextShown.PARTIAL_CONVERSATION_CONTEXT_SHOWN)
    , [messages],
  );

  return (
    <div
      className={classNames([
        'labeling-view',
        'vertical-layout',
      ])}
    >
      <div className="labeling-items-display">
        <LabelingItemsDisplay
          context={context}
          showContextButtonVisible={false}
          messages={messages}
          targetMessageId={targetMessageId}
          setTargetMessage={setTargetMessage}
          getConceptTitle={getConceptTitle}
          failedMessages={failedMessages}
          annotationsDisplayType="concepts-colors"
          customMessageTags={customMessageTags}
          // Show messages from first message to target message
          partialChatTrailingMessages={targetMessageIndex}
        />
      </div>
      <div className="labeling-decision-panel">
        <PolicyDecision
          concepts={concepts}
          message={targetMessage}
          annotations={targetMessageAnnotations}
          predictions={predictions}
          createAnnotation={createAnnotation}
          submitAnnotations={submitAnnotations}
          getConceptTitle={getConceptTitle}
          getConceptRole={getConceptRole}
          contextShown={context}
          removeAnnotations={removeAnnotationsHandler}
          showRedoButton={hasFalseAnnotations}
        />
      </div>
    </div>
  );
}
