import { ModelName, Split, TaskType } from './constants/actions';

// typings derived from server types
export interface AgentInfo {
  agentName: string;
  agentAvailability: string;
  // 15 Feb, BP:  the agentId prop is not formally in the AgentInfo type, however some parts of the
  // app still rely on it being there, so this should be removed once those parts are refactored
  agentId: string;
}

export interface Suggestion {
  id: number;
  text: string;
  model: string;
  confidence: string;
  timestamp: string;
  message: Message;
  author: Agent | null;
}

export interface Hint {
  id: string;
  text: string;
  label: string;
  model: string;
  example: string;
  timestamp: string;
  followed: boolean;
}

export interface Message {
  id: string;
  msgId: string;
  speakerRole: string;
  speakerId: string;
  pubDate: Date;
  chat_name: string;
  text: string;
  suggestions: Suggestion[];
  hints: Hint[];
  basedOnSuggestion?: boolean;
  manual_studio_labels?: ManualStudioLabel[];
}

export interface Agent {
  nickname: string;
  id?: string; // TODO make required
  email: string;
  agentId: string;
  lastHeartbeat: string;
  agentInfo: AgentInfo;
  chats: Chat[];
}

export interface Chat {
  id?: string; // TODO make required
  openDate: Date;
  status: string;
  chatId: string;
  agent: Agent;
  agents?: Agent[];
  latestAgent?: Agent;
  chatValue?: string;
  messages?: Message[];
  timestamp?: string;
  platformSessionId?: string;
  selectedMessageIds?: string[]; // message ids that belong to the filter.
}

export interface User {
  id: string;
  email: string;
  full_name: string;
  role: string;
}

// TODO(Gray): Remove this type once all labels and tasks are migrated to v2.
export interface LegacyAnnotator {
  id: number;
  email: string;
  name: string;
  source: string;
  firebase_id?: string;
  created_at?: string;
  updated_at?: string;
}

export type Intent = string;

export interface Action {
  id: string;
  intent: string;
  speakerRole: string;
  type: Intent;
  inactive: boolean;
}

export interface LabelingInputFilter {
  start_datetime?: string;
  end_datetime?: string;
  limit?: number;
  last_message_ilike?: string;
  last_speaker_role?: string;
  message_ids?: string[];
  messageIds?: string[]; // TODO replace usages with snake case
  chat_tags?: string;
}

// TODO find and replace usages
export interface LabelingTask {
  id?: number;
  labeling_input_filter: LabelingInputFilter;
  labeling_guideline?: string;
  action?: string;
  original_task_ids: number[];
  coach?: {
    name: string;
    email: string;
  };
  label_count?: { aggregate: { count: number } };
}

interface LabelingTaskBase {
  id: number;
  labeling_input_filter: LabelingInputFilter;
  split: Split;
  task: TaskType;
  created_at: string;
  original_task_ids: number[];
}

export interface ManualLabelingTask extends LabelingTaskBase {
  action: string;
}

export interface ManualStudioLabel {
  id: number;
  label: string;
  value: boolean;
  labeler_id: number;
  task_id: number;
  message_id: string;
  created_at: string;
  message?: Message;
  labeling_task?: ManualLabelingTask;
}

export enum SpeakerRole {
  AGENT = 'agent',
  VISITOR = 'visitor',
  SYSTEM = 'system',
  BOT = 'bot',
}

export interface Model {
  name: string;
  lastModifiedAt: string;
  type: ModelName;
}

export enum LabelValue {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

// For select filters in utterance view
export type ConfustionTypeFilter = 'TP' | 'FP' | 'FP_RULES' | 'FP_TRIGGERS' | 'TN' | 'FN' | 'CORRECT' | 'INCORRECT';

// Not typed in the proto
export interface PolicyDebugOutput {
  trigger_intent: string;
  rule_evaluation: {
    edge_as_str: string;
    conditions: {
      condition_as_str: string;
      value: boolean;
    }[];
  }
  filter_evaluation: {
    edge_as_str: string;
    conditions: {
      condition_as_str: string;
      value: boolean;
    }[];
  }
}
