import React from 'react';
import { ModelArtifactStatus } from '@cresta/web-client/dist/cresta/v1/studio/models/artifact/model_artifact_service.pb';
import { DefaultMantineColor, Indicator } from '@mantine/core';

export function StatusIndicator({ text, color }: {
  text: string,
  color: DefaultMantineColor
}) {
  return (
    <Indicator zIndex={1} pl="sm" position="middle-start" size={8} color={color} radius="xl">{text}</Indicator>
  );
}

export function getDeploymentStatusElement(status: ModelArtifactStatus, theme) {
  switch (status) {
    case ModelArtifactStatus.ACCEPTED:
      return <StatusIndicator color={theme.colors.yellow[4]} text="In progress"/>;
    case ModelArtifactStatus.ACTIVE:
      return <StatusIndicator color={theme.colors.green[4]} text="Deployed" />;
    case ModelArtifactStatus.FAILED:
      return <StatusIndicator color={theme.colors.red[4]} text="Failed" />;
    case ModelArtifactStatus.DELETED:
      return <StatusIndicator color={theme.colors.gray[4]} text="Deleted" />;
    default:
      return null;
  }
}
