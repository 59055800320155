import React from 'react';
import { Button, Stack, Text } from '@mantine/core';
import * as SetCard from './components';

export const ControlItem = ({ onClick, cypressId, children }) => (
  <Button style={{ flex: 1 }} variant="subtle" onClick={onClick} data-cy={cypressId}>
    <Stack align="center">
      <Text size="xl">+</Text>
      <Text>{children}</Text>
    </Stack>
  </Button>
);

export const ControlCard = ({ children }) => (
  <SetCard.Container>
    {children}
  </SetCard.Container>
);
