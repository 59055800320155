import React from 'react';
import './TaskTypeTag.scss';
import classNames from 'classnames';
import { UserLabelingTaskType } from '@cresta/web-client/dist/cresta/v1/studio/tasks/labelingtask/labeling_task.pb';

export type UnionTaskType = UserLabelingTaskType|ExtendedTaskType;

export enum ExtendedTaskType {
  TASK_TYPE_DUAL = 'TASK_TYPE_DUAL',
  QA_SUGGESTION_COMPARE = 'QA_SUGGESTIONS_COMPARE',
}

interface TaskTypeTagProps {
  taskType: UnionTaskType;
}

const TASK_TYPES_MAP = new Map<UnionTaskType, { text: string, className: string }>([
  [UserLabelingTaskType.USER_LABELING_TASK_TYPE_CALIBRATION, {
    text: 'Calibration',
    className: 'calibration',
  }],
  [UserLabelingTaskType.AUTO_LABELING, {
    text: 'AutoLabeling',
    className: 'auto-labeling',
  }],
  [UserLabelingTaskType.USER_LABELING_TASK_TYPE_DENSE_LABELING, {
    text: 'Dense Labeling',
    className: 'dense',
  }],
  [UserLabelingTaskType.USER_LABELING_TASK_TYPE_DYNAMIC_LABELING, {
    text: 'Dynamic Labeling',
    className: 'dynamic',
  }],
  [UserLabelingTaskType.USER_LABELING_TASK_TYPE_MANUAL_LABELING, {
    text: 'Sparse Labeling',
    className: 'manual',
  }],
  [UserLabelingTaskType.USER_LABELING_TASK_TYPE_UNSPECIFIED, {
    text: 'Unspecified',
    className: 'unspecified',
  }],
  [UserLabelingTaskType.LABELING_ENTITY, {
    text: 'Entity',
    className: 'entity',
  }],
  [UserLabelingTaskType.LABELING_CLO, {
    text: 'CLO',
    className: 'clo',
  }],
  [UserLabelingTaskType.LABELING_SUMMARIZATION, {
    text: 'Summarization',
    className: 'summarization',
  }],
  [UserLabelingTaskType.QA_HINT_DDX, {
    text: 'Hints: SDX',
    className: 'hint',
  }],
  [UserLabelingTaskType.QA_HINT_SDX, {
    text: 'Hints: DDX',
    className: 'hint',
  }],
  [UserLabelingTaskType.QA_HINT_SDX_DDX, {
    text: 'Hints: SDX+DDX',
    className: 'hint',
  }],
  [UserLabelingTaskType.QA_INTENT_PREDICTION, {
    text: 'Intents - Precision',
    className: 'intent-prediction',
  }],
  [UserLabelingTaskType.QA_INTENT_PREDICTION_RECALL, {
    text: 'Intents - All',
    className: 'intent-recall',
  }],
  [UserLabelingTaskType.QA_POLICY, {
    text: 'Policy',
    className: 'qa-policy',
  }],
  [UserLabelingTaskType.QA_SUGGESTIONS, {
    text: 'Suggestions',
    className: 'qa-suggestions',
  }],
  [UserLabelingTaskType.QA_SUMMARIZATION, {
    text: 'Summarization',
    className: 'qa-summarization',
  }],
  [UserLabelingTaskType.LABELING_SPARSE_CLO, {
    text: 'CLO - Sparse',
    className: 'clo-sparse',
  }],
  [UserLabelingTaskType.LABELING_DENSE_CLO, {
    text: 'CLO - Dense',
    className: 'clo-dense',
  }],
  [ExtendedTaskType.TASK_TYPE_DUAL, {
    text: 'Dual',
    className: 'dual',
  }],
  [ExtendedTaskType.QA_SUGGESTION_COMPARE, {
    text: 'Suggestions Comparison',
    className: 'qa-suggestions',
  }],
  [UserLabelingTaskType.QA_INTENT_MISCLASSIFICATION, {
    text: 'Misclassification',
    className: 'misclassification',
  }],
  [UserLabelingTaskType.INTENT_MISCLASSIFICATION_REVIEW, {
    text: 'Misc. Review',
    className: 'misc-review',
  }],
]);

export default function TaskTypeTag({ taskType }: TaskTypeTagProps) {
  return (
    <div className={classNames(['labeling-task-type-tag', TASK_TYPES_MAP.get(taskType)?.className])}>{TASK_TYPES_MAP.get(taskType)?.text}</div>
  );
}
