import React, { ReactElement, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'hooks/reduxHooks';
import { useCustomerParams, useCustomerProfile } from 'hooks/useCustomerParams';
import { ModelProject, ModelProjectProjectType } from '@cresta/web-client/dist/cresta/v1/studio/models/project/model_project_service.pb';
import { listModelProjects } from 'store/modelProject/asyncThunks';
import { selectModelProjectById } from 'store/modelProject/selectors';
import { Button } from '@mantine/core';
import externalLinkIcon from 'assets/svg/icon-external-link.svg';
import styles from './styles.module.scss';
import { Generative } from './ModelWizardProjectDashboard/ProjectTypes/Generative';
import { Retrieval } from './ModelWizardProjectDashboard/ProjectTypes/Retrieval';
import { Intents } from './ModelWizardProjectDashboard/ProjectTypes/Intents';
import { SmartCompose } from './ModelWizardProjectDashboard/ProjectTypes/SmartCompose';
import { Ensemble } from './ModelWizardProjectDashboard/ProjectTypes/Ensemble';

/**
 * TODO: Add doc for this page.
 */
export function ModelWizardProject(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerProfile = useCustomerProfile();
  const { usecaseId, languageCode, path } = useCustomerParams();
  const params = useParams<{ projectId: string }>();
  useEffect(() => {
    dispatch(listModelProjects({
      parent: customerProfile,
      usecaseId,
      languageCode,
    }));
  }, [path]);

  const modelProject = useSelector<ModelProject>(selectModelProjectById(params.projectId));

  const handleBackProject = useCallback(() => {
    navigate(`/${path}/model-wizard`);
  }, [navigate, params]);

  const getProjectTypeComponent = (projectType: ModelProjectProjectType) => {
    switch (projectType) {
      case ModelProjectProjectType.SUGGESTION_GENERATIVE:
        return <Generative />;
      case ModelProjectProjectType.SUGGESTION_RETRIEVAL:
        return <Retrieval />;
      case ModelProjectProjectType.SUGGESTION_ENSEMBLE:
        return <Ensemble />;
      case ModelProjectProjectType.SMART_COMPOSE:
        return <SmartCompose />;
      case ModelProjectProjectType.VISITOR_INTENT:
      case ModelProjectProjectType.AGENT_INTENT:
      case ModelProjectProjectType.CONVERSATION_DRIVER:
      case ModelProjectProjectType.PROJECT_TYPE_UNSPECIFIED:
      default:
        return <Intents />;
    }
  };

  return (
    <div className={styles.ModelWizardProject}>
      <div className={styles.projectHeader}>
        <Button onClick={handleBackProject} variant="subtle">
          <ArrowLeftOutlined style={{ marginRight: '4px' }} />
          Back to projects
        </Button>
        <div className={styles.projectTitle}>
          <span className={styles.title}>{modelProject?.displayName || '---'}</span>
          <span className={styles.spacer} />
          <Link
            to={`/${path}/model-builder?project=${params.projectId}&tab=EVALUATION`}
            target="_blank"
          >
            <img src={externalLinkIcon} alt="Open link" />&nbsp;Open in Model Builder
          </Link>
        </div>
      </div>
      {getProjectTypeComponent(modelProject?.projectType)}
    </div>
  );
}
